import { createTheme } from '@material-ui/core';
import { AR } from 'helpers';
import { ArrowDownIcon } from 'assets/icons';

// Import components
import { ScrollbarForTheme } from 'components/utilities';

// Import overrides
import palette from './palette';
import typography from './typography';
import layout from './layout';
import overrides from './overrides';

const themeProps = {
	palette,
	typography,
	layout,
	overrides,
	props: {
		MuiSelect: {
			MenuProps: { PaperProps: { component: ScrollbarForTheme } },
			IconComponent: ArrowDownIcon,
		},
		MuiTextField: {
			InputLabelProps: {
				shrink: false,
			},
		},
	},
	zIndex: {
		appBar: 1200,
		drawer: 1100,
	},
};

const theme = createTheme(themeProps);

export const generateTheme = (lng) => {
	const muiFonts = ['"Inter"', '"Helvetica"', '"Arial"', 'sans-serif'];

	lng === AR && muiFonts.unshift('"Hanimation Arabic"');

	muiFonts.join(',');

	return createTheme({
		...themeProps,
		typography: { ...themeProps.typography, fontFamily: muiFonts },
	});
};

export default theme;
