import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { object } from 'prop-types';

// Import components
import {
	ActiveTableCell,
	RatingTableCell,
	AmountTableCell,
	ExpandTableCell,
	RoleTableCell,
} from 'components/elements';
import { ExpandedRow } from '../../components';

// Import utilities
import { useTableActionsProvider } from '../../context/TableActionsProvider';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { toFixedWithoutZeros } from 'helpers';

const { RATING, ACTIVE, EXPAND, ROLE, EARNINGS } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const { isRowOpen, handleOnExpandRows } = useTableActionsProvider();

	const renderCell = (cell) => {
		const { earnings, rating, is_active, uuid, role } = cell.row.original;

		if (cell.column.isHidden) return null;

		const { id } = cell.column;

		switch (id) {
			case EXPAND:
				return (
					<ExpandTableCell
						key={id}
						cell={cell}
						isOpen={isRowOpen(uuid)}
						onClick={() => handleOnExpandRows(uuid)}
					/>
				);

			case RATING:
				return (
					<RatingTableCell
						key={cell.column.id}
						cell={cell}
						rating={toFixedWithoutZeros(+rating, 2)}
					/>
				);

			case ROLE:
				return (
					<RoleTableCell key={cell.column.id} cell={cell} userRole={role} />
				);

			case EARNINGS:
				return <AmountTableCell key={id} cell={cell} amount={earnings} />;

			case ACTIVE:
				return (
					<ActiveTableCell
						key={id}
						isActive={!!is_active}
						cell={cell}
						asLabel
					/>
				);

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<>
			<TableRow key={row.id} {...row.getRowProps()}>
				{row.cells.map(renderCell)}
			</TableRow>

			<ExpandedRow
				isExpanded={isRowOpen(row.original.uuid)}
				colSpan={row.cells.length}
				employeeUuid={row.original.uuid}
			/>
		</>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
