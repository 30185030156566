import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';
import { useCustomToolbar } from './useCustomToolbar';

// Import components
import { Filters } from '../../components';
import { PlatesFile } from 'views/Cars/PlatesFile';

export const CustomToolbar = () => {
	const { pathname } = useLocation();
	const { t } = useTranslations();

	const {
		isOpen,
		togglePlatesFileDialog,
		goToAddCarForm,
		goToAddPlateForm,
		isAdmin,
	} = useCustomToolbar();

	const isContractPage = pathname.includes('contract');

	return (
		<Grid container justifyContent="flex-end" spacing={2}>
			{isAdmin && (
				<Grid item xs={12}>
					<Filters />
				</Grid>
			)}
			{!isAdmin && !isContractPage && (
				<>
					<Grid item xs="auto">
						<Button
							variant="contained"
							color="primary"
							onClick={goToAddPlateForm}
						>
							{t('common.buttons.add_plate')}
						</Button>
					</Grid>
					<Grid item xs="auto">
						<Button
							variant="contained"
							color="primary"
							onClick={togglePlatesFileDialog}
						>
							{t('common.buttons.plates_file')}
						</Button>
					</Grid>
					<PlatesFile toggle={togglePlatesFileDialog} isOpen={isOpen} />
				</>
			)}
			{isAdmin && (
				<Grid item xs="auto">
					<Button variant="contained" color="primary" onClick={goToAddCarForm}>
						{t('common.buttons.add_car')}
					</Button>
				</Grid>
			)}
		</Grid>
	);
};
