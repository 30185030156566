import {
	Main as MainLayout,
	PrintContent as PrintContentLayout,
} from 'layouts';

// Import Authorization
import RoleAuthorization from 'components/hoc/RoleAuthorization';

// Import roles
import {
	ADMINS,
	ADMINS_WITH_CORPORATE_COORDINATOR,
	COMPANY_MANAGER,
	COMPANY_MANAGERS,
	COMPANY_MANAGERS_WITHOUT_ADMINS,
	CORPORATE_COORDINATOR,
	OFFICE_MANAGER,
	OFFICE_MANAGERS,
	OFFICE_MANAGERS_WITH_CORPORATE_COORDINATOR,
	OFFICE_MANAGERS_WITH_DRIVERS,
} from 'helpers';

// Import components
import {
	Dashboard as DashboardView,
	BookingsList as BookingsListView,
	EditBooking as EditBookingView,
	ScheduledBookings as ScheduledBookingsView,
	LateDeliveryBookings as LateDeliveryBookingsView,
	LastBooking as LastBookingView,
	Offices as OfficesView,
	EditOffice as EditOfficeView,
	OfficeDetails as OfficeDetailsView,
	PreviewBooking as PreviewBookingView,
	Cars as CarsView,
	Customers as CustomersView,
	PhoneNumberTable as PhoneNumberTableView,
	CustomerPreview as CustomerPreviewView,
	EditCustomer as EditCustomerView,
	CompanyForm as CompanyFormView,
	EditCompanyManager as EditCompanyManagerView,
	Profile as ProfileView,
	EditCar as EditCarView,
	GlobalEditCars as GlobalEditCarsView,
	AddCar as AddCarView,
	PrintBooking as PrintBookingView,
	GlobalAddCar as GlobalAddCarView,
	Organizations as OrganizationsView,
	CompanyManagers as CompanyManagersView,
	Discounts as DiscountsView,
	EditDiscount as EditDiscountView,
	AddDiscount as AddDiscountView,
	CancellationReasons as CancellationReasonsView,
	CancellationReasonForm as CancellationReasonFormView,
	PromoCodes as PromoCodesView,
	EditPromoCode as EditPromoCodeView,
	UserForm as UserFormView,
	Users as UsersView,
	CarList as CarListView,
	CarListForm as CarListFormView,
	ModelForm as ModelFormView,
	Models as ModelsView,
	ManufacturerForm as ManufacturerFormView,
	Manufacturer as ManufacturerView,
	SliderForm as SliderFormView,
	Sliders as SlidersView,
	Pages as PagesView,
	PageForm as PageFormView,
	Partners as PartnersView,
	PartnersForm as PartnersFormView,
	PartnersPositionsForm as PartnersPositionsFormView,
	Cities as CitiesView,
	DefaultSeoForm as DefaultSeoFormView,
	PublicHolidays as PublicHolidaysView,
	NotFound as NotFoundView,
	CompaniesList as CompaniesListView,
	DropCities as DropCitiesView,
	SpecialOffer as SpecialOfferView,
	SpecialOfferForm as SpecialOfferFormView,
	SystemClients as SystemClientsView,
	RatingReasons as RatingReasonsView,
	RatingReasonsForm as RatingReasonsFormView,
	UrgentReasons as UrgentReasonsView,
	UrgentReasonsForm as UrgentReasonsFormView,
	Validation as CompensationValidationView,
	AccountingValidation as AccountingValidationView,
	UrgentBookings as UrgentBookingsView,
	WaitingCustomerInfo as WaitingCustomerInfoView,
	Airports as AirportsView,
	EditAirports as EditAirportsView,
	Terminals as TerminalsView,
	EditTerminals as EditTerminalsView,
	Kiosk as KioskView,
	EditKiosk as EditKioskView,
	Reports as ReportsView,
	PhoneNumberForm as PhoneNumberFormView,
	Campaigns as CampaignsView,
	CampaignsForm as CampaignsFormView,
	ExperienceSharedForm as ExperienceSharedFormView,
	CarsAvailability as CarsAvailabilityView,
	CancellationValidation as CancellationValidationView,
	EmployeeRewardingSettings as EmployeeRewardingSettingsView,
	EmployeePerformanceUsers as EmployeePerformanceUsersView,
	EmployeePerformanceBookings as EmployeePerformanceBookingsView,
	OfficeCars as OfficeCarsView,
	ExtendedBookings as ExtendedBookingsView,
	Drivers as DriversView,
	DriverForm as DriverFormView,
	RewardsSettlement as RewardsSettlementView,
	DriverLicenseTable as DriverLicenseTableView,
	InitiateContract as InitiateContractView,
	Contract as ContractView,
	ExtendContract as ExtendContractView,
	CloseContract as CloseContractView,
	SuspendContract as SuspendContractView,
	CancellationByCustomer as CancellationByCustomerView,
	TransferredBookings as TransferredBookingsView,
	PromoCodePreview as PromoCodePreviewView,
	SubscriptionBalance as SubscriptionBalanceView,
	AddPlate as AddPlateView,
	CorporateList as CorporateListView,
	AddCorporateAndCoordinatorPanel as AddCorporateAndCoordinatorPanelView,
	CorporateOverview as CorporateOverviewView,
	CorporateMaintenance as CorporateMaintenanceView,
	RegistrationRequestsList as RegistrationRequestsListView,
	CorporateSettlements as CorporateSettlementsView,
	UnsettledLimits as UnsettledLimitsView,
	CorporateBookingsList as CorporateBookingsListView,
	RentalCompanies as RentalCompaniesView,
	EditCorporateForm as EditCorporateFormView,
	EditCorporateCustomerForm as EditCorporateCustomerFormView,
	ExternalPartnersCard as ExternalPartnersCardView,
	LeasingCancellation as LeasingCancellationView,
	LeasingCars as LeasingCarsView,
	AddLeasingCar as AddLeasingCarView,
	FaqsList as FaqsListView,
	FaqForm as FaqFormView,
	FinancialReport as FinancialReportView,
	TrainStations as TrainStationsView,
	EditTrainStation as EditTrainStationView,
	EventReport as EventReportView,
} from 'views';

// Import helpers
import { URLS } from './urls';

// Define roles
const CompanyManagers = RoleAuthorization(COMPANY_MANAGERS);
const CompanyManagersOnly = RoleAuthorization(COMPANY_MANAGER);
const OfficeManagers = RoleAuthorization(OFFICE_MANAGERS);
const OfficeManagersWithDrivers = RoleAuthorization(
	OFFICE_MANAGERS_WITH_DRIVERS
);
const CompanyManagersWithoutAdmins = RoleAuthorization(
	COMPANY_MANAGERS_WITHOUT_ADMINS
);
const OfficeManagersOnly = RoleAuthorization(OFFICE_MANAGER);

const Admins = RoleAuthorization(ADMINS);
const CorporateCoordinator = RoleAuthorization(CORPORATE_COORDINATOR);
const OfficeManagersWithCorporateCoordinator = RoleAuthorization(
	OFFICE_MANAGERS_WITH_CORPORATE_COORDINATOR
);
const AdminsWithCorporateCoordinator = RoleAuthorization(
	ADMINS_WITH_CORPORATE_COORDINATOR
);

export const DASH_ROUTES = [
	{
		path: URLS.dashboardUrl,
		component: OfficeManagers(DashboardView),
		layout: MainLayout,
	},
	{
		path: URLS.editBookingUrl,
		component: Admins(EditBookingView),
		layout: MainLayout,
	},
	{
		path: URLS.previewBookingUrl,
		component: OfficeManagersWithCorporateCoordinator(PreviewBookingView),
		layout: MainLayout,
	},
	{
		path: URLS.bookingsUrl,
		component: OfficeManagers(BookingsListView),
		layout: MainLayout,
	},
	{
		path: URLS.extendedBookingsUrl,
		component: OfficeManagers(ExtendedBookingsView),
		layout: MainLayout,
	},
	{
		path: URLS.transferredBookingsUrl,
		component: OfficeManagers(TransferredBookingsView),
		layout: MainLayout,
	},
	{
		path: URLS.printBookingUrl,
		component: OfficeManagers(PrintBookingView),
		layout: PrintContentLayout,
	},
	{
		path: URLS.scheduledBookingsUrl,
		component: OfficeManagers(ScheduledBookingsView),
		layout: MainLayout,
	},
	{
		path: URLS.lateDeliveryBookingsUrl,
		component: OfficeManagers(LateDeliveryBookingsView),
		layout: MainLayout,
	},
	{
		path: URLS.lastBookingUrl,
		component: OfficeManagers(LastBookingView),
		layout: MainLayout,
	},
	{
		path: URLS.carsUrl,
		component: OfficeManagers(CarsView),
		layout: MainLayout,
	},
	{
		path: URLS.editCarUrl,
		component: CompanyManagers(EditCarView),
		layout: MainLayout,
	},
	{
		path: URLS.globalEditCarsUrl,
		component: CompanyManagers(GlobalEditCarsView),
		layout: MainLayout,
	},
	{
		path: URLS.globalEditCarsUrlWithUuid,
		component: CompanyManagers(GlobalEditCarsView),
		layout: MainLayout,
	},
	{
		path: URLS.addCarUrl,
		component: CompanyManagers(AddCarView),
		layout: MainLayout,
	},
	{
		path: URLS.globalAddCarUrl,
		component: CompanyManagers(GlobalAddCarView),
		layout: MainLayout,
	},
	{
		path: URLS.globalAddCarUrlWithUuid,
		component: CompanyManagers(GlobalAddCarView),
		layout: MainLayout,
	},
	{
		path: URLS.createOfficeUrl,
		component: CompanyManagers(EditOfficeView),
		layout: MainLayout,
	},
	{
		path: URLS.editOfficeUrl,
		component: CompanyManagers(EditOfficeView),
		layout: MainLayout,
	},
	{
		path: URLS.officeDetailsUrl,
		component: OfficeManagers(OfficeDetailsView),
		layout: MainLayout,
	},
	{
		path: URLS.officesUrl,
		component: OfficeManagers(OfficesView),
		layout: MainLayout,
	},
	{
		path: URLS.customerPreviewUrl,
		component: CompanyManagers(CustomerPreviewView),
		layout: MainLayout,
	},
	{
		path: URLS.editCustomerUrl,
		component: Admins(EditCustomerView),
		layout: MainLayout,
	},
	{
		path: URLS.officeExternalPartnerCars,
		component: Admins(OfficeCarsView),
		layout: MainLayout,
	},
	{
		path: URLS.externalPartnersList,
		component: Admins(ExternalPartnersCardView),
		layout: MainLayout,
	},
	{
		path: URLS.customersUrl,
		component: Admins(CustomersView),
		layout: MainLayout,
	},
	{
		path: URLS.customerRegistrationUrl,
		component: Admins(PhoneNumberTableView),
		layout: MainLayout,
	},
	{
		path: URLS.addCustomerRegistrationUrl,
		component: Admins(PhoneNumberFormView),
		layout: MainLayout,
	},
	{
		path: URLS.companyOfficesUrl,
		component: Admins(OfficesView),
		layout: MainLayout,
	},
	{
		path: URLS.companyUsersUrl,
		component: Admins(CompanyManagersView),
		layout: MainLayout,
	},
	{
		path: URLS.driversUrl,
		component: CompanyManagers(DriversView),
		layout: MainLayout,
	},
	{
		path: URLS.addDriverUrl,
		component: CompanyManagers(DriverFormView),
		layout: MainLayout,
	},
	{
		path: URLS.editDriverUrl,
		component: CompanyManagers(DriverFormView),
		layout: MainLayout,
	},
	{
		path: URLS.editCompanyUrl,
		component: CompanyManagers(CompanyFormView),
		layout: MainLayout,
	},
	{
		path: URLS.createCompanyUrl,
		component: Admins(CompanyFormView),
		layout: MainLayout,
	},
	{
		path: URLS.companiesListUrl,
		component: Admins(CompaniesListView),
		layout: MainLayout,
	},
	{
		path: URLS.employeeRewardingSettingsUrl,
		component: Admins(EmployeeRewardingSettingsView),
		layout: MainLayout,
	},
	{
		path: URLS.subscriptionBalanceUrl,
		component: CompanyManagers(SubscriptionBalanceView),
		layout: MainLayout,
	},
	{
		path: URLS.editProfileUrl,
		component: OfficeManagers(ProfileView),
		layout: MainLayout,
	},
	{
		path: URLS.editCompanyManagersUrl,
		component: OfficeManagers(EditCompanyManagerView),
		layout: MainLayout,
	},
	{
		path: URLS.createCompanyManagerUrl,
		component: OfficeManagers(EditCompanyManagerView),
		layout: MainLayout,
	},
	{
		path: URLS.companyManagersUrl,
		component: OfficeManagers(CompanyManagersView),
		layout: MainLayout,
	},
	{
		path: URLS.publicHolidaysUrl,
		component: CompanyManagers(PublicHolidaysView),
		layout: MainLayout,
	},
	{
		path: URLS.editOrganizationDiscountUrl,
		component: Admins(EditDiscountView),
		layout: MainLayout,
	},
	{
		path: URLS.addOrganizationDiscountUrl,
		component: Admins(AddDiscountView),
		layout: MainLayout,
	},
	{
		path: URLS.organizationDiscountsUrl,
		component: Admins(DiscountsView),
		layout: MainLayout,
	},
	{
		path: URLS.organizationsUrl,
		component: Admins(OrganizationsView),
		layout: MainLayout,
	},
	{
		path: URLS.editCancellationReasonUrl,
		component: Admins(CancellationReasonFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addCancellationReasonUrl,
		component: Admins(CancellationReasonFormView),
		layout: MainLayout,
	},
	{
		path: URLS.cancellationReasonsUrl,
		component: Admins(CancellationReasonsView),
		layout: MainLayout,
	},
	{
		path: URLS.editPromoCodeUrl,
		component: Admins(EditPromoCodeView),
		layout: MainLayout,
	},
	{
		path: URLS.addPromoCodeUrl,
		component: Admins(EditPromoCodeView),
		layout: MainLayout,
	},
	{
		path: URLS.promoCodesUrl,
		component: Admins(PromoCodesView),
		layout: MainLayout,
	},
	{
		path: URLS.promoCodeUrl,
		component: Admins(PromoCodePreviewView),
		layout: MainLayout,
	},
	{
		path: URLS.editCarModelUrl,
		component: Admins(ModelFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addCarModelUrl,
		component: Admins(ModelFormView),
		layout: MainLayout,
	},
	{
		path: URLS.carModelsUrl,
		component: Admins(ModelsView),
		layout: MainLayout,
	},
	{
		path: URLS.editCarManufacturerUrl,
		component: Admins(ManufacturerFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addCarManufacturerUrl,
		component: Admins(ManufacturerFormView),
		layout: MainLayout,
	},
	{
		path: URLS.carManufacturerUrl,
		component: Admins(ManufacturerView),
		layout: MainLayout,
	},
	{
		path: URLS.editCarListUrl,
		component: Admins(CarListFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addCarListUrl,
		component: Admins(CarListFormView),
		layout: MainLayout,
	},
	{
		path: URLS.carListUrl,
		component: OfficeManagers(CarListView),
		layout: MainLayout,
	},
	{
		path: URLS.editSliderUrl,
		component: Admins(SliderFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addSliderUrl,
		component: Admins(SliderFormView),
		layout: MainLayout,
	},
	{
		path: URLS.slidersUrl,
		component: Admins(SlidersView),
		layout: MainLayout,
	},
	{
		path: URLS.editSpecialOfferUrl,
		component: Admins(SpecialOfferFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addSpecialOfferUrl,
		component: Admins(SpecialOfferFormView),
		layout: MainLayout,
	},
	{
		path: URLS.specialOfferUrl,
		component: Admins(SpecialOfferView),
		layout: MainLayout,
	},
	{
		path: URLS.editUserUrl,
		component: Admins(UserFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addUserUrl,
		component: Admins(UserFormView),
		layout: MainLayout,
	},
	{
		path: URLS.usersUrl,
		component: Admins(UsersView),
		layout: MainLayout,
	},
	{
		path: URLS.editCmsPageUrl,
		component: Admins(PageFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addCmsPageUrl,
		component: Admins(PageFormView),
		layout: MainLayout,
	},
	{
		path: URLS.cmsPagesUrl,
		component: Admins(PagesView),
		layout: MainLayout,
	},
	{
		path: URLS.cmsPartnersUrl,
		component: Admins(PartnersView),
		layout: MainLayout,
	},
	{
		path: URLS.editCmsPartnersUrl,
		component: Admins(PartnersFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addCmsPartnersUrl,
		component: Admins(PartnersFormView),
		layout: MainLayout,
	},
	{
		path: URLS.cmsPartnersPositionsUrl,
		component: Admins(PartnersPositionsFormView),
		layout: MainLayout,
	},
	{
		path: URLS.cmsCitiesUrl,
		component: Admins(CitiesView),
		layout: MainLayout,
	},
	{
		path: URLS.editDefaultSeoUrl,
		component: Admins(DefaultSeoFormView),
		layout: MainLayout,
	},
	{
		path: URLS.companyDropCitiesUrl,
		component: Admins(DropCitiesView),
		layout: MainLayout,
	},
	{
		path: URLS.companyDropCitiesListUrl,
		component: Admins(DropCitiesView),
		layout: MainLayout,
	},
	{
		path: URLS.editRatingReasonsUrl,
		component: Admins(RatingReasonsFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addRatingReasonsUrl,
		component: Admins(RatingReasonsFormView),
		layout: MainLayout,
	},
	{
		path: URLS.ratingReasonsUrl,
		component: Admins(RatingReasonsView),
		layout: MainLayout,
	},
	{
		path: URLS.editUrgentReasonsUrl,
		component: Admins(UrgentReasonsFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addUrgentReasonsUrl,
		component: Admins(UrgentReasonsFormView),
		layout: MainLayout,
	},
	{
		path: URLS.urgentReasonsUrl,
		component: Admins(UrgentReasonsView),
		layout: MainLayout,
	},
	{
		path: URLS.urgentBookingsUrl,
		component: Admins(UrgentBookingsView),
		layout: MainLayout,
	},
	{
		path: URLS.compensationValidationUrl,
		component: Admins(CompensationValidationView),
		layout: MainLayout,
	},
	{
		path: URLS.cancellationValidationUrl,
		component: Admins(CancellationValidationView),
		layout: MainLayout,
	},
	{
		path: URLS.cancellationByCustomerUrl,
		component: Admins(CancellationByCustomerView),
		layout: MainLayout,
	},
	{
		path: URLS.waitingCustomerInfoUrl,
		component: Admins(WaitingCustomerInfoView),
		layout: MainLayout,
	},
	{
		path: URLS.faqsUrl,
		component: Admins(FaqsListView),
		layout: MainLayout,
	},
	{
		path: URLS.createFaqUrl,
		component: Admins(FaqFormView),
		layout: MainLayout,
	},
	{
		path: URLS.editFaqUrl,
		component: Admins(FaqFormView),
		layout: MainLayout,
	},
	{
		path: URLS.accountingValidationUrl,
		component: Admins(AccountingValidationView),
		layout: MainLayout,
	},
	{
		path: URLS.financialReportUrl,
		component: Admins(FinancialReportView),
		layout: MainLayout,
	},
	{
		path: URLS.eventReportUrl,
		component: Admins(EventReportView),
		layout: MainLayout,
	},
	{
		path: URLS.reportsUrl,
		component: Admins(ReportsView),
		layout: MainLayout,
	},
	{
		path: URLS.carsAvailabilityUrl,
		component: OfficeManagers(CarsAvailabilityView),
		layout: MainLayout,
	},
	{
		path: URLS.mobileAppSystemClientsUrl,
		component: Admins(SystemClientsView),
		layout: MainLayout,
	},
	{
		path: URLS.kioskAirportsUrl,
		component: Admins(AirportsView),
		layout: MainLayout,
	},
	{
		path: URLS.editKioskAirportUrl,
		component: Admins(EditAirportsView),
		layout: MainLayout,
	},
	{
		path: URLS.createKioskAirportUrl,
		component: Admins(EditAirportsView),
		layout: MainLayout,
	},
	{
		path: URLS.airportTerminalsUrl,
		component: Admins(TerminalsView),
		layout: MainLayout,
	},
	{
		path: URLS.editAirportTerminalUrl,
		component: Admins(EditTerminalsView),
		layout: MainLayout,
	},
	{
		path: URLS.createAirportTerminalUrl,
		component: Admins(EditTerminalsView),
		layout: MainLayout,
	},
	{
		path: URLS.kioskManagementUrl,
		component: Admins(KioskView),
		layout: MainLayout,
	},
	{
		path: URLS.editKioskUrl,
		component: Admins(EditKioskView),
		layout: MainLayout,
	},
	{
		path: URLS.createKioskUrl,
		component: Admins(EditKioskView),
		layout: MainLayout,
	},
	{
		path: URLS.experienceSharedUrl,
		component: Admins(ExperienceSharedFormView),
		layout: MainLayout,
	},
	{
		path: URLS.campaignsUrl,
		component: Admins(CampaignsView),
		layout: MainLayout,
	},
	{
		path: URLS.editCampaignsUrl,
		component: Admins(CampaignsFormView),
		layout: MainLayout,
	},
	{
		path: URLS.addCampaignsUrl,
		component: Admins(CampaignsFormView),
		layout: MainLayout,
	},
	{
		path: URLS.employeeRewardingUsersUrl,
		component: CompanyManagersOnly(EmployeePerformanceUsersView),
		layout: MainLayout,
	},
	{
		path: URLS.employeeRewardingUsersWithIdUrl,
		component: Admins(EmployeePerformanceUsersView),
		layout: MainLayout,
	},
	{
		path: URLS.employeeRewardingBookingsWithIdUrl,
		component: CompanyManagers(EmployeePerformanceBookingsView),
		layout: MainLayout,
	},
	{
		path: URLS.employeeRewardingBookingsUrl,
		component: OfficeManagersOnly(EmployeePerformanceBookingsView),
		layout: MainLayout,
	},
	{
		path: URLS.corporateListUrl,
		component: Admins(CorporateListView),
		layout: MainLayout,
	},
	{
		path: URLS.addCorporateUrl,
		component: Admins(AddCorporateAndCoordinatorPanelView),
		layout: MainLayout,
	},
	{
		path: URLS.editCorporateUrl,
		component: Admins(EditCorporateFormView),
		layout: MainLayout,
	},
	{
		path: URLS.corporateOverviewUrl,
		component: CorporateCoordinator(CorporateOverviewView),
		layout: MainLayout,
	},
	{
		path: URLS.editCorporateCustomerUrl,
		component: CorporateCoordinator(EditCorporateCustomerFormView),
		layout: MainLayout,
	},
	{
		path: URLS.corporateMaintenanceUrl,
		component: Admins(CorporateMaintenanceView),
		layout: MainLayout,
	},
	{
		path: URLS.corporateSettlementsUrl,
		component: Admins(CorporateSettlementsView),
		layout: MainLayout,
	},
	{
		path: URLS.unsettledBookingsUrl,
		component: Admins(UnsettledLimitsView),
		layout: MainLayout,
	},
	{
		path: URLS.registrationRequestsUrl,
		component: Admins(RegistrationRequestsListView),
		layout: MainLayout,
	},
	{
		path: URLS.corporateBookingsUrl,
		component: AdminsWithCorporateCoordinator(CorporateBookingsListView),
		layout: MainLayout,
	},
	{
		path: URLS.rentalCompaniesUrl,
		component: Admins(RentalCompaniesView),
		layout: MainLayout,
	},
	{
		path: URLS.rewardsSettlementUrl,
		component: Admins(RewardsSettlementView),
		layout: MainLayout,
	},
	{
		path: URLS.createContractUrl,
		component: OfficeManagers(InitiateContractView),
		layout: MainLayout,
	},
	{
		path: URLS.ViewContractDraftUrl,
		component: OfficeManagersWithDrivers(InitiateContractView),
		layout: MainLayout,
	},
	{
		path: URLS.contractUrl,
		component: OfficeManagers(ContractView),
		layout: MainLayout,
	},
	{
		path: URLS.extendContractUrl,
		component: OfficeManagers(ExtendContractView),
		layout: MainLayout,
	},
	{
		path: URLS.closeContractUrl,
		component: OfficeManagersWithDrivers(CloseContractView),
		layout: MainLayout,
	},
	{
		path: URLS.suspendContractUrl,
		component: OfficeManagers(SuspendContractView),
		layout: MainLayout,
	},
	{
		path: URLS.driverLicenseList,
		component: Admins(DriverLicenseTableView),
		layout: MainLayout,
	},
	{
		path: URLS.addPlateUrl,
		component: CompanyManagersWithoutAdmins(AddPlateView),
		layout: MainLayout,
	},
	{
		path: URLS.editPlateUrl,
		component: OfficeManagers(AddPlateView),
		layout: MainLayout,
	},
	{
		path: URLS.leasingCancellation,
		component: CompanyManagers(LeasingCancellationView),
		layout: MainLayout,
	},
	{
		path: URLS.leasingCarsUrl,
		component: CompanyManagers(LeasingCarsView),
		layout: MainLayout,
	},
	{
		path: URLS.addLeasingCarUrl,
		component: CompanyManagers(AddLeasingCarView),
		layout: MainLayout,
	},
	{
		path: URLS.editLeasingCarUrl,
		component: CompanyManagers(AddLeasingCarView),
		layout: MainLayout,
	},
	{
		path: URLS.trainStations,
		component: Admins(TrainStationsView),
		layout: MainLayout,
	},
	{
		path: URLS.addTrainStation,
		component: Admins(EditTrainStationView),
		layout: MainLayout,
	},
	{
		path: URLS.editTrainStation,
		component: Admins(EditTrainStationView),
		layout: MainLayout,
	},
	{
		path: URLS.notFoundUrl,
		component: NotFoundView,
		layout: MainLayout,
	},
];
