export const COLUMNS_ACCESSORS = {
	TRANSACTION: 'transaction',
	BALANCE: 'total_wallet_balance',
	AMOUNT: 'amount',
};

export const columns = [
	{
		header: 'customers.preview.wallet.table.transaction',
		accessor: COLUMNS_ACCESSORS.TRANSACTION,
	},
	{
		header: 'customers.preview.wallet.table.date',
		accessor: 'created_at',
	},
	{
		header: 'customers.preview.wallet.table.amount',
		accessor: COLUMNS_ACCESSORS.AMOUNT,
	},
	{
		header: 'customers.preview.wallet.table.expiry_date',
		accessor: 'expires_at',
	},
	{
		header: 'customers.preview.wallet.table.balance',
		accessor: COLUMNS_ACCESSORS.BALANCE,
	},
	{
		header: 'customers.preview.wallet.table.admin_name',
		accessor: 'author_name',
	},
];
