import { useTranslations } from '../../../../../../components/utilities';
import { useForm } from 'react-final-form';
import { fieldNames } from '../../helpers';


const useCarDetailsFields = ({queryFetchStates, fieldsData, isGlobalEdit}) => {
	const { i18n } = useTranslations();

	const lng = i18n.language;

	const { change } = useForm();

	const {
		loadingModels,
		loadingYears,
		yearsError,
		modelsError,
	} = queryFetchStates;

	const {
		vehicleList,
		yearsArray: years,
		year,
		modelsArray: models,
		carModel,
		manufacturer,
	} = fieldsData;

	const {
		manufacturerFieldName,
		modelFieldName,
		yearFieldName,
		carUuidFieldName,
	} = fieldNames({
		lng,
	});

	const carBrand = manufacturer ? manufacturer[lng] : '';

	const isGlobalModelsLoaded = !loadingModels && !modelsError;

	const isSingleCarYearsLoaded = !loadingYears && !yearsError;

	const isGlobalYearsLoaded = isSingleCarYearsLoaded && isGlobalModelsLoaded;

	const isYearsLoaded = isGlobalEdit
		? isGlobalYearsLoaded
		: isSingleCarYearsLoaded;

	const isSingleCarModelsLoaded = isGlobalModelsLoaded && isYearsLoaded;

	const isModelsLoaded = isGlobalEdit
		? isGlobalModelsLoaded
		: isSingleCarModelsLoaded;

	const handleManufacturerChange = (event) => {
		change(manufacturerFieldName, event.target.value);
	};

	return {
		lng,
		change,
		vehicleList,
		years,
		year,
		models,
		carModel,
		manufacturerFieldName,
		modelFieldName,
		yearFieldName,
		carUuidFieldName,
		carBrand,
		isYearsLoaded,
		isModelsLoaded,
		handleManufacturerChange
	}
}

export default useCarDetailsFields
