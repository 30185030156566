export const FIELDS_ACCESSORS = {
	CONTRACT: 'contract',
	TRANSACTION_COPY: 'transaction_copy',
	TOPIC: 'topic',
	STATUS: 'status',
	RRN: 'rrn',
};

const FIELDS_COMPENSATION_HISTORY = [
	{
		accessor: FIELDS_ACCESSORS.TOPIC,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.STATUS,
		label: 'bookings.compensation_history.status',
	},
	{
		accessor: 'compensation_amount.nett',
		label: 'bookings.compensation_history.compensation_amount',
	},
	{
		accessor: 'method',
		label: 'bookings.compensation_history.method',
	},
	{
		accessor: 'bank_name',
		label: 'bookings.compensation_history.bank_name',
	},
	{
		accessor: 'iban',
		label: 'bookings.compensation_history.iban',
	},
	{
		accessor: FIELDS_ACCESSORS.RRN,
		label: 'bookings.compensation_history.rrn',
	},
	{
		accessor: FIELDS_ACCESSORS.CONTRACT,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.TRANSACTION_COPY,
		label: '',
	},
];

export const getCompensationHistoryFields = ({
	isInAccountingValidationPage,
}) => {
	const filters = [];
	isInAccountingValidationPage && filters.push(FIELDS_ACCESSORS.RRN);

	const filteredFields = FIELDS_COMPENSATION_HISTORY.filter(
		({ accessor }) => !filters.some((filter) => filter === accessor)
	);

	return filteredFields;
};
