import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { EXTERNAL_PARTNER_CARS_QUERY } from 'helpers';

export const useExternalPartnerCars = (officeUuid, enabled) => {
	const path = `v2/supplier/business/office/${officeUuid}/external-partners/cars-mapping/list`;

	const { data, isFetching, isError } = useQuery(
		[EXTERNAL_PARTNER_CARS_QUERY, officeUuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : {};

	return { data: fetchedData, isFetching, isError };
};
