import React from 'react';

// Import components
import { DataForm } from './components';

// Import utils
import { CloseContractProvider } from './context';

export const CloseContract = () => {
	return (
		<CloseContractProvider>
			<DataForm />
		</CloseContractProvider>
	);
};
