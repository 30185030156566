import React from 'react';
import { string } from 'prop-types';

// Import styles
import { useStyles } from './styles';

// Import components
import { Calculations, Header, TotalPaymentBar } from './components';
import { Template } from '../Template';

export const PaymentDetails = ({ label }) => {
	const classes = useStyles();

	return (
		<Template label={label} className={classes.container}>
			<Header />
			<Calculations />
			<TotalPaymentBar />
		</Template>
	);
};

PaymentDetails.propTypes = {
	label: string,
};
