import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const PinIcon = (props) => (
	<SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.9168 12.2506C16.9168 10.6391 15.6111 9.33337 14.0008 9.33337C12.3893 9.33337 11.0835 10.6391 11.0835 12.2506C11.0835 13.8609 12.3893 15.1667 14.0008 15.1667C15.6111 15.1667 16.9168 13.8609 16.9168 12.2506Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.9994 24.5C12.6012 24.5 5.25 18.5481 5.25 12.3238C5.25 7.45108 9.16662 3.5 13.9994 3.5C18.8322 3.5 22.75 7.45108 22.75 12.3238C22.75 18.5481 15.3977 24.5 13.9994 24.5Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
