import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CarDeliveryIcon = (props) => (
	<SvgIcon viewBox="0 0 17 17" {...props}>
		<path d="M3.99526139,2 L8.19411787,5.59406353 C8.85668684,5.22467798 9.62237998,5.01382487 10.438075,5.01382487 C12.9575566,5.01382487 15,7.0254487 15,9.50691244 C15,11.9883762 12.9575566,14 10.438075,14 C7.9185934,14 5.87615001,11.9883762 5.87615001,9.50691244 C5.87615001,8.92247503 5.98944486,8.36409976 6.19558344,7.85192915 L5.50525304,7.26174186 L5.2786998,6.1501801 L4.20695679,6.1501801 L3.96695727,5.02710604 L3.03006682,5.1425627 L2,4.26065082 L2,2.29059873 L3.99526139,2 Z M11.131871,8.78526048 C10.432015,8.78526048 9.86466957,9.34404488 9.86466957,10.0333404 C9.86466957,10.7226358 10.432015,11.2814202 11.131871,11.2814202 C11.831727,11.2814202 12.3990723,10.7226358 12.3990723,10.0333404 C12.3990723,9.34404488 11.831727,8.78526048 11.131871,8.78526048 Z" />
	</SvgIcon>
);

export default CarDeliveryIcon;
