import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Import utils and helpers
import { useAddCorporateAndCoordinatorProvider } from '../../context';
import { useMessage, useTranslations } from 'components/utilities';
import { convertSubmitCoordinators } from 'helpers';

// Import store
import { createOrUpdateCoordinator } from 'store/actions';
import { URLS } from 'components/routes';

export const useAddCoordinatorStep = () => {
	const { corporateUuid } = useAddCorporateAndCoordinatorProvider();
	const [isLoading, setLoading] = useState(false);
	const { t } = useTranslations();
	const { message } = useMessage();
	const history = useHistory();

	const handleOnSubmit = async (values) => {
		let apiErrors = { coordinators: [] };
		setLoading(true);

		const convertedCoordinators = convertSubmitCoordinators({
			coordinators: values.coordinators,
			corporateUuid,
		});

		const coordinatorsPromises = convertedCoordinators.map(
			(coordinator, index) =>
				createOrUpdateCoordinator({
					successCallback: () => {
						message.success(
							t(
								`b2b.corporate.form.messages.${
									coordinator?.uuid
										? 'coordinator_was_updated_successfully'
										: 'coordinator_was_created_successfully'
								}`,
								{ index: index + 1 }
							)
						);
					},
					errorCallback: (error) => {
						if (error.response) {
							apiErrors = {
								coordinators: [
									...apiErrors.coordinators,
									error.response.data.errors,
								],
							};
						}
						if (index === convertedCoordinators.length - 1) {
							setLoading(false);
						}
						message.error(t('common.messages.error_message'));
					},
					coordinator,
					corporateUuid,
				})
		);

		await Promise.all(coordinatorsPromises);

		setLoading(false);

		if (!apiErrors?.coordinators.length) {
			history.push(URLS.corporateListUrl);
		}

		return apiErrors;
	};

	return {
		handleOnSubmit,
		isLoading,
	};
};
