import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
// import { BOOKING_HISTORY_QUERY } from 'helpers';

const BOOKING_HISTORY_QUERY = 'booking_history';

const useBookingHistory = ({ bookingId }) => {
	const path = `/v2/booking/${bookingId}/history`;

	const { data, isFetching, isError, error } = useQuery(
		[BOOKING_HISTORY_QUERY, bookingId],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError, error };
};

export default useBookingHistory;