import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { VEHICLE_PLATES_QUERY } from 'helpers';

export const useVehiclePlates = (bookingUuid) => {
	const path = '/v2/vehicle/plates';

	const body = {
		booking_uuid: bookingUuid,
	};

	const { data, isFetching, isError } = useQuery(
		[VEHICLE_PLATES_QUERY, JSON.stringify(body)],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError };
};
