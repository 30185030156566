import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const SmallScratchIcon = (props) => (
	<SvgIcon
		width="13"
		height="13"
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.78299 3.217C7.96985 1.40386 5.03016 1.40385 3.21701 3.21701L3.217 3.21701C1.40386 5.03015 1.40385 7.96984 3.21701 9.78299L2.56041 10.4396L3.21701 9.783C5.03015 11.5961 7.96984 11.5961 9.78299 9.78299L10.4396 10.4396L9.78299 9.78299C11.5961 7.96985 11.5961 5.03016 9.78299 3.217ZM11.0941 1.90168C8.5555 -0.634604 4.44151 -0.633893 1.90381 1.90381L11.0941 1.90168ZM11.0941 1.90168C11.0948 1.90239 11.0955 1.9031 11.0962 1.90381C13.6346 4.44222 13.6346 8.55779 11.0962 11.0962C8.55778 13.6346 4.44221 13.6346 1.90381 11.0962C-0.634601 8.55778 -0.634604 4.44221 1.90381 1.90381"
			fill="currentColor"
		/>
	</SvgIcon>
);
