import React from 'react';
import { node, bool, number } from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';

//Import styles
import { useStyles } from './ButtonProgress.styles';

export const ButtonProgress = ({
	circularProgressSize = 24,
	children,
	isLoading,
	fullWidth,
	...props
}) => {
	const classes = useStyles({ fullWidth });

	return (
		<div className={classes.wrapper}>
			<Button fullWidth={fullWidth} {...props}>
				{children}
			</Button>
			{isLoading && (
				<CircularProgress
					size={circularProgressSize}
					classes={{ indeterminate: classes.indeterminate }}
					className={classes.progress}
				/>
			)}
		</div>
	);
};

ButtonProgress.propTypes = {
	children: node,
	isLoading: bool,
	circularProgressSize: number,
	fullWidth: bool,
};
