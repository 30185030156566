import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useBaseDetails } from './useBaseDetails';

// Import components
import { Manufacturer, Model, Year } from 'components/elements';

export const BaseDetails = () => {
	const { t } = useTranslations();

	const {
		manufacturerFieldName,
		modelFieldName,
		yearFieldName,
		manufacturers,
		models,
		years,
		isManufacturersLoading,
		isModelsLoading,
		isYearsLoading,
	} = useBaseDetails();

	return (
		<Card>
			<CardHeader title={t('cars.car_list.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Manufacturer
						label="cars.car_list.form.details.manufacturer"
						name={manufacturerFieldName}
						manufacturers={manufacturers}
						isLoading={isManufacturersLoading}
						showAddManufacturerOption
						required
					/>
					<Model
						label="cars.car_list.form.details.model"
						name={modelFieldName}
						models={models}
						isLoading={isModelsLoading}
						showAddModelOption
						required
					/>
					<Year
						label="cars.car_list.form.details.year"
						name={yearFieldName}
						isLoading={isYearsLoading}
						years={years}
						required
					/>
				</Grid>
			</CardContent>
		</Card>
	);
};
