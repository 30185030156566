export const POPULAR_BANKS_LIST = [
	'الراجحي',
	'الأهلي',
	'السعودي البريطاني',
	'السعودي الفرنسي',
	'الأول',
	'السعودي للاستثمار',
	'العربي الوطني',
	'البلاد',
	'الجزيرة',
	'الرياض',
	'سامبا',
	'الإنماء',
	'الخليج الدولي - السعودي',
];

export const getBankNameOptions = ({ options, modified, value }) =>
	options.filter((option) =>
		modified ? option.toLowerCase().includes(value.toLowerCase()) : option
	);
