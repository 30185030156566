import React from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { Form } from 'react-final-form';
import { bool, string } from 'prop-types';

// Import utils
import { useTranslations } from 'components/utilities';
import { useOfflineSettlementDialog } from './useOfflineSettlementDialog';

// Import helpers
import { validationSchema } from './validation';
import { INITIAL_VALUES } from './helpers';
import { yupValidator } from 'helpers';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';

export const OfflineSettlementDialog = ({
	buttonText = 'bookings.preview_booking.subscription_invoice.settle_offline',
	readOnly = false,
}) => {
	const { t } = useTranslations();

	const { handleOnSubmit, dialogCustomData, toggle, isLoading, isOpen } =
		useOfflineSettlementDialog();

	const getValidation = (values) =>
		!readOnly
			? yupValidator({
					values,
					validationSchema: validationSchema(),
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  })
			: null;

	return (
		<>
			<Button color="primary" disableRipple onClick={toggle}>
				{t(buttonText)}
			</Button>
			<Dialog fullWidth open={isOpen} onClose={toggle}>
				<ClosableDialogTitle
					onClose={toggle}
					title={t(
						readOnly
							? 'bookings.preview_booking.subscription_invoice.offline_settlement_details'
							: 'bookings.preview_booking.subscription_invoice.offline_settlement'
					)}
				/>
				<DialogContent>
					<Form
						onSubmit={handleOnSubmit}
						initialValues={INITIAL_VALUES({ dialogCustomData, readOnly })}
						validate={getValidation}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit} noValidate>
								<FormContent
									toggle={toggle}
									readOnly={readOnly}
									isLoading={isLoading}
								/>
							</form>
						)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

OfflineSettlementDialog.propTypes = {
	buttonText: string,
	readOnly: bool,
};
