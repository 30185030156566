/* eslint-disable */

import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Row = ({ children }) => (
	<Typography color="textSecondary" variant="body1">
		{children}
	</Typography>
);

const TermsAndCondition = () => {
	const classes = useStyles();
	return (
		<div dir="rtl" className={classes.modalContent}>
			<Row>اتفاقية سياسة الخصوصية وشروط الاستخدام:</Row>
			<br />
			<Row>
				تحدد هذه ("الاتفاقية") الشروط والأحكام العامة لإستخدامك لأي تطبيق أو
				نظام او موقع او صفة وأي من المنتجات والخدمات ذات الصلة (يشار إليها
				مجتمعة باسم "خدمات تلقاني")
			</Row>
			<br />
			<Row>
				باستعمالك لأي من "خدمات تلقاني" المملوكة لـشركة تلقاني المتقدمة
				للاتصالات وتقنية المعلومات، يشار إليها باسم ("تلقاني") فإنك تقر بأنك قد
				قرأت وفهمت ووافقت على الالتزام بالشروط أدناه لهذه الاتفاقية وتقر بأن هذه
				الاتفاقية هي عقد بينك وبين ”تلقاني” على الرغم من أنها إلكترونية ولم توقع
				عليها فعليًا.
			</Row>
			<br />
			<Row>
				"الإجراءات القانونية" حسب ما تنص عليه أنظمة الجرائم المعلوماتية، أو
				أنظمة هيئة الاتصالات وتقنية المعلومات، أو أي جهة ذات صلة حسب اختصاصها
			</Row>
			<br />
			<Row>أولاً: أهلية المستخدم</Row>
			<br />
			<Row>
				يتم توفير تطبيق "Telgani Partners” بواسطة ”تلقاني” وهو متاح فقط للشركات
				والأشخاص المخولين الذين تم التعاقد معهم مسبقًا كمزودي خدمة، ويكونون
				مؤهلين للدخول بطريقة شرعية في اتفاقية ملزمة قانونًا، وسيتم اتخاذ
				"الإجراءات القانونية" في حال الدخول غير المشروع أو مشاركة ايا من بيانات
				الدخول الخاصة بك وحدك.
			</Row>
			<br />
			<Row>ثانياً: حقوق النشر</Row>
			<br />
			<Row>
				إن المحتوى بكافة اشكاله ومحتوياته والتصميم والتجميع والبيانات وجميع
				المسائل الأخرى المتعلقة (بلا حصر) محمية ومحفوظة لصالح شركة "تلقاني" وتقر
				بمسؤوليتك الكاملة عن دخولك "خدمات تلقاني" الحفاظ على سرية المحتوى
				والبيانات كاملة ولا يحق بمشاركتها أو استعراضها لأي طرف آخر وسيتم اتخاذ
				"الإجراءات القانونية" في حال اطلاعها او مشاركتها لأي طرف آخر.
			</Row>
			<br />
			<Row>ثالثاً: التعديلات</Row>
			<br />
			<Row>
				قد تقوم "تلقاني" بمراجعة وتحديث شروط الاستخدام هذه في أي وقت، وإن
				استمرارك في استخدام أي من "خدمات تلقاني" بعد أي تغييرات على الشروط هذه
				يعتبر بمثابة قبول وموافقة لهذه التغييرات.
			</Row>
			<br />
			<Row>رابعًا: عام</Row>
			<br />
			<Row>
				لا يجوز لك التنازل عن شروط الاستخدام هذه أو أي من التزاماتك بموجب شروط
				الاستخدام هذه. إذا وجدت أي محكمة ذات اختصاص قضائي مختص أن أي حكم من شروط
				الاستخدام هذه غير صالح، فإن بطلان هذا الحكم لا يؤثر على صحة الأحكام
				المتبقية في شروط الاستخدام هذه، التي تظل سارية المفعول بالكامل.
			</Row>
			<br />
			<Row>خامسًا: الإنهاء</Row>
			<br />
			<Row>
				توافق على أنه يجوز لـ ”تلقاني” إنهاء أو تعليق استخدامك لأيا من ”خدمات
				تلقاني”، في أي وقت ولأي سبب أو بدون سبب وفقاً لتقديرها الخاص، وعند هذا
				التعليق أو الإنهاء يجب عليك فوراً إتلاف أي نسخ من أي جزء من المحتوى
				لديك.
			</Row>
			<br />
			<Row>سادسًا: النظام المعمول به</Row>
			<br />
			<Row>
				تحكم أنظمة المملكة العربية السعودية شروط الاستخدام هذه، ويكون للمحاكم
				السعودية في مدينة الرياض الاختصاص لأي منازعات قد تنشأ في هذا الخصوص.
			</Row>
			<br />
			<Row>آخر تحديث 12/16/2020</Row>
			<br />
		</div>
	);
};

export default TermsAndCondition;
