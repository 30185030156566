import React from 'react';
import { object } from 'prop-types';
import {
	Stepper as MuiStepper,
	Typography,
	StepLabel,
	Step,
	Box,
} from '@material-ui/core';

// Import styles
import { CustomConnector, useStyles } from '../../Steps.styles';

// Import utils
import { useTranslations } from 'components/utilities';
import { useStepper } from './useStepper';

export const Stepper = ({ deliveryData }) => {
	const {
		isDeliveryCancelled,
		cancelReasonIndex,
		cancelReason,
		activeSteps,
		splitDate,
		steps,
	} = useStepper({ deliveryData });

	const { t } = useTranslations();

	const classes = useStyles({ stepsAmount: steps?.length, cancelReasonIndex });

	return (
		<Box>
			<MuiStepper
				activeStep={activeSteps}
				connector={<CustomConnector />}
				className={classes.stepper}
				alternativeLabel
			>
				{steps?.map(({ state, updated_at }) => {
					const labelProps = {};

					if (isDeliveryCancelled(state)) {
						labelProps.error = true;
					}

					labelProps.optional = (
						<Typography className={classes.optionalLabel}>
							{t(`bookings.preview_booking.car.step_captions.${state}`)}
						</Typography>
					);

					return (
						<Step key={state}>
							<StepLabel {...labelProps}>
								{splitDate(updated_at).map((datePart) => (
									<Typography className={classes.splittedDate} key={datePart}>
										{datePart}
									</Typography>
								))}
							</StepLabel>
						</Step>
					);
				})}
			</MuiStepper>
			{cancelReason && (
				<Box className={classes.cancelReasonBox}>
					<Typography className={classes.cancelReason}>
						{cancelReason}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

Stepper.propTypes = {
	deliveryData: object,
};
