import services from 'services/services';
import {
	fetchOrganizationsUrl,
	updateActiveOrganizationUrl,
	deleteOrganizationUrl,
} from 'store/paths';

export const fetchOrganizations = ({
	options,
	queryParams,
	cancelToken,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		// Get response
		const { data } = await services.post(
			fetchOrganizationsUrl(queryParams),
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveOrganization = ({ isActive, id }) => async () => {
	await services.put(updateActiveOrganizationUrl(id, isActive));
};

export const deleteOrganization = ({ id }) => async () => {
	await services.delete(deleteOrganizationUrl(id));
};
