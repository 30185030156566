import i18n from 'i18next';
import * as yup from 'yup';

// Import helpers
import {
	isAuthorizationTypeCodeVisible,
	isHourlyContractType,
	isVisitorOrGcc,
} from 'helpers';

export const validationSchema = (values) => {
	const isAuthTypeCodeRequired = isAuthorizationTypeCodeVisible(values);
	const requiredFieldText = i18n.t('common.fields.required_field');
	const isVisitorOrGccState = isVisitorOrGcc(values);
	const isHourlyType = isHourlyContractType(values);

	const plateValidation = /^[\u0600-\u06FF]{3}[0-9\u0660-\u0669]{4}$/;

	return yup.object({
		payment_details: yup.object({
			payment_method_code: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			international_authorization_cost:
				values.authorization_details.authorization_type_code === 2
					? yup
							.number()
							.min(0, i18n.t('common.fields.minimum', { value: 0 }))
							.required(requiredFieldText)
					: yup.number().notRequired(),
			rent_hour_cost: isHourlyType
				? yup
						.number()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.required(requiredFieldText)
				: yup
						.number()
						.nullable()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.notRequired(),
			rent_day_cost: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			extra_km_cost: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			full_fuel_cost: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			vehicle_transfer_cost: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.notRequired(),
			driver_fare_per_hour: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.notRequired(),
			driver_fare_per_day: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.notRequired(),
			paid: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			car_transfer_cost: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.notRequired(),
			discount: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.max(100, i18n.t('common.fields.maximum', { value: 100 }))
				.required(requiredFieldText),
			additional_coverage_cost: values.contract_details?.extended_coverage_id
				? yup.number().required(requiredFieldText)
				: yup.number().notRequired(),
			extra_driver_cost: values.renter.is_extra_driver
				? yup
						.number()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.required(requiredFieldText)
				: yup.number().notRequired(),
		}),

		rent_status: yup.object({
			endurance_amount: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			odometer_reading: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			fuel_type_code: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			fire_extinguisher: yup.string().required(requiredFieldText),
			spare_tire_tools: yup.string().required(requiredFieldText),
			safety_triangle: yup.string().required(requiredFieldText),
			available_fuel: yup.string().required(requiredFieldText),
			first_aid_kit: yup.string().required(requiredFieldText),
			oil_change_date: yup.date().required(requiredFieldText),
			radio_stereo: yup.string().required(requiredFieldText),
			speedometer: yup.string().required(requiredFieldText),
			spare_tire: yup.string().required(requiredFieldText),
			car_seats: yup.string().required(requiredFieldText),
			oil_type: yup.string().required(requiredFieldText),
			screen: yup.string().required(requiredFieldText),
			tires: yup.string().required(requiredFieldText),
			keys: yup.string().required(requiredFieldText),
			ac: yup.string().required(requiredFieldText),
			oil_change_km_distance: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
		}),

		contract_details: yup.object({
			rent_policy_id: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			contract_start_date: yup.date().required(requiredFieldText),
			contract_end_date: yup.date().required(requiredFieldText),
			extended_coverage_id: yup.string().nullable().notRequired(),
		}),

		renter: yup.object({
			id_type_code: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			id_number: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			birth_date: yup.date().required(requiredFieldText),
			full_name: yup.string().nullable().notRequired(),
			person_address: yup.string().required(requiredFieldText),
			mobile: yup
				.string()
				.required(requiredFieldText)
				.test(
					'mobile',
					i18n.t('contract.create_contract_form.prefix_starts_with'),
					(value) => value.startsWith('9665')
				)
				.test(
					'len',
					i18n.t('common.validation.exactly_characters', { amount: 12 }),
					(val) => val.length === 12
				),
			nationality_code: isVisitorOrGccState
				? yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.notRequired(),
			drive_license_number: isVisitorOrGccState
				? yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.notRequired(),
			id_copy_number: isVisitorOrGccState
				? yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.notRequired(),
			license_expiry_date: isVisitorOrGccState
				? yup.date().required(requiredFieldText)
				: yup.date().notRequired(),
			issue_place_id: isVisitorOrGccState
				? yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.notRequired(),
		}),

		plate: yup
			.string()
			.required(requiredFieldText)
			.matches(plateValidation, 'common.fields.plate_number'),
		vehicle_details: yup.object({
			plate_type: yup.string().required(requiredFieldText),
		}),
		authorization_details: yup.object({
			contract_type_code: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.integer()
				.required(requiredFieldText),
			working_branch_id: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.integer()
				.required(requiredFieldText),
			receive_branch_id: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.integer()
				.required(requiredFieldText),
			return_branch_id: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.integer()
				.required(requiredFieldText),
			external_authorization_countries:
				values.authorization_details.authorization_type_code === 2
					? yup.array().of(yup.number().integer()).required(requiredFieldText)
					: yup.array().of(yup.number().integer()).notRequired(),
			allowed_late_hours: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.max(24, i18n.t('common.fields.maximum', { value: 24 }))
				.required(requiredFieldText),
			authorization_type_code: isAuthTypeCodeRequired
				? yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.nullable()
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.nullable()
						.notRequired(),
			allowed_km_per_day: !isHourlyType
				? yup
						.number()
						.integer()
						.nullable()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.nullable()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.notRequired(),
			allowed_km_per_hour: isHourlyType
				? yup
						.number()
						.integer()
						.nullable()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.nullable()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.notRequired(),
			operator_id: yup.number().integer().required(requiredFieldText),
		}),

		extra_driver: yup.object({
			id_type_code: values.renter.is_extra_driver
				? yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.nullable()
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.nullable()
						.notRequired(),
			id_number: values.renter.is_extra_driver
				? yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.nullable()
						.required(requiredFieldText)
				: yup
						.number()
						.integer()
						.min(0, i18n.t('common.fields.minimum', { value: 0 }))
						.nullable()
						.notRequired(),
			person_address: values.renter.is_extra_driver
				? yup.string().required(requiredFieldText)
				: yup.string().notRequired(),
			birth_date: values.renter.is_extra_driver
				? yup.date().nullable().required(requiredFieldText)
				: yup.date().nullable().notRequired(),
		}),

		additional_services: yup.object({
			disabilities_aids_per_day: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.nullable()
				.notRequired(),
			navigation_system_per_day: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.nullable()
				.notRequired(),
			car_seat_per_day: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.nullable()
				.notRequired(),
			internet_per_day: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.nullable()
				.notRequired(),
			car_delivery: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.nullable()
				.notRequired(),
		}),
	});
};
