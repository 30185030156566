import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	CompanyFilter,
	DeliveryFilter,
	PaidOnlineFilter,
	PendingFilter,
	CanceledFilter,
	BaseYesNoAllFilter,
} from 'components/elements';

const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<DeliveryFilter />

			<PendingFilter />

			<CanceledFilter />

			<PaidOnlineFilter />

			<CompanyFilter name="company_uuid" label="bookings.filters.company" />

			<BaseYesNoAllFilter name="is_kiosk" label="bookings.filters.kiosk" />
		</Grid>
	);
};

export default FilterFields;
