import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	image: {
		width: 64,
		objectFit: 'cover',
		cursor: 'pointer',
		transition: 'all 0.3s ease',
		'&:hover': {
			transform: 'scale(1.02)',
			boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.3)',
		},
	},
}));

export default useStyles;
