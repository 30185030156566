import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	mainText: {
		fontSize: '18px',
		fontWeight: 500,
	},
	list: {
		paddingLeft: '18px',
	},
	item: {
		fontWeight: 500,
	},
}));
