import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

// Import components
import {
	DateRangeFilter,
	OfficeFilter,
	CityFilter,
	BookingStatusFilter,
} from 'components/elements';
import { useTableContextProvider } from 'components/context';
import { formatValues, initialValues } from './helpers';

export const FilterFields = () => {
	const { companyUuid } = useParams();

	const { setFilters } = useTableContextProvider();

	useEffect(() => {
		setFilters(formatValues(initialValues));
		//eslint-disable-next-line
	}, []);

	return (
		<Grid container spacing={3}>
			<DateRangeFilter
				nameFrom="date"
				nameUntil="date_to"
				label="employee_rewarding.table.filters.date_range"
				combinedInputs
			/>

			<BookingStatusFilter
				name="booking_status"
				label="employee_rewarding.table.filters.booking_status"
			/>

			<OfficeFilter name="office_uuid" label="bookings.filters.office" />

			<CityFilter
				name="city_uuid"
				label="bookings.filters.city"
				externalCompanyUuid={companyUuid}
			/>
		</Grid>
	);
};
