import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	collapseBtn: {
		backgroundColor: alpha(theme.palette.primary.light, 0.1),
		color: theme.palette.text.primary,
		borderRadius: 8,
		textTransform: 'none',
	},
}));
