import { StepConnector, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	extraWrapper: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '16px',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	heading: {
		display: 'flex',
		alignItems: 'center',
		gap: '16px',
	},
	optionalLabel: {
		position: 'absolute',
		top: '-40px',
		left: '50%',
		transform: 'translate(-50%)',
		fontSize: '12px',
		textAlign: 'center',
		fontWeight: 'bold',
	},
	splittedDate: {
		fontSize: '12px',
		fontWeight: 'bold',
	},
	cancelReasonBox: ({ stepsAmount }) => ({
		display: 'grid',
		placeItems: 'center',
		gridTemplateColumns: `repeat(${stepsAmount}, 1fr)`,
		padding: '0 24px',
	}),
	cancelReason: ({ cancelReasonIndex }) => ({
		color: theme.palette.error.main,
		gridArea: `1 / ${cancelReasonIndex + 1} / 2 / ${cancelReasonIndex + 2}`,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '12px',
	}),
	innerWrapper: {
		display: 'flex',
		flexDirection: 'column',
		gap: '32px',
	},
	greenChip: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
	},
	redChip: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.main,
	},
	locationContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
	},
}));

export const CustomConnector = withStyles((theme) => ({
	alternativeLabel: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	active: {
		'& $line': {
			borderColor: theme.palette.primary.main,
		},
	},
	completed: {
		'& $line': {
			borderColor: theme.palette.primary.main,
		},
	},
	line: {
		borderTopWidth: '2px',
		borderColor: theme.palette.grey[400],
		borderStyle: 'solid',
	},
}))(StepConnector);
