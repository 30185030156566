// ********************  table  ********************

export const fetchManufacturerUrl = (queryParams) =>
	`/v2/vehicle/maker/list${queryParams}`;

export const deleteManufacturerUrl = (id) => `/v2/vehicle/maker/${id}/remove`;

// ********************  form  ********************

export const fetchManufacturerFormResourcesUrl = (itemId) =>
	`/v2/vehicle/maker/${itemId}/show`;

export const submitManufacturerFormUrl = (isEdit, itemId) =>
	`/v2/vehicle/maker/${isEdit ? `${itemId}/update` : 'create'}`;
