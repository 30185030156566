import services from 'services/services';
import {
	fetchOfficesUrl,
	updateActiveOfficeUrl,
	downloadCarPricesUrl,
} from 'store/paths';

export const fetchOffices =
	({ options, queryParams, itemId, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const { data } = await services.post(
				fetchOfficesUrl({ itemId, queryParams }),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const downloadCarPrices = async ({ companyUuid, fileFormat }) => {
	try {
		const { data } = await services.getBlob(
			downloadCarPricesUrl({ companyUuid, fileFormat })
		);
		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveOffice =
	({ isActive, id }) =>
	async () => {
		await services.put(updateActiveOfficeUrl(id, isActive));
	};
