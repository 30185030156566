import services from 'services/services';
import { fetchValidationBookingsUrl } from 'store/paths';

export const fetchValidationBookings =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const defaultQueryParams = '?columns[5][search][value]=review';

			// Get response
			const { data } = await services.post(
				fetchValidationBookingsUrl(queryParams || defaultQueryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
