// Import helpers
import { ENVS } from 'helpers';

export const convertSubmitCoordinators = ({ coordinators, corporateUuid }) =>
	coordinators.reduce((acc, coordinator) => {
		const { prefix, phone } = coordinator;
		const newCoordinator = {
			...coordinator,
			phone: `${prefix}${phone}`,
			corporate_uuid: corporateUuid,
		};

		return [...acc, newCoordinator];
	}, []);

export const getWebDomain = () => {
	switch (process.env.REACT_APP_RELEASE_STAGE) {
		case ENVS.DEVELOPMENT:
			return 'https://dev.telgani.com/en';
		case ENVS.TESTING:
			return 'https://uat.telgani.com/en';
		case ENVS.PRODUCTION:
			return 'https://telgani.com/en';
		default:
			return 'http://localhost:3000/en';
	}
};

export const getCoordinatorVerificationLink = ({
	token,
	customerUuid,
	corporateUuid,
}) => {
	const domain = getWebDomain();
	return `${domain}/coordinator-verification?token=${token}&customerUuid=${customerUuid}&corporateUuid=${corporateUuid}`;
};
