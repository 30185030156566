import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { DELIVERY_BOOKING_IMAGES_QUERY } from 'helpers';

export const useCarImagesForBookingDelivery = (bookingUuid) => {
	const url = `/v2/booking/${bookingUuid}/images`;

	const { data, isFetching, isError } = useQuery(
		DELIVERY_BOOKING_IMAGES_QUERY,
		async () => await services.get(url),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching, isError };
};
