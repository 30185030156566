import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { URLS } from 'components/routes';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = () => [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: URLS.addDriverUrl,
		icon: PersonAddIcon,
		text: 'users.table.toolbar.add_driver',
	},
];
