import _ from 'lodash';
import { useSelector } from 'react-redux';
import { tokenSelector } from 'store/selectors';

// Import utils
import { useToggle } from 'components/utilities';

export const useContent = (data) => {
	const { scanned, license_media_id, customer_uuid: customerUuid } = data || {};

	const { on: isEditView, toggle } = useToggle(false);

	const { driver, license } = scanned || {};

	const token = useSelector(tokenSelector);

	const apiUrl = process.env.REACT_APP_API_URL.slice(0, -3);

	const imageUrl = `${apiUrl}content/media/${license_media_id}/show?token=${token}`;

	const convertedDriverFields = _.omit(driver, 'has_birth_date');
	const convertedLicenseFields = _.omit(license, 'has_expire_date');

	const tables = [
		{ label: 'driver', fields: convertedDriverFields },
		{ label: 'license', fields: convertedLicenseFields },
	];

	const initialValues = {
		driver: convertedDriverFields,
		license: convertedLicenseFields,
	};

	return {
		initialValues,
		customerUuid,
		isEditView,
		imageUrl,
		tables,
		toggle,
	};
};
