import services from 'services/services';
import {
	fetchRewardsSettlementGroupedListUrl,
	fetchRewardsSettlementListUrl,
} from 'store/paths';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';
import { getFormattedParamsDates } from './helpers';

export const fetchRewardsSettlementGroupedList =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchRewardsSettlementGroupedListUrl(
					getFormattedParamsDates({
						params,
						pickFromCol: '17',
						pickToCol: '18',
						rewardFromCol: '19',
						rewardToCol: '20',
					})
				),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const fetchRewardsSettlementList =
	({ cancelToken, queryParams, employeeUuid, options }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchRewardsSettlementListUrl({
					queryParams: getFormattedParamsDates({
						params,
						pickFromCol: '9',
						pickToCol: '10',
						rewardFromCol: '11',
						rewardToCol: '12',
					}),
					employeeUuid,
				}),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
