// Import styles
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	officeTable: {
		maxWidth: '200px',
	},
	cell: {
		display: 'flex',
		alignItems: 'center',
	},
	button: {
		margin: theme.spacing(0),
		padding: '2px 4px',
		fontSize: '12px',
	},
	buttonReject: {
		marginLeft: theme.spacing(1),
		padding: '2px 4px',
		fontSize: '12px',
		background: theme.palette.error.main,
		color: theme.palette.white,

		'&:hover': {
			background: theme.palette.error.main,
		},
	},
}));

export default useStyles;
