import React from 'react';
import { object } from 'prop-types';
import { TableCell, Tooltip } from '@material-ui/core';

// Import utilities
import { useTranslations, useToggle } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

// Import components
import { IconButton } from 'components/elements';
import { SettlementsHistory } from './components';

// Import context
import { SettlementHistoryProvider } from './context';

// Import assets
import { HistoryIcon } from 'assets/icons';

export const HistoryActionButton = ({ cell }) => {
	const { user_uuid: userUuid } = cell.row.original;

	const classes = useStyles();

	const { on, toggle } = useToggle();

	const { t } = useTranslations();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{on && (
				<SettlementHistoryProvider userUuid={userUuid}>
					<SettlementsHistory open={on} onClose={toggle} />
				</SettlementHistoryProvider>
			)}
			<Tooltip title={t('rewards_settlement.history.title')}>
				<span>
					<IconButton onClick={toggle} className={classes.icon} size="small">
						<HistoryIcon />
					</IconButton>
				</span>
			</Tooltip>
		</TableCell>
	);
};

HistoryActionButton.propTypes = {
	cell: object,
};
