import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: ({ isActive }) => ({
		color: `${
			isActive ? theme.palette.primary.main : theme.palette.error.main
		}`,
		height: 20,
		fontSize: 7.5,
		border: '1px solid',
		borderColor: theme.palette.divider,
		borderRadius: 6,
		fontWeight: 'bold',
		'&:hover': {
			borderColor: `${
				isActive ? theme.palette.primary.main : theme.palette.error.main
			}`,
			backgroundColor: theme.palette.white,
		},
	}),
}));
