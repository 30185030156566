import React from 'react';
import { string, array, func, object } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, ListItem, Checkbox, ListItemText } from '@material-ui/core';

// Import helpers
import { EN } from 'helpers/variables';
import { useTranslations } from 'components/utilities';

const useStyles = makeStyles(() => ({
	checked: {
		backgroundColor: 'rgba(0, 150, 136, 0.3)',
	},
	formControl: {
		padding: '5px',
		margin: 0,
		width: '100%',
	},
}));

const OfficeItem = ({
	officeUuid,
	office,
	officesList,
	setCheckedOffices,
	checkedOffices,
	setSelectAll,
}) => {
	const { i18n } = useTranslations();
	const lng = i18n.language;

	const classes = useStyles();
	const isEnglish = lng === EN;
	const officeName = isEnglish ? office.en : office.ar;

	const isChecked = checkedOffices.includes(officeUuid);

	const handleCheckboxChange = () => {
		if (!isChecked) {
			const newOffices = [officeUuid, ...checkedOffices];
			newOffices.length === officesList.length && setSelectAll(true);
			setCheckedOffices(newOffices);
		} else {
			setSelectAll(false);
			const newOffices = checkedOffices.filter((item) => item !== officeUuid);
			setCheckedOffices(newOffices);
		}
	};

	const listItemWrapperClassName = isChecked ? classes.checked : '';

	return (
		<Grid item md={6} xs={12}>
			<Box
				className={listItemWrapperClassName}
				boxShadow={1}
				marginLeft="2px"
				marginRight="2px"
			>
				<ListItem button role={undefined} dense onClick={handleCheckboxChange}>
					<Checkbox
						edge="start"
						color="primary"
						checked={isChecked}
						disableRipple
						inputProps={{ 'aria-labelledby': officeUuid }}
					/>
					<ListItemText id={officeUuid} primary={officeName} />
				</ListItem>
			</Box>
		</Grid>
	);
};

OfficeItem.propTypes = {
	officeUuid: string.isRequired,
	office: object.isRequired,
	officesList: array.isRequired,
	setCheckedOffices: func.isRequired,
	setSelectAll: func.isRequired,
	checkedOffices: array.isRequired,
};

export default OfficeItem;
