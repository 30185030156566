import React from 'react';
import { object } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import utilities
import usePriceDifferenceDescription from './usePriceDifferenceDescription';

const PriceDifferenceDescription = ({ booking }) => {
	const { description, price, isHidden } = usePriceDifferenceDescription({
		booking,
	});

	if (isHidden) return null;

	return (
		<Grid container spacing={1}>
			<Grid item>
				<Typography variant="h6" noWrap>
					{description}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="h6" noWrap color="primary">
					{price}
				</Typography>
			</Grid>
		</Grid>
	);
};

PriceDifferenceDescription.propTypes = {
	booking: object.isRequired,
};

export default PriceDifferenceDescription;
