export const COLUMNS_ACCESSORS = {
	INDEX: 'index',
	PRICE: 'price',
};

export const columns = [
	{
		header: '',
		accessor: COLUMNS_ACCESSORS.INDEX,
	},
	{
		header: 'dashboard.rented_cars_table.headers.manufacturer',
		accessor: 'maker',
	},
	{
		header: 'dashboard.rented_cars_table.headers.model',
		accessor: 'model',
	},
	{
		header: 'dashboard.rented_cars_table.headers.year',
		accessor: 'year',
	},
	{
		header: 'dashboard.rented_cars_table.headers.price',
		accessor: COLUMNS_ACCESSORS.PRICE,
	},
];
