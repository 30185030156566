import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CheckIconTwoTone = (props) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm-2 13l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
				opacity=".1"
			/>
			<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
		</SvgIcon>
	);
};

export default CheckIconTwoTone;
