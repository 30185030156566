import React from 'react';
import { Field } from 'react-final-form';
import { Box, Card, ListItem, Typography } from '@material-ui/core';

// Import components
import { OptionFieldFactory } from './components';
import { TextFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import useOptionsField from './useOptionsField';

const OptionsField = () => {
	const {
		available_options,
		convertedErrors,
		submitting,
		isPromoCodeError,
		promoCodeError,
	} = useOptionsField();

	const { t } = useTranslations();

	return (
		<Card>
			{!!available_options.length && (
				<Box>
					{available_options.map((option) => (
						<ListItem key={option.type}>
							<OptionFieldFactory option={option} />
						</ListItem>
					))}
					{convertedErrors?.map((error) => (
						<Box key={error} m={2} mt={0}>
							<Typography color="error">{error}</Typography>
						</Box>
					))}
				</Box>
			)}
			<Box mx={2}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="text"
					label={t('bookings.edit_booking.form.promo_code')}
					name="options.promo_code"
					margin="dense"
					variant="outlined"
					disabled={submitting}
					error={isPromoCodeError}
					helperText={promoCodeError}
				/>
			</Box>
		</Card>
	);
};

export default OptionsField;
