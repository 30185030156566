import React from 'react';
import { object } from 'prop-types';
import { TableCell, Button, Dialog, Slide, Box } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';

// import components
import OfficesDialogContent from './OfficesDialogContent';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AssignOfficesCell = ({ cell }) => {
	const { t } = useTranslations();
	const [isOpen, setIsOpen] = React.useState(false);
	const classes = useStyles();

	const {
		row: { original },
	} = cell;

	const { office_count } = original;
	const badgeContent = office_count !== null ? office_count.toString() : '0';

	const handleClickOpen = () => setIsOpen((prev) => !prev);

	return (
		<TableCell {...cell.getCellProps()}>
			<Box className={classes.wrapper}>
				<Badge
					badgeContent={badgeContent}
					className={classes.badge}
					color="primary"
				/>
				<Button
					color="primary"
					onClick={handleClickOpen}
					className={classes.button}
				>
					{t('company_managers.table.cells.assign_offices')}
				</Button>
			</Box>
			<Dialog
				open={isOpen}
				TransitionComponent={Transition}
				onClose={handleClickOpen}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				fullWidth
			>
				<OfficesDialogContent
					isOpen={isOpen}
					user={original}
					dialogClose={handleClickOpen}
				/>
			</Dialog>
		</TableCell>
	);
};

AssignOfficesCell.propTypes = {
	cell: object.isRequired,
};

export default AssignOfficesCell;
