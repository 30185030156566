import AddCircleIcon from '@material-ui/icons/AddCircle';
import { URLS } from 'components/routes';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = () => [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: URLS.addCmsPartnersUrl,
		icon: AddCircleIcon,
		text: 'cms.partners.table.toolbar.add_partner',
	},
];
