import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 500,
		fontSize: '18px',
		letterSpacing: '-0.06px',
		lineHeight: '28px',
	},
	buttonClose: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(2),
	},
	dialogContent: {
		paddingBottom: theme.spacing(2),
		paddingTop: 0,
		height: '100%',
	},
	dialogActionsSticky: {
		position: 'sticky',
		bottom: 0,
		right: 0,
		background: theme.palette.background.paper,
	},
	dialogActions: {
		marginTop: theme.spacing(2),
		'& > button': {
			minWidth: 130,
		},
	},
	clearBtn: {
		marginRight: 'auto',
	},
	error: {
		color: '#e53935',
	},
	success: {
		color: theme.palette.primary.main,
	},
}));

export default useStyles;
