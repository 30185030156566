export const services = [
	'auth_out_ksa',
	'child_seat',
	'delivery',
	'delivery_drop',
	'driver',
	'dropCity',
	'extra_driver',
	'insurance',
	'no_smoking_car',
	'tam',
	'unlimitedKm',
];
