import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ADMINS_QUERY } from 'helpers';

const useAdmins = () => {
	const path = '/v2/user/list';

	const { data, isFetching } = useQuery(
		ADMINS_QUERY,
		async () => await services.post(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};

export default useAdmins;
