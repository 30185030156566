import React from 'react';
import { arrayOf, node, number, object, oneOfType, string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { TableCell, Link, Typography } from '@material-ui/core';

// Import helpers
import { TITLE_POSITIONS } from 'helpers';

const { TOP, BOTTOM } = TITLE_POSITIONS;

const LinkTableCell = ({
	cell,
	to,
	children,
	subtitle = '',
	minWidth = 'auto',
	subtitlePosition = BOTTOM,
}) => {
	const isTopSubtitle = subtitle && subtitlePosition === TOP;
	const isBottomSubtitle = subtitle && subtitlePosition === BOTTOM;

	return (
		<TableCell {...cell.getCellProps()} style={{ minWidth }}>
			{children && (
				<>
					{isTopSubtitle && <Typography>{subtitle}</Typography>}
					<Link component={RouterLink} to={to} variant="h6">
						{children}
					</Link>
					{isBottomSubtitle && <Typography>{subtitle}</Typography>}
				</>
			)}
		</TableCell>
	);
};

LinkTableCell.propTypes = {
	cell: object,
	to: string,
	children: oneOfType([node, arrayOf(node)]),
	subtitle: string,
	minWidth: oneOfType([string, number]),
	subtitlePosition: string,
};

export default LinkTableCell;
