import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import translations
import { useTranslations } from 'components/utilities';

// Import actions
import { fetchBookingGratification } from 'store/actions';

// import helpers
import { getErrorMessage } from '../../helpers';

const useExperienceSharedFormFields = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [bookingData, setBookingData] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');

	const { t } = useTranslations();

	const {
		values: { booking },
		submitErrors,
		hasSubmitErrors,
	} = useFormState();

	const { resetFieldState } = useForm();

	useEffect(() => {
		setErrorMessage('');
		submitErrors?.booking[0] && setErrorMessage(submitErrors?.booking[0]);
	}, [hasSubmitErrors, submitErrors]);

	const handleOnClick = async () => {
		resetFieldState('booking.uuid');

		if (booking.uuid.length < 4) {
			const message = t('campaigns.experience_form.error_too_short');
			setErrorMessage(message);
			return;
		}

		try {
			setBookingData(null);
			setErrorMessage('');
			setIsLoading(true);

			const bookingGratification = await fetchBookingGratification(booking);

			setBookingData(bookingGratification);
			setIsLoading(false);
		} catch (error) {
			const message = getErrorMessage(error);

			setErrorMessage(message);
			setIsLoading(false);
		}
	};

	const bookingIdFieldProps = {
		isLoading,
		errorMessage,
		onClick: handleOnClick,
	};

	return { bookingIdFieldProps, bookingData };
};

export default useExperienceSharedFormFields;
