import { SvgIcon } from '@material-ui/core';
import React from 'react';

export const MinusIcon = (props) => (
	<SvgIcon
		width="11"
		height="12"
		viewBox="0 0 11 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.05444 6H8.90106"
			stroke="currentColor"
			strokeWidth="0.939634"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
