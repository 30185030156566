import { useSelector } from 'react-redux';
import { isAdminSelector, profileItemSelector } from 'store/selectors';

// Import helpers
import { FILTERS_INITIAL_VALUES } from './helpers';

export const useCarsAvailability = () => {
	const isAdmin = useSelector(isAdminSelector);
	const profileData = useSelector(profileItemSelector);

	const userCompanyUuid = isAdmin ? null : profileData.belonging.company.uuid;

	const filtersInitialValues = {
		...FILTERS_INITIAL_VALUES,
		...(!isAdmin && { company_uuid: userCompanyUuid }),
	};

	return { filtersInitialValues, userCompanyUuid };
};
