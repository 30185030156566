import { sortBy } from 'lodash';

export const convertSubmitData = (values) => {
	const vehicleMakerUuid = values.filters.vehicle_maker_uuid;
	const vehicleModelUuid = values.filters.vehicle_model_uuid;
	const vehicleTypeUuid = values.filters.vehicle_type_uuid;
	const lightColor = values.light_color_hex;
	const mainColor = values.main_color_hex;

	return {
		...values,
		filters: {
			...values.filters,
			vehicle_maker_uuid: vehicleMakerUuid === 'all' ? null : vehicleMakerUuid,
			vehicle_model_uuid: vehicleModelUuid === 'all' ? null : vehicleModelUuid,
			vehicle_type_uuid: vehicleTypeUuid === 'all' ? null : vehicleTypeUuid,
		},
		light_color_hex:
			typeof lightColor === 'string'
				? lightColor
				: `#${lightColor.hex}`.toUpperCase(),
		main_color_hex:
			typeof mainColor === 'string'
				? mainColor
				: `#${mainColor.hex}`.toUpperCase(),
	};
};

export const convertFetchData = (values) => {
	const vehicleMakerUuid = values.filters.vehicle_maker_uuid;
	const vehicleModelUuid = values.filters.vehicle_model_uuid;
	const vehicleTypeUuid = values.filters.vehicle_type_uuid;

	return {
		...values,
		filters: {
			...values.filters,
			vehicle_maker_uuid: vehicleMakerUuid === null ? 'all' : vehicleMakerUuid,
			vehicle_model_uuid: vehicleModelUuid === null ? 'all' : vehicleModelUuid,
			vehicle_type_uuid: vehicleTypeUuid === null ? 'all' : vehicleTypeUuid,
		},
	};
};

export const convertPartnersPositionsData = (values) => {
	const sortedCompanies = sortBy(values.partners, ['position']);
	return {
		ordered_company_uuids: [...sortedCompanies.map(({ uuid }) => uuid)],
	};
};
