import React from 'react';
import { TableCell } from '@material-ui/core';
import { bool, object } from 'prop-types';

// Import components
import { ButtonProgress } from 'components/elements';

// Import utils
import { useSubscriptionActiveTableCell } from './useSubscriptionActiveTableCell';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

export const SubscriptionActiveTableCell = ({ cell, disabled }) => {
	const { t } = useTranslations();

	const { isActive, isLoading, label, onClick } =
		useSubscriptionActiveTableCell({
			cell,
		});

	const classes = useStyles({ isActive });

	return (
		<TableCell {...cell.getCellProps()}>
			<ButtonProgress
				size="small"
				variant="contained"
				onClick={onClick}
				isLoading={isLoading}
				className={classes.toggleButton}
				circularProgressSize={20}
				disabled={disabled}
			>
				{t(label)}
			</ButtonProgress>
		</TableCell>
	);
};

SubscriptionActiveTableCell.propTypes = {
	cell: object.isRequired,
	disabled: bool,
};

SubscriptionActiveTableCell.defaultProps = {
	disabled: false,
};
