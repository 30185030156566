import React from 'react';
import { bool } from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import components
import { BaseYesNoAllFilter } from 'components/elements';
import { ActiveCompaniesFilter } from 'components/elements';

// Import utilities and helpers
import { useCommonFields, useProfile } from 'components/utilities';
import { useCompanies } from 'queries';
import { FIELDS } from './helpers';

const FilterFields = ({ isExport }) => {
	const { renderFields } = useCommonFields();
	const { data: companies } = useCompanies();
	const { values } = useFormState();
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={3} alignItems="center">
			<BaseYesNoAllFilter
				name="active"
				label={`${
					isExport
						? 'offices.table.export_filters.office_is_active'
						: 'offices.table.headers.is_active'
				}`}
			/>
			{isExport && (
				<ActiveCompaniesFilter
					name="company_is_active"
					label="offices.table.export_filters.company_is_active"
				/>
			)}

			{isAdmin && isExport && FIELDS({ companies, values }).map(renderFields)}
		</Grid>
	);
};

FilterFields.propTypes = {
	isExport: bool,
};

FilterFields.defaultProps = {
	isExport: false,
};

export default FilterFields;
