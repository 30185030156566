import React from 'react';
import { any, array, object, oneOfType } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import utilities
import { useMediaQuerySizes } from 'components/utilities';

// Import styles
import { useStyles } from './FieldBox.styles';

export const FieldBox = ({ children: Children, fields, ...rest }) => {
	const classes = useStyles();
	const { isTabletSize } = useMediaQuerySizes();

	return (
		<Grid
			container
			spacing={isTabletSize ? 1 : 4}
			className={classes.container}
		>
			<Grid item xs={12}>
				<Children fields={fields} {...rest} />
			</Grid>
		</Grid>
	);
};

FieldBox.propTypes = {
	fields: oneOfType([array, object]).isRequired,
	children: any.isRequired,
};
