import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';

// Import styles
import useStyles from './styles';

const LoadingOverlay = ({ isLoading }) => {
	const classes = useStyles();

	if (!isLoading) {
		return null;
	}

	return (
		<Box className={classes.loadingOverlay}>
			<CircularProgress size={48} color="primary" />
		</Box>
	);
};

LoadingOverlay.propTypes = {
	isLoading: PropTypes.bool,
};

export default LoadingOverlay;
