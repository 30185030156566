import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			padding: '25px 25px 40px 25px',
		},
	},
}));
