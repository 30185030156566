import React from 'react';
import { array, bool, string } from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';

const BehaviourList = ({ availableBehaviours, name, disabled = false }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<List disablePadding>
			{availableBehaviours.map((behaviour) => (
				<ListItem key={behaviour}>
					<Field
						type="checkbox"
						component={CheckboxFieldAdapter}
						name={`${name}.behaviours`}
						disabled={submitting || disabled}
						value={behaviour}
						labelText={t(
							`company.form.payment_methods.behaviours.${behaviour}`
						)}
						color="primary"
					/>
				</ListItem>
			))}
		</List>
	);
};

BehaviourList.propTypes = {
	name: string,
	availableBehaviours: array,
	disabled: bool,
};

export default BehaviourList;
