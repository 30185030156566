import * as Yup from 'yup';
import i18n from 'i18next';

export const validationSchema = (maximumCustomerLimitAmount) => {
	const requiredField = i18n.t('common.fields.required_field');

	return Yup.object().shape({
		valid_until: Yup.date().required(requiredField),
		limit_amount_gross: Yup.number()
			.max(
				maximumCustomerLimitAmount,
				i18n.t(
					'b2b.corporate.overview.customer_limit_dialog.max_limit_message',
					{ value: maximumCustomerLimitAmount }
				)
			)
			.required(requiredField),
	});
};
