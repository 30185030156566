import i18next from 'i18next';

export const columns = (isAdmin) => {
	const exportColumns = [
		{
			Header: 'offices.table.headers.name',
			accessor: 'name',
		},
		{
			Header: 'offices.table.headers.city',
			accessor: 'city',
		},
		{
			Header: 'offices.table.headers.contact_person',
			accessor: 'contact_person',
		},
		{
			Header: 'offices.table.headers.contact_number',
			accessor: 'contact_number',
		},
		{
			Header: 'offices.table.headers.available_cars',
			accessor: 'regular_stats.available',
		},
		{
			Header: 'offices.table.headers.bookings_accepted',
			accessor: 'regular_stats.taken_accepted',
		},
		{
			Header: 'offices.table.headers.office_is_active',
			accessor: 'active',
			valueExtractor: ({ active }) =>
				i18next.t(`common.${active ? 'yes' : 'no'}`),
		},
		{
			Header: 'offices.table.headers.delivery',
			accessor: 'delivery',
			valueExtractor: ({ delivery }) =>
				i18next.t(`common.${delivery ? 'yes' : 'no'}`),
		},
		{
			Header: 'offices.table.headers.integration',
			accessor: 'is_external',
			valueExtractor: ({ is_external }) =>
				i18next.t(`common.${is_external ? 'yes' : 'no'}`),
		},
		{
			Header: 'offices.table.export_filters.company_is_active',
			accessor: 'company_is_active',
			valueExtractor: ({ company_is_active }) =>
				i18next.t(`common.${company_is_active ? 'yes' : 'no'}`),
		},
	];

	if (isAdmin)
		exportColumns.push({
			Header: 'offices.table.headers.company_name',
			accessor: 'company_name',
		});

	return exportColumns;
};
