import { format } from 'date-fns';

// Import helpers
import { DEFAULT_TIME_FORMAT } from 'helpers';

export const useMarkerWithLabel = () => {
	const getTimeFormatted = (date) =>
		format(new Date(date), DEFAULT_TIME_FORMAT);

	const getLabel = (state) =>
		`bookings.preview_booking.delivery_information.location_description.${state}`;

	const getPixelPositionOffset = (width, height) => ({
		x: -(width / 2),
		y: -(height / 2),
	});

	return {
		getPixelPositionOffset,
		getLabel,
		getTimeFormatted,
	};
};
