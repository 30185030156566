import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import services from 'services/services';
import { supplierUuidSelector, supplierPeriodSelector } from 'store/selectors';
import ls from 'localstorage-slim';

// Import helpers
import {
	COMPANY_COLLECTION_CAR_FILTER_QUERY,
	LOCAL_STORAGE_CAR_FILTERS,
	DEFAULT_DATE_FORMAT,
} from 'helpers';
import { convertDate } from 'helpers';

const useCompanyCollectionCarFilter = ({ values, enabled }) => {
	const companyCollectionUrl =
		'/v2/supplier/business/company/collection/car-filter';

	const { year, model_uuid, maker_uuid } = values?.vehicle;

	const { pickup_date, pick_time, drop_date } = values || {};

	const previous_office_uuid = useSelector(supplierUuidSelector);
	const {
		drop_date: dropDateSupplier,
		pick_date: pickDateSupplier,
		pick_time: pickTimeSupplier,
	} = useSelector(supplierPeriodSelector);

	const body = {
		vehicle_year: year,
		car_model_uuid: model_uuid,
		car_manufacturer_uuid: maker_uuid,
		previous_office_uuid,
		pickup_date: convertDate(
			`${convertDate(pickup_date || pickDateSupplier, DEFAULT_DATE_FORMAT)} ${
				pick_time || pickTimeSupplier
			}`
		),
		drop_date: convertDate(drop_date || dropDateSupplier, DEFAULT_DATE_FORMAT),
	};

	const localStorageCarFilters = ls.get(
		LOCAL_STORAGE_CAR_FILTERS(JSON.stringify(body))
	);

	const { data, isFetching, isFetched } = useQuery(
		[COMPANY_COLLECTION_CAR_FILTER_QUERY, JSON.stringify(body)],
		async () => await services.post(companyCollectionUrl, body),
		{ refetchOnWindowFocus: false, enabled: !localStorageCarFilters && enabled }
	);
	const fetchedData = data?.data.data || [];

	useEffect(() => {
		if (!localStorageCarFilters && isFetched && fetchedData.length) {
			ls.set(LOCAL_STORAGE_CAR_FILTERS(JSON.stringify(body)), fetchedData, {
				ttl: 7 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStorageCarFilters, isFetched, fetchedData]);

	const result = localStorageCarFilters || fetchedData;

	return { data: result, isFetching };
};

export default useCompanyCollectionCarFilter;
