import i18next from 'i18next';

// Import helpers
import { PAYMENT_METHODS } from 'helpers';

const getLabelSuffix = ({ gateway }) => {
	switch (gateway) {
		case PAYMENT_METHODS.BANK_TRANSFER: {
			return 'bank_transfer';
		}

		case PAYMENT_METHODS.LOYALTY_PROGRAM: {
			return 'qitaf';
		}
		case PAYMENT_METHODS.CORPORATE_LIMIT: {
			return 'corporate_limit';
		}

		case PAYMENT_METHODS.WALLET: {
			return 'wallet';
		}

		case PAYMENT_METHODS.TAMARA: {
			return 'tamara';
		}

		case PAYMENT_METHODS.CASH: {
			return 'cash';
		}

		case PAYMENT_METHODS.HYPERPAY:
		case PAYMENT_METHODS.CHECKOUT:
		default: {
			return 'online';
		}
	}
};

export const getLabel = ({ gateway }) => {
	const labelSuffix = getLabelSuffix({ gateway });
	const basePartLabelTrans = i18next.t(
		'bookings.preview_booking.invoice.total_amount_paid'
	);
	const suffixLabelTrans = i18next.t(
		`bookings.preview_booking.invoice.${labelSuffix}`
	);

	return `${basePartLabelTrans} (${suffixLabelTrans})`;
};
