import React from 'react';
import { Grid, Button, CircularProgress, Box } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Import components
import { PartnersFieldsRow } from '../../components';
import { AlertMessage } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useCompanies } from 'queries';

//Import styles
import { useStyles } from './PartnersContainer.styles';

export const PartnersContainer = () => {
	const { data: companies, isFetching, isError } = useCompanies(true);
	const { t } = useTranslations();
	const { mutators } = useForm();
	const classes = useStyles();

	const section = 'partners';

	const handlePushItem = () => mutators.push(section, undefined);

	if (isFetching) {
		return (
			<Grid item md={12} xs={12}>
				<Box className={classes.loader}>
					<CircularProgress size={27} />
				</Box>
			</Grid>
		);
	}

	if (isError) {
		return (
			<Grid item md={12} xs={12}>
				<AlertMessage
					variant="error"
					title="Error"
					description={t('common.messages.error_message')}
				/>
			</Grid>
		);
	}

	return (
		<>
			<FieldArray name={section}>
				{({ fields }) =>
					fields.map((name, index) => (
						<PartnersFieldsRow
							key={name}
							name={name}
							index={index}
							fields={fields}
							companies={companies}
						/>
					))
				}
			</FieldArray>
			<Grid item md={12} xs={12}>
				<Button variant="contained" color="primary" onClick={handlePushItem}>
					<AddCircleIcon className={classes.icon} fontSize="small" />
					{t('cms.partners.form.add_partner')}
				</Button>
			</Grid>
		</>
	);
};
