import * as types from 'store/types';
import produce from 'immer';

// Import helpers
import { FILTERS_INITIAL_VALUES } from 'views/CarsAvailability/helpers';

const INITIAL_STATE = {
	exportFilters: FILTERS_INITIAL_VALUES,
	isOpen: false,
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.UPDATE_EXPORT_FILTERS:
				draft.exportFilters = action.payload;
				break;

			case types.UPDATE_EXPORT_OPEN_STATE:
				draft.isOpen = action.payload;
				break;

			default:
				return state;
		}
	});
