import { useEffect } from 'react';
import { queryCache } from 'react-query';

// Import helpers
import { BOOKING_CHIP_REFERENCES, BOOKING_COUNT_QUERY } from 'helpers';

// Import utils
import { useTableContextProvider } from 'components/context';

export const useFilters = () => {
	const { loading } = useTableContextProvider();

	const refetch = () => {
		queryCache.refetchQueries([
			BOOKING_COUNT_QUERY,
			BOOKING_CHIP_REFERENCES.CANCELLATION,
		]);
	};

	useEffect(() => {
		if (loading) {
			refetch();
		}
		// eslint-disable-next-line
	}, [loading]);
};
