import React from 'react';
import { node, oneOf, oneOfType, string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './AlertMessage.styles';

// Import helpers
import { renderAlertIcon } from './helpers';

export const AlertMessage = ({ variant, title, description, action }) => {
	const classes = useStyles({ variant });

	return (
		<Box className={classes.container}>
			<Box className={classes.info}>
				<Box className={classes.icon}>{renderAlertIcon(variant)}</Box>
				<Box className={classes.labels}>
					<Typography variant="h5">{title}</Typography>
					<Typography variant="body1">{description}</Typography>
				</Box>
			</Box>
			{action && <Box className={classes.action}>{action}</Box>}
		</Box>
	);
};

AlertMessage.propTypes = {
	title: string.isRequired,
	description: string.isRequired,
	action: oneOfType([node, string]),
	variant: oneOf(['success', 'error', 'warning', 'default']).isRequired,
};
