import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { updateActiveCampaign } from 'store/actions';

// Import helpers
import { TABLE_ACTIONS_ACCESSORS } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { ActiveTableCell } from 'components/elements';
import {
	EditCampaignTableCell,
	TotalDepositCounterTableCell,
} from '../../components';

// Import translations
import { useTranslations } from 'components/utilities';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, CAMPAIGN_TYPE, CREATED, TOTAL_DEPOSIT_COUNTER } =
	COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const { t } = useTranslations();

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveCampaign}
						cell={cell}
					/>
				);
			case EDIT:
				return <EditCampaignTableCell key={cell.column.id} cell={cell} />;

			case TOTAL_DEPOSIT_COUNTER:
				return (
					<TotalDepositCounterTableCell key={cell.column.id} cell={cell} />
				);

			case CAMPAIGN_TYPE:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{t(`campaigns.types.${cell.row.original.campaign_type}`)}
					</TableCell>
				);

			case CREATED:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.row.original.created_at}
						<br />
						{cell.row.original.blameable}
					</TableCell>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
