import { EMAIL_REGEX } from '../../../helpers/validation';

export default (values) => {
	let errors = {};

	if (!values.email) {
		errors.email = 'forget_password.errors.email_required';
	} else if (!EMAIL_REGEX.test(values.email)) {
		errors.email = 'forget_password.errors.email_invalid';
	}

	return errors;
};
