import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { array, bool, object, func } from 'prop-types';
import {
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	CircularProgress,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';

const CampaignSupplierCard = ({
	companies,
	offices,
	selectedCompanies,
	selectedOffices,
	isFetching,
	onCompanySelectClose,
}) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	const isOfficesSelectDisabled =
		submitting || Object.keys(offices).length === 0;

	return (
		<Card>
			<CardHeader title={t('campaigns.form.supplier_card_title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={4} xs={12}>
						{isFetching ? (
							<CircularProgress />
						) : (
							<Field
								fullWidth
								component={MultiselectFiledAdapter}
								type="select"
								label={t('campaigns.form.supplier.company')}
								name="condition.supplier.company"
								margin="dense"
								variant="outlined"
								disabled={submitting}
								labelextractor={(value) => companies[value]}
								onClose={onCompanySelectClose}
							>
								{Object.keys(companies).map((key) => (
									<MenuItem key={key} value={key}>
										<Checkbox
											color="primary"
											checked={selectedCompanies.includes(key)}
										/>
										{companies[key]}
									</MenuItem>
								))}
							</Field>
						)}
					</Grid>

					<Grid item md={4} xs={12}>
						{isFetching ? (
							<CircularProgress />
						) : (
							<Field
								fullWidth
								component={MultiselectFiledAdapter}
								type="select"
								label={t('campaigns.form.supplier.office')}
								name="condition.supplier.office"
								margin="dense"
								variant="outlined"
								disabled={isOfficesSelectDisabled}
								labelextractor={(value) => offices[value]}
							>
								{Object.keys(offices).map((key) => (
									<MenuItem key={key} value={key}>
										<Checkbox
											color="primary"
											checked={selectedOffices.includes(key)}
										/>
										{offices[key]}
									</MenuItem>
								))}
							</Field>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

CampaignSupplierCard.propTypes = {
	companies: object.isRequired,
	offices: object.isRequired,
	selectedCompanies: array.isRequired,
	selectedOffices: array.isRequired,
	isFetching: bool,
	onCompanySelectClose: func.isRequired,
};

export default CampaignSupplierCard;
