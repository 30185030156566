import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers
import { getCampaignType } from '../../helpers';
import { replaceEditCampaignsUrl } from 'helpers';

// Import components
import { EditTableCell } from 'components/elements';

const EditCampaignTableCell = ({ cell }) => {
	const campaignType = cell.row.original.campaign_type;
	const allowedCampaignType = getCampaignType(campaignType);

	if (!allowedCampaignType) {
		return <TableCell key={cell.column.id} {...cell.getCellProps()} />;
	}

	return (
		<EditTableCell
			key={cell.column.id}
			cell={cell}
			editLinkPath={replaceEditCampaignsUrl(cell.row.original.uuid)}
		/>
	);
};

EditCampaignTableCell.propTypes = {
	cell: object.isRequired,
};

export default EditCampaignTableCell;
