import React from 'react';
import { IconButton, Typography } from '@material-ui/core';

// Import utilities
import { useLanguageButton } from './useLanguageButton';

// Import styles
import useStyles from './styles';

export const LanguageButton = ({ ...props }) => {
	const { convertedLanguage, handleChangeLanguage } = useLanguageButton();

	const classes = useStyles();

	return (
		<IconButton
			onClick={handleChangeLanguage}
			className={classes.button}
			disableRipple
			{...props}
		>
			<Typography
				variant="h5"
				color="textPrimary"
				className={classes.languageText}
			>
				{convertedLanguage}
			</Typography>
		</IconButton>
	);
};
