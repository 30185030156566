import React from 'react';

// Import components
import { TextFieldAdapter, SelectFieldAdapter } from 'components/elements';
import { MenuItem } from '@material-ui/core';

// Import helpers
import { KIOSK_TYPES } from 'helpers/variables/kiosk';

export const getLink = (slug) => {
	if (process.env.NODE_ENV === 'production') {
		return `https://${slug}.kiosk.telgani.com/start`;
	}
	return `https://${slug}.kiosk.telgani.at.proexe.co/start`;
};

export const fields = (section) => {
	const result = [
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'kiosk.management.form.details.name_en',
			name: `${section}.name.en`,
			md: 6,
			xs: 6,
		},
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'kiosk.management.form.details.name_ar',
			name: `${section}.name.ar`,
			md: 6,
			xs: 6,
		},
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'kiosk.management.form.details.slug',
			name: `${section}.slug`,
			md: 6,
			xs: 6,
		},
		{
			component: SelectFieldAdapter,
			type: 'select',
			label: 'kiosk.management.form.details.type',
			name: `${section}.type`,
			md: 6,
			xs: 6,
			children: KIOSK_TYPES.map((type) => (
				<MenuItem key={type} value={type}>
					{type}
				</MenuItem>
			)),
		},
	];

	return result;
};
