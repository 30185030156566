// Import helpers
import { EN } from 'helpers';

// Import styles
import theme from 'theme';

export const inputStyles = {
	width: '47px',
	height: '47px',
	margin: '0 2.5px',
	fontSize: '13px',
	fontWeight: '600',
	backgroundColor: theme?.palette.grey[100],
	borderRadius: '18px',
	border: 'none',

	':focus': {
		outline: 'none',
		border: `2px solid ${theme?.palette.primary.main}`,
	},
};

export const focusStyles = {
	outline: 'none',
	border: `2px solid ${theme?.palette.primary.main}`,
};

export const containerStyles = (lang) => ({
	direction: lang === EN ? 'ltr' : 'rtl',
	flexWrap: 'wrap',
	gap: '5px',
});
