import produce from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	item: {},
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.CUSTOMER_PREVIEW_FETCH_DATA_LOADING:
				draft.isLoaded = false;
				break;
			case types.CUSTOMER_PREVIEW_FETCH_DATA_SUCCESS:
				draft.isLoaded = true;
				draft.item = action.payload;
				break;
			case types.CUSTOMER_PREVIEW_FETCH_DATA_ERROR:
				draft.isError = true;
				draft.isLoaded = true;
				draft.error = action.payload;
				break;
			default:
				return state;
		}
	});
