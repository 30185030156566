import React from 'react';
import { Button } from '@material-ui/core';
import { func, oneOf, string } from 'prop-types';

// Import styles
import { useStyles } from './Label.styles';

// Import helpers
import { BUTTON_ACTION_TYPES } from 'helpers';

// Import icons
import { PlusIcon } from 'icons';

const { INCREMENT, DECREMENT } = BUTTON_ACTION_TYPES;

export const Label = ({ id, actionType = INCREMENT, onClick }) => {
	const classes = useStyles();

	return (
		<Button
			size="small"
			onClick={onClick}
			className={classes.button}
			startIcon={
				actionType === INCREMENT ? (
					<PlusIcon className={classes.icon} />
				) : (
					<PlusIcon className={classes.closeIcon} />
				)
			}
		>
			{id}
		</Button>
	);
};

Label.propTypes = {
	id: string,
	actionType: oneOf([INCREMENT, DECREMENT]),
	onClick: func,
};
