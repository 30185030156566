import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { Autocomplete } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { string } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { TextFieldAdapter } from 'components/elements';

// Import utilities
import useAdminFilter from './useAdminFilter';

const AdminFilter = ({ label, name }) => {
	const { sortedAdmins, handleOnChange, selectedAdmin } = useAdminFilter({
		name,
	});

	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid item xs={3} md={3}>
			<Autocomplete
				options={sortedAdmins}
				getOptionLabel={(option) => option.name}
				getOptionSelected={(option, value) => option.uuid === value.uuid}
				value={selectedAdmin}
				onChange={handleOnChange}
				renderInput={(params) => (
					<Field
						fullWidth
						component={TextFieldAdapter}
						type="text"
						label={t(label)}
						name={name}
						margin="dense"
						variant="outlined"
						disabled={submitting}
						defaultValue={selectedAdmin}
						{...params}
					/>
				)}
			/>
		</Grid>
	);
};

AdminFilter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
};

export default AdminFilter;
