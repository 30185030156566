import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),

		display: 'inline-block',
	},
	input: {
		'& .MuiInputBase-root': {
			minWidth: '300px',
		},
	},
	disabledInput: {
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
		'& .MuiOutlinedInput-root': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			display: 'none',
		},
		'& .MuiInputBase-input.Mui-disabled': {
			color: '#263238',
		},
		'&::placeholder': {
			display: 'none',
		},
	},
	borderInput: {
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: '4px',
		},
		'& .MuiOutlinedInput-root': {
			border: '1px solid transparent',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
			display: 'none',
		},
	},
}));

export default useStyles;
