import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	individualNumber: {
		color: theme.palette.primary.main,
		textTransform: 'uppercase',
		fontSize: '1em',
		fontWeight: 'bold',
		letterSpacing: 2,
		width: 'max-content',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1em',
	},
	tooltip: {
		background: 'none',
	},
	closeButton: {
		background: theme.palette.error.main,
		color: theme.palette.white,
		'&:hover': {
			background: theme.palette.error.dark,
		},
	},
	fetchingWrapper: {
		display: 'grid',
		placeItems: 'center',
	},
}));
