// ********************  table  ********************
export const fetchTrainStationsListUrl = (queryParams) =>
	`/railway/train-station${queryParams}`;

export const updateTrainStationStateUrl = ({ uuid }) =>
	`/railway/train-station/${uuid}/state`;

// ********************  form  ********************
export const submitTrainStationFormUrl = ({ uuid }) =>
	uuid ? `/railway/train-station/${uuid}` : '/railway/train-station';
