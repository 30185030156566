import React from 'react';
import { Form } from 'react-final-form';
import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useAddDialog } from './useAddDialog';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';

// Import helpers
import { initialValues } from './helpers';

export const AddDialog = () => {
	const { handleOnSubmit, toggle, isLoading, isOpen } = useAddDialog();

	const { t } = useTranslations();

	return (
		<>
			{isOpen && (
				<Dialog open={isOpen} fullWidth>
					<ClosableDialogTitle
						title={t('customers.preview.wallet.add_dialog.title')}
						onClose={toggle}
					/>
					<DialogContent>
						<Form
							onSubmit={handleOnSubmit}
							initialValues={initialValues}
							render={({ handleSubmit }) => (
								<form onSubmit={handleSubmit}>
									<FormContent toggle={toggle} isLoading={isLoading} />
								</form>
							)}
						/>
					</DialogContent>
				</Dialog>
			)}
			<Grid item xs={2}>
				<Button variant="contained" color="primary" onClick={toggle}>
					{t('common.buttons.add')}
				</Button>
			</Grid>
		</>
	);
};
