import React from 'react';
import { string } from 'prop-types';
import { useParams } from 'react-router';
import { Field, useFormState } from 'react-final-form';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	Card,
	CardHeader,
	Divider,
	CardContent,
	Grid,
	Box,
	CircularProgress,
	MenuItem,
} from '@material-ui/core';

// Import components
import { SelectFieldAdapter, TextFieldAdapter } from 'components/elements';

// Import utilities
import useBaseDetails from 'views/Cars/useBaseDetails';

// Import styles
import { useStyles } from './styles';

const BaseDetails = ({ className, ...rest }) => {
	const classes = useStyles();
	const params = useParams();
	const { submitting, values } = useFormState();

	const {
		t,
		lng,
		models,
		value,
		inputModelValue,
		isFetching,
		manufacturers,
		handleModelOnChange,
		handleOnModelInputChange,
	} = useBaseDetails({
		values,
		inputState: values.model_name.en,
	});

	return (
		<Card className={className} {...rest}>
			<CardHeader title={t('cars.models.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{isFetching ? (
						<Grid item md={12} xs={12}>
							<Box className={classes.loader}>
								<CircularProgress size={35} />
							</Box>
						</Grid>
					) : (
						<>
							<Grid item md={12} xs={12}>
								<Field
									fullWidth
									component={SelectFieldAdapter}
									type="select"
									label={t('cars.models.form.details.manufacturer')}
									name="maker_uuid"
									margin="dense"
									variant="outlined"
									disabled={submitting || !!params.id}
									required
								>
									{manufacturers.map(({ name, uuid }) => (
										<MenuItem key={name[lng]} value={uuid}>
											{name[lng]}
										</MenuItem>
									))}
								</Field>
							</Grid>

							<Grid item md={6} xs={6}>
								<Autocomplete
									value={inputModelValue}
									inputValue={inputModelValue}
									onChange={handleModelOnChange}
									onInputChange={handleOnModelInputChange}
									options={models.map((el) => el.name)}
									getOptionSelected={(option, value) =>
										option.name === value.name
									}
									disabled={submitting || !values.maker_uuid}
									renderInput={(params) => (
										<Field
											fullWidth
											component={TextFieldAdapter}
											type="text"
											label={t('cars.models.form.details.model_en')}
											name="model_name.en"
											margin="dense"
											variant="outlined"
											required
											defaultValue={value}
											{...params}
										/>
									)}
								/>
							</Grid>
							<Grid item md={6} xs={6}>
								<Field
									fullWidth
									component={TextFieldAdapter}
									type="text"
									label={t('cars.models.form.details.model_ar')}
									name="model_name.ar"
									margin="dense"
									variant="outlined"
									disabled={submitting}
									required
								/>
							</Grid>
						</>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

BaseDetails.propTypes = {
	className: string,
};

export default BaseDetails;
