import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { COMPANY_OFFICES_QUERY } from 'helpers';

const useCompanyOffices = (companyUuid) => {
	const officesCollectionUrl = `/v2/supplier/business/company/${companyUuid}/office/collection`;

	const enabled =
		companyUuid !== 'all' && companyUuid !== '' && null != companyUuid;

	const { data, isFetching, isError } = useQuery(
		[COMPANY_OFFICES_QUERY, companyUuid],
		async () => await services.get(officesCollectionUrl),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};

export default useCompanyOffices;
