import i18next from 'i18next';

// Import helpers
import { getBookingTopics } from './helpers';

export const columns = [
	{
		Header: 'bookings.table.headers.compensate_reason',
		accessor: 'operation_topics',
		valueExtractor: (row) => getBookingTopics(row),
	},

	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: 'cancel_reason',
		valueExtractor: ({ cancel_reason }) =>
			cancel_reason?.name?.[i18next.language],
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'individual_number',
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: 'customer_name',
	},
	{
		Header: 'bookings.table.headers.customer_phone',
		accessor: 'customer_phone',
	},
	{
		Header: 'bookings.compensation_history.bank_name',
		accessor: 'compensation.bank_name',
	},
	{
		Header: 'bookings.compensation_history.iban',
		accessor: 'compensation.iban',
		valueExtractor: ({ compensation: { iban } }) =>
			iban && !iban.toLowerCase().startsWith('sa') ? `sa${iban}` : iban,
	},
	{
		Header: 'bookings.table.headers.amount',
		accessor: 'compensation.compensation_amount',
		valueExtractor: ({ compensation_amount }) =>
			`${compensation_amount} ${i18next.t('currency.sar')}`,
	},
	{
		Header: 'bookings.table.headers.delivery',
		accessor: 'is_delivery',
		valueExtractor: ({ is_delivery }) =>
			i18next.t(`common.${is_delivery ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.payment_method',
		accessor: 'payment.method',
	},
	{
		Header: 'bookings.compensation_states.compensated',
		accessor: 'customer_compensation_counter',
	},
	{
		Header: 'bookings.table.headers.customer_first_compensated_booking_id',
		accessor: 'customer_first_compensated_booking_individual_number',
	},
	{
		Header: 'common.statuses.cancelled',
		accessor: 'booking_cancelled_counter',
	},
	{
		Header: 'common.statuses.accepted',
		accessor: 'booking_accepted_counter',
	},
	{
		Header: 'bookings.table.headers.validated_by',
		accessor: 'compensation_validated_by',
		valueExtractor: ({
			is_compensation_validated_by_admin: isAdmin,
			compensation_validated_by: validatedBy,
		}) =>
			isAdmin
				? validatedBy
				: i18next.t('bookings.table.headers.telgani_system'),
	},
	{
		Header: 'bookings.table.headers.fulfilled',
		accessor: 'fulfill',
		valueExtractor: ({ fulfill }) =>
			i18next.t(`common.${fulfill ? 'yes' : 'no'}`),
	},
];
