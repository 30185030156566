import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: '8px 0',
	},
	expandButton: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
	},
	minimizeButton: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.main,
	},
}));
