import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	cell: {
		display: 'flex',
		alignItems: 'center',
	},
	button: {
		margin: theme.spacing(0),
		padding: '1px 16px',
		fontSize: '12px',
		visibility: ({ hideActive }) => (!hideActive ? 'visible' : 'hidden'),
	},
	chip: {
		padding: '14px',
	},
}));

export default useStyles;
