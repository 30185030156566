import { DELIVERY_AT_AIRPORT_FIXED_PRICE, FIELD_TYPES } from 'helpers';

export const MAX_DELIVERY_OPTIONS = 5;

export const getFields = (section) => [
	{
		label: 'cars.form.extra_services.price_per_day',
		name: `${section}.child_seat_prices.daily.nett_price`,
	},
	{
		label: 'cars.form.extra_services.price_per_week',
		name: `${section}.child_seat_prices.weekly.nett_price`,
	},
	{
		label: 'cars.form.extra_services.price_per_month',
		name: `${section}.child_seat_prices.monthly.nett_price`,
	},
];

export const DRIVER_PRICES_FIELDS = (section) => {
	const fields = ['daily', 'weekly', 'monthly'];

	return fields.map((field) => ({
		type: FIELD_TYPES.NUMBER,
		label: `offices.form.extra_services.driver_prices.${field}`,
		name: `${section}.driver_prices.${field}.nett_price`,
		required: true,
		min: 0,
	}));
};

export const EXTRA_SERVICES_FIELDS = ({
	section,
	changeDeliveryDropWithDelivery,
}) => ({
	DRIVER_FIELD: {
		type: FIELD_TYPES.CHECKBOX,
		name: `${section}.driver`,
		label: 'offices.form.extra_services.driver',
	},
	DELIVERY_FIELD: {
		type: FIELD_TYPES.CHECKBOX,
		name: `${section}.car_delivery`,
		label: 'offices.form.extra_services.car_delivery',
		handleOnCheckboxClick: changeDeliveryDropWithDelivery,
	},
	DELIVERY_DROP_FIELD: {
		type: FIELD_TYPES.CHECKBOX,
		name: `${section}.is_delivery_drop`,
		label: 'offices.form.extra_services.delivery_pickup_and_drop_off',
		md: 6,
		xs: 12,
		disabled: true,
	},
	DELIVERY_AIRPORT_FIELD: {
		type: FIELD_TYPES.CHECKBOX,
		name: `${section}.is_delivery_at_airport`,
		label: 'offices.form.extra_services.delivery_at_airport',
		md: 6,
		xs: 12,
	},
});

export const DELIVERY_AT_AIRPORT_FIELDS = ({ airport, lang, t }) => [
	{
		type: FIELD_TYPES.TEXT,
		label: `offices.form.extra_services.drivery_at_airport_price`,
		name: `delivery_airport_price`,
		disabled: true,
		value: `${DELIVERY_AT_AIRPORT_FIXED_PRICE}${t('currency.sar')}`,
	},
	...(airport?.locations?.map((location, index) => {
		return {
			type: FIELD_TYPES.TEXT,
			label: location.name[lang],
			name: `location.airport.locations[${index}].delivery_address`,
			disabled: true,
		};
	}) || []),
];
