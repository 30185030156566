import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	loadingBox: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		padding: '24px 0',
	},
}));
