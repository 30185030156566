import React from 'react';
import { Box, Button, Card, CircularProgress } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';
import { useContract } from './useContract';

// Import styles
import { useStyles } from './Contract.styles';

// Import components
import { AlertMessage } from 'components/elements';

export const Contract = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { isFetching, isError, isContract, download, print, collectionError } =
		useContract();

	if (isFetching) {
		return (
			<Box className={classes.container}>
				<CircularProgress />
			</Box>
		);
	}

	if (isError) {
		return (
			<Box className={classes.errorWrapper}>
				<AlertMessage
					description={collectionError}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	if (!isContract) {
		return (
			<Box className={classes.errorWrapper}>
				<AlertMessage
					description={t('contract.contract_view.no_contract_message')}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	return (
		<Box className={classes.container}>
			<Card className={classes.buttonWrapper}>
				<Button onClick={download} variant="outlined" color="primary">
					{t('common.buttons.download')}
				</Button>
				<Button onClick={print} variant="contained" color="primary">
					{t('common.buttons.print')}
				</Button>
			</Card>
		</Box>
	);
};
