import services from 'services/services';
import { UPDATE_ACTIVE_TYPES } from 'helpers/variables';
import {
	fetchUrgentReasonsUrl,
	updateActiveUrgentReasonUrl,
	deleteUrgentReasonUrl,
} from 'store/paths';

export const fetchUrgentReasons = ({
	options,
	queryParams,
	cancelToken,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		// Get response
		const { data } = await services.post(
			fetchUrgentReasonsUrl(queryParams),
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveUrgentReason = ({ isActive, id }) => async () => {
	const { ACTIVATE, DEACTIVATE } = UPDATE_ACTIVE_TYPES;
	const type = isActive ? DEACTIVATE : ACTIVATE;

	await services.put(updateActiveUrgentReasonUrl(id, type));
};

export const deleteUrgentReason = ({ id }) => async () => {
	await services.delete(deleteUrgentReasonUrl(id));
};
