import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import ReasonName from '../ReasonName';

const ReasonDetails = () => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<ReasonName />
			</Grid>
		</Grid>
	);
};

export default ReasonDetails;
