import services from 'services/services';
import { convertPlate } from './conversion';

export const findSelectedPlate = ({
	vehiclePlates,
	currentPlateNumber = '',
}) => {
	if (currentPlateNumber) {
		return vehiclePlates.find(
			(plate) => convertPlate(plate).fullPlateFromApi === currentPlateNumber
		);
	}
};

export const getPlateData = async ({ plate, bookingUuid }) => {
	const path = 'econtract/latest-by-plate-number';

	const { plateBodyForGetPlateData } = convertPlate(plate);

	try {
		const { data } = await services.post(path, {
			...plateBodyForGetPlateData,
			booking_uuid: bookingUuid,
		});

		return Promise.resolve(data);
	} catch (error) {
		if (error?.response?.status === 422) {
			return Promise.reject(error?.response?.data?.errors?.plate_number?.[0]);
		} else {
			return Promise.reject(false);
		}
	}
};
