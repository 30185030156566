import React from 'react';

// Import components
import { Steps } from './components';

// Import utils
import { InitiateContractProvider } from './context';

export const InitiateContract = () => {
	return (
		<InitiateContractProvider>
			<Steps />
		</InitiateContractProvider>
	);
};
