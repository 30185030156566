import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

// Import styles
import useStyles from './styles';

const TwoHeadedTableHorizontalHeaderCell = ({ style, children }) => {
	const classes = useStyles();

	return (
		<TableCell component="div" style={style} className={classes.tableCell}>
			<Typography variant="h5" component="div" className={classes.cellContent}>
				{children}
			</Typography>
		</TableCell>
	);
};

TwoHeadedTableHorizontalHeaderCell.propTypes = {
	style: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

export default TwoHeadedTableHorizontalHeaderCell;
