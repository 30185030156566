import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	expandButton: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
		visibility: ({ hideExpand }) => (!hideExpand ? 'visible' : 'hidden'),
	},
	minimizeButton: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.main,
		visibility: ({ hideExpand }) => (!hideExpand ? 'visible' : 'hidden'),
	},
}));
