import { useRef, useState } from 'react';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';

// Import services
import myAxios from 'services/myAxiosFactory';

export const useUploadFileButton = ({ uploadUrl }) => {
	const [isLoading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const hiddenFileInput = useRef(null);
	const { message } = useMessage();
	const { t } = useTranslations();

	const handleOnButtonClick = () => {
		hiddenFileInput.current.click();
	};

	const handleOnInputChange = async (event) => {
		setLoading(true);
		setErrors([]);

		try {
			const fileUploaded = event.target.files[0];

			let formData = new FormData();
			formData.append('file', fileUploaded, fileUploaded.name);

			await myAxios().post(uploadUrl, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			message.success(t('common.messages.successfully_done'));
			setLoading(false);
		} catch (error) {
			const { status, data } = error?.response || {};

			if (status === 422) {
				const errors = data?.errors;
				const errorsConvertedToArray = errors && Object.values(errors).flat();

				setErrors(errorsConvertedToArray);
			}

			setLoading(false);
			message.error(t('common.messages.error_message'));
		} finally {
			event.target.value = '';
		}
	};

	return {
		handleOnInputChange,
		handleOnButtonClick,
		hiddenFileInput,
		isLoading,
		errors,
	};
};
