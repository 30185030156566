import React, { useState, createContext } from 'react';
import { node } from 'prop-types';

// Import utilities
import { useToggle } from 'components/utilities';

export const EditBookingProviderContext = createContext();

const EditBookingProvider = ({ children }) => {
	const { on, toggle } = useToggle();

	const [changeInitialValues, setChangeInitialValues] = useState(null);
	const [converted, setConverted] = useState(false);
	const [change, setChange] = useState(null);

	const setItem = ({ uuid }, values) => {
		change('item', {
			...values.item,
			uuid,
		});
	};

	return (
		<EditBookingProviderContext.Provider
			value={{
				on,
				toggle,
				setChange,
				setItem,
				converted,
				setConverted,
				changeInitialValues,
				setChangeInitialValues,
			}}
		>
			{children}
		</EditBookingProviderContext.Provider>
	);
};

EditBookingProvider.propTypes = {
	children: node,
};

export default EditBookingProvider;
