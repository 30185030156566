import { bool, string } from 'prop-types';

// Import utilities
import { useCommonFields, useProfile } from 'components/utilities';
import { useAdditionalFields } from './useAdditionalFields';
import { CHECKBOX_FIELDS } from './helpers';

export const AdditionalFields = ({ isAirportCheckboxDisabled, section }) => {
	const { trainStations, allTerminals, submitting, is_airport, values } =
		useAdditionalFields();
	const { renderFields } = useCommonFields();

	const { isAdmin } = useProfile();

	return CHECKBOX_FIELDS({
		isAirportCheckboxDisabled,
		is_airport,
		trainStations,
		allTerminals,
		submitting,
		section,
		values,
		isAdmin,
	}).map(renderFields);
};

AdditionalFields.propTypes = {
	isAirportCheckboxDisabled: bool,
	section: string,
};
