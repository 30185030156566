import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	CompanyFilter,
	DateRangeFilter,
	BaseSelectFilter,
} from 'components/elements';

// Import utils and helpers
import { useProfile } from 'components/utilities';
import { CANCELLATION_STATUS } from '../helpers';

export const FilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={3}>
			<BaseSelectFilter
				label="common.fields.cancellation_status"
				name="cancel_reason_status"
				options={CANCELLATION_STATUS}
			/>
			{isAdmin && (
				<DateRangeFilter
					nameFrom="pick_date"
					nameUntil="pick_date_to"
					label="common.fields.pick_date"
					combinedInputs
				/>
			)}
			{isAdmin && (
				<DateRangeFilter
					nameFrom="cancellation_date"
					nameUntil="cancellation_date_to"
					label="common.filters.cancellation_date"
					combinedInputs
				/>
			)}
			{isAdmin && (
				<CompanyFilter name="company_uuid" label="bookings.filters.company" />
			)}
		</Grid>
	);
};
