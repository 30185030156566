import i18n from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const SUSPENSION_DETAILS_FIELDS = ({ branches, suspensionCodes }) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'actual_return_branch_id',
		label: 'contract.create_contract_form.contract_details.return_branch',
		required: true,
		md: 4,
		options: branches,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'suspension_code',
		label: 'contract.suspend_contract_form.suspension_code',
		required: true,
		md: 4,
		options: suspensionCodes,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'operator_id',
		label: 'contract.create_contract_form.contract_details.operator_id',
		md: 4,
		removeUpAndDownArrows: true,
	},
];

export const RENT_STATUS_FIELDS = ({
	narrowedUsableConditions,
	availabilityStates,
	fuelAvailabilities,
	workingConditions,
	usableConditions,
	purityStates,
	classes,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.available_fuel',
		label: 'contract.close_contract_form.rent_status.available_fuel',
		md: 4,
		required: true,
		options: fuelAvailabilities,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ id }) =>
			i18n.t(`contract.close_contract_form.options.${id}`),
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'rent_status.odometer_reading',
		label: 'contract.create_contract_form.vehicle_details.odometer_reading',
		required: true,
		md: 4,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.fire_extinguisher',
		label: 'contract.create_contract_form.car_inspection.fire_extinguisher',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.first_aid_kit',
		label: 'contract.create_contract_form.car_inspection.first_aid_kit',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.safety_triangle',
		label: 'contract.create_contract_form.car_inspection.safety_triangle',
		md: 4,
		options: availabilityStates,
		required: true,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.tires',
		label: 'contract.create_contract_form.car_inspection.tires',
		md: 4,
		required: true,
		options: narrowedUsableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.spare_tire_tools',
		label: 'contract.create_contract_form.car_inspection.spare_tire_tools',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.spare_tire',
		label: 'contract.create_contract_form.car_inspection.spare_tire',
		md: 4,
		required: true,
		options: narrowedUsableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.ac',
		label: 'contract.create_contract_form.car_inspection.ac',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.radio_stereo',
		label: 'contract.create_contract_form.car_inspection.radio_stereo',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.screen',
		label: 'contract.create_contract_form.car_inspection.screen',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.car_seats',
		label: 'contract.create_contract_form.car_inspection.car_seats',
		md: 4,
		required: true,
		options: purityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.keys',
		label: 'contract.create_contract_form.car_inspection.keys',
		md: 4,
		required: true,
		options: workingConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.speedometer',
		label: 'contract.create_contract_form.car_inspection.speedometer',
		md: 4,
		required: true,
		options: workingConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'rent_status.notes',
		label: 'contract.create_contract_form.car_inspection.notes',
		multiline: true,
		rows: 4,
		className: classes.notes,
	},
];

export const PAYMENT_DETAILS_FIELDS = ({ paymentMethods }) => [
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.paid',
		label: 'contract.create_contract_form.payment_details.paid',
		required: true,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.discount',
		label: 'contract.create_contract_form.payment_details.discount_percentage',
		required: true,
		min: 0,
		max: 100,
		md: 4,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'payment_details.payment_method_code',
		label: 'contract.create_contract_form.payment_details.payment_method',
		md: 4,
		options: paymentMethods,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.spare_parts_cost',
		label: 'contract.suspend_contract_form.spare_parts_cost',
		min: 0,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.damage_cost',
		label: 'contract.suspend_contract_form.damage_cost',
		min: 0,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.oil_change_cost',
		label: 'contract.suspend_contract_form.oil_change_cost',
		min: 0,
		md: 4,
	},
];
