/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const TableActionsProviderContext = React.createContext();

export const TableActionsProvider = ({ children }) => {
	const [selectedParent, setSelectedParent] = useState(null);
	const [allExpandedData, setAllExpandedData] = useState(null);
	const [selectedChildren, setSelectedChildren] = useState([]);

	const handleExpandedRow = (cell) => {
		const { user_uuid: uuid } = cell.row.original;
		const isSameParent = selectedParent === uuid;
		setSelectedParent(isSameParent ? null : uuid);
		setAllExpandedData(isSameParent ? null : cell.row.original);
	};

	const handleSubCheckboxChange = ({
		e: {
			target: { checked },
		},
		cell,
	}) => {
		const item = cell.row.original;
		let selectedChildrenCopy = [...selectedChildren];
		if (checked) {
			selectedChildrenCopy.push(item);
		} else {
			_.remove(
				selectedChildrenCopy,
				({ item_uuid }) => item_uuid === item.item_uuid
			);
		}
		setSelectedChildren(selectedChildrenCopy);
	};

	const checkIfRowExpanded = (cells) =>
		!!cells.filter((cell) => selectedParent === cell.row.original.user_uuid)
			.length;

	const isIndeterminate =
		selectedChildren.length !==
			allExpandedData?.subRows?.filter(
				(row) => !row.is_settled && row.conditions_met
			)?.length &&
		!!selectedParent &&
		!!selectedChildren.length;

	return (
		<TableActionsProviderContext.Provider
			value={{
				handleExpandedRow,
				checkIfRowExpanded,
				selectedParent,
				setSelectedChildren,
				selectedChildren,
				handleSubCheckboxChange,
				allExpandedData,
				setAllExpandedData,
				isIndeterminate,
				setSelectedParent,
			}}
		>
			{children}
		</TableActionsProviderContext.Provider>
	);
};

TableActionsProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};
