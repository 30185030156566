export const COLUMNS_ACCESSORS = {
	ACTION: 'amount',
	ID: 'index',
};

export const columns = [
	{
		header: 'rewards_settlement.history.table.headers.id',
		accessor: COLUMNS_ACCESSORS.ID,
	},
	{
		header: 'rewards_settlement.history.table.headers.action',
		accessor: COLUMNS_ACCESSORS.ACTION,
	},
	{
		header: 'rewards_settlement.history.table.headers.user',
		accessor: 'creator_full_name',
	},
	{
		header: 'rewards_settlement.history.table.headers.date',
		accessor: 'created_at',
	},
];
