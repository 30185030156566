import { useFormState } from 'react-final-form';

// Import utilities and helpers
import { useAirportList, useTrainStationCollection } from 'queries';
import { useTranslations } from 'components/utilities';
import { EN } from 'helpers';

export const useAdditionalFields = () => {
	const { i18n } = useTranslations();
	const { values } = useFormState();

	const isEN = i18n.language === EN;

	const {
		location: { is_train_station, is_airport, lat, lng },
		submitting,
	} = values;

	const { data: airports } = useAirportList({ lat, lng });
	const { data: trainStations } = useTrainStationCollection({
		enabled: is_train_station,
	});

	const getTerminals = (airports) => {
		const convertTerminalName = ({ airportName, language }) => {
			const bracketIndex = airportName.indexOf('(');
			const cutAirportName = airportName.substring(0, bracketIndex - 1);
			const resultAirportName =
				bracketIndex >= 0 ? cutAirportName : airportName;

			return resultAirportName + ' - ' + language;
		};

		return airports.reduce((acc, airport) => {
			const airportName = airport.name[i18n.language];
			const terminals = airport.terminals
				.flat()
				.map(({ uuid, name: { en, ar } }) => ({
					uuid,
					name: convertTerminalName({ airportName, language: isEN ? en : ar }),
				}));
			return [...acc, ...terminals];
		}, []);
	};

	const allTerminals = getTerminals(airports);

	return {
		trainStations,
		allTerminals,
		submitting,
		is_airport,
		values,
	};
};
