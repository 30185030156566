import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { fetchUserFormResources, submitUserForm } from 'store/actions';

// Import utilities
import { FormRPC } from 'components/utilities';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import components
import { BaseDetails } from './components';

// Import styles
import useStyles from './styles';

export const UserForm = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchUserFormResources}
				submitFormAction={submitUserForm}
				isCardLayout={false}
				goBackPath={URLS.usersUrl}
				store={(store) => store.users.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<BaseDetails section="details" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
