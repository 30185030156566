import PHE from 'print-html-element';
import services from 'services/services';
import { printBookingUrl } from 'store/paths';

// Import helpers
import { onImagesLoad } from 'helpers';

export const printBooking = async ({ bookingUuid }) => {
	try {
		const response = await services.get(printBookingUrl(bookingUuid));
		const el = document.createElement('div');

		el.innerHTML = response.data;

		await onImagesLoad(el.querySelectorAll('img'));

		PHE.printHtml(response.data);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};
