import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import assets
import { LogoIcon } from 'assets/icons';

// Import styles
import { useStyles } from './styles';

// Import helpers
import { contactItems } from './helpers';

export const Footer = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Grid
			container
			className={classes.container}
			justifyContent="space-between"
		>
			<Grid item xs={4}>
				<Box className={classes.titleWrapper}>
					<Box className={classes.logoWrapper}>
						<LogoIcon className={classes.icon} />
					</Box>
					<Typography className={classes.heading}>
						{t('bookings.invoice_print.footer.title')}
					</Typography>
				</Box>
				<Typography> {t('bookings.invoice_print.footer.subtitle')} </Typography>
			</Grid>
			<Grid item xs="auto">
				<Typography className={classes.heading}>
					{t('bookings.invoice_print.footer.contact_heading')}
				</Typography>
				<Box className={classes.contactWrapper}>
					{contactItems.map(({ label, icon: Icon }) => (
						<Box key={label} className={classes.contactItem}>
							<Icon />
							<Typography> {label} </Typography>
						</Box>
					))}
				</Box>
			</Grid>
		</Grid>
	);
};
