// ********************  form  ********************

export const fetchProfileFormResourcesUrl = (itemId) =>
	itemId
		? `v2/supplier/business/manager/${itemId}/show`
		: '/v2/supplier/business/manager/profile';

export const submitProfileFormUrl = (isEdit, userUuid) =>
	isEdit
		? `/v2/supplier/business/manager/${userUuid}/update`
		: `v2/supplier/business/manager/create`;
