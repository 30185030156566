import { queryCache } from 'react-query';
import { format } from 'date-fns';
import {
	CUSTOMER_ORGANIZATIONS_QUERY,
	CUSTOMER_GENDERS_QUERY,
	EN,
	ORGANIZATION_SELECT_DEFAULT_VALUE,
	DEFAULT_DATE_FORMAT,
} from 'helpers';

export const convertSubmitData = ({ values, lng }) => {
	const {
		workplace: {
			organization: { name: organizationName },
		},
		details: {
			type: { type: customerType },
			gender: { name: genderName },
			birth_date,
			email,
			full_name,
			license,
			phone,
			phone_prefix,
		},
	} = values;

	const isEnglish = lng === EN;

	const isSelectedNone =
		organizationName.en === ORGANIZATION_SELECT_DEFAULT_VALUE;

	const newWorkplace = !isSelectedNone
		? convertWorkplace({ organizationName, isEnglish })
		: null;

	const newBirthDate =
		typeof birth_date !== 'string' ? formatDate(birth_date) : birth_date;

	const newGender = getGenderType({ genderName, isEnglish });

	const data = {
		workplace: !isSelectedNone ? newWorkplace.workplace : null,
		details: {
			birth_date: newBirthDate,
			type: { type: customerType },
			email,
			full_name,
			license,
			phone,
			phone_prefix,
			...newGender,
		},
	};

	return data;
};

const formatDate = (date) => (date ? format(date, 'dd/MM/yyyy') : null);

const convertWorkplace = ({ organizationName, isEnglish }) => {
	const {
		data: { data: organizations },
	} = queryCache.getQueryData(CUSTOMER_ORGANIZATIONS_QUERY);

	const organizationUuid = organizations.find(({ name }) => {
		const isOrganizationNameMatch = isEnglish
			? name.en === organizationName.en
			: name.ar === organizationName.ar;
		return isOrganizationNameMatch;
	}).uuid;

	const newWorkplace = {
		workplace: { organization: { uuid: organizationUuid } },
	};

	return newWorkplace;
};

const getGenderType = ({ genderName, isEnglish }) => {
	const {
		data: { data: genders },
	} = queryCache.getQueryData(CUSTOMER_GENDERS_QUERY);

	const newGenderType = genders.find(({ name }) => {
		const isGenderNameMatch = isEnglish
			? name.en === genderName.en
			: name.ar === genderName.ar;
		return isGenderNameMatch;
	}).type;

	const newGender = { gender: { type: newGenderType } };

	return newGender;
};

export const convertUpdateDriverLicenseSubmitData = (values) => ({
	...values,
	driver: {
		...values.driver,
		birth_date: format(new Date(values.driver.birth_date), DEFAULT_DATE_FORMAT),
	},
	license: {
		...values.license,
		expire_date: format(
			new Date(values.license.expire_date),
			DEFAULT_DATE_FORMAT
		),
	},
});
