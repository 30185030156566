import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	InputAdornment,
} from '@material-ui/core';

// Import components
import {
	OneTimePaymentField,
	PeriodPaymentsField,
	TextFieldAdapter,
} from 'components/elements';

//Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { PERIOD_FIELDS } from './helpers';

const ExtraServices = ({ className, section, ...rest }) => {
	const { t } = useTranslations();

	const {
		submitting,
		values,
		initialValues: {
			carInfo: {
				options: { unlimited_nett_price: initialUnlimitedPrice },
			},
		},
	} = useFormState();

	const {
		carInfo: {
			options: { is_unlimited, unlimited_nett_price },
		},
	} = values;

	return (
		<Card {...rest} className={clsx(className)}>
			<CardHeader title={t('cars.form.extra_services.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={6} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="number"
							label={t('cars.form.extra_services.free_kms')}
							name={`carInfo.${section}.free_kms`}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required
							inputProps={{ min: 0 }}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="number"
							label={t('cars.form.extra_services.price_for_extra_km')}
							name={`carInfo.${section}.extra_km_nett_price`}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required
							inputProps={{ step: 0.01, min: 0 }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{t('currency.sar')}
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<OneTimePaymentField
						initialInputValue={initialUnlimitedPrice}
						inputValue={unlimited_nett_price}
						inputName={`carInfo.${section}.unlimited_nett_price`}
						checkboxLabelText="cars.form.extra_services.unlimited_km"
						checkboxName={`carInfo.${section}.is_unlimited`}
						isOpen={is_unlimited}
					/>
				</Grid>
				{PERIOD_FIELDS(section, values).map(
					({ label, checkboxName, fields, isOpen }) => (
						<Grid key={label} item xs={12}>
							<PeriodPaymentsField
								labelText={label}
								checkboxName={checkboxName}
								fields={fields}
								isOpen={isOpen}
							/>
						</Grid>
					)
				)}
			</CardContent>
		</Card>
	);
};

ExtraServices.propTypes = {
	className: string,
	section: string.isRequired,
};

export default ExtraServices;
