// Import utilities
import { usePrintProvider } from 'components/context';

// Import helpers
import { PARTIAL_PAYMENT_TRANSACTION_GATEWAY } from 'helpers';

export const useCalculations = () => {
	const {
		data: {
			options,
			partial_payment_transactions,
			item: {
				total_discount_gross_price,
				total_base_gross_price,
				total_base_vat_price,
				base_nett_price,
				total_days,
			},
		},
	} = usePrintProvider();

	const discountPrice =
		Number(total_base_gross_price) - Number(total_discount_gross_price);

	const fixedDiscountPrice = discountPrice.toFixed(2);

	const isPartialPayments = partial_payment_transactions.length;

	const walletAmount = partial_payment_transactions?.find(
		({ gateway }) => gateway === PARTIAL_PAYMENT_TRANSACTION_GATEWAY.WALLET
	)?.amount_gross;

	const total = base_nett_price * total_days;

	const rowValues = {
		e_wallet: isPartialPayments ? walletAmount : null,
		sub_total: total_base_gross_price,
		discount: fixedDiscountPrice,
		vat: total_base_vat_price,
	};

	return {
		base_nett_price,
		total_days,
		rowValues,
		options,
		total,
	};
};
