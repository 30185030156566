import React from 'react';
import { useSelector } from 'react-redux';
import { string, object, array, oneOfType, bool } from 'prop-types';
import get from 'lodash/get';
import { Typography } from '@material-ui/core';

// Import components
import PreviewField from './PreviewField';

// Import selectors
import { previewBookingSelector } from 'store/selectors';
import { useTranslations } from 'components/utilities';

const DefaultPreviewField = ({
	accessor,
	label,
	color,
	customData,
	withCurrency,
	isHidden = false,
}) => {
	const booking = useSelector(previewBookingSelector);
	const { t } = useTranslations();

	if (isHidden) return null;

	return (
		<PreviewField label={label}>
			<Typography color={color || 'initial'} variant={color ? 'h6' : 'body1'}>
				{`${get(customData || booking, accessor) || ''} ${
					withCurrency ? t('currency.sar') : ''
				}`}
			</Typography>
		</PreviewField>
	);
};

DefaultPreviewField.propTypes = {
	customData: oneOfType([array, object]),
	withCurrency: bool,
	accessor: string,
	isHidden: bool,
	label: string,
	color: string,
};

export default DefaultPreviewField;
