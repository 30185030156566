import { useSelector } from 'react-redux';

// Import selectors
import { bookingBillingsSelector } from 'store/selectors';

const useOriginalDropDatePreviewField = () => {
	const billings = useSelector(bookingBillingsSelector);
	let dropDate = null;

	if (billings.length > 1) {
		dropDate = billings[billings.length - 1].drop_date;
	}

	return {
		dropDate,
	};
};

export default useOriginalDropDatePreviewField;
