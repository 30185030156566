import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	buttonText: {
		textTransform: 'none',
	},
	root: {
		boxShadow: 'none',
		background: 'none',
	},
	alignButton: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	icon: {
		width: 20,
		height: 20,
		display: 'flex',
		alignItems: 'center',
	},
	iconMarginLeft: {
		marginLeft: 10,
	},
	iconMarginRight: {
		marginRight: 10,
	},
}));

export default useStyles;
