import React from 'react';
import PropTypes from 'prop-types';

// Import components
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { BASE_DETAILS_FIELDS } from './helpers';

const BaseDetails = ({ section, ...rest }) => {
	const { renderFields } = useCommonFields();

	const { t } = useTranslations();

	return (
		<Card {...rest}>
			<CardHeader title={t('kiosk.airports.form.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{BASE_DETAILS_FIELDS(section).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

BaseDetails.propTypes = {
	section: PropTypes.string.isRequired,
};

export default BaseDetails;
