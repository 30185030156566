export const INITIAL_VALUES = {
	details: {
		name: {
			ar: '',
			en: '',
		},
		type: 'arrival',
		slug: '',
	},
	airport: {
		terminals: []
	},
};
