import React from 'react';
import { object, oneOfType, string, number } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

export const AmountTableCell = ({ cell, amount }) => {
	const { t } = useTranslations();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{amount ? `${t('currency.sar')} ${amount}` : '-'}
		</TableCell>
	);
};

AmountTableCell.propTypes = {
	cell: object.isRequired,
	amount: oneOfType([string, number]),
};
