// ********************  table  ********************

export const fetchRatingReasonsUrl = (queryParams) =>
	`/v2/rating-reason/list${queryParams}`;

export const updateActiveRatingReasonUrl = (id, type) =>
	`/v2/rating-reason/${id}/${type}`;

export const deleteRatingReasonUrl = (id) => `/v2/rating-reason/${id}/remove`;

// ********************  form  ********************

export const showRatingReasonUrl = (itemId) =>
	`/v2/rating-reason/${itemId}/show`;

export const compensationTypesUrl = '/v2/compensation-formula-listing';

export const submitRatingReasonsFormUrl = (itemId, isEdit) =>
	`/v2/rating-reason/${isEdit ? `${itemId}/update` : 'create'}`;
