import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Import utilities
import { useProfile, useTranslations } from 'components/utilities';
import { previewBookingSelector } from 'store/selectors';

// Import components
import { Card, MoreInfoButton } from 'components/elements';
import PreviewFields from '../PreviewFields';

// Import helpers
import { FIELDS_CUSTOMER } from '../../helpers';
import { replaceCustomerPreviewUrl } from 'helpers';

const CustomerCard = () => {
	const { customer } = useSelector(previewBookingSelector);
	const { isCorporateCoordinator } = useProfile();
	const { t } = useTranslations();

	return (
		<Card
			title={t('bookings.preview_booking.customer.title')}
			lg={6}
			actions={
				!isCorporateCoordinator && (
					<MoreInfoButton
						component={Link}
						to={replaceCustomerPreviewUrl(customer?.uuid)}
					/>
				)
			}
		>
			<PreviewFields fields={FIELDS_CUSTOMER} />
		</Card>
	);
};

export default CustomerCard;
