import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	strong: {
		color: theme.palette.primary.main,
	},
	text: {
		textTransform: 'uppercase',
		fontSize: 11,
		fontWeight: 700,
	},
}));

export default useStyles;
