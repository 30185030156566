import * as types from 'store/types';
import services from 'services/services';
import { fetchFaqFormResourcesUrl, submitFaqFormUrl } from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import { convertSubmitValues, getFormattedData } from './helpers';

export const fetchFaqFormResources =
	({ itemId, isEdit, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.FAQ_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			const { data } =
				(isEdit &&
					(await services.get(
						fetchFaqFormResourcesUrl(itemId),
						cancelToken
					))) ||
				{};

			const formatedDate = isEdit ? getFormattedData(data) : {};

			dispatch({
				type: types.FAQ_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: formatedDate,
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.FAQ_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitFaqForm =
	({ values, itemId, callbackSuccess, errorCallback }) =>
	async () => {
		const convertedValues = convertSubmitValues(values);
		try {
			await services[itemId ? 'put' : 'post'](
				submitFaqFormUrl(itemId),
				convertedValues
			);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
