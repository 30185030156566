import React from 'react';
import { Field, useFormState } from 'react-final-form';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { TextFieldAdapter } from 'components/elements';
import { Card, CardHeader, Divider, CardContent } from '@material-ui/core';

const FormContent = () => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	return (
		<Card>
			<CardHeader title={t('customers.whitelist.form.title')} />
			<Divider />
			<CardContent>
				<Field
					component={TextFieldAdapter}
					disabled={submitting}
					label={t('customers.whitelist.table.phone_number')}
					variant="outlined"
					name="phone"
					color="primary"
					margin="dense"
					type="text"
					fullWidth
					required
				/>
			</CardContent>
		</Card>
	);
};

export default FormContent;
