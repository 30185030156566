import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	cell: {
		paddingRight: 0,
		paddingLeft: 0,
	},
	checkbox: {
		padding: 0,
		visibility: ({ hideSelect }) => (!hideSelect ? 'visible' : 'hidden'),
	},
}));
