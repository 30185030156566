import { LOCAL_STORAGE_CHOOSABLE_ROLES } from 'helpers';
import ls from 'localstorage-slim';
import services from 'services/services';
import {
	choosableRolesUrl,
	fetchCarsUrl,
	updateActiveCarUrl,
	exportCarPlatesFileUrl,
} from 'store/paths';

export const fetchCars =
	({ options, queryParams, itemId, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			// Get response
			const { data } = await services.post(
				fetchCarsUrl(itemId, queryParams),
				body,
				cancelToken
			);

			const localStorageChoosableRoles = ls.get(LOCAL_STORAGE_CHOOSABLE_ROLES);
			const choosableRoles =
				!localStorageChoosableRoles && (await services.get(choosableRolesUrl));

			if (!localStorageChoosableRoles) {
				ls.set(LOCAL_STORAGE_CHOOSABLE_ROLES, choosableRoles.data.data, {
					ttl: 14 * 24 * 60 * 60,
				});
			}

			const roles = localStorageChoosableRoles || choosableRoles.data.data;
			return Promise.resolve({ data, rolesData: roles });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const exportCarPlatesFile =
	({ cancelToken }) =>
	async () => {
		try {
			const { data } = await services.post(
				exportCarPlatesFileUrl,
				{},
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActiveCar =
	({ isActive, id }) =>
	async () => {
		await services.put(updateActiveCarUrl(id, isActive));
	};
