import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => {
	const getColor = (isActive) =>
		isActive ? palette.primary.main : palette.error.main;

	return {
		toggleButton: {
			color: ({ isActive }) => getColor(isActive),
			backgroundColor: ({ isActive }) => alpha(getColor(isActive), 0.1),
		},
	};
});
