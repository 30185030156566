import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers
import { getCellContent } from './helpers';

export const LateAndDeliveryTableCell = ({ cell }) => {
	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{getCellContent(cell)}
		</TableCell>
	);
};

LateAndDeliveryTableCell.propTypes = {
	cell: object.isRequired,
};
