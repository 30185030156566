import React from 'react';
import { useForm } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

// Import utils
import { useCommonFields, useTranslations } from 'components/utilities';
import { EDIT_CUSTOMER_FIELDS } from './helpers';
import { usePhonePrefixes } from 'queries';
import { URLS } from 'components/routes';

// Import components
import { ButtonProgress } from 'components/elements';
import { bool } from 'prop-types';

export const FormContent = ({ isButtonLoading }) => {
	const { data: prefixes } = usePhonePrefixes();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const history = useHistory();
	const { change } = useForm();

	const goBack = () => history.push(URLS.corporateOverviewUrl);
	const handleOnFileUploader = (file) => {
		change('license', file);
	};

	return (
		<Grid container spacing={3}>
			{EDIT_CUSTOMER_FIELDS({ prefixes, handleOnFileUploader }).map(
				renderFields
			)}
			<Grid item xs={12}>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					spacing={2}
				>
					<Grid item>
						<Button variant="outlined" color="primary" onClick={goBack}>
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							isLoading={isButtonLoading}
							disabled={isButtonLoading}
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
						>
							{t('common.buttons.save')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isButtonLoading: bool,
};
