import { useForm, useFormState } from 'react-final-form';
import { useTranslations } from '../index';
import { useState } from 'react';
import Geocode from 'react-geocode';
import Bugsnag from '@bugsnag/js';

const useMapLocation = ({ section, includeIsAirport = false }) => {
	const { submitting, values } = useFormState();
	const { change } = useForm();
	const { lat, lng } = values.location;

	const center = { lat: lat || 0, lng: lng || 0 };

	const { t } = useTranslations();

	const [markerLocation, setMarkerLocation] = useState(center);
	const [autocompleteValue, setAutocompleteValue] = useState({});
	const [isAirportCheckboxDisabled, setIsAirportCheckboxDisabled] =
		useState(false);

	const changeFields = ({
		lat,
		lng,
		address,
		city,
		country,
		isAirportByGoogle = false,
		placeId,
	}) => {
		isAirportByGoogle &&
			includeIsAirport &&
			change(`${section}.is_airport`, true);

		change(`${section}.address`, address);
		change(`${section}.city`, city);
		change(`${section}.country`, country);
		change(`${section}.lat`, lat);
		change(`${section}.lng`, lng);
		change(`${section}.place_id`, placeId);
	};

	const findAddress = (address_components, firstType, secondType) =>
		address_components.find((element) =>
			element.types.every((type) => type === firstType || type === secondType)
		);

	const getCity = (addressComponents) =>
		findAddress(addressComponents, 'locality', 'political')?.long_name;

	const getCountry = (addressComponents) =>
		findAddress(
			addressComponents,
			'country',
			'political'
		)?.short_name?.toLowerCase();

	const getIsAirport = (types) =>
		types.some((type) => type.toLowerCase().includes('airport'));

	const getLocationData = (addressComponents, geometry) => {
		const city = getCity(addressComponents);
		const country = getCountry(addressComponents);

		const lat = geometry ? geometry.location.lat() : null;
		const lng = geometry ? geometry.location.lng() : null;

		return { city, country, lat, lng };
	};

	const handleChange = () => {
		if (autocompleteValue !== null) {
			const place = autocompleteValue.getPlace();
			const {
				geometry,
				formatted_address: address,
				address_components,
				types,
				place_id,
			} = place;

			const isAirportByGoogle = getIsAirport(types);
			setIsAirportCheckboxDisabled(isAirportByGoogle);

			const locationData = getLocationData(address_components, geometry);
			const { lat, lng, city } = locationData;

			if (city.toLowerCase().includes('airport')) {
				Bugsnag.notify('error', function (event) {
					event.addMetadata('handleChange', address_components);
				});
			}
			changeFields({
				...locationData,
				address,
				isAirportByGoogle,
				placeId: place_id,
			});
			setMarkerLocation({ lat, lng });
		}
	};

	const handleDragEnd = (evt) => {
		const {
			latLng: { lat, lng },
		} = evt;

		Geocode.fromLatLng(lat(), lng()).then((response) => {
			const {
				address_components,
				formatted_address: address,
				types,
				place_id,
			} = response.results[0];
			const { city, country } = getLocationData(address_components);

			const isAirportByGoogle = getIsAirport(types);
			setIsAirportCheckboxDisabled(isAirportByGoogle);

			if (city.toLowerCase().includes('airport')) {
				Bugsnag.notify('error', function (event) {
					event.addMetadata('handleDragEnd', address_components);
				});
			}

			changeFields({
				lat: lat(),
				lng: lng(),
				address,
				city,
				country,
				isAirportByGoogle,
				placeId: place_id,
			});
		});
	};

	const fieldDisabled = (field) => (!field?.disabled ? submitting : true);

	return {
		t,
		markerLocation,
		setAutocompleteValue,
		isAirportCheckboxDisabled,
		onChange: handleChange,
		onDragEnd: handleDragEnd,
		fieldDisabled,
	};
};

export default useMapLocation;
