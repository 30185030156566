import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	loadingOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 9999,
		width: '100%',
		height: '100%',
		background: 'rgba(0, 0, 0, 0.03)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default useStyles;
