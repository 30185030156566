// Import helpers
import { DELIVERY_STATUS } from 'helpers';

export const useStepper = ({ deliveryData }) => {
	const { PENDING, CANCELLED } = DELIVERY_STATUS;

	const { states } = deliveryData || {};

	const getFilteredSteps = () =>
		states?.filter(({ state }) => state !== PENDING);

	const steps = getFilteredSteps();

	const isDeliveryCancelled = (state) => state === CANCELLED;

	const getCancelReason = () =>
		steps?.find(({ state }) => state === CANCELLED)?.description;

	const splitDate = (date) => date.split(' ');

	const cancelReasonIndex = steps?.findIndex(
		({ state }) => state === CANCELLED
	);

	const cancelReason = getCancelReason();

	const activeSteps = steps?.length;

	return {
		isDeliveryCancelled,
		cancelReasonIndex,
		cancelReason,
		activeSteps,
		splitDate,
		steps,
	};
};
