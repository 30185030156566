import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { GET_CONTRACT_DRAFT_DATA_QUERY } from 'helpers';

export const useContractDraftData = ({ uuid, enabled }) => {
	const path = `/v2/booking/${uuid}/econtract`;

	const { data, isFetching, isError } = useQuery(
		[GET_CONTRACT_DRAFT_DATA_QUERY],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError };
};
