import React from 'react';
import { string, func } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import components
import { CloseButton } from 'components/elements';

const CompensationDialogTitle = ({ title, onClose }) => {
	return (
		<Grid
			container
			spacing={2}
			justifyContent="space-between"
			alignItems="center"
		>
			<Grid item>
				<Typography variant="h5">{title}</Typography>
			</Grid>
			<Grid item>
				<CloseButton onClick={onClose} />
			</Grid>
		</Grid>
	);
};

CompensationDialogTitle.propTypes = {
	title: string.isRequired,
	onClose: func.isRequired,
};

export default CompensationDialogTitle;
