import React from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	IconButton,
	Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Import components
import { LocationAutocomplete, Map } from './components';
import { GoogleMapsScriptLoader } from 'components/elements';
import useDeliveryMap from './useDeliveryMap';

// Import styles
import useStyles from './styles';

const DeliveryMap = ({ open, onClose }) => {
	const classes = useStyles();

	const { location, setLocation } = useDeliveryMap();

	return (
		<GoogleMapsScriptLoader>
			<Dialog open={open} onClose={onClose} fullScreen>
				<DialogTitle>
					<Grid container wrap="nowrap" spacing={2}>
						<Grid item xs={12}>
							<LocationAutocomplete value={location} onChange={setLocation} />
						</Grid>
						<Grid item xs="auto">
							<IconButton onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>

				<Divider />
				<DialogContent className={classes.dialogContent}>
					{location && <Map location={location} onChange={setLocation} />}
				</DialogContent>
			</Dialog>
		</GoogleMapsScriptLoader>
	);
};

DeliveryMap.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default DeliveryMap;
