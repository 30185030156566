import React from 'react';
import { List } from '@material-ui/core';

// Import helpers
import { items } from './helpers';

// Import components
import { InfoListItemFactory } from './components';

const InfoList = () => {
    return (
        <List>
            {items.map(item => (
                <InfoListItemFactory key={item.accessor} {...item} />
            ))}
        </List>
    )
};

export default InfoList;