// Import helpers
import { convertDate, DEFAULT_DATE_FORMAT } from 'helpers';

const getParsedDates = ({ dateFrom, dateTo, paramsParsed }) => {
	const datesValues = paramsParsed.get(`columns[${dateFrom}][search][value]`);
	if (datesValues) {
		const parsedDates = JSON.parse(datesValues);
		paramsParsed.set(
			`columns[${dateFrom}][search][value]`,
			convertDate(parsedDates[0], DEFAULT_DATE_FORMAT)
		);
		paramsParsed.set(
			`columns[${dateTo}][search][value]`,
			convertDate(parsedDates[1], DEFAULT_DATE_FORMAT)
		);
	}
};

export const getFormattedParamsDates = ({
	params,
	pickFromCol,
	pickToCol,
	rewardFromCol,
	rewardToCol,
}) => {
	const paramsParsed = new URLSearchParams(params);

	getParsedDates({
		dateFrom: pickFromCol,
		dateTo: pickToCol,
		paramsParsed,
	});
	getParsedDates({
		dateFrom: rewardFromCol,
		dateTo: rewardToCol,
		paramsParsed,
	});

	return `?${paramsParsed.toString()}`;
};
