import React from 'react';

// Import utilities
import { FormRPC, useProfile, useTranslations } from 'components/utilities';
import usePublicHolidayForm from './usePublicHolidayForm';
import {
	fetchPublicHolidaysFormResources,
	submitPublicHolidaysForm,
} from 'store/actions';

// Import components
import { NotificationAlert } from 'components/elements';
import { CompanySelect } from './components/CompanySelect';
import { DateFields } from './components';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { validationSchema } from './validation';

// Import store
import { publicHolidaysFormSelector } from 'store/selectors';
import { yupValidator } from 'helpers';

const PublicHolidaysForm = () => {
	const { t } = useTranslations();
	const { handleToggleAlert, callbackSuccess, showAlert } =
		usePublicHolidayForm();

	const { isAdmin, companyUuid } = useProfile();

	return (
		<FormRPC
			fetchFormResAction={(options) =>
				fetchPublicHolidaysFormResources({ ...options, isAdmin, companyUuid })
			}
			submitFormAction={submitPublicHolidaysForm}
			store={publicHolidaysFormSelector}
			title={t('public_holidays.form.title')}
			initialValues={INITIAL_VALUES}
			callbackFormSuccess={callbackSuccess}
			validation={(values) => yupValidator({ values, validationSchema })}
		>
			{() => (
				<>
					<NotificationAlert
						type="success"
						message={t('public_holidays.form.holidays_saved')}
						open={showAlert}
						onClose={handleToggleAlert}
						title=""
					/>
					<CompanySelect />
					<DateFields />
				</>
			)}
		</FormRPC>
	);
};

export default PublicHolidaysForm;
