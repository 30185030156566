import { useForm, useFormState } from 'react-final-form';
import { useEffect } from 'react';

export const useExtraServices = ({ section }) => {
	const {
		submitting,
		values: {
			options: {
				car_delivery,
				driver,
				is_child_seat,
				is_delivery_at_airport,
				is_delivery_drop,
			},
			location: { airport },
			service_type: { digital_branch },
		},
	} = useFormState();

	const { change } = useForm();

	const changeDeliveryDropWithDelivery = (event) =>
		change(`${section}.is_delivery_drop`, event.target.checked);

	useEffect(() => {
		if (digital_branch) {
			change('options.car_delivery', true);
			change('options.is_delivery_drop', true);
		}
		// eslint-disable-next-line
	}, [digital_branch, car_delivery, is_delivery_drop]);

	return {
		changeDeliveryDropWithDelivery,
		is_child_seat,
		car_delivery,
		submitting,
		driver,
		is_delivery_at_airport,
		airport,
	};
};
