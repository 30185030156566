import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	popover: {
		width: 260,
		padding: 30,
		borderRadius: 24,
	},
	image: {
		width: 64,
		height: 64,
	},
	fullName: {
		color: theme.palette.text.primary,
		textTransform: 'capitalize',
		fontWeight: 600,
		fontSize: 18,
	},
	role: {
		color: theme.palette.text.secondary,
		textTransform: 'capitalize',
		fontWeight: 500,
		fontSize: 13,
	},
	line: {
		margin: '30px 0 22px',
	},
	wrapper: {
		margin: '16px 0',
	},
	signOutIcon: {
		marginRight: 19,
	},
	editIcon: {
		marginRight: 11,
	},
}));

export default useStyles;
