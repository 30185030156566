import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils
import { getPaymentDay } from 'helpers';

export const PaymentDayTableCell = ({ cell }) => {
	const { days_to_payment: daysToPayment } = cell.row.original;

	const daysToPaymentText = getPaymentDay(daysToPayment);

	return <TableCell {...cell.getCellProps()}>{daysToPaymentText}</TableCell>;
};

PaymentDayTableCell.propTypes = {
	cell: object.isRequired,
};
