export const COLUMNS_ACCESSORS = {
	CORPORATE_NAME: 'corporate_name',
	ACTION: 'action',
};

export const columns = [
	{
		Header: 'table.headers.corporate_name',
		accessor: COLUMNS_ACCESSORS.CORPORATE_NAME,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.coordinator_name',
		accessor: 'coordinator_name',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.email',
		accessor: 'email',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.number',
		accessor: 'number',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.action',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
	},
];
