import React from 'react';
import { func, number, array } from 'prop-types';
import { Select, MenuItem, Typography, Box, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Trans } from 'react-i18next';

// Import utilities
import { useTranslations } from 'components/utilities';
import useTablePagination from './useTablePagination';

// Import styles
import { useStyles } from './styles';

const TablePagination = ({
	pageOptions,
	pageIndex,
	pageSize,
	setPageSize,
	rowsPerPageOptions,
	gotoPage,
	recordsFiltered,
	currentPageRowsCount,
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { renderItem, onChangeSize, onPaginationChange } = useTablePagination({
		setPageSize,
		gotoPage,
	});

	return (
		<Grid container justifyContent="space-between" className={classes.wrapper}>
			<Grid item>
				{!!currentPageRowsCount && (
					<Typography component="span" variant="subtitle2">
						<Trans
							i18nKey="table.controllers.info_text"
							currentPageRowsCount={currentPageRowsCount}
							recordsFiltered={recordsFiltered}
						>
							Showing <strong>{{ currentPageRowsCount }} items</strong> out of{' '}
							<strong>{{ recordsFiltered }} results</strong> found
						</Trans>
					</Typography>
				)}
			</Grid>
			<Grid item className={classes.paginationActions}>
				<Box display="flex" gridGap={10} alignItems="center">
					<Typography component="span" variant="subtitle2">
						{t('table.controllers.rows_per_page')}
					</Typography>
					<Select
						className={classes.select}
						value={pageSize}
						onChange={onChangeSize}
					>
						{rowsPerPageOptions.map((pageSize) => (
							<MenuItem key={pageSize} value={pageSize}>
								<Typography variant="subtitle2">{pageSize}</Typography>
							</MenuItem>
						))}
					</Select>
				</Box>
				<Box>
					<Pagination
						count={pageOptions.length}
						page={pageIndex + 1}
						onChange={onPaginationChange}
						color="primary"
						shape="rounded"
						showFirstButton
						showLastButton
						renderItem={renderItem}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};

TablePagination.propTypes = {
	gotoPage: func.isRequired,
	pageOptions: array.isRequired,
	pageIndex: number.isRequired,
	pageSize: number.isRequired,
	setPageSize: func.isRequired,
	rowsPerPageOptions: array.isRequired,
	recordsFiltered: number.isRequired,
	currentPageRowsCount: number.isRequired,
};

export default TablePagination;
