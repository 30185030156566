import { TextFieldAdapter } from 'components/elements';

export const DISCOUNT_DETAILS_FIELDS = [
	{
		component: TextFieldAdapter,
		type: 'number',
		label: 'discounts.form.details.discount',
		name: 'discount',
		max: 100,
		md: 6,
		xs: 12,
	},
	{
		component: TextFieldAdapter,
		type: 'number',
		label: 'discounts.form.details.max_discount',
		name: 'max_discount',
		md: 6,
		xs: 12,
	},
];
