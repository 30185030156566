import React from 'react';
import { useSelector } from 'react-redux';
import { string, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { Grid } from '@material-ui/core';

// Import components
import {
	TextFieldLabelAdapter,
	ImagePreviewLabelAdapter,
} from 'components/elements';

// Import styles
import { useStyles } from './styles';

// Import helpers
import { tokenSelector } from 'store/selectors';

const PreviewField = ({ name, label, labelWidth, isDriverLicense }) => {
	const classes = useStyles();

	const {
		item: {
			details: {
				license: { license_exists, file_path },
			},
		},
	} = useSelector((store) => store.customers.preview);

	const token = useSelector(tokenSelector);

	const imgUrl = `${file_path}${token ? '?token=' + token : ''}`;

	return (
		<Grid key={name} item md={6}>
			{isDriverLicense ? (
				license_exists && (
					<Field
						component={ImagePreviewLabelAdapter}
						label={label}
						imgUrl={imgUrl}
						name={name}
					/>
				)
			) : (
				<Field
					component={TextFieldLabelAdapter}
					type="text"
					label={label}
					name={name}
					margin="dense"
					variant="outlined"
					isDisabled={true}
					isBorder={false}
					disabled={true}
					labelWidth={labelWidth}
					className={classes.field}
				/>
			)}
		</Grid>
	);
};

PreviewField.propTypes = {
	name: string.isRequired,
	label: string.isRequired,
	isDriverLicense: bool.isRequired,
	labelWidth: string,
};

export default PreviewField;
