import React from 'react';
import { previewBookingSelector } from 'store/selectors';
import { useSelector } from 'react-redux';

// Import components
import UrgentReasonsHistoryRow from './UrgentReasonsHistoryRow';

const UrgentReasonsHistoryBody = () => {
	const { urgentReasons } = useSelector(previewBookingSelector);

	if (!urgentReasons) {
		return null;
	}

	return (
		<>
			{urgentReasons.map((row) => (
				<UrgentReasonsHistoryRow key={row.uuid} row={row} />
			))}
		</>
	);
};

export default UrgentReasonsHistoryBody;
