import React from 'react';
import { array } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import utils
import { useCommonFields, useMediaQuerySizes } from 'components/utilities';

export const SectionContent = ({ fields }) => {
	const { renderFields } = useCommonFields();
	const { isTabletSize } = useMediaQuerySizes();

	return (
		<Grid container spacing={isTabletSize ? 1 : 4}>
			{fields.map(renderFields)}
		</Grid>
	);
};

SectionContent.propTypes = {
	fields: array,
};
