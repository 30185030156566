import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	closeButton: {
		backgroundColor: theme.palette.primary.light,
	},
	closeIcon: {
		fontSize: 15,
	},
	elmLogo: {
		maxHeight: '30px',
		transform: 'translateY(10px)',
	},
}));
