import React from 'react';
import {
	Box,
	Typography,
	Dialog,
	DialogActions,
	Grid,
} from '@material-ui/core';
import { bool, string, func } from 'prop-types';

// Import components
import { CloseButton, ButtonProgress } from 'components/elements';
import { Import } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useLeasingImportExportDialog } from './useLeasingImportExportDialog';

// Import styles
import { useStyles } from '../../ActionButtonsToolbar.styles';

export const LeasingImportExportDialog = ({
	isDialogOpen,
	handleClose,
	officeId,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const {
		isExportLoading,
		isImportLoading,
		hiddenFileInputRef,
		handleExport,
		handleImportButtonClick,
		handleImportInputChange,
	} = useLeasingImportExportDialog({
		officeId,
	});

	return (
		<Dialog
			open={isDialogOpen}
			onClose={handleClose}
			aria-labelledby="upload-leasing-car-labelled"
			fullWidth
		>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				className={classes.titleWrapper}
			>
				<Typography
					variant="h5"
					id="upload-leasing-car-labelled"
					className={classes.modalTitle}
				>
					{t('offices.table.headers.upload_cars')}
				</Typography>

				<Grid item className={classes.closeIconWrapper}>
					<CloseButton onClick={handleClose} />
				</Grid>
			</Grid>

			<DialogActions>
				<Box className={classes.actionsWrapper}>
					<Grid container spacing={2} justifyContent="flex-end">
						<Import
							handleImportButtonClick={handleImportButtonClick}
							ref={hiddenFileInputRef}
							handleImportInputChange={handleImportInputChange}
							isLoading={isImportLoading}
						/>

						<Grid item>
							<ButtonProgress
								onClick={handleExport}
								isLoading={isExportLoading}
								disabled={isExportLoading}
								variant="contained"
								color="primary"
							>
								{t('common.buttons.export')}
							</ButtonProgress>
						</Grid>
					</Grid>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

LeasingImportExportDialog.propTypes = {
	isDialogOpen: bool.isRequired,
	handleClose: func.isRequired,
	officeId: string.isRequired,
};
