import services from 'services/services';
import {
	updateActiveTerminalUrl,
	fetchTerminalsUrl,
	deleteTerminalUrl,
} from 'store/paths';

export const fetchTerminals = ({
	options,
	cancelToken,
	itemId,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		const { data } = await services.post(
			fetchTerminalsUrl(itemId),
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveTerminal = ({ isActive, id }) => async () => {
	await services.put(updateActiveTerminalUrl(id, isActive));
};

export const deleteTerminal = ({ id }) => async () => {
	await services.delete(deleteTerminalUrl(id));
};
