import React from 'react';
import { DialogTitle, Typography, Box } from '@material-ui/core';
import { string } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

const Title = ({ userName }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<DialogTitle id="alert-dialog-slide-title">
			<Typography className={classes.dialogHeader} align="center">
				{t('company_managers.modal.assign_offices')}
			</Typography>

			<Typography variant="body1" align="center">
				<Box component="span">
					{t('company_managers.modal.before_username')}
				</Box>
				<Box component="strong" className={classes.strong}>
					{userName}
				</Box>
				<Box component="span">{t('company_managers.modal.after_username')}</Box>
			</Typography>
		</DialogTitle>
	);
};

Title.propTypes = {
	userName: string.isRequired,
};

export default Title;
