import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import utilities
import { useDescriptionTableCell } from './useDescriptionTableCell';

const DescriptionTableCell = ({ cell }) => {
	const {
		assignedByLabel,
		markedByLabel,
		reasonName,
		markedBy,
		status,
		uuid,
	} = useDescriptionTableCell(cell);

	return (
		<TableCell key={uuid}>
			<Grid container>
				<Grid item xs={12}>
					<Typography noWrap variant="h5">
						{reasonName}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography noWrap variant="subtitle1">
						{assignedByLabel}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography noWrap variant="subtitle1">
						{markedBy ? markedByLabel : status}
					</Typography>
				</Grid>
			</Grid>
		</TableCell>
	);
};

DescriptionTableCell.propTypes = {
	cell: PropTypes.object,
};

export default DescriptionTableCell;
