import React from 'react';

// Import components
import { DashboardContent } from './DashboardContent';
import { FiltersProvider } from './context';

export const Dashboard = () => {
	return (
		<FiltersProvider>
			<DashboardContent />
		</FiltersProvider>
	);
};
