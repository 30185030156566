import services from 'services/services';
import {
	fetchKiosksUrl,
	updateActiveKioskUrl,
	deleteKioskUrl,
} from 'store/paths';

export const fetchKiosks = ({ options, cancelToken }) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		const { data } = await services.post(fetchKiosksUrl, body, cancelToken);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveKiosk = ({ isActive, id }) => async () => {
	await services.put(updateActiveKioskUrl(isActive, id));
};

export const deleteKiosk = ({ id }) => async () => {
	await services.delete(deleteKioskUrl(id));
};
