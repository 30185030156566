import React from 'react';
import { string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import logo from 'assets/images/telgani_logo.png';

// Import translations
import { useTranslations } from 'components/utilities';

//Import styles
import useStyles from './styles';

const Header = ({ bookingNumber }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const displayDate = moment().format('DD/MM/YYYY');

	return (
		<Grid container spacing={3}>
			<Grid item md={12} xs={12}>
				<Typography gutterBottom variant="caption">
					{displayDate}
				</Typography>
			</Grid>
			<Grid item md={6} xs={6} className={classes.booking}>
				<Typography className={classes.fontWeight} variant="h3" color="primary">
					{t('booking_print.booking_no')}{' '}
					<Typography
						className={classes.fontWeight}
						variant="h3"
						component="span"
						color="textSecondary"
					>
						{bookingNumber}
					</Typography>
				</Typography>
			</Grid>
			<Grid item md={6} xs={6}>
				<div className={classes.imageContainer}>
					<img src={logo} alt="Telgani logo" className={classes.image} />
				</div>
			</Grid>
		</Grid>
	);
};

Header.propTypes = {
	bookingNumber: string.isRequired,
};

export default Header;
