import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { BOOKING_COUNT_QUERY } from 'helpers';

const useBookingPreview = (uuid) => {
	const path = `v2/booking/${uuid}/preview`;

	const { data, isFetching, isError, isFetched } = useQuery(
		[BOOKING_COUNT_QUERY, uuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching, isError, isFetched };
};

export default useBookingPreview;
