import React from 'react';
import { object, bool, func } from 'prop-types';
import {
	Dialog,
	DialogContent,
	CircularProgress,
	Box,
} from '@material-ui/core';

// Import utilities
import { useCampaignConsumersModal } from './useCampaignConsumersModal';

// Import components
import { ClosableDialogTitle, Alert } from 'components/elements';
import { CampaignConsumersList } from '../../components';

// Import translations
import { useTranslations } from 'components/utilities';

export const CampaignConsumersModal = ({ campaign, open, onClose }) => {
	const { t } = useTranslations();

	const { consumersList, type, isFetching, isError } =
		useCampaignConsumersModal({ campaign });

	return (
		<Dialog open={open} onClose={onClose}>
			<ClosableDialogTitle
				onClose={onClose}
				title={t('campaigns.table.total_deposit_counter_modal.title')}
			/>

			<DialogContent>
				{isFetching ? (
					<Box padding={5} display="flex" justifyContent="center">
						<CircularProgress />
					</Box>
				) : isError ? (
					<Alert message={t('common.messages.error_message')} />
				) : (
					<CampaignConsumersList consumers={consumersList} type={type} />
				)}
			</DialogContent>
		</Dialog>
	);
};

CampaignConsumersModal.propTypes = {
	campaign: object.isRequired,
	open: bool.isRequired,
	onClose: func.isRequired,
};
