import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import { Box } from '@material-ui/core';
import { fetchManagersList } from 'store/actions';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers.js';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './helpers/columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import TableBodyRow from './TableBodyRow';
import { useDispatch } from 'react-redux';

import { setCompanyContext } from '../../store/actions';

const CompanyManagers = ({ match: { params } }) => {
	const classes = useBaseLayoutStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		setCompanyContext(dispatch, params.id);
	}, [dispatch, params.id]);

	return (
		<TableProvider columns={columns} fetchDataTableAction={fetchManagersList}>
			<Box className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<Box className={classes.content}>
					<Table isDefaultRow={false}>
						{({ row }) => <TableBodyRow key={row.id} row={row} />}
					</Table>
				</Box>
			</Box>
		</TableProvider>
	);
};

CompanyManagers.propTypes = {
	match: shape({
		params: shape({ id: string }),
	}),
};

export default CompanyManagers;
