import services from 'services/services';
import * as types from 'store/types';
import { fetchSliderFormResourcesUrl, submitSliderFormUrl } from 'store/paths';

// import helpers
import { catchError } from '../helpers';
import { convertData } from './helpers';

export const fetchSliderFormResources = ({
	itemId,
	cancelToken,
	isEdit,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.SLIDER_FORM_FETCH_DATA_LOADING,
			payload: true,
		});

		const { data } = isEdit
			? await services.get(fetchSliderFormResourcesUrl(itemId), cancelToken)
			: {};

		dispatch({
			type: types.SLIDER_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: data || {},
			},
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.SLIDER_FORM_FETCH_DATA_ERROR,
		});
	}
};

export const submitSliderForm = ({
	values,
	itemId,
	isEdit,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		const convertedData = convertData(values);

		await services.post(submitSliderFormUrl(itemId, isEdit), convertedData);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
