import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import components
import InfoListItem from './InfoListItem';

const BaseGrossPriceInfoListItem = ({ label }) => {
	const { item } = useSelector((store) => store.bookings.form.info.item);
	return (
		<InfoListItem label={label}>
			{item.base_gross_price} {item.base_unit}
		</InfoListItem>
	);
};

BaseGrossPriceInfoListItem.propTypes = {
	label: PropTypes.string
};

export default BaseGrossPriceInfoListItem;
