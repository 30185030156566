import { useState } from 'react';
import axios from 'axios';
import { BOOKING_URGENT_STATES } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeUrgentReasonStatus,
	setValidationTableChangedState,
} from 'store/actions';
import { previewBookingSelector } from 'store/selectors';

const useValidateUrgentForm = () => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [translationEnd, setTranslationEnd] = useState('');

	const source = axios.CancelToken.source();
	const cancelToken = source.token;

	const booking = useSelector(previewBookingSelector);
	const {
		urgentAssistance: { status, name, updated_at: updatedAt },
	} = booking;

	const dispatch = useDispatch();

	const changeBookingStatus = async (status) => {
		setIsLoading(true);
		try {
			await changeUrgentReasonStatus({
				bookingId: booking.uuid,
				status,
				cancelToken,
			});

			setIsLoading(false);
			setIsSubmitted(true);
		} catch (e) {
			setIsLoading(false);
			setError(e);
			setIsError(true);
		}
	};

	const handleUnresolveBooking = async () => {
		await changeBookingStatus(BOOKING_URGENT_STATES.UNSOLVED);
		setValidationTableChangedState({ isTableChanged: true, dispatch });
		setTranslationEnd(BOOKING_URGENT_STATES.UNSOLVED);
	};

	const handleResolveBooking = async () => {
		await changeBookingStatus(BOOKING_URGENT_STATES.SOLVED);
		setValidationTableChangedState({ isTableChanged: true, dispatch });
		setTranslationEnd(BOOKING_URGENT_STATES.SOLVED);
	};

	return {
		handleResolveBooking,
		handleUnresolveBooking,
		isSubmitted,
		isError,
		error,
		isLoading,
		status,
		name,
		updatedAt,
		translationEnd,
	};
};
export default useValidateUrgentForm;
