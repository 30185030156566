import React from 'react';
import {
	Grid,
	Typography,
	Card,
	CardContent,
	CardHeader
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';

// Import utilities
import useCustomerRatingPreview from './useCustomerRatingPreview';
import { useTranslations } from 'components/utilities';

const CustomerRatingPreview = () => {
	const { rating } = useCustomerRatingPreview();

	const { t } = useTranslations();

	if (!rating) {
		return null;
	}

	return (
		<Card>
			<CardHeader title={t('bookings.customer_rating.title')} />
			<CardContent>
				<Grid container>
					<Grid item xs={5}>
						<Typography component="p">{t('bookings.customer_rating.form.rating')}</Typography>
					</Grid>
					<Grid item container spacing={3} xs={7}>
						<Grid item xs={12}>
							<Rating value={rating.rating} readOnly size="large" />
						</Grid>

						<Grid item xs={12}>
							<Typography variant="h5">{t('bookings.customer_rating.form.description')}</Typography>
							<Typography>{rating.description}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default CustomerRatingPreview;
