import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import helpers and utils
import {
	useMessage,
	useProfile,
	useToggle,
	useTranslations,
} from 'components/utilities';
import services from 'services/services';
import {
	useExtendSubscriptionCalculation,
	useSubscriptionPaymentsQuery,
} from 'queries';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { extendSubscriptionUrl } from 'store/paths';
import { fetchBookingPreview } from 'store/actions';

export const useExtendSubscription = () => {
	const { t } = useTranslations();
	const { subscription, uuid } = useSelector(previewBookingSelector);
	const { isAdmin } = useProfile();
	const dispatch = useDispatch();
	const { message } = useMessage();

	const [isExtendLoading, setIsExtendLoading] = useState(false);

	const { can_be_extended: canBeExtended, uuid: subscriptionUuid } =
		subscription || {};

	const { refetch: refetchSubscriptionPaymentList } =
		useSubscriptionPaymentsQuery({
			uuid: subscriptionUuid,
		});

	const { data } = useExtendSubscriptionCalculation({
		uuid,
		enabled: uuid && canBeExtended,
	});

	const { drop_date: newDropDate } = data || {};

	const { on: isExtendConfirmOpen, toggle: toggleExtendConfirm } =
		useToggle(false);

	const isExtendAllowed = canBeExtended && isAdmin;

	const handleSubmitSubscriptionExtend = async () => {
		setIsExtendLoading(true);

		try {
			await services.post(extendSubscriptionUrl(uuid));
			await fetchBookingPreview({ id: uuid })(dispatch);
			await refetchSubscriptionPaymentList();

			toggleExtendConfirm();
			message.success(t('common.messages.successfully_done'));
		} catch (error) {
			if (error?.response) {
				const errorMessage = error.response.data.message;
				message.error(errorMessage);
			}
		}

		setIsExtendLoading(false);
	};

	const isConfirmButtonDisabled = !newDropDate || isExtendLoading;

	return {
		handleSubmitSubscriptionExtend,
		isConfirmButtonDisabled,
		toggleExtendConfirm,
		isExtendConfirmOpen,
		isExtendLoading,
		isExtendAllowed,
		newDropDate,
	};
};
