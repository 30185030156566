import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	formControl: {
		minWidth: 80,
		width: '100%',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 4,
	},
	chip: {
		maxWidth: '100%',
	},
	outlinedInput: {
		overflow: 'hidden',
	},
}));

export default useStyles;
