import React from 'react';
import { bool, object } from 'prop-types';
import { Divider, Grid } from '@material-ui/core';

// Import styles
import useStyles from '../styles';

// Import components
import {
	CompensationHistoryFieldFactory,
	CustomerCompensationCheckedBy,
} from '../components';
import { MakeCompensationDialog } from 'components/elements';

// Import helpers
import { getCompensationHistoryFields } from '../helpers';

const CompensationHistoryFields = ({
	compensation,
	isInAccountingValidationPage = false,
}) => {
	const classes = useStyles();

	const compensationHistoryFields = getCompensationHistoryFields({
		isInAccountingValidationPage,
	});

	return (
		<>
			{!isInAccountingValidationPage && <Divider className={classes.divider} />}
			<Grid container className={classes.topBar}>
				<Grid item xs={9}>
					<CustomerCompensationCheckedBy outerCompensation={compensation} />
				</Grid>
				{!isInAccountingValidationPage && (
					<Grid item>
						<MakeCompensationDialog outerCompensation={compensation} />
					</Grid>
				)}
			</Grid>
			<Grid container direction="column" spacing={1}>
				{compensationHistoryFields.map((field) => (
					<Grid key={field.accessor} item>
						<CompensationHistoryFieldFactory
							compensation={compensation}
							{...field}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
};

CompensationHistoryFields.propTypes = {
	compensation: object,
	isInAccountingValidationPage: bool,
};

export default CompensationHistoryFields;
