import React from 'react';
import { string } from 'prop-types';
import {
	Card,
	CardHeader,
	Divider,
	CardContent,
	Grid,
} from '@material-ui/core';

// Import components
import BaseDetailsFields from './BaseDetailsFields';

// Import translations
import { useTranslations } from 'components/utilities';

const BaseDetails = ({ className, section, ...rest }) => {
	const { t } = useTranslations();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('customers.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<BaseDetailsFields section={section} />
				</Grid>
			</CardContent>
		</Card>
	);
};

BaseDetails.propTypes = {
	section: string.isRequired,
	className: string,
};

export default BaseDetails;
