import React from 'react';

// Import utils
import { useCancellationReasons } from 'queries';

// Import actions
import {
	rejectBooking,
	cancelBooking,
} from 'store/actions/bookings/bookings_state';

export const useCancelAndRejectBookingForm = ({
	booking,
	onChange,
	isRejection,
}) => {
	const [isLoading, setLoading] = React.useState(false);
	const [error, setError] = React.useState([]);

	const { data: cancellationReasons } = useCancellationReasons();

	const handleSubmit = async (values) => {
		try {
			setLoading(true);

			const options = {
				bookingId: booking.uuid,
				cancellationReasons,
				values,
			};

			isRejection ? await rejectBooking(options) : await cancelBooking(options);

			setLoading(false);

			onChange();
		} catch (error) {
			setLoading(false);
			setError(Object.values(error.data ? error.data.errors : {}));
		}
	};

	return {
		handleSubmit,
		isLoading,
		error,
		cancellationReasons,
	};
};
