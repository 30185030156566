import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Import styles
import useStyles from './styles';

const NotFound = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid container justifyContent="center" spacing={4}>
				<Grid item lg={6} xs={12}>
					<div className={classes.content}>
						<Typography variant="h1">Not authorized</Typography>
						<Typography variant="subtitle2"></Typography>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default NotFound;
