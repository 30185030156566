import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import queries
import { useRatingReasons } from 'queries';

const RatingReasonFilter = ({ name, label }) => {
	const { submitting } = useFormState();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data } = useRatingReasons({});

	if (!data) {
		return null;
	}

	return (
		<Grid item md={3}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{data.map(({ uuid, reason }) => (
					<MenuItem key={uuid} value={uuid}>
						{reason[language]}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

RatingReasonFilter.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
};

export default RatingReasonFilter;
