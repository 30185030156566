import React from 'react';
import { object } from 'prop-types';
import { TableCell, Link } from '@material-ui/core';

// Import components
import { CampaignConsumersModal } from './components';

// Import utilities
import { useToggle } from 'components/utilities';

export const TotalDepositCounterTableCell = ({ cell }) => {
	const campaign = cell.row.original;

	const { total_deposit_counter: totalDepositsCounter } = campaign;

	const { on, toggle } = useToggle();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Link component="button" onClick={toggle}>
				{totalDepositsCounter}
			</Link>
			{on && (
				<CampaignConsumersModal
					campaign={campaign}
					open={on}
					onClose={toggle}
				/>
			)}
		</TableCell>
	);
};

TotalDepositCounterTableCell.propTypes = {
	cell: object.isRequired,
};
