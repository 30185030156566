import React from 'react';
import { Grid, Box } from '@material-ui/core';
import {
	fetchPartnersPositionsFormResources,
	submitPartnersPositionsForm,
} from 'store/actions';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { yupValidator } from 'helpers';
import { validationSchema } from './validation';

// Import utilities
import { FormRPC } from 'components/utilities';
import { usePartnersPositionsForm } from './usePartnersPositionsForm';

// Import components
import { WebsitePartners } from './components';

//Import styles
import { useStyles } from './PartnersPositionsForm.styles';

export const PartnersPositionsForm = () => {
	const classes = useStyles();

	const { callbackSuccess } = usePartnersPositionsForm();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchPartnersPositionsFormResources}
				submitFormAction={submitPartnersPositionsForm}
				callbackFormSuccess={callbackSuccess}
				isCardLayout={false}
				goBackPath=""
				store={(store) => store.partners.partnersPositionsForm}
				initialValues={INITIAL_VALUES}
				validation={(values) => yupValidator({ values, validationSchema })}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<WebsitePartners />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
