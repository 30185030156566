import { useSelector } from 'react-redux';

// Import helpers
import { previewBookingSelector } from 'store/selectors';

export const useBookingInfoCardAction = () => {
	const booking = useSelector(previewBookingSelector);

	const sectionName = 'bookings.customer_compensation';

	const compensationStatus = booking?.compensation?.status;
	const compensationStatusText = `${sectionName}.statuses.${compensationStatus}`;

	const cancellationStatus = booking?.cancel_reason?.status;
	const cancellationStatusText = `bookings.filters.${cancellationStatus}`;

	const chipLabels = [];

	compensationStatus &&
		chipLabels.push({
			label: `bookings.filters.compensation_status`,
			statusText: compensationStatusText,
		});

	cancellationStatus &&
		chipLabels.push({
			label: `bookings.filters.cancellation_status`,
			statusText: cancellationStatusText,
		});

	return {
		chipLabels,
	};
};
