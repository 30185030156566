import React, { forwardRef } from 'react';
import { Grid } from '@material-ui/core';
import { bool, func } from 'prop-types';

// Import components
import { ButtonProgress } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './Import.styles';

export const Import = forwardRef(
	({ isLoading, handleImportButtonClick, handleImportInputChange }, ref) => {
		const { t } = useTranslations();
		const classes = useStyles();

		return (
			<Grid item>
				<ButtonProgress
					onClick={handleImportButtonClick}
					isLoading={isLoading}
					disabled={isLoading}
					variant="outlined"
					color="primary"
				>
					{t('common.buttons.import')}
				</ButtonProgress>
				<input
					onChange={handleImportInputChange}
					className={classes.hiddenInput}
					ref={ref}
					type="file"
				/>
			</Grid>
		);
	}
);

Import.displayName = 'Import';

Import.propTypes = {
	isLoading: bool.isRequired,
	handleImportButtonClick: func.isRequired,
	handleImportInputChange: func.isRequired,
};
