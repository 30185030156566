import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	icon: {
		'&:hover': {
			transition: 'box-shadow 0.2s ease',
			background: 'none',
			boxShadow: '0 8px 16px -6px rgba(0, 0, 0, 0.2)',
		},
	},
}));
