import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CONTRACT_DATA_QUERY } from 'helpers';

export const useContractData = (contractNumber) => {
	const path = `/econtract/${contractNumber}/collection`;

	const { data, error, isError, isFetching } = useQuery(
		[CONTRACT_DATA_QUERY, contractNumber],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: contractNumber }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError, error };
};
