import React from 'react';
import { bool } from 'prop-types';

// Import utils and helpers
import { formatValues, initialValues } from './helpers';
import { useProfile } from 'components/utilities';

// Import components
import { TableFilters } from 'components/elements';
import { AdditionalInfo } from '../../components';
import FilterFields from './FilterFields';

export const Filters = ({
	isExtended,
	showAssignedDriverAdditional = false,
	showDeliveryStatusAdditional = false,
}) => {
	const { isCorporateCoordinator } = useProfile();

	return (
		<TableFilters
			initialValues={initialValues}
			formatValues={formatValues}
			additionalInfo={
				!isCorporateCoordinator ? (
					<AdditionalInfo
						showAssignedDriverAdditional={showAssignedDriverAdditional}
						showDeliveryStatusAdditional={showDeliveryStatusAdditional}
					/>
				) : null
			}
		>
			{() => <FilterFields isExtended={isExtended} />}
		</TableFilters>
	);
};

Filters.propTypes = {
	isExtended: bool,
	showAssignedDriverAdditional: bool,
	showDeliveryStatusAdditional: bool,
};
