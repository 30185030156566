import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useQueryStringParams = () => {
	const location = useLocation();
	const decodedSearch = decodeURI(location.search.slice(1));

	const parsedQSParams = queryString.parse(decodedSearch);
	const parsedOptions = JSON.parse(parsedQSParams.options || null) || {};
	const parsedFilters = JSON.parse(parsedQSParams.filters || null);

	const handleStringify = ({ options, filters }) => {
		const urlParams = queryString.stringify({
			options: JSON.stringify(options),
			filters: JSON.stringify(filters),
		});

		const encodedParams = encodeURI(urlParams);

		return `?${encodedParams}`;
	};

	return {
		parsedQSParams,
		parsedOptions,
		parsedFilters,
		stringifyParams: handleStringify,
	};
};
