import { format } from 'date-fns';
import _ from 'lodash';

// Import helpers
import {
	ORIGINAL_SCRATCH_IMAGE_DIMENSIONS,
	isAuthorizationTypeCodeVisible,
	isClosureCodeRequired,
	isVisitorOrGcc,
	YMDTHM_DATE_FORMAT,
	YMDTHM_DATE_MOMENT_FORMAT,
	convertToGregorianDate,
	convertPlate,
	removeEmptyValues,
} from 'helpers';

const additionalServiceFields = [
	'navigation_system_per_day',
	'disabilities_aids_per_day',
	'internet_per_day',
	'car_seat_per_day',
	'car_delivery',
];

const convertSketchInfo = (data) => {
	return data?.map(({ left, top, type }) => ({
		x: (Math.abs(top + 2.5) * ORIGINAL_SCRATCH_IMAGE_DIMENSIONS.WIDTH) / 100,
		y: (Math.abs(left + 4) * ORIGINAL_SCRATCH_IMAGE_DIMENSIONS.HEIGHT) / 100,
		type,
	}));
};

const convertAdditionalServices = (values) =>
	additionalServiceFields.reduce((acc, fieldName) => {
		if (values.additional_services[`is_${fieldName}`]) {
			return {
				...acc,
				...{
					[fieldName]: +values.additional_services[fieldName],
				},
			};
		} else {
			return acc;
		}
	}, {});

export const convertSaveContractValues = ({ values, bookingUuid, isDraft }) => {
	const isAuthTypeCodeVisible = isAuthorizationTypeCodeVisible(values);

	const authorizationTypeCode =
		values.authorization_details.authorization_type_code;
	const { plateBodyForGetPlateDataReversed } = convertPlate(values.plate);

	let newValues = {
		...values,
		booking_id: bookingUuid,
		renter: {
			...values.renter,
			birth_date: convertToGregorianDate(values.renter.birth_date),
		},
		vehicle_details: {
			...values.vehicle_details,
			...plateBodyForGetPlateDataReversed,
		},
		rent_status: {
			...values.rent_status,
			oil_change_date: convertToGregorianDate(
				values.rent_status.oil_change_date
			),
			sketch_info: convertSketchInfo(values.rent_status.sketch_info),
		},
		contract_details: {
			...values.contract_details,
			contract_start_date: convertToGregorianDate(
				values.contract_details.contract_start_date,
				YMDTHM_DATE_MOMENT_FORMAT
			),
			contract_end_date: convertToGregorianDate(
				values.contract_details.contract_end_date,
				YMDTHM_DATE_MOMENT_FORMAT
			),
		},
		additional_services: convertAdditionalServices(values),
	};

	if (values.renter.is_extra_driver) {
		newValues.extra_driver = {
			...values.extra_driver,
			birth_date: convertToGregorianDate(values.extra_driver.birth_date),
		};
	} else {
		newValues = _.omit(newValues, 'extra_driver');
	}

	if (isVisitorOrGcc(newValues)) {
		newValues.renter = {
			...newValues.renter,
			license_expiry_date: convertToGregorianDate(
				values.renter.license_expiry_date
			),
		};
	} else {
		newValues.renter = _.omit(newValues.renter, [
			'drive_license_number',
			'license_expiry_date',
			'nationality_code',
			'id_copy_number',
			'issue_place_id',
		]);
	}

	if (!authorizationTypeCode || authorizationTypeCode === 1) {
		newValues.authorization_details = _.omit(
			newValues.authorization_details,
			'external_authorization_countries'
		);
	}

	if (!isAuthTypeCodeVisible) {
		newValues.authorization_details = _.omit(
			newValues.authorization_details,
			'authorization_type_code'
		);
	}

	newValues = _.omit(newValues, 'plate');

	if (isDraft) {
		newValues = removeEmptyValues(newValues);
	}

	return newValues;
};

export const convertExtendContractSubmitValues = (values, isOtpRequired) => ({
	...values,
	contract_end_date: format(
		new Date(values.contract_end_date),
		YMDTHM_DATE_FORMAT
	),
	discount: +values.discount,
	paid: +values.paid,
	...(isOtpRequired && { otp_value: +values.otp_value }),
});

export const convertCloseContractSubmitValues = ({
	values,
	closureReasons,
}) => {
	let newValues = {
		...values,
		contract_actual_end_date: format(
			new Date(values.contract_actual_end_date).setHours(
				new Date().getHours(),
				new Date().getMinutes()
			),
			YMDTHM_DATE_FORMAT
		),
		rent_status: {
			...values.rent_status,
			sketch_info: convertSketchInfo(values.rent_status.sketch_info),
		},
	};

	if (
		!isClosureCodeRequired({
			closureReasons,
			mainClosureCode: newValues.main_closure_code,
		})
	) {
		newValues = _.omit(newValues, 'closure_code');
	}

	return newValues;
};

export const convertSuspendContractSubmitValues = (values) => {
	let newValues = {
		...values,
		rent_status: {
			...values.rent_status,
			sketch_info: convertSketchInfo(values.rent_status.sketch_info),
		},
	};

	newValues = _.omit(newValues, [
		'additional_services',
		'vehicle_plate_uuid',
		'contract_details',
		'renter',
		'contract_number',
	]);

	return newValues;
};
