import React from 'react';
import { object, string } from 'prop-types';
import clsx from 'clsx';
import { FormControlLabel, FormControl, Typography } from '@material-ui/core';

// Import components
import { InputError, CustomSwitch } from 'components/elements';

// Import styles
import { useStyles } from './styles';

export const SwitchFieldAdapter = ({ input, meta, labelText, ...rest }) => {
	const classes = useStyles();

	const error = !!(meta.touched && (meta.error || meta.submitError));

	return (
		<FormControl component="fieldset" error={error}>
			<FormControlLabel
				control={<CustomSwitch color="primary" {...input} {...rest} />}
				label={
					<Typography className={clsx(input.checked && classes.bold)}>
						{labelText}
					</Typography>
				}
			/>
			<InputError meta={meta} />
		</FormControl>
	);
};

SwitchFieldAdapter.propTypes = {
	input: object.isRequired,
	labelText: string.isRequired,
	meta: object.isRequired,
};
