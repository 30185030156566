import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE, PREVIEW } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ANALYZE_DRIVER_LICENSE: 'analyze_driver_license',
	APPROVE: 'organization_verified',
	STATE: 'state',
	NAME: 'name',
	NOTE: 'note',
};

export const columns = ({ lng, isAllowedToDelete = false }) => [
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'customers.table.headers.name',
		accessor: COLUMNS_ACCESSORS.NAME,
		isHidden: false,
	},
	{
		Header: 'customers.table.headers.phone_number',
		accessor: 'phone',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'customers.table.headers.location',
		accessor: 'location',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'customers.table.headers.age',
		accessor: 'age',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'customers.table.headers.rating',
		accessor: 'rating',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'customers.table.headers.total_no_of_booking',
		accessor: 'accepted_booking_counter',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'customers.table.headers.approve_organization',
		accessor: COLUMNS_ACCESSORS.APPROVE,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.STATE,
		disableSortBy: true,
		isHidden: false,
		filterable: true,
	},
	{
		Header: 'customers.table.headers.analyze_driver_license',
		accessor: COLUMNS_ACCESSORS.ANALYZE_DRIVER_LICENSE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.preview',
		accessor: PREVIEW,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
		isHidden: !isAllowedToDelete,
	},

	// Additional
	{
		Header: 'customers.table.headers.gender',
		accessor: `gender_name.${lng}`,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'customers.table.headers.driver_license',
		accessor: 'license_number',
		disableSortBy: true,
		isHidden: true,
	},

	{
		Header: 'customers.table.headers.organization',
		accessor: `organization_name.${lng}`,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'customers.table.headers.type',
		accessor: 'type',
		disableSortBy: true,
		isHidden: true,
	},
];
