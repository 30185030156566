import React from 'react';
import { string } from 'prop-types';

// Import components
import {
	DefaultPriceSummaryItem,
	OptionsPriceSummaryItem,
} from '../components';

// Import helpers
import { ITEMS_ACCESSORS } from '../helpers';

const PriceSummaryItemFactory = (props) => {
	switch (props.accessor) {
		case ITEMS_ACCESSORS.OPTIONS:
			return <OptionsPriceSummaryItem {...props} />;
		default:
			return <DefaultPriceSummaryItem {...props} />;
	}
};

PriceSummaryItemFactory.propTypes = {
	accessor: string,
};

export default PriceSummaryItemFactory;
