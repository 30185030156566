import React from 'react';

// Import components
import { UnsettledLimitsWrapper } from './components';

// Import utils
import { UnsettledLimitsProvider } from './context';

export const UnsettledLimits = () => (
	<UnsettledLimitsProvider>
		<UnsettledLimitsWrapper />
	</UnsettledLimitsProvider>
);
