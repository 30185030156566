import _ from 'lodash';

export const getUniqueCar = (cars, vehicleUuid) => {
	const groupedCars = _.groupBy(cars, 'vehicle_uuid');
	const filteredCars = groupedCars[vehicleUuid];

	const isSomeActive = filteredCars?.some(({ is_active }) => +is_active);

	const quantity = filteredCars?.reduce((acc, car) => {
		if (isSomeActive) {
			if (car.is_active) {
				return acc + Number(car.quantity);
			} else {
				return acc;
			}
		} else {
			return acc + Number(car.quantity);
		}
	}, 0);

	const cars_uuids = filteredCars?.reduce((acc, car) => {
		const newUuid = { uuid: car.car_uuid };
		return [...acc, newUuid];
	}, []);

	if (filteredCars) {
		return {
			...filteredCars[0],
			quantity,
			cars_uuids,
			is_active: isSomeActive,
		};
	}

	return null;
};
