// Import helpers
import {
	closeContractUrl,
	updateBookingStateUrl,
	updatePlateUrl,
} from 'store/paths';
import { convertCloseContractSubmitValues } from './helpers';
import { BOOKING_STATES, PLATE_RENT_STATUS } from 'helpers';

// Import utils
import services from 'services/services';

// Import helpers
export const closeContract = async ({
	successCallback,
	closureReasons,
	contractNumber,
	errorCallback,
	bookingUuid,
	plateUuid,
	values,
}) => {
	try {
		const convertedValues = convertCloseContractSubmitValues({
			values,
			closureReasons,
		});

		await services.put(closeContractUrl(contractNumber), convertedValues);
		await services.put(updatePlateUrl(plateUuid), {
			rent_status: PLATE_RENT_STATUS.AVAILABLE,
			miles_usage: values.rent_status.odometer_reading,
			booking_uuid: bookingUuid,
		});
		await services.put(updateBookingStateUrl(bookingUuid), {
			state: BOOKING_STATES.CONTRACT_CLOSED,
		});
		successCallback();
	} catch (error) {
		if (error.response) {
			const { errors, message } = error.response.data || {};
			errorCallback(errors || message);
		}
	}
};
