export const prices = [
	{
		value: 0,
		label: 'Free',
	},
	{
		value: 100,
		label: 100,
	},
	{
		value: 150,
		label: 150,
	},
	{
		value: 200,
		label: 200,
	},
	{
		value: 250,
		label: 250,
	},
	{
		value: 300,
		label: 300,
	},
	{
		value: 350,
		label: 350,
	},
	{
		value: 400,
		label: 400,
	},
	{
		value: 450,
		label: 450,
	},
	{
		value: 500,
		label: 500,
	},
	{
		value: 550,
		label: 550,
	},
	{
		value: 600,
		label: 600,
	},
	{
		value: 650,
		label: 650,
	},
];
