import { useState } from 'react';
import { useForm } from 'react-final-form';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useAdmins } from 'queries';

// Import helpers
import { sortAdminsByName } from '../helpers';

const useAdminFilter = ({ name }) => {
	const { data: admins } = useAdmins();

	const { t } = useTranslations();

	const initialOption = { name: t('common.all'), uuid: 'all' };
	const sortedAdmins = [initialOption, ...sortAdminsByName(admins)];

	const { change } = useForm();

	const [selectedAdmin, setSelectedAdmin] = useState(initialOption);

	const handleOnChange = (_, newValue) => {
		setSelectedAdmin(newValue);
		change(name, newValue?.uuid || initialOption.uuid);
	};

	return {
		sortedAdmins,
		selectedAdmin,
		handleOnChange,
	};
};

export default useAdminFilter;
