import { ADMINS } from 'helpers';

export const defaultSeoCollapsedItems = (seoTypes) =>
	Object.values(seoTypes).map((type) => {
		const convertedType = type.replace('_', '-');
		return {
			title: `nav.marketing.default_seo.${type}`,
			href: `/default-seo/${convertedType}/edit`,
			role: ADMINS,
		};
	});
