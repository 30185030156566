import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '2fr 1fr',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr 1fr',
			marginTop: '32px',
		},
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		gap: '22px',
	},
	heading: {
		fontSize: '16px',
		fontWeight: '600',
		marginTop: '16px',
		color: theme.palette.text.primary,
		[theme.breakpoints.down('md')]: {
			marginBottom: '5px',
		},
	},
	scratchesWrapper: {
		display: 'flex',
		flexDirection: 'column',
		gap: '46px',
	},
	scratch: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		gap: '16px',
	},
	scratchIcon: {
		marginTop: '4px',
		width: '13px',
		height: '13px',
	},
	scratchTitle: {
		fontSize: '14px',
		fontWeight: '500',
		color: theme.palette.text.primary,
	},
	active: {
		color: theme.palette.primary.main,
	},
	activeText: {
		color: theme.palette.primary.main,
		fontSize: '10px',
	},
	image: {
		height: '100%',
		width: '100%',
	},
	imageWrapper: {
		display: 'flex',
		height: '100%',
		width: '100%',
		maxWidth: '300px',
	},
}));
