export default {
	elevation1: {
		boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',

		'@media (max-width: 960px)': {
			boxShadow: ' 0px 20px 72px 0px rgba(227, 230, 236, 0.85)',
		},
	},
	elevation8: {
		borderRadius: 24,
		padding: '5px 10px',
	},
};
