import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		gap: '32px',
		padding: '32px',
	},
}));

export const inputStyles = {
	width: '47px',
	height: '47px',
	margin: '0 2.5px',
	fontSize: '13px',
	fontWeight: '600',
	backgroundColor: theme?.palette.grey[100],
	borderRadius: '18px',
	border: 'none',
};

export const containerStyles = {
	direction: 'ltr',
};

export const focusStyles = {
	outline: 'none',
	border: `2px solid ${theme?.palette.primary.main}`,
};
