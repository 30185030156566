export const INITIAL_VALUES = {
	details: {
		active: false,
		name: {
			ar: '',
			en: '',
		},
	},
	logo: {
		file_name: '',
	},
	location: {
		address: '',
		city: '',
		country: '',
		lat: '',
		lng: '',
		place_id: '',
		delivery: {
			address: '',
			lat: '',
			lng: '',
		},
	},
};
