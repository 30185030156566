import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeFilter } from 'components/elements';

export const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<DateRangeFilter
				nameFrom="transaction_created_at"
				nameUntil="transaction_created_at_to"
				label="bookings.filters.transaction_created_at"
				combinedInputs
			/>
		</Grid>
	);
};
