import { makeStyles } from '@material-ui/styles';
import { DIMENSIONS } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	phoneNumberForm: {
		height: `calc(100vh - ${DIMENSIONS.nav}px - ${DIMENSIONS.footer}px)`,
		width: '100%',
		padding: theme.spacing(3),
	},
	elmLogo: {
		width: 40,
	},
}));
