import services from 'services/services';
import * as types from 'store/types';
import { submitPhoneNumberFormUrl } from 'store/paths';

// import helpers
import { INITIAL_VALUES } from 'views/Customers/PhoneNumberForm/helpers';

export const fetchPhoneNumberFormResources = () => async (dispatch) => {
	dispatch({
		type: types.WHITELIST_FORM_FETCH_DATA_SUCCESS,
		payload: {
			item: INITIAL_VALUES,
		},
	});
};
export const submitPhoneNumberForm = ({
	values,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		await services.post(submitPhoneNumberFormUrl, values);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
