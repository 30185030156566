import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles((theme) => ({
	label: {
		display: 'block',
		width: '100%',
		marginBottom: '10px',
		paddingTop: '10px',
		[theme.breakpoints.down('md')]: {
			color: theme.palette.text.primary,
			fontSize: 14,
			fontWeight: 500,
		},
	},
	button: {
		margin: '0 5px',
		[theme.breakpoints.down('md')]: {
			marginTop: '10px',
			width: '52px',
			height: '30px',
			borderRadius: 6,
			fontSize: 11,
		},
	},
}));

export const otpInputStyle = {
	width: '52px',
	height: '30px',
	backgroundColor: theme?.palette.white,
	border: `1px solid ${theme?.palette.gray.border}`,
	borderRadius: 6,
};
