import React from 'react';
import { func, array, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormState, Field } from 'react-final-form';
import { CircularProgress, Grid, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// Import components
import { CheckboxFieldAdapter, Alert } from 'components/elements';
import { OfficeItem } from '../OfficeItem';

// Import translations
import { useTranslations } from 'components/utilities';

const useStyles = makeStyles((theme) => ({
	loader: {
		height: '100%',
		width: '100%',
		zIndex: 9999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	item: {
		position: 'sticky',
		top: 0,
		left: 0,
		background: theme.palette.background.paper,
		zIndex: 100,
		padding: '0px 4px',
	},
}));

const OfficesFields = ({
	checkedOffices,
	setCheckedOffices,
	selectAll,
	setSelectAll,
}) => {
	const {
		isLoaded,
		isError,
		error,
		item: { officesList },
	} = useSelector((store) => store.car.form);

	const classes = useStyles();

	const { submitting } = useFormState();

	const { t } = useTranslations();

	const handleSelectAll = () => {
		setSelectAll((prev) => !prev);

		setCheckedOffices(
			officesList.length === checkedOffices.length
				? []
				: officesList.map((office) => office.uuid)
		);
	};

	return (
		<>
			{isError && isLoaded && <Alert message={error.message} />}
			{isLoaded ? (
				!isError && (
					<>
						<Grid item md={12} xs={12} className={classes.item}>
							<Field
								component={CheckboxFieldAdapter}
								type="checkbox"
								labelText={t('cars.form.offices.select_all')}
								name="select_all"
								disabled={submitting}
								color="primary"
								checked={selectAll}
								onChange={handleSelectAll}
							/>
						</Grid>
						<List>
							<Grid
								container
								diretion="row"
								alignItems="flex-start"
								justifyContent="flex-start"
								spacing={2}
							>
								{officesList.map(({ uuid, name }) => (
									<OfficeItem
										key={uuid}
										officeUuid={uuid}
										office={name}
										officesList={officesList || []}
										checkedOffices={checkedOffices}
										setCheckedOffices={setCheckedOffices}
										setSelectAll={setSelectAll}
									/>
								))}
							</Grid>
						</List>
					</>
				)
			) : (
				<div className={classes.loader}>
					<CircularProgress align="center" size={40} color="primary" />
				</div>
			)}
		</>
	);
};

OfficesFields.propTypes = {
	setCheckedOffices: func.isRequired,
	checkedOffices: array.isRequired,
	selectAll: bool.isRequired,
	setSelectAll: func.isRequired,
};

export default OfficesFields;
