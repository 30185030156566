import React from 'react';
import { fetchCarsAvailability } from 'store/actions';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';
import { companyColumns } from './columns';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import components
import { TableProvider } from 'components/context';
import { Toolbar, Table } from 'components/elements';
import { TableBodyRow, Export } from './components';

// Import utils
import { useCarsAvailability } from './useCarsAvailability';

export const CarsAvailability = () => {
	const classes = useBaseLayoutStyles();

	const { userCompanyUuid } = useCarsAvailability();

	return (
		<TableProvider
			columns={companyColumns}
			fetchDataTableAction={(options) =>
				fetchCarsAvailability({ ...options, userCompanyUuid })
			}
		>
			<div className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<div className={classes.content}>
					<Table isDefaultRow={false} tableInnerToolbar={<Export />}>
						{({ row }) => {
							return <TableBodyRow key={row.id} row={row} />;
						}}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};
