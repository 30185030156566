import * as yup from 'yup';
import { t } from 'i18next';

export const validationSchema = () => {
	const requiredText = t('common.fields.required_field');

	return yup.object({
		city_uuid: yup.string().nullable().required(requiredText),
		active: yup.string().nullable().required(requiredText),
		name_en: yup.string().required(requiredText),
		name_ar: yup.string().required(requiredText),
		location: yup.object({
			address: yup.string().required(requiredText),
		}),
	});
};
