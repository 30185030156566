import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import store
import { deleteDriver, updateActiveUser } from 'store/actions';

// Import helpers
import { replaceEditDriverUrl, TABLE_ACTIONS_ACCESSORS } from 'helpers';

// Import components
import {
	EditTableCell,
	DeleteTableCell,
	ActiveTableCell,
} from 'components/elements';

const { EDIT, DELETE, ACTIVE } = TABLE_ACTIONS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case ACTIVE:
				return (
					<ActiveTableCell
						activeAction={updateActiveUser}
						id={cell.row.original.user_uuid}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditDriverUrl(cell.row.original.uuid)}
					/>
				);

			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteDriver}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
