import { useEffect, useState } from 'react';
import { useCampaignConditions } from 'queries';
import { CAMPAIGN_TYPES } from 'helpers';
import { useFormState } from 'react-final-form';

const useCustomerRegisteredFields = () => {
	const campaignType = CAMPAIGN_TYPES.CUSTOMER_REGISTERED;

	const { values } = useFormState();

	const [options, setOptions] = useState(values);

	const { data, isFetching } = useCampaignConditions({ campaignType, options });

	const selectedTypes = values?.condition?.customer?.customer_type || [];
	const selectedOrganizations = values?.condition?.customer?.organization || [];

	const customerTypes = data?.customer?.customer_type || {};
	const customerOrganizations = data?.customer?.organization || {};

	const customerCardProps = {
		customerTypes,
		customerOrganizations,
		selectedTypes,
		selectedOrganizations,
	};

	const selectedCompanies = values?.condition?.supplier?.company || [];
	const selectedOffices = values?.condition?.supplier?.office || [];

	const companies = data?.supplier?.company || {};
	const offices = data?.supplier?.office || {};

	const handleOnCompanySelectClose = () => {
		setOptions(values);
	};

	useEffect(() => {
		values.uuid && setOptions(values);

		// eslint-disable-next-line
	}, [values.uuid]);

	const supplierCardProps = {
		companies,
		offices,
		selectedCompanies,
		selectedOffices,
		isFetching,
		onCompanySelectClose: handleOnCompanySelectClose,
	};

	return {
		customerCardProps,
		supplierCardProps,
	};
};

export default useCustomerRegisteredFields;
