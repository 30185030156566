import { PAYMENT_METHODS, METHOD_TYPES } from 'helpers';

const { CARD, CASH, TABBY, TAMARA } = PAYMENT_METHODS;
const { TELGANI_PAY, APPLE_PAY, QITAF } = METHOD_TYPES;

export const PAYMENT_METHOD_FILTER_FIELDS = [
	CARD,
	CASH,
	TELGANI_PAY,
	APPLE_PAY,
	TABBY,
	TAMARA,
	QITAF,
];
