const useBusinessHour = ({ onChange, value }) => {
	const handleOpenToggle = () => {
		onChange({
			...value,
			isOpen: !value.isOpen,
			isOpenSecond: false,
		});
	};

	const handleOpenSecondToggle = () => {
		onChange({
			...value,
			isOpenSecond: !value.isOpenSecond,
		});
	};

	/**
	 * @param {object} time
	 * @param {'open' | 'openSecond'} time.prop
	 * @param {'start' | 'end'} time.type
	 * @param {Event} time.ev
	 */
	const handleOpenTimeChange = ({ prop, type, ev }) => {
		onChange({
			...value,
			[prop]: {
				...value[prop],
				[type]: ev.target.value,
			},
		});
	};

	const buttonOpenVariant = value.isOpen ? 'contained' : 'outlined';
	const buttonOpenColor = value.isOpen ? 'primary' : 'default';

	return {
		handleOpenToggle,
		handleOpenSecondToggle,
		handleOpenTimeChange,
		buttonOpenVariant,
		buttonOpenColor,
	};
};

export default useBusinessHour;
