import { makeStyles } from '@material-ui/styles';

import { STATE_TYPES } from './helpers';

export const useStyles = makeStyles((theme) => ({
	chip: {
		borderRadius: theme.layout.borderRadius.button,
		textTransform: 'uppercase',
		fontWeight: '500',
		minWidth: theme.layout.width.button,
	},

	[STATE_TYPES.SUCCESS]: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.primary.main,
	},

	[STATE_TYPES.ERROR]: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.main,
	},
}));
