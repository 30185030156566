export const showLeasingCarDetailsUrl = ({ officeId, vehicleId }) =>
	`dashboard/office-vehicle/${vehicleId}/show?office_uuid=${officeId}`;

export const createLeasingCarUrl = () => `dashboard/office-vehicle/create`;

export const updateLeasingCarUrl = (vehicleId) =>
	`dashboard/office-vehicle/${vehicleId}/update`;

export const deleteLeasingCarUrl = (vehicleId) =>
	`dashboard/office-vehicle/${vehicleId}/delete`;

export const exportLeasingCarsData = (officeId) =>
	`dashboard/office-vehicle/export?office_uuid=${officeId}`;

export const importLeasingCarsData = (officeId) =>
	`dashboard/office-vehicle/import?office_uuid=${officeId}`;
