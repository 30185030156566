import services from 'services/services';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';

// Import store
import { fetchCorporateUnsettledLimitsUrl } from 'store/paths';
import { getCurrentPeriodData } from 'views/b2b/CorporateSettlements/components/Filters/helpers';

export const fetchUnsettledLimits =
	({ options, itemId, queryParams, handleOnSettledUuids }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const { formattedFromDate, formattedToDate } = getCurrentPeriodData({
				year: new Date().getFullYear(),
				month: new Date().getMonth() + 1,
			});

			const mergeParams = (queryParams = {}) => {
				const defaultParams = `columns[5][search][value]=false&columns[10][search][value]=["${formattedFromDate}","${formattedToDate}"]`;

				let defaultQueryParamsUrl = new URLSearchParams(defaultParams);
				let queryParamsUrl = new URLSearchParams(queryParams);

				const defaultParamsObject = Object.fromEntries(defaultQueryParamsUrl);
				const queryParamsObject = Object.fromEntries(queryParamsUrl);

				const encodedParams = new URLSearchParams({
					...defaultParamsObject,
					...queryParamsObject,
				}).toString();

				return `?${encodedParams}`;
			};

			const mergedParams = mergeParams(queryParams);

			const params = combineQueryAndPaginationParams({
				queryParams: mergedParams,
				body,
			});

			const { data } = await services.get(
				fetchCorporateUnsettledLimitsUrl({ corporateUuid: itemId, params })
			);

			data.data.forEach(({ uuid }) => {
				handleOnSettledUuids(uuid);
			});

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
