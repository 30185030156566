// ********************  table  ********************

export const fetchTerminalsUrl = (itemId) =>
	`/v2/airport/${itemId}/terminal/list`;

export const updateActiveTerminalUrl = (id, isActive) =>
	`/v2/airport/terminal/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const deleteTerminalUrl = (id) => `/v2/airport/terminal/${id}/remove`;

// ********************  form  ********************

export const fetchTerminalFormResourcesUrl = (itemId) =>
	`/v2/airport/terminal/${itemId}/show`;

export const submitTerminalFormUrl = (itemId, airportId, isEdit) =>
	isEdit
		? `/v2/airport/terminal/${itemId}/update`
		: `/v2/airport/${airportId}/terminal/create`;
