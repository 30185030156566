import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import { KIOSK_AIRPORTS_LIST_QUERY, LOCAL_STORAGE_AIRPORT_LIST } from 'helpers';

const useAirportList = (body = {}) => {
	const path = `/v2/airport/terminal/nearest/collection`;

	const localStorageAirportList = ls.get(
		LOCAL_STORAGE_AIRPORT_LIST(JSON.stringify(body))
	);

	const { data, isError, isFetching, isFetched } = useQuery(
		KIOSK_AIRPORTS_LIST_QUERY,
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false, enabled: !localStorageAirportList }
	);

	const fetchedData = data ? data.data.data : [];

	useEffect(() => {
		if (!localStorageAirportList && isFetched && fetchedData.length) {
			ls.set(LOCAL_STORAGE_AIRPORT_LIST(JSON.stringify(body)), fetchedData, {
				ttl: 7 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStorageAirportList, isFetched, fetchedData]);

	const result = localStorageAirportList || fetchedData;

	return { data: result, isFetching, isError };
};

export default useAirportList;
