import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Import styles
import { useStyles } from './IconButton.styles';

const IconButton = (props) => {
	const classes = useStyles();

	return (
		<MuiIconButton
			className={clsx([classes.icon, props.className])}
			data-testid="icon_button"
			{...props}
		>
			{props.children}
		</MuiIconButton>
	);
};

IconButton.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
};

export default IconButton;
