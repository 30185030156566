import { useState } from 'react';

const useMakeCompensationDialog = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOnClose = () => setIsOpen(false);
	const handleOnOpen = () => setIsOpen(true);

	return {
		isOpen,
		onClose: handleOnClose,
		onOpen: handleOnOpen,
	};
};

export default useMakeCompensationDialog;
