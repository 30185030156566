import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';

// Import components
import { EditTableCell, RatingTableCell } from 'components/elements';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { replaceOfficeDetailsUrl } from 'helpers';

const { OFFICE_NAME, RATING, ALL_BOOKINGS_AND_ACCEPTED } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		const {
			office_avg_rating: officeAvgRating,
			office_name: officeName,
			office_uuid: officeUuid,
			booking_accepted_count: acceptedBooking,
			booking_total_count: totalBooking,
		} = cell.row.original;

		switch (cell.column.id) {
			case OFFICE_NAME:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						isIcon={false}
						originalText={officeName}
						editLinkPath={replaceOfficeDetailsUrl(officeUuid)}
					/>
				);

			case ALL_BOOKINGS_AND_ACCEPTED:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						<Typography>
							{totalBooking}({acceptedBooking})
						</Typography>
					</TableCell>
				);

			case RATING:
				return (
					<RatingTableCell
						externalRating={Number(officeAvgRating).toFixed(1)}
						key={cell.column.id}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
