import Bugsnag from '@bugsnag/js';
import { useEffect, useState, useRef } from 'react';
import { arSA, enGB } from 'date-fns/locale';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { AR, BOOKING_URGENT_STATES } from 'helpers';
import { useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';

const useCalculateUrgentTime = ({ urgentAssistance }) => {
	const elapsedTimeInterval = useRef(null);
	const [elapsedTime, setElapsedTime] = useState('');
	const { created_at, updated_at, status } = urgentAssistance;
	const { i18n } = useTranslations();
	const lng = i18n.language;

	const { data: tableContent } = useTableContextProvider();

	const isReview = status === BOOKING_URGENT_STATES.REVIEW;

	const formatOptions = {
		locale: lng === AR ? arSA : enGB,
	};

	const handleInterval = () => {
		const urgentCreatedAt = new Date(created_at);
		const urgentUpdatedAt = new Date(updated_at);

		if (!urgentUpdatedAt && !urgentCreatedAt) return;

		const stringTimeNowInSA = new Date().toLocaleString('en-US', {
			timeZone: 'Asia/Riyadh',
		});
		const timeNowInSA = new Date(stringTimeNowInSA);

		const selectedTime = isReview ? timeNowInSA : urgentUpdatedAt;

		try {
			const elapsed = formatDistanceStrict(
				selectedTime,
				urgentCreatedAt,
				formatOptions
			);

			setElapsedTime(elapsed);
		} catch (e) {
			Bugsnag.notify(e, function (event) {
				const timeDebuggingData = {
					urgentCreatedAt,
					urgentUpdatedAt,
					stringTimeNowInSA,
					timeNowInSA,
					selectedTime,
				};
				event.addMetadata('timeDebug', timeDebuggingData);
				event.addMetadata('tableContent', tableContent);
			});
		}
	};

	useEffect(() => {
		handleInterval();
		if (isReview) {
			elapsedTimeInterval.current = setInterval(handleInterval, 1000);
		}

		return () => isReview && clearInterval(elapsedTimeInterval.current);

		// eslint-disable-next-line
	}, [urgentAssistance]);

	return { elapsedTime };
};

export default useCalculateUrgentTime;
