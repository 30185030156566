import React from 'react';
import { SvgIcon } from '@material-ui/core';

const NavigateLastIcon = (props) => (
	<SvgIcon viewBox="0 0 38 38" {...props}>
		<path
			d="M19.7327 13.2069C20.0326 12.9213 20.5074 12.9328 20.7931 13.2327L25.7944 18.4832C26.0703 18.7728 26.0703 19.2281 25.7944 19.5178L20.7931 24.7682C20.5074 25.0681 20.0326 25.0797 19.7327 24.794C19.4328 24.5083 19.4213 24.0336 19.7069 23.7336L24.2155 19.0005L19.7069 14.2673C19.4213 13.9674 19.4328 13.4926 19.7327 13.2069Z"
			fill="currentColor"
		/>
		<path
			d="M13.7327 13.2069C14.0326 12.9213 14.5074 12.9328 14.7931 13.2327L19.7944 18.4832C20.0703 18.7728 20.0703 19.2281 19.7944 19.5178L14.7931 24.7682C14.5074 25.0681 14.0326 25.0797 13.7327 24.794C13.4328 24.5083 13.4213 24.0336 13.7069 23.7336L18.2155 19.0005L13.7069 14.2673C13.4213 13.9674 13.4328 13.4926 13.7327 13.2069Z"
			fill="currentColor"
		/>
	</SvgIcon>
);

export default NavigateLastIcon;
