import React from 'react';
import { Box, Button, Dialog } from '@material-ui/core';
import { bool, func, object, string } from 'prop-types';

// Import components
import { DialogForm } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useDriverDialog } from './useDriverDialog';

// Import styles
import { useStyles } from './DriverDialog.styles';

export const DriverDialog = ({
	deliveryType,
	externalBookingUuid,
	externalDelivery = null,
	changeButtonOutlined = false,
	refetchCallback = () => {},
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { buttonText, isButtonVisible, toggle, on, isDriverAssigned } =
		useDriverDialog({
			deliveryType,
			t,
			externalDelivery,
		});

	return (
		<>
			<Box className={classes.contentBox}>
				{isButtonVisible && (
					<Button
						color="primary"
						variant={
							changeButtonOutlined && isDriverAssigned
								? 'outlined'
								: 'contained'
						}
						onClick={toggle}
						size="small"
					>
						{buttonText}
					</Button>
				)}
			</Box>
			<Dialog open={on} onClose={toggle} fullWidth>
				<DialogForm
					toggle={toggle}
					deliveryType={deliveryType}
					externalBookingUuid={externalBookingUuid}
					refetchCallback={refetchCallback}
					externalDelivery={externalDelivery}
				/>
			</Dialog>
		</>
	);
};

DriverDialog.propTypes = {
	deliveryType: string,
	externalBookingUuid: string,
	externalDelivery: object,
	changeButtonOutlined: bool,
	refetchCallback: func,
};
