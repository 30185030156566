import React from 'react';
import { bool } from 'prop-types';
import { Form } from 'react-final-form';

// Import utils and helpers
import { useCommentsBox } from './useCommentsBox';
import { validationSchema } from './validation';

// Import components
import { Comment, Comments } from './components';
import { Card } from 'components/elements';
import { yupValidator } from 'helpers';

export const CommentsBox = ({ adminsComments }) => {
	const {
		isDiscussionVisible,
		isLoading,
		discussions,
		onSubmit,
		handleOnSubmit,
		cardTitle,
	} = useCommentsBox({ adminsComments });

	return (
		<Card lg={6} title={cardTitle}>
			{isDiscussionVisible && <Comments discussions={discussions} />}
			<Form
				onSubmit={handleOnSubmit}
				validate={(values) =>
					yupValidator({
						validationSchema: validationSchema(),
						values,
					})
				}
				render={(formData) => (
					<form
						onSubmit={(event) => {
							onSubmit({ event, ...formData });
						}}
					>
						<Comment isLoading={isLoading} />
					</form>
				)}
			/>
		</Card>
	);
};

CommentsBox.propTypes = {
	adminsComments: bool,
};
