import { last } from 'lodash';
import services from 'services/services';
import {
	fetchCampaignsUrl,
	updateActiveCampaignUrl,
	campaignsExportUrl,
} from 'store/paths';

export const fetchCampaigns =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			// Get response
			const { data } = await services.post(
				fetchCampaignsUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const fetchCampaignsExportData =
	({ options, cancelToken, queryParams }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			if (pageSize === 1) {
				sessionStorage.removeItem('prevKeys');
			}

			const parsedBodyKeys = JSON.parse(sessionStorage.getItem('prevKeys'));

			const bodyKeys = pageIndex !== 0 ? parsedBodyKeys : {};

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
				...bodyKeys,
			};

			// Get response
			const { data } = await services.post(
				campaignsExportUrl(queryParams),
				body,
				cancelToken
			);

			const lastDataItem = last(data.data);

			// prevKeys required in the next request calculations
			const prevKeys = {
				previous_total_deposit: +lastDataItem?.total_wallet_deposit,
				previous_total_withdraw: +lastDataItem?.total_wallet_withdraw,
			};

			sessionStorage.setItem('prevKeys', JSON.stringify(prevKeys));

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActiveCampaign =
	({ isActive, id }) =>
	async () => {
		await services.put(updateActiveCampaignUrl(id, isActive));
	};
