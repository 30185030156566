// Import queries
import { useCampaignBookings, useCampaignCustomers } from 'queries';

// Import helpers
import { CAMPAIGN_TYPES } from 'helpers';
import { CAMPAIGN_CONSUMER_TYPES } from '../../helpers';

export const useCampaignConsumersModal = ({ campaign }) => {
	const { uuid: campaignUuid, campaign_type: campaignType } = campaign;

	const {
		isFetching: isBookingsFetching,
		isError: isBookingsError,
		data: bookings,
	} = useCampaignBookings({
		campaignUuid,
		campaignType,
	});

	const {
		isFetching: isCustomersFetching,
		isError: isCustomersError,
		data: customers,
	} = useCampaignCustomers({
		campaignUuid,
		campaignType,
	});

	const isFetching = isBookingsFetching || isCustomersFetching;

	const isError = isBookingsError || isCustomersError;

	let type, consumersList;

	if (campaignType === CAMPAIGN_TYPES.CUSTOMER_REGISTERED) {
		consumersList = customers;
		type = CAMPAIGN_CONSUMER_TYPES.CUSTOMERS;
	} else {
		consumersList = bookings;
		type = CAMPAIGN_CONSUMER_TYPES.BOOKINGS;
	}

	return {
		isFetching,
		isError,
		consumersList,
		type,
	};
};
