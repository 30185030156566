/* eslint-disable react/prop-types */
import React from 'react';
import { Grid } from '@material-ui/core';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { useContent } from './useContent';
import { COLORS } from './helpers';

// Import components
import { AlertMessage, LoadingWrapper } from 'components/elements';

// Import styles
import { useStyles } from './Content.styles';

export const Content = () => {
	const { data, isLoading, isError, renderLegend } = useContent();
	const { t } = useTranslations();
	const classes = useStyles();

	if (isLoading) return <LoadingWrapper />;

	if (isError)
		return (
			<AlertMessage
				title={t('common.error')}
				description={t('common.messages.error_message')}
			/>
		);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<ResponsiveContainer width="100%" height={400}>
					<PieChart className={classes.legend}>
						<Pie
							data={data}
							cx="50%"
							cy="50%"
							labelLine={false}
							outerRadius={100}
							innerRadius={50}
							fill="#8884d8"
							dataKey="value"
							label
						>
							{data.map((_, index) => (
								<Cell
									key={`cell-${index}`}
									fill={COLORS[index % COLORS.length]}
								/>
							))}
						</Pie>
						<Legend content={renderLegend} margin={{ top: '60px' }} />
					</PieChart>
				</ResponsiveContainer>
			</Grid>
		</Grid>
	);
};
