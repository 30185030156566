export const FUEL_TYPES = ['diesel', 'gas_91', 'gas_95', 'electric'];

export const OIL_TYPES = ['petromin', 'mobil', 'shell', 'none'];

export const SEAT_TYPES = ['leather', 'fabric'];

export const LEASING_CAR_SPECIFICATION = [
	'front_camera',
	'usb',
	'apple_car_play',
	'bluetooth',
	'self_driving_car',
	'sensors',
	'cruise_control',
	'rear_camera',
	'panorama',
];

export const MILES_USAGE_FLAG = 'miles-usage-flag';
