import { Filters } from '../components';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Filters,
		customKey: 'filters',
		componentProps: {
			showAssignedDriverAdditional: true,
			showDeliveryStatusAdditional: true,
		},
	},
];
