import React from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import helpers
import { useStyles } from './styles';

// Import components
import { SignInSideBanner } from 'components/elements';
import ResetPasswordForm from './form';

const ResetPassword = ({ history }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid className={classes.grid} container>
				<SignInSideBanner classes={classes} />
				<Grid className={classes.content} item lg={7} xs={12}>
					<div className={classes.content}>
						<div className={classes.contentBody}>
							<ResetPasswordForm history={history} />
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

ResetPassword.propTypes = {
	history: object,
};

export default withRouter(ResetPassword);
