import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import get from 'lodash/get';

// Import helpers
import { columns } from '../columns';

const CarsLogHistoryRow = ({ row }) => {
	return (
		<TableRow>
			{columns.map(({ accessor }) => {
				switch (accessor) {
					default:
						return <TableCell key={accessor}>{get(row, accessor)}</TableCell>;
				}
			})}
		</TableRow>
	);
};

CarsLogHistoryRow.propTypes = {
	row: PropTypes.object,
};

export default CarsLogHistoryRow;
