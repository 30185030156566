import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	companyUuid: null,
	officeUuid: null,
	vehicleUuid: null,
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.RESET_COMPANY_UUID_CONTEXT:
				return INITIAL_STATE;

			case types.SET_COMPANY_UUID_CONTEXT:
				draft.companyUuid = action.payload;
				break;

			case types.SET_OFFICE_UUID_CONTEXT:
				draft.officeUuid = action.payload;
				break;

			case types.SET_VEHICLE_UUID_CONTEXT:
				draft.vehicleUuid = action.payload;
				break;

			default:
				return state;
		}
	});
