import React from 'react';
import { bool, object } from 'prop-types';
import { Form } from 'react-final-form';
import {
	TableCell,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Box,
	Grid,
} from '@material-ui/core';

// Import utilities
import useMarkAsCompensatedTableCell from './useMarkAsCompensatedTableCell';
import { useTranslations } from 'components/utilities';
import useDialog from './useDialog';

// Import components
import { Fields } from './components';
import {
	CompensationHistoryFields,
	FulfilledCompensation,
} from 'components/elements';

// Import helpers
import { INITIAL_VALUES } from './helpers';

// Import styles
import { useStyles } from './styles';

export const MarkAsCompensatedTableCell = ({
	cell,
	showFullFilledCell = false,
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { isOpen, onClose, onOpen } = useDialog();

	const { compensation, onSubmit } = useMarkAsCompensatedTableCell({
		cell,
		onClose,
	});

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container direction="column" className={classes.container}>
				{showFullFilledCell && (
					<Grid item className={classes.fullFilledContent}>
						<FulfilledCompensation cell={cell} />
					</Grid>
				)}
				<Grid item>
					<Button
						color="primary"
						onClick={onOpen}
						variant="contained"
						size="small"
						className={classes.actionButton}
					>
						{t('validation.table.action.mark_as_compensated')}
					</Button>
				</Grid>
			</Grid>

			<Dialog fullWidth open={isOpen} onClose={onClose}>
				<DialogTitle>{t('bookings.close_compensation_form.title')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Form
							onSubmit={onSubmit}
							initialValues={INITIAL_VALUES}
							render={({ handleSubmit }) => (
								<form onSubmit={handleSubmit}>
									<CompensationHistoryFields
										compensation={compensation}
										isInAccountingValidationPage
									/>
									<Fields onClose={onClose} />
								</form>
							)}
						/>
					</Box>
				</DialogContent>
			</Dialog>
		</TableCell>
	);
};

MarkAsCompensatedTableCell.propTypes = {
	cell: object.isRequired,
	showFullFilledCell: bool,
};
