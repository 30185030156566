import React from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Import components
import { Card } from 'components/elements';

import {
	BookingIdCardContainer,
	BookingInfoCardAction,
	BookingStatusCardContainer,
	BookingUrgentCardContainer,
} from './components/';

// Import translations
import { useTranslations } from 'components/utilities';

const BookingInfoCard = () => {
	const { t } = useTranslations();

	const theme = useTheme();
	const isMdOrBigger = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Card
			title={t('bookings.preview_booking.booking_info.title')}
			actions={<BookingInfoCardAction />}
			lg={12}
		>
			<Grid container spacing={3}>
				<BookingIdCardContainer isMdOrBigger={isMdOrBigger} />

				<BookingStatusCardContainer isMdOrBigger={isMdOrBigger} />

				<BookingUrgentCardContainer />
			</Grid>
		</Card>
	);
};

export default BookingInfoCard;
