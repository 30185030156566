export default {
	root: {
		padding: '1em !important',
		borderRadius: 8,
		'&:hover': {
			backgroundColor: '#F7F7F7',
		},
		'&$selected': {
			backgroundColor: '#DCDFE3',
		},
	},
};
