import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { array, string } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

// Import helpers
import { services } from 'helpers';

// Import components
import { Template } from '../Template';

export const AdditionalServices = ({ label, options }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const filteredServices = services.filter(({ type }) =>
		options.map((option) => option.type).includes(type)
	);

	return (
		<Template label={label}>
			<Box className={classes.serviceWrapper}>
				{filteredServices.map(({ type, icon: Icon }) => (
					<Box className={classes.serviceItem} key={type}>
						<Icon className={classes.icon} />
						<Typography className={classes.serviceName}>
							{t(`bookings.invoice_print.additional_service.${type}`)}
						</Typography>
					</Box>
				))}
			</Box>
		</Template>
	);
};

AdditionalServices.propTypes = {
	label: string,
	options: array,
};
