import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { queryCache } from 'react-query';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	CircularProgress,
	Box,
	MenuItem,
} from '@material-ui/core';
import { Field, useFormState, useForm } from 'react-final-form';
import { useCompanies } from 'queries';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { COMPANY_ASSIGNMENT_FIELDS } from './helpers';
import { COMPANY_COLLECTION_QUERY } from 'helpers';

// Import styles
import useStyles from '../../styles';

const CompanyAssignment = ({ section, className, ...rest }) => {
	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const classes = useStyles();
	const { submitting, values } = useFormState();

	const { change } = useForm();

	const {
		company: { name: companyName },
	} = values;

	const { data: companies, isFetching } = useCompanies();

	useEffect(() => {
		const companyUuid =
			companyName &&
			companies &&
			companies.find(({ name }) => name[lng] === companyName).uuid;

		change('company.uuid', companyUuid);

		return () => {
			queryCache.cancelQueries(COMPANY_COLLECTION_QUERY, { exact: true });
			queryCache.removeQueries(COMPANY_COLLECTION_QUERY, { exact: true });
		};
		//eslint-disable-next-line
	}, [companyName]);

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('discounts.form.company.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{!isFetching ? (
						COMPANY_ASSIGNMENT_FIELDS.map(
							({ name, label, type, component, md, xs }) => (
								<Grid key={name} item md={md} xs={xs}>
									<Field
										fullWidth
										component={component}
										type={type}
										label={t(label)}
										name={`${section}.${name}`}
										disabled={submitting}
										margin="dense"
										variant="outlined"
										required
									>
										{companies.map(({ name, uuid }) => (
											<MenuItem key={uuid} value={name[lng]}>
												{name[lng]}
											</MenuItem>
										))}
									</Field>
								</Grid>
							)
						)
					) : (
						<Box className={classes.loader}>
							<CircularProgress size={33} color="primary" />
						</Box>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

CompanyAssignment.propTypes = {
	section: string.isRequired,
	className: string,
};

export default CompanyAssignment;
