import * as yup from 'yup';
import { t } from 'i18next';

// Import helpers
import { VALID_SUBDOMAIN } from 'helpers';

const lazy = (value) => {
	switch (typeof value) {
		case 'object':
			return yup.object().required(t('common.fields.required_field'));
		case 'string':
			return yup.string().required(t('common.fields.required_field'));
		default:
			return yup.mixed().required(t('common.fields.required_field'));
	}
};

export const validationSchema = yup.object({
	company_uuid: yup.string().required(t('common.fields.required_field')),
	subdomain: yup
		.string()
		.required(t('common.fields.required_field'))
		.matches(VALID_SUBDOMAIN, t('common.fields.invalid_subdomain')),
	header_title: yup.object({
		en: yup.string().required(t('common.fields.required_field')),
		ar: yup.string().required(t('common.fields.required_field')),
	}),
	header_content: yup.object({
		en: yup.string().required(t('common.fields.required_field')),
		ar: yup.string().required(t('common.fields.required_field')),
	}),
	light_color_hex: yup.lazy(lazy),
	main_color_hex: yup.lazy(lazy),
	filters: yup.object({
		default_location: yup.object({
			name: yup.string().required('common.fields.required_field'),
		}),
	}),
	images: yup.object({
		banner: yup.object({
			file_path: yup.string().required(t('common.fields.required_field')),
			file_name: yup.string().required(t('common.fields.required_field')),
		}),
		logo: yup.object({
			file_path: yup.string().required(t('common.fields.required_field')),
			file_name: yup.string().required(t('common.fields.required_field')),
		}),
	}),
});
