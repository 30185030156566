import i18next from 'i18next';

// Import helpers
import { AR, PAYMENT_METHODS } from 'helpers';

export const extendedColumns = (isAdmin = false) => [
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'individual_number',
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: 'customer_name',
	},
	{
		Header: 'bookings.table.headers.customer_phone',
		accessor: 'customer_phone',
	},
	{
		Header: 'bookings.table.headers.customer_type',
		accessor: 'customer_type',
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'bookings.table.headers.office_name',
		accessor: 'office_name',
	},
	{
		Header: 'bookings.table.headers.agent_name',
		accessor: 'blamable_full_name',
	},
	{
		Header: 'bookings.table.headers.office_city',
		accessor: 'office_city',
	},

	{
		Header: 'bookings.table.headers.pick_date',
		accessor: 'pick_date',
	},
	{
		Header: 'bookings.table.headers.pick_time',
		accessor: 'pick_time',
	},
	{
		Header: 'bookings.table.headers.created_time',
		accessor: 'created_at',
	},
	{
		Header: 'bookings.table.headers.status',
		accessor: 'state',
	},
	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: 'cancel_description',
	},
	{
		Header: 'bookings.table.headers.delivery',
		accessor: 'delivery_price',
	},
	{
		Header: 'bookings.table.headers.payment_method',
		accessor: 'payment.method',
		valueExtractor: ({ payment }) => {
			const { method, method_type } = payment;
			const sectionName = 'bookings.preview_booking.invoice';

			const paymentMethodLabel = i18next.t(`${sectionName}.${method}`);

			const paymentTypeLabel = i18next.t(
				`${sectionName}.method_type.${method_type}`
			);

			const prePaidLabel = i18next.t(`${sectionName}.pre_paid`);

			switch (method) {
				case PAYMENT_METHODS.CARD:
					return isAdmin ? paymentMethodLabel : prePaidLabel;

				case PAYMENT_METHODS.WALLET:
					return isAdmin ? paymentTypeLabel : prePaidLabel;

				default:
					return paymentMethodLabel;
			}
		},
	},
	{
		Header: 'bookings.table.headers.customer_nationality',
		accessor: 'customer_nationality',
	},
	{
		Header: 'bookings.table.headers.gender',
		accessor: 'gender',
	},
	{
		Header: 'bookings.table.headers.drop_date',
		accessor: 'drop_date',
	},
	{
		Header: 'bookings.table.headers.pick_location',
		accessor: 'pick_location',
	},
	{
		Header: 'bookings.table.headers.drop_location',
		accessor: 'drop_location',
	},
	{
		Header: 'bookings.table.headers.total_base_nett_price',
		accessor: 'total_base_nett_price',
	},
	{
		Header: 'bookings.table.headers.total_amount_paid_by_customer',
		accessor: 'total_discounted_gross_price',
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: 'vehicle',
	},
	{
		Header: 'bookings.table.headers.booking_no',
		accessor: 'customer_no_booking',
		valueExtractor: ({ customer_no_booking }) => +customer_no_booking,
	},
	{
		Header: 'bookings.table.headers.booking_time',
		accessor: 'respond_time',
	},
	{
		Header: 'bookings.table.headers.duration',
		accessor: 'response_time',
		valueExtractor: ({ response_time }) =>
			response_time
				? `${response_time} ${i18next.t('bookings.table.common.minutes')}`
				: '',
	},
	{
		Header: 'bookings.table.headers.updated_time',
		accessor: 'updated_at',
	},
	{
		Header: 'bookings.table.headers.approved',
		accessor: 'approved',
		valueExtractor: ({ approved }) =>
			i18next.t(`common.${approved ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.admins_comment',
		accessor: 'admin_discuss',
		valueExtractor: (row) => {
			const adminComments = row.admin_discuss || [];

			const adminCommentsString = adminComments
				.map(
					({ added_at, content, full_name }) =>
						`${full_name}: (${added_at}): ${content}`
				)
				.join(', \n');

			return adminCommentsString;
		},
	},
	{
		Header: 'bookings.table.headers.license_number',
		accessor: 'license_number',
	},
	{
		Header: 'bookings.table.headers.compensated',
		accessor: 'compensated',
		valueExtractor: ({ is_compensation, compensation_amount }) =>
			is_compensation ? `${compensation_amount} SAR` : 'N/A',
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'office_rating',
	},
	{
		Header: 'bookings.table.headers.rating_reason',
		accessor: 'rating_reason',
		valueExtractor: (row) => {
			const ratingReasons = row.rating_reasons || [];

			const ratingReasonsString = ratingReasons
				.map(({ reason_en, reason_ar }) =>
					i18next.language === AR ? reason_ar : reason_en
				)
				.join(', ');

			return ratingReasonsString;
		},
	},
	{
		Header: 'bookings.table.headers.customer_email',
		accessor: 'customer_email',
	},
	{
		Header: 'bookings.table.headers.office_address',
		accessor: 'office_address',
	},
	{
		Header: 'bookings.table.headers.settlement_status',
		accessor: 'settlement_status',
		valueExtractor: ({ is_settled }) =>
			i18next.t(`common.${is_settled ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.table.headers.kiosk',
		accessor: 'is_kiosk',
		valueExtractor: ({ is_kiosk }) =>
			i18next.t(`common.${is_kiosk ? 'yes' : 'no'}`),
	},
	{
		Header: 'bookings.preview_booking.period.contract_opened_at',
		accessor: 'contract_opened_at',
	},
	{
		Header: 'bookings.preview_booking.period.contract_closed_at',
		accessor: 'contract_closed_at',
	},
	{
		Header: 'bookings.preview_booking.period.title',
		accessor: 'number_of_days',
	},
	{
		Header: 'bookings.table.headers.edited_by_customer',
		accessor: 'manipulation',
	},
	{
		Header: 'bookings.table.headers.difference_amount',
		accessor: 'edit_booking_gross_price_diff',
		valueExtractor: (row) => {
			const difference = +row.edit_booking_gross_price_diff;
			const convertedDifference = (difference < 0 ? '' : '+') + difference;

			return convertedDifference;
		},
	},
	{
		Header: 'bookings.table.headers.previous_company',
		accessor: 'old_company',
	},
	{
		Header: 'bookings.table.headers.previous_office',
		accessor: 'old_office',
	},
	{
		Header: 'bookings.table.headers.previous_car',
		accessor: 'old_car',
	},
	{
		Header: 'bookings.table.headers.previous_pickup_date',
		accessor: 'old_pickup_time',
	},
	{
		Header: 'bookings.table.headers.previous_drop_off_date',
		accessor: 'old_drop_date',
	},
	{
		Header: 'bookings.table.headers.previous_total_price',
		accessor: 'old_total_gross_price',
	},
	{
		Header: 'bookings.table.headers.extension_request_date',
		accessor: 'extension.extension_request_date',
	},
	{
		Header: 'bookings.table.headers.duration_of_extension',
		accessor: 'extension.extension_days',
	},
	{
		Header: 'bookings.table.headers.total_duration',
		accessor: 'total_duration',
		valueExtractor: (row) => {
			return `${row.pick_date} - ${row.drop_date}`;
		},
	},
	{
		Header: 'bookings.table.headers.extension_gross_price',
		accessor: 'extension.extension_amount_gross',
		valueExtractor: (row) => {
			return (
				row.extension.extension_amount_gross &&
				i18next.t('currency.price', {
					amount: row.extension.extension_amount_gross,
				})
			);
		},
	},
	{
		Header: 'bookings.table.headers.original_booking_total_price',
		accessor: 'original_booking_total_price',
		valueExtractor: (row) => {
			const totalDiscountedGrossPrice = row.total_discounted_gross_price;
			const extensionAmountGross = row.extension.extension_amount_gross;
			const canShow = totalDiscountedGrossPrice && extensionAmountGross;

			const amount = (totalDiscountedGrossPrice - extensionAmountGross).toFixed(
				2
			);
			const totalExtensionAmount = i18next.t('currency.price', {
				amount,
			});

			return canShow && totalExtensionAmount;
		},
	},
	{
		Header: 'bookings.table.headers.total_amount_paid_online',
		accessor: 'partial_payment_online_paid_amount',
	},
	{
		Header: 'bookings.table.headers.total_amount_paid_wallet',
		accessor: 'partial_payment_wallet_paid_amount',
	},
	{
		Header: 'bookings.table.headers.price_after_close_contract',
		accessor: 'contract_total_gross_price',
	},
	{
		Header: 'bookings.table.headers.service_type',
		accessor: 'service_type',
	},
];
