import React from 'react';
import Typography from '@material-ui/core/Typography';
import { oneOfType, number, string } from 'prop-types';

// Import components
import { ChipLabel } from 'components/elements/atoms';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles.js';

export const CarUsageFlag = ({ milesUsage }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const usageInfo =
		parseInt(milesUsage, 10) > 99
			? 'leasing_cars.form.car_specifications.usage.used'
			: 'leasing_cars.form.car_specifications.usage.new';

	return (
		<div className={classes.usageWrapper}>
			<ChipLabel
				label={t('leasing_cars.form.car_specifications.usage.car_status')}
				size="small"
				className={classes.usageLabel}
			/>
			<Typography align="center" className={classes.usageIndicator}>
				{t(usageInfo)}
			</Typography>
		</div>
	);
};

CarUsageFlag.propTypes = {
	milesUsage: oneOfType([string, number]),
};
