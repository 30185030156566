import React from 'react';
import { SnackbarProvider } from 'notistack';
import { node } from 'prop-types';

const NotificationsProvider = ({ children }) => (
	<SnackbarProvider
		maxSnack={3}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
	>
		{children}
	</SnackbarProvider>
);

NotificationsProvider.propTypes = {
	children: node,
};

export default NotificationsProvider;
