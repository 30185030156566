import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import get from 'lodash/get';

// Import helpers
import { columns } from '../columns';

const BookingHistoryRow = ({ row }) => {
	return (
		<>
			<TableRow>
				{columns.map(({ accessor }) => {
					switch (accessor) {
						default:
							return <TableCell key={accessor}>{get(row, accessor)}</TableCell>;
					}
				})}
			</TableRow>
		</>
	);
};

BookingHistoryRow.propTypes = {
	row: PropTypes.object,
};

export default BookingHistoryRow;
