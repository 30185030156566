import React from 'react';
import { object } from 'prop-types';

// Import components
import PreviewField from '../PreviewField';

// Import helpers
import { filterOptions } from './helpers';
import { getPrice } from './helpers';

// Import utilities
import { useProfile } from 'components/utilities';

export const InvoiceOptionsPreviewField = ({ customData }) => {
	const { isAdmin } = useProfile();

	const { options } = customData || {};
	const filteredOptions = filterOptions({ isAdmin, options });

	if (!filteredOptions) return null;

	return options.map((option) => {
		const type = option.type;

		return (
			<PreviewField key={type} label={`bookings.options.${type}`}>
				{getPrice(option)}
			</PreviewField>
		);
	});
};

InvoiceOptionsPreviewField.propTypes = {
	customData: object,
};
