import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';

// Import styles
import useStyles from './styles';

// Import utilities
import { useTranslations } from 'components/utilities';

export const SubHeader = ({ value }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	return (
		<Typography
			color="textSecondary"
			variant="subtitle2"
			className={classes.text}
		>
			{t('common.total')}{' '}
			<Box className={classes.strong} component="strong">
				{value}
			</Box>
		</Typography>
	);
};

SubHeader.propTypes = {
	value: oneOfType([number, string]).isRequired,
};
