import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// Import components
import { TwoHeadedTableBodyCell } from 'components/elements';
import { DropCityPriceInput } from 'views/DropCities/components';

// Import styles
import useStyles from './styles';

const DropCitiesTableBodyCell = ({
	style,
	dropCity,
	onPriceClear,
	onPriceChange,
	onEdit,
}) => {
	const classes = useStyles();

	if (!dropCity) {
		return <TwoHeadedTableBodyCell style={style} />;
	}

	const { price, isEdited, cityFrom, cityTo } = dropCity;

	if (cityFrom.uuid === cityTo.uuid) {
		return <TwoHeadedTableBodyCell style={style} />;
	}

	const isValidPrice = !isNaN(parseFloat(price));

	const priceFormatted = isValidPrice ? `${price} SAR` : '';

	return (
		<TwoHeadedTableBodyCell style={style}>
			<Box className={classes.wrapper}>
				{isEdited && (
					<DropCityPriceInput
						value={price}
						onClear={onPriceClear}
						onChange={onPriceChange}
					/>
				)}
				{!isEdited && (
					<>
						<Typography variant="h5" component="span" className={classes.price}>
							{priceFormatted}
						</Typography>

						<IconButton onClick={onEdit}>
							<EditIcon />
						</IconButton>
					</>
				)}
			</Box>
		</TwoHeadedTableBodyCell>
	);
};

DropCitiesTableBodyCell.propTypes = {
	style: PropTypes.object.isRequired,
	dropCity: PropTypes.shape({
		cityFrom: PropTypes.shape({
			uuid: PropTypes.string,
		}),
		cityTo: PropTypes.shape({
			uuid: PropTypes.string,
		}),
		price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		isEdited: PropTypes.bool,
	}),
	onPriceClear: PropTypes.func.isRequired,
	onPriceChange: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
};

export default DropCitiesTableBodyCell;
