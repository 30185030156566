import React from 'react';
import { object, string } from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';

// Import utils
import { useLocationContainer } from './useLocationContainer';
import { useTranslations } from 'components/utilities';

// Import components
import { Stepper, DeliveryLocationMap } from '../../components';
import { Alert } from 'components/elements';

// Import styles
import { useStyles } from '../../Steps.styles';

export const LocationContainer = ({ deliveryType, customDeliveryData }) => {
	const { isFetched, isError, states, deliveryData } = useLocationContainer({
		deliveryType,
		customStepsData: customDeliveryData
			? { ...customDeliveryData[deliveryType].steps, isFetched: true }
			: null,
	});

	const { t } = useTranslations();

	const classes = useStyles();

	if (!isFetched) {
		return <CircularProgress size={28} />;
	}

	if (isError) {
		return <Alert message={t('common.messages.error_message')} />;
	}

	if (states?.length <= 1) {
		return null;
	}

	return (
		<Box className={classes.locationContainer}>
			<Stepper
				deliveryData={deliveryData}
				customDeliveryData={customDeliveryData}
			/>
			{!customDeliveryData && (
				<DeliveryLocationMap deliveryData={deliveryData} />
			)}
		</Box>
	);
};

LocationContainer.propTypes = {
	deliveryType: string,
	customDeliveryData: object,
};
