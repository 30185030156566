import { AUTO_COMPENSATION, IS_URGENT_ASSISTANCE } from 'helpers/variables';

const keysShouldBeRemoved = [IS_URGENT_ASSISTANCE, AUTO_COMPENSATION];

export const convertSubmitData = (values) => {
	let newValues = Object.keys(values)
		.filter((key) => !keysShouldBeRemoved.includes(key))
		.reduce((obj, key) => {
			obj[key] = values[key];
			return obj;
		}, {});

	if (!values.auto_compensation) {
		newValues.compensation_formula = null;
		newValues.compensation_value = null;
		newValues.compensation_max_value = null;
	}

	if (!values.is_urgent_assistance) {
		newValues.urgent_assistance_reason_uuid = null;
	}

	if (!values.use_for_mobile) {
		const { name } = values.details;

		newValues = {
			...newValues,
			details: {
				...newValues.details,
				name_mobile_app: {
					...name,
				},
			},
		};
	}

	return newValues;
};
