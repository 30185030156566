import { useMemo, useCallback } from 'react';
import * as dateFns from 'date-fns';
import { useForm, useFormState } from 'react-final-form';

// Import helpers
import { getMonthRange } from './helpers';

const usePeriodButtons = () => {
	const {
		values: { period }
	} = useFormState();

	const { change } = useForm();

	const setPeriodForMonth = (date) => {
		const [startDate, endDate] = getMonthRange(date);

		change('period', {
			...period,
			from: startDate,
			until: endDate
		});
	};

	const handleLastMonth = () => {
		const prevMonthDate = dateFns.subMonths(new Date(), 1);

		setPeriodForMonth(prevMonthDate);
	};

	const handleCurrentMonth = () => {
		setPeriodForMonth(new Date());
	};

	const isMonthRange = useCallback((date) => {
		const [startDate, endDate] = getMonthRange(date);
		
		return (
			dateFns.isSameDay(startDate, period.from) &&
			dateFns.isSameDay(endDate, period.until)
		);
	}, [period]);

	const isLastMonth = useMemo(() => {
		const prevMonthDate = dateFns.subMonths(new Date(), 1);

		return isMonthRange(prevMonthDate);
	}, [isMonthRange]);

	const isCurrentMonth = useMemo(() => {
		return isMonthRange(new Date());
	}, [isMonthRange]);

	return {
		isLastMonth,
		isCurrentMonth,
		handleCurrentMonth,
		handleLastMonth
	};
};

export default usePeriodButtons;
