import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { updateActivateCity } from 'store/actions';

// Import helpres
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { ActiveTableCell } from 'components/elements';

const { ACTIVE } = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActivateCity}
						cell={cell}
					/>
				);
			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
