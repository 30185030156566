import React from 'react';
import { object, func, string } from 'prop-types';
import { TableCell, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';

// Import utilities
import { useMessage, useTranslations } from 'components/utilities';

// Import components
import { useTableContextProvider } from 'components/context';
import { PopConfirm } from 'components/elements/molecules';

// Import styles
import useStyles from './styles';

const DeleteTableCell = ({
	cell,
	deleteAction = () => {},
	customFetchData,
	id = cell.row.original.uuid,
}) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { message } = useMessage();
	const dispatch = useDispatch();

	const { fetchData } = useTableContextProvider();

	const handleDeleteItem = async () => {
		try {
			await deleteAction({ id })(dispatch);
			fetchData();
			customFetchData && customFetchData();
		} catch (error) {
			if (error?.response) {
				const errorData = error.response.data;
				const errorMessage =
					errorData.message ?? errorData.error ?? errorData.errors.uuid;
				message.error(errorMessage);
			}
		}
	};

	return (
		<TableCell key={id}>
			<PopConfirm onConfirm={handleDeleteItem}>
				<Button className={classes.cell} color="primary">
					<DeleteIcon className={classes.icon} fontSize="small" />
					{t('table.modal.delete.btn_delete')}
				</Button>
			</PopConfirm>
		</TableCell>
	);
};

DeleteTableCell.propTypes = {
	cell: object,
	deleteAction: func,
	customFetchData: func,
	id: string,
};

export default DeleteTableCell;
