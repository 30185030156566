import _ from 'lodash';

export const INITIAL_VALUES = {
	offices: {},
};

export const getChangedOffices = ({ values, storedFormValues }) => {
	const convertedOffices = Object.entries(values.offices);
	return convertedOffices.reduce((acc, [officeUuid, cars]) => {
		const changed = !_.isEqual(cars, storedFormValues.offices[officeUuid]);
		return changed ? [...acc, officeUuid] : acc;
	}, []);
};
