import * as dateFns from 'date-fns';

export const PERIOD_TYPES = {
	TODAY: 'today',
	TOMORROW: 'tomorrow',
	AFTER_TOMORROW: 'after_tomorrow',
};

export const PERIODS = [
	{
		label: 'scheduled_bookings.table.legend.periods.today',
		type: PERIOD_TYPES.TODAY,
		isActive: (date) => dateFns.isToday(date),
	},
	{
		label: 'scheduled_bookings.table.legend.periods.tomorrow',
		type: PERIOD_TYPES.TOMORROW,
		isActive: (date) => dateFns.isTomorrow(date),
	},
	{
		label: 'scheduled_bookings.table.legend.periods.after_tomorrow',
		type: PERIOD_TYPES.AFTER_TOMORROW,
		isActive: (date) =>
			dateFns.isAfter(dateFns.startOfDay(dateFns.addDays(date, 2)), date),
	},
];

export const findPeriod = (date) => {
	const parseDate = (date) => dateFns.parse(date, 'yyyy-MM-dd', new Date());

	return PERIODS.find(({ isActive }) => isActive(parseDate(date)));
};
