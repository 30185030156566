export const EMPTY_FIELDS = {
	company: {
		uuid: '',
		name: '',
	},
	organization: {
		uuid: '',
	},
	discount: {
		discount: '',
		max_discount: '',
	},
};
