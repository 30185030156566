import i18next from 'i18next';

export const columns = [
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'rewards_settlement.table.headers.employee_name',
		accessor: 'user_name',
	},
	{
		Header: 'rewards_settlement.table.headers.employee_status',
		accessor: 'user_is_active',
		valueExtractor: ({ user_is_active }) =>
			i18next.t(`common.filters.${user_is_active ? 'active' : 'inactive'}`),
	},
	{
		Header: 'rewards_settlement.table.headers.phone_number',
		accessor: 'user_phone',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'booking_individual_number',
	},
	{
		Header: 'rewards_settlement.table.headers.rating',
		accessor: 'user_rating',
	},
	{
		Header: 'rewards_settlement.table.headers.earnings',
		accessor: 'earnings',
	},
	{
		Header: 'rewards_settlement.settle',
		accessor: 'is_settled',
		valueExtractor: ({ is_settled }) =>
			i18next.t(`rewards_settlement.${is_settled ? 'settled' : 'not_settled'}`),
	},
	{
		Header: 'rewards_settlement.settled_on',
		accessor: 'settled_date',
		disableSortBy: true,
	},
];
