import * as types from 'store/types';
import services from 'services/services';
import { registrationProcessUrl } from 'store/paths';

// Import helpers
import { catchError } from '../helpers';

export const fetchRegistrationProcessFormResources = ({
	cancelToken,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.REGISTRATION_PROCESS_LOADING,
		});

		const { data } = await services.get(registrationProcessUrl, cancelToken);

		dispatch({
			type: types.REGISTRATION_PROCESS_SUCCESS,
			payload: {
				item: data.data || {},
			},
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.REGISTRATION_PROCESS_ERROR,
		});
	}
};

export const submitRegistrationProcessForm = ({
	values,
	callbackSuccess,
	errorCallback,
}) => async (dispatch) => {
	try {
		await services.post(registrationProcessUrl, { ELM: values.ELM });

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
		catchError({
			error,
			dispatch,
			type: types.REGISTRATION_PROCESS_ERROR,
		});
	}
};
