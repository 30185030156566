import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import {
	CAR_SEARCH_CARS_QUERY,
	convertDate,
	DEFAULT_DATE_FORMAT,
} from 'helpers';

const useCars = ({ bookingUuid, filters, values }) => {
	const path = `/v2/booking/${bookingUuid}/items/search`;

	const { pick_date, pick_time, drop_date } = values?.period || {};

	const body = {
		...filters,
		pickup_date: convertDate(
			`${convertDate(pick_date, DEFAULT_DATE_FORMAT)} ${pick_time}`
		),
		drop_date: convertDate(drop_date, DEFAULT_DATE_FORMAT),
	};

	const { data, isFetching, refetch } = useQuery(
		[CAR_SEARCH_CARS_QUERY, JSON.stringify(body)],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, refetch };
};

export default useCars;
