import React from 'react';
import { object } from 'prop-types';
import clsx from 'clsx';
import { TableCell, TableRow } from '@material-ui/core';
import { updateStatusScheduleBooking } from 'store/actions';

// Import helpers and utils
import {
	replaceEditBookingUrl,
	replaceOfficeDetailsUrl,
	isRejectVisible,
	replacePreviewBookingUrl,
	DATE_TYPES,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';
import { findPeriod } from '../Legend/helpers';
import { useTableContextProvider } from 'components/context';
import { useProfile } from 'components/utilities';

// Import components
import { DeliveryAssignmentTableCell, TopicTableCell } from './components';
import {
	StatusTableCell,
	NoteTableCell,
	LinkTableCell,
	ActionsTableCell,
	PickDateTableCell,
	DropDateTableCell,
	DeliveryStatusTableCell,
} from 'components/elements';

// Import styles
import { useStyles } from '../Legend/styles';

const {
	BOOKING_ID,
	STATUS,
	OFFICE_NAME,
	NOTE,
	CUSTOMER_NAME,
	ACTIONS,
	PICK_DATE_TIME,
	DROP_DATE_TIME,
	DELIVERY_ASSIGNMENT,
	DELIVERY_STATUS,
	TOPIC,
} = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const { resources, filters, fetchData } = useTableContextProvider();
	const classes = useStyles({ isBodyRow: true });

	const { isAdmin } = useProfile();

	const { date_type } = filters || {};

	const { drop_date, pick_date } = row.original;

	const { DROP_DATE } = DATE_TYPES;

	const date = date_type === DROP_DATE ? drop_date : pick_date;

	const isDropOffSort = date_type === DROP_DATE;

	const period = findPeriod(date);

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case NOTE:
				return (
					<NoteTableCell
						key={cell.column.id}
						cell={cell}
						isTwoInRow
						showCustomerLocation
						minWidth={120}
					/>
				);

			case TOPIC:
				return <TopicTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<StatusTableCell
						key={cell.column.id}
						acceptAction={updateStatusScheduleBooking}
						isRejectVisible={isRejectVisible(cell, resources)}
						cell={cell}
					/>
				);

			case OFFICE_NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceOfficeDetailsUrl(cell.row.original.office_uuid)}
					>
						{cell.row.original.office_name}
					</LinkTableCell>
				);

			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(cell.row.original.uuid)}
					>
						{cell.row.original.individual_number}
					</LinkTableCell>
				);

			case PICK_DATE_TIME:
			case DROP_DATE_TIME:
				return isDropOffSort ? (
					<DropDateTableCell key={cell.column.id} cell={cell} withTime />
				) : (
					<PickDateTableCell key={cell.column.id} cell={cell} />
				);

			case DELIVERY_ASSIGNMENT:
				return (
					<DeliveryAssignmentTableCell
						key={cell.column.id}
						cell={cell}
						isDropOffSort={isDropOffSort}
						fetchData={fetchData}
					/>
				);

			case DELIVERY_STATUS:
				return (
					<DeliveryStatusTableCell
						key={cell.column.id}
						cell={cell}
						isDropOffSort={isDropOffSort}
						isSingleStatus
					/>
				);

			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.customer_name}
						<br /> {cell.row.original.customer_phone}
					</TableCell>
				);

			case ACTIONS:
				return (
					<ActionsTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditBookingUrl(cell.row.original.uuid)}
						canEdit={cell.row.original.can_edit && isAdmin}
						minWidth={140}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			key={row.id}
			className={clsx(classes.periodItem, classes[period.type])}
			{...row.getRowProps()}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
