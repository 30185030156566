import i18n from 'i18next';
import * as yup from 'yup';

export const validationSchema = () => {
	const requiredFieldText = i18n.t('common.fields.required_field');

	return yup.object({
		actual_return_branch_id: yup
			.number()
			.integer()
			.min(0, i18n.t('common.fields.minimum', { value: 0 }))
			.required(requiredFieldText),
		operator_id: yup.number().integer(),
		suspension_code: yup.string().required(requiredFieldText),
		rent_status: yup.object({
			odometer_reading: yup
				.number()
				.integer()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			fire_extinguisher: yup.string().required(requiredFieldText),
			spare_tire_tools: yup.string().required(requiredFieldText),
			spare_tire: yup.string().required(requiredFieldText),
			safety_triangle: yup.string().required(requiredFieldText),
			available_fuel: yup.string().required(requiredFieldText),
			first_aid_kit: yup.string().required(requiredFieldText),
			radio_stereo: yup.string().required(requiredFieldText),
			speedometer: yup.string().required(requiredFieldText),
			car_seats: yup.string().required(requiredFieldText),
			screen: yup.string().required(requiredFieldText),
			tires: yup.string().required(requiredFieldText),
			keys: yup.string().required(requiredFieldText),
			ac: yup.string().required(requiredFieldText),
			notes: yup.string().notRequired(),
		}),
		payment_details: yup.object({
			payment_method_code: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.integer()
				.required(requiredFieldText),
			discount: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.max(100, i18n.t('common.fields.maximum', { value: 100 }))
				.required(requiredFieldText),
			paid: yup
				.number()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.required(requiredFieldText),
			spare_parts_cost: yup
				.number()
				.nullable()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.notRequired(),
			damage_cost: yup
				.number()
				.nullable()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.notRequired(),
			oil_change_cost: yup
				.number()
				.nullable()
				.min(0, i18n.t('common.fields.minimum', { value: 0 }))
				.notRequired(),
		}),
	});
};
