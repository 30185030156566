import Store from 'store/store';
import { signOut } from 'store/actions';

export const checkErrorStatus = (error) => {
	if (!error.response) {
		return Promise.reject(error);
	}

	const {
		response: { status },
	} = error;
	if (status === 401) {
		const authenticate = false;
		// Dispatch an signOut action in auth folder
		Store.dispatch(signOut(authenticate));

		return Promise.reject(error);
	} else {
		return Promise.reject(error);
	}
};

export const createAxiosInterceptors = (axios) => {
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			return checkErrorStatus(error);
		}
	);
	return axios;
};
