export const getCopyActions = ({ uuid, individualNumber }) => [
	{
		value: individualNumber,
		buttonText: 'copy_id',
	},
	{
		value: uuid,
		buttonText: 'copy_uuid',
	},
];
