import React from 'react';
import { object } from 'prop-types';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { OLD_DELIVERY_INFORMATION_FIELDS } from '../../helpers';

// Import components
import PreviewFields from '../PreviewFields';
import { CollapsableCard } from 'components/elements';

// Import styles
import { useStyles } from './OldDeliveryInformation.styles';

export const OldDeliveryInformation = ({ customData }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const {
		old_deliveries: { deliveries },
		company_name: companyName,
	} = customData || {};

	return (
		<>
			{deliveries.length > 0 &&
				deliveries.map(({ delivery_uuid, ...delivery }) => (
					<CollapsableCard
						key={delivery_uuid}
						headerClassName={classes.header}
						cardClassName={classes.card}
						titleClassName={classes.title}
						title={`${companyName} (${t(
							`bookings.preview_booking.car.${delivery.delivery_type}`
						)})`}
						initialOpen={false}
					>
						<PreviewFields
							customData={delivery}
							fields={OLD_DELIVERY_INFORMATION_FIELDS}
						/>
					</CollapsableCard>
				))}
		</>
	);
};

OldDeliveryInformation.propTypes = {
	customData: object,
};
