import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import utilities
import { usePrintProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

// Import helpers
import { numberWithCommas } from 'helpers';

export const TotalPaymentBar = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const {
		data: {
			item: { total_discount_gross_price },
		},
	} = usePrintProvider();

	const totalPrice = `${t('currency.sr')} ${numberWithCommas(
		total_discount_gross_price
	)}`;

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			className={classes.container}
		>
			<Grid item className={classes.item}>
				<Typography className={classes.label}>
					{t('bookings.invoice_print.payment_details.total_payment')}
				</Typography>
			</Grid>
			<Grid item className={classes.item}>
				<Typography className={classes.price}>{totalPrice}</Typography>
			</Grid>
		</Grid>
	);
};
