import React from 'react';
import { bool } from 'prop-types';

// Import helpers
import { formatValues, initialValues } from './helpers';

// Import components
import { TableFilters } from 'components/elements';
import { AdditionalInfo } from '../../components';
import FilterFields from './FilterFields';

export const Filters = ({
	isExtended,
	showAssignedDriverAdditional = false,
	showDeliveryStatusAdditional = false,
}) => {
	return (
		<TableFilters
			initialValues={initialValues}
			formatValues={formatValues}
			additionalInfo={
				<AdditionalInfo
					showAssignedDriverAdditional={showAssignedDriverAdditional}
					showDeliveryStatusAdditional={showDeliveryStatusAdditional}
				/>
			}
		>
			{() => <FilterFields isExtended={isExtended} />}
		</TableFilters>
	);
};

Filters.propTypes = {
	isExtended: bool,
	showAssignedDriverAdditional: bool,
	showDeliveryStatusAdditional: bool,
};
