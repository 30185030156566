import React from 'react';
import { object, array, string } from 'prop-types';
import { TableRow } from '@material-ui/core';

// Import utilities
import useTableCarsBodyRow from './useTableCarsBodyRow';

const TableCarsBodyRow = ({ row, roles, officeRowID }) => {
	const { renderCell } = useTableCarsBodyRow({ officeRowID, roles });

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map((cell) => renderCell(cell))}
		</TableRow>
	);
};

TableCarsBodyRow.propTypes = {
	row: object.isRequired,
	roles: array.isRequired,
	officeRowID: string.isRequired,
};

export default TableCarsBodyRow;
