import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ImageMarker from 'react-image-marker';

// Import assets
import { carScratchesImage } from 'assets/images';

// Import styles
import { useStyles } from './CarScratches.styles';

// Import helpers
import { scratches } from './helpers';

// Import utils
import { useMediaQuerySizes, useTranslations } from 'components/utilities';
import { useCarScratches } from './useCarScratches';

// Import components
import { ScratchMarker, SingleScratch } from './components';

export const CarScratches = () => {
	const { t } = useTranslations();
	const { isTabletSize } = useMediaQuerySizes();
	const classes = useStyles();

	const {
		handleOnAddMarker,
		setScratchType,
		setMarkers,
		getIcon,
		getColor,
		scratchType,
		markers,
	} = useCarScratches();

	const renderHeader = () => (
		<>
			<Typography className={classes.heading}>
				{t('contract.create_contract_form.car_scratches.heading')}
			</Typography>
			<Typography>
				{t('contract.create_contract_form.car_scratches.hint_to_remove')}
			</Typography>
		</>
	);

	return (
		<>
			{isTabletSize && renderHeader()}
			<Box className={classes.container}>
				<Box className={classes.header}>
					{!isTabletSize && renderHeader()}
					<Box className={classes.scratchesWrapper}>
						{scratches.map(({ type, icon, color }) => (
							<SingleScratch
								key={type}
								isActive={type === scratchType}
								icon={icon}
								color={color}
								type={type}
								onClick={() => setScratchType(type)}
							/>
						))}
					</Box>
				</Box>
				<Box className={classes.imageWrapper}>
					<ImageMarker
						bufferLeft={0}
						bufferTop={0}
						extraClass={classes.image}
						src={carScratchesImage}
						markers={markers}
						onAddMarker={handleOnAddMarker}
						markerComponent={(props) => (
							<ScratchMarker
								setMarkers={setMarkers}
								icon={getIcon(scratchType)}
								color={getColor(scratchType)}
								{...props}
							/>
						)}
					/>
				</Box>
			</Box>
		</>
	);
};
