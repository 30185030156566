import React from 'react';
import { t } from 'i18next';

import { InputAdornment } from '@material-ui/core';

// Import utils and helpers
import { FIELD_TYPES } from 'helpers';

const { NUMBER, CHECKBOX } = FIELD_TYPES;

export const MONTHLY_RATE_FIELD = [
	{
		type: NUMBER,
		name: 'prices.base_rent.months.m1.price',
		label: 'leasing_cars.form.rates_model.monthly_rate',
		required: true,
		xs: 12,
		min: 1,
		step: 0.01,
		endAdornment: (
			<InputAdornment position="end">{t('currency.sar_month')}</InputAdornment>
		),
	},
];

export const SELL_PRICE_FIELDS = ({
	leasingCheckboxName,
	leasingSellPriceName,
	handleOnCheckboxClick,
	isLeasingSellActive,
}) => [
	{
		type: CHECKBOX,
		name: leasingCheckboxName,
		label: 'leasing_cars.form.rates_model.sell_checkbox',
		color: 'primary',
		handleOnCheckboxClick,
	},
	{
		type: NUMBER,
		name: leasingSellPriceName,
		label: 'leasing_cars.form.rates_model.sell_checkbox_label',
		required: true,
		xs: 12,
		min: 1,
		step: 0.01,
		isHidden: !isLeasingSellActive,
		endAdornment: (
			<InputAdornment position="end">{t('currency.sar')}</InputAdornment>
		),
	},
];
