import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	gridWrapper: {
		display: 'grid',
		gridTemplateColumns: ({ columns }) => `repeat(${columns}, 1fr)`,
		gap: 2,
	},
	dataTable: {
		display: 'flex',
		flexDirection: 'column',
	},
	separator: {
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.background[1],
	},
	divider: {
		backgroundColor: theme.palette.background[1],
		width: '100%',
		margin: '24px 0',
		colorAdjust: 'exact',
	},
}));
