import React from 'react';
import { Grid } from '@material-ui/core';
import { array, bool } from 'prop-types';

// Import components
import {
	CarManufacturerFilter,
	CarModelFilter,
	CarYearFilter,
	CompanyFilter,
	OfficeFilter,
	CarFilter,
} from '../../components';

// Import utilities
import useSelectCarFiltersInfo from './useSelectCarFiltersInfo';

const SelectCarFiltersInfo = ({ carsFetching, cars }) => {
	useSelectCarFiltersInfo();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<CarManufacturerFilter />
			</Grid>
			<Grid item xs={12}>
				<CarModelFilter />
			</Grid>
			<Grid item xs={12}>
				<CarYearFilter />
			</Grid>
			<Grid item xs={12}>
				<CompanyFilter />
			</Grid>
			<Grid item xs={12}>
				<OfficeFilter />
			</Grid>
			<Grid item xs={12}>
				<CarFilter cars={cars} carsFetching={carsFetching} />
			</Grid>
		</Grid>
	);
};

SelectCarFiltersInfo.propTypes = {
	cars: array,
	carsFetching: bool,
};

export default SelectCarFiltersInfo;
