import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	buttonContainer: { display: 'flex' },
	buttonContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		minWidth: '155px',
		cursor: 'pointer',
		padding: '10px',
		background: theme.palette.primary.main,
		color: theme.palette.white,
		borderRadius: '6px',
		position: 'relative',
	},
	inputFile: {
		opacity: 0,
		pointerEvents: 'none',
	},
	label: {
		color: theme.palette.gray.label,
		fontSize: '14px',
		fontWeight: 500,
		marginBottom: '8px',
	},
	error: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.main,
	},
	progress: {
		color: theme.palette.white,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));
