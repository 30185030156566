import React from 'react';
import { array, object, string } from 'prop-types';
import {
	Button,
	Typography,
	Grid,
	Avatar,
	Popover,
	Divider,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { signOut } from 'store/actions';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import assets
import { placeholderUser } from 'assets/images';
import { SignOutIcon } from 'assets/icons';

// Import components
import ProfileEditButton from '../ProfileEditButton';

// Import styles
import useStyles from './styles';

const ProfileModal = ({ role, fullName, bindPopover }) => {
	const { t } = useTranslations();
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleSignOut = () => signOut()(dispatch);

	return (
		<Popover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			classes={{ paper: classes.popover }}
			{...bindPopover}
		>
			<Grid container spacing={1}>
				<Grid container item xs={12} justifyContent="center">
					<Grid item>
						<Avatar
							src={placeholderUser}
							alt={t('common.placeholders.user_image_alt')}
							className={classes.image}
						/>
					</Grid>
				</Grid>
				<Grid container item className={classes.wrapper}>
					<Grid item xs={12}>
						<Typography className={classes.fullName} align="center">
							{fullName}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.role} align="center">
							{role[0]}
						</Typography>
					</Grid>
				</Grid>
				<ProfileEditButton />
				<Grid item xs={12}>
					<Divider className={classes.line} />
				</Grid>
				<Grid container item xs={12} justifyContent="center">
					<Grid item xs={12}>
						<Button onClick={handleSignOut}>
							<SignOutIcon className={classes.signOutIcon} />
							{t('common.buttons.sign_out')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Popover>
	);
};

ProfileModal.propTypes = {
	role: array,
	fullName: string,
	bindPopover: object,
};

export default ProfileModal;
