import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import actions
import { deleteDiscount, updateActiveDiscount } from 'store/actions';

// Import helpres
import {
	replaceEditOrganizationDiscountUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import {
	EditTableCell,
	DeleteTableCell,
	ActiveTableCell,
} from 'components/elements';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE } = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditOrganizationDiscountUrl(
							cell.row.original.organization_uuid,
							cell.row.original.uuid
						)}
					/>
				);
			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteDiscount}
						cell={cell}
					/>
				);
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveDiscount}
						cell={cell}
					/>
				);
			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
