import AddCircleIcon from '@material-ui/icons/AddCircle';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: `/cars/model/add`,
		icon: AddCircleIcon,
		text: 'cars.models.table.toolbar.add_model',
	},
];
