import * as types from 'store/types';
import services from 'services/services';
import { fetchCustomerPreviewDataUrl } from 'store/paths';

export const fetchCustomerPreviewData = ({ id, cancelToken }) => async (
	dispatch
) => {
	try {
		dispatch({
			type: types.CUSTOMER_PREVIEW_FETCH_DATA_LOADING,
			payload: true,
		});

		const { data } = await services.get(
			fetchCustomerPreviewDataUrl(id),
			cancelToken
		);

		dispatch({
			type: types.CUSTOMER_PREVIEW_FETCH_DATA_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		const defaultError = { message: '' };
		const response = error.response?.data ?? defaultError;
		dispatch({
			type: types.CUSTOMER_PREVIEW_FETCH_DATA_ERROR,
			payload: response,
		});
	}
};
