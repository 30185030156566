import React from 'react';
import { Field } from 'react-final-form';
import { bool, func } from 'prop-types';
import { CircularProgress, MenuItem, Button, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// Import components
import {
	KeyboardDatePickerFieldAdapter,
	SelectFieldAdapter,
	TextFieldAdapter,
	ButtonProgress,
	Alert,
} from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import helpers
import { capitalize } from 'helpers';

export const FormContent = ({ toggle, isLoading }) => {
	const { t } = useTranslations();

	const {
		submitting,
		reasons,
		minDate,
		isDescriptionInputVisible,
		isFetched,
		isError,
	} = useFormContent();

	if (isError) {
		return <Alert title={t('common.messages.error_message')} />;
	}

	return (
		<Grid container direction="column" spacing={1}>
			<Grid container item spacing={1}>
				<Grid item xs={isDescriptionInputVisible ? 6 : 12}>
					{!isFetched ? (
						<CircularProgress />
					) : (
						<Field
							label={t('customers.preview.wallet.add_dialog.add_reason')}
							name="reason_uuid"
							variant="outlined"
							margin="dense"
							component={SelectFieldAdapter}
							disabled={submitting}
							fullWidth
							required
						>
							{reasons.map(({ uuid, description }) => (
								<MenuItem key={uuid} value={uuid}>
									{capitalize(description)}
								</MenuItem>
							))}
						</Field>
					)}
				</Grid>
				{isDescriptionInputVisible && (
					<Grid item xs={6}>
						<Field
							label={t('customers.preview.wallet.description')}
							name="description"
							variant="outlined"
							margin="dense"
							component={TextFieldAdapter}
							disabled={submitting}
							fullWidth
							required
						/>
					</Grid>
				)}
				<Grid item xs={6}>
					<Field
						label={t('customers.preview.wallet.add_dialog.amount')}
						variant="outlined"
						margin="dense"
						name="amount"
						type="number"
						component={TextFieldAdapter}
						disabled={submitting}
						inputProps={{ min: 0.1, step: 0.01 }}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Field
							label={t('customers.preview.wallet.add_dialog.expiry_date')}
							inputVariant="outlined"
							name="expiry_date"
							margin="dense"
							component={KeyboardDatePickerFieldAdapter}
							disabled={submitting}
							minDate={minDate}
							fullWidth
							required
						/>
					</MuiPickersUtilsProvider>
				</Grid>
			</Grid>
			<Grid justifyContent="flex-end" spacing={1} container item>
				<Grid item xs="auto">
					<Button variant="outlined" color="primary" onClick={toggle}>
						{t('common.buttons.close')}
					</Button>
				</Grid>
				<Grid item xs="auto">
					<ButtonProgress
						circularProgressSize={24}
						isLoading={isLoading}
						disabled={isLoading}
						variant="contained"
						color="primary"
						type="submit"
					>
						{t('common.buttons.submit')}
					</ButtonProgress>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	toggle: func,
	isLoading: bool,
};
