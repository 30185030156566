import React from 'react';
import { Box, Card } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import utils and helpers
import { useExternalPartnersCard } from './useExternalPartnersCard';
import { useTranslations } from 'components/utilities';
import { INITIAL_VALUES } from './helpers';

// Import components
import { AlertMessage } from 'components/elements';
import { FormContent } from './components';

// Import styles
import { useStyles } from './ExternalPartnersCard.styles';

export const ExternalPartnersCard = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const {
		handleOnSubmit,
		offices,
		officesFetching,
		officesError,
		isButtonLoading,
	} = useExternalPartnersCard();

	if (officesError) {
		return (
			<Box className={classes.errorContainer}>
				<AlertMessage
					title={t('common.error')}
					variant="error"
					description={t('common.messages.error_message')}
				/>
			</Box>
		);
	}

	return (
		<Box className={classes.container}>
			<Card className={classes.card}>
				<Form
					onSubmit={handleOnSubmit}
					initialValues={INITIAL_VALUES}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit} noValidate>
							<FormContent
								offices={offices}
								isFetching={officesFetching}
								isButtonLoading={isButtonLoading}
							/>
						</form>
					)}
				/>
			</Card>
		</Box>
	);
};
