import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	message: {
		fontSize: '16px',
		textAlign: 'center',
		marginBottom: '10px',
	},
	actions: {
		padding: '14px 26px 16px 26px',
	},
}));
