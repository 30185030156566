import {
	TextFieldAdapter,
	LocationAutocompleteFieldAdapter,
} from 'components/elements';

export const FIELDS = ({ section, isAdmin }) => [
	{
		component: LocationAutocompleteFieldAdapter,
		type: 'text',
		label: 'company.form.location.address',
		name: `${section}.address`,
		md: 12,
		xs: 12,
		disabled: !isAdmin,
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'company.form.location.city',
		name: `${section}.city`,
		md: 6,
		xs: 12,
		disabled: 'disabled',
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'company.form.location.country',
		name: `${section}.country`,
		md: 6,
		xs: 12,
		disabled: 'disabled',
	},
];
