import { Filters } from './components';

// Import helpers
import { replaceCorporateSettlementsUrl, TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = ({ corporateUuid, isCorporateCoordinator }) => {
	const content = [
		{
			variant: TOOLBAR_VARIANTS.CUSTOM,
			component: Filters,
			customKey: 'filters',
			componentProps: {
				showAssignedDriverAdditional: true,
				showDeliveryStatusAdditional: true,
			},
		},
	];

	if (!isCorporateCoordinator) {
		content.push({
			variant: TOOLBAR_VARIANTS.NAV,
			to: replaceCorporateSettlementsUrl(corporateUuid),
			text: 'b2b.settlements.title',
		});
	}

	return content;
};
