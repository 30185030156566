import ls from 'localstorage-slim';

export const clearLocaleStorageCache = ({ shouldReload = false }) => {
	if (localStorage) {
		Object.keys(localStorage).forEach(
			(key) => key.startsWith('cache') && ls.remove(key)
		);
	}

	if (shouldReload) {
		window.location.reload();
	}
};
