import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { deleteManager, updateActiveManager } from 'store/actions';

// Import helpres
import {
	replaceEditCompanyManagersUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../helpers/columns';

// Import components
import {
	EditTableCell,
	ActiveTableCell,
	DeleteTableCell,
	RoleTableCell,
} from 'components/elements';
import AssignOfficesCell from '../components/AssignOfficesCell';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { OFFICES, ACTIVE, ROLE } = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		const {
			column: { id: columnId },
			row: {
				original: { uuid, role },
			},
		} = cell;
		switch (columnId) {
			case EDIT:
				return (
					<EditTableCell
						key={columnId}
						cell={cell}
						editLinkPath={replaceEditCompanyManagersUrl(uuid)}
					/>
				);

			case ACTIVE:
				return (
					<ActiveTableCell
						key={columnId}
						activeAction={updateActiveManager}
						cell={cell}
					/>
				);

			case DELETE:
				return (
					<DeleteTableCell
						key={columnId}
						deleteAction={deleteManager}
						cell={cell}
					/>
				);

			case OFFICES:
				return <AssignOfficesCell key={columnId} cell={cell} />;

			case ROLE:
				return <RoleTableCell key={columnId} cell={cell} userRole={role} />;

			default:
				return (
					<TableCell key={columnId} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
