import services from 'services/services';
import { changeIsFulfilledUrl } from 'store/paths';

export const changeIsFulfilled = async ({ bookingUuid, isFulfilled }) => {
	try {
		await services.put(changeIsFulfilledUrl(bookingUuid), {
			is_fulfilled: isFulfilled,
		});

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};
