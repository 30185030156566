import React from 'react';
import { object } from 'prop-types';
import { TableCell, Grid, Tooltip } from '@material-ui/core';
import PowerIcon from '@material-ui/icons/PowerOutlined';

import { useTranslations } from 'components/utilities';
import { useStyles } from './styles';

const AvailableTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { available, is_external } = cell.row.original;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container spacing={1} alignItems="center" wrap="nowrap">
				<Grid item>{available}</Grid>
				{is_external && (
					<Grid item>
						<Tooltip title={t('cars.table.is_external')}>
							<PowerIcon
								color="primary"
								className={classes.icon}
								fontSize="small"
							/>
						</Tooltip>
					</Grid>
				)}
			</Grid>
		</TableCell>
	);
};

AvailableTableCell.propTypes = {
	cell: object.isRequired,
};

export default AvailableTableCell;
