import services from 'services/services';

// Import utilities
import {
	fetchLeasingCarsListUrl,
	updateActiveLeasingCarUrl,
} from 'store/paths';

export const fetchLeasingCars =
	({ itemId, queryParams, cancelToken }) =>
	async () => {
		try {
			const { data } = await services.get(
				fetchLeasingCarsListUrl({ id: itemId, queryParams }),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActiveLeasingCar =
	({ isActive, id, officeId }) =>
	async () => {
		await services.patch(
			updateActiveLeasingCarUrl({
				id,
				isActive,
				officeId,
			})
		);
	};
