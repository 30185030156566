import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

// Import helpers
import { DASH_ROUTES, URLS } from './helpers';

// Import components
import { RouteWithLayout } from 'components/elements';
import { DashboardTemplate } from 'templates';

const DashboardRoutes = () => {
	return (
		<DashboardTemplate>
			<Switch>
				<Redirect exact from={URLS.emptyUrl} to={URLS.dashboardUrl} />
				{DASH_ROUTES.map((route) => (
					<RouteWithLayout
						key={route.path}
						component={route.component}
						exact
						layout={route.layout}
						path={route.path}
					/>
				))}
				<Redirect to={URLS.notFoundUrl} />
			</Switch>
		</DashboardTemplate>
	);
};

export default DashboardRoutes;
