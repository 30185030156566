import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	tableCell: {
		padding: '0 5px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		background: '#fff',
		color: theme.palette.text.primary,
		transform: theme.direction === 'rtl' ? 'rotateY(180deg)' : '',
		direction: theme.direction,
		flip: false,
	},
	cellContent: {
		color: theme.palette.text.primary,
	},
}));

export default useStyles;
