// Import helpers and utils
import { getStatusType } from './helpers';
import { useProfile } from 'components/utilities';

export const useBookingStatusChip = ({ booking, state }) => {
	const { isAdmin } = useProfile();

	const status = booking?.state || state;

	const statusDescription = isAdmin ? booking?.cancel_description || '' : '';

	const statusType = getStatusType(status);

	return { status, statusDescription, statusType };
};
