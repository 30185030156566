import React from 'react';
import { object } from 'prop-types';
import { fetchCars } from 'store/actions';
import { useParams } from 'react-router-dom';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableCarsBodyRow } from './components';

// Import helpers
import { toolbarContent } from './helpers';
import { URLS } from 'components/routes';

// Import utilities
import { useProfile } from 'components/utilities';

const Cars = ({ match }) => {
	const classes = useBaseLayoutStyles();

	const { id } = useParams();

	const { isOfficeManager } = useProfile();

	return (
		<TableProvider columns={columns} fetchDataTableAction={fetchCars}>
			<div className={classes.root}>
				<Toolbar content={toolbarContent({ id, isOfficeManager })} />
				<div className={classes.content}>
					<Table editLinkPath={URLS.accountUrl} isDefaultRow={false}>
						{({ row, editLinkPath, userRolesData }) => (
							<TableCarsBodyRow
								editLinkPath={editLinkPath}
								key={row.id}
								row={row}
								roles={userRolesData}
								officeRowID={match.params.id}
							/>
						)}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};

Cars.propTypes = {
	match: object.isRequired,
};

export default Cars;
