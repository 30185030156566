import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	'@keyframes rotateAnimation': {
		'0%': {
			transform: 'rotate(0deg)',
		},
		'100%': {
			transform: 'rotate(360deg)',
		},
	},
	icon: {
		animation: `$rotateAnimation 2.5s ${theme.transitions.easing.easeInOut} infinite`,
		height: '200px',
		width: '200px',
	},
}));
