import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { updateActiveCompany, deleteCompany } from 'store/actions';
import SpeedIcon from '@material-ui/icons/Speed';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import {
	replaceCompanyDropCitiesUrl,
	replaceCompanyEmployeePerformanceUrl,
	replaceCompanyOfficesUrl,
	replaceCompanyUsersUrl,
	replaceEditCompanyUrl,
	replaceEmployeePerformanceUsersWithIdUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';

// Import components
import {
	EditTableCell,
	ActiveTableCell,
	DeleteTableCell,
	ImageTableCell,
} from 'components/elements';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const {
	IMAGE,
	ACTIVE,
	DROP_CITIES,
	USERS,
	OFFICES,
	EMPLOYEE_PERFORMANCE_SETTINGS,
	EMPLOYEE_PERFORMANCE_TABLE,
} = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case IMAGE:
				return (
					<ImageTableCell
						cell={cell}
						key={cell.column.id}
						imgUrl={cell.row.original.media}
					/>
				);
			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditCompanyUrl(cell.row.original.uuid)}
					/>
				);
			case DROP_CITIES:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						isIcon={false}
						text="company.table.headers.drop_cities"
						editLinkPath={replaceCompanyDropCitiesUrl(cell.row.original.uuid)}
					/>
				);
			case EMPLOYEE_PERFORMANCE_SETTINGS:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						isIcon={true}
						icon={SpeedIcon}
						text="company.table.headers.rewards_settings"
						editLinkPath={replaceCompanyEmployeePerformanceUrl(
							cell.row.original.uuid
						)}
					/>
				);
			case EMPLOYEE_PERFORMANCE_TABLE:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						isIcon={false}
						text="company.table.headers.rewards_table"
						editLinkPath={replaceEmployeePerformanceUsersWithIdUrl(
							cell.row.original.uuid
						)}
					/>
				);
			case USERS:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						isIcon={false}
						text="company.table.headers.users"
						editLinkPath={replaceCompanyUsersUrl(cell.row.original.uuid)}
					/>
				);
			case OFFICES:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						isIcon={false}
						text="company.table.headers.offices"
						editLinkPath={replaceCompanyOfficesUrl(cell.row.original.uuid)}
					/>
				);
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveCompany}
						cell={cell}
					/>
				);
			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteCompany}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
