import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

// Import components
import OptionField from '../OptionField';
import { DropCityField } from './components';

const DropCityOptionField = ({ label }) => {
	return (
		<Grid container alignItems="center" justifyContent="space-between">
			<Grid item xs="auto">
				<OptionField type="drop_city" label={label} />
			</Grid>
			<Grid item xs={5}>
				<DropCityField label={label} />
			</Grid>
		</Grid>
	);
};

DropCityOptionField.propTypes = {
	label: PropTypes.string,
};

export default DropCityOptionField;
