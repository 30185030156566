import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	DateRangeFilter,
	OfficeFilter,
	CompanyOfficesFilter,
	CityFilter,
	BaseSelectFilter,
} from 'components/elements';
import RoleProtected from 'components/hoc/RoleProtected';

// Import utils and helpers
import { useTableContextProvider } from 'components/context';
import {
	PERMISSION_COMPANY_OFFICES_FILTER,
	PERMISSION_OFFICE_FILTER,
	formatValues,
	initialValues,
} from './helpers';
import { ROLE_FILTER_OPTIONS } from 'helpers';

export const FilterFields = () => {
	const { setFilters } = useTableContextProvider();

	useEffect(() => {
		setFilters(formatValues(initialValues));
		//eslint-disable-next-line
	}, []);

	return (
		<Grid container spacing={3}>
			<DateRangeFilter
				nameFrom="date"
				nameUntil="date_to"
				label="employee_rewarding.table.filters.date_range"
				combinedInputs
			/>

			<BaseSelectFilter
				label="employee_rewarding.table.filters.role"
				name="role"
				options={ROLE_FILTER_OPTIONS}
			/>

			<RoleProtected roles={PERMISSION_COMPANY_OFFICES_FILTER}>
				<CompanyOfficesFilter
					name="office_uuid"
					label="bookings.filters.office"
				/>
			</RoleProtected>

			<RoleProtected roles={PERMISSION_OFFICE_FILTER}>
				<OfficeFilter name="office_uuid" label="bookings.filters.office" />
			</RoleProtected>

			<CityFilter name="city_uuid" label="bookings.filters.city" />
		</Grid>
	);
};
