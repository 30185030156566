import React from 'react';
import PropTypes from 'prop-types';

// Import translation
import { useCommonFields, useTranslations } from 'components/utilities';

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';
import { AIRPORT_LOGO_FIELDS } from './helpers';

const AirportLogo = ({ section, ...props }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();

	return (
		<Card {...props}>
			<CardHeader title={t('kiosk.airports.form.logo.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{AIRPORT_LOGO_FIELDS(section).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

AirportLogo.propTypes = {
	section: PropTypes.string.isRequired,
};

export default AirportLogo;
