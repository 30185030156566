import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: '16px',
		width: '100%',
	},
	titleWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	button: {
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	header: {
		padding: 0,
	},
	title: {
		color: theme.palette.primary.main,
		fontSize: '16px',
	},
	card: {
		paddingBottom: 0,
	},
}));
