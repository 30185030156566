import { useTranslations } from 'components/utilities';
import React from 'react';
import { useSelector } from 'react-redux';

// Import utilities
import { previewBookingSelector } from 'store/selectors/bookings';

// Import components
import PreviewField from './PreviewField';

const OptionsPricesPreviewField = () => {
	const { options } = useSelector(previewBookingSelector);
	const { t } = useTranslations();

	return (
		<>
			{options &&
				options.map(({ type, nett_price }) => (
					<PreviewField key={type} label={`bookings.options.${type}`}>
						{`${nett_price} ${t('currency.SAR')}`}
					</PreviewField>
				))}
		</>
	);
};

export default OptionsPricesPreviewField;
