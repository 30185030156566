import services from 'services/services';
import { deleteDriversUrl, fetchDriversUrl } from 'store/paths';

export const fetchDrivers =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const { data } = await services.post(
				fetchDriversUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const deleteDriver =
	({ id }) =>
	async () => {
		await services.delete(deleteDriversUrl(id));
	};
