import React from 'react';

import { Form } from 'react-final-form';

// Import components
import { FiltersContent } from './FiltersContent';
import { useFilters } from '../../context/useFilters';

export const Filters = () => {
	const { filters } = useFilters();

	return (
		<Form onSubmit={() => {}} initialValues={filters}>
			{() => <FiltersContent />}
		</Form>
	);
};
