import React from 'react';
import { func } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import helpers
import { URLS } from 'components/routes';
import { useProfile } from 'components/utilities';

const ProtectedUserLogged = ({ component: Component }) => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const { isCorporateCoordinator } = useProfile();

	const redirectPath = isCorporateCoordinator
		? URLS.corporateOverviewUrl
		: URLS.dashboardUrl;

	return (
		<Route
			render={(props) =>
				isAuthenticated ? (
					<Redirect to={redirectPath} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

ProtectedUserLogged.propTypes = {
	component: func.isRequired,
};

export default ProtectedUserLogged;
