import _ from 'lodash';
import * as types from 'store/types';
import services from 'services/services';
import { convertValues, getCampaignType, INITIAL_VALUES } from './helpres';
import {
	fetchCampaignsFormResourcesUrl,
	submitCampaignFormUrl,
} from 'store/paths';

export const fetchCampaignsFormResources = ({
	itemId,
	isEdit,
	cancelToken,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.CAMPAIGN_FORM_FETCH_DATA_LOADING,
		});

		const { data } =
			isEdit &&
			(await services.get(fetchCampaignsFormResourcesUrl(itemId), cancelToken));

		const mergedData = _.merge({}, { data: INITIAL_VALUES }, data);

		dispatch({
			type: types.CAMPAIGN_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: mergedData || {},
			},
		});
	} catch (error) {
		const defaultError = { message: '' };
		const response = error.response?.data ?? defaultError;
		dispatch({
			type: types.CAMPAIGN_FORM_FETCH_DATA_ERROR,
			payload: response,
		});
	}
};

export const submitCampaignForm = ({
	values,
	itemId,
	isEdit,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		const actionPath = isEdit
			? `${itemId}/update`
			: `${getCampaignType()}/create`;

		const newValues = convertValues(values);

		await services.post(submitCampaignFormUrl(actionPath), newValues);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
