import React from 'react';
import { bool, object, string } from 'prop-types';
import { CardContent } from '@material-ui/core';

// Import components
import { Content } from './components';
import CardHeader from '../CardHeader';
import Card from '../Card';

// Import utilities
import { useTranslations } from 'components/utilities';

export const Rating = ({ title, isLoading, isError, data, unit }) => {
	const { t } = useTranslations();

	return (
		<Card>
			<CardHeader title={t(`dashboard.${title}.title`)} />
			<CardContent>
				<Content
					isLoading={isLoading}
					isError={isError}
					data={data}
					unit={unit}
				/>
			</CardContent>
		</Card>
	);
};

Rating.propTypes = {
	isLoading: bool.isRequired,
	isError: bool.isRequired,
	title: string.isRequired,
	data: object,
	unit: string,
};
