import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useCalculations } from './useCalculations';

// Import helpers
import { ROWS } from './helpers';

// Import components
import { CalculationItem } from '../CalculationItem';

export const Calculations = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { base_nett_price, total_days, options, rowValues, total } =
		useCalculations();

	return (
		<Box className={classes.container}>
			<Box className={classes.wrapper}>
				<CalculationItem
					label="bookings.invoice_print.payment_details.original_price"
					value={t(
						'bookings.invoice_print.payment_details.original_price_value',
						{ price: base_nett_price, days: total_days }
					)}
				/>
				<CalculationItem
					label="bookings.invoice_print.payment_details.total"
					value={total}
				/>

				{!!options.length && (
					<Box>
						<Typography color="primary">
							{t('bookings.invoice_print.additional_service.title')}
						</Typography>
						{options.map(({ type, gross_price }) => (
							<CalculationItem
								key={type}
								label={`bookings.invoice_print.additional_service.${type}`}
								value={gross_price}
								isServiceItem
							/>
						))}
					</Box>
				)}

				{ROWS(rowValues).map(({ label, value, variant, isHidden }) => (
					<CalculationItem
						key={label}
						label={label}
						value={value}
						variant={variant}
						isHidden={isHidden}
					/>
				))}
			</Box>
		</Box>
	);
};
