import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 24,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 4,
		color: theme.palette.primary.main,
		'&$checked': {
			transform: 'translateX(18px)',
			color: theme.palette.white,
			transition: theme.transitions.create(['color']),
			'& + $track': {
				backgroundColor: theme.palette.primary.main,
				opacity: 1,
				border: 'none',
			},
		},
	},
	thumb: {
		width: 16,
		height: 16,
	},
	track: {
		borderRadius: 24 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.white,
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
}));
