import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usePrevious from '@rooks/use-previous';
import _ from 'lodash';

// Import store
import { fetchDashboardInfo } from 'store/actions';

// Import utils
import { useTranslations } from 'components/utilities';
import { useFilters } from './context/useFilters';
import {
	useCancelledToAcceptedRateQuery,
	useDeliveriesSuccessRateQuery,
	useCancellationRateQuery,
	useBookingsByStateQuery,
	useAcceptanceRateQuery,
	useReportRevenueQuery,
	useReportRatingQuery,
} from 'queries';

export const useDashboardContent = () => {
	const { filters } = useFilters();
	const prevFilters = usePrevious(filters);
	const { t } = useTranslations();
	const dispatch = useDispatch();

	const {
		data: cancellationRate,
		isLoading: isCancellationRateLoading,
		isError: isCancellationRateError,
	} = useCancellationRateQuery(filters);

	const {
		data: acceptanceRate,
		isLoading: isAcceptanceRateLoading,
		isError: isAcceptanceRateError,
	} = useAcceptanceRateQuery(filters);

	const {
		data: deliveriesSuccessRate,
		isLoading: isDeliveriesSuccessRateLoading,
		isError: isDeliveriesSuccessRateError,
	} = useDeliveriesSuccessRateQuery(filters);

	const {
		data: rating,
		isLoading: isRatingLoading,
		isError: isRatingError,
	} = useReportRatingQuery(filters);

	const {
		data: cancelledToAcceptedRating,
		isLoading: isCancelledToAcceptedRatingLoading,
		isError: isCancelledToAcceptedRatingError,
	} = useCancelledToAcceptedRateQuery(filters);

	const {
		data: reportRevenue,
		isError: isReportRevenueError,
		isLoading: isReportRevenueLoading,
	} = useReportRevenueQuery(filters);

	const {
		data: bookingsByState,
		isError: isBookingsByStateError,
		isLoading: isBookingsByStateLoading,
	} = useBookingsByStateQuery(filters);

	const convertedReportRevenue =
		reportRevenue &&
		Object.entries(reportRevenue).map(([key, value]) => ({
			label: `dashboard.total_revenue.content.${key}`,
			value: t('currency.price', { amount: value }),
		}));

	const convertedBookingsByState =
		bookingsByState &&
		Object.entries(bookingsByState).map(([key, value]) => ({
			label: `dashboard.bookings_by_state.states.${key}`,
			value,
		}));

	useEffect(() => {
		const isEqual = _.isEqual(prevFilters, filters);

		if (!isEqual) {
			fetchDashboardInfo({ values: filters })(dispatch);
		}
	}, [filters, prevFilters, dispatch]);

	return {
		isCancelledToAcceptedRatingLoading,
		isDeliveriesSuccessRateLoading,
		isCancellationRateLoading,
		isBookingsByStateLoading,
		isAcceptanceRateLoading,
		isReportRevenueLoading,
		isRatingLoading,
		isCancelledToAcceptedRatingError,
		isDeliveriesSuccessRateError,
		isCancellationRateError,
		isBookingsByStateError,
		isAcceptanceRateError,
		isReportRevenueError,
		isRatingError,
		bookingsByState: convertedBookingsByState,
		reportRevenue: convertedReportRevenue,
		cancelledToAcceptedRating,
		deliveriesSuccessRate,
		cancellationRate,
		acceptanceRate,
		rating,
		filters,
	};
};
