import React from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import { CarScratches, TextFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from 'theme/styles/eContract.styles';

export const CarScratchesSection = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<CarScratches />
			</Grid>
			<Grid item xs={12}>
				<Field
					label={t('contract.create_contract_form.car_inspection.notes')}
					component={TextFieldAdapter}
					name="sketch_notes"
					variant="outlined"
					margin="dense"
					type="text"
					fullWidth
					multiline
					rows={4}
					className={classes.notes}
				/>
			</Grid>
		</Grid>
	);
};
