import React from 'react';
import { Typography } from '@material-ui/core';
import i18next from 'i18next';

// Import styles
import { styles } from './AccountingValidation.styles';

export const COLUMNS_ACCESSORS = {
	NOTE: 'note',
	BOOKING_ID: 'booking_id',
	CUSTOMER_NAME: 'customer_name',
	ACTION: 'action',
	TOPIC: 'topic',
	ACTIONS: 'actions',
	COMPENSATION_AMOUNT: 'compensation_amount',
	VALIDATED_BY: 'validated_by',
	BANK_NAME: 'bank_name',
	BOOKINGS: 'bookings',
};

export const columns = [
	{
		Header: 'bookings.table.headers.compensate_reason',
		accessor: COLUMNS_ACCESSORS.TOPIC,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.booking_id_slash',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
		isHidden: false,
		// eslint-disable-next-line react/display-name
		renderSubHeader: () => (
			<Typography style={styles}>
				{i18next.t('bookings.table.headers.company_name')}
			</Typography>
		),
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.bank_name_slash',
		accessor: COLUMNS_ACCESSORS.BANK_NAME,
		disableSortBy: true,
		isHidden: false,
		// eslint-disable-next-line react/display-name
		renderSubHeader: () => (
			<Typography style={styles}>
				{i18next.t('bookings.table.headers.iban')}
			</Typography>
		),
	},
	{
		Header: 'bookings.table.headers.amount',
		accessor: COLUMNS_ACCESSORS.COMPENSATION_AMOUNT,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.bookings',
		accessor: COLUMNS_ACCESSORS.BOOKINGS,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.validated_by',
		accessor: COLUMNS_ACCESSORS.VALIDATED_BY,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.compensation',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
