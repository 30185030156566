// Import components
import { TextFieldAdapter } from 'components/elements';

export const fields = (section) => {
	const result = [
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'kiosk.airports.terminals.form.details.name_en',
			name: `${section}.name.en`,
			md: 6,
			xs: 6,
		},
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'kiosk.airports.terminals.form.details.name_ar',
			name: `${section}.name.ar`,
			md: 6,
			xs: 6,
		},
	];

	return result;
};
