import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import { bool, func, string } from 'prop-types';

// Import components
import { ButtonProgress, TextFieldAdapter } from 'components/elements';

// import translations
import { useTranslations } from 'components/utilities';

const BookingIdField = ({ onClick, isLoading, errorMessage }) => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	return (
		<Grid item container alignItems="center" spacing={2}>
			<Grid item md={9}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="text"
					label={t('campaigns.experience_form.booking')}
					name="booking.uuid"
					variant="outlined"
					margin="dense"
					disabled={submitting}
					required
				/>
				{errorMessage && <Typography color="error">{errorMessage}</Typography>}
			</Grid>
			<Grid item md={3}>
				<ButtonProgress
					color="primary"
					variant="outlined"
					isLoading={submitting || isLoading}
					disabled={submitting}
					onClick={onClick}
				>
					{t('campaigns.experience_form.check_btn')}
				</ButtonProgress>
			</Grid>
		</Grid>
	);
};

BookingIdField.propTypes = {
	onClick: func.isRequired,
	isLoading: bool,
	errorMessage: string,
};

export default BookingIdField;
