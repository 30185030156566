import { combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';
import { fetchFaqsUrl, deleteFaqUrl, updateActiveFaqUrl } from 'store/paths';

export const fetchFaqs =
	({ options, cancelToken, queryParams }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(fetchFaqsUrl(params), cancelToken);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActiveFaq =
	({ isActive, id }) =>
	async () => {
		await services.patch(updateActiveFaqUrl(id, isActive));
	};

export const deleteFaq =
	({ id }) =>
	async () => {
		await services.delete(deleteFaqUrl(id));
	};
