import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		minHeight: '300px',
	},
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),
		minWidth: '105px',
		display: 'inline-block',
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 9999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default useStyles;