// Import helpers
import { replaceUnsettledBookingsUrl, TOOLBAR_VARIANTS } from 'helpers';
import { Filters } from './components';

export const TOOLBAR_CONTENT = (corporateUuid) => [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Filters,
		customKey: 'filters',
	},
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: replaceUnsettledBookingsUrl(corporateUuid),
		text: 'common.buttons.add_new',
	},
];
