/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

// Import utils and helpers
import { useBookingsByServiceQuery } from 'queries/KPI_Reports';
import { useTranslations } from 'components/utilities';
import { useFilters } from '../../../../context';
import { COLORS } from './helpers';

// Import styles
import { useStyles } from './Content.styles';

export const useContent = () => {
	const { filters } = useFilters();
	const { data, isLoading, isError } = useBookingsByServiceQuery(filters);
	const { t } = useTranslations();
	const classes = useStyles();

	const convertedData =
		data &&
		Object.entries(data).map(([name, value], index) => ({
			name,
			value,
			color: COLORS[index % COLORS.length],
		}));

	const renderLegend = ({ payload }) => (
		<Grid container spacing={1}>
			{payload.map((entry, index) => (
				<Grid item xs={6} key={index} className={classes.container}>
					<Box
						style={{ backgroundColor: entry.color }}
						className={classes.dot}
						component="span"
					/>
					<Typography className={classes.text}>
						{t(`dashboard.service_type.types.${entry.value}`)}:{' '}
						{entry.payload.value}
					</Typography>
				</Grid>
			))}
		</Grid>
	);

	return {
		data: convertedData,
		renderLegend,
		isLoading,
		isError,
	};
};
