import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { ITEMS_ACCESSORS } from '../helpers';

// Import components
import {
	BaseGrossPriceInfoListItem,
	OptionInfoListItem,
	VatInfoListItem,
	TotalPriceInfoListItem,
	DefaultInfoListItem,
} from './index';

const InfoListItemFactory = ({ accessor, label }) => {
	switch (accessor) {
		case ITEMS_ACCESSORS.BASE_GROSS_PRICE:
			return <BaseGrossPriceInfoListItem label={label} />;
		case ITEMS_ACCESSORS.OPTION_DRIVER:
			return <OptionInfoListItem label={label} type="driver" />;
		case ITEMS_ACCESSORS.OPTION_DELIVERY:
			return <OptionInfoListItem label={label} type="delivery" />;
		case ITEMS_ACCESSORS.OPTION_INSURANCE:
			return <OptionInfoListItem label={label} type="insurance" />;
		case ITEMS_ACCESSORS.OPTION_UNLIMITED_KM:
			return <OptionInfoListItem label={label} type="unlimitedKm" />;
		case ITEMS_ACCESSORS.VAT:
			return <VatInfoListItem label={label} />;
		case ITEMS_ACCESSORS.TOTAL_PRICE:
			return <TotalPriceInfoListItem label={label} />;
		default:
			return <DefaultInfoListItem accessor={accessor} label={label} />;
	}
};

InfoListItemFactory.propTypes = {
	accessor: PropTypes.string,
	label: PropTypes.string,
};

export default InfoListItemFactory;
