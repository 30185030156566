export const INITIAL_VALUES = {
	type: null,
	details: {
		name: {
			ar: '',
			en: '',
		},
		description: {
			ar: '',
			en: '',
		},
	},
	operation_validation: false,
	accounting_validation: false,
};
