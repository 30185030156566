import React from 'react';
import { bool, number, string } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

export const OutstandingBalance = ({
	hasEarlyReturnState,
	transactionalType,
	transactionAmount,
	debtBalance,
}) => {
	const { t } = useTranslations();

	const operator =
		transactionalType === 'payment' || transactionalType === null ? '+' : '-';

	const totalPrice = Number(debtBalance) + Number(transactionAmount);

	return (
		<TableCell>
			<Grid container>
				<Grid item xs={12}>
					<Typography>{`${t('currency.sar')} ${debtBalance}`}</Typography>
				</Grid>
				{hasEarlyReturnState && (
					<>
						{transactionAmount && (
							<Grid item xs={12}>
								<Typography>
									{operator} {transactionAmount}
								</Typography>
							</Grid>
						)}
						{totalPrice && (
							<Grid item xs={12}>
								<Typography> = {totalPrice}</Typography>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</TableCell>
	);
};

OutstandingBalance.propTypes = {
	hasEarlyReturnState: bool,
	transactionalType: string,
	transactionAmount: number,
	debtBalance: string,
};
