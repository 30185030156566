import React from 'react';
import { Box } from '@material-ui/core';

// Import store
import { fetchLeasingCars } from 'store/actions';

// Import components
import { Table } from 'components/elements';
import { TableBodyRow, ActionButtonsToolbar } from './components';

// Import contexts
import { TableActionsProvider } from './context';
import { TableProvider } from 'components/context';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns, customColumnsSettings } from './columns';

export const LeasingCars = () => {
	const classes = useBaseLayoutStyles();

	return (
		<TableActionsProvider>
			<TableProvider
				columns={columns}
				fetchDataTableAction={fetchLeasingCars}
				customColumnsSettings={customColumnsSettings}
				isDataTable={false}
			>
				<Box className={classes.root}>
					<Box className={classes.content}>
						<Table
							isDefaultRow={false}
							isPaginationVisible={false}
							tableInnerToolbar={<ActionButtonsToolbar />}
						>
							{({ row }) => <TableBodyRow key={row.id} row={row} />}
						</Table>
					</Box>
				</Box>
			</TableProvider>
		</TableActionsProvider>
	);
};
