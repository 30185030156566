import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BrandNameIcon = (props) => (
	<SvgIcon width="151" height="44" viewBox="0 0 151 44" fill="none" {...props}>
		<path
			d="M58.7995 27.9322V33.5881H55.509C52.9509 33.5881 50.7702 32.6713 48.9711 30.8105C47.1716 28.9379 46.2625 26.6878 46.2625 24.0485V-2.28882e-05H51.9065V24.0485C51.9065 26.1097 53.5635 27.9322 55.509 27.9322H58.7995Z"
			fill="currentColor"
		/>
		<path
			d="M34.4263 8.1887H25.2813C23.4316 8.1887 21.826 8.86184 20.5437 10.1506C19.2637 11.4517 18.5997 13.0282 18.5997 14.8974V25.5343C18.5997 27.746 19.3537 29.6555 20.9191 31.2358C22.4853 32.8169 24.3354 33.5881 26.5141 33.5881H39.3589V28.2214H26.5141C25.233 28.2214 24.2384 27.1663 24.2384 25.8714V23.285H41.0626V14.8974C41.0626 13.0282 40.4048 11.4517 39.119 10.1506C37.8337 8.86184 36.2752 8.1887 34.4263 8.1887ZM35.8951 18.4907H24.2384V14.4239C24.2384 13.8944 24.7104 13.4075 25.2813 13.4075H34.8998C35.4702 13.4075 35.8951 13.8944 35.8951 14.4239V18.4907Z"
			fill="currentColor"
		/>
		<path
			d="M63.6288 10.4365C62.1592 11.9257 61.3995 13.7462 61.3995 15.9047V24.0015C61.3995 26.6373 62.3059 28.9395 64.0579 30.8133C65.8547 32.6733 68.0316 33.5866 70.6421 33.5866H78.2255C78.2255 37.4826 78.4183 38.307 72.7914 38.4078C72.3171 38.4208 67.6297 38.4246 67.0692 38.4246V43.9392H74.7151C79.835 43.9392 82.1559 42.1635 83.1011 39.8652C83.8144 38.1377 83.8593 37.037 83.8593 35.1693V8.18797H69.0767C66.9451 8.18797 65.1421 8.95344 63.6288 10.4365ZM78.27 27.8828H70.6421C68.6997 27.8828 67.0432 26.109 67.0432 24.0015V15.9047C67.0432 14.7994 67.937 13.8949 69.0767 13.8949H78.27V27.8828Z"
			fill="currentColor"
		/>
		<path
			d="M103.612 8.1887H90.255V12.9324H103.612C104.89 12.9324 105.883 13.9898 105.883 15.2821V18.4907H89.0601V26.8744C89.0601 28.7467 89.7297 30.3267 91.0101 31.6205C92.2859 32.9146 93.8525 33.5881 95.696 33.5881H104.841C106.692 33.5881 108.308 32.9146 109.585 31.6205C110.857 30.3267 111.529 28.7467 111.529 26.8744V16.2414C111.529 14.0373 110.768 12.1666 109.204 10.5847C107.64 9.00474 105.791 8.1887 103.612 8.1887ZM105.883 27.115C105.883 27.6456 105.414 28.1203 104.841 28.1203H95.3704C94.8022 28.1203 94.3758 27.6456 94.3758 27.115V23.0908H105.883V27.115Z"
			fill="currentColor"
		/>
		<path
			d="M129.361 8.19604V8.18877H122.372H116.73V13.893V33.5867H122.372V13.893H129.324C131.721 13.9125 133.604 16.0101 133.604 18.5432V33.5867H139.187V18.5432C139.187 15.6672 138.202 13.2241 136.255 11.2089C134.324 9.21322 132.029 8.21405 129.361 8.19604Z"
			fill="currentColor"
		/>
		<path
			d="M144.387 33.5869H150.029V8.18825H144.387V33.5869Z"
			fill="currentColor"
		/>
		<path
			d="M5.64533 24.0498V13.4084H0.0043335V24.0498C0.0043335 26.6895 0.910786 28.9395 2.71181 30.8114C4.51054 32.6726 6.68932 33.5879 9.25007 33.5879H13.8302V27.9312H9.25007C7.30346 27.9312 5.64533 26.1083 5.64533 24.0498Z"
			fill="currentColor"
		/>
		<path
			d="M13.3999 7.75974V13.4038H5.64062L0 7.76396V0.00432873H5.64445V7.75974H13.3999Z"
			fill="currentColor"
		/>
	</SvgIcon>
);
