import services from 'services/services';
import * as types from 'store/types';
import {
	rejectBookingUrl,
	cancelBookingUrl,
	acceptBookingUrl,
	approveBookingCancellationUrl,
	assignUrgentAssistanceReasonUrl,
	changeUrgentReasonStatusUrl,
	markBookingAsCompensatedUrl,
	changeBookingCancelReasonStatusUrl,
	changeBookingCompensationStatusUrl,
	rejectBookingExtensionUrl,
	approveBookingExtensionUrl,
} from 'store/paths';

// Import helpers
import { BOOKING_CANCELLATION_STATES, isSpecificCancelReason } from 'helpers';

export const rejectBooking = async ({
	bookingId,
	values,
	cancellationReasons,
}) => {
	try {
		const uuid = values.cancellationReasonId;
		const shouldAddAttachment = isSpecificCancelReason(
			cancellationReasons,
			uuid
		);

		const data = {
			cancel_reason: {
				uuid,
				...(shouldAddAttachment && {
					attachment: values.attachment,
				}),
			},
		};

		await services.put(rejectBookingUrl(bookingId), data);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const cancelBooking = async ({
	bookingId,
	values,
	cancellationReasons,
}) => {
	try {
		const uuid = values.cancellationReasonId;
		const shouldAddAttachment = isSpecificCancelReason(
			cancellationReasons,
			uuid
		);

		const data = {
			cancel_reason: {
				uuid,
				...(shouldAddAttachment && {
					attachment: values.attachment,
				}),
			},
		};

		await services.put(cancelBookingUrl(bookingId), data);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const acceptBooking = async ({ bookingId }) => {
	try {
		await services.put(acceptBookingUrl(bookingId), {});

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const approveBookingCancellation = async ({ bookingId }) => {
	try {
		await services.put(approveBookingCancellationUrl(bookingId), {
			is_approved: true,
		});

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const assignUrgentAssistanceReason = async ({
	bookingId,
	urgentReasonId,
}) => {
	try {
		const body = {
			urgent_assistance_reason_uuid: urgentReasonId,
		};
		await services.put(assignUrgentAssistanceReasonUrl(bookingId), body);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const changeUrgentReasonStatus = async ({
	bookingId,
	status,
	cancelToken,
}) => {
	try {
		await services.put(
			changeUrgentReasonStatusUrl(bookingId),
			{ status },
			cancelToken
		);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const markBookingAsCompensated = async ({
	booking,
	values,
	cancelToken,
	isCancellation,
}) => {
	const { rrn, transaction_file } = values;
	const {
		uuid,
		compensation_amount,
		compensation: {
			method,
			iban,
			bank_name,
			contract_file,
			uuid: compensationUuid,
		},
	} = booking;

	try {
		const compensationValues = {
			rrn,
			iban,
			method,
			bank_name,
			contract_file,
			transaction_file,
			compensation_amount,
		};

		await services.put(
			markBookingAsCompensatedUrl(compensationUuid),
			compensationValues,
			cancelToken
		);

		const cancellationValues = {
			status: BOOKING_CANCELLATION_STATES.COMPENSATED,
			is_cancellation: isCancellation,
		};

		await services.put(
			changeBookingCancelReasonStatusUrl(uuid),
			cancellationValues,
			cancelToken
		);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const changeBookingCancelReasonStatus = async ({
	uuid,
	status,
	isCancellation,
	cancelToken,
}) => {
	try {
		const body = { status, is_cancellation: isCancellation };

		await services.put(
			changeBookingCancelReasonStatusUrl(uuid),
			body,
			cancelToken
		);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const changeBookingCompensationStatus = async ({
	booking,
	status,
	cancelToken,
}) => {
	const {
		uuid,
		compensation_amount,
		compensation: { method, iban, bank_name, contract_file },
	} = booking;

	try {
		const compensationValues = {
			compensation_amount,
			method,
			iban,
			bank_name,
			contract_file,
			status,
		};

		await services.put(
			changeBookingCompensationStatusUrl(uuid),
			compensationValues,
			cancelToken
		);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const approveBookingExtension =
	({ extensionUuid }) =>
	async (dispatch) => {
		const { data } = await services.put(
			approveBookingExtensionUrl(extensionUuid)
		);

		dispatch({
			type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
			payload: data.data,
		});
	};

export const rejectBookingExtension =
	({ extensionUuid }) =>
	async (dispatch) => {
		const { data } = await services.put(
			rejectBookingExtensionUrl(extensionUuid)
		);

		dispatch({
			type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
			payload: data.data,
		});
	};
