import { useState } from 'react';
import { useTableContextProvider } from 'components/context';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
	setValidationTableChangedState,
	setBookingPreviewItem,
	resetBookingPreviewItem,
} from 'store/actions';

import { URLS } from 'components/routes';

const useEditCompensation = ({ cell }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { fetchData } = useTableContextProvider();
	const dispatch = useDispatch();

	const { pathname } = useLocation();

	const handleOnOpenModal = () => {
		const booking = cell.row.original;
		setBookingPreviewItem({ booking, dispatch });

		setValidationTableChangedState({ isTableChanged: false, dispatch });

		setIsOpen(true);
	};

	const handleOnCloseModal = () => {
		resetBookingPreviewItem({ dispatch });
		setIsOpen(false);

		const isWaitingCustomerInfoPage = pathname.includes(
			URLS.waitingCustomerInfoUrl
		);

		isWaitingCustomerInfoPage && fetchData();
	};

	const handleCustomSubmit = () => {
		setIsOpen(false);
		resetBookingPreviewItem({ dispatch });
		fetchData();
	};

	return {
		handleOpenModal: handleOnOpenModal,
		handleCloseModal: handleOnCloseModal,
		handleCustomSubmit,
		isOpen,
	};
};

export default useEditCompensation;
