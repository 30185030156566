import { t } from 'i18next';

export const columns = [
	{
		Header: 'common.cars.headers.year',
		accessor: 'vehicle_year',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.model_english',
		accessor: 'car_model',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.maker_english',
		accessor: 'car_maker',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.miles_usage',
		accessor: 'miles_usage',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.color',
		accessor: 'body_color',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.plate_type',
		accessor: 'plate_type',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.deductible_amount',
		accessor: 'deductible_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.oil',
		accessor: 'used_oil',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.fuel',
		accessor: 'fuel_octane',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.full_tank_in_sar',
		accessor: 'cost_of_full_tank',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.plate_number',
		accessor: 'plate_number',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.plate_third_char',
		accessor: 'plate_third_char',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.plate_second_char',
		accessor: 'plate_second_char',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.plate_first_char',
		accessor: 'plate_first_char',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.rent_status',
		accessor: 'rent_status',
		valueExtractor: ({ rent_status }) =>
			t(`cars.car_list.plate_form.car_status.${rent_status}`).toLowerCase(),
		options: [
			`cars.car_list.plate_form.car_status.available`,
			`cars.car_list.plate_form.car_status.rented`,
		],
		type: 'list',
		disableSortBy: true,
	},
];
