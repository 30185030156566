import { omit } from 'lodash';

export const getConvertedPolicies = (policies) => {
	const filteredDescription = omit(policies, [
		'numberOfDaysToExtendBeforeContractEndDate',
		'shortName',
		'id',
	]);

	return Object.entries(filteredDescription).reduce((acc, [key, value]) => {
		const newObject = {
			title: `contract.create_contract_form.policies.${key}`,
			description: value,
		};
		return [...acc, newObject];
	}, []);
};
