import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';

// Import utils
import { useInitiateContractInitialValues, useContractPdf } from 'queries';

export const useContract = () => {
	const { id: uuid } = useParams();

	const {
		isFetching: isCollectionFetching,
		isError: isCollectionError,
		error: collectionError,
		data: collection,
	} = useInitiateContractInitialValues(uuid);

	const { contract_number: contractNumber } = collection || {};

	const {
		isFetching: isContractPdfFetching,
		isError: isContractPdfError,
		data: blob,
	} = useContractPdf(contractNumber);

	const isFetching = isContractPdfFetching || isCollectionFetching;

	const isError = isContractPdfError || isCollectionError;

	const download = () => {
		saveAs(blob, contractNumber);
	};

	const print = () => {
		const url = URL.createObjectURL(blob);
		const W = window.open(url);

		W.print();
	};

	return {
		isContract: !!contractNumber,
		isFetching,
		isError,
		collectionError,
		download,
		print,
	};
};
