import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	totalPrice: {
		color: theme.palette.primary.main,
		fontSize: '15px',
	},
}));

export default useStyles;
