import React from 'react';
import { object } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import components
import PreviewField from './PreviewField';

// Import utilities
import { useProfile, useTranslations } from 'components/utilities';

// Import helpers
import { PAYMENT_METHODS, GATWAY_METHOD_TYPES } from 'helpers';

// Import styles
import useStyles from './styles';

export const PaymentMethodsPreviewField = ({ customData }) => {
	const { payment_methods: paymentMethods } = customData || {};
	const { isAdmin } = useProfile();
	const { t } = useTranslations();
	const classes = useStyles();

	const renderPayment = (payment, index) => {
		const { gateway, gateway_method_type, gateway_method } = payment;
		const label = index === 0 ? t('bookings.table.headers.payment_method') : '';
		const sectionName = 'bookings.preview_booking.invoice';

		const getTextForAdmins = () => {
			if (gateway === 'corporate_limit') {
				return t('b2b.b2b');
			}

			if (gateway === PAYMENT_METHODS.TAMARA) {
				return t('bookings.preview_booking.invoice.tamara');
			}

			if (gateway === PAYMENT_METHODS.TABBY) {
				return t('bookings.preview_booking.invoice.tabby');
			}

			if (
				gateway === PAYMENT_METHODS.LOYALTY_PROGRAM &&
				gateway_method_type === GATWAY_METHOD_TYPES.QITAF_LOYALTY_PROGRAM
			) {
				return t('bookings.preview_booking.invoice.qitaf');
			}
			if (gateway === PAYMENT_METHODS.HYPERPAY) {
				return (
					t(`${sectionName}.${gateway_method}`) +
					' - ' +
					t(`${sectionName}.method_type.${gateway_method_type}`) +
					' (' +
					t(`bookings.preview_booking.invoice.hyperpay`) +
					')'
				);
			}

			return (
				t(`${sectionName}.${gateway_method}`) +
				' - ' +
				t(`${sectionName}.method_type.${gateway_method_type}`)
			);
		};

		const textForAdmins = getTextForAdmins();
		const textForOthers = t(`${sectionName}.pre_paid`);

		return (
			<Grid key={index} item xs={12} className={classes.paymentMethod}>
				<PreviewField label={label}>
					{isAdmin ? textForAdmins : textForOthers}
				</PreviewField>
			</Grid>
		);
	};

	return (
		<Grid container>
			{paymentMethods.map((payment, index) => renderPayment(payment, index))}
		</Grid>
	);
};

PaymentMethodsPreviewField.propTypes = {
	customData: object,
};
