import services from 'services/services';

// Import store
import { fetchLastBookingUrl, updateStatusLastBookingUrl } from 'store/paths';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';

export const fetchLastBooking =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchLastBookingUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateStatusLastBooking =
	({ isAccepted, id, cancelId }) =>
	async () => {
		try {
			const deleteOption = {
				cancel_reason: {
					uuid: cancelId,
				},
			};
			const options = isAccepted ? deleteOption : {};

			// Get response
			await services.put(updateStatusLastBookingUrl(id, isAccepted), options);
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	};
