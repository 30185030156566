import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	IMAGE: 'image',
	ACTIVE: 'active',
};

export const columns = [
	{
		Header: 'sliders.table.headers.image',
		accessor: COLUMNS_ACCESSORS.IMAGE,
		disableSortBy: true,
	},
	{
		Header: 'sliders.table.headers.name',
		accessor: 'name',
	},
	{
		Header: 'sliders.table.headers.position',
		accessor: 'position',
		disableSortBy: true,
	},
	{
		Header: 'sliders.table.headers.locale',
		accessor: 'locale',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
