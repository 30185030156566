import * as types from 'store/types';
import services from 'services/services';
import ls from 'localstorage-slim';
import { addYears, differenceInSeconds } from 'date-fns';

import {
	forgotPasswordUrl,
	forcePasswordUrl,
	resetPasswordUrl,
	verifyCodeUrl,
	signOutUrl,
	signInUrl,
	updateUserProfileUrl,
	signInVerifyUrl,
} from 'store/paths';

// Import variables
import { convertProfileData, modifyVerifyData } from './helpers';

// Import actions
import { getProfile } from 'store/actions';
import {
	clearLocaleStorageCache,
	LOCAL_STORAGE_USER,
	LOCAL_STORAGE_USER_PROFILE,
	DRIVER,
} from 'helpers';

// ******************** FORGOT PASSWORD ********************
export const forgotPassword =
	(values, errorCallback, callbackSuccess) => async (dispatch) => {
		try {
			const {
				data: {
					data: { token },
				},
			} = await services.post(forgotPasswordUrl, values);
			dispatch({
				type: types.SET_RESET_TOKEN,
				payload: token,
			});
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const verifyCode =
	(values, errorCallback, callbackSuccess) => async () => {
		try {
			await services.post(verifyCodeUrl, values);
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const resetPassword =
	(values, errorCallback, callbackSuccess) => async (dispatch) => {
		try {
			await services.post(resetPasswordUrl, values);
			dispatch({
				type: types.SET_RESET_TOKEN,
				payload: null,
			});
			await signOut()(dispatch);
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const forcePassword =
	(errorCallback, callbackSuccess) => async (dispatch) => {
		try {
			const {
				data: {
					data: { token },
				},
			} = await services.get(forcePasswordUrl);
			dispatch({
				type: types.SET_RESET_TOKEN,
				payload: token,
			});
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data?.errors);
		}
	};

// ******************** LOGIN ********************
export const signIn = async ({ values, errorCallback, successCallback }) => {
	try {
		const { data } = await services.post(signInUrl, values);
		successCallback(data);
	} catch (error) {
		error.response && errorCallback(error.response.data);
	}
};

// ******************** Verify OTP ********************
export const verifyLoginOtp = (values, errorCallback) => async (dispatch) => {
	try {
		const { data } = await services.post(signInVerifyUrl, values);

		const convertedData = { ...modifyVerifyData(data) };

		ls.set(LOCAL_STORAGE_USER, convertedData);

		dispatch({
			type: types.AUTH_USER_SUCCESS,
			payload: convertedData,
		});
	} catch (error) {
		error.response && errorCallback(error.response.data);
		dispatch({
			type: types.AUTH_USER_ERROR,
			payload: true,
		});
	}
};

// ******************** SIGN OUT ********************
export const signOut = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.SIGN_OUT_LOADING,
			payload: true,
		});

		// Sign out user from application
		await services.get(signOutUrl);

		ls.remove(LOCAL_STORAGE_USER);

		// Remove cached data
		clearLocaleStorageCache({});

		dispatch({
			type: types.SIGN_OUT_SUCCESS,
			payload: true,
		});
	} catch (error) {
		dispatch({
			type: types.SIGN_OUT_ERROR,
			payload: true,
		});
	}
};

// ******************** DRIVERS AUTO SIGN IN ********************
export const driversAutoSignIn = (token) => async (dispatch) => {
	const currentDate = new Date();
	const nextYearDate = addYears(new Date(), 1);
	const expiresIn = differenceInSeconds(nextYearDate, currentDate);

	try {
		await signOut()(dispatch);
		const data = {
			data: {
				meta: {
					access_token: token,
					expires_in: expiresIn,
				},
				isAuthenticated: true,
				force_password_reset: false,
				role: [DRIVER],
			},
		};

		ls.set(LOCAL_STORAGE_USER, data);

		return dispatch({
			type: types.AUTH_USER_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: types.AUTH_USER_ERROR,
			payload: true,
		});
	}
};

export const updateUserProfile =
	() => async (dispatch, isCorporateCoordinator) => {
		const localStorageUserProfile = ls.get(LOCAL_STORAGE_USER_PROFILE);

		try {
			const { data } =
				!localStorageUserProfile && (await services.get(updateUserProfileUrl));

			data?.details?.role !== DRIVER && (await getProfile(dispatch));
			const ttl = isCorporateCoordinator ? 60 : 24 * 60 * 60;

			if (!localStorageUserProfile) {
				ls.set(LOCAL_STORAGE_USER_PROFILE, data.data, {
					ttl: ttl,
				});
			}

			const userProfileData = localStorageUserProfile || data.data;

			const convertedProfileData = convertProfileData(userProfileData);

			await getProfile(dispatch);

			dispatch({
				type: types.USER_PROFILE_FETCH_SUCCESS,
				payload: convertedProfileData,
			});
		} catch (error) {
			dispatch({
				type: types.USER_PROFILE_FETCH_ERROR,
				payload: error.response,
			});
		}
	};
