import React from 'react';
import { arrayOf, string } from 'prop-types';
import { Button } from '@material-ui/core';

// Import helpers
import { getInitialValues } from '../helpers';
import useAdditionalInfo from '../useAdditionalInfo';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { ModalDialog } from 'components/elements';
import { AdditionalFields } from './components';
import { FilterIcon } from 'icons';

const AdditionalInfoModal = ({ fields }) => {
	const { t } = useTranslations();

	const { onSubmit, onClear, open, toggle } = useAdditionalInfo({
		fields,
	});

	return (
		<>
			<Button
				onClick={toggle}
				startIcon={<FilterIcon />}
				color="primary"
				variant="outlined"
			>
				{t('bookings.table.headers.additional_info')}
			</Button>
			<ModalDialog
				title="bookings.table.headers.additional_info"
				initialValues={getInitialValues(fields)}
				onSubmit={onSubmit}
				alignTitle="left"
				close={toggle}
				open={open}
				withAdditionalInfoClearButton
				onClear={onClear}
			>
				{({ submitting }) => (
					<AdditionalFields submitting={submitting} fields={fields} />
				)}
			</ModalDialog>
		</>
	);
};

AdditionalInfoModal.propTypes = {
	fields: arrayOf(string),
};

export default AdditionalInfoModal;
