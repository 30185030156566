import React from 'react';
import { bool, func, object } from 'prop-types';
import { TableCell, IconButton } from '@material-ui/core';

// Import icons
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

// Import styles
import { useStyles } from './styles';

export const ExpandTableCell = ({ cell, onClick, isOpen, hideExpand }) => {
	const classes = useStyles({ hideExpand });

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<IconButton
				className={isOpen ? classes.minimizeButton : classes.expandButton}
				size="small"
				onClick={onClick}
			>
				{isOpen ? <RemoveIcon /> : <AddIcon />}
			</IconButton>
		</TableCell>
	);
};

ExpandTableCell.propTypes = {
	cell: object.isRequired,
	onClick: func,
	isOpen: bool,
	hideExpand: bool,
};

ExpandTableCell.defaultProps = {
	hideExpand: false,
};
