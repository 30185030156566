import React, { useEffect } from 'react';
import { fetchOffices, setCompanyContext } from 'store/actions';
import { useDispatch } from 'react-redux';
import { shape, string } from 'prop-types';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { ActionButtonsToolbar, TableBodyRow } from './components';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';
import { useProfile } from 'components/utilities';

const Offices = ({ match: { params } }) => {
	const classes = useBaseLayoutStyles();

	const dispatch = useDispatch();

	useEffect(() => {
		setCompanyContext(dispatch, params.id);
	}, [dispatch, params.id]);

	const { isAdmin, isCompanyManager } = useProfile();

	return (
		<TableProvider
			columns={columns({ isAdmin, isCompanyManager })}
			fetchDataTableAction={fetchOffices}
		>
			<div className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<div className={classes.content}>
					<Table
						isDefaultRow={false}
						tableInnerToolbar={<ActionButtonsToolbar />}
					>
						{({ row }) => <TableBodyRow key={row.id} row={row} />}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};

Offices.propTypes = {
	match: shape({
		params: shape({
			id: string,
		}),
	}),
};

export default Offices;
