import { setIn } from 'final-form';

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const yupValidator = async ({ values, validationSchema }) => {
	try {
		await validationSchema.validate(values, { abortEarly: false });
	} catch (err) {
		const errors = err.inner.reduce((formError, innerError) => {
			return setIn(formError, innerError.path, innerError.message);
		}, {});

		return errors;
	}
};

export const isAuthorizationTypeCodeVisible = (values) => {
	const { contract_type_code: contractTypeCode } = values.authorization_details;
	const { id_type_code: idTypeCode } = values.renter;

	return (
		contractTypeCode === 3 ||
		contractTypeCode === 4 ||
		idTypeCode === 1 ||
		idTypeCode === 2
	);
};

export const isHourlyContractType = (values) => {
	const { contract_type_code } = values.authorization_details;
	return contract_type_code === 2 || contract_type_code === 4;
};

export const isVisitorOrGcc = (values) => {
	const { id_type_code: idTypeCode } = values.renter;
	return idTypeCode === 3 || idTypeCode === 4;
};

export const isClosureCodeRequired = ({ closureReasons, mainClosureCode }) => {
	const subClosureReasons = closureReasons?.find(
		({ id }) => id === mainClosureCode
	)?.subReasons;

	return null != mainClosureCode && !!subClosureReasons.length;
};
