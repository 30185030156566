// ----------------- FORM -----------------
export const CUSTOMER_FORM_FETCH_DATA_LOADING =
	'CUSTOMER_FORM_FETCH_DATA_LOADING';
export const CUSTOMER_FORM_FETCH_DATA_SUCCESS =
	'CUSTOMER_FORM_FETCH_DATA_SUCCESS';
export const CUSTOMER_FORM_FETCH_DATA_ERROR = 'CUSTOMER_FORM_FETCH_DATA_ERROR';

// ----------------- PREVIEW -----------------
export const CUSTOMER_PREVIEW_FETCH_DATA_LOADING =
	'CUSTOMER_PREVIEW_FETCH_DATA_LOADING';
export const CUSTOMER_PREVIEW_FETCH_DATA_SUCCESS =
	'CUSTOMER_PREVIEW_FETCH_DATA_SUCCESS';
export const CUSTOMER_PREVIEW_FETCH_DATA_ERROR =
	'CUSTOMER_PREVIEW_FETCH_DATA_ERROR';
