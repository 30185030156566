import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

// Import components
import { InfoListItem } from './index';

const DefaultInfoListItem = ({ accessor, label }) => {
	const info = useSelector((store) => store.bookings.form.info.item);

	return <InfoListItem label={label}>{get(info, accessor)}</InfoListItem>;
};

DefaultInfoListItem.propTypes = {
	accessor: PropTypes.string,
	label: PropTypes.string,
};

export default DefaultInfoListItem;
