import React from 'react';
import { func, string } from 'prop-types';
import { useSelector } from 'react-redux';

// Import selectors and actions
import { isAdminSelector } from 'store/selectors';
import { fetchBookings } from 'store/actions';

// Import components
import { TableExportProvider } from 'components/context';
import { TableExportButtons, TableExport } from 'components/elements';
import { ExportFilters } from '../ExportFilters';

// Import helpers
import { getExportColumns } from './helpers';
import { BOOKINGS_PAGES_TYPES } from 'helpers';

export const Export = ({ pageType, fetchDataTableAction }) => {
	const isAdmin = useSelector(isAdminSelector);
	const exportColumns = getExportColumns({ pageType, isAdmin });

	return (
		<TableExportProvider
			columns={exportColumns}
			fetchDataTableAction={fetchDataTableAction}
		>
			<TableExportButtons />
			<TableExport
				renderFilters={() => <ExportFilters pageType={pageType} />}
			/>
		</TableExportProvider>
	);
};

Export.propTypes = {
	fetchDataTableAction: func,
	pageType: string,
};

Export.defaultProps = {
	fetchDataTableAction: fetchBookings,
	pageType: BOOKINGS_PAGES_TYPES.MAIN,
};
