import React from 'react';
import { Field } from 'react-final-form';
import { Typography, Link, Box, FormHelperText } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

// Import utils
import { useFormContent } from './useFormContent';

// Import components
import {
	ButtonProgress,
	OTPInput,
	TextFieldAdapter,
} from 'components/elements';

// Import utils
import { URLS } from 'components/routes';

// Import styles
import {
	useStyles,
	otpInputsStyles,
	otpContainerStyles,
} from './FormContent.styles';

export const FormContent = () => {
	const classes = useStyles();

	const {
		handleOnChangeOtp,
		getOtpToken,
		isLoading,
		submitting,
		values,
		fieldsErrors,
		submitErrors,
	} = useFormContent();

	const { token, code } = values || {};

	const isFieldsDisabled = submitting || isLoading || !!token;

	return (
		<>
			<Field
				className={classes.textField}
				fullWidth
				component={TextFieldAdapter}
				type="text"
				label="Email address"
				name="email"
				variant="outlined"
				autoComplete="false"
				disabled={isFieldsDisabled}
				error={!!fieldsErrors?.email}
				helperText={fieldsErrors?.email || ''}
			/>
			<Field
				className={classes.textField}
				fullWidth
				component={TextFieldAdapter}
				type="password"
				label="Password"
				name="password"
				variant="outlined"
				autoComplete="false"
				disabled={isFieldsDisabled}
				error={!!fieldsErrors?.password}
				helperText={fieldsErrors?.password || ''}
			/>

			{!!token && (
				<Box className={classes.otpWrapper}>
					<Typography className={classes.otpLabel} variant="body1">
						OTP
					</Typography>
					<OTPInput
						value={code}
						onChange={handleOnChangeOtp}
						outerInputStyles={otpInputsStyles}
						outerContainerStyle={otpContainerStyles}
						numInputs={4}
					/>
					{submitErrors?.code && (
						<FormHelperText
							component="span"
							error
							className={classes.otpLabel}
							variant="body1"
						>
							{submitErrors.code}
						</FormHelperText>
					)}
				</Box>
			)}

			<div className={classes.wrapper}>
				{!token ? (
					<ButtonProgress
						className={classes.signInButton}
						color="primary"
						disabled={isLoading}
						fullWidth
						size="large"
						type="button"
						variant="contained"
						onClick={getOtpToken}
						isLoading={isLoading}
					>
						Sign in now
					</ButtonProgress>
				) : (
					<ButtonProgress
						className={classes.signInButton}
						color="primary"
						disabled={submitting}
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						isLoading={submitting}
					>
						Sign in now
					</ButtonProgress>
				)}
			</div>
			<Typography color="textSecondary" variant="body1">
				<Link to={URLS.forgotPasswordUrl} component={RouterLink} variant="h6">
					Forgot Password?
				</Link>
			</Typography>
		</>
	);
};
