import React from 'react';
import { Box } from '@material-ui/core';

// Import helpers and context
import { TOOLBAR_CONTENT } from './helpers';
import { FinancialReportProvider } from './context';

// Import components
import { Toolbar } from 'components/elements';

// Import styles
import { useStyles } from './FinancialReport.styles';

export const FinancialReport = () => {
	const classes = useStyles();

	return (
		<FinancialReportProvider>
			<Box className={classes.container}>
				<Toolbar content={TOOLBAR_CONTENT} />
			</Box>
		</FinancialReportProvider>
	);
};
