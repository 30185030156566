import React from 'react';
import { TableCell } from '@material-ui/core';
import { object } from 'prop-types';

// Import utils
import { useDoneActionTableCell } from './useDoneActionTableCell';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress } from 'components/elements';

export const DoneActionTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const { handleDoneAction, isLoading } = useDoneActionTableCell({ cell });

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<ButtonProgress
				onClick={handleDoneAction}
				color="primary"
				variant="contained"
				size="small"
				isLoading={isLoading}
				disabled={isLoading}
			>
				{t('common.buttons.done')}
			</ButtonProgress>
		</TableCell>
	);
};

DoneActionTableCell.propTypes = {
	cell: object.isRequired,
};
