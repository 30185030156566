// ********************  table  ********************

export const fetchCompanyDashboardStatsUrl = '/report/office-kpi';
export const fetchAdminDashboardStatsUrl = '/report/company-kpi';

// ********************  info  ********************

export const businessDashboardUrl = 'v2/supplier/business/dashboard';
export const businessManagerDashboardUrl =
	'v2/supplier/business/manager/dashboard';
