import React from 'react';
import { number, object, oneOfType, string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, TableCell, Typography, Link } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';

// Import helpers
import { replacePreviewBookingUrl } from 'helpers';

// Import styles
import { useStyles } from './BookingsCountTableCell.styles';

export const BookingsCountTableCell = ({ cell, minWidth = 'auto' }) => {
	const { t } = useTranslations();
	const {
		booking_accepted_counter: bookingAcceptedCounter,
		booking_cancelled_counter: bookingCancelledCounter,
		customer_compensation_counter: customerCompensationCounter,
		customer_first_compensated_booking_uuid: firstCompensatedBookingUuid,
		customer_first_compensated_booking_individual_number:
			firstCompensatedBookingId,
	} = cell.row.original;
	const classes = useStyles();

	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			style={{ minWidth }}
		>
			<Grid container wrap="nowrap" spacing={1}>
				<Grid item>
					<Typography>{customerCompensationCounter}</Typography>
				</Grid>
				<Grid item>
					<Typography>
						{t('bookings.compensation_states.compensated')}
					</Typography>

					<Link
						component={RouterLink}
						to={replacePreviewBookingUrl(firstCompensatedBookingUuid)}
						variant="h6"
					>
						{firstCompensatedBookingId}
					</Link>
				</Grid>
			</Grid>

			<Grid container alignItems="center" wrap="nowrap" spacing={1}>
				<Grid item>
					<Typography color="error">{bookingCancelledCounter}</Typography>
				</Grid>
				<Grid item>
					<Typography color="error">
						{t('common.statuses.cancelled')}
					</Typography>
				</Grid>
			</Grid>

			<Grid container alignItems="center" wrap="nowrap" spacing={1}>
				<Grid item>
					<Typography className={classes.acceptedBookings}>
						{bookingAcceptedCounter}
					</Typography>
				</Grid>
				<Grid item>
					<Typography className={classes.acceptedBookings}>
						{t('common.statuses.accepted')}
					</Typography>
				</Grid>
			</Grid>
		</TableCell>
	);
};

BookingsCountTableCell.propTypes = {
	cell: object,
	minWidth: oneOfType([string, number]),
};
