import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SwitchFieldAdapter } from 'components/elements';
import { SettingsFields } from './components';

// Import translations
import { useTranslations } from 'components/utilities';

export const BaseDetails = () => {
	const { t } = useTranslations(null, {
		keyPrefix: 'employee_rewarding.form.fields',
	});
	const { submitting, values } = useFormState();
	const isActive = values.is_active;

	return (
		<Box mt={2}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Field
						component={SwitchFieldAdapter}
						type="checkbox"
						labelText={t('is_active')}
						name="is_active"
						disabled={submitting}
						color="primary"
					/>
				</Grid>
				{isActive && (
					<Grid item xs={12} container spacing={2}>
						<SettingsFields submitting={submitting} />
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
