export const fetchBookingsFormResourcesUrl = (itemId) =>
	`/v2/booking/${itemId}/show`;

export const recalculateBookingUrl = (itemId) =>
	`/v2/booking/${itemId}/edit/preview`;

export const submitBookingFormUrl = (itemId) => `/v2/booking/${itemId}/edit`;

export const fetchBookingsFormInfoUrl = (itemId) =>
	`/v2/booking/${itemId}/show`;
