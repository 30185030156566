import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { object } from 'prop-types';
import { format } from 'date-fns';

// Import helpers
import {
	DLY_DATE_FORMAT,
	replacePreviewBookingUrl,
	replaceEditBookingUrl,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
// import { ValidationActionTableCell } from '../../components';
import { TopicTableCell } from './components';
import {
	ActionsTableCell,
	TwoRowsTableCell,
	LinkTableCell,
} from 'components/elements';
import { ValidationActionTableCell } from '../ValidationActionTableCell';

const {
	CANCELLATION_DATE,
	CUSTOMER_NAME,
	BOOKING_ID,
	PICK_DATE,
	ACTIONS,
	ACTION,
	TOPIC,
} = COLUMNS_ACCESSORS;

export const AdminTableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		const {
			cancellation_date: cancellationDate,
			cancellation_time: cancellationTime,
			individual_number: individualNumber,
			customer_phone: customerPhone,
			customer_name: customerName,
			pick_date: pickDate,
			pick_time: pickTime,
			uuid: bookingUuid,
		} = cell.row.original;

		const formattedPickDate = format(new Date(pickDate), DLY_DATE_FORMAT);
		const formattedCancellationDate =
			cancellationDate && format(new Date(cancellationDate), DLY_DATE_FORMAT);

		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case TOPIC:
				return <TopicTableCell key={cell.column.id} cell={cell} />;

			case PICK_DATE:
				return (
					<TwoRowsTableCell
						title={formattedPickDate}
						key={cell.column.id}
						subtitle={pickTime}
						cell={cell}
					/>
				);

			case CANCELLATION_DATE:
				return (
					<TwoRowsTableCell
						title={formattedCancellationDate}
						key={cell.column.id}
						subtitle={cancellationTime}
						cell={cell}
					/>
				);

			case CUSTOMER_NAME:
				return (
					<TwoRowsTableCell
						subtitle={customerPhone}
						title={customerName}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(bookingUuid)}
						minWidth={150}
					>
						{individualNumber}
					</LinkTableCell>
				);

			case ACTION:
				return <ValidationActionTableCell key={cell.column.id} cell={cell} />;

			case ACTIONS:
				return (
					<ActionsTableCell
						editLinkPath={replaceEditBookingUrl(bookingUuid)}
						key={cell.column.id}
						cell={cell}
						canEdit
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

AdminTableBodyRow.propTypes = {
	row: object.isRequired,
};
