import React from 'react';
import { string } from 'prop-types';
import { Tooltip } from '@material-ui/core';

// Import assets
import { MoneyIcon } from 'assets/icons';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';
import { useStyles } from './styles';

// Import components
import { IconButton } from 'components/elements';
import { SubscriptionHistory } from './components';

export const SubscriptionHistoryActionButton = ({ subscriptionUuid }) => {
	const { t } = useTranslations();
	const { on, toggle } = useToggle();
	const classes = useStyles();

	return (
		<>
			<Tooltip title={t('common.icon_names.subscription_history')}>
				<span>
					<IconButton onClick={toggle} className={classes.icon} size="small">
						<MoneyIcon />
					</IconButton>
				</span>
			</Tooltip>
			{on && (
				<SubscriptionHistory uuid={subscriptionUuid} on={on} onClose={toggle} />
			)}
		</>
	);
};

SubscriptionHistoryActionButton.propTypes = {
	subscriptionUuid: string.isRequired,
};
