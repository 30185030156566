import React from 'react';
import { MenuItem } from '@material-ui/core';
import { countryCodes } from 'helpers';
import SelectFiledAdapter from './SelectFiledAdapter';

const CountryCodeFieldAdapter = (props) => {
	return (
		<SelectFiledAdapter {...props}>
			{countryCodes.map(({ iso, code }) => {
				return (
					<MenuItem key={iso} value={iso}>
						{code}
					</MenuItem>
				);
			})}
		</SelectFiledAdapter>
	);
};

export default CountryCodeFieldAdapter;
