import React from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { Alert, LoadingOverlay } from 'components/elements';
import { TransactionHistoryBody } from './components';
import useTransactionHistory from './context/useTransactionHistory';

// Import helpers
import { columns } from './columns';

const TransactionHistory = ({ open, onClose }) => {
	const { t } = useTranslations();

	const { isFetching, isError, error } = useTransactionHistory();

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg">
			<Box position="relative">
				<DialogTitle>{t('bookings.transaction_history.title')}</DialogTitle>
				
				<LoadingOverlay isLoading={isFetching} />
				{isError && <Alert message={error} />}

				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{columns.map(({ header, accessor }) => (
									<TableCell key={accessor}>
										{header ? t(header) : ''}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							<TransactionHistoryBody />
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Dialog>
	);
};

TransactionHistory.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func
};

export default TransactionHistory;
