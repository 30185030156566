export const marks = [
	{
		value: 0,
		label: 'E',
	},
	{
		value: 1,
		label: '',
	},
	{
		value: 2,
		label: '1/2',
	},
	{
		value: 3,
		label: '',
	},
	{
		value: 4,
		label: 'F',
	},
];
