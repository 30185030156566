/* eslint-disable */
export const getCustomData = (data) => {
	const { before_transfer: beforeTransfer } = data || {};

	return beforeTransfer
		? {
				...beforeTransfer?.data,
				options: Object.values(beforeTransfer?.data?.options),
		  }
		: data;
};
