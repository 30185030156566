import React from 'react';
import { useSelector } from 'react-redux';
import { object, array, oneOfType } from 'prop-types';

// Import utilities
import { previewBookingSelector } from 'store/selectors/bookings';
import { useTranslations } from 'components/utilities';

// Import components
import PreviewField from './PreviewField';

// Import helpers
import { getOption } from '../helpers';
import { BOOKING_OPTION_TYPES } from 'helpers';

const OptionsPreviewField = ({ customData }) => {
	const { t } = useTranslations();

	const booking = useSelector(previewBookingSelector);

	return (
		<>
			{Object.keys(BOOKING_OPTION_TYPES).map((key) => {
				const type = BOOKING_OPTION_TYPES[key];

				return (
					<PreviewField key={key} label={`bookings.options.${type}`}>
						{getOption(type, customData || booking)
							? t('common.yes')
							: t('common.no')}
					</PreviewField>
				);
			})}
		</>
	);
};

OptionsPreviewField.propTypes = {
	customData: oneOfType([array, object]),
};

export default OptionsPreviewField;
