import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import {
	replaceEditBookingUrl,
	replaceOfficeDetailsUrl,
	replacePreviewBookingUrl,
} from 'helpers';

// Import components
import {
	EditCompensationActionTableCell,
	BookingTopicsTableCell,
	LinkTableCell,
	NoteTableCell,
	RatingReasonTableCell,
	ActionsTableCell,
	CompensationAmountTableCell,
} from 'components/elements';

// Import styles
import { useStyles } from 'theme/styles';

const {
	BOOKING_ID,
	OFFICE_NAME,
	NOTE,
	CUSTOMER_NAME,
	PICK_DATE,
	CREATED_AT,
	TOPIC,
	ACTION,
	RATING_REASON,
	ACTIONS,
	COMPENSATION_AMOUNT,
} = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const classes = useStyles();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case NOTE:
				return <NoteTableCell key={cell.column.id} cell={cell} />;

			case COMPENSATION_AMOUNT:
				return <CompensationAmountTableCell key={cell.column.id} cell={cell} />;

			case OFFICE_NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceOfficeDetailsUrl(cell.row.original.office_uuid)}
					>
						{cell.row.original.office_name}
					</LinkTableCell>
				);
			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(cell.row.original.uuid)}
					>
						{cell.row.original.individual_number}
					</LinkTableCell>
				);

			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.customer_name}
						<br /> {cell.row.original.customer_phone}
					</TableCell>
				);
			case PICK_DATE:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.pick_date}
						<br /> {cell.row.original.pick_time}
					</TableCell>
				);
			case CREATED_AT: {
				const [date, time] = cell.row.original?.created_at.split(' ');
				return (
					<TableCell key={cell.column.id}>
						{date}
						<br />
						{time}
					</TableCell>
				);
			}
			case RATING_REASON:
				return <RatingReasonTableCell key={cell.column.id} cell={cell} />;

			case TOPIC:
				return <BookingTopicsTableCell key={cell.column.id} cell={cell} />;
			case ACTION:
				return (
					<EditCompensationActionTableCell key={cell.column.id} cell={cell} />
				);
			case ACTIONS:
				return (
					<ActionsTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditBookingUrl(cell.row.original.uuid)}
						canEdit={cell.row.original.can_edit}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			key={row.id}
			{...row.getRowProps()}
			className={clsx({
				[classes.accountDeleted]: row.original.customer_deleted_at,
			})}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
