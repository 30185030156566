import { useDispatch } from 'react-redux';

import { forgotPassword } from 'store/actions';
import { convertApiFormErrors } from 'helpers/form';

export default (history) => {
	const dispatch = useDispatch();

	const handleSubmitStep1 = async (values) => {
		let apiErrors = {};
		await forgotPassword(
			values,
			(errors) => {
				apiErrors = convertApiFormErrors(errors);
			},
			() => {
				history.push('/reset-password');
			}
		)(dispatch);
		return apiErrors;
	};

	return { submitStep1: handleSubmitStep1 };
};
