import i18next from 'i18next';

export const getCarInfo = ({ model, models, lng }) =>
	models.find(
		({ model_name }) =>
			model_name[lng] === model || model_name[lng] === model[lng]
	);

export const getMenuValue = ({ name, fieldName }) =>
	fieldName.includes(i18next.language) ? name[i18next.language] : name;
