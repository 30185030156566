import { FIELD_TYPES } from 'helpers';

export const SYSTEM_CLIENTS_FIELDS = ({ appVersions, name }) => [
	{
		type: FIELD_TYPES.SELECT,
		name: `${name}.app`,
		label: 'mobile_app.form.label.app',
		required: true,
		md: 2,
		options: appVersions?.versioned?.apps,
		keyExtractor: (app) => app,
		labelExtractor: (app) => app,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: `${name}.platform`,
		label: 'mobile_app.form.label.platform',
		required: true,
		md: 2,
		options: appVersions?.versioned?.platforms,
		keyExtractor: (platform) => platform,
		labelExtractor: (platform) => platform,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: `${name}.version.current`,
		label: 'mobile_app.form.label.current_version',
		required: true,
		md: 3,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: `${name}.version.minimal`,
		label: 'mobile_app.form.label.minimal_version',
		required: true,
		md: 3,
	},
];
