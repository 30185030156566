import React from 'react';
import { Box, Button, ButtonGroup, Divider } from '@material-ui/core';
import { object } from 'prop-types';
import clsx from 'clsx';

// Import styles
import { useStyles } from './DialogContent.styles';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useDialogContent } from './useDialogContent';
import { DELIVERY_TYPES } from './helpers';

const { PICK, DROP } = DELIVERY_TYPES;

export const DialogContent = ({ data }) => {
	const { t } = useTranslations();

	const {
		setSelectedImage,
		setDeliveryType,
		deliveryType,
		selectedImage,
		images,
	} = useDialogContent({
		data,
	});

	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Divider className={classes.divider} />
			<ButtonGroup disableElevation variant="contained" color="primary">
				<Button
					onClick={() => setDeliveryType(PICK)}
					className={clsx({ [classes.notActive]: deliveryType !== PICK })}
				>
					{t('common.fields.pickup')}
				</Button>
				<Button
					onClick={() => setDeliveryType(DROP)}
					className={clsx({ [classes.notActive]: deliveryType !== DROP })}
				>
					{t('common.fields.return')}
				</Button>
			</ButtonGroup>
			<Box
				className={classes.mainImage}
				component="img"
				src={selectedImage.url}
			/>
			<Box className={classes.imagesContainer}>
				{images.map((image) => (
					<Box
						key={image.type}
						component="img"
						className={classes.image}
						src={image.url}
						onClick={() => setSelectedImage(image)}
					/>
				))}
			</Box>
		</Box>
	);
};

DialogContent.propTypes = {
	data: object,
};
