import React from 'react';
import { editBookingFormSelector } from 'store/selectors';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

// Import components
import { ButtonProgress, Card } from 'components/elements';
import { EditBookingInfo } from '../../..';

// Import utilities
import { useTranslations } from 'components/utilities';

const EditBookingCard = () => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	const {
		recalculate: { isRecalculated },
	} = useSelector(editBookingFormSelector);

	return (
		<Card
			title={t('bookings.single_booking.form.title')}
			lg={12}
			actions={
				isRecalculated && (
					<ButtonProgress
						type="submit"
						color="primary"
						variant="contained"
						isLoading={submitting}
						disabled={submitting}
						circularProgressSize={28}
					>
						{t('table.modal.edit.btn_save')}
					</ButtonProgress>
				)
			}
		>
			<EditBookingInfo />
		</Card>
	);
};

export default EditBookingCard;
