import React from 'react';
import { Button } from '@material-ui/core';
import { bool } from 'prop-types';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

export const ActiveButton = ({ isActive, ...props }) => {
	const { t } = useTranslations();

	const classes = useStyles({ isActive });

	return (
		<Button
			color="primary"
			variant="outlined"
			className={classes.button}
			{...props}
		>
			{t(`table.actions.${isActive ? 'active' : 'deactive'}`)}
		</Button>
	);
};

ActiveButton.propTypes = {
	isActive: bool.isRequired,
};
