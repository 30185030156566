export default {
	root: {
		'@media (max-width: 960px)': {
			'& .MuiRadio-root': {
				color: '#C8CCD2',
			},
		},
	},
	label: {
		color: '#7D8997',
		fontWeight: 600,
		fontSize: 14,
		lineHeight: '14px',
		'@media (max-width: 960px)': {
			color: '#323B4B',
		},
	},
};
