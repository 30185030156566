import React from 'react';
import { string, number, node, oneOfType, arrayOf, bool } from 'prop-types';
import { useParams, NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	Button,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { replaceEditCustomerUrl } from 'helpers';

const useStyles = makeStyles(() => ({
	action: {
		marginTop: 0,
		marginRight: 0,
	},
}));

const PreviewWrapper = ({ children, title, md = 8, xs = 12, isAction }) => {
	const { id } = useParams();

	const classes = useStyles();

	const { t } = useTranslations();

	const renderAction = () =>
		isAction && (
			<NavLink exact to={replaceEditCustomerUrl(id)}>
				<Button color="primary" variant="contained" size="small">
					{t('customers.form.title')}
				</Button>
			</NavLink>
		);

	return (
		<Grid item md={md} xs={xs}>
			<Card>
				<CardHeader
					title={title}
					action={renderAction()}
					classes={{ action: classes.action }}
				/>
				<Divider />
				<CardContent>{children}</CardContent>
			</Card>
		</Grid>
	);
};

PreviewWrapper.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	title: string.isRequired,
	isAction: bool,
	md: number,
	xs: number,
};

export default PreviewWrapper;
