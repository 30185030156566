import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { arSA, enGB } from 'date-fns/locale';
import {
	defaultStaticRanges,
	defaultInputRanges,
} from 'react-date-range/dist/defaultRanges';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { AR } from 'helpers';

const useDateRangeModal = ({ initialPeriod }) => {
	const {
		t,
		i18n: { language },
	} = useTranslations();

	const theme = useTheme();

	const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const locale = language === AR ? arSA : enGB;

	const [ranges, setRanges] = useState([initialPeriod]);

	useEffect(() => {
		setRanges([initialPeriod]);
	}, [initialPeriod]);

	const months = isDesktop ? 2 : 1;

	const rangeColors = [theme.palette.primary.main];

	const handleOnChange = (item) => setRanges([item.selection]);

	const sectionName = 'common.date_range_labels';

	const staticRangesLabels = {
		Today: t(`${sectionName}.today`),
		Yesterday: t(`${sectionName}.yesterday`),
		'This Week': t(`${sectionName}.this_week`),
		'Last Week': t(`${sectionName}.last_week`),
		'This Month': t(`${sectionName}.this_month`),
		'Last Month': t(`${sectionName}.last_month`),
	};

	const inputRangesLabels = {
		'days up to today': t(`${sectionName}.days_before_today`),
	};

	const translateRange = (dictionary) => {
		return (item) =>
			dictionary[item.label]
				? { ...item, label: dictionary[item.label] }
				: item;
	};

	const staticRanges = defaultStaticRanges.map(
		translateRange(staticRangesLabels)
	);

	const inputRanges = [
		translateRange(inputRangesLabels)(defaultInputRanges[0]),
	];

	return {
		months,
		ranges,
		rangeColors,
		locale,
		inputRanges,
		staticRanges,
		onChange: handleOnChange,
	};
};

export default useDateRangeModal;
