import React from 'react';

// Import helpers
import { BOOKING_CHIP_REFERENCES } from 'helpers';

// Import components
import { BookingCountChip } from '../BookingCountChip';

export const WaitingCustomerInfoCountChip = () => (
	<BookingCountChip reference={BOOKING_CHIP_REFERENCES.WAITING} />
);
