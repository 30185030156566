import React from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';
import { Field } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	MenuItem,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import translations
import { useTranslations, useExternalPartner } from 'components/utilities';

const extractorValues = (values) => {
	const {
		external: {
			has_external,
			branch_code,
			branches
		},
	} = values;
	return {
		has_external,
		selectedValue: branch_code,
		options: branches
	}
}

const initValueFun = (selectedValue) => selectedValue === null

const ExternalPartner = ({ className, section, ...rest }) => {
	const { t } = useTranslations();

	const {
		submitting,
		has_external,
		options: branches,
		isHidden,
		handleChangeInfo,
	} = useExternalPartner({extractorValues, initValueFun, valueName: `${section}.branch_code`, emptyValue: null})

	return has_external ? (
		<Card {...rest} className={clsx(className)}>
			<CardHeader title={t('cars.form.external_partner.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<FormControlLabel
							control={<Checkbox color="primary" checked={!isHidden} onChange={handleChangeInfo} />}
							label={t('cars.form.external_partner.use_external_partner')}
						/>
					</Grid>
					{!isHidden && (
						<Field
							fullWidth
							component={SelectFieldAdapter}
							type="select"
							label={t('cars.form.external_partner.external_partner')}
							name={`${section}.branch_code`}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required
						>
							{Object.entries(branches).map(([ key, name ]) => {
								return (
									<MenuItem key={key} value={key}>
										{name}
									</MenuItem>
								);
							})}
						</Field>
					)}
				</Grid>
			</CardContent>
		</Card>
	) : null;
};
ExternalPartner.propTypes = {
	className: string,
	section: string.isRequired,
};

export default ExternalPartner;
