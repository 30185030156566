import {
	TextFieldAdapter,
	KeyboardDatePickerFieldAdapter,
	SelectFieldAdapter,
} from 'components/elements';

export const FIELDS_NAMES = {
	PHONE_PREFIX: 'phone_prefix',
	GENDER: 'gender.name',
	USER_TYPE: 'type.type',
};

export const BASE_DETAILS_FIELDS = [
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'customers.form.details.name',
		name: 'full_name',
		md: 6,
		xs: 12,
	},
	{
		type: 'date',
		component: KeyboardDatePickerFieldAdapter,
		label: 'customers.form.details.birth_date',
		name: 'birth_date',
		md: 6,
		sm: 6,
		xs: 12,
	},
	{
		type: 'hidden',
		name: 'birth_date_hidden',
		xs: 6,
	},
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'customers.form.details.prefix',
		name: FIELDS_NAMES.PHONE_PREFIX,
		md: 6,
		xs: 6,
	},
	{
		component: TextFieldAdapter,
		type: 'number',
		label: 'customers.form.details.phone_number',
		name: 'phone',
		md: 6,
		xs: 6,
		fieldType: 'select',
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'customers.form.details.email',
		name: 'email',
		md: 6,
		xs: 12,
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'customers.form.details.license_number',
		name: 'license.license_number',
		md: 6,
		xs: 12,
		isRequired: false,
	},
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'customers.form.details.gender',
		name: FIELDS_NAMES.GENDER,
		md: 6,
		xs: 12,
		withLang: true,
	},
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'customers.form.details.user_type',
		name: FIELDS_NAMES.USER_TYPE,
		md: 6,
		xs: 12,
	},
];

export const getMenuItems = ({
	name,
	prefixes,
	genders,
	customerTypes,
	isEnglish,
}) => {
	switch (name) {
		case FIELDS_NAMES.PHONE_PREFIX:
			return Object.values(prefixes);
		case FIELDS_NAMES.GENDER:
			return genders.map(({ name }) => (isEnglish ? name.en : name.ar));
		case FIELDS_NAMES.USER_TYPE:
			return customerTypes.map(({ type }) => type);
		default:
			break;
	}
};
