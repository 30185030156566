import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { PROMO_CODES_LIST } from 'helpers';

export const usePromoCodesLookup = () => {
	const path = `/v2/coupon/list`;

	const { data, isFetching, isError } = useQuery(
		[PROMO_CODES_LIST],
		async () => await services.post(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
