import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { number, string } from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { TextFieldAdapter } from 'components/elements';

// Import utilities
import useAllCarModelsFilter from './useAllCarModelsFilter';

export const AllCarModelsFilter = ({ label, name, xs = 3 }) => {
	const {
		getOptionSelected,
		getOptionLabel,
		handleOnChange,
		selectedModel,
		sortedModels,
		isFetching,
	} = useAllCarModelsFilter({
		name,
	});

	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid item xs={xs}>
			{isFetching ? (
				<CircularProgress size={28} />
			) : (
				<Autocomplete
					options={sortedModels}
					getOptionLabel={getOptionLabel}
					getOptionSelected={getOptionSelected}
					value={selectedModel}
					onChange={handleOnChange}
					renderInput={(params) => (
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="text"
							label={t(label)}
							name={name}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							defaultValue={selectedModel}
							{...params}
						/>
					)}
				/>
			)}
		</Grid>
	);
};

AllCarModelsFilter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	xs: number,
};
