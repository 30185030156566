import React from 'react';

// Import components
import { RentalCompaniesContainer } from './components';

// Import utils
import { RentalCompaniesProvider } from './context';

export const RentalCompanies = () => {
	return (
		<RentalCompaniesProvider>
			<RentalCompaniesContainer />
		</RentalCompaniesProvider>
	);
};
