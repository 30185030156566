import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { func, bool, string } from 'prop-types';

// Import styles
import useStyles from '../styles';

const ImageFromApiPreview = ({ close, on, imgUrl }) => {
	const classes = useStyles();

	return (
		<Dialog
			open={on}
			onClose={close}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogContent>
				<img src={imgUrl} alt="Preview" className={classes.image} />
			</DialogContent>
		</Dialog>
	);
};

ImageFromApiPreview.propTypes = {
	imgUrl: string.isRequired,
	close: func.isRequired,
	on: bool.isRequired,
};

export default ImageFromApiPreview;
