// ********************  table  ********************

export const fetchUsersUrl = (queryParams, itemId) =>
	itemId
		? `/v2/supplier/business/company/${itemId}/manager/list${queryParams}`
		: `/v2/user/list${queryParams}`;

export const updateActiveUserUrl = (id, isActive) =>
	`/v2/user/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const deleteUserUrl = (id) => `/v2/user/${id}/remove`;

// ********************  form  ********************

export const fetchUserFormResourcesUrl = (itemId) =>
	itemId ? `/v2/user/${itemId}/show` : 'v2/user/profile';

export const submitUserFormUrl = (isEdit, userId) =>
	isEdit ? `/v2/user/${userId}/update` : '/v2/user/create';
