import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { string } from 'prop-types';
import {
	CardContent,
	CardHeader,
	MenuItem,
	Divider,
	Grid,
	Card,
} from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';
import { useCarSpecification } from './useCarSpecification';

export const CarSpecification = ({ section, ...rest }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	const { CHECKBOX_FIELDS, SELECT_FIELDS } = useCarSpecification();

	return (
		<Card {...rest}>
			<CardHeader title={t('cars.form.car_specifications.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid container item spacing={2}>
						{SELECT_FIELDS(section).map(({ name, label, options, xs }) => (
							<Grid key={name} item xs={xs}>
								<Field
									fullWidth
									component={SelectFieldAdapter}
									label={t(label)}
									name={name}
									variant="outlined"
									margin="dense"
									disabled={submitting}
								>
									{options.map(({ label, value }) => (
										<MenuItem key={value} value={value}>
											{label}
										</MenuItem>
									))}
								</Field>
							</Grid>
						))}
					</Grid>
					<Grid container item>
						{CHECKBOX_FIELDS(section).map(
							({ name, label, component, color, type, xs }) => (
								<Grid key={name} item xs={xs}>
									<Field
										component={component}
										type={type}
										labelText={t(label)}
										name={name}
										disabled={submitting}
										color={color}
									/>
								</Grid>
							)
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

CarSpecification.propTypes = {
	section: string.isRequired,
};
