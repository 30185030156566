import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	DISCOUNTS: 'discounts',
};

export const columns = ({ lng }) => [
	{
		Header: 'organizations.table.headers.name',
		accessor: `name.${lng}`,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'organizations.table.headers.discounts',
		accessor: COLUMNS_ACCESSORS.DISCOUNTS,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
