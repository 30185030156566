import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		right: 16,
		height: 80,
		width: '100vw',
		padding: '0 23px 0 35px',
		boxShadow: 'none',
		backgroundColor: theme.palette.white,
		[theme.breakpoints.up('lg')]: {
			padding: '0 35px',
			width: 'calc(100vw - (290px + 16px))',
		},
		[theme.breakpoints.down('md')]: {
			right: 0,
			height: 90,
		},
	},
	sectionTitle: {
		lineHeight: 2,
		fontSize: 8,
		fontWeight: 500,
		color: theme.palette.text.primary,
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: 20,
		},
	},
	line: {
		height: '40px',
	},
}));
