import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

export const CopyNo = () => {
	const { customer } = useSelector(previewBookingSelector);

	return (
		<Box paddingTop={1}>
			<Typography variant="subtitle1">
				ID Copy No.{' '}
				<Typography component="span" variant="inherit" color="primary">
					{customer.copy_no || 'n/a'}
				</Typography>
			</Typography>
			<Typography variant="subtitle1" dir="ltr">
				<Typography component="span" variant="inherit" color="primary">
					{customer.copy_no || 'n/a'}
				</Typography>{' '}
				رقم نسخة الهوية
			</Typography>
		</Box>
	);
};
