import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	LOGO: 'logo',
};

export const columns = ({ lng }) => [
	{
		Header: 'cars.manufacturer.table.headers.logo',
		accessor: COLUMNS_ACCESSORS.LOGO,
	},
	{
		Header: 'cars.manufacturer.table.headers.name',
		accessor: `name.${lng}`,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
