export const EXTENSION_INVOICE_FIELDS = [
	{
		accessor: 'payment_method',
		label: 'bookings.preview_booking.invoice.payment_method',
	},
	{
		accessor: 'created_at',
		label: 'bookings.preview_booking.invoice.date',
	},
	{
		accessor: 'extended_by_days',
		label: 'common.fields.no_of_days',
	},
	{
		accessor: 'rent_price',
		label: 'bookings.preview_booking.invoice.nett_price',
	},
	{
		accessor: 'amount_nett',
		label: 'bookings.preview_booking.invoice.total_nett_price',
	},
	{
		accessor: 'amount_gross',
		label: 'bookings.preview_booking.invoice.total_gross_price',
	},
];
