import React from 'react';
import { CircularProgress, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useCompanyCollectionCarFilter } from 'queries';

const CompanyFilter = () => {
	const { submitting, values } = useFormState();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { maker_uuid } = values.vehicle;

	const { data: companies, isFetching } = useCompanyCollectionCarFilter({
		enabled: !!maker_uuid,
		values,
	});

	if (isFetching) {
		return <CircularProgress size={28} />;
	}

	return (
		<Field
			label={t('bookings.edit_booking.select_car.form.company')}
			component={SelectFieldAdapter}
			disabled={submitting}
			name="company_uuid"
			variant="outlined"
			margin="dense"
			fullWidth
		>
			{companies.map(({ uuid, name }) => (
				<MenuItem key={uuid} value={uuid}>
					{name[language]}
				</MenuItem>
			))}
		</Field>
	);
};

export default CompanyFilter;
