import React from 'react';

// Import components
import {
	BaseYesNoAllFilter,
	BookingStatusFilter,
	CitiesFilter,
	CompaniesFilter,
	DateRangeFilter,
	ServiceTypeFilter,
} from 'components/elements';

// Import helpers
import { bookingStates } from './helpers';

export const RevenueFilters = () => {
	return (
		<>
			<ServiceTypeFilter
				name="service_type"
				label="common.filters.service_type"
			/>
			<DateRangeFilter
				nameFrom="created_at.from"
				nameUntil="created_at.until"
				label="common.filters.created_at_date"
				combinedInputs
			/>
			<DateRangeFilter
				nameFrom="pick_up_at.from"
				nameUntil="pick_up_at.until"
				label="bookings.filters.pick_date"
				combinedInputs
			/>
			<DateRangeFilter
				nameFrom="drop_off_at.from"
				nameUntil="drop_off_at.until"
				label="bookings.filters.drop_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="extended_at.from"
				nameUntil="extended_at.until"
				label="common.fields.extended_at"
				combinedInputs
			/>

			<CompaniesFilter name="companies" label="common.fields.company" />

			<CitiesFilter name="cities" label="common.fields.city" />

			<BaseYesNoAllFilter name="is_extension" label="common.fields.extension" />
			<DateRangeFilter
				nameFrom="paid_at.from"
				nameUntil="paid_at.until"
				label="common.fields.paid_at"
				combinedInputs
			/>

			<BookingStatusFilter
				name="booking_status"
				label="common.fields.booking_status"
				neededStates={bookingStates}
			/>
		</>
	);
};
