export const INITIAL_VALUES = {
	details: {
		slug: {
			en: '',
			ar: '',
		},
		title: {
			en: '',
			ar: '',
		},
		content: {
			en: '',
			ar: '',
		},
	},
	filter: {
		location: {
			label: '',
			place_id: '',
			formatted: '',
			lat: 0,
			lng: 0,
		},
		vehicle: {
			type: '',
			type_uuid: '',
			maker_name: {
				en: '',
				ar: '',
			},
			model_name: {
				en: '',
				ar: '',
			},
		},
		service: {
			type: '',
			airport_uuid: '',
		},
	},

	image: {
		file_path: '',
	},
	seo: {
		title: {
			en: '',
			ar: '',
		},
		keywords: {
			en: '',
			ar: '',
		},
		description: {
			en: '',
			ar: '',
		},
	},
};
