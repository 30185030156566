import React from 'react';
import { string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Link } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

const EditBookingButton = ({ uuid }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const url = `/bookings/${uuid}/edit`;

	return (
		<Link component={RouterLink} to={url} className={classes.edit}>
			<Button
				color="primary"
				variant="outlined"
				startIcon={<EditOutlinedIcon />}
			>
				{t('bookings.single_booking.details.action_change')}
			</Button>
		</Link>
	);
};

EditBookingButton.propTypes = {
	uuid: string.isRequired,
};

export default EditBookingButton;
