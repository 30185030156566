import services from 'services/services';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const useImageBlobFromApi = ({ imgUrl }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [imageBlob, setImageBlob] = useState(null);

	useEffect(() => {
		const source = axios.CancelToken.source();
		const cancelToken = source.token;

		setIsLoading(true);

		services
			.getBlob(imgUrl, cancelToken)
			.then((res) => {
				setImageBlob(res.data);
				setIsLoading(false);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}

				setError(error);
				setIsLoading(false);
			});

		return () => {
			source.cancel();
		};

		//eslint-disable-next-line
	}, [imgUrl]);

	return {
		imageBlob,
		error,
		isLoading,
	};
};
