import { useEffect } from 'react';
import { useQuery } from 'react-query';

// Import helpers
import { fetchOfficesCars } from './helpers';
import { OFFICES_CARS_QUERY } from 'helpers';
import { useTranslations } from 'components/utilities';

const useOfficesCars = ({ model_name, models, year, managerProfileUuid }) => {
	const { i18n } = useTranslations();
	
	const lng = i18n.language;

	useEffect(() => {
		refetch();
		//eslint-disable-next-line
	}, [model_name[lng] && year, model_name[lng]]);

	const { data, isError, isFetching, refetch } = useQuery(
		OFFICES_CARS_QUERY,
		async () => {
			return await fetchOfficesCars({
				models,
				model_name,
				year,
				managerProfileUuid,
				lng,
			});
		},
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};

export default useOfficesCars;
