const useCustomDateInput = () => {
	const datePickerProps = {
		KeyboardButtonProps: {
			disabled: true,
			style: { display: 'none' },
		},
		InputAdornmentProps: {
			position: 'start',
		},
	};

	return {
		datePickerProps,
	};
};

export default useCustomDateInput;
