import { useEffect } from 'react';
import { useMutation } from 'react-query';

// Import actions
import { changeIsSubscriptionActive } from 'store/actions';

// Import utils
import { useToggle, useMessage, useTranslations } from 'components/utilities';

export const useSubscriptionActiveTableCell = ({ cell }) => {
	const { t } = useTranslations();
	const { message } = useMessage();

	const { is_active: initialIsActive, uuid: subscriptionUuid } =
		cell.row.original;

	const { on: isActive, toggle: toggleIsActive } = useToggle(initialIsActive);

	const activeText = 'table.actions.active';
	const inactiveText = 'table.actions.inactive';
	const label = isActive ? activeText : inactiveText;

	const activatedAlertMessage = 'table.actions.user_activated';
	const deactivatedAlertMessage = 'table.actions.user_deactivated';
	const alertMessage = isActive
		? deactivatedAlertMessage
		: activatedAlertMessage;

	const [mutate, { error, isError, isLoading, isSuccess }] = useMutation(
		(data) => changeIsSubscriptionActive(data)
	);

	const handleOnToggle = () => {
		mutate({
			isActive: !isActive,
			subscriptionUuid,
		});
	};

	const handleOnSuccess = () => {
		toggleIsActive();
		message.success(t(alertMessage));
	};

	const handleOnError = () => {
		message.error(error.message);
	};

	useEffect(() => {
		isSuccess && handleOnSuccess();
		isError && handleOnError();

		// eslint-disable-next-line
	}, [isError, isSuccess]);

	return {
		isActive,
		label,
		isLoading,
		onClick: handleOnToggle,
	};
};
