import { BOOKING_COMPENSATION_METHODS } from 'helpers';

export const INITIAL_VALUES = {
	compensation_amount: 0,
	method: BOOKING_COMPENSATION_METHODS.WALLET,
	bank_name: '',
	iban: '',
	contract_file: {
		file_path: '',
		file_name: '',
	},
};
