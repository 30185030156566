import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	loader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export default useStyles;
