import { useState, useEffect, useCallback, useMemo } from 'react';
import flatten from 'lodash/flatten';
import {
	getCities,
	getCompanyDropCities,
	updateCompanyDropCities,
} from 'store/actions/dropCities';

// Import helpers
import { prepareInitialDropCities } from './helpers';
import getErrorMessage from 'helpers/getErrorMessage';
import { useParams } from 'react-router-dom';

const useDropCitiesForm = () => {
	const { id } = useParams();

	const [dropCities, setDropCities] = useState([]);
	const [cities, setCities] = useState([]);

	const [isLoadingDropCities, setIsLoadingDropCities] = useState(false);
	const [isLoadingCities, setIsLoadingCities] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [companyUuid, setCompanyUuid] = useState('');

	const [error, setError] = useState('');

	const isLoading = useMemo(() => {
		return isLoadingDropCities || isLoadingCities || isSaving;
	}, [isLoadingDropCities, isLoadingCities, isSaving]);

	const resetDropCities = (cities) => {
		setDropCities(prepareInitialDropCities(cities));
	};

	const fetchCities = async () => {
		const { data } = await getCities({
			body: {},
		});

		return data.data;
	};

	const fetchDropCities = async () => {
		const { data } = await getCompanyDropCities({
			companyId: companyUuid,
			body: {},
		});

		const dropCities = data.data
			.filter(({ is_available }) => is_available)
			.map(({ city_in, city_out, nett_price }) => {
				return {
					cityFrom: {
						uuid: city_in[0],
						name: city_in[1],
					},
					cityTo: {
						uuid: city_out[0],
						name: city_out[1],
					},
					price: parseFloat(nett_price),
					isEdited: false,
				};
			});

		return dropCities;
	};

	const handleCompanyUuidChange = (ev) => {
		setCompanyUuid(ev.target.value);
	};

	const updateDropCities = useCallback(
		(updateCallback) => {
			setDropCities((dropCities) => {
				return dropCities.map((_dropCities) => {
					return _dropCities.map((dropCity) => {
						return updateCallback(dropCity);
					});
				});
			});
		},
		[setDropCities]
	);

	const updateDropCity = useCallback(
		(searchDropCity, newDropCity) => {
			updateDropCities((dropCity) => {
				if (
					searchDropCity.cityFrom.uuid === dropCity.cityFrom.uuid &&
					searchDropCity.cityTo.uuid === dropCity.cityTo.uuid
				) {
					return {
						...dropCity,
						...newDropCity,
					};
				}

				return dropCity;
			});
		},
		[updateDropCities]
	);

	const handlePriceChange = useCallback(
		(ev, searchDropCity) => {
			updateDropCity(searchDropCity, { price: ev.target.value });
		},
		[updateDropCity]
	);

	const handlePriceClear = useCallback(
		(searchDropCity) => {
			updateDropCity(searchDropCity, { price: '' });
		},
		[updateDropCity]
	);

	const handleEdit = useCallback(
		(searchDropCity) => {
			updateDropCities((dropCity) => {
				// Only one dropCity can be edited at the same time (for performance reasons)
				const isEdited =
					searchDropCity.cityFrom.uuid === dropCity.cityFrom.uuid &&
					searchDropCity.cityTo.uuid === dropCity.cityTo.uuid;

				return {
					...dropCity,
					isEdited,
				};
			});
		},
		[updateDropCities]
	);

	const saveDropCities = async () => {
		const data = flatten(dropCities).filter(
			({ cityFrom, cityTo, price }) =>
				!isNaN(parseFloat(price)) && cityFrom.uuid !== cityTo.uuid
		);

		const backendData = data.map((dropCity) => {
			return {
				city_in_uuid: dropCity.cityFrom.uuid,
				city_out_uuid: dropCity.cityTo.uuid,
				nett_price: dropCity.price,
				is_available: true,
			};
		});

		setIsSaving(true);

		try {
			await updateCompanyDropCities({
				companyId: companyUuid,
				body: {
					data: backendData,
				},
			});

			setError('');
		} catch (error) {
			setError(getErrorMessage(error));
		}

		setIsSaving(false);
	};

	useEffect(() => {
		(async () => {
			setIsLoadingCities(true);
			setIsLoadingDropCities(true);

			if (!companyUuid && id) {
				setCompanyUuid(id)
			}

			try {
				const newCities = await fetchCities();

				setCities(newCities);

				if (!companyUuid) {
					resetDropCities([]);
				} else {
					const initialDropCities = await fetchDropCities();

					resetDropCities(newCities);

					updateDropCities((dropCity) => {
						const initialDropCity = initialDropCities.find(
							(_initialDropCity) =>
								_initialDropCity.cityFrom.uuid === dropCity.cityFrom.uuid &&
								_initialDropCity.cityTo.uuid === dropCity.cityTo.uuid
						);

						if (!initialDropCity) {
							return dropCity;
						}

						return {
							...dropCity,
							price: initialDropCity.price,
						};
					});
				}

				setError('');
			} catch (error) {
				setError(getErrorMessage(error));
			}

			setIsLoadingCities(false);
			setIsLoadingDropCities(false);
		})();
		// eslint-disable-next-line
	}, [companyUuid]);

	return {
		cities,
		dropCities,
		companyUuid,
		handleCompanyUuidChange,
		handlePriceClear,
		handlePriceChange,
		handleEdit,
		saveDropCities,
		isLoading,
		isSaving,
		error,
	};
};

export default useDropCitiesForm;
