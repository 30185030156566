import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	children: {
		padding: '24px',
	},
	collapseTitle: {
		[theme.breakpoints.down('md')]: {
			color: theme.palette.primary.main,
			fontSize: 14,
		},
	},
}));
