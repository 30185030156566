import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: '100%',
	},
	grid: {
		height: '100%',
	},
	quoteContainer: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white,
	},
	bio: {
		color: theme.palette.white,
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	contentHeader: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	logoImage: {
		marginLeft: theme.spacing(4),
	},
	contentBody: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
	},
	form: {
		flexBasis: 700,
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
	title: {
		marginTop: theme.spacing(3),
	},
	socialButtons: {
		marginTop: theme.spacing(3),
	},
	socialIcon: {
		marginRight: theme.spacing(1),
	},
	sugestion: {
		marginTop: theme.spacing(2),
	},
}));
