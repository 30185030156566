import React from 'react';
import PropTypes from 'prop-types';
import {
	Table,
	TableCell,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';

// Import helpers
import { columns } from './columns';

// Import components
import { TableBodyCell } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';

export const RentedCarsTable = ({ rows }) => {
	const { t } = useTranslations();

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{columns.map(({ accessor, header }) => (
							<TableCell key={accessor}>{header ? t(header) : ''}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, rowIndex) => (
						<TableRow key={row.uuid}>
							{columns.map((column) => (
								<TableBodyCell
									key={column.accessor}
									row={row}
									column={column}
									rowIndex={rowIndex}
								/>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

RentedCarsTable.propTypes = {
	rows: PropTypes.array,
};
