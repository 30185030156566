import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

const PdfIcon = (props) => (
	<SvgIcon viewBox="0 0 24 24" {...props}>
		<path
			d="M7.51414 15.0968C7.51414 14.7381 7.26523 14.5242 6.82605 14.5242C6.64671 14.5242 6.52526 14.5419 6.46173 14.5588V15.7097C6.53695 15.7267 6.62942 15.7323 6.75688 15.7323C7.22499 15.7323 7.51414 15.4958 7.51414 15.0968Z"
			fill="currentColor"
		/>
		<path
			d="M10.2329 14.5362C10.0362 14.5362 9.9091 14.5532 9.83392 14.5708V17.1197C9.90914 17.1374 10.0306 17.1374 10.1404 17.1374C10.9383 17.143 11.4586 16.7039 11.4586 15.7733C11.4643 14.964 10.9901 14.5362 10.2329 14.5362Z"
			fill="currentColor"
		/>
		<path
			d="M15.2841 1.20001H6.60861C5.3412 1.20001 4.30967 2.23226 4.30967 3.49894V12H4.08513C3.5736 12 3.15875 12.4144 3.15875 12.9264V18.5446C3.15875 19.0565 3.57356 19.4709 4.08513 19.4709H4.30967V20.5011C4.30967 21.7692 5.3412 22.8 6.60861 22.8H18.4459C19.7125 22.8 20.7442 21.7691 20.7442 20.5011V6.64097L15.2841 1.20001ZM5.58904 13.9403C5.86053 13.8944 6.24214 13.8598 6.77979 13.8598C7.32313 13.8598 7.71038 13.9635 7.97058 14.1719C8.21913 14.3682 8.38687 14.6922 8.38687 15.0736C8.38687 15.4548 8.25977 15.7789 8.02851 15.9985C7.72772 16.2816 7.2829 16.4087 6.76254 16.4087C6.64673 16.4087 6.54293 16.403 6.46175 16.3918V17.7849H5.58904V13.9403ZM18.4459 21.3921H6.60861C6.11786 21.3921 5.71824 20.9925 5.71824 20.5011V19.4709H16.753C17.2646 19.4709 17.6795 19.0565 17.6795 18.5446V12.9264C17.6795 12.4144 17.2646 12 16.753 12H5.71824V3.49894C5.71824 3.00892 6.11791 2.6093 6.60861 2.6093L14.7573 2.60078V5.61285C14.7573 6.49264 15.4712 7.20716 16.3517 7.20716L19.3023 7.19869L19.3355 20.501C19.3355 20.9925 18.9366 21.3921 18.4459 21.3921ZM8.9496 17.7672V13.9403C9.27329 13.8888 9.69517 13.8598 10.1404 13.8598C10.8803 13.8598 11.3601 13.9926 11.7361 14.2757C12.1407 14.5765 12.3948 15.0559 12.3948 15.7443C12.3948 16.4899 12.1234 17.0046 11.7473 17.3223C11.3371 17.6633 10.7126 17.8251 9.94973 17.8251C9.49285 17.8251 9.16917 17.7961 8.9496 17.7672ZM15.2587 15.5015V16.2181H13.8596V17.7849H12.9752V13.8888H15.3568V14.611H13.8596V15.5015H15.2587Z"
			fill="currentColor"
		/>
	</SvgIcon>
);

export default PdfIcon;
