import React from 'react';

import { useParams } from 'react-router';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow } from './components';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import helpers
import { columns } from './columns';
import { fetchTerminals } from 'store/actions';
import { TOOLBAR_CONTENT } from './helpers';

const Terminals = () => {
	const { content, root } = useBaseLayoutStyles();
	const { id: airportId } = useParams();

	return (
		<TableProvider columns={columns} fetchDataTableAction={fetchTerminals}>
			<div className={root}>
				<Toolbar content={TOOLBAR_CONTENT(airportId)} />
				<div className={content}>
					<Table isDefaultRow={false}>
						{({ row }) => <TableBodyRow key={row.id} row={row} />}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};

export default Terminals;
