import BusinessIcon from '@material-ui/icons/Business';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = (airportId) => {
	return [{
        variant: TOOLBAR_VARIANTS.NAV,
        to: `/kiosk/airports/${airportId}/terminals/create`,
        icon: BusinessIcon,
        text: 'kiosk.airports.terminals.table.toolbar.add_terminal'
    }];
};
