import * as types from 'store/types';
import services from 'services/services';
import {
	fetchCustomerFormResourcesUrl,
	submitCustomerFormUrl,
} from 'store/paths';

//Import helpers
import { catchError } from '../helpers';
import { convertSubmitData } from './helpers';

export const fetchCustomerFormResources = ({ itemId, cancelToken }) => async (
	dispatch
) => {
	try {
		dispatch({
			type: types.CUSTOMER_FORM_FETCH_DATA_LOADING,
		});

		const { data } = await services.get(
			fetchCustomerFormResourcesUrl(itemId),
			cancelToken
		);

		dispatch({
			type: types.CUSTOMER_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: data || {},
			},
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.CUSTOMER_FORM_FETCH_DATA_ERROR,
		});
	}
};

export const submitCustomerForm = ({
	values,
	itemId,
	lng,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		const data = convertSubmitData({ values, lng });

		await services.post(submitCustomerFormUrl(itemId), data);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
