import { makeStyles } from '@material-ui/styles';
import { BOOKING_VALIDATION_TOPICS } from 'helpers';

const { CANCELLATION, COMPENSATION, URGENT } = BOOKING_VALIDATION_TOPICS;

export const useStyles = makeStyles((theme) => ({
	[URGENT]: { color: theme.palette.error.main },
	[COMPENSATION]: { color: theme.palette.warning.main },
	[CANCELLATION]: { color: theme.palette.info.main },
	cancelReason: {
		color: theme.palette.gray.label,
		cursor: 'pointer',
	},
	topicDetails: {
		color: theme.palette.text.primary,
	},
}));
