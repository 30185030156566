import React from 'react';
import { Field } from 'react-final-form';
import { number, string } from 'prop-types';
import { CircularProgress, Grid, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useCompanyOfficesFilter } from './useCompanyOfficesFilter';
import { useTranslations } from 'components/utilities';

export const CompanyOfficesFilter = ({ label, name, xs = 3, md = 3 }) => {
	const { t, i18n } = useTranslations();

	const { filteredOffices, offices, isFieldDisabled, isFetching } =
		useCompanyOfficesFilter(name);

	const lng = i18n.language;

	if (!offices) {
		return null;
	}

	return (
		<Grid item xs={xs} md={md}>
			{isFetching ? (
				<Grid item xs>
					<CircularProgress size={28} />
				</Grid>
			) : (
				<Grid item xs>
					<Field
						fullWidth
						component={SelectFieldAdapter}
						label={t(label)}
						name={name}
						variant="outlined"
						margin="dense"
						disabled={isFieldDisabled}
					>
						<MenuItem value="all">{t('common.all')}</MenuItem>
						{filteredOffices.map(({ uuid, name }) => (
							<MenuItem key={uuid} value={uuid}>
								{name[lng]}
							</MenuItem>
						))}
					</Field>
				</Grid>
			)}
		</Grid>
	);
};

CompanyOfficesFilter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	xs: number,
	md: number,
};
