import React from 'react';
import { IconButton } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';

// Import components
import { UrgentReasonsHistory } from 'components/elements';

// Import utilities
import { useToggle } from 'components/utilities';

const UrgentReasonsHistoryDialog = () => {
	const { on, toggle } = useToggle();

	return (
		<>
			{on && <UrgentReasonsHistory open={on} onClose={toggle} />}
			<IconButton onClick={toggle}>
				<HistoryIcon color="primary" />
			</IconButton>
		</>
	);
};

export default UrgentReasonsHistoryDialog;
