import React from 'react';
import { bool, oneOfType, node, arrayOf, func } from 'prop-types';
import { Select, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Import styles
import { useStyles } from './ClearableSelect.styles';

export const ClearableSelect = ({ clearable, onClear, ...props }) => {
	const classes = useStyles(props);

	return (
		<Box className={classes.wrapper}>
			<Select {...props}>{props.children}</Select>
			{clearable && (
				<IconButton
					size="small"
					classes={{ root: classes.clearBtn }}
					onClick={onClear}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			)}
		</Box>
	);
};

ClearableSelect.propTypes = {
	children: oneOfType([node, arrayOf(node)]),
	clearable: bool,
	onClear: func,
};
