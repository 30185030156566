export const COLUMNS_ACCESSORS = {
    DROPDOWN: 'dropdown',
    ACTIONS: 'actions'
};

export const columns = [
    {
        header: '',
        accessor: COLUMNS_ACCESSORS.DROPDOWN
    },
    {
        header: 'bookings.transaction_history.table.headers.id',
        accessor: 'uuid'
    },
    {
        header: 'bookings.transaction_history.table.headers.type',
        accessor: 'type_translated'
    },
    {
        header: 'bookings.transaction_history.table.headers.amount',
        accessor: 'amount.gross'
    },
    {
        header: 'bookings.transaction_history.table.headers.date_time',
        accessor: 'created_at'
    },
    {
        header: 'bookings.transaction_history.table.headers.actions',
        accessor: COLUMNS_ACCESSORS.ACTIONS
    }
]