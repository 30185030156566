export const COLUMNS_ACCESSORS = {
	BOOKING_ID: 'booking_id',
	NOTE: 'note',
	STATUS: 'status',
	OFFICE_NAME: 'office_name',
	DURATION: 'response_time',
	APPROVED: 'approved',
	CANCELLATION_REASON: 'cancellation_reason',
	CUSTOMER_NAME: 'customer_name',
	PICK_DATE: 'pick_date',
	ACTIONS: 'actions',
	BOOKING_TIME: 'respond_time',
	OFFICE_ADDRESS: 'office_address',
	DROP_DATE: 'drop_date',
};

export const columns = [
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'last_booking.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'last_booking.table.headers.office_name',
		accessor: COLUMNS_ACCESSORS.OFFICE_NAME,
		disableSortBy: true,
	},
	{
		Header: 'last_booking.table.headers.office_address',
		accessor: COLUMNS_ACCESSORS.OFFICE_ADDRESS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: 'vehicle',
		disableSortBy: true,
	},
	{
		Header: 'last_booking.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
	},
	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: COLUMNS_ACCESSORS.CANCELLATION_REASON,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
	},
	{
		Header: 'bookings.table.headers.drop_date',
		accessor: COLUMNS_ACCESSORS.DROP_DATE,
	},
	{
		Header: 'bookings.table.headers.booking_no',
		accessor: 'customer_no_booking',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_time',
		accessor: COLUMNS_ACCESSORS.BOOKING_TIME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.duration',
		accessor: COLUMNS_ACCESSORS.DURATION,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},

	// Additional
	{
		Header: 'bookings.table.headers.approved',
		accessor: COLUMNS_ACCESSORS.APPROVED,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.gender',
		accessor: 'gender',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.number_of_days',
		accessor: 'number_of_days',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.price',
		accessor: 'price',
		disableSortBy: true,
		isHidden: true,
	},
];
