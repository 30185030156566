// ********************  table  ********************

export const fetchOfficesUrl = ({ queryParams, itemId }) =>
	itemId
		? `/v2/supplier/business/company/${itemId}/office/list${queryParams}`
		: `v2/supplier/business/manager/offices${queryParams}`;

export const downloadCarPricesUrl = ({ companyUuid, fileFormat }) =>
	`/dashboard/car-prices/download?company_uuid=${companyUuid}&file_format=${fileFormat}`;

export const uploadCarPricesUrl = (companyUuid) =>
	`/dashboard/car-prices/upload?company_uuid=${companyUuid}`;

export const updateActiveOfficeUrl = (id, isActive) =>
	`/v2/supplier/business/office/${id}/${isActive ? 'deactivate' : 'activate'}`;

// ********************  form  ********************

export const businessOfficeDeliveryPricesUrl = `/v2/supplier/business/office/delivery-prices`;
export const businessOfficeDeliveryDistancesUrl = `/v2/supplier/business/office/delivery-distances`;

export const showBusinessOfficeUrl = (itemId) =>
	`/v2/supplier/business/office/${itemId}/show`;

export const submitOfficeFormUrl = (itemId, isEdit) =>
	`/v2/supplier/business/office/${isEdit ? `${itemId}/update` : 'create'}`;

export const fetchCompanyOfficesUrl = ({ companyUuid }) =>
	`/v2/supplier/business/company/${companyUuid}/office/collection`;
