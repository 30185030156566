import React from 'react';
import { Form } from 'react-final-form';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

// Import components
import { AlertMessage } from 'components/elements';
import { DataFormContent } from './components';

// Import helpers
import { yupValidator } from 'helpers/validation';
import { validationSchema } from './validation';

// Import utils
import { useCloseContractProvider } from '../../context';
import { useTranslations } from 'components/utilities';
import { useDataForm } from './useDataForm';

// Import styles
import { useStyles } from 'theme/styles/eContract.styles';

export const DataForm = () => {
	const { handleOnSubmit, isLoading } = useDataForm();

	const { t } = useTranslations();

	const classes = useStyles();

	const {
		allowCloseContract,
		initialValues,
		formOptions,
		isFetching,
		isError,
	} = useCloseContractProvider();

	const { closure_reasons: closureReasons } = formOptions || {};

	if (isFetching) {
		return (
			<Box className={clsx(classes.container, classes.centerContainer)}>
				<CircularProgress />
			</Box>
		);
	}

	if (isError) {
		return (
			<Box className={classes.container}>
				<AlertMessage
					description={t('common.messages.error_message')}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	if (!allowCloseContract) {
		return (
			<Box className={classes.container}>
				<AlertMessage
					description={t('contract.close_contract_form.no_access_message')}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	return (
		<Form
			onSubmit={handleOnSubmit}
			initialValues={initialValues}
			keepDirtyOnReinitialize
			validateOnBlur
			validate={(values) =>
				yupValidator({
					values,
					validationSchema: validationSchema({
						values,
						closureReasons,
					}),
				})
			}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<DataFormContent isLoading={isLoading} />
				</form>
			)}
		/>
	);
};
