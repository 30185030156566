import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// Import utils and helpers
import { useMessage, useTranslations } from 'components/utilities';
import { convertInitialCoordinators } from '../../helpers';
import { useCoordinator, useCorporation } from 'queries';
import { convertSubmitCoordinators } from 'helpers';
import { getCoordinatorErrors } from './helpers';

//Import store
import { createOrUpdateCoordinator, updateCorporate } from 'store/actions';

export const useWrapper = () => {
	const [isLoading, setLoading] = useState(false);
	const { id: corporateUuid } = useParams();
	const { message } = useMessage();
	const { t } = useTranslations();

	const {
		data: corporateData,
		isFetching: corporateFetching,
		isError: corporateError,
	} = useCorporation(corporateUuid);

	const {
		data: coordinatorData,
		isFetching: coordinatorFetching,
		isError: coordinatorError,
	} = useCoordinator(corporateUuid);

	const isError = coordinatorError || corporateError;
	const isFetching = coordinatorFetching || corporateFetching;

	const handleOnSubmit = async (values) => {
		let apiErrors = { coordinators: [] };
		let coordinatorErrorUuids = [];

		setLoading(true);

		const corporatePromise = updateCorporate({
			successCallback: () => {
				message.success(
					t('b2b.corporate.form.messages.corporate_was_updated_successfully')
				);
			},
			errorCallback: (error) => {
				if (error.response) {
					apiErrors = {
						...apiErrors,
						...error.response.data.errors,
					};
				}
				message.error(t('common.messages.error_message'));
			},
			corporateUuid,
			values,
		});

		const convertedCoordinators = convertSubmitCoordinators({
			coordinators: values.coordinators,
			corporateUuid,
		});

		const coordinatorsPromises = convertedCoordinators.map(
			(coordinator, index) =>
				createOrUpdateCoordinator({
					successCallback: () => {
						message.success(
							t(
								`b2b.corporate.form.messages.${
									coordinator?.uuid
										? 'coordinator_was_updated_successfully'
										: 'coordinator_was_created_successfully'
								}`,
								{ index: index + 1 }
							)
						);
					},
					errorCallback: (error) => {
						coordinatorErrorUuids = [
							...coordinatorErrorUuids,
							{ index, error: error.response.data.errors },
						];

						if (error.response) {
							const coordinatorErrors = getCoordinatorErrors({
								convertedCoordinators,
								coordinatorErrorUuids,
							});

							apiErrors = coordinatorErrors;
						}
						message.error(t('common.messages.error_message'));
					},
					coordinator,
					corporateUuid,
				})
		);

		await Promise.all([corporatePromise, ...coordinatorsPromises]);

		setLoading(false);

		return apiErrors;
	};

	const initialValues = useMemo(
		() => ({
			...corporateData,
			coordinators: convertInitialCoordinators(coordinatorData),
		}),
		[corporateData, coordinatorData]
	);

	return {
		handleOnSubmit,
		initialValues,
		isFetching,
		isLoading,
		isError,
	};
};
