// ********************  form  ********************

export const fetchKiosksFormResourcesUrl = (itemId) =>
	`/v2/kiosk/${itemId}/show`;

export const submitKiosksFormUrl = (isEdit, itemId) =>
	`/v2/kiosk/${isEdit ? `${itemId}/update` : 'create'}`;

// ********************  table  ********************

export const fetchKiosksUrl = '/v2/kiosk/list';
export const deleteKioskUrl = (id) => `/v2/kiosk/${id}/remove`;
export const updateActiveKioskUrl = (isActive, id) =>
	`/v2/kiosk/${id}/${isActive ? 'deactivate' : 'activate'}`;
