export const COLUMNS_ACCESSORS = {
	TOPIC: 'topic',
	NOTE: 'note',
	BOOKING_ID: 'booking_id',
	CUSTOMER_NAME: 'customer_name',
	OFFICE_NAME: 'office_name',
	PICK_DATE: 'pick_date',
	OFFICE_RATING: 'office_rating',
	RATING_REASON: 'rating_reason',
	EMPLOYEE: 'employee',
	ACTIONS: 'actions',
	URGENT_STATUS: 'urgent_status',
	COMPENSATION_AMOUNT: 'compensation_amount',
};

export const columns = [
	{
		Header: 'bookings.table.headers.validation_topic',
		accessor: COLUMNS_ACCESSORS.TOPIC,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.rating_reason',
		accessor: COLUMNS_ACCESSORS.RATING_REASON,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.compensation_amount',
		accessor: COLUMNS_ACCESSORS.COMPENSATION_AMOUNT,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: COLUMNS_ACCESSORS.OFFICE_RATING,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.validated_by',
		accessor: 'compensation_validated_by',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.marked_by',
		accessor: 'urgent_assistance_marked_by',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.compensation_status',
		accessor: 'compensation_status',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.urgent_status',
		accessor: COLUMNS_ACCESSORS.URGENT_STATUS,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
