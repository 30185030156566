import i18next from 'i18next';

// Import helpers
import { AR } from 'helpers';

import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	agentName: {
		color: theme?.palette.text.secondary,
		letterSpacing: i18next.language === AR ? 0 : 1.3,
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
	},
}));
