import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import usePrevious from '@rooks/use-previous';
import _ from 'lodash';

// Import utils
import { useTranslations, useProfile, useMessage } from 'components/utilities';
import { useCompanies, usePhonePrefixes } from 'queries';

// Import helpers
import { sortByName } from 'components/elements/organisms/Tables/TableFiltersFields/helpers';
import { fetchAllCompanyOffices, getFields } from './helpers';

export const useDetails = () => {
	const [companyOffices, setCompanyOffices] = useState([]);
	const { data: phonePrefixOptions } = usePhonePrefixes();
	const { data: companies } = useCompanies(true);
	const [isLoading, setLoading] = useState(false);
	const { isAdmin, companyUuid } = useProfile();
	const { values } = useFormState();
	const { message } = useMessage();
	const { change } = useForm();
	const {
		i18n: { language },
		t,
	} = useTranslations();

	const companyUuids = isAdmin ? values.company_uuids : [companyUuid];
	const offices =
		companyOffices?.reduce((acc, { offices }) => [...acc, ...offices], []) ||
		[];
	const prevOffices = usePrevious(offices);

	const fetchAllOffices = async () => {
		const companyOffices = await fetchAllCompanyOffices({
			companyUuids,
			setLoading,
			errorCallback: () => {
				message.error(t('common.message.error_message'));
			},
		});

		setCompanyOffices(companyOffices);
	};

	const clearOffices = () => {
		const officeUuids = values.office_uuids;
		const newOfficeUuids = officeUuids?.filter((officeUuid) =>
			offices.map(({ uuid }) => uuid).includes(officeUuid)
		);

		change('office_uuids', newOfficeUuids);
	};

	useEffect(() => {
		fetchAllOffices();
		// eslint-disable-next-line
	}, [companyUuids.length]);

	useEffect(() => {
		if (!_.isEqual(prevOffices, offices) && !!offices.length && !isLoading) {
			clearOffices();
		}
		// eslint-disable-next-line
	}, [companyUuids.length, offices.length, prevOffices, isLoading]);

	const sortedCompanies = sortByName(companies, language);

	const fields = getFields({
		companies: sortedCompanies,
		phonePrefixOptions,
		offices,
		values,
		isAdmin,
	});

	return {
		fields,
		t,
	};
};
