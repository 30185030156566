import React from 'react';
import { object, arrayOf, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Link, Typography } from '@material-ui/core';
import { List as VirtualizedList } from 'react-virtualized';
import { Link as RouterLink } from 'react-router-dom';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { replacePreviewBookingUrl, replaceCustomerPreviewUrl } from 'helpers';
import { CAMPAIGN_CONSUMER_TYPES } from '../../helpers';

export const CampaignConsumersList = ({ consumers, type }) => {
	const { t } = useTranslations();

	const listHeight = Math.min(300, consumers.length * 30);

	//eslint-disable-next-line
	const rowRenderer = ({ key, index, style }) => {
		let url, label;

		if (type === CAMPAIGN_CONSUMER_TYPES.CUSTOMERS) {
			url = replaceCustomerPreviewUrl(consumers[index].customer_uuid);
			label = consumers[index].phone;
		} else {
			url = replacePreviewBookingUrl(consumers[index].uuid);
			label = consumers[index].booking_individual_number;
		}

		return (
			<Box key={key} style={style}>
				<Link to={url} component={RouterLink} variant="h6">
					{label}
				</Link>
			</Box>
		);
	};

	if (isEmpty(consumers)) {
		return (
			<Box justifyContent="center" display="flex" padding={2}>
				<Typography variant="h5">{t('table.no_data')}</Typography>
			</Box>
		);
	}

	return (
		<VirtualizedList
			rowCount={consumers.length}
			width={300}
			height={listHeight}
			rowHeight={30}
			rowRenderer={rowRenderer}
		/>
	);
};

CampaignConsumersList.propTypes = {
	consumers: arrayOf(object).isRequired,
	type: string.isRequired,
};
