import { useSelector } from 'react-redux';

// Import utilities
import { useExportColumns } from 'queries';

// Import helpers
import { carsAvailabilitySelector } from 'store/selectors';
import { formatValues } from '../Filters/helpers';

export const useExport = () => {
	const { exportFilters, isOpen } = useSelector(carsAvailabilitySelector);

	const formattedFilters = formatValues(exportFilters);

	const { data: exportColumns, isFetching } = useExportColumns(
		formattedFilters,
		isOpen
	);

	return {
		exportColumns,
		exportFilters,
		isFetching,
	};
};
