// Import helpers
import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
};

export const columns = [
	{
		Header: 'cms.partners.table.headers.company_name',
		accessor: `company_name`,
		disableSortBy: true,
	},
	{
		Header: 'cms.pages.table.headers.title',
		accessor: `header_title`,
		disableSortBy: true,
	},
	{
		Header: 'cms.pages.table.headers.content',
		accessor: `header_content`,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
