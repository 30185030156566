import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	logo: {
		height: '50px',
		width: 'auto',
	},
}));
