export const COLUMNS_ACCESSORS = {
	EXPAND: 'expand',
	RATING: 'rating',
	TOTAL_EARNINGS: 'total_earnings',
	ACTIVE: 'active',
	FULL_NAME: 'full_name',
	BOOKING_NUMBER: 'booking_number',
	IS_SETTLED: 'is_settled',
	PICKUP_TIME: 'pickup_time',
	EARNINGS: 'earnings',
	ROLE: 'role',
	LATE_ASSIGNMENT_AND_DELIVERY: 'late_assignment_and_delivery',
};

export const columns = [
	{
		Header: '',
		id: COLUMNS_ACCESSORS.EXPAND,
		accessor: COLUMNS_ACCESSORS.EXPAND,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.name',
		accessor: COLUMNS_ACCESSORS.FULL_NAME,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.role',
		accessor: COLUMNS_ACCESSORS.ROLE,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.orders_number',
		accessor: 'orders_number',
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.late_assignment_and_delivery',
		accessor: COLUMNS_ACCESSORS.LATE_ASSIGNMENT_AND_DELIVERY,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.earnings',
		accessor: COLUMNS_ACCESSORS.EARNINGS,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.rating',
		accessor: COLUMNS_ACCESSORS.RATING,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.booking_number',
		accessor: COLUMNS_ACCESSORS.BOOKING_NUMBER,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.pickup_time',
		accessor: COLUMNS_ACCESSORS.PICKUP_TIME,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.is_settled',
		accessor: COLUMNS_ACCESSORS.IS_SETTLED,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.is_active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
];
