import React from 'react';
import { createContext } from "react";
import PropTypes from 'prop-types';

// Import queries
import { useBookingHistory } from 'queries';

export const BookingHistoryContext = createContext();

const BookingHistoryProvider = ({ bookingId, children }) => {
    const { data, isFetching, isError, error } = useBookingHistory({ bookingId });

    return (
        <BookingHistoryContext.Provider value={{
            data,
            isFetching,
            isError,
            error
        }}>
            {children}
        </BookingHistoryContext.Provider>
    )
};

BookingHistoryProvider.propTypes = {
    bookingId: PropTypes.string,
    children: PropTypes.node
}

export default BookingHistoryProvider;