import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';

//Import styles
import useStyles from './styles';

export const InputError = ({ meta }) => {
	const { t } = useTranslation();

	const classes = useStyles();

	return meta.touched && (meta.error || meta.submitError) ? (
		<FormHelperText className={classes.errorText}>
			{t(meta.error) || meta.submitError}
		</FormHelperText>
	) : null;
};

InputError.propTypes = {
	meta: PropTypes.object.isRequired,
};
