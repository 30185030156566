import React from 'react';
import { node, any } from 'prop-types';
import { Box } from '@material-ui/core';

//Import styles
import useStyles from './styles';

const TabPanel = ({ children, value, index, ...rest }) => {
	const classes = useStyles();
	return (
		<Box
			className={classes.root}
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...rest}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Box>
	);
};

TabPanel.propTypes = {
	children: node,
	index: any.isRequired,
	value: any.isRequired,
};

export default TabPanel;
