import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const columns = ({ lng }) => [
	{
		Header: 'cars.models.table.headers.manufacturer',
		accessor: `maker_name.${lng}`,
	},
	{ Header: 'cars.models.table.headers.model', accessor: `name.${lng}` },
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
		isHidden: false,
	},
];
