import { useEffect, useState } from 'react';
import moment from 'moment-hijri';
import { get, indexOf } from 'lodash';
import { useForm, useFormState } from 'react-final-form';

// Import helpers
import {
	CALENDAR_TYPES,
	FIELD_TYPES,
	generateArray,
	YMD_SLASH_DATE_FORMAT,
	YMD_SLASH_HIJRI_DATE_FORMAT,
	YM_DATE_FORMAT,
	YM_HIJRI_DATE_FORMAT,
} from 'helpers';

export const useDateSelectFieldAdapter = ({
	name: fieldName,
	shouldDefaultHijri,
}) => {
	const { values } = useFormState();
	const { change } = useForm();
	const [isHijri, setIsHijri] = useState(shouldDefaultHijri);
	const [isInvalidDate, setIsInvalidDate] = useState(false);

	const initialDate = get(values, fieldName);

	const formattedSlashDate = isHijri
		? YMD_SLASH_HIJRI_DATE_FORMAT
		: YMD_SLASH_DATE_FORMAT;

	const initialFormattedDate = initialDate
		? moment(initialDate, YMD_SLASH_DATE_FORMAT).format(formattedSlashDate)
		: '//';

	const initialValue = initialFormattedDate.split('/');
	const [year, month, day] = initialValue;

	const [selectedDate, setSelectedDate] = useState({ day, year, month });

	useEffect(() => {
		if (year && month && day) {
			setSelectedDate({ day, year, month });
		}
	}, [day, year, month]);

	const handleRadioChange = (event) => {
		setIsHijri(event.target.value === CALENDAR_TYPES.HIJRI);
	};

	const currentHijriYear = moment().iYear();
	const currentGregorianYear = moment().year();

	const currentYear = isHijri ? currentHijriYear : currentGregorianYear;
	const years = generateArray(currentYear - 100, currentYear + 100);

	const months = isHijri
		? moment.localeData('ar-sa')._iMonths
		: moment.months();

	const days = moment(
		`${selectedDate.year}-${selectedDate.month}`,
		isHijri ? YM_HIJRI_DATE_FORMAT : YM_DATE_FORMAT
	).daysInMonth();

	const getMonthIndex = (month) => indexOf(months, month) + 1;

	const checkIsValidDate = ({ year, month, day }) => {
		const date = moment(`${year}/${month}/${day}`, formattedSlashDate);

		const isValidDate = date.isValid();
		setIsInvalidDate(!isValidDate);

		if (isValidDate) {
			return moment(date).format(YMD_SLASH_DATE_FORMAT);
		} else {
			return false;
		}
	};

	const handleDateChange = (e) => {
		const { name, value } = e.target;
		const newDate = {
			...selectedDate,
			[name]: value,
		};
		setSelectedDate(newDate);
		const { year, month, day } = newDate || {};
		if (year && month && day) {
			const date = checkIsValidDate(newDate);
			if (date) {
				change(fieldName, date);
			}
		}
	};

	const fields = [
		{
			type: FIELD_TYPES.SELECT,
			name: 'year',
			required: true,
			md: 4,
			xs: 4,
			options: years,
			keyExtractor: (year) => year,
			labelExtractor: (year) => year,
			onChange: handleDateChange,
			value: selectedDate.year,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'month',
			required: true,
			md: 4,
			xs: 5,
			options: months,
			keyExtractor: (month) => getMonthIndex(month).toString().padStart(2, '0'),
			labelExtractor: (month) => month,
			onChange: handleDateChange,
			value: selectedDate.month,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'day',
			required: true,
			md: 4,
			xs: 3,
			options: generateArray(1, days),
			keyExtractor: (day) => day.toString().padStart(2, '0'),
			labelExtractor: (day) => day,
			onChange: handleDateChange,
			value: selectedDate.day,
		},
	];

	return {
		handleRadioChange,
		isHijri,
		fields,
		isInvalidDate,
	};
};
