import React from 'react';
import { Box } from '@material-ui/core';
import { fetchRewardsSettlementGroupedList } from 'store/actions';

// Import helpers
import { columns } from './columns';
import { TOOLBAR_CONTENT } from './helpers';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow, InnerToolbar, TableActionsProvider } from './components';

export const RewardsSettlement = () => {
	const classes = useBaseLayoutStyles();

	return (
		<TableProvider
			columns={columns}
			fetchDataTableAction={fetchRewardsSettlementGroupedList}
		>
			<TableActionsProvider>
				<Box className={classes.root}>
					<Toolbar content={TOOLBAR_CONTENT} />
					<Box className={classes.content}>
						<Table
							isDefaultRow={false}
							tableInnerToolbar={<InnerToolbar />}
							rowsPerPageOptions={[100, 200, 300]}
							initialPageSize={100}
						>
							{({ row }) => <TableBodyRow key={row.id} row={row} />}
						</Table>
					</Box>
				</Box>
			</TableActionsProvider>
		</TableProvider>
	);
};
