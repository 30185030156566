import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import {
	COMPANY_COLLECTION_QUERY,
	LOCAL_STORAGE_COMPANIES_LIST,
} from 'helpers';

export const useCompanies = (isActive) => {
	const companyCollectionUrl = `/v2/supplier/business/company/collection${
		isActive ? '-active' : ''
	}`;

	const localStorageCompaniesList = ls.get(
		LOCAL_STORAGE_COMPANIES_LIST(isActive ? 'active' : '')
	);

	const { data, isFetching, isError, isFetched } = useQuery(
		COMPANY_COLLECTION_QUERY,
		async () => await services.get(companyCollectionUrl),
		{ refetchOnWindowFocus: false, enabled: !localStorageCompaniesList }
	);

	const fetchedData = data?.data.data || [];

	useEffect(() => {
		if (!localStorageCompaniesList && isFetched && fetchedData.length) {
			ls.set(
				LOCAL_STORAGE_COMPANIES_LIST(isActive ? 'active' : ''),
				fetchedData,
				{
					ttl: 7 * 24 * 60 * 60,
				}
			);
		}
		// eslint-disable-next-line
	}, [localStorageCompaniesList, isFetched, fetchedData]);

	const result = localStorageCompaniesList || fetchedData;

	return { data: result, isFetching, isError };
};
