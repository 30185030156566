export const rejectBookingUrl = (bookingId) =>
	`/v2/booking/${bookingId}/reject`;

export const cancelBookingUrl = (bookingId) =>
	`/v2/booking/${bookingId}/cancel`;

export const acceptBookingUrl = (bookingId) =>
	`/v2/booking/${bookingId}/accept`;

export const approveBookingCancellationUrl = (bookingId) =>
	`/v2/booking/${bookingId}/cancellation/approve`;

export const assignUrgentAssistanceReasonUrl = (bookingId) =>
	`/v2/booking/${bookingId}/urgent-assistance`;

export const changeUrgentReasonStatusUrl = (bookingId) =>
	`/v2/booking/${bookingId}/urgent-assistance-reason/change-status`;

export const markBookingAsCompensatedUrl = (uuid) =>
	`/v2/booking/compensation/${uuid}/close`;

export const changeBookingCancelReasonStatusUrl = (bookingId) =>
	`/v2/booking/${bookingId}/cancel-reason/change-status`;

export const changeBookingCompensationStatusUrl = (bookingId) =>
	`/v2/booking/${bookingId}/compensation`;

export const approveBookingExtensionUrl = (uuid) =>
	`/v2/extension/${uuid}/approve`;

export const rejectBookingExtensionUrl = (uuid) =>
	`/v2/extension/${uuid}/reject`;
