import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		borderRadius: '8px',
		border: '1px solid',
		borderColor: theme.palette.divider,
		marginTop: '16px',
		padding: ' 12px 16px',
	},
	title: {
		fontSize: '16px',
		fontWeight: 500,
	},
	button: {
		display: 'grid',
		placeItems: 'center',
		backgroundColor: 'transparent',
		border: 'none',
		cursor: 'pointer',
	},
	divider: {
		margin: '12px 0',
	},
}));
