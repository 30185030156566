import React from 'react';
import { useSelector } from 'react-redux';

// Import  store
import { previewBookingSelector } from 'store/selectors';

// Import components
import PreviewField from './PreviewField';

export const DropDatePreviewField = () => {
	const booking = useSelector(previewBookingSelector);

	const { period, early_return_details } = booking || {};

	const date = early_return_details
		? early_return_details.old_drop_date
		: period.drop_date;

	return (
		<PreviewField label="bookings.preview_booking.period.drop_date">
			{date}
		</PreviewField>
	);
};
