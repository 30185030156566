import i18n from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const CANCEL_BOOKING_FIELDS = (cancelReasons = []) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.cancel_reason',
		name: 'cancel_reason.uuid',
		required: true,
		options: cancelReasons,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ details }) => details.name[i18n.language],
	},
];
