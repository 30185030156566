import React from 'react';
import HistoryIcon from '@material-ui/icons/History';
import { IconButton } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { object } from 'prop-types';

// Import utilities
import { useToggle } from 'components/utilities';

// Import components
import { CarsLogHistory, CarsLogHistoryProvider } from 'components/elements';

const ActionTableCell = ({ cell }) => {
	const { on, toggle } = useToggle();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{on && (
				<CarsLogHistoryProvider uuid={cell.row.original.uuid}>
					<CarsLogHistory open={on} onClose={toggle} />
				</CarsLogHistoryProvider>
			)}

			<IconButton onClick={toggle}>
				<HistoryIcon />
			</IconButton>
		</TableCell>
	);
};

ActionTableCell.propTypes = {
	cell: object.isRequired,
};

export default ActionTableCell;
