// Import helpers
import { URLS } from 'components/routes';
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = () => [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: URLS.createFaqUrl,
		text: 'faq.table.toolbar.add_faq',
	},
];
