import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'sticky',
		bottom: 0,
		paddingLeft: 30,
		backgroundColor: theme.palette.white,
		'&:hover': {
			backgroundColor: '#f5f5f5',
		},

		'& .MuiListItem-root': {
			backgroundColor: 'red',
		},
	},
}));
