export const getListName = (value) => {
	switch (value) {
		case 4: {
			return 'spreadsheet_list';
		}

		case 5: {
			return 'important_instructions_list';
		}

		default:
			return null;
	}
};
