import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	chip: {
		borderRadius: '8px',
		padding: '10px 5px',
		height: 'auto',

		'& .MuiChip-label': {
			whiteSpace: 'normal',
		},
	},
}));
