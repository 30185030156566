import React from 'react';
import { MenuItem, Grid } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';
import { array } from 'prop-types';

const GratificationTypeField = ({ options = [] }) => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	return (
		<Grid item md={4} xs={12}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t('campaigns.form.gratification.type')}
				name="gratification.type"
				margin="dense"
				variant="outlined"
				disabled={submitting}
				required
			>
				{options.map((type) => (
					<MenuItem key={type} value={type}>
						{t(`campaigns.gratification_types.${type}`)}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

GratificationTypeField.propTypes = {
	options: array.isRequired,
};

export default GratificationTypeField;
