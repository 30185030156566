import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';

// Import components
import { useTranslations } from 'components/utilities';
import useTransactionHistory from '../context/useTransactionHistory';

const TransactionHistoryRowActions = ({ transaction }) => {
	const { t } = useTranslations();

	const {
		handleRefund,
		handleCharge
	} = useTransactionHistory();

	const { 
		can_refund,
		can_charge
	} = transaction;

	return (
		<>
			<Grid container spacing={1}>
				{can_refund && (
					<Grid item>
						<Button
							color="primary"
							variant="contained"
							size="small"
							onClick={() => handleRefund(transaction)}
						>
							{t('bookings.transaction_history.table.actions.refund')}
						</Button>
					</Grid>
				)}
				{can_charge && (
					<Grid item>
						<Button
							color="primary"
							variant="contained"
							size="small"
							onClick={() => handleCharge(transaction)}
						>
							{t('bookings.transaction_history.table.actions.charge')}
						</Button>
					</Grid>
				)}
			</Grid>
		</>
	);
};

TransactionHistoryRowActions.propTypes = {
	transaction: PropTypes.object,
};

export default TransactionHistoryRowActions;
