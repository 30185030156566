import services from 'services/services';
import {
	submitExperienceSharedFormUrl,
	fetchBookingGratificationUrl,
} from 'store/paths';

export const submitExperienceSharedForm = ({
	values,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		await services.post(submitExperienceSharedFormUrl, values);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};

export const fetchBookingGratification = async (booking) => {
	const {
		data: { data },
	} = await services.post(fetchBookingGratificationUrl, { booking });

	return data;
};
