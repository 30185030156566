import React from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	DialogContent,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';

// Import helpers
import { columns } from './columns';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { UrgentReasonsHistoryBody } from './components';

const UrgentReasonsHistory = ({ open, onClose }) => {
	const { t } = useTranslations();

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg">
			<DialogTitle disableTypography>
				<Typography variant="h4">
					{t(
						'bookings.preview_booking.booking_info.urgent_reasons_history.title'
					)}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{columns.map(({ header, accessor }) => (
									<TableCell key={accessor}>
										{header ? t(header) : ''}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							<UrgentReasonsHistoryBody />
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};

UrgentReasonsHistory.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

export default UrgentReasonsHistory;
