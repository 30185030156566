import React from 'react';
import { useParams } from 'react-router-dom';

// Import store
import { fetchCorporateSettlements } from 'store/actions';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TOOLBAR_CONTENT } from './helpers';

// Import components
import { TableRPC } from 'components/utilities';
import { TableBodyRow } from './components';

export const CorporateSettlements = () => {
	const { id: corporateUuid } = useParams();

	return (
		<TableRPC
			columns={columns}
			fetchDataTableAction={fetchCorporateSettlements}
			columnsAccessor={COLUMNS_ACCESSORS}
			toolBarContent={TOOLBAR_CONTENT(corporateUuid)}
			tableBodyRow={TableBodyRow}
		/>
	);
};
