import React from 'react';
import { Form } from 'react-final-form';
import { Grid, CircularProgress } from '@material-ui/core';

// Import helpers
import {
	PERMISSION_RATE_CUSTOMER,
	PERMISSION_CUSTOMER_COMPENSATION,
	PERMISSION_ADD_ADMIN_COMMENT,
	PERMISSION_RATE_OFFICE,
	FIELDS_OLD_COMPANY,
	PERMISSION_DELIVERY_INFORMATION,
} from './helpers';

// Import styles
import useStyles from './styles';

// Import utilities
import usePreviewBooking from './usePreviewBooking';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { AlertMessage, CollapsableCard } from 'components/elements';
import RoleProtected from 'components/hoc/RoleProtected';

import {
	CustomerCard,
	CompanyCard,
	CarCard,
	InvoiceCard,
	PeriodCard,
	BookingInfoCard,
	CommentsBox,
	CustomerRating,
	CustomerRatingPreview,
	CustomerCompensation,
	UrgentAttentionWarningCard,
	OfficeRatingCard,
	OfficeRatingPreview,
	DeliveryInformationCard,
	SubscriptionInvoiceCard,
	EarlyReturnCard,
	OldDeliveryInformation,
} from './components';

export const PreviewBooking = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		item,
		error,
		companyAndCarLabel,
		editBookingHistory,
		isFetchingEditBooking,
		subscriptionUuid,
		isHistory,
		isLoaded,
		isError,
	} = usePreviewBooking({ t });

	const renderCards = () =>
		isLoaded &&
		!isError && (
			<>
				<UrgentAttentionWarningCard />

				<BookingInfoCard />

				<CustomerCard />

				<RoleProtected
					roles={PERMISSION_RATE_OFFICE}
					fallback={<OfficeRatingPreview />}
				>
					<OfficeRatingCard />
				</RoleProtected>

				<PeriodCard />

				<RoleProtected roles={PERMISSION_CUSTOMER_COMPENSATION}>
					<CustomerCompensation />
				</RoleProtected>

				<InvoiceCard />

				<EarlyReturnCard />

				<SubscriptionInvoiceCard subscriptionUuid={subscriptionUuid} />

				<RoleProtected roles={PERMISSION_ADD_ADMIN_COMMENT}>
					<CommentsBox adminsComments />
				</RoleProtected>

				{isHistory && (
					<CollapsableCard
						title={t('bookings.preview_booking.old_company_and_car_card')}
					>
						<CarCard
							customData={editBookingHistory}
							isFetching={isFetchingEditBooking}
						/>
						<CompanyCard
							customData={editBookingHistory}
							isFetching={isFetchingEditBooking}
							fields={FIELDS_OLD_COMPANY}
						/>
					</CollapsableCard>
				)}

				<CollapsableCard title={companyAndCarLabel}>
					<CarCard />
					<CompanyCard />
				</CollapsableCard>

				<RoleProtected roles={PERMISSION_DELIVERY_INFORMATION}>
					<DeliveryInformationCard />
				</RoleProtected>

				{isHistory && (
					<CollapsableCard
						title={t(
							'bookings.preview_booking.delivery_information.old_deliveries.title'
						)}
						initialOpen={false}
					>
						<OldDeliveryInformation customData={editBookingHistory} />
					</CollapsableCard>
				)}

				<RoleProtected roles={PERMISSION_RATE_CUSTOMER}>
					<CollapsableCard
						title={t('bookings.preview_booking.supplier_customer_rating_card')}
					>
						<RoleProtected
							roles={PERMISSION_RATE_CUSTOMER}
							fallback={<CustomerRatingPreview />}
						>
							<CustomerRating />
						</RoleProtected>
						<CommentsBox />
					</CollapsableCard>
				</RoleProtected>
			</>
		);

	if (!isLoaded) {
		return (
			<div className={classes.loader}>
				<CircularProgress size={70} color="primary" />
			</div>
		);
	}

	return (
		<div className={classes.root}>
			{isError && isLoaded && (
				<AlertMessage
					title={t('common.error')}
					variant="error"
					description={error.error_header || error.message}
				/>
			)}

			<Grid container spacing={3}>
				<Form onSubmit={() => {}} initialValues={item} render={renderCards} />
			</Grid>
		</div>
	);
};
