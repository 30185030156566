import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

const CompensationHistoryField = ({ label, children }) => {
	const { t } = useTranslations();

	return (
		<Grid
			alignItems="flex-start"
			justifyContent="space-between"
			wrap="nowrap"
			container
		>
			<Grid item lg="auto">
				<Typography variant="h5">{t(label)}</Typography>
			</Grid>
			<Grid item lg="auto">
				<Typography variant="body1" component="div">
					{children}
				</Typography>
			</Grid>
		</Grid>
	);
};

CompensationHistoryField.propTypes = {
	label: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default CompensationHistoryField;
