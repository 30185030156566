import React from 'react';

// Import components
import { TableFilters } from 'components/elements';
import { FilterFields } from '../../components';

import { useCarsAvailability } from 'views/CarsAvailability/useCarsAvailability';

export const Filters = () => {
	const { filtersInitialValues } = useCarsAvailability();

	return (
		<TableFilters initialValues={filtersInitialValues}>
			{() => <FilterFields />}
		</TableFilters>
	);
};
