import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Import helpers
import { convertApiFormErrors, replacePreviewBookingUrl } from 'helpers';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { suspendContract } from 'store/actions';
import { useSuspendContractProvider } from '../../context';

export const useSuspendForm = () => {
	const { id: bookingUuid } = useParams();

	const [isLoading, setIsLoading] = useState(false);

	const { push } = useHistory();

	const { t } = useTranslations();

	const { message } = useMessage();

	const handleOnClose = () => {
		push(replacePreviewBookingUrl(bookingUuid));
	};

	const { contractNumber, plateUuid } = useSuspendContractProvider();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setIsLoading(true);

		const options = {
			callbackSuccess: () => {
				message.success(t('common.messages.successfully_saved'));
				setIsLoading(false);
				handleOnClose();
			},
			errorCallback: (error) => {
				const isErrorString = typeof error === 'string';

				message.error(
					isErrorString ? error : t('common.messages.error_message')
				);
				setIsLoading(false);
				if (error && !isErrorString) apiErrors = convertApiFormErrors(error);
			},
			contractNumber,
			bookingUuid,
			plateUuid,
			values,
		};

		await suspendContract(options);

		return apiErrors;
	};

	return {
		handleOnSubmit,
		handleOnClose,
		isLoading,
	};
};
