// ----------------- FORM -----------------
export const PARTNERS_FORM_FETCH_DATA_LOADING =
	'PARTNERS_FORM_FETCH_DATA_LOADING';
export const PARTNERS_FORM_FETCH_DATA_SUCCESS =
	'PARTNERS_FORM_FETCH_DATA_SUCCESS';
export const PARTNERS_FORM_FETCH_DATA_ERROR = 'PARTNERS_FORM_FETCH_DATA_ERROR';

// Partners positions
export const PARTNERS_POSITIONS_FORM_FETCH_DATA_LOADING =
	'PARTNERS_POSITIONS_FORM_FETCH_DATA_LOADING';
export const PARTNERS_POSITIONS_FORM_FETCH_DATA_SUCCESS =
	'PARTNERS_POSITIONS_FORM_FETCH_DATA_SUCCESS';
export const PARTNERS_POSITIONS_FORM_FETCH_DATA_ERROR =
	'PARTNERS_POSITIONS_FORM_FETCH_DATA_ERROR';
