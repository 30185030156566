import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { B2B_EXTEND_BOOKING_PRICE_CALCULATION } from 'helpers';

export const useExtendBookingPriceCalculation = ({ bookingUuid, dropDate }) => {
	const path = `/v2/coordinator/booking/${bookingUuid}/extend/calculate?drop_date=${dropDate}`;

	const { data, isFetching, error } = useQuery(
		[B2B_EXTEND_BOOKING_PRICE_CALCULATION, dropDate],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: !!dropDate }
	);

	const fetchedData = data ? data?.data?.data : null;

	return { data: fetchedData, isFetching, error };
};
