import React from 'react';
import { Grid } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

const DeliveryFilter = () => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid container item md={2} alignItems="flex-end">
			<Field
				type="checkbox"
				component={CheckboxFieldAdapter}
				name="is_delivery"
				disabled={submitting}
				labelText={t('bookings.filters.is_delivery') || ''}
				color="primary"
			/>
		</Grid>
	);
};

export default DeliveryFilter;
