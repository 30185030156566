import { Filters } from '../Bookings/components';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Filters,
		componentProps: { isExtended: true },
		customKey: 'filters',
	},
];
