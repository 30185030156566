import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import components
import {
	ScreenshotField,
	SourceField,
	BookingIdField,
	BookingDataPreview,
} from './components';
import useExperienceSharedFormFields from './useExperienceSharedFormFields';

// Import translations
import { useTranslations } from 'components/utilities';

const ExperienceSharedFormFields = () => {
	const { t } = useTranslations();

	const { bookingIdFieldProps, bookingData } = useExperienceSharedFormFields();

	return (
		<Grid item lg={9} xs={12}>
			<Card>
				<CardHeader title={t('campaigns.experience_form.title')} />
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<Grid item lg={9} xs={12}>
							<SourceField />
						</Grid>

						<Grid item lg={9} xs={12}>
							<ScreenshotField />
						</Grid>

						<BookingIdField {...bookingIdFieldProps} />

						{bookingData && (
							<Grid item lg={9} xs={12}>
								<BookingDataPreview data={bookingData} />
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default ExperienceSharedFormFields;
