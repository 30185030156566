// ********************  table  ********************

export const fetchCancellationReasonsUrl = (queryParams) =>
	`/v2/cancel-reason/list${queryParams}`;

export const updateActiveCancellationReasonUrl = (id, isActive) =>
	`/v2/cancel-reason/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const deleteCancellationReasonUrl = (id) =>
	`/v2/cancel-reason/${id}/remove`;

// ********************  form  ********************

export const fetchCancellationReasonsFormResourcesUrl = (itemId) =>
	`/v2/cancel-reason/${itemId}/show`;

export const submitCancellationReasonsFormUrl = (isEdit, itemId) =>
	`/v2/cancel-reason/${isEdit ? `${itemId}/update` : 'create'}`;
