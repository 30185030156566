import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from '../../styles';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import BookingStatusPreviewField from '../../../BookingStatusPreviewField';

const BookingStatusCardSection = () => {
	const { t } = useTranslations();

	const classes = useStyles();
	return (
		<Box className={classes.container}>
			<Typography variant="h5">
				{t('bookings.preview_booking.booking_info.status')}
			</Typography>
			<BookingStatusPreviewField />
		</Box>
	);
};

export default BookingStatusCardSection;
