import Bugsnag from '@bugsnag/js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import formatDistance from 'date-fns/formatDistance';
import { enGB, arSA } from 'date-fns/locale';
import { previewBookingSelector } from 'store/selectors';
import { useTranslations } from 'components/utilities';
import { AR } from 'helpers';

const useTimeElapsed = () => {
	const [elapsedTime, setElapsedTime] = useState('');
	const booking = useSelector(previewBookingSelector);

	const { i18n } = useTranslations();
	const lng = i18n.language;

	const formatOptions = {
		locale: lng === AR ? arSA : enGB,
	};

	const handleInterval = () => {
		const urgentUpdatedAt = new Date(booking.urgentAssistance.updated_at);

		if (!urgentUpdatedAt) return;

		const stringTimeNowInSA = new Date().toLocaleString('en-US', {
			timeZone: 'Asia/Riyadh',
		});

		const timeNowInSA = new Date(stringTimeNowInSA);
		try {
			const elapsed = formatDistance(
				timeNowInSA,
				urgentUpdatedAt,
				formatOptions
			);

			setElapsedTime(elapsed);
		} catch (e) {
			Bugsnag.notify(e, function (event) {
				const timeDebuggingData = {
					urgentUpdatedAt,
					stringTimeNowInSA,
					timeNowInSA,
				};
				event.addMetadata('timeDebug', timeDebuggingData);
				event.addMetadata('booking', booking);
			});
		}
	};

	useEffect(() => {
		const elapsedTimeInterval = setInterval(handleInterval, 1000);

		return () => clearInterval(elapsedTimeInterval);

		// eslint-disable-next-line
	}, [booking]);

	return { elapsedTime };
};

export default useTimeElapsed;
