export const prepareInitialDropCities = (cities) => {
	return cities.map((cityTo) => {
		return cities.map((cityFrom) => ({
			cityFrom,
			cityTo,
			price: '',
			isEdited: false,
		}));
	});
};
