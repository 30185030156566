// ********************  table  ********************

export const fetchModelsUrl = (queryParams) =>
	`v2/vehicle/model/list${queryParams}`;

export const deleteModelUrl = (id) => `v2/vehicle/model/${id}/remove`;

// ********************  form  ********************

export const fetchModelFormResourcesUrl = (itemId) =>
	`/v2/vehicle/model/${itemId}/show`;

export const submitModelFormUrl = (isEdit, itemId) =>
	`/v2/vehicle/model/${isEdit ? `${itemId}/update` : 'create'}`;
