import React from 'react';

// Import components
import { StatsCards } from './components';

// Import utilities
import { StatsContextProvider } from './context';

export const UrgentStats = () => {
	return (
		<StatsContextProvider>
			<StatsCards />
		</StatsContextProvider>
	);
};
