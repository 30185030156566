import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import {
	updateActiveCancellationReason,
	deleteCancellationReason,
} from 'store/actions';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import {
	replaceEditCancellationReasonUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';

// Import components
import {
	EditTableCell,
	ActiveTableCell,
	DeleteTableCell,
} from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, TYPE, OPERATION_VALIDATION } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const { t } = useTranslations();

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveCancellationReason}
						cell={cell}
					/>
				);
			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditCancellationReasonUrl(
							cell.row.original.uuid
						)}
					/>
				);
			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteCancellationReason}
						cell={cell}
					/>
				);
			case TYPE:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{t(`bookings.cancellation_types.${cell.row.original.type}`)}
					</TableCell>
				);
			case OPERATION_VALIDATION:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.operation_validation && <CheckIcon />}
					</TableCell>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
