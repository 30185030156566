import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useForm, useFormState } from 'react-final-form';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useSelector } from 'react-redux';

// Import utils
import { useTranslations } from 'components/utilities';
import { mobileAppVersionSelector } from 'store/selectors';

// Import components
import { SystemClientFieldRow } from '../SystemClientFieldRow';

// Import styles
import { useStyles } from '../../styles';

export const FormContent = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { mutators } = useForm();
	const { values } = useFormState();
	const {
		item: { versioned },
	} = useSelector(mobileAppVersionSelector);

	const { clients } = values || {};

	const parentFieldName = 'clients';

	const handlePushItem = () => mutators.push(parentFieldName, undefined);

	const isAddButtonDisabled =
		clients.length === versioned?.apps?.length * versioned?.platforms?.length;

	return (
		<Card>
			<CardHeader title={t('mobile_app.form.clients_app_title')} />
			<Divider />
			<CardContent>
				<FieldArray name={parentFieldName}>
					{({ fields }) =>
						fields.map((name, index) => (
							<SystemClientFieldRow
								key={name}
								name={name}
								index={index}
								fields={fields}
							/>
						))
					}
				</FieldArray>
				<Grid item md={12} xs={12}>
					<Button
						variant="contained"
						color="primary"
						className={classes.addButton}
						onClick={handlePushItem}
						disabled={isAddButtonDisabled}
					>
						<AddCircleIcon className={classes.addIcon} fontSize="small" />
						{t('mobile_app.form.add_client')}
					</Button>
				</Grid>
			</CardContent>
		</Card>
	);
};
