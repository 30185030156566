import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState } from 'react-final-form';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

const OptionField = ({ type, label }) => {
	const { submitting } = useFormState();

	return (
		<Field
			component={CheckboxFieldAdapter}
			type="checkbox"
			labelText={label}
			name={`options.${type}`}
			disabled={submitting}
			color="primary"
		/>
	);
};

OptionField.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string
};

export default OptionField;
