import { SERVICES_TYPES } from 'helpers';

export const initialValues = {
	license_number: '',
	is_kiosk: 'all',
	service_type: 'all',
	airport_uuid: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		is_kiosk: values.is_kiosk === 'all' ? null : values.is_kiosk,
		service_type: values.service_type === 'all' ? null : values.service_type,
		airport_uuid:
			values.airport_uuid === 'all' ||
			values.service_type !== SERVICES_TYPES.AIRPORT
				? null
				: values.airport_uuid,
	};
};
