import * as types from 'store/types';
import services from 'services/services';
import {
	fetchPromoCodeFormResourcesUrl,
	submitPromoCodeFormUrl,
} from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import { convertSubmitData, convertFetchData } from './helpers';

export const fetchPromoCodeFormResources =
	({ itemId, isEdit, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.PROMO_CODE_FORM_FETCH_DATA_LOADING,
			});

			const { data } =
				isEdit &&
				(await services.get(
					fetchPromoCodeFormResourcesUrl(itemId),
					cancelToken
				));

			const convertedData = data && convertFetchData(data);

			dispatch({
				type: types.PROMO_CODE_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: convertedData || {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.PROMO_CODE_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitPromoCodeForm =
	({ values, itemId, isEdit, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			const convertedData = convertSubmitData({ values });

			await services.post(
				submitPromoCodeFormUrl(itemId, isEdit),
				convertedData
			);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
