import React from 'react';
import { IconButton } from '@material-ui/core';
import { bool, oneOf } from 'prop-types';

// Import styles
import { useStyles } from './SquareButton.styles';

// Import helpers
import { BUTTON_ACTION_TYPES } from 'helpers';
import { getIcon } from './helpers';

const { INCREMENT, DECREMENT } = BUTTON_ACTION_TYPES;

export const SquareButton = ({ iconType, ...props }) => {
	const classes = useStyles();

	const Icon = getIcon(iconType);

	return (
		<IconButton
			variant="outlined"
			color="primary"
			className={classes.button}
			data-testid="square_button"
			{...props}
		>
			{Icon && <Icon className={classes.icon} />}
		</IconButton>
	);
};

SquareButton.propTypes = {
	iconType: oneOf([INCREMENT, DECREMENT]).isRequired,
	disabled: bool,
};
