import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CUSTOMER_GENDERS_QUERY } from 'helpers';

const useGenders = () => {
	const path = '/v2/customer/gender/list';

	const { data, isFetching } = useQuery(
		CUSTOMER_GENDERS_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || '';

	return { data: fetchedData, isFetching };
};

export default useGenders;
