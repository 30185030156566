import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

const InfoListItem = ({ label, children }) => {
	const { t } = useTranslations();

	return (
		<ListItem disableGutters>
			<Grid container spacing={2} justifyContent="space-between">
				<Grid item>
					<Typography variant="h6">{t(label)}</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body1">{children}</Typography>
				</Grid>
			</Grid>
		</ListItem>
	);
};

InfoListItem.propTypes = {
	label: PropTypes.node,
	children: PropTypes.node,
};

export default InfoListItem;
