import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { CompanyFilter } from 'components/elements';

export const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<CompanyFilter name="company_uuid" label="bookings.filters.company" />
		</Grid>
	);
};
