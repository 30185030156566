import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	verifyCode,
	resetPassword,
	forcePassword,
	signOut,
} from 'store/actions';

// Import helpers
import { convertApiFormErrors } from 'helpers/form';
import { validationStep2, validationStep3 } from './validation';
import { URLS } from 'components/routes';

export default ({ history }) => {
	const dispatch = useDispatch();
	const [nextStep, setNextStep] = useState(false);
	const [code, setCode] = useState(false);
	const forcePasswordReset = useSelector(
		(state) => state.auth.authenticate?.data.force_password_reset
	);
	const token = useSelector((store) => store.auth.resetToken);

	useEffect(() => {
		if (forcePasswordReset) {
			forcePassword()(dispatch);
		}
	}, [forcePasswordReset, dispatch]);

	const handleSubmitStep2 = async (values) => {
		let apiErrors = {};
		if (!nextStep) {
			await verifyCode(
				{ token: token, code: values.code },
				(errors) => {
					apiErrors = convertApiFormErrors(errors);
				},
				() => {
					setNextStep(true);
					setCode(values.code);
				}
			)();
			return apiErrors;
		} else {
			await resetPassword(
				{ ...values, token, code },
				(errors) => {
					apiErrors = convertApiFormErrors(errors);
				},
				() => {
					history.push('/');
				}
			)(dispatch);
			return apiErrors;
		}
	};

	const validation = (values) =>
		!nextStep ? validationStep2(values) : validationStep3(values);

	const handleOnSignOut = async () => {
		await signOut()(dispatch);
		history.push(URLS.signInUrl);
	};

	return {
		submitStep2: handleSubmitStep2,
		validation,
		nextStep,
		onSignOut: handleOnSignOut,
	};
};
