import React from 'react';
import { object, string } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './TwoRowsTableCell.styles';

export const TwoRowsTableCell = ({ cell, title, subtitle }) => {
	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container spacing={1}>
				<Grid container>
					{title && (
						<Grid item xs={12}>
							<Typography className={classes.title} noWrap>
								{title}
							</Typography>
						</Grid>
					)}
					{subtitle && (
						<Grid item xs={12}>
							<Typography className={classes.subtitle} noWrap>
								{subtitle}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
		</TableCell>
	);
};

TwoRowsTableCell.propTypes = {
	cell: object.isRequired,
	subtitle: string,
	title: string,
};

TwoRowsTableCell.defaultProps = {
	subtitle: null,
	title: null,
};
