import React from 'react';
import { Field } from 'react-final-form';
import { string } from 'prop-types';
import { CircularProgress, Grid, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useCityFilter } from './useCityFilter';

export const CityFilter = ({ label, name, externalCompanyUuid = null }) => {
	const { t } = useTranslations();

	const { cities, isFetching, isFieldDisabled } = useCityFilter(
		name,
		externalCompanyUuid
	);

	if (!cities) {
		return null;
	}

	return (
		<Grid container item xs={3} md={3}>
			{isFetching ? (
				<Grid item xs>
					<CircularProgress size={28} />
				</Grid>
			) : (
				<Grid item xs>
					<Field
						fullWidth
						component={SelectFieldAdapter}
						disabled={isFieldDisabled}
						label={t(label)}
						name={name}
						variant="outlined"
						margin="dense"
					>
						<MenuItem value="all">{t('common.all')}</MenuItem>
						{cities.map(({ uuid, name }) => (
							<MenuItem key={uuid} value={uuid}>
								{name}
							</MenuItem>
						))}
					</Field>
				</Grid>
			)}
		</Grid>
	);
};

CityFilter.propTypes = {
	externalCompanyUuid: string,
	label: string.isRequired,
	name: string.isRequired,
};
