import { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { pick } from 'lodash';

// Import helpers
import { convertApiFormErrors } from 'helpers';

// Import store
import { signIn } from 'store/actions';

// Import utils
import { useMessage } from 'components/utilities';

export const useFormContent = () => {
	const { values, submitting, submitErrors } = useFormState();

	const [fieldsErrors, setFieldsErrors] = useState(null);

	const { message } = useMessage();

	const { change } = useForm();

	const [isOtpTokenLoading, setOtpTokenLoading] = useState(false);

	const handleOnChangeOtp = (value) => change('code', value);

	const getOtpToken = async () => {
		setOtpTokenLoading(true);

		await signIn({
			values: pick(values, ['email', 'password']),

			errorCallback: (error) => {
				const { message: apiErrorMessage, errors } = error || {};

				apiErrorMessage && message.error(apiErrorMessage);
				errors && setFieldsErrors(convertApiFormErrors(errors));
			},
			successCallback: ({ data }) => {
				const { token } = data || {};
				change('token', token);
				setFieldsErrors(null);
			},
		});

		setOtpTokenLoading(false);
	};

	const isLoading = isOtpTokenLoading;

	return {
		handleOnChangeOtp,
		getOtpToken,
		isLoading,
		submitting,
		values,
		fieldsErrors,
		submitErrors,
	};
};
