import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { submitEditCarForm, fetchCarInfo } from 'store/actions';

// Import helpers and utils
import { INITIAL_VALUES } from './helpers';
import { useAddCar } from './useAddCar';
import { FormRPC } from 'components/utilities';

//Import styles
import useStyles from '../styles';

// Import components
import {
	CarDetails,
	Ratings,
	ExtraServices,
	CarSpecification,
	Details,
	ExternalPartner,
	SubscriptionModel,
} from '../components';

const AddCar = () => {
	const classes = useStyles();

	const { mounted, goBackPath } = useAddCar();

	const [isSubmitDisabled, setSubmitDisabled] = useState(false);

	if (!mounted) return null;

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchCarInfo}
				submitFormAction={submitEditCarForm}
				isCardLayout={false}
				goBackPath={goBackPath}
				store={(store) => store.car.form}
				initialValues={INITIAL_VALUES}
				isSubmitDisabled={isSubmitDisabled}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<CarDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Ratings section="prices" setSubmitDisabled={setSubmitDisabled} />
						</Grid>
						<Grid item lg={9} xs={12}>
							<SubscriptionModel section="subscription" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ExtraServices section="options" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<CarSpecification section="specification" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Details section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ExternalPartner section="external" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};

export default AddCar;
