import _ from 'lodash';

export const modifyVerifyData = (data) => {
	return {
		data: _.pick(data.data, [
			'force_password_reset',
			'uuid',
			'role',
			'meta',
			'id',
		]),
		isAuthenticated: true,
	};
};

export const convertProfileData = ({ details, customer_wallet }) => {
	const pickedDetails = _.pick(details, [
		'active',
		'email',
		'full_name',
		'language',
		'phone',
		'phone_prefix',
		'corporate',
	]);

	return { ...pickedDetails, customer_wallet };
};
