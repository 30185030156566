import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

export const convertFiltersToParams = (filters) => {
	const {
		company_uuid,
		office_uuids,
		period: { from, until },
	} = filters;

	const convertedFrom = from && format(new Date(from), DEFAULT_DATE_FORMAT);
	const convertedUntil = until && format(new Date(until), DEFAULT_DATE_FORMAT);
	const convertedOfficeUuids = office_uuids.reduce(
		(acc, uuid, index) => ({ ...acc, [`office_uuids[${index}]`]: uuid }),
		{}
	);

	const newFilters = {
		until: convertedUntil,
		from: convertedFrom,
		company_uuid: company_uuid || '',
		...convertedOfficeUuids,
	};

	const queryParams = new URLSearchParams(newFilters).toString();

	return queryParams;
};
