import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '8px',
	},
	dot: {
		width: '16px',
		height: '16px',
		borderRadius: '50%',
		backgroundColor: 'red',
	},
	text: {
		fontWeight: '400',
	},
	legend: {
		gap: '16px',
	},
}));
