import { useForm, useFormState } from 'react-final-form';
import React, { useEffect } from 'react';

export default ({ extractorValues, initValueFun, valueName, emptyValue }) => {
	const { submitting, values } = useFormState();
	const { change } = useForm();

	const { has_external, selectedValue, options } = extractorValues(values);

	const [isHidden, setHidden] = React.useState(initValueFun(selectedValue));

	useEffect(() => {
		if (isHidden) {
			change(valueName, emptyValue);
		}
		// eslint-disable-next-line
	}, [isHidden, valueName]);

	const handleChangeInfo = () => setHidden((prev) => !prev);

	return {
		submitting,
		has_external,
		options,
		isHidden,
		handleChangeInfo,
	};
};
