import React from 'react';
import { bool, func } from 'prop-types';

// Import helpers
import { DAYS, createBusinessHoursShape } from './helpers';

// Import components
import { BusinessHour } from './BusinessHour';

const BusinessHours = (props) => {
	const handleChange = (value, day) => {
		props.onChange({
			...props.value,
			[day.slug]: value,
		});
	};

	const disabled = props.isOfficeManager || props.disabled;

	return (
		<>
			{DAYS.map((day) => (
				<BusinessHour
					key={day.slug}
					value={props.value[day.slug]}
					onChange={(value) => handleChange(value, day)}
					disabled={disabled}
					day={day}
				/>
			))}
		</>
	);
};

BusinessHours.propTypes = {
	value: createBusinessHoursShape().isRequired,
	onChange: func.isRequired,
	disabled: bool,
	isOfficeManager: bool,
};

export default BusinessHours;
