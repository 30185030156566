import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import { COLUMNS_ACCESSORS } from '../UnsettledLimitsWrapper/columns';

// Import components
import { SettleCheckboxTableCell } from '../../components';
import { CustomerNameTableCell, LinkTableCell } from 'components/elements';
import { replacePreviewBookingUrl } from 'helpers';

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			booking_individual_number: bookingId,
			booking_uuid: bookingUuid,
			user_name: userName,
		} = cell.row.original;

		const previewBookingUrl = replacePreviewBookingUrl(bookingUuid);

		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.BOOKING_ID:
				return (
					<LinkTableCell
						to={previewBookingUrl}
						key={cell.column.id}
						cell={cell}
					>
						{bookingId}
					</LinkTableCell>
				);

			case COLUMNS_ACCESSORS.CUSTOMER_NAME:
				return (
					<CustomerNameTableCell
						customerName={userName}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case COLUMNS_ACCESSORS.SETTLE_CHECKBOX:
				return <SettleCheckboxTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
