import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	CardContent,
	CardHeader,
	Divider,
	Card,
	Grid,
} from '@material-ui/core';

// Import components
import { TextFieldAdapter } from 'components/elements';

// Import utilities
import useBaseDetails from 'views/Cars/useBaseDetails';

const BaseDetails = ({ className, ...props }) => {
	const { submitting, values } = useFormState();

	const {
		t,
		makers,
		value,
		inputMakerValue,
		handleMakerOnChange,
		handleOnMakerInputChange,
	} = useBaseDetails({
		values,
		inputState: values.name.en,
	});

	return (
		<Card className={className} {...props}>
			<CardHeader title={t('cars.manufacturer.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={6} xs={6}>
						<Autocomplete
							value={inputMakerValue}
							inputValue={inputMakerValue}
							onChange={handleMakerOnChange}
							onInputChange={handleOnMakerInputChange}
							options={makers.map((el) => el.name)}
							getOptionSelected={(option, value) => option.name === value.name}
							renderInput={(params) => (
								<Field
									fullWidth
									component={TextFieldAdapter}
									type="text"
									label={t('cars.manufacturer.form.details.manufacturer_en')}
									name="name.en"
									margin="dense"
									variant="outlined"
									disabled={submitting}
									required
									defaultValue={value}
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item md={6} xs={6}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="text"
							label={t('cars.manufacturer.form.details.manufacturer_ar')}
							name="name.ar"
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

BaseDetails.propTypes = {
	className: string,
};

export default BaseDetails;
