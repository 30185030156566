import React from 'react';
import { bool, object } from 'prop-types';
import { Chip, TableCell, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

// Import styles
import { useStyles } from './SettlementTableCell.styles';
import clsx from 'clsx';

// Import utils
import { useTranslations } from 'components/utilities';

export const SettlementTableCell = ({ cell, isSettled }) => {
	const classes = useStyles({ isSettled });
	const { t } = useTranslations();

	const getAdditionalChipClassName = (isSettled) => {
		if (isSettled) {
			return classes.settledChip;
		} else if (!isSettled && typeof isSettled === 'boolean') {
			return classes.notSettledChip;
		} else {
			return classes.NAChip;
		}
	};

	const getChipLabel = () => {
		if (isSettled) {
			return <CheckIcon className={classes.successIcon} />;
		} else if (!isSettled && typeof isSettled === 'boolean') {
			return <CloseIcon className={classes.errorIcon} />;
		} else {
			return <Typography>{t('common.not_available_shorthand')}</Typography>;
		}
	};

	const additionalChipClassName = getAdditionalChipClassName(isSettled);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Chip
				className={clsx({
					[classes.chip]: true,
					[additionalChipClassName]: true,
				})}
				label={getChipLabel()}
			/>
		</TableCell>
	);
};

SettlementTableCell.propTypes = {
	isSettled: bool,
	cell: object,
};
