import React from 'react';
import { string } from 'prop-types';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './styles';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { getStatusType } from './helpers';

export const StatusChip = ({ status }) => {
	const statusType = getStatusType(status);

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Chip
			label={t(`bookings.validation_statuses.${status}`)}
			className={clsx(classes.chip, classes[statusType])}
		/>
	);
};

StatusChip.propTypes = {
	status: string.isRequired,
};
