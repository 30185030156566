import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Import components
import { ChipLabel } from 'components/elements';
import { useBookingInfoCardAction } from './useBookingInfoCardAction';

// Import translations
import { useTranslations } from 'components/utilities';

const BookingInfoCardAction = () => {
	const { t } = useTranslations();

	const { chipLabels } = useBookingInfoCardAction();

	return (
		<Grid container spacing={1}>
			{chipLabels.map(({ label, statusText }) => (
				<Grid key={label} item>
					<ChipLabel
						label={
							<>
								<Typography color="primary">{t(label)}</Typography>
								<Typography variant="h6" color="primary">
									{': '}
									{t(statusText)}
								</Typography>
							</>
						}
					/>
				</Grid>
			))}
		</Grid>
	);
};

export default BookingInfoCardAction;
