import React from 'react';
import { useFormState, useForm, Field } from 'react-final-form';
import { bool, number, oneOfType, string } from 'prop-types';
import { Grid, InputAdornment } from '@material-ui/core';

// Import components
import { TextFieldAdapter, CheckboxFieldAdapter } from 'components/elements';

// Import translations
import { useToggle, useTranslations } from 'components/utilities';

const OneTimePaymentField = ({
	initialInputValue,
	inputValue,
	inputName,
	checkboxLabelText,
	checkboxName,
	isOpen,
	disabled = false,
	minValue = 0,
}) => {
	const { on: isFree, toggle } = useToggle(+initialInputValue === 0);

	const { submitting } = useFormState();

	const { change } = useForm();

	const { t } = useTranslations();

	const handleCheckboxChange = () => {
		toggle();
		const price = isFree ? inputValue : 0;
		change(inputName, price);
	};

	return (
		<>
			<Grid item xs={12}>
				<Field
					component={CheckboxFieldAdapter}
					type="checkbox"
					labelText={t(checkboxLabelText)}
					name={checkboxName}
					disabled={submitting || disabled}
					color="primary"
				/>
			</Grid>

			{isOpen && (
				<>
					<Grid item md={2} xs={3}>
						<Field
							component={CheckboxFieldAdapter}
							type="checkbox"
							labelText={t('cars.form.extra_services.free')}
							name="free"
							disabled={submitting || disabled}
							color="primary"
							required={isFree}
							checked={isFree}
							onChange={handleCheckboxChange}
						/>
					</Grid>
					<Grid item md={10} xs={5}>
						<Field
							component={CheckboxFieldAdapter}
							type="checkbox"
							labelText={t('cars.form.extra_services.for_money')}
							name="for_money"
							disabled={submitting || disabled}
							color="primary"
							required={!isFree}
							checked={!isFree}
							onChange={handleCheckboxChange}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="number"
							label={t('cars.form.extra_services.price')}
							name={inputName}
							margin="dense"
							variant="outlined"
							disabled={submitting || isFree || disabled}
							required
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{t('currency.sar')}
									</InputAdornment>
								),
							}}
							inputProps={{ step: 0.01, min: minValue }}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

OneTimePaymentField.propTypes = {
	initialInputValue: oneOfType([string, number]),
	inputValue: oneOfType([string, number]),
	inputName: string.isRequired,
	checkboxName: string.isRequired,
	checkboxLabelText: string.isRequired,
	isOpen: bool,
	disabled: bool,
	minValue: number,
};

export default OneTimePaymentField;
