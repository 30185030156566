import React from 'react';
import { useSelector } from 'react-redux';
import { fetchCustomers } from 'store/actions';
import { Box } from '@material-ui/core';

// Import helpers
import { TOOLBAR_CONTENT, PERMISSION_DELETE_CUSTOMER } from './helpers';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

//Import selectors
import { profileDataSelector } from 'store/selectors';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow, Export } from './components';

import { useTranslations } from 'components/utilities';

export const Customers = () => {
	const classes = useBaseLayoutStyles();

	const { i18n } = useTranslations();
	const lng = i18n.language;

	const { email: adminEmail } = useSelector(profileDataSelector);

	const isAllowedToDelete = PERMISSION_DELETE_CUSTOMER.some(
		(email) => email.toLowerCase() === adminEmail.toLowerCase()
	);

	return (
		<TableProvider
			columns={columns({ lng, isAllowedToDelete })}
			fetchDataTableAction={fetchCustomers}
		>
			<Box className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<Box className={classes.content}>
					<Table isDefaultRow={false} tableInnerToolbar={<Export />}>
						{({ row }) => <TableBodyRow key={row.id} row={row} />}
					</Table>
				</Box>
			</Box>
		</TableProvider>
	);
};
