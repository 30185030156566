import React from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	Tooltip,
	Typography,
} from '@material-ui/core';

// Import utilities
import { useBookingIdCardSection } from './useBookingIdCardSection';
import { useTranslations } from 'components/utilities';

// import styles
import { useStyles } from '../../styles';

// import components
import { CopyToClipboard } from 'components/elements';
import {
	CancelBookingDialog,
	EditBookingButton,
	ExtendBookingDialog,
} from './components';

// Import helpers
import { getCopyActions } from './helpers';
import RoleProtected from 'components/hoc/RoleProtected';
import { ADMIN } from 'helpers';

export const BookingIdCardSection = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		isCancelCorporateBookingButtonVisible,
		isExtendCorporateBookingButtonVisible,
		isInitiateContractButtonVisible,
		isSuspendContractButtonVisible,
		isExtendContractButtonVisible,
		isCloseContractButtonVisible,
		isViewContractButtonVisible,
		isCorporateCoordinator,
		isFetching,
		goToSuspendContractForm,
		goToExtendContractForm,
		goToCreateContractForm,
		goToCloseContractForm,
		goToContractView,
		individualNumber,
		bookingUuid,
		goToViewDraftForm,
		isViewDraftContractButtonVisible,
	} = useBookingIdCardSection();

	return (
		<Box className={classes.container}>
			<Typography variant="h5">
				{t('bookings.preview_booking.booking_info.booking_id')}
			</Typography>
			<Tooltip
				classes={{ tooltip: classes.tooltip }}
				title={
					<CopyToClipboard
						actions={getCopyActions({ uuid: bookingUuid, individualNumber })}
					/>
				}
				placement="right"
				interactive
			>
				<Typography className={classes.individualNumber}>
					{individualNumber}
				</Typography>
			</Tooltip>
			<Grid container spacing={2}>
				{isCorporateCoordinator ? (
					<>
						{isExtendCorporateBookingButtonVisible && (
							<Grid item xs={12}>
								<ExtendBookingDialog />
							</Grid>
						)}
						{isCancelCorporateBookingButtonVisible && (
							<Grid item xs={12}>
								<CancelBookingDialog />
							</Grid>
						)}
					</>
				) : (
					<RoleProtected roles={[ADMIN]}>
						<Grid item xs={12}>
							<EditBookingButton uuid={bookingUuid} />
						</Grid>
					</RoleProtected>
				)}

				{isViewContractButtonVisible && (
					<Grid item>
						<Button
							color="primary"
							variant="outlined"
							onClick={goToContractView}
						>
							{t('bookings.single_booking.details.view_contract')}
						</Button>
					</Grid>
				)}

				{isFetching ? (
					<Box className={classes.fetchingWrapper}>
						<CircularProgress size={24} />
					</Box>
				) : (
					<>
						{isInitiateContractButtonVisible && (
							<Grid item>
								<Button
									color="primary"
									variant="outlined"
									onClick={goToCreateContractForm}
								>
									{t('bookings.single_booking.details.initiate_contract')}
								</Button>
							</Grid>
						)}

						{isViewDraftContractButtonVisible && (
							<Grid item>
								<Button
									color="primary"
									variant="outlined"
									onClick={goToViewDraftForm}
								>
									{t('bookings.single_booking.details.view_draft')}
								</Button>
							</Grid>
						)}

						{isCloseContractButtonVisible && (
							<Grid item>
								<Button
									variant="contained"
									onClick={goToCloseContractForm}
									className={classes.closeButton}
								>
									{t('bookings.single_booking.details.close_contract')}
								</Button>
							</Grid>
						)}

						{isExtendContractButtonVisible && (
							<Grid item>
								<Button
									color="primary"
									variant="outlined"
									onClick={goToExtendContractForm}
								>
									{t('bookings.single_booking.details.extend_contract')}
								</Button>
							</Grid>
						)}

						{isSuspendContractButtonVisible && (
							<Grid item>
								<Button
									color="primary"
									variant="outlined"
									onClick={goToSuspendContractForm}
								>
									{t('bookings.single_booking.details.suspend_contract')}
								</Button>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</Box>
	);
};
