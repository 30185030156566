import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	InputAdornment,
} from '@material-ui/core';

// Import utilities
import useCampaignConditionsCard from './useCampaignConditionsCard';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import {
	KeyboardDatePickerFieldAdapter,
	TextFieldAdapter,
} from 'components/elements';

const CampaignConditionsCard = () => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	const { conditionsFields, sectionName } = useCampaignConditionsCard();

	return (
		<Card>
			<CardHeader title={t('campaigns.form.condition_card_title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						{conditionsFields.map(({ name, label, minDate }) => (
							<Grid item md={6} xs={12} key={name}>
								<Field
									fullWidth
									margin="dense"
									label={t(label)}
									component={KeyboardDatePickerFieldAdapter}
									name={name}
									inputVariant="outlined"
									disabled={submitting}
									required
									minDate={minDate}
								/>
							</Grid>
						))}
					</MuiPickersUtilsProvider>
					<Grid item md={6} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="number"
							label={t(`campaigns.form.condition.total_budget`)}
							name={`${sectionName}.total_budget_amount.nett_price`}
							margin="dense"
							variant="outlined"
							required
							disabled={submitting}
							inputProps={{ min: 0.01, step: 0.01 }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{t('currency.sar')}
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default CampaignConditionsCard;
