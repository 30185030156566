import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { FileFieldAdapter } from 'components/elements';

// import translations
import { useTranslations } from 'components/utilities';

const ScreenshotField = () => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	return (
		<>
			<Typography>{t('campaigns.experience_form.screenshot')}</Typography>
			<Box mt={2}>
				<Field
					previewFileState="screenshot"
					component={FileFieldAdapter}
					name="screenshot.file_path"
					fileNameField="screenshot.file_name"
					disabled={submitting}
					uploadButtonWidth={12}
					required
				/>
			</Box>
		</>
	);
};

export default ScreenshotField;
