import React from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import utils
import { useEarlyReturnDialog } from './useEarlyReturnDialog';

// Import helpers
import { INITIAL_VALUES } from './helpers';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';

export const EarlyReturnDialog = () => {
	const { handleOnSubmit, toggle, title, isLoading, isOpen } =
		useEarlyReturnDialog();

	return (
		<>
			<Button color="primary" disableRipple onClick={toggle}>
				{title}
			</Button>
			<Dialog fullWidth open={isOpen} onClose={toggle}>
				<ClosableDialogTitle onClose={toggle} title={title} />
				<DialogContent>
					<Form
						onSubmit={handleOnSubmit}
						initialValues={INITIAL_VALUES}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit} noValidate>
								<FormContent toggle={toggle} isLoading={isLoading} />
							</form>
						)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};
