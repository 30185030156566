import React from 'react';
import { Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Import translations
import { useTranslations } from 'components/utilities';

export const MoreInfoButton = (props) => {
	const { t } = useTranslations();

	return (
		<Button color="primary" endIcon={<ChevronRightIcon />} {...props}>
			{t('common.buttons.more_info')}
		</Button>
	);
};
