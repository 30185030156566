import BusinessIcon from '@material-ui/icons/Business';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = () => {
	return [{
        variant: TOOLBAR_VARIANTS.NAV,
        to: '/kiosk/management/create',
        icon: BusinessIcon,
        text: 'kiosk.management.table.toolbar.add_kiosk'
    }];
};
