import React from 'react';
import { Field } from 'react-final-form';
import {
	CardContent,
	CardHeader,
	MenuItem,
	Divider,
	Grid,
	Card,
} from '@material-ui/core';

// Import components
import { CarUsageFlag } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useLeasingCarSpec } from './useLeasingCarSpec';

// Import variables
import { FIELD_TYPES } from 'helpers';

const { SELECT } = FIELD_TYPES;

export const LeasingCarSpec = () => {
	const { t } = useTranslations();

	const {
		submitting,
		bodyColors,
		milesUsage,
		CHECKBOX_FIELDS,
		SPECIFICATION_FIELDS,
		MILES_USAGE_FLAG,
	} = useLeasingCarSpec();

	return (
		<Card>
			<CardHeader title={t('cars.form.car_specifications.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid container item spacing={2}>
						{!!bodyColors.length &&
							SPECIFICATION_FIELDS({ bodyColors }).map(
								({
									name,
									label,
									options,
									xs,
									md,
									required,
									component,
									type,
								}) => (
									<Grid key={name} item xs={xs} md={md}>
										{type === MILES_USAGE_FLAG ? (
											<CarUsageFlag milesUsage={milesUsage} />
										) : (
											<Field
												fullWidth
												component={component}
												label={t(label)}
												name={name}
												variant="outlined"
												margin="dense"
												disabled={submitting}
												required={required}
												type={type}
												selected
											>
												{type === SELECT &&
													options.map(({ label, value }) => (
														<MenuItem key={value} value={value}>
															{label}
														</MenuItem>
													))}
											</Field>
										)}
									</Grid>
								)
							)}
					</Grid>
					<Grid container item>
						{CHECKBOX_FIELDS().map(
							({ name, label, component, color, type, xs, md }) => (
								<Grid key={name} item xs={xs} md={md}>
									<Field
										component={component}
										type={type}
										labelText={t(label)}
										name={name}
										disabled={submitting}
										color={color}
									/>
								</Grid>
							)
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
