import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { INITIATE_CONTRACT_INITIAL_VALUES_QUERY } from 'helpers';

export const useInitiateContractInitialValues = (
	bookingUuid,
	enabled = true
) => {
	const path = `/v2/booking/${bookingUuid}/econtract/collection`;

	const { data, error, isError, isFetching } = useQuery(
		[INITIATE_CONTRACT_INITIAL_VALUES_QUERY, bookingUuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError, error };
};
