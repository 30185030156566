import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const LoaderIcon = (props) => (
	<SvgIcon
		version="1.1"
		width="141.731pt"
		height="141.732pt"
		viewBox="0 0 141.731 141.732"
		{...props}
	>
		<g enableBackground="new">
			<g id="Layer-1" data-name="Layer 1">
				<path
					transform="matrix(1,0,0,-1,94.4883,74.588397)"
					d="M 0 0 L 0 -19.9 L -27.358 -19.9 L -47.245 -.013 L -47.245 27.344 L -27.344 27.344 L -27.344 0 Z"
					fill="#15a9be"
				/>
			</g>
		</g>
	</SvgIcon>
);
