import i18next from 'i18next';

// Import variables
import { BOOKING_COMPENSATION_TYPES } from './variables';

export const getCompensationTypeLabel = ({ type, removeCancelState }) => {
	const translationKey = 'bookings.compensation_history.compensation_types';

	switch (type) {
		case BOOKING_COMPENSATION_TYPES.CUSTOMER_SHORTEN_PERIOD:
		case BOOKING_COMPENSATION_TYPES.AUTO_LOW_RATING:
		case BOOKING_COMPENSATION_TYPES.ADMIN_MANUAL:
			return i18next.t(`${translationKey}.${type}`);

		default:
			return removeCancelState
				? ''
				: i18next.t(`${translationKey}.cancellation`);
	}
};
