import { useForm, useFormState } from 'react-final-form';
import { queryCache } from 'react-query';

// Import helpers
import { getPartnerUrl, convertCompanyName } from './helpers';
import { COMPANY_COLLECTION_QUERY } from 'helpers';

export const useSettings = () => {
	const {
		values: { subdomain },
	} = useFormState();

	const { change } = useForm();
	const { partnerUrl, baseDomain } = getPartnerUrl(subdomain);

	const companies = queryCache.getQueryData(COMPANY_COLLECTION_QUERY)?.data
		?.data;

	const actions = [
		{
			buttonText: 'copy_url',
			value: partnerUrl,
			disabled: !subdomain,
		},
	];

	const handleOnCompanyChange = (event) => {
		const companyUuid = event.target.value;
		const convertedCompanyName = convertCompanyName({ companies, companyUuid });

		change('subdomain', convertedCompanyName);
		change('company_uuid', companyUuid);
	};

	return {
		subdomain,
		actions,
		baseDomain,
		handleOnCompanyChange,
	};
};
