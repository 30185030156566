import { useSelector } from 'react-redux';

import { previewBookingSelector } from 'store/selectors';
import { validateAndGetContractDate } from './helpers';

export const useContractDatesPreviewField = () => {
	const {
		contract,
		period: { pick_date: pickDate, drop_date: dropDate },
	} = useSelector(previewBookingSelector);

	const { opened_at, closed_at } = contract || {};

	const contractOpenedAt = opened_at
		? validateAndGetContractDate(opened_at, pickDate)
		: null;

	const contractClosedAt = closed_at
		? validateAndGetContractDate(closed_at, dropDate)
		: null;

	return {
		hasContract: !!contract,
		contractOpenedAt,
		contractClosedAt,
	};
};
