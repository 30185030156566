import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CAR_LIST_VEHICLE_TYPES_QUERY } from 'helpers';

const useVehicleTypes = () => {
	const path = `/v2/supplier/vehicle/type/list`;

	const { data, isError, isFetching } = useQuery(
		CAR_LIST_VEHICLE_TYPES_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};

export default useVehicleTypes;
