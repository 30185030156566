import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	popover: {
		width: 260,
		padding: 30,
		borderRadius: 24,
	},
	mainWrapper: {
		height: 48,
		padding: 0,
	},
	image: {
		marginRight: 12,
	},
	fullName: {
		color: theme.palette.text.primary,
		textTransform: 'capitalize',
		fontWeight: 600,
		fontSize: 15,
		width: 96,
		[theme.breakpoints.down('md')]: {
			fontSize: 12,
		},
	},
	role: {
		color: theme.palette.text.secondary,
		textTransform: 'capitalize',
		fontWeight: 500,
		width: 96,
		[theme.breakpoints.down('md')]: {
			fontSize: 10,
		},
	},
	rotate: {
		transform: 'rotateX(180deg)',
		height: 'auto',
	},
	arrowWrapper: {
		maxHeight: 12,
	},
}));

export default useStyles;
