import AddCircleIcon from '@material-ui/icons/AddCircle';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: '/cars/manufacturer/add',
		icon: AddCircleIcon,
		text: 'cars.manufacturer.table.toolbar.add_manufacturer',
	},
];
