import React from 'react';

// Import components
import useTransactionHistory from '../context/useTransactionHistory';
import TransactionHistoryRow from './TransactionHistoryRow';

const TransactionHistoryBody = () => {
	const { data } = useTransactionHistory();

	if (!data) {
		return null;
	}

	return (
		<>
			{data.map((row) => (
				<TransactionHistoryRow
					key={row.uuid}
					row={row}
				/>
			))}
		</>
	);
};

export default TransactionHistoryBody;
