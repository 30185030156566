export const COLUMNS_ACCESSORS = {
	NOTE: 'note',
	BOOKING_ID: 'booking_id',
	STATUS: 'status',
	OFFICE_NAME: 'office_name',
	DURATION: 'response_time',
	APPROVED: 'approved',
	COMPENSATED: 'compensated',
	RATING_REASON: 'rating_reason',
	SETTLEMENT_STATUS: 'settlement_status',
	CUSTOMER_NAME: 'customer_name',
	PICK_DATE: 'pick_date',
	CREATED_AT: 'created_at',
	TOPIC: 'topic',
	ACTION: 'action',
	URGENT_STATUS: 'urgent_assistance_status',
	URGENT_REASON: 'urgent_reason',
	ACTIONS: 'actions',
};

export const columns = [
	{
		Header: 'bookings.table.headers.urgent_assistance_status',
		accessor: COLUMNS_ACCESSORS.URGENT_STATUS,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.urgent_assistance_reason',
		accessor: COLUMNS_ACCESSORS.URGENT_REASON,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'office_rating',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.marked_by',
		accessor: 'urgent_assistance_marked_by',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.action',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},

	// Additional columns
	{
		Header: 'bookings.table.headers.created_time',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.office_city',
		accessor: 'office_city',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.office_name',
		accessor: 'office_name',
		disableSortBy: true,
		isHidden: true,
	},
];
