import React from 'react';
import { Box, List } from '@material-ui/core';
import { number, object, array, string, oneOfType, bool } from 'prop-types';

// Import helpers
import { getItems } from './helpers';

// Import components
import { Card } from 'components/elements';
import {
	PriceSummaryItemFactory,
	PriceSummaryItem,
	TotalAmountDifference,
} from './components';

// Import translations
import { useTranslations } from 'components/utilities';

const PriceSummary = ({ values, title, difference, showPrice }) => {
	const { t } = useTranslations();

	const items = getItems(values);

	return (
		<Card title={title} lg={12}>
			<List>
				{items.map((item) => (
					<PriceSummaryItemFactory
						key={item.accessor}
						values={values}
						{...item}
					/>
				))}
				{showPrice && (
					<Box marginTop={2}>
						<PriceSummaryItem
							label={t('bookings.edit_booking.form.difference')}
						>
							<TotalAmountDifference difference={difference} />
						</PriceSummaryItem>
					</Box>
				)}
			</List>
		</Card>
	);
};
PriceSummary.propTypes = {
	values: oneOfType([array, object]).isRequired,
	title: string.isRequired,
	difference: number,
	showPrice: bool,
};
export default PriceSummary;
