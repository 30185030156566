import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'flex-start',
		backgroundColor: theme.palette.primary.light,
		border: '1px solid',
		borderColor: theme.palette.primary.main,
		borderRadius: '10px',
		padding: '15px 24px 15px',
		gap: '10px',
	},
	info: {
		fontSize: '14px',
		fontWeight: 500,
		color: theme.palette.gray.dark,
	},
	icon: {
		color: theme.palette.primary.main,
		width: '16px',
		height: '16px',
	},
}));
