import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	item: {},
	isLoaded: false,
	isError: false,
	error: {},
	officeRating: {
		item: {},
		isLoaded: false,
		isError: false,
		error: {},
	},
	customerRating: {
		item: {},
		isLoaded: false,
		isError: false,
		error: {},
	},
	customerCompensation: {
		item: {},
		isLoaded: false,
		isError: false,
		error: {},
	},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.BOOKINGS_PREVIEW_FETCH_DATA_LOADING:
				draft.isLoaded = false;
				break;

			case types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES:
				draft.isLoaded = true;
				draft.item = action.payload;
				break;

			case types.BOOKINGS_PREVIEW_FETCH_DATA_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				draft.error = action.payload;
				break;

			case types.BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_LOADING:
				draft.officeRating.isLoaded = false;
				break;

			case types.BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_SUCCESS:
				draft.officeRating.isLoaded = true;
				draft.officeRating.item = action.payload.item;
				break;

			case types.BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_ERROR:
				draft.officeRating.isLoaded = true;
				draft.officeRating.isError = true;
				draft.officeRating.error = action.payload;
				break;

			case types.BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_LOADING:
				draft.customerRating.isLoaded = false;
				break;

			case types.BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_SUCCESS:
				draft.customerRating.isLoaded = true;
				draft.customerRating.item = action.payload.item;
				break;

			case types.BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_ERROR:
				draft.customerRating.isLoaded = true;
				draft.customerRating.isError = true;
				break;

			case types.BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_LOADING:
				draft.customerCompensation.isLoaded = false;
				break;

			case types.BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_SUCCESS:
				draft.customerCompensation.isLoaded = true;
				draft.customerCompensation.item = action.payload.item;
				break;

			case types.BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_ERROR:
				draft.customerCompensation.isLoaded = true;
				draft.customerCompensation.isError = true;
				break;

			default:
				return state;
		}
	});
