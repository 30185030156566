import React from 'react';
import { string } from 'prop-types';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Import utilities
import { useToggle } from 'components/utilities';

// Import components
import { ImagePreview } from 'components/elements';

export const ImagePreviewButton = ({ imgUrl, token }) => {
	const { on, toggle } = useToggle();

	return (
		<>
			<Button onClick={toggle}>
				<VisibilityIcon />
			</Button>
			<ImagePreview imgUrl={imgUrl} token={token} close={toggle} on={on} />
		</>
	);
};

ImagePreviewButton.propTypes = {
	imgUrl: string.isRequired,
	token: string,
};
