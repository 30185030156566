import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import utils and helpers
import { BOOKING_COUNT_QUERY, LOCAL_STORAGE_CHIP_COUNT } from 'helpers';

export const useBookingCounters = (reference) => {
	const path = `v2/booking/${reference}/counts`;

	const localStorageCounts = ls.get(LOCAL_STORAGE_CHIP_COUNT(reference));

	const enabled = null == localStorageCounts;

	const { data, isFetching, isFetched } = useQuery(
		[BOOKING_COUNT_QUERY, reference],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : null;

	useEffect(() => {
		if (!localStorageCounts && isFetched && typeof fetchedData === 'number') {
			ls.set(LOCAL_STORAGE_CHIP_COUNT(reference), fetchedData, {
				ttl: 15 * 60,
			});
		}

		// eslint-disable-next-line
	}, [localStorageCounts, isFetched, fetchedData]);

	const result =
		typeof localStorageCounts === 'number' ? localStorageCounts : fetchedData;

	return { data: result, isFetching };
};
