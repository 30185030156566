import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import selectors
import { officeRatingSelector } from 'store/selectors/bookings';

export const RatedByTitle = () => {
	const { t } = useTranslations();
	const {
		values: { isReset },
	} = useFormState();

	const officeRating = useSelector(officeRatingSelector);

	if (!officeRating || isReset) return null;

	return (
		<Grid item xs="auto">
			<Typography color="primary">
				{t('bookings.office_rating.rated_by', {
					createdBy: officeRating.rating_author_name,
					createdAt: officeRating.created_at,
				})}
			</Typography>
		</Grid>
	);
};
