import React from 'react';

// Import icons & images
import { WalletIcon, BankIcon } from 'assets/icons';

// Import styles
import { useStyles } from './styles';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { BOOKING_COMPENSATION_METHODS } from 'helpers';

const { WALLET, BANK } = BOOKING_COMPENSATION_METHODS;

export const useCompensationAmountTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { compensation } = cell.row.original;

	const compensationMethod = compensation?.method;
	const compensationAmount = compensation?.compensation_amount.nett;

	const tooltipTitle = t(
		`bookings.table.common.compensation_methods.${compensationMethod}`
	);

	const renderCompensationMethodIcon = () => {
		switch (compensationMethod) {
			case WALLET:
				return <WalletIcon className={classes.icon} />;
			case BANK:
				return <BankIcon className={classes.icon} />;
			default:
				return <></>;
		}
	};

	return { compensationAmount, renderCompensationMethodIcon, tooltipTitle };
};
