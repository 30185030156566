import React from 'react';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { TextFieldAdapter } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

const GratificationPointsField = () => {
	const { t } = useTranslations();

	const {
		submitting,
		values: {
			gratification: { point },
		},
	} = useFormState();

	const pointsLabel = t(`campaigns.form.gratification.points`);

	return (
		<Grid item md={8} xs={12} container spacing={2} alignItems="center">
			<Grid item xs={8} md={6}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="number"
					label={pointsLabel}
					name="gratification.point"
					margin="dense"
					variant="outlined"
					required
					disabled={submitting}
					inputProps={{ min: 0.01, step: 0.01 }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">{pointsLabel}</InputAdornment>
						),
					}}
				/>
			</Grid>

			{!!point && (
				<Grid item xs={4}>
					<Typography variant="h5" color="primary">
						= {+point / 10} {t('currency.sar')}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default GratificationPointsField;
