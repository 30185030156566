import React from 'react';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

// Import components
import Root from 'Root';

// Import react redux
import { Provider } from 'react-redux';

// Import store
import store from './store/store';

// Import translations
import './i18n';

const App = () => (
	<Provider store={store}>
		<Root />
	</Provider>
);

export default App;
