import React from 'react';
import { object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { EMPTY_VALUE } from 'helpers';

export const AssignedDriverTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const {
		delivery: { pick_up_delivery, drop_delivery },
	} = cell.row.original;

	const { driver_name: driverNamePickup } = pick_up_delivery || {};
	const { driver_name: driverNameDropoff } = drop_delivery || {};

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{driverNamePickup && (
				<Typography>{`${t(
					'bookings.table.common.pickup'
				)}: ${driverNamePickup}`}</Typography>
			)}
			{driverNameDropoff && (
				<Typography>{`${t(
					'bookings.table.common.return'
				)}: ${driverNameDropoff}`}</Typography>
			)}

			{!driverNamePickup && !driverNameDropoff && (
				<Typography>{EMPTY_VALUE}</Typography>
			)}
		</TableCell>
	);
};

AssignedDriverTableCell.propTypes = {
	cell: object.isRequired,
};
