import React from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { CardContent } from '@material-ui/core';

// Import components
import Card from '../Card';
import CardHeader from '../CardHeader';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import store
import { dashboardInfoSelector } from 'store/selectors';

// Import components
import { AlertMessage, LoadingWrapper } from 'components/elements';
import { RentedCarsTable } from './components';

export const MostRentedCars = ({ isCapital = false }) => {
	const { isLoaded, isError, info } = useSelector(dashboardInfoSelector);
	const { capital, global } = info || {};
	const { t } = useTranslations();

	const conditionalTrans = isCapital ? 'capital' : 'global';

	return (
		<Card>
			<CardHeader
				title={t(`dashboard.most_rented_cars_${conditionalTrans}.title`)}
				subheader={t(`dashboard.most_rented_cars_${conditionalTrans}.subtitle`)}
			/>
			<CardContent>
				{!isLoaded && !isError ? (
					<LoadingWrapper />
				) : isError ? (
					<AlertMessage
						variant="error"
						title={t('common.error')}
						description={t('common.messages.error_message')}
					/>
				) : (
					<RentedCarsTable rows={isCapital ? capital : global} />
				)}
			</CardContent>
		</Card>
	);
};

MostRentedCars.propTypes = {
	isCapital: bool,
};
