import services from 'services/services';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';

// Import store
import { fetchCorporateListUrl } from 'store/paths';

export const fetchCorporateList =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchCorporateListUrl(params),
				cancelToken
			);

			const resources = { statistics: data.metadata };

			return Promise.resolve({ data, resources });
		} catch (error) {
			return Promise.reject(error);
		}
	};
