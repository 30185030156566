import React from 'react';
import { object, bool, func, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field } from 'react-final-form';
import { submitOrganizationForm } from 'store/actions';
import { Grid } from '@material-ui/core';

// Import components
import { ModalDialog } from 'components/elements';
import { useTableContextProvider } from 'components/context';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { INITIAL_VALUES, MODAL_FIELDS } from './helpers';
import { convertApiFormErrors } from 'helpers/form';

const OrganizationForm = ({
	open,
	close,
	title = 'organizations.table.toolbar.add',
	initialValues = INITIAL_VALUES,
	uuid,
}) => {
	const { t } = useTranslations();

	const dispatch = useDispatch();

	const { fetchData } = useTableContextProvider();

	const submitSuccess = () => {
		close();
		fetchData();
	};

	const handleOnSubmit = async (values) => {
		let apiErrors = {};
		const options = {
			values,
			itemId: uuid,
			callbackSuccess: submitSuccess,
			errorCallback: (errors) =>
				(apiErrors = convertApiFormErrors(errors || {})),
		};
		await submitOrganizationForm(options)(dispatch);
		return apiErrors;
	};

	return (
		<ModalDialog
			title={title}
			alignTitle="left"
			maxWidth="sm"
			open={open}
			close={close}
			initialValues={initialValues}
			onSubmit={handleOnSubmit}
		>
			{({ submitting }) => {
				return MODAL_FIELDS.map(({ component, type, label, name }) => (
					<Grid key={name} item md={12} xs={12}>
						<Field
							fullWidth
							component={component}
							type={type}
							label={t(label)}
							name={name}
							margin="dense"
							variant="outlined"
							autoComplete="false"
							disabled={submitting}
						/>
					</Grid>
				));
			}}
		</ModalDialog>
	);
};

OrganizationForm.propTypes = {
	open: bool.isRequired,
	close: func.isRequired,
	initialValues: object,
	uuid: string,
	title: string,
};

export default OrganizationForm;
