import React from 'react';
import { Grid } from '@material-ui/core';
import { func, string, bool } from 'prop-types';

// Import components
import { CustomDateInput } from '../../components';

export const SeparatedDateInputs = ({
	nameFrom,
	labelFrom,
	nameUntil,
	labelUntil,
	toggle,
	required = false,
}) => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={6}>
				<CustomDateInput
					name={nameFrom}
					label={labelFrom}
					onIconClick={toggle}
					required={required}
				/>
			</Grid>

			<Grid item xs={6}>
				<CustomDateInput
					name={nameUntil}
					label={labelUntil}
					onIconClick={toggle}
					required={required}
				/>
			</Grid>
		</Grid>
	);
};

SeparatedDateInputs.propTypes = {
	nameFrom: string.isRequired,
	labelFrom: string.isRequired,
	nameUntil: string.isRequired,
	labelUntil: string.isRequired,
	toggle: func.isRequired,
	required: bool,
};
