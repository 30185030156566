import React from 'react';
import { object } from 'prop-types';
import {
	CircularProgress,
	Dialog,
	DialogContent,
	TableCell,
} from '@material-ui/core';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { Form } from 'react-final-form';

// Import utils and store
import { useTrackingMapTableCell } from './useTrackingMapTableCell';

// Import components
import { IconButton } from 'components/elements';
import { DeliveryInformationCard } from 'views/Bookings/PreviewBooking/components';

// Import styles
import { useStyles } from './TrackingMapTableCell.styles';

export const TrackingMapTableCell = ({ cell }) => {
	const { on, handleDialog, booking } = useTrackingMapTableCell({
		cell,
	});

	const classes = useStyles();

	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			className={classes.cell}
		>
			<>
				<IconButton size="small" onClick={handleDialog}>
					<TrackChangesIcon color="primary" />
				</IconButton>
				<Dialog
					open={on}
					onClose={handleDialog}
					className={classes.dialog}
					maxWidth="lg"
				>
					<DialogContent className={classes.content}>
						{Object.keys(booking)?.length > 0 ? (
							<Form
								onSubmit={() => {}}
								initialValues={booking?.item || {}}
								render={() => <DeliveryInformationCard />}
							/>
						) : (
							<CircularProgress />
						)}
					</DialogContent>
				</Dialog>
			</>
		</TableCell>
	);
};

TrackingMapTableCell.propTypes = {
	cell: object.isRequired,
};
