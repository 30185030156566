// Import components
import { useTableContextProvider } from 'components/context';

// Import helpers
import { BOOKING_STATES, BOOKING_EXTENSION_STATUS } from 'helpers';

const useStatusTableCell = ({ booking }) => {
	const { fetchData } = useTableContextProvider();

	const {
		extension: { extension_state },
		state,
	} = booking || {};

	const isPendingState = state === BOOKING_STATES.PENDING;
	const isExtensionStatusPending =
		extension_state === BOOKING_EXTENSION_STATUS.PENDING;

	const isStatusVisible = !(isPendingState || isExtensionStatusPending);

	const refreshTable = () => {
		fetchData();
	};

	return {
		refreshTable,
		isStatusVisible,
	};
};

export default useStatusTableCell;
