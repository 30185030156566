import React from 'react';
import i18next from 'i18next';

// Import icons
import { EditPen } from 'assets/icons';

// Import helpers
import {
	CONTRACT_ADDITIONAL_SERVICES,
	convertPlate,
	FIELD_TYPES,
	findSelectedPlate,
} from 'helpers';

export const INITIAL_VALUES = {
	renter: {
		is_extra_driver: false,
		id_type_code: null,
		birth_date: null,
		id_number: null,
		person_address: '',
		mobile: '',
		full_name: '',
	},
	extra_driver: {
		id_type_code: null,
		birth_date: null,
		id_number: null,
		person_address: '',
	},
	additional_services: {
		is_navigation_system_per_day: false,
		is_disabilities_aids_per_day: false,
		is_internet_per_day: false,
		is_car_seat_per_day: false,
		is_car_delivery: false,
		navigation_system_per_day: null,
		disabilities_aids_per_day: null,
		internet_per_day: null,
		car_seat_per_day: null,
		car_delivery: null,
	},
	payment_details: {
		discount: null,
		full_fuel_cost: 150,
		additional_coverage_cost: 0,
	},
	rent_status: {
		available_fuel: 'HALF_FULL',
		sketch_info: [],
		oil_change_km_distance: 5000,
		oil_type: 'Petromine',
		endurance_amount: 4500,
	},
	contract_details: {
		extended_coverage_id: null,
	},
	authorization_details: {
		external_authorization_countries: [],
		authorization_type_code: null,
		allowed_km_per_hour: 1,
		contract_type_code: 1,
		allowed_km_per_day: null,
		working_branch_id: null,
		receive_branch_id: null,
		return_branch_id: null,
		allowed_late_hours: 2,
	},
	plate: '',
	vehicle_details: {
		vehicle_plate_uuid: '',
	},
};

export const REQUIRED_FIELDS = ({
	vehiclePlates,
	currentPlateNumber = '',
	extendedCoverages,
	policies,
	toggleSearchPlateDialog,
	plateError,
}) => [
	{
		title: 'contract.create_contract_form.sections.vehicle_details',
		inputs: [
			{
				type: FIELD_TYPES.PLATE_NUMBER,
				name: 'plate',
				shouldAutoFocus: true,
				showSearchPlate: true,
				toggleSearchPlateDialog,
				outerValue: currentPlateNumber,
				warning:
					currentPlateNumber &&
					!findSelectedPlate({
						vehiclePlates,
						currentPlateNumber,
					})
						? 'contract.create_contract_form.vehicle_details.plate_different_car_type'
						: '',

				error: plateError,
				md: 6,
			},
			{
				type: FIELD_TYPES.NUMBER,
				name: 'rent_status.odometer_reading',
				label: 'contract.create_contract_form.vehicle_details.odometer_reading',
				required: true,
				md: 4,
			},
		],
	},
	{
		title: 'contract.create_contract_form.sections.contract_details',
		inputs: [
			{
				type: FIELD_TYPES.SELECT,
				name: 'contract_details.extended_coverage_id',
				label:
					'contract.create_contract_form.contract_details.extended_coverage',
				md: 4,
				options: extendedCoverages,
				keyExtractor: ({ id }) => id,
				labelExtractor: ({ name }) => name,
			},
			{
				type: FIELD_TYPES.SELECT,
				name: 'contract_details.rent_policy_id',
				label: 'contract.create_contract_form.contract_details.rent_policy',
				required: true,
				md: 4,
				options: policies || [],
				keyExtractor: ({ id }) => id,
				labelExtractor: ({ shortName }) => shortName,
			},
			{
				type: FIELD_TYPES.NUMBER,
				name: 'authorization_details.operator_id',
				label: 'contract.create_contract_form.contract_details.operator_id',
				required: true,
				md: 4,
				removeUpAndDownArrows: true,
			},
		],
	},
	{
		title: 'contract.create_contract_form.sections.renter',
		inputs: [
			{
				type: FIELD_TYPES.CHECKBOX,
				name: 'renter.is_extra_driver',
				label: 'contract.create_contract_form.renter.extra_driver',
				md: 4,
			},
		],
	},
];

export const RENTER_FIELDS = ({
	nationalityOptions,
	isNationalitySelectHidden,
	isVisitorOrGcc,
	idTypes,
	isCitizen,
}) => [
	{
		type: FIELD_TYPES.TEXT,
		name: 'renter.full_name',
		label: 'contract.create_contract_form.renter.customer_name',
		md: 4,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'renter.mobile',
		label: 'contract.create_contract_form.renter.mobile',
		required: true,
		placeholder: '9665XXXXXXXX',
		md: 4,
		inputLabelProps: {
			shrink: true,
		},
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'renter.id_type_code',
		label: 'contract.create_contract_form.renter.id_type',
		required: true,
		md: 4,
		options: idTypes,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ id }) =>
			i18next.t(`contract.create_contract_form.id_types.${id}`),
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'renter.id_number',
		label: 'contract.create_contract_form.renter.id_number',
		required: true,
		md: 4,
	},
	{
		type: FIELD_TYPES.DATE_SELECT,
		name: 'renter.birth_date',
		label: 'contract.create_contract_form.renter.birth_date',
		required: true,
		minDate: false,
		md: 4,
		shouldDefaultHijri: isCitizen,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'renter.person_address',
		label: 'contract.create_contract_form.renter.address',
		required: true,
		md: 4,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'renter.nationality_code',
		label: 'contract.create_contract_form.renter.nationality_code',
		isHidden: isNationalitySelectHidden,
		required: !isNationalitySelectHidden,
		md: 4,
		options: nationalityOptions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'renter.drive_license_number',
		label: 'contract.create_contract_form.renter.driver_license_number',
		required: isVisitorOrGcc,
		isHidden: !isVisitorOrGcc,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'renter.issue_place_id',
		label: 'contract.create_contract_form.renter.issue_place_id',
		required: isVisitorOrGcc,
		isHidden: !isVisitorOrGcc,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'renter.id_copy_number',
		label: 'contract.create_contract_form.renter.id_copy_number',
		required: isVisitorOrGcc,
		isHidden: !isVisitorOrGcc,
		md: 4,
	},
	{
		type: FIELD_TYPES.DATE_SELECT,
		name: 'renter.license_expiry_date',
		label: 'contract.create_contract_form.renter.license_expiry_date',
		required: isVisitorOrGcc,
		isHidden: !isVisitorOrGcc,
		minDate: false,
		md: 4,
	},
];

export const EXTRA_DRIVER_FIELDS = ({
	isNationalitySelectHidden,
	nationalityOptions,
	idTypes,
	isTabletSize,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'extra_driver.id_type_code',
		label: 'contract.create_contract_form.renter.id_type',
		required: true,
		md: 4,
		options: idTypes,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'extra_driver.id_number',
		label: 'contract.create_contract_form.renter.id_number',
		required: true,
		md: 4,
	},
	{
		type: FIELD_TYPES.DATE_SELECT,
		name: 'extra_driver.birth_date',
		label: 'contract.create_contract_form.renter.birth_date',
		required: true,
		minDate: false,
		md: 4,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'extra_driver.person_address',
		label: 'contract.create_contract_form.renter.address',
		required: true,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'extra_driver.nationality_code',
		label: 'contract.create_contract_form.renter.nationality_code',
		isHidden: isNationalitySelectHidden,
		required: !isNationalitySelectHidden,
		md: 4,
		options: nationalityOptions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
];

export const ADDITIONAL_SERVICES_FIELDS = CONTRACT_ADDITIONAL_SERVICES.map(
	(fieldName) => ({
		inputName: `additional_services.${fieldName}`,
		checkboxName: `additional_services.is_${fieldName}`,
		label: `contract.create_contract_form.additional_services.${fieldName}`,
	})
);

export const VEHICLE_FIELDS = ({ plateTypes }) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'vehicle_details.plate_type',
		label: 'contract.create_contract_form.vehicle_details.plate_type',
		required: true,
		md: 4,
		options: plateTypes,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ id }) =>
			i18next.t(`contract.create_contract_form.plate_types.${id}`),
	},
];

export const PAYMENT_DETAILS_FIELDS = ({
	isInternationalAuthCostRequired,
	isVehicleTransferCost,
	isExtendedCoverageId,
	isDriverFarePerHour,
	isDriverFarePerDay,
	isHourlyType,
	paymentMethods,
	isExtraDriver,
	isTabletSize,
}) => [
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.paid',
		label: 'contract.create_contract_form.payment_details.paid',
		required: true,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.discount',
		label: 'contract.create_contract_form.payment_details.discount_percentage',
		required: true,
		min: 0,
		max: 100,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.extra_km_cost',
		label: 'contract.create_contract_form.payment_details.extra_km_cost',
		required: true,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.rent_day_cost',
		label: 'contract.create_contract_form.payment_details.rent_day_cost',
		required: !isHourlyType,
		isHidden: isHourlyType,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.rent_hour_cost',
		label: 'contract.create_contract_form.payment_details.rent_hour_cost',
		required: isHourlyType,
		isHidden: !isHourlyType,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.full_fuel_cost',
		label: 'contract.create_contract_form.payment_details.full_fuel_cost',
		required: true,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.international_authorization_cost',
		label:
			'contract.create_contract_form.payment_details.international_authorization_cost',
		required: isInternationalAuthCostRequired,
		isHidden: !isInternationalAuthCostRequired,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.driver_fare_per_day',
		label: 'contract.create_contract_form.payment_details.driver_fare_per_day',
		required: isDriverFarePerDay,
		isHidden: !isDriverFarePerDay,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.driver_fare_per_hour',
		label: 'contract.create_contract_form.payment_details.driver_fare_per_hour',
		required: isDriverFarePerHour,
		isHidden: !isDriverFarePerHour,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.extra_driver_cost',
		label: 'contract.create_contract_form.payment_details.extra_driver_cost',
		required: isExtraDriver,
		isHidden: !isExtraDriver,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.vehicle_transfer_cost',
		label:
			'contract.create_contract_form.payment_details.vehicle_transfer_cost',
		required: isVehicleTransferCost,
		isHidden: !isVehicleTransferCost,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'payment_details.payment_method_code',
		label: 'contract.create_contract_form.payment_details.payment_method',
		required: true,
		md: 4,
		options: paymentMethods,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ id }) =>
			i18next.t(`contract.create_contract_form.payment_methods.${id}`),
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.additional_coverage_cost',
		label:
			'contract.create_contract_form.payment_details.additional_coverage_cost',
		required: isExtendedCoverageId,
		isHidden: !isExtendedCoverageId,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'rent_status.endurance_amount',
		label: 'contract.create_contract_form.car_inspection.endurance_amount',
		md: 4,
		required: isExtendedCoverageId,
		isHidden: !isExtendedCoverageId,
		endAdornment: isTabletSize && <EditPen />,
	},
];

export const CAR_INSPECTION_FIELDS = ({
	narrowedUsableConditions,
	availabilityStates,
	workingConditions,
	usableConditions,
	purityStates,
	fuelTypes,
	t,
	isTabletSize,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.fuel_type_code',
		label: 'contract.create_contract_form.car_inspection.fuel_type',
		md: 4,
		required: true,
		options: fuelTypes,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: fuelTypes[1]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.ac',
		label: 'contract.create_contract_form.car_inspection.ac',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: usableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.speedometer',
		label: 'contract.create_contract_form.car_inspection.speedometer',
		md: 4,
		required: true,
		options: workingConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: workingConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.car_seats',
		label: 'contract.create_contract_form.car_inspection.car_seats',
		md: 4,
		required: true,
		options: purityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: purityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.safety_triangle',
		label: 'contract.create_contract_form.car_inspection.safety_triangle',
		md: 4,
		options: availabilityStates,
		required: true,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: availabilityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.tires',
		label: 'contract.create_contract_form.car_inspection.tires',
		md: 4,
		required: true,
		options: narrowedUsableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: narrowedUsableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.fire_extinguisher',
		label: 'contract.create_contract_form.car_inspection.fire_extinguisher',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: availabilityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.first_aid_kit',
		label: 'contract.create_contract_form.car_inspection.first_aid_kit',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: availabilityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.spare_tire_tools',
		label: 'contract.create_contract_form.car_inspection.spare_tire_tools',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: availabilityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.spare_tire',
		label: 'contract.create_contract_form.car_inspection.spare_tire',
		md: 4,
		required: true,
		options: narrowedUsableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: narrowedUsableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.keys',
		label: 'contract.create_contract_form.car_inspection.keys',
		md: 4,
		required: true,
		options: workingConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: workingConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.radio_stereo',
		label: 'contract.create_contract_form.car_inspection.radio_stereo',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: usableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.screen',
		label: 'contract.create_contract_form.car_inspection.screen',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			t(`contract.create_contract_form.options.${name}`),
		defaultValue: usableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'rent_status.oil_change_km_distance',
		label:
			'contract.create_contract_form.car_inspection.oil_change_km_distance',
		md: 4,
		required: true,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'rent_status.oil_type',
		label: 'contract.create_contract_form.car_inspection.oil_type',
		md: 4,
		required: true,
		endAdornment: isTabletSize && <EditPen />,
	},
	{
		type: FIELD_TYPES.DATE_SELECT,
		name: 'rent_status.oil_change_date',
		label: 'contract.create_contract_form.car_inspection.oil_change_date',
		required: true,
		minDate: false,
		md: 4,
	},
];

export const TOP_CONTRACT_DETAILS_FIELDS = ({
	isAuthTypeCodeVisible,
	authorizationTypes,
	contractTypes,
}) => [
	{
		type: FIELD_TYPES.DATE_SELECT,
		name: 'contract_details.contract_start_date',
		label: 'contract.create_contract_form.contract_details.contract_start_date',
		required: true,
		minDate: false,
		md: 4,
	},
	{
		type: FIELD_TYPES.DATE_SELECT,
		name: 'contract_details.contract_end_date',
		label: 'contract.create_contract_form.contract_details.contract_end_date',
		required: true,
		minDate: false,
		md: 4,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'authorization_details.contract_type_code',
		label: 'contract.create_contract_form.contract_details.contract_type',
		required: true,
		md: 4,
		options: contractTypes,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ id }) =>
			i18next.t(`contract.create_contract_form.contract_types.${id}`),
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'authorization_details.authorization_type_code',
		label: 'contract.create_contract_form.contract_details.authorization_type',
		md: 4,
		options: authorizationTypes,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ id }) =>
			i18next.t(`contract.create_contract_form.authorization_types.${id}`),
		required: isAuthTypeCodeVisible,
		isHidden: !isAuthTypeCodeVisible,
	},
];

export const BOTTOM_CONTRACT_DETAILS_FIELDS = ({
	isHourlyType,
	branches,
	isTabletSize,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'authorization_details.working_branch_id',
		label: 'contract.create_contract_form.contract_details.working_branch',
		required: true,
		md: 4,
		options: branches,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'authorization_details.receive_branch_id',
		label: 'contract.create_contract_form.contract_details.receive_branch',
		required: true,
		md: 4,
		options: branches,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'authorization_details.return_branch_id',
		label: 'contract.create_contract_form.contract_details.return_branch',
		required: true,
		md: 4,
		options: branches,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'authorization_details.allowed_km_per_hour',
		label: 'contract.create_contract_form.contract_details.allowed_km_per_hour',
		required: isHourlyType,
		isHidden: !isHourlyType,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'authorization_details.allowed_km_per_day',
		label: 'contract.create_contract_form.contract_details.allowed_km_per_day',
		required: !isHourlyType,
		isHidden: isHourlyType,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'authorization_details.allowed_late_hours',
		label: 'contract.create_contract_form.contract_details.allowed_late_hours',
		required: true,
		min: 0,
		max: 24,
		md: 4,
		endAdornment: isTabletSize && <EditPen />,
	},
];

export const convertApiInitialValues = (values) => {
	const { vehicle_details: vehicleDetails } = values || {};
	const { fullPlateFromVehicleDetails } = convertPlate(vehicleDetails);

	return { ...values, plate: fullPlateFromVehicleDetails };
};
