// Import helpers
import { TABLE_ACTIONS_ACCESSORS } from 'helpers';

export const COLUMNS_ACCESSORS = {
	STATIONS: 'stations',
};

export const columns = [
	{
		Header: 'common.fields.name',
		accessor: 'name',
	},
	{
		Header: 'common.fields.city',
		accessor: 'city_name',
	},
	{
		Header: 'common.fields.active',
		accessor: TABLE_ACTIONS_ACCESSORS.ACTIVE,
	},
	{
		Header: 'common.fields.edit',
		accessor: TABLE_ACTIONS_ACCESSORS.EDIT,
	},
];
