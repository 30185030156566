import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { Grid, MenuItem } from '@material-ui/core';
import { number, string } from 'prop-types';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { getYearsRange } from './helpers';

export const CarYearFilter = ({ name, xs = 3 }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	const years = getYearsRange(2018);

	return (
		<Grid item xs={xs}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t('cars_availability.filters.car_year')}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				<MenuItem value={0}>{t('common.all')}</MenuItem>
				{years.map((value) => (
					<MenuItem key={value} value={value}>
						{value}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

CarYearFilter.propTypes = {
	name: string.isRequired,
	xs: number,
};
