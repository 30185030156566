import React from 'react';
import { object, bool, string, array, number } from 'prop-types';
import { ListItem, Checkbox, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useForm } from 'react-final-form';

// Import helpers
import { getNewStates } from './helpers';

const useStyles = makeStyles(() => ({
	item: {
		'&:hover': {
			borderRadius: '5px',
		},
	},
}));

const CollapsedItem = ({
	carUuid,
	car,
	cars,
	year,
	carsFieldName,
	officeUuid,
	isEnglish,
	...rest
}) => {
	const classes = useStyles();

	const { change } = useForm();

	const {
		checkedOffices,
		checkedCars,
		setCheckedCars,
		setCheckedOffices,
	} = rest;

	const carName = isEnglish ? car.en : car.ar;

	const labelId = `checkbox-list-label-${carUuid}`;

	const handleClick = () => {
		const { newCheckedCars, newCheckedOffices } = getNewStates({
			checkedCars,
			checkedOffices,
			carUuid,
			officeUuid,
			cars,
		});

		change(carsFieldName, newCheckedCars);

		setCheckedOffices(newCheckedOffices);
		setCheckedCars(newCheckedCars);
	};

	return (
		<ListItem
			key={carUuid}
			className={classes.item}
			button
			divider
			onClick={handleClick}
		>
			<Checkbox
				edge="start"
				checked={checkedCars.includes(carUuid)}
				tabIndex={-1}
				color="primary"
				size="small"
				inputProps={{ 'aria-labelledby': labelId }}
			/>
			<ListItemText
				id={labelId}
				primaryTypographyProps={{ variant: 'body2' }}
				primary={`${carName} ${year}`}
			/>
		</ListItem>
	);
};

CollapsedItem.propTypes = {
	carsFieldName: string.isRequired,
	officeUuid: string.isRequired,
	carUuid: string.isRequired,
	car: object.isRequired,
	cars: array.isRequired,
	year: number.isRequired,
	isEnglish: bool.isRequired,
};

export default CollapsedItem;
