import { TABLE_ACTIONS_ACCESSORS } from 'helpers';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
};

export const columns = [
	{
		Header: 'kiosk.management.table.headers.name',
		accessor: 'name',
		disableSortBy: true,
	},
	{
		Header: 'kiosk.management.table.headers.type',
		accessor: 'type',
	},
	{
		Header: 'kiosk.management.table.headers.slug',
		accessor: 'slug',
	},
	{
		Header: 'kiosk.management.table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
	},
	{
		Header: 'kiosk.management.table.headers.edit',
		accessor: EDIT,
	},
	{
		Header: 'kiosk.management.table.headers.delete',
		accessor: DELETE,
		isHidden: process.env.NODE_ENV !== 'development',
	},
];
