import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Import utils and helpers
import { useMessage, useTranslations } from 'components/utilities';
import { convertTrainStation, INITIAL_VALUES } from './helpers';
import { useTrainStation } from 'queries';

// Import store
import { submitTrainStationForm } from 'store/actions';
import { URLS } from 'components/routes';

export const useEditTrainStation = () => {
	const { uuid } = useParams();
	const {
		data: trainStationData,
		isFetching: isTrainStationDataFetching,
		isError: isTrainStationDataError,
	} = useTrainStation({ uuid });
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const { message } = useMessage();
	const { t } = useTranslations();
	const { push } = useHistory();

	const initialValues = uuid
		? convertTrainStation({ trainStationData })
		: INITIAL_VALUES;

	let apiErrors = {};

	const handleOnSubmit = async (values) => {
		setIsSubmitLoading(true);

		await submitTrainStationForm({
			successCallback: () => {
				message.success(
					t(
						uuid
							? 'common.messages.successfully_saved'
							: 'common.messages.successfully_created'
					)
				);
				push(URLS.trainStations);
			},
			errorCallback: (errors) => {
				message.error(t('common.messages.error_message'));
				apiErrors = errors;
			},
			values,
			uuid,
		});

		setIsSubmitLoading(false);

		return apiErrors;
	};

	return {
		isTrainStationDataFetching,
		isTrainStationDataError,
		isSubmitLoading,
		handleOnSubmit,
		initialValues,
	};
};
