import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		borderTop: `2px solid ${theme.palette.background.default}`,
		padding: '32px 20px',
	},
	heading: {
		fontSize: '14px',
		fontWeight: 700,
		textAlign: 'end',
		marginBottom: 6,
	},
	logoWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '25px',
		height: '25px',
		backgroundColor: theme.palette.primary.main,
		colorAdjust: 'exact',
		borderRadius: '10px',
	},
	icon: {
		width: '15px',
		height: '15px',
	},
	titleWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginBottom: 6,
		gap: 12,
	},
	contactWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: 10,
	},
	contactItem: {
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	},
}));
