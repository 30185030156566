import { useQuery } from 'react-query';

// Import utils and helpers
import services from 'services/services';
import { CAR_MODELS_QUERY } from 'helpers';

export const useVehicleModelList = ({ manufacturerUuid }) => {
	const path = '/v2/supplier/vehicle/model/list';

	const body = {
		vehicle_maker: {
			uuid: manufacturerUuid,
		},
	};

	const enabled = !!manufacturerUuid && manufacturerUuid !== 'all';

	const { data, isFetching, isError } = useQuery(
		[CAR_MODELS_QUERY, JSON.stringify(body)],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false, cacheTime: 0, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
