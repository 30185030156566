import React from 'react';
import { func, object } from 'prop-types';
import { Button, TableCell } from '@material-ui/core';

export const SelectPlateTableCell = ({ cell, onSelectPlate }) => {
	const {
		plate_number: plate,
		uuid,
		miles_usage: milesUsage,
	} = cell.row.original;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Button
				variant="text"
				onClick={() => onSelectPlate({ plate, uuid, milesUsage })}
			>
				{plate}
			</Button>
		</TableCell>
	);
};

SelectPlateTableCell.propTypes = {
	cell: object.isRequired,
	onSelectPlate: func.isRequired,
};
