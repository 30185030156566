import React from 'react';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import utilities
import { fetchDriversLicenseList } from 'store/actions';

// Import components
import { TableBodyRow } from './components';

// Import styles
import { TableRPC } from 'components/utilities';

export const DriverLicenseTable = () => (
	<TableRPC
		columnsAccessor={COLUMNS_ACCESSORS}
		columns={columns}
		tableBodyRow={TableBodyRow}
		fetchDataTableAction={fetchDriversLicenseList}
	/>
);
