import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';
import i18next from 'i18next';

import { AR } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: theme.spacing(3),

		'& .MuiTableCell-body': {
			fontSize: '13px',
		},
		'& .MuiTableCell-root': {
			maxWidth: '200px',
		},
	},
	tableHeader: {
		color: theme.palette.text.secondary,
		letterSpacing: i18next.language === AR ? 0 : 1.3,
		fontSize: 12,
		fontWeight: 700,
		textTransform: 'uppercase',
		width: '100%',
	},
	content: {
		position: 'relative',
		padding: 0,
	},
	inner: {
		minWidth: 1050,
		minHeight: 200,
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		marginRight: theme.spacing(2),
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 999,
		background: alpha(theme.palette.background.default, 0.5),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	noData: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: '20px',
	},
	alignRight: {
		textAlign: 'right',
	},
	alignLeft: {
		textAlign: 'left',
	},
	title: {
		margin: '20px 0 30px 30px',
		fontSize: '20px',
	},
}));
