import React from 'react';
import { array, string, bool, number } from 'prop-types';
import { Field } from 'react-final-form';
import { Grid, MenuItem, CircularProgress } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { capitalize } from 'helpers';

// Import components
import { SelectFieldAdapter } from 'components/elements';

export const CarType = ({
	showAllOption = false,
	required = false,
	disabled = false,
	isLoading,
	types,
	name,
	label,
	md = 12,
	xs = 12,
}) => {
	const { t } = useTranslations();

	if (isLoading) {
		return (
			<Grid item md={md} xs={xs}>
				<CircularProgress size={27} />
			</Grid>
		);
	}

	return (
		<Grid item md={6} xs={12}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				type="select"
				label={t(label)}
				name={name}
				margin="dense"
				variant="outlined"
				disabled={disabled}
				required={required}
			>
				{showAllOption && <MenuItem value="all">{t('common.all')}</MenuItem>}
				{types.map(({ uuid, type }) => (
					<MenuItem key={uuid} value={uuid}>
						{capitalize(type)}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

CarType.propTypes = {
	types: array.isRequired,
	label: string.isRequired,
	name: string.isRequired,
	showAllOption: bool,
	isLoading: bool,
	required: bool,
	disabled: bool,
	md: number,
	xs: number,
};
