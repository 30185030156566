export const getAdditionalInfoFields = ({
	showAssignedDriverAdditional,
	showDeliveryStatusAdditional,
}) => {
	let fields = [
		'customer_nationality',
		'gender',
		'license_number',
		'customer_email',
		'customer_no_booking',
		'office_city',
		'company_name',
		'vehicle',
		'respond_time',
		'drop_date',
		'updated_at',
		'response_time',
		'pick_location',
		'drop_location',
		'payment_method',
		'total_base_nett_price',
		'total_discounted_gross_price',
		'approved',
		'admin_comment',
		'office_rating',
		'rating_reason',
		'compensated',
		'settlement_status',
	];

	showAssignedDriverAdditional && fields.push('assigned_driver');
	showDeliveryStatusAdditional && fields.push('delivery_status');

	return fields;
};
