import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';
import { useImageWrapper } from './useImageWrapper';

export const ImageWrapper = () => {
	const classes = useStyles();

	const { handleOnError, onLoad, altText, image } = useImageWrapper();

	return (
		<Grid container item xs={12} justifyContent="center" alignItems="center">
			<Box
				onError={handleOnError}
				onLoad={onLoad}
				className={classes.image}
				component="img"
				src={image}
				alt={altText}
			/>
		</Grid>
	);
};
