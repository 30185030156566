import { useState, useEffect } from 'react';
import usePrevious from '@rooks/use-previous';
import { useFormState } from 'react-final-form';

const useDeliveryOptionField = () => {
	const [isMapVisible, setIsMapVisible] = useState(false);

	const {
		values: {
			options: { delivery },
		},
	} = useFormState();

	const prevDelivery = usePrevious(delivery);

	const toggleMapVisible = () => setIsMapVisible((prev) => !prev);

	useEffect(() => {
		if (delivery && prevDelivery === false) {
			setIsMapVisible(true);
		}
	}, [delivery, prevDelivery]);

	return {
		isMapVisible,
		toggleMapVisible,
	};
};

export default useDeliveryOptionField;
