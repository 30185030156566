import { format } from 'date-fns';

// Import helpers
import { BOOKING_REVENUE_REPORT_STATUS, DEFAULT_DATE_FORMAT } from 'helpers';

export const initialValues = {
	created_at: {
		from: null,
		until: null,
	},
	pick_up_at: {
		from: null,
		until: null,
	},
	drop_off_at: {
		from: null,
		until: null,
	},
	extended_at: {
		from: null,
		until: null,
	},
	paid_at: {
		from: null,
		until: null,
	},
	companies: ['all'],
	cities: ['all'],
	service_type: 'all',
	is_extension: 'all',
	booking_status: 'all',
	report_type: '',
};

const convertArrays = ({ key, values }) =>
	values.reduce((acc, value, index) => {
		if (value === 'all') {
			return { ...acc, [key]: null };
		} else {
			return { ...acc, [`${key}[${index}]`]: value };
		}
	}, {});

const formatDate = (date) => (date ? format(date, DEFAULT_DATE_FORMAT) : null);

const convertDates = ({ keys, values }) =>
	keys.reduce((acc, key) => {
		return {
			...acc,
			[`${key}[from]`]: formatDate(values[key].from),
			[`${key}[until]`]: formatDate(values[key].until),
		};
	}, {});

export const formatValues = (values) => ({
	...convertDates({
		keys: ['created_at', 'paid_at', 'pick_up_at', 'drop_off_at', 'extended_at'],
		values,
	}),
	...convertArrays({ key: 'company_uuids', values: values.companies }),
	...convertArrays({ key: 'city_uuids', values: values.cities }),
	cities: values.cities?.[0] === 'all' ? null : values.cities,
	companies: values.companies?.[0] === 'all' ? null : values.companies,
	service_type: values.service_type === 'all' ? null : values.service_type,
	is_extension:
		values.is_extension === 'all' ? null : values.is_extension ? 1 : 0,
	booking_status:
		values.booking_status === 'all' ? null : values.booking_status,
});

export const bookingStates = Object.values(BOOKING_REVENUE_REPORT_STATUS);
