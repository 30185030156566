import React from 'react';

// Import helpers
import {
	companyManagerColumns,
	COLUMNS_ACCESSORS,
	adminColumns,
} from './columns';
import { TOOLBAR_CONTENT } from './helpers';

// Import components
import { AdminTableBodyRow, CompanyManagerTableBodyRow } from './components';

// Import utils
import { TableRPC, useProfile } from 'components/utilities';

// Import store
import { fetchLeasingCancellation } from 'store/actions';

export const LeasingCancellation = () => {
	const { isAdmin } = useProfile();
	const defaultParams = '?columns[52][search][value]=review';

	return (
		<TableRPC
			fetchDataTableAction={fetchLeasingCancellation}
			columnsAccessor={COLUMNS_ACCESSORS}
			toolBarContent={TOOLBAR_CONTENT}
			tableBodyRow={isAdmin ? AdminTableBodyRow : CompanyManagerTableBodyRow}
			columns={isAdmin ? adminColumns : companyManagerColumns}
			defaultQueryParams={defaultParams}
		/>
	);
};
