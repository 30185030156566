import React from 'react';
import { Grid } from '@material-ui/core';
import { array } from 'prop-types';

// Import utils
import { useCommonFields, useMediaQuerySizes } from 'components/utilities';

export const CommonSection = ({ fields }) => {
	const { renderFields } = useCommonFields();
	const { isTabletSize } = useMediaQuerySizes();

	return (
		<Grid container spacing={isTabletSize ? 1 : 4}>
			{fields.map(renderFields)}
		</Grid>
	);
};

CommonSection.propTypes = {
	fields: array,
};
