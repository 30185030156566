import * as types from 'store/types';
import services from 'services/services';
import { fetchKiosksFormResourcesUrl, submitKiosksFormUrl } from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import { INITIAL_VALUES } from 'views/Kiosk/EditKiosk/helpers';

// ******************** Fetch kiosks form ********************
export const fetchKiosksFormResources = ({ itemId, cancelToken }) => async (
	dispatch
) => {
	try {
		dispatch({ type: types.KIOSKS_FORM_FETCH_DATA_LOADING });

		let result;
		if (itemId) {
			const {
				data: {
					data: { details, airports },
				},
			} = await services.get(fetchKiosksFormResourcesUrl(itemId), cancelToken);

			const kioskAirportId = airports[Object.keys(airports)[0]].uuid;
			const terminals = airports[Object.keys(airports)[0]].terminals.map(
				(terminal) => terminal.uuid
			);

			result = {
				details,
				kioskAirportId,
				airport: {
					terminals,
				},
			};
		} else {
			result = Object.assign({}, INITIAL_VALUES);
		}
		dispatch({
			type: types.KIOSKS_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: result || {},
			},
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.KIOSKS_FORM_FETCH_DATA_ERROR,
		});
	}
};

// ******************** Submit kiosks form ********************
export const submitKiosksForm = ({
	values,
	itemId,
	isEdit = true,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		await services.post(submitKiosksFormUrl(isEdit, itemId), values);
		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
