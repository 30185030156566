import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

if (process.env.NODE_ENV !== 'development') {
	// eslint-disable-next-line
	console.log = () => {};
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
