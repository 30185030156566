import { useState } from 'react';

import { useForm } from 'react-final-form';

// Import utilities
import {
	useManufacturers,
	useAutoVehicleModelCollection,
	useAutoVehicleMakerCollection,
} from 'queries';

// Import translations
import { useTranslations } from 'components/utilities';

const useBaseDetails = (initialValues) => {
	const { values, inputState } = initialValues;

	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	const { change } = useForm();

	const [value, setValue] = useState(inputState);
	const [inputModelValue, setInputModelValue] = useState(inputState);
	const [inputMakerValue, setInputMakerValue] = useState(inputState);

	const getMakerId = (makers, maker_uuid) => {
		return makers.find((maker) => maker.uuid === maker_uuid)?.auto.maker_id;
	};

	const setId = (array, inputName, fieldName) => {
		const id = array.find(({ name }) => name === inputName)?.id;
		change(fieldName, id);
	};

	const handleModelOnChange = (_, newValue) => {
		setValue(newValue);
		setId(models, newValue, 'auto.model_id');
		change('model_name.en', newValue);
	};

	const handleMakerOnChange = (_, newValue) => {
		setValue(newValue);
		setId(makers, newValue, 'auto.maker_id');
		change('name.en', newValue);
	};

	const handleOnModelInputChange = (_, newValue) => {
		setInputModelValue(newValue);
	};

	const handleOnMakerInputChange = (_, newValue) => {
		setInputMakerValue(newValue);
	};

	const { data: manufacturers, isFetching } = useManufacturers();
	const { data: models } = useAutoVehicleModelCollection({
		name: inputModelValue,
		maker_id: getMakerId(manufacturers, values.maker_uuid),
	});

	const { data: makers } = useAutoVehicleMakerCollection({
		name: inputMakerValue,
	});

	return {
		t,
		lng,
		value,
		models,
		makers,
		isFetching,
		manufacturers,
		inputModelValue,
		inputMakerValue,
		handleModelOnChange,
		handleMakerOnChange,
		handleOnModelInputChange,
		handleOnMakerInputChange,
	};
};

export default useBaseDetails;
