export const createCellRenderer = ({
	horizontalColumns,
	verticalColumns,
	renderLabelCell,
	renderHorizontalHeaderCell,
	renderVerticalHeaderCell,
	renderBodyCell,
	data,
}) => {
	return ({ columnIndex, rowIndex, key, style }) => {
		if (rowIndex === 0 && columnIndex === 0) {
			return renderLabelCell({ key, style });
		}

		const realColumnIndex = columnIndex - 1;
		const realRowIndex = rowIndex - 1;

		const horizontalColumn = horizontalColumns[realColumnIndex];
		const verticalColumn = verticalColumns[realRowIndex];

		if (rowIndex === 0) {
			const column = horizontalColumn;

			return renderHorizontalHeaderCell({
				key,
				style,
				column,
			});
		}

		if (columnIndex === 0) {
			const column = verticalColumn;

			return renderVerticalHeaderCell({
				key,
				style,
				column,
			});
		}

		let cellData;

		try {
			cellData = data[realRowIndex][realColumnIndex];
		} catch {
			cellData = null;
		}

		return renderBodyCell({
			key,
			style,
			data: cellData,
			columnIndex: realColumnIndex,
			rowIndex: realRowIndex,
			horizontalColumn,
			verticalColumn,
		});
	};
};
