import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import {
	fetchDiscountFormResources,
	submitEditDiscountForm,
} from 'store/actions';

// Import components
import { FormRPC } from 'components/utilities';
import { DiscountDetails } from '../components';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { replaceOrganizationDiscountsUrl } from 'helpers';

// Import styles
import useStyles from '../styles';

const EditDisocunt = () => {
	const classes = useStyles();
	const { organizationId } = useParams();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchDiscountFormResources}
				submitFormAction={submitEditDiscountForm}
				store={(store) => store.discounts.form}
				goBackPath={replaceOrganizationDiscountsUrl(organizationId)}
				isCardLayout={false}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<DiscountDetails section="discount" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default EditDisocunt;
