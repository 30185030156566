import React from 'react';
import { string } from 'prop-types';
import { Form } from 'react-final-form';
import { IconButton, Dialog, DialogContent, Tooltip } from '@material-ui/core';

// Import assets
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';

// Import utils and helpers
import { useToggle, useTranslations } from 'components/utilities';
import { useCustomerLimitDialog } from './useCustomerLimitDialog';
import { yupValidator } from 'helpers';
import { validationSchema } from './validation';

export const CustomerLimitDialog = ({ userUuid }) => {
	const { on: isOpen, toggle } = useToggle();
	const { maximumCustomerLimitAmount, handleOnSubmit, isButtonLoading } =
		useCustomerLimitDialog({ userUuid, toggle });
	const { t } = useTranslations();

	return (
		<>
			<Dialog onClose={toggle} open={isOpen} fullWidth>
				<ClosableDialogTitle
					onClose={toggle}
					title={t('b2b.corporate.overview.table.tooltips.set_customer_limit')}
				/>
				<DialogContent>
					<Form
						onSubmit={handleOnSubmit}
						validate={(values) =>
							yupValidator({
								values,
								validationSchema: validationSchema(maximumCustomerLimitAmount),
							})
						}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<FormContent
									toggle={toggle}
									isButtonLoading={isButtonLoading}
								/>
							</form>
						)}
					></Form>
				</DialogContent>
			</Dialog>
			<Tooltip
				title={t('b2b.corporate.overview.table.tooltips.set_customer_limit')}
			>
				<IconButton onClick={toggle}>
					<AccountBalanceWalletIcon color="primary" />
				</IconButton>
			</Tooltip>
		</>
	);
};

CustomerLimitDialog.propTypes = {
	userUuid: string,
};
