import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bool } from 'prop-types';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';
import { FIELDS } from './helpers';

// Import components
import { ButtonProgress } from 'components/elements';

// Import styles
import { useStyles } from './FormContent.styles';

// Import store
import { signOut } from 'store/actions';
import CheckboxFieldAndModal from 'views/ResetPassword/form/CheckboxFieldAndModal';

export const FormContent = ({ isLoading }) => {
	const { renderFields } = useCommonFields();
	const { submitting } = useFormState();
	const { t } = useTranslations();
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();

	const handleOnSignOut = async () => {
		await signOut()(dispatch);
		history.push(URLS.signInUrl);
	};

	return (
		<Grid container justifyContent="flex-start" spacing={2}>
			<Grid item xs={12}>
				<Typography className={classes.title} variant="h2">
					{t('set_password.form.title')}
				</Typography>
			</Grid>
			{FIELDS.map(renderFields)}
			<Grid item xs>
				<CheckboxFieldAndModal submitting={submitting} />
			</Grid>
			<Grid item xs={12}>
				<ButtonProgress
					isLoading={isLoading}
					disabled={isLoading}
					variant="contained"
					color="primary"
					type="submit"
					size="large"
					fullWidth
				>
					{t('common.buttons.submit')}
				</ButtonProgress>
			</Grid>
			<Grid item xs={12}>
				<Typography color="textSecondary" variant="body1">
					{t('sign_up.form.have_account')}
					<Button className={classes.buttonLogout} onClick={handleOnSignOut}>
						{t('common.buttons.sign_in')}
					</Button>
				</Typography>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isLoading: bool,
};
