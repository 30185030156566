import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// Import styles
import useStyles from './styles';
import { TimePicker } from '../../../components';

const BusinessHourItem = ({ icon: Icon, value, onChange, disabled }) => {
	const classes = useStyles();

	return (
		<Box className={classes.box}>
			<Icon className={classes.icon} fontSize="small" />

			<TimePicker
				value={value}
				onChange={onChange}
				disabled={disabled}
				fullWidth
			/>
		</Box>
	);
};

BusinessHourItem.propTypes = {
	icon: PropTypes.elementType.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default BusinessHourItem;
