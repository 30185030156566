import { useEffect, useState } from 'react';
import services from 'services/services';
import { useDebouncedCallback } from 'use-debounce/lib';

// Import utilities
import { useTableContextProvider } from 'components/context';
import { useMessage, useTranslations } from 'components/utilities';
import { useCarsAvailability } from 'views/CarsAvailability/useCarsAvailability';

// Import helpers
import { activateCarsUrl, updateOfficeCarQuantityUrl } from 'store/paths';
import { BUTTON_ACTION_TYPES } from 'helpers';
import { getUniqueCar } from './helpers';

export const useAvailableCarTableCell = ({ cell }) => {
	const { INCREMENT } = BUTTON_ACTION_TYPES;

	const { filters, loading, fetchData } = useTableContextProvider();

	const { t } = useTranslations();

	const { message } = useMessage();

	const { userCompanyUuid } = useCarsAvailability();

	const companyUuid = filters?.company_uuid || userCompanyUuid;

	const { cars } = cell.row.original;

	const { vehicle_uuid: vehicleUuid } = cell.column;

	const cellData = getUniqueCar(cars, vehicleUuid) || {};

	const { quantity, is_active, car_uuid, cars_uuids } = cellData;

	const [currentQuantity, setCurrentQuantity] = useState(quantity);

	const isCarActive = !!+is_active;

	const isCellVisible = typeof quantity !== 'undefined';

	const isOfficeLevel = !!companyUuid && companyUuid !== 'all' && !loading;

	const isDecrementButtonDisabled = !currentQuantity;

	const updateOfficeCarQuantity = useDebouncedCallback(async () => {
		try {
			const body = {
				quantity: currentQuantity,
			};

			await services.put(updateOfficeCarQuantityUrl(car_uuid), body);
			await fetchData();
		} catch (error) {
			setCurrentQuantity(quantity);
			message.error(t('common.messages.error_message'));
		}
	}, 500);

	const handleOnActivate = async () => {
		const body = {
			cars: cars_uuids,
		};

		try {
			await services.post(activateCarsUrl(isCarActive), body);
			await fetchData();
		} catch {
			message.error(t('common.messages.error_message'));
		}
	};

	const changeQuantity = (type) => {
		if (isCellVisible) {
			type === INCREMENT
				? setCurrentQuantity((prev) => +prev + 1)
				: setCurrentQuantity((prev) => (prev > 0 ? +prev - 1 : 0));
			updateOfficeCarQuantity();
		}
	};

	useEffect(() => {
		if (isCellVisible && isOfficeLevel) {
			setCurrentQuantity(quantity);
		}
	}, [quantity, isOfficeLevel, isCellVisible]);

	return {
		handleOnActivate,
		changeQuantity,
		quantity,
		isDecrementButtonDisabled,
		isOfficeLevel,
		isCellVisible,
		isCarActive,
	};
};
