import React from 'react';
import { object } from 'prop-types';
import { useParams } from 'react-router';
import { updateActiveTerminal, deleteTerminal } from 'store/actions';
import { TableCell, TableRow } from '@material-ui/core';

// Import components
import {
	EditTableCell,
	ActiveTableCell,
	DeleteTableCell,
} from 'components/elements';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import {
	replaceEditAirportTerminalUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';

const TableBodyRow = ({ row }) => {
	const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
	const { ACTIVE } = COLUMNS_ACCESSORS;
	const { id: airportId } = useParams();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case EDIT: {
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditAirportTerminalUrl(
							airportId,
							cell.row.original.uuid
						)}
					/>
				);
			}
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveTerminal}
						cell={cell}
					/>
				);
			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteTerminal}
						cell={cell}
					/>
				);
			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
