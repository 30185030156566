export const getTopics = ({ data }) => {
	const { delivery, pick_date, pick_time } = data;
	const { drop_delivery, pick_up_delivery } = delivery || {};

	const topics = [];

	const isDeliveryPassed = new Date(`${pick_date} ${pick_time}`) < new Date();

	if (
		drop_delivery.has_driver_assigned &&
		drop_delivery.is_available &&
		isDeliveryPassed
	) {
		return ['return_only'];
	}

	if (pick_up_delivery.is_available) {
		topics.push('delivery');
	}

	if (drop_delivery.is_available) {
		topics.push('return');
	}

	return topics;
};
