import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { object } from 'prop-types';
import { format } from 'date-fns';

// Import helpers
import { DLY_DATE_FORMAT, replacePreviewBookingUrl } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { LinkTableCell, TwoRowsTableCell } from 'components/elements';
import { ValidationActionTableCell } from '../../components';

const { PICK_DATE, ACTION, BOOKING_ID, CUSTOMER_NAME } = COLUMNS_ACCESSORS;

export const CompanyManagerTableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			pick_date: pickDate,
			pick_time: pickTime,
			customer_name: customerName,
			customer_phone: customerPhone,
			uuid: bookingUuid,
		} = cell.row.original;

		const formattedPickDate = format(new Date(pickDate), DLY_DATE_FORMAT);

		switch (cell.column.id) {
			case PICK_DATE:
				return (
					<TwoRowsTableCell
						title={formattedPickDate}
						key={cell.column.id}
						subtitle={pickTime}
						cell={cell}
					/>
				);

			case CUSTOMER_NAME:
				return (
					<TwoRowsTableCell
						subtitle={customerPhone}
						key={cell.column.id}
						title={customerName}
						cell={cell}
					/>
				);

			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(bookingUuid)}
						minWidth={150}
					>
						{cell.row.original.individual_number}
					</LinkTableCell>
				);

			case ACTION:
				return <ValidationActionTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

CompanyManagerTableBodyRow.propTypes = {
	row: object.isRequired,
};
