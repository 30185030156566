import { useSelector } from 'react-redux';

// Import utilities
import { useToggle } from 'components/utilities';

// Import helpers
import { previewBookingSelector } from 'store/selectors';

export const useCompensationHistoryDialog = () => {
	const { on, toggle } = useToggle();

	const { can_add_admin_manual_compensation: isAddButtonVisible } = useSelector(
		previewBookingSelector
	);

	return {
		toggle,
		on,
		isAddButtonVisible,
	};
};
