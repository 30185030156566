import * as yup from 'yup';

export const validationSchema = yup.object({
	transaction_amount: yup.number().notRequired(),
	total_amount: yup
		.number()
		.required('Required')
		.test(
			'equal',
			'Value of this field should the equal transaction amount',
			function (value) {
				const ref = yup.ref('transaction_amount');
				return value === this.resolve(ref);
			}
		),
});
