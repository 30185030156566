import { useFormState } from 'react-final-form';

// Import utils and helpers
import { useCompanies } from 'queries';
import { useTranslations } from 'components/utilities';
import { FIELD_TYPES } from 'helpers';

export const useCompaniesList = () => {
	const {
		i18n: { language: lang },
		t,
	} = useTranslations();

	const { values } = useFormState();

	const { data: companies, isFetching } = useCompanies();

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name?.[lang];

	const COMPANY_FIELD = {
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
		currentMultiSelectValues: values?.details.company_uuid,
		labelExtractor: ({ name }) => name?.[lang],
		keyExtractor: ({ uuid }) => uuid,
		searchExtractor: 'children[1]',
		multiSelectLabelExtractor,
		showAllOption: true,
		options: companies,
		label: t('sliders.form.companies.choose_companies'),
		name: 'details.company_uuid',
		required: true,
	};
	return {
		isFetching,
		COMPANY_FIELD,
	};
};
