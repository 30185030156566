import _ from 'lodash';

export const addSupRowsToParent = ({
	allExpandedData,
	data,
	setAllExpandedData,
	setSelectedChildren,
}) => {
	const allExpandedDataCopy = _.cloneDeep(allExpandedData);
	allExpandedDataCopy.subRows = data;
	setAllExpandedData(allExpandedDataCopy);
	setSelectedChildren(
		data.filter((row) => !row.is_settled && row.conditions_met)
	);
};
