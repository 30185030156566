import React from 'react';
import { array, bool } from 'prop-types';
import {
	Box,
	CircularProgress,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { columns } from './helpers';

// Import components
import { ButtonProgress } from 'components/elements';
import { ListRow } from '..';

// Import styles
import { useStyles } from '../../ExternalPartnersCard.styles';

export const FormContent = ({ offices, isFetching, isButtonLoading }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	if (isFetching) {
		return (
			<Box className={classes.circularBarContainer}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					{columns.map((name, index) => (
						<Grid item key={index} xs={4}>
							<Typography variant="h4">{t(name)}</Typography>
						</Grid>
					))}
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid item xs={12}>
				<Grid container direction="column">
					{offices.map((office) => (
						<Grid item key={office.uuid} className={classes.listRow}>
							<ListRow officeData={office} />
						</Grid>
					))}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<ButtonProgress
							circularProgressSize={24}
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
							isLoading={isButtonLoading}
							disabled={isButtonLoading}
						>
							{t('common.buttons.save')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isButtonLoading: bool,
	isFetching: bool,
	offices: array,
};
