// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

// Import components
import { Filters } from './components';

export const TOOLBAR_CONTENT = [
	{
		customKey: 'filters',
		component: Filters,
		variant: TOOLBAR_VARIANTS.CUSTOM,
	},
];
