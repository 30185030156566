import React, { useState } from 'react';
import { DialogActions, Button } from '@material-ui/core';
import { bool, array, func, string } from 'prop-types';
import SaveIcon from '@material-ui/icons/Save';
import { updateManagerAssignOffices } from 'store/actions';
import { useDispatch } from 'react-redux';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress } from 'components/elements';
import { useTableContextProvider } from 'components/context';

// Import styles
import useStyles from './styles';

const Actions = ({
	isLoading,
	isDataExist,
	checkedOffices,
	dialogClose,
	userId,
}) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const dispatch = useDispatch();
	const { fetchData } = useTableContextProvider();
	const [isDataSending, setDataSending] = useState(false);
	const isDisabled = isDataSending || isLoading || !isDataExist;

	const handleUpdateOffices = async () => {
		setDataSending(true);
		const offices = checkedOffices.map(({ uuid }) => ({ uuid }));
		await updateManagerAssignOffices({ userId, offices })(dispatch);
		setDataSending(false);
		dialogClose();
		fetchData();
	};

	return (
		<DialogActions>
			<Button disabled={isLoading} onClick={dialogClose} color="primary">
				{t('table.modal.delete.btn_cancel')}
			</Button>

			<ButtonProgress
				onClick={handleUpdateOffices}
				color="primary"
				className={classes.wrapper}
				disabled={isDisabled}
			>
				<SaveIcon className={classes.icon} fontSize="small" />
				{t('common.buttons.save')}
			</ButtonProgress>
		</DialogActions>
	);
};

Actions.propTypes = {
	isLoading: bool.isRequired,
	isDataExist: bool.isRequired,
	checkedOffices: array.isRequired,
	dialogClose: func.isRequired,
	userId: string.isRequired,
};

export default Actions;
