// Import helpers
import { BOOKING_COMPENSATION_STATES, LOCATIONS } from 'helpers';

export const getDialogData = ({ compensation, sectionName, location }) => {
	const addCompensationData = {
		buttonTitle: `${sectionName}.button_add_compensation`,
		dialogTitle: `${sectionName}.dialog_title_add_compensation`,
	};

	if (compensation) {
		if (compensation.status === BOOKING_COMPENSATION_STATES.INVALID) {
			return {
				buttonTitle: `${sectionName}.button_review_again`,
				dialogTitle: `${sectionName}.dialog_title_review_again`,
			};
		} else if (location === LOCATIONS.BOOKING_PREVIEW) {
			return addCompensationData;
		} else {
			return {
				buttonTitle: `${sectionName}.button_edit_compensation`,
				dialogTitle: `${sectionName}.dialog_title_edit_compensation`,
			};
		}
	} else {
		return addCompensationData;
	}
};
