import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

// Import store
import { fetchCompanyOffices } from 'store/actions';

const multiSelectLabelExtractor = (options, currentUuid) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.name[i18next.language];

export const getFields = ({
	phonePrefixOptions,
	offices,
	companies,
	isAdmin,
	values,
}) => [
	{
		type: FIELD_TYPES.TEXT,
		name: 'details.full_name',
		label: 'users.form.details.full_name',
		required: true,
	},
	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
		name: 'company_uuids',
		label: 'users.form.details.assign_to_company',
		options: companies,
		currentMultiSelectValues: values.company_uuids,
		searchExtractor: 'children[1]',
		multiSelectLabelExtractor,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[i18next.language],
		required: true,
		isHidden: !isAdmin,
	},
	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
		name: 'office_uuids',
		label: 'users.form.details.assign_to_office',
		options: offices,
		currentMultiSelectValues: values?.office_uuids,
		searchExtractor: 'children[1]',
		multiSelectLabelExtractor,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[i18next.language],
		required: true,
		disabled: !values.company_uuids.length || !offices.length,
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'users.form.details.phone_prefix',
		name: 'details.phone_prefix',
		md: 3,
		required: true,
		options: phonePrefixOptions,
		keyExtractor: (row) => row,
		labelExtractor: (row) => row,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'details.phone',
		label: 'users.form.details.phone',
		md: 9,
	},
	{
		type: FIELD_TYPES.DATE,
		name: 'license_expiry_date',
		label: 'users.form.details.license_expiry_date',
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'id_number',
		label: 'users.form.details.id_number',
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'users.form.details.email',
		name: 'details.email',
		required: true,
	},
	{
		type: FIELD_TYPES.PASSWORD,
		label: 'users.form.details.password',
		name: 'password',
		required: false,
		md: 6,
	},
	{
		type: FIELD_TYPES.PASSWORD,
		label: 'users.form.details.password_confirmation',
		name: 'password_confirmation',
		required: false,
		md: 6,
	},
];

export const fetchAllCompanyOffices = async ({
	errorCallback,
	companyUuids,
	setLoading,
}) => {
	setLoading(true);
	const companyOffices = [];
	const promises = await companyUuids.map(async (companyUuid) => {
		const offices = await fetchCompanyOffices({ companyUuid, errorCallback });
		companyOffices.push({ companyUuid, offices });
		return companyOffices;
	});

	const data = await Promise.all(promises);

	setLoading(false);

	return data[0];
};
