import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import store
import {
	fetchCancellationReasonsFormResources,
	submitCancellationReasonsForm,
} from 'store/actions';

// Import components
import { FormRPC } from 'components/utilities';
import { ReasonDetails } from '../components';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import styles
import { useStyles } from './styles';

export const CancellationReasonForm = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchCancellationReasonsFormResources}
				submitFormAction={submitCancellationReasonsForm}
				isCardLayout={false}
				goBackPath={URLS.cancellationReasonsUrl}
				store={(store) => store.cancellationReasons.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<ReasonDetails section="details" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
