import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

// Import utilities
import { useInitiateContractProvider } from '../../context';
import { useTranslations } from 'components/utilities';

// Import components
import { AlertMessage } from 'components/elements';

// Import styles
import { useStyles } from 'theme/styles/eContract.styles';

// Import helpers
import { getStep } from './helpers';

export const Steps = () => {
	const { step, isFetching, isError, allowInitiateContract, error } =
		useInitiateContractProvider();

	const { t } = useTranslations();

	const classes = useStyles();

	if (isFetching) {
		return (
			<Box className={clsx(classes.container, classes.centerContainer)}>
				<CircularProgress />
			</Box>
		);
	}

	if (isError) {
		return (
			<Box className={classes.container}>
				<AlertMessage
					description={error}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	if (!allowInitiateContract) {
		return (
			<Box className={classes.container}>
				<AlertMessage
					description={t('contract.create_contract_form.no_access_message')}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	return getStep(step);
};
