import { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import utilities
import { useTranslations } from 'components/utilities';
import { get } from 'lodash';

const useAutocompleteSelect = ({ name }) => {
	const { change } = useForm();

	const { t } = useTranslations();

	const { values } = useFormState();

	const initialValue = get(values, name) || null;
	const [selectedOption, setSelectedOption] = useState(initialValue);

	const handleOnChange = (_, newValue) => {
		setSelectedOption(newValue);
		change(name, newValue);
	};

	const getOptionLabel = (option) => `${option} ${t('cars.form.ratings.days')}`;
	const getOptionSelected = (option, value) => option === value;

	return {
		getOptionSelected,
		getOptionLabel,
		handleOnChange,
		selectedOption,
	};
};

export default useAutocompleteSelect;
