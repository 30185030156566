import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

export const CompensationFormulaCell = ({ cell }) => {
	const { t } = useTranslations();

	const { compensation_formula } = cell.row.original;

	return (
		<TableCell {...cell.getCellProps()}>
			{compensation_formula
				? t(`rating_reasons.compensation_types.${compensation_formula}`)
				: ''}
		</TableCell>
	);
};

CompensationFormulaCell.propTypes = {
	cell: object.isRequired,
};
