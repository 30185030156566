// Import helpers
import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE, ACTIVE } = TABLE_ACTIONS_ACCESSORS;

export const columns = [
	{
		Header: 'users.table.headers.name',
		accessor: 'user_full_name',
	},
	{
		Header: 'users.table.headers.phone_number',
		accessor: 'user_phone',
		disableSortBy: true,
	},
	{
		Header: 'users.table.headers.company_name',
		accessor: 'company_name',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
