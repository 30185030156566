import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const PlusIcon = (props) => (
	<SvgIcon
		width="11"
		height="12"
		viewBox="0 0 11 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.53442 3.07654V8.92315"
			stroke="currentColor"
			strokeWidth="0.939634"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.61133 6H8.45794"
			stroke="currentColor"
			strokeWidth="0.939634"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
