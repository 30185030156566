// ********************  table  ********************

export const fetchScheduledBookingsUrl = (queryParams) =>
	`v2/bookings/scheduled${queryParams}`;

export const updateStatusScheduleBookingUrl = (id, isAccepted) =>
	`/v2/booking/${id}/${isAccepted ? 'reject' : 'accept'}`;

export const updateDoneLateDeliveryBookingUrl = (deliveryUuid) =>
	`/v2/delivery/${deliveryUuid}/late-delivery-done`;
