import React from 'react';
import { string } from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './CloseButton.styles';

export const CloseButton = ({ className, ...restProps }) => {
	const classes = useStyles();

	return (
		<IconButton
			className={clsx([classes.closeButton, className])}
			data-testid="close_button"
			{...restProps}
		>
			<CloseIcon color="primary" className={classes.closeIcon} />
		</IconButton>
	);
};

CloseButton.propTypes = {
	className: string,
};
