import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';
import { usePageSlug } from 'queries';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { BASE_DETAILS_FIELDS } from './helpers';

//Import styles
import useStyles from '../../styles';

const BaseDetails = ({ className, section, ...rest }) => {
	const classes = useStyles();

	const {
		t,
		i18n: { language: lng },
	} = useTranslations();

	const { submitting } = useFormState();

	const { data: pageSlug, isFetching } = usePageSlug({ lng });

	return (
		<Card className={className} {...rest}>
			<CardHeader title={t('cms.pages.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{isFetching ? (
						<Grid item md={12} xs={12}>
							<Box className={classes.loader}>
								<CircularProgress size={35} />
							</Box>
						</Grid>
					) : (
						BASE_DETAILS_FIELDS.map(
							({ component, type, label, name, md, xs }) => (
								<Grid key={name} item md={md} xs={xs}>
									{type === 'select' ? (
										<Field
											fullWidth
											component={component}
											type={type}
											label={t(label)}
											name={`${section}.${name}`}
											margin="dense"
											variant="outlined"
											disabled={submitting}
											multiline
											required
										>
											{pageSlug.map((item) => (
												<MenuItem key={item} value={item}>
													{item}
												</MenuItem>
											))}
										</Field>
									) : (
										<Field
											fullWidth
											component={component}
											type={type}
											label={t(label)}
											name={`${section}.${name}`}
											margin="dense"
											variant="outlined"
											disabled={submitting}
											multiline
											required
										/>
									)}
								</Grid>
							)
						)
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

BaseDetails.propTypes = {
	className: string,
	section: string.isRequired,
};

export default BaseDetails;
