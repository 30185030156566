import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { Field, useFormState, useForm } from 'react-final-form';
import { Grid, Box, CircularProgress, MenuItem } from '@material-ui/core';

// Import utilities
import { useOrganizations } from 'queries';

// Import helpers
import { workplaceInfoFields } from './helpers';
import { EN, ORGANIZATION_SELECT_DEFAULT_VALUE } from 'helpers';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from '../../styles';

const WorkplaceInfoFields = ({ section }) => {
	const { submitting, values } = useFormState();
	const { change } = useForm();

	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const classes = useStyles();
	const { data: organizations, isFetching: isOrganizationsLoading } =
		useOrganizations();

	const isEnglish = lng === EN;

	useEffect(() => {
		if (!values.workplace.organization.uuid) {
			change(
				`workplace.organization.name.${lng}`,
				t('common.none').toLocaleLowerCase()
			);
		}
	}, [t, change, lng, values.workplace.organization.uuid]);

	// eslint-disable-next-line react/prop-types
	const renderField = ({ type, name, md, xs, label, component }) => {
		const fieldName = `${section}.${name}`;
		return (
			<Grid key={name} item md={md} xs={xs}>
				<Field
					fullWidth
					component={component}
					type={type}
					label={t(label)}
					name={fieldName}
					margin="dense"
					variant="outlined"
					disabled={submitting}
				>
					<MenuItem
						key={ORGANIZATION_SELECT_DEFAULT_VALUE}
						value={ORGANIZATION_SELECT_DEFAULT_VALUE}
					>
						{t('common.none')}
					</MenuItem>
					{organizations.map(renderMenuItem)}
				</Field>
			</Grid>
		);
	};

	// eslint-disable-next-line react/prop-types
	const renderMenuItem = ({ name, uuid }) => {
		// eslint-disable-next-line react/prop-types
		const itemName = isEnglish ? name.en : name.ar;
		return (
			<MenuItem key={uuid} value={itemName}>
				{itemName}
			</MenuItem>
		);
	};

	return (
		<>
			{!isOrganizationsLoading ? (
				workplaceInfoFields({ lng }).map(renderField)
			) : (
				<Grid item md={12} xs={12}>
					<Box className={classes.loader}>
						<CircularProgress size={35} />
					</Box>
				</Grid>
			)}
		</>
	);
};

WorkplaceInfoFields.propTypes = {
	section: string.isRequired,
};

export default WorkplaceInfoFields;
