import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	submitCustomerCompensationForm,
	updateDialogMessage,
} from 'store/actions';

// Import helpers
import { BOOKING_COMPENSATION_STATES, LOCATIONS } from 'helpers';
import { previewBookingSelector } from 'store/selectors';
import { INITIAL_VALUES } from './helpers';

const useMakeCompensationForm = ({ onClose, location, outerCompensation }) => {
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState(null);
	const { APPROVED, REVIEW } = BOOKING_COMPENSATION_STATES;
	const { pathname } = useLocation();
	const isBookingPreview = pathname.toLowerCase().includes('preview');
	const booking = useSelector(previewBookingSelector);

	const dispatch = useDispatch();

	const handleError = (error) => {
		setError(error);
		setIsError(true);
	};

	const getInitialValues = () => {
		if (location === LOCATIONS.BOOKING_PREVIEW) {
			return INITIAL_VALUES;
		} else {
			return outerCompensation || booking.compensation || INITIAL_VALUES;
		}
	};

	const initialValues = getInitialValues();

	const handleOnSubmit = async (values) => {
		const status = isBookingPreview ? REVIEW : APPROVED;
		const options = {
			values: { ...values, status },
			callbackSuccess: onClose,
			errorCallback: handleError,
			compensation: outerCompensation || booking?.compensation,
			location,
			booking,
		};

		updateDialogMessage('validation.table.modal.compensation_approved')(
			dispatch
		);

		await submitCustomerCompensationForm(options)(dispatch);
	};

	return { onSubmit: handleOnSubmit, isError, error, booking, initialValues };
};

export default useMakeCompensationForm;
