import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CONTRACT_BRANCHES_QUERY } from 'helpers';

export const useContractBranches = () => {
	const path = '/econtract/branches';

	const { data, isFetching, isError } = useQuery(
		[CONTRACT_BRANCHES_QUERY],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError };
};
