// Import helpers
import { FIELD_TYPES } from 'helpers';

export const CORPORATE_FIELDS = (handleOnFileUploader) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'b2b.corporate.form.corporate_name',
		name: 'name',
		md: 6,
	},
	{
		type: FIELD_TYPES.FILE_UPLOADER,
		label: 'b2b.corporate.form.corporate_logo',
		name: 'logo_url',
		handleOnFileUploader,
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.maximum_limit',
		name: 'total_limit_amount_gross',
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.vat_number',
		name: 'vat_number',
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.cr',
		name: 'commercial_registration_number',
		md: 6,
	},
];
