import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	button: {
		padding: 0,
		'&:hover': {
			backgroundColor: theme.palette.white,
			boxShadow: '0px 5px 20px 1px rgba(0,0,0,0.1) ',
		},
	},
	languageText: {
		[theme.breakpoints.down('md')]: {
			fontSize: 12,
		},
	},
}));

export default useStyles;
