import React from 'react';

// Import helpers
import { ADD_CORPORATE_AND_COORDINATOR_PANEL_STEPS } from 'helpers';

// Import components
import { AddCoordinatorStep, AddCorporateStep } from '../../components';

export const getFormStep = (step) => {
	switch (step) {
		case ADD_CORPORATE_AND_COORDINATOR_PANEL_STEPS.ADD_COORDINATOR:
			return <AddCoordinatorStep />;
		case ADD_CORPORATE_AND_COORDINATOR_PANEL_STEPS.ADD_CORPORATE:
			return <AddCorporateStep />;
		default:
			return null;
	}
};
