import React from 'react';
import { object, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import utils
import { useAssignmentStatus } from './useAssignmentStatus';

// Import styles
import { useStyles } from './AssignmentStatus.styles';
import { useTranslations } from 'components/utilities';

export const AssignmentStatus = ({ cell, deliveryType }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { assignDate, assignTime, driverName, lateAssignment, hoursLeftText } =
		useAssignmentStatus({
			deliveryType,
			cell,
		});

	const assignedLateClassName = lateAssignment && classes.assignedLate;

	return (
		<>
			{driverName && (
				<Typography className={clsx(classes.mb, assignedLateClassName)}>
					{t(`common.assigned${lateAssignment ? '_late' : ''}_to`)} {driverName}
				</Typography>
			)}

			{hoursLeftText && (
				<Typography className={clsx(classes.mb, classes.assignedLate)}>
					{hoursLeftText}
				</Typography>
			)}

			{assignDate && (
				<Grid container spacing={1}>
					<Grid item>
						<Typography className={clsx(assignedLateClassName)}>
							{t('common.at')}
						</Typography>
					</Grid>
					<Grid item>
						<Typography className={clsx(classes.mb, assignedLateClassName)}>
							{assignDate} {assignTime}
						</Typography>
					</Grid>
				</Grid>
			)}
		</>
	);
};

AssignmentStatus.propTypes = {
	cell: object.isRequired,
	deliveryType: string.isRequired,
};
