import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { COMPANY_COLLECTION_ACTIVE_QUERY } from 'helpers';

const useCompanyActiveCollection = () => {
	const path = 'v2/supplier/business/company/collection-active';

	const { data, isFetched } = useQuery(
		COMPANY_COLLECTION_ACTIVE_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetched };
};

export default useCompanyActiveCollection;
