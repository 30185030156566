export const INITIAL_VALUES = {
	title: {
		en: '',
		ar: '',
	},
	keywords: {
		en: '',
		ar: '',
	},
	description: {
		en: '',
		ar: '',
	},
};
