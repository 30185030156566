import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

//Import utils
import { useExtendContractProvider } from 'views/Bookings/ExtendContract/context';
import { useMessage, useTranslations } from 'components/utilities';
import { sendContractOTP } from 'store/actions/contract';

export const useFormContent = () => {
	const [isOtpLoading, setOtpLoading] = useState(false);
	const [otpSent, setOtpSent] = useState(false);
	const [otpValue, setOtpValue] = useState('');

	const { formOptions, branches, contractNumber, isOtpRequired } =
		useExtendContractProvider();
	const { message } = useMessage();
	const { t } = useTranslations();
	const { change } = useForm();

	const { payment_methods: paymentMethods } = formOptions || {};

	const handleOnChangeOtp = (value) => setOtpValue(value);

	const handleOnSendOtp = async () => {
		const options = {
			contractNumber,
			successCallback: () => {
				setOtpSent(true);
				setOtpLoading(false);
				message.success(
					t('contract.create_contract_form.success_send_otp_message')
				);
			},
			errorCallback: (error) => {
				message.error(error);
				setOtpLoading(false);
			},
		};

		setOtpLoading(true);

		await sendContractOTP(options);
	};

	useEffect(() => {
		if (isOtpRequired) {
			change('otp_value', otpValue?.length === 6 ? otpValue : null);
		}
	}, [otpValue, change, isOtpRequired]);

	return {
		handleOnChangeOtp,
		handleOnSendOtp,
		paymentMethods,
		isOtpRequired,
		isOtpLoading,
		branches,
		otpValue,
		otpSent,
	};
};
