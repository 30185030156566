import { useSelector } from 'react-redux';

// Import utilities
import { useDeliveryLocationProvider } from '../../context';

// Import helpers
import { DELIVERY_STATUS, getConvertedDelivery } from 'helpers';
import { previewBookingSelector } from 'store/selectors';

const { FINISHED, CANCELLED } = DELIVERY_STATUS;

export const useSteps = ({ deliveryType, customDeliveryData }) => {
	const contextData = useDeliveryLocationProvider();

	const deliveryLocationData = customDeliveryData
		? customDeliveryData
		: contextData;

	const { delivery } = useSelector(previewBookingSelector);

	const convertedDelivery = getConvertedDelivery(
		customDeliveryData || delivery
	);

	const deliveryData = convertedDelivery.find(
		({ deliveryType: type }) => type === deliveryType
	);

	const { driver_name: driverName, has_late_delivery } = deliveryData || {};

	const {
		state,
		refetch: handleRefetchLocation,
		isFetching: isLocationLoading,
	} = deliveryLocationData[deliveryType] || {};

	const getChipLabel = () => {
		const transKey = 'bookings.preview_booking.delivery_information.chip_label';

		if (state === CANCELLED) {
			return `${transKey}.cancelled`;
		} else {
			return has_late_delivery
				? `${transKey}.delivery_late`
				: `${transKey}.delivery_on_time`;
		}
	};

	const isChipLabelRed = state === CANCELLED || has_late_delivery;
	const isDeliveryWasFinishedOrCancelled =
		state === FINISHED || state === CANCELLED;

	return {
		isChipLabelRed,
		getChipLabel,
		driverName,
		handleRefetchLocation,
		isLocationLoading,
		isDeliveryWasFinishedOrCancelled,
	};
};
