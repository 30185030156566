export const getChangedCorporateValues = ({ values, initialValues }) =>
	initialValues &&
	Object.entries(initialValues).reduce((acc, [key, initialValue]) => {
		const formValue = values[key];
		if (formValue !== initialValue) {
			return { ...acc, ...{ [key]: formValue } };
		}
		return acc;
	}, {});

export const getCoordinatorErrors = ({
	convertedCoordinators,
	coordinatorErrorUuids,
}) => {
	return {
		coordinators: convertedCoordinators.map((_el, listIndex) => {
			const { index, error } =
				coordinatorErrorUuids?.find(
					({ index: coordinatorErrorIndex }) =>
						coordinatorErrorIndex === listIndex
				) || {};

			return index === listIndex ? error : null;
		}),
	};
};
