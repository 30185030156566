// ********************  table  ********************

export const fetchCampaignsUrl = (queryParams) =>
	`/v2/customer/wallet-campaign/list${queryParams}`;

export const updateActiveCampaignUrl = (id, isActive) =>
	`/v2/customer/wallet-campaign/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const campaignsExportUrl = (queryParams) =>
	`/v2/customer/wallet/export${queryParams}`;

// ********************  form  ********************

export const fetchCampaignsFormResourcesUrl = (itemId) =>
	`/v2/customer/wallet-campaign/${itemId}/show`;

export const submitCampaignFormUrl = (actionPath) =>
	`/v2/customer/wallet-campaign/${actionPath}`;

// ********************  experience shared form  ********************

export const submitExperienceSharedFormUrl =
	'v2/customer/wallet-campaign/experience_shared/apply';

export const fetchBookingGratificationUrl =
	'/v2/customer/wallet-campaign/experience_shared/preview';

export const deductCustomerWalletFundsUrl = (customerUuid) =>
	`/v2/customer/${customerUuid}/wallet/balance/deduct`;

export const addCustomerWalletFundsUrl = (customerUuid) =>
	`/v2/customer/${customerUuid}/wallet/balance/add`;
