export const validation = (values) => {
	let errors = {};
	const { offices } = values;

	if (offices.length === 0) {
		errors.offices = 'errors.at_least_one_office';
	}

	return errors;
};

export const INITIAL_VALUES = {
	carInfo: {
		details: {
			quantity: '',
			description: {
				ar: '',
				en: '',
			},
		},
		vehicle: {
			maker_name: {
				en: '',
				ar: '',
			},
			model_name: {
				en: '',
				ar: '',
			},
			year: null,
			uuid: '',
		},

		prices: {
			annual: {
				days: '',
			},
			monthly: {
				days: '',
			},
			daily: {
				days: '',
			},
			weekly: {
				days: '',
			},
		},
		options: {
			is_unlimited: false,
			unlimited_nett_price: '',
			free_kms: '',
			extra_km_nett_price: '',
			is_insurance: false,
			insurance_nett_price: '',
			is_no_smoking_car: false,
			no_smoking_prices: {
				daily: {
					nett_price: 0,
				},
				weekly: {
					nett_price: 0,
				},
				monthly: {
					nett_price: 0,
				},
			},
		},
		equipment: {
			navigation: false,
		},
	},
	prices: {
		annual: {
			from: 180,
			to: 360,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		monthly: {
			from: 15,
			to: 31,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		daily: {
			from: 1,
			to: 2,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		weekly: {
			from: 2,
			to: 7,
			average_nett_price: '',
			lowest_nett_price: '',
		},
	},
	offices: [],
};
