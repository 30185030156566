import React from 'react';
import { Button, TableCell } from '@material-ui/core';
import { string, object } from 'prop-types';

// Import utils
import useValidationActionTableCell from './useValidationActionTableCell';

// Import components
import { ValidationModal, StatusChip } from './components';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

const ValidationActionTableCell = ({ cell, actionButtonTitle }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { isOpen, handleOpenModal, handleCloseModal, isValidated, status } =
		useValidationActionTableCell({ cell });

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<ValidationModal open={isOpen} onClose={handleCloseModal} />
			{isValidated ? (
				<StatusChip status={status} />
			) : (
				<Button
					onClick={handleOpenModal}
					className={classes.actionButton}
					color="primary"
					variant="contained"
					size="small"
				>
					{actionButtonTitle || t('validation.table.action.validate')}
				</Button>
			)}
		</TableCell>
	);
};

ValidationActionTableCell.propTypes = {
	cell: object.isRequired,
	actionButtonTitle: string,
};

export default ValidationActionTableCell;
