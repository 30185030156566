import React from 'react';
import { object } from 'prop-types';
import { TableCell, Button } from '@material-ui/core';

// Import utilities
import { useMessage, useTranslations } from 'components/utilities';
import { analyzeCustomerDriverLicense } from 'store/actions';

export const AnalyzeDriverLicenseTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const { uuid } = cell.row.original;

	const { message } = useMessage();

	const handleOnClick = async () => {
		try {
			await analyzeCustomerDriverLicense(uuid);
			message.success(t('common.messages.successfully_done'));
		} catch (error) {
			const errorMessage = error.response.data.message;
			message.error(errorMessage);
		}
	};

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Button
				onClick={handleOnClick}
				variant="contained"
				color="primary"
				size="small"
			>
				{t('common.buttons.analyze')}
			</Button>
		</TableCell>
	);
};

AnalyzeDriverLicenseTableCell.propTypes = {
	cell: object.isRequired,
};
