import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { FileFieldAdapter } from 'components/elements';

const DriverLicense = ({ className, section, ...rest }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	const token = useSelector(
		(state) => state.auth.authenticate?.data?.meta?.access_token
	)

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('customers.form.driver_license.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							previewFileState={`${section}`}
							component={FileFieldAdapter}
							name={`${section}.file_path`}
							fileNameField={`${section}.file_name`}
							disabled={submitting}
							token={token}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

DriverLicense.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default DriverLicense;
