import React from 'react';
import { object } from 'prop-types';
import { TableCell, Box, Typography } from '@material-ui/core';

// Import assets
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const NumberOfTriesTableCell = ({ cell }) => {
	const {
		last_month_payment_tries: tries,
		last_month_payment_success: isSuccess,
	} = cell.row.original;

	const statusIcon = isSuccess ? (
		<CheckCircleOutlineIcon color="primary" />
	) : (
		<HighlightOffIcon color="error" />
	);

	return (
		<TableCell {...cell.getCellProps()}>
			<Box display="flex" alignItems="center" gridGap={10}>
				{statusIcon}
				<Typography>{tries}</Typography>
			</Box>
		</TableCell>
	);
};

NumberOfTriesTableCell.propTypes = {
	cell: object.isRequired,
};
