import {
	TextFieldAdapter,
	SelectFieldAdapter,
	CheckboxFieldAdapter,
} from 'components/elements';

export const FIELDS = (additional_notification, showCompanySelect) => {
	const result = [
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'profile.form.name',
			name: `details.name`,
			md: 12,
			xs: 12,
			required: true,
		},
		{
			component: SelectFieldAdapter,
			type: 'select',
			label: 'profile.form.role',
			name: `manager.role`,
			md: 12,
			xs: 12,
			required: true,
			menuItemsContent: 'roles',
			keyExtractor: (row) => row.key,
			labelExtractor: (row) => row.label
		},
	]
	
	if (showCompanySelect) {
		result.push({
			component: SelectFieldAdapter,
			type: 'select',
			label: 'profile.form.assign_to_company',
			name: `belonging.company.uuid`,
			md: 12,
			xs: 12,
			required: true,
			menuItemsContent: 'companyList',
			keyExtractor: (row) => row.uuid,
			labelExtractor: (row) => row.name.en,
		})
	}
	result.push(...[
		{
			component: CheckboxFieldAdapter,
			type: 'checkbox',
			label: 'profile.form.phone_number_notify',
			name: `details.phone_notify`,
			md: 4,
			xs: 12,
		},
		{
			component: SelectFieldAdapter,
			type: 'select',
			label: 'profile.form.phone_prefix',
			name: `details.phone_prefix`,
			md: 3,
			xs: 12,
			required: true,
			menuItemsContent: 'prefixes',
			keyExtractor: (row) => row,
			labelExtractor: (row) => row,
		},
		{
			component: TextFieldAdapter,
			type: 'number',
			label: 'profile.form.phone_number',
			name: `details.phone`,
			md: 4,
			xs: 12,
			required: true,
		},
		{
			component: CheckboxFieldAdapter,
			type: 'checkbox',
			label: 'profile.form.additional_phone_number_notify',
			name: `manager.additional_phone_notify`,
			md: 4,
			xs: 12,
		},
		{
			component: SelectFieldAdapter,
			type: 'select',
			label: 'profile.form.phone_prefix',
			name: `manager.additional_phone_prefix`,
			md: 3,
			xs: 12,
			required: additional_notification,
			menuItemsContent: 'prefixes',
			keyExtractor: (row) => row,
			labelExtractor: (row) => row,
		},
		{
			component: TextFieldAdapter,
			type: 'number',
			label: 'profile.form.additional_phone_number',
			name: `manager.additional_phone`,
			md: 4,
			xs: 12,
			required: additional_notification,
		},
		{
			component: CheckboxFieldAdapter,
			type: 'checkbox',
			label: 'profile.form.email_notify',
			name: `details.email_notify`,
			md: 4,
			xs: 12,
		},
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'profile.form.email',
			name: `details.email`,
			md: 7,
			xs: 12,
			required: true,
		},
		{
			component: TextFieldAdapter,
			type: 'password',
			label: 'profile.form.password',
			name: `details.password`,
			md: 12,
			xs: 12,
			required: false,
		},
		{
			component: TextFieldAdapter,
			type: 'password',
			label: 'profile.form.confirm_password',
			name: `details.password_confirmation`,
			md: 12,
			xs: 12,
			required: false,
		}
	])
	return result;
}
