import React from 'react';
import { object } from 'prop-types';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { CELL_TYPES } from '../helpers';

// Import components
import { DefaultTableCell } from '../../components';

// Import utilities
import { useHistoryTable } from '../../useHistoryTable';

export const TableBodyCell = ({ row, column }) => {
	const tableData = useHistoryTable({ row, column });

	switch (column.accessor) {
		case COLUMNS_ACCESSORS.AMOUNT:
		case COLUMNS_ACCESSORS.BALANCE:
			return (
				<DefaultTableCell type={CELL_TYPES.AMOUNT} tableData={tableData} />
			);

		case COLUMNS_ACCESSORS.TRANSACTION:
			return (
				<DefaultTableCell type={CELL_TYPES.DESCRIPTION} tableData={tableData} />
			);

		default:
			return <DefaultTableCell tableData={tableData} />;
	}
};

TableBodyCell.propTypes = {
	column: object,
	row: object,
};
