import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Import utils and helpers
import { convertApiFormErrors } from 'helpers';
import { URLS } from 'components/routes';
import {
	useQueryStringParams,
	useTranslations,
	useMessage,
} from 'components/utilities';

// Import store
import { resetPassword, verifyCode } from 'store/actions';

export const useSetNewPassword = () => {
	const { parsedQSParams } = useQueryStringParams();
	const [isLoading, setLoading] = useState(false);
	const [verified, setVerified] = useState(true);
	const { message } = useMessage();
	const { t } = useTranslations();

	const dispatch = useDispatch();
	const history = useHistory();

	const { code, token } = parsedQSParams || {};

	const fetchData = async () =>
		await verifyCode(
			{ token, code },
			() => {
				history.push(URLS.signInUrl);
			},
			() => {
				setVerified(true);
			}
		)();

	useEffect(() => {
		fetchData();
		//eslint-disable-next-line
	}, []);

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setLoading(true);

		const body = {
			...values,
			token,
			code,
		};

		await resetPassword(
			body,
			(errors) => {
				apiErrors = convertApiFormErrors(errors);
				message.error(t('common.messages.error_message'));
				setLoading(false);
			},
			() => {
				message.success(t('common.messages.successfully_done'));
				history.push(URLS.signInUrl);
				setLoading(false);
			}
		)(dispatch);

		return apiErrors;
	};

	return {
		handleOnSubmit,
		history,
		verified,
		isLoading,
	};
};
