import services from 'services/services';
import * as types from 'store/types';
import {
	fetchEmployeeRewardingFormResourcesUrl,
	submitEmployeeRewardingFormResourcesUrl,
} from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import { adjustFormData, convertFormValues } from './helpers';

export const fetchEmployeeRewardingFormResources =
	({ itemId, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.EMPLOYEE_REWARDING_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			const {
				data: { data },
			} = await services.get(
				fetchEmployeeRewardingFormResourcesUrl(itemId),
				cancelToken
			);

			const formData = adjustFormData(data);

			dispatch({
				type: types.EMPLOYEE_REWARDING_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: { data: formData },
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.EMPLOYEE_REWARDING_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitEmployeeRewardingForm =
	({ values, itemId, callbackSuccess, errorCallback }) =>
	async () => {
		const formData = convertFormValues(values);

		try {
			await services.post(
				submitEmployeeRewardingFormResourcesUrl(itemId),
				formData
			);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
