import AddCircleIcon from '@material-ui/icons/AddCircle';

// Import components
import { Filters } from './components';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Filters,
		customKey: 'filters',
	},
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: '/promoCode/add',
		icon: AddCircleIcon,
		text: 'promo_codes.table.toolbar.add',
	},
];
