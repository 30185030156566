import React, { createContext, useEffect, useState } from 'react';
import { node } from 'prop-types';

// Import helpers
import { FINANCIAL_REPORT_TYPES } from 'helpers';
import * as campaignHelpers from '../components/CampaignFilters/helpers';
import * as revenueHelpers from '../components/RevenueFilters/helpers';
import { columns as campaignColumns } from '../components/CampaignFilters/columns';
import { columns as revenueColumns } from '../components/RevenueFilters/columns';

// Import store
import { fetchCampaignReport, fetchRevenueReport } from 'store/actions';

export const FinancialReportContext = createContext(null);

export const FinancialReportProvider = ({ children }) => {
	const { CAMPAIGN, REVENUE } = FINANCIAL_REPORT_TYPES;

	const [reportType, setReportType] = useState('');

	const campaignHelpersObject = {
		fetchDataTableAction: fetchCampaignReport,
		columns: campaignColumns,
		initialValues: campaignHelpers.initialValues,
		formatValues: campaignHelpers.formatValues,
	};

	const revenueHelpersObject = {
		fetchDataTableAction: fetchRevenueReport,
		columns: revenueColumns,
		initialValues: revenueHelpers.initialValues,
		formatValues: revenueHelpers.formatValues,
	};

	const [reportHelpers, setReportHelpers] = useState(campaignHelpersObject);

	const setHelpersObject = () => {
		switch (reportType) {
			case CAMPAIGN:
				return setReportHelpers(campaignHelpersObject);
			case REVENUE:
				return setReportHelpers(revenueHelpersObject);
			default:
				return {};
		}
	};

	useEffect(() => {
		setHelpersObject();
		// eslint-disable-next-line
	}, [reportType]);

	return (
		<FinancialReportContext.Provider
			value={{ reportHelpers, setReportType, reportType }}
		>
			{children}
		</FinancialReportContext.Provider>
	);
};

FinancialReportProvider.propTypes = {
	children: node,
};
