// Import helpers
import { FIELD_TYPES } from 'helpers';

export const BASE_DETAILS_FIELDS = (section) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'kiosk.airports.form.details.airport_name_en',
		name: `${section}.name.en`,
		md: 4,
		xs: 12,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'kiosk.airports.form.details.airport_name_ar',
		name: `${section}.name.ar`,
		md: 4,
		xs: 12,
		required: true,
	},
];
