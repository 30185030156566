import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers
import { splitText } from 'helpers';

// Import components
import { DateBody } from 'components/elements';

export const ExtendedAtTableCell = ({ cell }) => {
	const [date, time] = splitText(cell.row.original.extension_created_at, ' ');
	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<DateBody date={date} time={time} />
		</TableCell>
	);
};

ExtendedAtTableCell.propTypes = {
	cell: object,
};
