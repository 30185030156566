// ----------------- FORM -----------------
export const SET_COMPANY_UUID_CONTEXT = 'SET_COMPANY_UUID_CONTEXT';
export const RESET_COMPANY_UUID_CONTEXT = 'RESET_COMPANY_UUID_CONTEXT';
export const SET_OFFICE_UUID_CONTEXT = 'SET_OFFICE_UUID_CONTEXT';
export const SET_VEHICLE_UUID_CONTEXT = 'SET_VEHICLE_UUID_CONTEXT';

export const COMPANY_MANAGER_FORM_FETCH_DATA_LOADING =
	'COMPANY_MANAGER_FORM_FETCH_DATA_LOADING';
export const COMPANY_MANAGER_FORM_FETCH_DATA_SUCCESS =
	'COMPANY_MANAGER_FORM_FETCH_DATA_SUCCESS';
export const COMPANY_MANAGER_FORM_FETCH_DATA_ERROR =
	'COMPANY_MANAGER_FORM_FETCH_DATA_ERROR';
export const COMPANY_MANAGER_FORM_ADD_USER_SUCCESS =
	'COMPANY_MANAGER_FORM_ADD_USER_SUCCESS';
