import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { prices } from './helpers';
import { ClearableSelect } from 'components/elements';
import { MenuItem } from '@material-ui/core';

const DropCityPriceInput = (props) => {
	const isValidValue = useMemo(() => {
		return !isNaN(parseFloat(props.value));
	}, [props.value]);

	return (
		<ClearableSelect
			fullWidth
			autoFocus
			variant="outlined"
			clearable={isValidValue}
			value={props.value}
			onChange={props.onChange}
			onClear={props.onClear}
		>
			{prices.map((price) => (
				<MenuItem value={price.value} key={price.value}>
					{price.label}
				</MenuItem>
			))}
		</ClearableSelect>
	);
};

DropCityPriceInput.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
};

export default DropCityPriceInput;
