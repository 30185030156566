import { useEffect, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { isNull } from 'lodash';

// Import utilities
import { useCityCollection } from 'queries';
import { getCompanyUuid } from './helpers';
import { useProfile } from 'components/utilities';

export const useCityFilter = (name, externalCompanyUuid = null) => {
	const { values } = useFormState();
	const { isAdmin } = useProfile();
	const { change } = useForm();
	const { id } = useParams();

	const companyUuidFormValue = values?.company_uuid;
	const initialCompanyUuid =
		externalCompanyUuid || companyUuidFormValue || id || null;

	const [companyUuid, setCompanyUuid] = useState(initialCompanyUuid);
	const { data: cities, isFetching } = useCityCollection({ companyUuid });

	const isFieldDisabled = isFetching || companyUuid === 'all' || !cities.length;

	useEffect(() => {
		// For company and office managers retrieve the company uuid from api
		if (isNull(companyUuid) && !isAdmin) {
			getCompanyUuid().then((uuid) => {
				setCompanyUuid(uuid);
			});
		}
	}, [name, companyUuid, isAdmin]);

	useEffect(() => {
		if (null != companyUuidFormValue) {
			setCompanyUuid(companyUuidFormValue);
		}

		if (null != externalCompanyUuid) {
			setCompanyUuid(externalCompanyUuid);
		}

		// eslint-disable-next-line
	}, [values.company_uuid, externalCompanyUuid]);

	useEffect(() => {
		if (companyUuid === 'all') {
			change(name, 'all');
		}
		// eslint-disable-next-line
	}, [companyUuid]);

	return {
		cities,
		isFieldDisabled,
		isFetching,
	};
};
