import React from 'react';
import { object, string, bool } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { TableCell, Link } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

const EditTableCell = ({
	cell,
	editLinkPath,
	icon: IconComponent = EditIcon,
	text = 'table.headers.edit',
	originalText = '',
	isIcon = true,
	originalIcon = false,
	canEdit = true,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const renderIcon = () =>
		!originalIcon && isIcon ? (
			<IconComponent className={classes.icon} fontSize="small" />
		) : (
			originalIcon
		);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{canEdit && (
				<>
					<Link
						className={classes.link}
						component={RouterLink}
						to={editLinkPath}
						variant="h6"
					>
						{renderIcon()}
						{originalText || t(text)}
					</Link>
				</>
			)}
		</TableCell>
	);
};

EditTableCell.propTypes = {
	cell: object.isRequired,
	editLinkPath: string.isRequired,
	text: string,
	originalText: string,
	icon: object,
	isIcon: bool,
	originalIcon: object,
	isExternal: bool,
	canEdit: bool,
};

export default EditTableCell;
