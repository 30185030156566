import React from 'react';
import { object, string } from 'prop-types';
import { Typography } from '@material-ui/core';

// Import components
import { OfflineSettlementDialog } from './SubscriptionInvoiceCard/components/OfflineSettlementDialog';
import PreviewField from './PreviewField';

// Import helpers
import { PAYMENT_METHODS } from 'helpers';

// Import utils
import { useTranslations } from 'components/utilities';

const { BANK_TRANSFER, CASH } = PAYMENT_METHODS;

export const SubscriptionInvoicePaymentMethodPreviewField = ({
	label,
	customData,
}) => {
	const { t } = useTranslations();

	const { subscription_invoice_payment_method: paymentMethod } = customData;

	const isOfflinePaymentMethod =
		paymentMethod === BANK_TRANSFER || paymentMethod === CASH;

	const paymentText = `bookings.filters.payment_methods.${paymentMethod}`;

	return (
		<PreviewField label={label}>
			{isOfflinePaymentMethod ? (
				<OfflineSettlementDialog
					buttonText="bookings.preview_booking.subscription_invoice.offline"
					readOnly
				/>
			) : (
				<Typography>{paymentMethod ? t(paymentText) : ''}</Typography>
			)}
		</PreviewField>
	);
};

SubscriptionInvoicePaymentMethodPreviewField.propTypes = {
	customData: object,
	label: string,
};
