export const COLUMNS_ACCESSORS = {
	LOGO: 'logo',
	COMPANY_NAME: 'name',
	RATING: 'rating',
	ALL_BOOKINGS_AND_ACCEPTED: 'all_bookings_and_accepted',
};

export const columns = [
	{
		Header: 'dashboard.table.headers.company_name',
		accessor: COLUMNS_ACCESSORS.COMPANY_NAME,
		disableSortBy: true,
	},
	{
		Header: 'dashboard.table.headers.acceptance_rate',
		accessor: 'booking_acceptance_rate',
		disableSortBy: true,
	},
	{
		Header: 'dashboard.table.headers.response_time',
		accessor: 'booking_avg_response_time',
		disableSortBy: true,
	},
	{
		Header: 'dashboard.table.headers.no_of_bookings',
		accessor: COLUMNS_ACCESSORS.ALL_BOOKINGS_AND_ACCEPTED,
		disableSortBy: true,
	},
	{
		Header: 'dashboard.table.headers.actual_revenue',
		accessor: 'revenue_gained',
		disableSortBy: true,
	},
	{
		Header: 'dashboard.table.headers.rating',
		accessor: COLUMNS_ACCESSORS.RATING,
		disableSortBy: true,
	},
];
