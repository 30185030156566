import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
	wrapper: (props) => ({
		position: 'relative',
		display: 'inline-block',
		width: props.fullWidth ? '100%' : 'auto',
	}),
	clearBtn: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		right: '30px',
	},
}));
