import React from 'react';
import { Grid, List, ListItem, Typography } from '@material-ui/core';
import { array, bool } from 'prop-types';

// Import utilities
import { useDataFormContent } from './useDataFormContent';
import { useTranslations } from 'components/utilities';
import { useInitiateContractProvider } from 'views/Bookings/InitiateContract/context';

// Import components
import { ButtonProgress, CollapsableCard, Alert } from 'components/elements';
import { FieldBox } from '../../components';
import { SearchPlateDialog } from './components';

// Import helpers
import { useSections } from './useSections';

// Import styles
import { useStyles } from './DataFormContent.styles';

// Import assets
import { DoubleCheckIcon } from 'assets/icons';

export const DataFormContent = ({ isLoading, submitApiErrors }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		isButtonsDisabled,
		plateError,
		checkIfFieldsHasErrors,
		handleSaveAsDraft,
		draftApiErrors,
		isDraft,
	} = useDataFormContent({
		isLoading,
	});

	const apiErrors = submitApiErrors.length ? submitApiErrors : draftApiErrors;

	const { toggleSearchPlateDialog, isSearchPlateDialogOpen } =
		useInitiateContractProvider();

	const { sections } = useSections({ plateError });

	const renderApiErrors = (apiErrors) => (
		<List>
			{apiErrors.map((error) => (
				<ListItem key={error}>
					<Typography variant="body1">{error}</Typography>
				</ListItem>
			))}
		</List>
	);

	return (
		<Grid container spacing={3} className={classes.container}>
			<Grid item xs={12}>
				{apiErrors?.length > 0 && (
					<Alert message={renderApiErrors(apiErrors)} />
				)}
			</Grid>
			{sections.map(
				({ section, children, isHidden, fields, defaultMinimize, ...rest }) =>
					isHidden ? null : (
						<Grid item key={section} xs={12}>
							<CollapsableCard
								close={defaultMinimize}
								icon={!checkIfFieldsHasErrors(fields) && DoubleCheckIcon}
								title={t(`contract.create_contract_form.sections.${section}`)}
								titleClassName={classes.collapseTitle}
								errorMessage={
									checkIfFieldsHasErrors(fields) &&
									t(`contract.create_contract_form.section_error`)
								}
							>
								<FieldBox fields={fields} {...rest}>
									{children}
								</FieldBox>
							</CollapsableCard>
						</Grid>
					)
			)}
			<Grid item xs={12} className={classes.buttonWrapper}>
				{!isDraft && (
					<ButtonProgress
						circularProgressSize={28}
						isLoading={isButtonsDisabled}
						disabled={isButtonsDisabled}
						variant="contained"
						color="primary"
						type="button"
						className={classes.button}
						onClick={handleSaveAsDraft}
					>
						{t('common.buttons.save_as_draft')}
					</ButtonProgress>
				)}
				<ButtonProgress
					circularProgressSize={28}
					isLoading={isButtonsDisabled}
					disabled={isButtonsDisabled}
					variant="contained"
					color="primary"
					type="submit"
					className={classes.button}
				>
					{t('common.buttons.next')}
				</ButtonProgress>
			</Grid>

			<SearchPlateDialog
				isOpen={isSearchPlateDialogOpen}
				toggle={toggleSearchPlateDialog}
			/>
		</Grid>
	);
};

DataFormContent.propTypes = {
	isLoading: bool.isRequired,
	submitApiErrors: array,
};
