import React from 'react';
import { object } from 'prop-types';
import { TableCell, Link } from '@material-ui/core';

// Import utils
import { useProfile, useTranslations } from 'components/utilities';

export const AttachmentTableCell = ({ cell }) => {
	const { t } = useTranslations();
	const { token } = useProfile();

	const href = cell.row.original.attachment_url;
	const url = `${href}?token=${token}`;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{url && (
				<Link
					download="wire_transfer"
					color="primary"
					target="_blank"
					href={url}
				>
					{t('common.buttons.download')}
				</Link>
			)}
		</TableCell>
	);
};

AttachmentTableCell.propTypes = {
	cell: object,
};
