import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BOOKING_COMPENSATION_STATES } from 'helpers';
import { previewBookingSelector } from 'store/selectors';
import { MAX_URGENT_REASONS } from './helpers';

const useUrgentStatusAssign = () => {
	const [isFormVisible, setIsFormVisible] = useState(false);

	const { urgentReasons } = useSelector(previewBookingSelector);

	const isMaxUrgentReasonsSize = urgentReasons?.length === MAX_URGENT_REASONS;

	const isSomeUrgentReason = !!urgentReasons?.length;
	const isSomeReviewStatus = !!urgentReasons?.filter(
		({ status }) => status === BOOKING_COMPENSATION_STATES.REVIEW
	)?.length;

	const toggleFormVisible = () => {
		setIsFormVisible((prev) => !prev);
	};

	return {
		isMaxUrgentReasonsSize,
		isSomeUrgentReason,
		isSomeReviewStatus,
		toggleFormVisible,
		isFormVisible,
	};
};

export default useUrgentStatusAssign;
