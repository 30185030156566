import { useFormState } from 'react-final-form';

export const useExternalPartnerCarsSelect = ({
	externalPartnerCars,
	officeUuid,
	index,
}) => {
	const { values } = useFormState();

	const selectedCars =
		values.offices?.[officeUuid]?.office_cars?.[index]
			?.external_partner_car_ids || [];

	const allSelectedCars = values.offices?.[officeUuid]?.office_cars.reduce(
		(acc, { external_partner_car_ids }) =>
			external_partner_car_ids.length
				? [...acc, ...external_partner_car_ids]
				: acc,
		[]
	);

	const findLabel = (checkedId) =>
		externalPartnerCars.find(({ id }) => checkedId === id).name;

	const getExternalPartnerCarsWithoutDuplications = () => {
		const uniqueExternalPartnerCars = externalPartnerCars.filter(
			({ id }) => !allSelectedCars?.includes(id)
		);
		const convertedSelectedCars = externalPartnerCars.filter(({ id }) =>
			selectedCars?.includes(id)
		);

		return [...convertedSelectedCars, ...uniqueExternalPartnerCars];
	};

	const externalPartnerCarsWithoutDuplications =
		getExternalPartnerCarsWithoutDuplications();

	return { findLabel, selectedCars, externalPartnerCarsWithoutDuplications };
};
