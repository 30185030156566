import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		margin: '16px 0',
	},
	descriptionWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	linkWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		gap: '16px',
	},
	divider: {
		margin: '48px 0',
	},
}));
