import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	status: {
		textTransform: 'capitalize',
	},
	chip: {
		maxWidth: 'fit-content',
		borderRadius: '8px',
		padding: '10px',
	},
}));
