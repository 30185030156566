import { useQuery } from 'react-query';

// Import helpers
import { OFFICE_LEASING_CARS_EXPORT_QUERY } from 'helpers';

export const useLeasingCarsExport = ({
	isExportAllowed,
	handleExportError,
	handleExportSettled,
	fetchExportData,
}) => {
	const { isLoading: isExportLoading } = useQuery(
		OFFICE_LEASING_CARS_EXPORT_QUERY,
		fetchExportData,
		{
			refetchOnWindowFocus: false,
			enabled: isExportAllowed,
			onError: handleExportError,
			onSettled: handleExportSettled,
		}
	);

	return { isExportLoading };
};
