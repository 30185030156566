import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';

// Import components
import { GoogleMapsScriptLoader } from 'components/elements';
import { MarkerWithLabel, CustomMarkerTooltip } from '../../components';

// Import styles
import { useStyles } from './DeliveryLocationMap.styles';

// Import utils
import { useDeliveryLocationMap } from './useDeliveryLocationMap';

export const DeliveryLocationMap = ({ deliveryData }) => {
	const { steps, customMarkers, onMapLoad } = useDeliveryLocationMap({
		deliveryData,
	});

	const classes = useStyles();

	return (
		<GoogleMapsScriptLoader>
			<GoogleMap
				mapContainerClassName={classes.map}
				onLoad={onMapLoad}
				zoom={15}
			>
				{customMarkers.map(({ lat, lng, url, label }) => (
					<Fragment key={url}>
						<Marker
							position={{ lat, lng }}
							icon={{
								url,
								anchor: { x: 45, y: 45 },
								scaledSize: { width: 90, height: 90 },
							}}
						/>
						<CustomMarkerTooltip lat={lat} lng={lng} title={label} />
					</Fragment>
				))}

				{steps.map(
					({
						location: { latitude: lat, longitude: lng },
						state,
						updated_at,
					}) => (
						<MarkerWithLabel
							time={updated_at}
							state={state}
							key={state}
							lng={lng}
							lat={lat}
						/>
					)
				)}
			</GoogleMap>
		</GoogleMapsScriptLoader>
	);
};

DeliveryLocationMap.propTypes = {
	deliveryData: object,
};
