import { FIELD_TYPES } from 'helpers';

export const FIELDS = ({ section, isAdmin }) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'company.form.bank_info.name',
		name: `${section}.name`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'company.form.bank_info.bank_name',
		name: `${section}.bank_name`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'company.form.bank_info.iban_number',
		name: `${section}.iban_number`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'company.form.bank_info.account_number',
		name: `${section}.number`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
	},
];
