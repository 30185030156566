import { t } from 'i18next';
import { intervalToDuration } from 'date-fns';

// Import routes
import { URLS } from 'components/routes';

// Import helpers
import { DELIVERY_TYPES } from 'helpers';

export const convertTime = (timeInMinutes) => {
	const { minutes, hours, days } = intervalToDuration({
		start: 0,
		end: timeInMinutes * 60 * 1000,
	});

	const convertedDays = `${days ? days + 'd' : ''}`;
	const convertedHours = `${hours ? hours + 'h' : ''}`;
	const convertedMinutes = `${minutes}m`;

	return `${convertedDays} ${convertedHours} ${convertedMinutes}`.trimStart();
};

export const getTransKeys = (key) => {
	const transKey = 'bookings.preview_booking.car';
	const pickupOrDropText =
		key === DELIVERY_TYPES.PICK_UP_DELIVERY
			? t(`${transKey}.pick_up`)
			: t(`${transKey}.return`);

	return { pickupOrDropText, transKey };
};

const getSubLabels = ({ key, deliveryData, pathname }) => {
	const {
		late_delivery_delay_in_minutes,
		has_late_delivery,
		has_wrong_location,
		has_on_time_delivery,
		is_delivery_prolonged,
		has_driver_assigned,
	} = deliveryData || {};

	const labels = [];

	const isBookingPage = pathname === URLS.bookingsUrl;

	const { pickupOrDropText, transKey } = getTransKeys(key);

	const isDeliveredLate = isBookingPage
		? has_late_delivery && !is_delivery_prolonged && has_driver_assigned
		: has_late_delivery;

	isDeliveredLate &&
		labels.push({
			color: 'error',
			label: `${pickupOrDropText} - ${t(`${transKey}.delivered_late`, {
				time: `(${convertTime(late_delivery_delay_in_minutes)})`,
			})}`,
		});

	has_on_time_delivery &&
		labels.push({
			color: 'primary',
			label: `${pickupOrDropText} - ${t(`${transKey}.delivered_on_time`)}`,
		});

	has_wrong_location &&
		labels.push({
			color: 'error',
			label: `${pickupOrDropText} - ${t(`${transKey}.wrong_location`)}`,
		});

	is_delivery_prolonged &&
		isBookingPage &&
		labels.push({
			color: 'error',
			label: `${pickupOrDropText} - ${t(`${transKey}.no_use_the_app`)}`,
		});

	return labels;
};

export const getDeliveryStatusLabels = ({ delivery, pathname }) =>
	Object.entries(delivery).reduce((acc, [key, deliveryData]) => {
		const labels = getSubLabels({ key, deliveryData, pathname });
		return [...acc, ...labels];
	}, []);

export const getDeliveryTimeLabels = (delivery) =>
	Object.entries(delivery).reduce((acc, [key, deliveryData]) => {
		const { late_delivery_delay_in_minutes, has_late_delivery } = deliveryData;

		const { pickupOrDropText } = getTransKeys(key);

		const label = has_late_delivery
			? `${pickupOrDropText} - ${convertTime(late_delivery_delay_in_minutes)}`
			: '';

		return [...acc, label];
	}, []);

export const getDeliveryLabelsExport = (delivery) => {
	const {
		has_late_delivery,
		has_on_time_delivery,
		is_delivery_prolonged,
		has_driver_assigned,
	} = delivery || {};

	const labels = [];

	const transKey = 'bookings.preview_booking.car';

	const isDeliveredLate =
		has_late_delivery && !is_delivery_prolonged && has_driver_assigned;

	isDeliveredLate &&
		labels.push(`${t(`${transKey}.delivered_late`, { time: '' })}`);

	has_on_time_delivery && labels.push(t(`${transKey}.delivered_on_time`));

	return labels.join(' / ');
};
