import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	item: {
		checkboxes: null,
	},
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.REGISTRATION_PROCESS_LOADING:
				return INITIAL_STATE;

			case types.REGISTRATION_PROCESS_SUCCESS:
				draft.item = action.payload.item || {};
				draft.isLoaded = true;
				break;

			case types.REGISTRATION_PROCESS_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				draft.error = action.payload;
				break;

			default:
				return state;
		}
	});
