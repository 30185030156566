import React from 'react';
import { object, oneOfType, array } from 'prop-types';

// Import components
import PriceSummaryItem from './PriceSummaryItem';

// Import utilities
import { useTranslations } from 'components/utilities';

const OptionsPriceSummaryItem = ({ values }) => {
	const { t } = useTranslations();

	return (
		<>
			{values?.selected_options?.map(({ price, label, type }) => (
				<PriceSummaryItem key={type} label={label}>
					{price.gross} {t('currency.sar')}
				</PriceSummaryItem>
			))}
		</>
	);
};

OptionsPriceSummaryItem.propTypes = {
	values: oneOfType([array, object]).isRequired,
};

export default OptionsPriceSummaryItem;
