import React from 'react';
import { Grid } from '@material-ui/core';

// Import helpers
import { EXTEND_FORM_FIELDS } from './helpers';

//Import utils
import { useCommonFields, useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import components
import { ButtonProgress, OTPInput } from 'components/elements';

export const FormContent = () => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();

	const {
		handleOnChangeOtp,
		handleOnSendOtp,
		paymentMethods,
		isOtpRequired,
		isOtpLoading,
		branches,
		otpValue,
		otpSent,
	} = useFormContent();

	return (
		<Grid container spacing={4}>
			{EXTEND_FORM_FIELDS({ paymentMethods, branches }).map(renderFields)}
			{isOtpRequired && (
				<Grid item xs={12}>
					<Grid container alignItems="center" spacing={2}>
						<Grid item>
							<ButtonProgress
								circularProgressSize={28}
								onClick={handleOnSendOtp}
								isLoading={isOtpLoading}
								disabled={isOtpLoading}
								variant="contained"
								color="primary"
							>
								{t(
									otpSent
										? 'contract.extend_contract_form.resend_otp'
										: 'contract.extend_contract_form.otp'
								)}
							</ButtonProgress>
						</Grid>
						<Grid item>
							<OTPInput value={otpValue} onChange={handleOnChangeOtp} />
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
