import React, { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { node } from 'prop-types';

// Import utils
import { useEContract } from 'components/utilities';
import {
	useInitiateContractInitialValues,
	useCreateContractFormOptions,
	useContractBranches,
} from 'queries';

export const ExtendContractContext = createContext(null);

export const ExtendContractProvider = ({ children }) => {
	const { id } = useParams();

	const {
		data: formOptions,
		isFetching: formOptionsFetching,
		isError: formOptionsError,
	} = useCreateContractFormOptions();

	const {
		data: branches,
		isFetching: branchesFetching,
		isError: branchesError,
	} = useContractBranches();

	const {
		data: collection,
		error: collectionError,
		isFetching: collectionFetching,
	} = useInitiateContractInitialValues(id);
	const { contract_number: contractNumber } = collection || {};

	const {
		isExtendContractButtonVisible,
		contractDataFetching,
		contractDataError,
		contractData,
		bookingFetching,
		bookingError,
	} = useEContract({ contractNumber });
	const { is_otp_required: isOtpRequired } = contractData || {};

	const isFetching =
		contractDataFetching ||
		formOptionsFetching ||
		collectionFetching ||
		bookingFetching ||
		branchesFetching;

	const isError =
		contractDataError ||
		formOptionsError ||
		collectionError ||
		bookingError ||
		branchesError;

	return (
		<ExtendContractContext.Provider
			value={{
				allowExtendContract: isExtendContractButtonVisible,
				contractNumber,
				isOtpRequired,
				formOptions,
				isFetching,
				branches,
				isError,
			}}
		>
			{children}
		</ExtendContractContext.Provider>
	);
};

ExtendContractProvider.propTypes = {
	children: node,
};
