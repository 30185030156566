// Import helpers
import { convertPaymentMethodFilterValues, convertFormDates } from 'helpers';

export const initialValues = {
	company_uuid: 'all',
	state: 'all',
	payment: 'all',
	payment_type: null,
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
	urgent_assistance_created_at: null,
	urgent_assistance_created_at_to: null,
	operation_topics: 'all',
	compensation_status: 'all',
	urgent_assistance_marked_by: 'all',
	compensation_validated_by: 'all',
	cancel_reason_status: 'all',
	is_compensation: 'all',
	is_subscription: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		state: values.state,
		company_uuid: values.company_uuid,
		operation_topics: values.operation_topics,
		compensation_status: values.compensation_status,
		cancel_reason_status: values.cancel_reason_status,
		urgent_assistance_marked_by: values.urgent_assistance_marked_by,
		compensation_validated_by: values.compensation_validated_by,
		is_subscription:
			values.is_subscription === 'all' ? null : values.is_subscription,
		is_compensation:
			values.is_compensation === 'all' ? null : values.is_compensation,
		...convertFormDates(values, [
			'pick_date',
			'drop_date',
			'urgent_assistance_created_at',
		]),
		...convertPaymentMethodFilterValues(values),
	};
};
