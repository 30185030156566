import { EMAIL_REGEX } from 'helpers/validation';

export const validation = (values) => {
	let errors = {};
	const MIN_PASSWORD_LENGTH = 6;
	const {
		details: { password, password_confirmation: passwordConfirmation, email },
	} = values;

	if (!EMAIL_REGEX.test(email)) {
		errors.details = {
			...errors.details,
			email: 'profile.form.validation.email_invalid',
		};
	}

	if (password?.length < MIN_PASSWORD_LENGTH) {
		errors.details = {
			...errors.details,
			password: 'profile.form.validation.password_too_short',
		};
	}

	if (passwordConfirmation?.length < MIN_PASSWORD_LENGTH) {
		errors.details = {
			...errors.details,
			password_confirmation: 'profile.form.validation.password_too_short',
		};
	}

	if (password !== passwordConfirmation) {
		errors.details = {
			...errors.details,
			password_confirmation: 'profile.form.validation.invalid_password',
		};
	}
	return errors;
};

export const INITIAL_VALUES = {
	details: {
		name: '',
		phone: '',
		phone_prefix: '',
		phone_notify: false,
		email: '',
		email_notify: true,
		password: '',
		password_confirmation: '',
	},
	manager: {
		additional_phone: '',
		additional_phone_notify: false,
		additional_phone_prefix: '',
		role: '',
	},
};
