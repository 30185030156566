import i18n from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';
import { subDays } from 'date-fns';

export const REQUIRED_FIELDS = ({
	closureCodeRequired,
	subClosureReasons,
	closureReasons,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'main_closure_code',
		label: 'contract.close_contract_form.contract_details.main_closure_reason',
		md: 4,
		options: closureReasons,
		required: true,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'closure_code',
		label: 'contract.close_contract_form.contract_details.sub_reason',
		md: 4,
		required: closureCodeRequired,
		isHidden: !closureCodeRequired,
		options: subClosureReasons,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'rent_status.odometer_reading',
		label: 'contract.create_contract_form.vehicle_details.odometer_reading',
		required: true,
		md: 4,
	},
];

export const CONTRACT_DETAILS_FIELDS = ({ branches }) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'actual_return_branch_id',
		label: 'contract.close_contract_form.contract_details.actual_return_branch',
		md: 4,
		required: true,
		options: branches,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.DATE,
		name: 'contract_actual_end_date',
		label:
			'contract.close_contract_form.contract_details.contract_actual_end_date',
		required: true,
		minDate: subDays(new Date(), 25),
		maxDate: new Date(),
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'operator_id',
		label: 'contract.create_contract_form.contract_details.operator_id',
		md: 4,
		removeUpAndDownArrows: true,
	},
];

export const RENT_STATUS_FIELDS = ({
	narrowedUsableConditions,
	availabilityStates,
	fuelAvailabilities,
	usableConditions,
	workingConditions,
	purityStates,
	classes,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.ac',
		label: 'contract.create_contract_form.car_inspection.ac',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: usableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.available_fuel',
		label: 'contract.close_contract_form.rent_status.available_fuel',
		md: 4,
		required: true,
		options: fuelAvailabilities,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ id }) =>
			i18n.t(`contract.close_contract_form.options.${id}`),
		defaultValue: fuelAvailabilities[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.speedometer',
		label: 'contract.create_contract_form.car_inspection.speedometer',
		md: 4,
		required: true,
		options: workingConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: workingConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.car_seats',
		label: 'contract.create_contract_form.car_inspection.car_seats',
		md: 4,
		required: true,
		options: purityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: purityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.safety_triangle',
		label: 'contract.create_contract_form.car_inspection.safety_triangle',
		md: 4,
		options: availabilityStates,
		required: true,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: availabilityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.tires',
		label: 'contract.create_contract_form.car_inspection.tires',
		md: 4,
		required: true,
		options: narrowedUsableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: narrowedUsableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.fire_extinguisher',
		label: 'contract.create_contract_form.car_inspection.fire_extinguisher',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: availabilityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.first_aid_kit',
		label: 'contract.create_contract_form.car_inspection.first_aid_kit',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: availabilityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.spare_tire_tools',
		label: 'contract.create_contract_form.car_inspection.spare_tire_tools',
		md: 4,
		required: true,
		options: availabilityStates,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: availabilityStates[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.spare_tire',
		label: 'contract.create_contract_form.car_inspection.spare_tire',
		md: 4,
		required: true,
		options: narrowedUsableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: narrowedUsableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.keys',
		label: 'contract.create_contract_form.car_inspection.keys',
		md: 4,
		required: true,
		options: workingConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: workingConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.radio_stereo',
		label: 'contract.create_contract_form.car_inspection.radio_stereo',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: usableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'rent_status.screen',
		label: 'contract.create_contract_form.car_inspection.screen',
		md: 4,
		required: true,
		options: usableConditions,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) =>
			i18n.t(`contract.create_contract_form.options.${name}`),
		defaultValue: usableConditions[0]?.id,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'rent_status.notes',
		label: 'contract.create_contract_form.car_inspection.notes',
		md: 12,
		multiline: true,
		rows: 4,
		className: classes.notes,
	},
];

export const PAYMENT_DETAILS_FIELDS = ({ paymentMethods }) => [
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.paid',
		label: 'contract.create_contract_form.payment_details.paid',
		required: true,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'payment_details.discount',
		label: 'contract.create_contract_form.payment_details.discount_percentage',
		required: true,
		min: 0,
		max: 100,
		md: 4,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'payment_details.payment_method_code',
		label: 'contract.create_contract_form.payment_details.payment_method',
		md: 4,
		options: paymentMethods,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
		defaultValue: paymentMethods[4]?.id,
	},
];
