import React from 'react';
import { Button, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';

// Import utilities
import useEditCompensationActionTableCell from './useEditCompensationActionTableCell';

// Import components
import { EditCompensationModal } from './components';

import { useTranslations } from 'components/utilities';
// Import translations

const EditCompensationActionTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const { isOpen, handleOpenModal, handleCloseModal, handleCustomSubmit } =
		useEditCompensationActionTableCell({ cell });

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<EditCompensationModal
				open={isOpen}
				onClose={handleCloseModal}
				onCustomSubmit={handleCustomSubmit}
			/>
			<Button
				onClick={handleOpenModal}
				color="primary"
				variant="contained"
				size="small"
			>
				{t('waiting_customer_info.table.action.edit_compensation')}
			</Button>
		</TableCell>
	);
};

EditCompensationActionTableCell.propTypes = {
	cell: PropTypes.object.isRequired,
};

export default EditCompensationActionTableCell;
