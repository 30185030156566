// Import helpers
import { NONE_VALUE } from 'helpers';

export const ADD_INITIAL_VALUES = {
	details: { code: '' },
	discount: {
		discount: '',
		max_discount: '',
	},
	period: {
		from: null,
		until: null,
	},
	max_usage: '',
	conditions: {
		one_time_use: false,
		only_for_delivery: false,
		allowed_previous_bookings_number: NONE_VALUE,
	},
	rental_companies: [],
	service_type: null,
};

export const EDIT_INITIAL_VALUES = {
	code: '',
	discount: {
		discount: '',
		max_discount: '',
	},
	period: {
		from: null,
		until: null,
	},
	max_usage: '',
	conditions: {
		one_time_use: false,
		allowed_previous_bookings_number: NONE_VALUE,
	},
};
