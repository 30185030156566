import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.primary.main,
	},
	disabledIcon: {
		color: theme.palette.gray.main,
	},
	errorIcon: {
		color: theme.palette.error.main,
	},
}));
