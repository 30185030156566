import { useState } from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { useUrgentReasons } from 'queries/Booking';
import {
	assignUrgentAssistanceReason,
	fetchBookingPreview,
} from 'store/actions';
import { convertApiFormErrors } from 'helpers';
import { previewBookingSelector } from 'store/selectors';

const useAssignUrgentBookingForm = () => {
	const [error, setError] = useState([]);

	const dispatch = useDispatch();

	const booking = useSelector(previewBookingSelector);

	const { data: urgentReasons } = useUrgentReasons();

	const handleSubmit = async ({ urgentReasonId }) => {
		try {
			const cancelToken = axios.CancelToken.source().token;
			const options = {
				bookingId: booking.uuid,
				urgentReasonId,
			};

			await assignUrgentAssistanceReason(options);
			fetchBookingPreview({ id: booking.uuid, cancelToken })(dispatch);
		} catch (error) {
			setError(convertApiFormErrors(error.data.errors));
		}
	};

	return {
		handleSubmit,
		error,
		urgentReasons,
	};
};

export default useAssignUrgentBookingForm;
