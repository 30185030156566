import { useState } from 'react';

// Import store
import { updateRentalCompaniesUrl } from 'store/paths';

// Import utils and helpers
import { useMessage, useTranslations } from 'components/utilities';
import { useRentalCompaniesProvider } from '../../context';
import services from 'services/services';

export const useRentalCompaniesContainer = () => {
	const { t } = useTranslations();

	const { refetchSavedCompanies, savedCompanies, isError } =
		useRentalCompaniesProvider();
	const { message } = useMessage();

	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const handleOnSubmit = async (values) => {
		setIsButtonLoading(true);

		try {
			await services.put(updateRentalCompaniesUrl, values);
			setIsButtonLoading(false);
			message.success(t('common.messages.successfully_saved'));
			refetchSavedCompanies();
		} catch {
			setIsButtonLoading(false);
			message.error(t('common.messages.error_message'));
		}
	};

	return {
		handleOnSubmit,
		savedCompanies,
		isButtonLoading,
		isError,
	};
};
