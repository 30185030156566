import React from 'react';
import { string } from 'prop-types';
import { Button } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';

// Import components
import PreviewField from '../../../PreviewField';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from '../../ViewAttachmentPreviewField.styles';

export const SingleAttachment = ({ file, label }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const openAttachment = () => window.open(file, '_blank');

	return (
		<PreviewField label={label}>
			<Button
				startIcon={<AttachmentIcon className={classes.icon} color="primary" />}
				className={classes.button}
				onClick={openAttachment}
			>
				{t('common.buttons.view_attachment')}
			</Button>
		</PreviewField>
	);
};

SingleAttachment.propTypes = {
	label: string.isRequired,
	file: string.isRequired,
};
