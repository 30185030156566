import React from 'react';
import { Box } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import components
import { Detail } from '../../../Detail';

// Import utilities
import { usePrintProvider } from 'components/context';

export const Header = () => {
	const classes = useStyles();

	const {
		data: {
			payment: { method, paid_at, card_last4 },
		},
	} = usePrintProvider();

	let paymentMethod = method;
	if (card_last4) paymentMethod += ` - ${card_last4}`;

	return (
		<Box className={classes.container}>
			<Detail
				label="bookings.invoice_print.payment_details.payment_due"
				value={paid_at}
			/>
			<Detail
				label="bookings.invoice_print.payment_details.payment_method"
				value={paymentMethod}
			/>
		</Box>
	);
};
