import { queryCache } from 'react-query';

// Import helpers
import { CAR_LIST_VEHICLE_TYPES_QUERY } from 'helpers';

export const convertFetchedData = (data) => {
	const { type } = data;

	const newTypes = type.map((item) => item.type);

	return newTypes;
};

export const convertSubmitData = (values) => {
	const { model_uuid, year, types, logo } = values;

	const {
		data: { data: vehicleTypes },
	} = queryCache.getQueryData(CAR_LIST_VEHICLE_TYPES_QUERY);

	const newTypes = types.map((item) => ({
		uuid: vehicleTypes.find(({ type }) => item === type).uuid,
	}));

	const convertedData = {
		model: { uuid: model_uuid },
		logo,
		year,
		type: newTypes,
	};

	return convertedData;
};
