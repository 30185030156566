import React from 'react';
import { Box } from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';

// Import components
import { PeriodButton } from './components';
import usePeriodButtons from './usePeriodButtons';

// Import utilities
import { useTranslations } from 'components/utilities';

const PeriodButtons = () => {
	const { t } = useTranslations();

	const { isLastMonth, isCurrentMonth, handleLastMonth, handleCurrentMonth } =
		usePeriodButtons();

	return (
		<Box display="flex" justifyContent="center">
			<ToggleButtonGroup color="primary">
				<PeriodButton selected={isLastMonth} onClick={handleLastMonth}>
					{t('dashboard.filters.last_month')}
				</PeriodButton>
				<PeriodButton selected={isCurrentMonth} onClick={handleCurrentMonth}>
					{t('dashboard.filters.current_month')}
				</PeriodButton>
			</ToggleButtonGroup>
		</Box>
	);
};

export default PeriodButtons;
