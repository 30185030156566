import { useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';
import usePrevious from '@rooks/use-previous';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import helpers
import { recalculationErrorsSelector } from 'store/selectors';

const useOptionsField = () => {
	const {
		values: {
			available_options,
			options: { delivery, driver },
		},
		submitting,
	} = useFormState();

	const { change } = useForm();

	const errors = useSelector(recalculationErrorsSelector);

	const isErrorsArray = _.isArray(errors);
	const isErrorsObject = _.isObject(errors) && !isErrorsArray;

	const isPromoCodeError = isErrorsObject && !!errors['options.promo_code'];
	const promoCodeError = isErrorsObject
		? Object.values(errors).flat()?.[0]
		: '';

	const convertedErrors = isErrorsArray ? errors?.flat() : null;

	const prevDelivery = usePrevious(delivery);
	const prevDriver = usePrevious(driver);

	useEffect(() => {
		// Driver option is not available when delivery option is selected
		if (delivery && prevDelivery === false) {
			change('options.driver', false);
		}
	}, [delivery, prevDelivery, change]);

	useEffect(() => {
		// Delivery option is not available when driver option is selected
		if (driver && prevDriver === false) {
			change('options.delivery', false);
		}
	}, [driver, prevDriver, change]);

	return {
		available_options: available_options || [],
		convertedErrors,
		submitting,
		isPromoCodeError,
		promoCodeError,
	};
};

export default useOptionsField;
