import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils and helpers
import { useEarlyReturnCalculations } from 'queries';
import { DEFAULT_DATE_FORMAT, FIELD_TYPES } from 'helpers';
import { convertedEarlyReturnData } from './helpers';

const { FILE_UPLOADER, DATE } = FIELD_TYPES;

export const useFormContent = () => {
	const { submitting, values } = useFormState();
	const { change } = useForm();
	const {
		uuid,
		can_be_returned_earlier: enabled,
		period: { pick_date: pickDate },
	} = useSelector(previewBookingSelector);

	const {
		data: earlyReturnData,
		isFetching,
		isError,
	} = useEarlyReturnCalculations({
		uuid,
		enabled,
		returnDate: format(new Date(values.return_date), DEFAULT_DATE_FORMAT),
	});

	const handleOnUploadWireTransfer = (file) => {
		change('attachments.wire_transfer', file);
	};

	const handleOnUploadContract = (file) => {
		change('attachments.contract', file);
	};

	const customData = convertedEarlyReturnData(earlyReturnData);

	const INPUT_FIELDS = [
		{
			label:
				'bookings.preview_booking.subscription_invoice.payments.return_date',
			name: 'return_date',
			type: DATE,
			md: 12,
			minDate: pickDate,
			maxDate: new Date(),
			required: true,
		},
		{
			label: 'bookings.preview_booking.subscription_invoice.payments.paid_at',
			name: 'paid_at',
			type: DATE,
			md: 12,
			minDate: true,
			maxDate: new Date(),
			required: true,
		},
		{
			label: 'common.fields.wire_transfer',
			type: FILE_UPLOADER,
			md: 12,
			handleOnFileUploader: handleOnUploadWireTransfer,
		},
		{
			label: 'common.fields.contract',
			type: FILE_UPLOADER,
			md: 12,
			handleOnFileUploader: handleOnUploadContract,
		},
	];

	return {
		INPUT_FIELDS,
		customData,
		submitting,
		isFetching,
		isError,
	};
};
