import React from 'react';
import { array } from 'prop-types';
import {
	Button,
	Dialog,
	DialogContent,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './ErrorsDialog.styles';

// Import components
import { ClosableDialogTitle } from 'components/elements';

export const ErrorsDialog = ({ errors = [] }) => {
	const { on: isOpen, toggle } = useToggle();
	const { t } = useTranslations();
	const classes = useStyles();

	if (!errors.length) return null;

	return (
		<>
			<Button onClick={toggle} className={classes.button}>
				{t('common.buttons.show_errors')} ({errors.length})
			</Button>
			<Dialog open={isOpen} onClose={toggle} maxWidth="lg" fullWidth>
				<ClosableDialogTitle title={t('common.errors')} onClose={toggle} />
				<Divider />
				<DialogContent>
					<Grid container spacing={1}>
						{errors.map((error, index) => (
							<Grid item xs={12} key={error}>
								<Typography className={classes.text}>
									{`${index + 1}. ${error}`}
								</Typography>
							</Grid>
						))}
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

ErrorsDialog.propTypes = {
	errors: array,
};
