import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

export const getAddSettlementFormData = ({ values, settledUuids }) => {
	const { payment_date, wire_transfer_file, total_amount } = values;

	const convertedPaymentDate = format(
		new Date(payment_date),
		DEFAULT_DATE_FORMAT
	);

	let formData = new FormData();

	formData.append('wire_transfer_file', wire_transfer_file);
	formData.append('payment_date', convertedPaymentDate);
	formData.append('total_amount', total_amount);

	for (var i = 0; i < settledUuids.length; i++) {
		formData.append(`corporate_limit_uuids[${i}]`, settledUuids[i]);
	}

	return formData;
};
