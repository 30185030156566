import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	bottomBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		padding: '8px 16px',
	},
}));
