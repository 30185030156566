import React from 'react';
import { Field } from 'react-final-form';
import { CircularProgress, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import useOfficeFilter from './useOfficeFilter';

const OfficeFilter = () => {
	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const { offices, submitting, isFetching } = useOfficeFilter();

	if (isFetching) {
		return <CircularProgress size={28} />;
	}

	return (
		<Field
			fullWidth
			component={SelectFieldAdapter}
			label={t('bookings.edit_booking.select_car.form.office')}
			margin="dense"
			name="office_uuid"
			variant="outlined"
			disabled={submitting}
		>
			{offices.map(({ name, uuid }) => (
				<MenuItem key={uuid} value={uuid}>
					{name[lng]}
				</MenuItem>
			))}
		</Field>
	);
};

export default OfficeFilter;
