import React, { useEffect } from 'react';
import { Form, useForm } from 'react-final-form';

// Import components
import { SelectCarFiltersInfo } from './components';

// Import utilities
import { useEditBookingProvider } from 'views/Bookings/EditBooking/context';
import useSelectCarFilters from './useSelectCarFilters';

const SelectCarFilters = () => {
	const { filters, handleFiltersChange, cars, carsFetching } =
		useSelectCarFilters();

	const { setChange } = useEditBookingProvider();

	const { change } = useForm();

	useEffect(() => {
		setChange(() => change);
	}, [change, setChange]);

	return (
		<Form onSubmit={handleFiltersChange} initialValues={filters}>
			{() => <SelectCarFiltersInfo cars={cars} carsFetching={carsFetching} />}
		</Form>
	);
};

export default SelectCarFilters;
