import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CARS_LOG_HISTORY_QUERY } from 'helpers';

const useCarsLogHistory = ({ uuid }) => {
	const path = `/v2/messaging/event-store/${uuid}/log`;

	const { data, isFetching, isError, error } = useQuery(
		[CARS_LOG_HISTORY_QUERY, uuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError, error };
};

export default useCarsLogHistory;
