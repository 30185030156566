import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Import helpers
import { convertApiFormErrors, replacePreviewBookingUrl } from 'helpers';
import { extendContract } from 'store/actions/contract';

// Import utils
import { useExtendContractProvider } from '../../context';
import { useMessage, useTranslations } from 'components/utilities';

export const useExtendForm = () => {
	const [isLoading, setIsLoading] = useState(false);

	const { t } = useTranslations();

	const { id } = useParams();

	const { push } = useHistory();

	const { message } = useMessage();

	const handleOnClose = () => {
		push(replacePreviewBookingUrl(id));
	};

	const { contractNumber, isOtpRequired } = useExtendContractProvider();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setIsLoading(true);

		const options = {
			callbackSuccess: () => {
				setIsLoading(false);
				message.success(t('common.messages.successfully_done'));
				handleOnClose();
			},
			errorCallback: (error) => {
				const isErrorString = typeof error === 'string';
				message.error(
					isErrorString ? error : t('common.messages.error_message')
				);
				setIsLoading(false);
				if (error && !isErrorString) apiErrors = convertApiFormErrors(error);
			},
			contractNumber,
			isOtpRequired,
			values,
		};

		await extendContract(options);

		return apiErrors;
	};

	return {
		handleOnSubmit,
		handleOnClose,
		isLoading,
	};
};
