import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	wrapper: { margin: '10px 0' },
	editorClassName: {
		border: '1px solid rgb(215, 215, 215)',
		minHeight: '200px',
	},
	editorErrorClassName: {
		border: `1px solid ${theme.palette.error.main}`,
		minHeight: '200px',
	},
	wrapperClassName: { minHeight: '200px' },
}));

export default useStyles;
