import React, { useEffect } from 'react';
import { array } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';

// Import utils
import { useInitiateContractProvider } from 'views/Bookings/InitiateContract/context';
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { FUEL_AVAILABILITIES } from 'helpers/variables';
import { findSelectedPlate } from 'helpers';
import { marks } from './helpers';

// Import styles
import { FuelTypeSlider } from './VehicleDetails.styles';

export const VehicleDetails = ({ fields }) => {
	const { vehiclePlates } = useInitiateContractProvider();
	const { renderFields } = useCommonFields();
	const { values } = useFormState();
	const { t } = useTranslations();
	const { change } = useForm();

	const currentPlateNumber = values.plate;

	const handleChange = (_, newValue) => {
		const convertedValue = FUEL_AVAILABILITIES[newValue];
		change('rent_status.available_fuel', convertedValue);
	};

	useEffect(() => {
		const selectedPlate = findSelectedPlate({
			vehiclePlates,
			currentPlateNumber,
		});

		if (selectedPlate) {
			const { uuid } = selectedPlate || {};
			change('vehicle_details.vehicle_plate_uuid', uuid);
		}
		//eslint-disable-next-line
	}, [currentPlateNumber]);

	return (
		<Grid container spacing={4}>
			{fields.map(renderFields)}
			<Grid item xs={12} md={8}>
				<Typography id="fuel-level-slider" variant="h6" gutterBottom>
					{t('contract.create_contract_form.vehicle_details.fuel_level')}
				</Typography>
				<FuelTypeSlider
					aria-labelledby="fuel-level-slider"
					onChange={handleChange}
					defaultValue={2}
					track={false}
					marks={marks}
					step={1}
					min={0}
					max={4}
				/>
			</Grid>
		</Grid>
	);
};

VehicleDetails.propTypes = {
	fields: array,
};
