import React from 'react';
import { object, func } from 'prop-types';
import { TableCell, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

// Import components
import EditCarsForm from './EditCarsForm';

const useStyles = makeStyles((theme) => ({
	cell: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(0.5),
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

const EditModalTableCell = ({ cell }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => setOpen(true);

	const handleClose = () => setOpen(false);

	const { available, uuid } = cell.row.original;
	const quantity = parseInt(available);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Button
				onClick={handleClickOpen}
				color="primary"
				className={classes.cell}
			>
				<EditIcon className={classes.icon} fontSize="small" />
				{t('table.headers.edit')}
			</Button>
			<EditCarsForm
				open={open}
				uuid={uuid}
				quantity={quantity}
				closeModal={handleClose}
			/>
		</TableCell>
	);
};

EditModalTableCell.propTypes = {
	cell: object.isRequired,
	deleteAction: func,
};

export default EditModalTableCell;
