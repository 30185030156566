import React from 'react';
import { object } from 'prop-types';
import { TableCell, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

// Import utils
import { getDeliveryStatusLabels } from 'helpers';

// Import components
import { ChipLabel } from 'components/elements';

export const DeliveryTimeAndLocationTableCell = ({ cell }) => {
	const { pathname } = useLocation();
	const labels = getDeliveryStatusLabels({
		delivery: cell.row.original.delivery,
		pathname,
	});

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container spacing={1}>
				{labels.map(({ label, color }, index) => (
					<Grid item key={index}>
						<ChipLabel color={color} label={label} />
					</Grid>
				))}
			</Grid>
		</TableCell>
	);
};

DeliveryTimeAndLocationTableCell.propTypes = {
	cell: object.isRequired,
};
