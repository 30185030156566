import { useState, useRef } from 'react';

// Import utilities
import { useLeasingCarsImport, useLeasingCarsExport } from 'queries';
import { useMessage, useTranslations } from 'components/utilities';

// Import store
import { importLeasingCarsData, exportLeasingCarsData } from 'store/paths';

// Import services
import services from 'services/services';
import myAxios from 'services/myAxiosFactory';

export const useLeasingImportExportDialog = ({ officeId }) => {
	const { t } = useTranslations();
	const { message } = useMessage();

	const [isExportAllowed, setIsExportAllowed] = useState(false);
	const [isImportAllowed, setIsImportAllowed] = useState(false);
	const [importData, setImportData] = useState('');
	const hiddenFileInputRef = useRef(null);

	const importDataUrl = importLeasingCarsData(officeId);
	const exportDataUrl = exportLeasingCarsData(officeId);

	// ==== Export functionalities ===
	const fetchExportData = async () => {
		const { data } = await services.get(exportDataUrl);

		const link = document.createElement('a');
		link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
		link.download = 'leasing_cars_data.csv';
		link.click();
	};

	const handleLeasingCarsExport = () => setIsExportAllowed(true);

	const handleExportError = () =>
		message.error(t('common.messages.error_message'));

	const handleExportSettled = () => setIsExportAllowed(false);

	const { isExportLoading } = useLeasingCarsExport({
		isExportAllowed,
		handleExportError,
		handleExportSettled,
		fetchExportData,
	});

	// ==== Import functionalities ===
	const handleImportButtonClick = () => {
		hiddenFileInputRef.current.click();
	};

	const handleImportInputChange = async (event) => {
		setImportData(event.target.files[0]);
		setIsImportAllowed(true);
	};

	const importDataFunction = async () => {
		let formData = new FormData();
		formData.append('file', importData);

		await myAxios().post(importDataUrl, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	const handleImportError = (error) => {
		const errorMessage = error?.response?.data?.errors?.file?.[0];
		if (errorMessage) {
			message.error(errorMessage);
		} else {
			message.error(t('common.messages.error_message'));
		}
	};

	const handleImportSuccess = () =>
		message.success(t('common.messages.successfully_done'));

	const handleImportSettled = () => setIsImportAllowed(false);

	const { isImportLoading } = useLeasingCarsImport({
		isImportAllowed,
		importDataFunction,
		handleImportError,
		handleImportSuccess,
		handleImportSettled,
	});

	return {
		isExportLoading,
		isImportLoading,
		hiddenFileInputRef,
		handleExport: handleLeasingCarsExport,
		handleImportButtonClick,
		handleImportInputChange,
	};
};
