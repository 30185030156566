import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { OFFICE_COLLECTION_QUERY } from 'helpers';

const useOfficeCollection = ({ bookingUuid, body = {} }) => {
	const path = `/v2/booking/${bookingUuid}/items/search/office/collection`;

	const { data, isFetching } = useQuery(
		[OFFICE_COLLECTION_QUERY, JSON.stringify(body)],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};

export default useOfficeCollection;
