import React from 'react';
import { TableCell, Tooltip } from '@material-ui/core';
import { object } from 'prop-types';

// Import component
import { CopyToClipboard } from 'components/elements';

// import helpers
import { getCopyActions, getShortenIban } from './helpers';

// import styles
import { useStyles } from './BankNameIbanTableCell.styles';

export const BankNameIbanTableCell = ({ cell }) => {
	const { bank_name: bankName, iban } = cell.row.original.compensation;
	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{bankName}
			<br />
			<Tooltip
				title={<CopyToClipboard actions={getCopyActions(iban)} />}
				placement="right"
				interactive
				classes={{ tooltip: classes.tooltip }}
			>
				<span>{getShortenIban(iban)}</span>
			</Tooltip>
		</TableCell>
	);
};

BankNameIbanTableCell.propTypes = {
	cell: object,
};
