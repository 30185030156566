import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	item: {},
	vehicleList: [],
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ------------ LEASING CAR DETAILS --------------
			case types.EDIT_LEASING_CAR_FORM_FETCH_DETAILS_LOADING:
				draft.isLoaded = false;
				draft.isError = false;
				break;

			case types.EDIT_LEASING_CAR_FORM_FETCH_DETAILS_SUCCESS:
				draft.item = action.payload.item || {};
				draft.vehicleList = action.payload.vehicleList || [];
				draft.isLoaded = true;
				break;

			case types.EDIT_LEASING_CAR_FORM_FETCH_DETAILS_ERROR:
				draft.error = action.payload;
				draft.isLoaded = true;
				draft.isError = true;
				break;

			case types.CLEAR_LEASING_CAR_INFO:
				draft.item = {};
				draft.isLoaded = false;
				draft.isError = false;
				break;

			default:
				return state;
		}
	});
