export const INITIAL_VALUES = {
	maker_name: {
		en: '',
		ar: '',
	},
	model_name: {
		en: '',
		ar: '',
	},
	maker_uuid: '',
	auto: {
		model_id: '',
	},
	seo: {
		title: {
			en: '',
			ar: '',
		},
		keywords: {
			en: '',
			ar: '',
		},
		description: {
			en: '',
			ar: '',
		},
	},
};
