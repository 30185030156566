import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { node } from 'prop-types';
import { Box } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import components
import { Loader } from 'components/elements';

// Import store
import { signOut } from 'store/actions';

export const DashboardTemplate = ({ children }) => {
	const { isUserProfileLoaded, profileError } = useSelector(
		(state) => state.auth
	);
	const dispatch = useDispatch();
	const classes = useStyles();

	const status = profileError?.status;

	useEffect(() => {
		if (status === 403) {
			signOut()(dispatch);
		}
		// eslint-disable-next-line
	}, [status]);

	if (!isUserProfileLoaded) {
		return (
			<Box className={classes.wrapper}>
				<Loader />
			</Box>
		);
	}

	return children;
};

DashboardTemplate.propTypes = {
	children: node,
};
