export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
};

export const columns = [
	{
		Header: 'cms.cities.table.headers.name',
		accessor: 'name',
		isHidden: false,
	},
	{
		Header: 'cms.cities.table.headers.country_code',
		accessor: 'country_code',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
		isHidden: false,
		filterable: true,
	},
];
