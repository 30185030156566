import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { Box, Grid, Tooltip, Zoom } from '@material-ui/core';

// Import components
import { TextFieldAdapter } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

const TabPanelContent = ({
	section,
	lang,
	titleName = '',
	keywordsName = '',
	descriptionName = '',
}) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid container spacing={3}>
			<Grid item md={12} xs={12}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="text"
					label={t('form.seo.title_field')}
					name={titleName ? `${titleName}.${lang}` : `${section}.title.${lang}`}
					margin="dense"
					variant="outlined"
					disabled={submitting}
				/>
			</Grid>
			<Grid item md={12} xs={12}>
				<Tooltip
					TransitionComponent={Zoom}
					disableHoverListener
					placement="top-start"
					title={t('form.seo.keywords_comma_separated') || ''}
					arrow
				>
					<Box>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="text"
							label={t('form.seo.keywords')}
							name={
								keywordsName
									? `${keywordsName}.${lang}`
									: `${section}.keywords.${lang}`
							}
							margin="dense"
							variant="outlined"
							disabled={submitting}
						/>
					</Box>
				</Tooltip>
			</Grid>
			<Grid item md={12} xs={12}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="text"
					label={t('form.seo.description')}
					name={
						descriptionName
							? `${descriptionName}.${lang}`
							: `${section}.description.${lang}`
					}
					margin="dense"
					variant="outlined"
					disabled={submitting}
					multiline
				/>
			</Grid>
		</Grid>
	);
};

TabPanelContent.propTypes = {
	section: string.isRequired,
	lang: string.isRequired,
	titleName: string,
	keywordsName: string,
	descriptionName: string,
};

export default TabPanelContent;
