import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { profileDataSelector } from 'store/selectors';

// Import components
import { CustomerWalletContent, Card } from 'components/elements';
import { AddDialog, ClearDialog } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';

const CustomerWallet = ({ uuid }) => {
	const [cardActions, setCardActions] = useState(null);

	const { t } = useTranslations();

	const {
		customer_wallet: { is_manager },
	} = useSelector(profileDataSelector);

	const actions = (
		<Grid container alignItems="center" spacing={1}>
			{is_manager && (
				<>
					<Grid item>
						<ClearDialog />
					</Grid>
					<Grid item>
						<AddDialog />
					</Grid>
				</>
			)}
			<Grid item>
				<Typography variant="h6" color="primary">
					{cardActions}
				</Typography>
			</Grid>
		</Grid>
	);

	return (
		<Card
			title={t('customers.preview.wallet.title')}
			actions={actions}
			xs={12}
			md={8}
			lg={8}
		>
			<CustomerWalletContent uuid={uuid} setCardActions={setCardActions} />
		</Card>
	);
};

CustomerWallet.propTypes = {
	uuid: string,
};

export default CustomerWallet;
