// ********************  TABLE  ********************

export const fetchLeasingCarsListUrl = ({ queryParams, id }) =>
	`dashboard/office-vehicle/list${
		!queryParams ? '?' : `${queryParams}&`
	}office_uuid=${id}`;

export const updateActiveLeasingCarUrl = ({ id, isActive, officeId }) =>
	`dashboard/office-vehicle/${id}/${
		isActive ? 'deactivate' : 'activate'
	}?office_uuid=${officeId}`;
