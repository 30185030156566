// Import components
import { RegistrationProcess } from '../../Customers';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: RegistrationProcess,
		customKey: 'registration_process',
	},
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: '/customers/registration/add',
		text: 'customers.whitelist.toolbar.button',
	},
];
