import * as types from 'store/types';
import services from 'services/services';
import {
	showRatingReasonUrl,
	compensationTypesUrl,
	submitRatingReasonsFormUrl,
} from 'store/paths';

// Import helpers
import { convertSubmitData } from './helpers';

export const fetchRatingReasonsFormResources =
	({ itemId, isEdit, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.RATING_REASONS_FORM_FETCH_DATA_LOADING,
			});

			const typesDataPromise = services.get(compensationTypesUrl, cancelToken);

			const allPromises = [typesDataPromise];

			if (isEdit) {
				const ratingReasonPromise = services.get(
					showRatingReasonUrl(itemId),
					cancelToken
				);

				allPromises.push(ratingReasonPromise);
			}

			const values = await Promise.all(allPromises);

			const [{ data: typesData }] = values;

			const ratingReasonData = values[1]?.data || {};

			dispatch({
				type: types.RATING_REASONS_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: ratingReasonData,
					compensationTypes: typesData || {},
				},
			});
		} catch (error) {
			const defaultError = { message: '' };
			const response = error.response?.data ?? defaultError;
			dispatch({
				type: types.RATING_REASONS_FORM_FETCH_DATA_ERROR,
				payload: response,
			});
		}
	};

export const submitRatingReasonsForm =
	({ values, itemId, isEdit, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			const newValues = convertSubmitData(values);

			await services.post(
				submitRatingReasonsFormUrl(itemId, isEdit),
				newValues
			);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
