import i18next from 'i18next';

const sectionName = 'customer_balance.table.dialog';

export const columns = [
	{
		header: `${sectionName}.payment`,
		accessor: 'payment',
		valueExtractor: ({ paid_at }) => paid_at,
	},
	{
		header: `${sectionName}.status`,
		accessor: 'status',
		valueExtractor: ({ is_success, exists }) =>
			i18next.t(
				`${sectionName}.${is_success ? 'success' : exists ? 'fail' : 'pending'}`
			),
	},
	{
		header: `${sectionName}.retries`,
		accessor: 'retries',
		valueExtractor: ({ retries, exists }) => (exists ? retries : '-'),
	},
	{
		header: `${sectionName}.due_date_days`,
		accessor: 'due_date_days',
		valueExtractor: ({ due_date_days }) => due_date_days || '-',
	},
	{
		header: `${sectionName}.amount`,
		accessor: 'amount_gross',
	},
	{
		header: `${sectionName}.source`,
		accessor: 'transaction_source',
	},
];
