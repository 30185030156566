export const convertData = (values) => {
	const { maker_uuid, seo, model_name, auto } = values;
	const convertedData = {
		maker: { uuid: maker_uuid },
		name: { ...model_name },
		auto,
		seo,
	};
	return convertedData;
};
