import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	AirportFilter,
	BaseTextFilter,
	BaseYesNoAllFilter,
	ServiceTypeFilter,
} from 'components/elements';
import { useTableContextProvider } from 'components/context';

// Import columns
import { columns, COLUMNS_ACCESSORS } from '../../../../columns';

// Import helpers
import { DATE_TYPES, SERVICES_TYPES } from 'helpers';

const { PICK_DATE_TIME, DROP_DATE_TIME } = COLUMNS_ACCESSORS;

export const FilterFields = () => {
	const { updateColumns, filters } = useTableContextProvider();
	const { date_type } = filters || {};
	const isDropOffSort = date_type === DATE_TYPES.DROP_DATE;

	useEffect(() => {
		updateColumns(
			columns.filter(
				({ accessor }) =>
					accessor !== (isDropOffSort ? PICK_DATE_TIME : DROP_DATE_TIME)
			)
		);
		// eslint-disable-next-line
	}, [isDropOffSort]);

	return (
		<Grid container spacing={3}>
			<BaseTextFilter
				name="license_number"
				label="bookings.filters.license_number"
			/>
			<BaseYesNoAllFilter name="is_kiosk" label="bookings.filters.kiosk" />
			<ServiceTypeFilter
				name="service_type"
				label="bookings.filters.service_type"
			/>

			<AirportFilter
				name="airport_uuid"
				label="bookings.filters.airports"
				dependName="service_type"
				dependValue={SERVICES_TYPES.AIRPORT}
			/>
		</Grid>
	);
};
