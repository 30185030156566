export const convertData = (values) => {
	const {
		details,
		image: { file_name, file_path },
	} = values;

	const company_uuid = details.company_uuid.filter((uuid) => uuid !== 'all');

	return {
		details: { ...details, company_uuid },
		image: { file_name, file_path },
	};
};
