export const getInitialValues = (fields) =>
	fields.reduce((obj, field) => {
		obj[field] = false;
		return obj;
	}, {});

export const setAdditionalColumns = (values, columns) => {
	const additionalColumns = Object.entries(values).reduce((acc, next) => {
		const column = columns.find((item) => item.accessor === next[0]);
		column && acc.push({ ...column, isHidden: next[1] });
		return acc;
	}, []);

	const convertedColumns = columns.map((item) => {
		const column = additionalColumns.find(
			(additional) => item.accessor === additional.accessor
		);
		return column ? { ...column, isHidden: !column.isHidden } : item;
	});

	return convertedColumns;
};
