import React from 'react';
import PropTypes from 'prop-types';
import {
	TableRow,
	TableCell,
	IconButton,
	List,
	ListItem,
	Typography,
} from '@material-ui/core';
import get from 'lodash/get';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Import components
import TransactionHistoryRowActions from './TransactionHistoryRowActions';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from '../columns';

// Import utilities
import { useTranslations } from 'components/utilities';

const { DROPDOWN, ACTIONS } = COLUMNS_ACCESSORS;

const TransactionHistoryRow = ({ row }) => {
	const { t } = useTranslations();

	const [open, setOpen] = React.useState(false);

	return (
		<>
			<TableRow>
				{columns.map(({ accessor }) => {
					switch (accessor) {
						case DROPDOWN:
							return (
								<TableCell key={accessor}>
									<IconButton size="small" onClick={() => setOpen(!open)}>
										{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
									</IconButton>
								</TableCell>
							);
						case ACTIONS:
							return (
								<TableCell key={accessor}>
									<TransactionHistoryRowActions transaction={row} />
								</TableCell>
							);
						default:
							return <TableCell key={accessor}>{get(row, accessor)}</TableCell>;
					}
				})}
			</TableRow>
			{open && (
				<TableRow>
					<TableCell colSpan={6}>
						<List>
							<ListItem>
								<Typography variant="h6">
									{t('bookings.transaction_history.table.meta.code')}:&nbsp;
								</Typography>
								<Typography>{row.meta?.code || '---'}</Typography>
							</ListItem>
							<ListItem>
								<Typography variant="h6">
									{t('bookings.transaction_history.table.meta.message')}:&nbsp;
								</Typography>
								<Typography>{row.meta?.message || '---'}</Typography>
							</ListItem>
							<ListItem>
								<Typography variant="h6">
									{t('bookings.transaction_history.table.meta.payment_id')}
									:&nbsp;
								</Typography>
								<Typography>{row.payment_id || '---'}</Typography>
							</ListItem>
						</List>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

TransactionHistoryRow.propTypes = {
	row: PropTypes.object,
};

export default TransactionHistoryRow;
