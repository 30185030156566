import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	removeButton: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
	heading: {
		color: theme.palette.gray.label,
	},
}));
