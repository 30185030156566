import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	removeButton: ({ isAddCoordinatorButtonVisible }) => ({
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
		marginLeft: isAddCoordinatorButtonVisible ? '16px' : 0,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	}),
	heading: {
		color: theme.palette.gray.label,
	},
}));
