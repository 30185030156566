import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CarCollectionIcon = (props) => (
	<SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
		<path
			d="M18.4487 14.6504H19.8613"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.7603 14.6504H14.1729"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.0537 12.5315L10.6418 11.8252"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.5669 12.5315L21.9795 11.8252"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.6088 9.00049C19.2652 9.00056 19.8351 9.45252 19.9847 10.0916L20.585 12.6527L19.9847 10.0916C19.8351 9.45252 19.2652 9.00056 18.6088 9.00049H14.0129C13.3566 9.00037 12.7868 9.45249 12.6376 10.0916L12.0374 12.6527"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.6414 15.0033V14.4764C10.6414 13.4339 11.4857 12.5884 12.5282 12.5869H20.0894C20.5906 12.5867 21.0714 12.7857 21.4258 13.1401C21.7803 13.4944 21.9795 13.9751 21.9795 14.4764V17.671C21.9795 18.0394 21.6809 18.338 21.3125 18.338H19.8213C19.4529 18.338 19.1543 18.0394 19.1543 17.671V16.7687H14"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.5059 16.9932H6.50919"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.50586 14.9961L6.49353 17.0001L8.50586 19.0041"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
