import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { SUBSCRIPTION_EXTEND_CALCULATION_QUERY } from 'helpers';

export const useExtendSubscriptionCalculation = ({ uuid, enabled }) => {
	const path = `/v2/booking/${uuid}/extend-subscription`;

	const { data, isLoading, isError, error } = useQuery(
		[SUBSCRIPTION_EXTEND_CALCULATION_QUERY, uuid],
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60,
			retry: 1,
			enabled,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isLoading, isError, error };
};
