import React, { useState } from 'react';
import { node, oneOfType, arrayOf } from 'prop-types';

// Import helpers and utils
import { getInitialFilters } from './helpers';
import { useProfile } from 'components/utilities';

export const FiltersContext = React.createContext();

export const FiltersProvider = ({ children }) => {
	const { assignedOfficesUuids, companyUuid, isCompanyManager } = useProfile();
	const initialFilters = getInitialFilters({
		assignedOfficesUuids,
		isCompanyManager,
		companyUuid,
	});

	const [filters, setFilters] = useState(initialFilters);

	return (
		<FiltersContext.Provider value={{ filters, setFilters }}>
			{children}
		</FiltersContext.Provider>
	);
};

FiltersProvider.propTypes = {
	children: oneOfType([node, arrayOf(node)]),
};
