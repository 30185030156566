import React, { useEffect, useRef } from 'react';
import { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { CircularProgress, useTheme } from '@material-ui/core';
import { fetchBookingPreview } from 'store/actions';

// Import components
import { ComponentToPrint } from './ComponentToPrint';
import { Alert } from 'components/elements';
import { Topbar } from './components';

//Import styles
import useStyles from './styles';

const PrintBooking = ({ location }) => {
	const componentRef = useRef();

	const theme = useTheme();

	const {
		isLoaded: bookingLoaded,
		item,
		isError: bookingError,
		error,
	} = useSelector((store) => store.bookings.preview);
	const dispatch = useDispatch();

	const classes = useStyles();

	const { id } = useParams();

	const urlParams = new URLSearchParams(location.search);
	const language = urlParams.get('lng');
	const directions = theme.direction;

	useEffect(() => {
		document.title = 'Telgani | Booking Details Print';
		document.body.style.direction = directions;
		fetchBookingPreview({ id })(dispatch);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		bookingLoaded && handlePrint();
		//eslint-disable-next-line
	}, [bookingLoaded]);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const renderContent = () =>
		bookingLoaded &&
		!bookingError && (
			<>
				<Topbar handlePrint={handlePrint} lng={language} />
				<ComponentToPrint
					ref={componentRef}
					direction={directions}
					bookingData={item}
					language={language}
				/>
			</>
		);

	return (
		<>
			{bookingError && bookingLoaded && <Alert message={error.message} />}
			{!bookingLoaded && (
				<div className={classes.loader}>
					<CircularProgress size={70} color="primary" />
				</div>
			)}
			{renderContent()}
		</>
	);
};

PrintBooking.propTypes = {
	location: object.isRequired,
};

export default PrintBooking;
