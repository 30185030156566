import React from 'react';
import PropTypes from 'prop-types';
import { useCompanies } from 'queries';
import { MenuItem, TextField } from '@material-ui/core';
import { useTranslations } from 'components/utilities';

const CompanySelect = ({ value, onChange }) => {
	const { data: companies } = useCompanies();
	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	if (!companies) {
		return null;
	}

	return (
		<TextField
			select
			onChange={onChange}
			value={value}
			variant="outlined"
			label={t('drop_cities.companyNameLabel')}
			fullWidth
		>
			{companies.map(({ uuid, name }) => (
				<MenuItem key={uuid} value={uuid}>
					{name[lng]}
				</MenuItem>
			))}
		</TextField>
	);
};

CompanySelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.string,
};

export default CompanySelect;
