// Import helpers
import { BOOKING_STATES } from 'helpers';

export const getTopic = (bookingState) => {
	switch (bookingState) {
		case BOOKING_STATES.CANCELED:
			return 'bookings.validation_topics.cancellation';
		case BOOKING_STATES.EARLY_RETURN:
			return 'bookings.booking_states.early_return';
		default:
			return null;
	}
};
