import { BOOKING_VALIDATION_TOPICS } from 'helpers';

const { COMPENSATION, CANCELLATION } = BOOKING_VALIDATION_TOPICS;

export const OPTIONS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: COMPENSATION,
		label: 'bookings.validation_topics.compensation',
	},
	{
		value: CANCELLATION,
		label: 'bookings.validation_topics.cancellation',
	},
];
