import React from 'react';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { Card } from 'components/elements';
import PreviewFields from '../PreviewFields';
import { ExtendSubscription } from './components';

// Import helpers
import { FIELDS_PERIOD } from '../../helpers';

export const PeriodCard = () => {
	const { t } = useTranslations();

	return (
		<Card
			title={t('bookings.preview_booking.period.title')}
			actions={<ExtendSubscription />}
		>
			<PreviewFields fields={FIELDS_PERIOD} />
		</Card>
	);
};
