// Import helpers
import { FIELD_TYPES } from 'helpers';

export const EXTEND_FORM_FIELDS = ({ branches, paymentMethods }) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'working_branch_id',
		label: 'contract.create_contract_form.contract_details.working_branch',
		required: true,
		md: 4,
		options: branches,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'payment_method_code',
		label: 'contract.create_contract_form.payment_details.payment_method',
		required: true,
		md: 4,
		options: paymentMethods,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'paid',
		label: 'contract.create_contract_form.payment_details.paid',
		required: true,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'discount',
		label: 'contract.create_contract_form.payment_details.discount_percentage',
		required: true,
		min: 0,
		max: 100,
		md: 4,
	},
	{
		type: FIELD_TYPES.DATE,
		name: 'contract_end_date',
		label: 'contract.create_contract_form.contract_details.contract_end_date',
		required: true,
		minDate: false,
		md: 4,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'operator_id',
		label: 'contract.create_contract_form.contract_details.operator_id',
		md: 4,
		removeUpAndDownArrows: true,
	},
];
