import { EN } from 'helpers';
import i18next from 'i18next';

export const columns = [
	{
		Header: 'company.table.headers.company_name',
		accessor: 'company_name',
		valueExtractor: ({ name: { en, ar } }) =>
			EN === i18next.language ? en : ar,
	},
	{
		Header: 'company.table.headers.contact_person',
		accessor: 'contact_person',
	},
	{
		Header: 'company.table.headers.contact_number',
		accessor: 'contact_phone',
	},
	{
		Header: 'company.table.headers.city',
		accessor: 'city',
	},
	{
		Header: 'company.table.headers.is_active',
		accessor: 'active',
		valueExtractor: ({ active }) =>
			i18next.t(`common.${active ? 'yes' : 'no'}`),
	},
	{
		Header: 'company.table.headers.active_cars',
		accessor: 'active_cars_count',
	},
];
