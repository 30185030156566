import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { DELIVERY_LOCATION_QUERY } from 'helpers';

export const useDeliveryLocationQuery = ({ deliveryUuid }) => {
	const path = `/v2/delivery/${deliveryUuid}/location`;

	const { data, isFetching, isError, refetch, isFetched } = useQuery(
		[DELIVERY_LOCATION_QUERY, deliveryUuid],
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			enabled: !!deliveryUuid,
		}
	);

	const fetchedData = data?.data?.data || [];

	return { data: fetchedData, isFetching, isError, isFetched, refetch };
};
