import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { PROMO_CODE_STATISTICS } from 'helpers';

export const usePromoCodeStatistics = (payload) => {
	const { promoCode, state } = payload || {};

	const path = `v2/coupon/${promoCode}/statistics`;

	const { data, isFetching, isError } = useQuery(
		[PROMO_CODE_STATISTICS, JSON.stringify(payload)],
		async () => await services.post(path, state ? { state } : {}),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching, isError };
};
