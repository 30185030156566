import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { Card } from 'components/elements';
import { OfficeRatingInfo, ResetButton, SubmitButton } from './components';

// Import translations
import { useTranslations } from 'components/utilities';

// Import utilities
import { FormRPC } from 'components/utilities';

// Import actions and selectors
import { previewOfficeRatingSelector } from 'store/selectors';
import {
	fetchOfficeRatingFormResources,
	submitOfficeRatingForm,
} from 'store/actions';

export const OfficeRatingCard = () => {
	const { t } = useTranslations();

	return (
		<Grid item lg={6}>
			<FormRPC
				fetchFormResAction={fetchOfficeRatingFormResources}
				submitFormAction={submitOfficeRatingForm}
				store={previewOfficeRatingSelector}
				goBackPath=""
				title=""
				isCardLayout={false}
				isActionButtonsHidden
			>
				{() => (
					<Card
						title={t('bookings.office_rating.title')}
						lg={12}
						actions={
							<Grid container spacing={1}>
								<Grid item>
									<ResetButton />
								</Grid>

								<Grid item>
									<SubmitButton />
								</Grid>
							</Grid>
						}
					>
						<OfficeRatingInfo />
					</Card>
				)}
			</FormRPC>
		</Grid>
	);
};
