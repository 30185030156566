// Import utilities
import { useMessage, useTranslations } from 'components/utilities';

export const usePartnersPositionsForm = () => {
	const { t } = useTranslations();
	const { message } = useMessage();

	const callbackSuccess = () => {
		message.success(t('common.messages.successfully_saved'));
	};

	return {
		callbackSuccess,
	};
};
