import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Import utils
import { useMessage, useProfile, useTranslations } from 'components/utilities';
import { useCloseContractProvider } from '../../context';

// Import store
import { closeContract, signOut } from 'store/actions';

// Import helpers
import { convertApiFormErrors, replacePreviewBookingUrl } from 'helpers';
import { URLS } from 'components/routes';

export const useDataForm = () => {
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	const { isDriver } = useProfile();

	const { message } = useMessage();

	const { t } = useTranslations();

	const { contractNumber, bookingUuid, plateUuid, formOptions } =
		useCloseContractProvider();

	const history = useHistory();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setIsLoading(true);

		const options = {
			successCallback: async () => {
				message.success(
					t('contract.close_contract_form.successfully_closed_contract')
				);
				setIsLoading(false);
				if (isDriver) {
					await signOut()(dispatch);
					history.push(URLS.signInUrl);
				} else {
					history.push(replacePreviewBookingUrl(bookingUuid));
				}
			},
			errorCallback: (error) => {
				setIsLoading(false);
				const isErrorString = typeof error === 'string';

				message.error(
					isErrorString ? error : t('common.messages.error_message')
				);

				if (error && !isErrorString) {
					apiErrors = convertApiFormErrors(error);
				}
			},
			closureReasons: formOptions.closure_reasons,
			contractNumber,
			bookingUuid,
			plateUuid,
			values,
		};

		await closeContract(options);

		return apiErrors;
	};

	return {
		handleOnSubmit,
		isLoading,
	};
};
