// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

// Import components
import { TopBar } from './components';

export const TOOLBAR_CONTENT = () => [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: TopBar,
		customKey: 'topBar',
	},
];
