
export const ITEMS_ACCESSORS = {
    BASE_GROSS_PRICE: 'base_gross_price',
    OPTION_DRIVER: 'option_driver',
    OPTION_DELIVERY: 'option_delivery',
    OPTION_INSURANCE: 'option_insurance',
    OPTION_UNLIMITED_KM: 'option_unlimited_km',
    VAT: 'vat',
    TOTAL_PRICE: 'total_price'
};

export const items = [
    {
        accessor: 'individual_number',
        label: 'bookings.edit_booking.info.booking_id'
    },
    {
        accessor: 'customer.full_name',
        label: 'bookings.edit_booking.info.customer_name'
    },
    {
        accessor: 'period.pick_date',
        label: 'bookings.edit_booking.info.pick_up_date'
    },
    {
        accessor: 'period.drop_date',
        label: 'bookings.edit_booking.info.drop_off_date'
    },
    {
        accessor: 'period.days',
        label: 'bookings.edit_booking.info.number_of_days'
    },
    {
        accessor: 'item.name',
        label: 'bookings.edit_booking.info.car'
    },
    {
        accessor: ITEMS_ACCESSORS.BASE_GROSS_PRICE,
        label: 'bookings.edit_booking.info.price'
    },
    {
        accessor: 'period.pick_time',
        label: 'bookings.edit_booking.info.pick_up_time'
    },
    {
        accessor: ITEMS_ACCESSORS.OPTION_DRIVER,
        label: 'bookings.edit_booking.info.car_driver'
    },
    {
        accessor: ITEMS_ACCESSORS.OPTION_DELIVERY,
        label: 'bookings.edit_booking.info.car_delivery'
    },
    {
        accessor: ITEMS_ACCESSORS.OPTION_INSURANCE,
        label: 'bookings.edit_booking.info.cdw_insurance'
    },
    {
        accessor: ITEMS_ACCESSORS.OPTION_UNLIMITED_KM,
        label: 'bookings.edit_booking.info.unlimited_km'
    },
    {
        accessor: ITEMS_ACCESSORS.VAT,
        label: 'bookings.edit_booking.info.vat'
    },
    {
        accessor: ITEMS_ACCESSORS.TOTAL_PRICE,
        label: 'bookings.edit_booking.info.total_price'
    }
]