import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Import utils and helpers
import { useMessage, useToggle, useTranslations } from 'components/utilities';

// Import actions
import { earlyCancellation, fetchBookingPreview } from 'store/actions';

export const useEarlyReturnDialog = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { on: isOpen, toggle } = useToggle();
	const { id: bookingUuid } = useParams();
	const { message } = useMessage();
	const { t } = useTranslations();
	const dispatch = useDispatch();

	const title = t('bookings.preview_booking.early_return.title');

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		const options = {
			values,
			bookingUuid,
			successCallback: async () => {
				message.success(t('common.messages.successfully_done'));
				toggle();
				setIsLoading(false);
				await fetchBookingPreview({ id: bookingUuid })(dispatch);
			},
			errorCallback: (error) => {
				const errors = Object.values(error.response.data.errors).flat();
				errors.forEach((error) => message.error(error));
				apiErrors = errors;
				setIsLoading(false);
			},
		};

		setIsLoading(true);

		await earlyCancellation(options);

		return apiErrors;
	};

	return {
		handleOnSubmit,
		toggle,
		title,
		isOpen,
		isLoading,
	};
};
