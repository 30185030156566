import { TextFieldAdapter } from 'components/elements';

export const FIELDS_CAR_DETAILS = (section) => [
	{
		component: TextFieldAdapter,
		type: 'number',
		label: 'cars.form.details.available_cars',
		name: `carInfo.${section}.quantity`,
		md: 12,
		xs: 12,
		rows: 1,
		isRequired: true
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'cars.form.details.description_en',
		name: `carInfo.${section}.description.en`,
		md: 6,
		xs: 12,
		rows: 4,
		isRequired: false
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'cars.form.details.description_ar',
		name: `carInfo.${section}.description.ar`,
		md: 6,
		xs: 12,
		rows: 4,
		isRequired: false
	}
];
