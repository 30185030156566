import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'is_active',
};

export const columns = ({ lng }) => [
	{
		Header: 'urgent_reasons.table.headers.reason',
		accessor: `name.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
];
