export const columns = [
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'individual_number',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.customer_phone',
		accessor: 'customer_phone',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.booking_duration',
		accessor: 'booking_duration',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.service_type',
		accessor: 'service_type',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: 'pick_up_date',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.original_booking_price',
		accessor: 'amount_before_discount',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.amount_of_discount',
		accessor: 'discount_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.amount_after_discount',
		accessor: 'amount_after_discount',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.discount_code',
		accessor: 'discount_type',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.discount_percentage',
		accessor: 'discount_percentage',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.campaign_name',
		accessor: 'bank_name',
		disableSortBy: true,
	},
];
