import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import {
	CUSTOMER_PHONE_PREFIXES_QUERY,
	LOCAL_STORAGE_PHONE_PREFIXES,
} from 'helpers';

const usePhonePrefixes = () => {
	const path = '/v2/content/country-phones';

	const localStoragePhonePrefixes = ls.get(LOCAL_STORAGE_PHONE_PREFIXES);

	const { data, isFetching, isFetched } = useQuery(
		CUSTOMER_PHONE_PREFIXES_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: !localStoragePhonePrefixes }
	);

	const fetchedData = data?.data.data || [];

	useEffect(() => {
		if (!localStoragePhonePrefixes && isFetched && fetchedData) {
			ls.set(LOCAL_STORAGE_PHONE_PREFIXES, fetchedData, {
				ttl: 14 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStoragePhonePrefixes, isFetched, fetchedData]);

	const result = localStoragePhonePrefixes || fetchedData;

	return { data: result, isFetching };
};

export default usePhonePrefixes;
