import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import queries
import { useOfficesAssignedToUser } from 'queries';

// Import helpers
import { sortByName } from '../helpers';

const OfficeFilter = ({ label, name }) => {
	const { submitting } = useFormState();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data } = useOfficesAssignedToUser();

	if (!data) {
		return null;
	}

	const sortedOffices = sortByName(data, language);

	return (
		<Grid item md={3}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{sortedOffices.map(({ uuid, name }) => (
					<MenuItem key={uuid} value={uuid}>
						{name[language]}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

OfficeFilter.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export default OfficeFilter;
