import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	usageWrapper: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '8px',
		'& .MuiChip-label': {
			padding: 0,
		},
		'& .MuiChip-root': {
			justifyContent: 'flex-start',
		},
	},
	usageIndicator: {
		width: '80px',
		borderRadius: '10px',
		padding: '7px',
		fontWeight: 700,
		color: theme.palette.primary.main,
		backgroundColor: `${theme.palette.primary.main}26`,
	},
	usageLabel: {
		backgroundColor: theme.palette.white,
		border: 'none',
		margin: '0 0 2px',
		color: theme.palette.gray.label,
		fontSize: '14px',
		transform: 'scale(0.9) !important',
	},
}));
