import { useSelector } from 'react-redux';
import { useState } from 'react';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';

// Import store
import { profileDataSelector } from 'store/selectors';
import { setCustomerLimit } from 'store/actions';

export const useCustomerLimitDialog = ({ userUuid, toggle }) => {
	const [isButtonLoading, setButtonLoading] = useState(false);
	const { corporate } = useSelector(profileDataSelector);
	const { fetchData } = useTableContextProvider();
	const { message } = useMessage();
	const { t } = useTranslations();

	const {
		coordinator_booking_limit: coordinatorLimit,

		uuid: corporateUuid,
	} = corporate || {};

	const handleOnSubmit = async (values) => {
		let apiErrors = {};
		setButtonLoading(true);

		await setCustomerLimit({
			successCallback: async () => {
				await fetchData();
				message.success(t('common.messages.successfully_done'));
				setButtonLoading(false);
				toggle();
			},
			errorCallback: (error) => {
				if (error.response) {
					apiErrors = error.response.data.errors;
				}
				setButtonLoading(false);
				message.error(t('common.messages.error_message'));
			},
			corporateUuid,
			userUuid,
			values,
		});

		return apiErrors;
	};

	return {
		maximumCustomerLimitAmount: coordinatorLimit,
		isButtonLoading,
		handleOnSubmit,
	};
};
