// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { VALUE_TYPES } from 'helpers';
import { last } from 'lodash';

export const useCalculationItem = ({
	isServiceItem,
	variant,
	label,
	value,
}) => {
	const { t } = useTranslations();

	const isFree = () => {
		const labelEnding = last(label.split('.'));
		return +value === 0 && labelEnding !== 'discount';
	};

	const getLabel = () => {
		const sign = isServiceItem ? '+ ' : '';
		return sign + t(label);
	};

	const getValue = () => {
		switch (variant) {
			case VALUE_TYPES.POSITIVE:
				return `+ ${t('currency.sr')} ${value}`;
			case VALUE_TYPES.NEGATIVE:
				return `- ${t('currency.sr')} ${value}`;
			default:
				return `${t('currency.sr')} ${value}`;
		}
	};

	const convertedLabel = getLabel();
	const convertedValue = isFree()
		? t('bookings.invoice_print.payment_details.free')
		: getValue();

	return {
		convertedLabel,
		convertedValue,
	};
};
