import React from 'react';
import { object, string } from 'prop-types';
import { Chip, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './styles';

// Import utilities
import { useBookingStatusChip } from './useBookingStatusChip';

// Import translations
import { useTranslations } from 'components/utilities';

const BookingStatusChip = ({ booking, state }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { statusType, status, statusDescription } = useBookingStatusChip({
		booking,
		state,
	});

	return (
		<Tooltip title={statusDescription}>
			<Chip
				label={t(`bookings.booking_states.${status}`)}
				className={clsx(classes.chip, classes[statusType])}
			/>
		</Tooltip>
	);
};

BookingStatusChip.propTypes = {
	booking: object,
	state: string,
};

export default BookingStatusChip;
