import React from 'react';
import { func } from 'prop-types';
import { Trans } from 'react-i18next';
import { Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Import translations
import { useTranslations } from 'components/utilities';

// Import icons
import { VerifyIcon } from 'icons';
import { elmLogo } from 'assets/images';

// Import styles
import { useStyles } from './styles';

export const ModalTitle = ({ toggle }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Grid container spacing={2} alignContent="center">
			<Grid item xs={1} container justifyContent="center" alignItems="center">
				<VerifyIcon color="primary" />
			</Grid>
			<Grid item xs={10} container>
				<Typography>
					<Trans i18nKey="bookings.preview_booking.customer.modal.title">
						The following data were verified automatically through the National
						Unified Access <strong>(Absher)</strong>
					</Trans>

					<img
						src={elmLogo}
						alt={t('bookings.preview_booking.customer.modal.title')}
						className={classes.elmLogo}
					/>
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<IconButton onClick={toggle} className={classes.closeButton}>
					<CloseIcon color="primary" className={classes.closeIcon} />
				</IconButton>
			</Grid>
		</Grid>
	);
};

ModalTitle.propTypes = {
	toggle: func.isRequired,
};
