import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	CircularProgress,
} from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useCompaniesList } from './useCompaniesList';
import { useCommonFields } from 'components/utilities';

export const CompaniesList = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	const { isFetching, COMPANY_FIELD } = useCompaniesList();

	return (
		<Card>
			<CardHeader title={t('sliders.form.companies.add_companies')} />
			<Divider />
			<CardContent>
				<Grid>
					{isFetching ? (
						<Grid item xs>
							<CircularProgress size={24} />
						</Grid>
					) : (
						renderFields(COMPANY_FIELD)
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};
