import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	MoreInfoButton,
	CompensationHistory,
	MakeCompensationDialog,
} from 'components/elements';

// Import helpers
import { LOCATIONS } from 'helpers';

// Import utilities
import { useCompensationHistoryDialog } from './useCompensationHistoryDialog';

export const CompensationHistoryDialog = () => {
	const { isAddButtonVisible, on, toggle } = useCompensationHistoryDialog();

	return (
		<Grid container justifyContent="center" alignItems="center" spacing={2}>
			{isAddButtonVisible && (
				<Grid item>
					<MakeCompensationDialog
						location={LOCATIONS.BOOKING_PREVIEW}
						shortButtonTitle
					/>
				</Grid>
			)}
			<Grid item>
				{on && <CompensationHistory open={on} onClose={toggle} />}
				<MoreInfoButton onClick={toggle} />
			</Grid>
		</Grid>
	);
};
