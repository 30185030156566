import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import components
import {
	IsSubscriptionSettledTableCell,
	LinkTableCell,
	NumberOfTriesTableCell,
	CustomerBalanceActionsTableCell,
	SubscriptionActiveTableCell,
	PaymentDayTableCell,
} from 'components/elements';
import { OutstandingBalance, PeriodDuration } from './components';

// Import translations
import { useProfile, useTranslations } from 'components/utilities';

// Import helpers
import { replaceCustomerPreviewUrl, replacePreviewBookingUrl } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

const {
	CUSTOMER,
	PICKUP_DROPOFF,
	NUMBER_OF_TRIES,
	OUT_STANDING_BALANCE,
	IS_SETTLED,
	IS_ACTIVE,
	ACTIONS,
	BOOKING_ID,
	SETTLED_DUES,
	NOT_SETTLED_DUES,
	PAYMENT_DAY,
	EARLY_RETURN_DATE,
	PERIOD_DURATION,
} = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const { t } = useTranslations();

	const { isAdmin } = useProfile();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			success_payments_count: successPaymentsCount,
			failed_payments_count: failedPaymentsCount,
			total_payments_count: totalPaymentsCount,
			debt_balance: debtBalance,
			customer_uuid: customerUuid,
			customer_name: customerName,
			customer_phone: customerPhone,
			booking_uuid: bookingUuid,
			booking_individual_number: bookingIndividualNumber,
			pickup_date: pickupDate,
			dropoff_date: dropoffDate,
			early_return_details: earlyReturnDetails,
			has_early_return_state: hasEarlyReturnState,
			period_duration: periodDuration,
		} = cell.row.original;

		const {
			return_date: earlyReturnDate,
			old_subscription_period: oldSubscriptionPeriod,
			new_subscription_period: newSubscriptionPeriod,
			early_return_transaction: earlyReturnTransaction,
		} = earlyReturnDetails || {};

		switch (cell.column.id) {
			case CUSTOMER:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceCustomerPreviewUrl(customerUuid)}
					>
						{customerName}
						<br />
						{customerPhone}
					</LinkTableCell>
				);

			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(bookingUuid)}
					>
						{bookingIndividualNumber}
					</LinkTableCell>
				);

			case PICKUP_DROPOFF:
				return (
					<TableCell key={cell.column.id} style={{ width: 60 }}>
						{pickupDate}
						<br />
						{dropoffDate}
					</TableCell>
				);

			case EARLY_RETURN_DATE:
				return (
					<TableCell key={cell.column.id}>
						{earlyReturnDate || t('common.not_available_shorthand')}
					</TableCell>
				);

			case PERIOD_DURATION:
				return (
					<PeriodDuration
						periodDuration={periodDuration}
						hasEarlyReturnState={hasEarlyReturnState}
						oldSubscriptionPeriod={oldSubscriptionPeriod}
						newSubscriptionPeriod={newSubscriptionPeriod}
						key={cell.column.id}
					/>
				);

			case OUT_STANDING_BALANCE:
				return (
					<OutstandingBalance
						hasEarlyReturnState={hasEarlyReturnState}
						transactionalType={earlyReturnTransaction?.transactional_type}
						transactionAmount={earlyReturnTransaction?.amount_gross}
						debtBalance={debtBalance}
						key={cell.column.id}
					/>
				);

			case NUMBER_OF_TRIES:
				return <NumberOfTriesTableCell key={cell.column.id} cell={cell} />;

			case IS_SETTLED:
				return (
					<IsSubscriptionSettledTableCell key={cell.column.id} cell={cell} />
				);

			case SETTLED_DUES:
				return (
					<TableCell key={cell.column.id}>
						{`${successPaymentsCount}/${totalPaymentsCount}`}
					</TableCell>
				);

			case NOT_SETTLED_DUES:
				return (
					<TableCell key={cell.column.id}>
						{`${failedPaymentsCount}/${totalPaymentsCount}`}
					</TableCell>
				);

			case PAYMENT_DAY:
				return <PaymentDayTableCell key={cell.column.id} cell={cell} />;

			case IS_ACTIVE:
				return (
					<SubscriptionActiveTableCell
						key={cell.column.id}
						disabled={!isAdmin}
						cell={cell}
					/>
				);

			case ACTIONS:
				return (
					<CustomerBalanceActionsTableCell key={cell.column.id} cell={cell} />
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
