import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import { APP_VERSION_QUERY, LOCAL_STORAGE_APP_VERSION } from 'helpers';

export const useAppVersion = () => {
	const path = `/version`;

	const localStorageAppVersion = ls.get(LOCAL_STORAGE_APP_VERSION);

	const { data, isFetched, isError, error } = useQuery(
		APP_VERSION_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: !localStorageAppVersion }
	);

	const fetchedData = data ? data?.data : {};
	const errorMessage = error ? error.message : '';

	useEffect(() => {
		const cleanup = () => {
			ls.remove(LOCAL_STORAGE_APP_VERSION);
		};

		window.addEventListener('beforeunload', cleanup);

		return () => {
			window.removeEventListener('beforeunload', cleanup);
		};
	}, []);

	useEffect(() => {
		if (!localStorageAppVersion && isFetched && fetchedData) {
			ls.set(LOCAL_STORAGE_APP_VERSION, fetchedData);
		}

		// eslint-disable-next-line
	}, [localStorageAppVersion, isFetched, fetchedData]);

	const result = localStorageAppVersion || fetchedData;

	return { data: result, isFetched, isError, errorMessage };
};
