import React from 'react';
import { object, string } from 'prop-types';
import { TableCell } from '@material-ui/core';
import PowerIcon from '@material-ui/icons/Power';

// Import component
import { ImagePreview } from 'components/elements';

// Import utilities
import { useToggle } from 'components/utilities';

// Import styles
import useStyles from './styles';

const ImageTableCell = ({ cell, imgUrl }) => {
	const classes = useStyles();

	const { on, toggle } = useToggle();

	const isExternal = cell.row.original.is_external;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<>
				<img
					className={classes.image}
					src={imgUrl}
					alt="Preview"
					onClick={toggle}
				/>
				<ImagePreview imgUrl={imgUrl} on={on} close={toggle} />
			</>
			{isExternal && <PowerIcon color="primary" />}
		</TableCell>
	);
};

ImageTableCell.propTypes = {
	cell: object.isRequired,
	imgUrl: string.isRequired,
};

export default ImageTableCell;
