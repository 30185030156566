import i18next from 'i18next';

export const columns = [
	{
		Header: 'employee_rewarding.table.headers.name',
		accessor: 'full_name',
	},
	{
		Header: 'employee_rewarding.table.headers.role',
		accessor: 'role',
		valueExtractor: ({ role }) => i18next.t(`common.roles.${role}`),
	},
	{
		Header: 'employee_rewarding.table.headers.rating',
		accessor: 'rating',
	},
	{
		Header: 'employee_rewarding.table.headers.orders_number',
		accessor: 'orders_number',
	},
	{
		Header: 'employee_rewarding.table.headers.is_active',
		accessor: 'is_active',
		valueExtractor: ({ is_active }) =>
			i18next.t(`common.${is_active ? 'yes' : 'no'}`),
	},
];
