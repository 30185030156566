import React from 'react';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

// Import components
import { FormContent } from './components';

// Import helpers
import { validationSchema } from './validation';
import { INITIAL_VALUES } from './helpers';
import { yupValidator } from 'helpers';
import { useAddCoordinatorStep } from './useAddCoordinatorStep';

export const AddCoordinatorStep = () => {
	const { handleOnSubmit, isLoading } = useAddCoordinatorStep();

	return (
		<Form
			initialValues={INITIAL_VALUES}
			mutators={{ ...arrayMutators }}
			onSubmit={handleOnSubmit}
			validate={(values) => yupValidator({ values, validationSchema })}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<FormContent isButtonLoading={isLoading} />
				</form>
			)}
		/>
	);
};
