import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles((theme) => ({
	textField: {
		marginTop: theme.spacing(2),
	},
	signInButton: {
		margin: theme.spacing(2, 0),
	},
	wrapper: {
		position: 'relative',
	},

	otpWrapper: {
		marginTop: theme.spacing(2),
	},
	otpLabel: {
		fontSize: 14,
		color: theme.palette.gray.label,
	},
}));

export const otpInputsStyles = {
	backgroundColor: theme?.palette.background[4],
};

export const otpContainerStyles = {
	justifyContent: 'center',
};
