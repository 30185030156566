import React from 'react';
import { object } from 'prop-types';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import useBookingDataPreview from './useBookingDataPreview';

const BookingDataPreview = ({ data }) => {
	const { previewFields } = useBookingDataPreview({ data });

	return (
		<Paper>
			<Box padding={3}>
				<Grid container spacing={3}>
					{previewFields.map((label) => (
						<Grid item md={3} key={label}>
							<Typography variant="h6">{label}</Typography>
						</Grid>
					))}
				</Grid>
			</Box>
		</Paper>
	);
};

BookingDataPreview.propTypes = {
	data: object,
};

export default BookingDataPreview;
