import React from 'react';
import { bool, string, func } from 'prop-types';
import {
	Dialog,
	DialogContent,
	CircularProgress,
	Box,
} from '@material-ui/core';

// Import query
import { useSubscriptionPaymentsQuery } from 'queries';

// Import components
import { Alert, ClosableDialogTitle } from 'components/elements';
import { SubscriptionPaymentsTable } from './components';

// Import translations
import { useTranslations } from 'components/utilities';

export const SubscriptionHistory = ({ uuid, on, onClose }) => {
	const { t } = useTranslations();

	const {
		isLoading,
		isError,
		error,
		data: payments,
	} = useSubscriptionPaymentsQuery({ uuid });

	return (
		<Dialog open={on} onClose={onClose} maxWidth="lg">
			<ClosableDialogTitle
				title={t('customer_balance.table.dialog.title')}
				onClose={onClose}
			/>
			<DialogContent>
				{isLoading && <CircularProgress />}
				{isError && (
					<Box py={2}>
						<Alert message={error.message} />
					</Box>
				)}
				{!isLoading && !isError && (
					<SubscriptionPaymentsTable payments={payments} />
				)}
			</DialogContent>
		</Dialog>
	);
};

SubscriptionHistory.propTypes = {
	uuid: string.isRequired,
	on: bool.isRequired,
	onClose: func.isRequired,
};
