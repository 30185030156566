import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
	serviceWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 20,
	},
	serviceItem: {
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	},
	icon: {
		width: '18px',
		height: '18px',
		'& path': {
			stroke: theme.palette.primary.main,
		},
	},
	serviceName: {
		fontWeight: 700,
		fontSize: 14,
		color: theme.palette.text.primary,
	},
	label: {
		fontWeight: 'bold',
		fontSize: 16,
	},
}));
