import React from 'react';
import {
	func,
	string,
	bool,
	object,
	oneOfType,
	node,
	arrayOf,
	number,
} from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CircularProgress,
	Link,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Grid,
} from '@material-ui/core';

// Import helpers
import { convertApiFormErrors } from 'helpers/form';
import { URLS } from 'components/routes';

// Import utilities
import { useForm, useTranslations, useMessage } from 'components/utilities';

// Import components
import { Alert, ButtonProgress } from 'components/elements';

// Import styles
import useStyles from './styles';

const FormRPC = ({
	submitFormAction,
	fetchFormResAction,
	store,
	goBackPath = URLS.dashboardUrl,
	isCardLayout = true,
	noValidate = false,
	initialValues = {},
	title = 'Office',
	validation = () => {},
	callbackFormSuccess = (history, goBackPath) =>
		goBackPath && history.push(goBackPath),
	children,
	lg = 9,
	md = 12,
	isActionButtonsHidden = false,
	isSubmitDisabled = false,
}) => {
	const classes = useStyles();
	const { message } = useMessage();

	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const { isEdit, itemId, carId, data } = useForm({
		fetchFormResAction,
		store,
	});

	const dispatch = useDispatch();
	const reduxStore = useStore();
	const history = useHistory();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};
		const options = {
			values,
			isEdit,
			itemId,
			carId,
			lng,
			message,
			callbackSuccess: () => callbackFormSuccess(history, goBackPath),
			errorCallback: (errors) => {
				if (errors) apiErrors = convertApiFormErrors(errors);
			},
		};
		await submitFormAction(options)(dispatch, reduxStore.getState);
		return apiErrors;
	};

	const renderActionButtons = (submitting) => (
		<CardActions classes={{ root: classes.cardActions }}>
			{!submitting && (
				<Link component={RouterLink} to={goBackPath} variant="h6">
					{t('table.modal.delete.btn_cancel') || ''}
				</Link>
			)}
			<ButtonProgress
				className={classes.buttonSave}
				type="submit"
				color="primary"
				variant="contained"
				isLoading={submitting}
				disabled={submitting || isSubmitDisabled}
			>
				{t('table.modal.edit.btn_save')}
			</ButtonProgress>
		</CardActions>
	);

	const setInitialValues =
		data.item && Object.keys(data.item).length > 0 ? data.item : initialValues;

	const renderContent = () => (
		<div className={classes.wrapper}>
			{data.isError && data.isLoaded && <Alert message={data.error.message} />}
			{isCardLayout && <CardHeader title={title} />}

			{!data.isLoaded && (
				<div className={classes.loader}>
					<CircularProgress size={48} color="primary" />
				</div>
			)}
			{data.isLoaded && !data.isError && (
				<Form
					initialValues={setInitialValues}
					keepDirtyOnReinitialize={true}
					onSubmit={handleOnSubmit}
					validate={validation}
					mutators={{ ...arrayMutators }}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit} noValidate={noValidate}>
							{isCardLayout ? (
								<CardContent>
									{children({ isEdit, itemId, carId, data })}
								</CardContent>
							) : (
								children({ isEdit, itemId, carId, data })
							)}

							{isCardLayout ? (
								<Card className={classes.removeBoxShadow}>
									{renderActionButtons(submitting)}
								</Card>
							) : (
								!isActionButtonsHidden && (
									<Grid container spacing={4}>
										<Grid item lg={lg} md={md} xs={12}>
											<Card>{renderActionButtons(submitting)}</Card>
										</Grid>
									</Grid>
								)
							)}
						</form>
					)}
				/>
			)}
		</div>
	);

	return isCardLayout ? (
		<Card>{renderContent()}</Card>
	) : (
		<div>{renderContent()}</div>
	);
};

FormRPC.propTypes = {
	validation: func,
	callbackFormSuccess: func,
	submitFormAction: func.isRequired,
	fetchFormResAction: func.isRequired,
	store: func.isRequired,
	goBackPath: string,
	title: oneOfType([node, arrayOf(node)]),
	isCardLayout: bool,
	initialValues: object,
	children: func.isRequired,
	isActionButtonsHidden: bool,
	noValidate: bool,
	lg: number,
	md: number,
	isSubmitDisabled: bool,
};

export default FormRPC;
