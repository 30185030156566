import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import {
	LOCAL_STORAGE_URGENT_ASSISTANCE_REASONS,
	URGENT_REASONS_QUERY,
} from 'helpers';

const useUrgentReasons = () => {
	const url = `/v2/booking/urgent-assistance-reasons`;

	const localStorageUrgentAssistanceReasons = ls.get(
		LOCAL_STORAGE_URGENT_ASSISTANCE_REASONS
	);

	const { data, isFetching, isFetched } = useQuery(
		URGENT_REASONS_QUERY,
		async () => await services.get(url),
		{
			refetchOnWindowFocus: false,
			enabled: !localStorageUrgentAssistanceReasons,
		}
	);

	const fetchedData = data ? data.data.data : [];

	useEffect(() => {
		if (
			!localStorageUrgentAssistanceReasons &&
			isFetched &&
			fetchedData.length
		) {
			ls.set(LOCAL_STORAGE_URGENT_ASSISTANCE_REASONS, fetchedData, {
				ttl: 7 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStorageUrgentAssistanceReasons, isFetched, fetchedData]);

	const result = localStorageUrgentAssistanceReasons || fetchedData;

	return { data: result, isFetching };
};

export default useUrgentReasons;
