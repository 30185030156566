import { makeStyles } from '@material-ui/styles';

export const useCardStyles = makeStyles((theme) => ({
	root: {
		borderRadius: theme.spacing(3),
		padding: theme.spacing(2),

		[theme.breakpoints.down('md')]: {
			boxShadow: '0px 20px 72px rgba(227, 230, 236, 0.85)',
		},
	},
}));
