import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 500,
	},
	subtitle: {
		color: theme.palette.gray.main,
		fontWeight: 500,
	},
}));
