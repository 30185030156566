import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { RATING_TYPES_QUERY } from 'helpers';

export const useRatingTypes = () => {
	const path = '/v2/rating-reason/rating-types';

	const { data, isFetching } = useQuery(
		RATING_TYPES_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};
