import React from 'react';
import { object } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { TableCell, Link } from '@material-ui/core';

// Import helpers
import { replaceAirportTerminalsUrl } from 'helpers';

const AddTerminalTableCell = ({ cell }) => {
	const airportId = cell.row.original.uuid;

	const { t } = useTranslations();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Link
				to={replaceAirportTerminalsUrl(airportId)}
				component={RouterLink}
				variant="h6"
			>
				{t('kiosk.airports.table.button.title')}
			</Link>
		</TableCell>
	);
};

AddTerminalTableCell.propTypes = {
	cell: object.isRequired,
};

export default AddTerminalTableCell;
