import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { array, object } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';

// Import utils
import { useCommonFields, useMediaQuerySizes } from 'components/utilities';

// Import helpers
import { isAuthorizationTypeCodeVisible } from 'helpers';

// Import components
import { ExternalAuthCountriesSelect } from '../../components';

export const ContractDetails = ({ fields, externalAuthorizationCountries }) => {
	const { renderFields } = useCommonFields();
	const { values } = useFormState();
	const { change } = useForm();
	const { isTabletSize } = useMediaQuerySizes();

	const isAuthTypeCodeVisible = isAuthorizationTypeCodeVisible(values);

	useEffect(() => {
		if (!isAuthTypeCodeVisible) {
			change('authorization_details.authorization_type_code', null);
		}
		//eslint-disable-next-line
	}, [isAuthTypeCodeVisible]);

	return (
		<Grid container spacing={isTabletSize ? 1 : 4}>
			{fields.topFields.map(renderFields)}
			<ExternalAuthCountriesSelect options={externalAuthorizationCountries} />
			{fields.bottomFields.map(renderFields)}
		</Grid>
	);
};

ContractDetails.propTypes = {
	externalAuthorizationCountries: array.isRequired,
	fields: object.isRequired,
};
