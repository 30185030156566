import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		display: 'grid',
		placeItems: 'center',
		height: '20px',
		width: '20px',
		border: '1px solid',
		borderColor: theme.palette.divider,
		borderRadius: 6,
		padding: 0,
		'&:hover': {
			borderColor: theme.palette.primary.main,
			backgroundColor: theme.palette.white,
		},
	},
	icon: {
		height: '12px',
		width: '12px',
	},
	sign: {
		lineHeight: '1',
		fontSize: 16,
	},
}));
