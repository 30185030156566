import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';

// Import components
import { LogoWrapper } from 'components/elements';

// Import classes
import { useStyles } from './styles';

// Import assets
import { DocumentIcon } from 'assets/icons';

// Import utilities
import { usePrintProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

// Import helpers
import { VAT_NUMBER } from 'helpers';

export const Header = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const {
		data: { individual_number },
	} = usePrintProvider();

	return (
		<Box className={classes.container}>
			<Box className={classes.wrapper}>
				<LogoWrapper />
				<Divider orientation="vertical" className={classes.divider} />
				<Box>
					<Typography variant="h5" className={classes.heading}>
						{t('bookings.invoice_print.header.receipt')} {individual_number}
					</Typography>
					<Typography variant="subtitle2">
						{t('bookings.invoice_print.header.vat_no')} {VAT_NUMBER}
					</Typography>
				</Box>
			</Box>
			<Box className={classes.wrapper}>
				<Box className={classes.documentLabel}>
					<Box className={classes.iconWrapper}>
						<DocumentIcon />
					</Box>
					<Box className={classes.textWrapper}>
						<Typography className={classes.text} component="span" variant="h6">
							{t('bookings.invoice_print.header.booking_no')}{' '}
							<Box component="strong">{individual_number}</Box>
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
