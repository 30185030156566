export const COLUMNS_ACCESSORS = {
	NOTE: 'note',
	STATUS: 'status',
	OFFICE_NAME: 'office_name',
	CUSTOMER_NAME: 'customer_name',
	BOOKING_ID: 'booking_id',
	DURATION: 'response_time',
	ACTIONS: 'actions',
	PICK_DATE_TIME: 'pick_date_time',
	DROP_DATE_TIME: 'drop_date_time',
	DELIVERY_ASSIGNMENT: 'delivery_assignment',
};

export const columns = [
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'individual_number',
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: 'customer_name',
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: 'vehicle',
	},
	{
		Header: 'bookings.table.headers.office_name',
		accessor: 'office_name',
	},
	{
		Header: 'bookings.table.headers.pick_date_time',
		accessor: 'pick_date',
		valueExtractor: (row) => `${row.pick_date} / ${row.pick_time}`,
	},
	{
		Header: 'bookings.table.headers.drop_date_time',
		accessor: 'drop_date',
		valueExtractor: (row) => `${row.drop_date} / ${row.pick_time}`,
	},
	{
		Header: 'bookings.table.headers.delivery_assignment_pickup',
		valueExtractor: (row) => row.delivery.pick_up_delivery.driver_name,
	},
	{
		Header: 'bookings.table.headers.delivery_assignment_dropoff',
		valueExtractor: (row) => row.delivery.drop_delivery.driver_name,
	},
	{
		Header: 'bookings.table.headers.delivery_status',
		accessor: 'total_discounted_gross_price',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_status',
		accessor: 'state',
	},
];
