import { addMonths, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { get, isObject } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

// Import utils
import { useInitiateContractProvider } from '../../../../context';
import { saveContract } from 'store/actions/contract';

// Import helpers and utils
import {
	YMD_DATE_FORMAT,
	getPlateData,
	replacePreviewBookingUrl,
	CONTRACT_STATUS,
} from 'helpers';
import { useMessage, useTranslations } from 'components/utilities';

export const useDataFormContent = ({ isLoading }) => {
	const { t } = useTranslations();
	const { message } = useMessage();
	const { values, submitting, errors, touched } = useFormState();
	const { batch, change } = useForm();
	const { pathname } = useLocation();
	const { push } = useHistory();

	const isDraft = pathname.includes(CONTRACT_STATUS.DRAFT);

	const [draftApiErrors, setDraftApiErrors] = useState([]);

	const [isDraftLoading, setIsDraftLoading] = useState(false);

	const [plateError, setPlateError] = useState('');
	const [isPlateDataLoading, setIsPlateDataLoading] = useState(false);

	const isExtraDriver = values.renter.is_extra_driver;

	const { isSectionFilled, addSection, initialValues, bookingUuid } =
		useInitiateContractProvider();

	const isButtonsDisabled = isLoading || submitting || isDraftLoading;

	const currentPlateNumber = values.plate;

	useEffect(() => {
		async function fetchData() {
			if (currentPlateNumber && !isPlateDataLoading) {
				setIsPlateDataLoading(true);
				try {
					const { data } = await getPlateData({
						plate: currentPlateNumber,
						bookingUuid,
					});
					const {
						extended_coverage_id,
						receive_branch_id,
						rent_policy_id,
						return_branch_id,
						working_branch_id,
						operator_id,
						fuel_type_code,
						full_fuel_cost,
					} = data || {};

					batch(() => {
						change(
							'contract_details.extended_coverage_id',
							extended_coverage_id ||
								initialValues?.contract_details?.extended_coverage_id
						);
						change(
							'authorization_details.receive_branch_id',
							receive_branch_id ||
								initialValues?.authorization_details?.receive_branch_id
						);
						change(
							'contract_details.rent_policy_id',
							rent_policy_id || initialValues?.contract_details?.rent_policy_id
						);
						change(
							'authorization_details.return_branch_id',
							return_branch_id ||
								initialValues?.authorization_details?.return_branch_id
						);
						change(
							'authorization_details.working_branch_id',
							working_branch_id ||
								initialValues?.authorization_details?.working_branch_id
						);
						change(
							'authorization_details.operator_id',
							operator_id || initialValues?.authorization_details?.operator_id
						);
						change(
							'rent_status.fuel_type_code',
							fuel_type_code || initialValues?.rent_status?.fuel_type_code
						);
						change(
							'payment_details.full_fuel_cost',
							full_fuel_cost || initialValues?.payment_details?.full_fuel_cost
						);
					});
					setPlateError('');
				} catch (error) {
					setPlateError(error ? error : '');
				}
				setIsPlateDataLoading(false);
			}
		}
		fetchData();
		// eslint-disable-next-line
	}, [currentPlateNumber]);

	const getOilChangeDate = (date = new Date()) =>
		format(addMonths(date, 1), YMD_DATE_FORMAT);

	useEffect(() => {
		if (initialValues) {
			const { contract_details, payment_details } = initialValues || {};

			const oilChangeData = contract_details?.contract_start_date
				? getOilChangeDate(new Date(contract_details.contract_start_date))
				: getOilChangeDate();
			change('rent_status.oil_change_date', oilChangeData);

			change(
				'authorization_details.allowed_km_per_day',
				payment_details?.free_kms || 150
			);
		}

		// eslint-disable-next-line
	}, [initialValues]);

	const checkIfFieldsHasErrors = (fields) => {
		const convertedFields = isObject(fields)
			? Object.values(fields)?.flat()
			: fields;

		return convertedFields.some(
			(field) => !!get(errors, field.name) && get(touched, field.name)
		);
	};

	const goToBookingDetails = (bookingUuid) =>
		push(replacePreviewBookingUrl(bookingUuid));

	const handleSaveAsDraft = async () => {
		let apiErrors = [];
		setIsDraftLoading(true);

		const saveContractOptions = {
			values,
			bookingUuid,
			isDraft: true,
			callbackSuccess: () => {
				message.success(t('common.messages.successfully_saved'));
				setIsDraftLoading(false);
				goToBookingDetails(bookingUuid);
			},
			errorCallback: (error) => {
				const isErrorString = typeof error === 'string';
				message.error(
					isErrorString ? error : t('common.messages.error_message')
				);
				setIsDraftLoading(false);

				const isErrorsObject = isObject(errors) && !isErrorString;
				if (error && isErrorsObject) {
					apiErrors = Object.values(error).flat();
				}
			},
		};

		await saveContract(saveContractOptions);

		apiErrors && setDraftApiErrors(apiErrors);
	};

	return {
		isButtonsDisabled,
		isSectionFilled,
		isExtraDriver,
		addSection,
		plateError,
		checkIfFieldsHasErrors,
		handleSaveAsDraft,
		draftApiErrors,
		isDraft,
	};
};
