import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { PLATE_FORM_OPTIONS_QUERY } from 'helpers';

export const usePlateFormOptions = () => {
	const path = '/v2/vehicle/plate/form-options';

	const { data, isError, isFetching } = useQuery(
		PLATE_FORM_OPTIONS_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : {};

	return { data: fetchedData, isFetching, isError };
};
