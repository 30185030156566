import React from 'react';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import utilities
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { LEASING_PLATE_NUMBER_FIELDS } from './helpers.js';

export const LeasingCarPlate = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();
	const { values } = useFormState();

	return (
		<Card>
			<CardHeader title={t('leasing_cars.form.plate.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={1}>
					<Grid container alignItems="flex-end" spacing={1}>
						{LEASING_PLATE_NUMBER_FIELDS({
							currentPlateNumber: values?.plate,
						}).map(renderFields)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
