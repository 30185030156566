import React from 'react';

// Import components
import { SuspendForm } from './components';

// Import utils
import { SuspendContractProvider } from './context';

export const SuspendContract = () => {
	return (
		<SuspendContractProvider>
			<SuspendForm />
		</SuspendContractProvider>
	);
};
