import { useParams } from 'react-router-dom';

// Import helpers
import { replaceAddLeasingCarUrl } from 'helpers';

// Import utilities
import { useToggle } from 'components/utilities';

export const useActionButtonsToolbar = () => {
	const { id: officeId } = useParams();
	const { on, toggle } = useToggle();

	const actionButtons = [
		{
			to: replaceAddLeasingCarUrl(officeId),
			icon: null,
			text: 'common.buttons.add_car',
		},
		{
			to: '',
			icon: null,
			text: 'common.buttons.upload_car',
			onClick: toggle,
			isLink: false,
		},
	];

	return {
		actionButtons,
		officeId,
		isDialogOpen: on,
		handleClose: toggle,
	};
};
