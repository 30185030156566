import { ACTIVE_CELL_STATES } from 'helpers/variables/table';

export const getActiveCellText = (state, textVariants) => {
	
	const { ACTIVE, INACTIVE, BLOCKED } = ACTIVE_CELL_STATES;
	const { activeText, inactiveText, blockedText } = textVariants;

	switch (state) {
		case ACTIVE:
			return activeText;
		case INACTIVE:
			return inactiveText;
		case BLOCKED:
			return blockedText;
		default:
			return null;
	}
};
