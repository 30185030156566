import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';
import { useExtendSubscription } from './useExtendSubscription';

// Import components
import {
	ButtonProgress,
	ClosableDialogTitle,
	LoadingWrapper,
} from 'components/elements';

// Import styles
import { useStyles } from './ExtendSubscription.styles';

export const ExtendSubscription = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		handleSubmitSubscriptionExtend,
		isConfirmButtonDisabled,
		toggleExtendConfirm,
		isExtendConfirmOpen,
		isExtendLoading,
		isExtendAllowed,
		newDropDate,
	} = useExtendSubscription();

	if (!isExtendAllowed) return null;

	return (
		<>
			<Button variant="contained" color="primary" onClick={toggleExtendConfirm}>
				{t('common.buttons.extend')}
			</Button>
			<Dialog
				onClose={toggleExtendConfirm}
				open={isExtendConfirmOpen}
				fullWidth
			>
				<ClosableDialogTitle
					onClose={toggleExtendConfirm}
					title={t('bookings.preview_booking.extend_subscription.title')}
				/>
				<DialogContent>
					<Typography className={classes.message}>
						{t('bookings.preview_booking.extend_subscription.confirm_message')}
					</Typography>

					{!newDropDate ? (
						<LoadingWrapper size={24} />
					) : (
						<Typography className={classes.message}>{`${t(
							'bookings.preview_booking.extend_subscription.new_date_message'
						)} ${newDropDate}`}</Typography>
					)}
				</DialogContent>
				<DialogActions className={classes.actions}>
					<ButtonProgress
						onClick={handleSubmitSubscriptionExtend}
						color="primary"
						variant="outlined"
						isLoading={isExtendLoading}
						disabled={isConfirmButtonDisabled}
					>
						{t('common.buttons.confirm')}
					</ButtonProgress>
				</DialogActions>
			</Dialog>
		</>
	);
};
