// ********************  form  ********************

export const getCitiesUrl = (params) =>
	`v2/city/list${params.queryParams || ''}`;

export const getCompanyDropCitiesUrl = (params) =>
	`/v2/supplier/business/company/${params.companyId}/drop-city/list`;

export const updateCompanyDropCitiesUrl = (params) =>
	`/v2/supplier/business/company/${params.companyId}/drop-city/refresh`;
