// Import helpers
import { BOOKING_STATES } from 'helpers';

const { PENDING, REJECTED } = BOOKING_STATES;

export const initialValues = {
	is_delivery: false,
	is_payment_online: false,
	is_pending_filter_applied: false,
	is_rejected_filter_applied: false,
	is_kiosk: 'all',
	company_uuid: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		is_payment_online: values.is_payment_online || null,
		is_kiosk: values.is_kiosk === 'all' ? null : values.is_kiosk,

		state: values.is_pending_filter_applied
			? PENDING
			: values.is_rejected_filter_applied
			? REJECTED
			: null,

		is_delivery: values.is_delivery || null,
		company_uuid: values.company_uuid === 'all' ? null : values.company_uuid,
	};
};
