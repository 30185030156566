import React from 'react';
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';

// Import components
import { AlertMessage, Card, LoadingWrapper } from 'components/elements';
import { FormContent } from './components';

// Import styles
import { useStyles } from './EditCorporateCustomerForm.styles';

// Import utils
import { useEditCorporateCustomerForm } from './useEditCorporateCustomerForm';
import { useTranslations } from 'components/utilities';

export const EditCorporateCustomerForm = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const {
		handleOnSubmit,
		isButtonLoading,
		initialValues,
		isFetching,
		isError,
	} = useEditCorporateCustomerForm();

	if (isError) {
		return (
			<Box className={classes.container}>
				<AlertMessage
					title={t('common.error')}
					description={t('common.messages.error_message')}
					variant="error"
				/>
			</Box>
		);
	}

	return (
		<Box className={classes.container}>
			<Card lg={12}>
				{isFetching ? (
					<LoadingWrapper />
				) : (
					<Form
						onSubmit={handleOnSubmit}
						initialValues={initialValues}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<FormContent isButtonLoading={isButtonLoading} />
							</form>
						)}
					></Form>
				)}
			</Card>
		</Box>
	);
};
