export default {
	formControl: {
		position: 'relative',
		paddingTop: 10,
		top: -10,
		'@media (max-width: 960px)': {
			top: 0,
			width: '40%',
		},
	},
};
