import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import store
import { ratingReasonsSelector } from 'store/selectors';
import {
	fetchRatingReasonsFormResources,
	submitRatingReasonsForm,
} from 'store/actions';

// Import components
import { FormRPC } from 'components/utilities';
import { ReasonDetails } from '../components';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import styles
import { useStyles } from './styles';

export const RatingReasonsForm = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchRatingReasonsFormResources}
				submitFormAction={submitRatingReasonsForm}
				isCardLayout={false}
				goBackPath={URLS.ratingReasonsUrl}
				store={ratingReasonsSelector}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<ReasonDetails />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
