import { useContext } from 'react';

// Import utils
import { TableActionsProviderContext } from './TableActionsProvider';

export const useTableActionsProvider = () => {
	const context = useContext(TableActionsProviderContext);

	return { ...context };
};
