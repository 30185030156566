import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { updateActiveEmployeePerformanceUser } from 'store/actions';

// Import components
import {
	ActiveTableCell,
	ExpandTableCell,
	SelectTableCell,
	SettlementRatingTableCell,
	SettledTableCell,
	RoleTableCell,
	EarningsTableCell,
} from 'components/elements';
import { HistoryActionButton } from '../HistoryActionButton';
import { EmployeeDetails } from '../EmployeeDetails';
import { useTableActionsProvider } from '../TableActionsProvider';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { toFixedWithoutZeros } from 'helpers';

const {
	EXPAND,
	SELECT,
	HISTORY,
	ACTIVE,
	RATING,
	SETTLED,
	ROLE,
	EARNINGS,
	TOTAL_EARNINGS,
} = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const {
		handleExpandedRow,
		checkIfRowExpanded,
		selectedParent,
		isIndeterminate,
		selectedChildren,
	} = useTableActionsProvider();

	const renderCell = (cell) => {
		const {
			user_uuid: uuid,
			user_is_active: employeeIsActive,
			average_rating: rating,
			settled_items_count: settledCount,
			not_settled_items_count: notSettledCount,
			user_role: userRole,
			earnings,
			total_earnings,
		} = cell.row.original || {};

		const { id, isHidden } = cell.column;

		if (isHidden) return null;
		switch (id) {
			case EXPAND:
				return (
					<ExpandTableCell
						key={id}
						cell={cell}
						onClick={() => handleExpandedRow(cell)}
						isOpen={selectedParent === uuid}
					/>
				);

			case SELECT:
				return (
					<SelectTableCell
						key={id}
						cell={cell}
						onChange={() => handleExpandedRow(cell)}
						checked={selectedParent === uuid && !!selectedChildren.length}
						indeterminate={isIndeterminate}
					/>
				);

			case ACTIVE:
				return (
					<ActiveTableCell
						key={id}
						activeAction={updateActiveEmployeePerformanceUser}
						cell={cell}
						isActive={!!employeeIsActive}
						id={uuid}
					/>
				);

			case ROLE:
				return <RoleTableCell key={id} cell={cell} userRole={userRole} />;

			case RATING:
				return (
					<SettlementRatingTableCell
						key={id}
						cell={cell}
						rating={toFixedWithoutZeros(rating, 2)}
					/>
				);

			case EARNINGS:
				return <EarningsTableCell key={id} cell={cell} earnings={earnings} />;

			case TOTAL_EARNINGS:
				return (
					<EarningsTableCell key={id} cell={cell} earnings={total_earnings} />
				);

			case SETTLED:
				return (
					<SettledTableCell
						key={id}
						cell={cell}
						settledCount={settledCount}
						notSettledCount={notSettledCount}
					/>
				);

			case HISTORY:
				return <HistoryActionButton key={id} cell={cell} />;
			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<>
			<TableRow key={row.id} {...row.getRowProps()}>
				{row.cells.map(renderCell)}
			</TableRow>

			<EmployeeDetails
				isExpanded={!!checkIfRowExpanded(row.cells)}
				colSpan={row.cells.length}
			/>
		</>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
