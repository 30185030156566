import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	chip: ({ isOkay }) => ({
		backgroundColor: isOkay ? theme.palette.white : theme.palette.error.light,
		borderRadius: '8px',
		border: isOkay ? `1px solid` : 'none',
		borderColor: isOkay
			? theme.palette.primary.main
			: theme.palette.transparent,
	}),
	label: ({ isOkay }) => ({
		color: isOkay ? theme.palette.primary.main : theme.palette.error.main,
		fontWeight: '500',
	}),
	strong: ({ isOkay }) => ({
		color: isOkay ? theme.palette.primary.main : theme.palette.error.main,
		fontSize: '12px',
		fontWeight: '500',
	}),
}));
