import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import { useHistory } from 'react-router-dom';
import { bool } from 'prop-types';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import components
import { ButtonProgress, Card } from 'components/elements';
import { CoordinatorFieldsRow } from '../../components';

export const FormContent = ({ isButtonLoading }) => {
	const { t } = useTranslations();
	const history = useHistory();

	const handleOnCancelButton = () => history.push(URLS.corporateListUrl);

	return (
		<Card title={t('b2b.corporate.form.add_coordinator')} lg={12}>
			<Grid container spacing={2} alignItems="flex-end">
				<FieldArray name="coordinators">
					{({ fields }) =>
						fields.map((name, index) => (
							<CoordinatorFieldsRow
								key={name}
								index={index}
								name={name}
								fields={fields}
							/>
						))
					}
				</FieldArray>
				<Grid item xs={12}>
					<Grid container justifyContent="flex-end" spacing={2}>
						<Grid item>
							<Button
								onClick={handleOnCancelButton}
								variant="contained"
								color="primary"
							>
								{t('common.buttons.cancel')}
							</Button>
						</Grid>
						<Grid item>
							<ButtonProgress
								isLoading={isButtonLoading}
								disabled={isButtonLoading}
								variant="contained"
								color="primary"
								type="submit"
							>
								{t('common.buttons.save')}
							</ButtonProgress>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

FormContent.propTypes = {
	isButtonLoading: bool,
};
