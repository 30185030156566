// ********************  table  ********************

export const fetchCarListUrl = (queryParams) =>
	`/v2/vehicle/list${queryParams}`;

export const deleteCarListUrl = (id) => `/v2/vehicle/${id}/remove`;

// ********************  form  ********************

export const fetchCarListFormResourcesUrl = (itemId) =>
	`v2/vehicle/${itemId}/show`;

export const submitCarListFormUrl = (isEdit, itemId) =>
	`/v2/vehicle/${isEdit ? `${itemId}/update` : `create`}`;

export const fetchVehiclePlatesListUrl = (queryParams) =>
	`/v2/vehicle/plate/list${queryParams}`;
