// Import helpers
import { TABLE_ACTIONS_ACCESSORS } from 'helpers';

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	ADD_TERMINALS: 'add_terminals',
};
const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, ADD_TERMINALS } = COLUMNS_ACCESSORS;

export const columns = [
	{
		Header: 'kiosk.airports.table.headers.name',
		accessor: 'name',
	},
	{
		Header: 'kiosk.airports.table.headers.city',
		accessor: 'city',
	},
	{
		Header: 'kiosk.airports.table.headers.total_terminals',
		accessor: 'total_terminals',
	},
	{
		Header: 'kiosk.airports.table.headers.terminals',
		accessor: ADD_TERMINALS,
	},
	{
		Header: 'table.headers.active',
		accessor: ACTIVE,
	},
	{
		Header: 'kiosk.airports.table.headers.edit',
		accessor: EDIT,
	},
	{
		Header: 'kiosk.airports.table.headers.delete',
		accessor: DELETE,
		isHidden: process.env.NODE_ENV !== 'development',
	},
];
