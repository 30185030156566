import _ from 'lodash';

// Import helpers
import { DELIVERY_TYPES } from 'helpers';

export const getConvertedDelivery = (delivery) => {
	const { PICK_UP_DELIVERY, DROP_DELIVERY } = DELIVERY_TYPES;

	const pickedKeys = _.pick(delivery, [PICK_UP_DELIVERY, DROP_DELIVERY]);

	return Object.entries(pickedKeys).map(([key, data]) => ({
		deliveryType: key,
		...data,
	}));
};

export const getDeliveryAvailabilityStates = (delivery) => {
	const { drop_delivery, pick_up_delivery, is_driver_assigned_to_company } =
		delivery || {};

	const { available: pickupAvailable, assigned: pickupAssigned } =
		pick_up_delivery || {};

	const { available: dropAvailable, assigned: dropAssigned } =
		drop_delivery || {};

	return {
		pickupDeliveryAvailableAndNotAssigned:
			pickupAvailable && !pickupAssigned && is_driver_assigned_to_company,
		dropDeliveryAvailableAndNotAssigned:
			dropAvailable && !dropAssigned && is_driver_assigned_to_company,
	};
};
