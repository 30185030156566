import { useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

// Import utilities
import { useCompanyOffices } from 'queries';

export const useCompanyOfficesFilter = (name) => {
	const {
		values: { company_uuid },
	} = useFormState();

	const { id } = useParams();

	const { change } = useForm();

	const { data: offices, isFetching } = useCompanyOffices(company_uuid || id);

	const filteredOffices = offices.filter(({ active }) => active);

	const isFieldDisabled =
		isFetching || company_uuid === 'all' || !filteredOffices.length;

	useEffect(() => {
		if (company_uuid === 'all') {
			change(name, 'all');
		}
		// eslint-disable-next-line
	}, [company_uuid]);

	return {
		filteredOffices,
		offices,
		isFieldDisabled,
		isFetching,
	};
};
