// Import helpers
import { fetchPromoCodeBookingListUrl } from 'store/paths';
import { combineQueryAndPaginationParams } from 'helpers';

// Import utils
import services from 'services/services';

export const fetchPromoCodeBookingList =
	({ options, queryParams, cancelToken, itemId }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			// Get response
			const { data } = await services.get(
				fetchPromoCodeBookingListUrl(itemId, params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
