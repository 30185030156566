import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// Import styles
import { useStyles } from './InfoLabel.styles';

// Import utils
import { useTranslations } from 'components/utilities';

export const InfoLabel = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<ErrorOutlineIcon color="primary" />
			<Typography color="primary">
				{t('b2b.corporate.overview.note')}
				{': '}
				<Typography className={classes.info} component="strong">
					{t('b2b.corporate.overview.info_message')}
				</Typography>
			</Typography>
		</Box>
	);
};
