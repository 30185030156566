import * as types from 'store/types';

export const updateExportFilters = (data) => (dispatch) => {
	dispatch({
		type: types.UPDATE_EXPORT_FILTERS,
		payload: data,
	});
};

export const updateExportOpenState = (state) => (dispatch) => {
	dispatch({
		type: types.UPDATE_EXPORT_OPEN_STATE,
		payload: state,
	});
};
