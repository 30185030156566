import { useTranslations } from 'components/utilities';

const useTotalAmountDifference = ({ difference }) => {
	const { t } = useTranslations();

	let text;
	let color;

	if (difference === 0) {
		text = 0 + ' ' + t('currency.sar');
		color = 'initial';
	} else if (difference > 0) {
		text = '+' + difference.toFixed(2) + ' ' + t('currency.sar');
		color = 'primary';
	} else {
		text = difference.toFixed(2) + ' ' + t('currency.sar');
		color = 'error';
	}

	return {
		text,
		color,
	};
};

export default useTotalAmountDifference;
