import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		gap: '26px',
		width: '100%',
		minHeight: 'calc(100vh - 2 * 80px)',
		padding: '32px',
	},
	centerContainer: {
		alignItems: 'center',
	},
	mainFormContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '32px',
		gap: '32px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		gap: '26px',
	},
	card: {
		padding: '32px',
	},
	notes: {
		'&.MuiFormControl-root': {
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
		},
		'& .MuiInputBase-root': {
			[theme.breakpoints.down('md')]: {
				backgroundColor: theme.palette.white,
				padding: 0,
			},
		},
	},
}));
