import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { useParams } from 'react-router-dom';

// Import components
import { ActionCell } from './components';
import { ActiveTableCell } from 'components/elements';

// Import actions
import { updateActiveLeasingCar } from 'store/actions';

// Import helpers
import { numberWithCommas, TABLE_ACTIONS_ACCESSORS, capitalize } from 'helpers';

// Import variables
import { COLUMNS_ACCESSORS } from 'views/Offices/LeasingCars/columns';

const { ACTION } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, ODOMETER, COLOR } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const { id: officeId } = useParams();

	const renderCell = (cell) => {
		const { uuid, miles_usage, body_color, is_active } = cell.row.original;

		const { id, isHidden } = cell.column;

		if (isHidden) return null;

		switch (id) {
			case ACTIVE:
				return (
					<ActiveTableCell
						key={id}
						activeAction={updateActiveLeasingCar}
						cell={cell}
						id={uuid}
						isActive={is_active}
						additionalParameters={{ officeId }}
					/>
				);

			case ODOMETER: {
				return <TableCell key={id}>{numberWithCommas(miles_usage)}</TableCell>;
			}

			case COLOR: {
				return <TableCell key={id}>{capitalize(body_color)}</TableCell>;
			}

			case ACTION: {
				return <ActionCell key={id} vehicleId={uuid} officeId={officeId} />;
			}

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
