export const validationStep2 = (values) => {
	let errors = {};

	if (!values.code) {
		errors.code = 'forget_password.errors.code_required';
	}

	return errors;
};

export const validationStep3 = (values) => {
	let errors = {};

	if (!values.password) {
		errors.password = 'forget_password.errors.password_required';
	} else {
		if (values.password.length < 8) {
			errors.password = 'forget_password.errors.password_too_short';
		}
	}

	if (!values.password_confirmation) {
		errors.password_confirmation =
			'forget_password.errors.password_confirmation_required';
	} else {
		if (values.password_confirmation.length < 8) {
			errors.password_confirmation =
				'forget_password.errors.password_confirmation_too_short';
		}
	}

	return errors;
};
