import { useContext } from 'react';

// Import context
import { TableContext } from './SubTableProvider';

export const useSubTableContextProvider = () => {
	const context = useContext(TableContext);

	return { ...context };
};
