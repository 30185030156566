export const getNewStates = ({
	checkedOffices,
	checkedCars,
	cars,
	officeUuid,
}) => {
	const carsInOffice = cars.map((car) => car.uuid);

	if (checkedOffices.includes(officeUuid)) {
		const newCheckedOffices = checkedOffices.filter(
			(uuid) => uuid !== officeUuid
		);
		const newCheckedCars = checkedCars.filter(
			(uuid) => !carsInOffice.includes(uuid)
		);

		return { newCheckedOffices, newCheckedCars };
	} else {
		const newCheckedOffices = [...checkedOffices, officeUuid];
		const newCheckedCars = checkedCars;

		carsInOffice.forEach((uuid) => {
			!checkedCars.includes(uuid) && newCheckedCars.push(uuid);
		});

		return { newCheckedOffices, newCheckedCars };
	}
};
