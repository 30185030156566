import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	actionCell: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	link: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: theme.spacing(0.5),
		'& path': {
			color: theme.palette.primary.main,
		},
	},
	deleteIconWrapper: {
		borderRadius: '50%',
		backgroundColor: `${theme.palette.error.main}17`,
		width: '25px',
		height: '25px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'& svg': {
			margin: 0,
		},

		'& path': {
			color: theme.palette.error.main,
		},
	},
}));

export default useStyles;
