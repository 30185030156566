import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	OperationTopicsFilter,
	CompensationStatusFilter,
	UrgentStatusFilter,
	CompanyFilter,
	BookingStatusFilter,
	PaymentMethodFilter,
	DateRangeFilter,
	AdminFilter,
	BaseYesNoAllFilter,
	CancellationStatusFilter,
} from 'components/elements';

const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<CompanyFilter name="company_uuid" label="bookings.filters.company" />

			<OperationTopicsFilter />

			<CompensationStatusFilter />

			<UrgentStatusFilter />

			<BookingStatusFilter name="state" label="bookings.filters.state" />

			<PaymentMethodFilter
				name="payment"
				label="bookings.filters.payment_methods.title"
			/>

			<DateRangeFilter
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="drop_date"
				nameUntil="drop_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="urgent_assistance_created_at"
				nameUntil="urgent_assistance_created_at_to"
				label="bookings.filters.urgent_assistance_created_at"
				combinedInputs
			/>

			<AdminFilter
				label="bookings.filters.marked_by"
				name="urgent_assistance_marked_by"
			/>
			<AdminFilter
				label="bookings.filters.validated_by"
				name="compensation_validated_by"
			/>
			<BaseYesNoAllFilter
				name="is_compensation"
				label="bookings.filters.compensation"
			/>
			<CancellationStatusFilter />

			<BaseYesNoAllFilter
				name="is_subscription"
				label="bookings.filters.subscription"
			/>
		</Grid>
	);
};

export default FilterFields;
