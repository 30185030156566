import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CalendarIcon = (props) => (
	<SvgIcon width="23" height="23" viewBox="0 0 23 23" fill="none" {...props}>
		<path
			d="M3.08496 9.12058H19.4236"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.3217 12.7006H15.3302"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.2543 12.7006H11.2628"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.17817 12.7006H7.18666"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.3217 16.2632H15.3302"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.2543 16.2632H11.2628"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.17817 16.2632H7.18666"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.9568 2.33334V5.34989"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.55155 2.33334V5.34989"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.1351 3.78094H7.37338C4.68142 3.78094 3 5.28055 3 8.03705V16.3326C3 19.1324 4.68142 20.6667 7.37338 20.6667H15.1266C17.827 20.6667 19.5 19.1584 19.5 16.4019V8.03705C19.5085 5.28055 17.8355 3.78094 15.1351 3.78094Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
