import React from 'react';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { object } from 'prop-types';
import clsx from 'clsx';

// Import styles
import { useStyles } from '../../styles';

// Import translations
import { useTranslations } from 'components/utilities';

export const PeriodItem = ({ period }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { label, type } = period;

	return (
		<Grid item>
			<Tooltip
				title={t('scheduled_bookings.table.legend.tooltip_message', {
					when: t(`scheduled_bookings.table.legend.periods.${type}`),
				})}
			>
				<Box className={clsx(classes[type], classes.periodItem)}>
					<Typography color="inherit" variant="h6">
						{t(label)}
					</Typography>
				</Box>
			</Tooltip>
		</Grid>
	);
};

PeriodItem.propTypes = {
	period: object.isRequired,
};
