import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	prices: {},
	distances: {},
	prefixes: {},
	holidays: [],
	companyList: [],
	item: {},
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.OFFICE_FORM_FETCH_DATA_LOADING:
				return INITIAL_STATE;

			case types.OFFICE_FORM_FETCH_DATA_SUCCESS:
				draft.isLoaded = true;
				draft.prices = action.payload.deliveryPrices;
				draft.distances = action.payload.deliveryDistances;
				draft.prefixes = action.payload.phonePrefixes;
				draft.holidays = action.payload.holidays;
				draft.companyList = action.payload.companyList;

				draft.item = action.payload.item;
				break;

			case types.OFFICE_FORM_FETCH_DATA_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				draft.error = action.payload;

				break;

			case types.OFFICE_FORM_REFRESH_HOLIDAYS:
				draft.holidays = action.payload;
				break;

			default:
				return state;
		}
	});
