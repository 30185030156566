import { AdditionalInfo, Filters } from './components';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';
import {
	CUSTOMER_PHONE_PREFIXES_QUERY,
	CUSTOMER_GENDERS_QUERY,
	CUSTOMER_TYPE_QUERY,
	CUSTOMER_ORGANIZATIONS_QUERY,
} from 'helpers';

export const PERMISSION_DELETE_CUSTOMER = [
	'halzahrani@telgani.com',
	'admin@admin.com',
	'Nalmogbel@telgani.com',
	'Aalfurhud@telgani.com',
];

export const INITIAL_VALUES = {
	details: {
		full_name: '',
		email: '',
		birth_date: '',
		phone_prefix: '',
		phone: '',
		gender: {
			type: '',
		},
		type: {
			type: '',
		},
		license: {
			file_path: '',
			license_number: '',
		},
	},
	workplace: {
		organization: {
			uuid: '',
			name: {
				ar: '',
				en: '',
			},
		},
	},
};

export const QUERY_KEYS = [
	CUSTOMER_PHONE_PREFIXES_QUERY,
	CUSTOMER_GENDERS_QUERY,
	CUSTOMER_TYPE_QUERY,
	CUSTOMER_ORGANIZATIONS_QUERY,
];

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.MODAL,
		text: 'customers.table.headers.additional_info',
		component: AdditionalInfo,
	},
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		customKey: 'filters',
		component: Filters,
	},
];
