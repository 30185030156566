import React from 'react';
import { number, string, arrayOf, shape, oneOfType, bool } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

export const BaseSelectFilter = ({
	name,
	label,
	options,
	md,
	xs,
	isAllOption,
}) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				{isAllOption && <MenuItem value="all">{t('common.all')}</MenuItem>}
				{options.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{t(label)}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

BaseSelectFilter.propTypes = {
	name: string.isRequired,
	label: string.isRequired,
	isAllOption: bool,
	md: number,
	xs: number,
	options: arrayOf(
		shape({
			value: oneOfType([string, number, bool]).isRequired,
			label: string.isRequired,
		})
	).isRequired,
};

BaseSelectFilter.defaultProps = {
	isAllOption: false,
	md: 3,
	xs: 6,
};
