import React from 'react';
import { AppBar, Grid, Typography, Divider } from '@material-ui/core';
import { func } from 'prop-types';

// Import styles
import { useStyles } from './styles';

// Import components
import {
	CorporateBalance,
	LanguageButton,
	ProfileToggle,
	Hamburger,
} from './components';

// Import utilities
import { useTopbar } from './useTopbar';

export const Topbar = ({ onSidebarOpen, ...props }) => {
	const classes = useStyles();

	const { sectionName, isTabletSize } = useTopbar();

	return (
		<AppBar className={classes.root} {...props}>
			<Grid container alignItems="center" justifyContent="space-between">
				{!isTabletSize && (
					<Grid container xs={1} item>
						<Grid item>
							<Typography className={classes.sectionTitle} noWrap>
								{sectionName}
							</Typography>
						</Grid>
					</Grid>
				)}
				<Grid container xs={1} item>
					<Grid item>
						<Hamburger onClick={onSidebarOpen} />
					</Grid>
				</Grid>
				<Grid
					container
					item
					xs
					justifyContent="flex-end"
					alignItems="center"
					spacing={2}
				>
					<Grid>
						<CorporateBalance />
					</Grid>
					<Grid item>
						<LanguageButton />
					</Grid>
					<Grid item>
						<Divider
							className={classes.line}
							orientation="vertical"
							variant="middle"
						/>
					</Grid>
					<Grid item>
						<ProfileToggle />
					</Grid>
				</Grid>
			</Grid>
		</AppBar>
	);
};

Topbar.propTypes = {
	onSidebarOpen: func,
};
