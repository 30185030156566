import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	provider: {
		height: '100%',
	},
	root: {
		position: 'relative',
		paddingTop: 80,
		height: '100%',
		overflowY: 'scroll',
		[theme.breakpoints.up('sm')]: {
			paddingTop: 80,
		},
	},
	shiftContentRight: {
		paddingRight: 290,
	},
	shiftContentLeft: {
		paddingLeft: 290,
	},
	content: {
		height: '100%',
	},
	signOutBox: {
		position: 'fixed',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 99999,
		background: 'rgba(0, 0, 0, 0.41)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	lngLoader: {
		background: theme.palette.white,
	},
	pageTitle: {
		fontSize: '20px',
		fontWeight: 500,
		padding: '40px 0 0 40px',
	},
}));
