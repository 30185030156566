import React from 'react';
import { object, func, string, bool } from 'prop-types';
import { TableCell, Button } from '@material-ui/core';
import useCustomerStateCell from './useCustomerStateCell';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { NotificationAlert } from 'components/elements';

// Import helpers
import { useStyles } from './styles';

const CustomerStatusCell = ({
	cell,
	stateAction = () => {},
	activeText = 'table.actions.active',
	inactiveText = 'table.actions.inactive',
	blockedText = 'table.actions.blocked',
	customerState = cell.row.values.state,
	id = cell.row.original.uuid,
}) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const textVariants = { activeText, inactiveText, blockedText };

	const {
		showAlert,
		toggleAlert,
		onChangeCustomerState,
		text,
		alertMessage,
		variant,
		color,
	} = useCustomerStateCell({ id, customerState, stateAction, textVariants });

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<NotificationAlert
				type="success"
				message={t(alertMessage)}
				open={showAlert}
				onClose={toggleAlert}
				autoHideDuration={2000}
				title=""
				horizontalPosition="center"
			/>
			<Button
				variant={variant}
				onClick={onChangeCustomerState}
				color={color}
				className={classes.button}
			>
				{t(text)}
			</Button>
		</TableCell>
	);
};

CustomerStatusCell.propTypes = {
	cell: object.isRequired,
	id: string,
	stateAction: func,
	activeText: string,
	inactiveText: string,
	blockedText: string,
	customerState: bool,
};

export default CustomerStatusCell;
