import React from 'react';
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';

// Import components
import { AlertMessage, Card } from 'components/elements';
import { FormContent } from '../../components';

// Import utils and helpers
import { useRentalCompaniesContainer } from './useRentalCompaniesContainer';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './RentalCompaniesContainer.styles';

export const RentalCompaniesContainer = () => {
	const { handleOnSubmit, savedCompanies, isButtonLoading, isError } =
		useRentalCompaniesContainer();

	const { t } = useTranslations();

	const classes = useStyles();

	if (isError) {
		return (
			<AlertMessage
				title={t('common.error')}
				description={t('common.messages.error_message')}
				variant="error"
			/>
		);
	}

	return (
		<Box className={classes.container}>
			<Card title={t('b2b.corporates_and_selected_companies')} lg={12}>
				<Form
					onSubmit={handleOnSubmit}
					initialValues={savedCompanies}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<FormContent isButtonLoading={isButtonLoading} />
						</form>
					)}
				></Form>
			</Card>
		</Box>
	);
};
