import React, { useEffect } from 'react';
import { Typography, Link, Grid, Button, Tooltip } from '@material-ui/core';

// Import utils and helpers
import { useTranslations, useMessage } from 'components/utilities';
import { clearLocaleStorageCache } from 'helpers';
import { useAppVersion } from 'queries';

// Import styles
import { useStyles } from './styles';

export const Footer = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { message } = useMessage();

	const { data: appVersion, errorMessage, isError } = useAppVersion();

	useEffect(() => {
		if (isError) {
			message.error(errorMessage);
		}
		// eslint-disable-next-line
	}, [isError]);

	return (
		<Grid container className={classes.root}>
			<Grid item>
				<Typography variant="body1">
					&copy; Copyright:
					<Link
						component="a"
						className={classes.link}
						href="https://telgani.com/"
						target="_blank"
					>
						Telgani
					</Link>
					2020
				</Typography>
			</Grid>
			<Grid item>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<Typography variant="body1">
							{t('common.api_version', { version: appVersion?.api?.version })}
						</Typography>
					</Grid>
					<Grid item>
						<Tooltip title={t('common.tooltip.updated_data')}>
							<Button
								onClick={() => clearLocaleStorageCache({ shouldReload: true })}
								color="primary"
							>
								{t('common.buttons.clear_cache')}
							</Button>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
