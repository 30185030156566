import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	quote: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		backgroundColor: theme.palette.neutral,
		backgroundImage: 'url(images/sign_in_bg_image.jpg)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	layer: {
		width: '100%',
		height: '100%',
		zIndex: 2,
		backgroundColor: alpha(theme.palette.primary.main, 0.78),
	},
	logo: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 3,
		color: theme.palette.white,
		fontSize: '160px',
	},
}));
