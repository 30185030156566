import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { bool } from 'prop-types';

// Import utils and helpers
import { useTranslations, useCommonFields } from 'components/utilities';
import { CORPORATE_FIELDS } from './helpers';
import { URLS } from 'components/routes';

// Import components
import { CoordinatorFieldsRow } from '../../components';
import { ButtonProgress } from 'components/elements';

export const FormContent = ({ isLoading }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { mutators, change } = useForm();
	const { values } = useFormState();
	const history = useHistory();

	const isAddCoordinatorButtonVisible = values?.coordinators?.length < 10;

	const goBack = () => history.push(URLS.corporateListUrl);
	const handleOnAddCoordinator = () => mutators.push('coordinators', undefined);
	const handleOnSuccessLogoUpload = (file) => {
		change('logo', file);
	};

	return (
		<Grid container spacing={2} alignItems="flex-end">
			{CORPORATE_FIELDS(handleOnSuccessLogoUpload).map(renderFields)}
			<FieldArray name="coordinators">
				{({ fields }) =>
					fields.map((name, index) => (
						<Grid item xs={12} key={name}>
							<CoordinatorFieldsRow index={index} fields={fields} />
						</Grid>
					))
				}
			</FieldArray>
			{isAddCoordinatorButtonVisible && (
				<Grid item xs={12}>
					<Grid container justifyContent="flex-start">
						<Grid item>
							<Button
								onClick={handleOnAddCoordinator}
								variant="contained"
								color="primary"
							>
								{t('common.buttons.add_new')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end" spacing={2}>
					<Grid item>
						<Button variant="outlined" color="primary" onClick={goBack}>
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							isLoading={isLoading}
							disabled={isLoading}
							variant="contained"
							color="primary"
							type="submit"
						>
							{t('common.buttons.save')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isLoading: bool,
};
