import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { useGratificationsTypes } from 'queries';
import { GRATIFICATION_TYPES } from './helpers';
import { useParams } from 'react-router-dom';

const useCampaignGratificationCard = () => {
	const { values } = useFormState();
	const { change } = useForm();
	const { type: typeFromUrl } = useParams();

	const { POINTS, DISCOUNT } = GRATIFICATION_TYPES;

	const type = values?.gratification?.type;

	const campaignType = values?.details?.campaign_type || typeFromUrl;

	const { data: filteredTypes, isFetching } = useGratificationsTypes({
		campaignType,
	});

	useEffect(() => {
		filteredTypes.length === 1 &&
			change('gratification.type', filteredTypes[0]);
	}, [filteredTypes, change]);

	const isPointsType = type === POINTS;
	const isDiscountType = type === DISCOUNT;

	return {
		isPointsType,
		isDiscountType,
		filteredTypes,
		isFetching,
	};
};

export default useCampaignGratificationCard;
