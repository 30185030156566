import { format } from 'date-fns';

export const COMBINED_INPUT_MASK = '99/99/9999 - 99/99/9999';

export const formatDate = (date) =>
	date instanceof Date && format(date, 'dd/MM/yyyy');

export const validateInputDates = (value) => {
	const dates = value
		.split('-')
		.map((d) => d.trim())
		.filter((d) => !!d);

	if (dates.length !== 2) return false;

	// Regex pattern to validate if the date matches dd/MM/yyyy format
	const dateRegex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;

	const isEveryDateValid = dates.every((date) => dateRegex.test(date));

	return isEveryDateValid;
};

export const getDatesFromString = (str) => {
	const [start, end] = str.split('-').map((d) => d.trim());

	const [startDay, startMonth, startYear] = start.split('/');
	const [endDay, endMonth, endYear] = end.split('/');

	const startDate = new Date(+startYear, +startMonth - 1, +startDay);

	const endDate = new Date(+endYear, +endMonth - 1, +endDay);

	return { startDate, endDate };
};
