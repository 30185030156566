import { useQuery } from 'react-query';
import services from 'services/services';
import _ from 'lodash';

// Import helpers
import { ACTIVE_COMPANY_COLLECTION_QUERY } from 'helpers';

export const useSpecialOfferCompanyCars = (company_uuid) => {
	const path = `/v2/special-offer/search-cars`;

	const { data, isError, isFetching } = useQuery(
		[ACTIVE_COMPANY_COLLECTION_QUERY, company_uuid],
		async () => await services.post(path, { company_uuid }),
		{
			refetchOnWindowFocus: false,
			enabled: !!company_uuid,
		}
	);

	const fetchedData = data ? data.data.data : [];

	const convertedFetchedData = fetchedData.map(({ vehicle_uuid, ...rest }) => ({
		uuid: vehicle_uuid,
		..._.omit(rest, 'vehicle_uuid'),
	}));

	return { data: convertedFetchedData, isFetching, isError };
};
