import { useForm, useFormState } from 'react-final-form';

const useDeliveryMap = () => {
    const { change } = useForm();

	const {
		values: { options }
	} = useFormState();

	const location = {
		formatted_address: options.delivery_address,
		lat: options.delivery_lat,
		lng: options.delivery_lng
	};

	const setLocation = ({ formatted_address, lat, lng }) => {
		change('options', {
			...options,
			delivery_address: formatted_address,
			delivery_lat: lat,
			delivery_lng: lng
		});
    };
    
    return {
        location,
        setLocation
    }
};

export default useDeliveryMap;