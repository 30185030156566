import React from 'react';
import { number, string } from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { TextFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

const BaseTextFilter = ({ name, label, md = 3 }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid item md={md}>
			<Field
				fullWidth
				component={TextFieldAdapter}
				type="text"
				label={t(label)}
				name={name}
				variant="outlined"
				autoComplete="false"
				margin="dense"
				disabled={submitting}
			/>
		</Grid>
	);
};

BaseTextFilter.propTypes = {
	name: string.isRequired,
	label: string.isRequired,
	md: number,
};

export default BaseTextFilter;
