import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import { func } from 'prop-types';

// Import components
import {
	TextFieldAdapter,
	ButtonProgress,
	FileFieldAdapter,
} from 'components/elements';

// Import styles
import { useStyles } from '../styles';

// Import utilities
import { useTranslations } from 'components/utilities';

const Fields = ({ onClose }) => {
	const { submitting } = useFormState();
	const { t } = useTranslations();
	const { box } = useStyles();

	return (
		<Grid container>
			<Grid item xs={12}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="text"
					label={t('bookings.close_compensation_form.rrn')}
					name="rrn"
					variant="outlined"
					margin="dense"
					disabled={submitting}
				/>
			</Grid>
			<Grid container item xs={12} className={box}>
				<Grid item xs={12}>
					<Typography>
						{t('bookings.customer_compensation.form.transaction_copy')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Field
						fileNameField="transaction_file.file_name"
						previewFileState="transaction_file"
						name="transaction_file.file_path"
						component={FileFieldAdapter}
						uploadButtonWidth={12}
						disabled={submitting}
						margin="dense"
					/>
				</Grid>
			</Grid>
			<Grid container item xs={12} justifyContent="flex-end">
				<Grid item>
					<Button onClick={onClose} color="primary">
						{t('common.buttons.cancel')}
					</Button>
				</Grid>
				<Grid item>
					<ButtonProgress
						color="primary"
						variant="contained"
						type="submit"
						disabled={submitting}
						isLoading={submitting}
					>
						{t('bookings.close_compensation_form.button_title')}
					</ButtonProgress>
				</Grid>
			</Grid>
		</Grid>
	);
};

Fields.propTypes = {
	onClose: func,
};

export default Fields;
