import React, { useEffect } from 'react';
import { Field, useFormState, useForm } from 'react-final-form';
import { bool, number, oneOfType, string } from 'prop-types';
import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Card,
	InputAdornment,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { TextFieldAdapter } from 'components/elements';

export const RateCard = ({
	calculationsChanged,
	countedValue,
	label,
	name,
	disabled,
	required,
}) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	const { change } = useForm();

	useEffect(() => {
		if (calculationsChanged) {
			change(name, countedValue);
		}
	}, [countedValue, name, change, calculationsChanged]);

	return (
		<Card>
			<CardHeader title={t(label)} />
			<Divider />
			<CardContent>
				<Grid container spacing={1}>
					<Field
						fullWidth
						component={TextFieldAdapter}
						type="number"
						label={t('cars.form.ratings.discount_price')}
						name={name}
						margin="dense"
						variant="outlined"
						required={required}
						disabled={disabled || submitting}
						inputProps={{ min: required ? 1 : null, step: 0.01 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{t('currency.sar_month')}
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</CardContent>
		</Card>
	);
};

RateCard.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	countedValue: oneOfType([number, string]).isRequired,
	calculationsChanged: bool.isRequired,
	disabled: bool.isRequired,
	required: bool.isRequired,
};
