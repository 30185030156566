import DriveEtaIcon from '@material-ui/icons/DriveEta';

// Import helpers
import {
	replaceOfficeExternalPartnerCarsUrl,
	replaceAddCarUrl,
	TOOLBAR_VARIANTS,
} from 'helpers';

export const toolbarContent = ({ id, isOfficeManager }) => {
	if (isOfficeManager) {
		return [];
	}

	return [
		{
			variant: TOOLBAR_VARIANTS.NAV,
			to: replaceAddCarUrl(id),
			icon: DriveEtaIcon,
			text: 'common.buttons.add_car',
		},
		{
			variant: TOOLBAR_VARIANTS.NAV,
			to: replaceOfficeExternalPartnerCarsUrl(id),
			icon: DriveEtaIcon,
			text: 'common.buttons.map_external_partner',
		},
	];
};
