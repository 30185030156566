import { useState, useEffect, useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';
import usePrevious from '@rooks/use-previous';

// Import helpers
import { INITIAL_FILTER_VALUES } from './helpers';
import { EN } from 'helpers';

// Import utilities
import { useEditBookingProvider } from 'views/Bookings/EditBooking/context';
import { useCarModels } from 'queries/CarSearch';
import { useCars } from 'queries/CarSearch';
import { useManufacturers } from 'queries';

const useSelectCarFilters = () => {
	const [filters, setFilters] = useState(INITIAL_FILTER_VALUES);
	const { id: bookingUuid } = useParams();
	const prevBookingUuid = usePrevious(bookingUuid);
	const prevFilters = usePrevious(filters);
	const { values } = useFormState();

	const { converted, setConverted, setChangeInitialValues } =
		useEditBookingProvider();

	const { maker, model, year, uuid: item_uuid } = values?.item || {};
	const { pick_time, pick_date, drop_date } = values?.period || {};

	const { data: manufacturers } = useManufacturers();

	const maker_uuid = manufacturers.find(({ name }) => name[EN] === maker)?.uuid;

	const { data: models } = useCarModels({
		manufacturerUuid: maker_uuid,
	});

	const model_uuid = models.find(
		({ model_name }) => model_name[EN] === model
	)?.model_uuid;

	const updatedFilters = (filters) => ({
		...filters,
		pickup_date: pick_date,
		drop_date,
		pick_time,
	});

	const convertInitialValues = useCallback(() => {
		INITIAL_FILTER_VALUES.item.uuid = item_uuid;
		INITIAL_FILTER_VALUES.vehicle = {
			maker_uuid,
			model_uuid,
			year,
		};

		setFilters(INITIAL_FILTER_VALUES);
	}, [maker_uuid, model_uuid, year, item_uuid]);

	useEffect(() => {
		if (pick_date && pick_time && drop_date) {
			setFilters((filters) => updatedFilters(filters));
		}
		// eslint-disable-next-line
	}, [pick_date, pick_time, drop_date]);

	const {
		data: cars,
		refetch,
		isFetching: carsFetching,
	} = useCars({
		bookingUuid,
		values,
		filters,
		offsets: {
			offset: 1,
		},
	});

	const handleFiltersChange = (filters) => {
		setFilters(updatedFilters(filters));
	};

	useEffect(() => {
		if (
			bookingUuid !== prevBookingUuid ||
			JSON.stringify(filters) !== JSON.stringify(prevFilters)
		) {
			refetch();
		}
	}, [bookingUuid, prevBookingUuid, filters, prevFilters, refetch]);

	useEffect(() => {
		if (maker_uuid && model_uuid) {
			setConverted(true);
		}
		if (!converted) {
			convertInitialValues();
			setChangeInitialValues(() => convertInitialValues);
		}
		//eslint-disable-next-line
	}, [maker_uuid, model_uuid, converted]);

	return {
		handleFiltersChange,
		carsFetching,
		filters,
		cars,
	};
};

export default useSelectCarFilters;
