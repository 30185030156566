import React from 'react';
import { number, string } from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { OverlayView } from '@react-google-maps/api';

// Import styles
import { useStyles } from './MarkerWithLabel.styles';

// Import utils
import { useTranslations } from 'components/utilities';
import { useMarkerWithLabel } from './useMarkerWithLabel';

export const MarkerWithLabel = ({ lat, lng, state, time }) => {
	const { t } = useTranslations();

	const { getPixelPositionOffset, getLabel, getTimeFormatted } =
		useMarkerWithLabel();

	const classes = useStyles();

	return (
		<OverlayView
			position={{ lat, lng }}
			mapPaneName={OverlayView.MARKER_LAYER}
			getPixelPositionOffset={getPixelPositionOffset}
		>
			<Box className={classes.labelBox}>
				<Typography className={classes.label}>
					{t(getLabel(state), { time: getTimeFormatted(time) })}
				</Typography>
				<Box className={classes.dot} />
			</Box>
		</OverlayView>
	);
};

MarkerWithLabel.propTypes = {
	state: string,
	time: string,
	lat: number,
	lng: number,
};
