import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CUSTOMER_WALLET_BALANCE } from 'helpers';

const useCustomerWalletBalance = ({ uuid }) => {
	const path = `/v2/customer/${uuid}/wallet/balance`;

	const { data, isFetched, isError, error } = useQuery(
		[CUSTOMER_WALLET_BALANCE, uuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, retry: false }
	);

	const fetchedData = data?.data.data || null;

	return { data: fetchedData, isFetched, isError, error };
};

export default useCustomerWalletBalance;
