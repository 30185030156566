import { TextFieldAdapter } from 'components/elements';

export const INITIAL_VALUES = { name: { ar: '', en: '' } };

export const MODAL_FIELDS = [
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'organizations.form.name_en',
		name: 'name.en',
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'organizations.form.name_ar',
		name: 'name.ar',
	},
];
