import React from 'react';
import { Card as MuiCard } from '@material-ui/core';

// Import styles
import useStyles from './styles';

const Card = (props) => {
	const classes = useStyles();

	return (
		<MuiCard className={classes.root} {...props} />
	);
};


export default Card;
