import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.success.light,
		colorAdjust: 'exact',
		padding: '0 20px',
		minHeight: '70px',
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
	},
	label: {
		fontWeight: 700,
		fontSize: 14,
	},
	price: {
		fontSize: 22,
		fontWeight: 'bold',
		color: theme.palette.primary.dark,
	},
}));
