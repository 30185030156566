import React from 'react';
import { number } from 'prop-types';
import { Field } from 'react-final-form';
import { Checkbox, MenuItem } from '@material-ui/core';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';

// Import utilities
import { useExternalPartnerCarsSelect } from './useExternalPartnerCarsSelect';
import { useTranslations } from 'components/utilities';

export const ExternalPartnerCarsSelect = ({ index }) => {
	const { findLabel, convertedExternalPartnerCars, selectedCars } =
		useExternalPartnerCarsSelect(index);

	const { t } = useTranslations();

	return (
		<Field
			format={(value) => (Array.isArray(value) ? value : [])}
			fullWidth
			component={MultiselectFiledAdapter}
			type="select"
			label={t('cars.map_external_partner_cars.external_partner')}
			name={`office_cars[${index}].external_partner_car_ids`}
			margin="dense"
			variant="outlined"
			labelextractor={findLabel}
		>
			{convertedExternalPartnerCars.map(({ id, name }) => (
				<MenuItem key={id} value={id}>
					<Checkbox color="primary" checked={selectedCars.includes(id)} />
					{name}
				</MenuItem>
			))}
		</Field>
	);
};

ExternalPartnerCarsSelect.propTypes = {
	index: number.isRequired,
};
