import axios from 'axios';

// Import helpers
import { getHeaders } from 'helpers/headers';

const myAxiosFactory = () => (config) => {
	const headers = getHeaders(config);
	return axios.create({
		// eslint-disable-next-line
		baseURL: `${process.env.REACT_APP_API_URL}`,
		headers,
	});
};

const myAxios = myAxiosFactory();

export default myAxios;
