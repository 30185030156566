import * as dateFns from 'date-fns';

export const INITIAL_VALUES = {
	condition: {
		duration_from: '',
		duration_to: '',
		booking_created_from: '',
		booking_created_to: '',
		booking_pick_date_from: '',
		booking_pick_date_to: '',
		total_budget_amount: {
			nett_price: 0,
		},
		customer: {
			customer_type: [],
			organization: [],
		},
		supplier: {
			company: [],
			office: [],
		},
	},
	gratification: {
		type: null,
		discount: null,
		max_amount_nett_price: null,
		point: 0,
	},
};

const formatDate = (date) => {
	const dateFormat = 'dd/MM/yyyy';

	return dateFns.format(new Date(date), dateFormat);
};

const convertDate = (date) => {
	if (typeof date === 'string') {
		const [day, month, year] = date.split('/');
		const newDate = new Date(year, month - 1, day);

		return formatDate(newDate);
	}

	return formatDate(date);
};

export const convertValues = (values) => {
	const { condition } = values;

	return {
		...values,
		condition: {
			...condition,

			booking_created_from: convertDate(condition.booking_created_from),
			booking_created_to: convertDate(condition.booking_created_to),
			booking_pick_date_from: convertDate(condition.booking_pick_date_from),
			booking_pick_date_to: convertDate(condition.booking_pick_date_to),
			duration_from: convertDate(condition.duration_from),
			duration_to: convertDate(condition.duration_to),
		},
	};
};

export const getCampaignType = () => window.location.pathname.split('/').pop();
