import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import {
	LOCAL_STORAGE_URGENT_STATES,
	URGENT_BOOKINGS_STATS_QUERY,
} from 'helpers';

export const useUrgentBookingsStats = (options = {}) => {
	const path = 'v2/booking/operation/urgent/stats';

	const localStorageStates = ls.get(
		LOCAL_STORAGE_URGENT_STATES(JSON.stringify(options))
	);

	const { data, isLoading, isStale, isError, error, refetch, isFetched } =
		useQuery(
			[URGENT_BOOKINGS_STATS_QUERY, JSON.stringify(options)],
			async () => await services.post(path, options),
			{ refetchOnWindowFocus: false, enabled: !localStorageStates }
		);

	const fetchedData = data ? data.data.data : null;

	useEffect(() => {
		if (!localStorageStates && isFetched && fetchedData) {
			ls.set(
				LOCAL_STORAGE_URGENT_STATES(JSON.stringify(options)),
				fetchedData,
				{ ttl: 15 * 60 }
			);
		}
		// eslint-disable-next-line
	}, [localStorageStates, isFetched, fetchedData]);

	const result = localStorageStates || fetchedData;
	const isFetchedResult = localStorageStates ? true : isFetched;

	return {
		data: result,
		isLoading,
		isError,
		error,
		refetch,
		isStale,
		isFetched: isFetchedResult,
	};
};
