import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		gap: '16px',
		width: '100%',
		padding: '0 28px 28px',
	},
	mainImage: {
		width: '100%',
		maxHeight: '400px',
		objectFit: 'cover',
		objectPosition: 'center center',
	},
	imagesContainer: {
		display: 'flex',
		flexWrap: 'nowrap',
		width: '100%',
		maxHeight: '112px',
		overflowX: 'scroll',
		gap: '6px',
	},
	image: {
		width: '100%',
		maxWidth: '170px',
		cursor: 'pointer',
		objectFit: 'cover',
		objectPosition: 'center center',
	},
	divider: {
		width: '100%',
	},
	notActive: {
		backgroundColor: theme.palette.white,
		color: theme.palette.primary.main,
	},
}));
