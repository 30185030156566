import React from 'react';
import {
	CardContent,
	CardHeader,
	Divider,
	Card,
	Grid,
} from '@material-ui/core';

// Import translations
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { BASE_DETAILS_FIELDS } from './helpers';

export const BaseDetails = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	return (
		<Card>
			<CardHeader component="h5" title={t('cms.pages.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{BASE_DETAILS_FIELDS.map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
