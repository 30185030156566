import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import {
	fetchDiscountFormResources,
	submitAddDiscountForm,
} from 'store/actions';
import { queryCache } from 'react-query';

// Import components
import { FormRPC } from 'components/utilities';
import { CompanyAssignment, DiscountDetails } from '../components';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import {
	COMPANY_COLLECTION_QUERY,
	replaceOrganizationDiscountsUrl,
} from 'helpers';
import { useParams } from 'react-router-dom';

// Import styles
import useStyles from '../styles';

const AddDiscount = () => {
	const classes = useStyles();

	const { id } = useParams();

	useEffect(() => {
		return () => {
			queryCache.removeQueries(COMPANY_COLLECTION_QUERY);
		};
		//eslint-disable-next-line
	}, []);

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchDiscountFormResources}
				submitFormAction={submitAddDiscountForm}
				store={(store) => store.discounts.form}
				goBackPath={replaceOrganizationDiscountsUrl(id)}
				isCardLayout={false}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<CompanyAssignment section="company" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<DiscountDetails section="discount" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default AddDiscount;
