const UNWANTED_STATES = ['created', 'taken', 'finished', 'skipped', 'deleted'];

export const removeUnwantedStates = ({ data: states, neededStates }) => {
	const filteredStates = states.filter((state) =>
		neededStates
			? neededStates.includes(state)
			: !UNWANTED_STATES.includes(state)
	);

	return filteredStates;
};
