/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import { oneOfType, node, arrayOf } from 'prop-types';

export const TableActionsProviderContext = React.createContext();

export const TableActionsProvider = ({ children }) => {
	const [expandedRows, setExpandedRows] = useState([]);

	const handleOnExpandRows = (rowUuid) => {
		const foundRowIndex = expandedRows.indexOf(rowUuid);

		if (foundRowIndex >= 0) {
			const arrayWithoutFoundRow = expandedRows.filter(
				(uuid) => uuid !== rowUuid
			);
			setExpandedRows(arrayWithoutFoundRow);
		} else {
			setExpandedRows([...expandedRows, rowUuid]);
		}
	};

	const isRowOpen = (rowUuid) => expandedRows.includes(rowUuid);

	return (
		<TableActionsProviderContext.Provider
			value={{ handleOnExpandRows, isRowOpen }}
		>
			{children}
		</TableActionsProviderContext.Provider>
	);
};

TableActionsProvider.propTypes = {
	children: oneOfType([node, arrayOf(node)]),
};
