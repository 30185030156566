import React from 'react';
import { number, string } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { OPTIONS } from './helpers';

export const ExtendStatusFilter = ({ name, label, md = 3 }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid item md={md}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{OPTIONS.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{t(label).toUpperCase()}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

ExtendStatusFilter.propTypes = {
	name: string.isRequired,
	label: string.isRequired,
	md: number,
};
