import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';

// Import utils
import useMakeCompensationForm from './useMakeCompensationForm';

// Import components
import MakeCompensationFields from './components/MakeCompensationFields';
import { Alert } from 'components/elements';

const MakeCompensationForm = ({
	onClose = () => {},
	onCustomSubmit = () => {},
	isInModal = false,
	location,
	outerCompensation,
	isInvalidButtonVisible = false,
}) => {
	const { onSubmit, isError, error, booking, initialValues } =
		useMakeCompensationForm({
			onClose,
			location,
			outerCompensation,
		});

	const handleOnSubmit = async (values) => {
		await onSubmit(values);
		onCustomSubmit();
	};

	return (
		<Box py={2}>
			{isError && <Alert message={error.message} />}
			<Form
				onSubmit={handleOnSubmit}
				initialValues={initialValues}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<MakeCompensationFields
							booking={booking}
							onClose={onClose}
							isInModal={isInModal}
							outerCompensation={outerCompensation}
							isInvalidButtonVisible={isInvalidButtonVisible}
							location={location}
						/>
					</form>
				)}
			/>
		</Box>
	);
};

MakeCompensationForm.propTypes = {
	onClose: PropTypes.func,
	onCustomSubmit: PropTypes.func,
	isInModal: PropTypes.bool,
	location: PropTypes.string,
	isInvalidButtonVisible: PropTypes.bool,
	outerCompensation: PropTypes.object,
};

export default MakeCompensationForm;
