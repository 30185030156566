import { useState } from 'react';

export const useCollapsableCard = ({ initialOpen }) => {
	const [isOpen, setIsOpen] = useState(initialOpen);

	const handleOnClick = () => setIsOpen((open) => !open);
	const handleOnClose = () => setIsOpen(false);

	return {
		isOpen,
		toggle: handleOnClick,
		onClose: handleOnClose,
	};
};
