// Import utils
import { useCommonFields } from 'components/utilities';
import { useAutoCompensationFields } from './useAutoCompensationFields';

export const AutoCompensationFields = () => {
	const { renderFields } = useCommonFields();

	const { autoCompensationFields } = useAutoCompensationFields();

	return autoCompensationFields.map(renderFields);
};
