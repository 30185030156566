import services from 'services/services';
import * as types from 'store/types';

// Import helpers
import {
	deletePlateUrl,
	fetchPlateFormResourcesUrl,
	fetchVehiclePlatesListUrl,
	submitPlateFormUrl,
} from 'store/paths';
import { convertSubmitValues, convertFetchData } from './helpers';
import { catchError } from 'store/actions/helpers';

export const fetchPlateFormResources =
	({ isEdit, itemId, cancelToken }) =>
	async (dispatch) => {
		dispatch({
			type: types.PLATE_FORM_FETCH_DATA_LOADING,
		});

		try {
			const { data: fetchData } =
				isEdit &&
				(await services.get(fetchPlateFormResourcesUrl(itemId), cancelToken));

			const { data } = fetchData || {};
			const convertedData = isEdit && convertFetchData(data);

			const itemData = isEdit ? convertedData : {};

			dispatch({
				type: types.PLATE_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: { data: itemData },
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.PLATE_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitPlateForm =
	({ itemId, values, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			const convertedValues = convertSubmitValues(values);

			await services[itemId ? 'put' : 'post'](
				submitPlateFormUrl(itemId),
				convertedValues
			);

			callbackSuccess();
		} catch (error) {
			const { errors, message } = error?.response?.data || {};
			errors && errorCallback(errors);
			message && errorCallback(message);
		}
	};

export const deletePlate =
	({ id }) =>
	async () => {
		await services.delete(deletePlateUrl(id));
	};

export const fetchVehiclePlatesList =
	({ cancelToken, queryParams, vehicleUuid }) =>
	async () => {
		try {
			const body = {
				start: 0,
				length: 999999,
				vehicle_uuid: vehicleUuid,
			};

			const { data } = await services.post(
				fetchVehiclePlatesListUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
