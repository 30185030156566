import services from 'services/services';
import { fetchCarListUrl, deleteCarListUrl } from 'store/paths';

export const fetchCarList =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const { data } = await services.post(
				fetchCarListUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const deleteCarList =
	({ id }) =>
	async () => {
		try {
			await services.delete(deleteCarListUrl(id));
		} catch (error) {
			return Promise.reject(error);
		}
	};
