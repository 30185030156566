import React from 'react';
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';

// Import utils and helpers
import { useEditTrainStation } from './useEditTrainStation';
import { useTranslations } from 'components/utilities';
import { validationSchema } from './validation';
import { yupValidator } from 'helpers';

// Import styles
import { useStyles } from './EditTrainStation.styles';

// Import components
import { AlertMessage, Card, LoadingWrapper } from 'components/elements';
import { FormContent } from './components';

export const EditTrainStation = () => {
	const { t } = useTranslations();

	const classes = useStyles();
	const {
		isTrainStationDataFetching,
		isTrainStationDataError,
		isSubmitLoading,
		handleOnSubmit,
		initialValues,
	} = useEditTrainStation();

	if (isTrainStationDataError) {
		return (
			<AlertMessage
				description={t('common.messages.error_message')}
				title={t('common.error')}
				variant="error"
			/>
		);
	}

	return (
		<Box className={classes.container}>
			<Card lg={12}>
				{isTrainStationDataFetching ? (
					<LoadingWrapper />
				) : (
					<Form
						initialValues={initialValues}
						onSubmit={handleOnSubmit}
						validate={(values) =>
							yupValidator({ values, validationSchema: validationSchema() })
						}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit} noValidate>
								<FormContent isSubmitLoading={isSubmitLoading} />
							</form>
						)}
					/>
				)}
			</Card>
		</Box>
	);
};
