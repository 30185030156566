import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import {
	CAR_LIST_MANUFACTURERS_QUERY,
	LOCAL_STORAGE_MANUFACTURES,
} from 'helpers';

export const useManufacturers = () => {
	const path = `/v2/supplier/vehicle/maker/list`;

	const localStorageManufactures = ls.get(LOCAL_STORAGE_MANUFACTURES);

	const { data, isError, isFetching, isFetched } = useQuery(
		CAR_LIST_MANUFACTURERS_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: !localStorageManufactures }
	);

	const fetchedData = data?.data.data || [];

	useEffect(() => {
		if (!localStorageManufactures && isFetched && fetchedData.length) {
			ls.set(LOCAL_STORAGE_MANUFACTURES, fetchedData, {
				ttl: 14 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStorageManufactures, isFetched, fetchedData]);

	const result = localStorageManufactures || fetchedData;

	return { data: result, isFetching, isError };
};
