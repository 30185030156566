import React from 'react';
import { bool, func, object } from 'prop-types';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
} from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';

// Import utilities
import { useTranslations } from 'components/utilities';
import useDateRangeModal from './useDateRangeModal';

// Import styles
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useStyles } from './stylesOverrides';

const DateRangeModal = ({ isOpen, onClose, updateValues, initialPeriod }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		ranges,
		months,
		onChange,
		rangeColors,
		locale,
		inputRanges,
		staticRanges,
	} = useDateRangeModal({
		initialPeriod,
	});

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="lg">
			<DialogContent>
				<DateRangePicker
					onChange={onChange}
					moveRangeOnFirstSelection={false}
					weekStartsOn={0}
					months={months}
					ranges={ranges}
					rangeColors={rangeColors}
					locale={locale}
					staticRanges={staticRanges}
					inputRanges={inputRanges}
					className={classes.dateRange}
					dateDisplayFormat="dd/MM/yyyy"
					direction="horizontal"
					showSelectionPreview
					editableDateInputs
				/>
			</DialogContent>
			<DialogActions>
				<Box padding={1}>
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item>
							<Button onClick={onClose} color="primary">
								{t('common.buttons.cancel')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								color="primary"
								variant="contained"
								onClick={() => updateValues(ranges)}
							>
								{t('common.buttons.ok')}
							</Button>{' '}
						</Grid>
					</Grid>
				</Box>
			</DialogActions>
		</Dialog>
	);
};
DateRangeModal.propTypes = {
	isOpen: bool.isRequired,
	onClose: func.isRequired,
	updateValues: func.isRequired,
	initialPeriod: object.isRequired,
};

export default DateRangeModal;
