import React from 'react';
import { Form } from 'react-final-form';

// Import components
import { DataFormContent } from './components';

// Import utils
import { useDataForm } from './useDataForm';

// Import helpers
import { validationSchema } from './validation';
import { yupValidator } from 'helpers';

export const DataForm = () => {
	const { handleOnSubmit, isLoading, initialValues, apiErrors } = useDataForm();

	return (
		<Form
			onSubmit={handleOnSubmit}
			initialValues={initialValues}
			validateOnSubmit
			keepDirtyOnReinitialize
			validate={(values) =>
				yupValidator({ values, validationSchema: validationSchema(values) })
			}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<DataFormContent isLoading={isLoading} submitApiErrors={apiErrors} />
				</form>
			)}
		/>
	);
};
