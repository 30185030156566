import React from 'react';
import { updateActiveCar } from 'store/actions';
import { TableCell } from '@material-ui/core';

// Import helpers
import { TABLE_ACTIONS_ACCESSORS, COMPANY_MANAGERS } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { ActiveTableCell } from 'components/elements';
import {
	AvailableTableCell,
	EditModalTableCell,
	EditFormTableCell,
	ActionTableCell,
} from './';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, AVAILABLE, ACTION } = COLUMNS_ACCESSORS;

const useTableCarsBodyRow = ({ roles, officeRowID }) => {
	const renderCell = (cell) => {
		const userRole = roles[roles.length - 1];

		switch (cell.column.id) {
			case EDIT: {
				return COMPANY_MANAGERS.includes(userRole) ? (
					<EditFormTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={`/offices/${officeRowID}/car/${cell.row.original.uuid}/edit`}
					/>
				) : (
					<EditModalTableCell key={cell.column.id} cell={cell} />
				);
			}
			case ACTIVE: {
				return (
					<ActiveTableCell
						activeAction={updateActiveCar}
						key={cell.column.id}
						cell={cell}
					/>
				);
			}
			case AVAILABLE: {
				return <AvailableTableCell key={cell.column.id} cell={cell} />;
			}
			case ACTION: {
				return <ActionTableCell key={cell.column.id} cell={cell} />;
			}
			default: {
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
			}
		}
	};

	return { renderCell };
};

export default useTableCarsBodyRow;
