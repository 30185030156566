import React from 'react';
import { useForm } from 'react-final-form';
import { Grid, Button, Typography } from '@material-ui/core';
import { number, object } from 'prop-types';

// Import utils
import { useTranslations, useCommonFields } from 'components/utilities';

// Import helpers
import { COORDINATOR_FIELDS } from './helpers';

// Import styles
import { useStyles } from './CoordinatorFieldsRow.styles';
import { usePhonePrefixes } from 'queries';

export const CoordinatorFieldsRow = ({ index, fields }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { mutators } = useForm();
	const { data: prefixes } = usePhonePrefixes();

	const handleOnAddCoordinator = () => mutators.push('coordinators', undefined);
	const handleOnRemoveCoordinator = () => fields.remove(index);

	const isRemoveCoordinatorButtonVisible = fields.length > 1;
	const isAddCoordinatorButtonVisible =
		fields.length - 1 === index && fields.length < 10;

	const classes = useStyles({ isAddCoordinatorButtonVisible });

	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h5" className={classes.heading}>
					{t('b2b.coordinator')} {index + 1}
				</Typography>
			</Grid>
			{COORDINATOR_FIELDS({ index, prefixes }).map(renderFields)}
			<Grid item xs={12} md={6}>
				{isAddCoordinatorButtonVisible && (
					<Button
						color="primary"
						variant="contained"
						onClick={handleOnAddCoordinator}
					>
						{t('common.buttons.add_new')}
					</Button>
				)}
				{isRemoveCoordinatorButtonVisible && (
					<Button
						variant="contained"
						className={classes.removeButton}
						onClick={handleOnRemoveCoordinator}
					>
						{t('common.buttons.remove')}
					</Button>
				)}
			</Grid>
		</>
	);
};

CoordinatorFieldsRow.propTypes = {
	fields: object.isRequired,
	index: number.isRequired,
};
