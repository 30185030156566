import * as types from './types';
import produce from 'immer';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_OPTIONS:
				draft.options = action.payload;
				break;

			case types.SET_LOADING:
				draft.loading = action.payload;
				break;

			case types.SET_TABLE_DATA:
				draft.data = action.payload.data;
				draft.pageCount = action.payload.pageCount;
				draft.recordsFiltered = action.payload.recordsFiltered;
				draft.resources = action.payload.resources;
				draft.roles = action.payload.rolesData;
				break;

			case types.SET_ERROR:
				draft.error = action.payload;
				break;

			case types.SET_FILTERS:
				draft.filters = action.payload;
				break;

			case types.SET_TABLE_COLUMNS:
				draft.tableColumns = action.payload;
				break;

			case types.SET_INPUT_COLUMNS:
				draft.inputColumns = action.payload;
				break;

			default:
				return state;
		}
	});

export default reducer;
