import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: ({ isBorderRadius }) => ({
		backgroundColor: theme.palette.white,
		borderRadius: isBorderRadius ? '24px' : 'none',
		overflow: 'hidden',
	}),
	loadingContainer: {
		display: 'grid',
		placeItems: 'center',
		width: '100%',
		minHeight: '100px',
	},
}));
