import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { BookingUrgentCardSection } from '../index';

const BookingUrgentCardContainer = () => {
	return (
		<Grid item md={4}>
			<BookingUrgentCardSection />
		</Grid>
	);
};

export default BookingUrgentCardContainer;
