// ********************  table  ********************

export const fetchSpecialOffersUrl = (queryParams) =>
	`/v2/special-offer/list${queryParams}`;

export const deleteSpecialOfferUrl = (id) => `/v2/special-offer/${id}/remove`;

// ********************  form  ********************

export const businessCompanyActiveCollectionUrl =
	'/v2/supplier/business/company/collection-active';

export const showSpecialOfferUrl = (itemId) =>
	`/v2/special-offer/${itemId}/show`;

export const submitSpecialOfferFormUrl = (itemId, isEdit) =>
	`/v2/special-offer/${isEdit ? `${itemId}/update` : `create`}`;
