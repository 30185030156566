export const sendContractOtpUrl = '/econtract/send-otp';
export const createContractUrl = '/econtract/create';
export const saveContractUrl = '/econtract/save';
export const saveContractAsDraftUrl = '/econtract/draft-save';

export const updatePlateUrl = (plateUuid) =>
	`/v2/vehicle/plate/${plateUuid}/update-econtract`;

export const updateBookingUrl = (bookingUuid) =>
	`/v2/booking/${bookingUuid}/econtract`;

export const updateBookingStateUrl = (bookingUuid) =>
	`/v2/booking/${bookingUuid}/econtract/change-status`;

export const extendContractUrl = (contractNumber) =>
	`/econtract/${contractNumber}/extend`;

export const closeContractUrl = (contractNumber) =>
	`/econtract/${contractNumber}/close`;

export const suspendContractUrl = (contractNumber) =>
	`/econtract/${contractNumber}/suspend`;

export const updateVehiclePlateUrl = (plateUuid) =>
	`/v2/vehicle/plate/${plateUuid}/update`;
