import React from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';

// Import components
import { SettleForm } from './components';
import { useTableActionsProvider } from '../TableActionsProvider';

export const SettleDialog = () => {
	const { selectedChildren, selectedParent } = useTableActionsProvider();
	const { t } = useTranslations();

	const { on, toggle } = useToggle();

	return (
		<>
			<Button
				color="primary"
				variant="contained"
				onClick={toggle}
				disabled={!selectedChildren.length || !selectedParent}
			>
				{t('rewards_settlement.settle')}
			</Button>
			<Dialog fullWidth open={on} onClose={toggle}>
				<DialogContent>
					<SettleForm toggle={toggle} />
				</DialogContent>
			</Dialog>
		</>
	);
};
