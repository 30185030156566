import React from 'react';
import { bool, func } from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { ValidateCancellationForm } from '../../components';
import { ClosableDialogTitle } from 'components/elements';

// Import styles
import { useStyles } from './ValidationModal.styles';

export const ValidationModal = ({ open, onClose }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Dialog fullWidth disableEscapeKeyDown open={open} onClose={onClose}>
			<ClosableDialogTitle
				onClose={onClose}
				title={t('validation.table.modal.cancellation_title')}
			/>
			<DialogContent>
				<ValidateCancellationForm />
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button color="primary" variant="outlined" onClick={onClose}>
					{t('common.buttons.ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

ValidationModal.propTypes = {
	open: bool.isRequired,
	onClose: func.isRequired,
};
