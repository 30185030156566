import useCalculateUrgentTime from './useCalculateUrgentTime';
import { BOOKING_URGENT_STATES } from 'helpers';

const useUrgentStatusCell = ({ cell }) => {
	const { SOLVED, UNSOLVED } = BOOKING_URGENT_STATES;

	const { urgent_assistance_status: status, urgentAssistance } =
		cell.row.original;

	const { elapsedTime } = useCalculateUrgentTime({ urgentAssistance });

	const color =
		status === SOLVED
			? 'primary'
			: status === UNSOLVED
			? 'error'
			: 'textPrimary';

	return {
		status,
		color,
		elapsedTime,
	};
};

export default useUrgentStatusCell;
