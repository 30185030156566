// Import helpers
import { getCompensationTypeLabel } from 'helpers';

export const getBookingTopics = (row) => {
	const {
		operation_topics: operationTopics,
		compensation: { type },
	} = row;

	let topics = [
		...operationTopics,
		getCompensationTypeLabel({ type, removeCancelState: true }),
	];

	return topics.join(', ');
};
