export const INITIAL_VALUES = {
	company_uuid: null,
	holidays: [
		{
			date_from: null,
			date_to: null,
			selected: false,
			name: 'eid_al_fitr_holiday',
		},
		{
			date_from: null,
			date_to: null,
			selected: false,
			name: 'eid_al_adha_holiday',
		},
		{
			date_from: null,
			date_to: null,
			selected: false,
			name: 'national_day_holiday',
		},
	],
};
