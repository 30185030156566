import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import store
import { updateCorporationCustomer } from 'store/actions';
import { profileDataSelector } from 'store/selectors';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { useCorporationCustomer } from 'queries';

export const useEditCorporateCustomerForm = () => {
	const [isButtonLoading, setButtonLoading] = useState(false);
	const { message } = useMessage();
	const { t } = useTranslations();

	const {
		corporate: { uuid: corporateUuid },
	} = useSelector(profileDataSelector);

	const { id: customerUuid } = useParams();

	const {
		data: initialValues,
		isFetching,
		isError,
		refetch,
	} = useCorporationCustomer({
		corporateUuid,
		customerUuid,
	});

	const convertedInitialValues = useMemo(() => {
		const { phone } = initialValues || {};
		const phoneNumber = phone?.slice(phone?.length - 9);
		const prefix = phone?.slice(0, phone?.length - phoneNumber?.length);

		return {
			...initialValues,
			phone: phoneNumber,
			prefix,
		};
	}, [initialValues]);

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setButtonLoading(true);

		await updateCorporationCustomer({
			errorCallback: (error) => {
				if (error.response) {
					apiErrors = error.response.data.errors;
				}
				setButtonLoading(false);
				message.error(t('common.messages.error_message'));
			},
			successCallback: async () => {
				message.success(t('common.messages.successfully_created'));
				setButtonLoading(false);
				await refetch();
			},
			corporateUuid,
			customerUuid,
			values,
		});

		return apiErrors;
	};

	return {
		initialValues: convertedInitialValues,
		handleOnSubmit,
		isButtonLoading,
		isFetching,
		isError,
	};
};
