export const fieldNames = (section) => ({
	labelFieldName: `${section}.location.label`,
	formattedLocationFieldName: `${section}.location.formatted`,
	placeIdFieldName: `${section}.location.place_id`,
	locationLatFieldName: `${section}.location.lat`,
	locationLngFieldName: `${section}.location.lng`,
	manufacturerFieldName: `${section}.vehicle.maker_uuid`,
	modelFieldName: `${section}.vehicle.model_uuid`,
	typeFieldName: `${section}.vehicle.type_uuid`,
	serviceTypeFieldName: `${section}.service.type`,
	airportFieldName: `${section}.service.airport_uuid`,
});
