import { useParams } from 'react-router-dom';

// Import store
import { settleCorporateBooking } from 'store/actions';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';
import { useUnsettledLimitsProvider } from '../../context';

export const useAddNewSettlementDialog = () => {
	const { setButtonLoading, toggleDialog, settledUuids, setSettledUuids } =
		useUnsettledLimitsProvider();
	const { fetchData } = useTableContextProvider();
	const { id: corporateUuid } = useParams();
	const { message } = useMessage();
	const { t } = useTranslations();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setButtonLoading(true);

		await settleCorporateBooking({
			successCallback: async () => {
				message.success(t('common.messages.successfully_saved'));
				setButtonLoading(false);
				setSettledUuids([]);
				toggleDialog();
				await fetchData();
			},
			errorCallback: (error) => {
				if (error.response) {
					apiErrors = error.response.data.errors;
				}
				message.error(t('common.messages.error_message'));
				setButtonLoading(false);
			},
			corporateUuid,
			settledUuids,
			values,
		});

		return apiErrors;
	};

	return {
		handleOnSubmit,
	};
};
