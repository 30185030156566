import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

export const EditCorporateFormContext = createContext(null);

// TODO it will be necessary in improvements phase
export const EditCorporateFormProvider = ({ children }) => {
	const [initialCorporateValues, setInitialCorporateValues] = useState(null);
	const [initialCoordinatorsValues, setCoordinatorsValues] = useState([]);

	return (
		<EditCorporateFormContext.Provider
			value={{
				setInitialCorporateValues,
				setCoordinatorsValues,
				initialCorporateValues,
				initialCoordinatorsValues,
			}}
		>
			{children}
		</EditCorporateFormContext.Provider>
	);
};

EditCorporateFormProvider.propTypes = {
	children: node,
};
