import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	drawer: {
		width: 290,
		[theme.breakpoints.up('lg')]: {
			height: '100%',
		},
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		backgroundColor: theme.palette.white,
		padding: theme.spacing(0),
	},
	nav: {
		marginBottom: theme.spacing(2),
	},
	logoWrapper: {
		marginLeft: 34,
	},
}));
