import { useMutation } from 'react-query';
import { printBooking } from 'store/actions';

const usePrintTableCell = ({ uuid }) => {
	const [mutate, { isLoading }] = useMutation((data) => printBooking(data));

	const print = () => mutate({ bookingUuid: uuid });

	return {
		print,
		isLoading,
	};
};

export default usePrintTableCell;
