import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	UrgentStatusAssign,
	UrgentStatusPreview,
	PermissionFallback,
} from './components';
import RoleProtected from 'components/hoc/RoleProtected';

// Import helpers
import { PERMISSION_URGENT_ASSIGN } from '../../../../helpers';

const BookingUrgentCardSection = () => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<RoleProtected
					roles={PERMISSION_URGENT_ASSIGN}
					fallback={<PermissionFallback />}
				>
					<UrgentStatusAssign />
				</RoleProtected>
			</Grid>
			<Grid item xs={12}>
				<UrgentStatusPreview />
			</Grid>
		</Grid>
	);
};

export default BookingUrgentCardSection;
