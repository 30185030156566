import React, { useState } from 'react';
import { string } from 'prop-types';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	Tab,
	Tabs,
	AppBar,
} from '@material-ui/core';

import TabPanelContent from './TabPanelContent';
import TabPanel from './TabPanel';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { EN, AR } from 'helpers';

//Import styles
import useStyles from './styles';

const SeoCard = ({
	className,
	section = '',
	titleName = '',
	keywordsName = '',
	descriptionName = '',
	...rest
}) => {
	const classes = useStyles();

	const [value, setValue] = useState(0);
	const [lang, setLang] = useState(EN);

	const { t } = useTranslations();

	const handleChange = (_, newValue) => {
		setValue(newValue);
		setLang((prevLang) => (prevLang === EN ? AR : EN));
	};

	const a11yProps = (index) => ({
		id: `tab-${index}`,
		'aria-controls': `tabpanel-${index}`,
	});

	return (
		<Card {...rest} className={className}>
			<CardHeader component="h5" title={t('form.seo.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<AppBar position="static" className={classes.appBar}>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="tabs"
							indicatorColor="primary"
						>
							<Tab
								label={t('form.seo.tab_english')}
								{...a11yProps(0)}
								className={classes.tab}
							/>
							<Tab
								label={t('form.seo.tab_arabic')}
								{...a11yProps(1)}
								className={classes.tab}
							/>
						</Tabs>
					</AppBar>
					<TabPanel value={value} index={0}>
						<TabPanelContent
							{...{ lang, section, titleName, keywordsName, descriptionName }}
						/>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<TabPanelContent
							{...{ lang, section, titleName, keywordsName, descriptionName }}
						/>
					</TabPanel>
				</Grid>
			</CardContent>
		</Card>
	);
};

SeoCard.propTypes = {
	className: string,
	section: string,
	titleName: string,
	keywordsName: string,
	descriptionName: string,
};

export default SeoCard;
