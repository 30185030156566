import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { REPORT_CANCELLATION_RATE_QUERY } from 'helpers';
import { convertFiltersToParams } from './helpers';

export const useCancellationRateQuery = (filters) => {
	const params = convertFiltersToParams(filters);
	const path = `/report/cancellation-rate?${params}`;

	const { data, isLoading, isError, error } = useQuery(
		[REPORT_CANCELLATION_RATE_QUERY, filters],
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
		}
	);

	const fetchedData = data ? data.data : null;

	return { data: fetchedData, isLoading, isError, error };
};
