import React from 'react';
import { bool, func } from 'prop-types';
import { Field } from 'react-final-form';
import { Grid, Button } from '@material-ui/core';

// Import helpers
import { ADDITIONAL_INFO_FIELDS } from './helpers';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';
import DividerLine from '../DividerLine';

const FilterFields = ({ submitting, clearFilters }) => {
	const { t } = useTranslations();

	// eslint-disable-next-line react/prop-types
	const renderItem = ({ name, label }) => (
		<Grid key={name} item md={3} xs={6}>
			<Field
				type="checkbox"
				component={CheckboxFieldAdapter}
				name={name}
				disabled={submitting}
				labelText={t(label)}
				color="primary"
			/>
		</Grid>
	);

	return (
		<>
			{ADDITIONAL_INFO_FIELDS.map(renderItem)}

			<DividerLine />

			<Grid item md={12} xs={12}>
				<Button
					type="button"
					onClick={clearFilters}
					disabled={submitting}
					variant="contained"
					color="primary"
				>
					{t('table.modal.edit.btn_clear')}
				</Button>
			</Grid>
		</>
	);
};

FilterFields.propTypes = {
	submitting: bool.isRequired,
	clearFilters: func.isRequired,
};

export default FilterFields;
