import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { B2B_RENTAL_COMPANIES } from 'helpers';

export const useRentalCompanies = () => {
	const path = `/dashboard/b2b/configuration`;

	const { data, isError, isFetching, refetch } = useQuery(
		B2B_RENTAL_COMPANIES,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data : [];

	return { data: fetchedData, isFetching, isError, refetch };
};
