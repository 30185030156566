import { convertFormDates, YMD_DATE_FORMAT } from 'helpers';
import { format, getDaysInMonth } from 'date-fns';

export const getInitialValues = () => {
	const currentMonth = (new Date().getMonth() + 1).toString();
	const currentYear = new Date().getFullYear().toString();

	return {
		month: currentMonth.length === 1 ? `0${currentMonth}` : currentMonth,
		year: currentYear,
	};
};

export const getCurrentPeriodData = ({ year, month }) => {
	const newCreatedAt = new Date(`${year}-${month}-01`);
	const monthDays = getDaysInMonth(newCreatedAt);
	const newCreatedAtTo = new Date(`${year}-${month}-${monthDays}`);

	return {
		formattedFromDate: format(newCreatedAt, YMD_DATE_FORMAT),
		formattedToDate: format(newCreatedAtTo, YMD_DATE_FORMAT),
		from: newCreatedAt,
		to: newCreatedAtTo,
	};
};

export const formatValues = (values) => {
	const { from, to } = getCurrentPeriodData(values);

	const dateValues = {
		created_at: from,
		created_at_to: to,
	};

	return {
		...values,
		...convertFormDates(dateValues, ['created_at']),
	};
};
