import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		width: '100%',
	},
	price: {
		padding: '0 5px',
	},
}));

export default useStyles;
