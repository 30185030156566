import axios from 'axios';

// Import helpers
import { getHeaders } from 'helpers/headers';

// Node js apis
const nodeApisAxiosFactory = () => (config) => {
	const headers = getHeaders(config);
	return axios.create({
		// eslint-disable-next-line
		baseURL: `${process.env.REACT_APP_NODE_API_URL}`,
		headers,
	});
};

const nodeApisAxios = nodeApisAxiosFactory();

export default nodeApisAxios;
