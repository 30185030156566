import React, { useState } from 'react';
import { Collapse, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { number, object } from 'prop-types';

// Import store
import { deleteCoordinator } from 'store/actions';

// Import utils
import {
	useTranslations,
	useCommonFields,
	useToggle,
	useMessage,
} from 'components/utilities';

// Import helpers
import { COORDINATOR_FIELDS } from './helpers';

// Import styles
import { useStyles } from './CoordinatorFieldsRow.styles';

// Import components
import { ButtonProgress, ToggleButton } from 'components/elements';
import { usePhonePrefixes } from 'queries';

export const CoordinatorFieldsRow = ({ index, fields }) => {
	const [isRemoveButtonLoading, setRemoveButtonLoading] = useState(false);
	const { on: isOpen, toggle } = useToggle();
	const { renderFields } = useCommonFields();
	const { id: corporateUuid } = useParams();
	const { message } = useMessage();
	const { t } = useTranslations();
	const classes = useStyles();

	const isRemoveCoordinatorButtonVisible = fields.length > 1;

	const { data: prefixes } = usePhonePrefixes();

	const handleOnRemoveCoordinator = async () => {
		const { uuid: coordinatorUuid } = fields?.value?.[index] || {};
		if (coordinatorUuid) {
			setRemoveButtonLoading(true);
			await deleteCoordinator({
				coordinatorUuid,
				corporateUuid,
				successCallback: () => {
					message.success(t('common.messages.successfully_deleted'));
					setRemoveButtonLoading(false);
				},
				errorCallback: () => {
					message.error(t('common.message.error_message'));
					setRemoveButtonLoading(false);
				},
			});
			fields.remove(index);
		} else {
			fields.remove(index);
		}
	};

	return (
		<>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h5" className={classes.heading}>
						{t('b2b.coordinator')} {index + 1}
					</Typography>
				</Grid>
				<Grid item>
					<ToggleButton onClick={toggle} isOpen={isOpen} />
				</Grid>
			</Grid>
			<Collapse in={isOpen}>
				<Grid container spacing={2}>
					{COORDINATOR_FIELDS({ index, prefixes }).map(renderFields)}
					{isRemoveCoordinatorButtonVisible && (
						<Grid item md={6}>
							<ButtonProgress
								variant="contained"
								className={classes.removeButton}
								onClick={handleOnRemoveCoordinator}
								isLoading={isRemoveButtonLoading}
								disabled={isRemoveButtonLoading}
							>
								{t('common.buttons.remove')}
							</ButtonProgress>
						</Grid>
					)}
				</Grid>
			</Collapse>
		</>
	);
};

CoordinatorFieldsRow.propTypes = {
	fields: object.isRequired,
	index: number.isRequired,
};
