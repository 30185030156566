import React from 'react';
import { editBookingFormSelector } from 'store/selectors';
import { submitBookingForm, fetchBookingsFormResources } from 'store/actions';
import { Grid, Box } from '@material-ui/core';

// Import utilities
import useEditBookingContent from './useEditBookingContent';
import { FormRPC } from 'components/utilities';

// Import components
import { BookingInfo } from '../../components';
import { EditBookingCard } from './components';

const EditBookingContent = () => {
	const { callbackSuccess, isInfoLoaded, errorCallback } =
		useEditBookingContent();

	return (
		<Box p={4}>
			<Grid container spacing={4}>
				<Grid item lg={6} xs={12}>
					{isInfoLoaded && <BookingInfo />}
				</Grid>
				<Grid item lg={6} xs={12}>
					<FormRPC
						fetchFormResAction={fetchBookingsFormResources}
						submitFormAction={(options) =>
							submitBookingForm({ ...options, errorCallback })
						}
						callbackFormSuccess={callbackSuccess}
						store={editBookingFormSelector}
						isActionButtonsHidden
						isCardLayout={false}
						goBackPath=""
						title=""
					>
						{() => <EditBookingCard />}
					</FormRPC>
				</Grid>
			</Grid>
		</Box>
	);
};

export default EditBookingContent;
