import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { array } from 'prop-types';

// Import utils
import {
	useCommonFields,
	useMediaQuerySizes,
	useTranslations,
} from 'components/utilities';

// Import components
import { TextFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from 'theme/styles/eContract.styles';

export const CarInspection = ({ fields }) => {
	const { renderFields } = useCommonFields();
	const classes = useStyles();
	const { isTabletSize } = useMediaQuerySizes();
	const { t } = useTranslations();

	return (
		<Grid container spacing={isTabletSize ? 1 : 4}>
			{fields.map(renderFields)}
			<Grid item md={12} xs={12}>
				<Field
					label={t('contract.create_contract_form.car_inspection.notes')}
					component={TextFieldAdapter}
					name="rent_status.notes"
					variant="outlined"
					margin="dense"
					type="text"
					fullWidth
					multiline
					rows={4}
					className={classes.notes}
				/>
			</Grid>
		</Grid>
	);
};

CarInspection.propTypes = {
	fields: array,
};
