export const COLUMNS_ACCESSORS = {
	LICENSE_PREVIEW: 'license_preview',
	VERIFICATION_NEEDED: 'verification_needed',
};

export const columns = [
	{
		Header: 'customers.driver_license_list.headers.phone_number',
		accessor: 'user_phone',
		disableSortBy: true,
	},
	{
		Header: 'customers.driver_license_list.headers.name',
		accessor: 'user_name',
		disableSortBy: true,
	},
	{
		Header: 'customers.driver_license_list.headers.updated_at',
		accessor: 'updated_at',
		disableSortBy: true,
	},
	{
		Header: 'customers.driver_license_list.headers.email',
		accessor: 'user_email',
		disableSortBy: true,
	},
	{
		Header: 'customers.driver_license_list.headers.verification_needed',
		accessor: COLUMNS_ACCESSORS.VERIFICATION_NEEDED,
		disableSortBy: true,
	},
	{
		Header: 'customers.driver_license_list.headers.license_preview',
		accessor: COLUMNS_ACCESSORS.LICENSE_PREVIEW,
		disableSortBy: true,
	},
];
