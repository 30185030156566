import React from 'react';
import { object } from 'prop-types';
import { Box } from '@material-ui/core';

// Import components
import { Steps } from '../PickUpAddressPreviewField/components';

// Import styles
import { useStyles } from './OldDeliveryInformationPreviewField.styles';

export const OldDeliveryInformationPreviewField = ({
	customData: delivery,
}) => {
	const classes = useStyles();
	const { delivery_type: deliveryType } = delivery || {};

	const customDeliveryData = {
		[delivery.delivery_type]: { ...delivery, state: delivery.delivery_state },
	};

	return (
		<Box className={classes.container}>
			<Steps
				deliveryType={deliveryType}
				customDeliveryData={customDeliveryData}
			/>
		</Box>
	);
};

OldDeliveryInformationPreviewField.propTypes = {
	customData: object,
};
