import { without } from 'lodash';
import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from './helpers';
import { generateArray } from 'helpers';

// Import components
import { SelectFieldAdapter } from 'components/elements';

export const useSettingsFields = () => {
	const { DATE_RANGE, SELECT } = FIELD_TYPES;

	const minAvgRatingOptions = generateArray(5, 10).map((value) => {
		const option = value / 10 + 4;
		return {
			value: option,
			label: option,
		};
	});

	// Generate array  0, 2...8  - without 1
	const rewardPriceOptions = without(generateArray(0, 8), 1).map((value) => {
		const label = value
			? `${i18next.t('currency.sar')} ${value}`
			: i18next.t('employee_rewarding.form.fields.no_reward');

		return {
			value,
			label,
		};
	});

	const SETTINGS_FIELDS = [
		{
			component: SelectFieldAdapter,
			name: 'company_manager_reward_price',
			type: SELECT,
			label: 'company_manager_reward_price',
			options: rewardPriceOptions,
		},
		{
			component: SelectFieldAdapter,
			name: 'office_manager_reward_price',
			type: SELECT,
			label: 'office_manager_reward_price',
			options: rewardPriceOptions,
		},
		{
			component: SelectFieldAdapter,
			name: 'driver_reward_price',
			type: SELECT,
			label: 'driver',
			options: rewardPriceOptions,
		},
		{
			component: SelectFieldAdapter,
			name: 'minimum_average_rating',
			type: SELECT,
			label: 'minimum_average_rating',
			options: minAvgRatingOptions,
		},
		{
			nameFrom: 'date',
			nameUntil: 'date_to',
			type: DATE_RANGE,
			label: 'date_range',
			options: minAvgRatingOptions,
		},
	];
	return {
		SETTINGS_FIELDS,
	};
};
