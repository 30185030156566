import { useSelector } from 'react-redux';
import { customerRatingSelector } from 'store/selectors/bookings';

const useCustomerRatingPreview = () => {
    const rating = useSelector(customerRatingSelector);

	return {
		rating
	};
};

export default useCustomerRatingPreview;