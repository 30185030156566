// Import utilities
import { useTranslations } from 'components/utilities';

export const useIsSettledTableCell = ({ isSettled }) => {
	const { t } = useTranslations();

	const employeeRewardingPrefix = 'employee_rewarding.table.cells.is_settled';

	const isSettledText = t(
		`${employeeRewardingPrefix}.${isSettled ? 'yes' : 'no'}`
	);

	const chipLabel = t(`${employeeRewardingPrefix}.not_available`);

	return {
		chipLabel,
		isSettledText,
	};
};
