import i18next from 'i18next';

// Import helpers
import { CAR_YEARS_QUERY, CAR_MODELS_QUERY, OFFICES_CARS_QUERY } from 'helpers';

export const QUERY_KEYS = {
	CAR_YEARS_QUERY,
	CAR_MODELS_QUERY,
	OFFICES_CARS_QUERY,
};

export const fieldNames = ({ lng }) => ({
	manufacturerFieldName: `carInfo.vehicle.maker_name.${lng}`,
	modelFieldName: `carInfo.vehicle.model_name.${lng}`,
	yearFieldName: 'carInfo.vehicle.year',
	carUuidFieldName: 'carInfo.vehicle.uuid',
});

export const getGlobalUuid = ({ values, supplierVehicleList }) => {
	const lng = i18next.language;
	const currentModelName = values.carInfo.vehicle.model_name[lng];

	return supplierVehicleList.find(
		({ model_name }) => currentModelName === model_name[lng]
	)?.uuid;
};
