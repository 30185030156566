import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	loader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export default useStyles;
