import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	cell: {
		display: 'flex',
		alignItems: 'center',
	},
	button: {
		margin: theme.spacing(0),
		padding: '1px 16px',
		fontSize: '12px',
	},
}));
