import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import helpers
import { previewBookingSelector } from 'store/selectors';
import {
	BOOKING_COMPENSATION_STATES,
	BOOKING_COMPENSATION_TYPES,
	LOCATIONS,
} from 'helpers';
import { getDialogData } from './helpers';

const useMakeCompensationDialog = ({ outerCompensation, location }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { pathname } = useLocation();
	const isBookingPreview = pathname.toLowerCase().includes('preview');

	const { compensation: defaultCompensation } = useSelector(
		previewBookingSelector
	);

	const compensation = outerCompensation || defaultCompensation;
	const sectionName = 'bookings.customer_compensation';

	const showButton =
		compensation?.status !== BOOKING_COMPENSATION_STATES.COMPENSATED ||
		(location === LOCATIONS.BOOKING_PREVIEW &&
			compensation?.type === BOOKING_COMPENSATION_TYPES.AUTO_LOW_RATING);

	const { buttonTitle, dialogTitle } = getDialogData({
		compensation,
		sectionName,
		location,
	});

	const color = isBookingPreview ? 'primary' : 'secondary';

	const handleOnClose = () => setIsOpen(false);
	const handleOnOpen = () => setIsOpen(true);

	return {
		color,
		isOpen,
		showButton,
		buttonTitle,
		dialogTitle,
		onOpen: handleOnOpen,
		onClose: handleOnClose,
	};
};

export default useMakeCompensationDialog;
