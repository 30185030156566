export const initialValues = {
	company_uuid: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		company_uuid: values.company_uuid === 'all' ? null : values.company_uuid,
	};
};
