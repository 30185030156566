import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import  store
import { previewBookingSelector } from 'store/selectors';

// Import components
import PreviewField from './PreviewField';

export const DaysPreviewField = () => {
	const booking = useSelector(previewBookingSelector);

	const { early_return_details, period } = booking || {};

	const isEarlyReturn =
		!!early_return_details && !_.isEmpty(early_return_details);

	const days = isEarlyReturn
		? early_return_details.old_period_in_days
		: period.days;

	return (
		<PreviewField label="bookings.preview_booking.period.days">
			{days}
		</PreviewField>
	);
};
