// Import helpers
import { VALIDATION_LABELS_TYPES } from 'helpers';
import { URLS } from 'components/routes';

const { APPROVED, VALIDATED, COMPENSATED } = VALIDATION_LABELS_TYPES;

export const getStatus = ({
	cancellationStatus,
	compensationStatus,
	pathname,
}) => {
	if (
		pathname === URLS.cancellationValidationUrl ||
		pathname === URLS.cancellationByCustomerUrl
	) {
		return cancellationStatus === COMPENSATED ? APPROVED : cancellationStatus;
	} else {
		if (compensationStatus === APPROVED) return VALIDATED;
		else return compensationStatus;
	}
};

export const getValidationState = ({ pathname, status }) => {
	const statuses = Object.values(VALIDATION_LABELS_TYPES);
	const statusFounded = statuses.includes(status);
	const isUrgentBookingPage = pathname === URLS.urgentBookingsUrl;

	return statusFounded && !isUrgentBookingPage;
};
