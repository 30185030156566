import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

export const initialValues = {
	startDate: null,
	endDate: null,
	userType: null,
};

const formatDate = (date) => (date ? format(date, DEFAULT_DATE_FORMAT) : null);

export const formatValues = (values) => {
	const { startDate, endDate } = values || {};

	return {
		...values,
		startDate: startDate ? formatDate(values.startDate) : null,
		endDate: endDate ? formatDate(values.endDate) : null,
	};
};

export const generateApiColumns = (columns) =>
	columns.map(({ accessor }) => ({ name: accessor }));
