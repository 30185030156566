import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import actions
import {
	fetchLeasingCarInfo,
	setOfficeContext,
	setVehicleContext,
} from 'store/actions';

export const useAddLeasingCarForm = () => {
	const { officeId, vehicleId } = useParams();
	const dispatch = useDispatch();

	const handleFetchLeasingCarInfo = (formProps) =>
		fetchLeasingCarInfo({ ...formProps, officeId, vehicleId });

	useEffect(() => {
		setOfficeContext(dispatch, officeId);
		setVehicleContext(dispatch, vehicleId);
	}, [dispatch, officeId, vehicleId]);

	return { handleFetchLeasingCarInfo };
};
