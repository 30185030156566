import * as types from 'store/types';
import produce from 'immer';

// import helpers
import { initialValues } from 'store/actions/profile/helpers';

const INITIAL_STATE = {
	item: initialValues,
	prefixes: {},
	roles: [],
	companyList: [],
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.COMPANY_MANAGER_FORM_FETCH_DATA_LOADING:
				return INITIAL_STATE;

			case types.COMPANY_MANAGER_FORM_FETCH_DATA_SUCCESS:
				draft.item = action.payload.item || {};
				draft.prefixes = action.payload.phonePrefixes;
				draft.roles = action.payload.roles;
				draft.companyList = action.payload.companyList;
				draft.isLoaded = true;
				break;

			case types.COMPANY_MANAGER_FORM_FETCH_DATA_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				draft.error = action.payload;
				break;

			case types.COMPANY_MANAGER_FORM_ADD_USER_SUCCESS:
				draft.item = {
					...INITIAL_STATE.item,
					belonging: action.payload.belonging,
				};
				draft.prefixes = action.payload.phonePrefixes;
				draft.roles = action.payload.roles;
				draft.companyList = action.payload.companyList;
				draft.isLoaded = true;
				break;

			default:
				return state;
		}
	});
