import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';

const MediaLink = ({ href, title }) => {
	const token = useSelector(
		(state) => state.auth.authenticate.data.meta.access_token
	);

	return (
		<form action={href} method="POST" target="_blank">
			<input type="hidden" name="token" value={token} />

			<Link component="button" type="submit">
				{title}
			</Link>
		</form>
	);
};

MediaLink.propTypes = {
	href: PropTypes.string,
	title: PropTypes.string
};

export default MediaLink;
