export default {
	root: {
		'@media (max-width: 960px)': {
			alignItems: 'center',
			flexDirection: 'row',
			justifyContent: 'space-between',
			gap: '10px',
		},
	},
};
