import React from 'react';
import { Typography } from '@material-ui/core';
import i18next from 'i18next';

export const COLUMNS_ACCESSORS = {
	NOTE: 'note',
	BOOKING_ID: 'booking_id',
	STATUS: 'status',
	OFFICE_NAME: 'office_name',
	DURATION: 'response_time',
	APPROVED: 'approved',
	COMPENSATED: 'compensated',
	RATING_REASON: 'rating_reason',
	SETTLEMENT_STATUS: 'settlement_status',
	CUSTOMER_NAME: 'customer_name',
	PICK_DATE: 'pick_date',
	CREATED_AT: 'created_at',
	ACTIONS: 'actions',
	DROP_DATE: 'drop_date',
	ASSIGNED_DRIVER: 'assigned_driver',
	DELIVERY_STATUS: 'delivery_status',
	EXTENDED_AT: 'extended_at',
};

export const columns = (classes) => [
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.customer_type',
		accessor: 'customer_type',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.office_name',
		accessor: COLUMNS_ACCESSORS.OFFICE_NAME,
		disableSortBy: true,
		// eslint-disable-next-line react/display-name
		renderSubHeader: () => (
			<Typography className={classes.agentName}>
				{i18next.t('bookings.table.headers.agent_name')}
			</Typography>
		),
	},
	{
		Header: 'bookings.table.headers.office_city',
		accessor: 'office_city',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
	},
	{
		Header: 'bookings.table.headers.drop_date',
		accessor: COLUMNS_ACCESSORS.DROP_DATE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.created_time',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.extended_at',
		accessor: COLUMNS_ACCESSORS.EXTENDED_AT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cancellation_date',
		accessor: 'cancellation_date',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},

	// Additional
	{
		Header: 'bookings.table.headers.customer_nationality',
		accessor: 'customer_nationality',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.gender',
		accessor: 'gender',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.pick_location',
		accessor: 'pick_location',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.drop_location',
		accessor: 'drop_location',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.payment_method',
		accessor: 'payment_method',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.total_base_nett_price',
		accessor: 'total_base_nett_price',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.total_discounted_gross_price',
		accessor: 'total_discounted_gross_price',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: 'vehicle',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.booking_no',
		accessor: 'customer_no_booking',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.booking_time',
		accessor: 'respond_time',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.duration',
		accessor: COLUMNS_ACCESSORS.DURATION,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.updated_time',
		accessor: 'updated_at',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.approved',
		accessor: COLUMNS_ACCESSORS.APPROVED,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.admin_comment',
		accessor: 'admin_comment',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.license_number',
		accessor: 'license_number',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.compensated',
		accessor: COLUMNS_ACCESSORS.COMPENSATED,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'office_rating',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.rating_reason',
		accessor: COLUMNS_ACCESSORS.RATING_REASON,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.customer_email',
		accessor: 'customer_email',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.office_address',
		accessor: 'office_address',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.settlement_status',
		accessor: COLUMNS_ACCESSORS.SETTLEMENT_STATUS,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.assigned_driver',
		accessor: COLUMNS_ACCESSORS.ASSIGNED_DRIVER,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.delivery_status',
		accessor: COLUMNS_ACCESSORS.DELIVERY_STATUS,
		disableSortBy: true,
		isHidden: true,
	},
];
