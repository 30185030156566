import React from 'react';
import { func, string } from 'prop-types';
import clsx from 'clsx';
import { Toolbar, Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { EN } from 'helpers/variables';

//Import styles
import useStyles from './styles';

const Topbar = ({ handlePrint, lng }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const iconMarginClass =
		lng === EN ? classes.iconMarginRight : classes.iconMarginLeft;

	return (
		<Toolbar className={classes.alignButton}>
			<Button
				className={classes.buttonText}
				color="primary"
				variant="contained"
				onClick={handlePrint}
			>
				<PrintIcon className={clsx(classes.icon, iconMarginClass)} />
				{t('booking_print.print')}
			</Button>
		</Toolbar>
	);
};

Topbar.propTypes = {
	handlePrint: func.isRequired,
	lng: string.isRequired,
};

export default Topbar;
