import React from 'react';
import { array, number, object, string } from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';
import { PARTNERS_POSITIONING_FIELDS } from '../../helpers';

export const PartnersFieldsRow = ({ companies, fields, name, index }) => {
	const { renderFields } = useCommonFields();

	const isIconButtonDisabled = fields.length === 1;

	return (
		<React.Fragment key={name}>
			{PARTNERS_POSITIONING_FIELDS({ companies, name }).map(renderFields)}
			<Grid item md={2} xs={1}>
				<IconButton
					onClick={() => fields.remove(index)}
					disabled={isIconButtonDisabled}
					data-testid={`remove-partner-icon-${index}`}
				>
					<HighlightOffIcon
						color={isIconButtonDisabled ? 'disabled' : 'error'}
					/>
				</IconButton>
			</Grid>
		</React.Fragment>
	);
};

PartnersFieldsRow.propTypes = {
	companies: array.isRequired,
	fields: object.isRequired,
	name: string.isRequired,
	index: number.isRequired,
};
