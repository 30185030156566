import React from 'react';
import { bool, element, func, object } from 'prop-types';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import _ from 'lodash';

// Import components
import { useTableContextProvider } from 'components/context';
import TableFiltersContent from './TableFiltersContent';

// Import utils
import { useQueryStringParams } from 'components/utilities';

const TableFilters = ({
	children,
	initialValues,
	formatValues,
	additionalInfo = null,
	initialOpen = false,
}) => {
	const { setFilters, inputColumns } = useTableContextProvider();
	const { parsedFilters } = useQueryStringParams();

	const handleSubmit = (values) => {
		const newValues = formatValues
			? formatValues(values, { inputColumns })
			: values;

		setFilters(newValues);
	};

	const persistedFilters = _.mergeWith(
		parsedFilters,
		initialValues,
		(parsedValue, initialValue) =>
			_.isNil(parsedValue) ? initialValue : parsedValue
	);

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={persistedFilters}
			render={(props) => (
				// eslint-disable-next-line
				<Box component="form" width="100%" onSubmit={props.handleSubmit}>
					<TableFiltersContent
						additionalInfo={additionalInfo}
						initialValues={initialValues}
						initialOpen={initialOpen}
					>
						{children}
					</TableFiltersContent>
				</Box>
			)}
		/>
	);
};

TableFilters.propTypes = {
	children: func.isRequired,
	initialValues: object.isRequired,
	formatValues: func,
	additionalInfo: element,
	initialOpen: bool,
};

export default TableFilters;
