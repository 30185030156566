export const INITIAL_VALUES = {
	company: {
		name: {
			ar: '',
			en: '',
		},
	},
	discount: {
		discount: '',
		max_discount: '',
	},
};
