import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	chip: ({ isSettled }) => ({
		backgroundColor: isSettled
			? theme.palette.success.light
			: theme.palette.error.light,
		color: isSettled ? theme.palette.primary.main : theme.palette.error.main,
		width: '100%',
		borderRadius: '8px',
	}),
}));
