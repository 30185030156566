import { useState } from 'react';
import { useSelector } from 'react-redux';

// Import store
import { convertConfirmationStepValues } from 'store/actions/b2b/Overview/helpers';
import { assignCustomerToCorporate } from 'store/actions';
import { profileDataSelector } from 'store/selectors';

// Import utils
import { useAddCorporateCustomerDialogProvider } from '../../context';
import { useMessage, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';
import { DIALOG_STEPS } from '../../helpers';

export const useConfirmationStep = () => {
	const { toggleDialog, setInitialValues, setStep } =
		useAddCorporateCustomerDialogProvider();
	const [isButtonLoading, setButtonLoading] = useState(false);
	const { corporate } = useSelector(profileDataSelector);
	const { fetchData } = useTableContextProvider();
	const { message } = useMessage();
	const { t } = useTranslations();

	const { uuid: corporateUuid } = corporate || {};

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setButtonLoading(true);

		await assignCustomerToCorporate({
			errorCallback: async (error) => {
				if (error.response.data) {
					switch (error.response.status) {
						case 422:
							apiErrors = error.response.data.errors;
							setButtonLoading(false);
							break;

						case 400:
							message.error(error.response.data.message);
							setButtonLoading(false);
							break;

						case 404: {
							const initialValues = convertConfirmationStepValues(values);
							setInitialValues(initialValues);
							setButtonLoading(false);
							setStep(DIALOG_STEPS.ADD_CUSTOMER);
							break;
						}

						default:
							message.error(t('common.messages.error_message'));
							setButtonLoading(false);
							break;
					}
				}
			},
			successCallback: async () => {
				message.success(t('common.messages.successfully_done'));
				setButtonLoading(false);
				toggleDialog();
				await fetchData();
			},
			corporateUuid,
			values,
		});

		return apiErrors;
	};

	return {
		isButtonLoading,
		handleOnSubmit,
	};
};
