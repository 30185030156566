import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import components
import { ExpandedRow } from 'views/Offices/LeasingCars/components/ExpandedRow';
import { ExpandTableCell, ImageTableCell } from 'components/elements';

// Import context
import { useTableActionsProvider } from 'views/Offices/LeasingCars/context';

// Import variables
import { COLUMNS_ACCESSORS } from 'views/Offices/LeasingCars/columns';

const { IMAGE, EXPAND } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const { handleOnExpandRows, isRowOpen } = useTableActionsProvider();

	const renderCell = (cell) => {
		const { vehicle_uuid, vehicle_image, vehicles } = cell.row.original;
		const { id, isHidden } = cell.column;

		if (isHidden) return null;

		switch (id) {
			case EXPAND:
				return (
					<ExpandTableCell
						key={id}
						cell={cell}
						isOpen={isRowOpen(vehicle_uuid)}
						onClick={() => handleOnExpandRows(vehicle_uuid)}
						hideExpand={!vehicles.length}
					/>
				);

			case IMAGE:
				return <ImageTableCell cell={cell} key={id} imgUrl={vehicle_image} />;

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()} size="small">
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<>
			<TableRow key={row.id} {...row.getRowProps()}>
				{row.cells.map(renderCell)}
			</TableRow>
			<ExpandedRow
				isExpanded={isRowOpen(row.original.vehicle_uuid)}
				colSpan={row.cells.length}
				customResources={row.original.vehicles}
			/>
		</>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
