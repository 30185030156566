import React from 'react';
import { fetchCarsAvailability } from 'store/actions';

// Import components
import { TableExportButtons, TableExport } from 'components/elements';
import { ExportFilters } from '../../components';

// Import utilities
import { TableExportProvider } from 'components/context';
import { useExport } from './useExport';

export const Export = () => {
	const { exportColumns, isFetching, exportFilters } = useExport();

	return (
		<TableExportProvider
			columns={exportColumns}
			columnsLoading={isFetching}
			fetchDataTableAction={(options) =>
				fetchCarsAvailability({
					...options,
					filters: exportFilters,
					isExport: true,
				})
			}
		>
			<TableExportButtons />
			<TableExport
				renderFilters={() => <ExportFilters columns={exportColumns} />}
			/>
		</TableExportProvider>
	);
};
