import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import actions
import { updateActivePromoCode } from 'store/actions';

// Import helpers
import {
	TABLE_ACTIONS_ACCESSORS,
	replaceEditPromoCodeUrl,
	replacePromoCodeUrl,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import {
	EditTableCell,
	ActiveTableCell,
	LinkTableCell,
} from 'components/elements';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, CODE } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const { code } = cell.row.original;

		switch (cell.column.id) {
			case CODE:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePromoCodeUrl(code)}
					>
						{code}
					</LinkTableCell>
				);

			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActivePromoCode}
						cell={cell}
						id={cell.row.original.code}
					/>
				);

			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditPromoCodeUrl(cell.row.original.code)}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
