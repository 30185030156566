import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	CompensationStatusFilter,
	UrgentStatusFilter,
	CompanyFilter,
	DateRangeFilter,
} from 'components/elements';

const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<CompanyFilter
				name="company_uuid"
				label="waiting_customer_info.filters.company_name"
			/>
			<CompensationStatusFilter
				name="compensation_status"
				label="waiting_customer_info.filters.compensation_status"
			/>
			<UrgentStatusFilter
				name="urgent_assistance_status"
				label="waiting_customer_info.filters.urgent_status"
			/>

			<DateRangeFilter
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="drop_date"
				nameUntil="drop_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="urgent_assistance_created_at"
				nameUntil="urgent_assistance_created_at_to"
				label="bookings.filters.urgent_assistance_created_at"
				combinedInputs
			/>
		</Grid>
	);
};

export default FilterFields;
