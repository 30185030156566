import React from 'react';

// Import helpers
import { initialValues, formatValues } from './helpers';

// Import components
import { TableFilters } from 'components/elements';
import { FilterFields } from './components';

const Filters = () => {
	return (
		<TableFilters initialValues={initialValues} formatValues={formatValues}>
			{({ submitting }) => <FilterFields submitting={submitting} />}
		</TableFilters>
	);
};

export default Filters;
