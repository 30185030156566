export const RATE_SECTION_TYPES = {
	DAILY: 'daily',
	WEEKLY: 'weekly',
	MONTHLY: 'monthly',
	ANNUAL: 'annual',
};

export const SUBSCRIPTION_MODEL_PERIODS = {
	ONE: '1',
	THREE: '3',
	SIX: '6',
	NINE: '9',
	TWELVE: '12',
};

export const SUBSCRIPTION_MODEL_PERIODS_TEXT = {
	1: 'one',
	3: 'three',
	6: 'six',
	9: 'nine',
	12: 'twelve',
};
