import React from 'react';
import { object } from 'prop-types';
import { Typography } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

const Period = ({ period }) => {
	const { days, drop_date, pick_date, pick_time } = period;

	const { t } = useTranslations();

	const getDayUnit = days > 1 ? 'days' : 'day';

	return (
		<>
			<Typography variant="h5" color="primary" gutterBottom>
				{days} {t(`booking_print.${getDayUnit}`)}
			</Typography>
			<Typography>
				{`${t('booking_print.from')}: ${pick_date} ${pick_time}
				${t('booking_print.to')}: ${drop_date}`}
			</Typography>
		</>
	);
};

Period.propTypes = {
	period: object.isRequired,
};

export default Period;
