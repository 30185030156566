import React from 'react';
import { object } from 'prop-types';
import { Grid, IconButton, TableCell, Tooltip, Link } from '@material-ui/core';

// Import assets
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AddIcon from '@material-ui/icons/Add';
import { EditIcon } from 'assets/icons';

// Import components
import { CustomerLimitDialog } from './components';

// Import utils and helpers
import { useActionsTableCell } from './useActionsTableCell';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './ActionsTableCell.styles';

export const ActionsTableCell = ({ cell }) => {
	const {
		goToEditCorporateCustomerView,
		goToPreviewBookingView,
		coordinatorVerificationLink,
		customerUuid,
		bookingUuid,
	} = useActionsTableCell(cell);
	const { t } = useTranslations();
	const classes = useStyles();

	const xs = bookingUuid ? 6 : 4;
	return (
		<TableCell
			key={cell.column.id}
			className={classes.container}
			{...cell.getCellProps()}
		>
			<Grid container spacing={2}>
				<Grid item xs={xs}>
					<Tooltip
						title={t('b2b.corporate.overview.table.tooltips.book_a_car')}
					>
						<Link
							href={coordinatorVerificationLink}
							rel="noopener noreferrer"
							target="_blank"
						>
							<IconButton>
								<AddIcon className={classes.icon} />
							</IconButton>
						</Link>
					</Tooltip>
				</Grid>
				{bookingUuid && (
					<Grid item xs={xs}>
						<Tooltip
							title={t('b2b.corporate.overview.table.tooltips.edit_booking')}
						>
							<IconButton onClick={goToPreviewBookingView}>
								<EditIcon className={classes.icon} />
							</IconButton>
						</Tooltip>
					</Grid>
				)}
				<Grid item xs={xs}>
					<Tooltip
						title={t('b2b.corporate.overview.table.tooltips.edit_customer')}
					>
						<IconButton onClick={goToEditCorporateCustomerView}>
							<PersonOutlineIcon className={classes.icon} />
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item xs={xs}>
					<CustomerLimitDialog userUuid={customerUuid} />
				</Grid>
			</Grid>
		</TableCell>
	);
};

ActionsTableCell.propTypes = {
	cell: object,
};
