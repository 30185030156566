import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { fetchSliderFormResources, submitSliderForm } from 'store/actions';

// Import components
import { FormRPC } from 'components/utilities';
import { BaseDetails, SliderImage, CompaniesList } from './components';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import styles
import useStyles from './styles';

const SliderForm = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchSliderFormResources}
				submitFormAction={submitSliderForm}
				isCardLayout={false}
				goBackPath={URLS.slidersUrl}
				store={(store) => store.sliders.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<BaseDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<SliderImage section="image" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<CompaniesList />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default SliderForm;
