import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	buttonClose: {
		fontSize: '10px',
		position: 'absolute',
		top: '0',
		right: '0',
	},
	wrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 'max-content',
		textAlign: 'center',
	},
	badge: {
		display: 'block',
		marginRight: theme.spacing(1),
	},
	button: {
		fontSize: '12px',
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default useStyles;
