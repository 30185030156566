import React from 'react';
import { string, array, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

// Import components
import PreviewWrapper from './PreviewWrapper';
import PreviewField from './PreviewField';

// Import translations
import { useTranslations } from 'components/utilities';

// Import images
import { elmLogo } from 'assets/images';

// Import styles
import { useStyles } from '../../styles';

// Import helpers
import { VERIFY_TYPES } from 'helpers';

const Card = ({
	title,
	fields,
	labelWidth,
	isAction = false,
	showLogo = false,
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		item: { details },
	} = useSelector((store) => store.customers.preview);

	const isElmLogoVisible = VERIFY_TYPES.ELM === details.verify_type;

	return (
		<PreviewWrapper title={t(title)} isAction={isAction}>
			<Grid container>
				<Grid item xs={11}>
					{fields.map(({ name, label, isDriverLicense = false }) => (
						<PreviewField
							key={name}
							name={name}
							label={t(label)}
							labelWidth={labelWidth}
							isDriverLicense={isDriverLicense}
						/>
					))}
				</Grid>
				<Grid container item xs={1} justifyContent="flex-end">
					{showLogo && isElmLogoVisible && (
						<Grid item>
							<img
								src={elmLogo}
								className={classes.elmLogo}
								alt={t('bookings.table.notes.verified_by_elm')}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		</PreviewWrapper>
	);
};

Card.propTypes = {
	title: string.isRequired,
	fields: array.isRequired,
	showLogo: bool,
	isAction: bool,
	labelWidth: string,
};

export default Card;
