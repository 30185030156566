import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState } from 'react-final-form';

// Import components
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { fields } from './helpers';

const BaseDetails = ({ section, ...rest }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Card {...rest}>
			<CardHeader title={t('kiosk.airports.terminals.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{fields(section).map((field) => (
						<Grid key={field.name} item md={field.md} xs={field.xs}>
							<Field
								fullWidth
								component={field.component}
								type={field.type}
								label={t(field.label)}
								name={field.name}
								margin="dense"
								variant="outlined"
								disabled={submitting}
								inputProps={{ min: 0 }}
								required
							/>
						</Grid>
					))}
				</Grid>
			</CardContent>
		</Card>
	);
};

BaseDetails.propTypes = {
	section: PropTypes.string.isRequired,
};

export default BaseDetails;
