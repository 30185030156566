import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { Autocomplete } from '@material-ui/lab';
import { array, string } from 'prop-types';

// Import utilities
import useAutocompleteSelect from './useAutocompleteSelect';
import { useTranslations } from 'components/utilities';

// Import components
import { TextFieldAdapter } from 'components/elements';

const AutocompleteSelect = ({ label, options, name }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	const { getOptionSelected, getOptionLabel, handleOnChange, selectedOption } =
		useAutocompleteSelect({ name });

	return (
		<Autocomplete
			options={options}
			getOptionLabel={getOptionLabel}
			getOptionSelected={getOptionSelected}
			disableListWrap
			value={selectedOption}
			onChange={handleOnChange}
			renderInput={(params) => (
				<Field
					fullWidth
					component={TextFieldAdapter}
					label={t(label)}
					name={name}
					margin="dense"
					variant="outlined"
					disabled={submitting}
					required
					{...params}
				/>
			)}
		/>
	);
};

AutocompleteSelect.propTypes = {
	label: string,
	options: array,
	name: string,
};

export default AutocompleteSelect;
