import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import clsx from 'clsx';

// Import styles
import { useStyles } from './SingleScratch.styles';

// Import utils
import { useTranslations } from 'components/utilities';

export const SingleScratch = ({
	icon: Icon,
	isActive,
	onClick,
	color,
	type,
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Box className={classes.scratch} onClick={onClick}>
			<Icon
				style={{ color }}
				className={clsx({
					[classes.scratchIcon]: true,
					[classes.active]: isActive,
				})}
			/>
			<Box>
				<Typography
					className={clsx({
						[classes.scratchTitle]: true,
						[classes.active]: isActive,
					})}
				>
					{t(`contract.create_contract_form.car_scratches.${type}`)}
				</Typography>

				{isActive && (
					<Typography className={classes.activeText}>
						{t('contract.create_contract_form.car_scratches.active_text')}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

SingleScratch.propTypes = {
	isActive: bool,
	onClick: func,
	color: string,
	type: string,
	icon: func,
};
