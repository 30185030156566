import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { SUBSCRIPTION_PAYMENTS_QUERY } from 'helpers';

export const useSubscriptionPaymentsQuery = ({ uuid }) => {
	const path = `/v2/subscription/${uuid}/payments/list`;

	const { data, isLoading, isError, error, refetch } = useQuery(
		[SUBSCRIPTION_PAYMENTS_QUERY, uuid],
		async () => await services.post(path),
		{
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60,
			retry: 1,
			enabled: !!uuid,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isLoading, isError, error, refetch };
};
