import React from 'react';
import { Typography, Divider, Grid } from '@material-ui/core';
import { array } from 'prop-types';

// Import styles
import { useStyles } from './Table.styles';

// Import utils
import { useTranslations } from 'components/utilities';

export const Table = ({ data }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return data.map(({ label, fields }, index) => (
		<Grid container item spacing={2} key={label}>
			<Grid item xs={12}>
				{Object.entries(fields).map(([key, value]) => (
					<Grid container key={key} justifyContent="space-between">
						<Grid item>
							<Typography className={classes.label}>
								{t(`customers.driver_license_list.${label}.${key}`)}
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.value}>{value}</Typography>
						</Grid>
					</Grid>
				))}
			</Grid>
			{index !== data.length - 1 && (
				<Grid item xs={12}>
					<Divider className={classes.divider} />
				</Grid>
			)}
		</Grid>
	));
};

Table.propTypes = {
	data: array,
};
