import { useTranslations } from 'components/utilities';

const useBookingDataPreviewBookingDataPreview = ({ data }) => {
	const { t } = useTranslations();

	const {
		gratification: { point, discount, max_amount_nett_price: maxPrice },
		user: { full_name: fullName },
	} = data;

	const userLabel = `${t(
		'campaigns.experience_form.full_name_preview'
	)}: ${fullName}`;

	const pointsLabel = `${t('campaigns.form.gratification.points')}: ${point}`;

	const discountLabel = `${t(
		'campaigns.form.gratification.discount'
	)}: ${discount}`;

	const maxAmountLabel = `${t(
		'campaigns.form.gratification.max_amount'
	)}: ${maxPrice} ${t('currency.sar')}`;

	const previewFields = [userLabel, pointsLabel, discountLabel, maxAmountLabel];

	return { previewFields };
};

export default useBookingDataPreviewBookingDataPreview;
