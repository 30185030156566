import React from 'react';
import OtpInput from 'react-otp-input';
import {
	bool,
	func,
	number,
	object,
	oneOfType,
	string,
	node,
} from 'prop-types';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { inputStyles, focusStyles, containerStyles } from './OTPInput.styles';

export const OTPInput = ({
	value,
	onChange,
	numInputs = 6,
	shouldAutoFocus = true,
	outerInputStyles = {},
	outerContainerStyle = {},
	placeholder = [],
	renderSeparator,
}) => {
	const {
		i18n: { language: lang },
	} = useTranslations();
	return (
		<OtpInput
			shouldAutoFocus={shouldAutoFocus}
			onChange={onChange}
			inputStyle={{ ...inputStyles, ...outerInputStyles }}
			focusStyle={focusStyles}
			containerStyle={{ ...containerStyles(lang), ...outerContainerStyle }}
			value={value}
			numInputs={numInputs}
			placeholder={placeholder}
			renderSeparator={renderSeparator}
			renderInput={(props) => <input {...props} />}
		/>
	);
};

OTPInput.propTypes = {
	value: oneOfType([string, number]),
	onChange: func,
	numInputs: number,
	shouldAutoFocus: bool,
	outerInputStyles: oneOfType([object, bool]),
	outerContainerStyle: oneOfType([object, bool]),
	placeholder: string,
	renderSeparator: oneOfType([node, func]),
};
