import React from 'react';
import { connect } from 'react-redux';
import { object } from 'prop-types';

// Import components
import NotAuthorized from 'views/NotAuthorized';

const RoleAuthorization = (allowedRoles) => (WrappedComponent) => {
	class WithAuthorization extends React.Component {
		render() {
			// Get the auth roles data
			const {
				auth: {
					authenticate: {
						data: { role },
					},
				},
			} = this.props;

			// Check if user's role is included in allowedRole array
			const isAllowedAccess = role.some((role) => allowedRoles.includes(role));

			if (isAllowedAccess) {
				return <WrappedComponent {...this.props} />;
			} else {
				return <NotAuthorized />;
			}
		}
	}
	const mapStateToProps = ({ auth }) => {
		return { auth };
	};
	WithAuthorization.propTypes = {
		auth: object,
	};
	return connect(mapStateToProps)(WithAuthorization);
};

export default RoleAuthorization;
