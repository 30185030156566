import React from 'react';
import PropTypes from 'prop-types';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Button } from '@material-ui/core';

// Import components
import { ChipLabel, MediaLink } from 'components/elements';
import PreviewField from '../PreviewField';
import { ElmLicensePreviewModal } from './components';

// Import utilities
import useLicensePreviewField from './useLicensePreviewField';
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from '../styles';

const LicensePreviewField = ({ label }) => {
	const { isElmCustomer, isOpen, toggleModal, license } =
		useLicensePreviewField();

	const classes = useStyles();

	const { t } = useTranslations();

	if (!isElmCustomer && !license) return null;

	return (
		<PreviewField label={label}>
			{!isElmCustomer ? (
				<ChipLabel
					label={
						<>
							<AttachFileIcon className={classes.icon} />
							<MediaLink href={license} title={t('common.buttons.preview')} />
						</>
					}
				/>
			) : (
				<>
					<Button
						color="primary"
						onClick={toggleModal}
						className={classes.button}
						startIcon={<AttachFileIcon className={classes.icon} />}
					>
						{t('common.buttons.preview')}
					</Button>
					<ElmLicensePreviewModal isOpen={isOpen} toggle={toggleModal} />
				</>
			)}
		</PreviewField>
	);
};

LicensePreviewField.propTypes = {
	label: PropTypes.string,
};

export default LicensePreviewField;
