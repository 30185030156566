import { format } from 'date-fns';

// Import helpers
import { NONE_VALUE } from 'helpers';

export const convertSubmitData = ({ values }) => {
	const {
		period: { from, until },
		conditions: { allowed_previous_bookings_number: prevBookingNumber },
		budget: { max: maxBudget },
	} = values;

	const newFrom = typeof from !== 'string' ? formatDate(from) : from;
	const newUntil = typeof until !== 'string' ? formatDate(until) : until;

	const newPeriod = { from: newFrom, until: newUntil };

	const newConditions = {
		...values.conditions,
		allowed_previous_bookings_number:
			prevBookingNumber === NONE_VALUE ? null : prevBookingNumber,
	};

	const convertedData = {
		...values,
		period: newPeriod,
		conditions: newConditions,
		max_budget: maxBudget,
	};

	return convertedData;
};

const formatDate = (date) => format(date, 'dd/MM/yyyy');

export const convertFetchData = (data) => {
	const { conditions, code } = data.data || {};
	const { allowed_previous_bookings_number: prevBookingNumber } = conditions;

	const newConditions = {
		...conditions,
		allowed_previous_bookings_number:
			prevBookingNumber === null ? NONE_VALUE : prevBookingNumber,
	};

	const newData = {
		...data.data,
		conditions: newConditions,
		details: { code },
	};

	return { ...data, data: newData };
};
