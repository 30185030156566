import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { deleteSlider, updateActiveSlider } from 'store/actions';

// Import helpers
import { replaceEditSliderUrl, TABLE_ACTIONS_ACCESSORS } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import {
	EditTableCell,
	ActiveTableCell,
	DeleteTableCell,
	ImageTableCell,
} from 'components/elements';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, IMAGE } = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		switch (cell.column.id) {
			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditSliderUrl(cell.row.original.uuid)}
					/>
				);

			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveSlider}
						cell={cell}
					/>
				);

			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteSlider}
						cell={cell}
					/>
				);

			case IMAGE:
				return (
					<ImageTableCell
						cell={cell}
						key={cell.column.id}
						imgUrl={cell.row.original.media}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
