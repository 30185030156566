// Import helpers
import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	NAME: 'name',
};

export const columns = [
	{
		Header: 'faq.table.headers.title',
		accessor: COLUMNS_ACCESSORS.NAME,
		disableSortBy: true,
	},
	{
		Header: 'faq.table.headers.order',
		accessor: `order`,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
