import _ from 'lodash';

export const getFormData = (values, isEdit) => {
	const omittedValues = _.omit(values, ['logo_url', 'uuid', 'coordinators']);
	const convertedValues = Object.entries(omittedValues);

	let formData = new FormData();

	isEdit && formData.append('_method', 'PUT');
	convertedValues.forEach(([key, value]) => {
		value && formData.append(key, value);
	});

	return formData;
};
