import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const PLATE_NUMBER_FIELDS = (plate) => [
	{
		type: FIELD_TYPES.PLATE_NUMBER,
		name: 'plate_number',
		outerValue: plate || '',
	},
];

export const REMAINING_FIELDS = (options) => [
	{
		type: FIELD_TYPES.TEXT,
		name: 'registration_number',
		label: 'cars.car_list.plate_form.fields.car_registration_no',
		xs: 6,
		md: 6,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'body_color',
		label: 'cars.car_list.plate_form.fields.color',
		required: true,
		xs: 6,
		md: 6,
		options,
		keyExtractor: ([, value]) => value,
		labelExtractor: ([key]) =>
			i18next.t(`cars.car_list.plate_form.select_colors.${key}`),
	},
];
