import React from 'react';
import { useSelector } from 'react-redux';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

// Import components
import { MakeCompensationDialog } from 'components/elements';
import { CompensationHistoryDialog } from '../index';

export const CompensationCardActions = () => {
	const { compensations } = useSelector(previewBookingSelector);

	if (compensations) {
		return <CompensationHistoryDialog />;
	} else {
		return <MakeCompensationDialog />;
	}
};
