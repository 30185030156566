import React from 'react';
import PropTypes from 'prop-types';
import { useFormState, Field } from 'react-final-form';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';
import {
	CircularProgress,
	CardContent,
	CardHeader,
	MenuItem,
	Checkbox,
	Divider,
	Grid,
	Card,
	Box,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useAirportList } from 'queries';
import { getTerminals, findTerminalLabel } from './helpers';
import { EN } from 'helpers';

const Terminals = ({ section, ...rest }) => {
	const { submitting, values } = useFormState();
	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	const {
		airport_uuid,
		airport: { terminals },
	} = values;

	const {
		data: airports,
		isFetching: isAirportsLoading,
		isError: isAirportsError,
	} = useAirportList();

	const isAirportsLoaded = !isAirportsLoading && !isAirportsError;
	const allTerminals = getTerminals(airports, airport_uuid);

	return (
		<Card {...rest}>
			<CardHeader title={t('kiosk.management.form.terminals.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						{isAirportsLoaded ? (
							<Field
								fullWidth
								component={MultiselectFiledAdapter}
								type="select"
								label={t('kiosk.management.form.terminals.title')}
								name={`${section}.terminals`}
								margin="dense"
								variant="outlined"
								disabled={submitting}
								format={(value) => (Array.isArray(value) ? value : [])}
								labelextractor={findTerminalLabel(allTerminals, lng)}
							>
								{allTerminals.map(
									({ uuid, name: { en, ar } }) => (
										<MenuItem key={uuid} value={uuid}>
											<Checkbox
												color="primary"
												checked={terminals.includes(uuid)}
											/>
											{lng === EN ? en : ar}
										</MenuItem>
									)
								)}
							</Field>
						) : (
							<Box>
								<CircularProgress size={35} />
							</Box>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

Terminals.propTypes = {
	section: PropTypes.string.isRequired,
};

export default Terminals;
