import React from 'react';
import { createContext } from 'react';
import PropTypes from 'prop-types';

// Import utilities
import { useCarsLogHistory } from 'queries';

export const CarsLogHistoryContext = createContext();

const CarsLogHistoryProvider = ({ uuid, children }) => {
	const { data, isFetching, isError, error } = useCarsLogHistory({ uuid });

	return (
		<CarsLogHistoryContext.Provider
			value={{
				data,
				isFetching,
				isError,
				error,
			}}
		>
			{children}
		</CarsLogHistoryContext.Provider>
	);
};

CarsLogHistoryProvider.propTypes = {
	uuid: PropTypes.string,
	children: PropTypes.node,
};

export default CarsLogHistoryProvider;
