import React from 'react';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import components
import { PartnersContainer } from './components';

// Import translations
import { useTranslations } from 'components/utilities';

export const WebsitePartners = () => {
	const { t } = useTranslations();

	return (
		<Card>
			<CardHeader title={t('cms.partners.form.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3} alignItems="flex-end">
					<PartnersContainer />
				</Grid>
			</CardContent>
		</Card>
	);
};
