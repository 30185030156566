import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import utilities
import { deletePhoneNumber } from 'store/actions';

// Import components
import { DeleteTableCell } from 'components/elements';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { TABLE_ACTIONS_ACCESSORS } from 'helpers';

// Import styles
import { useStyles } from './styles';

const { PHONE } = COLUMNS_ACCESSORS;
const { DELETE } = TABLE_ACTIONS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const classes = useStyles();

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case PHONE:
				return (
					<TableCell key={cell.column.id}>{cell.row.original.phone}</TableCell>
				);

			case DELETE:
				return (
					<DeleteTableCell
						deleteAction={deletePhoneNumber}
						key={cell.column.id}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell
						className={classes.cell}
						key={cell.column.id}
						{...cell.getCellProps()}
					>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
