import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

export const getAddCustomerFormData = (values) => {
	const {
		license_expire_date,
		license_number,
		birth_date,
		license,
		phone,
		email,
		name,
	} = values;

	const formData = new FormData();

	formData.append('license_number', license_number);
	formData.append('license', license);
	formData.append('phone', phone);
	formData.append('email', email);
	formData.append('name', name);
	formData.append(
		'birth_date',
		format(new Date(birth_date), DEFAULT_DATE_FORMAT)
	);
	formData.append(
		'license_expire_date',
		format(new Date(license_expire_date), DEFAULT_DATE_FORMAT)
	);

	return formData;
};

export const getUpdateCustomerFormData = (values) => {
	const {
		license_expire_date,
		license_number,
		birth_date,
		license,
		prefix,
		phone,
		email,
		name,
	} = values;

	const newPhone = `${prefix}${phone}`;
	const formData = new FormData();

	formData.append('license_number', license_number);
	license && formData.append('license', license);
	formData.append('phone', newPhone);
	formData.append('email', email);
	formData.append('name', name);
	formData.append(
		'birth_date',
		format(new Date(birth_date), DEFAULT_DATE_FORMAT)
	);
	formData.append(
		'license_expire_date',
		format(new Date(license_expire_date), DEFAULT_DATE_FORMAT)
	);

	formData.append('_method', 'PUT');

	return formData;
};

export const convertConfirmationStepValues = (values) => ({
	...values,
	phone: `${values.prefixes}${values.phone}`,
});

export const convertCustomerLimitValues = ({ values, userUuid }) => ({
	...values,
	user_uuid: userUuid,
	valid_until: format(values.valid_until, DEFAULT_DATE_FORMAT),
});
