import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, IconButton } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';

// Import components
import { NotificationAlert } from 'components/elements';
import useFulfilledTableCell from './useFulfilledTableCell';

const FulfilledTableCell = ({ cell }) => {
	const { isFulfilled, handleToggle, isError, error } = useFulfilledTableCell(
		cell.row.original
	);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<NotificationAlert open={isError} message={error} />
			<IconButton onClick={handleToggle}>
				{isFulfilled ? <FlagIcon /> : <FlagOutlinedIcon />}
			</IconButton>
		</TableCell>
	);
};

FulfilledTableCell.propTypes = {
	cell: PropTypes.object.isRequired,
};

export default FulfilledTableCell;
