import { makeStyles } from '@material-ui/styles';

export const pageMargins = `@page { margin: 5mm !important; }`;

export const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.white,
		gap: '50px',
	},
	content: {
		padding: '0 20px',
		gap: 20,
	},
}));
