export const COLUMNS_ACCESSORS = {
	DESCRIPTION: 'description',
	DATE: 'date',
};

export const columns = [
	{
		header:
			'bookings.preview_booking.booking_info.urgent_reasons_history.table.headers.description',
		accessor: COLUMNS_ACCESSORS.DESCRIPTION,
	},
	{
		header:
			'bookings.preview_booking.booking_info.urgent_reasons_history.table.headers.date',
		accessor: COLUMNS_ACCESSORS.DATE,
	},
];
