import React from 'react';
import { Box, CircularProgress, Grid, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';
import { func, string } from 'prop-types';

// Import utilities
import { useDialogForm } from '../DialogForm/useDialogForm';
import { useTranslations } from 'components/utilities';

// Import components
import { Alert, ButtonProgress, SelectFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from './Fields.styles';

export const Fields = ({ toggle, deliveryType, externalBookingUuid }) => {
	const { t } = useTranslations();

	const { handleOnSelectChange, drivers, isLoading, isFetching, isError } =
		useDialogForm({ toggle, deliveryType, externalBookingUuid });

	const classes = useStyles();

	return (
		<>
			<Box>
				{isFetching ? (
					<Box>
						<CircularProgress size={24} />
					</Box>
				) : isError ? (
					<Alert message={t('common.messages.error_message')} />
				) : (
					<Box key={deliveryType}>
						<Field
							label={t(`bookings.preview_booking.car.${deliveryType}`)}
							variant="outlined"
							margin="dense"
							onChange={handleOnSelectChange}
							component={SelectFieldAdapter}
							disabled={isLoading}
							name={deliveryType}
							fullWidth
							required
						>
							{drivers.map(({ uuid, name, busy_at: busyAt }) => (
								<MenuItem key={uuid} value={uuid}>
									<Grid
										container
										alignItems="center"
										justifyContent="space-between"
									>
										<Grid item>{name}</Grid>
										{!busyAt ? (
											<Grid item className={classes.available}>
												{t('bookings.preview_booking.car.available')}
											</Grid>
										) : (
											<Grid item className={classes.errorMessage}>{`${t(
												'bookings.preview_booking.car.has_delivery_at'
											)} ${busyAt}`}</Grid>
										)}
									</Grid>
								</MenuItem>
							))}
						</Field>
					</Box>
				)}
			</Box>
			<Box className={classes.buttonWrapper}>
				<ButtonProgress
					color="primary"
					type="submit"
					isLoading={isLoading}
					disabled={isLoading}
					circularProgressSize={18}
					variant="contained"
				>
					{t('common.buttons.save')}
				</ButtonProgress>
			</Box>
		</>
	);
};

Fields.propTypes = {
	toggle: func.isRequired,
	deliveryType: string,
	externalBookingUuid: string,
};
