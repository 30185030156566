import { PLATE_RENT_STATUS, SUSPENSION_CODES } from 'helpers';
import services from 'services/services';

// Import helpers
import { suspendContractUrl, updatePlateUrl } from 'store/paths';
import { convertSuspendContractSubmitValues } from './helpers';

export const suspendContract = async ({
	callbackSuccess,
	errorCallback,
	contractNumber,
	bookingUuid,
	plateUuid,
	values,
}) => {
	try {
		const convertedValues = convertSuspendContractSubmitValues(values);
		const shouldUpdatePlate =
			convertedValues.suspension_code === SUSPENSION_CODES.FINANCIAL_CLAIM;

		await services.put(suspendContractUrl(contractNumber), convertedValues);

		if (shouldUpdatePlate) {
			await services.put(updatePlateUrl(plateUuid), {
				rent_status: PLATE_RENT_STATUS.AVAILABLE,
				miles_usage: convertedValues.rent_status.odometer_reading,
				booking_uuid: bookingUuid,
			});
		}

		callbackSuccess();
	} catch (error) {
		if (error.response) {
			const { errors, message } = error.response.data || {};
			errorCallback(errors || message);
		}
	}
};
