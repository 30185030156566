import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		padding: theme.spacing(4),
		gap: '32px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		gap: '16px',
		padding: '32px',
	},
	resendOtpWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
	},
	subtitle: {
		color: theme.palette.grey[700],
	},
}));
