import React from 'react';
import { string } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import utils
import { useUploadFileButton } from './useUploadFileButton';
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress } from 'components/elements';
import { ErrorsDialog } from './components';

export const UploadFileButton = ({ uploadUrl }) => {
	const {
		handleOnInputChange,
		handleOnButtonClick,
		hiddenFileInput,
		isLoading,
		errors,
	} = useUploadFileButton({ uploadUrl });

	const { t } = useTranslations();

	return (
		<Grid container spacing={2}>
			<Grid item xs="auto">
				<ButtonProgress
					onClick={handleOnButtonClick}
					isLoading={isLoading}
					disabled={isLoading}
					variant="outlined"
					color="primary"
				>
					{t('common.buttons.upload')}
				</ButtonProgress>
				<input
					onChange={handleOnInputChange}
					style={{ display: 'none' }}
					ref={hiddenFileInput}
					type="file"
				/>
			</Grid>
			{!!errors.length && (
				<Grid item xs="auto">
					<ErrorsDialog errors={errors} />
				</Grid>
			)}
		</Grid>
	);
};

UploadFileButton.propTypes = {
	uploadUrl: string.isRequired,
};
