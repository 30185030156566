// Import components
import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TextFieldAdapter, SelectFieldAdapter } from 'components/elements';

const prefixesSorter = (a, b) => {
	const prefix1 = +a.substring(1);
	const prefix2 = +b.substring(1);

	return prefix1 - prefix2;
};

export const fields = (section, prefixes, companyList, showCompanySelect) => {
	const result = [
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'offices.form.details.company_name',
			name: `${section}.name.ar`,
			md: 4,
			xs: 12
		},
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'offices.form.details.english_name',
			name: `${section}.name.en`,
			md: 4,
			xs: 12
		},
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'offices.form.details.contact_person_name',
			name: `${section}.person_name`,
			md: 4,
			xs: 12
		},
		{
			component: SelectFieldAdapter,
			type: 'select',
			label: 'offices.form.details.number_prefix',
			name: `${section}.number_prefix`,
			md: 6,
			xs: 6,
			children:
				Object.values(prefixes).sort(prefixesSorter).map((prefix) => (
					<MenuItem key={prefix} value={prefix}>
						{prefix}
					</MenuItem>
				))
		},
		{
			component: TextFieldAdapter,
			type: 'number',
			label: 'offices.form.details.contact_number',
			name: `${section}.contact_number`,
			md: 6,
			xs: 6
		},
		{
			component: SelectFieldAdapter,
			type: 'select',
			label: 'offices.form.details.public_number_prefix',
			name: `${section}.public_number_prefix`,
			md: 6,
			xs: 6,
			children:
				Object.values(prefixes).sort(prefixesSorter).map((prefix) => (
					<MenuItem key={prefix} value={prefix}>
						{prefix}
					</MenuItem>
				))
		},
		{
			component: TextFieldAdapter,
			type: 'number',
			label: 'offices.form.details.public_contact_number',
			name: `${section}.public_contact_number`,
			md: 6,
			xs: 6
		},
		{
			component: TextFieldAdapter,
			type: 'text',
			label: 'offices.form.details.slug',
			name: `${section}.slug`,
			md: 6,
			xs: 6
		}
	];

	if (showCompanySelect) {
		result.push(
			{
				component: SelectFieldAdapter,
				type: 'select',
				label: 'offices.form.details.assign_to_company',
				name: `company.uuid`,
				md: 6,
				xs: 6,
				children:
					companyList.map((company) => (
						<MenuItem key={company.uuid} value={company.uuid}>
							{company.name.en}
						</MenuItem>
					))
			}
		);
	}

	return result;
};
