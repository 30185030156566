import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `2px solid ${theme.palette.background.default}`,
		padding: '0 20px',
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '30px',
	},
	columnWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	divider: {
		height: '20px',
		backgroundColor: theme.palette.background[1],
		colorAdjust: 'exact',
	},
	documentLabel: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		colorAdjust: 'exact',
		borderRadius: '40px',
		overflow: 'hidden',
		gap: 12,
		color: theme.palette.white,
	},
	iconWrapper: {
		display: 'grid',
		placeItems: 'center',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.dark,
		colorAdjust: 'exact',
	},
	textWrapper: {
		marginRight: 20,
	},
	text: {
		color: theme.palette.white,
	},
	heading: {
		fontWeight: 'bold',
	},
}));
