import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@material-ui/core';

//Import styles
import useStyles from './styles';

const CarTitle = ({ carName }) => {
	const classes = useStyles();

	return (
		<Typography
			className={classes.root}
			variant="h6"
			color="primary"
			align="center"
		>
			{carName}
		</Typography>
	);
};

CarTitle.propTypes = {
	carName: string.isRequired,
};

export default CarTitle;
