import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import  store
import { previewBookingSelector } from 'store/selectors';

// Import components
import PreviewField from './PreviewField';

export const DropDateAfterEarlyReturnPreviewField = () => {
	const booking = useSelector(previewBookingSelector);

	const { period, early_return_details } = booking || {};

	if (!early_return_details || _.isEmpty(early_return_details)) return null;

	return (
		<PreviewField label="bookings.preview_booking.period.drop_off_date_after_early_return">
			{period.drop_date}
		</PreviewField>
	);
};
