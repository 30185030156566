import React from 'react';
import { CardContent } from '@material-ui/core';

// Import components
import Card from '../Card';
import CardHeader from '../CardHeader';
import { Content } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';

export const BookingsStatsBySource = () => {
	const { t } = useTranslations();

	return (
		<Card>
			<CardHeader
				title={t('dashboard.bookings_stats.title')}
				titleTypographyProps={{
					variant: 'h4',
					align: 'center',
				}}
			/>
			<CardContent>
				<Content />
			</CardContent>
		</Card>
	);
};
