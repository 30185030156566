import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const PickupCarIcon = (props) => (
	<SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
		<path
			d="M10.5513 14.6504H9.13867"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.2397 14.6504H14.8271"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.9463 12.5315L18.3582 11.8252"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.4331 12.5315L7.02051 11.8252"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.3912 9.00049C9.73483 9.00056 9.1649 9.45252 9.01529 10.0916L8.41504 12.6527L9.01529 10.0916C9.1649 9.45252 9.73483 9.00056 10.3912 9.00049H14.9871C15.6434 9.00037 16.2132 9.45249 16.3624 10.0916L16.9626 12.6527"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.3586 15.0033V14.4764C18.3586 13.4339 17.5143 12.5884 16.4718 12.5869H8.91063C8.4094 12.5867 7.92864 12.7857 7.57415 13.1401C7.21967 13.4944 7.02051 13.9751 7.02051 14.4764V17.671C7.02051 18.0394 7.31911 18.338 7.68745 18.338H9.17874C9.54708 18.338 9.84568 18.0394 9.84568 17.671V16.7687H15"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.4941 16.9932H22.4908"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.4941 14.9961L22.5065 17.0001L20.4941 19.0041"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
