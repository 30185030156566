import React from 'react';
import { array } from 'prop-types';
import {
	CardContent,
	CardHeader,
	Divider,
	Card,
	Grid,
} from '@material-ui/core';

// Import utils
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { FIELDS } from './helpers';

export const CarPlateDetails = ({ rentStatuses }) => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	return (
		<Card>
			<CardHeader title={t('cars.car_list.plate_form.car_plate_details')} />
			<Divider />
			<CardContent>
				<Grid container spacing={1}>
					{FIELDS(rentStatuses).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

CarPlateDetails.propTypes = {
	rentStatuses: array,
};
