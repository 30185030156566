import React, { useEffect, useState } from 'react';
import { Button, Grid, InputLabel } from '@material-ui/core';
import { bool, func, object, string } from 'prop-types';
import { useForm } from 'react-final-form';

// Import utils and helpers
import { useMediaQuerySizes, useTranslations } from 'components/utilities';
import { arabicToEnglishNumber } from './helpers';

// Import components
import { InputError, OTPInput } from 'components/elements';

// Import styles
import { useStyles, otpInputStyle } from './CarPlateNumberFieldAdapter.styles';

export const CarPlateNumberFieldAdapter = ({
	shouldAutoFocus,
	showSearchPlate,
	toggleSearchPlateDialog,
	outerValue,
	meta,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { isTabletSize } = useMediaQuerySizes();
	const [value, setValue] = useState(outerValue);

	const { change } = useForm();

	const numInputs = 7;

	useEffect(() => {
		outerValue && setValue(outerValue);
	}, [outerValue]);

	useEffect(() => {
		value?.length === numInputs &&
			change('plate', arabicToEnglishNumber(value));
	}, [value, change]);

	return (
		<Grid container spacing={1} alignItems="center">
			<InputLabel className={classes.label}>
				{t('cars.car_list.plate_form.fields.plate_no')}
			</InputLabel>

			<OTPInput
				shouldAutoFocus={shouldAutoFocus}
				numInputs={numInputs}
				onChange={setValue}
				value={value}
				outerInputStyles={isTabletSize && otpInputStyle}
				placeholder="أبت1234"
				renderSeparator={(index) => index === 2 && <span>-</span>}
			/>
			{showSearchPlate && (
				<Button
					onClick={toggleSearchPlateDialog}
					color="primary"
					variant="outlined"
					className={classes.button}
				>
					{t('common.buttons.search')}
				</Button>
			)}
			<InputError meta={meta} />
		</Grid>
	);
};

CarPlateNumberFieldAdapter.propTypes = {
	shouldAutoFocus: bool,
	showSearchPlate: bool,
	toggleSearchPlateDialog: func,
	outerValue: string,
	meta: object.isRequired,
};

CarPlateNumberFieldAdapter.defaultProps = {
	shouldAutoFocus: false,
	showSearchPlate: false,
	toggleSearchPlateDialog: () => {},
	outerValue: '',
};
