import BusinessIcon from '@material-ui/icons/Business';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: '/kiosk/airports/create',
		icon: BusinessIcon,
		text: 'kiosk.airports.table.toolbar.add_airport',
	},
];
