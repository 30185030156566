import React from 'react';
import { bool, func } from 'prop-types';
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Box,
	Divider,
	CircularProgress,
} from '@material-ui/core';

// Import components
import { useTableExportContextProvider } from 'components/context';
import { Alert } from 'components/elements';
import {
	TableExportColumns,
	TableExportProgress,
	TableExportHeader,
} from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import useTableExport from './useTableExport';

const TableExport = ({ renderFilters, hideColumnsSelect, customExport }) => {
	const { open, hideExport, isLoading, error, columnsLoading } =
		useTableExportContextProvider();

	const { handleExport } = useTableExport({ customExport });

	const { t } = useTranslations();

	return (
		<Dialog open={open} onClose={hideExport} fullWidth maxWidth="xl">
			<DialogContent>
				<Box py={2}>
					{!hideColumnsSelect && (
						<>
							<TableExportHeader />
							{columnsLoading ? <CircularProgress /> : <TableExportColumns />}
						</>
					)}
				</Box>
				<Box py={2}>
					<Typography variant="h5" gutterBottom>
						{t('table_export.apply_filters')}
					</Typography>
					{renderFilters()}
				</Box>

				{isLoading && (
					<>
						<Box py={2}>
							<TableExportProgress />
						</Box>
					</>
				)}

				{error && (
					<>
						<Divider />
						<Box py={2}>
							<Alert message={error} />
						</Box>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={hideExport}>
					{t('table_export.cancel')}
				</Button>
				<Button
					variant="contained"
					color="primary"
					disabled={isLoading}
					onClick={handleExport}
				>
					{t('table_export.export')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

TableExport.propTypes = {
	renderFilters: func,
	hideColumnsSelect: bool,
	customExport: func,
};

TableExport.defaultProps = {
	hideColumnsSelect: false,
	customExport: null,
};

export default TableExport;
