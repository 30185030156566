import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	CompensationStatusFilter,
	CompanyFilter,
	DateRangeFilter,
} from 'components/elements';

const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<CompanyFilter
				name="company_uuid"
				label="waiting_customer_info.filters.company_name"
			/>
			<CompensationStatusFilter />
			<DateRangeFilter
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="drop_date"
				nameUntil="drop_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
			/>
		</Grid>
	);
};

export default FilterFields;
