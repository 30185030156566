import React from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';

// Import utils and helpers
import { useAddCorporateCustomerDialogProvider } from './context/useAddCorporateCustomerDialogProvider';
import { useTranslations } from 'components/utilities';
import { getDialogSteps } from './helpers';

// Import components
import { ClosableDialogTitle } from 'components/elements';

export const AddCorporateCustomerDialog = () => {
	const { t } = useTranslations();

	const { isDialogOpen, toggleDialog, step } =
		useAddCorporateCustomerDialogProvider();

	return (
		<>
			<Dialog onClose={toggleDialog} open={isDialogOpen} fullWidth>
				<ClosableDialogTitle
					onClose={toggleDialog}
					title={t('b2b.corporate.overview.table.toolbar.add_customer')}
				/>
				<DialogContent>{getDialogSteps(step)}</DialogContent>
			</Dialog>
			<Button variant="contained" color="primary" onClick={toggleDialog}>
				{t('b2b.corporate.overview.table.toolbar.add_customer')}
			</Button>
		</>
	);
};
