// Import helpers
import { convertDate, DEFAULT_DATE_FORMAT } from 'helpers';

export const convertOptions = (booking) => {
	const currentOptions = booking.options || [];

	const availableOptions = booking.available_options || [];

	const getOption = (type) => {
		return currentOptions.find((option) => option.type === type);
	};

	const getAvailableOption = (type) => {
		return availableOptions.find((option) => option.type === type);
	};

	const hasOption = (type) => {
		return !!getAvailableOption(type) && !!getOption(type);
	};

	const options = {
		insurance: hasOption('insurance'),
		unlimitedKm: hasOption('unlimitedKm'),
		driver: hasOption('driver'),
		delivery: hasOption('delivery'),
		delivery_drop: hasOption('delivery_drop'),
		auth_out_ksa: hasOption('auth_out_ksa'),
		extra_driver: hasOption('extra_driver'),
		no_smoking_car: hasOption('no_smoking_car'),
		child_seat: hasOption('child_seat'),
		tam: hasOption('tam'),
		delivery_address: booking.delivery.address,
		delivery_lat: booking.delivery.lat
			? booking.delivery.lat
			: booking.supplier.location.lat,
		delivery_lng: booking.delivery.lng
			? booking.delivery.lng
			: booking.supplier.location.lng,
		drop_city: hasOption('dropCity'),
		drop_city_uuid: getOption('dropCity')?.id || '',
	};

	return options;
};

export const convertValues = (values) => {
	const { options, period, discount } = values || {};

	const newItem = { uuid: values.item.uuid };

	const newOptions = {
		...options,
		dropCity: options.drop_city_uuid,
		delivery_location: {
			delivery_lat: options.delivery_lat,
			delivery_lng: options.delivery_lng,
		},
		promo_code: discount.used_discount,
	};

	const newPeriod = {
		drop_date: convertDate(period.drop_date, DEFAULT_DATE_FORMAT),
		pick_date: convertDate(period.pick_date, DEFAULT_DATE_FORMAT),
		pick_time: period.pick_time,
	};

	return {
		item: newItem,
		period: newPeriod,
		options: newOptions,
	};
};

export const reassignItem = (item) => {
	item.data.selected_options = item?.data?.options || [];
	item.data.options = convertOptions(item.data);
};
