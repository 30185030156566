import React from 'react';
import { useFormState } from 'react-final-form';
import { Button } from '@material-ui/core';
import { func } from 'prop-types';

// Import translations
import { useTranslations } from 'components/utilities';

const ActionButtons = ({ toggle }) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<Button
			color="primary"
			variant="outlined"
			onClick={toggle}
			disabled={submitting}
		>
			{t('common.buttons.close')}
		</Button>
	);
};

ActionButtons.propTypes = {
	toggle: func,
};

export default ActionButtons;
