import services from 'services/services';
import * as types from 'store/types';
import {
	fetchCarListFormResourcesUrl,
	submitCarListFormUrl,
} from 'store/paths';

// Import helpers
import { catchError } from 'store/actions/helpers';
import { convertFetchedData, convertSubmitData } from './helpers.js';

export const fetchCarListFormResources = ({
	itemId,
	isEdit,
	cancelToken,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.CAR_LIST_FORM_FETCH_DATA_LOADING,
			payload: true,
		});

		const { data } =
			isEdit &&
			(await services.get(fetchCarListFormResourcesUrl(itemId), cancelToken));

		if (data) data.data.types = convertFetchedData(data.data);

		dispatch({
			type: types.CAR_LIST_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: data || {},
			},
		});
	} catch (error) {
		catchError({ error, dispatch, type: types.CAR_LIST_FORM_FETCH_DATA_ERROR });
	}
};

export const submitCarListForm = ({
	values,
	itemId,
	isEdit,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		const convertedData = convertSubmitData(values);
		await services.post(submitCarListFormUrl(isEdit, itemId), convertedData);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
