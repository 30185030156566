import React from 'react';
import { Grid } from '@material-ui/core';

// Import helpers
import { PERMISSION_COMPANY_FILTER } from '../helpers';

// Import components
import {
	AvailableCarsNumberFilter,
	CompanyOfficesFilter,
	AllCarModelsFilter,
	CarTypeFilter,
	CarYearFilter,
	CompanyFilter,
	ServiceFilter,
	CityFilter,
	BaseYesNoAllFilter,
} from 'components/elements';
import RoleProtected from 'components/hoc/RoleProtected';

export const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<RoleProtected roles={PERMISSION_COMPANY_FILTER}>
				<CompanyFilter
					name="company_uuid"
					label="bookings.filters.company"
					isActive
				/>
			</RoleProtected>
			<CompanyOfficesFilter
				name="office_uuid"
				label="bookings.filters.office"
			/>
			<CityFilter name="city_uuid" label="bookings.filters.city" />
			<ServiceFilter name="services" />
			<AvailableCarsNumberFilter name="car_availability_range" />
			<BaseYesNoAllFilter
				label="cars_availability.filters.active"
				name="car_active"
			/>
			<AllCarModelsFilter
				name="model_uuid"
				label="cars_availability.filters.car_model"
			/>
			<CarTypeFilter name="car_type" />
			<CarYearFilter name="year" />
		</Grid>
	);
};
