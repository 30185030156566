// Import utils
import { useSuspendContractProvider } from 'views/Bookings/SuspendContract/context';

// Import components
import { CommonSection } from 'views/Bookings/InitiateContract/components/DataForm/components';
import { CarScratches } from 'components/elements';

// Import helpers
import {
	SUSPENSION_DETAILS_FIELDS,
	PAYMENT_DETAILS_FIELDS,
	RENT_STATUS_FIELDS,
} from './helpers';

// Import styles
import { useStyles } from 'theme/styles/eContract.styles';

export const useSections = () => {
	const { formOptions, branches } = useSuspendContractProvider();
	const classes = useStyles();

	const {
		narrowed_usable_conditions: narrowedUsableConditions,
		availability_states: availabilityStates,
		fuel_availabilities: fuelAvailabilities,
		working_conditions: workingConditions,
		usable_conditions: usableConditions,
		suspension_codes: suspensionCodes,
		payment_methods: paymentMethods,
		purity_states: purityStates,
	} = formOptions || {};

	const sections = [
		{
			section: 'suspension_details',
			children: CommonSection,
			fields: SUSPENSION_DETAILS_FIELDS({ branches, suspensionCodes }),
		},
		{
			section: 'rent_status',
			children: CommonSection,
			fields: RENT_STATUS_FIELDS({
				narrowedUsableConditions,
				availabilityStates,
				fuelAvailabilities,
				workingConditions,
				usableConditions,
				purityStates,
				classes,
			}),
		},
		{
			section: 'car_scratches',
			children: CarScratches,
		},
		{
			section: 'payment_details',
			children: CommonSection,
			fields: PAYMENT_DETAILS_FIELDS({ paymentMethods }),
		},
	];

	return { sections };
};
