import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

// Import utils and helpers
import { ADD_CORPORATE_AND_COORDINATOR_PANEL_STEPS } from 'helpers';

export const AddCorporateAndCoordinatorContext = createContext(null);

export const AddCorporateAndCoordinatorProvider = ({ children }) => {
	const [corporateUuid, setCorporateUuid] = useState(null);
	const [step, setStep] = useState(
		ADD_CORPORATE_AND_COORDINATOR_PANEL_STEPS.ADD_CORPORATE
	);

	return (
		<AddCorporateAndCoordinatorContext.Provider
			value={{
				setCorporateUuid,
				corporateUuid,
				setStep,
				step,
			}}
		>
			{children}
		</AddCorporateAndCoordinatorContext.Provider>
	);
};

AddCorporateAndCoordinatorProvider.propTypes = {
	children: node,
};
