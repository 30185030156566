import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	star: {
		color: theme.palette.starIcon,
		fontSize: '1.5em',
	},
	box: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		gap: '8px',
	},
}));
