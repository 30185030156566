import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	tableContainer: {
		marginBottom: theme.spacing(5),
	},
	notExists: {
		backgroundColor: theme.palette.error.light,
	},
}));
