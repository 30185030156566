// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { BOOKING_EXTENSION_STATUS, numberWithCommas } from 'helpers';
import { isNegativeDifference, convertDifference, getAmount } from './helpers';

const useNoteTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const {
		admin_edit_booking_gross_price_diff: adminPriceDifference,
		customer_edit_booking_gross_price_diff: customerPriceDifference,
		customer_edited_booking: isEditedByCustomer,
		admin_edited_booking: isEditedByAdmin,
		corporate_name: corporateName,
		customer_verify_type,
		customer_type,
		is_subscription,
		is_external,
		is_discount,
		is_delivery,
		payment,
		delivery,
		source,
		extension,
	} = cell.row.original;

	const {
		extension_amount_gross: extendedPrice,
		extension_state: extendedStatus,
	} = extension || {};

	const customerPriceDiff = +customerPriceDifference;
	const adminPriceDiff = +adminPriceDifference;

	const convertedAdminPriceDiff = convertDifference(adminPriceDiff);
	const showPriceDifference = !!adminPriceDiff && isEditedByAdmin;
	const adminPriceDifferenceTooltip = t(
		`bookings.table.notes.${
			isNegativeDifference(adminPriceDiff) ? 'negative' : 'positive'
		}_price_difference`,
		{ amount: numberWithCommas(getAmount(adminPriceDiff)) }
	);

	const convertedCustomerPriceDiff = convertDifference(customerPriceDiff);
	const showChangedLabel = isEditedByCustomer;
	const isChangedPriceDifference =
		!isNaN(getAmount(customerPriceDiff)) && getAmount(customerPriceDiff) !== 0;
	const changedLabel = `${t('bookings.table.notes.changed')} ${
		isChangedPriceDifference ? convertedCustomerPriceDiff : ''
	}`;

	const showExtendedLabel =
		extendedStatus === BOOKING_EXTENSION_STATUS.ACCEPTED ||
		extendedStatus === BOOKING_EXTENSION_STATUS.PENDING;
	const isExtendedPrice = extendedPrice && extendedPrice !== 0;
	const extendedLabel = `${t('bookings.table.notes.extended')} ${
		isExtendedPrice ? `+${extendedPrice}` : ''
	}`;

	const pickupDelivery = delivery?.pick_up_delivery;
	const dropDelivery = delivery?.drop_up_delivery;

	const isDeliveryLateAssignment =
		pickupDelivery?.has_late_assigment || dropDelivery?.has_late_assigment;

	const isDeliveryLate =
		pickupDelivery?.has_late_delivery || dropDelivery?.has_late_delivery;

	const isDriverAssigned =
		pickupDelivery?.is_available || dropDelivery?.is_available;

	const isPickupDelivery = pickupDelivery?.has_driver_assigned;
	const isDropDelivery = dropDelivery?.has_driver_assigned;

	return {
		adminPriceDifference: convertedAdminPriceDiff,
		adminPriceDifferenceTooltip,
		isNegativeDifference,
		customer_verify_type,
		showPriceDifference,
		showExtendedLabel,
		showChangedLabel,
		customer_type,
		extendedLabel,
		changedLabel,
		isDeliveryLateAssignment,
		isDeliveryLate,
		isPickupDelivery,
		isDropDelivery,
		isDriverAssigned,
		is_subscription,
		is_external,
		is_discount,
		is_delivery,
		delivery,
		source,
		payment,
		corporateName,
	};
};

export default useNoteTableCell;
