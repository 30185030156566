import { BOOKING_COMPENSATION_STATES, convertFormDates } from 'helpers';

export const initialValues = {
	compensation_status: BOOKING_COMPENSATION_STATES.REVIEW,
	company_uuid: 'all',
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
};

export const formatValues = (values) => {
	return {
		...values,
		company_uuid: values.company_uuid,
		compensation_status: values.compensation_status,
		...convertFormDates(values, ['pick_date', 'drop_date']),
	};
};
