import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ExcelIcon = (props) => (
	<SvgIcon viewBox="0 0 24 24" {...props}>
		<path
			d="M13.1057 2.02996C12.9518 1.90306 12.7479 1.85041 12.5508 1.88686L1.75081 3.91186C1.43086 3.97126 1.20001 4.24936 1.20001 4.57471V19.4247C1.20001 19.7487 1.43086 20.0282 1.75081 20.0876L12.5508 22.1126C12.5913 22.1207 12.6345 22.1247 12.675 22.1247C12.8303 22.1247 12.9842 22.0707 13.1057 21.9695C13.2609 21.8412 13.35 21.6495 13.35 21.4497V2.54971C13.35 2.34856 13.2609 2.15821 13.1057 2.02996ZM12 20.6357L2.55001 18.8645V5.13496L12 3.36376V20.6357Z"
			fill="currentColor"
		/>
		<path
			d="M22.125 4.57446H12.675C12.3024 4.57446 12 4.87686 12 5.24946C12 5.62206 12.3024 5.92446 12.675 5.92446H21.45V18.0745H12.675C12.3024 18.0745 12 18.3769 12 18.7495C12 19.1221 12.3024 19.4245 12.675 19.4245H22.125C22.4976 19.4245 22.8 19.1221 22.8 18.7495V5.24946C22.8 4.87686 22.4976 4.57446 22.125 4.57446Z"
			fill="currentColor"
		/>
		<path
			d="M15.375 7.27448H12.675C12.3024 7.27448 12 7.57688 12 7.94948C12 8.32208 12.3024 8.62448 12.675 8.62448H15.375C15.7476 8.62448 16.05 8.32208 16.05 7.94948C16.05 7.57688 15.7476 7.27448 15.375 7.27448Z"
			fill="currentColor"
		/>
		<path
			d="M15.375 9.97449H12.675C12.3024 9.97449 12 10.2769 12 10.6495C12 11.0221 12.3024 11.3245 12.675 11.3245H15.375C15.7476 11.3245 16.05 11.0221 16.05 10.6495C16.05 10.2769 15.7476 9.97449 15.375 9.97449Z"
			fill="currentColor"
		/>
		<path
			d="M15.375 12.6745H12.675C12.3024 12.6745 12 12.9769 12 13.3495C12 13.7221 12.3024 14.0245 12.675 14.0245H15.375C15.7476 14.0245 16.05 13.7221 16.05 13.3495C16.05 12.9769 15.7476 12.6745 15.375 12.6745Z"
			fill="currentColor"
		/>
		<path
			d="M15.375 15.3745H12.675C12.3024 15.3745 12 15.6769 12 16.0495C12 16.4221 12.3024 16.7245 12.675 16.7245H15.375C15.7476 16.7245 16.05 16.4221 16.05 16.0495C16.05 15.6769 15.7476 15.3745 15.375 15.3745Z"
			fill="currentColor"
		/>
		<path
			d="M19.425 7.27448H18.075C17.7024 7.27448 17.4 7.57688 17.4 7.94948C17.4 8.32208 17.7024 8.62448 18.075 8.62448H19.425C19.7976 8.62448 20.1 8.32208 20.1 7.94948C20.1 7.57688 19.7976 7.27448 19.425 7.27448Z"
			fill="currentColor"
		/>
		<path
			d="M19.425 9.97449H18.075C17.7024 9.97449 17.4 10.2769 17.4 10.6495C17.4 11.0221 17.7024 11.3245 18.075 11.3245H19.425C19.7976 11.3245 20.1 11.0221 20.1 10.6495C20.1 10.2769 19.7976 9.97449 19.425 9.97449Z"
			fill="currentColor"
		/>
		<path
			d="M19.425 12.6745H18.075C17.7024 12.6745 17.4 12.9769 17.4 13.3495C17.4 13.7221 17.7024 14.0245 18.075 14.0245H19.425C19.7976 14.0245 20.1 13.7221 20.1 13.3495C20.1 12.9769 19.7976 12.6745 19.425 12.6745Z"
			fill="currentColor"
		/>
		<path
			d="M19.425 15.3745H18.075C17.7024 15.3745 17.4 15.6769 17.4 16.0495C17.4 16.4221 17.7024 16.7245 18.075 16.7245H19.425C19.7976 16.7245 20.1 16.4221 20.1 16.0495C20.1 15.6769 19.7976 15.3745 19.425 15.3745Z"
			fill="currentColor"
		/>
		<path
			d="M10.4839 14.2553L5.75893 8.85533C5.51053 8.57318 5.08528 8.54618 4.80583 8.79188C4.52503 9.03758 4.49668 9.46418 4.74238 9.74363L9.46738 15.1436C9.60103 15.2962 9.78733 15.3745 9.97498 15.3745C10.1329 15.3745 10.2909 15.3191 10.4205 15.2071C10.7013 14.9614 10.7296 14.5361 10.4839 14.2553Z"
			fill="currentColor"
		/>
		<path
			d="M10.3894 8.09279C10.0951 7.86194 9.67122 7.91729 9.44172 8.21024L4.71672 14.2852C4.48857 14.5795 4.54122 15.0048 4.83552 15.2329C4.95972 15.3288 5.10552 15.3747 5.24997 15.3747C5.44977 15.3747 5.64957 15.2856 5.78187 15.1155L10.5069 9.04048C10.7364 8.74483 10.6837 8.32094 10.3894 8.09279Z"
			fill="currentColor"
		/>
	</SvgIcon>
);

export default ExcelIcon;
