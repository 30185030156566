import React, { createContext } from 'react';
import { node, object } from 'prop-types';

// Import queries
import { useDeliveryLocationQuery } from 'queries';

export const DeliveryLocationContext = createContext(null);

export const DeliveryLocationProvider = ({ deliveryUuids, children }) => {
	const { pick_up_delivery, drop_delivery } = deliveryUuids || {};

	const {
		data: pickupDeliveryData,
		isFetching: isPickupDeliveryFetching,
		isFetched: isPickupDeliveryFetched,
		isError: isPickupDeliveryError,
		refetch: refetchPickupDeliveryData,
	} = useDeliveryLocationQuery({
		deliveryUuid: pick_up_delivery,
	});

	const {
		data: dropDeliveryData,
		isFetching: isDropDeliveryFetching,
		isFetched: isDropDeliveryFetched,
		isError: isDropDeliveryError,
		refetch: refetchDropDeliveryData,
	} = useDeliveryLocationQuery({
		deliveryUuid: drop_delivery,
	});

	const getValues = () => {
		const values = {};

		if (pick_up_delivery) {
			values.pick_up_delivery = {
				...pickupDeliveryData,
				refetch: refetchPickupDeliveryData,
				isFetching: isPickupDeliveryFetching,
				isFetched: isPickupDeliveryFetched,
				isError: isPickupDeliveryError,
			};
		}

		if (drop_delivery) {
			values.drop_delivery = {
				...dropDeliveryData,
				refetch: refetchDropDeliveryData,
				isFetching: isDropDeliveryFetching,
				isFetched: isDropDeliveryFetched,
				isError: isDropDeliveryError,
			};
		}

		return values;
	};

	const values = getValues();

	return (
		<DeliveryLocationContext.Provider value={values}>
			{children}
		</DeliveryLocationContext.Provider>
	);
};

DeliveryLocationProvider.propTypes = {
	deliveryUuids: object,
	children: node,
};
