// Import helpers
import { FIELD_TYPES } from 'helpers';

export const CORPORATE_WALLET_FIELDS = [
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.amount',
		name: `limit_amount_gross`,
		required: true,
	},
	{
		type: FIELD_TYPES.DATE,
		label: 'common.fields.expiry_date',
		name: 'valid_until',
		required: true,
	},
];
