import React from 'react';
import { bool, func, object, string, number } from 'prop-types';
import { Form } from 'react-final-form';

import {
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Typography,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { CloseButton } from 'components/elements';

//Import styles
import useStyles from './styles';

const ModalDialog = ({
	title,
	secondaryTitle,
	titleError,
	titleSuccess,
	alignTitle,
	open,
	close,
	initialValues,
	onSubmit,
	children,
	maxWidth = 'md',
	itemSpacing = 3,
	isActionBarSticky = false,
	submitLabel,
	withAdditionalInfoClearButton = false,
	onClear = () => {},
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const dialogActionsClassName = isActionBarSticky
		? classes.dialogActionsSticky
		: classes.dialogActions;

	return (
		<Dialog
			keepMounted
			fullWidth
			maxWidth={maxWidth}
			open={open}
			onClose={close}
			aria-labelledby="booking-filters"
			scroll="paper"
		>
			<CloseButton onClick={close} className={classes.buttonClose} />

			<DialogTitle id="booking-filters" align={alignTitle}>
				<Typography className={classes.title}>{t(title) || ''}</Typography>
				<Typography>{t(secondaryTitle) || ''}</Typography>
				{titleError && (
					<Typography className={classes.error}>
						{t(titleError) || ''}
					</Typography>
				)}
				{titleSuccess && (
					<Typography className={classes.success}>
						{t(titleSuccess) || ''}
					</Typography>
				)}
			</DialogTitle>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, submitting, ...rest }) => (
					<DialogContent className={classes.dialogContent}>
						<form onSubmit={handleSubmit}>
							<Grid alignItems="center" container spacing={itemSpacing}>
								{children({ ...rest, submitting })}
							</Grid>
							<DialogActions className={dialogActionsClassName}>
								{withAdditionalInfoClearButton && (
									<Button
										onClick={() => onClear(rest.form.reset)}
										disabled={submitting}
										variant="contained"
										color="primary"
										className={classes.clearBtn}
									>
										{t('table.modal.edit.btn_clear')}
									</Button>
								)}
								<Button
									disabled={submitting}
									type="button"
									onClick={close}
									color="primary"
									variant="outlined"
								>
									{t('table.modal.delete.btn_cancel')}
								</Button>
								<Button
									disabled={submitting}
									type="submit"
									color="primary"
									variant="contained"
								>
									{submitLabel || t('table.modal.edit.btn_submit')}
								</Button>
							</DialogActions>
						</form>
					</DialogContent>
				)}
			/>
		</Dialog>
	);
};

ModalDialog.propTypes = {
	title: string.isRequired,
	secondaryTitle: string,
	titleError: string,
	titleSuccess: string,
	alignTitle: string,
	open: bool.isRequired,
	close: func.isRequired,
	initialValues: object.isRequired,
	onSubmit: func.isRequired,
	children: func.isRequired,
	maxWidth: string,
	itemSpacing: number,
	isActionBarSticky: bool,
	submitLabel: string,
	withAdditionalInfoClearButton: bool,
	onClear: func,
};

export default ModalDialog;
