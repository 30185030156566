import Geocode from 'react-geocode';

export const convertHolidays = (uuids, availableHolidays) =>
	uuids
		.filter((it) => it)
		.map((uuid) => availableHolidays.find((holiday) => holiday.uuid === uuid));

export const convertTerminals = (values) => {
	const {
		location: {
			airport: { terminals },
		},
	} = values;
	return terminals;
};

export const fetchPlaceId = async (address) => {
	try {
		const response = await Geocode.fromAddress(address);
		const { place_id } = response.results[0];

		return place_id;
	} catch {
		return null;
	}
};

const DEFAULT_DAY = {
	isOpen: false,
	isOpenSecond: false,
	open: { start: '03:00', end: '21:00' },
	openSecond: { start: '16:00', end: '22:00' },
};

export const INITIAL_DELIVERY_HOURS_VALUES = {
	fri: DEFAULT_DAY,
	mon: DEFAULT_DAY,
	sat: DEFAULT_DAY,
	sun: DEFAULT_DAY,
	thu: DEFAULT_DAY,
	tue: DEFAULT_DAY,
	wed: DEFAULT_DAY,
};
