import { useSelector } from 'react-redux';

// Import store
import { addNewCustomerToCorporate } from 'store/actions';
import { profileDataSelector } from 'store/selectors';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { useAddCorporateCustomerDialogProvider } from '../../context';
import { useTableContextProvider } from 'components/context';

export const useAddCustomerStep = () => {
	const {
		setSaveAndAddCustomerButtonLoading,
		setSaveAndBookCarButtonLoading,
		setCustomerUuid,
		toggleDialog,
	} = useAddCorporateCustomerDialogProvider();
	const { corporate } = useSelector(profileDataSelector);
	const { fetchData } = useTableContextProvider();
	const { message } = useMessage();
	const { t } = useTranslations();

	const { uuid: corporateUuid } = corporate || {};

	const resetParams = () => {
		setSaveAndAddCustomerButtonLoading(false);
		setSaveAndBookCarButtonLoading(false);
	};

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		await addNewCustomerToCorporate({
			errorCallback: (error) => {
				resetParams();
				if (error?.response?.data) {
					apiErrors = error.response.data.errors;
				} else {
					message.error(t('common.messages.error_message'));
				}
			},
			successCallback: async (customerUuid) => {
				message.success(t('common.messages.successfully_created'));
				setCustomerUuid(customerUuid);
				toggleDialog();
				resetParams();
				await fetchData();
			},
			corporateUuid,
			values,
		});

		return apiErrors;
	};

	return {
		handleOnSubmit,
	};
};
