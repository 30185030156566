import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

const ApprovedTableCell = ({ cell }) => {
    return (
        <TableCell key={cell.column.id} {...cell.getCellProps()}>
           {cell.row.original.approved && (
               <DoneIcon fontSize="small" />
           )}
        </TableCell>
    )
};

ApprovedTableCell.propTypes = {
	cell: PropTypes.object.isRequired
};


export default ApprovedTableCell;