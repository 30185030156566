export const COLUMNS_ACCESSORS = {
	ACTION: 'action',
	CORPORATE_NAME: 'corporate_name',
};

export const columns = [
	{
		Header: 'table.headers.corporate_name',
		accessor: COLUMNS_ACCESSORS.CORPORATE_NAME,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cr',
		accessor: 'commercial_registration_number',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.vat_number',
		accessor: 'vat_number',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.maximum_limit',
		accessor: 'total_limit_amount_gross',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.action',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
	},
];
