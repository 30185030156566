import React from 'react';

// Import components
import { DataForm, PrivacyPolicy, SubmitForm } from '../../components';

// Import helpers
import { INITIATE_CONTRACT_STEPS } from 'helpers';

const { DATA, PRIVACY_POLICY } = INITIATE_CONTRACT_STEPS;

export const getStep = (step) => {
	switch (step) {
		case DATA:
			return <DataForm />;
		case PRIVACY_POLICY:
			return <PrivacyPolicy />;
		default:
			return <SubmitForm />;
	}
};
