import React from 'react';
import { string } from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
	Grid,
	MenuItem,
	Hidden,
	CircularProgress,
	Box,
} from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import DateFnsUtils from '@date-io/date-fns';
import { usePhonePrefixes, useGenders, useCustomerType } from 'queries';

// Import helpers
import { BASE_DETAILS_FIELDS, getMenuItems } from './helpers';
import { EN } from 'helpers';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from '../../styles';

const BaseDetailsFields = ({ section }) => {
	const { submitting } = useFormState();

	const classes = useStyles();

	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const { data: prefixes, isFetching: isPrefixesLoading } = usePhonePrefixes();

	const { data: genders, isFetching: isGendersLoading } = useGenders();

	const { data: customerTypes, isFetching: isTypesLoading } = useCustomerType();

	const isFetched = !isPrefixesLoading && !isGendersLoading && !isTypesLoading;

	const isEnglish = lng === EN;

	/* eslint-disable react/prop-types */ // TODO separate logic from UI
	const renderField = ({
		type,
		name,
		md,
		xs,
		sm,
		label,
		component,
		withLang = false,
		isRequired = true,
	}) => {
		const fieldName = `${section}.${name}${withLang ? `.${lng}` : ''}`;
		switch (type) {
			case 'select':
				// eslint-disable-next-line no-case-declarations
				const menuItems = getMenuItems({
					name,
					prefixes,
					genders,
					customerTypes,
					isEnglish,
				});
				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							fullWidth
							component={component}
							type={type}
							label={t(label)}
							name={fieldName}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required={isRequired}
						>
							{menuItems.map((item) => (
								<MenuItem key={item} value={item}>
									{item}
								</MenuItem>
							))}
						</Field>
					</Grid>
				);
			case 'date':
				return (
					<Grid key={name} item md={md} xs={xs} sm={sm}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Field
								fullWidth
								label={t(label)}
								component={component}
								name={fieldName}
								margin="dense"
								disabled={submitting}
								required={isRequired}
								inputVariant="outlined"
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				);
			case 'hidden':
				return (
					<Hidden key={name} only={['xs', 'md', 'lg', 'xl']}>
						<Grid item xs={xs}></Grid>
					</Hidden>
				);
			default:
				return (
					<Grid key={name} item md={md} xs={xs}>
						<Field
							fullWidth
							component={component}
							type={type}
							label={t(label)}
							name={fieldName}
							margin="dense"
							variant="outlined"
							inputProps={type === 'number' ? { min: 1 } : {}}
							disabled={submitting}
							required={isRequired}
						/>
					</Grid>
				);
		}
	};

	return (
		<>
			{isFetched ? (
				BASE_DETAILS_FIELDS.map(renderField)
			) : (
				<Grid item md={12} xs={12}>
					<Box className={classes.loader}>
						<CircularProgress size={35} />
					</Box>
				</Grid>
			)}
		</>
	);
};

BaseDetailsFields.propTypes = {
	section: string.isRequired,
};

export default BaseDetailsFields;
