import React from 'react';
import { string } from 'prop-types';
import { Button, Dialog, DialogContent } from '@material-ui/core';

// Import utilities
import { useToggle, useTranslations } from 'components/utilities';
import { useCustomerDriverLicense } from 'queries';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { Content } from './components';

export const LicensePreviewDialog = ({ uuid }) => {
	const { t } = useTranslations();

	const { on: isOpen, toggle } = useToggle();

	const { data, isFetching } = useCustomerDriverLicense({
		uuid,
		enabled: isOpen,
	});

	return (
		<>
			{isOpen && (
				<Dialog open={isOpen} fullWidth onClose={toggle}>
					<ClosableDialogTitle
						title={t('customers.driver_license_list.license_preview.header')}
						onClose={toggle}
					/>
					<DialogContent>
						<Content data={data} isFetching={isFetching} />
					</DialogContent>
				</Dialog>
			)}

			<Button variant="contained" color="primary" onClick={toggle}>
				{t('common.buttons.license_preview')}
			</Button>
		</>
	);
};

LicensePreviewDialog.propTypes = {
	uuid: string,
};
