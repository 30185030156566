import { CUSTOMER_TYPES } from 'helpers';

export const OPTIONS = [
	{
		value: CUSTOMER_TYPES.NEW,
		label: 'customers.filters.type.new',
	},
	{
		value: CUSTOMER_TYPES.VERIFIED,
		label: 'customers.filters.type.verified',
	},
	{
		value: CUSTOMER_TYPES.TRUSTED,
		label: 'customers.filters.type.trusted',
	},
];
