import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	container: {
		minWidth: ({ width }) => width,
		minHeight: ({ height }) => height,
	},
	tableContainer: {
		position: 'relative',
		width: ({ width }) => width,
		height: ({ height }) => height,
	},
}));

export default useStyles;
