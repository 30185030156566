import * as types from 'store/types';
import services from 'services/services';
import {
	fetchCancellationReasonsFormResourcesUrl,
	submitCancellationReasonsFormUrl,
} from 'store/paths';
import {
	convertFetchCancellationReasonsFormData,
	convertSubmitCancellationReasonsFormData,
} from './helpers';

export const fetchCancellationReasonsFormResources =
	({ itemId, isEdit, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.CANCELLATION_REASONS_FORM_FETCH_DATA_LOADING,
			});

			const { data } =
				isEdit &&
				(await services.get(
					fetchCancellationReasonsFormResourcesUrl(itemId),
					cancelToken
				));

			const convertedData =
				isEdit && convertFetchCancellationReasonsFormData(data.data);

			dispatch({
				type: types.CANCELLATION_REASONS_FORM_FETCH_DATA_SUCCES,
				payload: {
					item: convertedData || {},
				},
			});
		} catch (error) {
			const defaultError = { message: '' };
			const response = error.response?.data ?? defaultError;
			dispatch({
				type: types.EDIT_COMPANY_FORM_FETCH_DATA_ERROR,
				payload: response,
			});
		}
	};

export const submitCancellationReasonsForm =
	({ values, itemId, isEdit, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			const convertedData = convertSubmitCancellationReasonsFormData(values);

			await services.post(
				submitCancellationReasonsFormUrl(isEdit, itemId),
				convertedData
			);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
