import React from 'react';
import { object } from 'prop-types';
import { Tooltip } from '@material-ui/core';

// Import styles
import { useStyles } from '../../styles';

// Import components
import { IconButton } from 'components/elements';
import { PrintIcon } from 'assets/icons';

// Import utilities
import usePrintTableCell from './usePrintTableCell';
import { useTranslations } from 'components/utilities';

const PrintPart = ({ cell }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { print, isLoading } = usePrintTableCell(cell.row.original);

	return (
		<Tooltip title={t('common.icon_names.print')}>
			<span>
				<IconButton
					onClick={print}
					disabled={isLoading}
					className={classes.icon}
					size="small"
				>
					<PrintIcon />
				</IconButton>
			</span>
		</Tooltip>
	);
};

PrintPart.propTypes = {
	cell: object.isRequired,
};

export default PrintPart;
