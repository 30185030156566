import React from 'react';
import { Button, Typography, Grid, Avatar, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

// Import assets
import { ArrowIcon } from 'assets/icons';
import { placeholderUser } from 'assets/images';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

const ToggleButton = ({ role, fullName, bindTrigger }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Button {...bindTrigger}>
			<Tooltip title={t('common.icon_names.profile')}>
				<Grid
					container
					spacing={1}
					direction="row"
					className={classes.mainWrapper}
				>
					<Grid item xs>
						<Avatar
							src={placeholderUser}
							alt={t('common.placeholders.user_image_alt')}
							className={classes.image}
						/>
					</Grid>
					<Grid container item direction="column" xs>
						<Grid item>
							<Typography className={classes.fullName} align="left">
								{fullName}
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.role} align="left">
								{role[0].replace('_', ' ')}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item direction="column" xs>
						<Grid item className={classes.arrowWrapper}>
							<ArrowIcon />
						</Grid>
						<Grid item className={classes.arrowWrapper}>
							<ArrowIcon className={classes.rotate} />
						</Grid>
					</Grid>
				</Grid>
			</Tooltip>
		</Button>
	);
};

ToggleButton.propTypes = {
	bindTrigger: PropTypes.object,
	fullName: PropTypes.string,
	role: PropTypes.array,
};
export default ToggleButton;
