import React, { useEffect } from 'react';
import {
	arrayOf,
	bool,
	func,
	node,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';
import clsx from 'clsx';
import {
	Card as MuiCard,
	CardContent as MuiCardContent,
	CardHeader as MuiCardHeader,
	Collapse,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';

// Import components
import { ToggleButton, Alert } from 'components/elements';

// Import styles and theme
import { useCardStyles } from 'theme/styles';
import { useStyles } from './CollapsableCard.styles';

// Import utils
import { useCollapsableCard } from './useCollapsableCard';
import { useMediaQuerySizes } from 'components/utilities';

// Import icons
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

export const CollapsableCard = ({
	title,
	children,
	initialOpen = true,
	close,
	xs = 12,
	md = 12,
	lg = 12,
	icon: Icon,
	cardClassName = null,
	headerClassName = null,
	contentClassName = null,
	titleClassName = null,
	elevation = 0,
	errorMessage = '',
}) => {
	const { isOpen, toggle, onClose } = useCollapsableCard({ initialOpen });
	const { isTabletSize } = useMediaQuerySizes();

	useEffect(() => {
		if (close) {
			onClose();
		}
		//eslint-disable-next-line
	}, [close]);

	const classes = useCardStyles();
	const localComponentStyles = useStyles();

	return (
		<Grid item xs={xs} md={md} lg={lg}>
			<MuiCard
				elevation={elevation}
				className={clsx([classes.root, cardClassName])}
			>
				<MuiCardHeader
					title={
						<Grid
							container
							alignItems="center"
							spacing={2}
							justifyContent={isTabletSize ? 'space-between' : 'flex-start'}
						>
							<Grid item className={localComponentStyles.headerTitleContainer}>
								{isTabletSize && (
									<Grid
										item
										onClick={toggle}
										className={localComponentStyles.actionIcon}
									>
										{isOpen ? (
											<RemoveIcon color="primary" />
										) : (
											<AddIcon color="primary" />
										)}
									</Grid>
								)}
								<Grid item>
									<Grid container alignItems="center" spacing={2}>
										<Grid item>
											<Typography
												role="heading"
												variant="h4"
												className={titleClassName}
											>
												{title}
											</Typography>
										</Grid>
										<Grid item>
											{errorMessage && (
												<Alert
													className={localComponentStyles.alert}
													message={errorMessage}
													title=""
												/>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							{Icon && (
								<Grid item>
									<Icon color="primary" />
								</Grid>
							)}
						</Grid>
					}
					className={headerClassName}
					action={
						!isTabletSize && <ToggleButton isOpen={isOpen} onClick={toggle} />
					}
				/>
				<Collapse in={isOpen}>
					<Divider />
					<MuiCardContent className={contentClassName}>
						<Grid container spacing={5}>
							{children}
						</Grid>
					</MuiCardContent>
				</Collapse>
			</MuiCard>
		</Grid>
	);
};

CollapsableCard.propTypes = {
	title: string,
	xs: number,
	md: number,
	lg: number,
	initialOpen: bool,
	close: bool,
	children: oneOfType([node, arrayOf(node)]),
	icon: oneOfType([func, node, object]),
	headerClassName: string,
	cardClassName: string,
	contentClassName: string,
	elevation: number,
	titleClassName: string,
	errorMessage: oneOfType([string, bool]),
};
