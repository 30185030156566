import ls from 'localstorage-slim';
import * as types from 'store/types';
import services from 'services/services';
import {
	phonePrefixUrl,
	fetchBusinessManagerProfileUrl,
	fetchBusinessCompanyCollectionUrl,
	fetchProfileFormResourcesUrl,
	submitProfileFormUrl,
} from 'store/paths';

// Import helpers
import {
	convertRoleToDisplay,
	OFFICE_MANAGER,
	COMPANY_MANAGER,
	LOCAL_STORAGE_MANAGER_PROFILE,
	LOCAL_STORAGE_COMPANIES_LIST,
	LOCAL_STORAGE_PHONE_PREFIXES,
} from 'helpers';
import {
	initialValues,
	formatUserData,
	findCompanyUuid,
	convertDataToStore,
} from './helpers';
import { catchError } from '../helpers';

const {
	COMPANY_MANAGER_FORM_FETCH_DATA_LOADING,
	COMPANY_MANAGER_FORM_FETCH_DATA_SUCCESS,
	COMPANY_MANAGER_FORM_ADD_USER_SUCCESS,
	COMPANY_MANAGER_FORM_FETCH_DATA_ERROR,
	PROFILE_FORM_FETCH_DATA_LOADING,
	PROFILE_FORM_FETCH_DATA_SUCCESS,
	PROFILE_FORM_FETCH_DATA_ERROR,
} = types;

export const fetchProfileFormResources =
	({ itemId, isEdit, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: COMPANY_MANAGER_FORM_FETCH_DATA_LOADING,
			});

			const localStoragePhonePrefixes = ls.get(LOCAL_STORAGE_PHONE_PREFIXES);

			const phonePrefixesPromise =
				!localStoragePhonePrefixes && services.get(phonePrefixUrl, cancelToken);

			const localStorageCompaniesList = ls.get(
				LOCAL_STORAGE_COMPANIES_LIST('')
			);

			const companyListPromise =
				!localStorageCompaniesList &&
				services.get(fetchBusinessCompanyCollectionUrl, cancelToken);

			const userDataPromise = services.get(
				fetchProfileFormResourcesUrl(itemId),
				cancelToken
			);

			const values = await Promise.all([
				phonePrefixesPromise,
				companyListPromise,
				userDataPromise,
			]);

			const phonePrefixes = values?.[0]?.data?.data || [];
			const companyList = values?.[1]?.data?.data || [];
			const userData = values?.[2]?.data?.data || {};

			if (!localStorageCompaniesList) {
				ls.set(LOCAL_STORAGE_COMPANIES_LIST(''), companyList, {
					ttl: 7 * 24 * 60 * 60,
				});
			}

			if (!localStoragePhonePrefixes) {
				ls.set(LOCAL_STORAGE_PHONE_PREFIXES, phonePrefixes, {
					ttl: 14 * 24 * 60 * 60,
				});
			}
			const companyListResult = localStorageCompaniesList || companyList;

			const phonePrefixesResult = localStoragePhonePrefixes || phonePrefixes;

			const { manager, belonging } = userData;
			const { userCompanyUuid } = findCompanyUuid(manager.role, belonging);

			const roles = [OFFICE_MANAGER, COMPANY_MANAGER].map((role) => ({
				key: role,
				label: convertRoleToDisplay(role),
			}));

			const { belonging: initialBelonging } = initialValues;

			const additionalData = convertDataToStore({
				isEdit,
				userData,
				initialBelonging,
				userCompanyUuid,
			});

			const actionType = isEdit
				? COMPANY_MANAGER_FORM_FETCH_DATA_SUCCESS
				: COMPANY_MANAGER_FORM_ADD_USER_SUCCESS;
			const payload = {
				phonePrefixes: phonePrefixesResult,
				companyList: companyListResult,
				roles,
				...additionalData,
			};

			dispatch({
				type: actionType,
				payload,
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: COMPANY_MANAGER_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitProfileForm =
	({ values, callbackSuccess, errorCallback, isEdit }) =>
	async () => {
		try {
			const { userData, userUuid } = formatUserData({
				values,
			});

			await services.post(submitProfileFormUrl(isEdit, userUuid), userData);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const getProfile = async (dispatch) => {
	const localStorageManagerProfile = ls.get(LOCAL_STORAGE_MANAGER_PROFILE);

	try {
		dispatch({
			type: PROFILE_FORM_FETCH_DATA_LOADING,
		});

		const { data: mangerUserData } =
			!localStorageManagerProfile &&
			(await services.get(fetchBusinessManagerProfileUrl));

		if (!localStorageManagerProfile) {
			ls.set(LOCAL_STORAGE_MANAGER_PROFILE, mangerUserData.data, {
				ttl: 24 * 60 * 60,
			});
		}

		const mangerUserDataResult =
			localStorageManagerProfile || mangerUserData.data;

		dispatch({
			type: PROFILE_FORM_FETCH_DATA_SUCCESS,
			payload: mangerUserDataResult,
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: PROFILE_FORM_FETCH_DATA_ERROR,
		});
	}
};
