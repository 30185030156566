import * as types from 'store/types';
import services from 'services/services';
import {
	updatePromotedCompaniesUrl,
	showPromotedCompaniesUrl,
} from 'store/paths';

// Import helpers
import { catchError } from '../../helpers';
import { convertPartnersPositionsData } from './helpers';

export const fetchPartnersPositionsFormResources =
	({ cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.PARTNERS_POSITIONS_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			const data = await services.get(showPromotedCompaniesUrl, cancelToken);

			dispatch({
				type: types.PARTNERS_POSITIONS_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: { partners: data?.data?.data } || {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.PARTNERS_POSITIONS_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitPartnersPositionsForm =
	({ values, errorCallback, callbackSuccess }) =>
	async () => {
		try {
			const convertedValues = convertPartnersPositionsData(values);
			await services.post(updatePromotedCompaniesUrl, convertedValues);
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
