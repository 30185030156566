import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';

// Import components
import {
	ExpandTableCell,
	SelectTableCell,
	ActiveTableCell,
	LinkTableCell,
	SettlementRatingTableCell,
	SettledDateTableCell,
} from 'components/elements';
import { useSubTableContextProvider } from 'components/context';
import { HistoryActionButton } from '../../../HistoryActionButton';
import { useTableActionsProvider } from 'views/RewardsSettlement/components/TableActionsProvider';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../../../columns';
import { replacePreviewBookingUrl, toFixedWithoutZeros } from 'helpers';
import { addSupRowsToParent } from './helpers';

// Import styles
import { useStyles } from 'theme/styles';

const { EXPAND, SELECT, HISTORY, ACTIVE, RATING, BOOKING_ID, SETTLED } =
	COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const classes = useStyles();

	const {
		selectedParent,
		setSelectedChildren,
		selectedChildren,
		handleSubCheckboxChange,
		allExpandedData,
		setAllExpandedData,
	} = useTableActionsProvider();
	const { data } = useSubTableContextProvider();

	useEffect(() => {
		if (selectedParent) {
			addSupRowsToParent({
				allExpandedData,
				data,
				setAllExpandedData,
				setSelectedChildren,
			});
		}
		// eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (selectedParent) {
			const parentSubRows = allExpandedData.subRows?.filter(
				(row) => !row.is_settled && row.conditions_met
			);
			parentSubRows && setSelectedChildren(parentSubRows);
		}
		// eslint-disable-next-line
	}, [selectedParent]);

	const renderCell = (cell) => {
		const {
			item_uuid: itemUuid,
			user_rating: userRating,
			booking_uuid: bookingUuid,
			booking_individual_number: bookingIndividualNumber,
			settled_date: settledAt,
			is_settled: isSettled,
			conditions_met: conditionsMet,
		} = cell.row.original || {};

		const { id, isHidden } = cell.column;

		if (isHidden) return null;
		switch (id) {
			case EXPAND:
				return <ExpandTableCell key={id} cell={cell} hideExpand />;

			case SELECT:
				return (
					<SelectTableCell
						key={id}
						cell={cell}
						checked={
							!!selectedChildren.find((item) => item.item_uuid === itemUuid)
						}
						onChange={(e) => handleSubCheckboxChange({ e, cell })}
						hideSelect={isSettled || !conditionsMet}
					/>
				);

			case ACTIVE:
				return <ActiveTableCell hideActive key={id} cell={cell} />;

			case RATING:
				return (
					<SettlementRatingTableCell
						key={id}
						cell={cell}
						rating={toFixedWithoutZeros(userRating, 2)}
					/>
				);

			case BOOKING_ID:
				return (
					<LinkTableCell
						key={id}
						cell={cell}
						to={replacePreviewBookingUrl(bookingUuid)}
					>
						{bookingIndividualNumber}
					</LinkTableCell>
				);

			case SETTLED:
				return (
					<SettledDateTableCell key={id} cell={cell} settledAt={settledAt} />
				);

			case HISTORY:
				return <HistoryActionButton key={id} cell={cell} />;
			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<>
			<TableRow
				key={row.id}
				{...row.getRowProps()}
				className={clsx({
					[classes.accountDeleted]: row.original.customer_deleted_at,
				})}
			>
				{row.cells.map(renderCell)}
			</TableRow>
		</>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
