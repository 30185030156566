import React from 'react';
import { bool, number, string } from 'prop-types';
import { CircularProgress, Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import queries
import { useCompanies } from 'queries';

// Import helpers
import { sortByName } from '../helpers';

const CompanyFilter = ({
	label = 'bookings.table.headers.company',
	name,
	xs = 3,
	md = 3,
	showFetching = false,
	showAll = true,
	isActive = false,
	required = false,
	...rest
}) => {
	const { submitting } = useFormState();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data, isFetching } = useCompanies(isActive);

	if (!data) {
		return null;
	}

	const sortedCompanies = sortByName(data, language);

	return (
		<Grid item md={md} xs={xs}>
			{isFetching && showFetching ? (
				<CircularProgress size={28} />
			) : (
				<Field
					fullWidth
					component={SelectFieldAdapter}
					label={t(label)}
					name={name}
					variant="outlined"
					margin="dense"
					disabled={submitting}
					required={required}
					{...rest}
				>
					{showAll && <MenuItem value="all">{t('common.all')}</MenuItem>}
					{sortedCompanies.map(({ uuid, name }) => (
						<MenuItem key={uuid} value={uuid}>
							{name[language]}
						</MenuItem>
					))}
				</Field>
			)}
		</Grid>
	);
};

CompanyFilter.propTypes = {
	name: string.isRequired,
	label: string,
	showFetching: bool,
	isActive: bool,
	required: bool,
	showAll: bool,
	md: number,
	xs: number,
};

export default CompanyFilter;
