export const OPTIONS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: true,
		label: 'common.yes',
	},
	{
		value: false,
		label: 'common.no',
	},
];
