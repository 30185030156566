import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton } from '@material-ui/lab';
import TodayIcon from '@material-ui/icons/Today';

const PeriodButton = ({ children, ...restProps }) => {
	return (
		<ToggleButton {...restProps} value="">
			<TodayIcon />
			&nbsp;
			{children}
		</ToggleButton>
	);
};

PeriodButton.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	])
};

export default PeriodButton;
