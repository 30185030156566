import { useFormState } from 'react-final-form';

// Import helpers and utils
import { useBookingStates } from 'queries';
import { removeUnwantedStates } from './helpers';

export const useBookingStatusFilter = ({ neededStates }) => {
	const { submitting } = useFormState();

	const { data } = useBookingStates();

	const bookingStates = removeUnwantedStates({ data, neededStates });

	return { submitting, bookingStates };
};
