import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { CircularProgress, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import queries
import { useManufacturers } from 'queries';

// Import utilities
import { useTranslations } from 'components/utilities';

const CarManufacturerFilter = () => {
	const { submitting } = useFormState();

	const { data: manufacturers, isFetching } = useManufacturers();

	const { i18n, t } = useTranslations();

	const lng = i18n.language;

	if (isFetching) {
		return <CircularProgress size={28} />;
	}

	return (
		<Field
			fullWidth
			component={SelectFieldAdapter}
			label={t('bookings.edit_booking.select_car.form.car_manufacturer_field')}
			name="vehicle.maker_uuid"
			variant="outlined"
			margin="dense"
			disabled={submitting}
		>
			{manufacturers.map(({ name, uuid }) => (
				<MenuItem key={uuid} value={uuid}>
					{name[lng]}
				</MenuItem>
			))}
		</Field>
	);
};

export default CarManufacturerFilter;
