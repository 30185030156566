import * as yup from 'yup';
import i18n from 'i18next';

// Import helpers
import { isClosureCodeRequired } from 'helpers';

export const validationSchema = ({ values, closureReasons }) => {
	const minimumValueFieldText = i18n.t('common.fields.minimum', { value: 0 });
	const requiredFieldText = i18n.t('common.fields.required_field');

	return yup.object({
		actual_return_branch_id: yup
			.number()
			.min(0, minimumValueFieldText)
			.required(requiredFieldText),
		contract_actual_end_date: yup.date().required(requiredFieldText),
		main_closure_code: yup
			.number()
			.min(0, minimumValueFieldText)
			.required(requiredFieldText),
		closure_code: isClosureCodeRequired({
			closureReasons,
			mainClosureCode: values.main_closure_code,
		})
			? yup.number().min(0, minimumValueFieldText).required(requiredFieldText)
			: yup.number().nullable().min(0, minimumValueFieldText).notRequired(),
		operator_id: yup.number().integer(),
		payment_details: yup.object({
			discount: yup
				.number()
				.required(requiredFieldText)
				.min(0, minimumValueFieldText)
				.max(100, i18n.t('common.fields.maximum', { value: 100 })),
			paid: yup
				.number()
				.integer()
				.min(0, minimumValueFieldText)
				.required(requiredFieldText),
			payment_method_code: yup
				.number()
				.nullable()
				.min(0, minimumValueFieldText)
				.notRequired(),
		}),
		rent_status: yup.object({
			available_fuel: yup.string().required(requiredFieldText),
			odometer_reading: yup
				.number()
				.min(0, minimumValueFieldText)
				.required(requiredFieldText),
			fire_extinguisher: yup.string().required(requiredFieldText),
			first_aid_kit: yup.string().required(requiredFieldText),
			safety_triangle: yup.string().required(requiredFieldText),
			spare_tire: yup.string().required(requiredFieldText),
			spare_tire_tools: yup.string().required(requiredFieldText),
			ac: yup.string().required(requiredFieldText),
			radio_stereo: yup.string().required(requiredFieldText),
			tires: yup.string().required(requiredFieldText),
			screen: yup.string().required(requiredFieldText),
			car_seats: yup.string().required(requiredFieldText),
			keys: yup.string().required(requiredFieldText),
			speedometer: yup.string().required(requiredFieldText),
		}),
	});
};
