import React from 'react';
import { Grid, Box } from '@material-ui/core';

// Import utilities
import { fetchTerminalFormResources, submitTerminalForm } from 'store/actions';
import { FormRPC } from 'components/utilities';
import { useParams } from 'react-router';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { replaceAirportTerminalsUrl } from 'helpers';

// Import styles
import { useStyles } from './styles';

// Import components
import { BaseDetails } from './components';

const EditTerminals = () => {
	const classes = useStyles();
	const { airportId } = useParams();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchTerminalFormResources}
				submitFormAction={(props) =>
					submitTerminalForm({ ...props, airportId })
				}
				isCardLayout={false}
				goBackPath={replaceAirportTerminalsUrl(airportId)}
				store={(store) => store.terminals.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<BaseDetails section="details" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default EditTerminals;
