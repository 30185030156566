import React from 'react';
import { object } from 'prop-types';
import { Grid, TableCell } from '@material-ui/core';

// Import components
import { DateBody } from '.';

// Import images
import { calendar } from 'assets/images';

// Import styles
import useStyles from './styles';

// Import utilities
import { useTranslations } from 'components/utilities';

const PickDateTableCell = ({ cell }) => {
	const { pick_date, pick_time, is_edited } = cell.row.original;

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<TableCell
			key={cell.column.id}
			className={classes.dateColumn}
			{...cell.getCellProps()}
		>
			<Grid container spacing={2}>
				{is_edited && (
					<Grid item xs={2}>
						<img
							src={calendar}
							className={classes.smallImage}
							alt={t('bookings.table.common.calendar')}
						/>
					</Grid>
				)}
				<Grid item xs={10}>
					<DateBody date={pick_date} time={pick_time} />
				</Grid>
			</Grid>
		</TableCell>
	);
};

PickDateTableCell.propTypes = {
	cell: object.isRequired,
};

export default PickDateTableCell;
