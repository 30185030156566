import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

// Import actions
import { deleteOrganization, updateActiveOrganization } from 'store/actions';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import {
	replaceOrganizationDiscountsUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';

// Import components
import {
	EditTableCell,
	DeleteTableCell,
	ActiveTableCell,
} from 'components/elements';
import { EditOrganization } from '../../EditOrganization';

// Import styles
import useStyles from '../../styles';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, DISCOUNTS } = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const classes = useStyles();

	const renderDiscountsIcon = () => (
		<LocalOfferIcon className={classes.icon} fontSize="small" />
	);

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case EDIT:
				return <EditOrganization key={cell.column.id} cell={cell} />;
			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteOrganization}
						cell={cell}
					/>
				);
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveOrganization}
						cell={cell}
					/>
				);
			case DISCOUNTS:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceOrganizationDiscountsUrl(
							cell.row.original.uuid
						)}
						text="organizations.table.headers.discounts"
						originalIcon={renderDiscountsIcon()}
					/>
				);
			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
