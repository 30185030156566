import i18next from 'i18next';

export const getCompletePaymentText = (payments) => {
	const max = payments.length;
	const completed = payments.reduce(
		(acc, { is_success }) => (is_success ? (acc += 1) : acc),
		0
	);
	const counterText = `${completed}/${max}`;

	return `${i18next.t(
		'bookings.preview_booking.subscription_invoice.completed_payment'
	)} ${counterText}`;
};

export const getSettledPaymentsSum = (payments) => {
	const paymentsSum = payments.reduce(
		(acc, { amount_gross: rentPrice, is_success: isSettled }) =>
			isSettled ? acc + Number(rentPrice) : acc,
		0
	);

	return paymentsSum.toFixed(2);
};
