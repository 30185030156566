import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { colors } from '@material-ui/core';

// Import helpers
import { PERIOD_TYPES } from './helpers';

export const useStyles = makeStyles((theme) => ({
	legendContainer: {
		padding: '30px 32px',
	},

	periodItem: {
		padding: '6px 18px',
		borderLeft: ({ isBodyRow }) => `${isBodyRow ? '14' : '7'}px solid`,
	},

	[PERIOD_TYPES.TODAY]: {
		backgroundColor: colors.pink[50],
		color: colors.pink[200],
	},

	[PERIOD_TYPES.TOMORROW]: {
		backgroundColor: alpha(theme.palette.secondary.main, 0.1),
		color: theme.palette.secondary.main,
	},

	[PERIOD_TYPES.AFTER_TOMORROW]: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
	},
}));
