import AddCircleIcon from '@material-ui/icons/AddCircle';

// Import helpers
import { TOOLBAR_VARIANTS, replaceAddSpecialOfferUrl } from 'helpers';

export const TOOLBAR_CONTENT = (serviceType) => [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: replaceAddSpecialOfferUrl(serviceType),
		icon: AddCircleIcon,
		text: 'special_offer.table.toolbar.add_offer',
	},
];
