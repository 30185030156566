import { useEffect, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';

// Import utilities
import {
	useManufacturers,
	useVehicleTypes,
	useVehicleModelList,
} from 'queries';

// Import helpers
import { fieldNames } from './helpers';

export const useFilters = ({ section }) => {
	const [autocompleteValue, setAutocompleteValue] = useState({});
	const { submitting, values } = useFormState();
	const { change } = useForm();

	const manufacturerUuid = values.filters.vehicle_maker_uuid;

	const {
		labelFieldName,
		formattedLocationFieldName,
		placeIdFieldName,
		locationLatFieldName,
		locationLngFieldName,
		manufacturerFieldName,
		modelFieldName,
		typeFieldName,
	} = fieldNames({ section });

	const isModelFieldDisabled = manufacturerUuid === 'all';

	const { data: carTypes, isFetching: isCarTypesLoading } = useVehicleTypes();
	const { data: manufacturers, isFetching: isManufacturersLoading } =
		useManufacturers();
	const { data: models, isFetching: isModelsLoading } = useVehicleModelList({
		manufacturerUuid,
	});

	const handleLocationChanged = () => {
		if (autocompleteValue !== null) {
			const place = autocompleteValue.getPlace();
			const {
				formatted_address,
				name,
				place_id,
				geometry: { location },
			} = place;

			change(labelFieldName, name);
			change(formattedLocationFieldName, formatted_address);
			change(placeIdFieldName, place_id);
			change(locationLatFieldName, location.lat());
			change(locationLngFieldName, location.lng());
		}
	};

	useEffect(() => {
		if (manufacturerUuid === 'all') {
			change(modelFieldName, 'all');
		}
		// eslint-disable-next-line
	}, [manufacturerUuid]);

	return {
		handleLocationChanged,
		setAutocompleteValue,
		manufacturers,
		models,
		carTypes,
		manufacturerFieldName,
		modelFieldName,
		typeFieldName,
		labelFieldName,
		isModelFieldDisabled,
		isModelsLoading,
		isManufacturersLoading,
		isCarTypesLoading,
		submitting,
	};
};
