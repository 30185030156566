import React from 'react';
import { SvgIcon } from '@material-ui/core';

const NavigateFirstIcon = (props) => (
	<SvgIcon viewBox="0 0 38 38" {...props}>
		<path
			d="M18.2673 13.2069C17.9674 12.9213 17.4926 12.9328 17.2069 13.2327L12.2056 18.4832C11.9297 18.7728 11.9297 19.2281 12.2056 19.5178L17.2069 24.7682C17.4926 25.0681 17.9674 25.0797 18.2673 24.794C18.5672 24.5083 18.5787 24.0336 18.2931 23.7336L13.7845 19.0005L18.2931 14.2673C18.5787 13.9674 18.5672 13.4926 18.2673 13.2069Z"
			fill="currentColor"
		/>
		<path
			d="M24.2673 13.2069C23.9674 12.9213 23.4926 12.9328 23.2069 13.2327L18.2056 18.4832C17.9297 18.7728 17.9297 19.2281 18.2056 19.5178L23.2069 24.7682C23.4926 25.0681 23.9674 25.0797 24.2673 24.794C24.5672 24.5083 24.5787 24.0336 24.2931 23.7336L19.7845 19.0005L24.2931 14.2673C24.5787 13.9674 24.5672 13.4926 24.2673 13.2069Z"
			fill="currentColor"
		/>
	</SvgIcon>
);

export default NavigateFirstIcon;
