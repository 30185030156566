import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	chip: {
		backgroundColor: (props) => theme.palette[props.color].light,
		color: (props) => theme.palette[props.color].main,
		borderRadius: 8,
		width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
	},
}));
