import React from 'react';
import { bool, func } from 'prop-types';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Import components
import {
	CopyNo,
	LicenseImageCard,
	ModalTitle,
	LicenseDetailsCardTitle,
	LicenseDetailsCardContent,
} from './components';

// Import translations
import { useTranslations } from 'components/utilities';

export const ElmLicensePreviewModal = ({ isOpen, toggle }) => {
	const { t } = useTranslations();

	return (
		<Dialog open={isOpen} onClose={toggle}>
			<DialogTitle>
				<ModalTitle toggle={toggle} />
			</DialogTitle>

			<PerfectScrollbar>
				<DialogContent>
					<Card>
						<CardHeader
							title={<LicenseDetailsCardTitle />}
							action={<CopyNo />}
						/>
						<Divider />
						<CardContent>
							<LicenseDetailsCardContent />
						</CardContent>
					</Card>
					<LicenseImageCard />
				</DialogContent>
			</PerfectScrollbar>

			<DialogActions>
				<Button onClick={toggle} variant="outlined" color="primary">
					{t('common.buttons.ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

ElmLicensePreviewModal.propTypes = {
	isOpen: bool.isRequired,
	toggle: func.isRequired,
};
