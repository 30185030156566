import React from 'react';
import { object, oneOfType, array, string } from 'prop-types';
import get from 'lodash/get';

// Import components
import PriceSummaryItem from './PriceSummaryItem';

// Import utilities
import { useTranslations } from 'components/utilities';

const DefaultPriceSummaryItem = ({ label, unitAccessor, accessor, values }) => {
	const { t } = useTranslations();

	return (
		<PriceSummaryItem label={t(label)}>
			{get(values, accessor)} {unitAccessor ? get(values, unitAccessor) : ''}
		</PriceSummaryItem>
	);
};

DefaultPriceSummaryItem.propTypes = {
	values: oneOfType([array, object]).isRequired,
	unitAccessor: string,
	accessor: string,
	label: string,
};

export default DefaultPriceSummaryItem;
