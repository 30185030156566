export const OPTIONS = [
    {
        value: 'all',
        label: 'common.all',
        translateLabel: true,
    },
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    },
    {
        value: 5,
        label: 5
    }
]