import React from 'react';
import { Box } from '@material-ui/core';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';

// Import components
import { Toolbar } from 'components/elements';

// Import styles
import { useStyles } from './EventReport.styles';

export const EventReport = () => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Toolbar content={TOOLBAR_CONTENT} />
		</Box>
	);
};
