import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	AdditionalServices,
	PaymentDetails,
	ImageWrapper,
	Details,
	Header,
	Footer,
} from './components';

// Import styles
import { useStyles, pageMargins } from './styles';

// Import helpers
import { BOOKING_DETAILS, CAR_DETAILS, CUSTOMER_DETAILS } from './helpers';

// Import utilities
import { usePrintProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

export const InvoicePrint = React.forwardRef((props, ref) => {
	const { t } = useTranslations();

	const styles = useStyles();

	const { data: booking, isFetched } = usePrintProvider();

	const { options, delivery } = booking || {};

	const conditionalText = delivery?.address
		? t('bookings.invoice_print.booking_details.delivery')
		: t('bookings.invoice_print.booking_details.pickup');

	if (!isFetched) return null;

	return (
		<Grid
			className={styles.container}
			direction="column"
			container
			ref={ref}
			{...props}
		>
			<style>{pageMargins}</style>
			<Header />
			<ImageWrapper />
			<Grid container item xs direction="column" className={styles.content}>
				<Details
					label="bookings.invoice_print.car_details.title"
					rows={CAR_DETAILS(booking)}
				/>
				<Details
					label="bookings.invoice_print.customer_details.title"
					rows={CUSTOMER_DETAILS(booking)}
				/>
				<Details
					label="bookings.invoice_print.booking_details.title"
					rows={BOOKING_DETAILS(booking, conditionalText)}
				/>
				{!!options.length && (
					<AdditionalServices
						label="bookings.invoice_print.additional_service.title"
						options={options}
					/>
				)}
				<PaymentDetails label="bookings.invoice_print.payment_details.title" />
			</Grid>
			<Footer />
		</Grid>
	);
});
