import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import PreviewField from '../../../../../PreviewField';

// Import styles
import { useStyles } from './BottomBar.styles';

export const BottomBar = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const booking = useSelector(previewBookingSelector);

	const totalPaidAmount = booking.item.total_discount_gross_price;

	return (
		<Box className={classes.container}>
			<Divider />
			<PreviewField
				label={t('bookings.preview_booking.invoice.total_paid_amount')}
			>
				{totalPaidAmount}
			</PreviewField>
		</Box>
	);
};
