import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	flexBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	iconDay: {
		marginRight: '7px',
		marginLeft: '7px',
	},
}));
