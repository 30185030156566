import * as yup from 'yup';
import { t } from 'i18next';

const requiredText = t('common.fields.required_field');
const minimumText = (value) => t('common.fields.minimum', { value });

export const validationSchema = yup.object({
	details: yup.object({
		name: yup.object({
			en: yup.string().required(requiredText),
			ar: yup.string().required(requiredText),
		}),
		description: yup.object({
			en: yup.string().required(requiredText),
			ar: yup.string().required(requiredText),
		}),
	}),
	order: yup.number().integer().required(requiredText).min(1, minimumText(1)),
	image: yup.object({
		file_name: yup.string().nullable(),
		file_path: yup.string().required(requiredText),
	}),
	faq_location: yup.array().of(yup.string()).required(requiredText),
	service_type: yup.array().of(yup.string()).required(requiredText),
});
