import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { replaceEditBookingUrl, replacePreviewBookingUrl } from 'helpers';

// Import components
import {
	BookingTopicsTableCell,
	LinkTableCell,
	MarkAsCompensatedTableCell,
	NoteTableCell,
	ActionsTableCell,
	CompensationAmountTableCell,
} from 'components/elements';
import {
	ValidatedByTableCell,
	BankNameIbanTableCell,
	BookingsCountTableCell,
} from '../../components';

// Import styles
import { useStyles } from 'theme/styles';

const {
	BOOKING_ID,
	NOTE,
	CUSTOMER_NAME,
	ACTION,
	TOPIC,
	ACTIONS,
	COMPENSATION_AMOUNT,
	VALIDATED_BY,
	BANK_NAME,
	BOOKINGS,
} = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const classes = useStyles();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case NOTE:
				return (
					<NoteTableCell
						key={cell.column.id}
						cell={cell}
						showAdminNotes={false}
					/>
				);

			case COMPENSATION_AMOUNT:
				return (
					<CompensationAmountTableCell
						key={cell.column.id}
						cell={cell}
						showAdminNotes
					/>
				);

			case BOOKINGS:
				return (
					<BookingsCountTableCell
						key={cell.column.id}
						cell={cell}
						minWidth={150}
					/>
				);

			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(cell.row.original.uuid)}
						subtitle={cell.row.original.company_name}
						minWidth={150}
					>
						{cell.row.original.individual_number}
					</LinkTableCell>
				);

			case VALIDATED_BY:
				return <ValidatedByTableCell key={cell.column.id} cell={cell} />;

			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.customer_name}
						<br /> {cell.row.original.customer_phone}
					</TableCell>
				);

			case BANK_NAME:
				return <BankNameIbanTableCell key={cell.column.id} cell={cell} />;

			case ACTION:
				return (
					<MarkAsCompensatedTableCell
						key={cell.column.id}
						cell={cell}
						showFullFilledCell
					/>
				);

			case ACTIONS:
				return (
					<ActionsTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditBookingUrl(cell.row.original.uuid)}
						canEdit={cell.row.original.can_edit}
						minWidth="150px"
					/>
				);

			case TOPIC:
				return (
					<BookingTopicsTableCell
						key={cell.column.id}
						cell={cell}
						showCancellationReason
						showTopicDetails
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			key={row.id}
			{...row.getRowProps()}
			className={clsx({
				[classes.accountDeleted]: row.original.customer_deleted_at,
			})}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
