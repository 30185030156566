import React from 'react';
import { bool } from 'prop-types';
import { Divider, Grid } from '@material-ui/core';

// Import components
import { BookingStatusCardSection } from '../index';

const BookingStatusCardContainer = ({ isMdOrBigger }) => {
	return (
		<Grid item md={4} container>
			<Grid item md={11}>
				<BookingStatusCardSection />
			</Grid>
			<Grid item md={1}>
				<Divider orientation={isMdOrBigger ? 'vertical' : 'horizontal'} />
			</Grid>
		</Grid>
	);
};

BookingStatusCardContainer.propTypes = {
	isMdOrBigger: bool.isRequired,
};

export default BookingStatusCardContainer;
