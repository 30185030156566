import { EN } from '../../../../../helpers';

export const getTerminals = (array, airport_uuid) => {
    const filteredAirport = array.filter(
        (airport) => airport.uuid === airport_uuid
    );

    if (filteredAirport.length) {
        return filteredAirport[0].terminals;
    }
    return [];
};

export const findTerminalLabel = (allTerminals, lng) => (uuid) => {
    const terminal = allTerminals.find(terminal => terminal.uuid === uuid)
    if (terminal) {
        const terminalName = terminal.name;
        return lng === EN ? terminalName.en : terminalName.ar
    } else {
        return ''
    }
}
