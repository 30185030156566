import { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import utilities
import { assignDriver, changeDriver, fetchBookingPreview } from 'store/actions';
import { useMessage, useTranslations } from 'components/utilities';
import { useDriverUsersListQuery } from 'queries';

// Import helpers
import { getConvertedDelivery } from 'helpers';
import { previewBookingSelector } from 'store/selectors';

export const useDialogForm = ({
	toggle,
	deliveryType,
	externalBookingUuid = null,
	refetchCallback = () => {},
	externalDelivery,
}) => {
	const { id } = useParams();

	const { submitting } = useFormState();

	const { message } = useMessage();

	const { t } = useTranslations();

	const { change } = useForm();

	const dispatch = useDispatch();

	const { delivery, uuid } = useSelector(previewBookingSelector);

	const bookingUuid = externalBookingUuid || id || uuid;

	const {
		data: drivers,
		isError: isDriversError,
		isFetching: isDriversFetching,
	} = useDriverUsersListQuery({
		bookingUuid,
		deliveryType,
	});

	const [isButtonLoading, setIsButtonLoading] = useState(false);

	const isFetching = isDriversFetching;
	const isError = isDriversError;
	const isLoading = isButtonLoading || submitting;

	const convertedDelivery = getConvertedDelivery(
		externalDelivery ? externalDelivery : delivery
	);

	const deliveryData = convertedDelivery.find(
		({ deliveryType: type }) => type === deliveryType
	);

	const {
		assigned,
		driver_uuid,
		delivery_uuid,
		has_driver_assigned: hasDriverAssigned,
	} = deliveryData || {};

	const isDriverAssigned = hasDriverAssigned || assigned;

	const initialValues = isDriverAssigned ? { [deliveryType]: driver_uuid } : {};

	const handleOnSelectChange = async (event) => {
		const { value: driverUuid } = event.target;
		change(deliveryType, driverUuid);
	};

	const handleOnSubmit = async (values) => {
		setIsButtonLoading(true);

		const driverUuid = values[deliveryType];

		try {
			if (!isDriverAssigned) {
				await assignDriver({ driverUuid, bookingUuid, deliveryType });
			} else {
				await changeDriver({ driverUuid, deliveryUuid: delivery_uuid });
			}

			!externalBookingUuid
				? await fetchBookingPreview({ id: bookingUuid })(dispatch)
				: await refetchCallback();

			message.success(t('common.messages.successfully_done'));
			setIsButtonLoading(false);
			toggle();
		} catch {
			message.error(t('common.messages.error_message'));
			setIsButtonLoading(false);
		}
	};

	return {
		handleOnSelectChange,
		handleOnSubmit,
		initialValues,
		drivers,
		isLoading,
		isFetching,
		isError,
	};
};
