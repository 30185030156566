import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import utils and helpers
import {
	replaceEditCorporateCustomerUrl,
	getCoordinatorVerificationLink,
	replacePreviewBookingUrl,
} from 'helpers';
import { useProfile } from 'components/utilities';

// Import store
import { profileDataSelector } from 'store/selectors';

export const useActionsTableCell = (cell) => {
	const { uuid: customerUuid, last_booking: booking } = cell.row.original;
	const { token } = useProfile();
	const history = useHistory();
	const {
		corporate: { uuid: corporateUuid },
	} = useSelector(profileDataSelector);

	const { uuid: bookingUuid } = booking || {};

	const goToEditCorporateCustomerView = () =>
		history.push(replaceEditCorporateCustomerUrl(customerUuid));

	const goToPreviewBookingView = () =>
		history.push(replacePreviewBookingUrl(bookingUuid));

	const coordinatorVerificationLink = getCoordinatorVerificationLink({
		token,
		corporateUuid,
		customerUuid,
	});

	return {
		goToEditCorporateCustomerView,
		goToPreviewBookingView,
		coordinatorVerificationLink,
		customerUuid,
		bookingUuid,
	};
};
