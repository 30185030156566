import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	item: {},
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.AIRPORTS_FORM_FETCH_DATA_LOADING:
				return INITIAL_STATE;

			case types.AIRPORTS_FORM_FETCH_DATA_SUCCESS:
				draft.isLoaded = true;
				draft.item = action.payload.item;
				break;

			case types.AIRPORTS_FORM_FETCH_DATA_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				draft.error = action.payload;

				break;
			default:
				return state;
		}
	});
