import React from 'react';
import clsx from 'clsx';
import { string, object, oneOf, oneOfType, node } from 'prop-types';
import { Alert as MaterialAlert, AlertTitle } from '@material-ui/lab';

//Import styles
import { useStyles } from './Alert.styles';

export const Alert = ({
	title = 'Error',
	type = 'error',
	message,
	className,
}) => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<MaterialAlert severity={type}>
				{title && <AlertTitle>{title}</AlertTitle>}
				{message}
			</MaterialAlert>
		</div>
	);
};

Alert.propTypes = {
	type: oneOf(['error', 'success', 'warning', 'info']),
	title: string,
	message: oneOfType([string, node]),
	className: oneOfType([string, object]),
};
