import React from 'react';
import { Tooltip } from '@material-ui/core';
import { object } from 'prop-types';

// Import utilities
import { useToggle, useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from '../../styles';

// Import components
import { MoneyIcon } from 'assets/icons';
import {
	TransactionHistory,
	TransactionHistoryProvider,
	IconButton,
} from 'components/elements';

const TransactionHistoryPart = ({ cell }) => {
	const classes = useStyles();

	const { on, toggle } = useToggle();

	const { t } = useTranslations();

	return (
		<>
			{on && (
				<TransactionHistoryProvider bookingId={cell.row.original.uuid}>
					<TransactionHistory open={on} onClose={toggle} />
				</TransactionHistoryProvider>
			)}
			<Tooltip title={t('common.icon_names.transaction_history')}>
				<span>
					<IconButton onClick={toggle} className={classes.icon} size="small">
						<MoneyIcon />
					</IconButton>
				</span>
			</Tooltip>
		</>
	);
};

TransactionHistoryPart.propTypes = {
	cell: object.isRequired,
};

export default TransactionHistoryPart;
