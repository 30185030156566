import React from 'react';

// Import components
import { Wrapper } from './components';

// Import utils
import { EditCorporateFormProvider } from './context';

export const EditCorporateForm = () => {
	return (
		<EditCorporateFormProvider>
			<Wrapper />
		</EditCorporateFormProvider>
	);
};
