import i18n from 'i18next';
import { useFormState } from 'react-final-form';

// Import helpers and context
import { EVENT_REPORT_TYPES, FIELD_TYPES } from 'helpers';

export const useFilterFields = () => {
	const {
		DRIVER_ACTION,
		CUSTOMER_CARE_ACTION,
		AIRPORT_ACTION,
		TRAIN_STATION_ACTION,
	} = EVENT_REPORT_TYPES;

	const { values } = useFormState();

	const { userType: isUserTypeSelected } = values || {};

	const options = [
		{
			label: 'common.fields.driver_action',
			value: DRIVER_ACTION,
		},
		{
			label: 'common.fields.customer_care_action',
			value: CUSTOMER_CARE_ACTION,
		},
		{
			label: 'common.fields.airport_action',
			value: AIRPORT_ACTION,
		},
		{
			label: 'common.fields.train_station_action',
			value: TRAIN_STATION_ACTION,
		},
	];

	const REPORT_TYPE_FILTER = [
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.report_type',
			name: 'userType',
			xs: 3,
			md: 3,
			options,
			labelExtractor: ({ label }) => i18n.t(label),
			keyExtractor: ({ value }) => value,
		},
	];

	return { REPORT_TYPE_FILTER, isUserTypeSelected };
};
