import React from 'react';
import { array } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';

// Import utils
import { useCommonFields, useTranslations } from 'components/utilities';
import { useManufacturers, useModelYears, useVehicleModelList } from 'queries';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import helpers
import { PLATE_NUMBER_FIELDS, REMAINING_FIELDS } from './helpers';

export const CarPlate = ({ bodyColors }) => {
	const { values } = useFormState();

	const { t, i18n } = useTranslations();

	const language = i18n.language;

	const { data: manufacturers, makersFetching } = useManufacturers();

	const { data: models, isFetching: modelsFetching } = useVehicleModelList({
		manufacturerUuid: values.vehicle_maker_uuid,
	});

	const { data: years, isFetching: yearsFetching } = useModelYears({
		modelUuid: values.vehicle_model_uuid,
	});

	const isManufacturerSelected = !!values.vehicle_maker_uuid;
	const isModelSelected = !!values.vehicle_model_uuid;

	const { renderFields } = useCommonFields();

	return (
		<Card>
			<CardHeader title={t('cars.car_list.plate_form.car_plate')} />
			<Divider />
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Field
							fullWidth
							component={SelectFieldAdapter}
							type="select"
							label={t('common.cars.headers.maker')}
							name="vehicle_maker_uuid"
							margin="dense"
							variant="outlined"
							disabled={makersFetching}
							required
						>
							{manufacturers.map(({ name, uuid }) => (
								<MenuItem key={uuid} value={uuid}>
									{name[language]}
								</MenuItem>
							))}
						</Field>
					</Grid>
					<Grid item xs={12}>
						<Field
							fullWidth
							component={SelectFieldAdapter}
							type="select"
							label={t('common.cars.headers.model')}
							name="vehicle_model_uuid"
							margin="dense"
							variant="outlined"
							disabled={modelsFetching || !isManufacturerSelected}
							required
						>
							{models.map(({ model_name, uuid, model_uuid }) => (
								<MenuItem key={uuid} value={model_uuid}>
									{model_name[language]}
								</MenuItem>
							))}
						</Field>
					</Grid>
					<Grid item xs={12}>
						<Field
							fullWidth
							component={SelectFieldAdapter}
							type="select"
							label={t('common.cars.headers.year')}
							name="vehicle_year"
							margin="dense"
							variant="outlined"
							disabled={yearsFetching || !isModelSelected}
							required
						>
							{years.map((year) => (
								<MenuItem key={year} value={year}>
									{year}
								</MenuItem>
							))}
						</Field>
					</Grid>
					<Grid container alignItems="flex-end" spacing={1}>
						{PLATE_NUMBER_FIELDS(values?.plate).map(renderFields)}
						{REMAINING_FIELDS(bodyColors).map(renderFields)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

CarPlate.propTypes = {
	bodyColors: array,
};
