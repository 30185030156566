import React from 'react';
import { object, string } from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import styles
import { useStyles } from './Statistics.styles';

// Import utils
import { useTranslations } from 'components/utilities';

// Import helpers
import { previewBookingSelector } from 'store/selectors';
import { getConvertedDelivery } from 'helpers';

export const Statistics = ({ deliveryType, customDeliveryData }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { delivery } = useSelector(previewBookingSelector);

	const convertedDelivery = getConvertedDelivery(
		customDeliveryData || delivery
	);

	const deliveryData = convertedDelivery.find(
		({ deliveryType: type }) => type === deliveryType
	);

	const { statistics } = deliveryData;

	const convertedStatistics = Object.entries(statistics);

	return (
		<Grid container spacing={10}>
			{convertedStatistics.map(([key, value]) => (
				<Grid item xs={12} md={6} key={key}>
					<Typography className={classes.label}>
						{t(
							`bookings.preview_booking.delivery_information.statistics.${key}`
						)}
					</Typography>
					<Box className={classes.field}>
						<Typography className={classes.fieldValue}>{value}</Typography>
					</Box>
				</Grid>
			))}
		</Grid>
	);
};

Statistics.propTypes = {
	deliveryType: string,
	customDeliveryData: object,
};
