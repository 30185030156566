import React from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { Alert, LoadingOverlay } from 'components/elements';
import { SettlementsHistoryBodyRow } from '../SettlementsHistoryBodyRow';
import { useSettlementHistory } from '../../context';

// Import helpers
import { columns } from '../../columns';

// Import styles
import { useStyles } from '../../styles';

export const SettlementsHistory = ({ open, onClose }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { isFetching, isError, error } = useSettlementHistory();

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg">
			<Box position="relative">
				<DialogTitle className={classes.title}>
					{t('rewards_settlement.history.title')}
				</DialogTitle>

				<LoadingOverlay isLoading={isFetching} />
				{isError && <Alert message={error} />}

				<TableContainer>
					<Table>
						<TableHead className={classes.tableHead}>
							<TableRow>
								{columns.map(({ header, accessor }) => (
									<TableCell key={accessor}>
										{header ? t(header) : ''}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody className={classes.tableBody}>
							<SettlementsHistoryBodyRow />
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Dialog>
	);
};

SettlementsHistory.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};
