import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useFormState } from 'react-final-form';

// Import helpers
import { checkIsLocationOutsideArea, geocodeCoords } from '../../helpers';

const useMap = ({ location, onChange }) => {
	const {
		values: { supplier },
	} = useFormState();

	const markerRef = useRef();

	const [markerPosition, setMarkerPosition] = useState(location);

	const mapCenter = location;

	const draggableArea = {
		radius: supplier.max_delivery_radius * 1000,
		center: supplier.location,
	};

	const adjustMarkerPosition = async () => {
		const lat = markerRef.current.getPosition().lat();
		const lng = markerRef.current.getPosition().lng();

		const isOutside = checkIsLocationOutsideArea(
			{
				lat,
				lng,
			},
			draggableArea
		);

		if (isOutside) {
			// Reset position
			markerRef.current.setPosition(markerPosition);
		} else {
			// Set new position
			setMarkerPosition({ lat, lng });
		}
	};

	const handleMarkerLoad = (marker) => {
		markerRef.current = marker;
	};

	const handleMarkerDragEndDebounced = useDebouncedCallback(async () => {
		const newLocation = await geocodeCoords(markerPosition);

		onChange(newLocation);
	}, 500);

	const handleMarkerDragEnd = async () => {
		await adjustMarkerPosition();

		handleMarkerDragEndDebounced();
	};

	return {
		mapCenter,
		markerPosition,
		handleMarkerLoad,
		handleMarkerDragEnd,
		draggableArea,
	};
};

export default useMap;
