import React, { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { node } from 'prop-types';

// Import utils
import { useEContract } from 'components/utilities';
import {
	useInitiateContractInitialValues,
	useCreateContractFormOptions,
	useContractBranches,
} from 'queries';

export const SuspendContractContext = createContext(null);

export const SuspendContractProvider = ({ children }) => {
	const { id } = useParams();

	const {
		data: formOptions,
		isFetching: formOptionsFetching,
		isError: formOptionsError,
	} = useCreateContractFormOptions();

	const {
		data: branches,
		isFetching: branchesFetching,
		isError: branchesError,
	} = useContractBranches();

	const {
		data: initialValues,
		isFetching: initialValuesFetching,
		isError: initialValuesError,
	} = useInitiateContractInitialValues(id);

	const { contract_number: contractNumber, vehicle_plate_uuid: plateUuid } =
		initialValues || {};

	const {
		isSuspendContractButtonVisible,
		contractDataFetching,
		contractDataError,
		bookingFetching,
		bookingError,
	} = useEContract({ contractNumber });

	const isFetching =
		initialValuesFetching ||
		contractDataFetching ||
		formOptionsFetching ||
		bookingFetching ||
		branchesFetching;

	const isError =
		initialValuesError ||
		contractDataError ||
		formOptionsError ||
		bookingError ||
		branchesError;

	const convertInitialValues = (values) => ({
		...values,
		rent_status: {
			...values.rent_status,
			sketch_info: [],
		},
	});

	const convertedInitialValues = convertInitialValues(initialValues);

	return (
		<SuspendContractContext.Provider
			value={{
				allowSuspendContract: isSuspendContractButtonVisible,
				initialValues: convertedInitialValues,
				contractNumber,
				formOptions,
				plateUuid,
				branches,
				isFetching,
				isError,
			}}
		>
			{children}
		</SuspendContractContext.Provider>
	);
};

SuspendContractProvider.propTypes = {
	children: node,
};
