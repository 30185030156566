import React from 'react';
import { bool } from 'prop-types';
import { Box } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress, LoadingWrapper } from 'components/elements';
import { MultiSelect } from '../../components';

// Import styles
import { useStyles } from './FormContent.styles.js';
import { useRentalCompaniesProvider } from '../../context';

export const FormContent = ({ isButtonLoading }) => {
	const { isFetching } = useRentalCompaniesProvider();
	const { t } = useTranslations();
	const classes = useStyles();

	if (isFetching) {
		return <LoadingWrapper />;
	}

	return (
		<Box className={classes.container}>
			<MultiSelect />
			<ButtonProgress
				isLoading={isButtonLoading}
				disabled={isButtonLoading}
				variant="contained"
				color="primary"
				size="medium"
				type="submit"
			>
				{t('common.buttons.save')}
			</ButtonProgress>
		</Box>
	);
};

FormContent.propTypes = {
	isButtonLoading: bool,
	isFetching: bool,
};
