export const FAQ_PAGE_LOCATION = {
	HOME: 'home',
	CAR_DETAILS: 'car_details',
};

export const FAQ_SERVICE_TYPE = {
	AIRPORT: 'airport',
	DAILY: 'daily',
	SUBSCRIPTION: 'subscription',
	TRAIN_STATION: 'train_station',
	LEASING: 'leasing',
};
