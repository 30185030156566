import { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { previewBookingSelector } from 'store/selectors/bookings';

// Import helpers
import { VERIFY_TYPES } from 'helpers';

const useLicensePreviewField = () => {
	const [isOpen, toggleModal] = useReducer((state) => !state, false);

	const {
		customer: { license, verify_type },
	} = useSelector(previewBookingSelector);

	const isElmCustomer = verify_type === VERIFY_TYPES.ELM;

	return {
		isElmCustomer,
		license,
		isOpen,
		toggleModal,
	};
};

export default useLicensePreviewField;
