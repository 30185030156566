import { t } from 'i18next';

export const getDiscountLabel = ({ data, isAdmin, discountPrice }) => {
	let label = '';

	const { max_amount: maxAmount, percentage, type, code } = data || {};

	const baseLabelPart = t('bookings.preview_booking.invoice.total_discount');
	const maxDiscountLabel = `${t(
		'bookings.preview_booking.invoice.max_discount'
	)} ${maxAmount} ${t('currency.sar')}`;

	const discountLabelWithAllInfo = `(-${percentage}%, ${type}, "${code}", ${maxDiscountLabel})`;
	const discountLabelWithoutCodeAndType = `(-${percentage}%, ${maxDiscountLabel})`;
	const discountInfoForOthers = `${t(
		'bookings.preview_booking.invoice.pre_paid'
	)}`;

	if (discountPrice) {
		if (isAdmin) {
			if (percentage && type && code) {
				label = baseLabelPart + discountLabelWithAllInfo;
			} else if (percentage && !type && !code) {
				label = baseLabelPart + discountLabelWithoutCodeAndType;
			} else {
				label = baseLabelPart;
			}
		} else {
			label = discountInfoForOthers;
		}
	} else {
		label = baseLabelPart;
	}

	return label;
};
