export const INITIAL_VALUES = {
	image: {
		file_path: '',
		file_name: '',
	},
	details: {
		locale: '',
		name: '',
		title: '',
		position: '',
		company_uuid: [],
	},
};
