import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// Import components
import { AssignUrgentBookingForm } from 'components/elements';
import UrgentReasonsHistoryDialog from '../UrgentReasonsHistoryDialog';

// Import translations
import { useTranslations } from 'components/utilities';

// Import utilities
import useUrgentStatusAssign from './useUrgentStatusAssign';

const UrgentStatusAssign = () => {
	const { t } = useTranslations();

	const {
		isMaxUrgentReasonsSize,
		isSomeReviewStatus,
		isSomeUrgentReason,
		toggleFormVisible,
		isFormVisible,
	} = useUrgentStatusAssign();

	return (
		<Grid container spacing={3} direction="column">
			<Grid container item alignItems="center" justifyContent="space-between">
				<Grid item xs={11}>
					<Typography variant="h5">
						{t('bookings.assign_urgent_form.title')}
					</Typography>
				</Grid>
				{isSomeUrgentReason && (
					<Grid item xs={1}>
						<UrgentReasonsHistoryDialog />
					</Grid>
				)}
			</Grid>
			{!isMaxUrgentReasonsSize && (
				<Grid item>
					{isFormVisible ? (
						<AssignUrgentBookingForm onCancel={toggleFormVisible} />
					) : (
						<Button
							onClick={toggleFormVisible}
							color="primary"
							variant="outlined"
							startIcon={<AddIcon />}
							disabled={isSomeReviewStatus}
						>
							{t('bookings.assign_urgent_form.assign_button')}
						</Button>
					)}
				</Grid>
			)}
		</Grid>
	);
};

export default UrgentStatusAssign;
