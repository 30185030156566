import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import assets
import { BrandNameIcon } from 'assets/icons';

// Import styles
import { useStyles } from './SignInSideBanner.styles';

export const SignInSideBanner = () => {
	const classes = useStyles();

	return (
		<Grid className={classes.container} item lg={5}>
			<Box className={classes.quote}>
				<Box className={classes.layer} />
				<BrandNameIcon className={classes.logo} />
			</Box>
		</Grid>
	);
};
