export const COLUMNS_ACCESSORS = {
	STATUS: 'status',
	BOOKING_ID: 'booking_id',
	SETTLE_CHECKBOX: 'settle_checkbox',
	CUSTOMER_NAME: 'customer_name',
};

export const columns = [
	{
		Header: '',
		accessor: COLUMNS_ACCESSORS.SETTLE_CHECKBOX,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.amount',
		accessor: 'used_amount_gross',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.created_at',
		accessor: 'created_at',
		disableSortBy: true,
	},
];
