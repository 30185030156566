import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'inline-block',
		position: 'relative',
		width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
	},
	progress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
	},
	indeterminate: {
		animation: 'none',
	},
}));
