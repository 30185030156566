import { get } from 'lodash';

// Import helpers
import { CUSTOMER_WALLET_REFERENCE_IDENTIFIER } from 'helpers';

const { CUSTOMER_REGISTERED, BOOKING_CASHBACK } =
	CUSTOMER_WALLET_REFERENCE_IDENTIFIER;

export const useHistoryTable = ({ row, column }) => {
	const { reference_identifier, label, reason } = row || {};

	const isRegistration = reference_identifier === CUSTOMER_REGISTERED;
	const isCashback = reference_identifier === BOOKING_CASHBACK;
	const cellValue = get(row, column.accessor);

	return {
		description: label,
		cellValue,
		reason,
		isRegistration,
		isCashback,
	};
};
