import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';
import { truncate } from 'lodash';

// Import utils
import useBookingTopicsTableCell from './useBookingTopicsTableCell';

// Import components
import { TopicField } from '../CompensationHistory/components';

// Import styles
import { useStyles } from './styles';

const BookingTopicsTableCell = ({
	cell,
	showCancellationReason = false,
	showTopicDetails = false,
}) => {
	const classes = useStyles();

	const {
		topics,
		cancelReasonName,
		toggleShowFullCancellationReason,
		showFullCancellationReason,
		compensation,
	} = useBookingTopicsTableCell({ cell });

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{topics.map((topic) => (
				<Typography key={topic} className={classes[topic]}>
					{topic}
				</Typography>
			))}
			{showCancellationReason && (
				<Typography
					className={classes.cancelReason}
					onClick={toggleShowFullCancellationReason}
				>
					{showFullCancellationReason
						? cancelReasonName
						: truncate(cancelReasonName, { length: 23 })}
				</Typography>
			)}
			{showTopicDetails && (
				<TopicField
					type={compensation.type}
					hideTitle
					removeCancelState
					className={classes.topicDetails}
				/>
			)}
		</TableCell>
	);
};

BookingTopicsTableCell.propTypes = {
	cell: object.isRequired,
	showCancellationReason: bool,
	showTopicDetails: bool,
};

export default BookingTopicsTableCell;
