import React from 'react';
import { Form } from 'react-final-form';
import { Box, Dialog, DialogContent } from '@material-ui/core';
import { bool, func, string } from 'prop-types';

// Import components
import ClosableDialogTitle from '../ClosableDialogTitle';
import { FormContent } from './components';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './AssignDriverDialog.styles';
import { useAssignDriverDialog } from './useAssignDriverDialog';

export const AssignDriverDialog = ({ open, toggle, bookingUuid }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { handleOnSubmit, isLoading } = useAssignDriverDialog({
		bookingUuid,
		toggle,
	});

	return (
		<Dialog onClose={toggle} open={open} fullWidth>
			<ClosableDialogTitle
				onClose={toggle}
				title={t('bookings.preview_booking.car.assign_driver')}
			/>
			<DialogContent className={classes.dialogContent}>
				<Form
					onSubmit={handleOnSubmit}
					render={({ handleSubmit }) => (
						<Box component="form" onSubmit={handleSubmit}>
							<FormContent
								bookingUuid={bookingUuid}
								isLoading={isLoading}
								open={open}
							/>
						</Box>
					)}
				></Form>
			</DialogContent>
		</Dialog>
	);
};

AssignDriverDialog.propTypes = {
	bookingUuid: string,
	toggle: func,
	open: bool,
};
