import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	BaseSelectFilter,
	CompanyFilter,
	DateRangeFilter,
} from 'components/elements';

// Import helpers
import { EMPLOYEE_ACTIVE_STATUS, EMPLOYEE_SETTLEMENT_STATUS } from './helpers';
import { ROLE_FILTER_OPTIONS } from 'helpers';

export const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<CompanyFilter name="company_uuid" label="bookings.filters.company" />

			<BaseSelectFilter
				label="employee_rewarding.table.filters.role"
				name="user_role"
				options={ROLE_FILTER_OPTIONS}
			/>

			<BaseSelectFilter
				label="rewards_settlement.filters.employee_status"
				name="user_is_active"
				options={EMPLOYEE_ACTIVE_STATUS}
			/>

			<BaseSelectFilter
				label="rewards_settlement.filters.settlement"
				name="is_settled"
				options={EMPLOYEE_SETTLEMENT_STATUS}
			/>

			<DateRangeFilter
				nameFrom="pickup_date"
				nameUntil="pickup_date_to"
				label="rewards_settlement.filters.pickup_date_from_to"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="reward_date"
				nameUntil="reward_date_to"
				label="rewards_settlement.filters.reward_date_from_to"
				combinedInputs
			/>
		</Grid>
	);
};
