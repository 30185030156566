import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	appBar: {
		backgroundColor: 'inherit',
		color: 'inherit',
		boxShadow: 'none',
	},
}));

export default useStyles;
