import React from 'react';
import { bool, func } from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';

// Import views
import { CarList } from 'views/Cars';

// Import utils
import { useSearchPlateDialog } from './useSearchPlateDialog';

// Import styles
import { useStyles } from './SearchPlateDialog.styles';

export const SearchPlateDialog = ({ isOpen, toggle }) => {
	const classes = useStyles();
	const { onSelectPlate } = useSearchPlateDialog({ toggle });

	return (
		<Dialog onClose={toggle} open={isOpen} className={classes.dialog} fullWidth>
			<DialogContent>
				<CarList onSelectPlate={onSelectPlate} />
			</DialogContent>
		</Dialog>
	);
};

SearchPlateDialog.propTypes = {
	isOpen: bool,
	toggle: func,
};

SearchPlateDialog.defaultProps = {
	isOpen: false,
	toggle: () => {},
};
