import { useQuery } from 'react-query';
import { CAMPAIGN_TYPES } from 'helpers';

import { CAMPAIGN_BOOKINGS_QUERY } from 'helpers';
import services from 'services/services';

export const useCampaignCustomers = ({ campaignUuid, campaignType }) => {
	const path = `v2/customer/wallet-campaign/${campaignUuid}/customers`;

	const { data, isFetching, isError } = useQuery(
		[CAMPAIGN_BOOKINGS_QUERY, campaignUuid],
		async () => await services.post(path),
		{
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000,
			enabled: campaignType === CAMPAIGN_TYPES.CUSTOMER_REGISTERED,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
