import { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import helpers
import { REASON_DETAILS_ASSESSORS } from './helpers';

const useReasonName = () => {
	const { USE_FOR_MOBILE, NAME_MOBILE_EN, NAME_MOBILE_AR } =
		REASON_DETAILS_ASSESSORS;
	const { submitting, values } = useFormState();
	const {
		use_for_mobile: useForMobile,
		details: { name },
	} = values;
	const { change } = useForm();
	const [isMobileVisible, setIsMobileVisible] = useState(useForMobile);

	const handleOnChange = (event) => {
		const { checked } = event.target;
		change(USE_FOR_MOBILE, checked);
		setIsMobileVisible(checked);
		if (checked) {
			change(NAME_MOBILE_EN, name.en);
			change(NAME_MOBILE_AR, name.ar);
		}
	};

	return {
		submitting,
		isMobileVisible,
		onChange: handleOnChange,
	};
};

export default useReasonName;
