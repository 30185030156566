// Import utils
import { useDeliveryLocationProvider } from '../../../../context';

export const useLocationContainer = ({ deliveryType, customStepsData }) => {
	const data = useDeliveryLocationProvider();

	const deliveryData = customStepsData ? customStepsData : data[deliveryType];

	const { isFetching, isFetched, isError, states } = deliveryData || {};

	return {
		deliveryData,
		isFetching,
		isFetched,
		isError,
		states,
	};
};
