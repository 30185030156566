import _ from 'lodash';

const findLink = (obj, query) => {
	for (const key in obj) {
		if (obj[key] && _.isObject(obj[key])) {
			const result = findLink(obj[key], query);
			if (result) {
				result.unshift(key);
				return result;
			}
		} else if (query.includes(obj[key]?.slice(0, -1))) {
			return [key];
		}
	}
};

const cleanupNavLinks = (obj) =>
	_.transform(obj, (result, value, key) => {
		if (value.collapsed) {
			result[key] = {
				..._.pick(value, ['title']),
				collapsed: cleanupNavLinks(value.collapsed),
			};
		} else {
			result[key] = _.pick(value, ['title', 'href', 'collapsed']);
		}
	});

const getTitlesByPath = (nav, path) => {
	let currentPathList = _.initial(path);
	const depth = path.length / 2;

	let result = [];
	for (let i = 0; i < depth; i++) {
		result.unshift(_.get(nav, `${currentPathList.join('.')}.title`));
		currentPathList = _.take(currentPathList, currentPathList.length - 2);
	}

	return result;
};

export const findTitles = (nav, query) => {
	const cleanedNav = cleanupNavLinks(nav);

	const path = findLink(cleanedNav, query);

	if (!path) {
		return [];
	}

	return getTitlesByPath(nav, path);
};

export const getPromoCodeSectionName = (fullLocation) => {
	const [, base, id] = fullLocation.split('/') || [];
	return base === 'promoCodes' && !!id ? id : null;
};
