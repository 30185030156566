import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import { replaceCorporateBookingsUrl } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { LinkTableCell } from 'components/elements';
import { ActionTableCell } from '../../components';

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const replacedUrl = replaceCorporateBookingsUrl(cell.row.original.uuid);

		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.CORPORATE_NAME:
				return (
					<LinkTableCell key={cell.column.id} cell={cell} to={replacedUrl}>
						{cell.row.original.name}
					</LinkTableCell>
				);

			case COLUMNS_ACCESSORS.ACTION:
				return <ActionTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
