import React, { forwardRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { string, element, oneOfType, arrayOf } from 'prop-types';

// Import styles
import { useStyles } from './styles';

// eslint-disable-next-line react/display-name
const ScrollbarForTheme = forwardRef(({ children, ...props }, ref) => {
	const classes = useStyles();

	return (
		<PerfectScrollbar
			{...props}
			ref={ref}
			className={clsx([props.className, classes.container])}
		>
			{children}
		</PerfectScrollbar>
	);
});

ScrollbarForTheme.propTypes = {
	children: oneOfType([element, arrayOf(element)]),
	className: string,
};

export default ScrollbarForTheme;
