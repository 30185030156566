import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	label: ({ error }) => ({
		color: error ? theme.palette.error.main : theme.palette.gray.label,
		fontWeight: 500,
	}),
	error: {
		color: theme.palette.error.main,
		fontWeight: 400,
		fontSize: '11px',
		marginLeft: '14px',
	},
}));
