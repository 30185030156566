/* eslint-disable no-mixed-spaces-and-tabs */

import * as yup from 'yup';
import { t } from 'i18next';

const requiredText = t('common.fields.required_field');

export const validationSchema = yup.object({
	holidays: yup.array().of(
		yup.object({
			name: yup.string(),
			selected: yup.boolean(),
			date_from: yup
				.string()
				.nullable()
				.when('selected', {
					is: true,
					then: yup.string().nullable().required(requiredText),
					otherwise: yup.string().nullable().notRequired(),
				}),
			date_to: yup
				.string()
				.nullable()
				.when('selected', {
					is: true,
					then: yup.string().nullable().required(requiredText),
					otherwise: yup.string().nullable().notRequired(),
				}),
		})
	),
});
