// Import helpers
import { BOOKING_STATES } from 'helpers';

import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	text: {
		fontWeight: 500,
		whiteSpace: 'noWrap',
	},
	[BOOKING_STATES.EARLY_RETURN]: {
		color: theme.palette.info.main,
	},
	[BOOKING_STATES.CANCELED]: {
		color: theme.palette.error.main,
	},
}));
