import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { node, string } from 'prop-types';
import clsx from 'clsx';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

export const Template = ({ label, children, className }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Typography color="primary" className={classes.label}>
				{t(label)}
			</Typography>
			<Box className={clsx(classes.rowsWrapper, className)}>{children}</Box>
		</Box>
	);
};

Template.propTypes = {
	label: string,
	children: node,
	className: string,
};
