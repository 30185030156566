import React from 'react';
import { Switch } from '@material-ui/core';

// Import styles
import { useStyles } from './CustomSwitch.styles';

export const CustomSwitch = (props) => {
	const classes = useStyles();

	return <Switch classes={classes} {...props} />;
};
