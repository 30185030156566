import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Import store
import { previewBookingSelector } from 'store/selectors';
import {
	changeBookingCancelReasonStatus,
	setValidationTableChangedState,
	changeCancelReason,
} from 'store/actions';

// Import utilities and helpers
import { BOOKING_CANCELLATION_STATES } from 'helpers';
import { useCancellationReasons } from 'queries';

export const useValidateCancellationForm = ({ lng }) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isApprove, setIsApprove] = useState(false);

	const {
		uuid,
		cancel_reason: { cancel_reason_uuid },
	} = useSelector(previewBookingSelector);

	const dispatch = useDispatch();

	const { data: cancellationReasons, isFetched: isFetchedCancellationReasons } =
		useCancellationReasons();

	const isFetched = isFetchedCancellationReasons;

	const getReasons = () =>
		cancellationReasons.map(({ uuid, details }) => ({
			uuid,
			name: details.name,
			// eslint-disable-next-line no-mixed-spaces-and-tabs
		}));

	const reasons = getReasons();

	const { APPROVED, REJECTED } = BOOKING_CANCELLATION_STATES;

	const source = axios.CancelToken.source();
	const cancelToken = source.token;

	const approve = () => setIsApprove(true);

	const getReasonName = (cancellationReasonUuid) =>
		reasons.find(({ uuid }) => uuid === cancellationReasonUuid)?.name[lng];

	const handleValidateCancellation = async (values) => {
		setIsLoading(true);

		try {
			const status = isApprove ? APPROVED : REJECTED;
			const { cancellationReason: cancellationReasonUuid } = values;

			const body = {
				cancel_reason: {
					uuid: cancellationReasonUuid,
					description: getReasonName(cancellationReasonUuid),
				},
			};

			await changeCancelReason({ uuid, body });
			await changeBookingCancelReasonStatus({
				uuid,
				status,
				cancelToken,
				isCancellation: true,
			});

			setIsLoading(false);
			setIsSubmitted(true);
			setValidationTableChangedState({ isTableChanged: true, dispatch });
		} catch (e) {
			setIsLoading(false);
			setError(e);
			setIsError(true);
		}
	};

	return {
		handleValidateCancellation,
		reasons,
		cancel_reason_uuid,
		isFetched,
		isSubmitted,
		isLoading,
		isError,
		approve,
		error,
	};
};
