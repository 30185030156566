import React from 'react';
import { object, string } from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';

// Import helpers
import { DEFAULT_SLASH_DATE_FORMAT } from 'helpers';

// Import styles
import { useStyles } from './DateAndTimePickerFieldAdapter.styles';

// Import assets
import { CalendarIcon } from 'assets/icons';

// Import components
import { InputError } from 'components/elements/atoms';

export const DateAndTimePickerFieldAdapter = ({
	input,
	meta,
	pickerFormat = DEFAULT_SLASH_DATE_FORMAT,
	...rest
}) => {
	const classes = useStyles();

	const convertInputDate = (value) => {
		const [day, month, year] = value.split('/');

		return new Date(year, month - 1, day);
	};

	const iconStyles = rest.disabled ? classes.disabledIcon : classes.icon;

	const date =
		typeof input.value === 'string' && input.value.includes('/')
			? convertInputDate(input.value)
			: input.value;

	const isError = !!(meta.touched && meta.error);

	return (
		<>
			<DateTimePicker
				{...input}
				{...rest}
				className={classes.picker}
				value={date || null}
				format={pickerFormat}
				error={isError}
				ampm={false}
				InputProps={{
					endAdornment: (
						<IconButton size="small">
							<CalendarIcon className={iconStyles} />
						</IconButton>
					),
				}}
			/>
			<InputError meta={meta} />
		</>
	);
};

DateAndTimePickerFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	pickerFormat: string,
};
