import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { node } from 'prop-types';

// Import utils and helpers
import { CLICKED_BUTTON_NAME, DIALOG_STEPS } from '../helpers';
import { useProfile, useToggle } from 'components/utilities';
import { getCoordinatorVerificationLink } from 'helpers';

// Import store
import { profileDataSelector } from 'store/selectors';

export const AddCorporateCustomerDialogContext = createContext(null);

export const AddCorporateCustomerDialogProvider = ({ children }) => {
	const { corporate } = useSelector(profileDataSelector);
	const { on: isDialogOpen, toggle } = useToggle();
	const { token } = useProfile();

	const { uuid: corporateUuid } = corporate || {};

	const [step, setStep] = useState(DIALOG_STEPS.CONFIRMATION);
	const [initialValues, setInitialValues] = useState({});
	const [isSaveAndBookCarButtonLoading, setSaveAndBookCarButtonLoading] =
		useState(false);
	const [
		isSaveAndAddCustomerButtonLoading,
		setSaveAndAddCustomerButtonLoading,
	] = useState(false);
	const [customerUuid, setCustomerUuid] = useState('');
	const [clickedButtonName, setClickedButtonName] = useState('');

	const toggleDialog = () => {
		toggle();
		setStep(DIALOG_STEPS.CONFIRMATION);
		if (!isDialogOpen) {
			setCustomerUuid('');
			setClickedButtonName('');
		}
	};

	useEffect(() => {
		if (
			customerUuid &&
			clickedButtonName === CLICKED_BUTTON_NAME.ADD_CUSTOMER_AND_BOOK_CAR
		) {
			const verificationLink = getCoordinatorVerificationLink({
				customerUuid,
				corporateUuid,
				token,
			});
			window.open(verificationLink, '_blank');
		}
		// eslint-disable-next-line
	}, [customerUuid]);

	return (
		<AddCorporateCustomerDialogContext.Provider
			value={{
				setSaveAndAddCustomerButtonLoading,
				setSaveAndBookCarButtonLoading,
				setClickedButtonName,
				setInitialValues,
				setCustomerUuid,
				toggleDialog,
				setStep,
				isSaveAndAddCustomerButtonLoading,
				isSaveAndBookCarButtonLoading,
				isDialogOpen,
				initialValues,
				customerUuid,
				step,
			}}
		>
			{children}
		</AddCorporateCustomerDialogContext.Provider>
	);
};

AddCorporateCustomerDialogProvider.propTypes = {
	children: node,
};
