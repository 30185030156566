import React from 'react';
import { Form } from 'react-final-form';
import { Button, Dialog, DialogContent } from '@material-ui/core';

// Import utils and helpers
import { useUnsettledLimitsProvider } from '../../context';
import { useTranslations } from 'components/utilities';
import { INITIAL_VALUES } from './helpers';
import { yupValidator } from 'helpers';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';
import { validationSchema } from './validation';
import { useAddNewSettlementDialog } from './useAddNewSettlementDialog';
import { useTableContextProvider } from 'components/context';

export const AddNewSettlementDialog = () => {
	const { handleOnSubmit } = useAddNewSettlementDialog();
	const { data: tableData } = useTableContextProvider();
	const { isDialogOpen, toggleDialog, settledUuids } =
		useUnsettledLimitsProvider();
	const { t } = useTranslations();

	const isButtonDisabled = !tableData.length || !settledUuids.length;

	return (
		<>
			<Dialog onClose={toggleDialog} open={isDialogOpen} fullWidth>
				<ClosableDialogTitle
					onClose={toggleDialog}
					title={t('common.buttons.add_settlement')}
				/>
				<DialogContent>
					<Form
						onSubmit={handleOnSubmit}
						initialValues={INITIAL_VALUES}
						validate={(values) => yupValidator({ values, validationSchema })}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<FormContent toggle={toggleDialog} />
							</form>
						)}
					/>
				</DialogContent>
			</Dialog>
			<Button
				disabled={isButtonDisabled}
				onClick={toggleDialog}
				variant="contained"
				color="primary"
			>
				{t('common.buttons.add_settlement')}
			</Button>
		</>
	);
};
