import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		gridGap: 10,
		paddingRight: 20,
		color: theme.palette.text.primary,
	},
	timeElapsed: {
		fontWeight: 'bolder',
	},
}));
