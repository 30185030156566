import React, { useState } from 'react';
import { func, string, node, oneOfType, arrayOf } from 'prop-types';
import { Popover, Grid, Typography, Button, Box } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

// Import components
import { ButtonProgress } from 'components/elements';

const PopConfirm = ({
	title = 'common.pop_confirm.questions.default',
	confirmButtonTitle = 'common.yes',
	cancelButtonTitle = 'common.no',
	onConfirm,
	children,
}) => {
	const [isLoading, setLoading] = useState(false);
	const classes = useStyles();

	const { t } = useTranslations();

	const handleOnConfirm = async (popupState) => {
		setLoading(true);
		try {
			await onConfirm();
			setLoading(false);
			bindPopover(popupState).onClose();
		} catch {
			setLoading(false);
		}
	};

	return (
		<PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<Box>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					>
						<Grid container className={classes.container}>
							<Grid container item xs={12} justifyContent="center">
								<Grid item>
									<Typography className={classes.title}>{t(title)}</Typography>
								</Grid>
							</Grid>
							<Grid container item xs={12} justifyContent="center" spacing={1}>
								<Grid item>
									<Button
										onClick={() => bindPopover(popupState).onClose()}
										variant="outlined"
										color="primary"
										size="small"
									>
										{t(cancelButtonTitle)}
									</Button>
								</Grid>
								<Grid item>
									<ButtonProgress
										onClick={() => handleOnConfirm(popupState)}
										circularProgressSize={28}
										isLoading={isLoading}
										disabled={isLoading}
										variant="contained"
										color="primary"
										size="small"
									>
										{t(confirmButtonTitle)}
									</ButtonProgress>
								</Grid>
							</Grid>
						</Grid>
					</Popover>
					<div {...bindTrigger(popupState)}>{children}</div>
				</Box>
			)}
		</PopupState>
	);
};

PopConfirm.propTypes = {
	confirmButtonTitle: string,
	cancelButtonTitle: string,
	onConfirm: func.isRequired,
	title: string,
	children: oneOfType([arrayOf(node), node]).isRequired,
};

export default PopConfirm;
