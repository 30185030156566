import React from 'react';
import { object, string } from 'prop-types';
import { Tooltip } from '@material-ui/core';

// Import utilities
import { useToggle, useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

// Import components
import {
	BookingHistory,
	BookingHistoryProvider,
	IconButton,
} from 'components/elements';
import { HistoryIcon } from 'assets/icons';

export const BookingHistoryActionButton = ({ cell, bookingUuid = '' }) => {
	const classes = useStyles();

	const { on, toggle } = useToggle();

	const { t } = useTranslations();

	const bookingId = bookingUuid || cell.row.original.uuid;

	return (
		<>
			{on && (
				<BookingHistoryProvider bookingId={bookingId}>
					<BookingHistory open={on} onClose={toggle} />
				</BookingHistoryProvider>
			)}
			<Tooltip title={t('common.icon_names.booking_history')}>
				<span>
					<IconButton onClick={toggle} className={classes.icon} size="small">
						<HistoryIcon />
					</IconButton>
				</span>
			</Tooltip>
		</>
	);
};

BookingHistoryActionButton.propTypes = {
	cell: object,
	bookingUuid: string,
};
