import React from 'react';
import { CardContent } from '@material-ui/core';

// Import components
import { Content } from './components';
import CardHeader from '../CardHeader';
import Card from '../Card';

// Import utilities
import { useTranslations } from 'components/utilities';

export const ServiceTypeChart = () => {
	const { t } = useTranslations();

	return (
		<Card>
			<CardHeader title={t('dashboard.service_type.title')} />
			<CardContent>
				<Content />
			</CardContent>
		</Card>
	);
};
