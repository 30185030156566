import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { PAGE_SLUG_QUERY } from 'helpers';

const usePageSlug = ({lng}) => {
	const path = `/v2/page/${lng}/slug`;

	const { data, isFetching } = useQuery(
		PAGE_SLUG_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || '';

	return { data: fetchedData, isFetching };
};

export default usePageSlug;
