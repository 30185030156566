import React from 'react';
import { number } from 'prop-types';
import { Typography } from '@material-ui/core';

// Import utilities
import useTotalAmountDifference from './useTotalAmountDifference';

const TotalAmountDifference = ({ difference }) => {
	const { color, text } = useTotalAmountDifference({ difference });

	return (
		<Typography component="span" color={color}>
			{text}
		</Typography>
	);
};

TotalAmountDifference.propTypes = {
	difference: number.isRequired,
};

export default TotalAmountDifference;
