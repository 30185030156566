import * as types from './types';

export const setLoading = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SUB_LOADING, payload });
};

export const setOptions = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SUB_OPTIONS, payload });
};

export const setTableData = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SUB_TABLE_DATA, payload });
};

export const setInputColumns = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SUB_INPUT_COLUMNS, payload });
};

export const updateInputColumns = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SUB_TABLE_COLUMNS, payload });
};

export const setFilters = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SUB_FILTERS, payload });
};

export const setError = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SUB_ERROR, payload });
};
