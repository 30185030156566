import React from 'react';
import { object } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

// Import helpers
import { BOOKING_STATES } from 'helpers';

// Import styles
import { useStyles } from './OfficeNameTableCell.styles';

const { ACCEPTED, CANCELED, REJECTED } = BOOKING_STATES;

export const OfficeNameTableCell = ({ cell }) => {
	const classes = useStyles();

	const {
		blamable_full_name: blamableFullName,
		blamable_rating: blamableRating,
		office_name: officeName,
		state,
	} = cell.row.original;

	const requiredStates = [ACCEPTED, CANCELED, REJECTED];

	const showAgentName = requiredStates.includes(state);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container direction="column">
				<Grid item>
					<Typography variant="h6">{officeName}</Typography>
				</Grid>
				{showAgentName && (
					<Grid item className={classes.box}>
						<Typography>{blamableFullName}</Typography>
						{blamableRating && (
							<>
								<StarIcon className={classes.star} />
								<Typography variant="h5">{blamableRating}</Typography>
							</>
						)}
					</Grid>
				)}
			</Grid>
		</TableCell>
	);
};

OfficeNameTableCell.propTypes = {
	cell: object,
};
