import React from 'react';
import { Button, Dialog } from '@material-ui/core';

// Import components
import { ClosableDialogTitle, LoadingWrapper } from 'components/elements';
import { DialogContent } from './components';

// Import utils
import { useTranslations, useToggle } from 'components/utilities';
import { useCarImagesDialog } from './useCarImagesDialog';

export const CarImagesDialog = () => {
	const { isButtonVisible, isFetching, isError, transKey, data } =
		useCarImagesDialog();
	const { on: isOpen, toggle } = useToggle();
	const { t } = useTranslations();

	if (isFetching) return <LoadingWrapper size={20} />;
	if (isError || !isButtonVisible) return null;

	return (
		<>
			<Button color="primary" onClick={toggle}>
				{t(`${transKey}.view_car_images`)}
			</Button>
			<Dialog fullWidth open={isOpen} onClose={toggle} maxWidth="md">
				<ClosableDialogTitle
					onClose={toggle}
					title={t(`${transKey}.car_images`)}
				/>
				<DialogContent data={data} />
			</Dialog>
		</>
	);
};
