import React from 'react';
import { func, bool, array } from 'prop-types';
import {
	Button,
	DialogActions,
	DialogContent,
	Grid,
	MenuItem,
	Typography,
} from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import {
	SelectFieldAdapter,
	Alert,
	ButtonProgress,
	FileFieldAdapter,
} from 'components/elements';

// Import styles
import { useStyles } from './FormContent.styles';

// Import helpers
import { isSpecificCancelReason } from 'helpers';

export const FormContent = ({
	cancellationReasons,
	isLoading,
	onClose,
	error,
}) => {
	const { t, i18n } = useTranslations();

	const { submitting, values } = useFormState();

	const classes = useStyles();

	const showAttachment = isSpecificCancelReason(
		cancellationReasons,
		values.cancellationReasonId
	);

	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					{error.length > 0 && (
						<Grid item xs={12}>
							<Alert message={error[0][0]} />
						</Grid>
					)}
					<Grid item xs={12}>
						<Field
							fullWidth
							component={SelectFieldAdapter}
							label={t(
								'bookings.reject_booking_form.label.cancellation_reason'
							)}
							name="cancellationReasonId"
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required
						>
							{cancellationReasons.map(({ uuid, details: { name } }) => (
								<MenuItem key={uuid} value={uuid}>
									{name[i18n.language]}
								</MenuItem>
							))}
						</Field>
					</Grid>
					{showAttachment && (
						<Grid item xs={12}>
							<Typography className={classes.label}>Image</Typography>
							<Field
								previewFileState="attachment"
								component={FileFieldAdapter}
								name="attachment.file_path"
								fileNameField="attachment.file_name"
								disabled={submitting}
								uploadButtonWidth={12}
							/>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={isLoading} onClick={onClose} color="primary">
					{t('common.buttons.cancel')}
				</Button>
				<ButtonProgress
					color="primary"
					type="submit"
					isLoading={submitting}
					disabled={submitting}
				>
					{t('common.buttons.submit')}
				</ButtonProgress>
			</DialogActions>
		</>
	);
};

FormContent.propTypes = {
	cancellationReasons: array,
	isLoading: bool,
	onClose: func,
	error: array,
};
