import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	button: {
		width: '100%',
	},
	editIcon: {
		marginRight: 11,
	},
}));

export default useStyles;
