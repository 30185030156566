import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState, useForm } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';
import {
	CircularProgress,
	CardContent,
	CardHeader,
	MenuItem,
	Divider,
	Grid,
	Card,
	Box,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useAirportList } from 'queries';
import { EN } from 'helpers';

const Airport = ({ section, ...rest }) => {
	const { submitting, values } = useFormState();
	const { change } = useForm();
	const { kioskAirportId } = values;
	const [airportUuid, setAirportUuid] = useState(kioskAirportId);
	const {
		data: airports,
		isFetching: isAirportsLoading,
		isError: isAirportsError,
	} = useAirportList();

	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	const isAirportsLoaded = !isAirportsLoading && !isAirportsError;

	const handleChange = (e) => {
		setAirportUuid(e.target.value);
		change('airport.terminals', []);
	};

	return (
		<Card {...rest}>
			<CardHeader title={t('kiosk.management.form.airport.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						{isAirportsLoaded ? (
							<Field
								fullWidth
								component={SelectFieldAdapter}
								type="select"
								label={t('kiosk.management.form.airport.title')}
								name={`${section}_uuid`}
								margin="dense"
								variant="outlined"
								disabled={submitting}
								onChange={handleChange}
								defaultValue={airportUuid}
							>
								{airports.map(({ uuid, name: { en, ar } }) => (
									<MenuItem key={uuid} value={uuid}>
										{lng === EN ? en : ar}
									</MenuItem>
								))}
							</Field>
						) : (
							<Box>
								<CircularProgress size={35} />
							</Box>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

Airport.propTypes = {
	section: PropTypes.string.isRequired,
};

export default Airport;
