import { useDispatch, useSelector } from 'react-redux';

// Import utilities
import { fetchBookingPreview } from 'store/actions';

// Import helpers
import { previewBookingSelector } from 'store/selectors';
import { BOOKING_EXTENSION_STATUS } from 'helpers';

const useBookingStatusPreviewField = () => {
	const booking = useSelector(previewBookingSelector);

	const dispatch = useDispatch();

	const isExtended =
		booking.extension?.extension_state === BOOKING_EXTENSION_STATUS.ACCEPTED ||
		booking.extension?.extension_state === BOOKING_EXTENSION_STATUS.PENDING;

	const handleOnStatusChange = () => {
		fetchBookingPreview({
			id: booking.uuid,
		})(dispatch);
	};
	return {
		onChange: handleOnStatusChange,
		isExtended,
		booking,
	};
};

export default useBookingStatusPreviewField;
