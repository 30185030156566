import services from 'services/services';
import {
	fetchBusinessCompanyListUrl,
	updateActiveCompanyUrl,
	deleteCompanyUrl,
} from 'store/paths';

export const fetchCompanyList = ({
	options,
	queryParams,
	cancelToken,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		// Get response
		const { data } = await services.post(
			fetchBusinessCompanyListUrl(queryParams),
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveCompany = ({ isActive, id }) => async () => {
	await services.put(updateActiveCompanyUrl(id, isActive));
};

export const deleteCompany = ({ id }) => async () => {
	await services.delete(deleteCompanyUrl(id));
};
