import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(2),
		maxHeight: 180,
		width: '100%',
	},
	item: {
		display: 'flex',
		alignItems: 'column',
	},
	info: {
		display: 'flex',
		marginBottom: theme.spacing(1),
	},
	name: {
		color: theme.palette.primary.main,
		fontWeight: 700,
	},
	date: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	content: {
		flex: 3,
	},
	error: {
		color: theme.palette.error.main,
	},
	commentInput: {
		'& .MuiInputBase-input, .MuiInputBase-multiline': {
			background: 'none',
		},
		'& .MuiInputLabel-root': {
			top: '1em',
		},
	},
	inputs: {
		display: 'flex',
		alignItems: 'flex-end',
		position: 'relative',
		paddingTop: 5,

		'&::before': {
			content: '""',
			position: 'absolute',
			left: -15,
			top: 15,
			width: 'calc(100% + 15px)',
			height: '100%',
			backgroundColor: alpha(theme.palette.background.default, 0.7),
			borderRadius: 10,
		},
	},
}));
