import React from 'react';
import { object } from 'prop-types';
import { Button, Grid, TableCell } from '@material-ui/core';

// Import styles
import { useStyles } from './ActionTableCell.styles';
import { useTranslations } from 'components/utilities';

export const ActionTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Button color="primary" variant="contained">
						{t('common.buttons.accept')}
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" className={classes.rejectButton}>
						{t('common.buttons.reject')}
					</Button>
				</Grid>
			</Grid>
		</TableCell>
	);
};

ActionTableCell.propTypes = {
	cell: object,
};
