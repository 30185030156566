import produce from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	item: {},
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.PARTNERS_FORM_FETCH_DATA_LOADING:
				return INITIAL_STATE;

			case types.PARTNERS_FORM_FETCH_DATA_SUCCESS:
				draft.item = action.payload.item;
				draft.isLoaded = true;
				break;

			case types.PARTNERS_FORM_FETCH_DATA_ERROR:
				draft.error = action.payload;
				draft.isLoaded = true;
				draft.isError = true;
				break;

			default:
				return state;
		}
	});
