import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	image: {
		width: '25px',
	},
	box: {
		display: 'flex',
		alignItems: 'center',
	},
	button: {
		padding: '0 5px',
	},
	icon: {
		fontSize: 'small',
		marginRight: 5,
		transform: 'rotate(45deg)',
	},
	paymentMethod: {
		marginBottom: '16px',
	},
}));

export default useStyles;
