import React from 'react';
import { CircularProgress, Typography, Card, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

// Import styles
import { useStyles } from './Statistics.styles';

// Import utils
import { useQueryStringParams, useTranslations } from 'components/utilities';
import { usePromoCodeStatistics } from 'queries';

// Import components
import { AlertMessage } from 'components/elements';

// Import helpers
import { convertStatistics } from './helpers';

export const Statistics = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { id: promoCode } = useParams();
	const { parsedFilters } = useQueryStringParams();
	const { state } = parsedFilters || {};

	const {
		data: statistics,
		isError,
		isFetching,
	} = usePromoCodeStatistics({ promoCode, state });

	const convertedStatistics = convertStatistics(statistics);

	if (isFetching) {
		return (
			<Grid container justifyContent="center">
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}

	if (isError) {
		return (
			<Grid container justifyContent="flex-start">
				<Grid item xs>
					<AlertMessage
						title={t('errors.error')}
						description={t('common.messages.error_message')}
						variant="error"
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container spacing={2}>
			{convertedStatistics.map(({ label, value }) => (
				<Grid item xs={6} md={4} key={label}>
					<Card className={classes.card}>
						<Typography className={classes.label}>{t(label)}</Typography>
						<Typography className={classes.fieldValue}>{value}</Typography>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};
