import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		background: theme.palette.error.main,
		color: theme.palette.white,
		borderRadius: '12px',

		'&:hover': {
			background: theme.palette.error.dark,
		},
	},
	text: {
		fontSize: '16px',
		fontWeight: '500',
	},
}));
