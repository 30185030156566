import React from 'react';
import { string, object } from 'prop-types';
import get from 'lodash/get';

// Import components
import CompensationHistoryField from './CompensationHistoryField';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { FIELDS_ACCESSORS } from '../helpers';

const DefaultCompensationHistoryField = ({ accessor, label, compensation }) => {
	const { t } = useTranslations();

	const getStatus = () =>
		accessor === FIELDS_ACCESSORS.STATUS &&
		t(`bookings.customer_compensation.statuses.${compensation.status}`);

	return (
		<CompensationHistoryField label={label}>
			{getStatus() || get(compensation, accessor)}
		</CompensationHistoryField>
	);
};

DefaultCompensationHistoryField.propTypes = {
	compensation: object,
	accessor: string,
	label: string,
};

export default DefaultCompensationHistoryField;
