import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

import { COLUMNS_ACCESSORS } from '../../columns';
import { LinkTableCell } from 'components/elements';
import { replacePreviewBookingUrl } from 'helpers';

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const { individual_number: bookingId, uuid: bookingUuid } =
			cell.row.original;

		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.INDIVIDUAL_NUMBER:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(bookingUuid)}
					>
						{bookingId}
					</LinkTableCell>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
