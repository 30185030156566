import React, { Component } from 'react';
import { string, object } from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

// Import components
import {
	Header,
	CarTitle,
	DividerLine,
	Period,
	Customer,
	Prices,
} from '../components';

const styles = {
	padding: {
		padding: 50,
	},
};

class ComponentToPrint extends Component {
	render() {
		const { direction, bookingData, language, classes } = this.props;
		const { individual_number, period, customer, item } = bookingData;

		return (
			<div dir={direction} className={classes.padding}>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Header bookingNumber={individual_number} />
					</Grid>
					<DividerLine />
					<Grid item md={12} xs={12}>
						<CarTitle carName={item.name} />
					</Grid>
					<DividerLine />
					<Grid item md={12} xs={12}>
						<Period period={period} />
					</Grid>
					<DividerLine />
					<Grid item md={12} xs={12}>
						<Customer customer={customer} />
					</Grid>
					<DividerLine />
					<Grid item md={12} xs={12}>
						<Prices bookingDetails={item} lng={language} />
					</Grid>
				</Grid>
			</div>
		);
	}
}

ComponentToPrint.propTypes = {
	direction: string.isRequired,
	bookingData: object.isRequired,
	language: string.isRequired,
	classes: object.isRequired,
};

export default withStyles(styles)(ComponentToPrint);
