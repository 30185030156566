import React from 'react';
import { fetchCorporateList } from 'store/actions';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow } from './components';
import { TableRPC } from 'components/utilities';

export const CorporateMaintenance = () => {
	return (
		<TableRPC
			fetchDataTableAction={fetchCorporateList}
			columnsAccessor={COLUMNS_ACCESSORS}
			tableBodyRow={TableBodyRow}
			columns={columns}
		/>
	);
};
