import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormState, useForm } from 'react-final-form';
import usePrevious from '@rooks/use-previous';

const TableExportFiltersContent = ({ children }) => {
	const { values } = useFormState();
	const { submit } = useForm();

	const prevValues = usePrevious(values);

	useEffect(() => {
		if (JSON.stringify(values) !== JSON.stringify(prevValues)) {
			submit();
		}
	}, [values, prevValues, submit]);

	return children;
};

TableExportFiltersContent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	])
};

export default TableExportFiltersContent;
