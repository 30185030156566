import React from 'react';
import { Collapse } from '@material-ui/core';
import { bool, string } from 'prop-types';

// Import components
import { ExternalPartnerCarsTable } from './components';

export const CollapsedCarList = ({ officeUuid, isOpen }) => {
	return (
		<Collapse in={isOpen}>
			<ExternalPartnerCarsTable officeUuid={officeUuid} isOpen={isOpen} />
		</Collapse>
	);
};

CollapsedCarList.propTypes = {
	officeUuid: string,
	isOpen: bool,
};
