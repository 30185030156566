// Import helpers
import { EN, ENVS, FIELD_TYPES } from 'helpers';

export const SETTINGS_FIELDS = (baseDomain) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'cms.partners.form.settings.subdomain',
		name: 'subdomain',
		required: true,
		md: 6,
		xs: 12,
		startAdornment: 'https://',
		endAdornment: baseDomain,
	},
	{
		type: FIELD_TYPES.SWITCH,
		label: 'cms.partners.form.settings.is_active',
		name: 'is_active',
		required: true,
		md: 6,
		xs: 12,
	},
];

export const getPartnerUrl = (subdomain) => {
	const env = process.env.REACT_APP_RELEASE_STAGE;

	switch (env) {
		case ENVS.DEVELOPMENT:
			return {
				partnerUrl: `https://${subdomain}.dev.telgani.com`,
				baseDomain: `.dev.telgani.com`,
			};

		case ENVS.TESTING:
			return {
				partnerUrl: `https://${subdomain}.uat.telgani.com`,
				baseDomain: `.uat.telgani.com`,
			};

		default:
			return {
				partnerUrl: `https://${subdomain}.telgani.com`,
				baseDomain: `.telgani.com`,
			};
	}
};

export const convertCompanyName = ({ companies, companyUuid }) => {
	const companyName = companies?.find(({ uuid }) => uuid === companyUuid)?.name[
		EN
	];

	return companyName
		?.replace(/[^a-zA-Z0-9 ]/, '')
		.replaceAll(' ', '-')
		.toLowerCase();
};
