// Import assets
import {
	DeliveryDropIcon,
	CarDeliveryIcon,
	UnlimitedKmIcon,
	ExtraDriverIcon,
	AuthOutKSAIcon,
	ChildSeatIcon,
	InsuranceIcon,
	NoSmokingIcon,
	DropCityIcon,
	DriverIcon,
} from 'assets/icons';

export const services = [
	{ type: 'delivery_drop', icon: DeliveryDropIcon },
	{ type: 'no_smoking_car', icon: NoSmokingIcon },
	{ type: 'extra_driver', icon: ExtraDriverIcon },
	{ type: 'unlimitedKm', icon: UnlimitedKmIcon },
	{ type: 'auth_out_ksa', icon: AuthOutKSAIcon },
	{ type: 'child_seat', icon: ChildSeatIcon },
	{ type: 'delivery', icon: CarDeliveryIcon },
	{ type: 'insurance', icon: InsuranceIcon },
	{ type: 'dropCity', icon: DropCityIcon },
	{ type: 'driver', icon: DriverIcon },
	{ type: 'tam', icon: InsuranceIcon },
];

export const specialOfferServiceTypes = [
	'pickup',
	'airport',
	'delivery',
	'subscription',
];
