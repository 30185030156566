import PersonAddIcon from '@material-ui/icons/PersonAdd';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: '/company-managers/create',
		icon: PersonAddIcon,
		text: 'company_managers.toolbar.add_user_btn',
	},
];
