// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useRatingTypes } from 'queries';
import { FIELD_TYPES, generateArray } from 'helpers';

export const useReasonType = () => {
	const { t } = useTranslations();

	const sectionName = 'rating_reasons.form.reason_type';

	const { data: ratingTypes } = useRatingTypes();

	const numbers = generateArray(1, 5);

	const ratingFields = [
		{
			label: `${sectionName}.type`,
			name: 'rating_type',
			type: FIELD_TYPES.SELECT,
			md: 6,
			required: true,
			options: ratingTypes,
			keyExtractor: (row) => row.toLowerCase(),
			labelExtractor: (row) => t(`${sectionName}.${row.toLowerCase()}`),
		},
		{
			label: `${sectionName}.stars`,
			name: 'rating_number',
			type: FIELD_TYPES.SELECT,
			md: 6,
			required: false,
			options: numbers,
			keyExtractor: (row) => row,
			labelExtractor: (row) => row,
		},
	];

	return {
		ratingFields,
		sectionName,
	};
};
