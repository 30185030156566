// Import helpers and utils
import { combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';

// Import store
import {
	updateTrainStationStateUrl,
	fetchTrainStationsListUrl,
} from 'store/paths';

export const fetchTrainStationsList =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			// Get response
			const { data } = await services.get(
				fetchTrainStationsListUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateTrainStationState =
	({ active, uuid }) =>
	async () => {
		await services.patch(updateTrainStationStateUrl({ uuid }), {
			active: !active,
		});
	};
