import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { updateStatusLastBooking } from 'store/actions';

// Import helpers
import {
	EMPTY_VALUE,
	replaceEditBookingUrl,
	replaceOfficeDetailsUrl,
	replacePreviewBookingUrl,
	isRejectVisible,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { BookingTimeTableCell } from '../TableBodyRow';
import {
	ApprovedTableCell,
	ActionsTableCell,
	StatusTableCell,
	NoteTableCell,
	LinkTableCell,
	PickDateTableCell,
	DropDateTableCell,
} from 'components/elements';

// Import Utilities
import { useProfile, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';

// Import styles
import useStyles from './styles';

const {
	BOOKING_ID,
	STATUS,
	DROP_DATE,
	OFFICE_NAME,
	NOTE,
	DURATION,
	APPROVED,
	CANCELLATION_REASON,
	CUSTOMER_NAME,
	PICK_DATE,
	ACTIONS,
	BOOKING_TIME,
	OFFICE_ADDRESS,
} = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const { resources } = useTableContextProvider();
	const { officeTable } = useStyles();
	const { t } = useTranslations();
	const { isAdmin } = useProfile();

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map((cell) => {
				if (cell.column.isHidden) return null;
				switch (cell.column.id) {
					case NOTE:
						return <NoteTableCell key={cell.column.id} cell={cell} />;

					case OFFICE_NAME:
						return (
							<LinkTableCell
								key={cell.column.id}
								cell={cell}
								to={replaceOfficeDetailsUrl(cell.row.original.office_uuid)}
							>
								{cell.row.original.office_name}
							</LinkTableCell>
						);

					case ACTIONS:
						return (
							<ActionsTableCell
								key={cell.column.id}
								cell={cell}
								editLinkPath={replaceEditBookingUrl(cell.row.original.uuid)}
								canEdit={cell.row.original.can_edit && isAdmin}
								minWidth={140}
							/>
						);

					case BOOKING_ID:
						return (
							<LinkTableCell
								key={cell.column.id}
								cell={cell}
								to={replacePreviewBookingUrl(cell.row.original.uuid)}
							>
								{cell.row.original.individual_number}
							</LinkTableCell>
						);

					case BOOKING_TIME:
						return <BookingTimeTableCell key={cell.column.id} cell={cell} />;

					case OFFICE_ADDRESS:
						return (
							<TableCell key={cell.column.id} className={officeTable}>
								{cell.row.original.office_address}
							</TableCell>
						);

					case CANCELLATION_REASON:
						return (
							<TableCell key={cell.column.id}>
								{cell.row.original.cancel_description || EMPTY_VALUE}
							</TableCell>
						);
					case DROP_DATE:
						return <DropDateTableCell key={cell.column.id} cell={cell} />;

					case PICK_DATE:
						return <PickDateTableCell key={cell.column.id} cell={cell} />;

					case STATUS:
						return (
							<StatusTableCell
								isRejectVisible={isRejectVisible(cell, resources)}
								acceptAction={updateStatusLastBooking}
								key={cell.column.id}
								cell={cell}
							/>
						);

					case DURATION:
						return (
							<TableCell key={cell.column.id}>
								{cell.row.original.response_time &&
									`${cell.row.original.response_time} ${t(
										'bookings.table.common.minutes'
									)}`}
							</TableCell>
						);

					case CUSTOMER_NAME:
						return (
							<TableCell key={cell.column.id}>
								{cell.row.original.customer_name}
								<br /> {cell.row.original.customer_phone}
							</TableCell>
						);

					case APPROVED:
						return <ApprovedTableCell key={cell.column.id} cell={cell} />;

					default:
						return (
							<TableCell key={cell.column.id} {...cell.getCellProps()}>
								{cell.render('Cell')}
							</TableCell>
						);
				}
			})}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
