import format from 'date-fns/format';

// Import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

export const convertData = ({ date_from, date_to, ...rest }, serviceType) => {
	return {
		...rest,
		date_from: format(new Date(date_from), DEFAULT_DATE_FORMAT),
		date_to: format(new Date(date_to), DEFAULT_DATE_FORMAT),
		service_type: serviceType,
	};
};
