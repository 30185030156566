import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import helpers and utils
import {
	AUTO_COMPENSATION,
	FIELD_TYPES,
	IS_URGENT_ASSISTANCE,
} from 'helpers/variables';
import { useTranslations } from 'components/utilities';
import { useUrgentReasons } from 'queries';

export const useAdditionalInfo = () => {
	const {
		i18n: { language },
	} = useTranslations();

	const sectionName = 'rating_reasons.form';

	const { change } = useForm();

	const { values } = useFormState();

	const { data: urgentReasons } = useUrgentReasons();

	const {
		auto_compensation: autoCompensation,
		compensation_formula: compensationFormula,
		compensation_value: compensationValue,
		compensation_max_value: compensationMaxValue,
		is_urgent_assistance: isUrgentAssistance,
		urgent_assistance_reason_uuid: urgentAssistanceReasonUuid,
	} = values;

	useEffect(() => {
		const isAutoCompensationVisible = compensationFormula || compensationValue;
		isAutoCompensationVisible && change(AUTO_COMPENSATION, true);

		!compensationMaxValue && change('compensation_max_value', null);

		urgentAssistanceReasonUuid && change(IS_URGENT_ASSISTANCE, true);

		// eslint-disable-next-line
	}, []);

	const additionalInfoFields = [
		{
			type: FIELD_TYPES.CHECKBOX,
			name: 'operation_validation',
			label: `${sectionName}.auto_compensation.operation_validation`,
			md: 6,
		},
		{
			type: FIELD_TYPES.CHECKBOX,
			name: IS_URGENT_ASSISTANCE,
			label: `${sectionName}.urgent_assistance.title`,
			md: 6,
		},
		{
			type: FIELD_TYPES.CHECKBOX,
			name: AUTO_COMPENSATION,
			label: `${sectionName}.auto_compensation.switch`,
			md: 6,
		},
		{
			label: `${sectionName}.urgent_assistance.urgent_assistance_reasons`,
			name: 'urgent_assistance_reason_uuid',
			type: FIELD_TYPES.SELECT,
			md: 6,
			required: !!isUrgentAssistance,
			options: urgentReasons,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ details }) => details.name[language],
			isHidden: !isUrgentAssistance,
		},
	];

	return {
		autoCompensation,
		sectionName,
		additionalInfoFields,
	};
};
