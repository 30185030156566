// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FIELDS = ({ isAdmin }) => [
	{
		type: FIELD_TYPES.CHECKBOX,
		label: 'offices.form.service_type.b2b',
		name: 'service_type.b2b',
		md: 4,
		xs: 6,
		disabled: !isAdmin,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		label: 'offices.form.service_type.b2c',
		name: 'service_type.b2c',
		md: 4,
		xs: 6,
		disabled: true,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		label: 'offices.form.service_type.both',
		name: 'service_type.both',
		md: 4,
		xs: 6,
		disabled: true,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		label: 'offices.form.service_type.daily',
		name: 'service_type.daily',
		md: 4,
		xs: 6,
		disabled: true,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		label: 'offices.form.service_type.subscription',
		name: 'service_type.subscription',
		md: 4,
		xs: 6,
		disabled: true,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		label: 'offices.form.service_type.leasing',
		name: 'service_type.offers_leasing_cars',
		md: 4,
		xs: 6,
		disabled: !isAdmin,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		label: 'offices.form.service_type.digital_branch',
		name: 'service_type.digital_branch',
		md: 4,
		xs: 6,
		disabled: !isAdmin,
	},
];
