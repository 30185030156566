import React from 'react';
import { TableCell, Collapse, TableRow } from '@material-ui/core';
import { bool, number, array } from 'prop-types';

// Import component
import { SubTable } from 'components/elements';
import { TableBodyRow } from './components';

// Import context
import { SubTableProvider } from 'components/context';

// Import helpers
import { columns } from 'views/Offices/LeasingCars/columns';

export const ExpandedRow = ({
	isExpanded,
	colSpan = 1,
	customResources = [],
}) => {
	return (
		<TableRow>
			<TableCell style={{ padding: 0 }} colSpan={colSpan}>
				<Collapse in={isExpanded} unmountOnExit>
					<SubTableProvider
						columns={columns}
						customResources={customResources}
						fetchDataTableAction={() => {}}
					>
						<SubTable>
							{({ row }) => <TableBodyRow key={row.id} row={row} />}
						</SubTable>
					</SubTableProvider>
				</Collapse>
			</TableCell>
		</TableRow>
	);
};

ExpandedRow.propTypes = {
	isExpanded: bool.isRequired,
	colSpan: number,
	customResources: array,
};
