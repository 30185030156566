import React from 'react';
import { Box } from '@material-ui/core';
import { fetchUsers } from 'store/actions';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow } from './components';

export const Users = () => {
	const classes = useBaseLayoutStyles();

	return (
		<TableProvider columns={columns} fetchDataTableAction={fetchUsers}>
			<Box className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<Box className={classes.content}>
					<Table isDefaultRow={false}>
						{({ row }) => <TableBodyRow key={row.id} row={row} />}
					</Table>
				</Box>
			</Box>
		</TableProvider>
	);
};
