import React from 'react';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import { func, object, string } from 'prop-types';

// Import utilities
import { useDialogForm } from './useDialogForm';

// Import components
import { Fields } from '../../components';

// Import styles
import { useStyles } from './DriverForm.styles';

export const DialogForm = ({
	toggle,
	deliveryType,
	externalBookingUuid,
	externalDelivery = null,
	refetchCallback = () => {},
}) => {
	const { handleOnSubmit, initialValues } = useDialogForm({
		deliveryType,
		toggle,
		externalBookingUuid,
		refetchCallback,
		externalDelivery,
	});

	const classes = useStyles();

	return (
		<Form
			onSubmit={handleOnSubmit}
			initialValues={initialValues}
			render={({ handleSubmit }) => (
				<Box className={classes.form} component="form" onSubmit={handleSubmit}>
					<Fields
						toggle={toggle}
						deliveryType={deliveryType}
						externalBookingUuid={externalBookingUuid}
					/>
				</Box>
			)}
		/>
	);
};

DialogForm.propTypes = {
	toggle: func.isRequired,
	deliveryType: string,
	externalBookingUuid: string,
	refetchCallback: func,
	externalDelivery: object,
};
