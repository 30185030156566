import React from 'react';
import { Field, Form } from 'react-final-form';
import { func } from 'prop-types';
import { Button, Grid, MenuItem } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { SelectFieldAdapter, Alert, ButtonProgress } from 'components/elements';
import useAssignUrgentBookingForm from './useAssignUrgentBookingForm';

// Import helpers
import { INITIAL_VALUES } from './helpers';

// Import styles
import { useStyles } from './styles';

const AssignUrgentBookingForm = ({ onCancel = () => {} }) => {
	const { t, i18n } = useTranslations();

	const classes = useStyles();

	const { handleSubmit, error, urgentReasons } = useAssignUrgentBookingForm();

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={INITIAL_VALUES}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					{error.length > 0 && <Alert message={error[0][0]} />}
					<Grid container spacing={2} className={classes.container}>
						<Grid item xs={12}>
							<Field
								fullWidth
								component={SelectFieldAdapter}
								label={t('bookings.assign_urgent_form.label')}
								name="urgentReasonId"
								margin="dense"
								variant="outlined"
								disabled={submitting}
								required
							>
								{urgentReasons.map(({ uuid, details: { name } }) => (
									<MenuItem key={uuid} value={uuid}>
										{name[i18n.language]}
									</MenuItem>
								))}
							</Field>
						</Grid>
						<Grid item xs={12} container spacing={2} justifyContent="flex-end">
							<Grid item>
								<Button color="primary" onClick={onCancel}>
									{t('common.buttons.cancel')}
								</Button>
							</Grid>
							<Grid item>
								<ButtonProgress
									color="primary"
									type="submit"
									variant="contained"
									isLoading={submitting}
									disabled={submitting}
								>
									{t('common.buttons.submit')}
								</ButtonProgress>
							</Grid>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	);
};

AssignUrgentBookingForm.propTypes = {
	onCancel: func,
};

export default AssignUrgentBookingForm;
