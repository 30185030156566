import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
	fetchDefaultSeoFormResources,
	submitDefaultSeoForm,
} from 'store/actions';

// Import components
import { FormRPC } from 'components/utilities';
import { SeoCard } from 'components/elements';

// Import helpers
import { INITIAL_VALUES } from './helpers';

// Import styles
import useStyles from './styles';

const DefaultSeoForm = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchDefaultSeoFormResources}
				submitFormAction={submitDefaultSeoForm}
				isCardLayout={false}
				store={(store) => store.defaultSeo.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<SeoCard />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default DefaultSeoForm;
