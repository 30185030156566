import React from 'react';
import { object, string } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { deleteSpecialOffer } from 'store/actions';

// Import helpers
import { replaceEditSpecialOfferUrl, TABLE_ACTIONS_ACCESSORS } from 'helpers';

// Import components
import { EditTableCell, DeleteTableCell } from 'components/elements';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

const TableBodyRow = ({ row, type }) => {
	const renderCell = (cell) => {
		switch (cell.column.id) {
			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditSpecialOfferUrl(
							cell.row.original.uuid,
							type
						)}
					/>
				);

			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteSpecialOffer}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	type: string.isRequired,
};

export default TableBodyRow;
