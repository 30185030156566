import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { CircularProgress, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import queries
import { useCarModels } from 'queries/CarSearch';

// Import utilities
import { useTranslations } from 'components/utilities';

const CarModelFilter = () => {
	const { submitting, values } = useFormState();

	const manufacturerUuid = values.vehicle?.maker_uuid;

	const { data: models, isFetching } = useCarModels({ manufacturerUuid });

	const { i18n, t } = useTranslations();

	const lng = i18n.language;

	if (isFetching) {
		return <CircularProgress size={28} />;
	}

	return (
		<Field
			fullWidth
			component={SelectFieldAdapter}
			label={t('bookings.edit_booking.select_car.form.car_model_field')}
			name="vehicle.model_uuid"
			variant="outlined"
			margin="dense"
			disabled={submitting}
		>
			{models.map(({ model_name, model_uuid }) => (
				<MenuItem key={model_uuid} value={model_uuid}>
					{model_name[lng]}
				</MenuItem>
			))}
		</Field>
	);
};

export default CarModelFilter;
