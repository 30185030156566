import React from 'react';
import { useSelector } from 'react-redux';

// Import selectors
import { previewBookingSelector } from 'store/selectors';
import {
	Box,
	Card,
	CardContent,
	CircularProgress,
	Typography,
} from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import utilities
import { useTranslations, useImageBlobFromApi } from 'components/utilities';

export const LicenseImageCard = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const {
		customer: { license },
	} = useSelector(previewBookingSelector);

	const { imageBlob, error, isLoading } = useImageBlobFromApi({
		imgUrl: license,
	});

	if (!license) return null;

	return (
		<Card className={classes.container}>
			<CardContent>
				<Box className={classes.imgContainer}>
					{isLoading && <CircularProgress size={48} color="primary" />}

					{imageBlob && (
						<img
							src={URL.createObjectURL(imageBlob)}
							alt={t('bookings.preview_booking.customer.modal.title')}
							className={classes.image}
						/>
					)}

					{error && (
						<Typography color="error">{t('errors.image_not_found')}</Typography>
					)}
				</Box>
			</CardContent>
		</Card>
	);
};
