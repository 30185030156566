
import React from 'react';

// Import components
import BookingHistoryRow from './BookingHistoryRow';
import useBookingHistory from '../context/useBookingHistory';

const BookingHistoryBody = () => {
    const { data } = useBookingHistory();

    if (!data) {
        return null;
    }
    
    return (
        <>
            {data.map((row) => (
                <BookingHistoryRow
                    key={row.id}
                    row={row}
                />
            ))}
        </>
    )
};

export default BookingHistoryBody;