import { BOOKING_URGENT_STATES, convertFormDates } from 'helpers';

export const initialValues = {
	urgent_assistance_status: BOOKING_URGENT_STATES.REVIEW,
	compensation_status: 'all',
	company_uuid: 'all',
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
	urgent_assistance_created_at: null,
	urgent_assistance_created_at_to: null,
};

export const formatValues = (values) => {
	return {
		...values,
		company_uuid: values.company_uuid,
		urgent_assistance_status: values.urgent_assistance_status,
		compensation_status: values.compensation_status,
		...convertFormDates(values, [
			'pick_date',
			'drop_date',
			'urgent_assistance_created_at',
		]),
	};
};
