export const initialValues = {
	active: 'all',
};

export const formatValues = (values) => {
	return {
		active: values.active === 'all' ? null : values.active,
		...values,
	};
};
