/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { List, ListItem, Button, Collapse, Box, Grid } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { array } from 'prop-types';
import clsx from 'clsx';

// Import icons
import ArrowRightIcon from '@material-ui/icons/NavigateNext';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowLeftIcon from '@material-ui/icons/NavigateBefore';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { AR } from 'helpers';

// Import styles
import useSidebarNav from './useSidebarNav';

const CustomRouterLink = forwardRef((props, ref) => (
	<Box ref={ref} style={{ flexGrow: 1 }}>
		<RouterLink {...props} />
	</Box>
));

const SidebarNav = ({ pages, ...rest }) => {
	const {
		t,
		i18n: { language },
	} = useTranslations();
	const isRTL = language === AR;

	const {
		collapsedHeadListItemClasses,
		collapsedHeadButtonClasses,
		listItemButtonClasses,
		clearDropdownTabs,
		handleClick,
		isNestedItem,
		titleClass,
		hasAccess,
		classes,
		navigationDropdownTabs,
		isItemWithoutCollapsed,
	} = useSidebarNav({ pages });

	const renderListItem = ({
		title,
		icon,
		href,
		chip: Chip,
		collapsed,
		bold,
	}) => (
		<ListItem
			className={clsx(classes.item, titleClass(title))}
			disableGutters
			key={title}
		>
			{!collapsed ? (
				<Button
					onClick={() => clearDropdownTabs(title)}
					activeClassName={clsx(classes.active, bold && classes.boldText)}
					component={CustomRouterLink}
					className={clsx(
						bold && classes.boldText,
						listItemButtonClasses(icon),
						isNestedItem(title) && classes.nestedItem,
						isItemWithoutCollapsed(title) && classes.horizontalBar
					)}
					to={href}
				>
					{icon && <Box className={classes.icon}>{icon}</Box>}

					{t(title)}

					{Chip && (
						<Box pl={1}>
							<Chip />
						</Box>
					)}
				</Button>
			) : (
				<List disablePadding className={clsx(classes.list)}>
					{renderCollapsedList({
						title,
						icon,
						href,
						chip: Chip,
						collapsed,
						bold,
					})}
				</List>
			)}
		</ListItem>
	);

	const renderCollapsedList = ({
		title,
		icon,
		collapsed,
		chip: Chip,
		bold,
	}) => {
		const isCollapsed = navigationDropdownTabs.includes(title);

		return (
			<ListItem
				disableGutters
				className={clsx(collapsedHeadListItemClasses(title))}
			>
				<Grid container direction="column">
					<Grid item xs={12}>
						<Button
							className={clsx(
								collapsedHeadButtonClasses(title),
								titleClass(title),
								bold && classes.boldText
							)}
							onClick={handleClick(title)}
						>
							<Box className={classes.icon}>{icon}</Box>
							{t(title)}
							{Chip && (
								<Box pl={1}>
									<Chip />
								</Box>
							)}
							<Box component="span" className={classes.spacer} />
							{isCollapsed ? (
								<ArrowDownIcon />
							) : isRTL ? (
								<ArrowLeftIcon />
							) : (
								<ArrowRightIcon />
							)}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Collapse in={isCollapsed} timeout="auto" unmountOnExit>
							<List className={classes.collapsedList} disablePadding {...rest}>
								{collapsed.map(
									({ role, ...rest }) =>
										hasAccess(role) &&
										renderListItem({
											...rest,
										})
								)}
							</List>
						</Collapse>
					</Grid>
				</Grid>
			</ListItem>
		);
	};

	return (
		<PerfectScrollbar>
			<List {...rest} className={classes.root}>
				{pages.map(
					({ role, title, icon, href, chip, collapsed }) =>
						hasAccess(role) &&
						renderListItem({ title, icon, href, chip, collapsed })
				)}
			</List>
		</PerfectScrollbar>
	);
};

SidebarNav.propTypes = {
	pages: array.isRequired,
};

export default SidebarNav;
