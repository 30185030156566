import * as types from 'store/types';
import services from 'services/services';
import {
	fetchCmsPartnersFormResourcesUrl,
	submitCmsPartnersFormUrl,
} from 'store/paths';

// Import helpers
import { convertSubmitData, convertFetchData } from './helpers';
import { catchError } from '../../helpers';

export const fetchCmsPartnersFormResources =
	({ isEdit, itemId }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.PARTNERS_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			const data =
				isEdit &&
				(await services.get(fetchCmsPartnersFormResourcesUrl(itemId)));

			const item = isEdit && data?.data?.data;

			const convertedData = isEdit && convertFetchData(item);

			dispatch({
				type: types.PARTNERS_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: isEdit ? convertedData : {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.PARTNERS_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitCmsPartnersForm =
	({ values, errorCallback, callbackSuccess, itemId }) =>
	async () => {
		try {
			const method = itemId ? 'put' : 'post';
			const path = submitCmsPartnersFormUrl(itemId);
			const convertedSubmitData = convertSubmitData(values);

			await services[method](path, convertedSubmitData);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
