import React from 'react';
import { object } from 'prop-types';
import { Checkbox, TableCell } from '@material-ui/core';

// Import utils and helpers
import { useUnsettledLimitsProvider } from '../../context';

export const SettleCheckboxTableCell = ({ cell }) => {
	const { handleOnSettledUuids, isSettled } = useUnsettledLimitsProvider();
	const settledUuid = cell.row.original.uuid;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Checkbox
				onChange={() => handleOnSettledUuids(settledUuid)}
				checked={isSettled(settledUuid)}
				color="primary"
			/>
		</TableCell>
	);
};

SettleCheckboxTableCell.propTypes = {
	cell: object,
};
