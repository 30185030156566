import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { Button, Grid, Typography } from '@material-ui/core';
import { func } from 'prop-types';

// Import helpers
import { FIELDS } from './helpers';

// Import utils
import { useTranslations, useCommonFields } from 'components/utilities';

// Import components
import { ButtonProgress, FileFieldAdapter } from 'components/elements';
import { useTableActionsProvider } from 'views/RewardsSettlement/components/TableActionsProvider';
import { useFormContent } from './useFormContent';
import { BookingIdsHandler } from '../BookingIdsHandler';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = ({ toggle }) => {
	const { selectedChildren, allExpandedData } = useTableActionsProvider();
	const { bookingsIds, handleAddAndRemoveIds } = useFormContent({
		selectedChildren,
		allExpandedData,
	});

	const isNoCheckedIds = !bookingsIds?.filter(({ checked }) => checked).length;

	const { renderFields } = useCommonFields();

	const { submitting } = useFormState();

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Grid container>
			{FIELDS.map(renderFields)}
			<Grid item xs={12}>
				<BookingIdsHandler
					data={bookingsIds}
					add={handleAddAndRemoveIds}
					remove={handleAddAndRemoveIds}
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography className={classes.fileLabel}>
					{t('rewards_settlement.fields.transaction_file')}
				</Typography>
				<Field
					previewFileState="file"
					component={FileFieldAdapter}
					name="file.file_path"
					fileNameField="file.file_name"
					disabled={submitting}
					uploadButtonWidth={12}
					required
				/>
			</Grid>
			<Grid container justifyContent="flex-end" spacing={2}>
				<Grid item>
					<Button variant="outlined" color="primary" onClick={toggle}>
						{t('common.buttons.close')}
					</Button>
				</Grid>
				<Grid item>
					<ButtonProgress
						variant="contained"
						color="primary"
						type="submit"
						isLoading={submitting}
						disabled={isNoCheckedIds || submitting}
					>
						{t('common.buttons.save')}
					</ButtonProgress>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	toggle: func,
};
