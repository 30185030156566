import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { ACTION } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	IMAGE: 'vehicle_image',
	MAKER: 'vehicle_maker',
	MODEL: 'vehicle_model',
	YEAR: 'vehicle_year',
	AVAILABLE_COUNT: 'available_count',
	TAKEN_COUNT: 'taken_count',
	EXPAND: 'expand',
	ACTIVE: 'is_active',
	ODOMETER: 'miles_usage',
	PLATE_NUMBER: 'plate_number',
	COLOR: 'body_color',
};

export const customColumnsSettings = [
	{ name: COLUMNS_ACCESSORS.EXPAND, searchable: false, orderable: false },
	{ name: COLUMNS_ACCESSORS.IMAGE, searchable: false, orderable: false },
	{ name: COLUMNS_ACCESSORS.MAKER, searchable: false, orderable: false },
	{ name: COLUMNS_ACCESSORS.MODEL, searchable: false, orderable: false },
	{ name: COLUMNS_ACCESSORS.YEAR, searchable: false, orderable: false },
	{
		name: COLUMNS_ACCESSORS.AVAILABLE_COUNT,
		searchable: false,
		orderable: false,
	},
	{
		name: COLUMNS_ACCESSORS.TAKEN_COUNT,
		searchable: false,
		orderable: false,
	},
	{
		name: COLUMNS_ACCESSORS.ACTIVE,
		searchable: false,
		orderable: false,
	},
	{
		name: COLUMNS_ACCESSORS.PLATE_NUMBER,
		searchable: false,
		orderable: false,
	},
	{
		name: COLUMNS_ACCESSORS.COLOR,
		searchable: false,
		orderable: false,
	},
	{
		name: COLUMNS_ACCESSORS.ODOMETER,
		searchable: false,
		orderable: false,
	},
	{
		name: ACTION,
		searchable: false,
		orderable: false,
	},
];

export const columns = [
	{
		Header: '',
		accessor: COLUMNS_ACCESSORS.EXPAND,
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.image',
		accessor: COLUMNS_ACCESSORS.IMAGE,
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.maker',
		accessor: COLUMNS_ACCESSORS.MAKER,
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.model',
		accessor: COLUMNS_ACCESSORS.MODEL,
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.year',
		accessor: COLUMNS_ACCESSORS.YEAR,
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.availability',
		accessor: COLUMNS_ACCESSORS.AVAILABLE_COUNT,
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.taken',
		accessor: COLUMNS_ACCESSORS.TAKEN_COUNT,
		disableSortBy: false,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.plate',
		accessor: COLUMNS_ACCESSORS.PLATE_NUMBER,
	},
	{
		Header: 'common.cars.headers.color',
		accessor: COLUMNS_ACCESSORS.COLOR,
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.odometer',
		accessor: COLUMNS_ACCESSORS.ODOMETER,
		disableSortBy: false,
	},

	{
		Header: 'table.headers.action',
		accessor: ACTION,
		disableSortBy: false,
	},
];
