import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { registrationProcessSelector } from 'store/selectors';
import {
	fetchRegistrationProcessFormResources,
	submitRegistrationProcessForm,
} from 'store/actions';

// Import utilities
import { FormRPC, useTranslations } from 'components/utilities';
import useRegistrationProcess from './useRegistrationProcess';

// Import components
import { CheckboxSection } from './components';

// Import helpers
import { INITIAL_VALUES } from './helpers';

// Import styles
import { useStyles } from 'views/Customers/styles';

export const RegistrationProcess = () => {
	const { root } = useStyles();

	const { t } = useTranslations();

	const { callbackSuccess } = useRegistrationProcess({ t });
	return (
		<Box className={root}>
			<FormRPC
				fetchFormResAction={fetchRegistrationProcessFormResources}
				submitFormAction={submitRegistrationProcessForm}
				callbackFormSuccess={callbackSuccess}
				store={registrationProcessSelector}
				initialValues={INITIAL_VALUES}
				isCardLayout={true}
				title={t('customers.registration_process.form.title')}
			>
				{() => (
					<Grid container spacing={3}>
						<Grid item>
							<CheckboxSection />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
