import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, Typography } from '@material-ui/core';

const PriceSummaryItem = ({ label, children }) => {
	return (
		<ListItem disableGutters>
			<Grid container spacing={2} justifyContent="space-between">
				<Grid item>
					<Typography variant="h6">{label}</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body1">{children}</Typography>
				</Grid>
			</Grid>
		</ListItem>
	);
};

PriceSummaryItem.propTypes = {
	label: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

export default PriceSummaryItem;
