import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	cell: {
		textAlign: 'center',
	},
	dialog: {
		'& .MuiDialog-paper': {
			minWidth: '70vw',
			padding: 0,
			borderRadius: '24px',
			minHeight: '100px',
		},
	},
	content: {
		'&.MuiDialogContent-root': {
			padding: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
}));
