import { AddCircleOutlineRounded } from '@material-ui/icons';

// Import components
import { NewReport } from './components';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.MODAL,
		text: 'common.buttons.new_report',
		icon: AddCircleOutlineRounded,
		component: NewReport,
	},
];
