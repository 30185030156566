import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

// Import utilities and helpers
import { officeRatingSelector } from 'store/selectors/bookings';
import { fetchOfficeRatingFormResources } from 'store/actions';
import { useRatingReasons } from 'queries';

export const useOfficeRatingInfo = () => {
	const { values, submitting } = useFormState();

	const { data: reasons } = useRatingReasons({});

	const officeRating = useSelector(officeRatingSelector);

	const dispatch = useDispatch();

	const { change } = useForm();

	const { getState } = useStore();

	const { rating_reason_uuid: ratingReasonUuid } = values || {};

	const chosenRating = reasons.find(
		({ uuid }) => uuid === ratingReasonUuid
	)?.rating_number;

	const isRatingFieldDisabled = submitting || !ratingReasonUuid;
	const isRatingFieldReadOnly = !!chosenRating;

	const isDescriptionRequired = useMemo(() => {
		const reasonOther = reasons.find((reason) => reason.reason.en === 'Other');

		if (!reasonOther) {
			return false;
		}

		return ratingReasonUuid === reasonOther.uuid;
	}, [ratingReasonUuid, reasons]);

	useEffect(() => {
		if (chosenRating) {
			change('rating', chosenRating);
		}
		//eslint-disable-next-line
	}, [chosenRating]);

	useDeepCompareEffect(() => {
		// Update initial values on initial rating change
		fetchOfficeRatingFormResources()(dispatch, getState);
		// eslint-disable-next-line
	}, [officeRating || {}]);

	return {
		isDescriptionRequired,
		isRatingFieldDisabled,
		isRatingFieldReadOnly,
		reasons,
		submitting,
	};
};
