import React, { useEffect } from 'react';
import { object } from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { getProfile } from '../../store/actions';
import {
	ADMIN,
	SUPPLIER_ADMIN,
	COMPANY_MANAGER,
	COMPANY_WORKER,
	OFFICE_MANAGER,
	OFFICE_WORKER,
} from '../../helpers';

import { EditCompanyManager } from '../index';
import { UserForm } from '../index';

const Profile = ({ match }) => {
	const { item } = useSelector((store) => store.profile.profile);
	const dispatch = useDispatch();

	useEffect(() => {
		if (item.manager.role === '') {
			getProfile(dispatch);
		}
	}, [item.manager.role, dispatch]);

	switch (item.manager.role) {
		case '':
			return null;
		case ADMIN:
		case SUPPLIER_ADMIN:
			return <UserForm match={match} />;
		case COMPANY_MANAGER:
		case COMPANY_WORKER:
		case OFFICE_MANAGER:
		case OFFICE_WORKER:
		default:
			return <EditCompanyManager match={match} />;
	}
};

Profile.propTypes = {
	match: object,
};

export default Profile;
