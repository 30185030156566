// Import helpers
import { TABLE_OPTIONS } from 'helpers';

const TABLE_CACHE_LS_KEY = 'telgani-table-cache';

export const tableCache = {
	setTableColumns: (tableColumns) => {
		const pathName = window.location.pathname.slice(1);

		const currentCache = JSON.parse(localStorage.getItem(TABLE_CACHE_LS_KEY));

		const newCache = {
			...currentCache,
			[pathName]: tableColumns,
		};

		localStorage.setItem(TABLE_CACHE_LS_KEY, JSON.stringify(newCache));
	},

	getTableColumns: () => {
		const pathName = window.location.pathname.slice(1);

		const cache = JSON.parse(localStorage.getItem(TABLE_CACHE_LS_KEY)) || {};

		return cache[pathName] || [];
	},
};

export const INITIAL_OPTIONS = {
	pageSize: TABLE_OPTIONS.PAGE_SIZE,
	pageIndex: TABLE_OPTIONS.PAGE_INDEX,
	sortBy: [],
	globalFilter: '',
};

export const INITIAL_STATE = {
	data: [],
	loading: false,
	pageCount: 0,
	recordsFiltered: 0,
	options: INITIAL_OPTIONS,
	tableColumns: [],
	inputColumns: [],
	resources: {},
	filters: null,
	error: '',
};
