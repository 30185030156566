import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import components
import { ChipLabel } from 'components/elements';

// Import utilities
import { useIsSettledTableCell } from './useIsSettledTableCell';

export const IsSettledTableCell = ({ cell }) => {
	const {
		is_settled: isSettled,
		conditions_met: conditionsMet,
		uuid,
	} = cell.row.original;

	const classes = useStyles({ isSettled });

	const { chipLabel, isSettledText } = useIsSettledTableCell({
		isSettled,
		uuid,
	});

	return (
		<TableCell {...cell.getCellProps()}>
			{conditionsMet ? (
				<ChipLabel className={classes.chip} fullWidth label={isSettledText} />
			) : (
				<ChipLabel fullWidth label={chipLabel} color="gray" />
			)}
		</TableCell>
	);
};

IsSettledTableCell.propTypes = {
	cell: object.isRequired,
};
