import React from 'react';
import { Grid, Box } from '@material-ui/core';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import components
import FormContent from './FormContent';

// Import utilities
import { whitelistSelector } from 'store/selectors';
import { FormRPC } from 'components/utilities';
import {
	fetchPhoneNumberFormResources,
	submitPhoneNumberForm,
} from 'store/actions';

// Import styles
import { useStyles } from 'views/Customers/styles';

export const PhoneNumberForm = () => {
	const { phoneNumberForm } = useStyles();

	return (
		<Box className={phoneNumberForm}>
			<FormRPC
				fetchFormResAction={fetchPhoneNumberFormResources}
				submitFormAction={submitPhoneNumberForm}
				isCardLayout={false}
				goBackPath={URLS.customerRegistrationUrl}
				store={whitelistSelector}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={3}>
						<Grid item xs={9} ls={9}>
							<FormContent />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
