import services from 'services/services';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';

// Import store
import { fetchCorporateSettlementsUrl } from 'store/paths';
import { getCurrentPeriodData } from 'views/b2b/CorporateSettlements/components/Filters/helpers';

export const fetchCorporateSettlements =
	({ options, queryParams, itemId }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const { formattedFromDate, formattedToDate } = getCurrentPeriodData({
				year: new Date().getFullYear(),
				month: new Date().getMonth() + 1,
			});

			const defaultQueryParams =
				queryParams ||
				`?columns[6][search][value]=["${formattedFromDate}","${formattedToDate}"]`;

			const params = combineQueryAndPaginationParams({
				queryParams: defaultQueryParams,
				body,
			});

			const { data } = await services.get(
				fetchCorporateSettlementsUrl({ corporateUuid: itemId, params })
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
