import React from 'react';
import { string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const DefaultTextField = ({ label, value }) => {
	return (
		<Grid container spacing={2} justifyContent="space-between">
			<Grid item>
				<Typography variant="h6">{label}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body1">{value}</Typography>
			</Grid>
		</Grid>
	);
};

DefaultTextField.propTypes = {
	label: string.isRequired,
	value: string,
};

export default DefaultTextField;
