import { BOOKING_COMPENSATION_STATES } from 'helpers';

const {
	REVIEW,
	APPROVED,
	COMPENSATED,
	WAITING,
	INVALID,
} = BOOKING_COMPENSATION_STATES;

export const OPTIONS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: WAITING,
		label: 'bookings.compensation_states.waiting',
	},
	{
		value: REVIEW,
		label: 'bookings.compensation_states.review',
	},
	{
		value: APPROVED,
		label: 'bookings.compensation_states.approved',
	},
	{
		value: COMPENSATED,
		label: 'bookings.compensation_states.compensated',
	},
	{
		value: INVALID,
		label: 'bookings.compensation_states.invalid',
	},
];
