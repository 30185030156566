// Import helpers
import { VALUE_TYPES } from 'helpers';

export const ROWS = (values) => [
	{
		label: 'bookings.invoice_print.payment_details.e_wallet',
		value: values.e_wallet,
		variant: VALUE_TYPES.NEGATIVE,
		isHidden: !values.e_wallet,
	},
	{
		label: 'bookings.invoice_print.payment_details.discount',
		value: values.discount,
		variant: VALUE_TYPES.NEGATIVE,
	},
	{
		label: 'bookings.invoice_print.payment_details.sub_total',
		value: values.sub_total,
	},
	{
		label: 'bookings.invoice_print.payment_details.vat',
		value: values.vat,
		variant: VALUE_TYPES.POSITIVE,
	},
];
