import { useFormState, useForm } from 'react-final-form';
import { useEffect } from 'react';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const useCommissionFields = (section) => {
	const { values } = useFormState();
	const { change } = useForm();
	const { commission } = values;

	const FIELDS = [
		{
			type: FIELD_TYPES.DATE,
			label: 'offices.form.commission_percentage.start_date',
			name: `${section}.start_date`,
			required: true,
			minDate: false,
			md: 6,
		},
		{
			type: FIELD_TYPES.DATE,
			label: 'offices.form.commission_percentage.end_date',
			name: `${section}.end_date`,
			required: true,
			disabled: !commission.start_date,
			minDate: commission.start_date && new Date(commission.start_date),
			md: 6,
		},
		{
			type: FIELD_TYPES.NUMBER,
			label: 'offices.form.commission_percentage.revenue_percentage',
			name: `${section}.percentage`,
			required: true,
		},
	];

	useEffect(() => {
		if (!!commission.start_date && !!commission.end_date) {
			if (commission.start_date > commission.end_date) {
				change(`${section}.end_date`, null);
			}
		}
		// eslint-disable-next-line
	}, [commission.start_date, commission.end_date]);

	return { FIELDS };
};
