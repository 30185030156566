import React from 'react';
import { object } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import components
import { MakeCompensationDialog } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

export const CompensationPreviewItem = ({ compensation }) => {
	const {
		type,
		status,
		method,
		compensation_amount: { nett },
	} = compensation;

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Grid item xs={12} container spacing={2} alignItems="center">
			<Grid item xs={3}>
				<Typography variant="h5">
					{t(`bookings.compensation_history.compensation_types.${type}`)}
				</Typography>
			</Grid>
			<Grid item xs={3}>
				<Typography>
					{t(`bookings.customer_compensation.statuses.${status}`)}
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography align="right">{nett}</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography className={classes.paymentMethod}>{method}</Typography>
			</Grid>
			<Grid item xs={2} className={classes.actionButton}>
				<MakeCompensationDialog
					outerCompensation={compensation}
					shortButtonTitle
				/>
			</Grid>
		</Grid>
	);
};

CompensationPreviewItem.propTypes = {
	compensation: object.isRequired,
};
