import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { updateSupplierComments } from 'store/actions';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { ADMIN } from 'helpers';

export const useCommentsBox = ({ adminsComments }) => {
	const { uuid, discussions } = useSelector(previewBookingSelector);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslations();
	const dispatch = useDispatch();

	const filteredDiscussions =
		discussions?.filter((comment) =>
			adminsComments ? comment.type === ADMIN : comment.type !== ADMIN
		) ?? [];
	const isDiscussionVisible = filteredDiscussions.length > 0;
	const cardTitle = t(
		`bookings.preview_booking.supplier.${
			adminsComments ? 'admin_title' : 'supplier_title'
		}`
	);

	const handleOnSubmit = async (values) => {
		setIsLoading(true);

		const options = {
			id: uuid,
			content: values.content,
			discuss_type: adminsComments ? 'admin' : 'supplier',
		};
		await updateSupplierComments(options)(dispatch);
		setIsLoading(false);
	};

	const onSubmit = ({ event, handleSubmit, form }) => {
		const promise = handleSubmit(event);

		promise &&
			promise.then(() => {
				form.reset();
			});

		return promise;
	};

	return {
		discussions: filteredDiscussions,
		handleOnSubmit,
		onSubmit,
		isDiscussionVisible,
		isLoading,
		cardTitle,
	};
};
