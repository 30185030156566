import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translation
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter, FileFieldAdapter } from 'components/elements';

const CompanyLogo = (props) => {
	const { className, section, ...rest } = props;

	const { submitting, values } = useFormState();
	const {
		logo: { use_company_logo },
	} = values;

	const { t } = useTranslations();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.logo.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							type="checkbox"
							component={CheckboxFieldAdapter}
							name={`${section}.use_company_logo`}
							disabled={submitting}
							labelText={t('offices.form.logo.use_company_logo')}
							color="primary"
						/>
					</Grid>
					{!use_company_logo && (
						<Grid item md={12} xs={12}>
							<Field
								previewFileState={section}
								component={FileFieldAdapter}
								name={`${section}.file_path`}
								fileNameField={`${section}.file_name`}
								disabled={submitting}
							/>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

CompanyLogo.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default CompanyLogo;
