import React from 'react';
import { Form } from 'react-final-form';
import { Box, Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

// Import components
import { AlertMessage, ButtonProgress } from 'components/elements';
import { FormContent } from './components';

// Import helpers
import { validationSchema } from './validation';
import { yupValidator } from 'helpers';

// Import utils
import { useSuspendContractProvider } from '../../context';
import { useTranslations } from 'components/utilities';
import { useSuspendForm } from './useSuspendForm';

// Import styles
import { useStyles } from 'theme/styles/eContract.styles';

export const SuspendForm = () => {
	const { handleOnSubmit, handleOnClose, isLoading } = useSuspendForm();

	const { t } = useTranslations();

	const classes = useStyles();

	const { isFetching, isError, allowSuspendContract, initialValues } =
		useSuspendContractProvider();

	if (isFetching) {
		return (
			<Box className={clsx(classes.container, classes.centerContainer)}>
				<CircularProgress />
			</Box>
		);
	}

	if (isError) {
		return (
			<Box className={classes.container}>
				<AlertMessage
					description={t('common.messages.error_message')}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	if (!allowSuspendContract) {
		return (
			<Box className={classes.container}>
				<AlertMessage
					description={t('contract.suspend_contract_form.no_access_message')}
					title={t('common.error')}
					variant="error"
				/>
			</Box>
		);
	}

	return (
		<Form
			initialValues={initialValues}
			onSubmit={handleOnSubmit}
			validateOnBlur
			validate={(values) =>
				yupValidator({ values, validationSchema: validationSchema() })
			}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Box className={classes.mainFormContainer}>
						<FormContent />
						<Box className={classes.buttonWrapper}>
							<ButtonProgress
								circularProgressSize={28}
								variant="contained"
								isLoading={isLoading}
								disabled={isLoading}
								color="primary"
								type="submit"
							>
								{t('common.buttons.suspend_contract')}
							</ButtonProgress>
							<Button
								variant="outlined"
								color="primary"
								onClick={handleOnClose}
							>
								{t('common.buttons.cancel')}
							</Button>
						</Box>
					</Box>
				</form>
			)}
		/>
	);
};
