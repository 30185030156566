import { useDispatch, useSelector } from 'react-redux';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils
import { useToggle } from 'components/utilities';
import { fetchBookingPreview } from 'store/actions';

export const useTrackingMapTableCell = ({ cell }) => {
	const dispatch = useDispatch();
	const { on, toggle } = useToggle();
	const { uuid } = cell.row.original || {};

	const booking = useSelector(previewBookingSelector);

	const handleDialog = async () => {
		toggle();
		!on &&
			(await fetchBookingPreview({ id: uuid, getBookingOnly: true })(dispatch));
	};

	return { on, handleDialog, cell, booking };
};
