import React from 'react';
import { array, bool } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { CircularProgress, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';
import { CarFilterItem } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useEditBookingProvider } from 'views/Bookings/EditBooking/context';

const CarFilter = ({ cars, carsFetching }) => {
	const { t } = useTranslations();

	const { setItem } = useEditBookingProvider();

	const { values } = useFormState();

	if (carsFetching) {
		return <CircularProgress size={28} />;
	}

	return (
		<Field
			fullWidth
			component={SelectFieldAdapter}
			label={t('bookings.edit_booking.select_car.form.car')}
			margin="dense"
			name="item.uuid"
			variant="outlined"
		>
			{cars.map((car) => (
				<MenuItem
					key={car.uuid}
					value={car.uuid}
					onClick={() => setItem(car, values)}
				>
					<CarFilterItem car={car} />
				</MenuItem>
			))}
		</Field>
	);
};

CarFilter.propTypes = {
	cars: array,
	carsFetching: bool,
};

export default CarFilter;
