import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '12px',
	},
	signatureWrapper: {
		display: 'flex',
		width: '100%',
		height: '200px',
		boxShadow: '0px 10px 54px rgba(8, 68, 64, 0.05)',
		borderRadius: '12px',

		[theme.breakpoints.down('md')]: {
			height: '150px',
		},
	},
	heading: {
		fontSize: '16px',
		fontWeight: '500',
		color: theme.palette.black,
	},
	headerWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));
