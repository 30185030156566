import * as Yup from 'yup';
import i18n from 'i18next';

Yup.addMethod(Yup.array, 'unique', function (message, fieldName) {
	return this.test('unique', message, function (list) {
		const mapper = (obj) => obj[fieldName];
		const set = [...new Set(list.map(mapper))];
		const isUnique = list.length === set.length;
		if (isUnique) {
			return true;
		}

		const idx = list.findIndex((l, i) => mapper(l) !== set[i]);

		return this.createError({
			path: `coordinators.[${idx}].[${fieldName}]`,
			message: message,
		});
	});
});

export const validationSchema = Yup.object().shape({
	coordinators: Yup.array()
		.of(
			Yup.object({
				email: Yup.string().required(i18n.t('common.fields.required_field')),
				name: Yup.string().required(i18n.t('common.fields.required_field')),
			})
		)
		.unique(i18n.t('common.validation.unique'), 'email')
		.unique(i18n.t('common.validation.unique'), 'name'),
});
