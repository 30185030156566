import React from 'react';
import { oneOfType, node, arrayOf, object, func, any } from 'prop-types';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import _ from 'lodash';

// Import components
import TableExportFiltersContent from './TableExportFiltersContent';

// Import utils
import { useTableExportContextProvider } from 'components/context/TableExportProvider/useTableExportContextProvider';
import { useTableContextProvider } from 'components/context';

const TableExportFilters = ({
	children,
	initialValues,
	formatValues,
	setCustomFilters,
}) => {
	const { setFilters } = useTableExportContextProvider();

	// It works only if requests for list and export are the same
	const { inputColumns } = useTableContextProvider();

	const handleSubmit = (values) => {
		const newValues = formatValues
			? formatValues(values, { inputColumns })
			: values;

		if (_.isFunction(setCustomFilters)) {
			setCustomFilters(newValues);
		}

		setFilters(newValues);
	};

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={initialValues}
			render={(props) => (
				// eslint-disable-next-line
				<Box component="form" width="100%" onSubmit={props.handleSubmit}>
					<TableExportFiltersContent>{children}</TableExportFiltersContent>
				</Box>
			)}
		></Form>
	);
};

TableExportFilters.propTypes = {
	children: oneOfType([node, arrayOf(node)]),
	initialValues: object,
	formatValues: func,
	setCustomFilters: any,
};

export default TableExportFilters;
