import { useHistory } from 'react-router-dom';

// Import utils and helpers
import { useProfile, useToggle } from 'components/utilities';
import { URLS } from 'components/routes';

export const useCustomToolbar = () => {
	const { on: isOpen, toggle: togglePlatesFileDialog } = useToggle();

	const { isAdmin } = useProfile();

	const { push } = useHistory();

	const goToAddCarForm = () => push(URLS.addCarListUrl);
	const goToAddPlateForm = () => push(URLS.addPlateUrl);

	return {
		isOpen,
		togglePlatesFileDialog,
		goToAddCarForm,
		goToAddPlateForm,
		isAdmin,
	};
};
