export const COLUMNS_ACCESSORS = {
	BASE_COLUMN: 'base_column',
};

export const companyColumns = [
	{
		Header: 'bookings.table.headers.company',
		accessor: COLUMNS_ACCESSORS.BASE_COLUMN,
		disableSortBy: true,
		isVisible: true,
	},
];

export const officeColumns = [
	{
		Header: 'bookings.table.headers.office',
		accessor: COLUMNS_ACCESSORS.BASE_COLUMN,
		disableSortBy: true,
		isVisible: true,
	},
];
