import React from 'react';
import { func, number, string } from 'prop-types';
import { useFormState } from 'react-final-form';

// Import styles
import { useStyles } from './ScratchMarker.styles';

export const ScratchMarker = ({ setMarkers, icon: Icon, color, counter }) => {
	const classes = useStyles({ color });

	const { values } = useFormState();

	const removeElement = () => {
		const filteredScratches = values.rent_status.sketch_info.filter(
			({ counter: checkedCounter }) => counter !== checkedCounter
		);

		setMarkers(filteredScratches);
	};

	return <Icon onClick={removeElement} className={classes.marker}></Icon>;
};

ScratchMarker.propTypes = {
	counter: number,
	left: number,
	top: number,
	setMarkers: func,
	color: string,
	icon: func,
};
