import React from 'react';
import { oneOf } from 'prop-types';
import { Chip } from '@material-ui/core';

// Import styles
import { useStyles } from './StatusChip.styles';

// Import translations
import { useTranslations } from 'components/utilities';
import { BOOKING_CANCELLATION_STATES } from 'helpers';

const { APPROVED, REJECTED } = BOOKING_CANCELLATION_STATES;

export const StatusChip = ({ cancelReasonStatus }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Chip
			label={t(`common.statuses.${cancelReasonStatus}`)}
			className={classes[cancelReasonStatus || null]}
		/>
	);
};

StatusChip.propTypes = {
	cancelReasonStatus: oneOf([APPROVED, REJECTED]),
};
