import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateExportFilters, updateExportOpenState } from 'store/actions';

// Import helpers
import { carsAvailabilitySelector } from 'store/selectors';

// Import components

// Import utilities
import { useTableExportContextProvider } from 'components/context';

export const useExportFilters = (columns) => {
	const dispatch = useDispatch();

	const { updateColumns, open } = useTableExportContextProvider();

	const { exportFilters } = useSelector(carsAvailabilitySelector);

	const setFilters = (filters) => updateExportFilters(filters)(dispatch);

	useEffect(() => {
		updateColumns(columns);
		// eslint-disable-next-line
	}, [exportFilters, columns.length]);

	useEffect(() => {
		updateExportOpenState(open)(dispatch);
		// eslint-disable-next-line
	}, [open]);

	return {
		setFilters,
	};
};
