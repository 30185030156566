// Import helpers
import { FIELD_TYPES } from 'helpers/variables';

export const FIELDS = [
	{
		name: 'total_amount',
		type: FIELD_TYPES.TEXT,
		label: 'rewards_settlement.fields.total_amount_that_should_be_settled',
		disabled: true,
	},
	{
		name: 'amount',
		type: FIELD_TYPES.TEXT,
		label: 'rewards_settlement.fields.amount_in_sar',
		required: true,
	},
];
