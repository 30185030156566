import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(4),
	},
	buttonWrapper: {
		position: 'fixed',
		right: 0,
		bottom: '30px',
		zIndex: 1,
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: 5,
	},
	button: {
		'&:disabled': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	collapseTitle: {
		[theme.breakpoints.down('md')]: {
			color: theme.palette.primary.main,
			fontSize: 14,
		},
	},
}));
