import React from 'react';
import { object } from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Typography, Button, CircularProgress } from '@material-ui/core';

import useResetPasswordForm from './useResetPasswordForm';
import { RESET_PASSWORD_FIELDS } from './helpers';
import { useStyles } from './styles';

// Import components
import { TextFieldAdapter } from 'components/elements';
import CheckboxFieldAndModal from './CheckboxFieldAndModal';
import { useTranslations } from '../../../components/utilities';

const ResetPasswordForm = ({ history }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { onSignOut, submitStep2, validation, nextStep } = useResetPasswordForm(
		{
			history,
		}
	);

	return (
		<Form
			onSubmit={submitStep2}
			validate={validation}
			render={({ handleSubmit, submitting }) => (
				<form className={classes.form} onSubmit={handleSubmit}>
					<Typography className={classes.title} variant="h2">
						Reset Password
					</Typography>
					{!nextStep && (
						<Field
							className={classes.textField}
							fullWidth
							component={TextFieldAdapter}
							type="number"
							label={t('forget_password.form.code_label')}
							name="code"
							variant="outlined"
							autoComplete="false"
							disabled={submitting}
						/>
					)}
					{!!nextStep && (
						<>
							{RESET_PASSWORD_FIELDS.map(({ label, type, name, component }) => (
								<Field
									key={label}
									className={classes.textField}
									fullWidth
									component={component}
									type={type}
									label={t(label)}
									name={name}
									variant="outlined"
									autoComplete="false"
									disabled={submitting}
								/>
							))}
							<CheckboxFieldAndModal submitting={submitting} />
						</>
					)}

					<div className={classes.wrapper}>
						<Button
							className={classes.continueButton}
							color="primary"
							disabled={submitting}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
						>
							Continue
						</Button>
						{submitting && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</div>
					<Typography color="textSecondary" variant="body1">
						Have an account?
						<Button className={classes.buttonLogout} onClick={onSignOut}>
							Sign in
						</Button>
					</Typography>
				</form>
			)}
		/>
	);
};

ResetPasswordForm.propTypes = {
	history: object,
};

export default ResetPasswordForm;
