import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import utils and helpers
import { fieldNames } from './helpers';
import {
	useVehicleModelList,
	useVehicleYearList,
	useManufacturers,
} from 'queries';

export const useBaseDetails = () => {
	const { values } = useFormState();
	const { change } = useForm();

	const { maker_uuid: manufacturerUuid, year } = values;

	const { manufacturerFieldName, modelFieldName, yearFieldName } = fieldNames();

	const { data: manufacturers, isFetching: isManufacturersLoading } =
		useManufacturers();
	const { data: models, isFetching: isModelsLoading } = useVehicleModelList({
		manufacturerUuid,
	});
	const { data: years, isFetching: isYearsLoading } = useVehicleYearList({
		enabled: !!manufacturerUuid,
		body: { vehicle_maker: { uuid: manufacturerUuid } },
	});

	useEffect(() => {
		const shouldClearYearField = !years.includes(year) && !!years.length;

		if (shouldClearYearField) {
			change(yearFieldName, null);
		}
		//eslint-disable-next-line
	}, [years]);

	return {
		manufacturerFieldName,
		modelFieldName,
		yearFieldName,
		manufacturers,
		models,
		years,
		isManufacturersLoading,
		isModelsLoading,
		isYearsLoading,
	};
};
