import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Divider,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import {
	BusinessHoursFieldAdapter,
	TextFieldAdapter,
} from 'components/elements';

// Import helpers
import { profileDataSelector } from 'store/selectors';
import { OFFICE_MANAGER } from 'helpers';

const OfficeHours = ({ className, section, subSection, ...rest }) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	const { role } = useSelector(profileDataSelector);

	const isOfficeManager = OFFICE_MANAGER === role[0];

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.office_hours.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							name={section}
							component={(props) => (
								<BusinessHoursFieldAdapter
									isOfficeManager={isOfficeManager}
									{...props}
								/>
							)}
							disabled={submitting}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="number"
							label={t('offices.form.office_hours.min_pick_up_time')}
							name={`${subSection}.min_pickup_time`}
							margin="dense"
							variant="outlined"
							disabled={isOfficeManager || submitting}
							required
							inputProps={{ min: 0 }}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

OfficeHours.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
	subSection: PropTypes.string.isRequired,
};

export default OfficeHours;
