import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const AssignedDriverIcon = (props) => (
	<SvgIcon
		width="26"
		height="25"
		viewBox="0 0 26 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16.7992 12.64C16.7992 12.2157 16.6306 11.8087 16.3306 11.5087C16.0305 11.2086 15.6236 11.04 15.1992 11.04H8.79922C8.37487 11.04 7.96791 11.2086 7.66785 11.5087C7.36779 11.8087 7.19922 12.2157 7.19922 12.64"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.19922 12.6396V14.6996C7.19922 14.9118 7.2835 15.1153 7.43353 15.2653C7.58356 15.4154 7.78705 15.4996 7.99922 15.4996H9.19922C9.41139 15.4996 9.61487 15.4154 9.7649 15.2653C9.91493 15.1153 9.99922 14.9118 9.99922 14.6996V14.1708H13.9992V14.6996C13.9992 14.9118 14.0835 15.1153 14.2335 15.2653C14.3836 15.4154 14.587 15.4996 14.7992 15.4996H15.9992C16.2114 15.4996 16.4149 15.4154 16.5649 15.2653C16.7149 15.1153 16.7992 14.9118 16.7992 14.6996V12.6396"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.6035 10.9892L16.7995 10.3916"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.39922 10.9892L7.19922 10.3916"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.0548 8C9.78506 8.00068 9.52341 8.09223 9.31207 8.25988C9.10074 8.42752 8.95205 8.66148 8.89001 8.924L8.38281 11.092L8.89001 8.924C8.95205 8.66148 9.10074 8.42752 9.31207 8.25988C9.52341 8.09223 9.78506 8.00068 10.0548 8H13.946C14.2158 8.00068 14.4774 8.09223 14.6888 8.25988C14.9001 8.42752 15.0488 8.66148 15.1108 8.924L15.618 11.092"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.9997 20.769C12.0896 21.2093 10.0881 21.016 8.29752 20.2183C6.50697 19.4206 5.02469 18.0618 4.07464 16.3473C3.12459 14.6327 2.75837 12.6555 3.03133 10.7144C3.30429 8.77327 4.20159 6.97374 5.58766 5.58766C6.97374 4.20159 8.77327 3.30429 10.7144 3.03133C12.6555 2.75837 14.6327 3.12459 16.3473 4.07464C18.0618 5.02469 19.4206 6.50697 20.2183 8.29752C21.016 10.0881 21.2093 12.0896 20.769 13.9997"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M22.6666 22.9167V23.3334C22.6666 23.5634 22.4799 23.7501 22.2499 23.7501H17.2499C17.0199 23.7501 16.8333 23.5634 16.8333 23.3334V22.9167V22.9101C16.8333 21.6492 18.2741 20.8301 19.7499 20.8301C21.2258 20.8301 22.6666 21.6497 22.6666 22.9101"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.7788 17.0932C21.347 17.6614 21.347 18.5826 20.7788 19.1508C20.2105 19.719 19.2893 19.719 18.7211 19.1508C18.1529 18.5826 18.1529 17.6614 18.7211 17.0932C19.2893 16.5249 20.2105 16.5249 20.7788 17.0932"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
