import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { string, shape } from 'prop-types';

// Import utilities
import { FormRPC, useTranslations } from 'components/utilities';

// Import store actions and selectors
import { fetchProfileFormResources, submitProfileForm } from 'store/actions';
import { companyManagerContextSelector } from 'store/selectors';

// Import helpers
import { replaceCompanyUsersUrl } from 'helpers';
import { INITIAL_VALUES, validation } from './helpers';
import { URLS } from 'components/routes';

// Import styles
import { useStyles } from './styles';

// Import components
import { ProfileDetails } from './components';

const EditCompanyManager = ({
	match: {
		params: { id: userId },
		path,
	},
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { companyUuid } = useSelector(companyManagerContextSelector);

	const isUserCreate = path.includes('create');
	const goBackPath = companyUuid
		? replaceCompanyUsersUrl(companyUuid)
		: userId || isUserCreate
		? URLS.companyManagersUrl
		: URLS.dashboardUrl;

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchProfileFormResources}
				submitFormAction={submitProfileForm}
				isCardLayout={false}
				store={(store) => store.companyManager.form}
				validation={validation}
				initialValues={INITIAL_VALUES}
				title={t('profile.form.title')}
				goBackPath={goBackPath}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<ProfileDetails isUserCreate={isUserCreate} />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};

EditCompanyManager.propTypes = {
	match: shape({
		params: shape({ id: string }),
		path: string,
	}),
};

export default EditCompanyManager;
