import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	tableCell: {
		color: ({ cellColor }) => cellColor,
		fontWeight: 500,
	},
	statusTable: {
		width: '150px',
	},
}));
