import React from 'react';
import { FormControlLabel, Typography } from '@material-ui/core';
import { CustomSwitch } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';
import { useTableExportContextProvider } from 'components/context';

export const TableExportHeader = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { isSelectAllChecked, onSelectAllChange } =
		useTableExportContextProvider();

	return (
		<>
			<FormControlLabel
				control={
					<CustomSwitch
						color="primary"
						checked={isSelectAllChecked}
						onChange={onSelectAllChange}
					/>
				}
				label={
					<Typography variant="h5">{t('table_export.select_all')}</Typography>
				}
				className={classes.switch}
				labelPlacement="start"
			/>
			<Typography variant="h5">{t('table_export.select_columns')}</Typography>
		</>
	);
};
