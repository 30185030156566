export const LOCAL_STORAGE_USER = 'telgani-web';
export const LOCAL_STORAGE_CONTRACT = 'contract';
export const SESSION_STORAGE_MAIN_TABLE_HEADER_REF = 'main-header-table-ref';
export const LOCAL_STORAGE_APP_VERSION = 'app_version';
export const LOCAL_STORAGE_CHIP_COUNT = (reference) =>
	`cache_${reference}-counts`;
export const LOCAL_STORAGE_BOOKING_STATES = 'cache_booking_states';
export const LOCAL_STORAGE_CHOOSABLE_ROLES = 'cache_choosable_roles';
export const LOCAL_STORAGE_DEFAULT_SEO_TYPES = 'cache_default_seo_types';
export const LOCAL_STORAGE_URGENT_STATES = (options) =>
	`cache_urgent_states_${options}`;
export const LOCAL_STORAGE_DASHBOARD_DATA = 'cache_dashboard_data';
export const LOCAL_STORAGE_DASHBOARD_MANAGER_DATA =
	'cache_dashboard_manager_data';
export const LOCAL_STORAGE_COMPANY_DASHBOARD_STATES = (options) =>
	`cache_company_dashboard_states_${options}`;
export const LOCAL_STORAGE_ADMIN_DASHBOARD_STATES = (options) =>
	`cache_admin_dashboard_states_${options}`;
export const LOCAL_STORAGE_CAR_PRICE_LIST = (options) =>
	`cache_car_price_list_${options}`;
export const LOCAL_STORAGE_DELIVERY_PRICES = 'cache_delivery_prices';
export const LOCAL_STORAGE_DELIVERY_DISTANCES = 'cache_delivery_distances';
export const LOCAL_STORAGE_USER_PROFILE = 'cache_user_profile';
export const LOCAL_STORAGE_MANAGER_PROFILE = 'cache_manager_profile';
export const LOCAL_STORAGE_CANCELLATION_REASONS = 'cache_cancellation_reasons';
export const LOCAL_STORAGE_RATING_REASONS = 'cache_rating_reasons';
export const LOCAL_STORAGE_URGENT_ASSISTANCE_REASONS =
	'cache_urgent_assistance_reasons';
export const LOCAL_STORAGE_AIRPORT_LIST = (options) =>
	`cache_airport_list_${options}`;
export const LOCAL_STORAGE_COMPANIES_LIST = (options) =>
	`cache_companies_list_${options}`;
export const LOCAL_STORAGE_MANUFACTURES = 'cache_manufactures';
export const LOCAL_STORAGE_VEHICLE_YEAR_LIST = (options) =>
	`cache_vehicle_year_list_${options}`;
export const LOCAL_STORAGE_PHONE_PREFIXES = 'cache_phone_prefixes';
export const LOCAL_STORAGE_CAR_FILTERS = (options) =>
	`cache_car_filters_${options}`;
export const LOCAL_STORAGE_OFFICES_COLLECTION = 'cache_offices_collection';
export const DELIVERY_AT_AIRPORT_FIXED_PRICE = '20';
