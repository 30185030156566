import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { LicensePreviewField } from './components';

// Import helpers
import { FIELDS_ELM_LICENSE_PREVIEW } from './helpers';

export const LicenseDetailsCardContent = () => {
	return (
		<Grid container spacing={2}>
			{FIELDS_ELM_LICENSE_PREVIEW.map((field) => (
				<LicensePreviewField key={field.accessor} field={field} />
			))}
		</Grid>
	);
};
