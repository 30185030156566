import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import components
import { InfoListItem } from './index';

const TotalPriceInfoListItem = ({ label }) => {
	const { item } = useSelector((store) => store.bookings.form.info.item);

	return (
		<InfoListItem label={label}>
			{item.total_discounted_gross_price} {item.total_discounted_unit}
		</InfoListItem>
	);
};

TotalPriceInfoListItem.propTypes = {
	label: PropTypes.string,
};

export default TotalPriceInfoListItem;
