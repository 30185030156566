import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import {
	updateApproveOrganization,
	updateStateCustomer,
	deleteCustomer,
} from 'store/actions';

// Import helpers
import {
	replaceCustomerPreviewUrl,
	replaceEditCustomerUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { NoteTableCell, CustomerStateCell } from '../../components';
import {
	PreviewTableCell,
	ActiveTableCell,
	DeleteTableCell,
	EditTableCell,
	LinkTableCell,
} from 'components/elements';
import { AnalyzeDriverLicenseTableCell } from '../AnalyzeDriveLicenseTableCell';

const { EDIT, DELETE, PREVIEW } = TABLE_ACTIONS_ACCESSORS;

const { STATE, NAME, APPROVE, NOTE, ANALYZE_DRIVER_LICENSE } =
	COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case NOTE:
				return <NoteTableCell key={cell.column.id} cell={cell} />;

			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditCustomerUrl(cell.row.original.uuid)}
					/>
				);

			case PREVIEW:
				return (
					<PreviewTableCell
						key={cell.column.id}
						cell={cell}
						path={replaceCustomerPreviewUrl(cell.row.original.uuid)}
					/>
				);

			case ANALYZE_DRIVER_LICENSE:
				return (
					<AnalyzeDriverLicenseTableCell key={cell.column.id} cell={cell} />
				);

			case NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceCustomerPreviewUrl(cell.row.original.uuid)}
					>
						{cell.row.original.name || ''}
					</LinkTableCell>
				);

			case STATE:
				return (
					<CustomerStateCell
						key={cell.column.id}
						stateAction={updateStateCustomer}
						cell={cell}
					/>
				);

			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteCustomer}
						cell={cell}
					/>
				);

			case APPROVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateApproveOrganization}
						cell={cell}
						activeText="table.actions.approved"
						inactiveText="table.actions.approve"
						activatedAlertMessage="table.actions.organization_approved"
						deactivatedAlertMessage="table.actions.organization_disapproved"
						isActive={cell.row.values.organization_verified}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
