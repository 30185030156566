import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		paddingTop: 20,
		height: '100%',
		position: 'relative',
	},
	content: {
		height: '100%',
	},
}));
