import * as types from 'store/types';
import services from 'services/services';
import {
	fetchDiscountFormResourcesUrl,
	submitEditDiscountFormUrl,
	submitAddDiscountFormUrl,
} from 'store/paths';

// Import helpers
import { EMPTY_FIELDS } from './helpers';

export const fetchDiscountFormResources = ({
	itemId,
	isEdit,
	cancelToken,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.DISCOUNT_FORM_FETCH_DATA_LOADING,
		});

		const { data } =
			isEdit &&
			(await services.get(fetchDiscountFormResourcesUrl(itemId), cancelToken));

		const payload = data?.data || EMPTY_FIELDS;

		dispatch({
			type: types.DISCOUNT_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: payload,
			},
		});
	} catch (error) {
		const defaultError = { message: '' };
		const response = error.response?.data ?? defaultError;
		dispatch({
			type: types.DISCOUNT_FORM_FETCH_DATA_ERROR,
			payload: response,
		});
	}
};

export const submitEditDiscountForm = ({
	values,
	itemId,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		const { discount } = values;

		await services.post(submitEditDiscountFormUrl(itemId), { discount });

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};

export const submitAddDiscountForm = ({
	values,
	itemId,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		const {
			discount,
			company: { uuid },
		} = values;

		const data = {
			company: { uuid },
			discount,
			organization: { uuid: itemId },
		};

		await services.post(submitAddDiscountFormUrl, data);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
