import React from 'react';
import { Field } from 'react-final-form';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { REASON_DETAILS_ASSESSORS } from './helpers';
import { CheckboxFieldAdapter, TextFieldAdapter } from 'components/elements';

// Import components
import useReasonName from './useReasonName';

export const ReasonName = () => {
	const { submitting, onChange, isMobileVisible } = useReasonName();

	const { t } = useTranslations();

	const sectionName = 'rating_reasons.form.reason_name';

	const { USE_FOR_MOBILE, NAME_MOBILE_EN, NAME_MOBILE_AR } =
		REASON_DETAILS_ASSESSORS;

	return (
		<Card>
			<CardHeader title={t(`${sectionName}.title`)} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={6} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="text"
							label={t(`${sectionName}.reason_en`)}
							name="details.name.en"
							variant="outlined"
							margin="dense"
							disabled={submitting}
							required
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="text"
							label={t(`${sectionName}.reason_ar`)}
							name="details.name.ar"
							variant="outlined"
							margin="dense"
							disabled={submitting}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							type="checkbox"
							component={CheckboxFieldAdapter}
							name={USE_FOR_MOBILE}
							disabled={submitting}
							labelText={t(`${sectionName}.use_for_mobile`)}
							color="primary"
							onChange={onChange}
						/>
					</Grid>
					{isMobileVisible && (
						<>
							<Grid item md={6} xs={12}>
								<Field
									fullWidth
									component={TextFieldAdapter}
									type="text"
									label={t(`${sectionName}.name_mobile_app_en`)}
									name={NAME_MOBILE_EN}
									variant="outlined"
									margin="dense"
									disabled={submitting}
									required={isMobileVisible}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<Field
									fullWidth
									component={TextFieldAdapter}
									type="text"
									label={t(`${sectionName}.name_mobile_app_ar`)}
									name={NAME_MOBILE_AR}
									variant="outlined"
									margin="dense"
									disabled={submitting}
									required={isMobileVisible}
								/>
							</Grid>
						</>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};
