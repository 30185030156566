import React from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { previewBookingSelector } from 'store/selectors';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { CompensationHistoryFields } from './components';

const CompensationHistory = ({ open, onClose }) => {
	const { t } = useTranslations();

	const { compensations } = useSelector(previewBookingSelector);

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<DialogTitle disableTypography>
				<Typography variant="h4">
					{t('bookings.compensation_history.title')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				{compensations.map((compensation) => (
					<CompensationHistoryFields
						compensation={compensation}
						key={compensation.uuid}
					/>
				))}
			</DialogContent>
		</Dialog>
	);
};

CompensationHistory.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

export default CompensationHistory;
