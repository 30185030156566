import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	box: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		marginRight: '7px',
		marginLeft: '7px',
	},
}));

export default useStyles;
