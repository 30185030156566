import React from 'react';
import { fetchCorporateList } from 'store/actions';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow } from './components';
import { TableRPC } from 'components/utilities';

export const CorporateList = () => {
	return (
		<TableRPC
			fetchDataTableAction={fetchCorporateList}
			columnsAccessor={COLUMNS_ACCESSORS}
			toolBarContent={TOOLBAR_CONTENT()}
			tableBodyRow={TableBodyRow}
			columns={columns}
		/>
	);
};
