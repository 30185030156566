import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { useQuery, queryCache } from 'react-query';
import { fetchManagerAssignOffices } from 'store/actions';
import CloseIcon from '@material-ui/icons/Close';
import { func, shape, string } from 'prop-types';

// Import helpers
import { USER_ASSIGN_OFFICES_QUERY } from 'helpers/variables';

// Import components
import * as Dialog from './dialog';

// Import styles
import useStyles from './styles';

const useAssignOffices = ({ userId }) => {
	return useQuery(USER_ASSIGN_OFFICES_QUERY, async () => {
		const data = await fetchManagerAssignOffices({ userId });
		return data;
	});
};

const OfficesDialogContent = ({
	user: { uuid: userId, full_name: userName },
	dialogClose,
}) => {
	const classes = useStyles();
	const { isLoading, data } = useAssignOffices({ userId });
	const [checkedOffices, setCheckedOffices] = useState([]);

	const officesData = data ? Object.values(data) : [];
	const isDataExist = officesData?.length > 0;

	useEffect(() => {
		if (isDataExist) {
			const offices = officesData.filter(
				(office) => office.is_manager_assigned
			);
			setCheckedOffices(offices);
		}

		return () => {};
		// eslint-disable-next-line
	}, [JSON.stringify(officesData)]);

	useEffect(() => {
		return () => {
			queryCache.cancelQueries(USER_ASSIGN_OFFICES_QUERY, { exact: true });
			queryCache.removeQueries(USER_ASSIGN_OFFICES_QUERY, { exact: true });
		};
	}, []);

	return (
		<>
			<IconButton
				aria-label="close"
				onClick={dialogClose}
				className={classes.buttonClose}
			>
				<CloseIcon />
			</IconButton>

			<Dialog.Title userName={userName} />
			<Dialog.Content
				isLoading={isLoading}
				isDataExist={isDataExist}
				officesData={officesData}
				checkedOffices={checkedOffices}
				setCheckedOffices={setCheckedOffices}
			/>
			<Dialog.Actions
				isLoading={isLoading}
				isDataExist={isDataExist}
				dialogClose={dialogClose}
				checkedOffices={checkedOffices}
				userId={userId}
			/>
		</>
	);
};

OfficesDialogContent.propTypes = {
	user: shape({
		uuid: string,
		full_name: string,
	}),

	dialogClose: func,
};

export default OfficesDialogContent;
