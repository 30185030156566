import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

// Import actions
import { changeSettlementStatus } from 'store/actions';

// Import helpers
import { getErrorMessage } from 'helpers';

const useSettlementStatusTableCell = ({
	uuid,
	is_settled
}) => {
    const [isSettled, setIsSettled] = useState(is_settled);

    const [mutate, {
        error,
        isError
    }] = useMutation((data) => changeSettlementStatus(data));
    
	const handleChange = async (ev) => {
        const checked = ev.target.checked;

        setIsSettled(checked);

        mutate({
            bookingUuid: uuid,
            isSettled: checked
        });		
    };

    useEffect(() => {
        setIsSettled(is_settled);
        // eslint-disable-next-line
    }, [uuid]);
    
    useEffect(() => {
        isError && setIsSettled(is_settled);
        // eslint-disable-next-line
    }, [isError]);

	return {
        isSettled,
        error: getErrorMessage(error),
        isError,
		handleChange
	};
};

export default useSettlementStatusTableCell;
