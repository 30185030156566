import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';

// Import utilities and helpers
import { useOfficeRatingInfo } from './useOfficeRatingInfo';
import { useCommonFields } from 'components/utilities';
import { FIELDS } from './helpers';

// Import components
import { RatingFieldAdapter } from 'components/elements';
import { RatedByTitle } from '../../components';

export const OfficeRatingInfo = () => {
	const {
		reasons,
		submitting,
		isDescriptionRequired,
		isRatingFieldDisabled,
		isRatingFieldReadOnly,
	} = useOfficeRatingInfo();

	const { renderFields } = useCommonFields();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<RatedByTitle />
			</Grid>
			<Grid item xs={12}>
				<Field
					component={RatingFieldAdapter}
					name="rating"
					size="large"
					disabled={isRatingFieldDisabled}
					readOnly={isRatingFieldReadOnly}
				/>
			</Grid>
			{FIELDS({ reasons, isDescriptionRequired, submitting }).map(renderFields)}
		</Grid>
	);
};
