export const INITIAL_VALUES = {
	gender_name: false,
	license_number: false,
	organization_name: false,
	type: false,
};

export const ADDITIONAL_INFO_FIELDS = [
	{ name: 'gender_name', label: 'customers.table.headers.gender' },
	{ name: 'license_number', label: 'customers.table.headers.driver_license' },
	{ name: 'organization_name', label: 'customers.table.headers.organization' },
	{ name: 'type', label: 'customers.table.headers.type' },
];

export const setAdditionalColumns = (values, columns) => {
	const additionalColumns = Object.entries(values).reduce((acc, next) => {
		const column = columns.find(
			(item) => item.accessor.split('.')[0] === next[0]
		);
		column && acc.push({ ...column, isHidden: next[1] });
		return acc;
	}, []);

	const convertedColumns = columns.map((item) => {
		const column = additionalColumns.find(
			(additional) => item.accessor === additional.accessor
		);
		return column ? { ...column, isHidden: !column.isHidden } : item;
	});

	return convertedColumns;
};
