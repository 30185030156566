import { useState } from 'react';

// Import utilities
import { useTableContextProvider } from 'components/context';

// Import helpers
import { getInitialValues, setAdditionalColumns } from './helpers';

const useAdditionalInfo = ({ fields }) => {
	const { columns, updateColumns } = useTableContextProvider();

	const [open, setOpen] = useState(false);

	const toggle = () => setOpen((o) => !o);

	const handleOnSubmit = (values) => {
		const additionalColumns = setAdditionalColumns(values, [...columns]);
		updateColumns(additionalColumns);
		toggle();
	};

	const handleOnClear = (reset) => {
		const additionalColumns = setAdditionalColumns(getInitialValues(fields), [
			...columns,
		]);
		updateColumns(additionalColumns);
		reset();
	};

	return {
		onSubmit: handleOnSubmit,
		onClear: handleOnClear,
		open,
		toggle,
	};
};

export default useAdditionalInfo;
