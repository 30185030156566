import React from 'react';
import { Field, useFormState } from 'react-final-form';

// Import components
import { TextFieldAdapter } from 'components/elements';

// import translations
import { useTranslations } from 'components/utilities';

const SourceField = () => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	return (
		<Field
			fullWidth
			component={TextFieldAdapter}
			type="text"
			label={t('campaigns.experience_form.source')}
			name="source.link"
			variant="outlined"
			margin="dense"
			disabled={submitting}
			required
		/>
	);
};

export default SourceField;
