// Import utils and helpers
import { useProfile } from 'components/utilities';
import { getDiscountLabel } from './helpers';

export const useDiscountPreviewField = (data) => {
	const { isAdmin } = useProfile();

	const { amount_gross: amountGross } = data || {};

	const discountPrice = +amountGross;

	let fixedDiscount = discountPrice.toFixed(2);

	if (discountPrice) fixedDiscount = `-${fixedDiscount}`;

	const color = discountPrice ? 'error' : 'initial';

	const label = getDiscountLabel({ data, isAdmin, discountPrice });

	return {
		label,
		fixedDiscount,
		color,
	};
};
