import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

export const useExternalAuthCountriesSelect = ({ options }) => {
	const { submitting, values } = useFormState();

	const { change } = useForm();

	const currentOptions =
		values.authorization_details.external_authorization_countries;

	const authorizationTypeCode =
		values.authorization_details.authorization_type_code;

	const isExternalAuthCountriesRequired = authorizationTypeCode === 2;

	const isSelectHidden =
		authorizationTypeCode === 1 ||
		!authorizationTypeCode ||
		!options.length ||
		submitting;

	const findCountryLabel = (selectedUuid) =>
		options.find(({ id }) => selectedUuid === id)?.name;

	useEffect(() => {
		if (isSelectHidden) {
			change('authorization_details.external_authorization_countries', []);
		}
		//eslint-disable-next-line
	}, [isSelectHidden]);

	return {
		isExternalAuthCountriesRequired,
		isSelectHidden,
		findCountryLabel,
		currentOptions,
		values,
	};
};
