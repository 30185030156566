import { PLATE_NUMBERS_MAP } from 'helpers';

export const arabicToEnglishNumber = (str) => {
	return str.replace(/[٠-٩]/g, (match) => PLATE_NUMBERS_MAP[match]);
};

export const isArabicLetters = (value) => {
	const isArabicRegex = /[\u0600-\u06FF]/g;
	return isArabicRegex.test(value) ? value : null;
};

export const isNumber = (value) => {
	const isNumberRegex = /^\d+$/g;
	return isNumberRegex.test(value) ? value : null;
};
