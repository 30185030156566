import React from 'react';
import { CardHeader as MuiCardHeader } from '@material-ui/core';

const CardHeader = (props) => {
	return (
		<MuiCardHeader	
			titleTypographyProps={{
				variant: 'h4'
            }}
            {...props}
		/>
	);
};

export default CardHeader;
