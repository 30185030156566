import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { MODEL_CAR_YEARS_QUERY } from 'helpers';

export const useModelYears = ({ modelUuid }) => {
	const path = '/v2/supplier/vehicle/model/year/list';

	const body = {
		vehicle_model: {
			uuid: modelUuid,
		},
	};

	const { data, isFetching } = useQuery(
		[MODEL_CAR_YEARS_QUERY, JSON.stringify(body)],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false, enabled: !!modelUuid }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};
