import React from 'react';
import { Field } from 'react-final-form';
import { TextFieldAdapter } from 'components/elements';
import { Box, CircularProgress, InputAdornment } from '@material-ui/core';

// Import components
import useCompensationAmountField from './useCompensationAmountField';

// Import translations
import { useTranslations } from 'components/utilities';

export const CompensationAmountField = () => {
	const { t } = useTranslations();

	const { submitting, isLoading } = useCompensationAmountField();

	return (
		<>
			{isLoading ? (
				<CircularProgress size={16} color="primary" />
			) : (
				<Box>
					<Field
						fullWidth
						component={TextFieldAdapter}
						type="number"
						label={t('bookings.customer_compensation.form.compensation_amount')}
						name="compensation_amount"
						margin="dense"
						variant="outlined"
						disabled={submitting}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{t('currency.sar')}
								</InputAdornment>
							),
						}}
						inputProps={{ step: 0.01, min: 0 }}
					/>
				</Box>
			)}
		</>
	);
};
