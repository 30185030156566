import React from 'react';

// Import components
import CarsLogHistoryRow from './CarsLogHistoryRow';
import { useCarsLogHistory } from '../context';

const CarsLogHistoryBody = () => {
	const { data } = useCarsLogHistory();

	if (!data) return null;

	return (
		<>
			{data.map((row) => (
				<CarsLogHistoryRow key={row.id || row.version} row={row} />
			))}
		</>
	);
};

export default CarsLogHistoryBody;
