import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const toolbarContent = ({ id }) => [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: `/organization/${id}/discount/add`,
		icon: MonetizationOnIcon,
		text: 'discounts.table.toolbar.add',
	},
];
