export const INITIATE_CONTRACT_STEPS = {
	DATA: 'data',
	PRIVACY_POLICY: 'privacy_policies',
	SUBMIT: 'submit',
};

export const PLATE_RENT_STATUS = {
	AVAILABLE: 'available',
	RENTED: 'rented',
};

export const SUSPENSION_CODES = {
	DAMAGED: 'DAMAGE',
	FINANCIAL_CLAIM: 'FINANCIAL_CLAIM',
};

export const SCRATCH_TYPES = {
	SMALL_SCRATCH: 'small-scratch',
	DEEP_SCRATCH: 'deep-scratch',
	VERY_DEEP_SCRATCH: 'very-deep-scratch',
	BEND_IN_BODY: 'bend-in-body',
};

export const FUEL_AVAILABILITIES = [
	'EMPTY',
	'QUARTER_FULL',
	'HALF_FULL',
	'THREE_QUARTERS_FULL',
	'FULL',
];

export const CONTRACT_ADDITIONAL_SERVICES = [
	'disabilities_aids_per_day',
	'internet_per_day',
	'car_seat_per_day',
	'car_delivery',
	'navigation_system_per_day',
];

export const CONTRACT_STATUS = {
	OPENED: 'opened',
	EXTENDED: 'extended',
	SUSPENDED: 'suspended',
	DRAFT: 'draft',
};

export const ORIGINAL_SCRATCH_IMAGE_DIMENSIONS = {
	WIDTH: 897,
	HEIGHT: 429,
};
