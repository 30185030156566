import React from 'react';
import { Typography } from '@material-ui/core';

export const LicenseDetailsCardTitle = () => {
	return (
		<>
			<Typography variant="h5">Driver License Details</Typography>

			<Typography variant="h5">تفاصيل رخصة القيادة</Typography>
		</>
	);
};
