import { useFormState } from 'react-final-form';

const useCarDetails = () => {
	const { submitting, values } = useFormState();

	const { name, base_gross_price, base_currency } = values.item;

	const price = `${base_gross_price || ''} ${base_currency || ''}`;

	return {
		submitting,
		price,
		name,
	};
};

export default useCarDetails;
