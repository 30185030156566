import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	link: {
		position: 'relative',
		minHeight: '100px',
		width: 'fit-content',
	},
	logo: {
		fontSize: '150px',
		height: 'auto',
		marginTop: '20px',
		color: theme.palette.primary.main,
	},
}));
