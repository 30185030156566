import React from 'react';
import { number, object } from 'prop-types';
import get from 'lodash/get';
import { TableRow, TableCell } from '@material-ui/core';

// Import helpers
import { columns, COLUMN_ACCESSOR } from '../../helpers';

// Import components
import { ExternalPartnerCarsSelect } from '../ExternalPartnerCarsSelect';

export const CarsTableRow = ({ row, index }) => (
	<TableRow>
		{columns.map(({ accessor }) => {
			switch (accessor) {
				case COLUMN_ACCESSOR.EXTERNAL_PARTNER:
					return (
						<TableCell key={accessor}>
							<ExternalPartnerCarsSelect index={index} />
						</TableCell>
					);

				default:
					return <TableCell key={accessor}>{get(row, accessor)}</TableCell>;
			}
		})}
	</TableRow>
);

CarsTableRow.propTypes = {
	index: number.isRequired,
	row: object,
};
