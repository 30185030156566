import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		padding: '20px',
		gap: 25,
	},
	detailTitle: {
		fontWeight: 500,
		fontSize: 12,
		color: theme.palette.text.secondary,
	},
	detailValue: {
		fontWeight: 700,
		fontSize: 16,
	},
}));
