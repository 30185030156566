import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	chip: {
		borderRadius: theme.layout.borderRadius.button,
		fontWeight: '500',
		minWidth: theme.layout.width.button,
		border: `1px solid ${theme.palette.primary.main}`,
		backgroundColor: theme.palette.white,
		color: theme.palette.primary.main,
	},
}));
