import myAxios from 'services/myAxiosFactory';
import services from 'services/services';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';
import {
	convertCustomerLimitValues,
	convertConfirmationStepValues,
	getAddCustomerFormData,
	getUpdateCustomerFormData,
} from './helpers';

// Import store
import {
	addNewCustomerToCorporateUrl,
	assignCustomerToCorporateUrl,
	fetchCorporateOverviewUrl,
	setCustomerLimitUrl,
	updateCorporationCustomerUrl,
} from 'store/paths';
import _ from 'lodash';

export const fetchCorporateOverview =
	({ corporateUuid, options, queryParams }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchCorporateOverviewUrl({ corporateUuid, queryParams: params })
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const assignCustomerToCorporate = async ({
	successCallback,
	errorCallback,
	corporateUuid,
	values,
}) => {
	try {
		const convertedValues = convertConfirmationStepValues(values);
		await services.patch(
			assignCustomerToCorporateUrl(corporateUuid),
			convertedValues
		);
		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};

export const addNewCustomerToCorporate = async ({
	successCallback,
	errorCallback,
	corporateUuid,
	values,
}) => {
	try {
		const formData = getAddCustomerFormData(values);

		const { data } = await myAxios().post(
			addNewCustomerToCorporateUrl(corporateUuid),
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);

		const { user_uuid: customerUuid } = data || {};

		await services.patch(
			assignCustomerToCorporateUrl(corporateUuid),
			_.pick(values, ['phone', 'license_number'])
		);

		successCallback(customerUuid);
	} catch (error) {
		errorCallback(error);
	}
};

export const updateCorporationCustomer = async ({
	successCallback,
	errorCallback,
	corporateUuid,
	customerUuid,
	values,
}) => {
	try {
		const formData = getUpdateCustomerFormData(values);

		await myAxios().post(
			updateCorporationCustomerUrl({ corporateUuid, customerUuid }),
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);

		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};

export const setCustomerLimit = async ({
	successCallback,
	errorCallback,
	corporateUuid,
	userUuid,
	values,
}) => {
	try {
		const convertedValues = convertCustomerLimitValues({ values, userUuid });
		await services.post(setCustomerLimitUrl(corporateUuid), convertedValues);
		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};
