export const INITIAL_VALUES = {
	details: {
		name: {
			ar: '',
			en: '',
		},
		description: {
			ar: '',
			en: '',
		},
	},
	order: null,
	is_active: true,
	image: {
		file_path: '',
		file_name: '',
	},
	faq_location: null,
	service_type: null,
};
