import React from 'react';
import { array, string, bool, func, object } from 'prop-types';
import { Checkbox, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useForm } from 'react-final-form';

// Import translations
import { useTranslations } from 'components/utilities';
import { getNewStates } from './helpers';

const useStyles = makeStyles(() => ({
	list: {
		background: 'rgba(0, 0, 0, 0.04)',
		borderRadius: '5px',
		boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
		'&:hover': { background: 'rgba(0, 0, 0, 0.08)' },
	},
}));

const OfficeItem = ({
	carsFieldName,
	officeName,
	officeUuid,
	cars,
	isOpen,
	setIsOpen,
	isEnglish,
	...rest
}) => {
	const classes = useStyles();

	const { change } = useForm();

	const { t } = useTranslations();

	const {
		checkedOffices,
		checkedCars,
		setCheckedCars,
		setCheckedOffices,
	} = rest;

	const office = isEnglish ? officeName.en : officeName.ar;

	const labelId = `checkbox-list-secondary-label-${officeUuid}`;

	const handleChange = () => {
		const { newCheckedOffices, newCheckedCars } = getNewStates({
			checkedOffices,
			checkedCars,
			cars,
			officeUuid,
		});

		change(carsFieldName, newCheckedCars);

		setCheckedOffices(newCheckedOffices);
		setCheckedCars(newCheckedCars);
	};

	const handleChosenCarsQuantity = () =>
		cars.filter((car) => checkedCars.includes(car.uuid)).length;

	return (
		<ListItem>
			<Checkbox
				edge="start"
				color="primary"
				onChange={handleChange}
				checked={checkedOffices.includes(officeUuid)}
				inputProps={{
					'aria-labelledby': labelId,
				}}
			/>
			<ListItem
				button
				onClick={() => setIsOpen(!isOpen)}
				className={classes.list}
			>
				<ListItemText
					id={labelId}
					primary={office}
					secondary={`${t(
						'cars.form.cars.selected'
					)} ${handleChosenCarsQuantity()}/${cars.length}`}
					primaryTypographyProps={{ variant: 'body1' }}
				/>
				{isOpen ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
		</ListItem>
	);
};

OfficeItem.propTypes = {
	carsFieldName: string.isRequired,
	cars: array.isRequired,
	officeName: object.isRequired,
	officeUuid: string.isRequired,
	isEnglish: bool.isRequired,
	isOpen: bool.isRequired,
	setIsOpen: func.isRequired,
};

export default OfficeItem;
