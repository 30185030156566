import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { Checkbox, Grid, MenuItem } from '@material-ui/core';
import { string } from 'prop-types';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { services } from './helpers';

export const ServiceFilter = ({ name }) => {
	const { submitting, values } = useFormState();

	const { t } = useTranslations();

	const currentServices = values[name] || [];

	const findServiceLabel = (name) => {
		const label = services.find((service) => service === name);
		return label ? t(`cars_availability.services.${label}`) : '';
	};

	return (
		<Grid container item xs={3} md={3} alignItems="center">
			<Field
				format={(value) => (Array.isArray(value) ? value : [])}
				label={t('cars_availability.services.title')}
				component={MultiselectFiledAdapter}
				labelextractor={findServiceLabel}
				disabled={submitting}
				variant="outlined"
				margin="dense"
				type="select"
				name={name}
				fullWidth
			>
				{services.map((service) => (
					<MenuItem key={service} value={service}>
						<Checkbox
							checked={currentServices.includes(service)}
							color="primary"
						/>
						{t(`cars_availability.services.${service}`)}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

ServiceFilter.propTypes = {
	name: string.isRequired,
};
