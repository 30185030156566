import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Button, Box } from '@material-ui/core';
import ultralightCopy from 'copy-to-clipboard-ultralight';

import BookmarkIcon from '@material-ui/icons/Bookmark';

// Import utils
import { useTranslations, useMessage } from 'components/utilities';

// Import styles
import { useStyles } from './CopyToClipboard.styles';
import clsx from 'clsx';

export const CopyToClipboard = ({ actions = [] }) => {
	const { t } = useTranslations();

	const { message } = useMessage();

	const classes = useStyles();

	const handleOnClick = (value) => {
		if (ultralightCopy(value)) {
			message.success(t('common.messages.copied', { value }));
		} else {
			message.success(t('common.messages.error_message'));
		}
	};

	return (
		<Box>
			{actions.map(({ value, buttonText, disabled }, index) => (
				<Button
					size="small"
					onClick={() => handleOnClick(value)}
					endIcon={
						<BookmarkIcon
							fontSize="small"
							color="primary"
							className={clsx({
								[classes.disabledIcon]: disabled,
							})}
						/>
					}
					key={index}
					disabled={disabled}
				>
					{t(`common.buttons.${buttonText}`)}
				</Button>
			))}
		</Box>
	);
};

CopyToClipboard.propTypes = {
	actions: arrayOf(
		shape({
			value: string,
			buttonText: string,
		})
	),
};
