import React from 'react';
import { object, array, func } from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './AutocompleteFieldAdapter.styles';

export const AutocompleteFieldAdapter = ({
	input,
	meta,
	options,
	filterOptions,
	...rest
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const error = meta.touched && (meta.error || meta.submitError) ? true : false;
	const errorText =
		meta.touched && (meta.error || meta.submitError)
			? t(meta.error) || meta.submitError
			: '';

	const handleOnChange = (ـ, option) => {
		rest?.inputProps?.onChange && rest.inputProps.onChange(input);
		input.onChange(option);
	};

	const autocompleteValue = options.includes(input.value) ? input.value : null;

	return (
		<Autocomplete
			disableClearable
			options={options}
			filterOptions={filterOptions}
			onChange={handleOnChange}
			className={classes.autocomplete}
			value={autocompleteValue}
			renderInput={(params) => (
				<TextField
					{...input}
					{...rest}
					{...params}
					error={error}
					helperText={errorText}
				/>
			)}
		/>
	);
};

AutocompleteFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	options: array.isRequired,
	filterOptions: func,
};
