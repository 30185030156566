import { Filters } from './components';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Filters,
		customKey: 'filters',
	},
];

export const FILTERS_INITIAL_VALUES = {
	company_uuid: 'all',
	office_uuid: 'all',
	city_uuid: 'all',
	car_active: 'all',
	year: 0,
	model_uuid: 'all',
	services: '[]',
	car_type: '[]',
	car_availability_range: '[]',
};
