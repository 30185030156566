import React from 'react';
import { bool, func, object } from 'prop-types';
import { TableCell, Checkbox } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

export const SelectTableCell = ({
	cell,
	onChange,
	checked,
	hideSelect,
	indeterminate = false,
}) => {
	const classes = useStyles({ hideSelect });

	return (
		<TableCell
			className={classes.cell}
			key={cell.column.id}
			{...cell.getCellProps()}
		>
			<Checkbox
				className={classes.checkbox}
				onChange={onChange}
				color="primary"
				checked={checked}
				indeterminate={checked && indeterminate}
			/>
		</TableCell>
	);
};

SelectTableCell.propTypes = {
	cell: object.isRequired,
	onChange: func,
	checked: bool,
	hideSelect: bool,
	indeterminate: bool,
};
