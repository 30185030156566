import React from 'react';
import { useSelector } from 'react-redux';
import { object } from 'prop-types';
import {
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Box,
} from '@material-ui/core';

// Import store
import { officeCarsItemSelector } from 'store/selectors';

//Import styles
import { useStyles } from './ExternalPartnerCarsTable.styles';

// Import helpers
import { columns } from './helpers';

// Import components
import { CarsTableRow } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';

export const ExternalPartnerCarsTable = () => {
	const { office_cars: officeCars } = useSelector(officeCarsItemSelector);

	const { t } = useTranslations();

	const transKey = 'cars.map_external_partner_cars';

	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							{columns.map(({ header, accessor }) => (
								<TableCell key={accessor}>
									{t(`${transKey}.${header}`)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{officeCars.map((row, index) => (
							<CarsTableRow key={row.uuid} index={index} row={row} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

ExternalPartnerCarsTable.propTypes = {
	externalData: object,
};
