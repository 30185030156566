import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	labelBox: {
		position: 'relative',
		backgroundColor: theme.palette.white,
		borderRadius: '2px',
		padding: '4px 16px',
		minWidth: '154px',
		textAlign: 'center',
	},
	label: {
		color: theme.palette.purple.main,
		fontSize: '14px',
		fontWeight: '400',
	},
}));
