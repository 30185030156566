import services from 'services/services';
import {
	changeCancelReasonStatusByCustomerUrl,
	fetchCancellationBookingsByCustomerUrl,
} from 'store/paths';

export const fetchCancellationBookingsByCustomer =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const defaultQueryParams = '?columns[3][search][value]=review';

			// Get response
			const { data } = await services.post(
				fetchCancellationBookingsByCustomerUrl(
					queryParams || defaultQueryParams
				),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const changeBookingCancelReasonStatusByCustomer = async ({
	uuid,
	status,
	cancelToken,
	cancel_reason_uuid,
}) => {
	try {
		const body = { status, cancel_reason_uuid };

		await services.put(
			changeCancelReasonStatusByCustomerUrl(uuid),
			body,
			cancelToken
		);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};
