import { t } from 'i18next';

// Import helpers
import {
	BOOKING_CANCELLATION_REASONS_TYPES,
	FIELD_TYPES,
	KPI_TYPES,
} from 'helpers';

export const REASON_DETAILS_FIELDS = [
	{
		type: FIELD_TYPES.CHECKBOX,
		name: 'show_for_agent',
		label: 'cancellation_reasons.form.reason_details.show_for_agent',
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		name: 'operation_validation',
		label: 'cancellation_reasons.form.reason_details.operation_validation',
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		name: 'accounting_validation',
		label: 'cancellation_reasons.form.reason_details.accounting_validation',
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'type',
		label: 'cancellation_reasons.form.reason_details.cancellation_type',
		options: BOOKING_CANCELLATION_REASONS_TYPES,
		labelExtractor: (value) => t(`bookings.cancellation_types.${value}`),
		keyExtractor: (row) => row,
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.SELECT,
		name: 'kpi_type',
		label: 'cancellation_reasons.form.reason_details.kpi_type.title',
		options: Object.values(KPI_TYPES),
		keyExtractor: (type) => type,
		labelExtractor: (type) =>
			t(`cancellation_reasons.form.reason_details.kpi_type.${type}`),
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'details.name.en',
		label: 'cancellation_reasons.form.reason_details.reason_en',
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'details.name.ar',
		label: 'cancellation_reasons.form.reason_details.reason_ar',
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'details.description.en',
		label: 'cancellation_reasons.form.reason_details.description_en',
		md: 6,
		xs: 12,
	},
	{
		type: FIELD_TYPES.TEXT,
		name: 'details.description.ar',
		label: 'cancellation_reasons.form.reason_details.description_ar',
		md: 6,
		xs: 12,
	},
];
