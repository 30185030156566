import { combineReducers } from 'redux';

// Import reducers
import form from './partners_form_reducer';
import partnersPositionsForm from './partners_positions_form_reducer';

export default combineReducers({
	form,
	partnersPositionsForm,
});
