import React from 'react';
import { object } from 'prop-types';
import { TableCell, Link } from '@material-ui/core';
import { useStyles } from '../../styles';

// Import components
import { useCompanyNameTableCell } from './useCompanyNameTableCell';

export const CompanyNameTableCell = ({ cell }) => {
	const { company_uuid: companyUuid, company_name: companyName } =
		cell.row.original;
	const { handleOnClick } = useCompanyNameTableCell(companyUuid);
	const classes = useStyles();

	return (
		<TableCell
			className={classes.companyNameTableCell}
			{...cell.getCellProps()}
		>
			<Link className={classes.link} component="button" onClick={handleOnClick}>
				{companyName}
			</Link>
		</TableCell>
	);
};

CompanyNameTableCell.propTypes = {
	cell: object,
};
