import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		justifyContent: 'space-between',
	},
	link: {
		margin: '0 6px',
	},
}));
