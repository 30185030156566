import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import components
import {
	YearAndMonthTableCell,
	AttachmentTableCell,
	BookingIdsTableCell,
} from '../../components';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { COLUMNS_ACCESSORS } from '../../columns';

export const TableBodyRow = ({ row }) => {
	const { t } = useTranslations();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const { settled_amount_gross: settledAmount } = cell.row.original;

		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.YEAR_AND_MONTH:
				return (
					<YearAndMonthTableCell
						cell={cell}
						key={cell.column.id}
						{...cell.getCellProps()}
					/>
				);

			case COLUMNS_ACCESSORS.BOOKING_ID:
				return (
					<BookingIdsTableCell
						cell={cell}
						key={cell.column.id}
						{...cell.getCellProps()}
					/>
				);

			case COLUMNS_ACCESSORS.AMOUNT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{settledAmount} {t('currency.sr')}
					</TableCell>
				);

			case COLUMNS_ACCESSORS.ATTACHMENT:
				return <AttachmentTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
