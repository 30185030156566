import React from 'react';
import { array } from 'prop-types';
import { Typography as MuiTypography, Grid } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Import styles
import { useStyles } from '../../styles';

export const Comments = ({ discussions }) => {
	const classes = useStyles();

	return (
		<PerfectScrollbar>
			<Grid className={classes.root} item container spacing={3}>
				{discussions.map(({ author, created_at, content }) => (
					<Grid key={created_at} item lg={12} xs={12}>
						<div className={classes.item}>
							<div className={classes.info}>
								<MuiTypography className={classes.name} component="span">
									{author}
								</MuiTypography>
								<MuiTypography className={classes.date} component="span">
									( {created_at} ):
								</MuiTypography>
							</div>
							<MuiTypography className={classes.content} component="p">
								{content}
							</MuiTypography>
						</div>
					</Grid>
				))}
			</Grid>
		</PerfectScrollbar>
	);
};

Comments.propTypes = {
	discussions: array.isRequired,
};
