import services from 'services/services';
import {
	fetchCustomersUrl,
	updateApproveOrganizationUrl,
	updateActiveCustomerUrl,
	updateStateCustomerUrl,
	deleteCustomerUrl,
	analyzeCustomerDriverLicenseUrl,
} from 'store/paths';

export const fetchCustomers =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			// Get response
			const { data } = await services.post(
				fetchCustomersUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateApproveOrganization =
	({ isActive, id }) =>
	async () => {
		await services.put(updateApproveOrganizationUrl(id, isActive));
	};

export const updateActiveCustomer =
	({ isActive, id }) =>
	async () => {
		await services.put(updateActiveCustomerUrl(id, isActive));
	};

export const updateStateCustomer =
	({ action, id }) =>
	async () => {
		await services.put(updateStateCustomerUrl(id, action));
	};

export const deleteCustomer =
	({ id }) =>
	async () => {
		await services.delete(deleteCustomerUrl(id));
	};

export const analyzeCustomerDriverLicense = async (uuid) =>
	await services.post(analyzeCustomerDriverLicenseUrl(uuid));
