import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	IMAGE: 'image',
	ACTIVE: 'active',
	DROP_CITIES: 'drop_cities',
	USERS: 'users',
	OFFICES: 'offices',
	EMPLOYEE_PERFORMANCE_SETTINGS: 'employee_performance_settings',
	EMPLOYEE_PERFORMANCE_TABLE: 'employee_performance_table',
};

export const columns = ({ lng }) => [
	{
		Header: 'company.table.headers.logo',
		accessor: COLUMNS_ACCESSORS.IMAGE,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.company_name',
		accessor: `name.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.contact_person',
		accessor: 'contact_person',
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.contact_number',
		accessor: 'contact_phone',
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.city',
		accessor: 'city',
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.drop_cities',
		accessor: COLUMNS_ACCESSORS.DROP_CITIES,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.users',
		accessor: COLUMNS_ACCESSORS.USERS,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.offices',
		accessor: COLUMNS_ACCESSORS.OFFICES,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.total_cars_availability',
		accessor: 'active_cars_count',
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.rewards_table',
		accessor: COLUMNS_ACCESSORS.EMPLOYEE_PERFORMANCE_TABLE,
		disableSortBy: true,
	},
	{
		Header: 'company.table.headers.rewards_settings',
		accessor: COLUMNS_ACCESSORS.EMPLOYEE_PERFORMANCE_SETTINGS,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
