import { makeStyles } from '@material-ui/styles';

import { BOOKING_STATES_TYPES } from './helpers';

export const useStyles = makeStyles((theme) => ({
	chip: {
		borderRadius: theme.layout.borderRadius.button,
		textTransform: 'uppercase',
		fontWeight: '500',
		minWidth: theme.layout.width.button,
	},

	[BOOKING_STATES_TYPES.SUCCESS]: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.primary.main,
	},

	[BOOKING_STATES_TYPES.ERROR]: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.main,
	},

	[BOOKING_STATES_TYPES.WARNING]: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.warning.main,
	},

	[BOOKING_STATES_TYPES.INFO]: {
		backgroundColor: theme.palette.info.light,
		color: theme.palette.info.main,
	},

	[BOOKING_STATES_TYPES.DEFAULT]: {
		backgroundColor: theme.palette.info.light,
		color: theme.palette.info.main,
	},
}));
