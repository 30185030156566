import { format, add } from 'date-fns';

// Import helpers
import { INITIAL_VALUES } from 'views/Company/EmployeeRewardingSettings/helpers';

const addDaysFromToday = (num) => add(new Date(), { days: num });

export const adjustFormData = ({
	date,
	date_to,
	company_manager_reward_price,
	office_manager_reward_price,
	driver_reward_price,
	...rest
}) => ({
	date: date ? new Date(date) : addDaysFromToday(1),
	date_to: date_to ? new Date(date_to) : addDaysFromToday(8),
	company_manager_reward_price: company_manager_reward_price || 0,
	office_manager_reward_price: office_manager_reward_price || 0,
	driver_reward_price: driver_reward_price || 0,
	...rest,
});

export const convertFormValues = (values) => {
	const { is_active } = values;

	if (!is_active) return INITIAL_VALUES;

	return {
		...values,
		date: format(values.date, 'yyyy-MM-dd'),
		date_to: format(values.date_to, 'yyyy-MM-dd'),
		company_manager_reward_price: values.company_manager_reward_price || null,
		office_manager_reward_price: values.office_manager_reward_price || null,
		driver_reward_price: values.driver_reward_price || null,
	};
};
