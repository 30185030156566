// Import store
import { fetchRevenueReportUrl } from 'store/paths';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';
import nodeApisAxios from 'services/nodeApisAxiosFactory';

export const fetchRevenueReport =
	({ queryParams, cancelToken, options }) =>
	async () => {
		let axios = nodeApisAxios();

		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			if (pageSize <= 1)
				return Promise.resolve({ data: { input: { columns: [] } } });

			const { data } = await axios.get(
				fetchRevenueReportUrl(
					combineQueryAndPaginationParams({ queryParams, body })
				),
				{ cancelToken }
			);

			const result = { data: data?.data };

			return Promise.resolve({ data: result });
		} catch (error) {
			return Promise.reject(error);
		}
	};
