import React from 'react';
import { object } from 'prop-types';
import { Form } from 'react-final-form';
import { Typography } from '@material-ui/core';

// Import utils
import { useSignInForm } from './useSignInForm';
import { validation } from './validation';

import { FormContent } from './FormContent';

export const SignInForm = ({ classes }) => {
	const { handleOnSubmit } = useSignInForm();

	return (
		<Form
			onSubmit={handleOnSubmit}
			validate={validation}
			render={({ handleSubmit }) => (
				<form className={classes.form} onSubmit={handleSubmit}>
					<Typography className={classes.title} variant="h2">
						Sign in
					</Typography>

					<FormContent />
				</form>
			)}
		/>
	);
};

SignInForm.propTypes = {
	classes: object.isRequired,
};
