import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	icon: {
		marginRight: 10,
	},
	loader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));
