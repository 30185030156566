import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		position: 'relative',
	},
	buttonGroup: {
		boxShadow: 'none',
	},
	popper: {
		position: 'absolute',
		width: '100%',
	},
	button: {
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			gap: '8px',
		},
	},
}));
