import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';

import useMap from './useMap';

const Map = ({ location, onChange }) => {
	const {
		mapCenter,
		markerPosition,
		handleMarkerLoad,
        handleMarkerDragEnd,
        draggableArea
	} = useMap({ location, onChange });

	return (
		<GoogleMap
			zoom={10}
			mapContainerStyle={{
				width: '100%'
			}}
			center={mapCenter}
		>
			<Marker
                draggable
                position={markerPosition}
				onLoad={handleMarkerLoad}
				onDragEnd={handleMarkerDragEnd}		
			/>
            <Circle 
                center={draggableArea.center}
                radius={draggableArea.radius}
                options={{
                    strokeColor: '#00ACA1',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: '#00ACA1',
                    fillOpacity: 0.35,
                }}
            />
		</GoogleMap>
	);
};

Map.propTypes = {
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Map;
