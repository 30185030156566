import { ACTIVE_CELL_STATES } from 'helpers';

export const OPTIONS = [
	{
		value: ACTIVE_CELL_STATES.ACTIVE,
		label: 'common.filters.active',
	},
	{
		value: ACTIVE_CELL_STATES.INACTIVE,
		label: 'common.filters.inactive',
	},
	{
		value: ACTIVE_CELL_STATES.BLOCKED,
		label: 'common.filters.blocked',
	},
];
