import React from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';

// Import Actions and Selectors
import { fetchEmployeePerformanceUsers } from 'store/actions';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow, Export } from './components';

// Import utils
import { useProfile } from 'components/utilities';
import { TableActionsProvider } from './context';

export const EmployeePerformanceUsers = () => {
	const classes = useBaseLayoutStyles();

	const { companyUuid: profileCompanyUuid } = useProfile();
	const { id } = useParams();

	const companyUuid = profileCompanyUuid || id;

	return (
		<TableProvider
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchEmployeePerformanceUsers({ ...options, companyUuid })
			}
		>
			<TableActionsProvider>
				<Box className={classes.root}>
					<Toolbar content={TOOLBAR_CONTENT} />
					<Box className={classes.content}>
						<Table
							isDefaultRow={false}
							tableInnerToolbar={<Export companyUuid={companyUuid} />}
						>
							{({ row }) => <TableBodyRow key={row.id} row={row} />}
						</Table>
					</Box>
				</Box>
			</TableActionsProvider>
		</TableProvider>
	);
};
