import { TextFieldAdapter, SelectFieldAdapter } from 'components/elements';

export const DETAILS_FIELDS = [
	{
		type: 'text',
		name: 'name',
		component: TextFieldAdapter,
		label: 'sliders.form.details.name',
		md: 6,
		xs: 12,
	},
	{
		type: 'text',
		name: 'title',
		component: TextFieldAdapter,
		label: 'sliders.form.details.title',
		md: 6,
		xs: 12,
	},
	{
		type: 'number',
		name: 'position',
		component: TextFieldAdapter,
		label: 'sliders.form.details.position',
		md: 6,
		xs: 12,
	},
	{
		type: 'select',
		name: 'locale',
		component: SelectFieldAdapter,
		label: 'sliders.form.details.locale',
		md: 6,
		xs: 12,
	},
];

export const LOCALE = [
	{
		name: 'Polish',
		locale: 'pl',
	},
	{
		name: 'English',
		locale: 'en',
	},
	{
		name: 'Arabic',
		locale: 'ar',
	},
];
