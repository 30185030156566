import React from 'react';
import { func, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../../../columns';
import {
	numberWithCommas,
	replaceEditPlateUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';

// Import utils
import { useTranslations } from 'components/utilities';
import { deletePlate } from 'store/actions';

// Import components
import { DeleteTableCell, EditTableCell } from 'components/elements';
import { useSubTableContextProvider } from 'components/context';
import { SelectPlateTableCell } from './components';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const TableBodyRow = ({ row, onSelectPlate }) => {
	const { pathname } = useLocation();
	const { fetchData } = useSubTableContextProvider();

	const { t } = useTranslations();

	const isContractPage = pathname.includes('contract');

	const renderCell = (cell) => {
		const { uuid, miles_usage, rent_status, plate_number } = cell.row.original;

		const { id, isHidden } = cell.column;

		if (isHidden) return null;

		switch (id) {
			case COLUMNS_ACCESSORS.ODOMETER: {
				return <TableCell key={id}>{numberWithCommas(miles_usage)}</TableCell>;
			}

			case COLUMNS_ACCESSORS.RENT_STATUS: {
				return (
					<TableCell key={id}>
						{t(`common.cars.headers.${rent_status}`)}
					</TableCell>
				);
			}

			case COLUMNS_ACCESSORS.PLATE_NUMBER: {
				return !isContractPage ? (
					<TableCell key={id}>{plate_number}</TableCell>
				) : (
					<SelectPlateTableCell
						key={id}
						cell={cell}
						onSelectPlate={onSelectPlate}
					/>
				);
			}

			case EDIT: {
				return (
					<EditTableCell
						key={id}
						cell={cell}
						editLinkPath={replaceEditPlateUrl(uuid)}
					/>
				);
			}

			case DELETE: {
				return (
					<DeleteTableCell
						key={id}
						id={uuid}
						deleteAction={deletePlate}
						customFetchData={fetchData}
					/>
				);
			}

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	onSelectPlate: func,
};

TableBodyRow.defaultProps = {
	onSelectPlate: () => {},
};
