import { SelectFieldAdapter } from 'components/elements';

export const COMPANY_ASSIGNMENT_FIELDS = [
	{
		component: SelectFieldAdapter,
		type: 'select',
		name: `name`,
		label: 'discounts.form.company.assign_to_company',
		md: 6,
		xs: 12,
	},
];
