import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOfficeContext } from 'store/actions';

// Import helpers
import { replaceCarsUrl } from 'helpers';

export const useAddCar = () => {
	const { id } = useParams();

	const dispatch = useDispatch();

	const [mounted, setMounted] = useState(false);

	const goBackPath = replaceCarsUrl(id);

	useEffect(() => {
		setOfficeContext(dispatch, id);
		setMounted(true);
	}, [dispatch, id]);

	return {
		mounted,
		goBackPath,
	};
};
