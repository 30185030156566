// Import helpers
import { FIELD_TYPES } from 'helpers/variables';

export const FIELDS = [
	{
		name: 'driver.first_name',
		type: FIELD_TYPES.TEXT,
		label: 'customers.driver_license_list.driver.first_name',
		required: true,
		xs: 12,
		md: 6,
	},
	{
		name: 'driver.last_name',
		type: FIELD_TYPES.TEXT,
		label: 'customers.driver_license_list.driver.last_name',
		required: true,
		xs: 12,
		md: 6,
	},
	{
		name: 'driver.birth_date',
		type: FIELD_TYPES.DATE,
		label: 'customers.driver_license_list.driver.birth_date',
		required: true,
		minDate: false,
	},
	{
		name: 'license.number',
		type: FIELD_TYPES.TEXT,
		label: 'customers.driver_license_list.license.number',
		required: true,
	},
	{
		name: 'license.expire_date',
		type: FIELD_TYPES.DATE,
		label: 'customers.driver_license_list.license.expire_date',
		required: true,
	},
];
