import React from 'react';
import { bool, func } from 'prop-types';

// Import components
import { ExportAndImportDialog } from 'components/elements';

// Import utils
import { exportCarPlatesFile } from 'store/actions';

// Import helpers
import { columns } from './columns';
import { importCarPlatesFileUrl } from 'store/paths';

export const PlatesFile = ({ toggle, isOpen }) => {
	return (
		<ExportAndImportDialog
			toggle={toggle}
			isOpen={isOpen}
			columns={columns}
			fetchDataTableAction={exportCarPlatesFile}
			title="cars.car_list.plate_file_dialog.heading"
			importDataUrl={importCarPlatesFileUrl}
		/>
	);
};

PlatesFile.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired,
};
