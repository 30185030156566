export const ORGANIZATION_SELECT_DEFAULT_VALUE = 'none';

export const CUSTOMER_TYPES = {
	VERIFIED: 'verified',
	TRUSTED: 'trusted',
	NEW: 'new',
};

export const CUSTOMER_WALLET_OPERATIONS = {
	ADD: 'add',
	DEDUCT: 'deduct',
};

export const CUSTOMER_WALLET_REFERENCE_TYPES = {
	BILLING: 'billing',
	CUSTOMER_WALLET_CAMPAIGN: 'customer_wallet_campaign',
};

export const CUSTOMER_WALLET_REFERENCE_IDENTIFIER = {
	CUSTOMER_REGISTERED: 'customer_registered',
	CUSTOMER_BOOKING_COMPENSATED: 'customer_booking_compensated',
	CUSTOMER_EXPERIENCE_SHARED: 'customer_experience_shared',
	CUSTOMER_BOOKING_CANCELLED: 'customer_booking_cancelled',
	BOOKING_CASHBACK: 'booking_cashback',
};
