import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import OtpInput from 'react-otp-input';
import { Grid, Typography, Box } from '@material-ui/core';

// Import styles
import {
	useStyles,
	inputStyles,
	focusStyles,
	containerStyles,
} from './OTPInputs.styles';

// Import utils
import { useTranslations } from 'components/utilities';

export const OTPInputs = () => {
	const [value, setValue] = useState(null);

	const classes = useStyles();

	const { t } = useTranslations();

	const { change } = useForm();

	useEffect(() => {
		change('otp_value', value?.length === 6 ? value : null);
	}, [value, change]);

	return (
		<Box className={classes.container}>
			<Typography variant="h4">
				{t('contract.create_contract_form.submit_step.otp')}
			</Typography>
			<Grid container justifyContent="center" spacing={2}>
				<OtpInput
					shouldAutoFocus
					onChange={(newValue) => setValue(newValue)}
					inputStyle={inputStyles}
					focusStyle={focusStyles}
					containerStyle={containerStyles}
					value={value}
					numInputs={6}
					renderInput={(props) => <input {...props} />}
				/>
			</Grid>
		</Box>
	);
};
