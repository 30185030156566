import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const PARTNERS_POSITIONING_FIELDS = ({ companies, name }) => [
	{
		type: FIELD_TYPES.SELECT,
		name: `${name}.uuid`,
		label: 'cms.partners.form.company_name',
		required: true,
		md: 6,
		options: companies,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[i18next.language],
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: `${name}.position`,
		label: 'cms.partners.form.position',
		required: true,
		md: 4,
	},
];
