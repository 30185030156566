export const COLUMNS_ACCESSORS = {
	YEAR_AND_MONTH: 'year_and_month',
	BOOKING_ID: 'booking_id',
	ATTACHMENT: 'attachment',
	AMOUNT: 'amount',
};

export const columns = [
	{
		Header: 'table.headers.year_and_month',
		accessor: COLUMNS_ACCESSORS.YEAR_AND_MONTH,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.amount',
		accessor: COLUMNS_ACCESSORS.AMOUNT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.attachment',
		accessor: COLUMNS_ACCESSORS.ATTACHMENT,
		disableSortBy: true,
	},
];
