import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

export const ValidatedByTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const {
		is_compensation_validated_by_admin: isAdmin,
		compensation_validated_by: validatedBy,
	} = cell.row.original;

	const validator = isAdmin
		? validatedBy
		: `${t('bookings.table.headers.telgani_system')}`;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{validator}
		</TableCell>
	);
};

ValidatedByTableCell.propTypes = {
	cell: object,
};
