import React from 'react';
import { string } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

export const ActionTableCell = ({ amount }) => {
	const { t } = useTranslations();

	return (
		<TableCell>
			<Typography>
				{t('rewards_settlement.history.settlement_amount')}: {amount}{' '}
				{t('rewards_settlement.history.sar')}
			</Typography>
		</TableCell>
	);
};

ActionTableCell.propTypes = {
	amount: string.isRequired,
};
