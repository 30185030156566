import React from 'react';

// Import helpers
import { formatValues, initialValues } from './helpers';

// Import components
import { FilterFields } from './FilterFields';
import { TableFilters } from 'components/elements';

export const Filters = () => {
	return (
		<TableFilters initialValues={initialValues} formatValues={formatValues}>
			{() => <FilterFields />}
		</TableFilters>
	);
};
