import React from 'react';
import { string } from 'prop-types';
import {
	Card,
	CardHeader,
	Divider,
	CardContent,
	Grid,
} from '@material-ui/core';

// Import components
import WorkplaceInfoFields from './WorkplaceInfoFields';

// Import translations
import { useTranslations } from 'components/utilities';

const WorkplaceInfo = ({ className, section, ...rest }) => {
	const { t } = useTranslations();
	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('customers.form.workplace.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<WorkplaceInfoFields section={section} />
				</Grid>
			</CardContent>
		</Card>
	);
};

WorkplaceInfo.propTypes = {
	section: string.isRequired,
	className: string,
};

export default WorkplaceInfo;
