import React from 'react';
import { Grid } from '@material-ui/core';
import { Field, useFormState, useForm } from 'react-final-form';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

const PendingFilter = () => {
	const { submitting } = useFormState();
	const { change } = useForm();

	const { t } = useTranslations();

	const handleOnChange = (e) => {
		const { checked } = e.target;

		change('is_pending_filter_applied', checked);
		checked && change('is_rejected_filter_applied', false);
	};

	return (
		<Grid container item md={2} alignItems="flex-end">
			<Field
				type="checkbox"
				component={CheckboxFieldAdapter}
				name="is_pending_filter_applied"
				disabled={submitting}
				labelText={t('bookings.filters.is_pending') || ''}
				color="primary"
				onChange={handleOnChange}
			/>
		</Grid>
	);
};

export default PendingFilter;
