// Import utils
import { useFinancialReportProvider } from 'views/FinancialReport/context';
import { useTranslations } from 'components/utilities';

// Import helpers
import { FIELD_TYPES, FINANCIAL_REPORT_TYPES } from 'helpers';
import { reportTypeOptions } from './helpers';

export const useFilterFields = () => {
	const { setReportType, reportType } = useFinancialReportProvider();
	const { t } = useTranslations();

	const isCampaign = reportType === FINANCIAL_REPORT_TYPES.CAMPAIGN;
	const isRevenue = reportType === FINANCIAL_REPORT_TYPES.REVENUE;

	const handleOnTypeChange = (e) => {
		const { value } = e.target;
		setReportType(value);
	};

	const REPORT_TYPE_FILTER = {
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.report_type',
		name: 'report_type',
		xs: 3,
		md: 3,
		options: reportTypeOptions,
		labelExtractor: ({ label }) => t(label),
		keyExtractor: ({ value }) => value,
		onChange: handleOnTypeChange,
		value: reportType,
	};

	return { REPORT_TYPE_FILTER, isCampaign, isRevenue };
};
