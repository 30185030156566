import { useState } from 'react';
import { saveAs } from 'file-saver';

// Import helpers
import { getFileName, getMimeType } from './helpers';

export const useDownloadFileButton = ({ fetchAction, fileName }) => {
	const [isLoading, setLoading] = useState(false);

	const handleOnDownload = async (fileFormat) => {
		setLoading(true);

		try {
			const { data } = await fetchAction(fileFormat);
			const blob = new Blob([data], {
				type: getMimeType({ fileFormat }),
			});

			const fullFileName = getFileName({ fileName, fileFormat });

			saveAs(blob, fullFileName);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	return {
		handleOnDownload,
		isLoading,
	};
};
