export default {
	borderRadius: {
		card: 24,
		button: 12,
		input: 8,
	},
	width: {
		button: 120,
	},
};
