import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import { useProfile, useTranslations } from 'components/utilities';

// Import components
import { FileFieldAdapter } from 'components/elements';

const CompanyLogo = (props) => {
	const { className, section, ...rest } = props;
	const { submitting } = useFormState();

	const { t } = useTranslations();

	const { isAdmin } = useProfile();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('company.form.company_logo.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							previewFileState={section}
							component={FileFieldAdapter}
							name={`${section}.file_path`}
							fileNameField={`${section}.file_name`}
							disabled={submitting || !isAdmin}
							required
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

CompanyLogo.propTypes = {
	className: string,
	section: string.isRequired,
};

export default CompanyLogo;
