import React from 'react';
import { object } from 'prop-types';
import { FormControl } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { v4 } from 'uuid';

// Import components
import { InputError } from 'components/elements';

const RatingFieldAdapter = ({ input, meta, ...rest }) => {
	const error = meta.touched && (meta.error || meta.submitError) ? true : false;

	const { name } = input;

	const uniqueName = React.useMemo(() => {
		return `${name}_${v4()}`;
	}, [name]);

	const handleChange = (ev) => {
		input.onChange({
			target: {
				name,
				value: Number(ev.target.value),
			},
		});
	};

	return (
		<FormControl component="fieldset" error={error}>
			<Rating {...input} {...rest} name={uniqueName} onChange={handleChange} />
			<InputError meta={meta} />
		</FormControl>
	);
};

RatingFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
};

export default RatingFieldAdapter;
