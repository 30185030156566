import services from 'services/services';
import { fetchModelsUrl, deleteModelUrl } from 'store/paths';

export const fetchModels =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			// Get response
			const { data } = await services.post(
				fetchModelsUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const deleteModel =
	({ id }) =>
	async () => {
		try {
			await services.delete(deleteModelUrl(id));
		} catch (error) {
			return Promise.reject(error);
		}
	};
