import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	form: {
		display: 'grid',
		padding: '12px',
		gap: '12px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));
