import React from 'react';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';
import { BOOKINGS_PAGES_TYPES } from 'helpers';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { Export, TableBodyRow } from '../Bookings/components';
import { TableRPC } from 'components/utilities';

// Import actions
import { fetchTransferredBookings } from 'store/actions';

export const TransferredBookings = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			toolBarContent={TOOLBAR_CONTENT}
			tableBodyRow={TableBodyRow}
			export={
				<Export
					pageType={BOOKINGS_PAGES_TYPES.TRANSFERRED}
					fetchDataTableAction={fetchTransferredBookings}
				/>
			}
			fetchDataTableAction={fetchTransferredBookings}
		/>
	);
};
