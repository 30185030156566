import React from 'react';
import { oneOfType, string, number, arrayOf, node } from 'prop-types';
import {
	Card as MuiCard,
	CardHeader as MuiCardHeader,
	CardContent as MuiCardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import styles
import { useCardStyles } from 'theme/styles';

export const Card = ({
	children,
	title,
	actions,
	lg = 6,
	xs = 12,
	...rest
}) => {
	const classes = useCardStyles();

	const showCardHeader = title || actions;

	return (
		<Grid item lg={lg} xs={xs} {...rest}>
			<MuiCard className={classes.root}>
				{showCardHeader && <MuiCardHeader title={title} action={actions} />}
				{showCardHeader && <Divider />}
				<MuiCardContent>{children}</MuiCardContent>
			</MuiCard>
		</Grid>
	);
};

Card.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	actions: oneOfType([arrayOf(node), node]),
	contentClassName: string,
	cardClassName: string,
	title: string,
	lg: number,
	xs: number,
};
