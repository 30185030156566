export const getNewStates = ({
	checkedCars,
	checkedOffices,
	carUuid,
	officeUuid,
	cars,
}) => {
	if (checkedCars.includes(carUuid)) {
		const newCheckedCars = checkedCars.filter((uuid) => uuid !== carUuid);

		const newCheckedOffices = checkedOffices.filter(
			(uuid) => uuid !== officeUuid
		);

		return { newCheckedCars, newCheckedOffices };
	} else {
		const newCheckedCars = [...checkedCars, carUuid];

		const allOfficesCarsSelected = cars.every(({ uuid }) =>
			newCheckedCars.includes(uuid)
		);

		const newCheckedOffices = allOfficesCarsSelected
			? [...checkedOffices, officeUuid]
			: checkedOffices;

		return { newCheckedCars, newCheckedOffices };
	}
};
