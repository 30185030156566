import { makeStyles } from '@material-ui/styles';

const tableBodyCellStyles = (theme) => ({
	color: theme.palette.gray.dark,
	fontWeight: 600,
});

export const useStyles = makeStyles((theme) => ({
	icon: {
		padding: 7,
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
	},
	tableHead: {
		backgroundColor: theme.palette.primary.light,
		'& .MuiTableCell-root': {
			fontWeight: 600,
		},
	},
	title: {
		'& .MuiTypography-root': {
			fontSize: 16,
			fontWeight: 600,
		},
	},
	tableBody: {
		'& .MuiTableCell-root': {
			border: 0,
			paddingTop: '22px',
			paddingBottom: '22px',
			...tableBodyCellStyles(theme),
		},
		'& .MuiTypography-root': {
			...tableBodyCellStyles(theme),
		},
	},
}));
