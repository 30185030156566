import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import utilities
import { useTranslations, useToggle } from 'components/utilities';

// Import components
import { OfficesDialog } from '../OfficesDialog';

const useStyles = makeStyles(() => ({
	selectOfficesTitle: {
		color: '#e53935',
		paddingBottom: 10,
		fontWeight: 500,
	},
}));

const SelectOffices = ({ className, change, ...rest }) => {
	const [checkedOffices, setCheckedOffices] = useState([]);

	const {
		errors,
		values: { offices },
	} = useFormState();

	const { t } = useTranslations();

	const { on, toggle } = useToggle();

	const classes = useStyles();

	return (
		<Box className={className} {...rest}>
			<Box>
				{offices.length === 0 && (
					<Typography className={classes.selectOfficesTitle}>
						{t(errors.offices)}
					</Typography>
				)}

				<Button onClick={toggle} color="primary" variant="contained">
					{t('cars.form.offices.select_offices')}
				</Button>
			</Box>

			<OfficesDialog
				open={on}
				close={toggle}
				change={change}
				checkedOffices={checkedOffices}
				setCheckedOffices={setCheckedOffices}
			/>
		</Box>
	);
};

SelectOffices.propTypes = {
	change: func.isRequired,
	className: string,
};

export default SelectOffices;
