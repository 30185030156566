import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';

// Import utilities
import { useActionButtonsToolbar } from './useActionButtonsToolbar';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './ActionButtonsToolbar.styles';

// Import components
import Export from '../Export';
import { EditPrices } from '../EditPrices';

export const ActionButtonsToolbar = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { actionButtons, isOpen, toggleEditPriceDialog } =
		useActionButtonsToolbar();

	// eslint-disable-next-line
	const renderButton = ({ text, icon: Icon, onClick }) => (
		<Button
			className={classes.button}
			variant="contained"
			onClick={onClick}
			color="primary"
			fullWidth
			key={text}
		>
			{Icon && <Icon className={classes.icon} />}
			{t(text)}
		</Button>
	);

	return (
		<Box>
			<Box className={classes.buttonsContainer}>
				{actionButtons.map(({ to, icon, text, onClick, isLink = true }) => (
					<Box key={text} className={classes.buttonWrapper}>
						{isLink ? (
							<NavLink exact to={to}>
								{renderButton({ text, icon })}
							</NavLink>
						) : (
							renderButton({ text, icon, onClick })
						)}
					</Box>
				))}
				<EditPrices toggle={toggleEditPriceDialog} isOpen={isOpen} />
			</Box>
			<Box className={classes.exportBox}>
				<Export />
			</Box>
		</Box>
	);
};
