import services from 'services/services';
import { changeSettlementStatusUrl } from 'store/paths';

export const changeSettlementStatus = async ({ bookingUuid, isSettled }) => {
	try {
		await services.put(changeSettlementStatusUrl(bookingUuid), {
			is_settled: isSettled,
		});

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};
