import React from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	InputAdornment,
	MenuItem,
	Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field } from 'react-final-form';
import { object, bool, func, string } from 'prop-types';

// Import components
import {
	TextFieldAdapter,
	ButtonProgress,
	SelectFieldAdapter,
	FileFieldAdapter,
} from 'components/elements';

import useMakeCompensationFields from './useMakeCompensationFields';
import BasePriceRequestFields from '../BasePriceRequestFields';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { BOOKING_COMPENSATION_METHODS } from 'helpers';

const MakeCompensationFields = ({
	booking,
	onClose,
	isInModal,
	isInvalidButtonVisible,
	outerCompensation,
	location,
}) => {
	const { t } = useTranslations();

	const {
		bankNameInput,
		bankNameOptions,
		sectionName,
		submitButtonLabel,
		invalidButtonLabel,
		token,
		submitting,
		isLoading,
		isBasePriceRequested,
		onBasePriceSubmit,
		handleOnInvalidButton,
		onBankNameChange,
		isInvalidButtonLoading,
		isBankPaymentMethod,
		showInvalidButton,
		compensationAmountOptions,
	} = useMakeCompensationFields({
		booking,
		isInModal,
		onClose,
		outerCompensation,
		location,
		isInvalidButtonVisible,
	});

	if (isLoading) {
		return <CircularProgress size={24} />;
	}

	if (isBasePriceRequested) {
		return (
			<BasePriceRequestFields
				onBasePriceSubmit={onBasePriceSubmit}
				isLoading={isLoading}
				section={sectionName}
			/>
		);
	}

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<Field
						fullWidth
						component={TextFieldAdapter}
						type="number"
						label={t(`${sectionName}.form.compensation_amount`)}
						name="compensation_amount"
						variant="outlined"
						margin="dense"
						disabled={submitting}
						inputProps={compensationAmountOptions}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Field
						fullWidth
						component={SelectFieldAdapter}
						label={t(`${sectionName}.form.compensation_method`)}
						name="method"
						variant="outlined"
						margin="dense"
						disabled={submitting}
						required
					>
						{Object.values(BOOKING_COMPENSATION_METHODS).map((value) => (
							<MenuItem key={value} value={value}>
								{t(`bookings.compensation_methods.${value}`)}
							</MenuItem>
						))}
					</Field>
				</Grid>

				{isBankPaymentMethod && (
					<>
						<Grid item xs={12}>
							<Typography>{t(`${sectionName}.form.cta_message`)}</Typography>
						</Grid>

						<Grid item xs={12} md={6}>
							<Autocomplete
								options={bankNameOptions}
								freeSolo
								value={bankNameInput.value}
								onChange={onBankNameChange}
								renderInput={(params) => (
									<Field
										{...params}
										fullWidth
										component={TextFieldAdapter}
										type="text"
										label={t(`${sectionName}.form.bank_name`)}
										name={bankNameInput.name}
										variant="outlined"
										margin="dense"
										disabled={submitting}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<Field
								fullWidth
								component={TextFieldAdapter}
								type="text"
								label={t(`${sectionName}.form.iban`)}
								name="iban"
								variant="outlined"
								margin="dense"
								disabled={submitting}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">SA</InputAdornment>
									),
								}}
							/>
						</Grid>
					</>
				)}

				<Grid item xs={12} md={6}>
					<Typography>{t(`${sectionName}.form.contract`)}</Typography>
					<Box marginTop={1}>
						<Field
							previewFileState="contract_file"
							component={FileFieldAdapter}
							name="contract_file.file_path"
							fileNameField="contract_file.file_name"
							disabled={submitting}
							uploadButtonWidth={12}
							token={token}
						/>
					</Box>
				</Grid>

				<Grid
					item
					xs={12}
					direction="row"
					container
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Grid item xs>
						{showInvalidButton && (
							<ButtonProgress
								color="primary"
								variant="outlined"
								type="button"
								onClick={handleOnInvalidButton}
								isLoading={isInvalidButtonLoading}
								disabled={isInvalidButtonLoading}
								circularProgressSize={28}
							>
								{t(invalidButtonLabel)}
							</ButtonProgress>
						)}
					</Grid>
					<Grid container item spacing={1} xs justifyContent="flex-end">
						{!isInModal && (
							<Grid item>
								<Button
									disabled={submitting}
									onClick={onClose}
									color="primary"
									variant="outlined"
								>
									{t('common.buttons.cancel')}
								</Button>
							</Grid>
						)}
						<Grid item>
							<ButtonProgress
								color="primary"
								variant="contained"
								type="submit"
								isLoading={submitting}
								disabled={submitting}
								circularProgressSize={28}
							>
								{t(submitButtonLabel)}
							</ButtonProgress>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

MakeCompensationFields.propTypes = {
	booking: object.isRequired,
	onClose: func.isRequired,
	isInModal: bool.isRequired,
	isInvalidButtonVisible: bool,
	outerCompensation: object,
	location: string,
};

export default MakeCompensationFields;
