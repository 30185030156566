export const numberWithCommas = (number) =>
	number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const toFixedWithoutZeros = (num, precision) =>
	num ? num.toFixed(precision).replace(/\.0+$/, '') : num;

export const numberOrdinalSuffix = (number) => {
	const numString = String(number);
	const lastNumbers = +numString.slice(-2);
	if (lastNumbers > 3 && lastNumbers < 21) return 'th';
	switch (lastNumbers % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
