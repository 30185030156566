import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ALL_CAR_MODELS_QUERY } from 'helpers';

const useCarModels = () => {
	const path = 'v2/vehicle/model/list';

	const body = { start: 0, length: -1 };

	const { data, isFetching, isError } = useQuery(
		ALL_CAR_MODELS_QUERY,
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};

export default useCarModels;
