import services from 'services/services';
import { fetchBusinessManagerProfileUrl } from 'store/paths';

export const getCompanyUuid = async () => {
	const {
		data: {
			data: {
				belonging: {
					company: { uuid },
				},
			},
		},
	} = await services.get(fetchBusinessManagerProfileUrl);
	return uuid;
};
