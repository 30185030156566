import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import {
	LOCAL_STORAGE_OFFICES_COLLECTION,
	OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY,
} from 'helpers';

const useOfficesAssignedToUser = () => {
	const officesCollectionUrl = `/v2/supplier/business/manager/offices/collection`;

	const localStorageOfficesCollection = ls.get(
		LOCAL_STORAGE_OFFICES_COLLECTION
	);

	const { data, isFetching, isFetched } = useQuery(
		OFFICES_ASSIGNED_TO_USER_COLLECTION_QUERY,
		async () => await services.get(officesCollectionUrl),
		{ refetchOnWindowFocus: false, enabled: !localStorageOfficesCollection }
	);
	const fetchedData = data?.data.data || [];

	useEffect(() => {
		if (!localStorageOfficesCollection && isFetched && fetchedData.length) {
			ls.set(LOCAL_STORAGE_OFFICES_COLLECTION, fetchedData, {
				ttl: 7 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStorageOfficesCollection, isFetched, fetchedData]);

	const result = localStorageOfficesCollection || fetchedData;

	return { data: result, isFetching };
};

export default useOfficesAssignedToUser;
