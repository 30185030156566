import { colors } from '@material-ui/core';

// Import palette
import palette from '../palette.js';

export default {
	root: {
		borderRadius: 8,
		'& $notchedOutline': {
			borderColor: 'transparent',

			'@media (max-width: 960px)': {
				border: `1px solid ${palette?.gray.border}`,
			},
		},
		'&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
			borderColor: 'transparent',
			'@media (max-width: 960px)': {
				border: `1px solid ${palette?.gray.border}`,
			},
			// Reset on touch devices
			'@media (hover: none)': {
				borderColor: 'transparent',
			},
		},
		'&$focused $notchedOutline': {
			borderColor: colors.teal[500],
		},
		'& .MuiOutlinedInput-notchedOutline legend': { display: 'none' },
		'& .MuiOutlinedInput-notchedOutline ': { top: '.1px', height: 'auto' },

		'& .MuiSelect-root': {
			'@media (max-width: 960px)': {
				minHeight: '20px',
				maxWidth: '100%',
				lineHeight: '20px',
				whiteSpace: 'unset',
			},
		},
		'& .MuiSelect-icon': {
			'@media (max-width: 960px)': {
				color: palette?.gray.icon,
			},
		},
	},
};
