import React from 'react';
import { array, bool, number, string, func } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid, MenuItem } from '@material-ui/core';

// Import helpers
import { getMenuValue } from './helpers';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { SelectFieldAdapter } from 'components/elements';

import { useStyles } from './styles';

const Manufacturer = ({
	vehicles,
	fieldName,
	label,
	manufacturersLoaded = true,
	onChange = () => {},
	isCarList = false,
	md = 12,
	xs = 12,
	isRequired = true,
	showAllOption = false,
}) => {
	const { submitting } = useFormState();

	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const classes = useStyles();

	const history = useHistory();

	const handleClick = () => history.push('/cars/manufacturer/add');

	return (
		<Grid item md={md} xs={xs}>
			{manufacturersLoaded ? (
				<Field
					fullWidth
					component={SelectFieldAdapter}
					type="select"
					label={t(label)}
					name={fieldName}
					margin="dense"
					variant="outlined"
					disabled={submitting}
					onChange={onChange}
					required={isRequired}
				>
					{showAllOption && <MenuItem value="all">{t('common.all')}</MenuItem>}
					{vehicles.map(({ name, uuid }) => (
						<MenuItem key={uuid} value={getMenuValue({ name, fieldName })}>
							{name[lng]}
						</MenuItem>
					))}
					{isCarList && (
						<MenuItem
							className={classes.root}
							onClick={handleClick}
							value="cars.manufacturer.table.toolbar.add_manufacturer"
						>
							+ {t('cars.manufacturer.table.toolbar.add_manufacturer')}
						</MenuItem>
					)}
				</Field>
			) : (
				<CircularProgress size={27} />
			)}
		</Grid>
	);
};

Manufacturer.propTypes = {
	vehicles: array.isRequired,
	fieldName: string.isRequired,
	label: string.isRequired,
	onChange: func,
	manufacturersLoaded: bool,
	isCarList: bool,
	md: number,
	xs: number,
	isRequired: bool,
	showAllOption: bool,
};

export default Manufacturer;
