// ********************  table  ********************

export const fetchBusinessCompanyListUrl = (queryParams) =>
	`/v2/supplier/business/company/list${queryParams}`;

export const updateActiveCompanyUrl = (id, isActive) =>
	`/v2/supplier/business/company/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const deleteCompanyUrl = (id) =>
	`/v2/supplier/business/company/${id}/remove`;

// ********************  form  ********************

export const phonePrefixUrl = `/v2/content/country-phones`;
export const externalPartnersUrl = '/v2/supplier/business/partner/list';
export const paymentMethodListUrl = `/v2/booking/payment-methods`;

export const showBusinessCompanyUrl = (itemId) =>
	`/v2/supplier/business/company/${itemId}/show`;
export const submitCompanyFormUrl = (itemId) =>
	`/v2/supplier/business/company/${itemId ? `${itemId}/update` : 'create'}`;
