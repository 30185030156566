import React from 'react';
import { useSelector } from 'react-redux';

// Import store
import { fetchScheduledBookings } from 'store/actions';
import { isAdminSelector } from 'store/selectors';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table } from 'components/elements';
import { TableBodyRow } from './components';

export const LateDeliveryBookings = () => {
	const classes = useBaseLayoutStyles();

	const isAdmin = useSelector(isAdminSelector);

	return (
		<TableProvider
			columns={columns(isAdmin)}
			fetchDataTableAction={(options) =>
				fetchScheduledBookings({ ...options, isLateDelivery: 1 })
			}
		>
			<div className={classes.root}>
				<div className={classes.content}>
					<Table isDefaultRow={false}>
						{({ row }) => (
							<TableBodyRow isAdmin={isAdmin} key={row.id} row={row} />
						)}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};
