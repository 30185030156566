import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	select: {
		display: 'flex',
		gap: 18,
		width: 100,
		marginRight: 12,
		'& .MuiInputBase-input': {
			backgroundColor: 'transparent',
		},
		'& svg': {
			color: theme.palette.primary.main,
		},
	},
}));
