import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.gray.label,
		borderRadius: '16px',
		padding: '6px 16px',
		margin: 0,
	},
	icon: {
		rotate: '-45deg',
	},
}));
