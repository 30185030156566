// ********************  table  ********************

export const fetchPromoCodesUrl = (queryParams) =>
	`/v2/coupon/list${queryParams}`;

export const updateActivePromoCodeUrl = (id, isActive) =>
	`/v2/coupon/${id}/${isActive ? 'deactivate' : 'activate'}`;

// ********************  form  ********************

export const fetchPromoCodeFormResourcesUrl = (itemId) =>
	`/v2/coupon/${itemId}/show`;

export const submitPromoCodeFormUrl = (itemId, isEdit) =>
	`/v2/coupon/${isEdit ? `${itemId}/update` : `create`}`;

// ********************  promo code booking list  ********************

export const fetchPromoCodeBookingListUrl = (itemId, params) =>
	`/v2/coupon/${itemId}/bookings/list${params}`;
