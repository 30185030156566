import React from 'react';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities and helpers
import {
	useCommonFields,
	useProfile,
	useTranslations,
} from 'components/utilities';
import { FIELDS } from './helpers';

export const ServiceTypes = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	const { isAdmin } = useProfile();

	return (
		<Card>
			<CardHeader title={t('offices.form.service_type.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS({ isAdmin }).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
