import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	TYPE: 'type',
	OPERATION_VALIDATION: 'operation_validation',
};

export const columns = ({ lng }) => [
	{
		Header: 'cancellation_reasons.table.headers.reason',
		accessor: `name.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'cancellation_reasons.table.headers.operation_validation',
		accessor: COLUMNS_ACCESSORS.OPERATION_VALIDATION,
		disableSortBy: true,
	},
	{
		Header: 'cancellation_reasons.table.headers.description',
		accessor: `description.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'cancellation_reasons.table.headers.type',
		accessor: COLUMNS_ACCESSORS.TYPE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
];
