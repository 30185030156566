import React from 'react';
import { func, string } from 'prop-types';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

// Import components
import { Alert } from 'components/elements';
import { HistoryTable } from './components';

// Import utilities
import useCustomerWalletContent from './useCustomerWalletContent';

const CustomerWalletContent = ({ uuid, setCardActions = null }) => {
	const {
		isBalanceError,
		isBalanceVisible,
		isHistoryError,
		balanceError,
		historyError,
		isLoaded,
		balance,
		history,
	} = useCustomerWalletContent({ uuid, setCardActions });

	return (
		<>
			{isLoaded ? (
				<Grid container direction="column" spacing={2}>
					<Grid item>
						{!isBalanceError ? (
							isBalanceVisible && (
								<Typography variant="h6" color="primary">
									{balance}
								</Typography>
							)
						) : (
							<Alert message={balanceError} />
						)}
					</Grid>
					<Grid container item>
						{!isHistoryError ? (
							<HistoryTable rows={history} />
						) : (
							<Grid item xs={12}>
								<Alert message={historyError} />
							</Grid>
						)}
					</Grid>
				</Grid>
			) : (
				<Grid item>
					<CircularProgress size={48} />
				</Grid>
			)}
		</>
	);
};

CustomerWalletContent.propTypes = {
	uuid: string,
	setCardActions: func,
};

export default CustomerWalletContent;
