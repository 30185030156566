// Import helpers
import { FIELD_TYPES } from 'helpers';

export const EXTEND_BOOKING_FIELDS = (minDropDate) => [
	{
		type: FIELD_TYPES.DATE,
		label: 'common.fields.new_drop_off_date',
		name: 'drop_date',
		required: true,
		minDate: minDropDate,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.amount',
		name: `amount`,
		required: true,
		disabled: true,
	},
];
