import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	label: {
		color: theme.palette.gray.label,
		fontFamily: 'Inter',
		fontWeight: '500',
		fontSize: '14px',
		marginBottom: '8px',
	},
}));
