import React from 'react';
import { Grid } from '@material-ui/core';
import { fetchCompanyFormResources, submitCompanyForm } from 'store/actions';

// Import utilities
import { FormRPC, useProfile, useTranslations } from 'components/utilities';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import components
import { SeoCard } from 'components/elements';
import {
	BaseDetails,
	Location,
	Commercial,
	CompanyLogo,
	ExternalSupplier,
	BankInfo,
	PaymentMethods,
	ExtraServices,
} from './components';

// Import styles
import useStyles from './styles';

const EditCompany = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { isAdmin } = useProfile();

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchCompanyFormResources}
				submitFormAction={submitCompanyForm}
				isCardLayout={false}
				store={(store) => store.company.form}
				validation={() => {}}
				initialValues={INITIAL_VALUES}
				title={t('company.form.title')}
				goBackPath={URLS.companiesListUrl}
				isActionButtonsHidden={!isAdmin}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<BaseDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Location section="location" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<CompanyLogo section="logo" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Commercial section="commercial_record" />
						</Grid>
						{isAdmin && (
							<Grid item lg={9} xs={12}>
								<ExternalSupplier section="external" />
							</Grid>
						)}
						<Grid item lg={9} xs={12}>
							<BankInfo section="bank" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ExtraServices />
						</Grid>
						<Grid item lg={9} xs={12}>
							<PaymentMethods section="payment_methods" />
						</Grid>
						{isAdmin && (
							<Grid item lg={9} xs={12}>
								<SeoCard section="seo" />
							</Grid>
						)}
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};

export default EditCompany;
