import React from 'react';
import { SvgIcon } from '@material-ui/core';

const NavigatePrevIcon = (props) => (
	<SvgIcon viewBox="0 0 38 38" {...props}>
		<path
			d="M21.2673 13.2069C20.9674 12.9213 20.4926 12.9328 20.2069 13.2327L15.2056 18.4832C14.9297 18.7728 14.9297 19.2281 15.2056 19.5178L20.2069 24.7682C20.4926 25.0681 20.9674 25.0797 21.2673 24.794C21.5672 24.5083 21.5787 24.0336 21.2931 23.7336L16.7845 19.0005L21.2931 14.2673C21.5787 13.9674 21.5672 13.4926 21.2673 13.2069Z"
			fill="currentColor"
		/>
	</SvgIcon>
);

export default NavigatePrevIcon;
