import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: ({ columnsCount }) => ({
		display: 'grid',
		gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
		alignItems: 'start',
		gap: '1em',
		[theme.breakpoints.down('lg')]: {
			gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr));',
		},
	}),
}));
