import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	fileLabel: {
		color: theme.palette.gray.main,
		fontSize: '14px',
		fontWeight: '500',
		marginBottom: '8px',
	},
}));
