import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utils
import { useProfile, useTranslations } from 'components/utilities';

// Import components
import { FileFieldAdapter, TextFieldAdapter } from 'components/elements';
import { useSelector } from 'react-redux';

const Commercial = (props) => {
	const { className, section, ...rest } = props;
	const { submitting } = useFormState();
	const token = useSelector(
		(state) => state.auth.authenticate?.data?.meta?.access_token
	);

	const { t } = useTranslations();

	const { isAdmin } = useProfile();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('company.form.commercial.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={4} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="text"
							label={t('company.form.commercial.number')}
							name={`${section}.number`}
							margin="dense"
							variant="outlined"
							disabled={submitting || !isAdmin}
							required
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<Field
							previewFileState={section}
							component={FileFieldAdapter}
							name={`${section}.file_path`}
							fileNameField={`${section}.file_name`}
							disabled={submitting || !isAdmin}
							token={token}
							required
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

Commercial.propTypes = {
	className: string,
	section: string.isRequired,
};

export default Commercial;
