import React from 'react';
import { CircularProgress, Grid, Box, Button } from '@material-ui/core';
import { bool, object } from 'prop-types';

// Import styles
import { useStyles } from './Content.styles';

// Import utils
import { useTranslations } from 'components/utilities';
import { useContent } from './useContent';

// Import components
import { Table, DriverForm } from '../../components';

export const Content = ({ data, isFetching }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { tables, imageUrl, toggle, isEditView, initialValues, customerUuid } =
		useContent(data);

	if (isFetching) {
		return <CircularProgress size={40} />;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box
					className={classes.image}
					alt="License image"
					component="img"
					src={imageUrl}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<Button color="primary" variant="contained" onClick={toggle}>
							{isEditView
								? t('common.buttons.go_back')
								: t('common.buttons.edit')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				{isEditView ? (
					<DriverForm
						initialValues={initialValues}
						customerUuid={customerUuid}
						toggle={toggle}
					/>
				) : (
					<Table data={tables} />
				)}
			</Grid>
		</Grid>
	);
};

Content.propTypes = {
	isFetching: bool,
	data: object,
};
