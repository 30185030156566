import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Typography } from '@material-ui/core';

// Import components
import { KeyboardDatePickerFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import icons
import { EditIcon } from 'assets/icons';

// Import styles
import { useStyles } from './styles';

const DateField = ({ name, label }) => {
	const classes = useStyles();

	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Grid container spacing={2} justifyContent="space-between">
				<Grid item>
					<Typography variant="h6">{t(label)}</Typography>
				</Grid>
				<Grid item>
					<Field
						component={KeyboardDatePickerFieldAdapter}
						name={name}
						disabled={submitting}
						keyboardIcon={<EditIcon className={classes.icon} />}
						className={classes.input}
						InputProps={{ readOnly: true, disableUnderline: true }}
					/>
				</Grid>
			</Grid>
		</MuiPickersUtilsProvider>
	);
};

DateField.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
};

export default DateField;
