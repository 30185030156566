import React from 'react';

// Import components
import { AdditionalInfoModal } from 'components/elements';

// Import helpers
import { ADDITIONAL_INFO_FIELDS } from './helpers';

const AdditionalInfo = () => {
	return <AdditionalInfoModal fields={ADDITIONAL_INFO_FIELDS} />;
};

export default AdditionalInfo;
