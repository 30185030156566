import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { object, func, array } from 'prop-types';

// Import helpers
import { EN } from 'helpers/variables';

import { useTranslations } from 'components/utilities';

const useStyles = makeStyles({
	checkboxWrapper: ({ isChecked }) => ({
		backgroundColor: isChecked ? 'rgba(0, 150, 136, 0.3)' : 'transparent',
	}),
	formControl: {
		padding: '5px',
		margin: 0,
		width: '100%',
	},
});

const OfficeItem = ({ office, checkedOffices, setCheckedOffices }) => {
	const { uuid, name, is_manager_assigned } = office;
	const [isChecked, setIsChecked] = useState(is_manager_assigned);
	const { i18n } = useTranslations();
	const lng = i18n.language;
	const classes = useStyles({ isChecked });
	const isEnglish = lng === EN;
	const officeName = isEnglish ? name.en : name.ar;

	const handleCheckboxChange = ({ target }) => {
		setIsChecked((prev) => !prev);

		if (target.checked) {
			setCheckedOffices([...checkedOffices, office]);
		} else {
			const newOffices = checkedOffices.filter((item) => item.uuid !== uuid);
			setCheckedOffices(newOffices);
		}
	};

	return (
		<Grid item xs={12} height="max-content">
			<Box className={classes.checkboxWrapper} boxShadow={1}>
				<FormControlLabel
					className={classes.formControl}
					control={
						<Checkbox
							color="primary"
							checked={isChecked}
							name={officeName}
							onChange={handleCheckboxChange}
						/>
					}
					label={officeName}
				/>
			</Box>
		</Grid>
	);
};

OfficeItem.propTypes = {
	office: object,
	checkedOffices: array,
	setCheckedOffices: func,
};

export default OfficeItem;
