import React from 'react';
import { bool, func } from 'prop-types';

// Import utils and helpers
import { useFinancialReportProvider } from 'views/FinancialReport/context';

// Import components
import { TableExport, TableExportFilters } from 'components/elements';
import { TableExportProvider } from 'components/context';
import { FilterFields } from '../FilterFields';

export const NewReport = ({ open, close }) => {
	const { reportHelpers } = useFinancialReportProvider();

	const { fetchDataTableAction, initialValues, formatValues, columns } =
		reportHelpers || {};

	return (
		<TableExportProvider
			fetchDataTableAction={fetchDataTableAction}
			columns={columns}
			outerOpen={open}
			outerClose={close}
		>
			<TableExport
				hideColumnsSelect
				renderFilters={() => (
					<TableExportFilters
						initialValues={initialValues}
						formatValues={formatValues}
					>
						<FilterFields />
					</TableExportFilters>
				)}
			/>
		</TableExportProvider>
	);
};

NewReport.propTypes = {
	open: bool,
	close: func,
};
