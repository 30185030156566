import React from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useExtraServices } from './useExtraServices';
import {
	getFields,
	DRIVER_PRICES_FIELDS,
	EXTRA_SERVICES_FIELDS,
	DELIVERY_AT_AIRPORT_FIELDS,
} from './helpers';

// Import components
import { PeriodPaymentsField } from 'components/elements';
import { PriceFieldArray } from './components';

const ExtraServices = ({ className, section, ...rest }) => {
	const {
		t,
		i18n: { language: lang },
	} = useTranslations();
	const { renderFields } = useCommonFields();
	const {
		changeDeliveryDropWithDelivery,
		is_child_seat,
		car_delivery,
		submitting,
		driver,
		is_delivery_at_airport,
		airport,
	} = useExtraServices({ section });

	const driverPricesFields = DRIVER_PRICES_FIELDS(section);

	const {
		DRIVER_FIELD,
		DELIVERY_FIELD,
		DELIVERY_DROP_FIELD,
		DELIVERY_AIRPORT_FIELD,
	} = EXTRA_SERVICES_FIELDS({
		section,
		changeDeliveryDropWithDelivery,
	});

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.extra_services.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={6} xs={12}>
						{renderFields(DRIVER_FIELD)}
						{driver && driverPricesFields.map(renderFields)}
					</Grid>

					<Grid item md={6} xs={12}>
						{renderFields(DELIVERY_FIELD)}
						{car_delivery && (
							<>
								<Typography variant="h6" gutterBottom>
									{t('offices.form.extra_services.message')}
								</Typography>
								<PriceFieldArray
									sectionName={`${section}.car_delivery_options`}
									submitting={submitting}
								/>
							</>
						)}
					</Grid>

					{car_delivery && renderFields(DELIVERY_DROP_FIELD)}

					<Grid item md={6} xs={12}>
						{renderFields(DELIVERY_AIRPORT_FIELD)}
						{is_delivery_at_airport &&
							DELIVERY_AT_AIRPORT_FIELDS({ airport, lang, t }).map(
								renderFields
							)}
					</Grid>

					<Grid item md={6} xs={12}>
						<PeriodPaymentsField
							labelText="offices.form.extra_services.child_seat"
							checkboxName={`${section}.is_child_seat`}
							fields={getFields(section)}
							isOpen={is_child_seat}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

ExtraServices.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default ExtraServices;
