import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: `/slider/add`,
		icon: AddPhotoAlternateIcon,
		text: 'sliders.table.toolbar.add_slider',
	},
];
