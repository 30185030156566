import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	detailTitle: {
		fontWeight: 500,
		fontSize: 12,
		color: theme.palette.text.secondary,
	},
	detailValue: {
		fontWeight: 700,
		fontSize: 14,
		textTransform: 'capitalize',
	},
	colored: {
		color: theme.palette.primary.main,
	},
}));
