import React, { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { node } from 'prop-types';

// Import utils
import { useEContract } from 'components/utilities';
import {
	useInitiateContractInitialValues,
	useCreateContractFormOptions,
	useContractBranches,
} from 'queries';

export const CloseContractContext = createContext(null);

export const CloseContractProvider = ({ children }) => {
	const { id } = useParams();

	const {
		data: formOptions,
		isFetching: formOptionsFetching,
		isError: formOptionsError,
	} = useCreateContractFormOptions();

	const {
		data: branches,
		isFetching: branchesFetching,
		isError: branchesError,
	} = useContractBranches();

	const {
		data: initialValues,
		isFetching: initialValuesFetching,
		isError: initialValuesError,
	} = useInitiateContractInitialValues(id);

	const { contract_number: contractNumber, vehicle_plate_uuid: plateUuid } =
		initialValues || {};

	const {
		isCloseContractButtonVisible,
		contractDataFetching,
		contractDataError,
		bookingFetching,
		bookingError,
	} = useEContract({ contractNumber });

	const convertInitialValues = (initialValues) => ({
		...initialValues,
		contract_actual_end_date: new Date(),
		rent_status: {
			odometer_reading: null,
		},
	});

	const convertedInitialValues = convertInitialValues(initialValues);

	const isFetching =
		initialValuesFetching ||
		contractDataFetching ||
		formOptionsFetching ||
		bookingFetching ||
		branchesFetching;

	const isError =
		initialValuesError ||
		contractDataError ||
		formOptionsError ||
		bookingError ||
		branchesError;

	return (
		<CloseContractContext.Provider
			value={{
				allowCloseContract: isCloseContractButtonVisible,
				initialValues: convertedInitialValues,
				bookingUuid: id,
				contractNumber,
				formOptions,
				isFetching,
				isError,
				plateUuid,
				branches,
			}}
		>
			{children}
		</CloseContractContext.Provider>
	);
};

CloseContractProvider.propTypes = {
	children: node,
};
