import React from 'react';
import {
	DialogContent,
	FormControl,
	FormGroup,
	CircularProgress,
	Grid,
	Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { bool, array, func } from 'prop-types';

// Import components
import OfficeItem from './OfficeItem';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

const Content = ({
	isLoading,
	isDataExist,
	officesData,
	checkedOffices,
	setCheckedOffices,
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<DialogContent>
			{isLoading ? (
				<Box className={classes.loader}>
					<CircularProgress align="center" size={27} color="primary" />
				</Box>
			) : isDataExist ? (
				<FormControl component="fieldset">
					<FormGroup>
						<Grid container spacing={2}>
							{officesData.map((office) => (
								<OfficeItem
									key={office.uuid}
									office={office}
									checkedOffices={checkedOffices}
									setCheckedOffices={setCheckedOffices}
								/>
							))}
						</Grid>
					</FormGroup>
				</FormControl>
			) : (
				<Alert severity="warning">
					<strong>{t('company_managers.modal.no_data')}</strong>
				</Alert>
			)}
		</DialogContent>
	);
};

Content.propTypes = {
	isLoading: bool.isRequired,
	isDataExist: bool.isRequired,
	officesData: array.isRequired,
	checkedOffices: array.isRequired,
	setCheckedOffices: func.isRequired,
};

export default Content;
