import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { B2B_CORPORATION_CUSTOMERS_LIST } from 'helpers';

export const useCorporationCustomersList = ({ corporateUuid }) => {
	const path = `/dashboard/b2b/corporate/${corporateUuid}/customer`;

	const { data, isFetching, isError } = useQuery(
		B2B_CORPORATION_CUSTOMERS_LIST,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data || [];

	return { data: fetchedData, isFetching, isError };
};
