import { FIELD_TYPES } from 'helpers';

export const FIELDS = ({ handleOnFileUploader, fileUploaderError }) => [
	{
		type: FIELD_TYPES.DATE,
		label: 'common.fields.money_paid_in',
		name: `payment_date`,
		required: true,
		minDate: false,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.transaction_amount',
		name: 'transaction_amount',
		disabled: true,
		required: true,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.amount_in_sar',
		name: 'total_amount',
		required: true,
	},
	{
		type: FIELD_TYPES.FILE_UPLOADER,
		label: 'common.fields.wire_transfer',
		name: 'wire_transfer_file',
		required: true,
		handleOnFileUploader,
		fileUploaderError,
	},
];

export const getTransactionAmount = ({ settledUuids, tableData }) =>
	settledUuids.reduce((acc, settledUuid) => {
		let convertedAcc = Number(acc);
		const settledRecord = tableData.find(({ uuid }) => uuid === settledUuid);
		const { used_amount_gross: amount } = settledRecord || {};

		if (!settledRecord) {
			return convertedAcc;
		}

		convertedAcc += Number(amount);

		return convertedAcc.toFixed(2);
	}, 0);
