import React from 'react';
import { array, bool, func, node, string } from 'prop-types';
import { Dialog, DialogContent, Grid } from '@material-ui/core';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { Import, Export } from './components';

// Import utils
import { TableExportProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

export const ExportAndImportDialog = ({
	toggle,
	isOpen,
	columns,
	fetchDataTableAction,
	title,
	importDataUrl,
	exportButtonText = '',
	importButtonText = '',
	additionalContent = null,
}) => {
	const { t } = useTranslations();

	return (
		<Dialog open={isOpen} fullWidth onClose={toggle}>
			<ClosableDialogTitle title={t(title)} onClose={toggle} />
			<DialogContent>
				<Grid container>
					<Grid item xs={12}>
						{additionalContent}
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2} justifyContent="flex-end">
							<Import
								importButtonText={importButtonText}
								importDataUrl={importDataUrl}
							/>
							<TableExportProvider
								columns={columns}
								fetchDataTableAction={fetchDataTableAction}
								noPagination
							>
								<Export exportButtonText={exportButtonText} />
							</TableExportProvider>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

ExportAndImportDialog.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired,
	columns: array.isRequired,
	fetchDataTableAction: func.isRequired,
	title: string.isRequired,
	importDataUrl: string.isRequired,
	exportButtonText: string,
	importButtonText: string,
	additionalContent: node,
};
