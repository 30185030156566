import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { array } from 'prop-types';

// Import utils
import {
	useCommonFields,
	useMediaQuerySizes,
	useTranslations,
} from 'components/utilities';

// Import styles
import { useStyles } from './RequiredFieldsSection.styles';

export const RequiredFieldsSection = ({ fields }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const classes = useStyles();
	const { isTabletSize } = useMediaQuerySizes();

	return fields.map(({ title, inputs }) => (
		<Box key={title} className={classes.itemWrapper}>
			<Typography variant="body1" className={classes.title}>
				{t(title)}
			</Typography>

			<Grid container spacing={isTabletSize ? 1 : 4} alignItems="flex-start">
				{inputs.map(({ warning, error, md, xs = 12, ...input }) => {
					return (
						<Grid item md={md} xs={xs} key={input.name}>
							{renderFields(input)}
							{warning && (
								<Typography variant="body1" className={classes.warning}>
									{t(warning)}
								</Typography>
							)}

							{error && (
								<Typography variant="body1" className={classes.error}>
									{error}
								</Typography>
							)}
						</Grid>
					);
				})}
			</Grid>
		</Box>
	));
};

RequiredFieldsSection.propTypes = {
	fields: array,
};
