import * as types from 'store/types';
import services from 'services/services';
import { fetchDefaultSeoTypesUrl } from 'store/paths';
import ls from 'localstorage-slim';

//Import helpers
import { catchError } from '../helpers';
import { LOCAL_STORAGE_DEFAULT_SEO_TYPES } from 'helpers';

export const fetchDefaultSeoTypes = () => async (dispatch) => {
	try {
		dispatch({
			type: types.DEFAULT_SEO_TYPES_LOADING,
			payload: true,
		});

		const localStorageDefaultSeoTypes = ls.get(LOCAL_STORAGE_DEFAULT_SEO_TYPES);

		// Get  response
		const { data } =
			!localStorageDefaultSeoTypes &&
			(await services.get(fetchDefaultSeoTypesUrl));

		if (!localStorageDefaultSeoTypes) {
			ls.set(LOCAL_STORAGE_DEFAULT_SEO_TYPES, data, { ttl: 14 * 24 * 60 * 60 });
		}

		const payload = localStorageDefaultSeoTypes || data;

		dispatch({
			type: types.DEFAULT_SEO_TYPES_SUCCESS,
			payload,
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.DEFAULT_SEO_TYPES_ERROR,
		});
	}
};
