import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { PriceDifferenceDescription } from './components';
import {
	BookingStatusActions,
	BookingExtendedChip,
	BookingStatusChip,
} from 'components/elements';

// Import utilities
import useBookingStatusPreviewField from './useBookingStatusPreviewField';

const BookingStatusPreviewField = () => {
	const { onChange, booking, isExtended } = useBookingStatusPreviewField();

	return (
		<Grid container spacing={5}>
			<Grid container direction="column" item xs={6} spacing={1}>
				{isExtended && (
					<Grid item>
						<BookingExtendedChip />
					</Grid>
				)}
				<Grid item>
					<BookingStatusChip booking={booking} />
				</Grid>
			</Grid>
			<Grid item xs={6}>
				<BookingStatusActions booking={booking} onChange={onChange} />
			</Grid>
			<Grid item xs={12}>
				<PriceDifferenceDescription booking={booking} />
			</Grid>
		</Grid>
	);
};

export default BookingStatusPreviewField;
