import { useContext } from 'react';

// Import components
import { CarsLogHistoryContext } from './CarsLogHistoryProvider';

const useCarsLogHistory = () => {
	return useContext(CarsLogHistoryContext);
};

export default useCarsLogHistory;
