import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { bool } from 'prop-types';

// Import utils and helpers
import { useAddCorporateCustomerDialogProvider } from '../../../../context';
import { useCommonFields, useTranslations } from 'components/utilities';
import { CONFIRMATION_STEP_FIELDS } from './helpers';
import { usePhonePrefixes } from 'queries';

// Import components
import { ButtonProgress } from 'components/elements';

export const FormContent = ({ isButtonLoading }) => {
	const { toggleDialog } = useAddCorporateCustomerDialogProvider();
	const { renderFields } = useCommonFields();

	const { t } = useTranslations();

	const { data: prefixes } = usePhonePrefixes();

	return (
		<Grid container spacing={1}>
			{CONFIRMATION_STEP_FIELDS(prefixes).map(renderFields)}
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end" spacing={1}>
					<Grid item>
						<Button onClick={toggleDialog} variant="outlined" color="primary">
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							isLoading={isButtonLoading}
							disabled={isButtonLoading}
							variant="contained"
							color="primary"
							type="submit"
						>
							{t('common.buttons.next')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isButtonLoading: bool,
};
