import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormState, Field } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	List,
	ListItem,
	Typography,
} from '@material-ui/core';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

const Holidays = ({ section }) => {
	const { holidays } = useSelector((store) => store.offices.form);
	const { submitting } = useFormState();
	const { t } = useTranslations();

	return (
		<Card>
			<CardHeader title={t('offices.form.holidays.title')} />
			<Divider />
			<CardContent>
				<List>
					{holidays.map(({ name, date_from, date_to }) => (
						<ListItem key={name}>
							<Grid container alignItems="center">
								<Grid item xs={4}>
									<Field
										type="checkbox"
										component={CheckboxFieldAdapter}
										name={section}
										disabled={submitting}
										value={name}
										labelText={t(`public_holidays.form.${name}`)}
										color="primary"
										readOnly
									/>
								</Grid>
								<Grid item xs={4}>
									<Typography component="span" variant="h6">
										{date_from}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography component="span" variant="h6">
										{date_to}
									</Typography>
								</Grid>
							</Grid>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
	);
};

Holidays.propTypes = {
	section: PropTypes.string.isRequired,
};

export default Holidays;
