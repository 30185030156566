import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	ReasonName,
	AdditionalInfo,
	ReasonType,
} from '../../RatingReasonsForm/components';

export const ReasonDetails = () => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<ReasonName />
			</Grid>
			<Grid item xs={12}>
				<ReasonType />
			</Grid>
			<Grid item xs={12}>
				<AdditionalInfo />
			</Grid>
		</Grid>
	);
};
