import React from 'react';
import { object } from 'prop-types';
import { Box, TableCell, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import useStyles from './styles';

// Import components
import { SquareButton } from 'components/elements';
import { ActiveButton } from '../../components';

// Import utilities
import { useAvailableCarTableCell } from './useAvailableCarTableCell';

// Import helpers
import { BUTTON_ACTION_TYPES } from 'helpers';

export const AvailableCarTableCell = ({ cell }) => {
	const { INCREMENT, DECREMENT } = BUTTON_ACTION_TYPES;

	const {
		handleOnActivate,
		changeQuantity,
		quantity,
		isDecrementButtonDisabled,
		isOfficeLevel,
		isCellVisible,
		isCarActive,
	} = useAvailableCarTableCell({ cell });

	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{isCellVisible && (
				<Box className={classes.wrapper}>
					<Typography
						className={clsx({ [classes.deactive]: !isCarActive })}
						color="primary"
						variant="h6"
					>
						{quantity}
					</Typography>
					<ActiveButton isActive={isCarActive} onClick={handleOnActivate} />
					{isOfficeLevel && (
						<>
							<SquareButton
								onClick={() => changeQuantity(INCREMENT)}
								iconType={INCREMENT}
							/>
							<SquareButton
								onClick={() => changeQuantity(DECREMENT)}
								iconType={DECREMENT}
								disabled={isDecrementButtonDisabled}
							/>
						</>
					)}
				</Box>
			)}
		</TableCell>
	);
};

AvailableCarTableCell.propTypes = {
	cell: object.isRequired,
};
