import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
	rowsWrapper: {
		borderRadius: 8,
		border: '1px solid',
		borderColor: theme.palette.background[1],
		padding: 20,
		overflow: 'hidden',
	},
	label: {
		fontWeight: 'bold',
		fontSize: 16,
	},
}));

export default useStyles;
