import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	dateRange: {
		fontFamily: theme.typography.fontFamily,
		display: 'flex',

		'& .rdrStaticRangeLabel': {
			fontFamily: theme.typography.fontFamily,
			lineHeight: '25px',
			fontSize: 14,
		},

		'& .rdrDayToday .rdrDayNumber span:after': {
			backgroundColor: theme.palette.text.secondary,
		},

		'& .datePicker': {
			justifyContent: 'space-around',
		},

		'& .rdrStaticRange': {
			border: 'none',
		},

		'& .rdrCalendarWrapper, .rdrDefinedRangesWrapper': {
			flex: 1,
			border: 'none',
		},

		'& .rdrMonthPicker': {
			display: 'none',
		},

		'& .rdrMonthAndYearWrapper': {
			position: 'relative',
		},

		'& .rdrMonthAndYearPickers': {
			position: 'absolute',
			right: 10,
		},

		'& .rdrNextButton': {
			position: 'absolute',
			top: 65,
			right: 10,
		},

		'& .rdrPprevButton': {
			position: 'absolute',
			top: 65,
			left: 0,
		},

		'& .rdrMonthName': {
			textAlign: 'center',
		},

		[theme.breakpoints.down('xs')]: {
			'& .rdrDefinedRangesWrapper': {
				display: 'none',
			},
		},
	},
}));
