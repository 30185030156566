export const getShortenIban = (iban) =>
	iban.length > 12 ? iban.substr(iban.length - 12) : iban;

export const getIbanCopyValue = (iban) =>
	iban && !iban.toLowerCase().startsWith('sa') ? `sa${iban}` : iban;

export const getCopyActions = (iban) => {
	return [
		{
			value: getIbanCopyValue(iban),
			buttonText: 'copy_iban',
		},
	];
};
