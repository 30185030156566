import React from 'react';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';
import { BOOKINGS_PAGES_TYPES } from 'helpers';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { Export, TableBodyRow } from '../Bookings/components';
import { TableRPC } from 'components/utilities';

// Import actions
import { fetchExtendedBookings } from 'store/actions';

// Import styles
import { useStyles } from './ExtendedBookings.styles';

export const ExtendedBookings = () => {
	const classes = useStyles();

	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns(classes)}
			toolBarContent={TOOLBAR_CONTENT}
			tableBodyRow={TableBodyRow}
			export={
				<Export
					pageType={BOOKINGS_PAGES_TYPES.EXTENDED}
					fetchDataTableAction={fetchExtendedBookings}
				/>
			}
			fetchDataTableAction={fetchExtendedBookings}
		/>
	);
};
