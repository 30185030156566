// Import helpers
import { FIELD_TYPES } from 'helpers';

export const AIRPORT_LOGO_FIELDS = (section) => [
	{
		type: FIELD_TYPES.IMAGE_FILE,
		name: `${section}.file_path`,
		previewFileState: section,
		fileNameField: `${section}.file_name`,
		required: true,
	},
];
