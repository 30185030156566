import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { B2B_CORPORATION_CUSTOMER } from 'helpers';

export const useCorporationCustomer = ({ corporateUuid, customerUuid }) => {
	const path = `/dashboard/b2b/corporate/${corporateUuid}/customer/${customerUuid}`;

	const { data, isFetching, isError, refetch } = useQuery(
		B2B_CORPORATION_CUSTOMER,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data || null;

	return { data: fetchedData, isFetching, isError, refetch };
};
