import React from 'react';
import { func, string } from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';

// Import components
import { PopConfirm } from 'components/elements/molecules';

// Import context
import { useTableContextProvider } from 'components/context';

// Import utilities
import { useMessage } from 'components/utilities';

// Import styles
import useStyles from '../styles';

export const DeleteButton = ({
	deleteAction = () => {},
	customFetchData,
	vehicleId,
}) => {
	const classes = useStyles();
	const { message } = useMessage();
	const { fetchData } = useTableContextProvider();
	const dispatch = useDispatch();

	const handleDeleteItem = async () => {
		try {
			await deleteAction(vehicleId)(dispatch);
			fetchData();
			customFetchData && customFetchData();
		} catch (error) {
			if (error?.response) {
				const errorData = error.response.data;
				const errorMessage =
					errorData.message ?? errorData.error ?? errorData.errors.uuid;
				message.error(errorMessage);
			}
		}
	};

	return (
		<PopConfirm onConfirm={handleDeleteItem}>
			<div className={classes.deleteIconWrapper}>
				<DeleteIcon className={classes.icon} fontSize="small" />
			</div>
		</PopConfirm>
	);
};

DeleteButton.propTypes = {
	deleteAction: func,
	customFetchData: func,
	vehicleId: string,
};
