import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import {
	Box,
	Grid,
	TableCell,
	TableRow,
	TableSortLabel,
	Typography,
} from '@material-ui/core';
import _ from 'lodash';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { SESSION_STORAGE_MAIN_TABLE_HEADER_REF } from 'helpers';

// Import styles
import { useStyles } from '../../styles';

export const TableHeadRow = ({ headerGroup }) => {
	const [cellWidths, setCellWidths] = useState([]);

	const {
		t,
		i18n: { language: lang },
	} = useTranslations();

	const classes = useStyles();

	const parentHeaderCellWidths = JSON.parse(
		window.sessionStorage.getItem(SESSION_STORAGE_MAIN_TABLE_HEADER_REF)
	);

	useEffect(() => {
		parentHeaderCellWidths && setCellWidths(parentHeaderCellWidths);
		// eslint-disable-next-line
	}, [lang, parentHeaderCellWidths?.[0]]);

	return (
		<TableRow {...headerGroup.getHeaderGroupProps()}>
			{headerGroup.headers.map(
				(column, index) =>
					!column.isHidden && (
						<TableCell
							key={column.id}
							{...column.getHeaderProps(column.getSortByToggleProps())}
							className={classes.headerTableCell}
							width={cellWidths[index]}
						>
							<Box display="flex">
								<Grid container direction="column">
									<Grid item xs>
										<Typography noWrap className={classes.tableHeader}>
											{t(column.render('Header'))}
										</Typography>
									</Grid>
									{_.isFunction(column.renderSubHeader) && (
										<Grid item xs>
											{column.renderSubHeader(column.subHeader)}
										</Grid>
									)}
								</Grid>
								{!column.disableSortBy && column.id !== 'selection' ? (
									<TableSortLabel
										active={column.isSorted}
										// react-table has a unsorted state which is not treated here
										direction={column.isSortedDesc ? 'desc' : 'asc'}
									/>
								) : null}
							</Box>
						</TableCell>
					)
			)}
		</TableRow>
	);
};

TableHeadRow.propTypes = {
	headerGroup: object.isRequired,
};
