import React from 'react';
import { object, string } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import { TABLE_ACTIONS_ACCESSORS } from 'helpers';

// Import components
import { EditTableCell, DeleteTableCell } from '../../../TableActions';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

const TableBodyRow = ({ row, editLinkPath }) => {
	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map((cell) => {
				if (cell.column.isHidden) return null;
				if (cell.column.id === EDIT) {
					return (
						<EditTableCell
							key={cell.column.id}
							cell={cell}
							editLinkPath={editLinkPath}
						/>
					);
				} else if (cell.column.id === DELETE) {
					return <DeleteTableCell key={cell.column.id} cell={cell} />;
				} else {
					return (
						<TableCell key={cell.column.id} {...cell.getCellProps()}>
							{cell.render('Cell')}
						</TableCell>
					);
				}
			})}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	editLinkPath: string.isRequired,
};

export default TableBodyRow;
