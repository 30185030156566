import React from 'react';
import { string, func, bool, number } from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

export const NotificationAlert = ({
	type = 'error',
	message,
	title = 'Error',
	open,
	onClose = () => {},
	autoHideDuration,
	horizontalPosition,
}) => {
	return (
		<Snackbar
			open={open}
			anchorOrigin={{
				vertical: 'top',
				horizontal: horizontalPosition || 'right',
			}}
			autoHideDuration={autoHideDuration}
			onClose={onClose}
		>
			<Alert onClose={onClose} severity={type}>
				{title && <AlertTitle>{title}</AlertTitle>}
				{message}
			</Alert>
		</Snackbar>
	);
};

NotificationAlert.propTypes = {
	type: string,
	title: string,
	message: string,
	open: bool,
	onClose: func,
	autoHideDuration: number,
	horizontalPosition: string,
};
