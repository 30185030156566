import React from 'react';
import { string } from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	List,
} from '@material-ui/core';

// Import utils
import { useProfile, useTranslations } from 'components/utilities';

// Import components
import PaymentMethod from './components/PaymentMethod';

const PaymentMethods = ({ className, section, ...rest }) => {
	const { t } = useTranslations();

	const { isAdmin } = useProfile();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('company.form.payment_methods.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<List>
							<FieldArray name={`${section}`}>
								{({ fields }) => {
									return fields.map((name, index) => {
										const value = fields.value[index];

										return (
											<PaymentMethod
												key={name}
												value={value}
												name={name}
												disabled={!isAdmin}
											/>
										);
									});
								}}
							</FieldArray>
						</List>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

PaymentMethods.propTypes = {
	className: string,
	section: string.isRequired,
};

export default PaymentMethods;
