import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';
import { useDeliveryStatusTableCell } from './useDeliveryStatusTableCell';

// Import components
import { ChipLabel } from 'components/elements';

// Import styles
import { useStyles } from './DeliveryStatusTableCell.styles';

export const DeliveryStatusTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { deliveryStatus } = useDeliveryStatusTableCell({ cell });

	const { text, color } = deliveryStatus || {};

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<ChipLabel
				fullWidth
				label={t(text)}
				color={color}
				className={classes.chip}
			/>
		</TableCell>
	);
};

DeliveryStatusTableCell.propTypes = {
	cell: object.isRequired,
	isDropOffSort: bool,
	isSingleStatus: bool,
};
