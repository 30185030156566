import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const VeryDeepScratchIcon = (props) => (
	<SvgIcon
		width="13"
		height="13"
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.20909 0.598759C3.59254 0.214492 4.11307 0 4.6538 0H8.34681C8.88858 0 9.40797 0.215209 9.79112 0.598363L12.4007 3.20853C12.4008 3.20866 12.4005 3.2084 12.4007 3.20853C12.7851 3.59199 13 4.11299 13 4.6538V8.3462C13 8.88797 12.7848 9.40736 12.4016 9.79051L9.79147 12.4007C9.79136 12.4008 9.79158 12.4006 9.79147 12.4007C9.408 12.7851 8.88704 13 8.3462 13H4.6538C4.11203 13 3.59264 12.7848 3.20949 12.4016L0.599319 9.79147C0.599205 9.79136 0.599433 9.79158 0.599319 9.79147C0.214914 9.408 0 8.88703 0 8.3462V4.6538C0 4.11203 0.215209 3.59264 0.598363 3.20949L3.20909 0.598759C3.2089 0.598946 3.20928 0.598572 3.20909 0.598759ZM8.34681 1.85714H4.6538C4.604 1.85714 4.55709 1.87705 4.52364 1.9106L1.91156 4.52268C1.87655 4.5577 1.85714 4.60478 1.85714 4.6538V8.3462C1.85714 8.396 1.87706 8.44291 1.9106 8.47636L4.52268 11.0884C4.5577 11.1235 4.60478 11.1429 4.6538 11.1429H8.3462C8.396 11.1429 8.44291 11.1229 8.47636 11.0894L11.0884 8.47732C11.1235 8.4423 11.1429 8.39522 11.1429 8.3462V4.6538C11.1429 4.604 11.1229 4.55709 11.0894 4.52364L8.47792 1.91156C8.4779 1.91154 8.47795 1.91159 8.47792 1.91156C8.44292 1.87659 8.39579 1.85714 8.34681 1.85714Z"
			fill="currentColor"
		/>
	</SvgIcon>
);
