import * as dateFns from 'date-fns';

export const getInitialFilters = ({
	assignedOfficesUuids,
	companyUuid,
	isCompanyManager,
}) => ({
	period: {
		from: dateFns.subDays(new Date(), 1),
		until: new Date(),
	},
	...(companyUuid && { company_uuid: companyUuid }),
	office_uuids:
		assignedOfficesUuids.length && !isCompanyManager
			? assignedOfficesUuids
			: [],
});
