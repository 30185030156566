import React from 'react';
import { object, func, string, bool } from 'prop-types';
import { Button, Tooltip } from '@material-ui/core';

// Import utilities
import { useTranslations, useProfile } from 'components/utilities';

export const ActiveButton = ({
	variant,
	onActiveItemClick,
	classes,
	text,
	isStateFrozen,
}) => {
	const { t } = useTranslations();
	const { isAdmin } = useProfile();

	return (
		<Tooltip
			title={
				!isAdmin && isStateFrozen ? t('offices.table.notes.contact_admin') : ''
			}
		>
			<span>
				<Button
					onClick={onActiveItemClick}
					className={classes.button}
					disabled={!isAdmin && isStateFrozen}
					variant={variant}
					color="primary"
				>
					{t(text)}
				</Button>
			</span>
		</Tooltip>
	);
};

ActiveButton.propTypes = {
	variant: string.isRequired,
	onActiveItemClick: func.isRequired,
	classes: object.isRequired,
	text: string.isRequired,
	isStateFrozen: bool.isRequired,
};
