import React from 'react';
import { object, string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { TableCell, Link } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

const PreviewTableCell = ({ cell, path }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Link
				className={classes.link}
				component={RouterLink}
				to={path}
				variant="h6"
			>
				<SearchIcon className={classes.icon} fontSize="small" />
				{t('table.headers.preview')}
			</Link>
		</TableCell>
	);
};

PreviewTableCell.propTypes = {
	cell: object.isRequired,
	path: string.isRequired
};

export default PreviewTableCell;
