import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { bool, shape, string } from 'prop-types';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';

// Import icons
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

// Import styles
import { useStyles } from './ListRow.styles';

// Import components
import { CollapsedCarList } from '../CollapsedCarList';
import { ChipLabel } from 'components/elements';

export const ListRow = ({ officeData }) => {
	const { on: isOpen, toggle } = useToggle();
	const { t, i18n } = useTranslations();
	const classes = useStyles();

	const { uuid: officeUuid, name, active } = officeData;
	const language = i18n.language;

	return (
		<Grid container>
			<Grid item xs={12}>
				<Grid container alignItems="center" className={classes.container}>
					<Grid item xs={4}>
						<IconButton
							className={isOpen ? classes.minimizeButton : classes.expandButton}
							size="small"
							onClick={toggle}
						>
							{isOpen ? <RemoveIcon /> : <AddIcon />}
						</IconButton>
					</Grid>
					<Grid item xs={4}>
						<Typography>{name[language]}</Typography>
					</Grid>
					<Grid item xs={4}>
						<ChipLabel
							label={t(`table.actions.${active ? 'active' : 'inactive'}`)}
							color={active ? 'primary' : 'error'}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<CollapsedCarList officeUuid={officeUuid} isOpen={isOpen} />
			</Grid>
		</Grid>
	);
};

ListRow.propTypes = {
	officeData: shape({
		uuid: string,
		active: bool,
		name: shape({ en: string, ar: string }),
	}),
};
