import React from 'react';
import { func, object, string } from 'prop-types';
import { Form } from 'react-final-form';

// Import components
import { FormContent } from './components';

// Import utils
import { useDriverForm } from './useDriverForm';

export const DriverForm = ({ initialValues, customerUuid, toggle }) => {
	const { handleOnSubmit, isLoading } = useDriverForm({ customerUuid, toggle });

	return (
		<Form
			onSubmit={handleOnSubmit}
			initialValues={initialValues}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormContent isLoading={isLoading} />
				</form>
			)}
		/>
	);
};

DriverForm.propTypes = {
	initialValues: object,
	customerUuid: string,
	toggle: func,
};
