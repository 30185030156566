export const COLUMNS_ACCESSORS = {
	INDIVIDUAL_NUMBER: 'individual_number',
};

export const columns = [
	{
		Header: 'promo_codes.preview.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.INDIVIDUAL_NUMBER,
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.preview.table.headers.phone_number',
		accessor: 'customer_phone',
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.preview.table.headers.name',
		accessor: 'customer_name',
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.preview.table.headers.number_of_booking',
		accessor: 'customer_no_booking',
		disableSortBy: true,
	},
];
