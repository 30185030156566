import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

// Import utilities
import { previewBookingSelector } from 'store/selectors/bookings';
import { useTranslations } from 'components/utilities';

// Import components
import PreviewField from './PreviewField';

// Import styles
import useStyles from './styles';
import { elmLogo } from 'assets/images';

// Import helpers
import { VERIFY_TYPES } from 'helpers';

const FullNamePreviewField = () => {
	const { t } = useTranslations();

	const classes = useStyles();
	const {
		customer: { full_name, verify_type },
	} = useSelector(previewBookingSelector);

	return (
		<PreviewField label={t('bookings.preview_booking.customer.full_name')}>
			{full_name && (
				<Box className={classes.box}>
					{full_name}
					{verify_type === VERIFY_TYPES.ELM && (
						<img
							alt={t('bookings.table.notes.verified_by_elm')}
							className={classes.image}
							src={elmLogo}
						/>
					)}
				</Box>
			)}
		</PreviewField>
	);
};

export default FullNamePreviewField;
