import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	Link,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { fields, getLink } from './helpers';

const BaseDetails = ({ section, ...rest }) => {
	const { submitting, values } = useFormState();
	const params = useParams();
	const { t } = useTranslations();

	const slug = values.details.slug;
	const isEdit = !!params.id;

	return (
		<Card {...rest}>
			<CardHeader title={t('kiosk.management.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{fields(section).map((field) =>
						field.type === 'select' ? (
							<Grid key={field.name} item md={field.md} xs={field.xs}>
								<Field
									fullWidth
									component={field.component}
									type={field.type}
									label={t(field.label)}
									name={field.name}
									margin="dense"
									variant="outlined"
									disabled={submitting}
									required
								>
									{field.children}
								</Field>
							</Grid>
						) : (
							<Grid key={field.name} item md={field.md} xs={field.xs}>
								<Field
									fullWidth
									component={field.component}
									type={field.type}
									label={t(field.label)}
									name={field.name}
									margin="dense"
									variant="outlined"
									disabled={submitting}
									inputProps={{ min: 0 }}
									required
								/>
							</Grid>
						)
					)}
					{isEdit && (
						<Grid item>
							<Link href={getLink(slug)} variant="h6">
								{getLink(slug)}
							</Link>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

BaseDetails.propTypes = {
	section: PropTypes.string.isRequired,
};

export default BaseDetails;
