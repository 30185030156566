const onImagesLoad = (images) => {
	return new Promise((resolve) => {
		let finishedImages = 0;

		if (images.length === 0) {
			resolve();
			return;
		}

		images.forEach((img) => {
			const image = new Image();
			image.src = img.src;

			const handleFinish = () => {
				finishedImages++;

				if (finishedImages === images.length) {
					resolve();
				}
			};

			image.onload = handleFinish;
			image.onerror = handleFinish;
		});
	});
};

export default onImagesLoad;
