import React from 'react';
import { Grid } from '@material-ui/core';

// Import utilities
import { FormRPC, useTranslations } from 'components/utilities';
import {
	fetchCustomerRatingFormResources,
	submitCustomerRatingForm,
} from 'store/actions';

// Import selectors
import { previewCustomerRatingSelector } from 'store/selectors';

// Import components
import { CustomerRatingInfo, SubmitButton } from './components';
import { Card } from 'components/elements';

const CustomerRating = () => {
	const { t } = useTranslations();

	return (
		<Grid item lg={6}>
			<FormRPC
				fetchFormResAction={fetchCustomerRatingFormResources}
				submitFormAction={submitCustomerRatingForm}
				store={previewCustomerRatingSelector}
				goBackPath=""
				title=""
				isCardLayout={false}
				isActionButtonsHidden
			>
				{() => (
					<Card
						title={t('bookings.customer_rating.title')}
						lg={12}
						actions={<SubmitButton />}
					>
						<CustomerRatingInfo />
					</Card>
				)}
			</FormRPC>
		</Grid>
	);
};

export default CustomerRating;
