import React from 'react';

// Import store
import { fetchUnsettledLimits } from 'store/actions';

// Import utils and helpers
import { useUnsettledLimitsProvider } from '../../context';
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';
import { TOOLBAR_CONTENT } from './helpers';

// Import components
import { TableBodyRow } from '../../components';

export const UnsettledLimitsWrapper = () => {
	const { handleOnSettledUuids } = useUnsettledLimitsProvider();

	return (
		<TableRPC
			columns={columns}
			toolBarContent={TOOLBAR_CONTENT()}
			columnsAccessor={COLUMNS_ACCESSORS}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={(options) =>
				fetchUnsettledLimits({ ...options, handleOnSettledUuids })
			}
		/>
	);
};
