import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';

// Import helpers
import { COLOR_VARIANTS, getColor } from './helpers';

const { LIGHT } = COLOR_VARIANTS;

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: 70,
		height: 'min-content',
		width: '100%',
		borderRadius: theme.layout.borderRadius.card,
		padding: theme.spacing(3),
		backgroundColor: (props) => getColor({ props, theme, variant: LIGHT }),
		border: (props) => `1px solid ${alpha(getColor({ props, theme }), 0.5)}`,
	},
	info: {
		display: 'flex',
		alignItems: 'center',
		gridGap: 25,
	},
	icon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 52,
		height: 52,
		borderRadius: '50%',
		backgroundColor: (props) => alpha(getColor({ props, theme }), 0.1),
		color: (props) => getColor({ props, theme }),
		fontSize: 20,
	},
	action: {},
	labels: {
		display: 'flex',
		flexDirection: 'column',
		gridGap: 6,
	},
}));
