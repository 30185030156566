import React from 'react';
import { string } from 'prop-types';
import { useParams } from 'react-router-dom';
import { fetchEmployeePerformanceBookings } from 'store/actions';

// Import columns
import { columns } from './columns';

// Import components
import { TableExportProvider } from 'components/context';
import { TableExportButtons, TableExport } from 'components/elements';
import { ExportFilters } from '../ExportFilters';

export const Export = ({ officeManagerUuid: uuid }) => {
	const { id } = useParams();

	const officeManagerUuid = uuid || id;

	return (
		<TableExportProvider
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchEmployeePerformanceBookings({
					...options,
					officeManagerUuid,
				})
			}
		>
			<TableExportButtons />
			<TableExport renderFilters={() => <ExportFilters />} />
		</TableExportProvider>
	);
};

Export.propTypes = {
	officeManagerUuid: string,
};
