// Import helpers
import { FIELD_TYPES } from 'helpers';

export const CHECKBOX_FIELD = ({ name, index }) => [
	{
		type: FIELD_TYPES.CHECKBOX,
		label: `public_holidays.form.${name}`,
		name: `holidays.[${index}].selected`,
		xs: 'auto',
		md: 'auto',
	},
];

export const DATE_FIELDS = ({ index, selected, dateFrom, dateTo }) => [
	{
		type: FIELD_TYPES.DATE,
		label: 'public_holidays.form.date_from',
		name: `holidays[${index}].date_from`,
		disabled: !selected,
		required: selected,
		minDate: false,
		maxDate: dateTo,
		xs: 'auto',
		md: 'auto',
	},
	{
		type: FIELD_TYPES.DATE,
		label: 'public_holidays.form.date_to',
		name: `holidays[${index}].date_to`,
		disabled: !selected,
		required: selected,
		minDate: dateFrom,
		xs: 'auto',
		md: 'auto',
	},
];
