import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	row: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginLeft: theme.spacing(2),
	},
	icon: {
		width: 17,
		height: 17,
		marginRight: theme.spacing(1),
	},
}));

export default useStyles;
