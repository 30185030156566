export const INITIAL_VALUES = {
	details: {
		name: { ar: '', en: '' },
		person_name: '',
		number_prefix: '',
		contact_number: '',
		slug: '',
	},
	location: {
		address: '',
		city: '',
		country: '',
		lat: null,
		lng: null,
	},
	commercial_record: {
		number: '',
		file_path: '',
	},
	logo: {
		file_path: '',
	},
	external: {
		use_partner: false,
		partner_uuid: '',
		partner_name: '',
	},
	bank: {
		bank_name: '',
		iban_number: '',
		name: '',
		number: '',
	},
	options: {
		is_tam: false,
		tam_nett_price: 0,
		is_extra_driver: false,
		extra_driver_nett_price: 0,
		is_auth_out_ksa: false,
		auth_out_ksa_nett_price: 0,
	},
	seo: {
		title: {
			en: '',
			ar: '',
		},
		keywords: {
			en: '',
			ar: '',
		},
		description: {
			en: '',
			ar: '',
		},
	},
	payment_methods: [],
};
