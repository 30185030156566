import React from 'react';
import { object, bool, array, oneOfType } from 'prop-types';
import { CircularProgress } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { Card } from 'components/elements';
import PreviewFields from './PreviewFields';

// Import helpers
import { FIELDS_NEW_COMPANY } from '../helpers';

const CompanyCard = ({ customData, isFetching, fields }) => {
	const { t } = useTranslations();

	if (isFetching) {
		return <CircularProgress />;
	}

	return (
		<Card title={t('bookings.preview_booking.company.title')} lg={12}>
			<PreviewFields
				customData={customData}
				fields={fields || FIELDS_NEW_COMPANY}
			/>
		</Card>
	);
};

CompanyCard.propTypes = {
	customData: oneOfType([object, array]),
	fields: oneOfType([object, array]),
	isFetching: bool,
};

export default CompanyCard;
