import React from 'react';
import { array, bool, number, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Field } from 'react-final-form';
import { CircularProgress, Grid, MenuItem } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import components
import { SelectFieldAdapter } from 'components/elements';

import { useStyles } from '../styles';

export const Manufacturer = ({
	showAddManufacturerOption = false,
	showAllOption = false,
	disabled = false,
	required = false,
	isLoading,
	manufacturers,
	label,
	name,
	md,
	xs,
}) => {
	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const classes = useStyles();

	const history = useHistory();

	const handleClick = () => history.push(URLS.addCarManufacturerUrl);

	if (isLoading) {
		return (
			<Grid item md={md} xs={xs}>
				<Grid item>
					<CircularProgress size={27} />
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				type="select"
				label={t(label)}
				name={name}
				margin="dense"
				variant="outlined"
				disabled={disabled}
				required={required}
			>
				{showAllOption && <MenuItem value="all">{t('common.all')}</MenuItem>}
				{manufacturers.map(({ name, uuid }) => (
					<MenuItem key={uuid} value={uuid}>
						{name[lng]}
					</MenuItem>
				))}
				{showAddManufacturerOption && (
					<MenuItem
						className={classes.root}
						onClick={handleClick}
						value="cars.manufacturer.table.toolbar.add_manufacturer"
					>
						+ {t('cars.manufacturer.table.toolbar.add_manufacturer')}
					</MenuItem>
				)}
			</Field>
		</Grid>
	);
};

Manufacturer.propTypes = {
	manufacturers: array.isRequired,
	name: string.isRequired,
	label: string.isRequired,
	md: number,
	xs: number,
	showAddManufacturerOption: bool,
	showAllOption: bool,
	isLoading: bool,
	disabled: bool,
	required: bool,
};

Manufacturer.defaultProps = {
	xs: 12,
	md: 12,
};
