import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import { useCommonFields, useTranslations } from 'components/utilities';

// Import components
import { AutoCompensationFields } from '../AutoCompensationFields';
import { useAdditionalInfo } from './useAdditionalInfo';

export const AdditionalInfo = () => {
	const { t } = useTranslations();
	const { renderFields } = useCommonFields();

	const { sectionName, autoCompensation, additionalInfoFields } =
		useAdditionalInfo();

	return (
		<Card>
			<CardHeader title={t(`${sectionName}.auto_compensation.title`)} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{additionalInfoFields.map(renderFields)}
					{autoCompensation && <AutoCompensationFields />}
				</Grid>
			</CardContent>
		</Card>
	);
};
