import React from 'react';
import { bool, func, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { deleteCarList } from 'store/actions';

// Import helpers
import { replaceEditCarListUrl, TABLE_ACTIONS_ACCESSORS } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { ExpandedRow } from '../../components';
import {
	DeleteTableCell,
	ExpandTableCell,
	ImageTableCell,
	EditTableCell,
} from 'components/elements';

// Import utils
import { useTableActionsProvider } from '../../context';
import { useProfile } from 'components/utilities';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { IMAGE, EXPAND, AVAILABILITY } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row, isContractPage, onSelectPlate }) => {
	const { handleOnExpandRows, isRowOpen } = useTableActionsProvider();

	const { isAdmin } = useProfile();

	const renderCell = (cell) => {
		const { uuid, media, plate_availability } = cell.row.original;
		const { id, isHidden } = cell.column;

		if (isHidden) return null;

		switch (id) {
			case EXPAND:
				return (
					<ExpandTableCell
						key={id}
						cell={cell}
						isOpen={isRowOpen(uuid)}
						onClick={() => handleOnExpandRows(uuid)}
						hideExpand={!plate_availability}
					/>
				);

			case EDIT:
				return (
					isAdmin && (
						<EditTableCell
							key={id}
							cell={cell}
							editLinkPath={replaceEditCarListUrl(uuid)}
						/>
					)
				);

			case AVAILABILITY:
				return (
					<TableCell key={id}>
						{cell.row.original.plates?.data?.length}
					</TableCell>
				);

			case DELETE:
				return (
					isAdmin && (
						<DeleteTableCell
							key={id}
							deleteAction={deleteCarList}
							cell={cell}
						/>
					)
				);

			case IMAGE:
				return <ImageTableCell cell={cell} key={id} imgUrl={media} />;

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<>
			<TableRow key={row.id} {...row.getRowProps()}>
				{row.cells.map(renderCell)}
			</TableRow>
			<ExpandedRow
				isExpanded={isRowOpen(row.original.uuid)}
				colSpan={row.cells.length}
				vehicleUuid={row.original.uuid}
				isContractPage={isContractPage}
				onSelectPlate={onSelectPlate}
			/>
		</>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	isContractPage: bool,
	onSelectPlate: func,
};

TableBodyRow.defaultProps = {
	isContractPage: false,
	onSelectPlate: () => {},
};
