import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { fetchVersionsForm, submitVersionsForm } from 'store/actions';

// Import components
import { FormContent } from './components';

// Import utils
import { FormRPC } from 'components/utilities';
import { mobileAppVersionSelector } from 'store/selectors';
import { useSystemClients } from './useSystemClients';

// Import styles
import { useStyles } from './styles';

export const SystemClients = () => {
	const { root } = useStyles();
	const { callbackSuccess, errorCallback } = useSystemClients();

	return (
		<Box className={root}>
			<FormRPC
				fetchFormResAction={fetchVersionsForm}
				submitFormAction={(props) =>
					submitVersionsForm({ ...props, callbackSuccess, errorCallback })
				}
				isCardLayout={false}
				store={mobileAppVersionSelector}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9}>
							<FormContent />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
