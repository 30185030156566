// Import helpers
import { BOOKINGS_PAGES_TYPES } from 'helpers';

// Import columns
import { bookingsColumns } from './bookingsColumns';
import { extendedColumns } from './extendedColumns';
import { transferredColumns } from './transferredColumns';

export const getExportColumns = ({ pageType, isAdmin }) => {
	const { MAIN, EXTENDED, TRANSFERRED } = BOOKINGS_PAGES_TYPES;

	switch (pageType) {
		case MAIN:
			return bookingsColumns(isAdmin);
		case EXTENDED:
			return extendedColumns(isAdmin);
		case TRANSFERRED:
			return transferredColumns(isAdmin);

		default:
			return [];
	}
};
