import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(3),
	},
	imgContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	image: {
		maxHeight: 500,
		width: '100%',
		objectFit: 'contain',
	},
}));
