// ----------------- FORM -----------------
export const BOOKINGS_FORM_FETCH_DATA_LOADING =
	'BOOKINGS_FORM_FETCH_DATA_LOADING';
export const BOOKINGS_FORM_FETCH_DATA_SUCCESS =
	'BOOKINGS_FORM_FETCH_DATA_SUCCESS';
export const BOOKINGS_FORM_FETCH_DATA_ERROR = 'BOOKINGS_FORM_FETCH_DATA_ERROR';

export const BOOKINGS_FORM_RECALCULATE_DATA_LOADING =
	'BOOKINGS_FORM_RECALCULATE_DATA_LOADING';
export const BOOKINGS_FORM_RECALCULATE_DATA_SUCCESS =
	'BOOKINGS_FORM_RECALCULATE_DATA_SUCCESS';
export const BOOKINGS_FORM_RECALCULATE_DATA_ERROR =
	'BOOKINGS_FORM_RECALCULATE_DATA_ERROR';

export const BOOKINGS_FORM_FETCH_INFO_LOADING =
	'BOOKINGS_FORM_FETCH_INFO_LOADING';
export const BOOKINGS_FORM_FETCH_INFO_SUCCESS =
	'BOOKINGS_FORM_FETCH_INFO_SUCCESS';
export const BOOKINGS_FORM_FETCH_INFO_ERROR = 'BOOKINGS_FORM_FETCH_INFO_ERROR';

// ----------------- PREVIEW -----------------
export const BOOKINGS_PREVIEW_FETCH_DATA_LOADING =
	'BOOKINGS_PREVIEW_FETCH_DATA_LOADING';
export const BOOKINGS_PREVIEW_FETCH_DATA_SUCCES =
	'BOOKINGS_PREVIEW_FETCH_DATA_SUCCES';
export const BOOKINGS_PREVIEW_FETCH_DATA_ERROR =
	'BOOKINGS_PREVIEW_FETCH_DATA_ERROR';

// ----------------- OFFICE RATING FORM -----------------
export const BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_LOADING =
	'BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_LOADING';
export const BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_SUCCESS =
	'BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_SUCCESS';
export const BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_ERROR =
	'BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_ERROR';

// ----------------- CUSTOMER RATING FORM -----------------
export const BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_LOADING =
	'BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_LOADING';
export const BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_SUCCESS =
	'BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_SUCCESS';
export const BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_ERROR =
	'BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_ERROR';

// ----------------- CUSTOMER COMPENSATION FORM -----------------
export const BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_LOADING =
	'BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_LOADING';
export const BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_SUCCESS =
	'BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_SUCCESS';
export const BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_ERROR =
	'BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_ERROR';

// ----------------- BOOKING VALIDATION MODAL -----------------
export const BOOKINGS_VALIDATION_NEED_TO_REFRESH =
	'BOOKINGS_VALIDATION_NEED_TO_REFRESH';

export const BOOKINGS_VALIDATION_NO_NEED_TO_REFRESH =
	'BOOKINGS_VALIDATION_NO_NEED_TO_REFRESH';
