import { useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';
import usePrevious from '@rooks/use-previous';
import { useDebouncedCallback } from 'use-debounce';

const useSelectCarFiltersInfo = () => {
	const { values } = useFormState();

	const prevValues = usePrevious(values);

	const { submit } = useForm();

	const handleChangeDebounced = useDebouncedCallback(() => {
		submit();
	}, 500);

	useEffect(() => {
		const changed = JSON.stringify(values) !== JSON.stringify(prevValues);

		if (changed) {
			handleChangeDebounced();
		}
	}, [values, prevValues, handleChangeDebounced]);
};

export default useSelectCarFiltersInfo;
