import { createSelector } from 'reselect';

export const previewSelector = (state) => state.bookings.preview;

export const previewBookingSelector = (state) => state.bookings.preview.item;

export const previewCustomerRatingSelector = (state) =>
	state.bookings.preview.customerRating;

export const previewOfficeRatingSelector = (state) =>
	state.bookings.preview.officeRating;

export const editBookingFormSelector = (state) => state.bookings.form;

export const recalculationErrorsSelector = (state) =>
	state.bookings.form.recalculate.errors;

export const supplierUuidSelector = (state) =>
	state.bookings.form.item.item.supplier_uuid;

export const supplierPeriodSelector = (state) =>
	state.bookings.form.item.period;

export const isInfoLoadedSelector = (state) =>
	state.bookings.form.info.isLoaded;

const createRatingSelector = (ratingType) => {
	return createSelector(previewSelector, (preview) =>
		preview.item.ratings && preview.item.ratings[ratingType]
			? preview.item.ratings[ratingType]
			: null
	);
};

export const officeRatingSelector = createRatingSelector('office');

export const customerRatingSelector = createRatingSelector('customer');

export const customerCompensationSelector = createSelector(
	previewSelector,
	(preview) => preview.item.compensation
);

export const isValidationTableChangedSelector = (state) =>
	state.bookings.validation.isTableChanged;

export const bookingBillingsSelector = (state) =>
	state.bookings.preview.item.billings || [];
