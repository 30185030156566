import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	COMPENSATION_FORMULA: 'compensation_formula',
	OPERATION_VALIDATION: 'operation_validation',
	REASON_IN_APP: 'reason_in_app',
	RATING_TYPE: 'rating_type',
};

export const columns = ({ lng }) => [
	{
		Header: 'rating_reasons.table.headers.reason',
		accessor: `name.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'rating_reasons.table.headers.reason_in_app',
		accessor: COLUMNS_ACCESSORS.REASON_IN_APP,
		disableSortBy: true,
	},
	{
		Header: 'rating_reasons.table.headers.type',
		accessor: COLUMNS_ACCESSORS.RATING_TYPE,
		disableSortBy: true,
	},
	{
		Header: 'rating_reasons.table.headers.operation_validation',
		accessor: COLUMNS_ACCESSORS.OPERATION_VALIDATION,
		disableSortBy: true,
	},
	{
		Header: 'rating_reasons.table.headers.compensation_type',
		accessor: `compensation_formula`,
		disableSortBy: true,
	},
	{
		Header: 'rating_reasons.table.headers.compensation_value',
		accessor: `compensation_value`,
		disableSortBy: true,
	},
	{
		Header: 'rating_reasons.table.headers.compensation_max_value',
		accessor: `compensation_max_value`,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
];
