import services from 'services/services';
import {
	companyCarsAvailabilityUrl,
	officeCarsAvailabilityUrl,
} from 'store/paths';

// Import helpers
import { formatValues } from 'views/CarsAvailability/components/Filters/helpers';
import { companyColumns, officeColumns } from 'views/CarsAvailability/columns';
import { getColumns, getExportData } from './helpers';

export const fetchCarsAvailability =
	({
		queryParams,
		cancelToken,
		updateColumns,
		options,
		filters,
		userCompanyUuid,
	}) =>
	async () => {
		try {
			const { pageSize, pageIndex, isExport } = options;

			const formattedFilters = filters ? formatValues(filters) : {};

			if (userCompanyUuid) {
				formattedFilters.company_uuid = userCompanyUuid;
			}

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
				filters: formattedFilters,
			};

			const { company_uuid } = formattedFilters;

			let data;

			if (userCompanyUuid || company_uuid) {
				const { data: offices } = await services.post(
					officeCarsAvailabilityUrl(queryParams),
					body,
					cancelToken
				);
				data = offices;
			} else {
				const { data: companies } = await services.post(
					companyCarsAvailabilityUrl(queryParams),
					body,
					cancelToken
				);
				data = companies;
			}

			const newColumns = getColumns(data.data);

			const sortedColumns = newColumns.sort((a, b) =>
				a.Header.localeCompare(b.Header)
			);

			const isOfficeLevel = !!company_uuid;

			const exportData = isExport && getExportData(data.data, isOfficeLevel);

			const resultData = isExport ? { ...data, data: exportData } : data;

			const baseColumn = company_uuid ? officeColumns : companyColumns;

			!isExport && updateColumns([...baseColumn, ...sortedColumns]);

			return Promise.resolve({ data: resultData });
		} catch (error) {
			return Promise.reject(error);
		}
	};
