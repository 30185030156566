import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { OPTIONS } from './helpers';

const OperationTopicsFilter = () => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid item md={3}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t('bookings.filters.topic')}
				name="operation_topics"
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				{OPTIONS.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{t(label)}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

export default OperationTopicsFilter;
