import {
	checkIfTimeIsBeforeCurrentTime,
	DELIVERY_STATUS,
	isTimeInPast,
} from 'helpers';

export const useDeliveryStatusTableCell = ({ cell }) => {
	const { PENDING, IN_PROGRESS, FINISHED, CANCELLED } = DELIVERY_STATUS;
	const { delivery, pick_time: pickTime } = cell.row.original || {};
	const { pick_up_delivery: pickUpDelivery } = delivery || {};
	const { delivery_state: deliveryState, has_late_delivery: hasLateDelivery } =
		pickUpDelivery || {};

	let deliveryStatus = {};

	const isPickupTimePass = isTimeInPast(pickTime);
	const isPickupTimeSoon = checkIfTimeIsBeforeCurrentTime({
		time: pickTime,
		beforeInMin: 15,
	});

	switch (true) {
		case deliveryState === PENDING && isPickupTimeSoon:
			deliveryStatus = {
				text: 'bookings.late_delivery_status.delivery_has_not_started',
				color: 'error',
			};
			break;
		case deliveryState === PENDING && isPickupTimePass:
			deliveryStatus = {
				text: 'bookings.preview_booking.car.step_captions.prolonged',
				color: 'error',
			};
			break;
		case deliveryState === IN_PROGRESS && isPickupTimePass:
			deliveryStatus = {
				text: 'bookings.late_delivery_status.driver_has_not_arrived',
				color: 'error',
			};
			break;
		case deliveryState === FINISHED && hasLateDelivery:
			deliveryStatus = {
				text: 'bookings.late_delivery_status.delivered_late',
				color: 'error',
			};
			break;
		case deliveryState === CANCELLED && isPickupTimePass:
			deliveryStatus = {
				text: 'bookings.late_delivery_status.cancelled_after_pickup_time',
				color: 'error',
			};
			break;

		default:
			deliveryStatus = {
				text: 'common.not_available_shorthand',
				color: 'gray',
			};
			break;
	}

	return { deliveryStatus };
};
