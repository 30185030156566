// ********************  form  ********************

export const fetchPlateFormResourcesUrl = (uuid) =>
	`v2/supplier/vehicle/plate/${uuid}/show`;

export const submitPlateFormUrl = (itemId) =>
	itemId
		? `v2/supplier/vehicle/plate/${itemId}/update`
		: 'v2/supplier/vehicle/plate/create';

export const deletePlateUrl = (uuid) =>
	`v2/supplier/vehicle/plate/${uuid}/remove`;
