import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import components
import { TotalUrgentsCard, ListItemsCard, AvgTimeCard } from '..';
import { Alert } from 'components/elements';

// Import utilities
import { useStatsContextProvider } from '../../context';

// Import helpers
import { LIST_ITEMS_TYPES } from '../../helpers';

export const StatsCards = () => {
	const { isError, error } = useStatsContextProvider();

	if (isError)
		return (
			<Box width="100%">
				<Alert message={error.message} />
			</Box>
		);

	return (
		<Grid container spacing={2}>
			<Grid item xs={6} md={3}>
				<TotalUrgentsCard />
			</Grid>
			<Grid item xs={6} md={3}>
				<AvgTimeCard />
			</Grid>
			<Grid item xs={6} md={3}>
				<ListItemsCard
					title="bookings.stats_cards.top_reasons_title"
					type={LIST_ITEMS_TYPES.REASONS}
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<ListItemsCard
					title="bookings.stats_cards.top_companies_title"
					type={LIST_ITEMS_TYPES.COMPANIES}
				/>
			</Grid>
		</Grid>
	);
};
