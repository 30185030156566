import { useState } from 'react';
// Import utilities
import { usePrintProvider } from 'components/context';

// Import assets
import { carPlaceholder } from 'assets/images';

export const useImageWrapper = () => {
	const [isError, setIsError] = useState(false);

	const {
		data: {
			item: { vehicle_image_url, vehicle_maker_name, vehicle_model_name },
		},
		setIsReadyToPrint,
	} = usePrintProvider();

	const altText = `${vehicle_maker_name} ${vehicle_model_name}`;

	const image = isError ? carPlaceholder : vehicle_image_url;

	const handleOnError = () => {
		setIsReadyToPrint(true);
		setIsError(true);
	};

	const onLoad = () => setIsReadyToPrint(true);

	return {
		handleOnError,
		onLoad,
		altText,
		image,
	};
};
