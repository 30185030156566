import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

// Import utilities
import { useOfficeCollection } from 'queries';

// Import helpers
import { supplierPeriodSelector } from 'store/selectors';
import { convertDate, DEFAULT_DATE_FORMAT } from 'helpers';

const useOfficeFilter = () => {
	const { submitting, values } = useFormState();

	const { id: bookingUuid } = useParams();

	const { pickup_date, pick_time, drop_date, vehicle, company_uuid } =
		values || {};

	const {
		drop_date: dropDateSupplier,
		pick_date: pickDateSupplier,
		pick_time: pickTimeSupplier,
	} = useSelector(supplierPeriodSelector);

	const body = {
		vehicle_year: vehicle?.year || null,
		car_model_uuid: vehicle?.model_uuid || null,
		car_manufacturer_uuid: vehicle?.maker_uuid || null,
		company_uuid: company_uuid || null,
		pickup_date: convertDate(
			`${convertDate(pickup_date || pickDateSupplier, DEFAULT_DATE_FORMAT)} ${
				pick_time || pickTimeSupplier
			}`
		),
		drop_date: convertDate(drop_date || dropDateSupplier, DEFAULT_DATE_FORMAT),
	};

	const { data: offices, isFetching } = useOfficeCollection({
		bookingUuid,
		body,
	});

	return { submitting, offices, isFetching };
};

export default useOfficeFilter;
