import React from 'react';
import { dialogMessageSelector } from 'store/selectors';
import { useSelector } from 'react-redux';
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from '@material-ui/core';

// Import components
import { MakeCompensationForm } from 'components/elements';
import useValidateCompensationForm from './useValidateCompensationForm';

// Import translations
import { useTranslations } from 'components/utilities';

const ValidateCompensationForm = () => {
	const { t } = useTranslations();

	const { isSubmitted, onClose } = useValidateCompensationForm();
	const dialogMessage = useSelector(dialogMessageSelector);

	if (isSubmitted) {
		return <Typography>{t(dialogMessage)}</Typography>;
	}

	return (
		<Grid item xs={12}>
			<Card>
				<CardHeader title={t('validation.table.modal.compensation_title')} />
				<CardContent>
					<MakeCompensationForm
						isInModal
						onClose={onClose}
						location="validation"
						isInvalidButtonVisible
					/>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default ValidateCompensationForm;
