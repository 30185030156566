import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';

export const RatingTypeTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const { rating_type: ratingType } = cell.row.original || {};

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{t(`rating_reasons.form.reason_type.${ratingType.toLowerCase()}`)}
		</TableCell>
	);
};

RatingTypeTableCell.propTypes = {
	cell: object.isRequired,
};
