import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import get from 'lodash/get';

// Import context
import { useSettlementHistory } from '../../context';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { ActionTableCell } from './components';

const { ACTION, ID } = COLUMNS_ACCESSORS;

export const SettlementsHistoryBodyRow = () => {
	const { data } = useSettlementHistory();

	if (!data) {
		return null;
	}

	return (
		<>
			{data.map((row, rowIndex) => (
				<TableRow key={row.uuid}>
					{columns.map(({ accessor }) => {
						switch (accessor) {
							case ID:
								return (
									<TableCell key={accessor}>
										<Typography>{rowIndex + 1}</Typography>
									</TableCell>
								);

							case ACTION:
								return (
									<ActionTableCell key={accessor} amount={get(row, accessor)} />
								);
							default:
								return (
									<TableCell key={accessor}>{get(row, accessor)}</TableCell>
								);
						}
					})}
				</TableRow>
			))}
		</>
	);
};
