import { useSelector } from 'react-redux';
import { get } from 'lodash';

// Import selectors
import {
	profileItemSelector,
	roleSelector,
	tokenSelector,
} from 'store/selectors';

// Import helpers
import {
	COMPANY_MANAGER,
	ADMIN,
	OFFICE_MANAGER,
	CORPORATE_COORDINATOR,
	DRIVER,
} from 'helpers';

export const useProfile = () => {
	const role = useSelector(roleSelector)?.[0];
	const profileData = useSelector(profileItemSelector);
	const token = useSelector(tokenSelector);

	const isAdmin = role === ADMIN;
	const isCompanyManager = role === COMPANY_MANAGER;
	const isOfficeManager = role === OFFICE_MANAGER;
	const isCorporateCoordinator = role === CORPORATE_COORDINATOR;

	const companyUuid = get(profileData, 'belonging.company.uuid', null);

	const assignedOfficesUuids = get(profileData, 'belonging.offices', []).map(
		({ uuid }) => uuid
	);

	const profileDetails = get(profileData, 'details', {});

	const isDriver = role === DRIVER;

	return {
		isAdmin,
		isCompanyManager,
		isOfficeManager,
		isCorporateCoordinator,
		companyUuid,
		assignedOfficesUuids,
		profileDetails,
		token,
		isDriver,
	};
};
