// Import utils and helpers
import { useTableExportContextProvider } from 'components/context/TableExportProvider/useTableExportContextProvider';
import { createExport } from './helpers';

const useTableExport = (props) => {
	const { type, fetchData, visibleColumns, isLoading } =
		useTableExportContextProvider();

	const { customExport } = props || {};

	const handleExport = async () => {
		const data = await fetchData();

		if (!data) {
			return;
		}

		const tableExport = createExport({ type, customExport });

		const file = tableExport({
			data,
			columns: visibleColumns,
		});

		file.download();
	};

	return {
		handleExport,
		isLoading,
	};
};

export default useTableExport;
