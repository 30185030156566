import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '100%',
		padding: '8px 16px',
	},
	topBar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	settlementBox: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
	collapse: {
		width: '100%',
		padding: '4px',
	},
	innerCollapseBox: {
		minHeight: '300px',
	},
}));
