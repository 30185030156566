import React, { useEffect } from 'react';
import { Field, useFormState } from 'react-final-form';
import { Grid, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';

// Import utilities
import { useCompanyActiveCollection } from 'queries/PublicHolidays';
import { useProfile, useTranslations } from 'components/utilities';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import helpers
import { EN } from 'helpers';

// Import store
import { PUBLIC_HOLIDAYS_FORM_REFRESH } from 'store/types';
import { refreshHolidays } from 'store/actions/helpers';

const CompanySelect = () => {
	const { t, i18n } = useTranslations();
	const { data: companies, isFetched } = useCompanyActiveCollection();
	const { submitting, values } = useFormState();
	const { isAdmin } = useProfile();
	const dispatch = useDispatch();

	const language = i18n.language;

	useEffect(() => {
		if (isAdmin && values?.company_uuid) {
			refreshHolidays({
				type: PUBLIC_HOLIDAYS_FORM_REFRESH,
				companyUuid: values?.company_uuid,
				isConverted: true,
				dispatch,
			});
		}
		// eslint-disable-next-line
	}, [values?.company_uuid]);

	if (!isAdmin) return null;

	return (
		<Grid item md={6} xs={12}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				type="select"
				label={t('public_holidays.form.company_select')}
				name="company_uuid"
				margin="dense"
				variant="outlined"
				required
				disabled={!isFetched || submitting}
				defaultValue={isFetched ? companies[0].uuid : null}
			>
				{companies.map(({ uuid, name: { en, ar } }) => (
					<MenuItem key={uuid} value={uuid}>
						{language === EN ? en : ar}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

export default CompanySelect;
