import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { URLS } from 'components/routes';

// Import components
import { LinkTableCell } from 'components/elements';
import { ActionTableCell } from '..';

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.CORPORATE_NAME:
				return (
					<LinkTableCell key={cell.column.id} cell={cell} to={URLS.bookingsUrl}>
						Corporate name
					</LinkTableCell>
				);

			case COLUMNS_ACCESSORS.ACTION:
				return <ActionTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
