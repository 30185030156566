import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce/lib';
import usePrevious from '@rooks/use-previous';
import _ from 'lodash';

// Import utilities
import { recalculateBooking } from 'store/actions';
import { useEditBookingHistory } from 'queries';

// Import helpers
import { editBookingFormSelector } from 'store/selectors';

const useEditBookingInfo = () => {
	const { values } = useFormState();

	const dispatch = useDispatch();

	const prevValues = usePrevious(values);

	const { id: itemId } = useParams();

	const { data: editHistoryValues, isFetching: historyFetching } =
		useEditBookingHistory({ uuid: itemId });

	const isHistory = editHistoryValues?.length;
	const initialHistoryValues = isHistory ? editHistoryValues?.[0] : null;

	const { item } = useSelector(editBookingFormSelector);

	const initialValues = useRef(item).current;

	const { values: currentValues } = useFormState();

	const initialTotal = +initialValues?.item?.total_discounted_gross_price;
	const initialHistoryTotal =
		+initialHistoryValues?.item?.total_discounted_gross_price;
	const currentTotal = +currentValues.item.total_discounted_gross_price;

	const priceDifference = currentTotal - (initialHistoryTotal || initialTotal);

	const showPrice =
		typeof priceDifference === 'number' &&
		!isNaN(priceDifference) &&
		priceDifference !== 0;

	const debounceRecalculation = useDebouncedCallback(() => {
		recalculateBooking({ values, itemId })(dispatch);
	}, 500);

	useEffect(() => {
		const isEqual = _.isEqual(prevValues, values);

		if (!isEqual && !!prevValues) {
			debounceRecalculation();
		}
	}, [values, prevValues, itemId, dispatch, debounceRecalculation]);

	return {
		initialHistoryValues,
		initialValues,
		currentValues,
		priceDifference,
		showPrice,
		isHistory,
		historyFetching,
	};
};

export default useEditBookingInfo;
