import React from 'react';
import { AddCustomerStep, ConfirmationStep } from './components';

export const DIALOG_STEPS = {
	CONFIRMATION: 'confirmation',
	ADD_CUSTOMER: 'add_customer',
};

export const CLICKED_BUTTON_NAME = {
	ADD_CUSTOMER_AND_BOOK_CAR: 'add_customer_and_book_car',
	ADD_CUSTOMER: 'add_customer',
};

export const getDialogSteps = (step) => {
	switch (step) {
		case DIALOG_STEPS.CONFIRMATION:
			return <ConfirmationStep />;
		case DIALOG_STEPS.ADD_CUSTOMER:
			return <AddCustomerStep />;
		default:
			return null;
	}
};
