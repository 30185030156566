// Import helpers
import { convertFormDates } from 'helpers';

export const initialValues = {
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
	state: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		state: values.state === 'all' ? null : values.state,
		...convertFormDates(values, ['pick_date', 'drop_date']),
	};
};
