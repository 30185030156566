import React from 'react';
import { Box } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

const CheckboxSection = () => {
	const { t } = useTranslations();
	const { values, submitting } = useFormState();

	return (
		<Box>
			<Field
				component={CheckboxFieldAdapter}
				type="checkbox"
				labelText={t('customers.registration_process.form.elm_checkbox')}
				name="ELM"
				margin="dense"
				disabled={submitting}
				checked={values.ELM}
			/>
			<Field
				component={CheckboxFieldAdapter}
				type="checkbox"
				labelText={t('customers.registration_process.form.telgani_checkbox')}
				name="TELGANI"
				margin="dense"
				disabled
				checked={true}
			/>
		</Box>
	);
};

export default CheckboxSection;
