import React from 'react';
import { string, bool } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// Import styles
import useStyles from '../styles';

export const EditLink = ({ editLinkPath, canEdit = true }) => {
	const classes = useStyles();

	return (
		<>
			{canEdit ? (
				<>
					<Link
						className={classes.link}
						component={RouterLink}
						to={editLinkPath}
						variant="h6"
					>
						<EditIcon className={classes.icon} />
					</Link>
				</>
			) : null}
		</>
	);
};

EditLink.propTypes = {
	editLinkPath: string.isRequired,
	canEdit: bool,
};
