import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { generateArray } from 'helpers';

// Import styles
import { useStyles } from './DialogInstruction.styles';

// Import components
import { NestedList } from '../../components';

export const DialogInstruction = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const keyPrefix = 'offices.edit_prices_dialog';

	const isNestedListVisible = (number) => number === 4 || number === 5;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography className={classes.mainText}>
					{t(`${keyPrefix}.header`)}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box component="ol" className={classes.list}>
					{generateArray(1, 8).map((value) => (
						<li key={value}>
							<Typography className={classes.item}>
								{t(`${keyPrefix}.list.${value}`)}
							</Typography>
							{isNestedListVisible(value) && <NestedList value={value} />}
						</li>
					))}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Typography className={classes.mainText}>
					{t(`${keyPrefix}.end_information`)}
				</Typography>
			</Grid>
		</Grid>
	);
};
