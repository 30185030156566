import i18next from 'i18next';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

// Import helpers
import { fuelTypes, specification } from './helpers';
import { generateArray } from 'helpers';

export const useCarSpecification = () => {
	const doorsNumber = generateArray(1, 5).map((number) => ({
		label: number,
		value: number.toString(),
	}));

	const fuelTypesLabels = fuelTypes.map((option) => ({
		label: i18next.t(`cars.form.car_specifications.options.energy.${option}`),
		value: option,
	}));

	const CHECKBOX_FIELDS = (section) =>
		specification.map((option) => ({
			component: CheckboxFieldAdapter,
			type: 'checkbox',
			label: `cars.form.car_specifications.options.${option}`,
			name: `carInfo.${section}.${option}`,
			xs: 12,
			color: 'primary',
		}));

	const SELECT_FIELDS = (section) => [
		{
			label: `cars.form.car_specifications.options.energy.title`,
			name: `carInfo.${section}.fuel_type`,
			options: fuelTypesLabels,
			xs: 6,
		},
		{
			label: `cars.form.car_specifications.options.doors_number`,
			name: `carInfo.${section}.doors_number`,
			options: doorsNumber,
			xs: 6,
		},
	];

	return { CHECKBOX_FIELDS, SELECT_FIELDS };
};
