import React from 'react';
import { Typography } from '@material-ui/core';
import { object, array, oneOfType } from 'prop-types';

// Import components
import PreviewField from '../PreviewField';

// Import helpers
import { getLabel } from './helpers';

export const DividedTotalGrossAmountPreviewField = ({ customData }) => {
	const { payment_methods: paymentMethods } = customData;

	return paymentMethods.map(({ gateway, amount_gross }) => {
		const label = getLabel({ gateway });

		return (
			<PreviewField label={label} key={gateway}>
				<Typography>{amount_gross}</Typography>
			</PreviewField>
		);
	});
};

DividedTotalGrossAmountPreviewField.propTypes = {
	customData: oneOfType([array, object]),
};
