import { BOOKING_CANCELLATION_STATES, convertFormDates } from 'helpers';

export const initialValues = {
	is_compensation: 'all',
	company_uuid: 'all',
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
	cancel_reason_status: BOOKING_CANCELLATION_STATES.REVIEW,
};

export const formatValues = (values) => {
	return {
		...values,
		company_uuid: values.company_uuid,
		cancel_reason_status: values.cancel_reason_status,
		is_compensation:
			values.is_compensation === 'all' ? null : values.is_compensation,
		...convertFormDates(values, ['pick_date', 'drop_date']),
	};
};
