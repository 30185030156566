import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { array, bool } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { AlertMessage, LoadingWrapper } from 'components/elements';

export const Content = ({ data, isLoading, isError }) => {
	const { t } = useTranslations();
	if (isLoading) return <LoadingWrapper />;

	if (isError)
		return (
			<AlertMessage
				title={t('common.error')}
				description={t('common.messages.error_message')}
			/>
		);

	return (
		<Grid container spacing={1}>
			{data.map(({ label, value }) => (
				<Grid key={label} item xs={6}>
					<Typography variant="h5" align="center">
						{t(label)}
					</Typography>
					<Typography variant="subtitle1" align="center" color="primary">
						{value}
					</Typography>
				</Grid>
			))}
		</Grid>
	);
};

Content.propTypes = {
	isLoading: bool,
	isError: bool,
	data: array,
};
