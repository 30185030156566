import { splitText } from 'helpers';

export const formatDates = (cell) => {
	if (cell.row.original.extension_created_at) {
		const allDatesArr = cell.row.original.extension_created_at.split(',');
		const trimDatesArr = allDatesArr.map((date) => splitText(date.trim(), ' '));
		return trimDatesArr;
	}
	return [];
};
