import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { number, string } from 'prop-types';
import { CircularProgress, MenuItem, Checkbox, Grid } from '@material-ui/core';

// Import components
import { MultiselectFiledAdapter } from 'components/elements/molecules';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useVehicleTypes } from 'queries';

// Import helpers

export const CarTypeFilter = ({ name, xs = 3 }) => {
	const { submitting, values } = useFormState();

	const { t } = useTranslations();

	const { data: carTypes, isFetching } = useVehicleTypes();

	const currentTypes = values[name] || [];

	if (!carTypes.length) return null;

	return (
		<Grid container item xs={xs} alignItems="center">
			{isFetching ? (
				<Grid item xs>
					<CircularProgress size={27} />
				</Grid>
			) : (
				<Grid item xs>
					<Field
						format={(value) => (Array.isArray(value) ? value : [])}
						label={t('cms.pages.form.filters.car_type')}
						component={MultiselectFiledAdapter}
						disabled={submitting}
						variant="outlined"
						margin="dense"
						type="select"
						name={name}
						fullWidth
					>
						{carTypes.map(({ uuid, type }) => (
							<MenuItem key={uuid} value={type}>
								<Checkbox
									checked={currentTypes.includes(type)}
									color="primary"
								/>
								{type}
							</MenuItem>
						))}
					</Field>
				</Grid>
			)}
		</Grid>
	);
};

CarTypeFilter.propTypes = {
	name: string.isRequired,
	xs: number,
};
