import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { bool } from 'prop-types';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { STATION_FIELDS } from './helpers';
import { useAllCities } from 'queries';

// Import components
import { ButtonProgress } from 'components/elements';
import { Location } from './components';

export const FormContent = ({ isSubmitLoading }) => {
	const { renderFields } = useCommonFields();
	const { data: cities } = useAllCities();
	const { t } = useTranslations();
	const { goBack } = useHistory();

	return (
		<Grid container spacing={2}>
			{STATION_FIELDS({ cities }).map(renderFields)}
			<Grid item xs={12}>
				<Location section="location" />
			</Grid>
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end" spacing={2}>
					<Grid item xs="auto">
						<Button color="primary" variant="outlined" onClick={goBack}>
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item xs="auto">
						<ButtonProgress
							isLoading={isSubmitLoading}
							disabled={isSubmitLoading}
							variant="contained"
							color="primary"
							type="submit"
						>
							{t('common.buttons.submit')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isSubmitLoading: bool.isRequired,
};
