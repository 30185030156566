import services from 'services/services';

// Import store
import {
	approveLeasingCancellationUrl,
	rejectLeasingCancellationUrl,
	fetchLeasingCancellationUrl,
} from 'store/paths';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';

export const fetchLeasingCancellation =
	({ options, queryParams, cancelToken, defaultQueryParams }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({
				queryParams: queryParams || defaultQueryParams,
				body,
			});

			const { data } = await services.get(
				fetchLeasingCancellationUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const approveLeasingCancellationBooking = async (bookingUuid) =>
	await services.patch(approveLeasingCancellationUrl(bookingUuid), {});

export const rejectLeasingCancellationBooking = async (bookingUuid) =>
	await services.patch(rejectLeasingCancellationUrl(bookingUuid), {});
