export const INITIAL_VALUES = {
	details: {
		name: {
			ar: '',
			en: '',
		},
		name_mobile_app: {
			ar: '',
			en: '',
		},
	},
	operation_validation: false,
	auto_compensation: false,
	compensation_formula: null,
	compensation_value: null,
	compensation_max_value: null,
	use_for_mobile: false,
};
