import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utils
import { useCommonFields, useTranslations } from 'components/utilities';
import { useReasonType } from './useReasonType';

export const ReasonType = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	const { sectionName, ratingFields } = useReasonType();

	return (
		<Card>
			<CardHeader title={t(`${sectionName}.title`)} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{ratingFields.map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
