import React from 'react';
import { string } from 'prop-types';
import { Field } from 'react-final-form';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';
import { useFilters } from './useFilters';

// Import components
import {
	CarType,
	Manufacturer,
	Model,
	LocationAutocompleteFieldAdapter,
} from 'components/elements';

export const Filters = ({ section }) => {
	const { t } = useTranslations();

	const {
		handleLocationChanged,
		setAutocompleteValue,
		manufacturers,
		carTypes,
		models,
		manufacturerFieldName,
		typeFieldName,
		labelFieldName,
		modelFieldName,
		submitting,
		isManufacturersLoading,
		isModelsLoading,
		isModelFieldDisabled,
		isCarTypesLoading,
	} = useFilters({ section });

	return (
		<Card>
			<CardHeader component="h5" title={t('cms.pages.form.filters.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={6} xs={12}>
						<Field
							fullWidth
							component={LocationAutocompleteFieldAdapter}
							type="text"
							name={labelFieldName}
							label={t('cms.pages.form.filters.add_location')}
							margin="dense"
							variant="outlined"
							setAutocompleteValue={setAutocompleteValue}
							onChange={handleLocationChanged}
							placeholder={t('cms.pages.form.filters.add_location')}
							required
						/>
					</Grid>
					<CarType
						label="cms.pages.form.filters.car_type"
						name={typeFieldName}
						types={carTypes}
						isLoading={isCarTypesLoading}
						showAllOption
						disabled={submitting}
						md={6}
					/>
					<Manufacturer
						label="cars.car_list.form.details.manufacturer"
						name={manufacturerFieldName}
						manufacturers={manufacturers}
						isLoading={isManufacturersLoading}
						showAddManufacturerOption
						showAllOption
						md={6}
					/>
					<Model
						label="cars.car_list.form.details.model"
						name={modelFieldName}
						models={models}
						disabled={isModelFieldDisabled}
						isLoading={isModelsLoading}
						showAddModelOption
						showAllOption
						md={6}
					/>
				</Grid>
			</CardContent>
		</Card>
	);
};

Filters.propTypes = {
	section: string.isRequired,
};
