// Import helpers
import { extendContractUrl } from 'store/paths';
import { convertExtendContractSubmitValues } from './helpers';

// Import utils
import services from 'services/services';

export const extendContract = async ({
	callbackSuccess,
	contractNumber,
	errorCallback,
	isOtpRequired,
	values,
}) => {
	try {
		const convertedValues = convertExtendContractSubmitValues(
			values,
			isOtpRequired
		);
		await services.put(extendContractUrl(contractNumber), convertedValues);
		callbackSuccess();
	} catch (error) {
		if (error.response) {
			const { errors, message } = error.response.data || {};
			const callbackText = errors ? errors.contract_number[0] : message;
			errorCallback(callbackText);
		}
	}
};
