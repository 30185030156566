import services from 'services/services';
import _ from 'lodash';

// Import store
import {
	fetchCompanyDashboardStatsUrl,
	fetchAdminDashboardStatsUrl,
} from 'store/paths';

// Import helpers
import { convertPeriod } from './helpers';

export const fetchCompanyDashboardStats =
	({ options, externalFilters, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const period = convertPeriod(externalFilters.period);

			const convertedExternalFilters = externalFilters.office_uuids.length
				? externalFilters
				: _.omit(externalFilters, ['office_uuids', 'period']);

			const filters = {
				...convertedExternalFilters,
				from: period.from,
				to: period.until,
			};

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				...filters,
			};

			const { data } = await services.post(
				fetchCompanyDashboardStatsUrl,
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const fetchAdminDashboardStats =
	({ options, externalFilters, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const period = convertPeriod(externalFilters.period);

			const convertedExternalFilters = externalFilters.office_uuids.length
				? externalFilters
				: _.omit(externalFilters, ['office_uuids', 'period']);

			const filters = {
				...convertedExternalFilters,
				from: period.from,
				until: period.until,
			};

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				...filters,
			};

			const { data } = await services.post(
				fetchAdminDashboardStatsUrl,
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
