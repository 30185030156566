import React from 'react';
import {
	Box,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Typography,
} from '@material-ui/core';

// Import components
import { ChipLabel } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useStatsContextProvider } from '../../context';

export const AvgTimeCard = () => {
	const { t } = useTranslations();

	const {
		isLoading,
		stats: { currentAvg, prevAvg },
	} = useStatsContextProvider();

	return (
		<Card>
			<CardContent>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h6">
								{t('bookings.stats_cards.avg_time_title')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h3" color="primary">
								{currentAvg}{' '}
								<Typography component="span">
									{t('bookings.stats_cards.minutes')}
								</Typography>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Box display="flex" gridGap={10} alignItems="center">
								<ChipLabel
									label={<Box fontWeight="bold">{prevAvg}</Box>}
									size="small"
								/>
								<Typography>
									{t('bookings.stats_cards.avg_prev_month')}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	);
};
