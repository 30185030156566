import React, { useEffect } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { Field, useForm, useFormState } from 'react-final-form';
import { array } from 'prop-types';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter, TextFieldAdapter } from 'components/elements';

// Import helpers
import { isInputDisable } from './helpers';

// Import styles
import { useStyles } from './AdditionalServices.styles';

export const AdditionalServices = ({ fields }) => {
	const { values } = useFormState();
	const { t } = useTranslations();
	const { change } = useForm();
	const classes = useStyles();

	useEffect(() => {
		fields.forEach(({ inputName, checkboxName }) => {
			const splittedInputName = inputName.split('.')[1];
			const inputValue = values.additional_services[splittedInputName];
			change(checkboxName, !!inputValue);
		});
		//eslint-disable-next-line
	}, []);

	return fields.map(({ inputName, checkboxName, label }) => (
		<Grid
			container
			justifyContent="space-between"
			key={inputName}
			className={classes.container}
		>
			<Grid item xs={8}>
				<Field
					component={CheckboxFieldAdapter}
					type="checkbox"
					labelText={t(label)}
					name={checkboxName}
					color="primary"
				/>
			</Grid>
			<Grid item xs={4}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="number"
					name={inputName}
					disabled={isInputDisable(values, checkboxName)}
					margin="dense"
					variant="outlined"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{t('currency.sar')}
							</InputAdornment>
						),
					}}
				/>
			</Grid>
		</Grid>
	));
};

AdditionalServices.propTypes = {
	fields: array,
};
