// Import helpers
import { KPI_TYPES } from 'helpers';

export const convertSubmitCancellationReasonsFormData = (values) => ({
	...values,
	kpi_type: values.kpi_type === KPI_TYPES.NONE ? null : values.kpi_type,
});

export const convertFetchCancellationReasonsFormData = (values) => ({
	...values,
	kpi_type: values.kpi_type === null ? KPI_TYPES.NONE : values.kpi_type,
});
