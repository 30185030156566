import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CUSTOMER_WALLET_REASONS, CUSTOMER_WALLET_OPERATIONS } from 'helpers';
import { defaultReason } from './helpers';

const { DEDUCT } = CUSTOMER_WALLET_OPERATIONS;

export const useCustomerWalletReasons = (type = DEDUCT) => {
	const path = '/v2/customer/wallet/balance/history/reasons';

	const body = { type };

	const { data, isFetched, isError, error } = useQuery(
		[CUSTOMER_WALLET_REASONS, type],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false, staleTime: Infinity }
	);

	const walletReasons = data?.data.data;

	const convertedWalletReasons =
		isFetched && !isError ? [...walletReasons, defaultReason] : [defaultReason];

	return { data: convertedWalletReasons, isFetched, isError, error };
};
