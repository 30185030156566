// Import helpers
import { ADMIN } from 'helpers';

export const INITIAL_VALUES = {
	details: {
		full_name: '',
		email: '',
		phone_prefix: '',
		phone: '',
		role: ADMIN,
		license_expiry_date: new Date(),
	},
	password: '',
	password_confirmation: '',
};
