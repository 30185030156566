import React from 'react';
import { bool, func, string } from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { TextFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';

const BasePriceRequestFields = ({ isLoading, onBasePriceSubmit, section }) => {
	const { t } = useTranslations();

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item xs={6}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="number"
					label={t(`${section}.form.base_nett_price`)}
					name="base_nett_price"
					variant="outlined"
					disabled={isLoading}
					inputProps={{ step: 0.01, min: 0.01 }}
				/>
			</Grid>
			<Grid item>
				<Button
					disabled={isLoading}
					onClick={onBasePriceSubmit}
					variant="contained"
					color="primary"
				>
					{t('common.buttons.ok')}
				</Button>
			</Grid>
		</Grid>
	);
};

BasePriceRequestFields.propTypes = {
	isLoading: bool.isRequired,
	onBasePriceSubmit: func.isRequired,
	section: string.isRequired,
};

export default BasePriceRequestFields;
