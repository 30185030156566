import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { updateActiveRatingReason, deleteRatingReason } from 'store/actions';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslations } from 'components/utilities';
// Import helpers
import { replaceEditRatingReasonsUrl, TABLE_ACTIONS_ACCESSORS } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { CompensationFormulaCell } from '../../components';
import {
	EditTableCell,
	ActiveTableCell,
	DeleteTableCell,
	RatingTypeTableCell,
} from 'components/elements';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const {
	ACTIVE,
	COMPENSATION_FORMULA,
	OPERATION_VALIDATION,
	REASON_IN_APP,
	RATING_TYPE,
} = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const {
		i18n: { language },
	} = useTranslations();
	const renderCell = (cell) => {
		switch (cell.column.id) {
			case ACTIVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateActiveRatingReason}
						cell={cell}
					/>
				);
			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditRatingReasonsUrl(cell.row.original.uuid)}
					/>
				);
			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteRatingReason}
						cell={cell}
					/>
				);

			case REASON_IN_APP:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.name_mobile_app[language]}
					</TableCell>
				);

			case OPERATION_VALIDATION:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.operation_validation && <CheckIcon />}
					</TableCell>
				);

			case COMPENSATION_FORMULA:
				return <CompensationFormulaCell key={cell.column.id} cell={cell} />;

			case RATING_TYPE:
				return <RatingTypeTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
