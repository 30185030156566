import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';

// Import components
import { ButtonProgress } from 'components/elements';
import CompanySelect from '../CompanySelect';

// Import utilities
import { useTranslations } from 'components/utilities';

const DropCitiesTableToolbar = ({
	companyUuid,
	handleCompanyUuidChange,
	saveDropCities,
	isSaving,
	isLoading,
}) => {
	const { t } = useTranslations();

	return (
		<Box mb={1}>
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item xs={3}>
					<CompanySelect
						value={companyUuid}
						onChange={handleCompanyUuidChange}
					/>
				</Grid>
				<Grid item xs="auto">
					<ButtonProgress
						onClick={saveDropCities}
						color="primary"
						variant="contained"
						isLoading={isSaving}
						disabled={isLoading}
					>
						{t('common.buttons.save')}
					</ButtonProgress>
				</Grid>
			</Grid>
		</Box>
	);
};

DropCitiesTableToolbar.propTypes = {
	companyUuid: PropTypes.string,
	handleCompanyUuidChange: PropTypes.func,
	saveDropCities: PropTypes.func,
	isSaving: PropTypes.bool,
	isLoading: PropTypes.bool,
};
export default DropCitiesTableToolbar;
