import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import { useFormState, useForm, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/styles';
import { List, Box, Typography } from '@material-ui/core';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';
// import { ListItem, CollapsedList } from '../index';
import { ListItem } from '../ListItem';

// Import helpers
import { EN } from 'helpers/variables';
import { getNewStates } from './helpers';

// Import translations
import { useTranslations } from 'components/utilities';

const useStyles = makeStyles(() => ({
	root: {
		maxHeight: 400,
		width: '100%',
		overflowY: 'auto',
	},
	selectAll: {
		padding: '0px 15px 8px 15px',
	},
	error: {
		color: '#e53935',
		paddingBottom: 10,
		fontWeight: 500,
	},
}));

const OfficesList = ({ offices }) => {
	const [isSelectAll, setIsSellectAll] = useState(false);
	const [checkedCars, setCheckedCars] = useState([]);
	const [checkedOffices, setCheckedOffices] = useState([]);

	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const classes = useStyles();

	const { values, submitting, errors } = useFormState();

	const { change } = useForm();

	const isEnglish = lng === EN;

	const carsFieldName = 'cars';

	const {
		carInfo: {
			vehicle: { year, maker_name, model_name },
		},
	} = values;

	const officesArray = Object.values(offices);

	useEffect(() => {
		setCheckedCars([]);
		setCheckedOffices([]);
		setIsSellectAll(false);
	}, [year, maker_name, model_name]);

	const handleSelectAll = ({ target }) => {
		setIsSellectAll(!isSelectAll);

		const newCheckedCars = getNewStates({
			target,
			officesArray,
			checkedCars,
			checkedOffices,
			setCheckedCars,
			setCheckedOffices,
		});

		change(carsFieldName, newCheckedCars);
	};

	return (
		<>
			{checkedCars.length === 0 && (
				<Typography className={classes.error}>{t(errors.cars)}</Typography>
			)}
			<Box className={classes.selectAll}>
				<Field
					component={CheckboxFieldAdapter}
					type="checkbox"
					labelText={t('cars.form.cars.select_all')}
					name="selectAll"
					disabled={submitting}
					color="primary"
					onClick={handleSelectAll}
					checked={officesArray.every((office) =>
						checkedOffices.includes(office.uuid)
					)}
				/>
			</Box>

			<List className={classes.root}>
				{officesArray.map(({ cars, office_name, uuid: officeUuid }) => {
					return (
						<ListItem
							key={officeUuid}
							officeName={office_name}
							cars={cars}
							officeUuid={officeUuid}
							offices={officesArray}
							isEnglish={isEnglish}
							carsFieldName={carsFieldName}
							checkedCars={checkedCars}
							checkedOffices={checkedOffices}
							setCheckedCars={setCheckedCars}
							setCheckedOffices={setCheckedOffices}
						/>
					);
				})}
			</List>
		</>
	);
};

OfficesList.propTypes = {
	offices: object.isRequired,
};

export default OfficesList;
