import { useLocation } from 'react-router-dom';
import { useFormState } from 'react-final-form';

// Import helpers
import {
	FIELD_TYPES,
	generateArray,
	NONE_VALUE,
	PROMOCODE_SERVICE_TYPES,
} from 'helpers';

// Import utils
import { useTranslations } from 'components/utilities';
import { useCompanies } from 'queries';

export const useFormContent = () => {
	const { t, i18n } = useTranslations();
	const { pathname } = useLocation();
	const { data: companies, isFetching } = useCompanies(true);

	const { values } = useFormState();
	const { SUBSCRIPTION, DAILY, ALL } = PROMOCODE_SERVICE_TYPES;

	const isAddView = pathname.includes('add');
	const prevBookingNumbers = generateArray(0, 20).map((number) => ({
		label: number,
		value: number,
	}));

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name[
					i18n.language
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  ];

	const selectOptions = [
		{ label: t('common.none'), value: NONE_VALUE },
		...prevBookingNumbers,
	];

	const serviceTypes = [
		{
			id: ALL,
			name: t('promo_codes.form.maximum_usage.all'),
		},
		{
			id: DAILY,
			name: t('promo_codes.form.maximum_usage.daily'),
		},
		{
			id: SUBSCRIPTION,
			name: t('promo_codes.form.maximum_usage.subscription'),
		},
	];

	const cards = [
		{
			title: 'promo_code',
			fields: [
				{
					type: FIELD_TYPES.TEXT,
					name: 'details.code',
					label: 'promo_codes.form.promo_code.code',
					required: isAddView,
					disabled: !isAddView,
				},
			],
		},
		{
			title: 'discount',
			fields: [
				{
					type: FIELD_TYPES.NUMBER,
					name: 'discount.discount',
					label: 'promo_codes.form.discount.discount',
					md: 6,
				},
				{
					type: FIELD_TYPES.NUMBER,
					name: 'discount.max_discount',
					label: 'promo_codes.form.discount.max_discount',
					md: 6,
				},
			],
		},
		{
			title: 'period',
			fields: [
				{
					type: FIELD_TYPES.DATE,
					name: 'period.from',
					label: 'promo_codes.form.period.from',
					md: 6,
					minDate: false,
				},
				{
					type: FIELD_TYPES.DATE,
					name: 'period.until',
					label: 'promo_codes.form.period.until',
					md: 6,
					minDate: false,
				},
			],
		},
		{
			title: 'maximum_usage',
			fields: [
				{
					type: FIELD_TYPES.NUMBER,
					name: 'max_usage',
					label: 'promo_codes.form.maximum_usage.max_usage',
					md: 6,
				},
				{
					type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
					name: 'rental_companies',
					label: 'promo_codes.form.maximum_usage.rental_company',
					md: 6,
					multiSelectLabelExtractor,
					currentMultiSelectValues: values?.rental_companies,
					disabled: isFetching,
					options: companies,
					searchExtractor: 'children[1]',
					showAllOption: true,
					keyExtractor: ({ uuid }) => uuid,
					labelExtractor: ({ name }) => name[i18n.language],
				},
				{
					type: FIELD_TYPES.SELECT,
					name: 'conditions.allowed_previous_bookings_number',
					label: 'promo_codes.form.maximum_usage.number_of_previous_booking',
					md: 6,
					options: selectOptions,
					keyExtractor: ({ value }) => value,
					labelExtractor: ({ label }) => label,
				},
				{
					type: FIELD_TYPES.SELECT,
					name: 'service_type',
					label: 'promo_codes.form.maximum_usage.service_type',
					md: 12,
					options: serviceTypes,
					required: true,
					keyExtractor: ({ id }) => id,
					labelExtractor: ({ name }) => name,
				},
				{
					type: FIELD_TYPES.NUMBER,
					name: 'budget.max',
					label: 'promo_codes.form.maximum_usage.max_budget',
					md: 6,
				},
				{
					type: FIELD_TYPES.CHECKBOX,
					name: 'conditions.one_time_use',
					label: 'promo_codes.form.maximum_usage.one_time_use',
					md: 12,
				},
				{
					type: FIELD_TYPES.CHECKBOX,
					name: 'conditions.only_for_delivery',
					label: 'promo_codes.form.maximum_usage.only_for_delivery',
					md: 12,
				},
			],
		},
	];

	return {
		cards,
	};
};
