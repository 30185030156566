import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import utilities
import { deleteManufacturer } from 'store/actions';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import {
	replaceEditCarManufacturerUrl,
	TABLE_ACTIONS_ACCESSORS,
} from 'helpers';

// Import components
import {
	EditTableCell,
	DeleteTableCell,
	ImageTableCell,
} from 'components/elements';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { LOGO } = COLUMNS_ACCESSORS;

const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case LOGO:
				return (
					<ImageTableCell
						key={cell.column.id}
						cell={cell}
						imgUrl={cell.row.original.media}
					/>
				);
			case EDIT:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditCarManufacturerUrl(cell.row.original.uuid)}
					/>
				);
			case DELETE:
				return (
					<DeleteTableCell
						key={cell.column.id}
						deleteAction={deleteManufacturer}
						cell={cell}
					/>
				);
			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};

export default TableBodyRow;
