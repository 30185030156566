// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';
import { URLS } from 'components/routes';

// Import components
import { Statistics } from './components';

export const TOOLBAR_CONTENT = () => [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Statistics,
		customKey: 'statistics',
	},
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: URLS.addCorporateUrl,
		text: 'b2b.corporate.table.toolbar.add_corporate',
	},
];
