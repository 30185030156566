// Import helpers
import { convertFormDates, BOOKING_CANCELLATION_STATES } from 'helpers';

export const initialValues = {
	cancel_reason_status: BOOKING_CANCELLATION_STATES.REVIEW,
	pick_date: null,
	pick_date_to: null,
	cancellation_date: null,
	cancellation_date_to: null,
};

export const formatValues = (values) => ({
	...values,
	cancel_reason_status: values.cancel_reason_status,
	...convertFormDates(values, ['pick_date', 'cancellation_date']),
});
