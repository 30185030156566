export const fieldNames = ({ section }) => ({
	labelFieldName: `${section}.default_location.name`,
	formattedLocationFieldName: `${section}.default_location.formatted_name`,
	placeIdFieldName: `${section}.default_location.place_id`,
	locationLatFieldName: `${section}.default_location.latitude`,
	locationLngFieldName: `${section}.default_location.longitude`,
	manufacturerFieldName: `${section}.vehicle_maker_uuid`,
	typeFieldName: `${section}.vehicle_type_uuid`,
	modelFieldName: `${section}.vehicle_model_uuid`,
});
