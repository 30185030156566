import React from 'react';
import { bool, func, string } from 'prop-types';
import {
	DialogContent,
	DialogTitle,
	Typography,
	Dialog,
	Divider,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { CustomerWalletContent } from 'components/elements';

export const CustomerWalletDialog = ({ on, toggle, uuid }) => {
	const { t } = useTranslations();

	return (
		<Dialog open={on} onClose={toggle} maxWidth="md">
			<DialogTitle disableTypography>
				<Typography variant="h4">
					{t('bookings.compensation_history.title')}
				</Typography>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<CustomerWalletContent uuid={uuid} />
			</DialogContent>
		</Dialog>
	);
};

CustomerWalletDialog.propTypes = {
	uuid: string.isRequired,
	toggle: func.isRequired,
	on: bool.isRequired,
};
