import React from 'react';
import { object } from 'prop-types';
import { Grid, Tooltip, Chip, Box } from '@material-ui/core';

// Import helpers
import { METHOD_TYPES, PAYMENT_METHODS, CARD_SCHEME } from 'helpers';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import icons
import { applepay, mada, masterCard, visa } from 'assets/images';
import { WalletIcon } from 'assets/icons';

export const NotesForAdmins = ({ classes, payment }) => {
	const { t } = useTranslations();

	const { method, method_type, card_scheme } = payment || {};

	return (
		<>
			{method === PAYMENT_METHODS.CARD && (
				<Grid item>
					<Tooltip title={t('bookings.table.notes.mada')}>
						<img className={classes.image} src={mada} alt="Mada" />
					</Tooltip>
				</Grid>
			)}

			{method === PAYMENT_METHODS.TAMARA && (
				<Grid item>
					<Tooltip title={t('bookings.preview_booking.invoice.tamara')}>
						<Box className={classes.tamaraLogo}></Box>
					</Tooltip>
				</Grid>
			)}

			{method_type === METHOD_TYPES.TELGANI_PAY && (
				<Grid item>
					<Tooltip title={t('bookings.table.notes.wallet')}>
						<Chip
							label={<WalletIcon fontSize="small" />}
							className={classes.chip}
							size="small"
						/>
					</Tooltip>
				</Grid>
			)}

			{method_type === METHOD_TYPES.APPLE_PAY && (
				<Grid item>
					<Tooltip title={t('bookings.table.notes.applepay')}>
						<img className={classes.image} src={applepay} alt="Applepay" />
					</Tooltip>
				</Grid>
			)}

			{card_scheme === CARD_SCHEME.MASTER_CARD && (
				<Grid item>
					<Tooltip title={t('bookings.table.notes.master_card')}>
						<img className={classes.image} src={masterCard} alt="MasterCard" />
					</Tooltip>
				</Grid>
			)}

			{card_scheme === CARD_SCHEME.VISA && (
				<Grid item>
					<Tooltip title={t('bookings.table.notes.visa')}>
						<img className={classes.image} src={visa} alt="VISA" />
					</Tooltip>
				</Grid>
			)}
		</>
	);
};

NotesForAdmins.propTypes = {
	classes: object.isRequired,
	payment: object,
};
