import React from 'react';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow } from './components';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import helpers
import { columns } from './columns';
import { fetchKiosks } from 'store/actions';
import { TOOLBAR_CONTENT } from './helpers';

const KioskManagement = () => {
	const { content, root } = useBaseLayoutStyles();

	return (
		<TableProvider columns={columns} fetchDataTableAction={fetchKiosks}>
			<div className={root}>
				<Toolbar content={TOOLBAR_CONTENT()} />
				<div className={content}>
					<Table isDefaultRow={false}>
						{({ row }) => <TableBodyRow key={row.id} row={row} />}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};

export default KioskManagement;
