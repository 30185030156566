import React from 'react';

// Import components
import { Card } from 'components/elements';
import {
	CompensationsPreviewList,
	CompensationCardActions,
} from './components';

// Import translations
import { useTranslations } from 'components/utilities';

export const CustomerCompensation = () => {
	const { t } = useTranslations();

	return (
		<Card
			title={t('bookings.customer_compensation.title')}
			lg={6}
			actions={<CompensationCardActions />}
		>
			<CompensationsPreviewList />
		</Card>
	);
};
