import { useRef } from 'react';
import { useForm } from 'react-final-form';

export const useSignature = () => {
	const signatureRef = useRef(null);

	const { change } = useForm();

	const convertBase64toBlob = async (imageFile) =>
		await fetch(imageFile).then((res) => res.blob());

	const onSave = async () => {
		const imageFile = signatureRef.current
			.getTrimmedCanvas()
			.toDataURL('image/jpeg');

		const signature = await convertBase64toBlob(imageFile);
		change('signature', signature);
	};

	const onClear = () => {
		signatureRef.current.clear();
		change('signature', null);
	};

	return {
		onSave,
		onClear,
		signatureRef,
	};
};
