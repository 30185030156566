import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CUSTOMER_WALLET_BALANCE_HISTORY } from 'helpers';

const useCustomerWalletBalanceHistory = ({ uuid }) => {
	const path = `/v2/customer/${uuid}/wallet/balance/history`;

	const { data, isFetched, isError, error } = useQuery(
		[CUSTOMER_WALLET_BALANCE_HISTORY, uuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || null;

	return { data: fetchedData, isFetched, isError, error };
};

export default useCustomerWalletBalanceHistory;
