import { useContext } from 'react';

// Import context
import { TableContext } from './TableProvider';

const useTableContextProvider = () => {
	const context = useContext(TableContext);

	return { ...context };
};
export default useTableContextProvider;
