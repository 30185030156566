import i18n from 'i18next';
import * as yup from 'yup';

export const validationSchema = () => {
	const requiredFieldText = i18n.t('common.fields.required_field');

	return yup.object({
		working_branch_id: yup
			.number()
			.min(0, i18n.t('common.fields.minimum', { value: 0 }))
			.integer()
			.required(requiredFieldText),
		contract_end_date: yup.date().required(requiredFieldText),
		payment_method_code: yup
			.number()
			.min(0, i18n.t('common.fields.minimum', { value: 0 }))
			.integer()
			.required(requiredFieldText),
		paid: yup
			.number()
			.integer()
			.min(0, i18n.t('common.fields.minimum', { value: 0 }))
			.required(requiredFieldText),
		discount: yup
			.number()
			.required(requiredFieldText)
			.min(0, i18n.t('common.fields.minimum', { value: 0 }))
			.max(100, i18n.t('common.fields.maximum', { value: 100 })),
		operator_id: yup.number().integer(),
	});
};
