import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	tableCell: {
		padding: '0 5px',
		display: 'flex',
		alignItems: 'center',
		borderRight: `1px solid ${theme.palette.divider}`,
		transform: theme.direction === 'rtl' ? 'rotateY(180deg)' : '',
		direction: theme.direction,
		flip: false,
	},
}));

export default useStyles;
