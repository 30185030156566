// Import helpers
import { DELIVERY_STATUS, DELIVERY_TYPES } from 'helpers';

export const useDeliveryStatus = ({ cell, isDropOffSort, isSingleStatus }) => {
	const { PICK_UP_DELIVERY, DROP_DELIVERY } = DELIVERY_TYPES;

	const {
		delivery: { drop_delivery, pick_up_delivery },
		is_drop_delivery,
		is_pickup_delivery,
	} = cell.row.original;

	const { delivery_state: pickupDeliveryState } = pick_up_delivery || {};
	const { delivery_state: dropoffDeliveryState } = drop_delivery || {};

	const deliveryTypesToShow = isSingleStatus
		? isDropOffSort
			? [DROP_DELIVERY]
			: [PICK_UP_DELIVERY]
		: [PICK_UP_DELIVERY, DROP_DELIVERY];

	const isTypeAllowedToShow = (type) => !!deliveryTypesToShow.includes(type);
	const isDeliveryPending = (status) => status === DELIVERY_STATUS.PENDING;

	const isPickupAllowed =
		(pick_up_delivery.is_available || is_pickup_delivery) &&
		pickupDeliveryState &&
		isTypeAllowedToShow(PICK_UP_DELIVERY) &&
		!isDeliveryPending(pickupDeliveryState);

	const isDropoffAllowed =
		(drop_delivery.is_available || is_drop_delivery) &&
		dropoffDeliveryState &&
		isTypeAllowedToShow(DROP_DELIVERY) &&
		!isDeliveryPending(dropoffDeliveryState);

	return {
		pickupDeliveryState,
		dropoffDeliveryState,
		isPickupAllowed,
		isDropoffAllowed,
	};
};
