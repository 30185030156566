import React from 'react';
import InputMask from 'react-input-mask';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { bool, func, string } from 'prop-types';

// Import icons
import { CalendarIcon } from 'assets/icons';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { COMBINED_INPUT_MASK } from '../../helpers';

export const CombinedDateInputs = ({
	value,
	error,
	label,
	toggle,
	onChange,
	errorText,
}) => {
	const { t } = useTranslations();

	return (
		<InputMask
			mask={COMBINED_INPUT_MASK}
			onChange={onChange}
			value={value}
			error={error}
			helperText={errorText}
			fullWidth
			type="text"
			label={t(label)}
			margin="dense"
			variant="outlined"
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton onClick={toggle}>
							<CalendarIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
		>
			{(textFieldProps) => <TextField {...textFieldProps} />}
		</InputMask>
	);
};

CombinedDateInputs.propTypes = {
	error: bool,
	errorText: string,
	toggle: func.isRequired,
	label: string.isRequired,
	value: string.isRequired,
	onChange: func.isRequired,
};
