export const isRejectVisible = (cell, resources) => {
	if (resources?.cancelReasons) {
		const cancelReasonId = resources.cancelReasons.find(
			({ uuid }) => uuid === cell.row.original.uuid
		);
		return !!cancelReasonId;
	} else {
		return false;
	}
};
