import React from 'react';
import { Grid, Divider } from '@material-ui/core';

const DividerLine = () => {
	return (
		<Grid item md={12} xs={12}>
			<Divider variant="fullWidth" />
		</Grid>
	);
};

export default DividerLine;
