import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import { CAR_YEARS_QUERY, LOCAL_STORAGE_VEHICLE_YEAR_LIST } from 'helpers';

export const useVehicleYearList = ({ body = {}, enabled }) => {
	const path = '/v2/supplier/vehicle/year/list';

	const localStorageVehicleYearList = ls.get(
		LOCAL_STORAGE_VEHICLE_YEAR_LIST(JSON.stringify(body))
	);

	const { data, isFetching, isFetched } = useQuery(
		[CAR_YEARS_QUERY, JSON.stringify(body)],
		async () => await services.post(path, body),
		{
			refetchOnWindowFocus: false,
			enabled: !localStorageVehicleYearList && enabled,
		}
	);

	const fetchedData = data ? data.data.data : [];

	useEffect(() => {
		if (!localStorageVehicleYearList && isFetched && fetchedData.length) {
			ls.set(
				LOCAL_STORAGE_VEHICLE_YEAR_LIST(JSON.stringify(body)),
				fetchedData,
				{
					ttl: 14 * 24 * 60 * 60,
				}
			);
		}
		// eslint-disable-next-line
	}, [localStorageVehicleYearList, isFetched, fetchedData]);

	const result = localStorageVehicleYearList || fetchedData;

	return { data: result, isFetching };
};
