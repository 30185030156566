import React, { useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { string, array, func, bool, object, number, element } from 'prop-types';
import {
	Card,
	CardContent,
	Table as MaterialTable,
	TableBody,
	CircularProgress,
	Typography,
	TableHead,
} from '@material-ui/core';
import {
	useTable,
	useGlobalFilter,
	useSortBy,
	usePagination,
} from 'react-table';

// Import styles
import { useStyles } from './styles';

// Import components
import { useSubTableContextProvider } from 'components/context';
import { Alert } from 'components/elements';
import { TableHeadRow } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useQueryStringParams } from 'components/utilities';

// Import helpers
import { TABLE_OPTIONS } from 'helpers';

const { PAGE_INDEX, PAGE_SIZE } = TABLE_OPTIONS;

export const SubTable = ({ className, children, ...rest }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { parsedFilters, parsedOptions } = useQueryStringParams();

	const {
		columns,
		inputColumns,
		data,
		fetchData,
		loading,
		error,
		filters,
		pageCount: controlledPageCount,
		setFilters,
	} = useSubTableContextProvider();

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		rows,
		state,
		headerGroups,
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: parsedOptions.pageIndex || PAGE_INDEX,
				pageSize: parsedOptions.pageSize || PAGE_SIZE,
				globalFilter: parsedOptions.globalFilter || '',
			}, // Pass our hoisted table state
			manualPagination: true,
			manualGlobalFilter: true,
			manualSortBy: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: controlledPageCount,
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { pageIndex, pageSize, sortBy, globalFilter } = state;

	const currentPageRowsCount = data.length;
	// Listen for changes in pagination etc and use the state to fetch our new data
	useEffect(() => {
		const source = axios.CancelToken.source();
		const cancelToken = source.token;

		fetchData(
			{ pageIndex, pageSize, sortBy, globalFilter },
			inputColumns,
			filters,
			cancelToken
		);

		return () => {
			source.cancel();
		};
		// eslint-disable-next-line
	}, [fetchData, pageIndex, pageSize, sortBy, globalFilter, filters]);

	useEffect(() => {
		// Important to compare with JSON.stringify because of different date types
		!(JSON.stringify(filters) === JSON.stringify(parsedFilters)) &&
			setFilters(parsedFilters);

		// eslint-disable-next-line
	}, [location.search]);

	return (
		<>
			{error && <Alert message={error} />}
			<Card {...rest} className={clsx(classes.root, className)}>
				<CardContent className={classes.content}>
					{loading && (
						<div className={classes.loader}>
							<CircularProgress size={48} color="primary" />
						</div>
					)}

					{!loading && currentPageRowsCount === 0 && (
						<Typography className={classes.noData} variant="subtitle1">
							{t('table.no_data')}
						</Typography>
					)}

					<div className={classes.inner}>
						<MaterialTable {...getTableProps()} aria-label="sub table">
							<TableHead>
								{headerGroups.map((headerGroup, index) => (
									<TableHeadRow
										key={headerGroup.headers[index].id}
										headerGroup={headerGroup}
									/>
								))}
							</TableHead>
							<TableBody {...getTableBodyProps()}>
								{rows.map((row) => {
									prepareRow(row);
									return children({ row });
								})}
							</TableBody>
						</MaterialTable>
					</div>
				</CardContent>
			</Card>
		</>
	);
};

SubTable.propTypes = {
	className: string,
	rowsPerPageOptions: array,
	initialPageSize: number,
	children: func,
	labelDisplayedRows: func,
	isDefaultRow: bool,
	editLinkPath: string,
	externalFilters: object,
	tableInnerToolbar: element,
};
