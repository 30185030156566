import services from 'services/services';
import {
	updateActiveAirportStatusUrl,
	fetchAirportListUrl,
	deleteAirportUrl,
} from 'store/paths';

export const fetchAirports = ({ options, cancelToken }) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		const { data } = await services.post(
			fetchAirportListUrl,
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveAirport = ({ isActive, id }) => async () => {
	await services.put(updateActiveAirportStatusUrl(id, isActive));
};

export const deleteAirport = ({ id }) => async () => {
	await services.delete(deleteAirportUrl(id));
};
