import React from 'react';

// Import components
import { EditBookingProvider } from '../EditBooking/context';
import { EditBookingContent } from './components';

export const EditBooking = () => {
	return (
		<EditBookingProvider>
			<EditBookingContent />
		</EditBookingProvider>
	);
};
