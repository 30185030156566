import React from 'react';
import { useFormState } from 'react-final-form';

// Import components
import { ButtonProgress } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

export const SubmitButton = () => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<ButtonProgress
			color="primary"
			variant="contained"
			type="submit"
			disabled={submitting}
			isLoading={submitting}
		>
			{t('common.buttons.submit')}
		</ButtonProgress>
	);
};
