import PersonAddIcon from '@material-ui/icons/PersonAdd';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: `/user/add`,
		icon: PersonAddIcon,
		text: 'users.table.toolbar.add_user',
	},
];
