import React from 'react';
import { node, oneOfType, oneOf, string, bool } from 'prop-types';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './ChipLabel.styles';

export const ChipLabel = ({
	label,
	color = 'primary',
	fullWidth = false,
	className,
	...rest
}) => {
	const classes = useStyles({ color, fullWidth });

	return (
		<Chip
			className={clsx([classes.chip, className])}
			data-testid="chip-label"
			label={label}
			{...rest}
		/>
	);
};

ChipLabel.propTypes = {
	label: oneOfType([string, node]).isRequired,
	color: oneOf(['primary', 'error', 'gray']),
	fullWidth: bool,
	className: string,
};
