export const COLUMNS_ACCESSORS = {
	STATUS: 'status',
	COMPANY_OFFICE_NAME: 'COMPANY_OFFICE_NAME',
	CUSTOMER_NAME: 'customer_name',
	BOOKING_ID: 'booking_id',
	DURATION: 'response_time',
	ACTION: 'action',
	PICK_DATE_TIME: 'pick_date_time',
	DELIVERY_ASSIGNMENT: 'delivery_assignment',
	DELIVERY_STATUS: 'delivery_status',
	VEHICLE: 'vehicle',
	TRACKING_MAP: 'tracking_map',
};

export const columns = (isAdmin) => [
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.customer_name_phone',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: COLUMNS_ACCESSORS.VEHICLE,
		disableSortBy: true,
	},
	{
		Header: `bookings.table.headers.${isAdmin ? 'company_' : ''}office_name`,
		accessor: COLUMNS_ACCESSORS.COMPANY_OFFICE_NAME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.pick_date_time',
		accessor: COLUMNS_ACCESSORS.PICK_DATE_TIME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.delivery_assignment',
		accessor: COLUMNS_ACCESSORS.DELIVERY_ASSIGNMENT,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.delivery_status',
		accessor: COLUMNS_ACCESSORS.DELIVERY_STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.tracking_map',
		accessor: COLUMNS_ACCESSORS.TRACKING_MAP,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
	},
];
