import services from 'services/services';
import * as types from 'store/types';
import { fetchUserFormResourcesUrl, submitUserFormUrl } from 'store/paths';

// Import helpers
import { catchError } from '../helpers';

export const fetchUserFormResources =
	({ itemId, isEdit, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.USER_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			const { data } =
				isEdit &&
				(await services.get(fetchUserFormResourcesUrl(itemId), cancelToken));

			dispatch({
				type: types.USER_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: data || {},
				},
			});
		} catch (error) {
			catchError({ error, dispatch, type: types.USER_FORM_FETCH_DATA_ERROR });
		}
	};

export const submitUserForm =
	({ values, itemId, callbackSuccess, errorCallback, isEdit }) =>
	async () => {
		try {
			const userId = itemId || values.details.uuid;
			await services.post(submitUserFormUrl(isEdit, userId), values);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
