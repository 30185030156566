import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'fixed',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100vw',
		height: '100vh',
        background: theme.palette.white
	},
}));
