import React from 'react';

// Import components
import DateField from './DateField';

const PickUpDateField = () => {
	return (
		<DateField
			label="bookings.edit_booking.form.pick_up_date"
			name="period.pick_date"
		/>
	);
};

export default PickUpDateField;