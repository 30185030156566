import _ from 'lodash';
import { ADMINS } from 'helpers';

export const PERMISSION_COMPANY_FILTER = ADMINS;

export const formatValues = (values) => {
	const carAvailabilityRange = _.isArray(values.car_availability_range)
		? values.car_availability_range
		: JSON.parse(values.car_availability_range);

	return {
		...values,
		company_uuid: values.company_uuid === 'all' ? '' : values.company_uuid,
		office_uuid: values.office_uuid === 'all' ? '' : values.office_uuid,
		city_uuid: values.city_uuid === 'all' ? '' : values.city_uuid,
		model_uuid: values.model_uuid === 'all' ? '' : values.model_uuid,
		year: values.year,
		car_active: values.car_active === 'all' ? null : values.car_active,
		services: values.services === '[]' ? [] : values.services,
		car_type: values.car_type === '[]' ? [] : values.car_type,
		car_availability_range: carAvailabilityRange,
	};
};
