import _ from 'lodash';
import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT, DRIVER } from 'helpers';

export const INITIAL_VALUES = ({ companyUuid }) => ({
	details: {
		full_name: '',
		email: '',
		phone_prefix: '',
		phone: '',
	},
	license_expiry_date: '',
	id_number: '',
	company_uuids: companyUuid ? [companyUuid] : [],
	office_uuids: [],
	password: '',
	password_confirmation: '',
});

export const getConvertedUserData = (values) => ({
	...values,
	details: {
		...values.details,
		role: DRIVER,
	},
});

export const convertSubmitDriverPayload = ({ userUuidFromApi, values }) => {
	const licenseExpiryDate = values.license_expiry_date;

	const omittedValues = _.omit(values, ['company_uuid', 'offices_uuid']);
	return {
		...omittedValues,
		user_uuid: userUuidFromApi,
		license_expiry_date: licenseExpiryDate
			? format(new Date(licenseExpiryDate), DEFAULT_DATE_FORMAT)
			: '',
	};
};
