import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import styles
import useStyles from './styles';

const DateTableCell = ({ cell }) => {
	const { created_at, marked_at, uuid, marked_by_name: markedBy } = cell;

	const classes = useStyles({ markedBy });

	return (
		<TableCell key={uuid} className={classes.cell}>
			<Grid container>
				<Grid item xs={12}>
					<Typography noWrap variant="subtitle1">
						{created_at}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography noWrap variant="subtitle1">
						{marked_at}
					</Typography>
				</Grid>
			</Grid>
		</TableCell>
	);
};

DateTableCell.propTypes = {
	cell: PropTypes.object,
};

export default DateTableCell;
