import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { EMPTY_VALUE } from 'helpers';

// Import components
import {
	BookingStatusChip,
	LinkTableCell,
	RatingTableCell,
	IsSettledTableCell,
	AmountTableCell,
} from 'components/elements';

// Import translations
import { replacePreviewBookingUrl } from 'helpers';
// import { NoteTableCell } from './components';

const {
	RATING,
	EARNINGS,
	BOOKING_NUMBER,
	BOOKING_STATUS,
	IS_SETTLED,
	PICKUP_TIME,
	SETTLED_ON,
	// NOTE,
} = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		const {
			booking_number,
			booking_status,
			booking_uuid,
			pickup_time,
			settled_at,
			earnings,
			rating,
		} = cell.row.original || {};

		if (cell.column.isHidden) return null;

		const columnId = cell.column.id;

		switch (columnId) {
			// TODO Uncomment in the next iteration (Driver - EmployeePerformance);
			// case NOTE:
			// 	return <NoteTableCell key={cell.column.id} cell={cell} />;

			case BOOKING_NUMBER:
				return (
					<LinkTableCell
						key={columnId}
						cell={cell}
						to={replacePreviewBookingUrl(booking_uuid)}
					>
						{booking_number}
					</LinkTableCell>
				);

			case RATING:
				return <RatingTableCell key={columnId} cell={cell} rating={rating} />;

			case BOOKING_STATUS:
				return (
					<TableCell key={columnId} {...cell.getCellProps()}>
						<BookingStatusChip state={booking_status} />
					</TableCell>
				);

			case EARNINGS:
				return <AmountTableCell key={columnId} cell={cell} amount={earnings} />;

			case PICKUP_TIME:
				return (
					<TableCell key={columnId} {...cell.getCellProps()}>
						{pickup_time.split(' ').map((row) => (
							<Typography key={row}>{row}</Typography>
						))}
					</TableCell>
				);

			case SETTLED_ON:
				return (
					<TableCell key={columnId} {...cell.getCellProps()}>
						{settled_at
							? settled_at
									.split(' ')
									.map((row) => <Typography key={row}>{row}</Typography>)
							: EMPTY_VALUE}
					</TableCell>
				);

			case IS_SETTLED:
				return <IsSettledTableCell key={columnId} cell={cell} />;

			default:
				return (
					<TableCell key={columnId} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
