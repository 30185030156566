import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import { useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	InputAdornment,
} from '@material-ui/core';

// Import components
import { OneTimePaymentField, PeriodPaymentsField } from 'components/elements';

//Import translations
import { useTranslations, useCommonFields } from 'components/utilities';

// Import helpers
import { PERIOD_FIELDS, GENERAL_FIELDS } from './helpers';

export const LeasingExtraServices = ({ className, ...rest }) => {
	const { t } = useTranslations();
	const { values } = useFormState();
	const { renderFields } = useCommonFields();

	const {
		prices: {
			unlimited_kms: { is_unlimited, one_time: unlimited_price },
		},
	} = values;

	const sarAdornment = (
		<InputAdornment position="end">{t('currency.sar')}</InputAdornment>
	);

	return (
		<Card {...rest} className={clsx(className)}>
			<CardHeader title={t('leasing_cars.form.extra_services.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{GENERAL_FIELDS({ sarAdornment }).map(renderFields)}
				</Grid>
				<Grid container spacing={1}>
					<OneTimePaymentField
						initialInputValue={unlimited_price}
						inputValue={unlimited_price}
						inputName="prices.unlimited_kms.one_time"
						checkboxLabelText="cars.form.extra_services.unlimited_km"
						checkboxName="prices.unlimited_kms.is_unlimited"
						isOpen={is_unlimited}
						minValue={1}
					/>
				</Grid>
				{PERIOD_FIELDS(values).map(
					({ label, checkboxName, fields, isOpen }) => (
						<Grid key={label} item xs={12}>
							<PeriodPaymentsField
								labelText={label}
								checkboxName={checkboxName}
								fields={fields}
								isOpen={isOpen}
								minValue={1}
							/>
						</Grid>
					)
				)}
			</CardContent>
		</Card>
	);
};

LeasingExtraServices.propTypes = {
	className: string,
};
