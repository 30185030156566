// Import helpers
import { FINANCIAL_REPORT_TYPES } from 'helpers';

const { CAMPAIGN, REVENUE } = FINANCIAL_REPORT_TYPES;

export const reportTypeOptions = [
	{
		label: 'common.fields.campaign',
		value: CAMPAIGN,
	},
	{
		label: 'common.fields.revenue',
		value: REVENUE,
	},
];
