import React from 'react';
import { fetchCancellationValidationBookings } from 'store/actions';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow } from './components';
import { TableRPC } from 'components/utilities';

export const CancellationValidation = () => {
	return (
		<TableRPC
			fetchDataTableAction={fetchCancellationValidationBookings}
			columnsAccessor={COLUMNS_ACCESSORS}
			toolBarContent={TOOLBAR_CONTENT}
			tableBodyRow={TableBodyRow}
			columns={columns}
		/>
	);
};
