import React from 'react';
import { fetchPromoCodeBookingList } from 'store/actions';

// Import helpers and utils
import { TableRPC } from 'components/utilities';
import { TOOLBAR_CONTENT } from './helpers';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow, Export } from './components';

export const PromoCodePreview = () => {
	return (
		<TableRPC
			fetchDataTableAction={fetchPromoCodeBookingList}
			columnsAccessor={COLUMNS_ACCESSORS}
			toolBarContent={TOOLBAR_CONTENT}
			tableBodyRow={TableBodyRow}
			export={<Export />}
			columns={columns}
		/>
	);
};
