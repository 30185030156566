import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import {
	useCommonFields,
	useProfile,
	useTranslations,
} from 'components/utilities';

// Import helpers
import { FIELDS } from './helpers';

const CompanyEdit = (props) => {
	const { className, section, ...rest } = props;
	const { prefixes } = useSelector((store) => store.company.form);
	const { isAdmin } = useProfile();
	const { renderFields } = useCommonFields();

	const { t } = useTranslations();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('company.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS({ section, isAdmin, prefixes }).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

CompanyEdit.propTypes = {
	className: string,
	section: string.isRequired,
};

export default CompanyEdit;
