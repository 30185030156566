// Import helpers
import { BOOKING_EXTENSION_STATUS } from 'helpers';

export const OPTIONS = [
	{
		value: BOOKING_EXTENSION_STATUS.ACCEPTED,
		label: 'bookings.booking_states.accepted',
	},
	{
		value: BOOKING_EXTENSION_STATUS.REJECTED,
		label: 'bookings.booking_states.rejected',
	},
	{
		value: BOOKING_EXTENSION_STATUS.PENDING,
		label: 'bookings.booking_states.pending',
	},
];
