import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
    info: {},
    error: {},
    isLoaded: false,
    isError: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.DASHBOARD_INFO_FETCH_DATA_LOADING:
				return INITIAL_STATE;

			case types.DASHBOARD_INFO_FETCH_DATA_SUCCESS:
				draft.info = action.payload;
				draft.isLoaded = true;
				break;

			case types.DASHBOARD_INFO_FETCH_DATA_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				draft.error = action.payload;
				break;
			default:
				return state;
		}
	});
