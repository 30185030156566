import { useCallback, useEffect, useState } from 'react';
import { previewBookingSelector } from 'store/selectors';
import { useSelector } from 'react-redux';

// Import helpers
import { DELIVERY_STATUS } from 'helpers';

export const useDeliveryLocationMap = ({ deliveryData }) => {
	const { states } = deliveryData || {};

	const booking = useSelector(previewBookingSelector);

	const [map, setMap] = useState(null);

	const onMapLoad = useCallback((map) => setMap(map), []);

	const { lat: customerLat, lng: customerLng } = booking.delivery;
	const { lat: officeLat, lng: officeLng } = booking.office;

	const officeLocation = { lat: officeLat, lng: officeLng };

	const getFilteredSteps = () =>
		states?.filter(({ state }) => state !== DELIVERY_STATUS.PENDING);

	const steps = getFilteredSteps();

	const customMarkers = [
		{
			lat: customerLat,
			lng: customerLng,
			url: '/images/customer_marker_icon.svg',
			label:
				'bookings.preview_booking.delivery_information.icon_tooltips.customer_location',
		},
		{
			lat: officeLat,
			lng: officeLng,
			url: '/images/office_marker_icon.svg',
			label:
				'bookings.preview_booking.delivery_information.icon_tooltips.office_location',
		},
	];

	useEffect(() => {
		if (map) {
			const bounds = new window.google.maps.LatLngBounds();
			// eslint-disable-next-line
			customMarkers.map(({ lat, lng }) => {
				bounds.extend({
					lat,
					lng,
				});
			});
			map.fitBounds(bounds);
		}
		// eslint-disable-next-line
	}, [map]);

	return {
		officeLocation,
		customMarkers,
		steps,
		onMapLoad,
	};
};
