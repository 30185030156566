import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	addIcon: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	addButton: {
		marginTop: theme.spacing(2),
	},
}));
