export const getExternalPartnerUuid = (values, externalPartner) => {
	const extPart = externalPartner.find(
		(exPart) => exPart.name === values.partner_name
	);
	return {
		...values,
		partner_uuid: extPart.uuid,
	};
};

export const convertData = (values) => {
	return {
		...values,
		payment_methods: values.payment_methods.map((element) => element.uuid),
	};
};

export const getConvertedValues = (values) => {
	const options = { ...values.options };

	const newOptions = Object.values(options).reduce((acc, option) => {
		const convertedOption = {
			[`is_${option.option_name}`]: true,
			[`${option.option_name}_nett_price`]: option.price.nett,
		};

		return Object.assign(acc, convertedOption);
	}, {});

	return { ...values, options: newOptions };
};

export const getExtendedOptions = (values) => {
	const initialOptions = {
		is_tam: false,
		is_extra_driver: false,
		is_auth_out_ksa: false,
	};

	return { ...initialOptions, ...values.options };
};
