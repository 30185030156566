import i18next from 'i18next';

export const columns = [
	{
		Header: 'campaigns.export_headers.booking_created_at',
		accessor: 'booking_created_at',
	},
	{
		Header: 'campaigns.export_headers.transaction_created_at',
		accessor: 'transaction_created_at',
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'booking_individual_number',
	},
	{
		Header: 'campaigns.export_headers.customer_national_id',
		accessor: 'customer_national_id',
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: 'customer_name',
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'campaigns.export_headers.description',
		accessor: 'operation',
	},
	{
		Header: 'bookings.table.headers.rating_reason',
		accessor: 'rating_reason',
	},
	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: 'cancellation_reason',
	},
	{
		Header: 'campaigns.export_headers.expiry_date',
		accessor: 'wallet_campaign_expiry_date',
	},
	{
		Header: 'campaigns.export_headers.withdraw',
		accessor: 'wallet_withdraw',
		valueExtractor: ({ wallet_withdraw }) => getPrice(wallet_withdraw),
	},
	{
		Header: 'campaigns.export_headers.deposit',
		accessor: 'wallet_deposit',
		valueExtractor: ({ wallet_deposit }) => getPrice(wallet_deposit),
	},
	{
		Header: 'campaigns.export_headers.wallet_total_balance',
		accessor: 'total_wallet_balance',
		valueExtractor: ({ total_wallet_balance }) =>
			getPrice(total_wallet_balance),
	},
	{
		Header: 'campaigns.export_headers.pervious_company',
		accessor: 'old_company_name',
	},
];

const getPrice = (accessor) => {
	if (+accessor) {
		return i18next.t('currency.price', {
			amount: accessor,
		});
	}
};
