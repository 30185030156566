import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';

// Import utils and helpers
import { useUnsettledLimitsProvider } from 'views/b2b/CorporateSettlements/UnsettledLimits/context';
import { useCommonFields, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';
import { FIELDS, getTransactionAmount } from './helpers';

// Import components
import { ButtonProgress } from 'components/elements';

export const FormContent = ({ toggle }) => {
	const { isButtonLoading, settledUuids } = useUnsettledLimitsProvider();
	const { data: tableData } = useTableContextProvider();
	const { submitErrors } = useFormState();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { change } = useForm();

	const fileName = 'wire_transfer_file';
	const fileUploaderError = submitErrors?.[fileName]?.[0];

	const handleOnFileUploader = (file) => {
		change(fileName, file);
	};

	useEffect(() => {
		const transactionsAmount = getTransactionAmount({
			settledUuids,
			tableData,
		});

		if (tableData.length && settledUuids.length && !isNaN(transactionsAmount)) {
			change('transaction_amount', transactionsAmount);
		}

		//eslint-disable-next-line
	}, [settledUuids, tableData]);

	return (
		<Grid container spacing={1}>
			{FIELDS({ handleOnFileUploader, fileUploaderError }).map(renderFields)}
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end" spacing={1}>
					<Grid item>
						<Button variant="outlined" color="primary" onClick={toggle}>
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							isLoading={isButtonLoading}
							disabled={isButtonLoading}
							variant="contained"
							color="primary"
							type="submit"
						>
							{t('common.buttons.save')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	toggle: func.isRequired,
};
