import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import { object } from 'prop-types';
import clsx from 'clsx';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { getTopic } from './helpers';

// Import styles
import { useStyles } from './TopicTableCell.styles';

export const TopicTableCell = ({ cell }) => {
	const { state } = cell.row.original;
	const { t } = useTranslations();
	const classes = useStyles();

	const topic = getTopic(state);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{topic && (
				<Typography className={clsx(classes.text, classes[state])}>
					{t(topic)}
				</Typography>
			)}
		</TableCell>
	);
};

TopicTableCell.propTypes = {
	cell: object,
};
