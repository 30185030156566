import { combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';
import {
	deletePartnerUrl,
	fetchPartnersUrl,
	updatePartnerActiveStateUrl,
} from 'store/paths';

export const fetchPartners =
	({ options, cancelToken, queryParams }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchPartnersUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updatePartnerActiveState =
	({ isActive, id }) =>
	async () => {
		await services.patch(updatePartnerActiveStateUrl(id, isActive));
	};

export const deletePartner =
	({ id }) =>
	async () => {
		await services.delete(deletePartnerUrl(id));
	};
