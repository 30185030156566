import React from 'react';
import { func, bool, string } from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';

// Import styles
import useStyles from './styles';

export const ImagePreview = ({ close, on, imgUrl, token }) => {
	const classes = useStyles();

	const url = `${imgUrl}${token ? '?token=' + token : ''}`;

	return (
		<Dialog
			open={on}
			onClose={close}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogContent>
				<img src={url} alt="Preview" className={classes.image} />
			</DialogContent>
		</Dialog>
	);
};

ImagePreview.propTypes = {
	close: func.isRequired,
	on: bool.isRequired,
	imgUrl: string.isRequired,
	token: string,
};
