import React from 'react';
import { object } from 'prop-types';
import { TableCell, Box } from '@material-ui/core';

// Import components
import {
	BookingHistoryActionButton,
	SubscriptionHistoryActionButton,
} from 'components/elements';

export const CustomerBalanceActionsTableCell = ({ cell }) => {
	const { uuid: subscriptionUuid, booking_uuid: bookingUuid } =
		cell.row.original;

	return (
		<TableCell {...cell.getCellProps()}>
			<Box display="flex" alignItems="center" gridGap={10}>
				<BookingHistoryActionButton bookingUuid={bookingUuid} />
				<SubscriptionHistoryActionButton subscriptionUuid={subscriptionUuid} />
			</Box>
		</TableCell>
	);
};

CustomerBalanceActionsTableCell.propTypes = {
	cell: object.isRequired,
};
