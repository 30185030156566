export const FIELDS_ELM_LICENSE_PREVIEW = [
	{
		accessor: 'customer.full_name',
		labelEn: 'Name',
		labelAr: 'الاسم',
	},
	{
		accessor: 'customer.license_number',
		labelEn: 'National / Iqama ID',
		labelAr: 'رقم الهوية/الاقامة',
	},
	{
		accessor: 'customer.birth_date',
		labelEn: 'Birth Date',
		labelAr: 'تاريخ الميلاد',
	},
	{
		accessor: 'customer.license_expire_date',
		labelEn: 'License Expiry Date',
		labelAr: 'تاريخ انتهاء الرخصة',
	},
	{
		accessor: 'customer.gender',
		labelEn: 'Gender',
		labelAr: 'الجنس',
	},
];
