import { useLocation } from 'react-router-dom';

export const useEditPromoCode = () => {
	const { pathname } = useLocation();

	const isAddView = pathname.includes('add');

	return {
		isAddView,
	};
};
