export const COLUMNS_ACCESSORS = {
	NOTE: 'note',
	BOOKING_ID: 'booking_id',
	OFFICE_NAME: 'office_name',
	TRANSACTION_HISTORY: 'transaction_history',
	BOOKING_HISTORY: 'booking_history',
	CUSTOMER_NAME: 'customer_name',
	PICK_DATE: 'pick_date',
	CREATED_AT: 'created_at',
	TOPIC: 'topic',
	ACTION: 'action',
	CANCELLATION_REASON: 'cancellation_reason',
	ACTIONS: 'actions',
	COMPENSATION_AMOUNT: 'compensation_amount',
};

export const columns = [
	{
		Header: 'bookings.table.headers.topic',
		accessor: COLUMNS_ACCESSORS.TOPIC,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: COLUMNS_ACCESSORS.CANCELLATION_REASON,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.compensation_amount',
		accessor: COLUMNS_ACCESSORS.COMPENSATION_AMOUNT,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'office_rating',
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.action',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
		isHidden: false,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
