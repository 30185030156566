import React from 'react';
import { number, object } from 'prop-types';
import { TableCell, Grid, Typography } from '@material-ui/core';

export const StatsTableCell = ({ cell, regularValue, leasingValue }) => (
	<TableCell key={cell.column.id} {...cell.getCellProps()}>
		<Grid container>
			<Grid item xs={12}>
				<Typography>{regularValue}</Typography>
			</Grid>
			{leasingValue && (
				<Grid item xs={12}>
					<Typography>{leasingValue}</Typography>
				</Grid>
			)}
		</Grid>
	</TableCell>
);

StatsTableCell.propTypes = {
	cell: object.isRequired,
	regularValue: number.isRequired,
	leasingValue: number,
};
