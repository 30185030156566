import React, { useEffect } from 'react';
import { node } from 'prop-types';
import { useLoadScript } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { Box, CircularProgress } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

//Import styles
import useStyles from './styles';

const libraries = ['places', 'geometry'];

const GoogleMapsScriptLoader = ({ children }) => {
	const { i18n } = useTranslations();
	const lng = i18n.language;

	const classes = useStyles();

	//eslint-disable-next-line
	const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

	const { isLoaded, loadedError } = useLoadScript({
		googleMapsApiKey: API_KEY,
		libraries: libraries,
	});

	useEffect(() => {
		Geocode.setApiKey(API_KEY);
		Geocode.setLanguage(lng);
		//eslint-disable-next-line
	}, []);

	return isLoaded ? (
		loadedError ? (
			<Box>{loadedError}</Box>
		) : (
			<>{children}</>
		)
	) : (
		<Box className={classes.loader}>
			<CircularProgress size={27} />
		</Box>
	);
};

GoogleMapsScriptLoader.propTypes = {
	children: node.isRequired,
};

export default GoogleMapsScriptLoader;
