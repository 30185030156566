import React from 'react';
import { fetchBookings } from 'store/actions';

// Import utils and helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';

// Import components
import { TableBodyRow } from './components';

export const RegistrationRequestsList = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchBookings}
		/>
	);
};
