import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translation
import { useTranslations } from 'components/utilities';

// Import components
import { FileFieldAdapter } from 'components/elements';

const PageImage = (props) => {
	const { className, section, ...rest } = props;

	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('cms.pages.form.image.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							previewFileState={section}
							component={FileFieldAdapter}
							name={`${section}.file_path`}
							fileNameField={`${section}.file_name`}
							disabled={submitting}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

PageImage.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default PageImage;
