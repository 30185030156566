import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { AlertMessage, LoadingWrapper } from 'components/elements';

// Import utils
import { useBookingsBySourceQuery } from 'queries';
import { useFilters } from '../../../../context';

export const Content = () => {
	const { t } = useTranslations();
	const { filters } = useFilters();
	const { data, isError, isLoading } = useBookingsBySourceQuery(filters);

	if (isLoading) return <LoadingWrapper />;

	if (isError)
		return (
			<AlertMessage
				title={t('common.error')}
				description={t('common.messages.error_message')}
			/>
		);

	const sources = Object.entries(data).map(([source, value]) => ({
		label: `dashboard.bookings_stats.content.${source}`,
		value,
	}));

	const total = sources.reduce((acc, { value }) => (acc += value), 0);

	return (
		<>
			<Typography variant="h3" color="primary" align="center" gutterBottom>
				{total}
			</Typography>
			<Grid container spacing={1}>
				{sources.map(({ label, value }) => (
					<Grid key={label} item xs={4}>
						<Typography variant="h5" align="center">
							{t(label)}
						</Typography>
						<Typography variant="body1" align="center">
							{value}
						</Typography>
					</Grid>
				))}
			</Grid>
		</>
	);
};
