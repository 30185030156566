import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	divider: {
		margin: '20px 0',
	},
	topBar: {
		marginBottom: 20,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'nowrap',
	},
}));

export default useStyles;
