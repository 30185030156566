import React from 'react';
import { Grid } from '@material-ui/core';

//Import utils
import { useTranslations } from 'components/utilities';
import { useSections } from './useSections';

// Import components
import { CollapsableCard } from 'components/elements';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = () => {
	const { t } = useTranslations();

	const { sections } = useSections();

	const classes = useStyles();

	return (
		<Grid container spacing={4}>
			{sections.map(({ section, children: Children, fields }) => (
				<Grid item key={section} xs={12}>
					<CollapsableCard
						title={t(`contract.create_contract_form.sections.${section}`)}
						titleClassName={classes.collapseTitle}
					>
						<Grid container className={classes.children}>
							<Children fields={fields} />
						</Grid>
					</CollapsableCard>
				</Grid>
			))}
		</Grid>
	);
};
