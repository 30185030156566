import React from 'react';

// Import utils
import { AddCorporateAndCoordinatorProvider } from './context';

// Import components
import { PanelWrapper } from './components';

export const AddCorporateAndCoordinatorPanel = () => {
	return (
		<AddCorporateAndCoordinatorProvider>
			<PanelWrapper />
		</AddCorporateAndCoordinatorProvider>
	);
};
