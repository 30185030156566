import React from 'react';
import useDropCitiesForm from '../../hooks/useDropCitiesForm';
import { AutoSizer } from 'react-virtualized';
import {
	TwoHeadedTable,
	TwoHeadedTableLabelCell,
	TwoHeadedTableHorizontalHeaderCell,
	TwoHeadedTableVerticalHeaderCell,
} from 'components/elements';
import DropCitiesTableBodyCell from './DropCitiesTableBodyCell';
import DropCitiesTableToolbar from './DropCitiesTableToolbar';

const DropCitiesTable = () => {
	const form = useDropCitiesForm();

	const {
		cities,
		dropCities,
		handlePriceClear,
		handlePriceChange,
		handleEdit,
		isLoading,
		error,
	} = form;

	return (
		<>
			<DropCitiesTableToolbar {...form} />

			<AutoSizer disableHeight>
				{({ width }) => (
					<TwoHeadedTable
						data={dropCities}
						isLoading={isLoading}
						error={error}
						horizontalColumns={cities}
						verticalColumns={cities}
						renderLabelCell={({ key, style }) => (
							<TwoHeadedTableLabelCell
								key={key}
								style={style}
								labelHorizontal="In"
								labelVertical="Out"
							/>
						)}
						renderHorizontalHeaderCell={({ key, style, column: cityFrom }) => (
							<TwoHeadedTableHorizontalHeaderCell key={key} style={style}>
								{cityFrom.name}
							</TwoHeadedTableHorizontalHeaderCell>
						)}
						renderVerticalHeaderCell={({ key, style, column: cityTo }) => (
							<TwoHeadedTableVerticalHeaderCell key={key} style={style}>
								{cityTo.name}
							</TwoHeadedTableVerticalHeaderCell>
						)}
						renderBodyCell={({ key, style, data: dropCity }) => {
							return (
								<DropCitiesTableBodyCell
									key={key}
									style={style}
									dropCity={dropCity}
									onEdit={() => handleEdit(dropCity)}
									onPriceChange={(ev) => handlePriceChange(ev, dropCity)}
									onPriceClear={() => handlePriceClear(dropCity)}
								/>
							);
						}}
						columnCount={cities.length}
						rowCount={cities.length}
						columnWidth={150}
						rowHeight={54}
						height={600}
						width={width}
					/>
				)}
			</AutoSizer>
		</>
	);
};

export default DropCitiesTable;
