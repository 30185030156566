// ********************  table  ********************

export const fetchOrganizationsUrl = (queryParams) =>
	`/v2/organization/list${queryParams}`;

export const updateActiveOrganizationUrl = (id, isActive) =>
	`/v2/organization/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const deleteOrganizationUrl = (id) => `/v2/organization/${id}/remove`;

// ********************  form  ********************

export const submitOrganizationFormUrl = (itemId, isEdit) =>
	`/v2/organization/${isEdit ? `${itemId}/update` : 'create'}`;

export const submitAddOrganizationFormUrl = `/v2/organization/create`;
