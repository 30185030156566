import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { string } from 'prop-types';

// Import utils
import { PaymentProvider } from './components/Payment/context';
import { useTranslations } from 'components/utilities';
import { useSubscriptionPaymentsQuery } from 'queries';

// Import components
import { CollapsableCard } from 'components/elements';
import { Payment } from './components';

// Import helpers
import { getCompletePaymentText, getSettledPaymentsSum } from './helpers';

// Import styles
import { useStyles } from './SubscriptionInvoiceCard.styles';

export const SubscriptionInvoiceCard = ({ subscriptionUuid }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { data: payments, isLoading } = useSubscriptionPaymentsQuery({
		uuid: subscriptionUuid,
	});

	const completePaymentText = getCompletePaymentText(payments);
	const settledPaymentsSum = getSettledPaymentsSum(payments);

	if (!subscriptionUuid) return null;

	return (
		<CollapsableCard
			lg={6}
			title={t('bookings.preview_booking.subscription_invoice.title')}
			elevation={1}
		>
			{isLoading ? (
				<Grid container justifyContent="center" alignItems="center">
					<Grid item>
						<CircularProgress />
					</Grid>
				</Grid>
			) : (
				<Grid container direction="column">
					{payments.map((payment, index) => (
						<Grid item xs key={index}>
							<PaymentProvider
								subscriptionUuid={subscriptionUuid}
								payments={payments}
								payment={payment}
								index={index}
							>
								<Payment
									title={`${t(
										'bookings.preview_booking.subscription_invoice.invoice'
									)} (${index + 1}/${payments.length})`}
								/>
							</PaymentProvider>
						</Grid>
					))}
					<Grid item>
						<Grid container className={classes.bottomBox}>
							<Grid item>
								<Typography variant="h5">{completePaymentText}</Typography>
							</Grid>
							<Grid item>
								<Typography variant="h5">{settledPaymentsSum}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</CollapsableCard>
	);
};

SubscriptionInvoiceCard.propTypes = {
	subscriptionUuid: string,
};
