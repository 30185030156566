import React from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { Alert, LoadingOverlay } from 'components/elements';
import { BookingHistoryBody } from './components';
import useBookingHistory from './context/useBookingHistory';

// Import helpers
import { columns } from './columns';

const BookingHistory = ({ open, onClose }) => {
	const { t } = useTranslations();

	const { isFetching, isError, error } = useBookingHistory();

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg">
			<Box position="relative">
				<DialogTitle>{t('bookings.booking_history.title')}</DialogTitle>

				<LoadingOverlay isLoading={isFetching} />
				{isError && <Alert message={error} />}

				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{columns.map(({ header, accessor }) => (
									<TableCell key={accessor}>{header ? t(header) : ''}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							<BookingHistoryBody />
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Dialog>
	);
};

BookingHistory.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func
};

export default BookingHistory;
