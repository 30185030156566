import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
};

export const columns = ({ lng }) => [
	{
		Header: 'cms.pages.table.headers.slug',
		accessor: `slug.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'cms.pages.table.headers.title',
		accessor: `title.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'cms.pages.table.headers.content',
		accessor: `content.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
