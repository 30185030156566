import { FIELD_TYPES } from 'helpers';

export const ADD_CUSTOMER_STEP_FIELDS = ({
	handleOnFileUploader,
	fileUploaderError,
}) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.name',
		name: `name`,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.email',
		name: 'email',
		required: true,
	},
	{
		type: FIELD_TYPES.FILE_UPLOADER,
		label: 'common.fields.driver_license',
		name: 'license',
		required: true,
		handleOnFileUploader,
		fileUploaderError,
	},
	{
		type: FIELD_TYPES.DATE,
		label: 'common.fields.expiration_date',
		name: 'license_expire_date',
		required: true,
	},
	{
		type: FIELD_TYPES.DATE,
		label: 'common.fields.birth_date',
		name: 'birth_date',
		required: true,
		minDate: false,
	},
];
