/* eslint-disable no-mixed-spaces-and-tabs */
import { useSelector } from 'react-redux';

// Import helpers
import { previewBookingSelector } from 'store/selectors';
import { DELIVERY_STATUS, DELIVERY_TYPES, getConvertedDelivery } from 'helpers';

export const usePickUpAddressPreviewField = () => {
	const { PICK_UP_DELIVERY } = DELIVERY_TYPES;

	const booking = useSelector(previewBookingSelector);

	const { delivery, drop } = booking || {};

	let deliveryData = getConvertedDelivery(delivery);

	const { lat, lng, address } = delivery;

	const getDeliveryLink = ({ lat, lng }) =>
		`https://www.google.com/maps/place/${lat},${lng}`;

	deliveryData = deliveryData.map((data) =>
		data.deliveryType === PICK_UP_DELIVERY
			? {
					...data,
					address,
					lat,
					lng,
					deliveryLink: getDeliveryLink({ lat, lng }),
			  }
			: {
					...data,
					...drop,
					deliveryLink: getDeliveryLink({ lat: drop?.lat, lng: drop?.lng }),
			  }
	);

	const availableDeliveryData = deliveryData.filter(
		({ available }) => !!available
	);

	const showDeliverySteps = (deliveryType) => {
		const { delivery_state } = deliveryData.find(
			({ deliveryType: type }) => type === deliveryType
		);

		return null != delivery_state && delivery_state !== DELIVERY_STATUS.PENDING;
	};

	const deliveryUuids = deliveryData.reduce(
		(acc, { deliveryType, delivery_uuid }) => {
			if (delivery_uuid) {
				return { ...acc, [deliveryType]: delivery_uuid };
			} else return acc;
		},
		{}
	);

	const getLabel = (deliveryType) =>
		deliveryType === PICK_UP_DELIVERY
			? 'bookings.preview_booking.delivery_information.pick_address'
			: 'bookings.preview_booking.delivery_information.return_address';

	return {
		availableDeliveryData,
		showDeliverySteps,
		deliveryUuids,
		getLabel,
	};
};
