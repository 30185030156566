import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, Checkbox } from '@material-ui/core';

// Import components
import { NotificationAlert } from 'components/elements';
import useSettlementStatusTableCell from './useSettlementStatusTableCell';

const SettlementStatusTableCell = ({ cell }) => {
	const {
		isSettled,
		handleChange,
		isError,
		error
	} = useSettlementStatusTableCell(cell.row.original);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<NotificationAlert open={isError} message={error} />
			<Checkbox color="primary" checked={isSettled} onChange={handleChange} />
		</TableCell>
	);
};

SettlementStatusTableCell.propTypes = {
	cell: PropTypes.object.isRequired
};

export default SettlementStatusTableCell;
