import { makeStyles } from '@material-ui/styles';
import { DIMENSIONS } from 'helpers';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
	},
	buttonSave: {
		marginLeft: theme.spacing(3),
	},
	cardActions: {
		justifyContent: 'flex-end',
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: `calc(100vh - 2*${DIMENSIONS.nav}px - ${DIMENSIONS.footer}px)`,
		width: '100%',
		zIndex: 9999,
	},
	removeBoxShadow: {
		boxShadow: 'none',
	},
}));

export default useStyles;
