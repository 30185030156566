import * as types from 'store/types';
import services from 'services/services';
import { fetchCmsPagesFormResourcesUrl, submitPageFormUrl } from 'store/paths';

// Import helpers
import { catchError } from '../../helpers';

export const fetchCmsPagesFormResources =
	({ itemId, isEdit, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.CMS_PAGE_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			const { data } =
				isEdit &&
				(await services.get(
					fetchCmsPagesFormResourcesUrl(itemId),
					cancelToken
				));

			dispatch({
				type: types.CMS_PAGE_FORM_FETCH_DATA_SUCCES,
				payload: {
					item: data || {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.CMS_PAGE_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitPageForm =
	({ values, itemId, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			await services.post(submitPageFormUrl(itemId), values);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
