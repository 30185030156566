import React from 'react';
import { string } from 'prop-types';

// Import components
import NotFound from 'views/NotFound';
import {
	CustomerRegisteredFields,
	CustomerExperienceSharedFields,
} from './components';

// Import helpers
import { CAMPAIGN_TYPES } from 'helpers';

const CampaignFormFields = ({ campaignType }) => {
	const { CUSTOMER_EXPERIENCE_SHARED, CUSTOMER_REGISTERED } = CAMPAIGN_TYPES;

	switch (campaignType) {
		case CUSTOMER_EXPERIENCE_SHARED:
			return <CustomerExperienceSharedFields />;

		case CUSTOMER_REGISTERED:
			return <CustomerRegisteredFields />;

		default:
			return <NotFound />;
	}
};

CampaignFormFields.propTypes = {
	campaignType: string,
};

export default CampaignFormFields;
