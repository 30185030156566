import * as types from 'store/types';
import services from 'services/services';
import { defaultSeoFormUrl } from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import { convertItemId } from './helpers';

export const fetchDefaultSeoFormResources = ({
	itemId,
	isEdit,
	cancelToken,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.DEFAULT_SEO_FORM_FETCH_DATA_LOADING,
			payload: true,
		});

		const convertedItemId = convertItemId(itemId);
		const { data } =
			isEdit &&
			(await services.get(defaultSeoFormUrl(convertedItemId), cancelToken));

		dispatch({
			type: types.DEFAULT_SEO_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: data || {},
			},
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.DEFAULT_SEO_FORM_FETCH_DATA_ERROR,
		});
	}
};

export const submitDefaultSeoForm = ({
	values,
	itemId,
	errorCallback,
}) => async () => {
	try {
		const convertedItemId = convertItemId(itemId);
		await services.post(defaultSeoFormUrl(convertedItemId), values);
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
