import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';
import { useStatistics } from './useStatistics';

// Import styles
import { useStyles } from './Statistics.styles';

export const Statistics = () => {
	const { statistics } = useStatistics();

	const { t } = useTranslations();

	const classes = useStyles();

	if (!statistics) {
		return (
			<Box className={classes.box}>
				<CircularProgress size={40} />
			</Box>
		);
	}

	return (
		<Box className={classes.list}>
			{statistics.map(({ name, value, unit }) => (
				<Box key={name} className={classes.container}>
					<Typography className={classes.name}>{t(name)}</Typography>
					<Typography className={classes.amount} component="span">
						{value}{' '}
						<Typography className={classes.unit} component="span">
							{t(unit)}
						</Typography>
					</Typography>
				</Box>
			))}
		</Box>
	);
};
