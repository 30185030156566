import React from 'react';
import { node } from 'prop-types';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function RTLProvider({ children }) {
	return <StylesProvider jss={jss}>{children}</StylesProvider>;
}

RTLProvider.propTypes = {
	children: node,
};

export default RTLProvider;
