// Import helpers
import { FAQ_PAGE_LOCATION, FIELD_TYPES } from 'helpers';

const hideServiceTypesIfPageValue = [FAQ_PAGE_LOCATION.CAR_DETAILS, 'all'];

export const FAQ_FIELDS = ({
	selectedPages,
	selectedServiceTypes,
	pages,
	multiSelectLabelExtractor,
	serviceTypes,
}) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'faq.form.fields.title_en',
		name: 'details.name.en',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'faq.form.fields.title_ar',
		name: 'details.name.ar',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'faq.form.fields.order',
		name: 'order',
		required: true,
		md: 6,
		min: 1,
	},

	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		name: 'faq_location',
		label: 'faq.form.fields.page',
		md: 6,
		options: pages,
		multiSelectLabelExtractor,
		currentMultiSelectValues: selectedPages,
		keyExtractor: ({ value }) => value,
		labelExtractor: ({ label }) => label,
		showAllOption: true,
		searchExtractor: 'children[1]',
		required: true,
	},
	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		name: 'service_type',
		label: 'faq.form.fields.service_type',
		md: 6,
		options: serviceTypes,
		multiSelectLabelExtractor,
		currentMultiSelectValues: selectedServiceTypes,
		keyExtractor: ({ value }) => value,
		labelExtractor: ({ label }) => label,
		showAllOption: true,
		searchExtractor: 'children[1]',
		required: true,
		isHidden: !selectedPages?.some((service) =>
			hideServiceTypesIfPageValue.includes(service)
		),
	},
	{
		type: FIELD_TYPES.IMAGE_FILE,
		label: 'faq.form.fields.image',
		name: 'image.file_path',
		previewFileState: 'image',
		fileNameField: 'image.file_name',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.RICH_TEXT,
		label: 'faq.form.fields.description_en',
		name: 'details.description.en',
		required: true,
		md: 12,
	},
	{
		type: FIELD_TYPES.RICH_TEXT,
		label: 'faq.form.fields.description_ar',
		name: 'details.description.ar',
		required: true,
		md: 12,
	},
];
