import React from 'react';
import { Button, TableCell } from '@material-ui/core';
import { object } from 'prop-types';

// Import utils
import { useValidationActionTableCell } from './useValidationActionTableCell';
import { useTranslations } from 'components/utilities';

// Import components
import { ValidationModal } from './components';
import { StatusChip } from '../../components';

// Import styles
import { useStyles } from './styles';

export const ValidationActionTableCell = ({ cell }) => {
	const {
		cancelReasonStatus,
		handleCloseModal,
		handleOpenModal,
		isValidated,
		isOpen,
	} = useValidationActionTableCell({ cell });
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<ValidationModal open={isOpen} onClose={handleCloseModal} />
			{isValidated ? (
				<StatusChip cancelReasonStatus={cancelReasonStatus} />
			) : (
				<Button
					onClick={handleOpenModal}
					className={classes.actionButton}
					color="primary"
					variant="contained"
					size="small"
				>
					{t('validation.table.action.validate')}
				</Button>
			)}
		</TableCell>
	);
};

ValidationActionTableCell.propTypes = {
	cell: object.isRequired,
};
