import React from 'react';
import { func, element, object, bool } from 'prop-types';
import { Box, Button, Grid } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';

// Import components
import { CollapsableCard } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

const TableFiltersContent = ({
	children,
	additionalInfo: AdditionalInfo,
	initialValues,
	initialOpen = false,
}) => {
	const form = useForm();
	const formState = useFormState();
	const { t } = useTranslations();

	const { submitting } = formState;

	const handleClearFilters = () => {
		form.reset(initialValues);
		form.submit();
	};

	return (
		<CollapsableCard title={t('table.filters.title')} initialOpen={initialOpen}>
			<Box padding={2} width="100%">
				{children({ ...formState, form })}
			</Box>
			<Box padding={2} width="100%">
				<Grid container spacing={2} justifyContent="space-between">
					<Grid item xs={6}>
						{AdditionalInfo}
					</Grid>
					<Grid item xs={6} container spacing={2} justifyContent="flex-end">
						<Grid item>
							<Button
								variant="outlined"
								disabled={submitting}
								type="button"
								onClick={handleClearFilters}
								color="primary"
							>
								{t('table.modal.edit.btn_clear')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								disabled={submitting}
								type="submit"
								color="primary"
							>
								{t('common.buttons.submit')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</CollapsableCard>
	);
};

TableFiltersContent.propTypes = {
	children: func.isRequired,
	additionalInfo: element,
	initialValues: object,
	initialOpen: bool,
};

export default TableFiltersContent;
