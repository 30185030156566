import React from 'react';
import { Box } from '@material-ui/core';
// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

import { DropCitiesTable } from './components';

const DropCities = () => {
	const classes = useBaseLayoutStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.content}>
				<DropCitiesTable />
			</Box>
		</Box>
	);
};

export default DropCities;
