import { makeStyles } from '@material-ui/core';

const messageStyle = {
	fontSize: 12,
	width: '100%',
	marginTop: '5px',
};

export const useStyles = makeStyles((theme) => ({
	title: {
		color: theme.palette.text.primary,
		fontWeight: 'bold',
		fontSize: 14,
		marginBottom: 10,
	},
	warning: {
		...messageStyle,
		color: theme.palette.warning.main,
	},
	error: {
		...messageStyle,
		color: theme.palette.error.main,
	},
	itemWrapper: {
		marginBottom: 20,
	},
}));
