import { TextFieldAdapter } from 'components/elements';

export const RESET_PASSWORD_FIELDS = [
	{
		type: 'password',
		label: 'forget_password.form.password_label',
		name: 'password',
		component: TextFieldAdapter
	},
	{
		type: 'password',
		label: 'forget_password.form.password_confirmation_label',
		name: 'password_confirmation',
		component: TextFieldAdapter
	},
];
