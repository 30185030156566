import services from 'services/services';

// Import store
import * as types from 'store/types';
import {
	fetchCompanyOfficesUrl,
	fetchDriverFormResourcesUrl,
	fetchUserFormResourcesUrl,
	submitDriverFormUrl,
	submitUserFormUrl,
} from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import {
	convertSubmitDriverPayload,
	getConvertedUserData,
	INITIAL_VALUES,
} from './helpers';

export const fetchDriverFormResources =
	({ itemId, isEdit, cancelToken, companyUuid }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.DRIVER_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			const { data: driverData } =
				isEdit &&
				(await services.get(fetchDriverFormResourcesUrl(itemId), cancelToken));

			const userUuid = driverData?.data.user_uuid;

			const { data: userData } =
				isEdit &&
				(await services.get(fetchUserFormResourcesUrl(userUuid), cancelToken));

			const itemData = { ...driverData?.data, ...userData?.data };

			dispatch({
				type: types.DRIVER_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: { data: isEdit ? itemData : INITIAL_VALUES({ companyUuid }) },
				},
			});
		} catch (error) {
			catchError({ error, dispatch, type: types.DRIVER_FORM_FETCH_DATA_ERROR });
		}
	};

export const submitDriverForm =
	({ values, callbackSuccess, errorCallback, isEdit, itemId }) =>
	async () => {
		try {
			const userUuid = values.user_uuid;

			const convertedUserData = getConvertedUserData(values);

			// USER CREATE/EDIT
			const { data } = await services.post(
				submitUserFormUrl(isEdit, userUuid),
				convertedUserData
			);

			const userUuidFromApi = data.data.details.uuid;

			const driverPayload = convertSubmitDriverPayload({
				userUuidFromApi,
				values,
			});

			// DRIVER CREATE/EDIT
			await services[isEdit ? 'put' : 'post'](
				submitDriverFormUrl(isEdit, itemId),
				driverPayload
			);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const fetchCompanyOffices = async ({ companyUuid, errorCallback }) => {
	try {
		const data = await services.get(
			fetchCompanyOfficesUrl({
				companyUuid,
			})
		);

		return data.data.data;
	} catch {
		errorCallback();
	}
};
