import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 9999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.white,
	},
}));

export default useStyles;
