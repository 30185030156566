import services from 'services/services';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';

// Import store
import { fetchBookingsUrl } from 'store/paths';

export const fetchCorporateBookings =
	({ options, queryParams, cancelToken, itemId, customerUuid }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const iniialReqParams = '?start=0&length=1';
			const {
				data: {
					input: { columns },
				},
			} = await services.get(fetchBookingsUrl(iniialReqParams), cancelToken);

			const corporateUuidIdx = columns.findIndex(
				(col) => col.name === 'corporate_uuid'
			);
			const customerUuidIdx = columns.findIndex(
				(col) => col.name === 'customer_uuid'
			);

			const getDefaultQueryParams = () => {
				const baseParams = `?columns[${corporateUuidIdx}][search][value]=${itemId}`;
				if (customerUuid) {
					return `${baseParams}&columns[${customerUuidIdx}][search][value]=${customerUuid}`;
				}

				return baseParams;
			};

			const defaultQueryParams = getDefaultQueryParams();

			const filtersQueryParams = queryParams ? queryParams.split('?')[1] : '';
			const extendedQueryParams = queryParams
				? `${defaultQueryParams}&${filtersQueryParams}`
				: defaultQueryParams;

			const params = combineQueryAndPaginationParams({
				queryParams: extendedQueryParams,
				body,
			});

			const { data } = await services.get(
				fetchBookingsUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
