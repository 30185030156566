import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@material-ui/core';
import { bool } from 'prop-types';

// Import utilities
import { useDataFormContent } from './useDataFormContent';
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress, CollapsableCard } from 'components/elements';

// Import styles
import { useStyles } from './DataFormContent.styles';

// Import helpers
import { replacePreviewBookingUrl } from 'helpers';

export const DataFormContent = ({ isLoading }) => {
	const { t } = useTranslations();

	const { sections } = useDataFormContent();

	const classes = useStyles();

	const { id } = useParams();

	const history = useHistory();

	const goToBookingPreview = () => history.push(replacePreviewBookingUrl(id));

	return (
		<Grid container spacing={3} className={classes.container}>
			{sections.map(
				({ section, fields, children: Children, defaultMinimize }) => (
					<Grid item key={section} xs={12}>
						<CollapsableCard
							close={defaultMinimize}
							titleClassName={classes.collapseTitle}
							title={t(`contract.create_contract_form.sections.${section}`)}
						>
							<Box className={classes.innerCardBox}>
								<Children fields={fields} />
							</Box>
						</CollapsableCard>
					</Grid>
				)
			)}
			<Grid item xs={12} className={classes.buttonWrapper}>
				<ButtonProgress
					circularProgressSize={28}
					isLoading={isLoading}
					disabled={isLoading}
					variant="contained"
					color="primary"
					type="submit"
					className={classes.closeButton}
				>
					{t('common.buttons.close_contract')}
				</ButtonProgress>
				<Button
					onClick={goToBookingPreview}
					disabled={isLoading}
					variant="outlined"
					color="primary"
				>
					{t('common.buttons.cancel')}
				</Button>
			</Grid>
		</Grid>
	);
};

DataFormContent.propTypes = {
	isLoading: bool,
};
