import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	mb: {
		marginBottom: '8px',
	},
	assignedLate: {
		color: theme.palette.error.main,
	},
}));
