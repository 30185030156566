import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.primary.main,
	},
	input: {
		'& .MuiInputBase-adornedEnd, .MuiInputBase-input': {
			backgroundColor: 'transparent',
		},
		'& .MuiInputBase-input': {
			textAlign: 'end',
		},
	},
}));
