// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { BOOKING_URGENT_STATES, EN } from 'helpers';

export const useDescriptionTableCell = (cell) => {
	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const {
		blamable_user_name: assignedBy,
		marked_by_name: markedBy,
		name: { en, ar },
		uuid,
		status,
	} = cell;

	const reasonName = lng === EN ? en : ar;

	const labelEnding =
		status === BOOKING_URGENT_STATES.SOLVED ? 'solved_by' : 'unsolved_by';

	const assignedByLabel = t(
		'bookings.preview_booking.booking_info.urgent_reasons_history.assigned_by',
		{ assignedBy }
	);

	const markedByLabel = t(
		`bookings.preview_booking.booking_info.urgent_reasons_history.${labelEnding}`,
		{ markedBy }
	);

	return { uuid, assignedByLabel, markedByLabel, reasonName, markedBy, status };
};
