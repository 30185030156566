import services from 'services/services';
import { fetchSpecialOffersUrl, deleteSpecialOfferUrl } from 'store/paths';

export const fetchSpecialOffers =
	({ options, queryParams, cancelToken, serviceType }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				service_type: serviceType,
			};

			// Get response
			const { data } = await services.post(
				fetchSpecialOffersUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const deleteSpecialOffer =
	({ id }) =>
	async () => {
		await services.delete(deleteSpecialOfferUrl(id));
	};
