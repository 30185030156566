import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';

// Import components
import { LateAndDeliveryTableCell } from '../../components';
import {
	ExpandTableCell,
	ActiveTableCell,
	LinkTableCell,
	AmountTableCell,
	IsSettledTableCell,
	RatingTableCell,
} from 'components/elements';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../../../columns';
import { replacePreviewBookingUrl, toFixedWithoutZeros } from 'helpers';

const {
	EXPAND,
	ACTIVE,
	RATING,
	BOOKING_NUMBER,
	EARNINGS,
	PICKUP_TIME,
	IS_SETTLED,
	LATE_ASSIGNMENT_AND_DELIVERY,
	ROLE,
} = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		const { booking_number, booking_uuid, pickup_time, earnings, rating } =
			cell.row.original || {};

		const { id, isHidden } = cell.column;

		if (isHidden) return null;
		switch (id) {
			case EXPAND:
				return <ExpandTableCell key={id} cell={cell} hideExpand />;

			case ACTIVE:
				return <ActiveTableCell hideActive key={id} cell={cell} />;

			case RATING:
				return (
					<RatingTableCell
						key={id}
						cell={cell}
						rating={toFixedWithoutZeros(+rating, 2)}
					/>
				);

			case BOOKING_NUMBER:
				return (
					<LinkTableCell
						key={id}
						cell={cell}
						to={replacePreviewBookingUrl(booking_uuid)}
					>
						{booking_number}
					</LinkTableCell>
				);

			case EARNINGS:
				return <AmountTableCell key={id} cell={cell} amount={earnings} />;

			case PICKUP_TIME:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{pickup_time.split(' ').map((row) => (
							<Typography key={row}>{row}</Typography>
						))}
					</TableCell>
				);

			case IS_SETTLED:
				return <IsSettledTableCell key={id} cell={cell} />;

			case LATE_ASSIGNMENT_AND_DELIVERY:
				return <LateAndDeliveryTableCell key={id} cell={cell} />;

			case ROLE:
				return <TableCell key={id} {...cell.getCellProps()} />;

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<>
			<TableRow key={row.id} {...row.getRowProps()}>
				{row.cells.map(renderCell)}
			</TableRow>
		</>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
