import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';

// Import components
import { NotificationAlert } from 'components/elements';

// Import utils
import { useFulfilledCompensation } from './useFulfilledCompensation';

export const FulfilledCompensation = ({ cell }) => {
	const { compensation } = cell.row.original;
	const { isFulfilled, handleToggle, isError, error } =
		useFulfilledCompensation(compensation);

	return (
		<>
			<NotificationAlert open={isError} message={error} />
			<IconButton onClick={handleToggle}>
				{isFulfilled ? (
					<FlagIcon color="primary" />
				) : (
					<FlagOutlinedIcon color="primary" />
				)}
			</IconButton>
		</>
	);
};

FulfilledCompensation.propTypes = {
	cell: PropTypes.object.isRequired,
};
