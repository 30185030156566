import React from 'react';
import { useLocation } from 'react-router-dom';
import { array, string, bool } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { Grid, MenuItem, CircularProgress } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import components
import { SelectFieldAdapter } from 'components/elements';

const Year = ({
	years,
	fieldName,
	label,
	isGlobalEdit,
	yearsLoaded,
	carModel,
	carBrand,
}) => {
	const { submitting } = useFormState();

	const { pathname } = useLocation();

	const { t } = useTranslations();

	const disableYearField =
		submitting || (isGlobalEdit ? !carModel : !carBrand) || years.length === 0;

	const isAddOption = isGlobalEdit || pathname === URLS.globalAddCarUrl;

	return (
		<Grid item md={12} xs={12}>
			{yearsLoaded ? (
				<Field
					fullWidth
					component={SelectFieldAdapter}
					type="select"
					label={t(label)}
					name={fieldName}
					margin="dense"
					variant="outlined"
					disabled={disableYearField}
					required={!isGlobalEdit}
				>
					{years.map((year) => (
						<MenuItem key={year} value={year}>
							{year}
						</MenuItem>
					))}
					{isAddOption && (
						<MenuItem value="all">
							{t('cars.form.car_details.option_all')}
						</MenuItem>
					)}
				</Field>
			) : (
				<CircularProgress size={27} />
			)}
		</Grid>
	);
};

Year.propTypes = {
	years: array.isRequired,
	fieldName: string.isRequired,
	label: string.isRequired,
	isGlobalEdit: bool.isRequired,
	yearsLoaded: bool.isRequired,
	carBrand: string.isRequired,
	carModel: string,
};

export default Year;
