import React from 'react';

// Import components
import { DateRangeFilter } from 'components/elements';

export const CampaignFilters = () => {
	return (
		<DateRangeFilter
			nameFrom="from_date"
			nameUntil="from_date_to"
			label="common.filters.create_date"
			combinedInputs
		/>
	);
};
