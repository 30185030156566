import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		padding: '32px',
	},
	additionalWrapper: {
		width: '100%',
		height: 'calc(100vh - 2 * 80px)',
		padding: '16px',
	},
	title: {
		color: theme.palette.primary.main,
		fontFamily: 'Rubik',
		fontWeight: '500',
		fontSize: '20px',
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	},
	description: {
		color: theme.palette.text.secondary,
		fontFamily: 'Rubik',
		fontWeight: '500',
		fontSize: '20px',
		[theme.breakpoints.down('md')]: {
			fontSize: '14px',
			color: theme.palette.gray.lightLabel,
		},
	},
	buttonBox: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: 0,
	},
	list: {
		padding: '32px 18px',
	},
}));
