import React from 'react';
import { IconButton } from '@material-ui/core';
import { PaginationItem } from '@material-ui/lab';
import clsx from 'clsx';

import {
	NavigateFirstIcon,
	NavigateLastIcon,
	NavigateNextIcon,
	NavigatePrevIcon,
} from 'icons';

import { PAGINATION_BUTTON_TYPES } from './helpers';
import { AR } from 'helpers';
import { useStyles } from './styles';
import { useTranslations } from 'components/utilities';

const useTablePagination = ({ setPageSize, gotoPage }) => {
	const classes = useStyles();

	const {
		i18n: { language },
	} = useTranslations();

	const { FIRST, LAST, NEXT, PREV } = PAGINATION_BUTTON_TYPES;

	const navIconClassName = language === AR ? classes.rtlIcon : null;

	const handleChangeSize = (e) => setPageSize(Number(e.target.value));

	const handlePaginationChange = (event, value) => {
		gotoPage(value - 1);
	};

	const renderIcon = (type) => {
		switch (type) {
			case FIRST:
				return <NavigateFirstIcon />;
			case PREV:
				return <NavigatePrevIcon />;
			case NEXT:
				return <NavigateNextIcon />;
			case LAST:
				return <NavigateLastIcon />;
			default:
				return;
		}
	};

	const renderItem = (renderProps) => {
		const { type } = renderProps;

		switch (type) {
			case FIRST:
			case PREV:
			case NEXT:
			case LAST:
				return (
					<IconButton
						{...renderProps}
						className={clsx([
							classes.paginationButton,
							classes.sideButton,
							navIconClassName,
						])}
					>
						{renderIcon(type)}
					</IconButton>
				);

			default:
				return (
					<PaginationItem
						{...renderProps}
						className={classes.paginationButton}
					/>
				);
		}
	};

	return {
		renderItem,
		onChangeSize: handleChangeSize,
		onPaginationChange: handlePaginationChange,
	};
};

export default useTablePagination;
