import React from 'react';
import { number } from 'prop-types';
import { Box, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { getListName } from './helpers';

// Import styles
import { useStyles } from './NestedList.styles';

export const NestedList = ({ value }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const list = t(`offices.edit_prices_dialog.${getListName(value)}`, {
		returnObjects: true,
	});

	return (
		<Box component="ul" className={classes.list}>
			{list.map((text) => (
				<li key={text}>
					<Typography>{text}</Typography>
				</li>
			))}
		</Box>
	);
};

NestedList.propTypes = {
	value: number.isRequired,
};
