import React from 'react';
import { func, string } from 'prop-types';

// Import utils
import { useDownloadFileButton } from './useDownloadFileButton';

// Import components
import { SplitButton } from 'components/elements';

// Import helpers
import { SPLIT_BUTTON_OPTIONS } from './helpers';

export const DownloadFileButton = ({ fetchAction, fileName }) => {
	const { handleOnDownload, isLoading } = useDownloadFileButton({
		fetchAction,
		fileName,
	});

	return (
		<SplitButton
			additionalOptionText="common.buttons.download"
			options={SPLIT_BUTTON_OPTIONS}
			onClick={handleOnDownload}
			isLoading={isLoading}
		/>
	);
};

DownloadFileButton.propTypes = {
	fetchAction: func.isRequired,
	fileName: string,
};
