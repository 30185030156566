export const COLUMNS_ACCESSORS = {
	NOTE: 'note',
	STATUS: 'status',
	OFFICE_NAME: 'office_name',
	CUSTOMER_NAME: 'customer_name',
	BOOKING_ID: 'booking_id',
	DURATION: 'response_time',
	ACTIONS: 'actions',
	PICK_DATE_TIME: 'pick_date_time',
	DROP_DATE_TIME: 'drop_date_time',
	DELIVERY_ASSIGNMENT: 'delivery_assignment',
	DELIVERY_STATUS: 'delivery_status',
	TOPIC: 'topic',
};

export const columns = [
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.topic',
		accessor: COLUMNS_ACCESSORS.TOPIC,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.vehicle',
		accessor: 'vehicle',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.office_name',
		accessor: COLUMNS_ACCESSORS.OFFICE_NAME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.pick_date_time',
		accessor: COLUMNS_ACCESSORS.PICK_DATE_TIME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.drop_date_time',
		accessor: COLUMNS_ACCESSORS.DROP_DATE_TIME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.delivery_assignment',
		accessor: COLUMNS_ACCESSORS.DELIVERY_ASSIGNMENT,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.delivery_status',
		accessor: COLUMNS_ACCESSORS.DELIVERY_STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
