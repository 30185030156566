import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
// import { BOOKING_HISTORY_QUERY } from 'helpers';

const TRANSACTION_HISTORY_QUERY = 'booking_history';

const useTransactionHistory = ({ bookingId }) => {
	const path = `/v2/transaction/${bookingId}/booking`;

	const { data, refetch, isFetching, isError, error } = useQuery(
		[TRANSACTION_HISTORY_QUERY, bookingId],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : null;
	const meta = data ? data.data.meta : null;

	return { data: fetchedData, meta, refetch, isFetching, isError, error };
};

export default useTransactionHistory;
