export const fuelTypes = ['diesel', 'gas_91', 'gas_95', 'electric'];

export const specification = [
	'navigation',
	'bluetooth',
	'panorama',
	'usb',
	'rear_camera',
	'sensors',
	'car_multimedia',
];
