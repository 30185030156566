import React from 'react';
import { object } from 'prop-types';

// Import components
import { SingleAttachment } from './components';

export const ViewAttachmentPreviewField = ({ customData }) =>
	customData.attachments.map(({ file, label }) => (
		<SingleAttachment file={file} label={label} key={label} />
	));

ViewAttachmentPreviewField.propTypes = {
	customData: object,
};
