import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		marginBottom: theme.spacing(3),
	},
	buttonLogout: {
		color: theme.palette.primary.main,
		textTransform: 'none',
		minWidth: 'auto',
		verticalAlign: 'baseline',
		'&:hover': {
			background: 'none',
		},
	},
}));
