import React from 'react';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import get from 'lodash/get';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

// Import styles
import { useStyles } from './styles';

export const LicensePreviewField = ({ field }) => {
	const classes = useStyles();

	const booking = useSelector(previewBookingSelector);
	const { labelEn, labelAr, accessor } = field;

	const value = get(booking, accessor, '---');

	return (
		<>
			<Grid item xs={4}>
				<Typography variant="h6" className={classes.labelEn}>
					{labelEn}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<Typography variant="h6" className={classes.value}>
					{value}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<Typography variant="h6" className={classes.labelAr}>
					{labelAr}
				</Typography>
			</Grid>
		</>
	);
};

LicensePreviewField.propTypes = {
	field: object,
};
