import React from 'react';
import { object, string } from 'prop-types';
import {
	Toolbar,
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';

// Import helpers
import { TABLE_ROWS } from './helpers';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { EN } from 'helpers/variables';

//Import styles
import useStyles from './styles';

const Prices = ({ bookingDetails, lng }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const isEnglish = lng === EN;
	return (
		<>
			<Toolbar disableGutters variant="dense">
				<Typography variant="h5" color="primary">
					{t('booking_print.price')}
				</Typography>
			</Toolbar>
			<TableContainer>
				<Table>
					<TableBody>
						{TABLE_ROWS(bookingDetails).map(
							({ isTotal, rowName, price, currency }) => {
								const variant = isTotal ? 'head' : 'body';
								const totalPriceStyle = isTotal && classes.totalPrice;
								const alignNames = isEnglish ? 'left' : 'right';
								const alignValues = !isEnglish ? 'left' : 'right';

								return (
									<TableRow key={rowName}>
										<TableCell
											size="small"
											variant={variant}
											className={totalPriceStyle}
											align={alignNames}
										>
											{t(rowName)}
										</TableCell>
										<TableCell
											size="small"
											variant={variant}
											align={alignValues}
											className={totalPriceStyle}
										>
											{price}
										</TableCell>
										<TableCell
											size="small"
											variant={variant}
											align={alignValues}
											className={totalPriceStyle}
										>
											{t(currency)}
										</TableCell>
									</TableRow>
								);
							}
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

Prices.propTypes = {
	bookingDetails: object.isRequired,
	lng: string.isRequired,
};

export default Prices;
