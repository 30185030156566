import React from 'react';
import { string, func } from 'prop-types';
import { DialogTitle, Grid, Typography } from '@material-ui/core';

// Import components
import { CloseButton } from 'components/elements';

const ClosableDialogTitle = ({ title, onClose }) => {
	return (
		<DialogTitle>
			<Grid
				container
				spacing={2}
				justifyContent="space-between"
				alignItems="center"
			>
				<Grid item>
					<Typography variant="h4">{title}</Typography>
				</Grid>
				<Grid item>
					<CloseButton onClick={onClose} />
				</Grid>
			</Grid>
		</DialogTitle>
	);
};

ClosableDialogTitle.propTypes = {
	title: string.isRequired,
	onClose: func.isRequired,
};

export default ClosableDialogTitle;
