import React from 'react';

// Import utilities
import { fetchAirportsFormResources, submitAirportsForm } from 'store/actions';
import { FormRPC } from 'components/utilities';
import { INITIAL_VALUES } from './helpers';

// Import styles
import { useStyles } from './styles';

// Import components
import { BaseDetails, AirportLogo, Address } from './components';
import { Grid, Box } from '@material-ui/core';
import { URLS } from 'components/routes';

const EditAirports = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchAirportsFormResources}
				submitFormAction={submitAirportsForm}
				isCardLayout={false}
				goBackPath={URLS.kioskAirportsUrl}
				store={(store) => store.airports.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<BaseDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<AirportLogo section="logo" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Address section="location" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Address section="location.delivery" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default EditAirports;
