import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { COMPANY_DETAILS_QUERY } from 'helpers';

export const useCompanyDetails = (uuid, enabled) => {
	const companyCollectionUrl = `/v2/supplier/business/company/${uuid}/show
	`;

	const { data, isFetching, isError } = useQuery(
		COMPANY_DETAILS_QUERY,
		async () => await services.get(companyCollectionUrl),
		{ refetchOnWindowFocus: false, enabled }
	);
	const fetchedData = data?.data.data || {};

	return { data: fetchedData, isFetching, isError };
};
