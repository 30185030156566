import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	label: {
		fontSize: '14px',
		fontWeight: '500',
	},
	value: {
		fontSize: '14px',
	},
	divider: {
		width: '100%',
	},
}));
