import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import { Field } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { DraftWysiwygFieldAdapter } from 'components/elements';

const SupplierNotes = (props) => {
	const { className, section, ...rest } = props;
	const { submitting } = useFormState();
	const { t } = useTranslations();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.supplier_notes.supplier_notes')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12}>
						<Field
							component={DraftWysiwygFieldAdapter}
							name={`${section}.en`}
							disabled={submitting}
						/>
					</Grid>
					<Grid item md={12}>
						<CardHeader
							title={t('offices.form.supplier_notes.supplier_notes_ar')}
						/>
						<Field
							component={DraftWysiwygFieldAdapter}
							name={`${section}.ar`}
							disabled={submitting}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

SupplierNotes.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default SupplierNotes;
