// ********************  table  ********************

export const fetchPhoneNumbersUrl = (queryParams) =>
	`/v2/customer/yakeen/whitelist-phone/list${queryParams}`;

export const deletePhoneNumberUrl = (id) =>
	`/v2/customer/yakeen/whitelist-phone/${id}/remove`;

// ********************  form  ********************

export const submitPhoneNumberFormUrl =
	'/v2/customer/yakeen/whitelist-phone/create';
