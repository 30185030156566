export const TABLE_ROWS = (bookingPrices) => [
	{
		rowName: 'booking_print.car',
		price: bookingPrices.base_nett_price,
		currency: 'currency.sar_day',
	},
	{
		rowName: 'booking_print.original_price',
		price: bookingPrices.total_base_nett_price,
		currency: 'currency.sar',
	},
	{
		rowName: 'booking_print.estimated_cost',
		price: bookingPrices.total_discount_nett_price,
		currency: 'currency.sar',
	},
	{
		rowName: 'booking_print.vat_amount',
		price: bookingPrices.total_discount_vat_price,
		currency: 'currency.sar',
	},
	{
		rowName: 'booking_print.total_amount',
		price: bookingPrices.total_discount_gross_price,
		currency: 'currency.sar',
		isTotal: true,
	},
];
