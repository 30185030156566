import services from 'services/services';
import { rewardsSettlementBookingsUrl } from 'store/paths';

export const rewardsSettlementBookings = async (body) => {
	try {
		await services.post(rewardsSettlementBookingsUrl, body);

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};
