import { useTableContextProvider } from 'components/context';

export const useStatistics = () => {
	const {
		resources: { statistics },
	} = useTableContextProvider();

	const statisticsEntries = statistics && Object.entries(statistics);

	const convertedStatistics = statisticsEntries?.reduce(
		(acc, [name, value]) => {
			const statisticsKey = 'b2b.corporate.statistics';
			const unitsKey = 'b2b.corporate.units';

			const newStat = {
				name: `${statisticsKey}.${name}`,
				unit: `${unitsKey}.${name}`,
				value,
			};

			return [...acc, newStat];
		},
		[]
	);

	return {
		statistics: convertedStatistics,
	};
};
