import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	fieldBox: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		border: 'none',
		backgroundColor: theme.palette.gray.field,
		padding: '0 8px',
		borderRadius: '8px',
	},
	circularProgressBox: {
		display: 'grid',
		placeItems: 'center',
	},
	divider: {
		color: theme.palette.gray.dark,
		minHeight: '40px',
	},
	label: {
		color: theme.palette.gray.label,
		marginBottom: '8px',
	},
	prefixField: {
		maxWidth: '90px',
	},
	phoneNumberField: {
		'& .MuiInputBase-input': {
			backgroundColor: 'transparent',
		},
	},
}));
