import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { bool, string } from 'prop-types';
import {
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Box,
	CircularProgress,
} from '@material-ui/core';

//Import styles
import { useStyles } from './ExternalPartnerCarsTable.styles';

// Import helpers
import { columns } from './helpers';

// Import components
import { AlertMessage } from 'components/elements';
import { CarsTableRow } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useExternalPartnerCars } from 'queries';

export const ExternalPartnerCarsTable = ({ officeUuid, isOpen }) => {
	const { t } = useTranslations();

	const transKey = 'cars.map_external_partner_cars';

	const classes = useStyles();

	const { change } = useForm();

	const { data, isFetching, isError } = useExternalPartnerCars(
		officeUuid,
		isOpen
	);

	const {
		external_partner_cars: externalPartnerCars,
		office_cars: officeCars,
	} = data || {};

	useEffect(() => {
		change(`offices[${officeUuid}].office_cars`, officeCars);
		//eslint-disable-next-line
	}, [officeCars]);

	if (isFetching) {
		return (
			<Box className={classes.loadingContainer}>
				<CircularProgress size={32} />
			</Box>
		);
	}

	if (isError) {
		return (
			<AlertMessage
				title={t('common.error')}
				description={t('common.messages.error_message')}
			/>
		);
	}

	return (
		<Box className={classes.container}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							{columns.map(({ header, accessor }) => (
								<TableCell key={accessor}>
									{t(`${transKey}.${header}`)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{officeCars?.map((row, index) => (
							<CarsTableRow
								key={row.uuid}
								index={index}
								row={row}
								officeUuid={officeUuid}
								externalPartnerCars={externalPartnerCars}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

ExternalPartnerCarsTable.propTypes = {
	officeUuid: string,
	isOpen: bool,
};
