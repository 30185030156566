import React from 'react';
import { bool, func } from 'prop-types';
import { Grid, Tooltip } from '@material-ui/core';

// Import utils and helpers
import { useValidateCancellationForm } from '../../useValidateCancellationForm';
import { useCommonFields, useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';
import { FIELDS } from './helpers';

// Import components
import { ButtonProgress } from 'components/elements';

export const FormContent = ({ isLoading, approve }) => {
	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	const { cancel_reason_uuid, reasons } = useValidateCancellationForm({ lng });
	const { hasReasonChanged } = useFormContent({ cancel_reason_uuid });
	const { renderFields } = useCommonFields();

	return (
		<Grid
			container
			spacing={1}
			alignItems="center"
			justifyContent="space-between"
		>
			{FIELDS(reasons).map(renderFields)}
			<Grid item>
				<Grid container spacing={1}>
					<Grid item>
						<ButtonProgress
							circularProgressSize={24}
							isLoading={isLoading}
							disabled={isLoading}
							variant="contained"
							onClick={approve}
							color="primary"
							type="submit"
							size="small"
						>
							{t('bookings.state_actions.approve_cancellation')}
						</ButtonProgress>
					</Grid>
					<Grid item>
						<Tooltip
							title={
								!hasReasonChanged
									? t('validation.table.modal.reject_button_tooltip')
									: ''
							}
						>
							<span>
								<ButtonProgress
									circularProgressSize={24}
									isLoading={isLoading}
									disabled={isLoading || !hasReasonChanged}
									variant="contained"
									color="secondary"
									type="submit"
									size="small"
								>
									{t('bookings.state_actions.reject')}
								</ButtonProgress>
							</span>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
FormContent.propTypes = {
	isLoading: bool,
	approve: func,
};
