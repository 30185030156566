export const ITEMS_ACCESSORS = {
	OPTIONS: 'options',
};

export const getItems = (values) => {
	const { discount } = values || {};

	let prices = [
		{
			label: 'bookings.edit_booking.summary.total_days',
			accessor: 'item.total_days',
		},
		{
			label: '',
			accessor: ITEMS_ACCESSORS.OPTIONS,
		},
		{
			label: 'bookings.edit_booking.summary.vat',
			accessor: 'item.total_discounted_vat_price',
			unitAccessor: 'item.total_discounted_unit',
		},
		{
			label: 'bookings.edit_booking.summary.total_price',
			accessor: 'item.total_discounted_gross_price',
			unitAccessor: 'item.total_discount_unit',
		},
	];

	if (discount?.used_discount)
		prices.splice(2, 0, {
			label: 'bookings.edit_booking.summary.promo_code',
			accessor: 'discount.used_discount',
		});

	return prices;
};
