import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	cell: {
		maxWidth: 80,
	},
	link: {
		textAlign: 'left',
	},
	companyNameTableCell: {
		maxWidth: 120,
	},
}));
