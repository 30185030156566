import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(4),
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		gap: '16px',
	},
	innerCardBox: {
		width: '100%',
		padding: '16px',
	},
	closeButton: {
		background: theme.palette.error.main,
		color: theme.palette.white,

		'&:hover': {
			background: theme.palette.error.main,
		},
	},
	collapseTitle: {
		[theme.breakpoints.down('md')]: {
			color: theme.palette.primary.main,
			fontSize: 14,
		},
	},
}));
