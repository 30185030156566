import React from 'react';

// Import components
import DateField from './DateField';

const DropOffDateField = () => {
	return (
		<DateField
			label="bookings.edit_booking.form.drop_off_date"
			name="period.drop_date"
		/>
	);
};

export default DropOffDateField;
