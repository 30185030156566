import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { SelectFieldAdapter, CheckboxFieldAdapter } from 'components/elements';

const ExternalSupplier = (props) => {
	const { className, section, ...rest } = props;
	const { submitting, values } = useFormState();
	const {
		external: { use_partner },
	} = values;

	const { t } = useTranslations();

	const { externalPartners } = useSelector((store) => store.company.form);

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('company.form.external_supplier.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							type="checkbox"
							component={CheckboxFieldAdapter}
							name={`${section}.use_partner`}
							disabled={submitting}
							labelText={t('company.form.external_supplier.has_external') || ''}
							color="primary"
						/>
					</Grid>
					{use_partner && (
						<Grid item md={4} xs={12}>
							<Field
								fullWidth
								component={SelectFieldAdapter}
								type="select"
								label={t('company.form.external_supplier.partner_name')}
								name={`${section}.partner_name`}
								margin="dense"
								variant="outlined"
								disabled={submitting}
								required
							>
								{externalPartners.map((externalPartner) => (
									<MenuItem
										key={externalPartner.name}
										value={externalPartner.name}
									>
										{externalPartner.name}
									</MenuItem>
								))}
							</Field>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

ExternalSupplier.propTypes = {
	className: string,
	section: string.isRequired,
};

export default ExternalSupplier;
