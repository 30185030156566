export const columns = [
    {
        header: 'bookings.booking_history.table.headers.id',
        accessor: 'id'
    },
    {
        header: 'bookings.booking_history.table.headers.message',
        accessor: 'message'
    },
    {
        header: 'bookings.booking_history.table.headers.user',
        accessor: 'author'
    },
    {
        header: 'bookings.booking_history.table.headers.date_time',
        accessor: 'created_at'
    }
]