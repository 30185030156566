import services from 'services/services';
import * as types from 'store/types';
import { format } from 'date-fns';
import { submitPublicHolidaysFormUrl } from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import { ADMINS, DEFAULT_DATE_FORMAT } from 'helpers';
import { INITIAL_VALUES } from 'views/PublicHolidays/components/PublicHolidaysForm/helpers';
import {
	getHolidays,
	getCompanyUuid,
	getCompanyProfile,
} from 'store/actions/helpers';

const {
	PUBLIC_HOLIDAYS_FORM_FETCH_DATA_LOADING,
	PUBLIC_HOLIDAYS_FORM_FETCH_DATA_SUCCESS,
	PUBLIC_HOLIDAYS_FORM_FETCH_DATA_ERROR,
} = types;

export const fetchPublicHolidaysFormResources =
	({ cancelToken, isAdmin, companyUuid }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: PUBLIC_HOLIDAYS_FORM_FETCH_DATA_LOADING,
			});

			const holidays = isAdmin
				? INITIAL_VALUES.holidays
				: await getHolidays({ uuid: null, isConverted: true, cancelToken });

			const resultData = {
				company_uuid: companyUuid,
				holidays,
			};

			dispatch({
				type: PUBLIC_HOLIDAYS_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: resultData,
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: PUBLIC_HOLIDAYS_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitPublicHolidaysForm =
	({ values, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			const companyProfile = await getCompanyProfile();
			const isAdmin = ADMINS.includes(companyProfile.manager.role);
			const companyUuid = isAdmin
				? values.company_uuid
				: await getCompanyUuid();

			const convertedHolidays = Object.entries(values.holidays).reduce(
				(acc, [name, { date_from, date_to, selected }]) => {
					if (!selected) return acc;

					const newValue = {
						date_from:
							date_from && format(new Date(date_from), DEFAULT_DATE_FORMAT),
						date_to:
							date_from && format(new Date(date_to), DEFAULT_DATE_FORMAT),
					};

					return { ...acc, [name]: newValue };
				},
				{}
			);

			const body = {
				public_holidays: convertedHolidays,
				company_uuids: [companyUuid],
			};

			await services.post(submitPublicHolidaysFormUrl, body);
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
