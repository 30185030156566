export const COLUMNS_ACCESSORS = {
	RATING: 'rating',
	EARNINGS: 'earnings',
	IS_SETTLED: 'is_settled',
	PICKUP_TIME: 'pickup_time',
	BOOKING_STATUS: 'booking_status',
	BOOKING_NUMBER: 'booking_number',
	SETTLED_ON: 'settled_on',
	NOTE: 'note',
};

export const columns = [
	// TODO Uncomment in the next iteration (Driver);

	// {
	// 	Header: 'employee_rewarding.table.headers.note',
	// 	accessor: COLUMNS_ACCESSORS.NOTE,
	// 	disableSortBy: true,
	// },
	{
		Header: 'employee_rewarding.table.headers.booking_number',
		accessor: COLUMNS_ACCESSORS.BOOKING_NUMBER,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.rating',
		accessor: COLUMNS_ACCESSORS.RATING,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.booking_status',
		accessor: COLUMNS_ACCESSORS.BOOKING_STATUS,
		disableSortBy: true,
	},

	{
		Header: 'employee_rewarding.table.headers.pickup_time',
		accessor: COLUMNS_ACCESSORS.PICKUP_TIME,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.earnings',
		accessor: COLUMNS_ACCESSORS.EARNINGS,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.is_settled',
		accessor: COLUMNS_ACCESSORS.IS_SETTLED,
		disableSortBy: true,
	},
	{
		Header: 'employee_rewarding.table.headers.settled_on',
		accessor: COLUMNS_ACCESSORS.SETTLED_ON,
		disableSortBy: true,
	},
];
