import React from 'react';
import { useParams } from 'react-router-dom';

// Import selectors and actions
import { fetchPromoCodeBookingList } from 'store/actions';

// Import components
import { TableExportButtons, TableExport } from 'components/elements';
import { TableExportProvider } from 'components/context';
import { ExportFilters } from '../../components';

// Import helpers
import { columns } from '../../columns';

export const Export = () => {
	const { id: coupon } = useParams();

	return (
		<TableExportProvider
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchPromoCodeBookingList({ ...options, itemId: coupon })
			}
		>
			<TableExportButtons />
			<TableExport renderFilters={() => <ExportFilters />} />
		</TableExportProvider>
	);
};
