import React from 'react';
import { useSelector } from 'react-redux';
import { object } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import selectors
import { customerCompensationSelector } from 'store/selectors';

const CustomerCompensationCheckedBy = ({ outerCompensation }) => {
	const { t } = useTranslations();

	const defaultCompensation = useSelector(customerCompensationSelector);
	const compensation = outerCompensation || defaultCompensation;

	return (
		<Grid container spacing={2}>
			<Grid container item xs={12}>
				{compensation?.compensation_author_name && (
					<Grid item xs={12}>
						<Typography color="primary">
							{t('bookings.customer_compensation.checked_by')}{' '}
							{compensation?.compensation_author_name}{' '}
							{compensation?.compensation_date}
						</Typography>
					</Grid>
				)}
				{compensation?.compensation_validated_by && (
					<Grid item xs={12}>
						<Typography color="primary">
							{t('bookings.customer_compensation.validated_by')}{' '}
							{compensation?.compensation_validated_by}{' '}
							{compensation?.compensation_validated_at}
						</Typography>
					</Grid>
				)}
				{compensation?.compensation_maker_author_name && (
					<Grid item xs={12}>
						<Typography color="primary">
							{t('bookings.customer_compensation.compensated_by')}{' '}
							{compensation?.compensation_maker_author_name}{' '}
							{compensation?.compensation_maker_date}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

CustomerCompensationCheckedBy.propTypes = {
	outerCompensation: object,
};

export default CustomerCompensationCheckedBy;
