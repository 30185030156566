import React from 'react';
import { object, bool } from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import { CalendarIcon } from 'assets/icons';

// Import components
import { InputError } from 'components/elements';

// Import styles
import useStyles from './styles';

const KeyboardDatePickerFieldAdapter = ({ input, meta, ...rest }) => {
	const classes = useStyles();

	const convertInputDate = (value) => {
		const [day, month, year] = value.split('/');
		const newDate = new Date(year, month - 1, day);
		return newDate;
	};

	const date =
		typeof input.value === 'string' && input.value.includes('/')
			? convertInputDate(input.value)
			: input.value;

	return (
		<Box className={classes.root}>
			<KeyboardDatePicker
				keyboardIcon={<CalendarIcon />}
				{...input}
				{...rest}
				value={date || null}
				format="dd/MM/yyyy"
			/>
			<InputError meta={meta} />
		</Box>
	);
};

KeyboardDatePickerFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	isDisabled: bool,
};

export default KeyboardDatePickerFieldAdapter;
