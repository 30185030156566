import React from 'react';
import { Field } from 'react-final-form';
import { array } from 'prop-types';
import { Checkbox, Grid, MenuItem } from '@material-ui/core';

// Import utilities
import { useExternalAuthCountriesSelect } from './useExternalAuthCountriesSelect';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';

export const ExternalAuthCountriesSelect = ({ options }) => {
	const {
		findCountryLabel,
		currentOptions,
		isSelectHidden,
		isExternalAuthCountriesRequired,
	} = useExternalAuthCountriesSelect({ options });

	if (isSelectHidden) return null;

	return (
		<Grid container item xs={12} md={4} alignItems="center">
			<Field
				label="contract.create_contract_form.contract_details.external_authorization_countries"
				name="authorization_details.external_authorization_countries"
				format={(value) => (Array.isArray(value) ? value : [])}
				required={isExternalAuthCountriesRequired}
				component={MultiselectFiledAdapter}
				labelextractor={findCountryLabel}
				variant="outlined"
				margin="dense"
				type="select"
				fullWidth
			>
				{options.map(({ id, name }) => (
					<MenuItem key={id} value={id}>
						<Checkbox checked={currentOptions?.includes(id)} color="primary" />
						{name}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

ExternalAuthCountriesSelect.propTypes = {
	options: array.isRequired,
};
