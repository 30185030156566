import React from 'react';
import { object, string } from 'prop-types';
import { FormControl } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';

// Import components
import { InputError } from 'components/elements';

const TimePickerFieldAdapter = ({ input, meta, ...rest }) => {
	const error = meta.touched && (meta.error || meta.submitError) ? true : false;
	return (
		<FormControl error={error}>
			<KeyboardTimePicker
				{...input}
				{...rest}
				KeyboardButtonProps={{
					'aria-label': 'change time',
				}}
			/>
			<InputError meta={meta} />
		</FormControl>
	);
};

TimePickerFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	className: string,
};

export default TimePickerFieldAdapter;
