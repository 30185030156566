import * as types from 'store/types';
import services from 'services/services';
import { fetchAirportUrl, submitAirportUrl } from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import { INITIAL_VALUES } from 'views/Airports/EditAirports/helpers';

// ******************** Fetch airports form ********************
export const fetchAirportsFormResources =
	({ itemId, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({ type: types.AIRPORTS_FORM_FETCH_DATA_LOADING });

			let result;
			if (itemId) {
				const { data } = await services.get(
					fetchAirportUrl(itemId),
					cancelToken
				);
				result = data.data;
			} else {
				result = Object.assign({}, INITIAL_VALUES);
			}
			dispatch({
				type: types.AIRPORTS_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: result || {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.AIRPORTS_FORM_FETCH_DATA_ERROR,
			});
		}
	};

// ******************** Submit airports form ********************
export const submitAirportsForm =
	({ values, itemId, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			await services.post(submitAirportUrl(itemId), values);
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
