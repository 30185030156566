import { useSelector } from 'react-redux';
import { BOOKING_URGENT_STATES } from 'helpers';
import { previewBookingSelector } from 'store/selectors';

// Import translation
import { useTranslations } from 'components/utilities';

const useUrgentAttentionWarningCard = () => {
	const { t } = useTranslations();

	const booking = useSelector(previewBookingSelector);

	const { REVIEW } = BOOKING_URGENT_STATES;

	const urgentStatus = booking?.urgentAssistance?.status;

	const isWarningVisible = urgentStatus === REVIEW;

	const warningMessage =
		t('bookings.preview_booking.urgent_attention.message') +
		' ' +
		t(`bookings.urgent_statuses.${urgentStatus}`);

	return {
		isWarningVisible,
		warningMessage,
	};
};

export default useUrgentAttentionWarningCard;
