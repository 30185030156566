import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CarInfoIcon = (props) => (
	<SvgIcon width="19" height="20" viewBox="0 0 19 20" fill="none" {...props}>
		<path
			d="M14.0521 18.4167C13.3866 18.4167 12.7361 18.2193 12.1828 17.8496C11.6295 17.4799 11.1983 16.9545 10.9436 16.3397C10.689 15.7249 10.6223 15.0484 10.7522 14.3957C10.882 13.743 11.2024 13.1435 11.673 12.673C12.1435 12.2024 12.743 11.882 13.3957 11.7522C14.0484 11.6223 14.7249 11.689 15.3397 11.9436C15.9545 12.1983 16.4799 12.6295 16.8496 13.1828C17.2193 13.7361 17.4167 14.3866 17.4167 15.0521C17.4167 15.9444 17.0622 16.8002 16.4312 17.4312C15.8002 18.0622 14.9444 18.4167 14.0521 18.4167"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0439 15.4082V16.3978"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0439 15.4082V16.3978"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0478 13.7059C14.0556 13.7067 14.0635 13.7052 14.0704 13.7016C14.0773 13.698 14.083 13.6924 14.0868 13.6855C14.0905 13.6786 14.0922 13.6708 14.0915 13.663C14.0908 13.6552 14.0878 13.6478 14.0829 13.6417C14.078 13.6356 14.0714 13.6311 14.0639 13.6288C14.0564 13.6264 14.0484 13.6264 14.0409 13.6286C14.0334 13.6308 14.0267 13.6351 14.0217 13.6412C14.0167 13.6472 14.0135 13.6545 14.0127 13.6623V13.6708C14.0137 13.6798 14.0177 13.6881 14.0241 13.6945C14.0304 13.7009 14.0388 13.7049 14.0478 13.7059"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.58956 9.08057H4.91211"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.3444 9.08057H11.667"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.1826 6.56431L15.8593 5.72559"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M4.07296 6.56431L2.39551 5.72559"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.39844 2.37158C5.61901 2.37166 4.94222 2.90837 4.76455 3.66729L4.05176 6.70856L4.76455 3.66729C4.94222 2.90837 5.61901 2.37166 6.39844 2.37158H11.8561C12.6354 2.37145 13.3121 2.90833 13.4892 3.66729L14.202 6.70856"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.8594 9.49978V8.8741C15.8594 7.63616 14.8568 6.63212 13.6189 6.63037H4.64003C4.04482 6.63016 3.47391 6.86646 3.05296 7.28726C2.63201 7.70807 2.39551 8.27889 2.39551 8.8741V12.6678C2.39551 13.1052 2.7501 13.4598 3.1875 13.4598H4.95841C5.39582 13.4598 5.75041 13.1052 5.75041 12.6678V11.5962H9.50051"
			stroke="currentColor"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
