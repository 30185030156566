import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { object } from 'prop-types';
import { TableCell, Link } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { replaceCarsUrl, replaceLeasingCarsUrl } from 'helpers';

// Import styles
import { useStyles } from './CarsTableCell.styles';

export const CarsTableCell = ({ cell }) => {
	const { uuid, offers_leasing_cars: offersLeasingCars } = cell.row.original;
	const { t } = useTranslations();
	const classes = useStyles();

	const carsLink = replaceCarsUrl(uuid);
	const leasingCarsLink = replaceLeasingCarsUrl(uuid);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Link
				className={classes.link}
				component={RouterLink}
				to={carsLink}
				variant="h6"
			>
				<DriveEtaIcon className={classes.icon} fontSize="small" />
				{t('offices.table.headers.cars')}
			</Link>
			{offersLeasingCars && (
				<Link
					className={classes.link}
					component={RouterLink}
					to={leasingCarsLink}
					variant="h6"
				>
					{t('offices.table.headers.leasing_cars')}
				</Link>
			)}
		</TableCell>
	);
};

CarsTableCell.propTypes = {
	cell: object.isRequired,
};
