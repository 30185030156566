import React from 'react';
import { object } from 'prop-types';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Import styles
import useStyles from './styles';

// Import components
import { InputError } from 'components/elements';

const DraftWysiwygFieldAdapter = ({ input, meta, ...rest }) => {
	const classes = useStyles();

	const setInitialValue = () => {
		if (input.value) {
			const blocksFromHtml = htmlToDraft(input.value);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(
				contentBlocks,
				entityMap
			);
			return EditorState.createWithContent(contentState);
		} else {
			return EditorState.createEmpty();
		}
	};

	const [editorState, setEditorState] = React.useState(setInitialValue());

	const onEditorStateChange = (editorState) => {
		setEditorState(editorState);
		input.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
	};

	const error = meta.touched && (meta.error || meta.submitError) ? true : false;

	return (
		<div className={classes.wrapper}>
			<Editor
				spellCheck
				editorState={editorState}
				wrapperClassName={classes.wrapperClassName}
				editorClassName={
					error ? classes.editorErrorClassName : classes.editorClassName
				}
				onEditorStateChange={onEditorStateChange}
				{...rest}
			/>
			<InputError meta={meta} />
		</div>
	);
};

DraftWysiwygFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
};

export default DraftWysiwygFieldAdapter;
