import * as types from 'store/types';
import produce from 'immer';

// import helpers
import { initialValues } from 'store/actions/profile/helpers';

const INITIAL_STATE = {
	item: initialValues,
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.PROFILE_FORM_FETCH_DATA_LOADING:
				return INITIAL_STATE;

			case types.PROFILE_FORM_FETCH_DATA_SUCCESS:
				draft.item = action.payload || {};
				draft.isLoaded = true;
				break;

			case types.PROFILE_FORM_FETCH_DATA_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				draft.error = action.payload;
				break;

			default:
				return state;
		}
	});
