import React from 'react';
import { bool, arrayOf, string } from 'prop-types';
import { Field } from 'react-final-form';
import { Box, Grid } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

const AdditionalFields = ({ submitting, fields }) => {
	const { t } = useTranslations();

	// eslint-disable-next-line
	const renderItem = (field) => (
		<Grid key={field} item md={3}>
			<Field
				component={CheckboxFieldAdapter}
				disabled={submitting}
				labelText={t(`bookings.additional_info.${field}`)}
				type="checkbox"
				color="primary"
				name={field}
			/>
		</Grid>
	);

	return (
		<Box paddingY={3} paddingX={2}>
			<Grid container spacing={3}>
				{fields.map(renderItem)}
			</Grid>
		</Box>
	);
};

AdditionalFields.propTypes = {
	submitting: bool.isRequired,
	fields: arrayOf(string),
};

export default AdditionalFields;
