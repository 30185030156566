import React from 'react';
import { object, bool, string } from 'prop-types';
import clsx from 'clsx';
import { TextField, Typography as MuiTypography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// Import styles
import useStyles from './styles';

const TextFieldLabelAdapter = ({
	input,
	meta,
	isDisabled = false,
	isBorder = true,
	labelWidth = '105px',
	className,
	...rest
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const { touched, error, submitError } = meta;

	const isError = touched && (error || submitError) ? true : false;
	const errorText =
		touched && (error || submitError) ? t(error) || submitError : '';

	const inputDisabledClass = `${isDisabled ? classes.disabledInput : ''}`;
	const inputBorderClass = isBorder ? '' : classes.borderInput;

	return (
		<div className={classes.root}>
			<MuiTypography
				style={{ minWidth: labelWidth }}
				component="p"
				className={classes.label}
			>
				{rest.label}
			</MuiTypography>
			<TextField
				{...input}
				{...rest}
				className={clsx(
					inputDisabledClass,
					inputBorderClass,
					classes.input,
					className
				)}
				error={isError}
				helperText={errorText}
			/>
		</div>
	);
};

TextFieldLabelAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	isDisabled: bool,
	isBorder: bool,
	labelWidth: string,
	className: string,
};

export default TextFieldLabelAdapter;
