import React, { useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { fetchCustomerFormResources, submitCustomerForm } from 'store/actions';
import { queryCache } from 'react-query';
import { useLastLocation } from 'react-router-last-location';

// Import helpers
import { INITIAL_VALUES, QUERY_KEYS } from '../helpers';
import { URLS } from 'components/routes';

// Import utilities
import { FormRPC } from 'components/utilities';

// Import components
import { BaseDetails, WorkplaceInfo, DriverLicense } from './components';

// Import styles
import useStyles from './styles';

export const EditCustomer = () => {
	const classes = useStyles();

	const lastLocation = useLastLocation();

	useEffect(() => {
		return () => {
			queryCache.removeQueries((query) => !QUERY_KEYS.includes(query));
		};
		//eslint-disable-next-line
	}, []);

	const goBackPath = lastLocation?.pathname || URLS.customersUrl;

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchCustomerFormResources}
				submitFormAction={submitCustomerForm}
				isCardLayout={false}
				goBackPath={goBackPath}
				store={(store) => store.customers.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<BaseDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<WorkplaceInfo section="workplace" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<DriverLicense section="details.license" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
