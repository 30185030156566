// Import helpers
import { FIELD_TYPES } from 'helpers';

export const CONFIRMATION_STEP_FIELDS = (prefixes) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.phone_prefix',
		name: 'prefixes',
		keyExtractor: (prefix) => prefix,
		labelExtractor: (prefix) => prefix,
		options: prefixes,
		required: true,
		xs: 3,
		md: 3,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.phone_number',
		name: 'phone',
		required: true,
		xs: 9,
		md: 9,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.national_or_iqama_id',
		name: 'license_number',
		required: true,
	},
];
