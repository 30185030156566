import React, { useState } from 'react';
import { Form } from 'react-final-form';

// Import components
import { FormContent } from './components';

// Import utils and helpers
import { useAddCorporateAndCoordinatorProvider } from '../../context';
import { ADD_CORPORATE_AND_COORDINATOR_PANEL_STEPS } from 'helpers';
import { useMessage, useTranslations } from 'components/utilities';
import { INITIAL_VALUES } from './helpers';

// Import store
import { createCorporate } from 'store/actions';

export const AddCorporateStep = () => {
	const { setStep, setCorporateUuid } = useAddCorporateAndCoordinatorProvider();
	const [isButtonLoading, setButtonLoading] = useState(false);
	const { t } = useTranslations();
	const { message } = useMessage();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setButtonLoading(true);

		await createCorporate({
			values,
			successCallback: (corporateUuid) => {
				message.success(t('common.messages.successfully_created'));
				setStep(ADD_CORPORATE_AND_COORDINATOR_PANEL_STEPS.ADD_COORDINATOR);
				setButtonLoading(false);
				setCorporateUuid(corporateUuid);
			},
			errorCallback: (error) => {
				if (error.response) {
					const errors = error.response.data.errors;
					apiErrors = errors;
				}
				message.error(t('common.messages.error_message'));
				setButtonLoading(false);
			},
		});

		return apiErrors;
	};

	return (
		<Form
			initialValues={INITIAL_VALUES}
			onSubmit={handleOnSubmit}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<FormContent isButtonLoading={isButtonLoading} />
				</form>
			)}
		/>
	);
};
