import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

// Import helpers
import { SCRATCH_TYPES } from 'helpers';
import { scratches } from './helpers';

export const useCarScratches = () => {
	const [scratchType, setScratchType] = useState(SCRATCH_TYPES.SMALL_SCRATCH);

	const [markers, setMarkers] = useState([]);
	const [counter, setCounter] = useState(0);

	const { change } = useForm();

	const handleOnAddMarker = ({ top, left }) => {
		const newMarker = {
			left: left - 4,
			top: top - 2.5,
			type: scratchType,
			icon: getIcon(scratchType),
			color: getColor(scratchType),
			counter,
		};

		setMarkers((prev) => [...prev, newMarker]);
		setCounter((prev) => (prev += 1));
	};

	const getIcon = (currentType) =>
		scratches.find(({ type }) => type === currentType).icon;

	const getColor = (currentType) =>
		scratches.find(({ type }) => type === currentType).color;

	useEffect(() => {
		change('rent_status.sketch_info', markers);
		// eslint-disable-next-line
	}, [markers]);

	return {
		handleOnAddMarker,
		scratchType,
		setScratchType,
		markers,
		setMarkers,
		getIcon,
		getColor,
	};
};
