import { useState } from 'react';
import { useParams } from 'react-router-dom';
import services from 'services/services';

// Import store
import { submitExternalPartnerCarsListUrl } from 'store/paths';

// Import utils and helpers
import { useMessage, useTranslations } from 'components/utilities';
import { getChangedOffices } from './helpers';
import { useCompanyOffices } from 'queries';

export const useExternalPartnersCard = () => {
	const [storedFormValues, setStoredFormValues] = useState({ offices: {} });
	const [isButtonLoading, setButtonLoading] = useState(false);
	const { id: companyUuid } = useParams();
	const { message } = useMessage();
	const { t } = useTranslations();

	const {
		data: offices,
		isError: officesError,
		isFetching: officesFetching,
	} = useCompanyOffices(companyUuid);

	const handleOnSubmit = (values) => {
		setButtonLoading(true);

		const changedOffices = getChangedOffices({ values, storedFormValues });
		const convertedValues = Object.entries(values.offices);
		const filteredValues = convertedValues.filter(([office_uuid]) =>
			changedOffices.includes(office_uuid)
		);

		filteredValues.forEach(async ([office_uuid, { office_cars }], index) => {
			try {
				const mappedOfficeCars = office_cars.map(
					({ uuid, external_partner_car_ids }) => ({
						external_partner_car_ids,
						office_car_uuid: uuid,
					})
				);

				await services.post(submitExternalPartnerCarsListUrl(office_uuid), {
					external_partner_cars_mapping: mappedOfficeCars,
				});

				if (filteredValues.length - 1 <= index) {
					setButtonLoading(false);
					message.success(t('common.messages.successfully_saved'));
					setStoredFormValues(values);
				}
			} catch {
				setButtonLoading(false);
				message.error(t('common.messages.error_message'));
			}
		});
	};

	return {
		handleOnSubmit,
		officesFetching,
		officesError,
		offices,
		isButtonLoading,
	};
};
