import React from 'react';

// Import store
import { fetchTrainStationsList } from 'store/actions';

// Import utils and helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';
import { TOOLBAR_CONTENT } from './helpers';

// Import components
import { TableBodyRow } from './components';

export const TrainStations = () => (
	<TableRPC
		fetchDataTableAction={fetchTrainStationsList}
		columnsAccessor={COLUMNS_ACCESSORS}
		toolBarContent={TOOLBAR_CONTENT}
		tableBodyRow={TableBodyRow}
		columns={columns}
	/>
);
