// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

// Import components
import { Legend, Filters } from './components';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Legend,
		customKey: 'legend'
	},
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Filters,
		customKey: 'filters'
	}
];
