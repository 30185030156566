import React from 'react';
import { Card, Box, Typography, CircularProgress } from '@material-ui/core';
import { bool } from 'prop-types';

// Import utilities
import { useSubmitFormContent } from './useSubmitFormContent';
import { useTranslations } from 'components/utilities';

// Import components
import { OTPInputs, Signature } from '../../components';
import { ButtonProgress } from 'components/elements';

// Import styles
import { useStyles } from './SubmitFormContent.styles';

export const SubmitFormContent = ({ isLoading }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { contractDataFetching, resendOTP, isOtpLoading, isOtpRequired } =
		useSubmitFormContent();

	return (
		<Box className={classes.container}>
			<Card className={classes.card}>
				{contractDataFetching ? (
					<CircularProgress />
				) : (
					<>
						{isOtpRequired && <OTPInputs />}
						<Signature />
						{isOtpRequired && (
							<Box className={classes.resendOtpWrapper}>
								<Typography className={classes.subtitle}>
									{t(
										'contract.create_contract_form.submit_step.no_received_otp'
									)}
								</Typography>
								<ButtonProgress
									color="primary"
									onClick={resendOTP}
									disabled={isOtpLoading}
									isLoading={isOtpLoading}
									circularProgressSize={28}
								>
									{t('contract.create_contract_form.submit_step.resend_again')}
								</ButtonProgress>
							</Box>
						)}
					</>
				)}
			</Card>
			<ButtonProgress
				variant="contained"
				color="primary"
				type="submit"
				isLoading={isLoading}
				disabled={isLoading}
				circularProgressSize={28}
			>
				{t('common.buttons.confirm')}
			</ButtonProgress>
		</Box>
	);
};

SubmitFormContent.propTypes = {
	isLoading: bool,
};
