import React from 'react';
import { bool, number, string } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import { get } from 'lodash';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import queries
import { useAirportList } from 'queries';

export const AirportFilter = ({
	label,
	name,
	dependName,
	dependValue,
	isRequired = false,
	md = 3,
	showAllOption = true,
}) => {
	const { submitting, values } = useFormState();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data } = useAirportList();

	if (!data || (dependName && get(values, dependName) !== dependValue)) {
		return null;
	}

	return (
		<Grid item md={md}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
				required={isRequired}
			>
				{showAllOption && <MenuItem value="all">{t('common.all')}</MenuItem>}
				{data.map(({ uuid, name }) => (
					<MenuItem key={uuid} value={uuid}>
						{name[language]}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

AirportFilter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	dependName: string,
	dependValue: string,
	md: number,
	isRequired: bool,
	showAllOption: bool,
};
