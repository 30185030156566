export const CAR_DETAILS = (booking) => [
	[
		{
			label: 'bookings.invoice_print.car_details.car_manufacturer',
			value: booking.item.vehicle_maker_name,
		},
		{
			label: 'bookings.invoice_print.car_details.car_model',
			value: booking.item.vehicle_model_name,
		},
		{
			label: 'bookings.invoice_print.car_details.car_year',
			value: booking.item.vehicle_year,
		},
		{
			label: 'bookings.invoice_print.car_details.company_name',
			value: booking.company.name,
		},
	],
];

export const CUSTOMER_DETAILS = (booking) => [
	[
		{
			label: 'bookings.invoice_print.customer_details.customer_name',
			value: booking.customer.full_name,
		},
		{
			label: 'bookings.invoice_print.customer_details.customer_phone',
			value: `${booking.customer.phone_prefix} ${booking?.customer.phone}`,
		},
		{
			label: 'bookings.invoice_print.customer_details.customer_type',
			value: booking.customer.type,
		},
		{
			label: 'bookings.invoice_print.customer_details.birth_date',
			value: booking.customer.birth_date,
		},
		{
			label: 'bookings.invoice_print.customer_details.customer_id',
			value: booking.customer.license_number,
		},
	],
];

export const BOOKING_DETAILS = (booking, conditionalText) => [
	[
		{
			label: 'bookings.invoice_print.booking_details.booking_number',
			value: booking.individual_number,
		},
		{
			label: 'bookings.invoice_print.booking_details.booking_status',
			value: booking.state,
			colored: true,
		},
		{
			label: 'bookings.table.headers.company_name',
			value: booking.office.name,
		},
		{
			label: 'bookings.invoice_print.booking_details.office_phone',
			value: booking.office.contact_phone,
		},
	],
	[
		{
			label: 'bookings.invoice_print.booking_details.pick_up_time',
			value: booking.period.pick_date,
		},
		{
			label: 'bookings.invoice_print.booking_details.drop_off_date',
			value: booking.period.drop_date,
		},
		{
			label: 'bookings.invoice_print.booking_details.pick_up_delivery',
			value: conditionalText,
		},
		{
			label: 'bookings.invoice_print.booking_details.no_of_days',
			value: booking.period.days,
		},
	],
];
