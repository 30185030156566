import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import components
import {
	PickUpDateField,
	DropOffDateField,
	CarDetails,
	PickUpTimeField,
	PriceSummary,
	OptionsField,
} from './components';
import { DefaultTextField } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import useEditBookingInfo from './useEditBookingInfo';

// Import styles
import { useStyles } from './styles';

const EditBookingInfo = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		initialHistoryValues,
		currentValues,
		priceDifference,
		showPrice,
		isHistory,
		historyFetching,
	} = useEditBookingInfo();

	const { values } = useFormState();

	return (
		<>
			<DefaultTextField
				label={t('bookings.edit_booking.form.car')}
				value={values.item.name}
			/>

			<PickUpDateField />

			<DropOffDateField />

			<PickUpTimeField />

			<DefaultTextField
				label={t('bookings.edit_booking.form.period')}
				value={t('bookings.edit_booking.form.period_value', {
					days: values.period.days,
				})}
			/>

			<DefaultTextField
				label={t('bookings.edit_booking.form.location')}
				value={`${values.pick.address}`}
			/>

			<CarDetails />

			<OptionsField />

			<Grid container className={classes.amountContainer}>
				{historyFetching ? (
					<CircularProgress />
				) : (
					<Grid container item spacing={2}>
						<Grid item xs={6}>
							{isHistory && (
								<PriceSummary
									values={{
										...initialHistoryValues,
										discount: currentValues?.discount,
									}}
									title={t('bookings.edit_booking.form.init_amount')}
								/>
							)}
						</Grid>
						<Grid item xs={isHistory ? 6 : 12}>
							<PriceSummary
								values={currentValues}
								title={t(
									`bookings.edit_booking.form.${
										isHistory ? 'current_amount' : 'init_amount'
									}`
								)}
								difference={priceDifference}
								showPrice={showPrice}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default EditBookingInfo;
