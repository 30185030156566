export const COLUMNS_ACCESSORS = {
	EXPAND: 'expand',
	SELECT: 'select',
	ACTIVE: 'employee_is_active',
	RATING: 'average_rating',
	HISTORY: 'history',
	SETTLED: 'settled',
	BOOKING_ID: 'booking_individual_number',
	ROLE: 'user_role',
	EARNINGS: 'earnings',
	TOTAL_EARNINGS: 'total_earnings',
};

export const columns = [
	{
		Header: '',
		id: COLUMNS_ACCESSORS.EXPAND,
		accessor: COLUMNS_ACCESSORS.EXPAND,
		disableSortBy: true,
	},
	{
		Header: '',
		id: COLUMNS_ACCESSORS.SELECT,
		accessor: COLUMNS_ACCESSORS.SELECT,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
		disableSortBy: true,
	},
	{
		Header: 'rewards_settlement.table.headers.employee_name',
		accessor: 'user_name',
		disableSortBy: true,
	},
	{
		Header: 'rewards_settlement.table.headers.role',
		accessor: COLUMNS_ACCESSORS.ROLE,
		disableSortBy: true,
	},
	{
		Header: 'rewards_settlement.table.headers.employee_status',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'rewards_settlement.table.headers.num_of_orders',
		accessor: 'items_count',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'rewards_settlement.table.headers.rating',
		accessor: 'average_rating',
		disableSortBy: true,
	},
	{
		Header: 'rewards_settlement.table.headers.earnings',
		accessor: 'earnings',
		disableSortBy: true,
	},
	{
		Header: 'rewards_settlement.table.headers.total_earnings',
		accessor: 'total_earnings',
		disableSortBy: true,
	},
	{
		Header: 'rewards_settlement.table.headers.settled',
		accessor: COLUMNS_ACCESSORS.SETTLED,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.action',
		accessor: COLUMNS_ACCESSORS.HISTORY,
		disableSortBy: true,
	},
];
