import { FIELD_TYPES } from 'helpers';

export const EDIT_CUSTOMER_FIELDS = ({ prefixes, handleOnFileUploader }) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.name',
		name: `name`,
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.phone_prefix',
		name: 'prefix',
		required: true,
		options: prefixes,
		keyExtractor: (value) => value,
		labelExtractor: (value) => value,
		xs: 3,
		md: 2,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.phone_number',
		name: 'phone',
		required: true,
		disabled: true,
		xs: 9,
		md: 4,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.email',
		name: 'email',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.DATE,
		label: 'common.fields.birth_date',
		name: 'birth_date',
		required: true,
		minDate: false,
		md: 6,
	},
	{
		type: FIELD_TYPES.DATE,
		label: 'common.fields.expiration_date',
		name: 'license_expire_date',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.national_or_iqama_id',
		name: 'license_number',
		md: 6,
	},
	{
		type: FIELD_TYPES.FILE_UPLOADER,
		label: 'common.fields.driver_license',
		required: true,
		handleOnFileUploader,
		md: 6,
	},
];
