import React from 'react';
import { object, node } from 'prop-types';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SelectFieldAdapter = ({ input, meta, children, ...rest }) => {
	const { t } = useTranslation();

	return (
		<TextField
			select
			{...input}
			{...rest}
			error={meta.touched && (meta.error || meta.submitError) ? true : false}
			helperText={
				meta.touched && (meta.error || meta.submitError)
					? t(meta.error) || meta.submitError
					: ''
			}
		>
			{children}
		</TextField>
	);
};

SelectFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	children: node.isRequired,
};

export default SelectFieldAdapter;
