export const companyCarsAvailabilityUrl = (queryParams = '') =>
	`v2/supplier/operation/company/car-availability${queryParams}`;

export const officeCarsAvailabilityUrl = (queryParams = '') =>
	`v2/supplier/operation/office/car-availability${queryParams}`;

export const activateCarsUrl = (isActive) =>
	`v2/supplier/business/cars/${isActive ? 'deactivate' : 'activate'}`;

export const updateOfficeCarQuantityUrl = (carUuid) =>
	`v2/supplier/business/car/${carUuid}/stock`;
