import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	paymentMethod: {
		textTransform: 'capitalize',
		textAlign: 'center',
	},
	actionButton: {
		textAlign: 'right',
	},
}));
