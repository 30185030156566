import { makeStyles } from '@material-ui/styles';

export const FILEDS_OFFICE_DETAILS = [
	{
		name: 'details.name.ar',
		label: 'offices.details.office_name',
	},
	{
		name: 'details.name.en',
		label: 'offices.details.english_name',
	},
	{
		name: 'details.person_name',
		label: 'offices.details.person_name',
	},
	{
		name: 'details.public_contact_number',
		label: 'offices.details.public_number',
	},
	{
		name: 'details.contact_number',
		label: 'offices.details.number',
	},
	{
		name: 'location.address',
		label: 'offices.details.address',
	},
	{
		name: 'location.city',
		label: 'offices.details.city',
	},
	{
		name: 'location.country',
		label: 'offices.details.country',
	},
	{
		name: 'commercial_record.number',
		label: 'offices.details.commercial_number',
	},
];

export const FILEDS_OFFICE_BANK_INFO = [
	{
		name: 'bank.bank_name',
		label: 'offices.form.bank_info.bank_name',
	},
	{
		name: 'bank.name',
		label: 'offices.form.bank_info.name',
	},
	{
		name: 'bank.iban_number',
		label: 'offices.form.bank_info.iban_number',
	},
	{
		name: 'bank.number',
		label: 'offices.form.bank_info.account_number',
	},
];

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		minHeight: '300px',
	},
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),
		minWidth: '105px',
		display: 'inline-block',
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 9999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));
