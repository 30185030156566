import React from 'react';
import _ from 'lodash';

// Import components
import { SubHeader } from 'views/CarsAvailability/components';

const getAllCars = (data) =>
	data.reduce((acc, company) => {
		const cars = company.cars;
		return [...acc, ...cars];
	}, []);

const generateColumns = (data, columns) => {
	const allCars = getAllCars(data);
	const groupedColumns = _.groupBy(allCars, 'vehicle_uuid');

	const updatedColumns = columns.reduce((columnsAcc, column) => {
		const { vehicle_uuid } = column;

		const cars = groupedColumns[vehicle_uuid];

		const isSomeActive = cars.some(({ is_active }) => is_active);

		const subHeader = cars.reduce((carsAcc, car) => {
			if (isSomeActive) {
				if (car.is_active) {
					return carsAcc + Number(car.quantity);
				} else {
					return carsAcc;
				}
			} else {
				return carsAcc + Number(car.quantity);
			}
		}, 0);

		const convertedColumn = { ...column, subHeader };

		return [...columnsAcc, convertedColumn];
	}, []);

	return updatedColumns;
};

export const getColumns = (data) => {
	const columns = data.reduce((columnsAcc, company) => {
		const cars = company.cars;

		const newColumns = cars.map(({ vehicle_uuid, model_name, year }) => ({
			Header: `${model_name} ${year}`,
			accessor: vehicle_uuid,
			disableSortBy: true,
			isVisible: true,
			subHeader: null,
			renderSubHeader,
			vehicle_uuid,
		}));

		const convertedAcc = columnsAcc.map(({ vehicle_uuid }) => vehicle_uuid);
		const convertedColumns = _.uniqBy(newColumns, 'vehicle_uuid').filter(
			(newColumn) => !convertedAcc.includes(newColumn.vehicle_uuid) && newColumn
		);

		return [...columnsAcc, ...convertedColumns];
	}, []);

	const updatedColumns = generateColumns(data, columns);

	return updatedColumns;
};

export const getExportData = (data, isOfficeLevel) =>
	data.map(({ company_name, name, cars }) => {
		const getUniqueCars = (cars) => {
			const groupedCars = _.groupBy(cars, 'vehicle_uuid');
			const groups = Object.values(groupedCars);

			return groups.reduce((acc, group) => {
				const isSomeActive = group.some(({ is_active }) => is_active);

				const quantity = group.reduce((acc, car) => {
					if (isSomeActive) {
						if (car.is_active) {
							return acc + Number(car.quantity);
						} else {
							return acc;
						}
					} else {
						return acc + Number(car.quantity);
					}
				}, 0);
				const convertedGroup = { ...group[0], quantity };

				return [...acc, convertedGroup];
			}, []);
		};

		const convertedCars = getUniqueCars(cars).reduce(
			(acc, { quantity, vehicle_uuid }) => {
				const convertedCar = {
					[vehicle_uuid]: quantity,
				};

				return { ...acc, ...convertedCar };
			},
			{}
		);

		return {
			base_column: isOfficeLevel ? name : company_name,
			...convertedCars,
		};
	});

export const renderSubHeader = (value) => <SubHeader value={value} />;
