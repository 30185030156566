import React from 'react';
import { Field } from 'react-final-form';
import { bool, func } from 'prop-types';
import { CircularProgress, MenuItem, Button, Grid } from '@material-ui/core';

// Import components
import {
	SelectFieldAdapter,
	TextFieldAdapter,
	ButtonProgress,
	Alert,
} from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import helpers
import { capitalize } from 'helpers';

export const FormContent = ({ toggle, isLoading }) => {
	const { t } = useTranslations();

	const { reasons, submitting, isDescriptionInputVisible, isFetched, isError } =
		useFormContent();

	if (isError) {
		return <Alert title={t('common.messages.error_message')} />;
	}

	return (
		<Grid container direction="column" spacing={1}>
			<Grid container item spacing={1}>
				<Grid item xs>
					{!isFetched ? (
						<CircularProgress />
					) : (
						<Field
							label={t(
								'customers.preview.wallet.clear_dialog.clear_wallet_reason'
							)}
							component={SelectFieldAdapter}
							fullWidth
							name="reason_uuid"
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required
						>
							{reasons.map(({ uuid, description }) => (
								<MenuItem key={uuid} value={uuid}>
									{capitalize(description)}
								</MenuItem>
							))}
						</Field>
					)}
				</Grid>
				{isDescriptionInputVisible && (
					<Grid item xs>
						<Field
							label={t('customers.preview.wallet.description')}
							component={TextFieldAdapter}
							fullWidth
							name="description"
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<Field
						label={t('customers.preview.wallet.clear_dialog.clear_amount')}
						component={TextFieldAdapter}
						fullWidth
						name="amount"
						type="number"
						margin="dense"
						variant="outlined"
						disabled={submitting}
						required
					/>
				</Grid>
			</Grid>
			<Grid container item justifyContent="flex-end" spacing={1}>
				<Grid item>
					<Button variant="outlined" color="primary" onClick={toggle}>
						{t('common.buttons.close')}
					</Button>
				</Grid>
				<Grid item>
					<ButtonProgress
						circularProgressSize={24}
						isLoading={isLoading}
						disabled={isLoading}
						variant="contained"
						color="primary"
						type="submit"
					>
						{t('common.buttons.clear')}
					</ButtonProgress>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	toggle: func,
	isLoading: bool,
};
