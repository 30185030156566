import { useLocation, useParams } from 'react-router-dom';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import BusinessIcon from '@material-ui/icons/Business';

// Import utils and helpers
import { useProfile, useToggle } from 'components/utilities';
import {
	replaceCompanyOfficesUrl,
	replaceExternalPartnersListUrl,
} from 'helpers';
import { useCompanyDetails } from 'queries';

export const useActionButtonsToolbar = () => {
	const { on: isOpen, toggle: toggleEditPriceDialog } = useToggle();
	const { isAdmin, isCompanyManager } = useProfile();
	const { id: companyUuid } = useParams();
	const { pathname } = useLocation();

	const actionButtons = [];

	const isCompanyOfficesView =
		pathname === replaceCompanyOfficesUrl(companyUuid);

	const { data } = useCompanyDetails(companyUuid, isCompanyOfficesView);

	const hasPartner = !!data?.external?.use_partner;

	const showMapExternalPartnerButton =
		isAdmin && isCompanyOfficesView && hasPartner;

	const uuidParam = companyUuid ? `/${companyUuid}` : '';

	if (showMapExternalPartnerButton) {
		actionButtons.push({
			to: replaceExternalPartnersListUrl(companyUuid),
			icon: DriveEtaIcon,
			text: 'common.buttons.map_external_partner',
		});
	}

	if (!!companyUuid || isCompanyManager) {
		actionButtons.push(
			...[
				{
					to: `/offices/cars/edit${uuidParam}`,
					icon: DriveEtaIcon,
					text: 'common.buttons.edit_cars',
				},
				{
					to: `/offices/car/add${uuidParam}`,
					icon: DriveEtaIcon,
					text: 'common.buttons.add_car',
				},
			]
		);
	}

	if (isAdmin) {
		actionButtons.push({
			to: '/offices/create',
			icon: BusinessIcon,
			text: 'common.buttons.add_office',
		});
	}

	if (isCompanyOfficesView) {
		actionButtons.push({
			text: 'common.buttons.edit_prices',
			isLink: false,
			onClick: toggleEditPriceDialog,
		});
	}

	return { actionButtons, isOpen, toggleEditPriceDialog };
};
