import { useState } from 'react';

const useToggle = (initialState = false) => {
	const [on, setOn] = useState(initialState);

	const toggle = () => {
		setOn((prev) => !prev);
	};

	return { on, toggle };
};

export default useToggle;
