import React from 'react';
import { useLocation } from 'react-router-dom';
import { Chip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import utils and helpers
import { useProfile, useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import store
import { profileDataSelector } from 'store/selectors';

// Import styles
import { useStyles } from './CorporateBalance.styles';

export const CorporateBalance = () => {
	const { corporate } = useSelector(profileDataSelector);
	const { isCorporateCoordinator } = useProfile();
	const classes = useStyles({ isOkay: true });
	const { pathname } = useLocation();
	const { t } = useTranslations();

	const {
		remaining_corporate_limit: corporateLimit,
		coordinator_booking_limit: coordinatorLimit,
	} = corporate || {};

	if (!isCorporateCoordinator || pathname !== URLS.corporateOverviewUrl) {
		return null;
	}

	return (
		<Chip
			className={classes.chip}
			label={
				<Typography className={classes.label}>
					{t('b2b.corporate.overview.balance', { value: corporateLimit })}{' '}
					<Typography className={classes.strong} component="strong">
						{t('b2b.corporate.overview.limit', { value: coordinatorLimit })}
					</Typography>
				</Typography>
			}
		/>
	);
};
