import { useForm, useFormState } from 'react-final-form';
import _ from 'lodash';

export const useRateModel = () => {
	const { values } = useFormState();
	const { change } = useForm();

	const priceOptions = values?.prices;
	const ratePrices = priceOptions?.base_rent?.months;
	const isLeasingSellActive = priceOptions?.leasing_sell_price?.is_active;

	const leasingCheckboxName = 'prices.leasing_sell_price.is_active';
	const leasingSellPriceName = 'prices.leasing_sell_price.one_time';

	const getCards = () => {
		const filteredPrices = _.omit(ratePrices, 'm1');

		return Object.entries(filteredPrices).map(([period]) => ({
			label: `leasing_cars.form.rates_model.${period}_months_heading`,
			name: `prices.base_rent.months.${period}.price`,
			disabled: false,
			required: true,
		}));
	};

	const leasingRateCards = getCards();

	const depositCard = {
		label: `leasing_cars.form.rates_model.security_deposit`,
		name: `prices.security_deposit.one_time`,
		disabled: false,
		required: true,
	};

	const handleOnActiveCheckboxChange = (_, checked) => {
		change(leasingCheckboxName, checked);
		!checked && change(leasingSellPriceName, '');
	};

	return {
		onActiveCheckboxChange: handleOnActiveCheckboxChange,
		leasingCheckboxName,
		leasingSellPriceName,
		leasingRateCards,
		depositCard,
		isLeasingSellActive,
	};
};
