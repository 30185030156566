import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

// Import store
import { fetchCorporateOverview } from 'store/actions';
import { profileDataSelector } from 'store/selectors';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';
import { columns } from './columns';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow } from './components';

export const CorporateOverview = () => {
	const classes = useBaseLayoutStyles();

	const { corporate } = useSelector(profileDataSelector);

	const { uuid: corporateUuid } = corporate || {};

	return (
		<TableProvider
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchCorporateOverview({ ...options, corporateUuid })
			}
		>
			<Box className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT()} />
				<Box className={classes.content}>
					<Table isDefaultRow={false}>
						{({ row }) => <TableBodyRow key={row.id} row={row} />}
					</Table>
				</Box>
			</Box>
		</TableProvider>
	);
};
