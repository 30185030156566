import React from 'react';
import { object, string } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import components
import { LinkTableCell } from 'components/elements';

export const CustomerNameTableCell = ({
	customerName,
	phoneNumber,
	link,
	cell,
}) => {
	// eslint-disable-next-line react/prop-types
	const Container = ({ children }) =>
		link ? (
			<LinkTableCell cell={cell} to={link} {...cell.getCellProps()}>
				{children}
			</LinkTableCell>
		) : (
			<TableCell {...cell.getCellProps()}>{children}</TableCell>
		);

	return (
		<Container>
			<Grid container>
				<Grid item xs={12}>
					<Typography color={link ? 'primary' : 'inherit'} variant="h6">
						{customerName}
					</Typography>
				</Grid>
				{phoneNumber && (
					<Grid item xs={12}>
						<Typography color={link ? 'primary' : 'inherit'}></Typography>
						{phoneNumber}
					</Grid>
				)}
			</Grid>
		</Container>
	);
};

CustomerNameTableCell.propTypes = {
	customerName: string.isRequired,
	cell: object.isRequired,
	phoneNumber: string,
	link: string,
};

CustomerNameTableCell.defaultProps = {
	link: null,
	phoneNumber: '',
};
