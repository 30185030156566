import React from 'react';
import { object } from 'prop-types';
import { Grid, TableCell } from '@material-ui/core';

// Import components
import { BookingStatusActions, BookingStatusChip } from 'components/elements';

import useStatusTableCell from './useStatusTableCell';

import { useStyles } from '../styles';

const StatusTableCell = ({ cell }) => {
	const booking = cell.row.original;

	const { refreshTable, isStatusVisible } = useStatusTableCell({ booking });

	const { statusTable } = useStyles();

	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			className={statusTable}
		>
			<Grid container spacing={1} alignItems="center" justifyContent="center">
				{isStatusVisible && (
					<Grid item xs={12}>
						<BookingStatusChip booking={booking} />
					</Grid>
				)}
				<Grid item xs={12}>
					<BookingStatusActions booking={booking} onChange={refreshTable} />
				</Grid>
			</Grid>
		</TableCell>
	);
};

StatusTableCell.propTypes = {
	cell: object.isRequired,
};

export default StatusTableCell;
