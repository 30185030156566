import React from 'react';
import { useHistory } from 'react-router-dom';
import { object } from 'prop-types';
import { IconButton, TableCell, Tooltip } from '@material-ui/core';

// Import assets
import { EditIcon } from 'assets/icons';

// Import helpers
import { replaceEditCorporateUrl } from 'helpers';

// Import styles
import { useStyles } from './ActionTableCell.styles';
import { useTranslations } from 'components/utilities';

export const ActionTableCell = ({ cell }) => {
	const { t } = useTranslations();
	const { push } = useHistory();
	const classes = useStyles();

	const url = replaceEditCorporateUrl(cell.row.original.uuid);
	const goToEditForm = () => push(url);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Tooltip title={t('b2b.corporate.form.edit_corporate')}>
				<IconButton onClick={goToEditForm}>
					<EditIcon className={classes.editIcon} />
				</IconButton>
			</Tooltip>
		</TableCell>
	);
};

ActionTableCell.propTypes = {
	cell: object,
};
