import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	link: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(0.5),
	},
}));

export default useStyles;
