// Import helpers
import { AR, EN } from 'helpers';

// Import utilities
import { useTranslations } from 'components/utilities';

export const useLanguageButton = () => {
	const { i18n } = useTranslations();

	const lng = i18n.language;

	const handleChangeLanguage = () => {
		lng === EN ? i18n.changeLanguage(AR) : i18n.changeLanguage(EN);
	};

	const convertedLanguage = (lng === EN ? AR : EN).toUpperCase();

	return {
		handleChangeLanguage,
		convertedLanguage,
	};
};
