export const columns = [
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: 'individual_number',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer_phone_number',
		accessor: 'customer_phone_number',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer_type',
		accessor: 'customer_type',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.no_of_bookings',
		accessor: 'booking_total_number',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.booking_duration',
		accessor: 'booking_duration',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.booking_status',
		accessor: 'booking_status',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.service_type',
		accessor: 'service_type',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.created_at',
		accessor: 'created_at',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.pick_date',
		accessor: 'pick_date',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.drop_off_date',
		accessor: 'drop_date',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company_name',
		accessor: 'company_name',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.office_name',
		accessor: 'office_name',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.city',
		accessor: 'city_name',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.car_model',
		accessor: 'car_model',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.car_maker',
		accessor: 'car_maker',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.car_year',
		accessor: 'car_year',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.extended_at',
		accessor: 'extended_at_date',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.extension_price',
		accessor: 'extensions_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.extra_services',
		accessor: 'extra_services',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.extra_service_prices',
		accessor: 'extra_services_prices',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.extra_services_total_cost',
		accessor: 'extra_services_total_cost',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.paid_at',
		accessor: 'paid_at_date',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.paid_by_online',
		accessor: 'paid_online',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.paid_by_wallet',
		accessor: 'paid_wallet',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.paid_by_qitaf',
		accessor: 'paid_by_qitaf_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.paid_transactions',
		accessor: 'paid_transactions',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.original_booking_price',
		accessor: 'original_booking_price',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.amount_of_discount',
		accessor: 'discount_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.amount_after_discount',
		accessor: 'booking_price_after_discount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.discount_name',
		accessor: 'discount_type',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.coupon',
		accessor: 'coupon_code',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.coupon_percentage_discount',
		accessor: 'coupon_percentage_discount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.coupon_max_discount_amount',
		accessor: 'coupon_max_discount_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cashback_discount',
		accessor: 'cashback_discount_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cashback_discount_percentage',
		accessor: 'cashback_percentage_discount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cashback_discount_max_amount',
		accessor: 'cashback_max_discount_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cashback_to_wallet',
		accessor: 'cashback_wallet_discount_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cashback_wallet_percentage',
		accessor: 'cashback_wallet_percentage_discount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cashback_wallet_max_discount',
		accessor: 'cashback_wallet_max_discount_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.bank_name',
		accessor: 'bank_name',
		disableSortBy: true,
	},
];
