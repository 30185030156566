import { t } from 'i18next';

// Import helpers
import { CELL_TYPES } from '../helpers';

export const getCellContent = ({ type, tableData }) => {
	const { description, cellValue } = tableData;

	switch (type) {
		case CELL_TYPES.AMOUNT:
			return t('currency.price', { amount: cellValue });
		case CELL_TYPES.DESCRIPTION:
			return description;
		default:
			return cellValue;
	}
};
