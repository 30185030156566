import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CITY_COLLECTION_QUERY } from 'helpers';

const useCityCollection = ({ companyUuid }) => {
	const cityCollectionUrl = `v2/supplier/business/company/${companyUuid}/city/collection`;

	const enabled = companyUuid && companyUuid !== 'all' && companyUuid !== '';

	const { data, isFetching, isError } = useQuery(
		[CITY_COLLECTION_QUERY, companyUuid],
		async () => await services.get(cityCollectionUrl),
		{ refetchOnWindowFocus: false, enabled }
	);
	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};

export default useCityCollection;
