import React from 'react';
import { node } from 'prop-types';
import clsx from 'clsx';
import { Box, Grid, Typography } from '@material-ui/core';
import RTLProvider from 'rtl';

// Import components
import { Sidebar, Footer, Topbar } from './components';
import { Loader } from 'components/elements';
import { useTopbar } from './components/Topbar/useTopbar';

// Import utilities
import { useMain } from './useMain';

export const Main = ({ children }) => {
	const {
		isDesktop,
		isLoaded,
		shiftContentClass,
		shouldOpenSidebar,
		toggle,
		classes,
	} = useMain();

	const { sectionName, isTabletSize } = useTopbar();

	return (
		<RTLProvider>
			<div
				className={clsx({
					[classes.root]: true,
					[shiftContentClass]: isDesktop,
				})}
			>
				<main className={classes.content}>
					<Grid container>
						<Grid item>
							<Sidebar
								onClose={toggle}
								open={shouldOpenSidebar}
								variant={isDesktop ? 'persistent' : 'temporary'}
							/>
						</Grid>
						<Grid item xs={12}>
							<Box>
								<Topbar onSidebarOpen={toggle} />
								{!isLoaded ? (
									<Loader
										classNames={clsx(classes.signOutBox, classes.lngLoader)}
									/>
								) : (
									<Box>
										{isTabletSize && (
											<Typography variant="h4" className={classes.pageTitle}>
												{sectionName}
											</Typography>
										)}
										{children}
									</Box>
								)}
							</Box>
						</Grid>
					</Grid>
					<Footer />
				</main>
			</div>
		</RTLProvider>
	);
};

Main.propTypes = {
	children: node,
};
