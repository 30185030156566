import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { CampaignConditionsCard, CampaignGratificationCard } from '../index';

const CustomerExperienceSharedFields = () => {
	return (
		<>
			<Grid item lg={9} xs={12}>
				<CampaignConditionsCard />
			</Grid>

			<Grid item lg={9} xs={12}>
				<CampaignGratificationCard />
			</Grid>
		</>
	);
};

export default CustomerExperienceSharedFields;
