import React from 'react';
import { useSelector } from 'react-redux';
import { string, bool } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { FIELDS } from './helpers';
import { ADMIN, SUPPLIER_ADMIN } from 'helpers';

const useStyles = makeStyles(() => ({
	checkboxCenter: {
		display: 'flex',
		alignItems: 'center',
	},
}));

const ProfileDetails = (props) => {
	const { className, isUserCreate, ...rest } = props;
	const { submitting, values } = useFormState();
	const { prefixes, companyList, roles } = useSelector(
		(store) => store.companyManager.form
	);

	const currentUserRole = useSelector(
		(store) => store.auth.authenticate.data.role
	);
	const isCurrentUserAdmin = !!currentUserRole.find(
		(role) => role === ADMIN || role === SUPPLIER_ADMIN
	);

	const {
		manager: { additional_phone_notify },
	} = values;

	const showCompanySelect = isCurrentUserAdmin;

	const { t } = useTranslations();

	const classes = useStyles();

	const renderFields = (field) => {
		const getOptions = (name) => {
			switch (name) {
				case 'prefixes':
					return Object.values(prefixes);
				case 'roles':
					return roles;
				case 'companyList':
					return companyList;
				default:
					return [];
			}
		};
		const selectFieldItems = getOptions(field.menuItemsContent);
		switch (field.type) {
			case 'checkbox':
				return (
					<Grid
						key={field.name}
						item
						md={field.md}
						xs={field.xs}
						className={classes.checkboxCenter}
					>
						<Field
							type={field.type}
							component={field.component}
							name={field.name}
							disabled={submitting}
							labelText={t(field.label) || ''}
							color="primary"
						/>
					</Grid>
				);
			case 'select':
				if (selectFieldItems.length < 1) {
					return null;
				}

				return (
					<Grid key={field.name} item md={field.md} xs={field.xs}>
						<Field
							fullWidth
							component={field.component}
							type={field.type}
							label={t(field.label)}
							name={field.name}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required={field.required}
						>
							{selectFieldItems.map((role) => (
								<MenuItem
									key={field.keyExtractor(role)}
									value={field.keyExtractor(role)}
								>
									{field.labelExtractor(role)}
								</MenuItem>
							))}
						</Field>
					</Grid>
				);
			default:
				return (
					<Grid key={field.name} item md={field.md} xs={field.xs}>
						<Field
							fullWidth
							component={field.component}
							type={field.type}
							label={t(field.label)}
							name={field.name}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required={field.required}
						></Field>
					</Grid>
				);
		}
	};

	const pageTitle = isUserCreate
		? 'company_managers.toolbar.add_user_btn'
		: 'profile.form.title';

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t(`${pageTitle}`)} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS(additional_phone_notify, showCompanySelect).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

ProfileDetails.propTypes = {
	className: string,
	isUserCreate: bool,
};

export default ProfileDetails;
