import { convertFormDates } from 'helpers';

export const initialValues = {
	pickup_date: null,
	pickup_date_to: null,
	reward_date: null,
	reward_date_to: null,
	company_uuid: null,
	user_is_active: 'all',
	is_settled: 'all',
	month: null,
	user_role: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		is_settled: values.is_settled === 'all' ? null : values.is_settled,
		user_is_active:
			values.user_is_active === 'all' ? null : values.user_is_active,
		user_role: values.user_role === 'all' ? null : values.user_role,

		...convertFormDates(values, [
			'pickup_date',
			'pickup_date_to',
			'reward_date',
			'reward_date_to',
		]),
	};
};
