import React from 'react';
import { Card, Box, Grid, CardContent } from '@material-ui/core';

// Import components
import { DateRangeInput } from 'components/elements';
import { PeriodButtons } from '../components';

// Import utilities and helpers
import { useCompanyOfficesFilter } from 'components/elements/organisms/Tables/TableFiltersFields/CompanyOfficesFilter/useCompanyOfficesFilter';
import { useCommonFields, useProfile } from 'components/utilities';
import { useFiltersContent } from './useFiltersContent';
import { FILTER_FIELDS } from './helpers';
import { useCompanies } from 'queries';

export const FiltersContent = () => {
	const { filteredOffices } = useCompanyOfficesFilter('office_uuids');
	const { isAdmin, isOfficeManager } = useProfile();
	const { data: companies } = useCompanies();
	const { renderFields } = useCommonFields();
	const { values } = useFiltersContent();

	return (
		<Card>
			<CardContent>
				<Box maxWidth={1200}>
					<Grid
						container
						spacing={2}
						alignItems="flex-end"
						justifyContent="center"
					>
						<Grid item md={4}>
							<DateRangeInput
								nameFrom="period.from"
								nameUntil="period.until"
								label="dashboard.filters.date"
								combinedInputs
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<PeriodButtons />
						</Grid>
						{FILTER_FIELDS({
							offices: filteredOffices,
							isOfficeManager,
							isAdmin,
							companies,
							values,
						}).map(renderFields)}
					</Grid>
				</Box>
			</CardContent>
		</Card>
	);
};
