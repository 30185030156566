import React from 'react';
import { createContext } from 'react';
import PropTypes from 'prop-types';

// Import queries
import { useSettlementsHistory } from 'queries';

export const SettlementHistoryContext = createContext();

export const SettlementHistoryProvider = ({ userUuid, children }) => {
	const { data, isFetching, isError, error } = useSettlementsHistory({
		userUuid,
	});

	return (
		<SettlementHistoryContext.Provider
			value={{
				data,
				isFetching,
				isError,
				error,
			}}
		>
			{children}
		</SettlementHistoryContext.Provider>
	);
};

SettlementHistoryProvider.propTypes = {
	userUuid: PropTypes.string,
	children: PropTypes.node,
};
