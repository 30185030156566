import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	BaseYesNoAllFilter,
	CompanyFilter,
	DateRangeFilter,
} from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const FilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={3}>
			<DateRangeFilter
				nameFrom="pickup_date"
				nameUntil="pickup_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
			/>
			<DateRangeFilter
				nameFrom="dropoff_date"
				nameUntil="dropoff_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
			/>
			{isAdmin && (
				<CompanyFilter name="company_uuid" label="bookings.filters.company" />
			)}
			<BaseYesNoAllFilter
				name="has_early_return_state"
				label="bookings.filters.early_return"
			/>
			<BaseYesNoAllFilter
				name="is_settled"
				label="bookings.filters.is_settled"
			/>
		</Grid>
	);
};
