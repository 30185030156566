import React from 'react';
import { bool, func } from 'prop-types';

// Import components
import { useTableContextProvider } from 'components/context';
import { ModalDialog } from 'components/elements';
import AdditionalFields from './AdditionalFields';

// Import helpers
import { setAdditionalColumns, INITIAL_VALUES } from './helpers';

const AdditionalInfo = ({ open, close }) => {
	const { columns, updateColumns } = useTableContextProvider();

	const handleOnSubmit = (values) => {
		const additionalColumns = setAdditionalColumns(values, [...columns]);
		updateColumns(additionalColumns);
		close();
	};

	const handleClearFilters = (reset) => () => reset();

	return (
		<ModalDialog
			title="customers.table.headers.additional_info"
			alignTitle="left"
			open={open}
			close={close}
			initialValues={INITIAL_VALUES}
			onSubmit={handleOnSubmit}
		>
			{({ submitting, form: { reset } }) => (
				<>
					<AdditionalFields
						clearFilters={handleClearFilters(reset)}
						submitting={submitting}
					/>
				</>
			)}
		</ModalDialog>
	);
};

AdditionalInfo.propTypes = {
	open: bool.isRequired,
	close: func.isRequired,
};

export default AdditionalInfo;
