import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DoubleCheckIcon = (props) => (
	<SvgIcon width="21" height="11" viewBox="0 0 21 11" fill="none" {...props}>
		<path
			d="M14.4318 0.312393C14.2361 0.114547 13.9701 0.00232156 13.6917 3.04152e-05C13.4135 -0.00208371 13.1458 0.10609 12.947 0.300941L5.18552 7.97132L1.81172 4.47863C1.61792 4.27673 1.35191 4.16028 1.07215 4.15499C0.792379 4.14971 0.522137 4.25612 0.32094 4.45044C0.119747 4.64494 0.00435102 4.91151 0.000120517 5.19128C-0.00410999 5.47104 0.103186 5.74097 0.298389 5.94145L4.41213 10.2046C4.60892 10.4054 4.87811 10.519 5.15927 10.5203H5.1709C5.4468 10.5194 5.71122 10.4102 5.9075 10.216L14.4222 1.79695V1.79712C14.6198 1.60104 14.7317 1.33484 14.7335 1.05665C14.7352 0.77829 14.6267 0.5107 14.4317 0.312118L14.4318 0.312393Z"
			fill="currentColor"
		/>
		<path
			d="M10.7258 10.2045C10.9221 10.4048 11.1904 10.5185 11.4709 10.5202H11.4825C11.7584 10.5193 12.023 10.4101 12.2191 10.216L20.7338 1.79691V1.79708C20.9724 1.52842 21.0575 1.15669 20.9595 0.811035C20.8615 0.46555 20.5939 0.193688 20.2499 0.0904515C19.9058 -0.0129657 19.5329 0.0663152 19.2606 0.300633L11.5027 7.97146L11.226 7.68411H11.2258C11.0322 7.48221 10.766 7.36576 10.4862 7.36047C10.2066 7.35519 9.9362 7.46142 9.735 7.65592C9.53398 7.85042 9.41841 8.11699 9.41418 8.39676C9.40995 8.67653 9.51742 8.94645 9.71263 9.14675L10.7258 10.2045Z"
			fill="currentColor"
		/>
	</SvgIcon>
);
