import { useQuery } from 'react-query';
import services from 'services/services';

const SETTLEMENTS_HISTORY_QUERY = 'settlements_history';

export const useSettlementsHistory = ({ userUuid }) => {
	const path = `/v2/employee-performance/user/${userUuid}/settlements-history/list`;

	const { data, isFetching, isError, error } = useQuery(
		[SETTLEMENTS_HISTORY_QUERY, userUuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError, error };
};
