import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		margin: '0 16px',
	},
}));
