import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	chip: {
		display: 'grid',
		placeItems: 'center',
		width: '24px',
		height: '24px',
	},
	notSettledChip: {
		border: '2px solid',
		borderColor: theme.palette.error.main,
		backgroundColor: theme.palette.error.light,
	},
	settledChip: {
		border: '2px solid',
		borderColor: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.light,
	},
	NAChip: {
		width: '40px',
		borderRadius: '8px',
	},
	errorIcon: {
		color: theme.palette.error.main,
		width: '16px',
		height: '16px',
	},
	successIcon: {
		color: theme.palette.success.main,
		width: '16px',
		height: '16px',
	},
}));
