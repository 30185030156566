import React from 'react';
import { string } from 'prop-types';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities and helpers
import {
	useTranslations,
	useCommonFields,
	useProfile,
} from 'components/utilities';
import { useCommissionFields } from './useCommissionFields';

export const CommissionPercentage = ({ section }) => {
	const { t } = useTranslations();
	const { renderFields } = useCommonFields();
	const { isAdmin } = useProfile();
	const { FIELDS } = useCommissionFields(section);

	if (!isAdmin) return null;

	return (
		<Card>
			<CardHeader title={t('offices.form.commission_percentage.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS.map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

CommissionPercentage.propTypes = {
	section: string.isRequired,
};
