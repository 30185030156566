import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	list: {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 1fr)',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: 'repeat(2, 1fr)',
		},
		width: '100%',
		gap: '16px',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.white,
		borderRadius: '24px',
		padding: '32px 40px',
		gap: '16px',
	},
	name: {
		color: theme.palette.text.primary,
		fontWeight: '500',
	},
	amount: {
		color: theme.palette.primary.main,
		fontSize: '32px',
		fontWeight: '500',
	},
	unit: {
		color: theme.palette.text.secondary,
	},
	box: {
		display: 'grid',
		width: '100%',
	},
}));
