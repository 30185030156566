import React from 'react';
import { object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';
import { enGB, arSA } from 'date-fns/locale';
import { format } from 'date-fns';

// Import helpers and utils
import { EN } from 'helpers';
import { useTranslations } from 'components/utilities';

export const YearAndMonthTableCell = ({ cell }) => {
	const {
		i18n: { language },
	} = useTranslations();

	const { created_at, year } = cell.row.original;
	const locale = language === EN ? enGB : arSA;
	const month = format(new Date(created_at), 'MMMM', { locale });

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Typography>
				{month} - {year}
			</Typography>
		</TableCell>
	);
};

YearAndMonthTableCell.propTypes = {
	cell: object,
};
