import React from 'react';
import { array, func } from 'prop-types';
import { Box, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './BookingIdsHandler.styles';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import { Label } from './components';

// Import helpers
import { BUTTON_ACTION_TYPES } from 'helpers';

const { INCREMENT, DECREMENT } = BUTTON_ACTION_TYPES;

export const BookingIdsHandler = ({ add, remove, data = [] }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const checkedIds = data.filter(({ checked }) => checked);
	const unCheckedIds = data.filter(({ checked }) => !checked);

	return (
		<Box className={classes.container}>
			<Typography className={classes.heading}>
				{t('rewards_settlement.fields.booking_ids')}
			</Typography>
			<Box className={classes.list}>
				{checkedIds.map(({ id }) => (
					<Label
						key={id}
						id={id}
						actionType={DECREMENT}
						onClick={() => remove({ id, checked: false })}
					/>
				))}
			</Box>
			{!!unCheckedIds.length && (
				<Box className={classes.list}>
					{unCheckedIds.map(({ id }) => (
						<Label
							key={id}
							id={id}
							actionType={INCREMENT}
							onClick={() => add({ id, checked: true })}
						/>
					))}
				</Box>
			)}
		</Box>
	);
};

BookingIdsHandler.propTypes = {
	data: array,
	add: func,
	remove: func,
};
