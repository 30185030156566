import React from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

// Import components
import { CarDetails, Details } from 'views/Cars/components';
import {
	RateModel,
	LeasingCarPlate,
	LeasingCarSpec,
	LeasingExtraServices,
} from './components';

// Import actions
import { submitLeasingCarForm } from 'store/actions';

// Import utilities
import { FormRPC } from 'components/utilities';
import { useAddLeasingCarForm } from './useAddLeasingCarForm';

// Import helpers
import {
	LEASING_CAR_INITIAL_VALUES,
	LEASING_CAR_DETAILS_FIELDS,
} from './helpers';
import { replaceLeasingCarsUrl } from 'helpers';

// Import selectors
import { leasingCarFormSelector } from 'store/selectors';

//Import styles
import useStyles from '../LeasingCars.styles';

export const AddLeasingCar = () => {
	const { handleFetchLeasingCarInfo } = useAddLeasingCarForm();
	const { officeId } = useParams();
	const goBackPath = replaceLeasingCarsUrl(officeId);

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={handleFetchLeasingCarInfo}
				submitFormAction={submitLeasingCarForm}
				isCardLayout={false}
				goBackPath={goBackPath}
				store={leasingCarFormSelector}
				initialValues={LEASING_CAR_INITIAL_VALUES}
				lg={12}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<CarDetails
								section="details"
								dataSelector={leasingCarFormSelector}
							/>
						</Grid>

						<Grid item xs={12}>
							<RateModel />
						</Grid>

						<Grid item xs={12}>
							<LeasingCarPlate />
						</Grid>

						<Grid item xs={12}>
							<LeasingCarSpec />
						</Grid>

						<Grid item xs={12}>
							<LeasingExtraServices />
						</Grid>

						<Grid item xs={12}>
							<Details section="details" fields={LEASING_CAR_DETAILS_FIELDS} />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};
