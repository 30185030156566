export const OPTIONS = [
	{
		value: 'all',
		label: 'company.table.filters.all_companies',
	},
	{
		value: true,
		label: 'company.table.filters.active_companies',
	},
	{
		value: false,
		label: 'company.table.filters.inactive_companies',
	},
];
