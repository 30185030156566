import React from 'react';
import { Box } from '@material-ui/core';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import components
import { PublicHolidaysForm } from './components';

const PublicHolidays = () => {
	const classes = useBaseLayoutStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.content}>
				<PublicHolidaysForm />
			</Box>
		</Box>
	);
};

export default PublicHolidays;
