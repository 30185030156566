import React from 'react';
import { Grid } from '@material-ui/core';
import {
	fetchOfficeCarsFormResources,
	submitOfficeCarsForm,
} from 'store/actions';

// Import helpers
import { URLS } from 'components/routes';

// Import utilities
import { officeCarsFormSelector } from 'store/selectors';
import { FormRPC, useMessage, useTranslations } from 'components/utilities';

//Import styles
import useStyles from '../styles';

// Import components
import { ExternalPartnerCarsTable } from './components';

export const OfficeCars = () => {
	const classes = useStyles();

	const { message } = useMessage();

	const { t } = useTranslations();

	const errorCallback = () => {
		message.error(t('common.messages.error_message'));
	};

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchOfficeCarsFormResources}
				submitFormAction={(options) =>
					submitOfficeCarsForm({ ...options, errorCallback })
				}
				isCardLayout={false}
				goBackPath={URLS.emptyUrl}
				store={officeCarsFormSelector}
				lg={12}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item xs>
							<ExternalPartnerCarsTable />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};
