import { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import usePrevious from '@rooks/use-previous';
import _ from 'lodash';

// Import components
import { useFilters } from '../../../context/useFilters';

// import helpers
import { validatePeriod } from './helpers';

export const useFiltersContent = () => {
	const { values } = useFormState();

	const prevValues = usePrevious(values);

	const { setFilters } = useFilters();

	useEffect(() => {
		const { period } = values;

		if (!validatePeriod(period)) return;

		const isEqual = _.isEqual(prevValues, values);

		if (!isEqual) {
			setFilters(values);
		}
	}, [values, prevValues, setFilters]);

	return { values };
};
