import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { REASON_DETAILS_FIELDS } from './helpers';

export const ReasonDetails = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	return (
		<Card>
			<CardHeader title={t('cancellation_reasons.form.reason_details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{REASON_DETAILS_FIELDS.map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
