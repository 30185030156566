import { startOfMonth } from 'date-fns';

// Import helpers
import { convertFormDates } from 'helpers';

export const initialValues = {
	date: startOfMonth(new Date()),
	date_to: new Date(),
	booking_status: 'all',
	office_uuid: 'all',
	city_uuid: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		office_uuid: values.office_uuid === 'all' ? null : values.office_uuid,
		city_uuid: values.city_uuid === 'all' ? null : values.city_uuid,
		booking_status:
			values.booking_status === 'all' ? null : values.booking_status,

		...convertFormDates(values, ['date']),
	};
};
