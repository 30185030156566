import { t } from 'i18next';

// Import helpers
import { EMPTY_VALUE } from 'helpers';

export const EARLY_RETURN_FIELDS = [
	{
		accessor: 'payment_no',
		label: 'common.fields.payment_no',
	},
	{
		accessor: 'amount.gross',
		label: 'common.fields.amount',
	},
	{
		accessor: 'transactional_type',
		label: 'common.fields.transactional_type',
	},
];

export const convertedEarlyReturnData = (earlyReturnData) => {
	const { transactional_type, amount } = earlyReturnData || {};

	return {
		...earlyReturnData,
		transactional_type: transactional_type
			? t(`common.fields.transactional_types.${transactional_type}`)
			: EMPTY_VALUE,
		amount: {
			...amount,
			gross: t('currency.price', { amount: amount?.gross }),
		},
	};
};

export const OFFLINE_REASON_OPTIONS = [
	{
		label:
			'bookings.preview_booking.subscription_invoice.offline_reasons.transfer_to_telgani_bank_account',
		value: 'transfer_to_telgani_bank_account',
	},
	{
		label:
			'bookings.preview_booking.subscription_invoice.offline_reasons.paid_by_telgani_link',
		value: 'paid_by_telgani_link',
	},
	{
		label:
			'bookings.preview_booking.subscription_invoice.offline_reasons.paid_through_office_rental',
		value: 'paid_through_office_rental',
	},
];
