import React from 'react';
import { string } from 'prop-types';

// Import styles
import { useStyles } from './styles';

// Import assets
import { LoaderIcon } from 'assets/icons';

export const Loader = ({ classNames }) => {
	const classes = useStyles();

	return (
		<div className={classNames}>
			<LoaderIcon className={classes.icon} />
		</div>
	);
};

Loader.propTypes = {
	classNames: string,
};
