import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(0),
		padding: '1px 16px',
		fontSize: '12px',
	},
	box: {
		margin: `${theme.spacing(2)}px 0`,
	},
	actionButton: {
		minWidth: theme.layout.width.button,
	},
	container: {
		maxWidth: '120px',
	},
	fullFilledContent: {
		alignSelf: 'center',
	},
}));
