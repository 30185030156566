// Import redux
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// Import redux thunk
import reduxThunk from 'redux-thunk';
// Import reducers
import reducers from './reducers';
import ls from 'localstorage-slim';

// Import variables
import { LOCAL_STORAGE_USER } from 'helpers';

const user = ls.get(LOCAL_STORAGE_USER);

// Initial state
const INITIAL_STATE = {
	auth: {
		authenticate: user || null,
		isError: false,
		isAuthenticated: user ? user.isAuthenticated : false,
		resetToken: null,
		signOut: {
			error: false,
			loading: false,
		},
	},
};
// Create middleware with redux thunk
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

let store;

// eslint-disable-next-line
if (process.env.NODE_ENV === 'production') {
	// Create store
	store = createStoreWithMiddleware(reducers, INITIAL_STATE);
} else {
	// Create store
	store = createStoreWithMiddleware(
		reducers,
		INITIAL_STATE,
		composeWithDevTools()
	);
}

export default store;
