import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	},
	deactive: {
		color: theme.palette.error.main,
	},
}));

export default useStyles;
