import React from 'react';
import { object } from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Typography, Button, Link, CircularProgress } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

// Import helpers
import validation from './validation';
import useForgotPasswordForm from './useForgotPasswordForm';
import { URLS } from 'components/routes';

// Import styles
import { useStyles } from './styles';

// Import components
import { TextFieldAdapter } from 'components/elements';

const ForgotPasswordForm = ({ history }) => {
	const classes = useStyles();
	const { submitStep1 } = useForgotPasswordForm(history);

	return (
		<Form
			onSubmit={submitStep1}
			validate={validation}
			render={({ handleSubmit, submitting }) => (
				<form className={classes.form} onSubmit={handleSubmit}>
					<Typography className={classes.title} variant="h2">
						Forget Password
					</Typography>

					<Field
						className={classes.textField}
						fullWidth
						component={TextFieldAdapter}
						type="text"
						label="Email address"
						name="email"
						variant="outlined"
						autoComplete="false"
						disabled={submitting}
					/>

					<div className={classes.wrapper}>
						<Button
							className={classes.continueButton}
							color="primary"
							disabled={submitting}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
						>
							Continue
						</Button>
						{submitting && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</div>

					<Typography color="textSecondary" variant="body1">
						Have an account?{' '}
						<Link component={RouterLink} to={URLS.signInUrl} variant="h6">
							Sign in
						</Link>
					</Typography>
				</form>
			)}
		/>
	);
};

ForgotPasswordForm.propTypes = {
	history: object,
};

export default ForgotPasswordForm;
