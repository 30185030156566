import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	CARS: 'cars',
	ACTIVE: 'active',
	NAME: 'name',
	NOTE: 'note',
	BOOKINGS_ACCEPTED: 'bookings_accepted',
	BOOKINGS: 'bookings',
	AVAILABLE_CARS: 'available_cars',
};

export const columns = ({ isAdmin, isCompanyManager }) => [
	{
		Header: 'offices.table.headers.name',
		accessor: 'name',
	},
	{
		Header: 'offices.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
	},
	{
		Header: 'offices.table.headers.city',
		accessor: 'city',
	},
	{
		Header: 'offices.table.headers.contact_person',
		accessor: 'contact_person',
		disableSortBy: true,
	},
	{
		Header: 'offices.table.headers.contact_number',
		accessor: 'contact_number',
		disableSortBy: true,
	},
	{
		Header: 'offices.table.headers.available_cars',
		accessor: COLUMNS_ACCESSORS.AVAILABLE_CARS,
		disableSortBy: true,
	},
	{
		Header: 'offices.table.headers.bookings',
		accessor: COLUMNS_ACCESSORS.BOOKINGS,
		disableSortBy: true,
	},
	{
		Header: 'offices.table.headers.bookings_accepted',
		accessor: COLUMNS_ACCESSORS.BOOKINGS_ACCEPTED,
		disableSortBy: true,
	},
	{
		Header: 'offices.table.headers.cars',
		accessor: COLUMNS_ACCESSORS.CARS,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
		isHidden: !isAdmin && !isCompanyManager,
	},
];
