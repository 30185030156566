import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';

const CompensatedTableCell = ({ cell }) => {
	const { is_compensated, compensation_amount } = cell.row.original;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{!is_compensated && 'N/A'}
			{is_compensated && `${compensation_amount} SAR`}
		</TableCell>
	);
};

CompensatedTableCell.propTypes = {
	cell: PropTypes.object.isRequired
};

export default CompensatedTableCell;
