// Import utilities
import services from 'services/services';
import * as types from 'store/types';

// Import utilities
import {
	fetchExternalPartnerCarsListUrl,
	submitExternalPartnerCarsListUrl,
} from 'store/paths';

// Import helpers
import { catchError } from 'store/actions/helpers';
import { convertValues } from './helpers';

export const fetchOfficeCarsFormResources =
	({ itemId, cancelToken }) =>
	async (dispatch) => {
		dispatch({
			type: types.OFFICE_CARS_FORM_FETCH_DATA_LOADING,
		});

		try {
			const {
				data: { data },
			} = await services.get(
				fetchExternalPartnerCarsListUrl(itemId),
				cancelToken
			);

			dispatch({
				type: types.OFFICE_CARS_FORM_FETCH_DATA_SUCCESS,
				payload: {
					item: data || {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.OFFICE_CARS_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitOfficeCarsForm =
	({ values, itemId, callbackSuccess, errorCallback }) =>
	async () => {
		const convertedValues = convertValues(values);

		try {
			await services.post(
				submitExternalPartnerCarsListUrl(itemId),
				convertedValues
			);
			callbackSuccess();
		} catch {
			errorCallback();
		}
	};
