import { TextFieldAdapter } from 'components/elements';

export const REASON_DETAILS_FIELDS = [
	{
		component: TextFieldAdapter,
		type: 'text',
		name: 'details.name.en',
		label: 'urgent_reasons.form.reason_name.reason_en',
		md: 6,
		xs: 12,
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		name: 'details.name.ar',
		label: 'urgent_reasons.form.reason_name.reason_ar',
		md: 6,
		xs: 12,
	},
];
