import en from './en';
import ar from './ar';

export default {
	en: {
		translation: en
	},
	ar: {
		translation: ar
	}
};
