import React from 'react';
import { Box } from '@material-ui/core';
import { fetchEmployeePerformanceBookings } from 'store/actions';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow, Export } from './components';

// Import utils
import { useProfile } from 'components/utilities';

export const EmployeePerformanceBookings = () => {
	const classes = useBaseLayoutStyles();

	const { profileDetails, isOfficeManager } = useProfile();

	const officeManagerUuid = isOfficeManager ? profileDetails.user_uuid : null;

	return (
		<TableProvider
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchEmployeePerformanceBookings({ ...options, officeManagerUuid })
			}
		>
			<Box className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<Box className={classes.content}>
					<Table
						isDefaultRow={false}
						tableInnerToolbar={<Export officeManagerUuid={officeManagerUuid} />}
					>
						{({ row }) => <TableBodyRow key={row.id} row={row} />}
					</Table>
				</Box>
			</Box>
		</TableProvider>
	);
};
