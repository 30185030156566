export const initialValues = {
	active: 'all',
	company_is_active: 'all',
	company_uuid: ['all'],
};

export const formatValues = (values) => {
	return {
		...values,
		active: values.active === 'all' ? null : values.active,
		company_is_active:
			values.company_is_active === 'all' ? null : values.company_is_active,
		company_uuid: values.company_uuid.includes('all')
			? 'all'
			: `[${values.company_uuid.map((company) => `"${company}"`).join(',')}]`,
	};
};
