import React from 'react';
import { Form } from 'react-final-form';
import { Grid } from '@material-ui/core';

// Import utils and helpers
import { useSetNewPassword } from './useSetNewPassword';

// Import components
import { LoadingWrapper, SignInSideBanner } from 'components/elements';
import { FormContent } from './components';

// Import styles
import { useStyles } from './SetNewPassword.styles';

export const SetNewPassword = () => {
	const { handleOnSubmit, verified, isLoading } = useSetNewPassword();
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid className={classes.grid} container>
				<SignInSideBanner classes={classes} />
				<Grid className={classes.content} item lg={7} xs={12}>
					<div className={classes.content}>
						<div className={classes.contentBody}>
							{verified ? (
								<Form
									onSubmit={handleOnSubmit}
									render={({ handleSubmit }) => (
										<form onSubmit={handleSubmit} className={classes.form}>
											<FormContent isLoading={isLoading} />
										</form>
									)}
								></Form>
							) : (
								<LoadingWrapper />
							)}
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};
