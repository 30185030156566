import React from 'react';
import { useLocation } from 'react-router-dom';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';

// Import utilities
import { useProfile, useTranslations } from 'components/utilities';
import { updateStatusBooking } from 'store/actions';

// Import components
import {
	OfficeNameTableCell,
	CreatedAtTableCell,
	ExtendedAtMultipleDatesTableCell,
} from '../../components';
import {
	SettlementStatusTableCell,
	RatingReasonTableCell,
	CompensatedTableCell,
	PickDateTableCell,
	ApprovedTableCell,
	NoteTableCell,
	ActionsTableCell,
	StatusTableCell,
	LinkTableCell,
	DropDateTableCell,
	AssignedDriverTableCell,
	DeliveryStatusTableCell,
	DeliveryTimeAndLocationTableCell,
} from 'components/elements';

// Import helpers
import { replacePreviewBookingUrl, replaceEditBookingUrl } from 'helpers';
import { URLS } from 'components/routes';

// Import styles
import { useStyles } from 'theme/styles';

export const TableBodyRow = ({ row, columnsAccessor }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { isAdmin } = useProfile();

	const { pathname } = useLocation();

	const isBookingView = pathname === URLS.bookingsUrl;

	const {
		BOOKING_ID,
		STATUS,
		OFFICE_NAME,
		NOTE,
		DURATION,
		APPROVED,
		COMPENSATED,
		RATING_REASON,
		SETTLEMENT_STATUS,
		CUSTOMER_NAME,
		PICK_DATE,
		CREATED_AT,
		ACTIONS,
		DROP_DATE,
		EXTENDED_AT,
		TRANSFER,
		ASSIGNED_DRIVER,
		DELIVERY_STATUS,
	} = columnsAccessor || {};

	const renderCell = (cell) => {
		const {
			uuid,
			individual_number,
			response_time,
			customer_phone,
			customer_name,
		} = cell.row.original;

		if (cell.column.isHidden) return null;
		switch (cell.column.id) {
			case NOTE:
				return (
					<NoteTableCell key={cell.column.id} cell={cell} showAssignedDriver />
				);

			case ACTIONS:
				return (
					<ActionsTableCell
						key={cell.column.id}
						cell={cell}
						editLinkPath={replaceEditBookingUrl(uuid)}
						canEdit={isAdmin}
						minWidth={140}
					/>
				);
			case OFFICE_NAME:
				return <OfficeNameTableCell key={cell.column.id} cell={cell} />;
			case CREATED_AT:
				return <CreatedAtTableCell cell={cell} key={cell.column.id} />;
			case EXTENDED_AT:
				return (
					<ExtendedAtMultipleDatesTableCell cell={cell} key={cell.column.id} />
				);
			case DROP_DATE:
				return <DropDateTableCell cell={cell} key={cell.column.id} />;
			case BOOKING_ID:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replacePreviewBookingUrl(uuid)}
					>
						{individual_number}
					</LinkTableCell>
				);
			case PICK_DATE:
				return <PickDateTableCell key={cell.column.id} cell={cell} />;
			case STATUS:
				return (
					<StatusTableCell
						key={cell.column.id}
						acceptAction={updateStatusBooking}
						cell={cell}
					/>
				);
			case DURATION:
				return (
					<TableCell key={cell.column.id}>
						{response_time &&
							`${response_time} ${t('bookings.table.common.minutes')}`}
					</TableCell>
				);
			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{customer_name}
						<br /> {customer_phone}
					</TableCell>
				);

			case APPROVED:
				return <ApprovedTableCell key={cell.column.id} cell={cell} />;
			case COMPENSATED:
				return <CompensatedTableCell key={cell.column.id} cell={cell} />;
			case RATING_REASON:
				return <RatingReasonTableCell key={cell.column.id} cell={cell} />;
			case SETTLEMENT_STATUS:
				return <SettlementStatusTableCell key={cell.column.id} cell={cell} />;

			case TRANSFER:
				return (
					<TableCell key={cell.column.id}>
						{cell.row.original.transfer.full_name}
					</TableCell>
				);
			case ASSIGNED_DRIVER:
				return <AssignedDriverTableCell key={cell.column.id} cell={cell} />;

			case DELIVERY_STATUS:
				return isBookingView ? (
					<DeliveryTimeAndLocationTableCell key={cell.column.id} cell={cell} />
				) : (
					<DeliveryStatusTableCell key={cell.column.id} cell={cell} />
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			key={row.id}
			{...row.getRowProps()}
			className={clsx({
				[classes.accountDeleted]: row.original.customer_deleted_at,
			})}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
};
