import React from 'react';
import { object, string } from 'prop-types';
import { TableCell, Tooltip, Typography } from '@material-ui/core';

// Import utils
import { useDefaultTableCell } from './useDefaultTableCell';

export const DefaultTableCell = (props) => {
	const { disableTooltip, cellContent, classes, reason } =
		useDefaultTableCell(props);

	return (
		<TableCell>
			<Tooltip title={reason} disableHoverListener={disableTooltip}>
				<Typography className={classes.modifiedElement} noWrap>
					{cellContent}
				</Typography>
			</Tooltip>
		</TableCell>
	);
};

DefaultTableCell.propTypes = {
	tableData: object,
	type: string,
};
