import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import components
import PreviewField from './PreviewField';

// Import utilities
import { previewBookingSelector, isAdminSelector } from 'store/selectors';
import { useTranslations } from 'components/utilities';

// Import helpers
import { PAYMENT_METHODS } from 'helpers';

const PaymentMethodPreviewField = ({ label }) => {
	const { t } = useTranslations();

	const { payment } = useSelector(previewBookingSelector);

	const isAdmin = useSelector(isAdminSelector);

	const sectionName = 'bookings.preview_booking.invoice';

	const textForAdmins =
		t(`${sectionName}.${payment.method}`) +
		' - ' +
		t(`${sectionName}.method_type.${payment.method_type}`);

	const textForOthers =
		payment.method === PAYMENT_METHODS.CASH
			? t(`${sectionName}.method_type.cash`)
			: t(`${sectionName}.pre_paid`);

	return (
		<PreviewField label={label}>
			{isAdmin ? textForAdmins : textForOthers}
		</PreviewField>
	);
};

PaymentMethodPreviewField.propTypes = {
	label: PropTypes.string,
};

export default PaymentMethodPreviewField;
