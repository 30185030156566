import React from 'react';
import { array, string } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useBookingStatusFilter } from './useBookingStatusFilter';
import { useTranslations } from 'components/utilities';

export const BookingStatusFilter = ({ name, label, neededStates }) => {
	const { t } = useTranslations();

	const { submitting, bookingStates } = useBookingStatusFilter({
		neededStates,
	});

	if (!bookingStates) {
		return null;
	}

	return (
		<Grid item md={3}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{bookingStates.map((state) => (
					<MenuItem key={state} value={state}>
						{t(`bookings.booking_states.${state}`).toUpperCase()}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

BookingStatusFilter.propTypes = {
	name: string,
	label: string,
	neededStates: array,
};

BookingStatusFilter.defaultProps = {
	neededStates: null,
};
