import { getDaysInMonth } from 'date-fns';
import { convertFormDates } from 'helpers';

export const getInitialValues = () => {
	const currentMonth = (new Date().getMonth() + 1).toString();
	const currentYear = new Date().getFullYear().toString();

	return {
		month: currentMonth.length === 1 ? `0${currentMonth}` : currentMonth,
		year: currentYear,
	};
};

export const formatValues = (values) => {
	const newCreatedAt = new Date(`${values.year}-${values.month}-01`);

	const monthDays = getDaysInMonth(newCreatedAt);
	const newCreatedAtTo = new Date(
		`${values.year}-${values.month}-${monthDays}`
	);

	const convertedValues = {
		created_at: newCreatedAt,
		created_at_to: newCreatedAtTo,
	};

	return {
		...values,
		...convertFormDates(convertedValues, ['created_at']),
	};
};
