import React from 'react';
import { number, object, oneOfType, string } from 'prop-types';
import { Box, TableCell, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

// Import styles
import useStyles from '../styles';

// Import helpers
import { EMPTY_VALUE } from 'helpers';

export const SettlementRatingTableCell = ({ cell, rating }) => {
	const classes = useStyles();

	return (
		<TableCell {...cell.getCellProps()}>
			{rating ? (
				<Box className={classes.ratingCellContainer}>
					<Typography>{rating}</Typography>
					<StarIcon className={classes.ratingCellIcon} />
				</Box>
			) : (
				<Typography>{EMPTY_VALUE}</Typography>
			)}
		</TableCell>
	);
};

SettlementRatingTableCell.propTypes = {
	rating: oneOfType([string, number]),
	cell: object.isRequired,
};
