// ********************  table  ********************

export const choosableRolesUrl = `/v2/account/auth/choosable/roles`;

export const fetchCarsUrl = (itemId, queryParams) =>
	`v2/supplier/business/office/${itemId}/car/list${queryParams}`;

export const exportCarPlatesFileUrl = 'v2/supplier/vehicle/plate/export';

export const importCarPlatesFileUrl = 'v2/supplier/vehicle/plate/import';

export const updateActiveCarUrl = (id, isActive) =>
	`/v2/supplier/business/car/${id}/${isActive ? 'deactivate' : 'activate'}`;

// ********************  form  ********************

export const submitEditCarFormUrl = (companyUuid, carId, itemId, isEdit) => {
	let path = '/v2/supplier/business/car';
	if (isEdit) {
		path += carId ? `/${carId}/update` : `/company/${companyUuid}/update`;
	} else {
		path += itemId ? `/create` : `/company/${companyUuid}/create`;
	}
	return path;
};
export const submitCarsFormUrl = (itemId) =>
	`/v2/supplier/business/car/${itemId}/stock`;
export const fetchVehicleMakerListUrl = '/v2/supplier/vehicle/maker/list';
export const fetchSupplierBusinessCarPriceListUrl =
	'/v2/supplier/business/car/price-list';
export const showSupplierBusinessCarUrl = (carId) =>
	`/v2/supplier/business/car/${carId}/show`;
export const fetchCompanyOfficesCollectionUrl = (uuid, queryParams = null) =>
	`/v2/supplier/business/company/${uuid}/office/collection${queryParams || ''}`;

export const showOfficeDetailsUrl = (userOfficeUuid) =>
	`/v2/supplier/business/office/${userOfficeUuid}/show`;
export const fetchBusinessManagerProfileUrl =
	'/v2/supplier/business/manager/profile';
