import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	formControl: {
		width: '100%',
		margin: '8px 0 4px 0',
	},
	labels: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 4,
	},
	outlinedInput: {
		overflow: 'hidden',
	},
	openOutlinedInput: {
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
	},
	selectedValue: {
		fontWeight: 600,
	},
}));
