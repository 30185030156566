import i18next from 'i18next';
import { isBefore, isEqual } from 'date-fns';

// Import helpers
import { FIELD_TYPES } from 'helpers';

const validateDate = (date) => date instanceof Date && !isNaN(date);

export const validatePeriod = ({ from, until }) =>
	validateDate(from) &&
	validateDate(until) &&
	(isBefore(from, until) || isEqual(from, until));

const multiSelectLabelExtractor = (options, currentUuid) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.name[i18next.language];

export const FILTER_FIELDS = ({
	isOfficeManager,
	isAdmin,
	companies,
	offices,
	values,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'company_uuid',
		label: 'common.fields.company',
		md: 6,
		options: companies,
		isHidden: !isAdmin,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[i18next.language],
	},
	{
		type: FIELD_TYPES.MULTI_SELECT,
		name: 'office_uuids',
		label: 'common.fields.offices',
		md: 6,
		options: offices,
		showAllOption: true,
		disabled: !offices.length,
		isHidden: isOfficeManager,
		multiSelectLabelExtractor,
		currentMultiSelectValues: values?.office_uuids,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[i18next.language],
	},
];
