import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';

// Import helpers
import { hours } from './helpers';

const TimePicker = ({ ...props }) => {
	return (
		<TextField select variant="outlined" margin="dense" {...props}>
			{hours.map(({ value }) => (
				<MenuItem key={value} value={value}>
					{value}
				</MenuItem>
			))}
		</TextField>
	);
};

export default TimePicker;
