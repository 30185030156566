import { TextFieldAdapter, SelectFieldAdapter } from 'components/elements';

export const BASE_DETAILS_FIELDS = [
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'cms.pages.form.details.slug_en',
		name: 'slug.en',
		md: 6,
		xs: 12,
	},
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'cms.pages.form.details.slug_ar',
		name: 'slug.ar',
		md: 6,
		xs: 12,
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'cms.pages.form.details.title_en',
		name: 'title.en',
		md: 6,
		xs: 12,
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'cms.pages.form.details.title_ar',
		name: 'title.ar',
		md: 6,
		xs: 12,
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'cms.pages.form.details.content_en',
		name: 'content.en',
		md: 6,
		xs: 12,
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: 'cms.pages.form.details.content_ar',
		name: 'content.ar',
		md: 6,
		xs: 12,
	},
];
