// Import helpers
import { EN, ENVS, FIELD_TYPES } from 'helpers';

export const BASE_DETAILS_FIELDS = [
	{
		type: FIELD_TYPES.TEXT,
		label: 'cms.pages.form.details.title_en',
		name: 'header_title.en',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'cms.pages.form.details.title_ar',
		name: 'header_title.ar',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'cms.pages.form.details.content_en',
		name: 'header_content.en',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'cms.pages.form.details.content_ar',
		name: 'header_content.ar',
		required: true,
		md: 6,
	},
];

export const getCompanyName = ({ companies, currentCompanyUuid }) => {
	const companyName =
		companies &&
		companies.find(({ uuid }) => uuid === currentCompanyUuid)?.name[EN];

	return companyName && companyName.replaceAll(' ', '_');
};

export const getPartnerUrl = (companyName) => {
	const env = process.env.REACT_APP_RELEASE_STAGE;

	switch (env) {
		case ENVS.DEVELOPMENT:
			return `https://dev.telgani.com/${companyName}`;

		case ENVS.TESTING:
			return `https://uat.telgani.com/${companyName}`;

		default:
			return `https://telgani.com/${companyName}`;
	}
};
