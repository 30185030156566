import { useRef, useState } from 'react';
import _ from 'lodash';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';

export const useFileUploader = ({
	onSuccessCallback,
	onErrorCallback,
	defaultButtonText,
}) => {
	const [buttonText, setButtonText] = useState(defaultButtonText);
	const [isLoading, setLoading] = useState(false);
	const hiddenFileInput = useRef(null);
	const { message } = useMessage();
	const { t } = useTranslations();

	const handleOnButtonClick = () => {
		hiddenFileInput.current.click();
	};

	const handleOnInputChange = async (event) => {
		setLoading(true);
		try {
			const file = event.target.files[0];
			const fileName = _.truncate(file.name, { length: 50 });
			await onSuccessCallback(file);
			setLoading(false);
			setButtonText(fileName);
		} catch (error) {
			onErrorCallback && onErrorCallback(error);
			message.error(t('common.messages.error_message'));
			setLoading(false);
		}
	};

	return {
		handleOnInputChange,
		handleOnButtonClick,
		hiddenFileInput,
		buttonText,
		isLoading,
	};
};
