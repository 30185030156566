import BusinessIcon from '@material-ui/icons/Business';
import { URLS } from 'components/routes';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: () => URLS.addTrainStation,
		icon: BusinessIcon,
		text: 'common.buttons.add_a_new_station',
	},
];
