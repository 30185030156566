import { format } from 'date-fns';

// Import store
import * as types from 'store/types';
import { getHolidays } from 'store/actions/helpers';
import ls from 'localstorage-slim';
import services from 'services/services';
import {
	fetchBusinessCompanyCollectionUrl,
	fetchBusinessManagerProfileUrl,
	businessOfficeDeliveryPricesUrl,
	businessOfficeDeliveryDistancesUrl,
	showBusinessOfficeUrl,
	submitOfficeFormUrl,
	phonePrefixUrl,
} from 'store/paths';

// Import helpers
import { INITIAL_VALUES } from 'views/Offices/EditOffice/helpers';
import { findCompanyUuid } from '../profile/helpers';
import { catchError } from '../helpers';
import {
	INITIAL_DELIVERY_HOURS_VALUES,
	convertTerminals,
	fetchPlaceId,
} from './helpers';
import {
	LOCAL_STORAGE_COMPANIES_LIST,
	LOCAL_STORAGE_DELIVERY_DISTANCES,
	LOCAL_STORAGE_DELIVERY_PRICES,
	LOCAL_STORAGE_PHONE_PREFIXES,
	ADMINS,
	OFFICE_TYPES,
	DEFAULT_DATE_FORMAT,
} from 'helpers';

export const fetchOfficeFormResources =
	({ isEdit, itemId, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.OFFICE_FORM_FETCH_DATA_LOADING,
				payload: true,
			});

			let item = INITIAL_VALUES;
			isEdit && dispatch({ type: types.OFFICE_FORM_FETCH_DATA_LOADING });

			const localStorageDeliveryPrices = ls.get(LOCAL_STORAGE_DELIVERY_PRICES);
			const localStorageDeliveryDistances = ls.get(
				LOCAL_STORAGE_DELIVERY_DISTANCES
			);

			const deliveryPricesPromise =
				!localStorageDeliveryPrices &&
				services.get(businessOfficeDeliveryPricesUrl, cancelToken);

			const deliveryDistancesPromise =
				!localStorageDeliveryDistances &&
				services.get(businessOfficeDeliveryDistancesUrl, cancelToken);

			const localStoragePhonePrefixes = ls.get(LOCAL_STORAGE_PHONE_PREFIXES);
			const phonePrefixesPromise =
				!localStoragePhonePrefixes && services.get(phonePrefixUrl, cancelToken);

			const localStorageCompaniesList = ls.get(
				LOCAL_STORAGE_COMPANIES_LIST('')
			);

			const companyListPromise =
				!localStorageCompaniesList &&
				services.get(fetchBusinessCompanyCollectionUrl, cancelToken);

			const allPromises = [
				deliveryPricesPromise,
				deliveryDistancesPromise,
				phonePrefixesPromise,
				companyListPromise,
			];

			if (itemId) {
				const officePromise = services.get(
					showBusinessOfficeUrl(itemId),
					cancelToken
				);

				allPromises.push(officePromise);
			} else {
				const userDataPromise = services.get(
					fetchBusinessManagerProfileUrl,
					cancelToken
				);

				allPromises.push(userDataPromise);
			}

			const values = await Promise.all(allPromises);

			const [
				{ data: deliveryPrices },
				{ data: deliveryDistances },
				{ data: phonePrefixes },
				{ data: companyList },
			] = values;

			if (!localStoragePhonePrefixes) {
				ls.set(LOCAL_STORAGE_PHONE_PREFIXES, phonePrefixes.data, {
					ttl: 14 * 24 * 60 * 60,
				});
			}

			if (!localStorageDeliveryPrices) {
				ls.set(LOCAL_STORAGE_DELIVERY_PRICES, deliveryPrices.data, {
					ttl: 4 * 60 * 60,
				});
			}

			if (!localStorageDeliveryDistances) {
				ls.set(LOCAL_STORAGE_DELIVERY_DISTANCES, deliveryDistances.data, {
					ttl: 4 * 60 * 60,
				});
			}

			if (!localStorageCompaniesList) {
				ls.set(LOCAL_STORAGE_COMPANIES_LIST(''), companyList.data, {
					ttl: 7 * 24 * 60 * 60,
				});
			}

			const deliveryPricesResult =
				localStorageDeliveryPrices || deliveryPrices.data;
			const deliveryDistancesResult =
				localStorageDeliveryDistances || deliveryDistances.data;

			const companyListResult = localStorageCompaniesList || companyList.data;

			const phonePrefixesResult =
				localStoragePhonePrefixes || phonePrefixes.data;

			if (itemId) {
				const officeData = values[4].data.data;
				const { commission } = officeData;
				if (commission.start_date && commission.end_date) {
					const startDate = new Date(commission.start_date);
					const endDate = new Date(commission.end_date);
					officeData.commission.start_date = startDate;
					officeData.commission.end_date = endDate;
				}

				if (officeData.details.office_type === OFFICE_TYPES.DIGITAL) {
					officeData.service_type.digital_branch = true;
				}

				item = officeData;
			} else {
				const userData = values[4].data.data;

				const { manager, belonging } = userData;
				const { userCompanyUuid } = findCompanyUuid(manager.role, belonging);

				const showCompanySelect = ADMINS.includes(manager.role);

				item = {
					...item,
					showCompanySelect,
					company: { uuid: userCompanyUuid },
				};
			}

			if (!item.delivery_hours || Array.isArray(item.delivery_hours)) {
				item.delivery_hours = INITIAL_DELIVERY_HOURS_VALUES;
			}

			let holidays = [];
			if (item?.company?.uuid) {
				holidays = await getHolidays({ uuid: item.company.uuid, cancelToken });
			}

			item.details.office_type = 'physical'; // TODO - remove when Digital task is merged

			dispatch({
				type: types.OFFICE_FORM_FETCH_DATA_SUCCESS,
				payload: {
					deliveryPrices: deliveryPricesResult,
					deliveryDistances: deliveryDistancesResult,
					phonePrefixes: phonePrefixesResult,
					holidays,
					companyList: companyListResult,
					item: item || {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.OFFICE_FORM_FETCH_DATA_ERROR,
			});
		}
	};

// ******************** Submit office form ********************
export const submitOfficeForm =
	({ values, itemId, isEdit = true, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			// Get city place_id from google maps
			const { address } = values.location;

			const place_id = await fetchPlaceId(address);
			const terminals = convertTerminals(values);

			values.location = {
				...values.location,
				place_id,
				airport: { terminals },
			};

			///////////////////////////////////// HACK /////////////////////////////////

			const newOfficeHours = values.office_hours;
			const tempOfficeHours = {};
			values.office_hours = Object.keys(newOfficeHours).forEach((key) => {
				const day = newOfficeHours[key];
				tempOfficeHours[key] = { ...day, isOpenThird: 0 };
			});

			values.office_hours = tempOfficeHours;

			if (values.service_type.digital_branch) {
				values.details = {
					...values.details,
					office_type: OFFICE_TYPES.DIGITAL,
				};
			}

			values.commission = {
				...values.commission,
				start_date: format(
					new Date(values.commission.start_date),
					DEFAULT_DATE_FORMAT
				),
				end_date: format(
					new Date(values.commission.end_date),
					DEFAULT_DATE_FORMAT
				),
			};

			await services.post(submitOfficeFormUrl(itemId, isEdit), values);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
