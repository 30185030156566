import React from 'react';
import { bool } from 'prop-types';

// Import components
import { AdditionalInfoModal } from 'components/elements';

// Import helpers
import { getAdditionalInfoFields } from './helpers';

export const AdditionalInfo = ({
	showAssignedDriverAdditional = false,
	showDeliveryStatusAdditional = false,
}) => {
	return (
		<AdditionalInfoModal
			fields={getAdditionalInfoFields({
				showAssignedDriverAdditional,
				showDeliveryStatusAdditional,
			})}
		/>
	);
};

AdditionalInfo.propTypes = {
	showAssignedDriverAdditional: bool,
	showDeliveryStatusAdditional: bool,
};
