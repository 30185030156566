import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { bool } from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress, TextFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from '../../styles';

export const Comment = ({ isLoading }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { values } = useFormState();

	const isButtonDisabled = !values.content || isLoading;

	return (
		<Grid item container spacing={3}>
			<Grid item lg={12} xs={12}>
				<Box className={classes.inputs}>
					<Field
						label={t('bookings.preview_booking.supplier.comment')}
						className={classes.commentInput}
						component={TextFieldAdapter}
						name="content"
						type="text"
						maxRows="7"
						fullWidth
						multiline
					/>
					<ButtonProgress
						color="primary"
						isLoading={isLoading}
						disabled={isButtonDisabled}
						type="submit"
						aria-label="send-comment-icon"
					>
						<SendIcon />
					</ButtonProgress>
				</Box>
			</Grid>
		</Grid>
	);
};

Comment.propTypes = {
	isLoading: bool.isRequired,
};
