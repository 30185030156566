import i18next from 'i18next';
import theme from 'theme';

// Import helpers
import { AR } from 'helpers';

export const styles = {
	color: theme?.palette.text.secondary,
	letterSpacing: i18next.language === AR ? 0 : 1.3,
	textTransform: 'uppercase',
	fontWeight: 700,
	fontSize: 12,
};
