import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { bool, func } from 'prop-types';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { EXTEND_BOOKING_FIELDS } from './helpers';
import { useFormContent } from './useFormContent';

// Import components
import { ButtonProgress } from 'components/elements';

export const FormContent = ({ toggle, isButtonLoading }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();

	const { minDropDate } = useFormContent();

	return (
		<Grid container spacing={3}>
			{EXTEND_BOOKING_FIELDS(minDropDate).map(renderFields)}
			<Grid item xs={12}>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					spacing={2}
				>
					<Grid item>
						<Button
							variant="outlined"
							color="primary"
							size="medium"
							onClick={toggle}
						>
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							isLoading={isButtonLoading}
							disabled={isButtonLoading}
							variant="contained"
							color="primary"
							size="medium"
							type="submit"
						>
							{t('common.buttons.save')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isButtonLoading: bool,
	toggle: func,
};
