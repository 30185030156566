import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	form: {
		display: 'grid',
		padding: '12px',
		gap: '12px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	warningMessage: {
		color: theme.palette.warning.main,
		marginBottom: '16px',
	},
	errorMessage: {
		color: theme.palette.error.main,
	},
	available: {
		color: theme.palette.primary.main,
	},
}));
