import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

// Import helpers
import { carFormSelector } from 'store/selectors';
import { RATE_SECTION_TYPES } from 'helpers';

// Import utilities
import { useProfile, useTranslations } from 'components/utilities';

export const useRatings = ({ setSubmitDisabled }) => {
	const { values } = useFormState();
	const { isAdmin } = useProfile();
	const { t } = useTranslations();
	const { change } = useForm();

	const {
		item: { prices: additionalPriceList },
	} = useSelector(carFormSelector);

	const getLabelType = (section) => {
		const splittedSection = section.split('.');
		return splittedSection[splittedSection.length - 1];
	};

	const isInputDisabled = (section, isDays) =>
		isDays && !isAdmin && getLabelType(section) === RATE_SECTION_TYPES.DAILY;

	const getDaysLabel = (section) => {
		const type = getLabelType(section);
		return t(`cars.form.ratings.${type}_price_start_from`);
	};

	const getMenuItemText = (section, item) => {
		const type = getLabelType(section);
		const isSingular = type === RATE_SECTION_TYPES.DAILY;

		return `${item} ${t(`cars.form.ratings.${isSingular ? 'day' : 'days'}`)}`;
	};

	const isAutocompleteSelect = (section, isDays) =>
		isDays && getLabelType(section) === RATE_SECTION_TYPES.ANNUAL;

	const range = (min, max) =>
		Array.from({ length: max - min + 1 }, (_, i) => i + min);

	useEffect(() => {
		const newMonthlyPrices = {
			...values.carInfo.prices.monthly,
			days: 360,
		};

		change('carInfo.prices.annual', newMonthlyPrices);
		// eslint-disable-next-line
	}, [values.carInfo.prices.monthly]);

	const { carInfo } = values || {};

	const { prices, subscription } = carInfo || {};

	const isMonthPriceMoreThanOrEqual3Months = !(
		+prices?.monthly?.discount_nett_price * 30 >=
		+subscription?.prices?.three?.nett_price
	);

	useEffect(() => {
		setSubmitDisabled(isMonthPriceMoreThanOrEqual3Months);
		// eslint-disable-next-line
	}, [isMonthPriceMoreThanOrEqual3Months]);

	return {
		getMenuItemText,
		getDaysLabel,
		additionalPriceList,
		range,
		isAutocompleteSelect,
		isInputDisabled,
		isAdmin,
		isMonthPriceMoreThanOrEqual3Months,
	};
};
