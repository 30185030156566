import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { object } from 'prop-types';
import { TableCell, Link, Box } from '@material-ui/core';

// Import helpers
import { replacePreviewBookingUrl } from 'helpers';

// Import styles
import { useStyles } from './BookingIdsTableCell.styles';

export const BookingIdsTableCell = ({ cell }) => {
	const classes = useStyles();

	const bookingIds = cell.row.original.corporate_limits;
	const mappedBookingIds = bookingIds.map(
		({ booking_individual_number, booking_uuid }) => ({
			bookingId: booking_individual_number,
			uuid: booking_uuid,
		})
	);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Box className={classes.container}>
				{mappedBookingIds.map(({ bookingId, uuid }, index) => (
					<Box key={uuid}>
						<Link
							to={replacePreviewBookingUrl(uuid)}
							component={RouterLink}
							variant="h6"
						>
							{bookingId}
						</Link>
						{index < mappedBookingIds.length - 1 && ', '}
					</Box>
				))}
			</Box>
		</TableCell>
	);
};

BookingIdsTableCell.propTypes = {
	cell: object,
};
