import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		gap: '16px',
		margin: '16px 0',
	},
	list: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '8px',
		width: '100%',
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: '8px',
		padding: '24px',
		maxHeight: '100px',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '6px',
		},
		'&::-webkit-scrollbar-track': {
			backgroundColor: theme.palette.primary.light,
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.primary.main,
			borderRadius: '8px',
		},
	},
	heading: {
		color: theme.palette.gray.main,
		fontSize: '14px',
		fontWeight: '500',
	},
}));
