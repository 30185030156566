import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import utils
import { useInitiateContractProvider } from 'views/Bookings/InitiateContract/context';
import { useMediaQuerySizes, useTranslations } from 'components/utilities';

// Import components
import { CarScratches } from 'components/elements';
import {
	AdditionalServices,
	ContractDetails,
	VehicleDetails,
	CommonSection,
	CarInspection,
	RequiredFieldsSection,
} from '../../components';

// Import helpers
import {
	BOTTOM_CONTRACT_DETAILS_FIELDS,
	TOP_CONTRACT_DETAILS_FIELDS,
	ADDITIONAL_SERVICES_FIELDS,
	PAYMENT_DETAILS_FIELDS,
	CAR_INSPECTION_FIELDS,
	EXTRA_DRIVER_FIELDS,
	VEHICLE_FIELDS,
	RENTER_FIELDS,
	REQUIRED_FIELDS,
} from '../../helpers';
import { isAuthorizationTypeCodeVisible, isHourlyContractType } from 'helpers';

export const useSections = ({ plateError }) => {
	const { change } = useForm();
	const { isTabletSize } = useMediaQuerySizes();
	const {
		formOptions,
		extendedCoverages,
		branches,
		policies,
		vehiclePlates,
		toggleSearchPlateDialog,
	} = useInitiateContractProvider();

	const { values } = useFormState();

	const { t } = useTranslations();

	const isExtendedCoverageId = !!values.contract_details?.extended_coverage_id;

	const contractType = values.authorization_details.contract_type_code;
	const {
		id_type_code: idTypeCode,
		is_extra_driver: isExtraDriver,
		id_number: idNumber,
	} = values.renter;

	const isAuthTypeCodeVisible = isAuthorizationTypeCodeVisible(values);
	const isHourlyType = isHourlyContractType(values);
	const isDriverFarePerHour = contractType === 3;
	const isDriverFarePerDay = contractType === 4;
	const isVehicleTransferCost =
		values.authorization_details.return_branch_id !==
		values.authorization_details.receive_branch_id;
	const isInternationalAuthCostRequired =
		values.authorization_details.authorization_type_code === 2;

	const currentPlateNumber = values.plate;

	const {
		external_authorization_countries: externalAuthorizationCountries,
		narrowed_usable_conditions: narrowedUsableConditions,
		availability_states: availabilityStates,
		authorization_types: authorizationTypes,
		yakeen_nationality: yakeenNationalities,
		working_conditions: workingConditions,
		usable_conditions: usableConditions,
		payment_methods: paymentMethods,
		gcc_nationality: gccNationality,
		contract_types: contractTypes,
		purity_states: purityStates,
		plate_types: plateTypes,
		fuel_types: fuelTypes,
		id_types: idTypes,
	} = formOptions || {};

	const isNationalitySelectHidden = idTypeCode !== 3 && idTypeCode !== 4;
	const isVisitorOrGcc = idTypeCode === 3 || idTypeCode === 4;
	const isCitizen = Boolean(`${idNumber}`?.startsWith('1'));
	const nationalityOptions =
		idTypeCode === 3 ? yakeenNationalities : gccNationality;

	const sections = [
		{
			section: 'required_fields',
			children: RequiredFieldsSection,
			fields: REQUIRED_FIELDS({
				vehiclePlates,
				currentPlateNumber,
				extendedCoverages,
				policies,
				toggleSearchPlateDialog,
				plateError,
			}),
			defaultMinimize: false,
		},
		{
			section: 'extra_driver',
			children: CommonSection,
			fields: EXTRA_DRIVER_FIELDS({
				isNationalitySelectHidden,
				nationalityOptions,
				idTypes,
				isTabletSize,
			}),
			isHidden: !isExtraDriver,
			defaultMinimize: false,
		},
		{
			section: 'renter',
			children: CommonSection,
			fields: RENTER_FIELDS({
				isNationalitySelectHidden,
				nationalityOptions,
				isVisitorOrGcc,
				idTypes,
				isCitizen,
			}),
			defaultMinimize: true,
		},

		{
			section: 'vehicle_details',
			children: VehicleDetails,
			fields: VEHICLE_FIELDS({ plateTypes }),
			defaultMinimize: true,
		},
		{
			section: 'car_scratches',
			children: CarScratches,
			fields: [],
			defaultMinimize: true,
		},
		{
			section: 'car_inspection',
			children: CarInspection,
			fields: CAR_INSPECTION_FIELDS({
				availabilityStates,
				narrowedUsableConditions,
				isExtendedCoverageId,
				workingConditions,
				usableConditions,
				purityStates,
				fuelTypes,
				t,
				isTabletSize,
			}),
			defaultMinimize: true,
		},
		{
			section: 'additional_services',
			children: AdditionalServices,
			fields: ADDITIONAL_SERVICES_FIELDS,
			defaultMinimize: true,
		},
		{
			section: 'contract_details',
			children: ContractDetails,
			fields: {
				topFields: TOP_CONTRACT_DETAILS_FIELDS({
					isAuthTypeCodeVisible,
					authorizationTypes,
					contractTypes,
				}),
				bottomFields: BOTTOM_CONTRACT_DETAILS_FIELDS({
					isHourlyType,
					branches,
					isTabletSize,
				}),
			},
			externalAuthorizationCountries,
			defaultMinimize: true,
		},
		{
			section: 'payment_details',
			children: CommonSection,
			fields: PAYMENT_DETAILS_FIELDS({
				isExtendedCoverageId,
				isInternationalAuthCostRequired,
				isVehicleTransferCost,
				isDriverFarePerHour,
				isDriverFarePerDay,
				isHourlyType,
				paymentMethods,
				isExtraDriver,
				isTabletSize,
			}),
			defaultMinimize: true,
		},
	];

	// Setting default values
	useEffect(() => {
		if (branches.length) {
			change('authorization_details.working_branch_id', branches[0].id);
			change('authorization_details.receive_branch_id', branches[0].id);
			change('authorization_details.return_branch_id', branches[0].id);
		}
		//eslint-disable-next-line
	}, [branches]);

	useEffect(() => {
		if (extendedCoverages.length === 1) {
			change('contract_details.extended_coverage_id', extendedCoverages[0].id);
		}
		//eslint-disable-next-line
	}, [extendedCoverages]);

	useEffect(() => {
		if (policies.length === 1) {
			change('contract_details.rent_policy_id', policies[0].id);
		}
		//eslint-disable-next-line
	}, [policies]);

	useEffect(() => {
		if (plateTypes.length) {
			change('vehicle_details.plate_type', plateTypes[0].id);
		}
		//eslint-disable-next-line
	}, [plateTypes]);

	useEffect(() => {
		if (paymentMethods.length) {
			change('payment_details.payment_method_code', paymentMethods[4].id);
		}
		//eslint-disable-next-line
	}, [paymentMethods]);

	useEffect(() => {
		if (authorizationTypes.length) {
			change(
				'authorization_details.authorization_type_code',
				authorizationTypes[0].id
			);
		}
		//eslint-disable-next-line
	}, [authorizationTypes]);

	useEffect(() => {
		if (usableConditions.length) {
			change('rent_status.ac', usableConditions[0].id);
		}
		//eslint-disable-next-line
	}, [usableConditions]);

	return { sections };
};
