import services from 'services/services';

// Import helpers
import { fetchDriverLicenseUrl, updateDriverLicenseUrl } from 'store/paths';
import { convertUpdateDriverLicenseSubmitData } from './helpers';

export const fetchDriversLicenseList =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			// Get response
			const { data } = await services.post(
				fetchDriverLicenseUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateDriverLicense = async ({
	successCallback,
	errorCallback,
	customerUuid,
	values,
}) => {
	try {
		const convertedValues = convertUpdateDriverLicenseSubmitData(values);
		const res = await services.put(
			updateDriverLicenseUrl(customerUuid),
			convertedValues
		);
		const { uuid: licenseUuid } = res.data.data || {};

		successCallback(licenseUuid);
	} catch (error) {
		if (error.response) {
			const apiErrors = error.response.data.errors;
			errorCallback(apiErrors);
		}
	}
};
