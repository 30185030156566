import { format } from 'date-fns';
import { omit } from 'lodash';

// Import helpers
import { convertPlate, YMD_DATE_FORMAT } from 'helpers';

export const convertSubmitValues = (values) => {
	const { addPlatePayload } = convertPlate(values.plate);
	const convertedValues = {
		...values,
		...addPlatePayload,
	};

	if (values.last_open_contract_date) {
		convertedValues.last_open_contract_date = format(
			new Date(values.last_open_contract_date),
			YMD_DATE_FORMAT
		);
	}

	return omit(convertedValues, ['plate']);
};

export const convertFetchData = (data) => {
	const lastOpenContractDate = data.last_open_contract_date;

	const { fullPlateFromApi } = convertPlate(data);

	return {
		...data,
		last_open_contract_date: lastOpenContractDate
			? new Date(lastOpenContractDate)
			: null,
		plate: fullPlateFromApi,
	};
};
