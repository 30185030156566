import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	OFFICES: 'office_count',
	ACTIVE: 'active',
	ROLE: 'role',
};

export const columns = [
	{
		Header: 'company_managers.table.headers.name',
		accessor: 'full_name',
	},
	{
		Header: 'company_managers.table.headers.phone_number',
		accessor: 'phone',
	},
	{
		Header: 'company_managers.table.headers.email_id',
		accessor: 'email',
	},
	{
		Header: 'company_managers.table.headers.role',
		accessor: COLUMNS_ACCESSORS.ROLE,
	},
	{
		Header: 'company_managers.table.headers.offices',
		accessor: COLUMNS_ACCESSORS.OFFICES,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
