import { makeStyles } from '@material-ui/styles';

export const customerInfoFields = (lng) => [
	{
		name: 'details.full_name',
		label: 'customers.preview.customer.name',
	},
	{
		name: 'details.full_arabic_name',
		label: 'customers.preview.customer.arabic_name',
	},
	{
		name: 'details.phone',
		label: 'customers.preview.customer.phone_number',
	},
	{
		name: 'details.email',
		label: 'customers.preview.customer.email',
	},
	{
		name: 'details.age',
		label: 'customers.preview.customer.age',
	},
	{
		name: 'details.birth_date',
		label: 'customers.preview.customer.birth_date',
	},
	{
		name: 'details.license.license_number',
		label: 'customers.preview.customer.license_number',
	},
	{
		name: 'details.license.license_expire_date',
		label: 'customers.preview.customer.license_expiry_date',
	},
	{
		name: 'details.license.file_path',
		label: 'customers.preview.customer.driver_license',
		isDriverLicense: true,
	},
	{
		name: `details.gender.name.${lng}`,
		label: 'customers.preview.customer.gender',
	},
	{
		name: 'details.address.country',
		label: 'customers.preview.customer.nationality',
	},
	{
		name: 'details.created_at',
		label: 'customers.preview.customer.sign_up_date',
	},
	{
		name: 'booking.last_created',
		label: 'customers.preview.customer.last_created_booking',
	},
	{
		name: 'details.type.type',
		label: 'customers.preview.customer.user_type',
	},
	{
		name: 'details.rating',
		label: 'customers.preview.customer.rating',
	},
	{
		name: 'details.credit_card.last4',
		label: 'customers.preview.customer.credit_card_info',
	},
];

export const FIELDS_CUSTOMER_BOOKING_INFO = [
	{
		name: 'booking.total_count',
		label: 'customers.preview.booking.total_no_of_bookings',
	},
	{
		name: 'booking.total_accepted_count',
		label: 'customers.preview.booking.total_no_accepted_bookings',
	},
	{
		name: 'booking.total_customer_cancelled_count',
		label: 'customers.preview.booking.total_no_canceled_by_customer',
	},
	{
		name: 'booking.total_company_cancelled_count',
		label: 'customers.preview.booking.total_no_canceled_by_company',
	},
	{
		name: 'booking.total_customer_no_show_count',
		label: 'customers.preview.booking.total_no_show_by_customer',
	},
	{
		name: 'booking.location',
		label: 'customers.preview.booking.location_of_booking',
	},
];

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		minHeight: '300px',
	},
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),
		minWidth: '105px',
		display: 'inline-block',
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 9999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));
