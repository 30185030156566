import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	menuItem: {
		padding: '10px 0',
		color: theme.palette.primary.main,
		'&:hover': {
			textDecoration: 'none',
		},
	},
	menuContainer: {
		marginTop: 10,
	},
}));
