import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { array, bool, func, string } from 'prop-types';
import {
	ClickAwayListener,
	CircularProgress,
	ButtonGroup,
	MenuItem,
	MenuList,
	Button,
	Popper,
	Paper,
	Grow,
	Box,
} from '@material-ui/core';

// Import utils
import { useSplitButton } from './useSplitButton';

// Import styles
import { useStyles } from './SplitButton.styles';

export const SplitButton = ({
	additionalOptionText = '',
	isLoading = false,
	options,
	onClick,
}) => {
	const classes = useStyles();
	const {
		handleMenuItemClick,
		handleClose,
		selectedIndex,
		getOption,
		anchorRef,
		toggle,
		open,
	} = useSplitButton({ additionalOptionText });

	return (
		<Box className={classes.container}>
			<ButtonGroup
				className={classes.buttonGroup}
				disabled={isLoading}
				variant="contained"
				color="primary"
				ref={anchorRef}
			>
				<Button
					onClick={() => onClick(options[selectedIndex])}
					className={classes.button}
				>
					{isLoading && <CircularProgress size={16} />}
					<Box>
						{getOption({
							option: options[selectedIndex],
							additionalOptionText,
						})}
					</Box>
				</Button>
				<Button onClick={toggle} color="primary" size="small">
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				anchorEl={anchorRef.current}
				className={classes.popper}
				role={undefined}
				disablePortal
				transition
				open={open}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu">
									{options.map((option, index) => (
										<MenuItem
											onClick={() => handleMenuItemClick(index)}
											selected={index === selectedIndex}
											key={option}
										>
											{option}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Box>
	);
};

SplitButton.propTypes = {
	additionalOptionText: string,
	options: array.isRequired,
	onClick: func.isRequired,
	isLoading: bool,
};
