import { useQuery } from 'react-query';

// Import helpers and utils
import { TRAIN_STATION_COLLECTION_QUERY } from 'helpers';
import services from 'services/services';

export const useTrainStationCollection = ({ enabled }) => {
	const path = '/railway/train-station/collection';

	const { data, isError, isFetching } = useQuery(
		[TRAIN_STATION_COLLECTION_QUERY],
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			enabled,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
