import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	loader: {
		height: '100%',
		width: '100%',
		zIndex: 9999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));
