import React from 'react';
import { Grid, Box } from '@material-ui/core';

// Import components
import {
	BookingsStatsBySource,
	CompanyStatsTable,
	TwoStatesSection,
	ServiceTypeChart,
	AdminStatsTable,
	MostRentedCars,
	Filters,
	Rating,
} from './components';
import RoleProtected from 'components/hoc/RoleProtected';
import { useDashboardContent } from './useDashboardContent';

// Import helpers
import {
	COMPANY_WORKERS_WITHOUT_PARENTS,
	OFFICE_WORKERS_WITHOUT_PARENTS,
	COMPANY_MANAGER,
	ADMINS,
} from 'helpers';

export const DashboardContent = () => {
	const {
		isCancelledToAcceptedRatingLoading,
		isDeliveriesSuccessRateLoading,
		isCancellationRateLoading,
		isBookingsByStateLoading,
		isAcceptanceRateLoading,
		isReportRevenueLoading,
		isRatingLoading,
		isCancelledToAcceptedRatingError,
		isDeliveriesSuccessRateError,
		isCancellationRateError,
		isBookingsByStateError,
		isAcceptanceRateError,
		isReportRevenueError,
		isRatingError,
		bookingsByState,
		cancelledToAcceptedRating,
		deliveriesSuccessRate,
		cancellationRate,
		acceptanceRate,
		reportRevenue,
		rating,
		filters,
	} = useDashboardContent();

	return (
		<Box p={4}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Filters />
				</Grid>

				<Grid item md={4} xs={6}>
					<Rating
						data={cancellationRate}
						isLoading={isCancellationRateLoading}
						isError={isCancellationRateError}
						title="cancellation_rate"
					/>
				</Grid>

				<Grid item md={4} xs={6}>
					<Rating
						data={acceptanceRate}
						isLoading={isAcceptanceRateLoading}
						isError={isAcceptanceRateError}
						title="acceptance_rate"
					/>
				</Grid>

				<Grid item md={4} xs={6}>
					<Rating
						data={deliveriesSuccessRate}
						isLoading={isDeliveriesSuccessRateLoading}
						isError={isDeliveriesSuccessRateError}
						title="deliveries_success_rate"
					/>
				</Grid>

				<Grid item md={4} xs={6}>
					<Rating
						data={rating}
						isLoading={isRatingLoading}
						isError={isRatingError}
						title="rating"
					/>
				</Grid>

				<Grid item md={4} xs={6}>
					<Rating
						data={cancelledToAcceptedRating}
						isLoading={isCancelledToAcceptedRatingLoading}
						isError={isCancelledToAcceptedRatingError}
						title="cancelled_to_accepted_rating"
					/>
				</Grid>

				<Grid item md={4} xs={6}>
					<BookingsStatsBySource />
				</Grid>

				<Grid item md={4} xs={6}>
					<TwoStatesSection
						title="dashboard.total_revenue.title"
						isLoading={isReportRevenueLoading}
						isError={isReportRevenueError}
						data={reportRevenue}
					/>
				</Grid>

				<Grid item md={4} xs={6}>
					<TwoStatesSection
						title="dashboard.bookings_by_state.title"
						isLoading={isBookingsByStateLoading}
						isError={isBookingsByStateError}
						data={bookingsByState}
					/>
				</Grid>

				<Grid item md={6} xs={12}>
					<ServiceTypeChart />
				</Grid>

				<Grid item xs={12} lg={6}>
					<MostRentedCars />
				</Grid>

				<RoleProtected
					roles={[
						...COMPANY_WORKERS_WITHOUT_PARENTS,
						...OFFICE_WORKERS_WITHOUT_PARENTS,
					]}
				>
					<Grid item xs={12} lg={6}>
						<MostRentedCars isCapital />
					</Grid>
				</RoleProtected>

				<RoleProtected roles={[COMPANY_MANAGER]}>
					<Grid item xs={12}>
						<CompanyStatsTable />
					</Grid>
				</RoleProtected>
				<RoleProtected roles={ADMINS}>
					<Grid item xs={12}>
						<AdminStatsTable />
					</Grid>
					{filters.company_uuid && (
						<Grid item xs={12}>
							<CompanyStatsTable />
						</Grid>
					)}
				</RoleProtected>
			</Grid>
		</Box>
	);
};
