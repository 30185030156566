import React from 'react';
import { string, array } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button, Box, Grid } from '@material-ui/core';

// Import utilities
import { useTranslations, useToggle } from 'components/utilities';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

// Import styles
import useStyles from './styles';

const Toolbar = ({ className, content, ...rest }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { on, toggle } = useToggle();

	//eslint-disable-next-line
	const renderButton = ({ icon: Icon, text, onClick = () => {} }) => (
		<Button
			className={classes.button}
			color="primary"
			variant="contained"
			onClick={onClick}
		>
			{Icon && <Icon className={classes.icon} />}
			{t(text)}
		</Button>
	);

	//eslint-disable-next-line
	const renderToolbarContent = ({
		to,
		icon,
		text,
		variant,
		component: Component,
		componentProps = {},
		customKey,
	}) => {
		switch (variant) {
			case TOOLBAR_VARIANTS.NAV:
				return (
					<Grid item xs={12} key={to} className={classes.row}>
						<NavLink exact to={to}>
							{renderButton({ icon, text })}
						</NavLink>
					</Grid>
				);
			case TOOLBAR_VARIANTS.MODAL:
				return (
					<Grid item xs={12} key={text} className={classes.row}>
						{renderButton({ icon, text, onClick: toggle })}
						<Component open={on} close={toggle} {...componentProps} />
					</Grid>
				);
			case TOOLBAR_VARIANTS.CUSTOM:
				return (
					<Grid item xs={12} key={customKey} className={classes.row}>
						<Component {...componentProps} />
					</Grid>
				);
			default:
				break;
		}
	};

	return (
		<Box {...rest} className={className}>
			<Grid container spacing={2}>
				{content.map(renderToolbarContent)}
			</Grid>
		</Box>
	);
};

Toolbar.propTypes = {
	className: string,
	content: array.isRequired,
};

export default Toolbar;
