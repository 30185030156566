import services from 'services/services';
import {
	fetchDiscountsUrl,
	updateActiveDiscountUrl,
	deleteDiscountUrl,
} from 'store/paths';

export const fetchDiscounts = ({
	options,
	queryParams,
	itemId,
	cancelToken,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		// Get response
		const { data } = await services.post(
			fetchDiscountsUrl(queryParams, itemId),
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveDiscount = ({ isActive, id }) => async () => {
	await services.put(updateActiveDiscountUrl(id, isActive));
};

export const deleteDiscount = ({ id }) => async () => {
	await services.delete(deleteDiscountUrl(id));
};
