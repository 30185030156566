import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	dialogHeader: {
		fontSize: '26px',
		marginBottom: '10px',
		fontWeight: '600',
	},
	strong: {
		margin: theme.spacing(1),
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(0.5),
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default useStyles;
