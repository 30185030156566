import { useQuery } from 'react-query';
import services from 'services/services';
import { CAMPAIGN_CONDITIONS_QUERY } from 'helpers';

const useCampaignConditions = ({ campaignType, options = {} }) => {
	const path = `v2/customer/wallet-campaign/${campaignType}/condition`;

	const { data, isFetching } = useQuery(
		[CAMPAIGN_CONDITIONS_QUERY, campaignType, JSON.stringify(options)],
		async () => await services.post(path, options),
		{
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000,
			enabled: !!campaignType,
		}
	);

	const fetchedData = data ? data.data.data.condition : {};

	return { data: fetchedData, isFetching };
};

export default useCampaignConditions;
