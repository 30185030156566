import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		padding: 12,
	},
	paginationActions: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
	},
	paginationButton: {
		borderRadius: 10,
		color: theme.palette.text.secondary,
	},
	sideButton: {
		width: 32,
		height: 32,
		margin: '0 3px',
		backgroundColor: '#F4F8FD',
		'& svg': {
			fontSize: 30,
		},
	},
	select: {
		'& .MuiSelect-select': {
			backgroundColor: 'transparent',
		},
	},
	rtlIcon: {
		transform: 'scaleX(-1)',
	},
}));
