import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@react-google-maps/api';
import { TextField } from '@material-ui/core';

// Import components
import useLocationAutocomplete from './useLocationAutocomplete';

const LocationAutocomplete = ({ value, onChange }) => {
	const {
		handleLoad,
        handleChange,
        inputRef
	} = useLocationAutocomplete({ value, onChange });

	return (
		<Autocomplete
			fields={['geometry.location', 'formatted_address']}
			onLoad={handleLoad}
			onPlaceChanged={handleChange}
		>
			<TextField fullWidth variant="outlined" inputRef={inputRef} />
		</Autocomplete>
	);
};

LocationAutocomplete.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func.isRequired
};

export default LocationAutocomplete;
