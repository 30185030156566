import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	DISCOUNTS: 'discounts',
};

// translations here
export const columns = ({ lng }) => [
	{
		Header: 'discounts.table.headers.organization_name',
		accessor: `organization_name.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'discounts.table.headers.company_name',
		accessor: `company_name.${lng}`,
		disableSortBy: true,
	},
	{
		Header: 'discounts.table.headers.discount',
		accessor: 'discount',
	},
	{
		Header: 'discounts.table.headers.max_discount',
		accessor: 'max_discount',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
