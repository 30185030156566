import React from 'react';
import { Typography, Link, Box, Divider } from '@material-ui/core';

// Import components
import { Steps } from './components';
import { DriverDialog } from 'components/elements';

// Import styles
import { useStyles } from './PickUpAddressPreviewField.styles';

// Import utilities
import { usePickUpAddressPreviewField } from './usePickUpAddressPreviewField';
import { useTranslations } from 'components/utilities';
import { DeliveryLocationProvider } from './context';

export const PickUpAddressPreviewField = () => {
	const { t } = useTranslations();

	const { availableDeliveryData, showDeliverySteps, deliveryUuids, getLabel } =
		usePickUpAddressPreviewField();

	const classes = useStyles();

	return (
		<DeliveryLocationProvider deliveryUuids={deliveryUuids}>
			{availableDeliveryData.map(
				({ deliveryType, deliveryLink, address }, index) => (
					<Box key={deliveryType} className={classes.container}>
						<Box className={classes.descriptionWrapper}>
							<Typography variant="h5">{t(getLabel(deliveryType))}</Typography>
							<Box className={classes.linkWrapper}>
								<Link href={deliveryLink} target="_blank" rel="noopener">
									{address}
								</Link>
								<DriverDialog deliveryType={deliveryType} />
							</Box>
						</Box>

						{showDeliverySteps(deliveryType) && (
							<Steps deliveryType={deliveryType} />
						)}

						{index !== availableDeliveryData.length - 1 && (
							<Divider className={classes.divider} />
						)}
					</Box>
				)
			)}
		</DeliveryLocationProvider>
	);
};
