import React from 'react';
import clsx from 'clsx';
import { func, string } from 'prop-types';
import { useFormState } from 'react-final-form';
import { Field } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	Typography,
	InputAdornment,
	MenuItem,
} from '@material-ui/core';

// Import helpers and utils
import { RATING_FIELDS, RATING_SECTIONS, getRatingData } from './helpers';
import { useTranslations } from 'components/utilities';
import { useRatings } from './useRatings';
import { RATE_SECTION_TYPES } from 'helpers';

// Import components
import { AutocompleteSelect } from './components';

// Import styles
import { useStyles } from './Ratings.styles';

const Ratings = ({
	className,
	section,
	setSubmitDisabled = () => {},
	...rest
}) => {
	const { submitting } = useFormState();
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		getMenuItemText,
		getDaysLabel,
		additionalPriceList,
		range,
		isAutocompleteSelect,
		isInputDisabled,
		isAdmin,
		isMonthPriceMoreThanOrEqual3Months,
	} = useRatings({ setSubmitDisabled });

	const renderRatings = () =>
		RATING_SECTIONS(section).map(({ section, title, type }) => {
			const { daysFrom, daysTo, averagePrice, popularPrice } = getRatingData({
				section,
				prices: additionalPriceList,
			});

			const menuItems = range(daysFrom, daysTo);

			return (
				<Grid item xs={12} key={section}>
					<Card {...rest} className={clsx(className)}>
						<CardHeader title={t(title)} />
						<Divider />
						<CardContent>
							<Grid container spacing={3}>
								{renderFields({
									section,
									averagePrice,
									popularPrice,
									menuItems,
								})}
							</Grid>

							{type === RATE_SECTION_TYPES.MONTHLY &&
								isMonthPriceMoreThanOrEqual3Months && (
									<Typography className={classes.monthlyRateHint}>
										{t('common.validation.monthly_rate_validation')}
									</Typography>
								)}
						</CardContent>
					</Card>
				</Grid>
			);
		});

	const renderFields = ({ section, averagePrice, popularPrice, menuItems }) =>
		RATING_FIELDS({
			section,
			average: averagePrice,
			popular: popularPrice,
			isAdmin,
		}).map(
			({
				name,
				label,
				title,
				type,
				component,
				price,
				md,
				isField,
				isDays,
				disabled,
			}) => (
				<Grid key={name || title} item md={md} xs={12}>
					{isField ? (
						isAutocompleteSelect(section, isDays) ? (
							<AutocompleteSelect
								options={menuItems}
								name={name}
								label={getDaysLabel(section)}
							/>
						) : (
							<Field
								fullWidth
								component={component}
								type={type}
								label={isDays ? getDaysLabel(section) : t(label)}
								name={name}
								margin="dense"
								variant="outlined"
								disabled={
									isInputDisabled(section, isDays) || submitting || disabled
								}
								required
								inputProps={!isDays ? { min: 0, step: 0.01 } : null}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{!isDays && t('currency.sar')}
										</InputAdornment>
									),
									// eslint-disable-next-line
								}}
							>
								{isDays &&
									menuItems.map((item) => (
										<MenuItem key={item} value={item}>
											{getMenuItemText(section, item)}
										</MenuItem>
									))}
							</Field>
						)
					) : (
						<>
							<Typography variant="h6" color="primary">
								{`${t(title)}`}
							</Typography>
							<Typography color="primary">
								{price ? `${price} ${t('currency.sar')}` : t('common.unknown')}
							</Typography>
						</>
					)}
				</Grid>
			)
		);

	return <Grid container>{renderRatings()}</Grid>;
};

Ratings.propTypes = {
	section: string.isRequired,
	className: string,
	setSubmitDisabled: func,
};

export default Ratings;
