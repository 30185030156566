import React from 'react';
import PropTypes, { array, arrayOf, node, oneOf, oneOfType } from 'prop-types';
import { useSelector } from 'react-redux';
import { createHasAnyRoleSelector } from 'store/selectors/auth';

const MODE = {
	ALLOWED: 'allowed',
	FORBIDDEN: 'forbidden',
};

const RoleProtected = ({
	roles,
	mode = MODE.ALLOWED,
	fallback = null,
	children,
}) => {
	const hasAnyRoleSelector = React.useMemo(() => {
		return createHasAnyRoleSelector(roles);
	}, [roles]);

	const hasAnyRole = useSelector(hasAnyRoleSelector);

	const isAuthorized = React.useMemo(() => {
		switch (mode) {
			case MODE.ALLOWED:
				return hasAnyRole;
			case MODE.FORBIDDEN:
				return !hasAnyRole;
			default:
				return false;
		}
	}, [mode, hasAnyRole]);

	if (isAuthorized) {
		return children;
	}

	return fallback;
};

RoleProtected.propTypes = {
	mode: oneOf([MODE.ALLOWED, MODE.FORBIDDEN]),
	roles: array.isRequired,
	fallback: oneOfType([node, arrayOf(PropTypes.node)]),
	children: oneOfType([node, arrayOf(PropTypes.node)]).isRequired,
};

export default RoleProtected;
