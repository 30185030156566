import services from 'services/services';
import * as types from 'store/types';
import {
	fetchManufacturerFormResourcesUrl,
	submitManufacturerFormUrl,
} from 'store/paths';

// Import helpers
import { catchError } from '../../helpers';

export const fetchManufacturerFormResources = ({
	itemId,
	isEdit,
	cancelToken,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.MANUFACTURER_FORM_FETCH_DATA_LOADING,
			payload: true,
		});

		const { data } =
			isEdit &&
			(await services.get(
				fetchManufacturerFormResourcesUrl(itemId),
				cancelToken
			));

		dispatch({
			type: types.MANUFACTURER_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: data || {},
			},
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.MANUFACTURER_FORM_FETCH_DATA_ERROR,
		});
	}
};

export const submitManufacturerForm = ({
	values,
	itemId,
	callbackSuccess,
	errorCallback,
	isEdit,
}) => async () => {
	try {
		const { name, seo, auto } = values;
		const body = { name, seo, auto };

		await services.post(submitManufacturerFormUrl(isEdit, itemId), body);

		callbackSuccess();
	} catch (error) {
		const errors = error.response.data.errors;
		if (errors['auto.maker_id']) {
			errors['name.en'] = errors['auto.maker_id'];
		}
		error.response && errorCallback(errors);
	}
};
