import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import actions
import { deleteFaq, updateActiveFaq } from 'store/actions';

// Import utils and helpers
import { replaceEditFaqUrl, TABLE_ACTIONS_ACCESSORS } from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';
import { useTranslations } from 'components/utilities';

// Import components
import {
	EditTableCell,
	DeleteTableCell,
	ActiveTableCell,
} from 'components/elements';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;
const { ACTIVE, NAME } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const {
		i18n: { language: lng },
	} = useTranslations();
	const { getRowProps } = row || {};

	const renderCell = (cell) => {
		const {
			column: { id: columnId, isHidden },
			row: {
				original: { uuid, is_active: isActive, name },
			},
			getCellProps,
		} = cell || {};

		if (isHidden) return null;

		switch (columnId) {
			case NAME:
				return <TableCell key={columnId}>{name[lng]}</TableCell>;
			case ACTIVE:
				return (
					<ActiveTableCell
						key={columnId}
						activeAction={updateActiveFaq}
						cell={cell}
						isActive={isActive}
					/>
				);
			case EDIT:
				return (
					<EditTableCell
						key={columnId}
						cell={cell}
						editLinkPath={replaceEditFaqUrl(uuid)}
					/>
				);
			case DELETE:
				return (
					<DeleteTableCell
						key={columnId}
						deleteAction={deleteFaq}
						cell={cell}
					/>
				);
			default:
				return (
					<TableCell key={columnId} {...getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
