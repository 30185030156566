export const INITIAL_VALUES = {
	booking: {
		uuid: '',
	},
	source: {
		link: '',
	},
	screenshot: {
		file_path: '',
		file_name: '',
	},
};

export const getErrorMessage = (error) => {
	const { errors } = error.response.data;

	const errorMessage = errors.booking
		? errors.booking[0][0]
		: errors['booking.uuid'] && errors['booking.uuid'][0];

	return errorMessage;
};
