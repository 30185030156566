import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	actionIcon: {
		'&.MuiGrid-item': {
			padding: 0,
			marginTop: '4px',
		},
	},
	alert: {
		marginBottom: 0,
	},
	headerTitleContainer: {
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			alignItems: 'center',
			gap: '12px',
			flexWrap: 'wrap',
		},
	},
}));
