import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button, CircularProgress } from '@material-ui/core';
import { previewBookingSelector } from 'store/selectors';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Import components
import PreviewField from '../PreviewField';
import { CustomerWalletDialog } from './components';

// Import utilities
import { useToggle } from 'components/utilities';
import useCustomerWalletPreviewField from './useCustomerWalletPreviewField';

// Import styles
import useStyles from '../styles';

const CustomerWalletPreviewField = ({ label }) => {
	const { on, toggle } = useToggle();

	const { customer } = useSelector(previewBookingSelector);

	const classes = useStyles();

	const { balance, isError, errorMessage, isFetched } =
		useCustomerWalletPreviewField({
			uuid: customer.uuid,
		});

	return (
		<PreviewField label={label}>
			{isFetched ? (
				<Button
					disabled={isError}
					onClick={toggle}
					color="primary"
					className={classes.button}
					endIcon={<ChevronRightIcon />}
				>
					{isError ? errorMessage : balance}
				</Button>
			) : (
				<CircularProgress size={28} />
			)}
			<CustomerWalletDialog on={on} toggle={toggle} uuid={customer.uuid} />
		</PreviewField>
	);
};

CustomerWalletPreviewField.propTypes = {
	label: PropTypes.string,
};

export default CustomerWalletPreviewField;
