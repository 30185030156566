import { useFormState } from 'react-final-form';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { FAQ_PAGE_LOCATION, FAQ_SERVICE_TYPE } from 'helpers';

export const useFaqDetails = () => {
	const { t } = useTranslations();

	const { values } = useFormState();

	const { faq_location: selectedPages, service_type: selectedServiceTypes } =
		values || {};

	const pages = [
		{
			value: FAQ_PAGE_LOCATION.HOME,
			label: t('faq.form.fields.home'),
		},
		{
			value: FAQ_PAGE_LOCATION.CAR_DETAILS,
			label: t('faq.form.fields.car_details'),
		},
	];

	const serviceTypes = [
		{
			value: FAQ_SERVICE_TYPE.DAILY,
			label: t('faq.form.fields.service_types.daily'),
		},
		{
			value: FAQ_SERVICE_TYPE.SUBSCRIPTION,
			label: t('faq.form.fields.service_types.subscription'),
		},
		{
			value: FAQ_SERVICE_TYPE.LEASING,
			label: t('faq.form.fields.service_types.leasing'),
		},
		{
			value: FAQ_SERVICE_TYPE.AIRPORT,
			label: t('faq.form.fields.service_types.airport'),
		},
		{
			value: FAQ_SERVICE_TYPE.TRAIN_STATION,
			label: t('faq.form.fields.service_types.train_station'),
		},
	];

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid.includes('all')
			? null
			: options.find(({ value }) => value === currentUuid)?.label;

	return {
		selectedPages,
		selectedServiceTypes,
		pages,
		multiSelectLabelExtractor,
		serviceTypes,
	};
};
