import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

// Import actions
import { changeIsCompensationFulfilled } from 'store/actions';

// Import helpers
import { getErrorMessage } from 'helpers';

export const useFulfilledCompensation = ({ uuid, fulfill }) => {
	const [isFulfilled, setIsFulfilled] = useState(fulfill);

	const [mutate, { error, isError }] = useMutation((data) =>
		changeIsCompensationFulfilled(data)
	);

	const handleToggle = async () => {
		setIsFulfilled(!isFulfilled);

		mutate({
			uuid,
			fulfill: !isFulfilled,
		});
	};

	useEffect(() => {
		isError && setIsFulfilled(fulfill);
		// eslint-disable-next-line
	}, [isError]);

	return {
		isFulfilled,
		error: getErrorMessage(error),
		isError,
		handleToggle,
	};
};
