import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	header: {
		display: 'flex',
		flexDirection: 'column',
		gap: '22px',
	},
	scratch: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		gap: '16px',
	},
	scratchIcon: {
		marginTop: '4px',
		width: '13px',
		height: '13px',
	},
	scratchTitle: {
		fontSize: '14px',
		fontWeight: '500',
		color: theme.palette.text.primary,
	},
	active: {
		color: theme.palette.primary.main,
	},
	activeText: {
		color: theme.palette.primary.main,
		fontSize: '10px',
	},
}));
