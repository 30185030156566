import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

const multiSelectLabelExtractor = (options, currentUuid) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.name[i18next.language];

export const FIELDS = ({ companies, values }) => [
	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		name: 'company_uuid',
		label: 'waiting_customer_info.filters.company_name',
		md: 3,
		options: companies,
		showAllOption: true,
		currentMultiSelectValues: values?.company_uuid,
		searchExtractor: 'children[1]',
		multiSelectLabelExtractor,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[i18next.language],
	},
];
