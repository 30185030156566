import AddCircleIcon from '@material-ui/icons/AddCircle';
import { OrganizationForm } from './OrganizationForm';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.MODAL,
		text: 'organizations.table.toolbar.add',
		icon: AddCircleIcon,
		component: OrganizationForm,
	},
];
