// Import helpers
import { TOOLBAR_VARIANTS, CAMPAIGN_TYPES } from 'helpers';

// Import components
import AddCampaignDropdown from './components/AddCampaignDropdown';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: AddCampaignDropdown,
		customKey: 'add_campaign',
	},
];

export const getCampaignType = (campaignType) =>
	Object.values(CAMPAIGN_TYPES).find((type) => campaignType === type);
