import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ls from 'localstorage-slim';

// Import utils
import { useMessage, useProfile, useTranslations } from 'components/utilities';
import { createContract } from 'store/actions/contract';

// Import components
import { SubmitFormContent } from './components';

// Import helpers
import { LOCAL_STORAGE_CONTRACT, replacePreviewBookingUrl } from 'helpers';
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import store
import { signOut } from 'store/actions';

export const SubmitForm = () => {
	const { t } = useTranslations();
	const dispatch = useDispatch();

	const { isDriver } = useProfile();

	const { message } = useMessage();

	const [isLoading, setIsLoading] = useState(false);

	const history = useHistory();

	const handleOnSubmit = async (values) => {
		const {
			contractNumber: contract_number,
			workingBranchId: working_branch_id,
			operator_id,
			bookingUuid,
		} = ls.get(LOCAL_STORAGE_CONTRACT) || {};

		const { otp_value, signature } = values;

		const options = {
			body: {
				working_branch_id,
				contract_number,
				otp_value,
				signature,
				operator_id,
			},
			bookingUuid,
			callbackSuccess: async () => {
				message.success(t('common.messages.successfully_created'));
				setIsLoading(false);
				localStorage.removeLsValue(LOCAL_STORAGE_CONTRACT);

				if (isDriver) {
					await signOut()(dispatch);
					history.push(URLS.signInUrl);
				} else {
					history.push(replacePreviewBookingUrl(bookingUuid));
				}
				ls.remove(LOCAL_STORAGE_CONTRACT);
			},
			errorCallback: () => {
				message.error(t('common.messages.error_message'));
				setIsLoading(false);
			},
		};

		setIsLoading(true);
		await createContract(options);
	};

	return (
		<Form
			onSubmit={handleOnSubmit}
			initialValues={INITIAL_VALUES}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<SubmitFormContent isLoading={isLoading} />
				</form>
			)}
		/>
	);
};
