import { useRef, useState } from 'react';

import { useToggle, useTranslations } from 'components/utilities';

export const useSplitButton = () => {
	const [selectedIndex, setSelectedIndex] = useState(1);
	const { on: open, toggle } = useToggle();
	const { t } = useTranslations();
	const anchorRef = useRef(null);

	const handleMenuItemClick = (index) => {
		setSelectedIndex(index);
		toggle();
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		toggle();
	};

	const getOption = ({ option, additionalOptionText }) => {
		if (additionalOptionText) {
			return `${t(additionalOptionText)} ${option}`;
		}

		return option;
	};

	return {
		handleMenuItemClick,
		handleClose,
		selectedIndex,
		getOption,
		anchorRef,
		toggle,
		open,
	};
};
