import React from 'react';
import { object } from 'prop-types';
import { Typography } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

const Customer = ({ customer }) => {
	const { t } = useTranslations();

	const {
		uuid,
		full_name: fullName,
		phone,
		license_number: licenseNumber,
		type
	} = customer;

	const displayId = uuid ? `${t('booking_print.id')}: ${uuid},` : '';
	const displayType = type ? `${t('booking_print.type')}: ${type},` : '';
	const displayPhoneNumber = phone
		? `${t('booking_print.phone_number')}: ${phone},`
		: '';
	const displayLicenseNumber = licenseNumber
		? `${t('booking_print.license_number')}: ${licenseNumber}`
		: '';
	return (
		<>
			<Typography variant="h5" color="primary" gutterBottom>
				{fullName}
			</Typography>
			<Typography>
				{displayId} {displayType} {displayPhoneNumber} {displayLicenseNumber}
			</Typography>
		</>
	);
};

Customer.propTypes = {
	customer: object.isRequired
};

export default Customer;
