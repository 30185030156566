import { alpha, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export const FuelTypeSlider = withStyles((theme) => ({
	thumb: {
		height: '30px',
		width: '30px',
		borderRadius: '3px',
		backgroundColor: theme.palette.primary.main,
		marginTop: -8,
		marginLeft: -14,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
		[theme.breakpoints.down('md')]: {
			height: '16px',
			width: '12px',
			marginTop: -4,
			marginLeft: -5,
		},
	},
	track: {
		height: 15,
		borderRadius: 4,
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.down('md')]: {
			height: 8,
			backgroundColor: theme.palette.background[4],
		},
	},
	active: {},
	rail: {
		height: 15,
		borderRadius: 4,
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.down('md')]: {
			height: 8,
			backgroundColor: theme.palette.background[4],
		},
	},
	mark: {
		marginTop: -4,
		height: '22px',
		width: '2px',
		backgroundColor: alpha(theme.palette.black, 0.65),
		[theme.breakpoints.down('md')]: {
			height: '16px',
		},
		'&[data-index="0"]': {
			backgroundColor: theme.palette.error.main,
			height: '32px',
			marginTop: -9,
			[theme.breakpoints.down('md')]: {
				height: '17px',
				marginTop: -4,
			},
		},
		'&[data-index="4"]': {
			backgroundColor: theme.palette.primary.main,
			height: '32px',
			marginTop: -9,
			[theme.breakpoints.down('md')]: {
				height: '17px',
				marginTop: -4,
			},
		},
	},
	markLabel: {
		fontSize: '12px',
		fontWeight: '500',
		marginTop: 14,
		color: theme.palette.black,
	},
}))(Slider);
