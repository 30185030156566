import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	authenticate: null,
	isError: false,
	isAuthenticated: false,
	isUserProfileLoaded: false,
	resetToken: null,
	customer_wallet: null,
	profile_error: null,
	signOut: {
		error: false,
		loading: false,
	},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ------------ Authenticate user ------------
			case types.AUTH_USER_LOADING:
				return { ...INITIAL_STATE };

			case types.AUTH_USER_SUCCESS:
				draft.authenticate = action.payload;
				draft.isError = false;
				draft.isAuthenticated = true;
				return;

			case types.AUTH_USER_ERROR:
				draft.isError = action.payload;
				return;

			// ------------ User Profile ------------
			case types.USER_PROFILE_FETCH_SUCCESS:
				draft.isUserProfileLoaded = true;
				draft.authenticate.data = {
					...draft.authenticate.data,
					...action.payload,
				};
				return;

			case types.USER_PROFILE_FETCH_ERROR:
				draft.isUserProfileLoaded = false;
				draft.profileError = action.payload;
				return;

			// ------------ Sign out ------------
			case types.SIGN_OUT_LOADING:
				draft.signOut.loading = true;
				return;

			case types.SIGN_OUT_SUCCESS:
				return { ...INITIAL_STATE };

			case types.SIGN_OUT_ERROR:
				draft.signOut.loading = false;
				draft.signOut.error = true;
				return;

			// ------------ Reset Password ------------
			case types.SET_RESET_TOKEN:
				draft.resetToken = action.payload;
				return;

			default:
				return state;
		}
	});
