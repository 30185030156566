import PropTypes from 'prop-types';

export const DAYS = [
	{ slug: 'sun', name: 'Sun' },
	{ slug: 'mon', name: 'Mon' },
	{ slug: 'tue', name: 'Tue' },
	{ slug: 'wed', name: 'Wed' },
	{ slug: 'thu', name: 'Thu' },
	{ slug: 'fri', name: 'Fri' },
	{ slug: 'sat', name: 'Sat' },
];

export const createBusinessHourShape = () => {
	return PropTypes.shape({
		isOpen: PropTypes.bool,
		isOpenSecond: PropTypes.bool,
		open: PropTypes.shape({
			start: PropTypes.string,
			end: PropTypes.string,
		}),
		openSecond: PropTypes.shape({
			start: PropTypes.string,
			end: PropTypes.string,
		}),
	});
};

export const createBusinessHoursShape = () => {
	const type = {};

	DAYS.forEach((day) => {
		type[day.slug] = createBusinessHourShape();
	});

	return PropTypes.shape(type);
};
