import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDebouncedCallback } from 'use-debounce/lib';
import usePrevious from '@rooks/use-previous';
import services from 'services/services';
import _ from 'lodash';

// Import helpers
import { SUBSCRIPTION_PRICES_QUERY } from 'helpers';

export const useSubscriptionPrices = (values, enabled) => {
	const { carId } = useParams();

	const carUuid = carId || null;
	const { monthlyDiscountPrice, yearlyDiscountPrice } = values;

	const currentDiscountPrices = {
		monthly_discount_amount: +monthlyDiscountPrice,
		yearly_discount_amount: +yearlyDiscountPrice,
	};

	const prevDiscountPrices = usePrevious(currentDiscountPrices);

	const [body, setBody] = useState(currentDiscountPrices);

	const debounceBody = useDebouncedCallback(() => {
		setBody(currentDiscountPrices);
	}, 750);

	useEffect(() => {
		const changed = !_.isEqual(currentDiscountPrices, prevDiscountPrices);

		if (changed) {
			debounceBody();
		}
	}, [currentDiscountPrices, prevDiscountPrices, body, debounceBody]);

	const newParams = carUuid ? { ...body, car_uuid: carUuid } : body;

	const convertedParams = new URLSearchParams(newParams);

	const path = `/v2/subscription/prices?${convertedParams}`;

	const { data, isFetching, isFetched, isError } = useQuery(
		[SUBSCRIPTION_PRICES_QUERY, JSON.stringify(body)],
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000,
			enabled,
		}
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isFetched, isError };
};
