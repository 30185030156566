import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { array, object } from 'prop-types';

import {
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';

const CampaignCustomerCard = ({
	customerTypes,
	customerOrganizations,
	selectedTypes,
	selectedOrganizations,
}) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<Card>
			<CardHeader title={t('campaigns.form.customer_card_title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={4} xs={12}>
						<Field
							fullWidth
							component={MultiselectFiledAdapter}
							type="select"
							label={t('campaigns.form.customer.organization')}
							name="condition.customer.organization"
							margin="dense"
							variant="outlined"
							disabled={submitting}
							labelextractor={(value) => customerOrganizations[value]}
						>
							{Object.keys(customerOrganizations).map((key) => (
								<MenuItem key={key} value={key}>
									<Checkbox
										color="primary"
										checked={selectedOrganizations.includes(key)}
									/>
									{customerOrganizations[key]}
								</MenuItem>
							))}
						</Field>
					</Grid>
					<Grid item md={4} xs={12}>
						<Field
							fullWidth
							component={MultiselectFiledAdapter}
							type="select"
							label={t('campaigns.form.customer.types')}
							name="condition.customer.customer_type"
							margin="dense"
							variant="outlined"
							disabled={submitting}
							labelextractor={(value) => customerTypes[value]}
						>
							{Object.keys(customerTypes).map((key) => (
								<MenuItem key={key} value={key}>
									<Checkbox
										color="primary"
										checked={selectedTypes.includes(key)}
									/>
									{customerTypes[key]}
								</MenuItem>
							))}
						</Field>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

CampaignCustomerCard.propTypes = {
	customerTypes: object.isRequired,
	customerOrganizations: object.isRequired,
	selectedTypes: array.isRequired,
	selectedOrganizations: array.isRequired,
};

export default CampaignCustomerCard;
