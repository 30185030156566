import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	continueButton: {
		margin: theme.spacing(2, 0),
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));
