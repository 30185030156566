import React from 'react';
import { Collapse, TableCell, TableRow } from '@material-ui/core';
import { bool, number, string } from 'prop-types';
import { fetchEmployeePerformanceBookings } from 'store/actions';

// Import helpers
import { columns } from '../../columns';

// Import component
import { SubTableProvider } from 'components/context';
import { SubTable } from 'components/elements';
import { TableBodyRow } from './components';

export const ExpandedRow = ({ isExpanded, colSpan, employeeUuid }) => {
	return (
		<TableRow>
			<TableCell style={{ padding: 0 }} colSpan={colSpan}>
				<Collapse in={isExpanded} unmountOnExit>
					<SubTableProvider
						columns={columns}
						fetchDataTableAction={(options) =>
							fetchEmployeePerformanceBookings({
								...options,
								options: { ...options.options, pageSize: 999999, pageIndex: 0 },
								itemId: employeeUuid,
							})
						}
					>
						<SubTable>
							{({ row }) => <TableBodyRow key={row.id} row={row} />}
						</SubTable>
					</SubTableProvider>
				</Collapse>
			</TableCell>
		</TableRow>
	);
};

ExpandedRow.propTypes = {
	isExpanded: bool.isRequired,
	colSpan: number,
	employeeUuid: string.isRequired,
};

ExpandedRow.defaultProps = {
	colSpan: 1,
};
