import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { REPORT_BOOKINGS_BY_SOURCE_QUERY } from 'helpers';
import { convertFiltersToParams } from './helpers';

export const useBookingsBySourceQuery = (filters = {}) => {
	const params = convertFiltersToParams(filters);
	const path = `/report/bookings-by-source?${params}`;

	const { data, isLoading, isError, error } = useQuery(
		[REPORT_BOOKINGS_BY_SOURCE_QUERY, filters],
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
		}
	);

	const fetchedData = data ? data.data : null;

	return { data: fetchedData, isLoading, isError, error };
};
