import React from 'react';

// Import components
import { ButtonProgress } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useResetButton } from './useResetButton';

export const ResetButton = () => {
	const { t } = useTranslations();

	const { handleDeleteBookingRate, isDisabled, isLoading } = useResetButton();

	return (
		<ButtonProgress
			color="primary"
			variant="contained"
			type="button"
			disabled={isDisabled}
			isLoading={isLoading}
			onClick={handleDeleteBookingRate}
		>
			{t('common.buttons.reset_rating')}
		</ButtonProgress>
	);
};
