import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

// Import components
import { Card } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useOfficeRatingPreview } from './useOfficeRatingPreview';

const OfficeRatingPreview = () => {
	const { i18n, t } = useTranslations();

	const { rating, ratingReasons, showRatingReasons } = useOfficeRatingPreview();

	if (!rating) {
		return null;
	}

	return (
		<Card title={t('bookings.office_rating.title')} lg={6}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography component="p">
						{t('bookings.office_rating.form.rating')}
					</Typography>
				</Grid>
				<Grid item container spacing={3} xs={12}>
					<Grid item xs={12}>
						<Rating value={rating.rating} readOnly size="large" />
					</Grid>

					{showRatingReasons && (
						<Grid
							item
							xs={12}
							container
							spacing={2}
							justifyContent="space-between"
						>
							<Grid item>
								<Typography variant="h5">
									{t('bookings.office_rating.form.rating_reason')}
								</Typography>
							</Grid>
							<Grid item>
								{ratingReasons.map(({ reason }) => (
									<Typography key={reason.uuid}>
										{reason[i18n.language]}
									</Typography>
								))}
							</Grid>
						</Grid>
					)}

					<Grid
						item
						xs={12}
						container
						spacing={2}
						justifyContent="space-between"
					>
						<Grid item>
							<Typography variant="h5">
								{t('bookings.office_rating.form.description')}
							</Typography>
						</Grid>

						<Grid item>
							<Typography>{rating.description}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

export default OfficeRatingPreview;
