import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';

// Import components
import { RatingTableCell } from 'components/elements';
import { CompanyNameTableCell } from './components';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';

// Import styles
import { useStyles } from './styles';

const { COMPANY_NAME, RATING, ALL_BOOKINGS_AND_ACCEPTED } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const classes = useStyles();

	const renderCell = (cell) => {
		const {
			booking_accepted_count: acceptedBooking,
			company_avg_rating: companyAvgRating,
			booking_total_count: totalBooking,
		} = cell.row.original;

		switch (cell.column.id) {
			case COMPANY_NAME:
				return <CompanyNameTableCell key={cell.column.id} cell={cell} />;

			case ALL_BOOKINGS_AND_ACCEPTED:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						<Typography>
							{totalBooking}({acceptedBooking})
						</Typography>
					</TableCell>
				);

			case RATING:
				return (
					<RatingTableCell
						rating={Number(companyAvgRating).toFixed(1)}
						key={cell.column.id}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell
						className={classes.cell}
						key={cell.column.id}
						{...cell.getCellProps()}
					>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
