import React from 'react';

// Import helpers
import { initialValues, formatValues } from './helpers';

// Import components
import { TableFilters } from 'components/elements';
import { FilterFields } from './components';
import { useFilters } from './useFilters';

export const Filters = () => {
	useFilters();

	return (
		<TableFilters initialValues={initialValues} formatValues={formatValues}>
			{() => <FilterFields />}
		</TableFilters>
	);
};
