import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	value: {
		textAlign: 'center',
		color: theme.palette.primary.main,
	},
	labelAr: {
		textAlign: 'end',
	},
	labelEn: {
		textAlign: 'start',
	},
}));
