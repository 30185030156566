import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import components
import useUrgentStatusCell from './useUrgentStatusCell';

// Import translations
import { useTranslations } from 'components/utilities';

const UrgentStatusTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const { status, color, elapsedTime } = useUrgentStatusCell({ cell });

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{status && (
				<>
					<Typography color={color} variant="h5">
						{t(`bookings.urgent_statuses.${status}`)}
					</Typography>
					<Typography variant="body2">{elapsedTime}</Typography>
				</>
			)}
		</TableCell>
	);
};

UrgentStatusTableCell.propTypes = {
	cell: PropTypes.object.isRequired,
};

export default UrgentStatusTableCell;
