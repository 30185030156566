import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: '16px',
	},
	button: {
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
}));
