import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	labelBox: {
		position: 'relative',
		backgroundColor: theme.palette.purple.main,
		borderRadius: '2px',
		padding: '8px 16px',
		'&:after': {
			content: '""',
			position: 'absolute',
			left: 'calc(50% - 8px)',
			bottom: '-17px',
			transform: 'translateY(-50%)',
			width: 0,
			height: 0,
			borderLeft: '8px solid transparent',
			borderRight: '8px solid transparent',
			borderTop: `18px solid ${theme.palette.purple.main}`,
			zIndex: -2,
		},
	},
	label: {
		color: theme.palette.white,
		fontSize: '12px',
		fontWeight: '400',
	},
	dot: {
		position: 'absolute',
		left: 'calc(50% - 7px)',
		transform: 'translateY(-50%)',
		width: '14px',
		height: '14px',
		bottom: '-30px',
		backgroundColor: theme.palette.white,
		borderRadius: '50%',
		zIndex: -1,
		'&:before': {
			content: '""',
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)',
			width: '8px',
			height: '8px',
			backgroundColor: theme.palette.purple.main,
			borderRadius: '50%',
		},
	},
}));
