import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Grid } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';

// Import styles
import { useStyles } from './styles';

// Import components
import useBusinessHour from './useBusinessHour';
import { BusinessHourItem } from './components';

// Import helpers
import { createBusinessHourShape } from '../helpers';

const BusinessHour = ({ value, onChange, disabled, day }) => {
	const classes = useStyles();

	const {
		handleOpenToggle,
		handleOpenSecondToggle,
		handleOpenTimeChange,
		buttonOpenVariant,
		buttonOpenColor,
	} = useBusinessHour({
		value,
		onChange,
	});

	return (
		<Grid
			container
			spacing={1}
			alignItems="center"
			justifyContent="space-between"
			wrap="nowrap"
		>
			<Grid item>
				<Button
					onClick={handleOpenToggle}
					type="button"
					className={classes.buttonDay}
					variant={buttonOpenVariant}
					color={buttonOpenColor}
					disabled={disabled}
				>
					{day.name}
				</Button>
			</Grid>

			<Grid item xs={2}>
				{value.isOpen && (
					<BusinessHourItem
						icon={WbSunnyIcon}
						value={value.open.start}
						onChange={(ev) =>
							handleOpenTimeChange({ prop: 'open', type: 'start', ev })
						}
						disabled={disabled}
					/>
				)}
			</Grid>

			<Grid item xs={2}>
				{value.isOpen && (
					<BusinessHourItem
						icon={NightsStayIcon}
						value={value.open.end}
						onChange={(ev) =>
							handleOpenTimeChange({ prop: 'open', type: 'end', ev })
						}
						disabled={disabled}
					/>
				)}
			</Grid>

			<Grid item>
				{value.isOpen && (
					<IconButton onClick={handleOpenSecondToggle} disabled={disabled}>
						{!value.isOpenSecond && <AddCircleIcon fontSize="small" />}
						{value.isOpenSecond && <RemoveCircleIcon fontSize="small" />}
					</IconButton>
				)}
			</Grid>

			<Grid item xs={2}>
				{value.isOpen && value.isOpenSecond && (
					<BusinessHourItem
						icon={WbSunnyIcon}
						value={value.openSecond.start}
						onChange={(ev) =>
							handleOpenTimeChange({ prop: 'openSecond', type: 'start', ev })
						}
						disabled={disabled}
					/>
				)}
			</Grid>

			<Grid item xs={2}>
				{value.isOpen && value.isOpenSecond && (
					<BusinessHourItem
						icon={NightsStayIcon}
						value={value.openSecond.end}
						onChange={(ev) =>
							handleOpenTimeChange({ prop: 'openSecond', type: 'end', ev })
						}
						disabled={disabled}
					/>
				)}
			</Grid>
		</Grid>
	);
};

BusinessHour.propTypes = {
	value: createBusinessHourShape().isRequired,
	onChange: PropTypes.func.isRequired,
	day: PropTypes.shape({
		slug: PropTypes.string,
		name: PropTypes.string,
	}).isRequired,
	disabled: PropTypes.bool,
};

export default BusinessHour;
