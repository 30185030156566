import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
		},

		'& .MuiFormControlLabel-root': {
			[theme.breakpoints.down('md')]: {
				alignItems: 'flex-start',
			},
		},
		'& .MuiCheckbox-root:not(.Mui-checked)': {
			[theme.breakpoints.down('md')]: {
				color: theme.palette.gray.medium,
			},
		},
		'& .MuiCheckbox-root': {
			[theme.breakpoints.down('md')]: {
				paddingTop: 0,
				borderWidth: '2.3px',
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled , & .MuiOutlinedInput-notchedOutline':
			{
				[theme.breakpoints.down('md')]: {
					borderColor: 'transparent !important',
				},
			},

		'& .MuiInputBase-root': {
			[theme.breakpoints.down('md')]: {
				backgroundColor: theme.palette.white,
			},
		},

		'& .MuiInputBase-input': {
			[theme.breakpoints.down('md')]: {
				border: 0,
			},
		},

		'& .MuiFormControlLabel-label > *': {
			[theme.breakpoints.down('md')]: {
				color: theme.palette.gray.labelLight,
			},
		},
		'& .MuiInputAdornment-root > *': {
			[theme.breakpoints.down('md')]: {
				color: theme.palette.black,
				fontSize: 11,
			},
		},
		'& .MuiTextField-root': {
			[theme.breakpoints.down('md')]: {
				border: `1px solid ${alpha(theme.palette.gray.border)}`,
				borderRadius: 6,
			},
		},
	},
}));
