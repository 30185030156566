import React from 'react';
import { number, string } from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { OverlayView } from '@react-google-maps/api';

// Import styles
import { useStyles } from './CustomMarkerTooltip.styles';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { getPixelPositionOffset } from 'helpers';

export const CustomMarkerTooltip = ({ lat, lng, title }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<OverlayView
			position={{ lat, lng }}
			mapPaneName={OverlayView.MARKER_LAYER}
			getPixelPositionOffset={getPixelPositionOffset({
				xOffsetExtra: -77,
				yOffsetExtra: 55,
			})}
		>
			<Box className={classes.labelBox}>
				<Typography className={classes.label}>{t(title)}</Typography>
			</Box>
		</OverlayView>
	);
};

CustomMarkerTooltip.propTypes = {
	lat: number,
	lng: number,
	title: string,
};
