import axios from 'axios';
import { markBookingAsCompensated } from 'store/actions';
import { useTableContextProvider } from 'components/context';
import { BOOKING_COMPENSATION_TYPES, convertApiFormErrors } from 'helpers';

const useMarkAsCompensatedTableCell = ({ cell, onClose }) => {
	const { fetchData } = useTableContextProvider();
	const cancelToken = axios.CancelToken.source().token;
	const booking = cell.row.original;

	const { operation_topics: topics, compensation } = booking;

	const isCancellation = topics.includes(
		BOOKING_COMPENSATION_TYPES.CANCELLATION
	);

	const onSubmit = async (values) => {
		try {
			await markBookingAsCompensated({
				booking,
				cancelToken,
				values,
				isCancellation,
			});
			onClose();
			fetchData();
		} catch (error) {
			const errors = convertApiFormErrors(error.data?.errors);
			return errors;
		}
	};

	return { onSubmit, compensation };
};

export default useMarkAsCompensatedTableCell;
