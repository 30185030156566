import React from 'react';
import { object, func, string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { TableCell, Link } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	cell: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(0.5),
	},
}));

const EditFormTableCell = ({ cell, editLinkPath }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Link
				className={classes.cell}
				component={RouterLink}
				to={editLinkPath}
				variant="h6"
			>
				<EditIcon className={classes.icon} fontSize="small" />
				{t('table.headers.edit')}
			</Link>
		</TableCell>
	);
};

EditFormTableCell.propTypes = {
	cell: object.isRequired,
	editLinkPath: string.isRequired,
	deleteAction: func,
};

export default EditFormTableCell;
