import React from 'react';
import { bool, func, object } from 'prop-types';
import { Form } from 'react-final-form';
import { TableCell } from '@material-ui/core';

// Import components
import { DriverDialog } from 'components/elements';
import { AssignmentStatus } from './components';

// Import helpers
import { DELIVERY_TYPES } from 'helpers';

const { PICK_UP_DELIVERY, DROP_DELIVERY } = DELIVERY_TYPES;

export const DeliveryAssignmentTableCell = ({
	cell,
	isDropOffSort = false,
	fetchData = () => {},
}) => {
	const { uuid, delivery } = cell.row.original || {};

	const { drop_delivery, pick_up_delivery } = delivery || {};

	const deliveryType = isDropOffSort ? DROP_DELIVERY : PICK_UP_DELIVERY;
	const isDeliveryCheck = isDropOffSort
		? drop_delivery?.is_available
		: pick_up_delivery?.is_available;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{isDeliveryCheck ? (
				<>
					<AssignmentStatus cell={cell} deliveryType={deliveryType} />
					<Form
						onSubmit={() => {}}
						render={() => (
							<DriverDialog
								deliveryType={deliveryType}
								externalBookingUuid={uuid}
								externalDelivery={delivery}
								changeButtonOutlined
								refetchCallback={fetchData}
							/>
						)}
					/>
				</>
			) : null}
		</TableCell>
	);
};

DeliveryAssignmentTableCell.propTypes = {
	cell: object.isRequired,
	isDropOffSort: bool,
	fetchData: func,
};
