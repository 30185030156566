import theme from 'theme';

// Import assets
import {
	SmallScratchIcon,
	DeepScratchIcon,
	VeryDeepScratchIcon,
	BendInBodyScratchIcon,
} from 'assets/icons';

// Import helpers
import { SCRATCH_TYPES } from 'helpers';

const { SMALL_SCRATCH, DEEP_SCRATCH, VERY_DEEP_SCRATCH, BEND_IN_BODY } =
	SCRATCH_TYPES;

export const scratches = [
	{
		type: SMALL_SCRATCH,
		icon: SmallScratchIcon,
		color: theme?.palette.tertiary.main,
	},
	{
		type: DEEP_SCRATCH,
		icon: DeepScratchIcon,
		color: theme?.palette.warning.main,
	},
	{
		type: VERY_DEEP_SCRATCH,
		icon: VeryDeepScratchIcon,
		color: theme?.palette.error.main,
	},
	{
		type: BEND_IN_BODY,
		icon: BendInBodyScratchIcon,
		color: theme?.palette.info.main,
	},
];
