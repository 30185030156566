import React from 'react';
import { Typography } from '@material-ui/core';
import { object } from 'prop-types';

// Import components
import PreviewField from '../PreviewField';

// Import utilities
import { useDiscountPreviewField } from './useDiscountPreviewField';
import { useTranslations } from 'components/utilities';

export const DiscountPreviewField = ({ customData }) => {
	const discount = customData.discount;

	const { label, fixedDiscount, color } = useDiscountPreviewField(discount);

	const { t } = useTranslations();

	return (
		<PreviewField label={label}>
			<Typography color={color}>{`${fixedDiscount} ${t(
				'currency.sar'
			)}`}</Typography>
		</PreviewField>
	);
};

DiscountPreviewField.propTypes = {
	customData: object,
};
