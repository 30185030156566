import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

// Import helpers
import { officeCarsItemSelector } from 'store/selectors';

export const useExternalPartnerCarsSelect = (index) => {
	const { external_partner_cars: externalPartnerCars } = useSelector(
		officeCarsItemSelector
	);

	const { values } = useFormState();

	const selectedCars = values.office_cars[index].external_partner_car_ids;

	const allSelectedCars = values.office_cars.reduce(
		(acc, { external_partner_car_ids }) =>
			external_partner_car_ids.length
				? [...acc, ...external_partner_car_ids]
				: acc,
		[]
	);

	const findLabel = (checkedId) =>
		externalPartnerCars.find(({ id }) => checkedId === id).name;

	const convertExternalPartnerCars = () => {
		const uniqueExternalPartnerCars = externalPartnerCars.filter(
			({ id }) => !allSelectedCars.includes(id)
		);
		const convertedSelectedCars = externalPartnerCars.filter(({ id }) =>
			selectedCars.includes(id)
		);

		return [...convertedSelectedCars, ...uniqueExternalPartnerCars];
	};

	const convertedExternalPartnerCars = convertExternalPartnerCars();

	return { findLabel, convertedExternalPartnerCars, selectedCars };
};
