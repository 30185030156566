import React from 'react';
import { SvgIcon } from '@material-ui/core';

const NavigateNextIcon = (props) => (
	<SvgIcon viewBox="0 0 38 38" {...props}>
		<path
			d="M16.7327 13.2069C17.0326 12.9213 17.5074 12.9328 17.7931 13.2327L22.7944 18.4832C23.0703 18.7728 23.0703 19.2281 22.7944 19.5178L17.7931 24.7682C17.5074 25.0681 17.0326 25.0797 16.7327 24.794C16.4328 24.5083 16.4213 24.0336 16.7069 23.7336L21.2155 19.0005L16.7069 14.2673C16.4213 13.9674 16.4328 13.4926 16.7327 13.2069Z"
			fill="currentColor"
		/>
	</SvgIcon>
);

export default NavigateNextIcon;
