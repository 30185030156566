import React from 'react';
import { object } from 'prop-types';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TextFieldAdapter = ({ input, meta, ...rest }) => {
	const { t } = useTranslation();
	const error = !!(meta.touched && (meta.error || meta.submitError));
	const errorText =
		meta.touched && (meta.error || meta.submitError)
			? t(meta.error) || meta.submitError
			: '';

	return (
		<TextField error={error} helperText={errorText} {...input} {...rest} />
	);
};

TextFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
};

export default TextFieldAdapter;
