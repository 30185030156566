import React from 'react';
import { array, element, func, object, oneOfType, string } from 'prop-types';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';

export const TableRPC = ({
	columnsAccessor,
	columns,
	toolBarContent,
	tableBodyRow: TableBodyRow,
	export: Export,
	fetchDataTableAction,
	defaultQueryParams,
}) => {
	const classes = useBaseLayoutStyles();

	return (
		<TableProvider
			initialResources={{ cancelReasons: [], states: [] }}
			initialTableOptions={{ pageSize: 10 }}
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchDataTableAction({ ...options, defaultQueryParams })
			}
		>
			<div className={classes.root}>
				{toolBarContent && <Toolbar content={toolBarContent} />}
				<div className={classes.content}>
					<Table isDefaultRow={false} tableInnerToolbar={Export}>
						{({ row }) => {
							return (
								<TableBodyRow
									key={row.id}
									row={row}
									columnsAccessor={columnsAccessor}
								/>
							);
						}}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};

TableRPC.propTypes = {
	columnsAccessor: object.isRequired,
	columns: array.isRequired,
	toolBarContent: array,
	tableBodyRow: oneOfType([element, func]).isRequired,
	export: element,
	fetchDataTableAction: func.isRequired,
	defaultQueryParams: string,
};

TableRPC.defaultProps = {
	defaultQueryParams: '',
};
