import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TimeClockInfoIcon = (props) => (
	<SvgIcon width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
		<path
			d="M6.33301 9.80938H9.80922V5.5415"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0521 17.4167C13.3866 17.4167 12.7361 17.2193 12.1828 16.8496C11.6295 16.4799 11.1983 15.9545 10.9436 15.3397C10.689 14.7249 10.6223 14.0484 10.7522 13.3957C10.882 12.743 11.2024 12.1435 11.673 11.673C12.1435 11.2024 12.743 10.882 13.3957 10.7522C14.0484 10.6223 14.7249 10.689 15.3397 10.9436C15.9545 11.1983 16.4799 11.6295 16.8496 12.1828C17.2193 12.7361 17.4167 13.3866 17.4167 14.0521C17.4167 14.9444 17.0622 15.8002 16.4312 16.4312C15.8002 17.0622 14.9444 17.4167 14.0521 17.4167"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0439 14.4082V15.3978"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0478 12.7059C14.0556 12.7067 14.0635 12.7052 14.0704 12.7016C14.0773 12.698 14.083 12.6924 14.0868 12.6855C14.0905 12.6786 14.0922 12.6708 14.0915 12.663C14.0908 12.6552 14.0878 12.6478 14.0829 12.6417C14.078 12.6356 14.0714 12.6311 14.0639 12.6288C14.0564 12.6264 14.0484 12.6264 14.0409 12.6286C14.0334 12.6308 14.0267 12.6351 14.0217 12.6412C14.0167 12.6472 14.0135 12.6545 14.0127 12.6623V12.6708C14.0137 12.6798 14.0177 12.6881 14.0241 12.6945C14.0304 12.7009 14.0388 12.7049 14.0478 12.7059"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.5786 8.70829C16.4297 7.37502 15.9074 6.11088 15.0717 5.06137C14.2361 4.01187 13.121 3.21967 11.855 2.77594C10.5889 2.33222 9.22332 2.25502 7.91532 2.55321C6.60732 2.85141 5.41011 3.5129 4.46149 4.46152C3.51287 5.41015 2.8514 6.60736 2.5532 7.91536C2.25501 9.22336 2.33223 10.589 2.77596 11.855C3.21969 13.1211 4.0119 14.2361 5.06141 15.0717C6.11092 15.9074 7.37506 16.4297 8.70833 16.5786"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
