export const INITIAL_VALUES = {
	company_uuid: '',
	position: '',
	label_en: '',
	label_ar: '',
	date_from: null,
	date_to: null,
	vehicle_uuids: [],
	city_uuids: [],
};
