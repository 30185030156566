import React from 'react';

// Import store
import { fetchSubscriptionBalance } from 'store/actions';

// Import utils and helpers
import { useProfile } from 'components/utilities';
import { TOOLBAR_CONTENT } from './helpers';
import { columns } from './columns';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import components
import { TableProvider } from 'components/context';
import { Toolbar, Table } from 'components/elements';
import { TableBodyRow, Export } from './components';

export const SubscriptionBalance = () => {
	const classes = useBaseLayoutStyles();

	const { isAdmin } = useProfile();

	return (
		<TableProvider
			columns={columns(isAdmin)}
			fetchDataTableAction={fetchSubscriptionBalance}
		>
			<div className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<div className={classes.content}>
					<Table isDefaultRow={false} tableInnerToolbar={<Export />}>
						{({ row }) => {
							return <TableBodyRow key={row.id} row={row} />;
						}}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};
