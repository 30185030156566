import isSameDay from 'date-fns/isSameDay';

export const validateAndGetContractDate = (contractDate, pickDropTime) => {
	// Convert date format from dd/MM/yyyy into yyyy-MM-dd
	// to avoid Date generation errors
	const formattedContractDate = contractDate.split('/').reverse();
	formattedContractDate[1]--;

	return isSameDay(new Date(...formattedContractDate), new Date(pickDropTime))
		? null
		: contractDate;
};
