// Import utilities
import { useTranslations } from 'components/utilities';
import { useCustomerWalletBalance } from 'queries';

const useCustomerWallet = ({ uuid }) => {
	const { t } = useTranslations();

	const {
		data: balance,
		isError,
		isFetched,
	} = useCustomerWalletBalance({ uuid });

	const getBalance = () =>
		`${(+balance?.amount.gross).toFixed(2)} ${balance?.amount.currency}`;

	const errorMessage = t('common.not_available');

	return {
		balance: getBalance(),
		errorMessage,
		isFetched,
		isError,
	};
};

export default useCustomerWallet;
