import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { CampaignFilters } from '../CampaignFilters';
import { RevenueFilters } from '../RevenueFilters';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';
import { useFilterFields } from './useFilterFields';

export const FilterFields = () => {
	const { REPORT_TYPE_FILTER, isCampaign, isRevenue } = useFilterFields();
	const { renderFields } = useCommonFields();

	return (
		<Grid container spacing={3}>
			{renderFields(REPORT_TYPE_FILTER)}
			{isCampaign && <CampaignFilters />}
			{isRevenue && <RevenueFilters />}
		</Grid>
	);
};
