import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { bool } from 'prop-types';

// Import components
import { BookingIdCardSection } from '../index';

const BookingIdCardContainer = ({ isMdOrBigger }) => {
	return (
		<Grid item md={4} container>
			<Grid item md={11}>
				<BookingIdCardSection />
			</Grid>
			<Grid item md={1}>
				<Divider orientation={isMdOrBigger ? 'vertical' : 'horizontal'} />
			</Grid>
		</Grid>
	);
};

BookingIdCardContainer.propTypes = {
	isMdOrBigger: bool.isRequired,
};

export default BookingIdCardContainer;
