// Import helpers
import { VALIDATION_LABELS_TYPES } from 'helpers';

export const STATE_TYPES = {
	ERROR: 'error',
	SUCCESS: 'success',
};

const { APPROVED, REJECTED, INVALID, VALIDATED, COMPENSATED } =
	VALIDATION_LABELS_TYPES;

export const getStatusType = (status) => {
	switch (status) {
		case VALIDATED:
		case APPROVED:
		case COMPENSATED:
			return STATE_TYPES.SUCCESS;

		case REJECTED:
		case INVALID:
			return STATE_TYPES.ERROR;

		default:
			return null;
	}
};
