import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	item: {},
	isLoaded: false,
	isError: false,
	error: {},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.CMS_PAGE_FORM_FETCH_DATA_LOADING:
				return INITIAL_STATE;

			case types.CMS_PAGE_FORM_FETCH_DATA_SUCCES:
				draft.item = action.payload.item.data;
				draft.isLoaded = true;
				break;

			case types.CUSTOMER_FORM_FETCH_DATA_ERROR:
				draft.error = action.payload;
				draft.isError = true;
				draft.isLoaded = true;
				break;

			default:
				return state;
		}
	});
