import React, { createContext, useEffect, useState } from 'react';
import { bool, func, node, string } from 'prop-types';

// Import queries
import { useBookingPreview } from 'queries';
import { useMessage, useTranslations } from 'components/utilities';

export const PrintContext = createContext(null);

export const PrintProvider = ({
	bookingUuid,
	handlePrint,
	toggle,
	children,
}) => {
	const { data, isError, isFetched } = useBookingPreview(bookingUuid);

	const [isReadyToPrint, setIsReadyToPrint] = useState(false);

	const { t } = useTranslations();

	const { message } = useMessage();

	useEffect(() => {
		if (!isError && isFetched && isReadyToPrint) {
			handlePrint();
		}
		if (isError) {
			message.error(t('common.messages.error_message'));
			toggle();
		}
		// eslint-disable-next-line
	}, [isFetched, isError, isReadyToPrint]);

	return (
		<PrintContext.Provider
			value={{ data, isFetched, isReadyToPrint, setIsReadyToPrint }}
		>
			{children}
		</PrintContext.Provider>
	);
};

PrintProvider.propTypes = {
	bookingUuid: string,
	handlePrint: func,
	children: node,
	enabled: bool,
	toggle: func,
};
