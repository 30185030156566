import React from 'react';
import { array, number, object, string } from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import get from 'lodash/get';

// Import helpers
import { columns, COLUMN_ACCESSOR } from '../../helpers';

// Import components
import { ExternalPartnerCarsSelect } from '../ExternalPartnerCarsSelect';

// Import styles
import { useStyles } from './CarsTableRow.styles';

export const CarsTableRow = ({
	row,
	index,
	officeUuid,
	externalPartnerCars,
}) => {
	const classes = useStyles();

	return (
		<TableRow>
			{columns.map(({ accessor }) => {
				switch (accessor) {
					case COLUMN_ACCESSOR.EXTERNAL_PARTNER:
						return (
							<TableCell key={accessor} className={classes.tableCell}>
								<ExternalPartnerCarsSelect
									externalPartnerCars={externalPartnerCars}
									officeUuid={officeUuid}
									index={index}
									row={row}
								/>
							</TableCell>
						);

					default:
						return (
							<TableCell className={classes.tableCell} key={accessor}>
								{get(row, accessor)}
							</TableCell>
						);
				}
			})}
		</TableRow>
	);
};

CarsTableRow.propTypes = {
	externalPartnerCars: array.isRequired,
	index: number.isRequired,
	officeUuid: string,
	row: object,
};
