import myAxios from 'services/myAxiosFactory';
import { settleCorporateBookingsUrl } from 'store/paths/b2b/settlements';

import { getAddSettlementFormData } from './helpers';

export const settleCorporateBooking = async ({
	successCallback,
	errorCallback,
	corporateUuid,
	settledUuids,
	values,
}) => {
	try {
		const formData = getAddSettlementFormData({ values, settledUuids });

		await myAxios().post(settleCorporateBookingsUrl(corporateUuid), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};
