import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

const PreviewField = ({ label, children, labelColor = 'initial' }) => {
	const { t } = useTranslations();

	return (
		<Grid
			container
			spacing={5}
			justifyContent="space-between"
			alignItems="center"
		>
			<Grid item lg="auto">
				<Typography variant="h5" color={labelColor}>
					{t(label)}
				</Typography>
			</Grid>
			<Grid item lg="auto">
				<Typography variant="body1" component="div" align="right">
					{children}
				</Typography>
			</Grid>
		</Grid>
	);
};

PreviewField.propTypes = {
	labelColor: PropTypes.oneOf([
		'initial',
		'inherit',
		'primary',
		'secondary',
		'textPrimary',
		'textSecondary',
		'error',
	]),
	label: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default PreviewField;
