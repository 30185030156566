import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { companyColumns, officeColumns } from 'views/CarsAvailability/columns';
import { getColumns } from 'store/actions/carsAvailability/helpers';
import { CARS_AVAILABILITY_EXPORT_COLUMNS_QUERY } from 'helpers';
import {
	companyCarsAvailabilityUrl,
	officeCarsAvailabilityUrl,
} from 'store/paths';

export const useExportColumns = (filters, enabled) => {
	const officeLevelPath = officeCarsAvailabilityUrl();
	const companyLevelPath = companyCarsAvailabilityUrl();

	const { company_uuid } = filters || {};

	const path = company_uuid ? officeLevelPath : companyLevelPath;

	const body = { start: 0, length: -1, filters };

	const { data, isFetching, isError } = useQuery(
		[CARS_AVAILABILITY_EXPORT_COLUMNS_QUERY, JSON.stringify(body)],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false, staleTime: 60 * 1000, enabled }
	);

	const fetchedData = data?.data?.data || [];

	const newColumns = getColumns(fetchedData);

	const baseColumn = company_uuid ? officeColumns : companyColumns;

	const convertedColumns = [...baseColumn, ...newColumns];

	return { data: convertedColumns, isFetching, isError };
};
