export const INITIAL_VALUES = {
	maker_name: {
		en: '',
		ar: '',
	},
	model_name: {
		en: '',
		ar: '',
	},
	model_uuid: '',
	year: '',
	logo: {
		file_path: '',
		file_name: '',
	},
	types: [],
};
