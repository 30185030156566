import * as types from 'store/types';
import services from 'services/services';

// Import store
import {
	rejectCustomerCompensationUrl,
	calculateCompensationUrl,
} from 'store/paths';

export const rejectCustomerCompensation = (uuid) => async (dispatch) => {
	const { data } = await services.put(rejectCustomerCompensationUrl(uuid));

	dispatch({
		type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
		payload: data.data,
	});
};

export const changeCancelReason = async ({ uuid, body }) => {
	const url = `/v2/booking/${uuid}/cancel-reason/change`;
	await services.put(url, body);
};

export const calculateCompensation = async ({
	values,
	booking,
	cancelToken,
}) => {
	const url = calculateCompensationUrl(booking.uuid);
	const {
		data: { data },
	} = await services.post(url, values, cancelToken);
	return data;
};

export const updateDialogMessage = (message) => (dispatch) => {
	dispatch({
		type: types.UPDATE_DIALOG_MESSAGE,
		payload: message,
	});
};
