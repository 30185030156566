import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { bool, func, string } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useFileUploader } from './useFileUploader';

// Import components
import { ButtonProgress } from 'components/elements/atoms';

// Import styles
import { useStyles } from './FileUploader.styles';

export const FileUploader = ({
	defaultButtonText = 'common.buttons.choose_file',
	onSuccessCallback,
	onErrorCallback,
	buttonClassName,
	required = true,
	error,
	label,
}) => {
	const {
		handleOnInputChange,
		handleOnButtonClick,
		hiddenFileInput,
		buttonText,
		isLoading,
	} = useFileUploader({
		onSuccessCallback,
		onErrorCallback,
		defaultButtonText,
	});

	const { t } = useTranslations();

	const classes = useStyles({ error });

	return (
		<Grid container spacing={1}>
			{label && (
				<Grid item xs={12}>
					<Typography className={classes.label}>{`${t(label)} ${
						required ? '*' : ''
					}`}</Typography>
				</Grid>
			)}
			<Grid item xs={12}>
				<ButtonProgress
					onClick={handleOnButtonClick}
					className={buttonClassName}
					circularProgressSize={24}
					isLoading={isLoading}
					disabled={isLoading}
					variant="contained"
					color="primary"
				>
					{t(buttonText)}
				</ButtonProgress>
				<input
					onChange={handleOnInputChange}
					style={{ display: 'none' }}
					ref={hiddenFileInput}
					type="file"
				/>
			</Grid>
			{error && (
				<Grid item xs={12}>
					<Typography className={classes.error}>{error}</Typography>
				</Grid>
			)}
		</Grid>
	);
};

FileUploader.propTypes = {
	onSuccessCallback: func.isRequired,
	defaultButtonText: string,
	buttonClassName: string,
	onErrorCallback: func,
	required: bool,
	label: string,
	error: string,
};
