import React from 'react';
import { array } from 'prop-types';

// Import helpers
import { formatValues } from '../Filters/helpers';

// Import utils
import { useCarsAvailability } from 'views/CarsAvailability/useCarsAvailability';

// Import components
import { TableExportFilters } from 'components/elements';
import { FilterFields } from '../../components';
import { useExportFilters } from './useExportFilters';

export const ExportFilters = ({ columns }) => {
	const { filtersInitialValues } = useCarsAvailability();
	const { setFilters } = useExportFilters(columns);

	return (
		<TableExportFilters
			initialValues={filtersInitialValues}
			formatValues={formatValues}
			setCustomFilters={setFilters}
		>
			<FilterFields />
		</TableExportFilters>
	);
};

ExportFilters.propTypes = {
	columns: array,
};
