import React from 'react';
import { array, string, bool, number, object, oneOfType } from 'prop-types';
import { Field, useFormState, useForm } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Grid, MenuItem, CircularProgress } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import helpers
import { getCarInfo, getMenuValue } from './helpers';

// Import styles
import { useStyles } from './styles';

const Model = ({
	models,
	fieldName,
	label,
	isGlobalEdit,
	modelsLoaded = true,
	year,
	carBrand,
	carUuidFieldName,
	isCarList = false,
	md = 12,
	xs = 12,
	isRequired = true,
	showAllOption = false,
	disabled = false,
}) => {
	const { submitting } = useFormState();

	const { change } = useForm();

	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const classes = useStyles();

	const history = useHistory();

	const disableModelField =
		disabled || submitting || (isGlobalEdit ? !carBrand : !year);

	const handleChange = (event) => {
		const value = event.target.value;

		if ((!isGlobalEdit || isCarList) && value !== 'all') {
			const carInfo = getCarInfo({ model: value, models, lng });
			const carUuid = isCarList ? carInfo.model_uuid : carInfo.uuid;

			change(carUuidFieldName, carUuid);
		}

		change(fieldName, value);
	};

	const handleClick = () => {
		history.push('/cars/model/add');
	};

	return (
		<Grid item md={md} xs={xs}>
			{modelsLoaded ? (
				<Field
					fullWidth
					component={SelectFieldAdapter}
					type="select"
					label={t(label)}
					name={fieldName}
					margin="dense"
					variant="outlined"
					disabled={disableModelField}
					onChange={handleChange}
					required={isRequired}
				>
					{showAllOption && <MenuItem value="all">{t('common.all')}</MenuItem>}
					{models.map(({ model_name, model_uuid }) => (
						<MenuItem
							key={model_uuid}
							value={getMenuValue({ name: model_name, fieldName })}
						>
							{model_name[lng]}
						</MenuItem>
					))}
					{isCarList && (
						<MenuItem className={classes.root} onClick={handleClick}>
							+ {t('cars.models.table.toolbar.add_model')}
						</MenuItem>
					)}
				</Field>
			) : (
				<CircularProgress size={27} />
			)}
		</Grid>
	);
};

Model.propTypes = {
	models: array.isRequired,
	fieldName: string.isRequired,
	label: string.isRequired,
	isGlobalEdit: bool.isRequired,
	modelsLoaded: bool,
	carBrand: oneOfType([string, object]).isRequired,
	carUuidFieldName: string.isRequired,
	year: oneOfType([string, number]),
	isCarList: bool,
	initialModel: oneOfType([string, object]),
	md: number,
	xs: number,
	isRequired: bool,
	showAllOption: bool,
	disabled: bool,
};

export default Model;
