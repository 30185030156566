import React, { createContext } from 'react';
import { node } from 'prop-types';

// Import utils
import { useCompanies, useRentalCompanies } from 'queries';

export const RentalCompaniesContext = createContext(null);

export const RentalCompaniesProvider = ({ children }) => {
	const {
		data: companiesList,
		isFetching: companiesListFetching,
		isError: companiesListError,
	} = useCompanies(true);

	const {
		data: rentalCompanies,
		isFetching: rentalCompaniesFetching,
		isError: rentalCompaniesError,
		refetch: refetchRentalCompanies,
	} = useRentalCompanies();

	const isFetching = rentalCompaniesFetching || companiesListFetching;
	const isError = rentalCompaniesError || companiesListError;

	return (
		<RentalCompaniesContext.Provider
			value={{
				refetchSavedCompanies: refetchRentalCompanies,
				savedCompanies: rentalCompanies,
				companiesList,
				isFetching,
				isError,
			}}
		>
			{children}
		</RentalCompaniesContext.Provider>
	);
};

RentalCompaniesProvider.propTypes = {
	children: node,
};
