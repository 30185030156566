import { makeStyles } from '@material-ui/styles';

/// Import helpers
import { BOOKING_CANCELLATION_STATES } from 'helpers';

const { APPROVED, REJECTED } = BOOKING_CANCELLATION_STATES;

export const useStyles = makeStyles((theme) => ({
	chip: {
		borderRadius: theme.layout.borderRadius.button,
		textTransform: 'uppercase',
		fontWeight: 600,
		minWidth: theme.layout.width.button,
	},
	[APPROVED]: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.primary.main,
	},
	[REJECTED]: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.medium,
	},
}));
