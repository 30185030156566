import React from 'react';
import { Grid } from '@material-ui/core';

// Import utilities
import { useCommonFields, useTranslations } from 'components/utilities';

// Import helpers
import { FIELDS } from './helpers';

// Import components
import { ButtonProgress } from 'components/elements';
import { bool } from 'prop-types';

export const FormContent = ({ isLoading }) => {
	const { renderFields } = useCommonFields();

	const { t } = useTranslations();

	return (
		<Grid container spacing={1}>
			{FIELDS.map(renderFields)}
			<Grid container item justifyContent="flex-end">
				<Grid item>
					<ButtonProgress
						circularProgressSize={24}
						isLoading={isLoading}
						disabled={isLoading}
						variant="contained"
						color="primary"
						type="submit"
					>
						{t('common.buttons.save')}
					</ButtonProgress>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isLoading: bool,
};
