import * as types from 'store/types';
import services from 'services/services';
import {
	phonePrefixUrl,
	externalPartnersUrl,
	paymentMethodListUrl,
	showBusinessCompanyUrl,
	submitCompanyFormUrl,
} from 'store/paths';
import ls from 'localstorage-slim';

// Import helpers
import {
	getExternalPartnerUuid,
	getConvertedValues,
	getExtendedOptions,
} from './helpers';
import { INITIAL_VALUES } from 'views/Company/EditCompany/helpers';
import { catchError } from '../helpers';
import { LOCAL_STORAGE_PHONE_PREFIXES } from 'helpers';

export const fetchCompanyFormResources =
	({ itemId, cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.EDIT_COMPANY_FORM_FETCH_DATA_LOADING,
			});

			const localStoragePhonePrefixes = ls.get(LOCAL_STORAGE_PHONE_PREFIXES);

			const phonePrefixesPromise =
				!localStoragePhonePrefixes && services.get(phonePrefixUrl, cancelToken);

			const externalPartnersPromise = services.get(
				externalPartnersUrl,
				cancelToken
			);

			const paymentMethodListPromise = services.get(
				paymentMethodListUrl,
				cancelToken
			);

			const allPromises = [
				phonePrefixesPromise,
				externalPartnersPromise,
				paymentMethodListPromise,
			];

			if (itemId) {
				const businessCompanyPromise = services.get(
					showBusinessCompanyUrl(itemId),
					cancelToken
				);
				allPromises.push(businessCompanyPromise);
			}

			const values = await Promise.all(allPromises);

			const [
				{ data: phonePrefixes },
				{ data: externalPartners },
				{
					data: { data: paymentMethodList },
				},
			] = values;

			if (!localStoragePhonePrefixes) {
				ls.set(LOCAL_STORAGE_PHONE_PREFIXES, phonePrefixes.data, {
					ttl: 14 * 24 * 60 * 60,
				});
			}

			const phonePrefixesResult =
				localStoragePhonePrefixes || phonePrefixes.data;

			const convertedValues = itemId && getConvertedValues(values[3].data.data);

			const result = itemId
				? convertedValues
				: Object.assign({}, INITIAL_VALUES);

			paymentMethodList.forEach((paymentMethod) => {
				if (
					!result.payment_methods.some(
						(element) => element.uuid === paymentMethod.uuid
					)
				) {
					result.payment_methods.push({
						...paymentMethod,
						behaviours: [],
						exclude: [],
						is_active: false,
					});
				}
			});

			dispatch({
				type: types.EDIT_COMPANY_FORM_FETCH_DATA_SUCCESS,
				payload: {
					phonePrefixes: phonePrefixesResult,
					externalPartners,
					item: result || {},
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.EDIT_COMPANY_FORM_FETCH_DATA_ERROR,
			});
		}
	};

export const submitCompanyForm =
	({ values, itemId, callbackSuccess, errorCallback }) =>
	async (_, getState) => {
		try {
			const {
				company: {
					form: { externalPartners },
				},
			} = getState();

			// Add uuid to external partner
			if (values.external.use_partner) {
				values.external = getExternalPartnerUuid(
					values.external,
					externalPartners
				);
			} else {
				values.external = {
					use_partner: false,
				};
			}

			values.options = getExtendedOptions(values);

			await services.post(submitCompanyFormUrl(itemId), values);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
