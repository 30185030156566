// ********************  table  ********************

export const fetchCustomersUrl = (queryParams) =>
	`v2/customer/list${queryParams}`;

export const updateApproveOrganizationUrl = (id, isActive) =>
	`/v2/customer/${id}/organization/${isActive ? 'un-verify' : 'verify'}`;

export const updateActiveCustomerUrl = (id, isActive) =>
	`/v2/customer/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const updateStateCustomerUrl = (id, action) =>
	`/v2/customer/${id}/${action}`;

export const deleteCustomerUrl = (id) => `/v2/customer/${id}/remove`;

export const analyzeCustomerDriverLicenseUrl = (customerUuid) =>
	`/v2/auth/license/customer/${customerUuid}/scan`;

// ********************  preview  ********************

export const fetchCustomerPreviewDataUrl = (id) => `/v2/customer/${id}/show`;

// ********************  form  ********************

export const fetchCustomerFormResourcesUrl = (itemId) =>
	`/v2/customer/${itemId}/show`;
export const submitCustomerFormUrl = (itemId) =>
	`/v2/customer/${itemId}/update`;

// ********************  driver license table  ********************

export const fetchDriverLicenseUrl = (queryParams) =>
	`/v2/auth/license/scanned/list${queryParams}`;

export const updateDriverLicenseUrl = (customerUuid) =>
	`/v2/auth/license/customer/${customerUuid}`;
