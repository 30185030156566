import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { CircularProgress, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import queries
import { useVehicleYearList } from 'queries';

// Import utilities
import { useTranslations } from 'components/utilities';

const CarYearFilter = () => {
	const { submitting, values } = useFormState();

	const { t } = useTranslations();

	const uuid = values.vehicle.maker_uuid;

	const { data: years, isFetching } = useVehicleYearList({
		enabled: !!uuid,
		body: { vehicle_maker: { uuid } },
	});

	if (isFetching) {
		return <CircularProgress size={28} />;
	}

	return (
		<Field
			fullWidth
			component={SelectFieldAdapter}
			label={t('bookings.edit_booking.select_car.form.car_year_field')}
			name="vehicle.year"
			variant="outlined"
			margin="dense"
			disabled={submitting}
		>
			{years.map((year) => (
				<MenuItem key={year} value={year}>
					{year}
				</MenuItem>
			))}
		</Field>
	);
};

export default CarYearFilter;
