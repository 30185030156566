export const LIST_ITEMS_TYPES = {
	REASONS: 'REASONS',
	COMPANIES: 'COMPANIES',
};

const parseDates = (dateStr) =>
	JSON.parse(dateStr).map((date) => date.replaceAll('/', '-'));

export const getOptions = (filters) => {
	let options = {
		start_date: '',
		end_date: '',
	};

	const { urgent_assistance_created_at: urgentAssistanceCreatedAt } =
		filters || {};

	if (urgentAssistanceCreatedAt) {
		const [start_date, end_date] = parseDates(urgentAssistanceCreatedAt);
		options = { start_date, end_date };
	}
	return options;
};
