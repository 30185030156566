import services from 'services/services';
import * as types from 'store/types';
import i18n from 'i18next';

// Import helpers
import { getLeasingCarsDataFromContext } from '../profile/helpers';
import {
	carCatchError,
	convertLeasingCarSubmitData,
	convertLeasingCarFetchData,
} from './helpers';
import { LEASING_CAR_INITIAL_VALUES } from 'views/Offices/LeasingCars/AddLeasingCar/helpers';
import { catchError } from '../helpers';

// Import utilities
import {
	fetchVehicleMakerListUrl,
	fetchCarListFormResourcesUrl,
	showLeasingCarDetailsUrl,
	createLeasingCarUrl,
	updateLeasingCarUrl,
	deleteLeasingCarUrl,
} from 'store/paths';

export const submitLeasingCarForm =
	({ values, isEdit, callbackSuccess, message }) =>
	async (dispatch) => {
		const { officeId, vehicleId } = getLeasingCarsDataFromContext();
		const submitData = convertLeasingCarSubmitData({
			values,
			officeId,
			isEdit,
		});

		try {
			if (isEdit) {
				await services.put(updateLeasingCarUrl(vehicleId), submitData);
			} else {
				await services.post(createLeasingCarUrl(), submitData);
			}

			clearLeasingCarInfoData()(dispatch);

			const successMessage = isEdit
				? i18n.t('cars.form.message.edit_leasing_success')
				: i18n.t('cars.form.message.add_leasing_success');
			message.success(successMessage);

			callbackSuccess();
		} catch (error) {
			if (error.response) {
				const apiErrors = error.response.data.errors ?? {
					undefinedError: i18n.t('common.messages.error_message'),
				};

				message.error(`${Object.values(apiErrors)[0]}`, {
					autoHideDuration: 10000,
				});
			}
		}
	};

export const fetchLeasingCarInfo =
	({ isEdit, vehicleId, cancelToken, officeId }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.EDIT_LEASING_CAR_FORM_FETCH_DETAILS_LOADING,
				payload: true,
			});

			// --------- FETCH CAR BRANDS -----------
			const {
				data: { data: vehicleList },
			} = await services.get(fetchVehicleMakerListUrl, cancelToken);

			if (!isEdit) {
				dispatch({
					type: types.EDIT_LEASING_CAR_FORM_FETCH_DETAILS_SUCCESS,
					payload: {
						item: LEASING_CAR_INITIAL_VALUES,
						vehicleList: [...vehicleList],
					},
				});
			}

			if (isEdit) {
				const { data: coreData } = await services.get(
					showLeasingCarDetailsUrl({ officeId, vehicleId }),
					cancelToken
				);

				const carId = coreData?.vehicle_uuid;

				const {
					data: { data: detailsVehicleData },
				} = await services.get(
					fetchCarListFormResourcesUrl(carId),
					cancelToken
				);

				const editItemData = convertLeasingCarFetchData({
					coreData,
					detailsVehicleData,
					carId,
				});

				dispatch({
					type: types.EDIT_LEASING_CAR_FORM_FETCH_DETAILS_SUCCESS,
					payload: {
						item: editItemData,
						vehicleList: [...vehicleList],
					},
				});
			}
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.EDIT_LEASING_CAR_FORM_FETCH_DETAILS_ERROR,
			});
		}
	};

export const clearLeasingCarInfoData = () => async (dispatch) => {
	try {
		dispatch({
			type: types.CLEAR_LEASING_CAR_INFO,
		});
	} catch (error) {
		carCatchError({ error, dispatch });
	}
};

export const deleteLeasingCar = (vehicleId) => async () => {
	await services.delete(deleteLeasingCarUrl(vehicleId));
};
