import React from 'react';
import { SvgIcon } from '@material-ui/core';

const AirplaneIcon = (props) => (
	<SvgIcon viewBox="0 0 512 512" {...props}>
		<path
			d="M511.996,60.921c0.168-16.341-6.104-31.703-17.659-43.259C482.933,6.257,467.822,0,451.719,0
			c-0.213,0-0.427,0.001-0.642,0.003c-16.341,0.168-31.571,6.755-42.885,18.545l-89.617,93.385L84.076,34.329l-69.253,69.253
			l195.97,120.664l-80.635,84.026l-63.288-10.39L0,364.75l109.054,38.197L147.25,512l66.87-66.87l-10.39-63.288l84.026-80.635
			L408.42,497.178l69.254-69.253L400.07,193.426l93.384-89.617C505.243,92.493,511.828,77.262,511.996,60.921z M472.661,82.142
			L365.608,184.876l77.739,234.907l-29.285,29.285L293.814,253.773L171.537,371.116l10.454,63.675l-22.193,22.193l-27.18-77.602
			l-77.602-27.18l22.193-22.193l63.675,10.454l117.343-122.276L62.933,97.94l29.285-29.285l234.907,77.739L429.859,39.341
			c5.679-5.919,13.326-9.225,21.529-9.31c0.107-0.002,0.215-0.002,0.321-0.002c8.083,0,15.67,3.141,21.396,8.867
			c5.802,5.801,8.949,13.513,8.865,21.716C481.885,68.815,478.579,76.461,472.661,82.142z"
		/>
	</SvgIcon>
);

export default AirplaneIcon;
