import React from 'react';
import { bool, object, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { AlertMessage, LoadingWrapper } from 'components/elements';

export const Content = ({ isLoading, isError, data, unit }) => {
	const { t } = useTranslations();

	const { global_avg: globalValue, business_avg: businessValue } = data || {};

	if (isLoading) return <LoadingWrapper />;
	if (isError)
		return (
			<AlertMessage
				variant="error"
				title={t('common.error')}
				description={t('common.messages.error_message')}
			/>
		);

	return (
		<Grid container spacing={1}>
			{null != globalValue && (
				<Grid item xs={12}>
					<Typography variant="h5" color="primary" component="span">
						{t('dashboard.global_avg')}: {globalValue} {unit && t(unit)}
					</Typography>
				</Grid>
			)}
			{null != businessValue && (
				<Grid item xs={12}>
					<Typography variant="h5" color="primary" component="span">
						{t('dashboard.business_avg')}: {businessValue} {unit && t(unit)}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

Content.propTypes = {
	isLoading: bool.isRequired,
	isError: bool.isRequired,
	data: object,
	unit: string,
};
