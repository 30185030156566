// Import helpers
import { URLS } from 'components/routes';
import { TOOLBAR_VARIANTS } from 'helpers';

// Import components
import { Filters } from './components';

export const TOOLBAR_CONTENT = () => [
	{
		text: 'company.toolbar.add_company_btn',
		to: URLS.createCompanyUrl,
		variant: TOOLBAR_VARIANTS.NAV,
	},
	{
		customKey: 'filters',
		component: Filters,
		variant: TOOLBAR_VARIANTS.CUSTOM,
	},
];
