// ********************  table  ********************

export const fetchUrgentReasonsUrl = (queryParams) =>
	`/v2/urgent-assistance-reason/list${queryParams}`;

export const updateActiveUrgentReasonUrl = (id, type) =>
	`/v2/urgent-assistance-reason/${id}/${type}`;

export const deleteUrgentReasonUrl = (id) =>
	`/v2/urgent-assistance-reason/${id}/remove`;

// ********************  form  ********************

export const fetchUrgentReasonsFormResourcesUrl = (itemId) =>
	`/v2/urgent-assistance-reason/${itemId}/show`;

export const submitUrgentReasonsFormUrl = (itemId, isEdit) =>
	`/v2/urgent-assistance-reason/${isEdit ? `${itemId}/update` : 'create'}`;
