import React, { useState } from 'react';
import { string, func, bool, array } from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

// Import components
import { OfficesFields } from './components';
import { ModalDialog } from 'components/elements';

const OfficesDialog = ({
	open,
	close,
	change,
	checkedOffices,
	setCheckedOffices,
}) => {
	const [displayError, setDisplayError] = useState(false);
	const [selectAll, setSelectAll] = useState(false);

	const {
		item: { officesList },
	} = useSelector((store) => store.car.form);

	const {
		errors,
		submitFailed,
		values: { offices },
	} = useFormState();

	React.useEffect(() => {
		submitFailed && close() && setDisplayError(true);
		//eslint-disable-next-line
	}, [submitFailed]);

	const onDialogSubmit = () => {
		if (checkedOffices.length === 0) {
			setDisplayError(true);
		} else {
			close();
			setDisplayError(false);
		}
		change('offices', checkedOffices);
	};

	const onCloseDialog = () => {
		const isSelectAll = officesList.length === offices.length;
		setSelectAll(isSelectAll);

		offices.length >= 0 && setCheckedOffices(offices);
		close();
	};

	const errorTitle = displayError ? errors.offices : '';

	return (
		<ModalDialog
			title="cars.form.offices.title"
			secondaryTitle="cars.form.offices.select_description"
			titleError={errorTitle}
			alignTitle="center"
			itemSpacing={0}
			maxWidth="sm"
			isActionBarSticky
			open={open}
			close={onCloseDialog}
			initialValues={{}}
			onSubmit={onDialogSubmit}
		>
			{({ submitting }) => (
				<>
					<OfficesFields
						submitting={submitting}
						checkedOffices={checkedOffices}
						setCheckedOffices={setCheckedOffices}
						selectAll={selectAll}
						setSelectAll={setSelectAll}
					/>
				</>
			)}
		</ModalDialog>
	);
};

OfficesDialog.propTypes = {
	open: bool.isRequired,
	close: func.isRequired,
	change: func.isRequired,
	setCheckedOffices: func.isRequired,
	checkedOffices: array.isRequired,
	className: string,
};

export default OfficesDialog;
