import { t } from 'i18next';
import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT, EMPTY_VALUE } from 'helpers';

export const getAmountLabel = ({
	earlyReturnTransactionAmount,
	refundAmount,
	isSubscription,
}) => {
	const paymentLabel =
		'bookings.preview_booking.early_return.fee_to_be_paid_by_customer';
	const refundLabel =
		'bookings.preview_booking.early_return.refund_to_customer';

	if (earlyReturnTransactionAmount) return paymentLabel;
	else if (refundAmount) return refundLabel;
	else return isSubscription ? paymentLabel : refundLabel;
};

const getTransactionAmount = ({
	earlyReturnTransactionAmount,
	refundAmount,
}) => {
	if (earlyReturnTransactionAmount) return earlyReturnTransactionAmount;
	else if (refundAmount) return refundAmount;
	else return EMPTY_VALUE;
};

export const getEarlyReturnFields = ({
	earlyReturnDetails,
	isSubscription,
}) => {
	const {
		early_return_transaction: { amount_gross: earlyReturnTransactionAmount },
		refund_amount: refundAmount,
	} = earlyReturnDetails || {};

	const fields = [
		{
			accessor: 'request_date',
			label: 'bookings.preview_booking.early_return.request_date',
		},
		{
			accessor: 'return_date',
			label: 'bookings.preview_booking.early_return.return_date',
		},
		{
			accessor: 'paid_at',
			label: 'common.fields.paid_at',
		},
		{
			accessor: 'view_attachment',
			label: 'bookings.preview_booking.early_return.contract',
		},
		{
			accessor: 'previous_booking_amount',
			label: 'bookings.preview_booking.early_return.previous_booking_amount',
		},
		{
			accessor: 'new_booking_amount',
			label: 'bookings.preview_booking.early_return.new_booking_amount',
		},
		{
			accessor: 'transaction_amount',
			label: getAmountLabel({
				earlyReturnTransactionAmount,
				refundAmount,
				isSubscription,
			}),
		},
	];

	return fields;
};

export const getCustomData = ({ earlyReturnDetails }) => {
	const {
		early_return_transaction: earlyReturnTransaction,
		new_subscription_period: newSubscriptionPeriod,
		old_subscription_period: oldSubscriptionPeriod,
		refund_amount: refundAmount,
		request_date: requestDate,
		return_date: returnDate,
		old_price: oldPrice,
		new_price: newPrice,
		paid_at: paidAt,
		attachments,
	} = earlyReturnDetails || {};

	const convertedAttachments = Object.entries(attachments).reduce(
		(acc, [name, file]) => {
			const convertedName = name.slice(0, -4);
			if (file)
				return [...acc, { label: t(`common.fields.${convertedName}`), file }];
			return acc;
		},
		[]
	);

	const getMonthLabel = (monthsNumber) => {
		if (monthsNumber === 1)
			return t('bookings.preview_booking.early_return.one_month_rate');
		else if (monthsNumber > 1)
			return t('bookings.preview_booking.early_return.months_rate', {
				monthsNumber,
			});
		else return '';
	};

	const newPriceAmount = newPrice.amount_gross;
	const oldPriceAmount = oldPrice.amount_gross;
	const earlyReturnTransactionAmount = earlyReturnTransaction.amount_gross;
	const newSubscriptionPeriodLabel = getMonthLabel(newSubscriptionPeriod);
	const oldSubscriptionPeriodLabel = getMonthLabel(oldSubscriptionPeriod);

	const newPriceTrans = `${t('currency.price', {
		amount: newPriceAmount,
	})} ${newSubscriptionPeriodLabel}`;

	const oldPriceTrans = `${t('currency.price', {
		amount: oldPriceAmount,
	})} ${oldSubscriptionPeriodLabel}`;

	const transactionPriceTrans = t('currency.price', {
		amount: getTransactionAmount({
			earlyReturnTransactionAmount,
			refundAmount,
		}),
	});

	return {
		request_date: requestDate
			? format(new Date(requestDate), DEFAULT_DATE_FORMAT)
			: EMPTY_VALUE,
		return_date: returnDate
			? format(new Date(returnDate), DEFAULT_DATE_FORMAT)
			: EMPTY_VALUE,
		paid_at: paidAt
			? format(new Date(paidAt), DEFAULT_DATE_FORMAT)
			: EMPTY_VALUE,
		previous_booking_amount: oldPriceAmount ? oldPriceTrans : EMPTY_VALUE,
		new_booking_amount: newPriceAmount ? newPriceTrans : EMPTY_VALUE,
		transaction_amount: transactionPriceTrans,
		attachments: convertedAttachments,
	};
};
