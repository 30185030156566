import get from 'lodash/get';
import i18next from 'i18next';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const exportToPdf = ({ data, columns }) => {
	const docDefinition = {
		defaultStyle: {
			font: 'Roboto',
		},
		content: [
			{
				style: 'table',
				table: {
					headerRows: 1,
					body: [],
				},
				layout: 'noBorders',
				defaultStyle: {
					fontSize: 10,
				},
			},
		],

		styles: {
			header: {
				fillColor: '#2d4154',
				color: 'white',
				bold: !0,
				fontSize: 11,
				alignment: 'center',
				tableBodyOdd: '',
				border: [false, false, false, false],
			},
			table: {
				fontSize: 10,
			},
			tableBodyEven: {},
			tableBodyOdd: {
				fillColor: '#f3f3f3',
			},
		},
	};

	const tableColumns = columns.map(({ Header }) => {
		return {
			text: i18next.t(Header),
			style: 'header',
		};
	});

	const tableRows = data
		.map((row) => {
			const tableColumns = columns.map(({ accessor, valueExtractor }, i) => {
				return {
					text: valueExtractor ? valueExtractor(row) : get(row, accessor),
					style: i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd',
				};
			});

			if (tableColumns.length < 1) {
				return null;
			}

			return tableColumns;
		})
		.filter((row) => !!row);

	const tableBody = docDefinition.content[0].table.body;

	tableBody.push(tableColumns);

	for (const row of tableRows) {
		tableBody.push(row);
	}

	return {
		download(filename = 'TELGANI.pdf') {
			pdfMake.createPdf(docDefinition).download(filename);
		},
	};
};

export default exportToPdf;
