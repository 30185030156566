import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	divider: {
		margin: '0 24px 20px',
	},
	dialogContent: {
		padding: '20px',
	},
}));
