import { useFormState } from 'react-final-form';

export const useFormContent = ({ cancel_reason_uuid }) => {
	const {
		values: { cancellationReason },
	} = useFormState();

	const hasReasonChanged = cancellationReason !== cancel_reason_uuid;

	return { hasReasonChanged };
};
