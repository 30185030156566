import React from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { bool, string } from 'prop-types';

// Import components
import { ButtonProgress } from 'components/elements';
import { DriversSelect } from '../../components';

// Import utils
import { useBookingPreview, useDriverUsersListQuery } from 'queries';
import { useTranslations } from 'components/utilities';

// Import helpers
import { DELIVERY_TYPES, getDeliveryAvailabilityStates } from 'helpers';

export const FormContent = ({ bookingUuid, open, isLoading }) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();
	const { data: bookingPreview, isFetching: isBookingPreviewFetching } =
		useBookingPreview(bookingUuid);

	const {
		dropDeliveryAvailableAndNotAssigned,
		pickupDeliveryAvailableAndNotAssigned,
	} = getDeliveryAvailabilityStates(bookingPreview?.delivery);

	const { data: pickupDeliveryDrivers, isFetching: isPickupDeliveryFetching } =
		useDriverUsersListQuery({
			enabled: pickupDeliveryAvailableAndNotAssigned && open,
			deliveryType: DELIVERY_TYPES.PICK_UP_DELIVERY,
			externalKey: DELIVERY_TYPES.PICK_UP_DELIVERY,
			bookingUuid,
		});

	const { data: dropDeliveryDrivers, isFetching: isDropDeliveryFetching } =
		useDriverUsersListQuery({
			enabled: dropDeliveryAvailableAndNotAssigned && open,
			deliveryType: DELIVERY_TYPES.DROP_DELIVERY,
			externalKey: DELIVERY_TYPES.DROP_DELIVERY,
			bookingUuid,
		});

	const selects = [
		{
			isVisible: pickupDeliveryAvailableAndNotAssigned,
			deliveryType: DELIVERY_TYPES.PICK_UP_DELIVERY,
			drivers: pickupDeliveryDrivers,
		},
		{
			isVisible: dropDeliveryAvailableAndNotAssigned,
			deliveryType: DELIVERY_TYPES.DROP_DELIVERY,
			drivers: dropDeliveryDrivers,
		},
	];

	const isFetching =
		isPickupDeliveryFetching ||
		isDropDeliveryFetching ||
		isBookingPreviewFetching;

	if (isFetching) {
		return (
			<Box>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Grid container spacing={2}>
			{selects.map(({ deliveryType, drivers, isVisible }) =>
				isVisible ? (
					<Grid key={deliveryType} item xs={12}>
						<DriversSelect drivers={drivers} deliveryType={deliveryType} />
					</Grid>
				) : null
			)}
			<Grid item xs={12}>
				<ButtonProgress
					circularProgressSize={24}
					variant="contained"
					color="primary"
					type="submit"
					fullWidth
					isLoading={submitting || isLoading}
					disabled={submitting || isLoading}
				>
					{t('common.buttons.submit')}
				</ButtonProgress>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	bookingUuid: string,
	isLoading: bool,
	open: bool,
};
