import React from 'react';
import { fetchScheduledBookings } from 'store/actions';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow, Export } from './components';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';

// Import utils
import { URLS } from 'components/routes';

export const ScheduledBookings = () => {
	const classes = useBaseLayoutStyles();

	return (
		<TableProvider
			columns={columns}
			fetchDataTableAction={fetchScheduledBookings}
		>
			<div className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<div className={classes.content}>
					<Table
						editLinkPath={URLS.dashboardUrl}
						isDefaultRow={false}
						tableInnerToolbar={<Export />}
					>
						{({ row, editLinkPath }) => (
							<TableBodyRow
								editLinkPath={editLinkPath}
								key={row.id}
								row={row}
							/>
						)}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};
