import React from 'react';
import { number, object, oneOfType, string } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from '../styles';

export const SettledTableCell = ({ cell, settledCount, notSettledCount }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<TableCell {...cell.getCellProps()} className={classes.settledTableCell}>
			<Typography className={classes.settlementText}>
				{settledCount} {t('rewards_settlement.settled')}
			</Typography>

			<Typography className={classes.settlementText}>
				{notSettledCount} {t('rewards_settlement.not_settled')}
			</Typography>
		</TableCell>
	);
};

SettledTableCell.propTypes = {
	settledCount: oneOfType([string, number]),
	notSettledCount: oneOfType([string, number]),
	cell: object.isRequired,
};
