import { convertFormDates } from 'helpers';

export const initialValues = {
	pickup_date: null,
	pickup_date_to: null,
	dropoff_date: null,
	dropoff_date_to: null,
	company_uuid: 'all',
	is_settled: 'all',
	has_early_return_state: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		...convertFormDates(values, ['pickup_date', 'dropoff_date']),
	};
};
