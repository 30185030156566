import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import actions and selectors
import {
	fetchEmployeeRewardingFormResources,
	submitEmployeeRewardingForm,
} from 'store/actions';
import { employeeRewardingFormSelector } from 'store/selectors';

// Import utilities
import { FormRPC, useTranslations } from 'components/utilities';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import components
import { BaseDetails } from './components';

export const EmployeeRewardingSettings = () => {
	const { t } = useTranslations(null, { keyPrefix: 'employee_rewarding.form' });

	return (
		<Box padding={4}>
			<FormRPC
				fetchFormResAction={fetchEmployeeRewardingFormResources}
				submitFormAction={submitEmployeeRewardingForm}
				store={employeeRewardingFormSelector}
				initialValues={INITIAL_VALUES}
				goBackPath={URLS.companiesListUrl}
				title={t('title')}
				isCardLayout
			>
				{() => (
					<Box>
						<Typography>{t('description')}</Typography>
						<BaseDetails />
					</Box>
				)}
			</FormRPC>
		</Box>
	);
};
