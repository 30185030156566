import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'flex-end',
		borderBottom: `1px solid ${theme.palette.background[1]}`,
		borderTop: `1px solid ${theme.palette.background[1]}`,
		padding: 20,
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '33%',
		gap: 10,
	},
	label: {
		fontSize: 12,
		fontWeight: 500,
		color: theme.palette.text.secondary,
	},
	value: {
		fontWeight: 700,
		fontSize: 12,
	},
	negativeAmount: {
		color: theme.palette.error.main,
	},
}));
