import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { officeFormSelector } from 'store/selectors';

// Import components
import { MAX_DELIVERY_OPTIONS } from '../../helpers';

const usePriceFieldArray = (sectionName) => {
	const { mutators } = useForm();

	const { prices, distances } = useSelector(officeFormSelector);

	const handlePushItem = () => mutators.push(sectionName, undefined);

	const {
		values: {
			options: { car_delivery_options: currentOptions },
		},
		initialValues: {
			options: { car_delivery_options: initialOptions },
		},
	} = useFormState();

	const isAddButtonVisible = currentOptions.length < MAX_DELIVERY_OPTIONS;

	const getMenuItems = (distances, index) => {
		return Object.entries(distances).filter(
			([uuid]) =>
				!currentOptions.find((item, i) => {
					return item?.delivery_distance_uuid === uuid && i !== index;
				})
		);
	};

	useEffect(() => {
		if (!currentOptions.length && !initialOptions.length) {
			handlePushItem();
		}
		// eslint-disable-next-line
	}, [initialOptions]);

	return {
		isAddButtonVisible,
		handlePushItem,
		getMenuItems,
		sectionName,
		distances,
		prices,
	};
};

export default usePriceFieldArray;
