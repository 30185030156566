import { SelectFieldAdapter } from 'components/elements';

export const workplaceInfoFields = ({ lng }) => [
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'customers.form.workplace.assigned_organization',
		name: `organization.name.${lng}`,
		md: 6,
		xs: 6,
		withLang: true,
	},
];
