import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
	},
	appBar: {
		backgroundColor: 'inherit',
		color: 'inherit',
		boxShadow: 'none',
	},
	tab: {
		transition: 'all 0.3s ease',
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.04)',
		},
	},
}));

export default useStyles;
