import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

// Import styles
import useStyles from './styles';

const TwoHeadedTableLabelCell = ({ style, labelHorizontal, labelVertical }) => {
	const classes = useStyles();

	return (
		<TableCell component="div" style={style} className={classes.tableCell}>
			<Typography
				variant="h6"
				component="span"
				className={classes.labelHorizontal}
			>
				{labelHorizontal}
			</Typography>
			<Typography
				variant="h6"
				component="span"
				className={classes.labelVertical}
			>
				{labelVertical}
			</Typography>
		</TableCell>
	);
};

TwoHeadedTableLabelCell.propTypes = {
	style: PropTypes.object.isRequired,
	labelHorizontal: PropTypes.string,
	labelVertical: PropTypes.string,
};

export default TwoHeadedTableLabelCell;
