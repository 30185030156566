import { format } from 'date-fns';

// Import helpers
import {
	FILE_FORMATS,
	MIME_TYPES,
	YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS_FORMAT,
} from 'helpers';

export const getFileName = ({ fileName, fileFormat }) => {
	const [date, time] = format(
		new Date(),
		YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS_FORMAT
	).split(' ');

	const formattedDate = `${date}_${time}`;

	if (fileName) {
		return `TELGANI_${fileName}_${formattedDate}.${fileFormat}`;
	}

	return `TELGANI_${formattedDate}.${fileFormat}`;
};

export const SPLIT_BUTTON_OPTIONS = Object.values(FILE_FORMATS);

export const getMimeType = ({ fileFormat }) => {
	const format = fileFormat.toUpperCase();
	return MIME_TYPES[format];
};
