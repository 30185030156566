import { convertFormDates } from 'helpers';

export const initialValues = {
	accepted_booking_counter: 'all',
	last_booking_status: 'all',
	state: 'all',
	type: 'all',
	created_at_to: null,
	created_at: null,
};

export const formatValues = (values) => {
	return {
		...values,
		state: values.state === 'all' ? null : values.state,
		type: values.type === 'all' ? null : values.type,
		last_booking_status:
			values.last_booking_status === 'all' ? null : values.last_booking_status,
		accepted_booking_counter:
			values.accepted_booking_counter === 'all'
				? null
				: values.accepted_booking_counter,
		...convertFormDates(values, ['created_at']),
	};
};
