import React from 'react';

// Import helpers
import { initialValues, formatValues } from './helpers';

// Import components
import { TableFilters } from 'components/elements';
import FilterFields from './FilterFields';
import { AdditionalInfo } from '../index';

const Filters = () => {
	return (
		<TableFilters
			initialValues={initialValues}
			formatValues={formatValues}
			additionalInfo={<AdditionalInfo />}
		>
			{() => <FilterFields />}
		</TableFilters>
	);
};

export default Filters;
