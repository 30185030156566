import React from 'react';
import { Form } from 'react-final-form';

// Import components
import { FormContent } from './components';

// Import utils
import { useConfirmationStep } from './useConfirmationStep';

export const ConfirmationStep = () => {
	const { handleOnSubmit, isButtonLoading } = useConfirmationStep();

	return (
		<Form
			onSubmit={handleOnSubmit}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<FormContent isButtonLoading={isButtonLoading} />
				</form>
			)}
		></Form>
	);
};
