import { FIELD_TYPES } from 'helpers';

export const FIELDS = ({ section, isAdmin, prefixes }) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'company.form.details.company_name_ar',
		name: `${section}.name.ar`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'company.form.details.company_name_en',
		name: `${section}.name.en`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'company.form.details.seo_slug_name',
		name: `${section}.slug`,
		md: 6,
		xs: 12,
		isHidden: !isAdmin,
		disabled: !isAdmin,
		required: true,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'company.form.details.contact_person_name',
		name: `${section}.person_name`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'company.form.details.number_prefix',
		name: `${section}.number_prefix`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
		options: prefixes,
		keyExtractor: (row) => row,
		labelExtractor: (row) => row,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'company.form.details.contact_number',
		name: `${section}.contact_number`,
		md: 6,
		xs: 12,
		disabled: !isAdmin,
		required: true,
	},
];
