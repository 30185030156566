import i18next from 'i18next';

export const columns = [
	{
		Header: 'employee_rewarding.table.headers.booking_number',
		accessor: 'booking_number',
	},
	{
		Header: 'employee_rewarding.table.headers.rating',
		accessor: 'rating',
	},
	{
		Header: 'employee_rewarding.table.headers.booking_status',
		accessor: 'booking_status',
	},
	{
		Header: 'employee_rewarding.table.headers.pickup_time',
		accessor: 'pickup_time',
	},
	{
		Header: 'employee_rewarding.table.headers.earnings',
		accessor: 'earnings',
		valueExtractor: ({ earnings }) =>
			earnings && `${i18next.t('currency.sar')} ${earnings}`,
	},
	{
		Header: 'employee_rewarding.table.headers.is_settled',
		accessor: 'is_settled',
		valueExtractor: ({ is_settled }) =>
			i18next.t(`common.${is_settled ? 'yes' : 'no'}`),
	},
];
