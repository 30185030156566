import React from 'react';
import { string } from 'prop-types';
import { fetchEmployeePerformanceUsers } from 'store/actions';

// Import columns
import { columns } from './columns';

// Import components
import { TableExportProvider } from 'components/context';
import { TableExportButtons, TableExport } from 'components/elements';
import { ExportFilters } from '../ExportFilters';

export const Export = ({ companyUuid }) => {
	return (
		<TableExportProvider
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchEmployeePerformanceUsers({ ...options, companyUuid })
			}
		>
			<TableExportButtons />
			<TableExport renderFilters={() => <ExportFilters />} />
		</TableExportProvider>
	);
};

Export.propTypes = {
	companyUuid: string.isRequired,
};
