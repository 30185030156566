/* eslint-disable no-mixed-spaces-and-tabs */
import { t } from 'i18next';

// Import helpers
import { COMPANY_MANAGER, DRIVER, OFFICE_MANAGER } from 'helpers';

export const getCellContent = (cell) => {
	const {
		is_delivery_late_assigment: isDeliveryLateAssignment,
		is_delivery_prolonged: isDeliveryProlonged,
		is_delivery_late: isDeliveryLate,
		role,
	} = cell.row.original || {};

	const transKey = 'employee_rewarding.table.cells';

	switch (role) {
		case OFFICE_MANAGER:
		case COMPANY_MANAGER: {
			return isDeliveryLateAssignment ? t(`${transKey}.late_assignment`) : '';
		}

		case DRIVER: {
			return isDeliveryLate
				? `${t(`${transKey}.late_delivery`)} ${
						isDeliveryProlonged ? '/' : ''
				  } ${isDeliveryProlonged ? t(`${transKey}.no_use_the_app`) : ''}`
				: '';
		}

		default:
			return '';
	}
};
