import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import actions
import {
	fetchCmsPartnersFormResources,
	submitCmsPartnersForm,
} from 'store/actions';

// Import components
import { BaseDetails, Filters, Design, Settings } from './components';
import { FormRPC } from 'components/utilities';
import { SeoCard } from 'components/elements';

// Import helpers
import { validationSchema } from './validation';
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';
import { yupValidator } from 'helpers';

// Import styles
import useStyles from './styles';

export const PartnersForm = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchCmsPartnersFormResources}
				submitFormAction={submitCmsPartnersForm}
				store={(store) => store.partners.form}
				initialValues={INITIAL_VALUES}
				isCardLayout={false}
				goBackPath={URLS.cmsPartnersUrl}
				noValidate
				validation={(values) => yupValidator({ values, validationSchema })}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={9}>
							<Settings />
						</Grid>
						<Grid item lg={9} xs={9}>
							<BaseDetails />
						</Grid>
						<Grid item lg={9} xs={9}>
							<Design />
						</Grid>
						<Grid item lg={9} xs={9}>
							<Filters section="filters" />
						</Grid>
						<Grid item lg={9} xs={9}>
							<SeoCard
								section="seo"
								titleName="seo_title"
								keywordsName="seo_keywords"
								descriptionName="seo_description"
							/>
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
