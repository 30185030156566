export const EMPLOYEE_ACTIVE_STATUS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: 1,
		label: 'common.filters.active',
	},
	{
		value: 0,
		label: 'common.filters.inactive',
	},
];

export const EMPLOYEE_SETTLEMENT_STATUS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: 1,
		label: 'common.filters.settled',
	},
	{
		value: 0,
		label: 'common.filters.not_settled',
	},
];
