import React from 'react';
import { string, func } from 'prop-types';
import clsx from 'clsx';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import {
	Grid,
	Card,
	Divider,
	CardContent,
	CardHeader,
} from '@material-ui/core';

// Import helpers
import { FIELDS_CAR_DETAILS } from './helpers';

// Import translations
import { useTranslations } from 'components/utilities';

const Details = ({
	section,
	fields = FIELDS_CAR_DETAILS,
	className,
	...rest
}) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Card {...rest} className={clsx(className)}>
			<CardHeader title={t('cars.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{fields(section).map(
						({ name, label, type, rows, component, md, xs, isRequired }) => (
							<Grid key={name} item md={md} xs={xs}>
								<Field
									fullWidth
									component={component}
									type={type}
									label={t(label)}
									name={name}
									disabled={submitting}
									margin="dense"
									variant="outlined"
									required={isRequired}
									rows={rows}
									multiline={rows > 1}
									inputProps={type === 'number' ? { min: 0 } : {}}
								/>
							</Grid>
						)
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

Details.propTypes = {
	section: string.isRequired,
	className: string,
	fields: func,
};

export default Details;
