import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import {
	refundTransaction,
	chargeTransaction
} from 'store/actions/bookings/bookings_transactions';

// Import queries
import { useTransactionHistory } from 'queries';

// Import helpers
import { convertApiFormErrors } from 'helpers/form';

export const TransactionHistoryContext = createContext(null);

const TransactionHistoryProvider = ({ bookingId, children }) => {
	const {
		data,
		meta,
		refetch,
		isFetching,
		isError: isDataError,
		error: dataError
	} = useTransactionHistory({ bookingId });

	const [actionError, setActionError] = useState('');

	const isActionError = !!actionError;

	const isError = isDataError || isActionError;

	const error = dataError || actionError;

	const handleRefund = async (transaction) => {
		try {
			await refundTransaction({ transactionId: transaction.uuid });
			refetch();
		} catch (err) {
			setActionError(convertApiFormErrors(error.data.errors));
		}
	};

	const handleCharge = async (transaction) => {
		try {
			await chargeTransaction({ transactionId: transaction.uuid });
			refetch();
		} catch (err) {
			setActionError(convertApiFormErrors(error.data.errors));
		}
	};

	return (
		<TransactionHistoryContext.Provider
			value={{
				data,
				meta,
				handleRefund,
				handleCharge,
				isFetching,
				isError,
				error
			}}
		>
			{children}
		</TransactionHistoryContext.Provider>
	);
};

TransactionHistoryProvider.propTypes = {
	bookingId: PropTypes.string,
	children: PropTypes.node
};

export default TransactionHistoryProvider;
