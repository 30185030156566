import React from 'react';
import { Grid } from '@material-ui/core';

// Import store
import { fetchFaqFormResources, submitFaqForm } from 'store/actions';

// Import utilities
import { FormRPC } from 'components/utilities';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';
import { yupValidator } from 'helpers';
import { validationSchema } from './validation';

// Import components
import { FaqDetails } from './components';

// Import styles
import { useStyles } from './styles';

export const FaqForm = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchFaqFormResources}
				submitFormAction={submitFaqForm}
				isCardLayout={false}
				store={(store) => store.faq.form}
				initialValues={INITIAL_VALUES}
				goBackPath={URLS.faqsUrl}
				noValidate
				validation={(values) => yupValidator({ values, validationSchema })}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<FaqDetails classes={classes} />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};
