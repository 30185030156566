import React from 'react';
import { TableCell, Tooltip } from '@material-ui/core';
import { string } from 'prop-types';

// Import components
import { EditLink } from '../EditLink';
import { DeleteButton } from '../DeleteButton';
import { IconButton } from 'components/elements';

// Import assets
import { HistoryIcon } from 'assets/icons';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import context
import { useSubTableContextProvider } from 'components/context';

// Import actions
import { deleteLeasingCar } from 'store/actions';

// Import helpers
import { replaceEditLeasingCarUrl } from 'helpers';

// Import styles
import useStyles from '../styles';

export const ActionCell = ({ vehicleId, officeId }) => {
	const { fetchData } = useSubTableContextProvider();
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<TableCell className={classes.actionCell}>
			<Tooltip title={t('common.cars.tooltip.history')}>
				<span>
					<IconButton size="small" disabled>
						<HistoryIcon className={classes.icon} />
					</IconButton>
				</span>
			</Tooltip>
			<Tooltip title={t('common.cars.tooltip.edit')}>
				<span>
					<EditLink
						editLinkPath={replaceEditLeasingCarUrl({ vehicleId, officeId })}
					/>
				</span>
			</Tooltip>
			<Tooltip title={t('common.cars.tooltip.delete')}>
				<span>
					<DeleteButton
						vehicleId={vehicleId}
						deleteAction={deleteLeasingCar}
						customFetchData={fetchData}
					/>
				</span>
			</Tooltip>
		</TableCell>
	);
};

ActionCell.propTypes = {
	vehicleId: string.isRequired,
	officeId: string.isRequired,
};
