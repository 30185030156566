import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	IMAGE: 'media',
	EXPAND: 'expand',
	PLATE: 'plate',
	ODOMETER: 'odometer',
	RENT_STATUS: 'rent_status',
	PLATE_NUMBER: 'plate_number',
};

export const columns = ({ lng, isContractPage }) => [
	{
		Header: '',
		accessor: COLUMNS_ACCESSORS.EXPAND,
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.image',
		accessor: COLUMNS_ACCESSORS.IMAGE,
		disableSortBy: false,
		isHidden: isContractPage,
	},
	{
		Header: 'common.cars.headers.maker',
		accessor: `maker_name.${lng}`,
	},
	{
		Header: 'common.cars.headers.model',
		accessor: `model_name.${lng}`,
	},
	{
		Header: 'common.cars.headers.year',
		accessor: 'year',
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.availability',
		accessor: 'plate_availability',
		disableSortBy: false,
		isHidden: isContractPage,
	},
	{
		Header: 'common.cars.headers.plate',
		accessor: COLUMNS_ACCESSORS.PLATE_NUMBER,
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.status',
		accessor: COLUMNS_ACCESSORS.RENT_STATUS,
		disableSortBy: false,
		isHidden: isContractPage,
	},
	{
		Header: 'common.cars.headers.odometer',
		accessor: COLUMNS_ACCESSORS.ODOMETER,
		disableSortBy: false,
		isHidden: isContractPage,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
		isHidden: isContractPage,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
		isHidden: isContractPage,
	},
];
