import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import utils
import { useDeliveryStatus } from './useDeliveryStatus';
import { useTranslations } from 'components/utilities';

// Import components
import { ChipLabel } from 'components/elements/atoms';

// Import styles
import { useStyles } from './DeliveryStatusTableCell.styles';

export const DeliveryStatusTableCell = ({
	cell,
	isDropOffSort = false,
	isSingleStatus = false,
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		pickupDeliveryState,
		dropoffDeliveryState,
		isPickupAllowed,
		isDropoffAllowed,
	} = useDeliveryStatus({ cell, isDropOffSort, isSingleStatus });

	const renderDeliveryStatusText = (status, type) => {
		return (
			<Typography className={classes.status}>
				{`${
					!isSingleStatus ? t(`bookings.table.common.${type}`) + ': ' : ''
				} ${t(`bookings.preview_booking.car.step_captions.${status}`)}`}
			</Typography>
		);
	};

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{!isPickupAllowed && !isDropoffAllowed && (
				<ChipLabel
					fullWidth
					label={t('common.not_available_shorthand')}
					color="gray"
					className={classes.chip}
				/>
			)}
			{isPickupAllowed
				? renderDeliveryStatusText(pickupDeliveryState, 'pickup')
				: null}

			{isDropoffAllowed
				? renderDeliveryStatusText(dropoffDeliveryState, 'return')
				: null}
		</TableCell>
	);
};

DeliveryStatusTableCell.propTypes = {
	cell: object.isRequired,
	isDropOffSort: bool,
	isSingleStatus: bool,
};
