import React from 'react';
import { Form, Field } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOfficeFormResources } from 'store/actions';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	CircularProgress,
} from '@material-ui/core';

// Import helpers
import {
	FILEDS_OFFICE_DETAILS,
	FILEDS_OFFICE_BANK_INFO,
	useStyles,
} from './helpers';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { TextFieldLabelAdapter, Alert } from 'components/elements';
import Preview from './Preview';

const OfficeDetails = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const dispatch = useDispatch();

	const { item, isLoaded, isError, error } = useSelector(
		(state) => state.offices.form
	);

	const { id } = useParams();

	React.useEffect(() => {
		const options = { isEdit: true, itemId: id };
		fetchOfficeFormResources(options)(dispatch);
		// eslint-disable-next-line
	}, []);

	// eslint-disable-next-line
	const renderFields = ({ name, label }) => (
		<Grid key={name} item md={6}>
			<Field
				component={TextFieldLabelAdapter}
				type="text"
				label={t(label)}
				name={name}
				margin="dense"
				variant="outlined"
				isDisabled={true}
				isBorder={false}
				disabled={true}
			/>
		</Grid>
	);

	const renderDetails = () => (
		<Grid item lg={6} md={6} xs={12}>
			<Card>
				<CardHeader title={t('offices.details.title')} />
				<Divider />
				<CardContent>
					{FILEDS_OFFICE_DETAILS.map(renderFields)}
					<Grid item md={6}>
						<Preview
							path="commercial_record"
							label={t('offices.details.attach_cr')}
						/>
					</Grid>
					<Grid item md={6}>
						<Preview path="logo" label={t('offices.details.logo')} />
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);

	const renderBankInfo = () => (
		<Grid item lg={6} md={6} xs={12}>
			<Card>
				<CardHeader title={t('offices.form.bank_info.title')} />
				<Divider />
				<CardContent>{FILEDS_OFFICE_BANK_INFO.map(renderFields)}</CardContent>
			</Card>
		</Grid>
	);

	return (
		<div className={classes.root}>
			<Form
				onSubmit={() => {}}
				initialValues={item}
				render={() => (
					<>
						{isError && isLoaded && <Alert message={error.message} />}
						<Grid container spacing={4}>
							{!isLoaded && (
								<div className={classes.loader}>
									<CircularProgress size={70} color="primary" />
								</div>
							)}
							{isLoaded && !isError && (
								<>
									{renderDetails()}

									{renderBankInfo()}
								</>
							)}
						</Grid>
					</>
				)}
			/>
		</div>
	);
};

export default OfficeDetails;
