import React from 'react';
import { bool, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { getCompensationTypeLabel } from 'helpers';

const TopicField = ({
	type,
	hideTitle = false,
	removeCancelState = false,
	className = '',
}) => {
	const { t } = useTranslations();

	return (
		<Grid
			alignItems="flex-start"
			justifyContent="space-between"
			wrap="nowrap"
			container
		>
			{!hideTitle && (
				<Grid item lg="auto">
					<Typography variant="h5">
						{t('bookings.compensation_history.topic')}
					</Typography>
				</Grid>
			)}
			<Grid item lg="auto">
				<Typography variant="body1" component="div" className={className}>
					{getCompensationTypeLabel({ type, removeCancelState })}
				</Typography>
			</Grid>
		</Grid>
	);
};

TopicField.propTypes = {
	type: string,
	hideTitle: bool,
	removeCancelState: bool,
	className: string,
};

export default TopicField;
