import { useLocation } from 'react-router';

// Import helpers and utils
import {
	BOOKING_CANCELLATION_STATES,
	BOOKING_COMPENSATION_STATES,
	BOOKING_URGENT_STATES,
	BOOKING_VALIDATION_TOPICS,
} from 'helpers';
import { URLS } from 'components/routes';
import { useToggle, useTranslations } from 'components/utilities';

const useBookingTopicsTableCell = ({ cell }) => {
	const { pathname } = useLocation();
	const {
		on: showFullCancellationReason,
		toggle: toggleShowFullCancellationReason,
	} = useToggle(false);

	const {
		i18n: { language },
	} = useTranslations();

	const {
		operation_topics: operationTopics,
		cancel_reason: cancelReason,
		compensation,
	} = cell.row.original || {};

	const cancelReasonName = cancelReason?.name?.[language];

	if (operationTopics) {
		return {
			topics: operationTopics,
			cancelReasonName,
			showFullCancellationReason,
			toggleShowFullCancellationReason,
			compensation,
		};
	}
	const isCompensationValidation = pathname === URLS.compensationValidationUrl;

	const { URGENT, CANCELLATION, COMPENSATION } = BOOKING_VALIDATION_TOPICS;
	const {
		urgent_assistance_status: urgentStatus,
		compensation_status: compensationStatus,
		cancel_reason_status: cancelStatus,
	} = cell.row.original;

	const isUrgent =
		urgentStatus && urgentStatus !== BOOKING_URGENT_STATES.SOLVED;

	const isCompensation =
		isCompensationValidation ||
		compensationStatus === BOOKING_COMPENSATION_STATES.REVIEW;

	const isCancellation =
		!!cancelReason?.cancel_reason_uuid &&
		!Object.values(BOOKING_CANCELLATION_STATES).some(
			(value) => cancelStatus === value
		);

	const topics = [];

	isUrgent && topics.push(URGENT);

	isCompensation && topics.push(COMPENSATION);

	isCancellation && topics.push(CANCELLATION);

	return {
		topics,
	};
};

export default useBookingTopicsTableCell;
