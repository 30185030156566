import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BendInBodyScratchIcon = (props) => (
	<SvgIcon
		width="13"
		height="13"
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.71979 0.160073C6.2178 -0.0533576 6.7822 -0.0533576 7.28021 0.160073L10.4438 1.51591C10.9105 1.71639 11.2833 2.08883 11.4838 2.55556L12.8399 5.71979C13.0534 6.2178 13.0534 6.7822 12.8399 7.28021L11.4841 10.4438C11.2836 10.9105 10.9112 11.2833 10.4444 11.4838L7.28021 12.8399C6.7822 13.0534 6.2178 13.0534 5.71979 12.8399L2.55618 11.4841C2.08946 11.2836 1.71665 10.9112 1.51617 10.4444L0.160073 7.28021C-0.0533576 6.7822 -0.0533576 6.2178 0.160073 5.71979L1.51591 2.55618C1.71639 2.08946 2.08883 1.71665 2.55556 1.51617L5.71979 0.160073ZM6.62987 1.67752C6.54716 1.64208 6.45284 1.64208 6.37013 1.67752L3.20714 3.03309C3.20706 3.03313 3.20723 3.03305 3.20714 3.03309C3.12925 3.06663 3.0667 3.12904 3.03318 3.20694C3.03315 3.20701 3.03321 3.20687 3.03318 3.20694L1.67752 6.37013C1.64208 6.45284 1.64208 6.54716 1.67752 6.62987L3.03309 9.79286C3.03313 9.79296 3.03305 9.79276 3.03309 9.79286C3.06664 9.87075 3.12908 9.93333 3.20699 9.96684C3.20704 9.96687 3.20694 9.96682 3.20699 9.96684L6.37013 11.3225C6.45284 11.3579 6.54716 11.3579 6.62987 11.3225L9.79286 9.96691C9.79289 9.9669 9.79282 9.96692 9.79286 9.96691C9.87086 9.93338 9.93334 9.87096 9.96687 9.79295C9.96688 9.79292 9.96686 9.79299 9.96687 9.79295L11.3225 6.62987C11.3579 6.54716 11.3579 6.45284 11.3225 6.37013L9.96691 3.20714C9.96689 3.20709 9.96693 3.20719 9.96691 3.20714C9.93339 3.12921 9.87101 3.06672 9.79308 3.03318C9.793 3.03315 9.79315 3.03322 9.79308 3.03318L6.62987 1.67752Z"
			fill="currentColor"
		/>
	</SvgIcon>
);
