import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import get from 'lodash/get';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from '../columns';

// Import components
import { DateTableCell, DescriptionTableCell } from './';

const { DATE, DESCRIPTION } = COLUMNS_ACCESSORS;

const UrgentReasonsHistoryRow = ({ row }) => {
	return (
		<TableRow>
			{columns.map(({ accessor }) => {
				switch (accessor) {
					case DATE:
						return <DateTableCell key={accessor} cell={row} />;
					case DESCRIPTION:
						return <DescriptionTableCell key={accessor} cell={row} />;
					default:
						return <TableCell key={accessor}>{get(row, accessor)}</TableCell>;
				}
			})}
		</TableRow>
	);
};

UrgentReasonsHistoryRow.propTypes = {
	row: PropTypes.object,
};

export default UrgentReasonsHistoryRow;
