import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { Grid, MenuItem, Typography } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

//Import icons
import { EditIcon } from 'assets/icons';

// Import styles
import { useStyles } from './styles';

const PickUpTimeField = () => {
	const { submitting, values } = useFormState();

	const { t } = useTranslations();

	const classes = useStyles();

	const pickUpTimeList = Object.entries(values.supplier.time_list);

	return (
		<Grid container spacing={2} justifyContent="space-between">
			<Grid item>
				<Typography variant="h6">
					{t('bookings.edit_booking.form.pick_up_time')}
				</Typography>
			</Grid>
			<Grid item>
				<Field
					type="select"
					component={SelectFieldAdapter}
					name="period.pick_time"
					disabled={submitting}
					InputProps={{ disableUnderline: true }}
					SelectProps={{
						IconComponent: EditIcon,
						className: classes.select,
					}}
				>
					{pickUpTimeList.map(([value, label]) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</Field>
			</Grid>
		</Grid>
	);
};

export default PickUpTimeField;
