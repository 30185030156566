import { useForm } from 'react-final-form';

export const useSearchPlateDialog = ({ toggle }) => {
	const { change, batch } = useForm();

	const onSelectPlate = ({ plate, uuid }) => {
		if (plate) {
			batch(() => {
				change('plate', plate);
				change('vehicle_details.vehicle_plate_uuid', uuid);
			});
			toggle();
		}
	};

	return { onSelectPlate };
};
