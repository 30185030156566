import React from 'react';
import { Form } from 'react-final-form';
import { Dialog, DialogContent, Button } from '@material-ui/core';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';

// Import utils and helpers
import { useToggle, useTranslations } from 'components/utilities';
import { useCancelBookingDialog } from './useCancelBookingDialog';

// Import styles
import { useStyles } from './CancelBookingDialog.styles';

export const CancelBookingDialog = () => {
	const { on: isOpen, toggle } = useToggle();
	const classes = useStyles();
	const { t } = useTranslations();
	const { isButtonLoading, handleOnSubmit } = useCancelBookingDialog(toggle);

	return (
		<>
			<Button
				className={classes.button}
				onClick={toggle}
				variant="contained"
				size="large"
			>
				{t('common.buttons.cancel')}
			</Button>
			<Dialog onClose={toggle} open={isOpen} fullWidth>
				<ClosableDialogTitle
					onClose={toggle}
					title={t('b2b.booking_preview.cancel_booking')}
				/>
				<DialogContent>
					<Form
						onSubmit={handleOnSubmit}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<FormContent
									isButtonLoading={isButtonLoading}
									toggle={toggle}
								/>
							</form>
						)}
					></Form>
				</DialogContent>
			</Dialog>
		</>
	);
};
