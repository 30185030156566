import React from 'react';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { REASON_DETAILS_FIELDS } from './helpers';

const ReasonName = () => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Card>
			<CardHeader title={t('urgent_reasons.form.reason_name.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{REASON_DETAILS_FIELDS.map(
						({ component, type, name, label, md, xs }) => (
							<Grid key={name} item md={md} xs={xs}>
								<Field
									fullWidth
									component={component}
									type={type}
									label={t(label)}
									name={name}
									variant="outlined"
									margin="dense"
									disabled={submitting}
									multiline
									required
								/>
							</Grid>
						)
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ReasonName;
