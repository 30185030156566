import services from 'services/services';
import { fetchCitiesUrl, updateActivateCityUrl } from 'store/paths';

export const fetchCities = ({
	options,
	queryParams,
	cancelToken,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = { start: pageIndex * pageSize, length: pageSize };

		// Get response
		const { data } = await services.post(
			fetchCitiesUrl(queryParams),
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActivateCity = ({ isActive, id }) => async () => {
	await services.put(updateActivateCityUrl(id, isActive));
};
