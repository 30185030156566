import React from 'react';
import { TableCell, Collapse, TableRow } from '@material-ui/core';
import { bool, func, number, string } from 'prop-types';

// Import component
import { SubTableProvider } from 'components/context';
import { SubTable } from 'components/elements';
import { TableBodyRow } from './components';

// Import helpers
import { fetchVehiclePlatesList } from 'store/actions';
import { columns } from '../../columns';

// Import utils
import { useTranslations } from 'components/utilities';

export const ExpandedRow = ({
	isExpanded,
	colSpan,
	vehicleUuid,
	isContractPage,
	onSelectPlate,
}) => {
	const { i18n } = useTranslations();
	const lng = i18n.language;

	return (
		<TableRow>
			<TableCell style={{ padding: 0 }} colSpan={colSpan}>
				<Collapse in={isExpanded} unmountOnExit>
					<SubTableProvider
						columns={columns({ lng, isContractPage })}
						fetchDataTableAction={(options) =>
							fetchVehiclePlatesList({
								...options,
								vehicleUuid,
							})
						}
					>
						<SubTable>
							{({ row }) => (
								<TableBodyRow
									key={row.id}
									row={row}
									onSelectPlate={onSelectPlate}
								/>
							)}
						</SubTable>
					</SubTableProvider>
				</Collapse>
			</TableCell>
		</TableRow>
	);
};

ExpandedRow.propTypes = {
	isExpanded: bool.isRequired,
	vehicleUuid: string.isRequired,
	colSpan: number,
	isContractPage: bool,
	onSelectPlate: func,
};

ExpandedRow.defaultProps = {
	colSpan: 1,
	isContractPage: false,
	onSelectPlate: () => {},
};
