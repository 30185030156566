export const convertSubmitData = (values) => {
	const { clients } = values || {};
	if (clients?.length) {
		return {
			clients: clients.map(({ version: { current, minimal }, ...rest }) => ({
				...rest,
				minimal_version: minimal,
				current_version: current,
			})),
		};
	}
};
