// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { BOOKING_EXTENSION_STATUS } from 'helpers';

const usePriceDifferenceDescription = ({ booking }) => {
	const { t } = useTranslations();

	const {
		admin_edit_booking_gross_price_diff: priceDifference,
		extension,
		is_edited,
	} = booking || {};

	const extensionState = extension?.extension_state;

	const isExtensionStatusPending =
		extensionState === BOOKING_EXTENSION_STATUS.PENDING;

	const difference = +priceDifference;

	const isHidden = !is_edited || !difference || isExtensionStatusPending;

	const amount = Math.abs(difference);

	const price = t('currency.price', { amount });

	const isNegativeDifference = difference < 0;

	const description = t(
		`bookings.preview_booking.booking_info.${
			isNegativeDifference ? 'negative' : 'positive'
		}_price_difference`
	);

	return {
		description,
		isHidden,
		price,
	};
};

export default usePriceDifferenceDescription;
