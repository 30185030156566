import AddCircleIcon from '@material-ui/icons/AddCircle';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.NAV,
		to: '/cancellationReason/add',
		icon: AddCircleIcon,
		text: 'cancellation_reasons.table.toolbar.add',
	},
];
