// ********************  table  ********************

export const fetchManagersListUrl = (queryParams, itemId) =>
	itemId
		? `/v2/supplier/business/company/${itemId}/manager/list${queryParams}`
		: `/v2/supplier/business/manager/list${queryParams}`;

export const updateActiveManagerUrl = (id, isActive) =>
	`/v2/supplier/business/manager/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const fetchManagerAssignOfficesUrl = (userId) =>
	`/v2/supplier/business/manager/${userId}/office-list`;

export const updateManagerAssignOfficesUrl = (userId) =>
	`/v2/supplier/business/manager/${userId}/office-assign`;

export const deleteManagerUrl = (id) =>
	`/v2/supplier/business/manager/${id}/remove`;
