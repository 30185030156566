import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils and helpers
import { useEContract, useProfile } from 'components/utilities';
import { useInitiateContractInitialValues } from 'queries';
import {
	replaceSuspendContractUrl,
	replaceCreateContractUrl,
	replaceExtendContractUrl,
	replaceCloseContractUrl,
	replaceContractUrl,
	replaceViewDraftUrl,
	CONTRACT_STATUS,
} from 'helpers';

export const useBookingIdCardSection = () => {
	const { DRAFT } = CONTRACT_STATUS;
	const {
		individual_number: individualNumber,
		uuid: bookingUuid,
		corporate,
	} = useSelector(previewBookingSelector);

	const {
		can_cancel: isCancelCorporateBookingButtonVisible,
		can_extend: isExtendCorporateBookingButtonVisible,
	} = corporate || {};

	const { isAdmin, isCorporateCoordinator } = useProfile();

	const { push } = useHistory();

	const { data: collection, isFetching: collectionFetching } =
		useInitiateContractInitialValues(bookingUuid, !isCorporateCoordinator);

	const {
		contract_number: contractNumber,
		has_credentials: hasCredentials,
		contract_details: contractDetails,
	} = collection || {};

	const { contract_state: contractState } = contractDetails || {};

	const {
		isInitiateContractButtonVisible:
			isInitiateContractButtonVisibleWithoutDraft,
		isSuspendContractButtonVisible,
		isExtendContractButtonVisible,
		isCloseContractButtonVisible,
		isViewContractButtonVisible,
		contractDataFetching,
		isBeforePickup,
	} = useEContract({ contractNumber, hasCredentials });

	const isViewDraftContractButtonVisible =
		!contractNumber &&
		!isAdmin &&
		contractState &&
		contractState === DRAFT &&
		isBeforePickup;

	const isInitiateContractButtonVisible =
		isInitiateContractButtonVisibleWithoutDraft && contractState !== DRAFT;

	const isFetching = collectionFetching || contractDataFetching;

	const goToSuspendContractForm = () => push(replacedSuspendContractUrl);
	const goToCreateContractForm = () => push(replacedCreateContractUrl);
	const goToViewDraftForm = () => push(replacedViewDraftUrl);
	const goToExtendContractForm = () => push(replacedExtendContractUrl);
	const goToCloseContractForm = () => push(replacedCloseContractUrl);
	const goToContractView = () => push(replacedContractViewUrl);

	const replacedSuspendContractUrl = replaceSuspendContractUrl(bookingUuid);
	const replacedCreateContractUrl = replaceCreateContractUrl(bookingUuid);
	const replacedViewDraftUrl = replaceViewDraftUrl(bookingUuid);
	const replacedExtendContractUrl = replaceExtendContractUrl(bookingUuid);
	const replacedCloseContractUrl = replaceCloseContractUrl(bookingUuid);
	const replacedContractViewUrl = replaceContractUrl(bookingUuid);

	return {
		isExtendCorporateBookingButtonVisible,
		isCancelCorporateBookingButtonVisible,
		isInitiateContractButtonVisible,
		isSuspendContractButtonVisible,
		isExtendContractButtonVisible,
		isCloseContractButtonVisible,
		isViewContractButtonVisible,
		isCorporateCoordinator,
		isFetching,
		isAdmin,
		goToSuspendContractForm,
		goToExtendContractForm,
		goToCreateContractForm,
		goToCloseContractForm,
		goToContractView,
		individualNumber,
		bookingUuid,
		goToViewDraftForm,
		isViewDraftContractButtonVisible,
	};
};
