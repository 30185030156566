import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { EARLY_RETURN_CALCULATIONS_QUERY } from 'helpers';

export const useEarlyReturnCalculations = ({ uuid, enabled, returnDate }) => {
	const url = `/v2/booking/${uuid}/early-return/calculate?return_date=${returnDate}`;

	const { data, isFetching, isError } = useQuery(
		[EARLY_RETURN_CALCULATIONS_QUERY, returnDate],
		async () => await services.get(url),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
