import React from 'react';
import { number, object, string } from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSelector } from 'react-redux';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';
import { SYSTEM_CLIENTS_FIELDS } from '../../helpers';
import { mobileAppVersionSelector } from 'store/selectors';

export const SystemClientFieldRow = ({ fields, name, index }) => {
	const { renderFields } = useCommonFields();
	const { item: appVersions } = useSelector(mobileAppVersionSelector);

	const isIconButtonDisabled = fields.length === 1;

	return (
		<Grid container spacing={2} key={name} alignItems="flex-end">
			{SYSTEM_CLIENTS_FIELDS({ name, appVersions }).map(renderFields)}
			<Grid item md={2} xs={1}>
				<IconButton
					onClick={() => fields.remove(index)}
					disabled={isIconButtonDisabled}
				>
					<HighlightOffIcon
						color={isIconButtonDisabled ? 'disabled' : 'error'}
					/>
				</IconButton>
			</Grid>
		</Grid>
	);
};

SystemClientFieldRow.propTypes = {
	fields: object.isRequired,
	name: string.isRequired,
	index: number.isRequired,
};
