import React from 'react';
import { List, ListItem, Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useStyles } from './styles';

// Import components
import { PublicHoliday } from '../PublicHoliday';
import { LoadingWrapper } from 'components/elements';

const DateFields = () => {
	const { values } = useFormState();
	const classes = useStyles();

	if (!values.company_uuid) {
		return <LoadingWrapper className={classes.loadingWrapper} />;
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<List>
					{values?.holidays.map(
						(
							{ name, selected, date_from: dateFrom, date_to: dateTo },
							index
						) => (
							<ListItem key={name}>
								<PublicHoliday
									selected={selected}
									dateFrom={dateFrom}
									dateTo={dateTo}
									index={index}
									name={name}
								/>
							</ListItem>
						)
					)}
				</List>
			</Grid>
		</Grid>
	);
};

export default DateFields;
