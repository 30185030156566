import services from 'services/services';
import {
	fetchManagersListUrl,
	updateActiveManagerUrl,
	fetchManagerAssignOfficesUrl,
	updateManagerAssignOfficesUrl,
	deleteManagerUrl,
} from 'store/paths';

// Import helpers
import { convertRoleToDisplay } from 'helpers';

// Fetch offices managers list
export const fetchManagersList = ({
	options,
	itemId,
	queryParams,
	cancelToken,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		// Get response
		const { data: responseData } = await services.post(
			fetchManagersListUrl(queryParams, itemId),
			body,
			cancelToken
		);

		const { data: users } = responseData;

		const usersData = users.map((item) => {
			const role = convertRoleToDisplay(item.role);
			return { ...item, role };
		});

		const data = {
			...responseData,
			data: usersData,
		};

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

// Active / deactive offices manager
export const updateActiveManager = ({ isActive, id }) => async () => {
	await services.put(updateActiveManagerUrl(id, isActive));
};

// Fetch offices managers assign offices
export const fetchManagerAssignOffices = async ({ userId }) => {
	const {
		data: { data: assignOffices },
	} = await services.get(fetchManagerAssignOfficesUrl(userId));

	return assignOffices;
};

// Uptade offices managers assign offices
export const updateManagerAssignOffices = ({ userId, offices }) => async () => {
	const body = {
		offices,
	};

	await services.put(updateManagerAssignOfficesUrl(userId), body);
};

// Delete office manager
export const deleteManager = ({ id }) => async () => {
	await services.delete(deleteManagerUrl(id));
};
