import { useFormState } from 'react-final-form';

// Import components
import { SectionContent, CarScratchesSection } from '../../components';

// Import utils
import { useCloseContractProvider } from 'views/Bookings/CloseContract/context';

// Import helpers
import { isClosureCodeRequired } from 'helpers';
import {
	CONTRACT_DETAILS_FIELDS,
	PAYMENT_DETAILS_FIELDS,
	RENT_STATUS_FIELDS,
	REQUIRED_FIELDS,
} from './helpers';

// Import styles
import { useStyles } from 'theme/styles/eContract.styles';

export const useDataFormContent = () => {
	const { formOptions, branches } = useCloseContractProvider();
	const classes = useStyles();
	const { values } = useFormState();

	const {
		narrowed_usable_conditions: narrowedUsableConditions,
		availability_states: availabilityStates,
		working_conditions: workingConditions,
		usable_conditions: usableConditions,
		fuel_availabilities: fuelAvailabilities,
		payment_methods: paymentMethods,
		closure_reasons: closureReasons,
		purity_states: purityStates,
	} = formOptions || {};

	const mainClosureCode = values.main_closure_code;
	const subClosureReasons = closureReasons?.find(
		({ id }) => id === mainClosureCode
	)?.subReasons;

	const closureCodeRequired = isClosureCodeRequired({
		mainClosureCode,
		closureReasons,
	});

	const sections = [
		{
			section: 'required_fields',
			children: SectionContent,
			fields: REQUIRED_FIELDS({
				closureCodeRequired,
				subClosureReasons,
				closureReasons,
			}),
			defaultMinimize: false,
		},
		{
			section: 'contract_details',
			children: SectionContent,
			fields: CONTRACT_DETAILS_FIELDS({ branches }),
			defaultMinimize: true,
		},
		{
			section: 'rent_status',
			children: SectionContent,
			fields: RENT_STATUS_FIELDS({
				narrowedUsableConditions,
				fuelAvailabilities,
				availabilityStates,
				workingConditions,
				usableConditions,
				purityStates,
				classes,
			}),
			defaultMinimize: true,
		},
		{
			section: 'payment_details',
			children: SectionContent,
			fields: PAYMENT_DETAILS_FIELDS({ paymentMethods }),
			defaultMinimize: true,
		},
		{
			section: 'car_scratches',
			children: CarScratchesSection,
			fields: null,
			defaultMinimize: true,
		},
	];

	return { sections };
};
