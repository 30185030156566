import React from 'react';
import { func } from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const useForm = ({ fetchFormResAction, store }) => {
	const data = useSelector(store);
	const { pathname } = useLocation();
	const { id, carId } = useParams();
	const isEdit = pathname.includes('edit');
	const dispatch = useDispatch();
	const { getState }= useStore();

	React.useEffect(() => {
		const source = axios.CancelToken.source();
		const cancelToken = source.token;
		
		fetchFormResAction({
			isEdit,
			itemId: id,
			carId: carId,
			cancelToken,
		})(dispatch, getState);

		return () => {
			source.cancel();
		};
		// eslint-disable-next-line
	}, [id]);

	return {
		isEdit,
		itemId: id,
		carId: carId,
		data
	};
};

useForm.propTypes = {
	fetchFormResAction: func.isRequired,
};

export default useForm;
