import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { string } from 'prop-types';

// Import utilities and helpers
import { useCommonFields } from 'components/utilities';
import { multiSelectLabelExtractor } from './helpers';
import { useAllCities } from 'queries';
import { FIELD_TYPES } from 'helpers';

export const CitiesFilter = ({ label, name }) => {
	const { data: cities, isFetching } = useAllCities();
	const { renderFields } = useCommonFields();
	const { values } = useFormState();

	if (!cities) {
		return null;
	}

	return (
		<Grid container item xs={3} md={3}>
			{isFetching ? (
				<Grid item xs>
					<CircularProgress size={24} />
				</Grid>
			) : (
				renderFields({
					type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
					currentMultiSelectValues: values?.[name],
					labelExtractor: ({ name }) => name,
					keyExtractor: ({ uuid }) => uuid,
					searchExtractor: 'children[1]',
					multiSelectLabelExtractor,
					showAllOption: true,
					options: cities,
					label,
					name,
				})
			)}
		</Grid>
	);
};

CitiesFilter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
};
