import produce from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	isTableChanged: false,
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.BOOKINGS_VALIDATION_NEED_TO_REFRESH:
				draft.isTableChanged = true;
				break;
			case types.BOOKINGS_VALIDATION_NO_NEED_TO_REFRESH:
				draft.isTableChanged = false;
				break;

			default:
				return state;
		}
	});
