import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { useFormState, Field } from 'react-final-form';
import { queryCache } from 'react-query';
import {
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
	Grid,
	MenuItem,
	Box,
	Checkbox,
} from '@material-ui/core';
import { useVehicleTypes } from 'queries';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';

// Import helpers
import { capitalize, CAR_LIST_VEHICLE_TYPES_QUERY } from 'helpers';

//Import styles
import useStyles from '../../../../styles';

export const Types = ({ section }) => {
	const { t } = useTranslations();

	const { submitting, values } = useFormState();

	const { types } = values;

	const classes = useStyles();

	useEffect(() => {
		return () => {
			queryCache.cancelQueries(CAR_LIST_VEHICLE_TYPES_QUERY);
			queryCache.removeQueries(CAR_LIST_VEHICLE_TYPES_QUERY);
		};
		//eslint-disable-next-line
	}, []);

	const {
		data: vehicleTypes,
		isFetching: isVehicleTypesLoading,
		isError: isVehicleTypesError,
	} = useVehicleTypes();

	const isVehiclesTypesLoaded = !isVehicleTypesLoading && !isVehicleTypesError;

	return (
		<Card>
			<CardHeader title={t('cars.car_list.form.types.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						{isVehiclesTypesLoaded ? (
							<Field
								fullWidth
								component={MultiselectFiledAdapter}
								type="select"
								label="cars.car_list.form.types.type"
								name={section}
								margin="dense"
								variant="outlined"
								disabled={submitting}
							>
								{vehicleTypes.map(({ uuid, type }) => (
									<MenuItem key={uuid} value={type}>
										<Checkbox color="primary" checked={types.includes(type)} />
										{capitalize(type)}
									</MenuItem>
								))}
							</Field>
						) : (
							<Box className={classes.loader}>
								<CircularProgress size={35} />
							</Box>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

Types.propTypes = {
	section: string.isRequired,
};
