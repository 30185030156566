import React from 'react';
import { object, node, string, func } from 'prop-types';
import {
	InputLabel,
	Select,
	FormControl,
	Chip,
	Box,
	OutlinedInput,
} from '@material-ui/core';

// Import translation
import { useTranslations } from 'components/utilities';

// Import components
import { InputError } from 'components/elements';

// Import styles
import useStyles from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 'auto',
			minWidth: 250,
		},
	},
};

const MultiselectFiledAdapter = ({
	input,
	label,
	meta,
	children,
	labelextractor,
	...rest
}) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const error = !!(meta.touched && (meta.error || meta.submitError));

	labelextractor = labelextractor ? labelextractor : (value) => value; // No labelExtractor because DOM error

	return (
		<FormControl error={error} className={classes.formControl}>
			<InputLabel id="mutiple-chip-label" required={rest?.required}>
				{t(label)}
			</InputLabel>
			<Select
				{...input}
				{...rest}
				labelId="mutiple-chip-label"
				multiple
				input={<OutlinedInput className={classes.outlinedInput} />}
				renderValue={(selected) => (
					<Box className={classes.chips}>
						{selected.map((value) => (
							<Chip
								key={value}
								label={labelextractor(value)}
								className={classes.chip}
							/>
						))}
					</Box>
				)}
				MenuProps={MenuProps}
			>
				{children}
			</Select>
			<InputError meta={meta} />
		</FormControl>
	);
};

MultiselectFiledAdapter.propTypes = {
	label: string.isRequired,
	input: object.isRequired,
	meta: object.isRequired,
	children: node.isRequired,
	labelextractor: func,
};

export default MultiselectFiledAdapter;
