import React from 'react';
import { Box, Collapse, Divider, Grid } from '@material-ui/core';

// Import utilities
import { useEditBookingProvider } from 'views/Bookings/EditBooking/context';
import { useTranslations } from 'components/utilities';
import useCarDetails from './useCarDetails';

// Import components
import {
	DefaultTextField,
	ActionButtons,
	SelectCarFilters,
} from '../../components';
import { ButtonProgress, Card } from 'components/elements';

// Import styles
import useStyles from '../styles';

const CarDetails = () => {
	const { price, name, submitting } = useCarDetails();

	const { on, toggle, converted } = useEditBookingProvider();

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Box py={2}>
			<Card
				title={t('bookings.edit_booking.form.car_details')}
				lg={12}
				actions={
					<Grid container>
						{on ? (
							<Grid item>
								<ActionButtons toggle={toggle} />
							</Grid>
						) : (
							<Grid item>
								<ButtonProgress
									color="primary"
									variant="contained"
									onClick={toggle}
									disabled={submitting || !converted}
									isLoading={submitting || !converted}
									circularProgressSize={28}
								>
									{t('common.buttons.edit')}
								</ButtonProgress>
							</Grid>
						)}
					</Grid>
				}
			>
				<DefaultTextField
					label={t('bookings.edit_booking.form.selected_car')}
					value={name}
				/>
				<DefaultTextField
					label={t('bookings.edit_booking.form.price')}
					value={price}
				/>
				<Collapse in={on}>
					<Divider className={classes.divider} />
					<SelectCarFilters />
				</Collapse>
			</Card>
		</Box>
	);
};

export default CarDetails;
