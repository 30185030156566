import React from 'react';
import { array } from 'prop-types';
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
} from '@material-ui/core';
import { get, isFunction } from 'lodash';

// Import columns
import { columns } from './columns';

// Import translations
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

export const SubscriptionPaymentsTable = ({ payments }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const renderHeader = (column) => (
		<TableCell key={column.header}>{t(column.header)}</TableCell>
	);

	const renderRow = (row) => (
		<TableRow key={row.uuid} className={row.exists ? '' : classes.notExists}>
			{columns.map((column) => (
				<TableCell key={column.accessor}>
					{isFunction(column.valueExtractor)
						? column.valueExtractor(row)
						: get(row, column.accessor)}
				</TableCell>
			))}
		</TableRow>
	);

	return (
		<TableContainer component={Paper} className={classes.tableContainer}>
			<Table>
				<TableHead>
					<TableRow>{columns.map(renderHeader)}</TableRow>
				</TableHead>
				<TableBody>{payments.map(renderRow)}</TableBody>
			</Table>
		</TableContainer>
	);
};

SubscriptionPaymentsTable.propTypes = {
	payments: array,
};
