import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import store
import { cancelCorporateBooking, fetchBookingPreview } from 'store/actions';

// Import utils
import { useMessage, useProfile, useTranslations } from 'components/utilities';

export const useCancelBookingDialog = (toggle) => {
	const [isButtonLoading, setButtonLoading] = useState(false);
	const { id: bookingUuid } = useParams();
	const { message } = useMessage();
	const { t } = useTranslations();
	const dispatch = useDispatch();
	const { isCorporateCoordinator } = useProfile();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};
		setButtonLoading(true);

		await cancelCorporateBooking({
			successCallback: async () => {
				await fetchBookingPreview({ id: bookingUuid, isCorporateCoordinator })(
					dispatch
				);
				message.success(t('common.messages.successfully_done'));
				setButtonLoading(false);
				toggle();
			},
			errorCallback: (error) => {
				setButtonLoading(false);
				if (error.response) {
					apiErrors = error.response.data.errors;
				}
				if (error.response.data.message) {
					message.error(error.response.data.message);
				}
			},
			bookingUuid,
			values,
		});

		return apiErrors;
	};

	return {
		isButtonLoading,
		handleOnSubmit,
	};
};
