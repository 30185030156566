import React from 'react';
import { object } from 'prop-types';
import { TableRow } from '@material-ui/core';

// Import components
import { CompanyNameTableCell, AvailableCarTableCell } from '../../components';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';

export const TableBodyRow = ({ row }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.BASE_COLUMN:
				return <CompanyNameTableCell key={cell.column.id} cell={cell} />;

			default:
				return <AvailableCarTableCell key={cell.column.id} cell={cell} />;
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
