import React from 'react';
import { object, bool } from 'prop-types';

// Import components
import { BusinessHours } from 'components/elements';

const BusinessHoursFieldAdapter = ({ input, isOfficeManager, ...rest }) => {
	const { name, onChange, value } = input;

	const handleChange = (newValue) => {
		onChange({
			target: {
				name,
				value: newValue,
			},
		});
	};

	return (
		<BusinessHours
			isOfficeManager={isOfficeManager}
			onChange={handleChange}
			value={value}
			{...rest}
		/>
	);
};

BusinessHoursFieldAdapter.propTypes = {
	input: object.isRequired,
	isOfficeManager: bool,
};

export default BusinessHoursFieldAdapter;
