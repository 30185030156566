import { useSelector } from 'react-redux';
import { previewBookingSelector } from 'store/selectors';
import { useTranslations } from 'components/utilities';

const useUrgentStatusPreview = () => {
	const { urgentReasons } = useSelector(previewBookingSelector);

	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const showPreviewFields = !!urgentReasons?.length;

	const assignedBy = urgentReasons?.[0]?.blamable_user_name;
	const assignedAt = urgentReasons?.[0]?.created_at;
	const assignedByLabel = t('bookings.single_booking.details.assigned_by', {
		assignedBy,
		assignedAt,
	});

	const markedBy = urgentReasons?.[0]?.marked_by_name;
	const markedAt = urgentReasons?.[0]?.marked_at;
	const markedByLabel = t('bookings.single_booking.details.marked_by', {
		markedBy,
		markedAt,
	});

	return {
		showPreviewFields,
		assignedByLabel,
		markedByLabel,
		assignedBy,
		markedBy,
		lng,
	};
};

export default useUrgentStatusPreview;
