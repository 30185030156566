import React from 'react';
import PropTypes from 'prop-types';
import { MultiGrid } from 'react-virtualized';
import { useTheme, Box } from '@material-ui/core';

// Import components
import { Alert } from 'components/elements';
import { LoadingOverlay } from 'components/elements';

// Import helpers
import { createCellRenderer } from '../helpers';

// Import styles
import useStyles from './styles';

const TwoHeadedTable = (props) => {
	const classes = useStyles(props);

	const theme = useTheme();

	const style =
		theme.direction === 'rtl'
			? { transform: 'rotateY(180deg)', flip: false }
			: {};

	const cellRenderer = createCellRenderer(props);

	return (
		<Box className={classes.container}>
			{props.error && <Alert message={props.error} />}

			<Box className={classes.tableContainer}>
				<LoadingOverlay isLoading={props.isLoading} />

				<MultiGrid
					_rerenderTrigger={props.data}
					style={style}
					cellRenderer={cellRenderer}
					fixedRowCount={1}
					fixedColumnCount={1}
					columnCount={props.horizontalColumns.length + 1}
					rowCount={props.verticalColumns.length + 1}
					columnWidth={props.columnWidth}
					rowHeight={props.rowHeight}
					height={props.height}
					width={props.width}
				/>
			</Box>
		</Box>
	);
};

TwoHeadedTable.propTypes = {
	data: PropTypes.arrayOf(PropTypes.array),
	renderLabelCell: PropTypes.func.isRequired,
	renderHorizontalHeaderCell: PropTypes.func.isRequired,
	renderVerticalHeaderCell: PropTypes.func.isRequired,
	renderBodyCell: PropTypes.func.isRequired,
	horizontalColumns: PropTypes.array.isRequired,
	verticalColumns: PropTypes.array.isRequired,
	columnWidth: PropTypes.number.isRequired,
	rowHeight: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
};

export default TwoHeadedTable;
