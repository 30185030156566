// Import helpers
import { getCellContent } from './helpers';
import { CELL_TYPES } from '../helpers';

// Import styles
import { useStyles } from '../styles';

export const useDefaultTableCell = ({ type, tableData }) => {
	const { isRegistration, isCashback, reason } = tableData;
	const classes = useStyles({ isRegistration });
	const disableTooltip = !isCashback || type !== CELL_TYPES.DESCRIPTION;
	const cellContent = getCellContent({ type, tableData });

	return { disableTooltip, cellContent, classes, reason };
};
