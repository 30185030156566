import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

// Import components
import ProtectedDashboard from 'components/hoc/ProtectedDashboard';
import ProtectedUserLogged from 'components/hoc/ProtectedUserLogged';
import { Minimal as MinimalLayout } from 'layouts';
import {
	SignIn as SignInView,
	ForgotPassword as ForgotPasswordView,
	ResetPassword as ResetPasswordView,
	SetNewPassword as SetNewPasswordView,
} from 'views';
import DashboardRoutes from './DashRoutes';

// Import urls
import { URLS } from 'components/routes';

const Routes = () => (
	<Switch>
		<Redirect exact from={URLS.emptyUrl} to={URLS.signInUrl} />
		<ProtectedUserLogged
			layout={MinimalLayout}
			path={URLS.signInUrl}
			component={SignInView}
		/>
		<ProtectedUserLogged
			path={URLS.forgotPasswordUrl}
			component={ForgotPasswordView}
		/>
		<Route path={URLS.resetPasswordUrl} component={ResetPasswordView} />
		<Route path={URLS.setPasswordUrl} component={SetNewPasswordView} />
		<ProtectedDashboard component={DashboardRoutes} />
		<Redirect to={URLS.signInUrl} />
	</Switch>
);

export default Routes;
