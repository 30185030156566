import React from 'react';
import { object } from 'prop-types';
import { TableCell, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// Import components
import { OrganizationForm } from '../OrganizationForm';

// Import translations
import { useTranslation } from 'react-i18next';

// Import styles
import useStyles from '../styles';

const EditOrganization = ({ cell }) => {
	const [open, setOpen] = React.useState(false);

	const classes = useStyles();

	const { t } = useTranslation();

	const handleClick = () => setOpen((prev) => !prev);

	const { uuid, name } = cell.row.original;

	return (
		<TableCell key={cell.column.id}>
			<Button onClick={handleClick} color="primary" className={classes.cell}>
				<EditIcon className={classes.icon} fontSize="small" />
				{t('table:row.edit')}
			</Button>
			<OrganizationForm
				open={open}
				close={handleClick}
				uuid={uuid}
				initialValues={{ name }}
				title="organizations.form.title"
			/>
		</TableCell>
	);
};

EditOrganization.propTypes = {
	cell: object.isRequired,
};

export default EditOrganization;
