import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Collapse, Divider, Grid, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

// Import store
import { bookingBillingsSelector } from 'store/selectors';

// Import components
import { SubSection, BottomBar } from './components';

// Import styles
import { useStyles } from './ExtensionInvoice.styles';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';

export const ExtensionInvoice = () => {
	const billings = useSelector(bookingBillingsSelector);
	const { on: isOpen, toggle } = useToggle();
	const { t } = useTranslations();
	const classes = useStyles();

	const convertedBillings = billings
		.filter(({ type }) => type === 'booking_extend')
		.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

	if (!convertedBillings.length) return null;

	return (
		<Box className={classes.container}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Typography className={classes.title}>
					{t('bookings.preview_booking.invoice.extension_invoice')}
				</Typography>
				<Box component="button" className={classes.button} onClick={toggle}>
					{isOpen ? <ExpandLess /> : <ExpandMore />}
				</Box>
			</Grid>

			<Collapse in={isOpen}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Divider className={classes.divider} />
					</Grid>
					{convertedBillings.map((data, index) => (
						<Grid item xs={12} key={data.uuid}>
							<SubSection data={data} index={index} />
						</Grid>
					))}
					<Grid item xs={12}>
						<BottomBar />
					</Grid>
				</Grid>
			</Collapse>
		</Box>
	);
};
