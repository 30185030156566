import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

// Import styles
import useStyles from './styles';

const TwoHeadedTableBodyCell = ({ style, children }) => {
	const classes = useStyles();

	return (
		<TableCell
			component="div"
			style={style}
			classes={{ root: classes.tableCell }}
		>
			{children}
		</TableCell>
	);
};

TwoHeadedTableBodyCell.propTypes = {
	style: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

export default TwoHeadedTableBodyCell;
