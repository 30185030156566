import services from 'services/services';
import { fetchPagesUrl, deletePageUrl, updateActivePageUrl } from 'store/paths';

export const fetchPages =
	({ options, cancelToken, queryParams }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const { data } = await services.post(
				fetchPagesUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActivePage =
	({ isActive, id }) =>
	async () => {
		await services.put(updateActivePageUrl(id, isActive));
	};

export const deletePage =
	({ id }) =>
	async () => {
		await services.delete(deletePageUrl(id));
	};
