import React from 'react';
import {
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	InputLabel,
	Grid,
	FormHelperText,
} from '@material-ui/core';
import { bool, object, string } from 'prop-types';

// Import utils and helpers
import { useDateSelectFieldAdapter } from './useDateSelectFieldAdapter';
import { useCommonFields, useTranslations } from 'components/utilities';
import { CALENDAR_TYPES } from 'helpers';

// Import styles
import { useStyles } from './DateSelectFieldAdapter.styles';

export const DateSelectFieldAdapter = ({
	input,
	label = '',
	shouldDefaultHijri = false,
	...rest
}) => {
	const classes = useStyles();

	const { t } = useTranslations();
	const { renderFields } = useCommonFields();

	const { HIJRI, GREGORIAN } = CALENDAR_TYPES;

	const { handleRadioChange, isHijri, fields, isInvalidDate } =
		useDateSelectFieldAdapter({
			name: input.name,
			label,
			shouldDefaultHijri,
		});

	return (
		<>
			<InputLabel
				id={input.name}
				required={rest?.required}
				className={classes.label}
			>
				{label}
			</InputLabel>

			<Grid container spacing={1}>
				{fields.map(renderFields)}
			</Grid>

			<FormControl component="fieldset">
				<RadioGroup
					aria-label="date-type"
					name="date-type"
					value={isHijri ? HIJRI : GREGORIAN}
					onChange={handleRadioChange}
					row
				>
					<FormControlLabel
						value={GREGORIAN}
						control={<Radio color="primary" />}
						label={t(`common.date_select.${GREGORIAN}`)}
					/>
					<FormControlLabel
						value={HIJRI}
						control={<Radio color="primary" />}
						label={t(`common.date_select.${HIJRI}`)}
					/>
				</RadioGroup>
			</FormControl>
			{isInvalidDate && (
				<FormHelperText>{t('common.validation.invalid_date')}</FormHelperText>
			)}
		</>
	);
};

DateSelectFieldAdapter.propTypes = {
	input: object.isRequired,
	label: string,
	shouldDefaultHijri: bool,
};
