import { useState } from 'react';
import { queryCache } from 'react-query';

// Import utils and helpers
import { useMessage, useToggle, useTranslations } from 'components/utilities';
import { usePaymentProvider } from '../Payment/context';
import { SUBSCRIPTION_PAYMENTS_QUERY } from 'helpers';

// Import actions
import { settleOffline } from 'store/actions';

export const useOfflineSettlementDialog = () => {
	const { dialogCustomData, subscriptionUuid, payment } = usePaymentProvider();
	const [isLoading, setIsLoading] = useState();
	const { on: isOpen, toggle } = useToggle();
	const { t } = useTranslations();
	const { message } = useMessage();

	const refetchQuery = () =>
		queryCache.refetchQueries([SUBSCRIPTION_PAYMENTS_QUERY, subscriptionUuid]);

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		const options = {
			subscriptionUuid,
			values,
			payment,
			successCallback: () => {
				message.success(t('common.messages.successfully_done'));
				toggle();
				refetchQuery();
				setIsLoading(false);
			},
			errorCallback: (errors) => {
				message.error(t('common.messages.error_message'));
				apiErrors = errors;
				setIsLoading(false);
			},
		};

		setIsLoading(true);

		await settleOffline(options);

		return apiErrors;
	};

	return {
		handleOnSubmit,
		dialogCustomData,
		toggle,
		isOpen,
		isLoading,
	};
};
