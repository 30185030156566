import React from 'react';
import {
	CardContent,
	CardHeader,
	Divider,
	Card,
	Grid,
} from '@material-ui/core';

// Import translations
import { useCommonFields, useTranslations } from 'components/utilities';

// Import components
import { CompanyFilter, CopyToClipboard } from 'components/elements';

// Import utils and helpers
import { useSettings } from './useSettings';
import { SETTINGS_FIELDS } from './helpers';

export const Settings = () => {
	const { actions, baseDomain, handleOnCompanyChange } = useSettings();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();

	return (
		<Card>
			<CardHeader
				component="h5"
				title={t('cms.partners.form.settings.title')}
			/>
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<CompanyFilter
						label="cms.partners.form.company_name"
						name="company_uuid"
						showAll={false}
						required
						md={6}
						xs={12}
						onChange={handleOnCompanyChange}
					/>
					{SETTINGS_FIELDS(baseDomain).map(renderFields)}
					<Grid item xs={12} md={6}>
						<CopyToClipboard actions={actions} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
