import { useState } from 'react';
import { useForm } from 'react-final-form';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useAllCarModels } from 'queries';

// Import helpers
import { sortByName } from '../helpers';

const useAllCarModelsFilter = ({ name }) => {
	const { data: models, isFetching } = useAllCarModels();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const initialOption = {
		name: { en: t('common.all'), ar: t('common.all') },
		uuid: 'all',
	};
	const sortedModels = [initialOption, ...sortByName(models, language)];

	const { change } = useForm();

	const [selectedModel, setSelectedModel] = useState(initialOption);

	const getOptionSelected = (option, value) => option.uuid === value.uuid;

	const getOptionLabel = (option) => option.name?.[language];

	const handleOnChange = (_, newValue) => {
		setSelectedModel(newValue);
		change(name, newValue?.uuid || initialOption.uuid);
	};

	return {
		getOptionSelected,
		getOptionLabel,
		handleOnChange,
		selectedModel,
		sortedModels,
		isFetching,
	};
};

export default useAllCarModelsFilter;
