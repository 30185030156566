import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
}));
