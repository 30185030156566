import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),
		minWidth: '105px',
		display: 'inline-block',
	},
	disabledInput: {
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
		'& .MuiOutlinedInput-root': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			display: 'none',
		},
		'& .MuiInputBase-input.Mui-disabled': {
			color: '#263238',
		},
	},
}));
