export const COLUMNS_ACCESSORS = {
	CUSTOMER_NAME: 'customer_name',
	LAST_BOOKING_ID: 'last_booking_id',
	CAR_NAME: 'car_name',
	SETTLEMENT: 'settlement',
	RATING: 'rating',
	BOOKING_AMOUNT: 'booking_amount',
	ACTIONS: 'actions',
	CREATED_AT: 'created_at',
	PICK_DATE: 'pick_date',
};

export const columns = [
	{
		Header: 'table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.LAST_BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.car_name',
		accessor: COLUMNS_ACCESSORS.CAR_NAME,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.amount_of_booking',
		accessor: COLUMNS_ACCESSORS.BOOKING_AMOUNT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.settlement',
		accessor: COLUMNS_ACCESSORS.SETTLEMENT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.rating',
		accessor: COLUMNS_ACCESSORS.RATING,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
