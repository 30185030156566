import React from 'react';
import { object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import helpers
import { formatDates } from './helpers';
import { EMPTY_VALUE } from 'helpers';

export const ExtendedAtMultipleDatesTableCell = ({ cell }) => {
	const datesArr = formatDates(cell);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{datesArr.length ? (
				datesArr.map(([date, time]) => (
					<Typography key={time}>
						{date} {time}
					</Typography>
				))
			) : (
				<Typography>{EMPTY_VALUE}</Typography>
			)}
		</TableCell>
	);
};

ExtendedAtMultipleDatesTableCell.propTypes = {
	cell: object,
};
