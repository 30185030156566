import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

const EmployeeTableCell = ({ cell }) => {
	const { compensation } = cell.row.original;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{compensation?.compensation_author}
		</TableCell>
	);
};

EmployeeTableCell.propTypes = {
	cell: object.isRequired,
};

export default EmployeeTableCell;
