import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { OPTIONS } from './helpers';

const RatingFilter = ({ name, label }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid item md={3}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				{OPTIONS.map(({ value, translateLabel, label }) => (
					<MenuItem key={value} value={value}>
						{translateLabel ? t(label) : label}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

RatingFilter.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
};

export default RatingFilter;
