import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import { BOOKING_STATES_QUERY, LOCAL_STORAGE_BOOKING_STATES } from 'helpers';
import { useEffect } from 'react';

export const useBookingStates = () => {
	const path = `v2/booking/states`;

	const localStorageStates = ls.get(LOCAL_STORAGE_BOOKING_STATES);

	const { data, isFetching, isFetched } = useQuery(
		BOOKING_STATES_QUERY,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: !localStorageStates }
	);

	const fetchedData = data?.data?.data ?? [];

	useEffect(() => {
		if (!localStorageStates && isFetched && fetchedData.length) {
			ls.set(LOCAL_STORAGE_BOOKING_STATES, fetchedData, {
				ttl: 14 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStorageStates, isFetched, fetchedData]);

	const result = localStorageStates || fetchedData;

	return { data: result, isFetching };
};
