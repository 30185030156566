import _ from 'lodash';

export const convertValues = (values) => {
	const omittedValues = _.omit(values, 'external_partner_cars');
	const officeCars = omittedValues.office_cars;
	const mappedOfficeCars = officeCars.map(
		({ uuid, external_partner_car_ids }) => ({
			office_car_uuid: uuid,
			external_partner_car_ids,
		})
	);

	return { external_partner_cars_mapping: mappedOfficeCars };
};
