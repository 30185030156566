import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import utilities
import { useTableContextProvider } from 'components/context';

// Import store
import { isValidationTableChangedSelector } from 'store/selectors';
import {
	setValidationTableChangedState,
	setBookingPreviewItem,
	resetBookingPreviewItem,
} from 'store/actions';

// Import helpers
import { BOOKING_CANCELLATION_STATES } from 'helpers';

export const useValidationActionTableCell = ({ cell }) => {
	const { fetchData } = useTableContextProvider();
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();

	const booking = cell.row.original;

	const { cancel_reason_status: cancelReasonStatus } = booking || {};

	const isChanged = useSelector(isValidationTableChangedSelector);

	const handleOnOpenModal = () => {
		setBookingPreviewItem({ booking, dispatch });
		setValidationTableChangedState({ isTableChanged: false, dispatch });
		setIsOpen(true);
	};

	const handleOnCloseModal = () => {
		setIsOpen(false);
		resetBookingPreviewItem({ dispatch });
		isChanged && fetchData();
	};

	const isValidated = cancelReasonStatus !== BOOKING_CANCELLATION_STATES.REVIEW;

	return {
		handleCloseModal: handleOnCloseModal,
		handleOpenModal: handleOnOpenModal,
		cancelReasonStatus,
		isValidated,
		isOpen,
	};
};
