// ********************  table  ********************
export const fetchRewardsSettlementGroupedListUrl = (queryParams) =>
	`v2/employee-performance/settlements/grouped-list${queryParams}`;
export const fetchRewardsSettlementListUrl = ({ queryParams, employeeUuid }) =>
	`v2/employee-performance/settlements/list/${
		employeeUuid || ''
	}${queryParams}`;

// ********************  form  ********************
export const rewardsSettlementBookingsUrl =
	'v2/employee-performance/settlements/create';
