import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FIELDS = (reasons) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'bookings.reject_booking_form.label.cancellation_reason',
		name: 'cancellationReason',
		disabled: !reasons.length,
		options: reasons,
		labelExtractor: ({ name }) => name[i18next.language],
		keyExtractor: ({ uuid }) => uuid,
	},
];
