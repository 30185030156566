import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	toolbarContainer: {
		display: 'flex',
	},
	actionsWrapper: {
		padding: '20px',
	},
	buttonsContainer: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'flex-end',
		flexWrap: 'wrap',
		gap: '8px',
		marginRight: '16px',
	},
	exportBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		'& > div': {
			display: 'flex',
		},
	},
	button: {
		marginLeft: theme.spacing(2),
	},
	icon: {
		width: 17,
		height: 17,
		marginRight: theme.spacing(1),
	},
	titleWrapper: {
		padding: '10px 20px',
	},
}));
