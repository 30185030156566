// ********************  form  ********************

export const fetchEmployeeRewardingFormResourcesUrl = (itemId) =>
	`/v2/employee-performance/company/${itemId}`;

export const submitEmployeeRewardingFormResourcesUrl = (itemId) =>
	`/v2/employee-performance/company/${itemId}/update`;

// ********************  table  ********************

export const fetchEmployeePerformanceUsersUrl = (queryParams, itemId) =>
	`/v2/employee-performance/company/${itemId}/users${queryParams}`;

export const updateEmployeePerformanceUserUrl = (id, isActive) =>
	`v2/employee-performance/user/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const fetchEmployeePerformanceBookingsUrl = (queryParams, itemId) =>
	`/v2/employee-performance/user/${itemId}/items${queryParams}`;

export const updateIsSettledStateUrl = ({ uuid, isSettled }) =>
	`/v2/employee-performance/item/${uuid}/${
		isSettled ? 'un' : ''
	}mark-as-settled`;
