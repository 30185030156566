import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { bool, object, string } from 'prop-types';

// Import components
import {
	MakeCompensationForm,
	CompensationDialogTitle,
} from 'components/elements';

// Import translations
import useMakeCompensationDialog from './useMakeCompensationDialog';
import { useTranslations } from 'components/utilities';

const MakeCompensationDialog = ({
	shortButtonTitle = false,
	outerCompensation,
	location,
}) => {
	const { t } = useTranslations();

	const {
		isOpen,
		onClose,
		onOpen,
		color,
		buttonTitle,
		dialogTitle,
		showButton,
	} = useMakeCompensationDialog({ outerCompensation, location });

	const buttonLabel = shortButtonTitle
		? t(buttonTitle).split(' ')[0]
		: t(buttonTitle);

	return (
		<>
			{showButton && (
				<Button onClick={onOpen} color={color} variant="contained" size="small">
					{buttonLabel}
				</Button>
			)}
			<Dialog fullWidth open={isOpen} onClose={onClose}>
				<DialogTitle>
					<CompensationDialogTitle title={t(dialogTitle)} onClose={onClose} />
				</DialogTitle>
				<DialogContent>
					<MakeCompensationForm
						outerCompensation={outerCompensation}
						onClose={onClose}
						location={location}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

MakeCompensationDialog.propTypes = {
	outerCompensation: object,
	shortButtonTitle: bool,
	location: string,
};

export default MakeCompensationDialog;
