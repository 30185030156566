import { makeStyles } from '@material-ui/styles';
import { INPUT_BACKGROUND_COLOR } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexBasis: 320,
		borderRadius: 8,
		padding: theme.spacing(1, 2),
		backgroundColor: INPUT_BACKGROUND_COLOR,
		marginBottom: theme.spacing(2),
		maxHeight: '40px',
		boxShadow: 'none',
	},
	searchIcon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	input: {
		flexGrow: 1,
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '-0.05px',
		minHeight: 'unset',
	},
}));
