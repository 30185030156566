import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	fontWeight: {
		fontWeight: 300,
	},
	booking: {
		display: 'flex',
		alignItems: 'center',
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	image: {
		height: 50,
		width: 130,
	},
}));

export default useStyles;
