import { useEffect, useState } from 'react';

// Import helpers
import { DELIVERY_TYPES } from './helpers';

export const useDialogContent = ({ data }) => {
	const [deliveryType, setDeliveryType] = useState(DELIVERY_TYPES.PICK);
	const images = data[deliveryType].images;
	const allImages = images
		.filter(({ image_url: url }) => !!url)
		.map(({ image_url, image_type }) => ({ url: image_url, type: image_type }));

	const [selectedImage, setSelectedImage] = useState(allImages[0]);
	const bottomImages = allImages.filter(
		({ type }) => type !== selectedImage.type
	);

	useEffect(() => {
		setSelectedImage(allImages[0]);
		// eslint-disable-next-line
	}, [deliveryType]);

	return {
		setSelectedImage,
		setDeliveryType,
		images: bottomImages,
		selectedImage,
		deliveryType,
	};
};
