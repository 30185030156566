import i18n from 'i18next';
import * as yup from 'yup';

export const validationSchema = () => {
	const requiredFieldText = i18n.t('common.fields.required_field');

	return yup.object({
		vehicle_maker_uuid: yup.string().required(requiredFieldText),
		vehicle_model_uuid: yup.string().required(requiredFieldText),
		vehicle_year: yup.string().required(requiredFieldText),
		plate: yup.string().required(requiredFieldText),
		registration_number: yup.string().notRequired(),
		body_color: yup.string().required(requiredFieldText),
		rent_status: yup.string().required(requiredFieldText),
		miles_usage: yup
			.number()
			.min(0, i18n.t('common.fields.minimum', { value: 0 }))
			.required(requiredFieldText),
		last_open_contract_date: yup.date().nullable().notRequired(),
	});
};
