// Import helpers
import { BUTTON_ACTION_TYPES } from 'helpers';

// Import assets
import { MinusIcon, PlusIcon } from 'icons';

const { INCREMENT, DECREMENT } = BUTTON_ACTION_TYPES;

export const getIcon = (iconType = null) => {
	switch (iconType) {
		case INCREMENT:
			return PlusIcon;

		case DECREMENT:
			return MinusIcon;

		default:
			return null;
	}
};
