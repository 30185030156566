import React from 'react';
import { object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

export const CarNameTableCell = ({ cell }) => {
	const { car_name: carName, company_name: companyName } =
		cell.row.original?.last_booking || {};

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Typography noWrap>{carName}</Typography>
			<Typography noWrap>{companyName}</Typography>
		</TableCell>
	);
};

CarNameTableCell.propTypes = {
	cell: object,
};
