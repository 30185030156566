// ********************  table  ********************

export const fetchDiscountsUrl = (queryParams, itemId) =>
	`/v2/organization/discount/${itemId}/list${queryParams}`;

export const updateActiveDiscountUrl = (id, isActive) =>
	`/v2/organization/discount/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const deleteDiscountUrl = (id) =>
	`/v2/organization/discount/${id}/remove`;

// ********************  form  ********************

export const fetchDiscountFormResourcesUrl = (itemId) =>
	`/v2/organization/discount/${itemId}/show`;

export const submitEditDiscountFormUrl = (itemId) =>
	`/v2/organization/discount/${itemId}/update`;

export const submitAddDiscountFormUrl = `/v2/organization/discount/create`;
