import React from 'react';
import { Grid } from '@material-ui/core';
import { getYear } from 'date-fns';

// Import components
import { BaseSelectFilter } from 'components/elements';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { generateArray, getMonthName } from 'helpers';

export const FilterFields = () => {
	const { t } = useTranslations();
	const years = generateArray(2020, getYear(new Date())).map((year) => ({
		label: year.toString(),
		value: year,
	}));

	const months = generateArray(1, 12).map((monthNumber) => ({
		label: getMonthName(monthNumber),
		value:
			monthNumber.toString().length === 1
				? `0${monthNumber}`
				: monthNumber.toString(),
	}));

	return (
		<Grid container spacing={3}>
			<BaseSelectFilter
				label={t('common.fields.year')}
				name="year"
				options={years}
			/>
			<BaseSelectFilter
				label={t('common.fields.month')}
				name="month"
				options={months}
			/>
		</Grid>
	);
};
