import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	errorText: {
		color: theme.palette.error.main,
		marginTop: theme.spacing(1),
	},
}));

export default useStyles;
