import React from 'react';
import { string } from 'prop-types';
import { queryCache } from 'react-query';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import {
	CardHeader,
	Divider,
	CardContent,
	Grid,
	CircularProgress,
	Typography,
	Box,
	Card,
} from '@material-ui/core';
import { useOfficesCars } from 'queries';

// Import components
import { OfficesList } from './components';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { CAR_MODELS_QUERY } from 'helpers';

// Import styles
import { useStyles } from './styles';

const Offices = ({ className, ...rest }) => {
	const classes = useStyles();

	const { values } = useFormState();

	const { managerProfileUuid } = useSelector((store) => store.car.form.item);

	const { t } = useTranslations();

	const {
		carInfo: {
			vehicle: { model_name, year },
		},
	} = values;

	const modelsData = queryCache.getQueryData(CAR_MODELS_QUERY);

	const models = modelsData && modelsData.data.data;

	const {
		data: offices,
		isFetching: loadingOffices,
		isError: officesError,
	} = useOfficesCars({
		models,
		model_name,
		year,
		managerProfileUuid,
	});

	const areLoadedOffices = !loadingOffices && !officesError && !!year;

	const officesObject = (!loadingOffices && offices) || {};

	const renderOfficesData = () =>
		!Array.isArray(offices) ? (
			<Grid item md={12} xs={12}>
				<OfficesList offices={officesObject} />
			</Grid>
		) : (
			<Grid item md={12} xs={12}>
				<Typography align="center" variant="subtitle1">
					{t('cars.form.cars.no_data')}
				</Typography>
			</Grid>
		);

	return (
		<>
			<Card {...rest} className={className}>
				<CardHeader title={t('cars.form.cars.title')} />
				<Divider />
				<CardContent>
					<Grid container spacing={1}>
						{areLoadedOffices ? (
							renderOfficesData()
						) : (
							<Grid item md={12} xs={12}>
								<Box className={classes.loader}>
									<CircularProgress size={35} />
								</Box>
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};

Offices.propTypes = {
	className: string,
};

export default Offices;
