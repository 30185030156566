import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { ColorPicker } from 'material-ui-color';
import clsx from 'clsx';
import {
	CardContent,
	Typography,
	CardHeader,
	Divider,
	Card,
	Grid,
} from '@material-ui/core';

// Import translation
import { useCommonFields, useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './Design.styles';

//Import helpers
import { colorFields, DESIGN_FIELDS } from './helpers';

export const Design = () => {
	const { values, errors, touched } = useFormState();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { change } = useForm();
	const classes = useStyles();

	const isError = (name) => touched[name] && errors[name];

	return (
		<Card>
			<CardHeader component="h5" title={t('cms.partners.form.design.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{DESIGN_FIELDS.map(renderFields)}
					{colorFields.map((name) => (
						<Grid item md={12} xs={12} key={name}>
							<Typography
								className={clsx({
									[classes.label]: true,
									[classes.error]: isError(name),
								})}
							>
								{t(`cms.partners.form.design.${name}`)} *
							</Typography>

							<ColorPicker
								onChange={(value) => change(name, value)}
								value={values[name]}
								disableAlpha
							/>

							{isError(name) && (
								<Typography className={classes.error}>
									{errors[name]}
								</Typography>
							)}
						</Grid>
					))}
				</Grid>
			</CardContent>
		</Card>
	);
};
