import { useSelector } from 'react-redux';

// Import helpers and utils
import { FIELD_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';
import { isNil, omitBy } from 'lodash';

export const useAutoCompensationFields = () => {
	const sectionName = 'rating_reasons.form';

	const { t } = useTranslations();

	const { compensationTypes: types } = useSelector(
		(store) => store.ratingReasons.form
	);

	const compensationTypes = omitBy(types, isNil);

	const autoCompensationFields = [
		{
			label: `${sectionName}.auto_compensation.formula`,
			name: 'compensation_formula',
			type: FIELD_TYPES.SELECT,
			md: 8,
			required: true,
			options: compensationTypes,
			keyExtractor: (row) => row,
			labelExtractor: (row) => t(`rating_reasons.compensation_types.${row}`),
		},
		{
			label: `${sectionName}.auto_compensation.value`,
			name: 'compensation_value',
			type: FIELD_TYPES.NUMBER,
			md: 4,
			required: true,
			min: 0.01,
			step: 0.01,
		},
		{
			label: `${sectionName}.auto_compensation.max_value`,
			name: 'compensation_max_value',
			type: FIELD_TYPES.NUMBER,
			md: 6,
			min: 0.01,
			step: 0.01,
		},
	];

	return {
		autoCompensationFields,
	};
};
