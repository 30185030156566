import React from 'react';
import { object, string } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from '../styles';

export const SettledDateTableCell = ({ cell, settledAt }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<TableCell {...cell.getCellProps()} className={classes.settledTableCell}>
			{settledAt ? (
				<>
					<Typography className={classes.settlementText}>
						{t('rewards_settlement.settled_on')}
					</Typography>
					<Typography className={classes.settlementText}>
						{settledAt}
					</Typography>
				</>
			) : (
				<>-</>
			)}
		</TableCell>
	);
};

SettledDateTableCell.propTypes = {
	settledAt: string,
	cell: object.isRequired,
};
