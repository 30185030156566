// Import components
import Filters from './components/Filters';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		customKey: 'filters',
		component: Filters,
	},
];
