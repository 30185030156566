import React from 'react';
import { Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useContractDatesPreviewField } from './useContractDatesPreviewField';

// Import components
import PreviewField from '../PreviewField';

export const ContractDatesPreviewField = () => {
	const { t } = useTranslations();

	const { contractOpenedAt, contractClosedAt, hasContract } =
		useContractDatesPreviewField();

	if (!hasContract) return null;

	return (
		<>
			{contractOpenedAt && (
				<PreviewField
					label={t('bookings.preview_booking.period.contract_opened_at')}
					labelColor="primary"
				>
					<Typography color="primary">{contractOpenedAt}</Typography>
				</PreviewField>
			)}
			{contractClosedAt && (
				<PreviewField
					label={t('bookings.preview_booking.period.contract_closed_at')}
					labelColor="primary"
				>
					<Typography color="primary">{contractClosedAt}</Typography>
				</PreviewField>
			)}
		</>
	);
};
