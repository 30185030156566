import React from 'react';
import { bool, func } from 'prop-types';

// Import utils and helpers
import { initialValues, formatValues } from './helpers';
import { exportToExcel } from './exportToExcel';

// Import store
import { fetchEventReports } from 'store/actions';

// Import components
import { TableExport, TableExportFilters } from 'components/elements';
import { TableExportProvider } from 'components/context';
import { FilterFields } from '../FilterFields';

export const NewReport = ({ open, close }) => {
	return (
		<TableExportProvider
			fetchDataTableAction={fetchEventReports}
			columns={[]}
			outerOpen={open}
			outerClose={close}
			noPagination
			noColumns
		>
			<TableExport
				hideColumnsSelect
				customExport={exportToExcel}
				renderFilters={() => (
					<TableExportFilters
						initialValues={initialValues}
						formatValues={formatValues}
					>
						<FilterFields />
					</TableExportFilters>
				)}
			/>
		</TableExportProvider>
	);
};

NewReport.propTypes = {
	open: bool,
	close: func,
};
