import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT, convertFormDates } from 'helpers';

export const initialValues = {
	from: null,
	until: null,
	from_date: null,
	from_date_to: null,
};

export const formatValues = (values) => {
	const { from_date: fromDate, from_date_to: fromDateTo } = values || {};
	return {
		...values,
		...convertFormDates(values, ['from_date']),
		from: fromDate ? format(fromDate, DEFAULT_DATE_FORMAT) : null,
		until: fromDateTo ? format(fromDateTo, DEFAULT_DATE_FORMAT) : null,
	};
};
