import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { SUPPLIER_VEHICLE_LIST_QUERY } from 'helpers';

export const useSupplierVehicleList = ({ vehicleMakerUuid, year }) => {
	const path = '/v2/supplier/vehicle/list';

	const resultYear = year === 'all' ? null : year;

	const body = {
		vehicle_maker: {
			uuid: vehicleMakerUuid,
		},
		vehicle: {
			year: resultYear,
		},
	};

	const enabled = vehicleMakerUuid && year;

	const { data, isFetching, isError } = useQuery(
		[SUPPLIER_VEHICLE_LIST_QUERY, body],
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
