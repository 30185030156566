import React from 'react';

// Import components
import { ExtendForm } from './components';

// Import utils
import { ExtendContractProvider } from './context';

export const ExtendContract = () => {
	return (
		<ExtendContractProvider>
			<ExtendForm />
		</ExtendContractProvider>
	);
};
