import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

const DriverLicence = (props) => {
	const { className, section, ...rest } = props;
	const { submitting } = useFormState();
	const { t } = useTranslations();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.driver_licence.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={6}>
						<Field
							type="checkbox"
							component={CheckboxFieldAdapter}
							name={`${section}.international_driver_licence`}
							disabled={submitting}
							labelText={t(
								'offices.form.driver_licence.international_diver_licence'
							)}
							color="primary"
						/>
					</Grid>
					<Grid item md={6}>
						<Field
							type="checkbox"
							component={CheckboxFieldAdapter}
							name={`${section}.foreign_driver_licence`}
							disabled={submitting}
							labelText={t(
								'offices.form.driver_licence.foreign_driver_licence'
							)}
							color="primary"
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

DriverLicence.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default DriverLicence;
