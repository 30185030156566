import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { AUTO_VEHICLE_MODEL_COLLECTION_QUERY } from 'helpers';

const useAutoVehicleModelCollection = ({ name, maker_id }) => {
	const path = '/v2/auto/vehicle/model/collection';

	const { data, isFetching } = useQuery(
		[AUTO_VEHICLE_MODEL_COLLECTION_QUERY, name, maker_id],
		async () => await services.post(path, { name, auto: { maker_id } }),
		{ refetchOnWindowFocus: false, cacheTime: 400, enabled: !!name }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching };
};

export default useAutoVehicleModelCollection;
