import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { Grid, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { DISCOUNT_SELECT_OPTIONS, MAX_AMOUNT_SELECT_OPTIONS } from './helpers';

const GratificationDiscountFields = () => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<>
			<Grid item md={4} xs={12}>
				<Field
					fullWidth
					component={SelectFieldAdapter}
					label={t('campaigns.form.gratification.discount')}
					name="gratification.discount"
					margin="dense"
					variant="outlined"
					disabled={submitting}
					required
				>
					{DISCOUNT_SELECT_OPTIONS.map((value) => (
						<MenuItem key={value} value={value}>
							{value} %
						</MenuItem>
					))}
				</Field>
			</Grid>
			<Grid item md={4} xs={12}>
				<Field
					fullWidth
					component={SelectFieldAdapter}
					label={t('campaigns.form.gratification.max_amount')}
					name="gratification.max_amount_nett_price"
					margin="dense"
					variant="outlined"
					disabled={submitting}
					required
				>
					{MAX_AMOUNT_SELECT_OPTIONS.map((value) => (
						<MenuItem key={value} value={value}>
							{value} {t('currency.sar')}
						</MenuItem>
					))}
				</Field>
			</Grid>
		</>
	);
};

export default GratificationDiscountFields;
