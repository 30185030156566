// Import helpers
import { ADMIN_ONLY_INVOICE_OPTIONS } from '../../helpers';
import { INVOICE_OPTION_TYPES } from 'helpers';

export const getPrice = ({
	amount_gross: grossPrice,
	amount_nett: netPrice,
	type,
}) => {
	if (type === INVOICE_OPTION_TYPES.SECURITY_DEPOSIT) {
		return grossPrice;
	}

	return netPrice;
};

export const filterOptions = ({ isAdmin, options }) =>
	options.filter(
		({ type }) =>
			isAdmin ||
			!Object.values(ADMIN_ONLY_INVOICE_OPTIONS).some((item) => item === type)
	);
