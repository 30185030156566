import React from 'react';
import { Box, Tooltip } from '@material-ui/core';

// Import components
import { useTableExportContextProvider } from 'components/context';
import { IconButton } from 'components/elements';

// Import helpers
import { EXPORT_TYPES } from 'helpers';

// Import icons
import { ExcelIcon, PdfIcon } from 'icons';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './TableExportButtons.styles';

const TableExportButtons = () => {
	const { openExport } = useTableExportContextProvider();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Tooltip title={t('common.icon_names.excel')}>
				<span>
					<IconButton onClick={() => openExport(EXPORT_TYPES.EXCEL)}>
						<ExcelIcon />
					</IconButton>
				</span>
			</Tooltip>
			<Tooltip
				title={t('common.icon_names.pdf')}
				onClick={() => openExport(EXPORT_TYPES.PDF)}
			>
				<span>
					<IconButton>
						<PdfIcon />
					</IconButton>
				</span>
			</Tooltip>
		</Box>
	);
};

export default TableExportButtons;
