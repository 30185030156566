import { makeStyles } from '@material-ui/styles';

// Import helpers
import { INPUT_BACKGROUND_COLOR } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	field: {
		backgroundColor: INPUT_BACKGROUND_COLOR,
		borderRadius: '8px',
		minHeight: '56px',
		padding: '18px 23px',
	},
	fieldValue: {
		fontSize: '14px',
		fontWeight: '500',
	},
	label: {
		color: theme.palette.gray.label,
		fontSize: '12px',
		marginBottom: '8px',
	},
}));
