import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { InfoListItem } from '../index';
import useOptionInfoListItem from './useOptionInfoListItem';
import { CheckIconTwoTone, ClearIconTwoTone } from 'icons';

const OptionInfoListItem = ({ label, type }) => {
	const { hasOption } = useOptionInfoListItem({ type });

	return (
		<InfoListItem label={label}>
			{hasOption ? (
				<CheckIconTwoTone color="primary" />
			) : (
				<ClearIconTwoTone color="error" />
			)}
		</InfoListItem>
	);
};

OptionInfoListItem.propTypes = {
	label: PropTypes.string,
	type: PropTypes.string,
};

export default OptionInfoListItem;
