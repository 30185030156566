import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	ratingCellContainer: {
		display: 'flex',
		alignItems: 'center',
		gridGap: 8,
	},
	ratingCellIcon: {
		fontSize: '1.5em',
		color: theme.palette.starIcon,
	},
	settlementText: {
		fontSize: '1em',
	},
	settledTableCell: {
		minWidth: '120px',
	},
}));

export default useStyles;
