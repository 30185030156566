import { useSelector } from 'react-redux';
import { isInfoLoadedSelector } from 'store/selectors';

// Import utilities
import { useEditBookingProvider } from '../../context';
import { useMessage, useTranslations } from 'components/utilities';

const useEditBookingContent = () => {
	const { t } = useTranslations();

	const { message } = useMessage();

	const { toggle, setConverted, changeInitialValues } =
		useEditBookingProvider();

	const isInfoLoaded = useSelector(isInfoLoadedSelector);

	const callbackSuccess = () => {
		changeInitialValues();
		setConverted(false);
		toggle();
	};

	const errorCallback = () => {
		message.error(t('common.messages.error_message'));
	};

	return {
		callbackSuccess,
		isInfoLoaded,
		errorCallback,
	};
};

export default useEditBookingContent;
