// ********************  table  ********************
export const fetchAirportListUrl = '/v2/airport/list';
export const updateActiveAirportStatusUrl = (id, isActive) =>
	`/v2/airport/${id}/${isActive ? 'deactivate' : 'activate'}`;
export const deleteAirportUrl = (id) => `/v2/airport/${id}/remove`;

// ********************  form  ********************
export const fetchAirportUrl = (itemId) => `/v2/airport/${itemId}/show`;
export const submitAirportUrl = (itemId) =>
	`/v2/airport/${itemId ? `${itemId}/update` : 'create'}`;
