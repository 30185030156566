import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	tableCell: {
		background: `linear-gradient(to bottom left, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, #fff 50%, #fff 100%)`,
		textAlign: 'center',
		padding: 0,
		transform: theme.direction === 'rtl' ? 'rotateY(180deg)' : '',
		direction: theme.direction,
	},
	labelHorizontal: {
		position: 'absolute',
		top: '11px',
		right: '11px',
		lineHeight: '1',
		color: theme.palette.primary.contrastText,
	},
	labelVertical: {
		position: 'absolute',
		bottom: '11px',
		left: '11px',
		lineHeight: '1',
	},
}));

export default useStyles;
