import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useForm } from 'react-final-form';
import services from 'services/services';

// Import helpers
import { createCarModelsBody } from './helpers';
import { CAR_MODELS_QUERY } from 'helpers';

const useCarModels = ({
	isGlobalEdit,
	vehicleList,
	manufacturer,
	year,
	initialYear,
	initialManufacturer,
	modelFieldName,
	lng,
}) => {
	const { change } = useForm();

	const path = isGlobalEdit
		? '/v2/supplier/vehicle/model/list'
		: '/v2/supplier/vehicle/list';

	const fieldName = isGlobalEdit ? manufacturer?.[lng] || manufacturer : year;

	const initialValue = isGlobalEdit
		? initialManufacturer?.[lng] || initialManufacturer
		: initialYear;

	useEffect(() => {
		if (initialValue !== fieldName) {
			change(modelFieldName, null);
		}

		refetch();
		//eslint-disable-next-line
	}, [fieldName, vehicleList.length]);

	const body = createCarModelsBody({
		fieldName,
		vehicleList,
		manufacturer,
		isGlobalEdit,
		year,
		lng,
	});

	const { data, isFetching, isError, refetch } = useQuery(
		CAR_MODELS_QUERY,
		async () =>
			fieldName &&
			vehicleList.length !== 0 &&
			(await services.post(path, body)),
		{ refetchOnWindowFocus: false, cacheTime: 0 }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};

export default useCarModels;
