// Import helpers
import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	AVAILABLE: 'available',
	ACTION: 'action',
};

export const columns = [
	{
		Header: 'common.cars.headers.maker',
		accessor: 'maker',
	},
	{
		Header: 'common.cars.headers.model',
		accessor: 'model',
	},
	{
		Header: 'common.cars.headers.year',
		accessor: 'year',
	},
	{
		Header: 'common.cars.headers.available',
		accessor: 'available',
		disableSortBy: true,
	},
	{
		Header: 'common.cars.headers.taken',
		accessor: 'taken',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.action',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
	},
];
