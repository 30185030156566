import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { TableCell } from '@material-ui/core';

// Import helpers
import { COLUMNS_ACCESSORS } from '../columns';

const TableBodyCell = ({ row, column, rowIndex }) => {
	switch (column.accessor) {
		case COLUMNS_ACCESSORS.INDEX:
			return <TableCell>{rowIndex + 1}</TableCell>;
		case COLUMNS_ACCESSORS.PRICE:
			return (
				<TableCell>
					{row.total.gross_price} {row.total.currency}
				</TableCell>
			);
		default:
			return <TableCell>{get(row, column.accessor)}</TableCell>;
	}
};

TableBodyCell.propTypes = {
	row: PropTypes.object,
	rowIndex: PropTypes.number,
	column: PropTypes.object,
};

export default TableBodyCell;
