export const COLUMNS_ACCESSORS = {
	CUSTOMER: 'customer',
	PICKUP_DROPOFF: 'pickup_dropoff',
	NUMBER_OF_TRIES: 'no_of_tries',
	OUT_STANDING_BALANCE: 'out_standing_balance',
	IS_SETTLED: 'is_settled',
	IS_ACTIVE: 'is_active',
	ACTIONS: 'actions',
	BOOKING_ID: 'booking_id',
	SETTLED_DUES: 'settled_dues',
	NOT_SETTLED_DUES: 'not_settled_dues',
	PAYMENT_DAY: 'payment_day',
	EARLY_RETURN_DATE: 'early_return_date',
	PERIOD_DURATION: 'period_duration',
};

export const columns = (isAdmin) => [
	{
		Header: 'customer_balance.table.headers.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.pickup_dropoff',
		accessor: COLUMNS_ACCESSORS.PICKUP_DROPOFF,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.early_return_date',
		accessor: COLUMNS_ACCESSORS.EARLY_RETURN_DATE,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.period_duration_months',
		accessor: COLUMNS_ACCESSORS.PERIOD_DURATION,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.no_of_tries',
		accessor: COLUMNS_ACCESSORS.NUMBER_OF_TRIES,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.out_standing_balance',
		accessor: COLUMNS_ACCESSORS.OUT_STANDING_BALANCE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.office_name',
		accessor: 'office_name',
		disableSortBy: true,
		isHidden: isAdmin,
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
		disableSortBy: true,
		isHidden: !isAdmin,
	},
	{
		Header: 'customer_balance.table.headers.is_settled',
		accessor: COLUMNS_ACCESSORS.IS_SETTLED,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.settled_dues',
		accessor: COLUMNS_ACCESSORS.SETTLED_DUES,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.not_settled_dues',
		accessor: COLUMNS_ACCESSORS.NOT_SETTLED_DUES,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.next_payment_due',
		accessor: COLUMNS_ACCESSORS.PAYMENT_DAY,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.is_active',
		accessor: COLUMNS_ACCESSORS.IS_ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
