import ls from 'localstorage-slim';
import services from 'services/services';
import * as types from 'store/types';
import { businessDashboardUrl, businessManagerDashboardUrl } from 'store/paths';

// Import helpers
import { convertPeriod } from './helpers';
import {
	LOCAL_STORAGE_DASHBOARD_DATA,
	LOCAL_STORAGE_DASHBOARD_MANAGER_DATA,
} from 'helpers';

export const fetchDashboardInfo =
	({ values }) =>
	async (dispatch) => {
		dispatch({
			type: types.DASHBOARD_INFO_FETCH_DATA_LOADING,
		});

		const newValues = {
			...values,
			period: convertPeriod(values.period),
		};

		const localStorageDashboardData = ls.get(LOCAL_STORAGE_DASHBOARD_DATA);
		const localStorageDashboardMangerData = ls.get(
			LOCAL_STORAGE_DASHBOARD_MANAGER_DATA
		);

		try {
			const dashboardDataPromise =
				!localStorageDashboardData &&
				services.post(businessDashboardUrl, newValues);

			const managerDataPromise =
				!localStorageDashboardMangerData &&
				services.post(businessManagerDashboardUrl, newValues);

			const values = await Promise.all([
				dashboardDataPromise,
				managerDataPromise,
			]);

			const [{ data: dashboardData }, { data: managerData }] = values;

			if (!localStorageDashboardData) {
				ls.set(LOCAL_STORAGE_DASHBOARD_DATA, dashboardData.data, {
					ttl: 4 * 60 * 60,
				});
			}

			if (!localStorageDashboardMangerData) {
				ls.set(LOCAL_STORAGE_DASHBOARD_MANAGER_DATA, managerData.data, {
					ttl: 4 * 60 * 60,
				});
			}

			const dashboardDataResult =
				localStorageDashboardData || dashboardData.data;
			const dashboardManagerDataResult =
				localStorageDashboardMangerData || managerData.data;

			dispatch({
				type: types.DASHBOARD_INFO_FETCH_DATA_SUCCESS,
				payload: {
					...dashboardDataResult,
					...dashboardManagerDataResult,
				},
			});
		} catch (error) {
			dispatch({
				type: types.DASHBOARD_INFO_FETCH_DATA_ERROR,
				payload: error,
			});
		}
	};
