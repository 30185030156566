export const getNewStates = ({
	target,
	officesArray,
	checkedCars,
	checkedOffices,
	setCheckedCars,
	setCheckedOffices,
}) => {
	if (target.checked) {
		const newCheckedCars = checkedCars;
		const newCheckedOffices = checkedOffices;
		officesArray.forEach(({ uuid, cars }) => {
			!checkedOffices.includes(uuid) && newCheckedOffices.push(uuid);

			cars.forEach(({ uuid }) => {
				!checkedCars.includes(uuid) && newCheckedCars.push(uuid);
			});
		});
		return newCheckedCars;
	} else {
		setCheckedCars([]);
		setCheckedOffices([]);
		return [];
	}
};
