import React from 'react';
import { Form } from 'react-final-form';
import { Dialog, DialogContent, Button } from '@material-ui/core';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';

// Import utils and helpers
import { useToggle, useTranslations } from 'components/utilities';
import { useExtendBookingDialog } from './useExtendBookingDialog';
import { setError } from './helpers';

export const ExtendBookingDialog = () => {
	const { on: isOpen, toggle } = useToggle();

	const { t } = useTranslations();

	const { isButtonLoading, handleOnSubmit } = useExtendBookingDialog(toggle);

	return (
		<>
			<Button size="large" color="primary" variant="contained" onClick={toggle}>
				{t('common.buttons.extend')}
			</Button>
			<Dialog onClose={toggle} open={isOpen} fullWidth>
				<ClosableDialogTitle
					onClose={toggle}
					title={t('b2b.booking_preview.extend_booking')}
				/>
				<DialogContent>
					<Form
						onSubmit={handleOnSubmit}
						mutators={{ setError }}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<FormContent
									isButtonLoading={isButtonLoading}
									toggle={toggle}
								/>
							</form>
						)}
					></Form>
				</DialogContent>
			</Dialog>
		</>
	);
};
