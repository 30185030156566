export const PERIOD_FIELDS = (section, values) => [
	{
		label: 'cars.form.extra_services.cdw',
		checkboxName: `carInfo.${section}.is_insurance`,
		isOpen: values.carInfo.options.is_insurance,
		fields: [
			{
				label: 'cars.form.extra_services.price_per_day',
				name: `carInfo.${section}.insurance_prices.daily.nett_price`,
			},
			{
				label: 'cars.form.extra_services.price_per_week',
				name: `carInfo.${section}.insurance_prices.weekly.nett_price`,
			},
			{
				label: 'cars.form.extra_services.price_per_month',
				name: `carInfo.${section}.insurance_prices.monthly.nett_price`,
			},
		],
	},
	{
		label: 'cars.form.extra_services.non_smoke_cars',
		checkboxName: `carInfo.${section}.is_no_smoking_car`,
		isOpen: values.carInfo.options.is_no_smoking_car,
		fields: [
			{
				label: 'cars.form.extra_services.price_per_day',
				name: `carInfo.${section}.no_smoking_prices.daily.nett_price`,
			},
			{
				label: 'cars.form.extra_services.price_per_week',
				name: `carInfo.${section}.no_smoking_prices.weekly.nett_price`,
			},
			{
				label: 'cars.form.extra_services.price_per_month',
				name: `carInfo.${section}.no_smoking_prices.monthly.nett_price`,
			},
		],
	},
];
