import React from 'react';
import { bool, string } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import components
import {
	CompanyFilter,
	OfficeFilter,
	BookingStatusFilter,
	RatingFilter,
	RatingReasonFilter,
	PaymentMethodFilter,
	DateRangeFilter,
	BaseYesNoAllFilter,
	EditedByCustomerFilter,
	ExtendStatusFilter,
	ServiceTypeFilter,
	AirportFilter,
	PromoCodesFilter,
	CitiesFilter,
} from 'components/elements';

// Import utils and helpers
import { BOOKINGS_PAGES_TYPES, SERVICES_TYPES } from 'helpers';
import RoleProtected from 'components/hoc/RoleProtected';
import { useProfile } from 'components/utilities';
import {
	PERMISSION_COMPANY_FILTER,
	PERMISSION_OFFICE_FILTER,
} from '../helpers';

const FilterFields = ({ pageType, isExport = false }) => {
	const { isAdmin } = useProfile();

	const isExtended = pageType === BOOKINGS_PAGES_TYPES.EXTENDED;
	const isBookingPage = pageType === BOOKINGS_PAGES_TYPES.MAIN;

	return (
		<Grid container spacing={3}>
			<RoleProtected roles={PERMISSION_COMPANY_FILTER}>
				<CompanyFilter name="company_uuid" label="bookings.filters.company" />
			</RoleProtected>

			<RoleProtected roles={PERMISSION_OFFICE_FILTER}>
				<OfficeFilter name="office_uuid" label="bookings.filters.office" />
			</RoleProtected>

			<BookingStatusFilter name="state" label="bookings.filters.state" />

			<PaymentMethodFilter
				name="payment"
				label="bookings.filters.payment_methods.title"
			/>

			<BaseYesNoAllFilter
				name="is_delivery"
				label="bookings.filters.is_delivery"
			/>

			<BaseYesNoAllFilter
				name="is_settled"
				label="bookings.filters.is_settled"
			/>

			<RatingFilter
				name="office_rating"
				label="bookings.filters.office_rating"
			/>

			<RatingReasonFilter
				name="rating_reasons"
				label="bookings.filters.rating_reason"
			/>

			<BaseYesNoAllFilter
				name="is_compensation"
				label="bookings.filters.compensated"
			/>

			<BaseYesNoAllFilter name="is_kiosk" label="bookings.filters.kiosk" />

			<BaseYesNoAllFilter
				name="is_discount"
				label="bookings.filters.is_discount"
			/>

			{!isExtended && (
				<EditedByCustomerFilter
					name="manipulation"
					label="bookings.filters.edited_by_customer"
				/>
			)}

			<DateRangeFilter
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="drop_date"
				nameUntil="drop_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="created_at"
				nameUntil="created_at_to"
				label="bookings.filters.created_at"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="contract_opened_at_date"
				nameUntil="contract_opened_at_date_to"
				label="bookings.filters.opened_contract_date"
				combinedInputs
			/>

			<DateRangeFilter
				nameFrom="contract_closed_at_date"
				nameUntil="contract_closed_at_date_to"
				label="bookings.filters.closed_contract_date"
				combinedInputs
			/>

			{isBookingPage && isAdmin && (
				<DateRangeFilter
					nameFrom="paid_at"
					nameUntil="paid_at_to"
					label="common.fields.paid_at"
					combinedInputs
				/>
			)}

			{isExport && !isExtended && isAdmin && (
				<DateRangeFilter
					nameFrom="paint_at_interval"
					nameUntil="paint_at_interval_to"
					label="bookings.preview_booking.invoice.total_paid_amount"
					combinedInputs
				/>
			)}

			{!isExtended && (
				<ExtendStatusFilter
					name="extension"
					label="bookings.filters.extend_status"
				/>
			)}

			<BaseYesNoAllFilter
				name="is_subscription"
				label="bookings.filters.subscription"
			/>

			<ServiceTypeFilter
				name="service_type"
				label="bookings.filters.service_type"
			/>

			<AirportFilter
				name="airport_uuid"
				label="bookings.filters.airports"
				dependName="service_type"
				dependValue={SERVICES_TYPES.AIRPORT}
			/>

			<CitiesFilter name="cities" label="common.fields.city" />

			<DateRangeFilter
				nameFrom="extension_created_at"
				nameUntil="extension_created_at_to"
				label="common.fields.extended_at"
				combinedInputs
			/>

			{isExport && !isExtended && <PromoCodesFilter name="discount_code" />}

			{!isExtended && (
				<DateRangeFilter
					nameFrom="cancellation_date"
					nameUntil="cancellation_date_to"
					label="common.fields.cancellation_date"
					combinedInputs
				/>
			)}
		</Grid>
	);
};

FilterFields.propTypes = {
	pageType: string,
	isExport: bool,
};

export default FilterFields;
