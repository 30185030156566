import React from 'react';
import { string } from 'prop-types';
import {
	Card,
	CardContent,
	CircularProgress,
	Grid,
	List,
	Typography,
} from '@material-ui/core';

// Import components
import { ListItem } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useStatsContextProvider } from '../../context';

// Import helpers
import { LIST_ITEMS_TYPES } from '../../helpers';

export const ListItemsCard = ({ title, type }) => {
	const { t } = useTranslations();

	const {
		isLoading,
		stats: { topReasons, topCompanies },
	} = useStatsContextProvider();

	const items = type === LIST_ITEMS_TYPES.REASONS ? topReasons : topCompanies;

	return (
		<Card>
			<CardContent>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h6">{t(title)}</Typography>
						</Grid>
						<Grid item xs={12}>
							<List>
								{items?.map((item, i) => (
									<ListItem key={i} item={item} index={i} type={type} />
								))}
							</List>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	);
};

ListItemsCard.propTypes = {
	title: string,
	type: string,
};
