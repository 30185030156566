import React, { useEffect } from 'react';
import { string, func, element, bool } from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { SearchInput } from 'components/elements';

// Import styles
import { useStyles } from './styles';

const UsersToolbar = ({
	className,
	setGlobalFilter,
	globalFilter,
	tableInnerToolbar,
	isSearchActive,
	...rest
}) => {
	const { t } = useTranslations();
	const classes = useStyles({ isSearchActive });
	const [query, setQuery] = React.useState(globalFilter);

	const delayedQuery = React.useRef(
		_.debounce((value) => setGlobalFilter(value), 400)
	).current;

	const handleOnChangeQuery = (e) => {
		e.persist();
		const value = e.target.value;
		setQuery(value);
		delayedQuery(value);
	};

	const handleClearQuery = () => {
		setQuery('');
		setGlobalFilter('');
	};

	useEffect(() => {
		setQuery(globalFilter || '');
	}, [globalFilter]);

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			{isSearchActive && (
				<SearchInput
					setGlobalFilter={handleOnChangeQuery}
					clearGlobalFilters={handleClearQuery}
					globalFilter={query}
					placeholder={t('table.search')}
				/>
			)}
			{tableInnerToolbar}
		</div>
	);
};

UsersToolbar.propTypes = {
	className: string,
	setGlobalFilter: func.isRequired,
	globalFilter: string,
	tableInnerToolbar: element,
	isSearchActive: bool,
};

export default UsersToolbar;
