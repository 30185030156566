export const OPTIONS = [
	{
		value: '[]',
		label: 'common.all',
	},
	{
		value: '[1,5]',
		label: '1 - 5',
	},
	{
		value: '[6,19]',
		label: '6 - 19',
	},
	{
		value: '[20,1000]',
		label: 'cars_availability.filters.last_select_option',
	},
];
