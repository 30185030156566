import { useState } from 'react';
import { useDispatch } from 'react-redux';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';

// Import context
import { useTableContextProvider } from 'components/context';

// Import store
import { updateDoneLateDeliveryBooking } from 'store/actions';

export const useDoneActionTableCell = ({ cell }) => {
	const dispatch = useDispatch();
	const { message } = useMessage();
	const { t } = useTranslations();

	const [isLoading, setIsLoading] = useState(false);

	const { fetchData } = useTableContextProvider();

	const { delivery } = cell.row.original || {};
	const { pick_up_delivery } = delivery || {};
	const { delivery_uuid: deliveryUuid } = pick_up_delivery || {};

	const handleDoneAction = async () => {
		setIsLoading(true);
		try {
			await updateDoneLateDeliveryBooking(deliveryUuid)(dispatch);
			message.success(t('common.messages.successfully_done'));
			await fetchData();
		} catch (error) {
			if (error.response) {
				if (error.response.status === 403) {
					message.error(t('errors.not_authorized'));
				} else {
					message.error(t('common.messages.error_message'));
				}
			} else if (error.message) {
				message.error(error.message);
			} else {
				message.error(t('errors.unknown'));
				throw error;
			}
		}
		setIsLoading(false);
	};
	return { handleDoneAction, isLoading };
};
