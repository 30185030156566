import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../columns';

// Import components
import { LicensePreviewDialog } from '../../components';

// Import utils
import { useTranslations } from 'components/utilities';

export const TableBodyRow = ({ row }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.LICENSE_PREVIEW: {
				return (
					<TableCell key={cell.column.id}>
						<LicensePreviewDialog uuid={cell.row.original.uuid} />
					</TableCell>
				);
			}

			case COLUMNS_ACCESSORS.VERIFICATION_NEEDED: {
				return (
					<TableCell key={cell.column.id}>
						<Typography>
							{cell.row.original.is_verification_needed
								? t('common.yes')
								: t('common.no')}
						</Typography>
					</TableCell>
				);
			}

			default:
				return (
					<TableCell
						className={classes.cell}
						key={cell.column.id}
						{...cell.getCellProps()}
					>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
