import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import helpers
import { FIELDS_URGENT_STATUS_PREVIEW } from 'views/Bookings/PreviewBooking/helpers';
import useUrgentStatusPreview from './useUrgentStatusPreview';

// Import components
import { PreviewFields } from 'views/Bookings/PreviewBooking/components';

// Import styles
import { useStyles } from './styles';

const UrgentStatusPreview = () => {
	const classes = useStyles();

	const {
		showPreviewFields,
		assignedByLabel,
		markedByLabel,
		assignedBy,
		markedBy,
		lng,
	} = useUrgentStatusPreview();

	return (
		<Box>
			{assignedBy && (
				<Typography className={classes.label}>{assignedByLabel}</Typography>
			)}
			{markedBy && (
				<Typography className={classes.label}>{markedByLabel}</Typography>
			)}
			{showPreviewFields && (
				<PreviewFields fields={FIELDS_URGENT_STATUS_PREVIEW(lng)} />
			)}
		</Box>
	);
};

export default UrgentStatusPreview;
