import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	root: {
		marginTop: 10,
		marginBottom: 10,
	},
}));

export default useStyles;
