import React from 'react';
import { string } from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { GoogleMapsScriptLoader } from 'components/elements';
import { Field } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import helpers and utils
import { FIELDS } from './helpers';
import useMapLocation from 'components/utilities/hooks/useMapLocation';
import { useGoogleMapsStyles } from 'theme/styles/googleMaps';
import { useProfile } from 'components/utilities';

const Location = ({ className, section, ...rest }) => {
	const classes = useGoogleMapsStyles();

	const { t, markerLocation, setAutocompleteValue, onChange, fieldDisabled } =
		useMapLocation({ section });

	const { isAdmin } = useProfile();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('company.form.location.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS({ section, isAdmin }).map((field) => (
						<Grid key={field.name} item md={field.md} xs={field.xs}>
							<Field
								fullWidth
								component={field.component}
								type={field.type}
								label={t(field.label)}
								name={field.name}
								margin="dense"
								variant="outlined"
								disabled={fieldDisabled(field)}
								required
								placeholder={t('offices.form.location.address')}
								setAutocompleteValue={setAutocompleteValue}
								onChange={onChange}
							/>
						</Grid>
					))}
					{markerLocation && (
						<Grid item md={12} xs={12}>
							<GoogleMapsScriptLoader>
								<GoogleMap
									mapContainerClassName={classes.mapContainer}
									center={markerLocation}
									zoom={17}
								>
									<Marker position={markerLocation} />
								</GoogleMap>
							</GoogleMapsScriptLoader>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

Location.propTypes = {
	className: string,
	section: string.isRequired,
};

export default Location;
