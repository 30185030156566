import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	dialogMessage: 'validation.table.modal.compensation_approved',
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.UPDATE_DIALOG_MESSAGE:
				draft.dialogMessage = action.payload;
				break;

			default:
				return state;
		}
	});
