import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DeepScratchIcon = (props) => (
	<SvgIcon
		width="13"
		height="13"
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.45573 0.250804C6.10534 -0.0836015 6.89399 -0.0836015 7.5436 0.250804L11.8775 2.48435C12.535 2.82324 13 3.48675 13 4.267V8.7337C13 9.51341 12.5356 10.1772 11.8782 10.5157L7.54465 12.7491C6.89504 13.0835 6.10601 13.0837 5.4564 12.7493L1.12246 10.5157C0.465 10.1769 0 9.51335 0 8.7331V4.267C0 3.48713 0.464597 2.82327 1.12283 2.48477C1.12303 2.48466 1.12262 2.48487 1.12283 2.48477L5.45573 0.250804ZM6.30573 1.90202C6.30567 1.90205 6.30578 1.90199 6.30573 1.90202L1.97337 4.13571C1.91987 4.16319 1.88889 4.19592 1.87397 4.21899C1.85951 4.24132 1.85714 4.25725 1.85714 4.267V8.7331C1.85714 8.74282 1.8595 8.75882 1.87407 8.78135C1.88913 8.80463 1.91926 8.8371 1.97322 8.86492C1.97324 8.86493 1.97321 8.86491 1.97322 8.86492L6.3064 11.0981C6.42261 11.1579 6.57807 11.1579 6.69427 11.0981L11.0275 8.86492C11.1357 8.8092 11.1429 8.7435 11.1429 8.7337V4.267C11.1429 4.25728 11.1405 4.24128 11.1259 4.21875C11.1109 4.19547 11.0807 4.163 11.0268 4.13518C11.0268 4.13517 11.0268 4.13519 11.0268 4.13518L6.6936 1.90202C6.57744 1.84223 6.42192 1.84229 6.30573 1.90202Z"
			fill="currentColor"
		/>
	</SvgIcon>
);
