import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Menu, MenuItem, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { CAMPAIGN_TYPES, replaceAddCampaignsUrl } from 'helpers';
import useAddCampaignDropdown from './useAddCampaignDropdown';

// Import styles
import { useStyles } from './styles';

const AddCampaignDropdown = () => {
	const { t } = useTranslations();

	const { anchorEl, onClose, onClick } = useAddCampaignDropdown();

	const classes = useStyles();

	return (
		<>
			<Button
				color="primary"
				variant="contained"
				onClick={onClick}
				startIcon={<AddCircleIcon />}
			>
				{t('campaigns.table.toolbar.add')}
			</Button>
			<Menu
				className={classes.menuContainer}
				getContentAnchorEl={null}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				keepMounted
				open={!!anchorEl}
				onClose={onClose}
			>
				{Object.values(CAMPAIGN_TYPES).map((type) => (
					<MenuItem key={type}>
						<Link
							to={replaceAddCampaignsUrl(type)}
							className={classes.menuItem}
							component={RouterLink}
						>
							{t(`campaigns.types.${type}`)}
						</Link>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default AddCampaignDropdown;
