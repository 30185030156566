import { combineReducers } from 'redux';
// Import reducers
import form from './bookings_form_reducer';
import preview from './bookings_preview_reducer';
import validation from './bookings_validation_reducer';

export default combineReducers({
	form,
	preview,
	validation,
});
