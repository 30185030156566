import React from 'react';

// Import components
import { TableFilters } from 'components/elements';
import { FilterFields } from './components';

// Import helpers
import { getInitialValues, formatValues } from './helpers';

export const Filters = () => {
	const initialValues = getInitialValues();

	return (
		<TableFilters initialValues={initialValues} formatValues={formatValues}>
			{() => <FilterFields />}
		</TableFilters>
	);
};
