import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import {
	CANCELLATION_REASONS_QUERY,
	LOCAL_STORAGE_CANCELLATION_REASONS,
} from 'helpers';

const useCancellationReasons = (enabled = true) => {
	const path = `/v2/booking/cancel-reasons`;

	const localStorageCancellationReasons = ls.get(
		LOCAL_STORAGE_CANCELLATION_REASONS
	);

	const { data, isFetched } = useQuery(
		CANCELLATION_REASONS_QUERY,
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			enabled: !localStorageCancellationReasons && enabled,
		}
	);

	const fetchedData = data ? data.data.data : [];

	useEffect(() => {
		if (!localStorageCancellationReasons && isFetched && fetchedData.length) {
			ls.set(LOCAL_STORAGE_CANCELLATION_REASONS, fetchedData, {
				ttl: 7 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStorageCancellationReasons, isFetched, fetchedData]);

	const result = localStorageCancellationReasons || fetchedData;
	const isFetchedResult = localStorageCancellationReasons ? true : isFetched;

	return { data: result, isFetched: isFetchedResult };
};

export default useCancellationReasons;
