import { useState } from 'react';
import { useDispatch } from 'react-redux';

// Import components
import { useTableContextProvider } from 'components/context';

// Import variables
import { ACTIVE_CELL_STATES } from 'helpers/variables/table';

// Import helpers
import { getActiveCellText } from './helpers';

const useCustomerStateCell = ({
	id,
	customerState,
	stateAction,
	textVariants,
}) => {
	const [showAlert, setShowAlert] = useState(false);

	const { fetchData } = useTableContextProvider();

	const dispatch = useDispatch();

	const { ACTIVE, BLOCKED } = ACTIVE_CELL_STATES;

	const text = getActiveCellText(customerState, textVariants);

	const alertMessage =
		customerState === ACTIVE
			? 'table.actions.user_activated'
			: 'table.actions.user_blocked';

	const variant = customerState === ACTIVE ? 'contained' : 'outlined';

	const color = customerState === BLOCKED ? 'default' : 'primary';

	const toggleAlert = () => setShowAlert((prev) => !prev);

	const handleOnChangeCustomerState = async () => {
		const action = customerState === ACTIVE ? 'blocked' : 'activate';

		await stateAction({ action, id })(dispatch);
		await fetchData();
		toggleAlert();
	};

	return {
		showAlert,
		toggleAlert,
		onChangeCustomerState: handleOnChangeCustomerState,
		text,
		alertMessage,
		variant,
		color,
	};
};

export default useCustomerStateCell;
