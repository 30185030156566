import React from 'react';
import axios from 'axios';
import { Form } from 'react-final-form';
import { queryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerPreviewData } from 'store/actions';
import { Grid, CircularProgress } from '@material-ui/core';

// Import helpers
import {
	customerInfoFields,
	FIELDS_CUSTOMER_BOOKING_INFO,
	useStyles,
} from './helpers';

import { useTranslations } from 'components/utilities';

// Import components
import { Alert } from 'components/elements';
import { Card, CustomerWallet } from './components';
import { QUERY_KEYS } from '../helpers';

export const CustomerPreview = () => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const { isLoaded, isError, item, error } = useSelector(
		(state) => state.customers.preview
	);

	const { i18n } = useTranslations();
	const lng = i18n.language;

	const { id } = useParams();

	React.useEffect(() => {
		const source = axios.CancelToken.source();
		const cancelToken = source.token;
		fetchCustomerPreviewData({ id, cancelToken })(dispatch);

		return () => {
			source.cancel();
			queryCache.cancelQueries((query) => !QUERY_KEYS.includes(query));
		};
		// eslint-disable-next-line
	}, []);

	const renderCards = () =>
		isLoaded &&
		!isError && (
			<>
				<Card
					title="customers.preview.customer.title"
					fields={customerInfoFields(lng)}
					labelWidth="220px"
					showLogo
					isAction
				/>
				<Card
					title="customers.preview.booking.title"
					fields={FIELDS_CUSTOMER_BOOKING_INFO}
					labelWidth="220px"
				/>
				<CustomerWallet uuid={id} />
			</>
		);

	return (
		<div className={classes.root}>
			{isLoaded && isError && <Alert message={error.message} />}
			<Grid container spacing={3}>
				{!isLoaded && (
					<div className={classes.loader}>
						<CircularProgress size={70} color="primary" />
					</div>
				)}
				<Form onSubmit={() => {}} initialValues={item} render={renderCards} />
			</Grid>
		</div>
	);
};
