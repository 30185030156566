import { useMessage } from 'components/utilities';

const useRegistrationProcess = ({ t }) => {
	const { message } = useMessage();

	const callbackSuccess = () => {
		message.success(t('customers.registration_process.form.success_message'));
	};

	return {
		callbackSuccess,
	};
};

export default useRegistrationProcess;
