import React from 'react';
import { func } from 'prop-types';
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';

// Import helpers
import { INITIAL_VALUES } from './helpers';

// Import components
import { Alert } from 'components/elements';
import { FormContent } from '../../components';
import { useSettleForm } from './useSettleForm';

// Import validation
import validation from './validation';

export const SettleForm = ({ toggle }) => {
	const { onSubmit, error } = useSettleForm({ toggle });
	return (
		<Form
			onSubmit={onSubmit}
			validate={validation}
			initialValues={INITIAL_VALUES}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					{error.length > 0 && <Alert message={error[0][0]} />}
					<Box>
						<FormContent toggle={toggle} />
					</Box>
				</form>
			)}
		/>
	);
};

SettleForm.propTypes = {
	toggle: func,
};
