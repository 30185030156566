import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '350px',
		padding: theme.spacing(1),
		gridGap: theme.spacing(2),
	},
	title: {
		fontSize: 16,
	},
}));

export default useStyles;
