import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';
import theme from 'theme';

// Import utils
import { useTranslations } from 'components/utilities';
import { useSignature } from './useSignature';

// Import styles
import { useStyles } from './Signature.styles';

export const Signature = () => {
	const { onSave, onClear, signatureRef } = useSignature();

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Box className={classes.headerWrapper}>
				<Typography className={classes.heading}>
					{t('contract.create_contract_form.submit_step.client_signature')}
				</Typography>
				<Button variant="contained" color="primary" onClick={onClear}>
					{t('common.buttons.clear')}
				</Button>
			</Box>
			<SignatureCanvas
				canvasProps={{ className: classes.signatureWrapper }}
				penColor={theme.palette.primary.main}
				ref={signatureRef}
				onEnd={onSave}
			/>
		</Box>
	);
};
