import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';
import { useCompanies } from 'queries';

// Import component
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import useStyles from './styles';

const CompanyField = () => {
	const classes = useStyles();

	const { data: companies } = useCompanies();

	const {
		i18n: { language },
		t,
	} = useTranslations();

	return (
		<Field
			component={SelectFieldAdapter}
			label={t('dashboard.filters.company')}
			name="company_uuid"
			variant="outlined"
			margin="dense"
			className={classes.select}
		>
			{companies.map(({ name, uuid }) => (
				<MenuItem key={uuid} value={uuid}>
					{name[language]}
				</MenuItem>
			))}
		</Field>
	);
};

export default CompanyField;
