import React from 'react';
import { object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

export const CompanyNameTableCell = ({ cell }) => {
	const { company_name, name } = cell.row.original || {};

	const cellText = company_name || name;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Typography variant="h6" color="primary">
				{cellText}
			</Typography>
		</TableCell>
	);
};

CompanyNameTableCell.propTypes = {
	cell: object.isRequired,
};
