import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm, useFormState } from 'react-final-form';
import { format, addDays } from 'date-fns';

// Import utils and helpers
import { useExtendBookingPriceCalculation } from 'queries';
import { DEFAULT_DATE_FORMAT } from 'helpers';

// Import components
import { previewBookingSelector } from 'store/selectors';

export const useFormContent = () => {
	const { period } = useSelector(previewBookingSelector);
	const { id: bookingUuid } = useParams();
	const { change, mutators } = useForm();
	const { values } = useFormState();

	const dropDate =
		values.drop_date &&
		`${format(new Date(values.drop_date), DEFAULT_DATE_FORMAT)}T${
			period.pick_time
		}`;

	const formattedDate =
		values.drop_date && format(new Date(dropDate), DEFAULT_DATE_FORMAT);

	const { data: calculations, error } = useExtendBookingPriceCalculation({
		bookingUuid,
		dropDate: formattedDate,
	});

	const dropDateError = error?.response?.data?.errors?.drop_date;
	const calculatedAmount = calculations?.item?.total_surcharge_gross_price;
	const minDropDate = new Date(addDays(new Date(period.drop_date), 1));

	useEffect(() => {
		mutators.setError('drop_date', dropDateError || null);
		// eslint-disable-next-line
	}, [dropDateError]);

	useEffect(() => {
		if (calculatedAmount) {
			change('amount', calculatedAmount);
		}
		// eslint-disable-next-line
	}, [formattedDate, calculatedAmount]);

	return {
		minDropDate,
	};
};
