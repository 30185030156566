// Import utils and helpers
import { FIELD_TYPES } from 'helpers';

const { NUMBER } = FIELD_TYPES;

export const GENERAL_FIELDS = ({ sarAdornment }) => [
	{
		type: NUMBER,
		name: 'free_kms',
		label: 'cars.form.extra_services.free_kms',
		required: true,
		md: 6,
		xs: 12,
		min: 1,
	},
	{
		type: NUMBER,
		name: 'prices.extra_km.kilometers.k1.price',
		label: 'cars.form.extra_services.price_for_extra_km',
		required: true,
		md: 6,
		xs: 12,
		min: 1,
		step: 0.01,
		endAdornment: sarAdornment,
	},
	{
		type: NUMBER,
		name: 'prices.deductible_amount.one_time',
		label: 'leasing_cars.form.extra_services.deductible',
		required: true,
		md: 6,
		xs: 12,
		min: 1,
		step: 0.01,
		endAdornment: sarAdornment,
	},
	{
		type: NUMBER,
		name: 'prices.full_tank.one_time',
		label: 'leasing_cars.form.extra_services.full_tank',
		required: true,
		md: 6,
		xs: 12,
		min: 1,
		step: 0.01,
		endAdornment: sarAdornment,
	},
];

export const PERIOD_FIELDS = (values) => [
	{
		label: 'cars.form.extra_services.cdw',
		checkboxName: `prices.insurance.months.m1.is_active`,
		isOpen: values?.prices?.insurance?.months?.m1?.is_active,
		fields: [
			{
				label: 'cars.form.extra_services.price_per_month',
				name: 'prices.insurance.months.m1.price',
			},
		],
	},
	{
		label: 'cars.form.extra_services.non_smoke_cars',
		checkboxName: `prices.no_smoking.months.m1.is_active`,
		isOpen: values?.prices?.no_smoking?.months?.m1?.is_active,
		fields: [
			{
				label: 'cars.form.extra_services.price_per_month',
				name: 'prices.no_smoking.months.m1.price',
			},
		],
	},
];
