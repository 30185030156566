export const TABLE_OPTIONS = {
	PAGE_SIZE: 10,
	PAGE_INDEX: 0,
	ROWS_PER_PAGE: [5, 10, 25, 50, 100],
};

export const TABLE_ACTIONS_ACCESSORS = {
	EDIT: 'edit',
	DELETE: 'delete',
	PRINT: 'print',
	PREVIEW: 'preview',
	ACTIVE: 'active',
	ACTION: 'action',
};

export const TOOLBAR_VARIANTS = {
	NAV: 'navigation',
	MODAL: 'modal',
	CUSTOM: 'custom',
};

export const ACTIVE_CELL_STATES = {
	ACTIVE: 'activated',
	INACTIVE: 'inactive',
	BLOCKED: 'blocked',
};

export const TITLE_POSITIONS = {
	TOP: 'top',
	BOTTOM: 'bottom',
};
