import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { FileFieldAdapter, TextFieldAdapter } from 'components/elements';
import { useSelector } from 'react-redux';

const CommercialRecord = (props) => {
	const { className, section, ...rest } = props;

	const { submitting } = useFormState();

	const { pathname } = useLocation();
	const isCreate = pathname.includes('create');

	const { t } = useTranslations();

	const token = useSelector(
		(state) => state.auth.authenticate?.data?.meta?.access_token
	);

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.commercial_record.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={4} xs={12}>
						<Field
							fullWidth
							component={TextFieldAdapter}
							type="number"
							label={t(
								'offices.form.commercial_record.commercial_record_number'
							)}
							name={`${section}.number`}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							inputProps={{ min: 0 }}
							required
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<Field
							previewFileState={section}
							component={FileFieldAdapter}
							name={`${section}.file_path`}
							fileNameField={`${section}.file_name`}
							disabled={submitting}
							token={token}
							required={isCreate}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

CommercialRecord.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default CommercialRecord;
