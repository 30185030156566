import React from 'react';
import { fetchAdminDashboardStats } from 'store/actions';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table } from 'components/elements';
import { TableBodyRow } from './components';

// Import utils
import { useFilters } from '../../context/useFilters';

export const AdminStatsTable = () => {
	const { filters } = useFilters();

	return (
		<TableProvider
			fetchDataTableAction={fetchAdminDashboardStats}
			columns={columns}
		>
			<Table isDefaultRow={false} externalFilters={filters}>
				{({ row }) => <TableBodyRow key={row.id} row={row} />}
			</Table>
		</TableProvider>
	);
};
