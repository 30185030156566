import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

// Import utils
import { AddCorporateCustomerDialogProvider } from '../AddCorporateCustomerDialog/context';

// Import components
import { AddCorporateCustomerDialog } from '../../components';

// Import store
import { profileDataSelector } from 'store/selectors';

// Import styles
import { useStyles } from './TopBar.styles';

export const TopBar = () => {
	const {
		corporate: { corporate_logo: corporateLogo },
	} = useSelector(profileDataSelector);

	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Box
				component="img"
				src={corporateLogo}
				alt="Corporate logo"
				className={classes.logo}
			/>
			<AddCorporateCustomerDialogProvider>
				<AddCorporateCustomerDialog />
			</AddCorporateCustomerDialogProvider>
		</Box>
	);
};
