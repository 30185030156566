import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	CODE: 'code',
};

export const columns = [
	{
		Header: 'promo_codes.table.headers.promo_code',
		accessor: COLUMNS_ACCESSORS.CODE,
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.table.headers.discount',
		accessor: 'discount',
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.table.headers.max_discount',
		accessor: 'max_discount',
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.table.headers.active_from',
		accessor: 'from',
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.table.headers.active_until',
		accessor: 'until',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.table.headers.created_by',
		accessor: 'created_by',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
];
