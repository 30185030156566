import React from 'react';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';
import { BOOKINGS_PAGES_TYPES } from 'helpers';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableRPC } from 'components/utilities';
import { Export, TableBodyRow } from '../components';

// Import actions
import { fetchBookings } from 'store/actions';

// Import styles
import { useStyles } from './BookingsList.styles';

export const BookingsList = () => {
	const classes = useStyles();

	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns(classes)}
			toolBarContent={TOOLBAR_CONTENT}
			tableBodyRow={TableBodyRow}
			export={<Export pageType={BOOKINGS_PAGES_TYPES.MAIN} />}
			fetchDataTableAction={fetchBookings}
		/>
	);
};
