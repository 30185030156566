// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

// Import components
import { CustomToolbar } from './components';

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: CustomToolbar,
		customKey: 'buttons',
	},
];
