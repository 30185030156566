import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './styles';

// Import utilities
import { useCalculationItem } from './useCalculationItem';

// Import helpers
import { VALUE_TYPES } from 'helpers';

export const CalculationItem = ({
	label,
	value,
	isServiceItem,
	variant,
	isHidden = false,
}) => {
	const classes = useStyles();

	const { convertedLabel, convertedValue } = useCalculationItem({
		isServiceItem,
		variant,
		label,
		value,
	});

	if (isHidden) return null;

	return (
		<Grid key={label} container justifyContent="space-between" spacing={1}>
			<Grid item>
				<Typography className={classes.label}>{convertedLabel}</Typography>
			</Grid>
			<Grid item>
				<Typography
					className={clsx(classes.value, {
						[classes.negativeAmount]: variant === VALUE_TYPES.NEGATIVE,
					})}
				>
					{convertedValue}
				</Typography>
			</Grid>
		</Grid>
	);
};

CalculationItem.propTypes = {
	value: oneOfType([string, number]),
	label: string.isRequired,
	isServiceItem: bool,
	isHidden: bool,
	variant: string,
};
