import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

// Import icons
import { EditIcon } from 'assets/icons';

// Import styles
import useStyles from './styles';

// Import utilities
import { useProfile, useTranslations } from 'components/utilities';

// Import helpers
import { URLS } from 'components/routes';

const ProfileEditButton = ({ ...props }) => {
	const { isCorporateCoordinator } = useProfile();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Button
			className={classes.button}
			variant="contained"
			color="primary"
			startIcon={<EditIcon className={classes.editIcon} />}
			component={Link}
			disabled={isCorporateCoordinator}
			to={URLS.editProfileUrl}
			{...props}
		>
			{t('common.buttons.edit_profile')}
		</Button>
	);
};

export default ProfileEditButton;
