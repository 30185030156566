import React from 'react';
import { Drawer, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { string, bool, func } from 'prop-types';

// Import helpers and utils
import { nav_links } from './helpers/nav_links';
import { useProfile } from 'components/utilities';

// Import components
import { LogoWrapper } from 'components/elements';
import { SidebarNav } from './components';

// Import styles
import { useStyles } from './Sidebar.styles';

export const Sidebar = ({ open, variant, onClose, ...props }) => {
	const classes = useStyles();

	const { seoTypes } = useSelector((store) => store.defaultSeo.seoTypes);
	const { companyUuid } = useProfile();

	return (
		<Drawer
			classes={{ paper: classes.drawer }}
			variant={variant}
			onClose={onClose}
			anchor="left"
			open={open}
		>
			<Box className={classes.root} {...props}>
				<LogoWrapper className={classes.logoWrapper} />
				<SidebarNav pages={nav_links(seoTypes, companyUuid)} />
			</Box>
		</Drawer>
	);
};

Sidebar.propTypes = {
	onClose: func,
	open: bool.isRequired,
	variant: string.isRequired,
};
