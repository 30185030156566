import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		gap: '16px',
	},
}));
