import React from 'react';
import { useSelector } from 'react-redux';
import { companyUuidSelector, isAdminSelector } from 'store/selectors';
import { fetchOffices } from 'store/actions';

// Import components
import { ExportFilters } from 'components/elements';
import { FilterFields } from '../Filters/components';

// Import helpers
import { formatValues, initialValues } from '../Filters/helpers';
import { columns } from './columns';

const Export = () => {
	const itemId = useSelector(companyUuidSelector);
	const isAdmin = useSelector(isAdminSelector);

	return (
		<ExportFilters
			fetchDataTableAction={(options) => fetchOffices({ ...options, itemId })}
			filterFields={() => <FilterFields isExport />}
			initialValues={initialValues}
			formatValues={formatValues}
			columns={columns(isAdmin)}
		/>
	);
};

export default Export;
