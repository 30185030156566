import React from 'react';
import PropTypes from 'prop-types';

// Import components
import {
	DefaultCompensationHistoryField,
	ImagePreviewField,
	TopicField,
} from './';

// Import helpers
import { FIELDS_ACCESSORS } from '../helpers';

const CompensationHistoryFieldFactory = (props) => {
	const { CONTRACT, TRANSACTION_COPY, TOPIC } = FIELDS_ACCESSORS;

	const { accessor, compensation } = props;

	switch (accessor) {
		case TOPIC:
			return <TopicField type={compensation.type} />;

		case CONTRACT:
			return (
				<ImagePreviewField
					label="bookings.compensation_history.contract"
					name="contract"
					imgUrl={compensation.contract_file?.file_path}
				/>
			);

		case TRANSACTION_COPY:
			return (
				<ImagePreviewField
					label="bookings.compensation_history.transaction_copy"
					name="transaction_copy"
					imgUrl={compensation.transaction_copy?.file_path}
				/>
			);

		default:
			return <DefaultCompensationHistoryField {...props} />;
	}
};

CompensationHistoryFieldFactory.propTypes = {
	compensation: PropTypes.object,
	accessor: PropTypes.string,
};

export default CompensationHistoryFieldFactory;
