// ********************  table  ********************

export const fetchPagesUrl = (queryParams) => `/v2/page/list${queryParams}`;
export const deletePageUrl = (id) => `/v2/page/${id}/remove`;
export const updateActivePageUrl = (id, isActive) =>
	`/v2/page/${id}/${isActive ? 'deactivate' : 'activate'}`;

// ********************  form  ********************

export const fetchCmsPagesFormResourcesUrl = (itemId) =>
	`/v2/page/${itemId}/show`;
export const submitPageFormUrl = (itemId) =>
	`/v2/page/${itemId ? `${itemId}/update` : 'create'}`;
