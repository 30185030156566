import { useQuery } from 'react-query';

// Import helpers and utils
import { TRAIN_STATION_QUERY } from 'helpers';
import services from 'services/services';

export const useTrainStation = ({ uuid }) => {
	const path = `/railway/train-station/${uuid}`;

	const { data, isError, isFetching } = useQuery(
		[TRAIN_STATION_QUERY, uuid],
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			enabled: !!uuid,
		}
	);

	const fetchedData = data ? data.data : null;

	return { data: fetchedData, isFetching, isError };
};
