import React from 'react';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { CardContent, Box, Card } from '@material-ui/core';

// Import components
import { AlertMessage, LoadingWrapper } from 'components/elements';
import { FormContent } from '../../components';

// Import utils
import { useTranslations } from 'components/utilities';
import { useWrapper } from './useWrapper';

// Import styles
import { useStyles } from './Wrapper.styles';

export const Wrapper = () => {
	const { isError, isLoading, isFetching, initialValues, handleOnSubmit } =
		useWrapper();
	const { t } = useTranslations();
	const classes = useStyles();

	if (isError) {
		return (
			<AlertMessage
				description={t('common.messages.error_message')}
				title={t('common.error')}
				variant="error"
			/>
		);
	}

	return (
		<Box className={classes.container}>
			<Card>
				<CardContent>
					{isFetching ? (
						<LoadingWrapper />
					) : (
						<Form
							initialValues={initialValues}
							onSubmit={handleOnSubmit}
							mutators={{ ...arrayMutators }}
							render={({ handleSubmit }) => (
								<form onSubmit={handleSubmit}>
									<FormContent isLoading={isLoading} />
								</form>
							)}
						></Form>
					)}
				</CardContent>
			</Card>
		</Box>
	);
};
