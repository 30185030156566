import React from 'react';
import { object, bool, array, oneOfType } from 'prop-types';
import { CircularProgress } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { PreviewFields } from '../components';
import { Card } from 'components/elements';

// Import helpers
import { FIELDS_CAR } from '../helpers';

const CarCard = ({ customData, isFetching }) => {
	const { t } = useTranslations();

	if (isFetching) {
		return <CircularProgress />;
	}

	return (
		<Card title={t('bookings.preview_booking.car.title')} lg={12}>
			<PreviewFields customData={customData} fields={FIELDS_CAR} />
		</Card>
	);
};

CarCard.propTypes = {
	customData: oneOfType([object, array]),
	isFetching: bool,
};

export default CarCard;
