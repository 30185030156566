export const extractorValues = (values) => {
	const {
		carInfo: {
			external: { has_external, car_codes, codes },
		},
	} = values;
	return {
		has_external,
		selectedValue: car_codes,
		options: codes,
	};
};

export const initValueFun = (selectedValue) => selectedValue.length === 0;
