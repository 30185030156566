import React from 'react';
import { string } from 'prop-types';
import { Button, Typography as MuiTypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useFormState } from 'react-final-form';

// Import utilities
import { useToggle } from 'components/utilities';

// Import components
import { ImagePreview } from 'components/elements';

const useStyles = makeStyles((theme) => ({
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),
		minWidth: '105px',
		display: 'inline-block',
	},
}));

const Preview = ({ label, path }) => {
	const classes = useStyles();

	const { on, toggle } = useToggle();

	const form = useFormState();
	const url = form.values[path]?.file_path ?? '';

	return (
		<div className={classes.root}>
			<MuiTypography component="p" className={classes.label}>
				{label}
			</MuiTypography>
			{url && (
				<Button onClick={toggle}>
					<SearchIcon />
				</Button>
			)}

			{url && on && <ImagePreview imgUrl={url} on={on} close={toggle} />}
		</div>
	);
};

Preview.propTypes = {
	path: string.isRequired,
	label: string.isRequired,
};

export default Preview;
