import { startOfMonth } from 'date-fns';

// Import helpers
import {
	convertFormDates,
	ADMINS,
	COMPANY_MANAGERS_WITHOUT_ADMINS,
} from 'helpers';

export const initialValues = {
	date: startOfMonth(new Date()),
	date_to: new Date(),
	role: 'all',
	office_uuid: 'all',
	city_uuid: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		role: values.role === 'all' ? null : values.role,
		office_uuid: values.office_uuid === 'all' ? null : values.office_uuid,
		city_uuid: values.city_uuid === 'all' ? null : values.city_uuid,

		...convertFormDates(values, ['date']),
	};
};

export const PERMISSION_COMPANY_OFFICES_FILTER = ADMINS;
export const PERMISSION_OFFICE_FILTER = COMPANY_MANAGERS_WITHOUT_ADMINS;
