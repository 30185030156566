import React from 'react';
import {
	CardContent,
	CardHeader,
	Divider,
	Card,
	Grid,
} from '@material-ui/core';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { FIELDS } from './helpers';
import { useSpecialOfferDetails } from './useSpecialOfferDetails';

export const SpecialOfferDetails = () => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { filteredCities, availableCars, companyList, values } =
		useSpecialOfferDetails();

	return (
		<Card>
			<CardHeader title={t('special_offer.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS({
						companyCars: availableCars,
						cities: filteredCities,
						companyList,
						values,
					}).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
