import { FIELD_TYPES } from 'helpers';

export const colorFields = ['main_color_hex', 'light_color_hex'];

export const DESIGN_FIELDS = [
	{
		type: FIELD_TYPES.IMAGE_FILE,
		label: `cms.partners.form.design.logo`,
		name: `images.logo.file_path`,
		previewFileState: `images.logo`,
		fileNameField: `images.logo.file_name`,
		required: true,
	},
	{
		type: FIELD_TYPES.IMAGE_FILE,
		label: `cms.partners.form.design.banner`,
		name: `images.banner.file_path`,
		previewFileState: `images.banner`,
		fileNameField: `images.banner.file_name`,
		required: true,
	},
];
