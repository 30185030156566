export const COLUMN_ACCESSOR = {
	EXTERNAL_PARTNER: 'external_partner',
};

export const columns = [
	{
		header: 'manufacturer',
		accessor: 'maker',
	},
	{
		header: 'model',
		accessor: 'model',
	},
	{
		header: 'year',
		accessor: 'year',
	},
	{
		header: 'external_partner',
		accessor: COLUMN_ACCESSOR.EXTERNAL_PARTNER,
	},
];
