// ********************  table  ********************

export const fetchValidationBookingsUrl = (queryParams) =>
	`v2/booking/operation/validation${queryParams}`;

// ********************  form  ********************

export const rejectCustomerCompensationUrl = (uuid) =>
	`/v2/booking/compensation/${uuid}/disapprove`;

export const calculateCompensationUrl = (uuid) =>
	`/v2/booking/${uuid}/calculate-compensation`;
