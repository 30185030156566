import React from 'react';
import { Grid } from '@material-ui/core';

// Import utils
import { useFormContent } from './useFormContent';

// Import components
import { Section } from '../../components';

export const FormContent = () => {
	const { cards } = useFormContent();

	return (
		<Grid container spacing={4}>
			{cards.map(({ title, fields }) => (
				<Grid key={title} item xs={12} md={9}>
					<Section title={title} fields={fields} />
				</Grid>
			))}
		</Grid>
	);
};
