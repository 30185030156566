import React from 'react';
import { number, object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { ChipLabel } from 'components/elements';

export const EarningsTableCell = ({ cell, earnings }) => {
	const { t } = useTranslations();

	return (
		<TableCell {...cell.getCellProps()}>
			{earnings ? (
				<Typography>{earnings}</Typography>
			) : (
				<ChipLabel label={t('common.not_available_shorthand')} color="gray" />
			)}
		</TableCell>
	);
};

EarningsTableCell.propTypes = {
	cell: object.isRequired,
	earnings: number,
};
