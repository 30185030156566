import React from 'react';
import { bool, number, string } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import utilities and helpers
import { CHECKBOX_FIELD, DATE_FIELDS } from './helpers';
import { useCommonFields } from 'components/utilities';

const PublicHoliday = ({ name, index, dateFrom, dateTo, selected }) => {
	const { renderFields } = useCommonFields();

	return (
		<Grid
			container
			spacing={1}
			alignItems="center"
			justifyContent="space-between"
		>
			<Grid item xs="auto">
				<Grid container>
					{CHECKBOX_FIELD({ name, index }).map(renderFields)}
				</Grid>
			</Grid>
			<Grid item xs="auto">
				<Grid container spacing={2}>
					{DATE_FIELDS({ index, dateFrom, dateTo, selected }).map(renderFields)}
				</Grid>
			</Grid>
		</Grid>
	);
};

PublicHoliday.propTypes = {
	dateFrom: string,
	dateTo: string,
	selected: bool,
	index: number,
	name: string,
};

export default PublicHoliday;
