import React from 'react';
import { array, string, bool, number } from 'prop-types';
import { Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Grid, MenuItem, CircularProgress } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from '../styles';

export const Model = ({
	showAddModelOption = false,
	showAllOption = false,
	required = false,
	disabled = false,
	isLoading,
	models,
	name,
	label,
	md = 12,
	xs = 12,
}) => {
	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const classes = useStyles();

	const history = useHistory();

	const addModel = () => history.push(URLS.addCarModelUrl);

	if (isLoading) {
		return (
			<Grid item md={md} xs={xs}>
				<CircularProgress size={27} />
			</Grid>
		);
	}

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				type="select"
				label={t(label)}
				name={name}
				margin="dense"
				variant="outlined"
				disabled={disabled}
				required={required}
			>
				{showAllOption && <MenuItem value="all">{t('common.all')}</MenuItem>}
				{models.map(({ model_name, model_uuid }) => (
					<MenuItem key={model_uuid} value={model_uuid}>
						{model_name[lng]}
					</MenuItem>
				))}
				{showAddModelOption && (
					<MenuItem className={classes.root} onClick={addModel}>
						+ {t('cars.models.table.toolbar.add_model')}
					</MenuItem>
				)}
			</Field>
		</Grid>
	);
};

Model.propTypes = {
	models: array.isRequired,
	label: string.isRequired,
	name: string.isRequired,
	showAddModelOption: bool,
	showAllOption: bool,
	isLoading: bool,
	required: bool,
	disabled: bool,
	md: number,
	xs: number,
};

Model.defaultProps = {
	xs: 12,
	md: 12,
};
