import React from 'react';
import { node, string } from 'prop-types';
import { Topbar } from './components';

// Import styles
import { useStyles } from './Minimal.styles';

export const Minimal = (props) => {
	const { children } = props;

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Topbar />
			<main className={classes.content}>{children}</main>
		</div>
	);
};

Minimal.propTypes = {
	children: node,
	className: string,
};
