import React from 'react';
import { node } from 'prop-types';
import RTLProvider from 'rtl';

// Import styles
import { useStyles } from './PrintContent.styles';

export const PrintContent = ({ children }) => {
	const classes = useStyles();

	return (
		<RTLProvider>
			<div className={classes.root}>
				<main className={classes.content}>{children}</main>
			</div>
		</RTLProvider>
	);
};

PrintContent.propTypes = {
	children: node,
};
