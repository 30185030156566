import React from 'react';
import { bool } from 'prop-types';

import { Field, useForm } from 'react-final-form';

// Import components
import { CheckboxFieldAdapter, ModalDialog } from 'components/elements';

// Import utilities
import { useToggle, useTranslations } from 'components/utilities';

import TermsAndCondition from './TermsAndCondition';

const CheckboxFieldAndModal = ({ submitting }) => {
	const { getFieldState, change } = useForm();
	const { t } = useTranslations();

	const { on, toggle } = useToggle();

	const fieldName = 'terms_and_condition';
	const fieldLabel = t('forget_password.form.checkbox_label');

	const handleChange = (toggle) => {
		const checkboxValue = getFieldState(fieldName).value;
		checkboxValue ? change(fieldName, false) : toggle();
	};

	const handleSubmit = (toggle) => {
		toggle();
		change(fieldName, true);
	};

	return (
		<>
			<Field
				component={CheckboxFieldAdapter}
				type="checkbox"
				label={fieldLabel}
				labelText={fieldLabel}
				name={fieldName}
				color="primary"
				autoComplete="false"
				disabled={submitting}
				onChange={() => handleChange(toggle)}
			/>

			<ModalDialog
				title="forget_password.form.terms_and_condition"
				alignTitle="left"
				open={on}
				close={toggle}
				onSubmit={() => handleSubmit(toggle)}
				initialValues={{}}
				submitLabel={t('forget_password.form.agree_label')}
			>
				{() => <TermsAndCondition />}
			</ModalDialog>
		</>
	);
};

CheckboxFieldAndModal.propTypes = {
	submitting: bool,
};

export default CheckboxFieldAndModal;
