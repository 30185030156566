import React from 'react';
import { fetchLastBooking } from 'store/actions';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import columns
import { columns } from './columns';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableBodyRow } from './components';

// Import helpers
import { TOOLBAR_CONTENT } from './helpers';
import { URLS } from 'components/routes';

const LastBooking = () => {
	const classes = useBaseLayoutStyles();

	return (
		<TableProvider columns={columns} fetchDataTableAction={fetchLastBooking}>
			<div className={classes.root}>
				<Toolbar content={TOOLBAR_CONTENT} />
				<div className={classes.content}>
					<Table editLinkPath={URLS.dashboardUrl} isDefaultRow={false}>
						{({ row, editLinkPath }) => (
							<TableBodyRow
								editLinkPath={editLinkPath}
								key={row.id}
								row={row}
							/>
						)}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};

export default LastBooking;
