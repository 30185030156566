import React from 'react';
import { array, bool, string } from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';

const ExcludeList = ({ availableExcludes, name, disabled = false }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<List disablePadding>
			{availableExcludes.map((exclude) => (
				<ListItem key={exclude}>
					<Field
						type="checkbox"
						component={CheckboxFieldAdapter}
						name={`${name}.exclude`}
						disabled={submitting || disabled}
						value={exclude}
						labelText={t(`company.form.payment_methods.excludes.${exclude}`)}
						color="primary"
					/>
				</ListItem>
			))}
		</List>
	);
};

ExcludeList.propTypes = {
	name: string,
	availableExcludes: array,
	disabled: bool,
};

export default ExcludeList;
