import { useSelector } from 'react-redux';

const useOptionInfoListItem = ({ type }) => {
    const { options } = useSelector((store) => store.bookings.form.info.item);

    const hasOption = !!(options || []).find((option) => option.type === type);

    return {
        hasOption
    }
};

export default useOptionInfoListItem;