import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Import helpers
import { URLS } from 'components/routes';
import { useQueryStringParams } from 'components/utilities';

// Import selectors
import { isAuthenticatedSelector, isForcePasswordReset } from 'store/selectors';
import { driversAutoSignIn } from 'store/actions';

const ProtectedDashboard = ({ component: Component }) => {
	const isAuthenticated = useSelector(isAuthenticatedSelector);
	const forcePasswordReset = useSelector(isForcePasswordReset);
	const dispatch = useDispatch();
	const { parsedQSParams } = useQueryStringParams();
	const { pathname } = useLocation();
	const driverToken = parsedQSParams.token || '';

	const isDriverAllowed =
		pathname.includes('draft') || pathname.includes('close');

	useEffect(() => {
		if (driverToken && isDriverAllowed) {
			driversAutoSignIn(driverToken)(dispatch);
		}
		// eslint-disable-next-line
	}, [driverToken]);

	return (
		<Route
			render={(props) =>
				isAuthenticated || driverToken ? (
					forcePasswordReset ? (
						<Redirect to={URLS.resetPasswordUrl} />
					) : (
						<Component {...props} />
					)
				) : (
					<Redirect to={URLS.signInUrl} />
				)
			}
		/>
	);
};

ProtectedDashboard.propTypes = {
	component: func.isRequired,
};

export default ProtectedDashboard;
