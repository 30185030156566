import i18next from 'i18next';
import { useFormState } from 'react-final-form';

// Import components
import {
	CheckboxFieldAdapter,
	SelectFieldAdapter,
	TextFieldAdapter,
} from 'components/elements';

// Import utilities
import { usePlateFormOptions } from 'queries';

// Import helpers
import { FIELD_TYPES, generateArray } from 'helpers';

// Import variables
import {
	FUEL_TYPES,
	OIL_TYPES,
	SEAT_TYPES,
	LEASING_CAR_SPECIFICATION,
	MILES_USAGE_FLAG,
} from './variables';

const { SELECT, CHECKBOX, NUMBER } = FIELD_TYPES;

export const useLeasingCarSpec = () => {
	const { submitting, values } = useFormState();
	const { data } = usePlateFormOptions();
	const { body_colors } = data || {};

	/* eslint-disable */
	const bodyColors = body_colors
		? Object.entries(body_colors)?.map(([value, key]) => ({
				label: i18next.t(`cars.car_list.plate_form.select_colors.${value}`),
				value: key,
		  }))
		: [];
	/* eslint-enable */

	const milesUsage = values?.carInfo?.specification?.miles_usage;

	const doorsNumber = generateArray(1, 5).map((number) => ({
		label: number,
		value: number.toString(),
	}));

	const fuelTypesLabels = FUEL_TYPES.map((option) => ({
		label: i18next.t(`cars.form.car_specifications.options.energy.${option}`),
		value: option,
	}));

	const oilTypesLabels = OIL_TYPES.map((option) => ({
		label: i18next.t(`leasing_cars.form.car_specifications.oil.${option}`),
		value: option,
	}));

	const seatTypesLabels = SEAT_TYPES.map((option) => ({
		label: i18next.t(`leasing_cars.form.car_specifications.seat.${option}`),
		value: option,
	}));

	const CHECKBOX_FIELDS = () =>
		LEASING_CAR_SPECIFICATION.map((option) => ({
			component: CheckboxFieldAdapter,
			type: CHECKBOX,
			label: `leasing_cars.form.car_specifications.options.${option}`,
			name: `carInfo.specification.${option}`,
			md: 2,
			xs: 6,
			color: 'primary',
		}));

	const SPECIFICATION_FIELDS = ({ bodyColors }) => [
		{
			type: SELECT,
			component: SelectFieldAdapter,
			label: 'cars.car_list.plate_form.fields.color',
			name: 'carInfo.specification.body_color',
			xs: 12,
			md: 6,
			options: bodyColors,
			required: true,
		},
		{
			type: SELECT,
			component: SelectFieldAdapter,
			label: `cars.form.car_specifications.options.energy.title`,
			name: `carInfo.specification.fuel_type`,
			options: fuelTypesLabels,
			xs: 12,
			md: 6,
			required: false,
		},
		{
			type: NUMBER,
			component: TextFieldAdapter,
			label: 'leasing_cars.form.car_specifications.usage.title',
			name: 'carInfo.specification.miles_usage',
			xs: 12,
			md: 6,
			required: true,
		},
		{
			type: MILES_USAGE_FLAG,
			name: MILES_USAGE_FLAG,
		},
		{
			type: SELECT,
			component: SelectFieldAdapter,
			label: `cars.form.car_specifications.options.doors_number`,
			name: `carInfo.specification.doors_number`,
			options: doorsNumber,
			xs: 12,
			md: 6,
			required: false,
		},
		{
			type: SELECT,
			component: SelectFieldAdapter,
			label: `leasing_cars.form.car_specifications.oil.title`,
			name: `carInfo.specification.used_oil`,
			options: oilTypesLabels,
			xs: 12,
			md: 6,
			required: false,
		},
		{
			type: SELECT,
			component: SelectFieldAdapter,
			label: `leasing_cars.form.car_specifications.seat.title`,
			name: `carInfo.specification.seat_material`,
			options: seatTypesLabels,
			xs: 12,
			md: 6,
			required: false,
		},
	];

	return {
		submitting,
		bodyColors,
		milesUsage,
		CHECKBOX_FIELDS,
		SPECIFICATION_FIELDS,
		MILES_USAGE_FLAG,
	};
};
