import React from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';
import { Field } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	MenuItem,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';

// Import components
import { MultiselectFiledAdapter } from 'components/elements';

// Import translations
import { useTranslations, useExternalPartner } from 'components/utilities';

// Import helpers
import { extractorValues, initValueFun } from './helpers';

const ExternalPartner = ({ className, section, ...rest }) => {
	const { t } = useTranslations();

	const {
		submitting,
		has_external,
		options: codes,
		isHidden,
		handleChangeInfo,
	} = useExternalPartner({
		extractorValues,
		initValueFun,
		valueName: `carInfo.${section}.car_codes`,
		emptyValue: [],
	});

	return has_external ? (
		<Card {...rest} className={clsx(className)}>
			<CardHeader title={t('cars.form.external_partner.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={!isHidden}
									onChange={handleChangeInfo}
								/>
							}
							label={t('cars.form.external_partner.use_external_partner')}
						/>
					</Grid>
					{!isHidden && (
						<Field
							fullWidth
							component={MultiselectFiledAdapter}
							type="select"
							label={t('cars.form.external_partner.external_partner')}
							name={`carInfo.${section}.car_codes`}
							margin="dense"
							variant="outlined"
							disabled={submitting}
							required
							labelextractor={(value) => codes[value]}
						>
							{Object.entries(codes).map(([key, name]) => {
								return (
									<MenuItem key={+key} value={+key}>
										{name}
									</MenuItem>
								);
							})}
						</Field>
					)}
				</Grid>
			</CardContent>
		</Card>
	) : null;
};
ExternalPartner.propTypes = {
	className: string,
	section: string.isRequired,
};

export default ExternalPartner;
