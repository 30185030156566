import * as types from 'store/types';
import produce from 'immer';

const INITIAL_STATE = {
	item: {},
	isLoaded: false,
	isError: false,
	recalculate: {
		isLoaded: false,
		isError: false,
		isRecalculated: false,
		errors: null,
	},
	info: {
		isLoaded: false,
		isError: false,
		item: {},
	},
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.BOOKINGS_FORM_FETCH_DATA_LOADING:
				draft.isLoaded = false;
				break;

			case types.BOOKINGS_FORM_FETCH_DATA_SUCCESS:
				draft.isLoaded = true;
				draft.item = action.payload.item.data;
				break;

			case types.BOOKINGS_FORM_FETCH_DATA_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				break;
			// ------ RECALCULATE -----
			case types.BOOKINGS_FORM_RECALCULATE_DATA_LOADING:
				draft.recalculate.isLoaded = false;
				break;

			case types.BOOKINGS_FORM_RECALCULATE_DATA_SUCCESS:
				draft.recalculate.isLoaded = true;
				draft.recalculate.isRecalculated = true;
				draft.recalculate.errors = null;
				draft.item = { ...draft.item, ...action.payload.item.data };
				break;

			case types.BOOKINGS_FORM_RECALCULATE_DATA_ERROR:
				draft.recalculate.isLoaded = true;
				draft.recalculate.isError = true;
				draft.recalculate.errors = action.payload.errors;
				break;
			// ------ INFO -----
			case types.BOOKINGS_FORM_FETCH_INFO_LOADING:
				draft.info.isLoaded = false;
				draft.recalculate.isRecalculated = false;
				break;
			case types.BOOKINGS_FORM_FETCH_INFO_SUCCESS:
				draft.info.isLoaded = true;
				draft.info.item = action.payload.item.data;
				break;
			case types.BOOKINGS_FORM_FETCH_INFO_ERROR:
				draft.info.isLoaded = true;
				draft.info.isError = true;
				break;

			default:
				return state;
		}
	});
