export const columns = [
	{
		header: 'cars.car_logs_history.table.headers.id',
		accessor: 'version',
	},
	{
		header: 'cars.car_logs_history.table.headers.message',
		accessor: 'event_name',
	},
	{
		header: 'cars.car_logs_history.table.headers.user',
		accessor: 'blamable',
	},
	{
		header: 'cars.car_logs_history.table.headers.date_time',
		accessor: 'created_at',
	},
];
