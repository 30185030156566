import React, { Fragment } from 'react';
import { bool, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { updateStatusScheduleBooking } from 'store/actions';

// Import helpers
import {
	replaceEditBookingUrl,
	replaceOfficeDetailsUrl,
	isRejectVisible,
	replacePreviewBookingUrl,
	TITLE_POSITIONS,
} from 'helpers';
import { COLUMNS_ACCESSORS } from '../../columns';

// Import utilities
import { useTableContextProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

// Import components
import {
	DeliveryAssignmentTableCell,
	DoneActionTableCell,
	TrackingMapTableCell,
	DeliveryStatusTableCell,
} from './components';
import {
	StatusTableCell,
	LinkTableCell,
	PickDateTableCell,
} from 'components/elements';

const {
	BOOKING_ID,
	STATUS,
	COMPANY_OFFICE_NAME,
	CUSTOMER_NAME,
	ACTION,
	PICK_DATE_TIME,
	DELIVERY_ASSIGNMENT,
	DELIVERY_STATUS,
	VEHICLE,
	TRACKING_MAP,
} = COLUMNS_ACCESSORS;

const { TOP } = TITLE_POSITIONS;

export const TableBodyRow = ({ row, isAdmin }) => {
	const { resources, fetchData } = useTableContextProvider();

	const { t } = useTranslations();

	const renderCell = (cell) => {
		const {
			uuid,
			office_name: officeName,
			office_uuid: officeUuid,
			company_name: companyName,
			individual_number: individualNumber,
			customer_phone: customerPhone,
			customer_name: customerName,
			vehicle,
		} = cell.row.original || {};

		const { id } = cell.column || {};

		switch (id) {
			case STATUS:
				return (
					<StatusTableCell
						key={id}
						acceptAction={updateStatusScheduleBooking}
						isRejectVisible={isRejectVisible(cell, resources)}
						cell={cell}
					/>
				);

			case COMPANY_OFFICE_NAME:
				return (
					<LinkTableCell
						key={id}
						cell={cell}
						to={replaceOfficeDetailsUrl(officeUuid)}
						subtitle={isAdmin ? companyName : ''}
						subtitlePosition={TOP}
					>
						{officeName}
					</LinkTableCell>
				);

			case BOOKING_ID:
				return (
					<LinkTableCell
						key={id}
						cell={cell}
						to={replacePreviewBookingUrl(uuid)}
					>
						{individualNumber}
					</LinkTableCell>
				);

			case VEHICLE:
				return (
					<Fragment key={id}>
						{isAdmin ? (
							<LinkTableCell
								cell={cell}
								to={replaceEditBookingUrl(uuid)}
								subtitle={vehicle}
								subtitlePosition={TOP}
							>
								{t('bookings.table.headers.transfer')}
							</LinkTableCell>
						) : (
							<TableCell {...cell.getCellProps()}>{vehicle}</TableCell>
						)}
					</Fragment>
				);

			case PICK_DATE_TIME:
				return <PickDateTableCell key={id} cell={cell} />;

			case DELIVERY_ASSIGNMENT:
				return (
					<DeliveryAssignmentTableCell
						key={id}
						cell={cell}
						isDropOffSort={false}
						fetchData={fetchData}
					/>
				);

			case DELIVERY_STATUS:
				return <DeliveryStatusTableCell key={id} cell={cell} />;

			case CUSTOMER_NAME:
				return (
					<TableCell key={id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case TRACKING_MAP:
				return <TrackingMapTableCell key={cell.column.id} cell={cell} />;

			case ACTION:
				return <DoneActionTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	isAdmin: bool.isRequired,
};
