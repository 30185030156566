import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingRight: 5,
	},
	item: {
		display: 'flex',
		padding: 0,
	},
	button: {
		color: colors.blueGrey[400],
		padding: '18px 34px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: '500',
		textAlign: 'left',
	},
	boldText: {
		fontWeight: 'bold',
	},
	buttonPadding: {
		padding: '12px 0 12px 78px',
	},
	icon: {
		color: colors.blueGrey[400],
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		marginRight: 20,
	},
	withoutIcon: {
		paddingLeft: 30,
	},
	active: {
		color: theme.palette.black,
		fontWeight: '500',
		'& $icon': {
			color: theme.palette.primary.main,
		},
	},

	activeTitle: {
		'& span:first-child': {
			color: theme.palette.primary.main,
		},
		'& span:last-child': {
			color: theme.palette.white,
		},
	},
	spacer: {
		flexGrow: 1,
	},
	collapsedHeadListItem: {
		paddingTop: 2,
	},
	collapsedHeadButton: {
		'&:hover': { background: 'rgba(0, 0, 0, 0.08)' },
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		position: 'relative',
	},
	horizontalBar: {
		'&:before': {
			content: '""',
			position: 'absolute',
			left: 0,
			height: '100%',
			width: 4,
			background: theme.palette.primary.main,
		},
	},
	collapsedList: {
		marginBottom: 2,
		borderBottomLeftRadius: 4,
		borderBottomRightRadius: 4,
	},
	list: {
		width: '100%',
	},
	nestedItem: {
		paddingLeft: 110,
	},
}));

export default useStyles;
