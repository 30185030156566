import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm, useFormState } from 'react-final-form';

// Import utils and helpers
import { useMessage } from 'components/utilities';
import { deleteBookingRate } from 'store/actions';
import { OFFICE_RATING_EMPTY_FIELDS } from 'store/actions/bookings/helpers';
import { officeRatingSelector } from 'store/selectors';

export const useResetButton = () => {
	const [isLoading, setIsLoading] = useState(false);

	const { message } = useMessage();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { reset, change } = useForm();
	const {
		values: { rating },
	} = useFormState();

	const officeRating = useSelector(officeRatingSelector);

	const handleDeleteBookingRate = async () => {
		setIsLoading(true);
		try {
			await deleteBookingRate({ id })(dispatch);
			await reset(OFFICE_RATING_EMPTY_FIELDS);
			setIsLoading(false);
			change('isReset', true);
		} catch (error) {
			if (error?.response) {
				const errorData = error.response.data;
				const errorMessage =
					errorData.message ?? errorData.error ?? errorData.errors.uuid;
				message.error(errorMessage);
			}
			setIsLoading(false);
		}
	};

	const isDisabled = isLoading || !rating || !officeRating;

	return { handleDeleteBookingRate, isDisabled, isLoading };
};
