import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { DETAILS_FIELDS, LOCALE } from './helpers';

const SliderDetails = ({ section, className, ...rest }) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<Card className={className} {...rest}>
			<CardHeader title={t('sliders.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{DETAILS_FIELDS.map(({ name, label, type, component, md, xs }) => (
						<Grid key={name} item md={md} xs={xs}>
							{type === 'select' ? (
								<Field
									fullWidth
									component={component}
									type={type}
									name={`${section}.${name}`}
									label={t(label)}
									margin="dense"
									variant="outlined"
									disabled={submitting}
									required
								>
									{LOCALE.map(({ name, locale }) => (
										<MenuItem key={name} value={locale}>
											{name}
										</MenuItem>
									))}
								</Field>
							) : (
								<Field
									fullWidth
									component={component}
									type={type}
									name={`${section}.${name}`}
									label={t(label)}
									margin="dense"
									variant="outlined"
									disabled={submitting}
									required
									inputProps={{ min: 0 }}
								/>
							)}
						</Grid>
					))}
				</Grid>
			</CardContent>
		</Card>
	);
};

SliderDetails.propTypes = {
	section: string.isRequired,
	className: string,
};

export default SliderDetails;
