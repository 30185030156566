import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { fetchCmsPagesFormResources, submitPageForm } from 'store/actions';

// Import components
import { FormRPC } from 'components/utilities';
import { BaseDetails, Filters, PageImage } from './components';
import { SeoCard } from 'components/elements';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

//Import styles
import useStyles from './styles';

const PagesForm = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchCmsPagesFormResources}
				submitFormAction={submitPageForm}
				store={(store) => store.pages.form}
				initialValues={INITIAL_VALUES}
				isCardLayout={false}
				goBackPath={URLS.cmsPagesUrl}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={9}>
							<BaseDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={9}>
							<PageImage section="image" />
						</Grid>
						<Grid item lg={9} xs={9}>
							<Filters section="filter" />
						</Grid>
						<Grid item lg={9} xs={9}>
							<SeoCard section="seo" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default PagesForm;
