import { useFilters } from '../../../../../../context/useFilters';

export const useCompanyNameTableCell = (companyUuid) => {
	const { setFilters } = useFilters();

	const handleOnClick = () => {
		setFilters((filters) => ({
			...filters,
			company_uuid: companyUuid,
		}));
	};

	return {
		handleOnClick,
	};
};
