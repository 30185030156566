import { useFormState } from 'react-final-form';

// Import helpers
import { getMinDateFrom, getMinDateTo } from './helpers';

const useCampaignConditionsCard = () => {
	const { values } = useFormState();

	const {
		duration_from: currentDurationFrom,
		booking_created_from: currentBookingCreatedFrom,
		booking_pick_date_from: currentBookingPickDateFrom,
	} = values.condition;

	const sectionName = 'condition';

	const labelSectionName = 'campaigns.form.condition';

	const conditionsFields = [
		{
			name: `${sectionName}.duration_from`,
			label: `${labelSectionName}.duration_from`,
			minDate: getMinDateFrom(),
		},
		{
			name: `${sectionName}.duration_to`,
			label: `${labelSectionName}.duration_to`,
			minDate: getMinDateTo(currentDurationFrom),
		},
		{
			name: `${sectionName}.booking_created_from`,
			label: `${labelSectionName}.booking_created_from`,
			minDate: getMinDateFrom(),
		},
		{
			name: `${sectionName}.booking_created_to`,
			label: `${labelSectionName}.booking_created_to`,
			minDate: getMinDateTo(currentBookingCreatedFrom),
		},
		{
			name: `${sectionName}.booking_pick_date_from`,
			label: `${labelSectionName}.booking_pick_date_from`,
			minDate: getMinDateFrom(),
		},
		{
			name: `${sectionName}.booking_pick_date_to`,
			label: `${labelSectionName}.booking_pick_date_to`,
			minDate: getMinDateTo(currentBookingPickDateFrom),
		},
	];

	return { conditionsFields, sectionName };
};

export default useCampaignConditionsCard;
