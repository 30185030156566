import services from 'services/services';
import { refundTransactionUrl, chargeTransactionUrl } from 'store/paths';

export const refundTransaction = async ({ transactionId }) => {
	try {
		await services.put(refundTransactionUrl(transactionId), {});

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};

export const chargeTransaction = async ({ transactionId }) => {
	try {
		await services.put(chargeTransactionUrl(transactionId), {});

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};
