// Import utils
import { useMessage, useTranslations } from 'components/utilities';

export const useSystemClients = () => {
	const { message } = useMessage();
	const { t } = useTranslations();

	const callbackSuccess = () => {
		message.success(t('common.messages.successfully_saved'));
	};

	const errorCallback = () => {
		message.error(t('common.messages.error_message'));
	};
	return { callbackSuccess, errorCallback };
};
