import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT } = TABLE_ACTIONS_ACCESSORS;

export const COLUMNS_ACCESSORS = {
	ACTIVE: 'active',
	CAMPAIGN_TYPE: 'campaign_type',
	CREATED: 'created',
	TOTAL_DEPOSIT_COUNTER: 'total_deposit_counter',
};

export const columns = [
	{
		Header: 'campaigns.table.headers.campaign_type',
		accessor: COLUMNS_ACCESSORS.CAMPAIGN_TYPE,
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.created',
		accessor: COLUMNS_ACCESSORS.CREATED,
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.started',
		accessor: 'campaign_duration_from',
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.ended',
		accessor: 'campaign_duration_to',
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.total_users',
		accessor: COLUMNS_ACCESSORS.TOTAL_DEPOSIT_COUNTER,
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.total_usage',
		accessor: 'total_used_counter',
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.total_deposits',
		accessor: 'total_deposit_amount',
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.total_spent',
		accessor: 'total_spend_amount',
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
];
