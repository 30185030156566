import { useEffect } from 'react';
import { useQuery } from 'react-query';
import services from 'services/services';
import ls from 'localstorage-slim';

// Import helpers
import { LOCAL_STORAGE_RATING_REASONS, RATING_REASONS_QUERY } from 'helpers';

const useRatingReasons = ({ enabled = true, rating_type = '' }) => {
	const path = '/v2/booking/rating-reasons';

	const localStorageRatingReasons = ls.get(LOCAL_STORAGE_RATING_REASONS);

	const { data, isFetching, isFetched } = useQuery(
		`${RATING_REASONS_QUERY}_${rating_type}`,
		async () => await services.post(path, { rating_type }),
		{
			refetchOnWindowFocus: false,
			enabled: !localStorageRatingReasons && enabled,
		}
	);

	const fetchedData = data ? data.data.data : [];

	useEffect(() => {
		if (!localStorageRatingReasons && isFetched && fetchedData.length) {
			ls.set(LOCAL_STORAGE_RATING_REASONS, fetchedData, {
				ttl: 7 * 24 * 60 * 60,
			});
		}
		// eslint-disable-next-line
	}, [localStorageRatingReasons, isFetched, fetchedData]);

	const result = localStorageRatingReasons || fetchedData;

	return { data: result, isFetching };
};

export default useRatingReasons;
