import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';
import { array, string } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from './DriversSelect.styles';

export const DriversSelect = ({ drivers, deliveryType }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Field
			label={t(
				`bookings.preview_booking.car.assign_driver_for_${deliveryType}`
			)}
			variant="outlined"
			margin="dense"
			component={SelectFieldAdapter}
			name={deliveryType}
			fullWidth
			required
		>
			{drivers.map(({ uuid, name, busy_at: busyAt }) => (
				<MenuItem key={uuid} value={uuid}>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>{name}</Grid>
						{!busyAt ? (
							<Grid item className={classes.available}>
								{t('bookings.preview_booking.car.available')}
							</Grid>
						) : (
							<Grid item className={classes.errorMessage}>{`${t(
								'bookings.preview_booking.car.has_delivery_at'
							)} ${busyAt}`}</Grid>
						)}
					</Grid>
				</MenuItem>
			))}
		</Field>
	);
};

DriversSelect.propTypes = {
	deliveryType: string,
	drivers: array,
};
