import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	icon: {
		fill: 'black',
	},
	button: {
		padding: 0,
	},
}));

export default useStyles;
