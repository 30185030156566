import React from 'react';
import { Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';

// Import styles
import useStyles from './styles';

export const Hamburger = ({ onClick, ...props }) => {
	const classes = useStyles();

	return (
		<Hidden lgUp {...props}>
			<IconButton color="inherit" className={classes.button} onClick={onClick}>
				<MenuIcon className={classes.icon} />
			</IconButton>
		</Hidden>
	);
};

Hamburger.propTypes = {
	onClick: PropTypes.func,
};
