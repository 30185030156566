import { useRef, useState } from 'react';
import ExcelJS from 'exceljs';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';

// Import services
import myAxios from 'services/myAxiosFactory';

export const useImport = (importDataUrl) => {
	const [isLoading, setLoading] = useState(false);
	const hiddenFileInput = useRef(null);
	const { message } = useMessage();
	const { t } = useTranslations();

	const handleOnButtonClick = () => {
		hiddenFileInput.current.click();
	};

	const handleOnInputChange = async (event) => {
		setLoading(true);
		try {
			const fileUploaded = event.target.files[0];

			let formData = new FormData();

			const reader = new FileReader();
			let data;
			reader.onload = function () {
				data = reader.result;
				const workbook = new ExcelJS.Workbook();

				workbook.xlsx.load(data).then(async (workbook) => {
					const worksheet = workbook.getWorksheet();
					worksheet.columns = worksheet.columns.map((column) => ({
						...column,
						hidden: false,
					}));

					const fileContent = await workbook.xlsx.writeBuffer();
					const file = new File([fileContent], 'Excel.xlsx');
					formData.append('file', file);

					await myAxios().post(importDataUrl, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					});
					message.success(t('common.messages.successfully_done'));
					setLoading(false);
				});
			};

			reader.readAsArrayBuffer(fileUploaded);
		} catch {
			message.error(t('common.messages.error_message'));
			setLoading(false);
		}
	};

	return {
		handleOnInputChange,
		handleOnButtonClick,
		hiddenFileInput,
		isLoading,
	};
};
