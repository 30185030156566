import services from 'services/services';
import * as types from 'store/types';
import { fetchModelFormResourcesUrl, submitModelFormUrl } from 'store/paths';

// Import helpers
import { catchError } from 'store/actions/helpers';
import { convertData } from './helpers';

export const fetchModelFormResources = ({
	itemId,
	isEdit,
	cancelToken,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.MODEL_FORM_FETCH_DATA_LOADING,
			payload: true,
		});

		const { data } =
			isEdit &&
			(await services.get(fetchModelFormResourcesUrl(itemId), cancelToken));

		dispatch({
			type: types.MODEL_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: data || {},
			},
		});
	} catch (error) {
		catchError({ error, dispatch, type: types.MODEL_FORM_FETCH_DATA_ERROR });
	}
};

export const submitModelForm = ({
	values,
	itemId,
	isEdit,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		const convertedData = convertData(values);

		await services.post(submitModelFormUrl(isEdit, itemId), convertedData);

		callbackSuccess();
	} catch (error) {
		const errors = error.response.data.errors;
		if (errors['auto.model_id']) {
			errors['model_name.en'] = errors['auto.model_id'];
		}
		error.response && errorCallback(errors);
	}
};
