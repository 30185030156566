import { useState } from 'react';

const useAddCampaignDropdown = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOnClose = () => {
		setAnchorEl(null);
	};
	const handleOnClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	return { anchorEl, onClick: handleOnClick, onClose: handleOnClose };
};

export default useAddCampaignDropdown;
