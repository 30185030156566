import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// helpers
import { URLS } from 'components/routes';

const usePublicHolidayForm = () => {
	const [showAlert, setShowAlert] = useState(false);

	const history = useHistory();

	const handleToggleAlert = () => setShowAlert((prevState) => !prevState);

	const callbackSuccess = () => {
		handleToggleAlert();
		history.push(URLS.publicHolidaysUrl);
	};

	return {
		showAlert,
		callbackSuccess,
		handleToggleAlert,
	};
};

export default usePublicHolidayForm;
