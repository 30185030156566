import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		minHeight: 'calc(100vh - (85px + 80px))',
		padding: theme.spacing(3),
	},
	errorContainer: {
		padding: theme.spacing(3),
	},
	card: {
		padding: theme.spacing(4),
	},
	circularBarContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '400px',
	},
	listRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.gray.light,
	},
}));
