import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { CUSTOMER_DRIVER_LICENSE_QUERY } from 'helpers';

export const useCustomerDriverLicense = ({ uuid, enabled }) => {
	const path = `/v2/auth/license/scanned/${uuid}/show`;

	const { data, isFetching, isError, error } = useQuery(
		[CUSTOMER_DRIVER_LICENSE_QUERY, uuid],
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data?.data.data;

	return { data: fetchedData, isFetching, isError, error };
};
