import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getCampaignType } from '../helpers';
import { campaignsFormSelector } from 'store/selectors';

const useCampaignsForm = () => {
	const { type } = useParams();

	const { item } = useSelector(campaignsFormSelector);

	const possibleType = type || item?.details?.campaign_type;

	const campaignType = getCampaignType(possibleType);

	return { campaignType };
};

export default useCampaignsForm;
