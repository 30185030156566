export const fetchCorporateOverviewUrl = ({ corporateUuid, queryParams }) =>
	`/dashboard/b2b/corporate/${corporateUuid}/customer${queryParams}`;

export const assignCustomerToCorporateUrl = (corporateUuid) =>
	`/dashboard/b2b/corporate/${corporateUuid}/customer/assign`;

export const addNewCustomerToCorporateUrl = (corporateUuid) =>
	`/dashboard/b2b/corporate/${corporateUuid}/customer`;

export const updateCorporationCustomerUrl = ({ corporateUuid, customerUuid }) =>
	`/dashboard/b2b/corporate/${corporateUuid}/customer/${customerUuid}`;

export const setCustomerLimitUrl = (corporateUuid) =>
	`/dashboard/b2b/corporate/${corporateUuid}/limit`;
