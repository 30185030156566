// Import helpers
import { AR, EN } from 'helpers';
import services from 'services/services';

// Import store
import { submitTrainStationFormUrl } from 'store/paths';

const convertPayload = ({ values }) => {
	return {
		locales: [
			{ name: values.name_en, locale: EN },
			{ name: values.name_ar, locale: AR },
		],
		location: values.location,
		active: values.active,
		city_uuid: values.city_uuid,
	};
};

export const submitTrainStationForm = async ({
	successCallback,
	errorCallback,
	values,
	uuid,
}) => {
	try {
		const payload = convertPayload({ values });

		await services[uuid ? 'put' : 'post'](
			submitTrainStationFormUrl({ uuid }),
			payload
		);
		successCallback();
	} catch (errors) {
		errorCallback(errors);
	}
};
