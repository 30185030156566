// Import components
import { AddNewSettlementDialog, Filters } from '../../components';

// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

export const TOOLBAR_CONTENT = () => [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Filters,
		customKey: 'filters',
	},
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: AddNewSettlementDialog,
		customKey: 'add_new_settlement',
	},
];
