import { useSnackbar } from 'notistack';

const useMessage = () => {
	const { enqueueSnackbar } = useSnackbar();

	const message = {
		success: (content, options = {}) =>
			enqueueSnackbar(content, {
				variant: 'success',
				...options,
			}),
		error: (content, options = {}) =>
			enqueueSnackbar(content, {
				variant: 'error',
				...options,
			}),
		warning: (content, options = {}) =>
			enqueueSnackbar(content, {
				variant: 'warning',
				...options,
			}),
		info: (content, options = {}) =>
			enqueueSnackbar(content, {
				variant: 'info',
				...options,
			}),
	};

	return { message };
};

export default useMessage;
