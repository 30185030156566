import { TABLE_ACTIONS_ACCESSORS } from 'helpers';

export const COLUMNS_ACCESSORS = {
	PHONE: 'phone',
};

const { DELETE } = TABLE_ACTIONS_ACCESSORS;

export const columns = [
	{
		Header: 'customers.whitelist.table.phone_number',
		accessor: COLUMNS_ACCESSORS.PHONE,
	},
	{
		Header: 'table.actions.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
