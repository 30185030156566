import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import utilities
import { submitExperienceSharedForm } from 'store/actions';
import { experienceSharedFormSelector } from 'store/selectors';

// Import components
import { FormRPC } from 'components/utilities';
import { ExperienceSharedFormFields } from './components';

// Import helpers
import { INITIAL_VALUES } from './helpers';

// Import styles
import { useStyles } from './styles';
import { URLS } from 'components/routes';

const ExperienceSharedForm = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={() => () => {}}
				submitFormAction={submitExperienceSharedForm}
				isCardLayout={false}
				goBackPath={URLS.campaignsUrl}
				store={experienceSharedFormSelector}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item xs={12} container spacing={4}>
							<ExperienceSharedFormFields />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default ExperienceSharedForm;
