import React from 'react';
import { string } from 'prop-types';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utils
import {
	useCommonFields,
	useProfile,
	useTranslations,
} from 'components/utilities';

// Import helpers
import { FIELDS } from './helpers';

const BankInfo = (props) => {
	const { className, section, ...rest } = props;
	const { renderFields } = useCommonFields();

	const { t } = useTranslations();

	const { isAdmin } = useProfile();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('company.form.bank_info.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS({ section, isAdmin }).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

BankInfo.propTypes = {
	className: string,
	section: string.isRequired,
};

export default BankInfo;
