import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import utils
import { useCarImagesForBookingDelivery } from 'queries';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useCarImagesDialog = () => {
	const { uuid } = useSelector(previewBookingSelector);

	const { data, isFetching, isError } = useCarImagesForBookingDelivery(uuid);

	const getButtonVisibilityState = (data) =>
		data &&
		Object.values(data)
			.filter((deliveryType) => !_.isArray(deliveryType))
			.map(({ images }) => [...images].flat())
			.flat()
			.map(({ image_url }) => image_url)
			.some((url) => url !== '');

	const isButtonVisible = getButtonVisibilityState(data);

	const transKey =
		'bookings.preview_booking.delivery_information.car_images_dialog';

	return {
		isButtonVisible,
		isFetching,
		isError,
		transKey,
		data,
	};
};
