import { t } from 'i18next';

export const getPaymentDay = (daysToPayment, isNextPayment = false) => {
	const transKey = 'customer_balance.table.content';

	switch (daysToPayment) {
		case null:
			return t('common.completed');

		case 0:
			return t(
				`${transKey}.${isNextPayment ? 'next_payment_due' : 'payment_due'}`,
				{
					day: t('common.date_range_labels.today'),
				}
			);

		case 1:
			return t(
				`${transKey}.${
					isNextPayment ? 'next_payment_due_in' : 'payment_due_in'
				}`,
				{
					days: `${daysToPayment} ${t('common.day')}`,
				}
			);

		default:
			return t(
				`${transKey}.${
					isNextPayment ? 'next_payment_due_in' : 'payment_due_in'
				}`,
				{
					days: `${daysToPayment} ${t('common.days')}`,
				}
			);
	}
};
