import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-final-form';

export const useFormContent = ({ allExpandedData, selectedChildren }) => {
	const { change } = useForm();
	const [bookingsIds, setBookingsIds] = useState([]);

	const checkTargetChildChecked = (row) =>
		_.find(selectedChildren, { item_uuid: row.item_uuid });

	useEffect(() => {
		setBookingsIds(
			allExpandedData?.subRows
				?.filter((row) => !row.is_settled && row.conditions_met)
				.map((row) => ({
					...row,
					checked: !!checkTargetChildChecked(row),
					id: row.booking_individual_number,
				})) || []
		);
		// eslint-disable-next-line
	}, [selectedChildren.length]);

	const handleAddAndRemoveIds = ({ id, checked }) => {
		const bookingIdsCopy = [...bookingsIds];

		const index = _.findIndex(bookingIdsCopy, { id });
		bookingIdsCopy.splice(index, 1, {
			...bookingIdsCopy[index],
			checked,
		});

		setBookingsIds(bookingIdsCopy);
	};

	useEffect(() => {
		if (bookingsIds.length) {
			const checkedBookings = bookingsIds.filter(({ checked }) => checked);
			change(
				'uuids',
				checkedBookings.map(({ item_uuid }) => item_uuid)
			);
			change(
				'total_amount',
				_.reduce(
					checkedBookings,
					(result, value) => {
						return result + value['earnings'];
					},
					0
				)
			);
		}
		// eslint-disable-next-line
	}, [bookingsIds]);

	return {
		bookingsIds,
		handleAddAndRemoveIds,
	};
};
