import { useState } from 'react';
import { rewardsSettlementBookings } from 'store/actions';

// import helpers
import { convertApiFormErrors } from 'helpers';

// Import components
import { useTableContextProvider } from 'components/context';
import { useTableActionsProvider } from 'views/RewardsSettlement/components/TableActionsProvider';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';

export const useSettleForm = ({ toggle }) => {
	const { message } = useMessage();
	const { t } = useTranslations();

	const [error, setError] = useState([]);
	const { fetchData } = useTableContextProvider();
	const { setAllExpandedData, setSelectedChildren, setSelectedParent } =
		useTableActionsProvider();

	const handleSettlementSubmit = async ({ amount, file, uuids }) => {
		try {
			const body = {
				amount,
				transaction_file_path: file.file_path,
				employee_performance_item_uuids: uuids,
			};

			await rewardsSettlementBookings(body);
			setAllExpandedData([]);
			setSelectedChildren([]);
			setSelectedParent(null);
			toggle();
			message.success(t(`rewards_settlement.successfully_settled`));
			await fetchData();
		} catch (error) {
			setError(convertApiFormErrors(error?.data?.errors));
		}
	};

	return {
		onSubmit: handleSettlementSubmit,
		error,
	};
};
