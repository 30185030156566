import * as types from 'store/types';
import services from 'services/services';
import {
	fetchTerminalFormResourcesUrl,
	submitTerminalFormUrl,
} from 'store/paths';

// Import helpers
import { catchError } from '../helpers';
import { INITIAL_VALUES } from 'views/Airports/Terminals/EditTerminals/helpers';

// ******************** Fetch terminal form ********************
export const fetchTerminalFormResources = ({
	itemId,
	cancelToken,
	isEdit,
}) => async (dispatch) => {
	try {
		dispatch({ type: types.TERMINALS_FORM_FETCH_DATA_LOADING });
		let result;
		if (isEdit) {
			const { data } = await services.get(
				fetchTerminalFormResourcesUrl(itemId),
				cancelToken
			);
			result = data.data;
		} else {
			result = Object.assign({}, INITIAL_VALUES);
		}
		dispatch({
			type: types.TERMINALS_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: result || {},
			},
		});
	} catch (error) {
		catchError({
			error,
			dispatch,
			type: types.TERMINALS_FORM_FETCH_DATA_ERROR,
		});
	}
};

// ******************** Submit terminal form ********************
export const submitTerminalForm = ({
	airportId,
	values,
	itemId,
	isEdit = true,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		await services.post(
			submitTerminalFormUrl(itemId, airportId, isEdit),
			values
		);
		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
