// ********************  table  ********************

export const fetchFaqsUrl = (queryParams) => `/v2/faq/list${queryParams}`;
export const deleteFaqUrl = (id) => `/v2/faq/${id}/remove`;
export const updateActiveFaqUrl = (id, isActive) =>
	`/v2/faq/${id}/${isActive ? 'deactivate' : 'activate'}`;

// ********************  form  ********************

export const fetchFaqFormResourcesUrl = (itemId) => `/v2/faq/${itemId}/show`;
export const submitFaqFormUrl = (itemId) =>
	`/v2/faq/${itemId ? `${itemId}/update` : 'create'}`;
