import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translation
import { useTranslations } from 'components/utilities';

// Import components
import { FileFieldAdapter } from 'components/elements';

const SliderImage = ({ className, section, ...rest }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('sliders.form.image.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							previewFileState={`${section}`}
							component={FileFieldAdapter}
							name={`${section}.file_path`}
							fileNameField={`${section}.file_name`}
							disabled={submitting}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

SliderImage.propTypes = {
	section: string.isRequired,
	className: string,
};

export default SliderImage;
