import React from 'react';
import {
	CardContent,
	CardHeader,
	Divider,
	Card,
	Grid,
} from '@material-ui/core';

// Import utils
import { useCommonFields } from 'components/utilities';

// Import helpers
import { useDetails } from './useDetails';

export const Details = () => {
	const { renderFields } = useCommonFields();
	const { fields, t } = useDetails();

	return (
		<Card>
			<CardHeader title={t('users.form.details.driver_title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					{fields.map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
