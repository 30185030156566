import React from 'react';
import { bool, string } from 'prop-types';
import { Typography as MuiTypography, Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

// Import components
import ImageFromApiPreview from '../../Previews/ImageFromApiPreview';

// Import utilities
import { useToggle } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

const ImagePreviewLabelAdapter = ({
	imgUrl,
	label,
	labelWidth = '105px',
	justifySpaceBetween = false,
}) => {
	const classes = useStyles(justifySpaceBetween);

	const { on, toggle } = useToggle();

	return (
		<div className={classes.root}>
			<MuiTypography
				style={{ minWidth: labelWidth }}
				component="p"
				className={classes.label}
			>
				{label}
			</MuiTypography>
			<>
				<Button
					onClick={toggle}
					color="primary"
					variant="contained"
					size="small"
				>
					<DescriptionIcon />
				</Button>
				<ImageFromApiPreview imgUrl={imgUrl} on={on} close={toggle} />
			</>
		</div>
	);
};

ImagePreviewLabelAdapter.propTypes = {
	imgUrl: string.isRequired,
	label: string.isRequired,
	labelWidth: string,
	justifySpaceBetween: bool,
};

export default ImagePreviewLabelAdapter;
