import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

// Import components
import { CompensationPreviewItem } from './components';

// Import translations
import { useTranslations } from 'components/utilities';

export const CompensationsPreviewList = () => {
	const { compensations } = useSelector(previewBookingSelector);

	const { t } = useTranslations();

	const sectionName = 'bookings.customer_compensation';

	if (!compensations) {
		return (
			<Typography>{t(`${sectionName}.compensation_not_provided`)}</Typography>
		);
	}

	return (
		<Grid container spacing={2}>
			{compensations.map((compensation) => (
				<CompensationPreviewItem
					key={compensation.uuid}
					compensation={compensation}
				/>
			))}
		</Grid>
	);
};
