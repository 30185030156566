import { format } from 'date-fns';

// Import services
import services from 'services/services';
import myAxios from 'services/myAxiosFactory';

// Import store
import * as types from 'store/types';
import {
	fetchUrgentAssistanceListUrl,
	submitCustomerRatingFormUrl,
	updateSupplierCommentsUrl,
	submitOfficeRatingFormUrl,
	fetchBookingPreviewUrl,
	updateAdminCommentUrl,
	updateRatingUrl,
	assignDriverUrl,
	driverAvailabilityUrl,
	changeDriverUrl,
	settleOfflineUrl,
	deleteBookingRateUrl,
	earlyCancellationUrl,
	extendCorporateBookingUrl,
	cancelCorporateBookingUrl,
} from 'store/paths';
import { catchError } from 'store/actions/helpers';

// Import helpers
import {
	OFFICE_RATING_EMPTY_FIELDS,
	CUSTOMER_RATING_EMPTY_FIELDS,
	CUSTOMER_COMPENSATION_EMPTY_FIELDS,
	getBookingPayload,
	getOfflineSettlementFormData,
	convertEarlyCancellationValues,
} from './helpers';
import { BOOKING_COMPENSATION_METHODS, DEFAULT_DATE_FORMAT } from 'helpers';

export const fetchBookingPreview =
	({ id, cancelToken, isCorporateCoordinator, getBookingOnly = false }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_LOADING,
				payload: true,
			});

			const bookingUrl = fetchBookingPreviewUrl(id);
			const bookingDataPromise = services.get(bookingUrl, cancelToken);

			const billingUrl = `v2/booking/${id}/billing`;
			const billingDataPromise =
				!getBookingOnly && services.get(billingUrl, cancelToken);

			const urgentReasonsPromise =
				!isCorporateCoordinator &&
				!getBookingOnly &&
				services.get(fetchUrgentAssistanceListUrl(id), cancelToken);

			const values = await Promise.all([
				bookingDataPromise,
				billingDataPromise,
				urgentReasonsPromise,
			]);

			const [
				{ data: bookingData },
				{ data: billingData },
				{ data: urgentReasonsData },
			] = values;

			const urgentReasons =
				!isCorporateCoordinator && !getBookingOnly && urgentReasonsData.data;
			const billings = !getBookingOnly && billingData;

			const payload = {
				...bookingData.data,
			};

			if (!isCorporateCoordinator) payload.urgentReasons = urgentReasons;
			if (!getBookingOnly) payload.billings = billings;

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
				payload,
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_ERROR,
			});
		}
	};

export const fetchOfficeRatingFormResources =
	() => async (dispatch, getState) => {
		const booking = getState().bookings.preview.item;

		let item = { ...OFFICE_RATING_EMPTY_FIELDS };

		const officeRating = booking?.ratings?.office || null;

		if (officeRating) {
			item = {
				...item,
				...officeRating,
				rating_reason_uuid: officeRating?.rating_reasons?.[0] || '',
			};
		}

		dispatch({
			type: types.BOOKINGS_PREVIEW_OFFICE_RATING_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item,
			},
		});
	};

export const submitOfficeRatingForm =
	({ values, callbackSuccess, errorCallback }) =>
	async (dispatch, getState) => {
		const booking = getState().bookings.preview.item;

		try {
			const { data } = await services.put(
				submitOfficeRatingFormUrl(booking.uuid),
				values
			);

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
				payload: data.data,
			});

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const fetchCustomerRatingFormResources =
	() => async (dispatch, getState) => {
		const booking = getState().bookings.preview.item;

		let item = {
			...CUSTOMER_RATING_EMPTY_FIELDS,
		};

		const customerRating =
			booking.ratings && booking.ratings.customer
				? booking.ratings.customer
				: null;

		if (customerRating) {
			item = {
				...item,
				...customerRating,
			};
		}

		dispatch({
			type: types.BOOKINGS_PREVIEW_CUSTOMER_RATING_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item,
			},
		});
	};

export const submitCustomerRatingForm =
	({ values, callbackSuccess, errorCallback }) =>
	async (dispatch, getState) => {
		const booking = getState().bookings.preview.item;

		try {
			const { data } = await services.put(
				submitCustomerRatingFormUrl(booking.uuid),
				values
			);

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
				payload: data.data,
			});

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const fetchCustomerCompensationFormResources =
	() => async (dispatch, getState) => {
		const booking = getState().bookings.preview.item;

		let item = {
			...CUSTOMER_COMPENSATION_EMPTY_FIELDS,
		};

		if (booking.compensation) {
			item = {
				...booking.compensation,
			};
		}

		dispatch({
			type: types.BOOKINGS_PREVIEW_CUSTOMER_COMPENSATION_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item,
			},
		});
	};

export const submitCustomerCompensationForm =
	({
		values,
		callbackSuccess,
		errorCallback,
		location,
		compensation,
		booking,
	}) =>
	async (dispatch) => {
		try {
			const options = { booking, compensation, values, location };
			const payload = await getBookingPayload(options);

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
				payload,
			});

			callbackSuccess();
		} catch (error) {
			errorCallback(error);
		}
	};

export const updateRating =
	({ id, rating, description }) =>
	async (dispatch) => {
		try {
			const options = {
				type: 'office',
				rating,
				description,
			};

			const { data } = await services.put(updateRatingUrl(id), options);

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
				payload: data.data,
			});
		} catch (error) {
			const defaultError = { message: '' };
			const response = error.response?.data ?? defaultError;

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_ERROR,
				payload: response,
			});
		}
	};

export const updateSupplierComments =
	({ id, content, discuss_type }) =>
	async (dispatch) => {
		try {
			const options = {
				type: 'office',
				content,
				discuss_type,
			};

			const { data } = await services.put(
				updateSupplierCommentsUrl(id),
				options
			);

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
				payload: data.data,
			});
		} catch (error) {
			const defaultError = { message: '' };
			const response = error.response?.data ?? defaultError;

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_ERROR,
				payload: response,
			});
		}
	};

export const updateAdminComment =
	({ id, comment }) =>
	async (dispatch) => {
		try {
			const { data } = await services.put(updateAdminCommentUrl(id), {
				comment,
			});

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
				payload: data.data,
			});
		} catch (error) {
			const defaultError = { message: '' };
			const response = error.response?.data ?? defaultError;

			dispatch({
				type: types.BOOKINGS_PREVIEW_FETCH_DATA_ERROR,
				payload: response,
			});
		}
	};

export const setBookingPreviewItem = ({ booking, dispatch }) => {
	dispatch({
		type: types.BOOKINGS_PREVIEW_FETCH_DATA_SUCCES,
		payload: booking,
	});
};

export const resetBookingPreviewItem = ({ dispatch }) => {
	dispatch({
		type: types.BOOKINGS_PREVIEW_FETCH_DATA_LOADING,
	});
};

// DRIVER DELIVERY

export const assignDriver = async ({
	driverUuid,
	bookingUuid,
	deliveryType,
}) => {
	const body = {
		booking_uuid: bookingUuid,
		delivery_type: deliveryType,
	};

	return await services.post(assignDriverUrl(driverUuid), body);
};

export const changeDriver = async ({ driverUuid, deliveryUuid }) =>
	await services.post(changeDriverUrl(deliveryUuid), {
		driver_uuid: driverUuid,
	});

export const getDriverAvailability = async ({
	driverUuid,
	bookingUuid,
	deliveryType,
}) => {
	const params = { booking_uuid: bookingUuid, delivery_type: deliveryType };

	return await myAxios().get(driverAvailabilityUrl(driverUuid), {
		params,
	});
};

export const deleteBookingRate =
	({ id }) =>
	async () => {
		await services.delete(deleteBookingRateUrl(id));
	};

// Subscription
export const settleOffline = async ({
	successCallback,
	errorCallback,
	values,
	subscriptionUuid,
	payment,
}) => {
	try {
		const formData = getOfflineSettlementFormData({ values, payment });

		await myAxios().post(settleOfflineUrl(subscriptionUuid), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};

export const earlyCancellation = async ({
	successCallback,
	errorCallback,
	bookingUuid,
	values,
}) => {
	try {
		const formData = convertEarlyCancellationValues(values);

		await myAxios().post(earlyCancellationUrl(bookingUuid), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};

export const extendCorporateBooking = async ({
	successCallback,
	errorCallback,
	bookingUuid,
	values,
}) => {
	try {
		const convertedData = {
			...values,
			drop_date: format(new Date(values.drop_date), DEFAULT_DATE_FORMAT),
		};

		await services.post(extendCorporateBookingUrl(bookingUuid), convertedData);

		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};

export const cancelCorporateBooking = async ({
	successCallback,
	errorCallback,
	bookingUuid,
	values,
}) => {
	try {
		const convertedData = {
			...values,
			compensation: {
				method: BOOKING_COMPENSATION_METHODS.CORPORATE_LIMIT,
			},
		};

		await services.put(cancelCorporateBookingUrl(bookingUuid), convertedData);

		successCallback();
	} catch (error) {
		errorCallback(error);
	}
};
