import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import useUrgentAttentionWarningCard from './useUrgentAttentionWarningCard';
import { AlertMessage } from 'components/elements';

// Import translation
import { useTranslations } from 'components/utilities';
import TimeElapsed from './components/TimeElapsed';

const UrgentAttentionWarningCard = () => {
	const { t } = useTranslations();

	const { isWarningVisible, warningMessage } = useUrgentAttentionWarningCard();

	if (!isWarningVisible) {
		return null;
	}

	return (
		<Grid item xs={12}>
			<AlertMessage
				title={t('bookings.preview_booking.urgent_attention.title')}
				description={warningMessage}
				variant="error"
				action={<TimeElapsed />}
			/>
		</Grid>
	);
};

export default UrgentAttentionWarningCard;
