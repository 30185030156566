export const COLUMNS_ACCESSORS = {
	CANCELLATION_DATE: 'cancellation_date',
	CUSTOMER_NAME: 'customer_name',
	BOOKING_ID: 'booking_id',
	PICK_DATE: 'pick_date',
	ACTIONS: 'actions',
	ACTION: 'action',
	TOPIC: 'topic',
	NOTE: 'note',
};

export const adminColumns = [
	{
		Header: 'bookings.table.headers.topic',
		accessor: COLUMNS_ACCESSORS.TOPIC,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: 'cancel_description',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.amount',
		accessor: 'price',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.company_name',
		accessor: 'company_name',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.cancellation_date',
		accessor: COLUMNS_ACCESSORS.CANCELLATION_DATE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'last_booking_office_rating',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.security_deposit',
		accessor: 'security_deposit',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.action',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];

export const companyManagerColumns = [
	{
		Header: 'bookings.table.headers.cancellation_reason',
		accessor: 'cancellation_reason',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.compensation_amount',
		accessor: 'price',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.office',
		accessor: 'office_name',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.office_rating',
		accessor: 'office_rating',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.action',
		accessor: COLUMNS_ACCESSORS.ACTION,
		disableSortBy: true,
	},
];
