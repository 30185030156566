export const INITIAL_VALUES = {
	header_title: {
		en: '',
		ar: '',
	},
	header_content: {
		en: '',
		ar: '',
	},
	filters: {
		vehicle_type_uuid: '',
		vehicle_maker_uuid: '',
		vehicle_model_uuid: '',
		default_location: {
			name: '',
			formatted_name: '',
			place_id: '',
			latitude: '',
			longitude: '',
		},
	},
	images: {
		logo: {
			file_path: '',
			file_name: '',
		},
		banner: {
			file_path: '',
			file_name: '',
		},
	},
	seo_title: {
		en: '',
		ar: '',
	},
	seo_description: {
		en: '',
		ar: '',
	},
	seo_keywords: {
		en: '',
		ar: '',
	},
	is_active: true,
};
