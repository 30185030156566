import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ls from 'localstorage-slim';
import { isObject } from 'lodash';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { useInitiateContractProvider } from '../../context';
import { saveContract } from 'store/actions/contract';

// Import helpers
import { INITIATE_CONTRACT_STEPS, LOCAL_STORAGE_CONTRACT } from 'helpers';

export const useDataForm = () => {
	const { t } = useTranslations();
	const [apiErrors, setApiErrors] = useState([]);
	const { message } = useMessage();

	const { id: bookingUuid } = useParams();

	const { setStep, initialValues } = useInitiateContractProvider();

	const [isLoading, setIsLoading] = useState(false);

	const { contractNumber, bookingUuid: savedBookingUuid } =
		ls.get(LOCAL_STORAGE_CONTRACT) || {};

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		setIsLoading(true);

		const saveContractOptions = {
			values,
			bookingUuid,
			callbackSuccess: (localStorageData) => {
				message.success(t('common.messages.successfully_saved'));
				setIsLoading(false);
				ls.set(LOCAL_STORAGE_CONTRACT, localStorageData);

				setStep(INITIATE_CONTRACT_STEPS.PRIVACY_POLICY);
			},
			errorCallback: (error) => {
				const isErrorString = typeof error === 'string';
				message.error(
					isErrorString ? error : t('common.messages.error_message')
				);
				setIsLoading(false);
				const isErrorsObject = isObject(error) && !isErrorString;
				if (error && isErrorsObject) {
					apiErrors = Object.values(error).flat();
				}
			},
		};

		await saveContract(saveContractOptions);

		apiErrors && setApiErrors(apiErrors);
	};

	useEffect(() => {
		if (contractNumber && bookingUuid === savedBookingUuid) {
			setStep(INITIATE_CONTRACT_STEPS.PRIVACY_POLICY);
		}
		//eslint-disable-next-line
	}, [contractNumber]);

	return {
		initialValues,
		handleOnSubmit,
		isLoading,
		apiErrors,
	};
};
