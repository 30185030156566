import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { Export, SettleDialog } from '../../components';

export const InnerToolbar = () => {
	return (
		<Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
			<Grid item>
				<SettleDialog />
			</Grid>
			<Grid item>
				<Export />
			</Grid>
		</Grid>
	);
};
