import React from 'react';
import { Collapse, TableCell, TableRow } from '@material-ui/core';
import { bool, number } from 'prop-types';
import { fetchRewardsSettlementList } from 'store/actions';

// Import helpers
import { columns } from '../../columns';

// Import component
import { SubTableProvider } from 'components/context';
import { SubTable } from 'components/elements';
import { TableBodyRow } from './components';

// import utils
import { useTableActionsProvider } from '../TableActionsProvider';

export const EmployeeDetails = ({ isExpanded, colSpan }) => {
	const { selectedParent } = useTableActionsProvider();

	return (
		<TableRow>
			<TableCell style={{ padding: 0 }} colSpan={colSpan}>
				<Collapse in={isExpanded} unmountOnExit>
					<SubTableProvider
						columns={columns}
						fetchDataTableAction={(options) =>
							fetchRewardsSettlementList({
								...options,
								employeeUuid: selectedParent,
							})
						}
					>
						<SubTable>
							{({ row }) => <TableBodyRow key={row.id} row={row} />}
						</SubTable>
					</SubTableProvider>
				</Collapse>
			</TableCell>
		</TableRow>
	);
};

EmployeeDetails.propTypes = {
	isExpanded: bool.isRequired,
	colSpan: number,
};

EmployeeDetails.defaultProps = {
	colSpan: 1,
};
