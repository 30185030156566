export const INITIAL_VALUES = {
	location: {
		address: '',
		city: '',
		country: '',
		lat: '',
		lng: '',
		place_id: '',
		delivery: {
			address: '',
			lat: '',
			lng: '',
		},
	},
	city_uuid: null,
	active: null,
	name_en: '',
	name_ar: '',
};

export const convertTrainStation = ({ trainStationData }) => {
	const { city, location, active, locales } = trainStationData || {};

	const convertedLocales = locales?.reduce(
		(acc, { locale, name }) => ({ ...acc, [`name_${locale}`]: name }),
		{}
	);

	return {
		...convertedLocales,
		city_uuid: city?.uuid,
		location,
		active,
	};
};
