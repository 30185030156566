import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export const FilterIcon = (props) => (
	<SvgIcon viewBox="0 0 20 20" {...props}>
		<path
			d="M8.60851 13.8274H3.35791"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.9504 5.75029H16.201"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.27195 5.70521C7.27195 4.6255 6.39015 3.75 5.30266 3.75C4.21517 3.75 3.33337 4.6255 3.33337 5.70521C3.33337 6.78492 4.21517 7.66042 5.30266 7.66042C6.39015 7.66042 7.27195 6.78492 7.27195 5.70521Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.6667 13.7948C16.6667 12.7151 15.7856 11.8396 14.6981 11.8396C13.6099 11.8396 12.7281 12.7151 12.7281 13.7948C12.7281 14.8745 13.6099 15.75 14.6981 15.75C15.7856 15.75 16.6667 14.8745 16.6667 13.7948Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
