import { TABLE_ACTIONS_ACCESSORS } from 'helpers/variables';

const { EDIT, DELETE } = TABLE_ACTIONS_ACCESSORS;

export const columns = [
	{
		Header: 'special_offer.table.headers.company_name',
		accessor: 'company_name',
	},
	{
		Header: 'special_offer.table.headers.label_en',
		accessor: 'label_en',
		disableSortBy: true,
	},
	{
		Header: 'special_offer.table.headers.label_ar',
		accessor: 'label_ar',
		disableSortBy: true,
	},
	{
		Header: 'special_offer.table.headers.position',
		accessor: 'position',
	},
	{
		Header: 'special_offer.table.headers.date_from',
		accessor: 'date_from',
	},
	{
		Header: 'special_offer.table.headers.date_to',
		accessor: 'date_to',
	},
	{
		Header: 'table.headers.edit',
		accessor: EDIT,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.delete',
		accessor: DELETE,
		disableSortBy: true,
	},
];
