import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slide,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { submitCarsForm } from 'store/actions';

// Import helpers
import { convertApiFormErrors } from 'helpers/form';

// Import validation
import validation from './validation';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { useTableContextProvider } from 'components/context';
import { ButtonProgress } from 'components/elements';
import { TextFieldAdapter } from 'components/elements';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const EditCarsForm = ({ open, closeModal, quantity, uuid }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const dispatch = useDispatch();

	const { fetchData } = useTableContextProvider();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};
		const options = {
			values,
			itemId: uuid,
			callbackSuccess: () => {
				closeModal();
				fetchData();
			},
			errorCallback: (errors) => (apiErrors = convertApiFormErrors(errors)),
		};
		await submitCarsForm(options)(dispatch);
		return apiErrors;
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth="sm"
			open={open}
			TransitionComponent={Transition}
			onClose={closeModal}
		>
			<DialogTitle>{t('cars.form.details.modal_title')}</DialogTitle>

			<Form
				onSubmit={handleOnSubmit}
				validate={validation}
				initialValues={{ quantity }}
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit}>
						<DialogContent>
							<Field
								fullWidth
								component={TextFieldAdapter}
								type="number"
								label={t('cars.form.details.available_cars')}
								name="quantity"
								variant="outlined"
								autoComplete="false"
								disabled={submitting}
							/>
						</DialogContent>

						<DialogActions>
							<Button
								type="button"
								disabled={submitting}
								onClick={closeModal}
								color="primary"
							>
								{t('table.modal.delete.btn_cancel')}
							</Button>
							<ButtonProgress
								type="submit"
								color="primary"
								className={classes.wrapper}
								isLoading={submitting}
								disabled={submitting}
							>
								{t('table.modal.edit.btn_save')}
							</ButtonProgress>
						</DialogActions>
					</form>
				)}
			/>
		</Dialog>
	);
};

EditCarsForm.propTypes = {
	open: bool.isRequired,
	closeModal: func.isRequired,
	quantity: number,
	uuid: string.isRequired,
};

export default EditCarsForm;
