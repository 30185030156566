// -----------------  GET USER -----------------
export const AUTH_USER_LOADING = 'AUTH_USER_LOADING';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';

// -----------------  UNAUTHORIZE - TOKEN INVALID -----------------
export const SIGN_OUT_LOADING = 'SIGN_OUT_LOADING';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

// -----------------  FORGOT_PASSWORD -----------------
export const SET_RESET_TOKEN = 'SET_RESET_TOKEN';

export const USER_PROFILE_FETCH_SUCCESS = 'USER_PROFILE_FETCH_SUCCESS';
export const USER_PROFILE_FETCH_ERROR = 'USER_PROFILE_FETCH_ERROR';
