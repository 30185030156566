import services from 'services/services';
import {
	submitOrganizationFormUrl,
	submitAddOrganizationFormUrl,
} from 'store/paths';

export const submitOrganizationForm = ({
	values,
	itemId,
	isEdit,
	callbackSuccess,
	errorCallback,
	cancelToken,
}) => async () => {
	try {
		const data = { details: values };

		await services.post(
			submitOrganizationFormUrl(itemId, isEdit),
			data,
			cancelToken
		);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};

export const submitAddOrganizationForm = ({
	values,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		const data = { details: values };

		await services.post(submitAddOrganizationFormUrl, data);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
