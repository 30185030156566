import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		minHeight: 'calc(100vh - 2 * 80px)',
		padding: '16px',
		gap: '32px',
	},
	errorWrapper: {
		width: '100%',
		minHeight: 'calc(100vh - 2 * 80px)',
		padding: '16px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: '16px',
	},
}));
