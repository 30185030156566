import React from 'react';
import { number, object, string } from 'prop-types';
import { Typography, ListItem as MuiListItem } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { LIST_ITEMS_TYPES } from '../../../../helpers';

// Import styles
import { useStyles } from './styles';

export const ListItem = ({ item, index, type }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const itemName =
		type === LIST_ITEMS_TYPES.REASONS ? item.name : item.company_name;

	return (
		<MuiListItem
			key={itemName}
			alignItems="flex-start"
			className={classes.item}
		>
			<Typography>
				<Typography color="primary" component="span">
					{index + 1}
					{' - '}
				</Typography>
				{itemName} {t('bookings.bookings_count', { count: item.booking_count })}
			</Typography>
		</MuiListItem>
	);
};

ListItem.propTypes = {
	index: number,
	item: object,
	type: string,
};
