import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { B2B_CORPORATION } from 'helpers';

export const useCorporation = (corporateUuid) => {
	const path = `/dashboard/b2b/corporate/${corporateUuid}`;

	const { data, isFetching, isError } = useQuery(
		B2B_CORPORATION,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data || null;

	return { data: fetchedData, isFetching, isError };
};
