import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { RatingFieldAdapter, TextFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

const CustomerRatingInfo = () => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography component="p">
					{t('bookings.customer_rating.form.rating')}
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<Field
					component={RatingFieldAdapter}
					name="rating"
					size="large"
					disabled={submitting}
				/>
			</Grid>

			<Grid item xs={12}>
				<Field
					component={TextFieldAdapter}
					label={t('bookings.customer_rating.form.description')}
					variant="outlined"
					size="small"
					name="description"
					disabled={submitting}
					fullWidth
					multiline
				/>
			</Grid>
		</Grid>
	);
};

export default CustomerRatingInfo;
