import i18n from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

const testOptions = [
	{ id: 'private', name: i18n.t('leasing_cars.form.plate.options.private') },
	{
		id: 'private_transport',
		name: i18n.t('leasing_cars.form.plate.options.private_transport'),
	},
];

export const LEASING_PLATE_NUMBER_FIELDS = ({ currentPlateNumber = '' }) => [
	{
		type: FIELD_TYPES.SELECT,
		name: 'plate_type',
		label: 'common.cars.headers.plate_type',
		md: 12,
		options: testOptions,
		required: false,
		keyExtractor: ({ id }) => id,
		labelExtractor: ({ name }) => name,
	},
	{
		type: FIELD_TYPES.PLATE_NUMBER,
		name: 'plate',
		required: false,
		shouldAutoFocus: true,
		outerValue: currentPlateNumber,
		md: 6,
		showSearchPlate: false,
	},
];
