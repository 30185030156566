import React from 'react';
import { fetchPromoCodes } from 'store/actions';

// Import helpers and utils
import { TableRPC } from 'components/utilities';
import { TOOLBAR_CONTENT } from './helpers';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow } from './components';

export const PromoCodes = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			toolBarContent={TOOLBAR_CONTENT}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchPromoCodes}
		/>
	);
};
