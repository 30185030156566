import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter, TextFieldAdapter } from 'components/elements';

const BankInfo = (props) => {
	const { className, section, ...rest } = props;
	const { submitting, values } = useFormState();
	const {
		bank: { use_company_bank },
	} = values;

	const { t } = useTranslations();

	const FIELDS = [
		{
			type: 'text',
			label: 'offices.form.bank_info.name',
			name: `${section}.name`,
		},
		{
			type: 'text',
			label: 'offices.form.bank_info.bank_name',
			name: `${section}.bank_name`,
		},
		{
			type: 'text',
			label: 'offices.form.bank_info.iban_number',
			name: `${section}.iban_number`,
		},
		{
			type: 'text',
			label: 'offices.form.bank_info.account_number',
			name: `${section}.number`,
		},
	];

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.bank_info.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Field
							type="checkbox"
							component={CheckboxFieldAdapter}
							name={`${section}.use_company_bank`}
							labelText={t('offices.form.bank_info.same_as_company')}
							disabled={submitting}
							color="primary"
						/>
					</Grid>
					{!use_company_bank && (
						<>
							{FIELDS.map((field) => (
								<Grid key={field.name} item md={6} xs={12}>
									<Field
										fullWidth
										component={TextFieldAdapter}
										type={field.type}
										label={t(field.label)}
										name={field.name}
										margin="dense"
										variant="outlined"
										disabled={submitting}
										required
									/>
								</Grid>
							))}
						</>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

BankInfo.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default BankInfo;
