export const FILE_FORMATS = {
	XLSX: 'xlsx',
	CSV: 'csv',
	ODS: 'ods',
};

export const MIME_TYPES = {
	XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	CSV: 'text/csv;charset=utf-8;',
	ODS: 'application/vnd.oasis.opendocument.spreadsheet',
};
