// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FIELDS = [
	{
		type: FIELD_TYPES.PASSWORD,
		label: 'common.fields.password',
		name: 'password',
		required: true,
	},
	{
		type: FIELD_TYPES.PASSWORD,
		label: 'common.fields.password_confirmation',
		name: 'password_confirmation',
		required: true,
	},
];
