import React from 'react';
import { bool, func } from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import useValidationModal from './useValidationModal';

// Import components
import {
	ValidateUrgentForm,
	ValidateCancellationForm,
	ValidateCompensationForm,
} from '../';

const ValidationModal = ({ open, onClose }) => {
	const { t } = useTranslations();

	const { isCancellation, isCompensation, isUrgent } = useValidationModal();

	return (
		<Dialog fullWidth disableEscapeKeyDown open={open} onClose={onClose}>
			<DialogTitle>{t('validation.table.modal.title')}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					{isCompensation && (
						<Grid item xs={12}>
							<ValidateCompensationForm />
						</Grid>
					)}
					{isUrgent && (
						<Grid item xs={12}>
							<ValidateUrgentForm />
						</Grid>
					)}
					{isCancellation && (
						<Grid item xs={12}>
							<ValidateCancellationForm />
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="outlined" onClick={onClose}>
					{t('common.buttons.ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

ValidationModal.propTypes = {
	open: bool.isRequired,
	onClose: func.isRequired,
};

export default ValidationModal;
