import React from 'react';
import { object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { getTopics } from './helpers';

export const TopicTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const data = cell.row.original || {};
	const topics = getTopics({ data });

	return (
		<TableCell
			key={cell.column.id}
			style={{ minWidth: '120px' }}
			{...cell.getCellProps()}
		>
			{topics.map((topic) => (
				<Typography key={topic}>{t(`common.fields.${topic}`)}</Typography>
			))}
		</TableCell>
	);
};

TopicTableCell.propTypes = {
	cell: object.isRequired,
};
