import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

// Import utils
import { useToggle } from 'components/utilities';

export const UnsettledLimitsContext = createContext(null);

export const UnsettledLimitsProvider = ({ children }) => {
	const { on: isDialogOpen, toggle: toggleDialog } = useToggle();
	const [isButtonLoading, setButtonLoading] = useState(false);
	const [settledUuids, setSettledUuids] = useState([]);

	const isSettled = (settledUuid) => settledUuids.includes(settledUuid);

	const handleOnSettledUuids = (settledUuid) => {
		if (isSettled(settledUuid)) {
			const filteredUuids = settledUuids.filter((uuid) => settledUuid !== uuid);
			setSettledUuids(filteredUuids);
		} else {
			setSettledUuids((prev) => [...new Set([...prev, settledUuid])]);
		}
	};

	return (
		<UnsettledLimitsContext.Provider
			value={{
				handleOnSettledUuids,
				setButtonLoading,
				setSettledUuids,
				settledUuids,
				toggleDialog,
				isSettled,
				isButtonLoading,
				isDialogOpen,
			}}
		>
			{children}
		</UnsettledLimitsContext.Provider>
	);
};

UnsettledLimitsProvider.propTypes = {
	children: node,
};
