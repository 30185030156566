import * as types from 'store/types';
import services from 'services/services';

// Import helpers and utils
import { catchError } from '../helpers';
import { appVersionsUrl } from 'store/paths';
import { convertSubmitData } from './helpers';

export const fetchVersionsForm =
	({ cancelToken }) =>
	async (dispatch) => {
		try {
			dispatch({ type: types.MOBILE_APP_VERSIONS_FETCH_DATA_LOADING });

			const { data } = await services.get(appVersionsUrl, cancelToken);

			dispatch({
				type: types.MOBILE_APP_VERSIONS_FETCH_DATA_SUCCESS,
				payload: {
					item: data,
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.MOBILE_APP_VERSIONS_FETCH_DATA_ERROR,
			});
		}
	};

export const submitVersionsForm =
	({ values, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			const body = convertSubmitData(values);
			await services.put(appVersionsUrl, body);

			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};
