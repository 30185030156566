import { useDispatch } from 'react-redux';
import { pick } from 'lodash';

// Import helpers and utils
import { convertApiFormErrors } from 'helpers';
import { useMessage } from 'components/utilities';

// Import store
import { verifyLoginOtp } from 'store/actions';

export const useSignInForm = () => {
	const dispatch = useDispatch();
	const { message } = useMessage();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		const body = pick(values, ['token', 'code']);

		await verifyLoginOtp(body, (error) => {
			const { message: apiErrorMessage, errors } = error || {};
			apiErrorMessage && message.error(apiErrorMessage);
			apiErrors = errors && convertApiFormErrors(errors);
		})(dispatch);

		return apiErrors;
	};

	return {
		handleOnSubmit,
	};
};
