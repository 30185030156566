import React from 'react';

// Import helpers
import { fetchCancellationBookingsByCustomer } from 'store/actions';
import { TOOLBAR_CONTENT } from './helpers';
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow } from 'views/CancellationValidation/components';
import { TableRPC } from 'components/utilities';

export const CancellationByCustomer = () => {
	return (
		<TableRPC
			fetchDataTableAction={fetchCancellationBookingsByCustomer}
			columnsAccessor={COLUMNS_ACCESSORS}
			toolBarContent={TOOLBAR_CONTENT}
			tableBodyRow={TableBodyRow}
			columns={columns}
		/>
	);
};
