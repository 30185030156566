import { t } from 'i18next';

export const sortColumns = (columns) => {
	const sortedColumns = [...columns].sort((a, b) => {
		const stringA = t(a.Header).trim();
		const stringB = t(b.Header).trim();
		return stringA.localeCompare(stringB);
	});
	return sortedColumns;
};
