export const convertInitialCoordinators = (coordinators) =>
	coordinators?.map((coordinator) => {
		const phoneNumber = coordinator.phone.slice(coordinator.phone.length - 9);
		return {
			...coordinator,
			prefix: coordinator.phone.slice(
				0,
				coordinator.phone.length - phoneNumber.length
			),
			phone: phoneNumber,
		};
	});
