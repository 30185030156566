import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

// Import components
import { ImagePreviewLabelAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

const ImagePreviewField = ({ label, name, imgUrl }) => {
	const { t } = useTranslations();

	if (!imgUrl) return null;

	return (
		<Field
			component={ImagePreviewLabelAdapter}
			label={t(label)}
			imgUrl={imgUrl}
			name={name}
			justifySpaceBetween
		/>
	);
};

ImagePreviewField.propTypes = {
	label: PropTypes.string,
	imgUrl: PropTypes.string,
	name: PropTypes.string,
};

export default ImagePreviewField;
