import React from 'react';
import { Chip } from '@material-ui/core';

// Import styles
import { useStyles } from './styles';

// Import translations
import { useTranslations } from 'components/utilities';

const BookingStatusChip = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	return (
		<Chip
			label={t(`bookings.preview_booking.booking_info.extended`)}
			className={classes.chip}
		/>
	);
};

export default BookingStatusChip;
