import services from 'services/services';
import { fetchWaitingCustomerInfoBookingsUrl } from 'store/paths';

export const fetchWaitingCustomerInfoBookings = ({
	options,
	queryParams,
	cancelToken,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		// Get response
		const { data } = await services.post(
			fetchWaitingCustomerInfoBookingsUrl(queryParams),
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};
