import * as types from 'store/types';
import services from 'services/services';
import {
	fetchUrgentReasonsFormResourcesUrl,
	submitUrgentReasonsFormUrl,
} from 'store/paths';

export const fetchUrgentReasonsFormResources = ({
	itemId,
	isEdit,
	cancelToken,
}) => async (dispatch) => {
	try {
		dispatch({
			type: types.URGENT_REASONS_FORM_FETCH_DATA_LOADING,
		});

		const { data } =
			isEdit &&
			(await services.get(
				fetchUrgentReasonsFormResourcesUrl(itemId),
				cancelToken
			));

		dispatch({
			type: types.URGENT_REASONS_FORM_FETCH_DATA_SUCCESS,
			payload: {
				item: data || {},
			},
		});
	} catch (error) {
		const defaultError = { message: '' };
		const response = error.response?.data ?? defaultError;
		dispatch({
			type: types.URGENT_REASONS_FORM_FETCH_DATA_ERROR,
			payload: response,
		});
	}
};

export const submitUrgentReasonsForm = ({
	values,
	itemId,
	isEdit,
	callbackSuccess,
	errorCallback,
}) => async () => {
	try {
		await services.post(submitUrgentReasonsFormUrl(itemId, isEdit), values);

		callbackSuccess();
	} catch (error) {
		error.response && errorCallback(error.response.data.errors);
	}
};
