import services from 'services/services';

// Import store
import { fetchExtendedBookingsUrl } from 'store/paths';

// Import helpers
import { combineQueryAndPaginationParams } from 'helpers';

export const fetchExtendedBookings =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchExtendedBookingsUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
