import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { array, string } from 'prop-types';

// Import components
import { Template, Detail } from '../../components';

// Import styles
import { useStyles } from './styles';

export const Details = ({ label, rows }) => {
	const columns = rows[0].length;

	const classes = useStyles({ columns });

	const isDividerVisible = (rows, index) =>
		rows.length > 1 && rows.length - 1 !== index;

	return (
		<Template label={label}>
			{rows.map((row, index) => (
				<Box key={index}>
					<Box className={classes.gridWrapper}>
						{row.map((data) => (
							<Detail key={data.label} {...data} />
						))}
					</Box>
					{isDividerVisible(rows, index) && (
						<Divider className={classes.divider} />
					)}
				</Box>
			))}
		</Template>
	);
};

Details.propTypes = {
	label: string,
	rows: array,
};
