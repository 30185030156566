import React from 'react';
import { Box } from '@material-ui/core';

// Import utils and helpers
import { useAddCorporateAndCoordinatorProvider } from '../../context';
import { getFormStep } from './helpers';

// Import styles
import { useStyles } from './PanelWrapper.styles';

export const PanelWrapper = () => {
	const { step } = useAddCorporateAndCoordinatorProvider();
	const classes = useStyles();

	return <Box className={classes.root}>{getFormStep(step)}</Box>;
};
