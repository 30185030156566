import { useQuery } from 'react-query';

import { CAMPAIGNS_GRATIFICATION_TYPES_QUERY } from 'helpers';
import services from 'services/services';

const useGratificationsTypes = ({ campaignType }) => {
	const path = `v2/customer/wallet-campaign/${campaignType}/gratification`;

	const { data, isFetching } = useQuery(
		[CAMPAIGNS_GRATIFICATION_TYPES_QUERY, campaignType],
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000,
			enabled: !!campaignType,
		}
	);

	const fetchedData = data ? data.data.data.gratification : [];

	return { data: fetchedData, isFetching };
};

export default useGratificationsTypes;
