import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { bool, number, oneOfType, string } from 'prop-types';
import clsx from 'clsx';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

export const Detail = ({ label, value, colored }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Box key={label}>
			<Typography className={classes.detailTitle}>{t(label)}</Typography>
			<Typography
				className={clsx(classes.detailValue, {
					[classes.colored]: colored,
				})}
			>
				{value}
			</Typography>
		</Box>
	);
};

Detail.propTypes = {
	value: oneOfType([string, number]).isRequired,
	label: string.isRequired,
	colored: bool,
};

Detail.defaultProps = {
	colored: false,
};
