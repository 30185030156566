import React from 'react';
import { bool, object, string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { ListItem } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';
import BehaviourList from './BehaviourList';
import ExcludeList from './ExcludeList';

// Import helpers
import { AVAILABLE_BEHAVIOURS, AVAILABLE_EXCLUDES } from '../helpers';

const PaymentMethod = ({ value, name, disabled = false }) => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	const availableBehaviours = AVAILABLE_BEHAVIOURS[value.name] || [];
	const availableExcludes = AVAILABLE_EXCLUDES[value.name] || [];

	const behavioursVisible = availableBehaviours.length > 0;
	const excludesVisible =
		availableExcludes.length > 0 &&
		(value.is_active || value.behaviours.length > 0);

	return (
		<>
			<ListItem>
				<Field
					type="checkbox"
					component={CheckboxFieldAdapter}
					name={`${name}.is_active`}
					disabled={submitting || disabled}
					labelText={t(`company.form.payment_methods.methods.${value.name}`)}
					color="primary"
				/>
			</ListItem>

			{behavioursVisible && (
				<BehaviourList
					name={name}
					availableBehaviours={availableBehaviours}
					disabled={disabled}
				/>
			)}

			{excludesVisible && (
				<ExcludeList
					name={name}
					availableExcludes={availableExcludes}
					disabled={disabled}
				/>
			)}
		</>
	);
};

PaymentMethod.propTypes = {
	value: object,
	name: string,
	disabled: bool,
};

export default PaymentMethod;
