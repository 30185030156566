import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isObject } from 'lodash';

// Import utils
import { useTableContextProvider } from 'components/context';
import { useQueryStringParams } from 'components/utilities';

export const useAdditionalFilters = () => {
	const { setFilters, filters, options } = useTableContextProvider();
	const { stringifyParams } = useQueryStringParams();
	const history = useHistory();

	const defaultFilters = {
		date_type: 'pick_date',
	};

	const isFilter = isObject(filters);
	const defaultValue = filters?.date_type || 'pick_date';

	const queryParams = stringifyParams({ filters, options });
	const defaultQueryParams = stringifyParams({
		filters: defaultFilters,
		options,
	});

	const handleOnChange = (_, value) => {
		const convertedFilters = {
			...filters,
			date_type: value,
		};

		setFilters(convertedFilters);

		history.push(queryParams);
	};

	useEffect(() => {
		history.push(isFilter ? queryParams : defaultQueryParams);
		//eslint-disable-next-line
	}, [isFilter]);

	return {
		handleOnChange,
		defaultValue,
	};
};
