import services from 'services/services';
import { changeIsCompensationFulfilledUrl } from 'store/paths';

export const changeIsCompensationFulfilled = async ({ uuid, fulfill }) => {
	try {
		await services.put(changeIsCompensationFulfilledUrl(uuid), {
			fulfill,
		});

		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error.response);
	}
};
