import { useQuery } from 'react-query';

// Import helpers
import { OFFICE_LEASING_CARS_IMPORT_QUERY } from 'helpers';

export const useLeasingCarsImport = ({
	isImportAllowed,
	importDataFunction,
	handleImportError,
	handleImportSuccess,
	handleImportSettled,
}) => {
	const { isLoading: isImportLoading } = useQuery(
		OFFICE_LEASING_CARS_IMPORT_QUERY,
		importDataFunction,
		{
			refetchOnWindowFocus: false,
			enabled: isImportAllowed,
			onError: handleImportError,
			onSuccess: handleImportSuccess,
			onSettled: handleImportSettled,
		}
	);

	return { isImportLoading };
};
