import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.primary.light,
		borderRadius: '16px',
		padding: '4px 12px',
		fontWeight: '500',
		fontSize: '12px',
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	closeIcon: {
		transform: 'rotate(45deg)',
		color: theme.palette.primary.main,
	},
}));
