import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { func, string, bool } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

// Import components
import { KeyboardDatePickerFieldAdapter } from 'components/elements';

// Import icons
import { CalendarIcon } from 'assets/icons';

// Import utilities
import { useTranslations } from 'components/utilities';
import useCustomDateInput from './useCustomDateInput';

// import styles
import { useStyles } from './styles';

const CustomDateInput = ({ name, label, onIconClick, required }) => {
	const { submitting } = useFormState();

	const classes = useStyles();

	const { t } = useTranslations();

	const { datePickerProps } = useCustomDateInput();

	return (
		<Field
			name={name}
			label={t(label)}
			component={KeyboardDatePickerFieldAdapter}
			disabled={submitting}
			inputVariant="outlined"
			margin="dense"
			fullWidth
			InputProps={{
				endAdornment: (
					<IconButton
						size="small"
						className={classes.icon}
						onClick={onIconClick}
					>
						<CalendarIcon />
					</IconButton>
				),
			}}
			{...datePickerProps}
			required={required}
		/>
	);
};

CustomDateInput.propTypes = {
	name: string.isRequired,
	label: string.isRequired,
	onIconClick: func.isRequired,
	required: bool.isRequired,
};

export default CustomDateInput;
