// -----------------  DEFAULT SEO -----------------
export const DEFAULT_SEO_TYPES_LOADING = 'DEFAULT_SEO_TYPES_LOADING';
export const DEFAULT_SEO_TYPES_SUCCESS = 'DEFAULT_SEO_TYPES_SUCCESS';
export const DEFAULT_SEO_TYPES_ERROR = 'DEFAULT_SEO_TYPES_ERROR';

// -----------------  FORM -----------------
export const DEFAULT_SEO_FORM_FETCH_DATA_LOADING =
	'DEFAULT_SEO_FORM_FETCH_DATA_LOADING';
export const DEFAULT_SEO_FORM_FETCH_DATA_SUCCESS =
	'DEFAULT_SEO_FORM_FETCH_DATA_SUCCESS';
export const DEFAULT_SEO_FORM_FETCH_DATA_ERROR =
	'DEFAULT_SEO_FORM_FETCH_DATA_ERROR';
