import React, { useState } from 'react';
import { object, string, array, bool } from 'prop-types';
import { OfficeItem, CollapsedItem } from './components';
import { Collapse, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	nested: {
		[theme.breakpoints.up('sm')]: {
			paddingLeft: '62px',
			paddingRight: '32px',
		},
	},
}));

const ListItem = ({
	carsFieldName,
	offices,
	officeName,
	cars,
	officeUuid,
	isEnglish,
	...rest
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const classes = useStyles();

	return (
		<>
			<OfficeItem
				carsFieldName={carsFieldName}
				offices={offices}
				officeName={officeName}
				officeUuid={officeUuid}
				cars={cars}
				isEnglish={isEnglish}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				{...rest}
			/>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<List dense className={classes.nested}>
					{cars.map(({ name, year, uuid: carUuid }) => (
						<CollapsedItem
							key={carUuid}
							carsFieldName={carsFieldName}
							offices={offices}
							officeUuid={officeUuid}
							car={name}
							cars={cars}
							carUuid={carUuid}
							year={year}
							isEnglish={isEnglish}
							{...rest}
						/>
					))}
				</List>
			</Collapse>
		</>
	);
};

ListItem.propTypes = {
	carsFieldName: string.isRequired,
	offices: array.isRequired,
	officeName: object.isRequired,
	cars: array.isRequired,
	officeUuid: string.isRequired,
	isEnglish: bool.isRequired,
};

export default ListItem;
