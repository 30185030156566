// Import helpers
import { FIELD_TYPES } from 'helpers';

export const COORDINATOR_FIELDS = ({ index, prefixes }) => [
	{
		name: `coordinators[${index}].name`,
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.contact_person_name',
		required: true,
		md: 6,
	},
	{
		name: `coordinators[${index}].booking_limit_amount_gross`,
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.maximum_limit_for_booking',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.phone_prefix',
		name: `coordinators[${index}].prefix`,
		required: true,
		xs: 3,
		md: 2,
		options: prefixes,
		labelExtractor: (prefix) => prefix,
		keyExtractor: (prefix) => prefix,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'common.fields.contact_person_number',
		name: `coordinators[${index}].phone`,
		required: true,
		xs: 9,
		md: 4,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.email',
		name: `coordinators[${index}].email`,
		required: true,
		md: 6,
	},
];
