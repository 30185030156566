import React from 'react';
import { string } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeInput } from 'components/elements';

// Import helpers
import { conditionallyRequired } from 'helpers';

const DateRangeFilter = (props) => {
	const md = props.combinedInputs ? 3 : 6;

	return (
		<Grid item md={md}>
			<DateRangeInput {...props} />
		</Grid>
	);
};

DateRangeFilter.propTypes = {
	nameFrom: string.isRequired,
	nameUntil: string.isRequired,
	combinedInputs: conditionallyRequired,
	label: conditionallyRequired,
	labelFrom: conditionallyRequired,
	labelUntil: conditionallyRequired,
};

export default DateRangeFilter;
