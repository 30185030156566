import _ from 'lodash';

export const removeSensitiveData = (store) => {
	const itemsToRemove = ['meta', 'email', 'phone', 'name'];
	const convertedItems = itemsToRemove.map(
		(item) => `auth.authenticate.data.${item}`
	);

	const newStore = _.omit(store, convertedItems);

	return newStore;
};
