import React from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';

// Import utils
import { useTableExportContextProvider } from 'components/context/TableExportProvider/useTableExportContextProvider';

const TableExportProgress = () => {
	const { progress } = useTableExportContextProvider();

	return (
		<Box display="flex" alignItems="center">
			<Box width="100% " mr={1}>
				<LinearProgress
					variant="determinate"
					color="primary"
					value={progress}
				/>
			</Box>
			<Box minWidth={35}>
				<Typography color="primary">{`${Math.round(progress)}`}%</Typography>
			</Box>
		</Box>
	);
};

export default TableExportProgress;
