import { EXPORT_TYPES } from 'helpers';

import exportToExcel from './exportToExcel';
import exportToPdf from './exportToPdf';

const createExport = ({ type, customExport }) => {
	switch (type) {
		case EXPORT_TYPES.EXCEL:
			return customExport ? customExport : exportToExcel;
		case EXPORT_TYPES.PDF:
			return exportToPdf;
		default:
			throw new Error(`Invalid export type ${type}`);
	}
};

export default createExport;
