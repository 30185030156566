import { combineReducers } from 'redux';
import * as types from 'store/types';

// IMPORT COMMON REDUCERS
import auth from './auth/auth_reducer';
import offices from './offices';
import bookings from './bookings';
import company from './company';
import profile from './profile';
import customers from './customers';
import car from './car';
import leasingCar from './leasingCar';
import promoCode from './promoCode';
import discounts from './discounts';
import cancellationReasons from './cancellationReasons';
import models from './models';
import users from './users';
import manufacturer from './manufacturer';
import sliders from './sliders';
import partners from './partners';
import defaultSeo from './defaultSeo';
import carList from './carList';
import pages from './pages';
import publicHolidays from './publicHolidays';
import specialOffer from './specialOffer';
import dashboard from './dashboard';
import companyManager from './companyManager';
import mobileAppVersions from './mobileAppVersions';
import ratingReasons from './ratingReasons';
import urgentReasons from './urgentReasons';
import airports from './airports';
import terminals from './terminals';
import kiosks from './kiosks';
import registrationProcess from './RegistrationProcess';
import whitelist from './whitelist';
import navigation from './navigation';
import campaigns from './campaigns';
import validation from './validation';
import carsAvailability from './carsAvailability';
import employeeRewarding from './employeeRewarding';
import officeCars from './officeCars';
import drivers from './drivers';
import corporate from './corporate';
import plate from './plate';
import faq from './faq';

const appReducer = combineReducers({
	airports,
	auth,
	bookings,
	campaigns,
	cancellationReasons,
	car,
	leasingCar,
	carList,
	carsAvailability,
	company,
	companyManager,
	customers,
	dashboard,
	defaultSeo,
	discounts,
	employeeRewarding,
	kiosks,
	manufacturer,
	mobileAppVersions,
	models,
	navigation,
	offices,
	pages,
	partners,
	profile,
	promoCode,
	publicHolidays,
	ratingReasons,
	registrationProcess,
	sliders,
	specialOffer,
	terminals,
	urgentReasons,
	users,
	validation,
	whitelist,
	corporate,
	officeCars,
	drivers,
	plate,
	faq,
});

const rootReducer = (state, action) => {
	if (action.type === types.SIGN_OUT_SUCCESS) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
