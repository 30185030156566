import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCarInfo, setCompanyContext } from 'store/actions';

export const useGlobalCarForm = () => {
	const { companyUuid } = useParams();

	const dispatch = useDispatch();

	const handleFetchCarInfo = (formProps) => {
		return fetchCarInfo({ ...formProps, companyUuid });
	};

	useEffect(() => {
		setCompanyContext(dispatch, companyUuid);
	}, [dispatch, companyUuid]);

	return { handleFetchCarInfo };
};
