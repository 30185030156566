import React from 'react';
import { useSelector } from 'react-redux';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

// Import components
import { DefaultPreviewField } from '../index';

const FullArabicNamePreviewField = (props) => {
	const {
		customer: { full_arabic_name },
	} = useSelector(previewBookingSelector);

	if (!full_arabic_name) return null;

	return <DefaultPreviewField {...props} />;
};

export default FullArabicNamePreviewField;
