import React from 'react';
import { array, string } from 'prop-types';
import {
	Card,
	CardHeader,
	Divider,
	CardContent,
	Grid,
} from '@material-ui/core';

// Import translations
import { useCommonFields, useTranslations } from 'components/utilities';

export const Section = ({ title, fields }) => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	return (
		<Card>
			<CardHeader component="h5" title={t(`promo_codes.form.${title}.title`)} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{fields.map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

Section.propTypes = {
	title: string.isRequired,
	fields: array.isRequired,
};
