import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

// Import utilities
import { useTableContextProvider } from 'components/context';

// Import store
import { isValidationTableChangedSelector } from 'store/selectors';
import {
	setValidationTableChangedState,
	setBookingPreviewItem,
	resetBookingPreviewItem,
} from 'store/actions';

// Import helpers
import { getStatus, getValidationState } from './helpers';

const useValidationActionTableCell = ({ cell }) => {
	const [isOpen, setIsOpen] = useState(false);

	const { fetchData } = useTableContextProvider();

	const dispatch = useDispatch();

	const { pathname } = useLocation();

	const booking = cell.row.original;

	const {
		cancel_reason_status: cancellationStatus,
		compensation_status: compensationStatus,
	} = booking || {};

	const isChanged = useSelector(isValidationTableChangedSelector);

	const handleOnOpenModal = () => {
		setBookingPreviewItem({ booking, dispatch });

		setValidationTableChangedState({ isTableChanged: false, dispatch });

		setIsOpen(true);
	};

	const handleOnCloseModal = () => {
		setIsOpen(false);

		resetBookingPreviewItem({ dispatch });

		isChanged && fetchData();
	};

	const status = getStatus({
		pathname,
		cancellationStatus,
		compensationStatus,
	});

	const isValidated = getValidationState({ pathname, status });

	return {
		handleOpenModal: handleOnOpenModal,
		handleCloseModal: handleOnCloseModal,
		isValidated,
		isOpen,
		status,
	};
};

export default useValidationActionTableCell;
