export const convertDataRowsForExtendedAt = (data) => {
	if (data && data.length > 0) {
		if (!data[0].is_filter_by_extension_created_at) {
			return data;
		} else {
			return data.reduce((acc, booking) => {
				if (booking.extension_created_at_as_array) {
					booking.extension_created_at_as_array.forEach((date) => {
						acc.push({
							...booking,
							extension_created_at: date,
							extension_gross_amount:
								booking.extension_gross_amount_with_created_at?.[date],
							drop_date: booking.extension_drop_date_as_array?.[date],
						});
					});
				} else {
					acc.push(booking);
				}
				return acc;
			}, []);
		}
	}
	return data;
};

export const transformColumns = (cols) => {
	return cols.reduce((result, col) => {
		if (col.subColumns && col.isVisible) {
			result.push(...col.subColumns);
		} else {
			result.push(col);
		}
		return result;
	}, []);
};
