import { makeStyles } from '@material-ui/styles';

export const useBaseLayoutStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	content: {
		marginTop: theme.spacing(2),
	},
}));
