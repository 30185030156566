import myAxios from './myAxiosFactory';

import { createAxiosInterceptors } from './helpers';

const servicesFactory = (myAxios) => ({
	post: (url, payload = {}, cancelToken) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.post(url, payload, { cancelToken });
	},
	put: (url, payload = {}, config = {}) => {
		let axios = myAxios(config);

		axios = createAxiosInterceptors(axios);
		return axios.put(url, payload);
	},
	patch: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.patch(url, payload);
	},
	delete: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.delete(url, payload);
	},
	get: (url, cancelToken) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.get(url, { cancelToken });
	},
	getBlob: (url, cancelToken) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.get(url, { cancelToken, responseType: 'blob' });
	},
});

const services = servicesFactory(myAxios);

export default services;
