import React from 'react';
import { string } from 'prop-types';

// Import components
import { PreviewField } from '../index';

// Import utilities
import useOriginalDropDatePreviewField from './useOriginalDropDatePreviewField';

const OriginalDropDatePreviewField = ({ label }) => {
	const { dropDate } = useOriginalDropDatePreviewField();

	if (!dropDate) return null;

	return (
		<PreviewField label={label} labelColor="primary">
			{dropDate}
		</PreviewField>
	);
};

OriginalDropDatePreviewField.propTypes = {
	label: string.isRequired,
};

export default OriginalDropDatePreviewField;
