// ********************  table  ********************

export const fetchPartnersUrl = (queryParams) =>
	`/v2/partner/list${queryParams}`;

export const deletePartnerUrl = (id) => `/v2/partner/${id}/delete`;
export const updatePartnerActiveStateUrl = (id, isActive) =>
	`/v2/partner/${id}/${isActive ? 'deactivate' : 'activate'}`;

// ********************  form  ********************

export const showPromotedCompaniesUrl = `/v2/promoted-companies/list`;
export const fetchBusinessCompanyCollectionUrl = `/v2/supplier/business/company/collection`;
export const updatePromotedCompaniesUrl = `/v2/promoted-companies/update-list`;

export const createPartnerUrl = `/v2/partner/create`;
export const updatePartnerUrl = (uuid) => `/v2/partner/${uuid}/update`;

export const fetchCmsPartnersFormResourcesUrl = (uuid) =>
	`/v2/partner/${uuid}/show`;

export const submitCmsPartnersFormUrl = (itemId) =>
	itemId ? `/v2/partner/${itemId}/update` : '/v2/partner/create';
