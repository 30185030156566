import React from 'react';
import { string } from 'prop-types';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { DISCOUNT_DETAILS_FIELDS } from './helpers';

const DiscountDetails = ({ section, className, ...rest }) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('discounts.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{DISCOUNT_DETAILS_FIELDS.map(
						({ name, label, type, component, max = '', md, xs }) => (
							<Grid key={name} item md={md} xs={xs}>
								<Field
									fullWidth
									component={component}
									type={type}
									label={t(label)}
									name={`${section}.${name}`}
									disabled={submitting}
									margin="dense"
									variant="outlined"
									required
									inputProps={{ min: 1, max: max }}
								/>
							</Grid>
						)
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

DiscountDetails.propTypes = {
	section: string.isRequired,
	className: string,
};

export default DiscountDetails;
