import services from 'services/services';
import {
	fetchRatingReasonsUrl,
	updateActiveRatingReasonUrl,
	deleteRatingReasonUrl,
} from 'store/paths';

// Import helpers
import { UPDATE_ACTIVE_TYPES } from 'helpers/variables';

export const fetchRatingReasons = ({
	options,
	queryParams,
	cancelToken,
}) => async () => {
	try {
		const { pageIndex, pageSize } = options;
		const body = {
			start: pageIndex * pageSize,
			length: pageSize,
		};

		// Get response
		const { data } = await services.post(
			fetchRatingReasonsUrl(queryParams),
			body,
			cancelToken
		);

		return Promise.resolve({ data });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateActiveRatingReason = ({ isActive, id }) => async () => {
	const { ACTIVATE, DEACTIVATE } = UPDATE_ACTIVE_TYPES;
	const type = isActive ? DEACTIVATE : ACTIVATE;

	await services.put(updateActiveRatingReasonUrl(id, type));
};

export const deleteRatingReason = ({ id }) => async () => {
	await services.delete(deleteRatingReasonUrl(id));
};
